import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoAddCircleOutline } from "react-icons/io5";
import BtcServices from "../../../../../rest-services/BtcServices";
import Pagination from "../../../../../components/PageNation";
import { IoMdRefresh } from "react-icons/io";
import { MdEdit, MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import BankStatementActions from "../../props/ActionButtons";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { useToast } from "../../../../../components/toast/toast";
import SearchInput from "../../../../../components/SearchInput";


export default function BTC() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [pageSize, setPageSize] = useState(20); // Default page size
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [showExtraIcons, setShowExtraIcons] = useState(false);

  useEffect(() => {
    fetchDataForTable();
  }, [currentPage]);

  const handleNavigate = (path) => {
    navigate(path);
  };


  const fetchDataForTable = async () => {
    try {
      const response = await BtcServices.getAllData(pageSize, currentPage);
      if (response.status === 200) {
        setTableData(response.data.data.btcCodes);
        let totalCount = response.data.data.totalCount;
        setTotalCount(totalCount);
        setTotalPages(Math.ceil(totalCount / pageSize));
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    if (searchQuery.length > 0) {
      fetchBtcBysearch();
    }
  }, [searchQuery]);

  const fetchBtcBysearch = async () => {
    try {
      setLoading(true);
      const response = await BtcServices.getBtcServiceBysearch(searchQuery, pageSize, currentPage);
      if (response.status === 200) {
        setTableData(response.data.data.records);
        setTotalCount(response.data.data.totalCount);
        setTotalPages(Math.ceil(response.data.data.totalCount / pageSize));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchDataForTable(); // Fetch data with updated page size
  };
  const columns = [
    {
      Header: "BTC Code",
      accessor: "btcCode",
      visible: true,
      width: ("BTC Code".length - 1) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Description",
      accessor: "description",
      visible: true,
      width: ("Decription".length - 1) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Inflow",
      accessor: "inflow",
      visible: true,
      width: ("Inflow".length - 1) * 10,

      Cell: ({ value }) => (
        <input type="checkbox" className="accent-customBlue" checked={value === "1"} readOnly />
      ),
    },
    {
      Header: "Outflow",
      accessor: "outflow",
      visible: true,
      width: ("Outflow".length - 1) * 10,
      Cell: ({ value }) => (
        <input type="checkbox" className="accent-customBlue" checked={value === "1"} readOnly />
      ),
    },

    {
      Header: "Statement Type",
      accessor: "statement",
      visible: true,
      width: ("Statement Type".length - 1) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },

    { Header: "Created By", accessor: "createdBy", visible: true, width: ("Created By".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: ("Created Date".length - 1) * 10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return (
          <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>
        );
      }
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width: ("Created Time".length - 1) * 10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
    {
      Header: "Updated By",
      accessor: "changedBy",
      visible: true,
      width: ("Updated By".length - 1) * 10,
      canResize: true,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: ("Updated Date".length - 1) * 10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return (
          <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>
        );
      }
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width: ("Updated Time".length - 1) * 10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };



  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = tableData.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];
    setShowExtraIcons(updatedSelectedRows.length === 1);
    setSelectedRows(updatedSelectedRows);
  };

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const onRowDoubleClick = (id) => {
    navigate(`/btc-view/${id}`, { state: { mode: 'view' } });
  }

  //Buttons things
  const buttons = bankStatementActionsConfig.screen1.map((button) => {
    let onClick;
    let title;
    if (button.icon === IoAddCircleOutline) {
      onClick = () => handleNavigate("/btc-add");
      title = "Navigate ";
    } else if (button.icon === IoMdRefresh) {
      onClick = () => fetchDataForTable();
      title = "download";
    }
    else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downloadExcel();
      title = "download";
    }
    else if (button.icon === MdOutlineFileUpload) {
      onClick = () => handleNavigate("/5/bank-statement/48/btc-upload");
      title = "upload";
    }
    return {
      ...button,
      onClick,
      title,
    };
  });
  const extraButtons = [
    {
      icon: MdEdit,
      title: "Approve",
      disabled: selectedRows.length === 0,
      onClick: () => navigate("/btc-add", { state: { id: selectedRows[0] } })
    },
  ]

  const downloadExcel = async () => {
    try {
      const response = await BtcServices.exportBTC();
      if (response) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "btc.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast("success", "Downloaded Successfully");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      toast("error", "Failed to download data");
    }
  }


  return (
    <div>
      <div>
        <Header
          title="Bank Statements > MT940 > BTC"
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>
      <div>
      </div>
      <div className="p-0">
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by BTC Code"
            />

            <BankStatementActions buttons={buttons} extraButtons={extraButtons} isExtraButtonsVisible={showExtraIcons} />
          </div>
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>

        </div>
        <div>
          <ReTable
            data={tableData}
            columns={columns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </div>
  );
}
