import React, { useState, useEffect } from "react";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import SetUpServices from "../../../../../rest-services/SetUpServices";
import { useToast } from "../../../../../components/toast/toast";
import Footer from "../../../../../components/footer";
import UserService from "../../../../../rest-services/UserServices";

export default function AccountNumberLogic() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedNewAccountRequest, setSelectedNewAccountRequest] =
    useState(null);
  const [selectedForBankAccountId, setSelectedForBankAccountId] =
    useState(null);
  const { iconId } = useParams();

  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    assigner: "",
  });
  const [localData, setLocalData] = useState(data); // Track local changes
  const [changes, setChanges] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { toast } = useToast();
  const [hasViewPermission, SethasPermissionForView] = useState("");
  const [rolesPermissions, setRolesPermissions] = useState([]);


  useEffect(() => {
    if (assignee.assignerId) {
      console.log("Assigner ID has changed:", assignee.assignerId);
      fetchUserPermissionDetails();
    }
  }, [assignee.assignerId]);

// Get Logged in User Permission details function
const fetchUserPermissionDetails = async () => {
  try {
    const data = { userId: assignee.assignerId };
    console.log("Request Data:", data);

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
      data
    );
    console.log("user permissions details", response);
    const responseData = response.data;

    const hasPermission = responseData.rolesPermissionsTables.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === iconId );

    // if(!hasPermission) {
    //   toast("error", "You Don't have permission to view this Account Number Logic.");
    // }

    SethasPermissionForView(hasPermission);

    setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
    console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
  } catch (error) {
    console.error(error.message); // Set error message
  }
  //  finally {
  //   setLoading(false); // Reset loading state
  // }
};

  const fetchAccountNumberLogic = async () => {
    try {
      if (!assignee.clientId) {
        return;
      }
      const response = await SetUpServices.getAccountNumberLogic(
        assignee.clientId
      );
      const fetchedData = response.data.data.map((item) => ({
        ...item,
        subRows: [],
      }));
      setData(fetchedData);
      setLocalData(fetchedData);
    } catch (error) {
      console.error("Error fetching account number logic:", error);
    }
  };
  const handleFieldChange = (id, field) => {
    setLocalData((prevData) =>
      prevData.map((item) => ({
        ...item,
        [field]: item.id === id ? 1 : 0,
      }))
    );
    setChanges((prevChanges) => ({
      ...prevChanges,
      [id]: {
        ...prevChanges[id],
        [field]: 1,
        ...(field === "newAccountRequest"
          ? { forBankAccountId: 0 }
          : { newAccountRequest: 0 }),
      },
    }));
    setUnsavedChanges(true);
  };

  const handleSaveChanges = async () => {
    try {
      let successCount = 0;
      let errorCount = 0;
      for (const id in changes) {
        const payload = changes[id];
        const response = await SetUpServices.updateAccountNumberLogic(
          id,
          assignee.clientId,
          payload
        );

        if (response.status === 200) {
          successCount++;
          setUnsavedChanges(false);
          await fetchAccountNumberLogic();
        } else if (response.status === 409) {
          errorCount++;
          await fetchAccountNumberLogic();
        } else {
          console.warn(`Unexpected response: ${response.status}`);
        }
      }
      if (successCount > 0) {
        toast("success", `${successCount} change(s) saved successfully!`);
      }
      if (errorCount > 0) {
        toast(
          "error",
          `${errorCount} change(s) couldn't be applied due to same row selection.`
        );
      }
      setChanges({});
    } catch (error) {
      console.error("Error saving changes:", error);
      toast("error", "Failed to save changes. Please try again.");
    }
  };

  // const handleRadioChange = async (id, field) => {
  //   const newValue =
  //     field === "newAccountRequest"
  //       ? selectedNewAccountRequest !== id
  //       : selectedForBankAccountId !== id;

  //   setData((prevData) =>
  //     prevData.map((item) => ({
  //       ...item,
  //       [field]: item.id === id ? 1 : 0,
  //     }))
  //   );

  //   try {
  //     const payload =
  //       field === "newAccountRequest"
  //         ? { newAccountRequest: newValue ? 1 : 0 }
  //         : { forBankAccountId: newValue ? 1 : 0 };

  //     const response = await SetUpServices.updateAccountNumberLogic(
  //       id,
  //       payload
  //     );

  //     if (response.status === 200) {
  //       field === "newAccountRequest"
  //         ? setSelectedNewAccountRequest(id)
  //         : setSelectedForBankAccountId(id);
  //       await fetchAccountNumberLogic();
  //     } else if (response.status === 204) {
  //       toast("error", "Can't Select Same Option For Both");
  //       fetchAccountNumberLogic();
  //     }
  //   } catch (error) {
  //     console.error("Error updating account number logic:", error);

  //     // Revert if the API call fails
  //     setData((prevData) =>
  //       prevData.map((item) => ({
  //         ...item,
  //         [field]:
  //           field === "newAccountRequest"
  //             ? item.id === selectedNewAccountRequest
  //               ? 1
  //               : 0
  //             : item.id === selectedForBankAccountId
  //             ? 1
  //             : 0,
  //       }))
  //     );
  //   }
  // };

  useEffect(() => {
    fetchAssignee();
  }, []);

  useEffect(() => {
    fetchAccountNumberLogic();
  }, [assignee]);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: "Account ID Generation Options",
  //       accessor: "accountIdGenerationOptions",
  //       width: 300,
  //     },
  //     { Header: "Multiple", accessor: "multiple", width: 200 },
  //     {
  //       Header: "New Account Request",
  //       accessor: "newAccountRequest",
  //       width: 150,
  //       Cell: ({ row }) =>
  //         row.original.accountIdGenerationOptions ===
  //         "Bank(4)Curr(3)Account(5)" ? (
  //           "--"
  //         ) : (
  //           <input
  //             type="radio"
  //             name="newAccountRequest"
  //             checked={row.original.newAccountRequest === 1}
  //             onChange={() =>
  //               handleRadioChange(row.original.id, "newAccountRequest")
  //             }
  //           />
  //         ),
  //     },
  //     {
  //       Header: "For Bank Account ID",
  //       accessor: "forBankAccountId",
  //       width: 150,
  //       Cell: ({ row }) => (
  //         <input
  //           type="radio"
  //           name="forBankAccountId"
  //           checked={row.original.forBankAccountId === 1}
  //           onChange={() =>
  //             handleRadioChange(row.original.id, "forBankAccountId")
  //           }
  //         />
  //       ),
  //     },
  //   ],
  //   [selectedNewAccountRequest, selectedForBankAccountId] // Update on changes to selected rows
  // );

  const columns = React.useMemo(
    () => [
      {
        Header: "Account ID Generation Options",
        accessor: "accountIdGenerationOptions",
        width: ("Account ID Generation Options".length - 3) * 10,
      },
      { Header: "Example", accessor: "multiple", width: ("Example".length - 2) * 10 },
      {
        Header: "New Account Request",
        accessor: "newAccountRequest",
        width: ("New Account Request".length - 3) * 10,
        Cell: ({ row }) =>
          row.original.accountIdGenerationOptions ===
            "Bank(4)Curr(3)Account(5)" ? (
            "--"
          ) : (
            <input
              type="radio"
              checked={row.original.newAccountRequest === 1}
              disabled={row.original.isAccountCreated === 1}
              onChange={(e) =>
                handleFieldChange(row.original.id, "newAccountRequest", 1)
              }
            />
          ),
      },
      {
        Header: "New Bank Account ID",
        accessor: "forBankAccountId",
        width: ("New Bank Account ID".length - 3) * 10,
        Cell: ({ row }) => (
          <input
            type="radio"
            checked={row.original.forBankAccountId === 1}
            disabled={row.original.isAccountCreated === 1}
            onChange={(e) =>
              handleFieldChange(row.original.id, "forBankAccountId", 1)
            }
          />
        ),
      },
    ],
    [selectedNewAccountRequest, selectedForBankAccountId]
  );

  return (
    <div>
      <Header
        title={"BAM > Set Up > Account Number Logic"}
        onBreadcrumbClick={(crumb) => {
          if (crumb.title === "BAM") navigate("/bam");
          else if (crumb.title === "Set Up") navigate("/bam");
          else if (crumb.title === "Account Number Logic")
            navigate("/3/BAM/22/account-number-logic");
        }}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/bam"}
      />

      {/* {hasViewPermission ? ( */}
        <div>
          

      {/* <BamActions buttons={buttons} /> */}
      <ReTable
        data={localData} // Use localData for display
        columns={columns}
        onSelectRow={setSelectedRows}
        selectedRows={selectedRows}
        onSelectAll={() => setSelectedRows(data.map((d) => d.id))}
        onRowDoubleClick={(id) => console.log("Row double-clicked:", id)}
        showCheckbox={false}
      />
      <Footer>
        <button
          onClick={handleSaveChanges}
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
        >
          Save
        </button>
      </Footer>

      </div>
          {/* ): ('')
      } */}
    </div>
  );
}
