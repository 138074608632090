
import CustomButton from "../../../../components/CustomButton";
import Footer from "../../../../components/footer";
import HoverButton from "../../../../components/HoverButton";
import Header from "../../../../components/Header";
import { useEffect, useState } from "react";
import { IoIosList } from "react-icons/io";
import Tabs from "../../../../components/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalProcessServices from "../../../../rest-services/GlobalProcessServices";
import StatementAnalyzerService from "../../../../rest-services/StatementAnalyserServices";
import { useToast } from "../../../../components/toast/toast";
import CheckboxPopup from "../../../../components/CheckBoxComponent";
import { RxDropdownMenu } from "react-icons/rx";
import CustomDropdown from "../../../../components/CustomDropdown";
import TableWrapper from "../../../../components/ScrollBarComponent";

export default function AddStatementAnalyzerBs() {
    //Tabs
    const [activeTab, setActiveTab] = useState("masterdata");
    const [isValidationTriggered, setIsValidationTriggered] = useState(false);

    const { toast } = useToast();
    const tabs = [
        { value: "masterdata", label: "Primary Data" },
        { value: "yearmonth", label: "Year/Month" },
        { value: "statuscontrols", label: "Status/Controls" },
    ]

    const errorss = {

    };
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const navigate = useNavigate()
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Statement Identifier") {
            navigate("/statement-identifire");
        } else if (crumb.title === "Bank Statements") {
            navigate("/bank-statements");
        } else if (crumb.title === "MT940") {
            navigate("/bank-statements");
        } else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };

    const location = useLocation();
    const id = location.state?.id;

    const iconId = 50
    const menuId = 11

    // useEffect(() => {
    //     if (id) fetchDataForField();
    // }, [id]);

    const [processName, setProcessName] = useState("");
    const [assingBankAccountId, setAssingBankAccountId] = useState(0);

    const [transactionFromMonth, setTransactionFromMonth] = useState("")
    const [transactionToMonth, setTransactionToMonth] = useState("")
    const [transactionFromValueDate, setTransactionFromValueDate] = useState("")
    const [transactionToValueDate, setTransactionToValueDate] = useState("")
    const [importedFromMonth, setImportedFromMonth] = useState("")
    const [importedToMonth, setImportedToMonth] = useState("")
    const [importedFromValueDate, setImportedFromValueDate] = useState("")
    const [importedToValueDate, setImportedToValueDate] = useState("")
    const [dateBalance, setDateBalance] = useState("0")
    const [transferStatus, setTransferStatus] = useState("0")
    const [importedStatus, setImportedStatus] = useState("0")
    const [duplicate, setDuplicate] = useState("0")
    const [btcCheck, setBtcCheck] = useState("0")
    const [balances, setBalances] = useState("0")
    const [transaction, setTransaction] = useState("0")

    const [modalStates, setModalStates] = useState({
        company: { isOpen: false, searchQuery: "", selected: [], tempSelected: [] },
        bank: { isOpen: false, searchQuery: "", selected: [], tempSelected: [] },
        statementType: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        statementFormat: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        legalEntity: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        companyCountry: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        buGroup: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        bankID: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        bankBranch: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        bankCountry: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        bankAccountID: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        bankBic: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
        userID: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    });

    const [dataLists, setDataLists] = useState({
        company: [],
        branch: [],
        legalEntity: [],
        buGroup: [],
        bankAccountID: [],
        userID: [],
        statement: [],
    });


    useEffect(() => {
        // if (!isCompanyModalOpen) return
        fetchCompanyCode();
        fetchBranch()
        fetchLegalEnity()
        fetchBugroup()
        fetchAllBankAccountId()
        fetchUserId()
        fetchAllStatement()
    }, []);

    const fetchCompanyCode = async () => {
        try {
            const response = await StatementAnalyzerService.getCompanyCode();
            if (response.status === 200) {
                const data = response.data.data;
                setDataLists((prev) => ({
                    ...prev,
                    company: data,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchUserId = async () => {
        try {
            const response = await StatementAnalyzerService.getAllUserId();
            if (response.status === 200) {
                const data = response.data;
                setDataLists((prev) => ({
                    ...prev,
                    userID: data,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }


    const fetchBranch = async () => {
        try {
            const response = await StatementAnalyzerService.getBranch();
            if (response.status === 200) {
                const data = response.data.data;
                setDataLists((prev) => ({
                    ...prev,
                    branch: data,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchLegalEnity = async () => {
        try {
            const response = await StatementAnalyzerService.getLegalEntity();
            if (response.status === 200) {
                const data = response.data.data;
                setDataLists((prev) => ({
                    ...prev,
                    legalEntity: data,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchBugroup = async () => {
        try {
            const response = await StatementAnalyzerService.getAllBugroup();
            if (response.status === 200) {
                const data = response.data.data;
                setDataLists((prev) => ({
                    ...prev,
                    buGroup: data,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchAllBankAccountId = async () => {
        try {
            const response = await StatementAnalyzerService.getAllBankAccountId();
            if (response.status === 200) {
                const data = response.data.data;
                setDataLists((prev) => ({
                    ...prev,
                    bankAccountID: data,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchAllStatement = async () => {
        try {
            const response = await StatementAnalyzerService.getAllStatement();
            if (response.status === 200) {
                const data = response.data.data;
                setDataLists((prev) => ({
                    ...prev,
                    statement: data,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }
    const assingBankaccountOptions = [
        { value: "0", label: "Assinged" },
        { value: "1", label: "Not Assinged" },
        { value: "2", label: "All" },
    ];

    const handleSave = async (action) => {
        try {
            if (!processName) setErrors({ processName: "Process Name is required." });
            const data = {
                processName,
                assingBankAccountId,
                statementFormat: modalStates.statementFormat.tempSelected,
                statementType: modalStates.statementType.tempSelected,
                companyCode: modalStates.company.tempSelected,
                bank: modalStates.bank.tempSelected,
                legalEntity: modalStates.legalEntity.tempSelected,
                buGroup: modalStates.buGroup.tempSelected,
                companyCountry: modalStates.companyCountry.tempSelected,
                bankAccountID: modalStates.bankAccountID.tempSelected,
                bankCountry: modalStates.bankCountry.tempSelected,
                bankBranch: modalStates.bankBranch.tempSelected,
                bankBic: modalStates.bankBic.tempSelected,
                // New transaction and imported date fields
                transactionFromMonth,
                transactionToMonth,
                transactionFromValueDate,
                transactionToValueDate,
                importedFromMonth,
                importedToMonth,
                importedFromValueDate,
                importedToValueDate,
                // Controlls Tab
                userID: modalStates.userID.tempSelected,
                dateBalance,
                duplicate,
                btcCheck,
                balances,
                transaction,
                transferStatus,
                importedStatus
            };

            const response = await StatementAnalyzerService.addProcessMt940BalanceDetails(data);
            if (response.status === 200) {
                toast("success", response.message);
                if (action === 1) {
                    navigate("/bank-statements");
                } else {
                    resetForm();
                }
            } else {
                toast("error", response.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (id) fetchDataForField();
    }, [id]);

    const fetchDataForField = async () => {
        try {
            const response = await GlobalProcessServices.getProcessDetailsById(id, menuId, iconId);
            if (response.status === 200) {
                const apiData = response.data.data;

                const processName = apiData.processName || "";
                setProcessName(processName);

                // Extracting values from API response
                const extractValues = (fieldName) =>
                    apiData.processListDetails
                        .filter((item) => item.fieldName === fieldName)
                        .map((item) => item.value) || [];

                // Fetch data for existing fields
                const companyCode = extractValues("companyCode");
                const statementType = extractValues("statementType");
                const statementFormat = extractValues("statementFormat");
                const legalEntity = extractValues("legalEntity");
                const companyCountry = extractValues("companyCountry");
                const bankAccountID = extractValues("bankAccountID");
                const assingBankAccountId = extractValues("assingBankAccountId")[0] || "";
                const bankCountry = extractValues("bankCountry");
                const bank = extractValues("bank");
                const bankBranch = extractValues("bankBranch");
                const bankBic = extractValues("bankBic");
                const buGroup = extractValues("buGroup");
                const userID = extractValues("userID");

                // Fetch data for new transaction and imported date fields
                const transactionFromMonth = extractValues("transactionFromMonth")[0] || "";
                const transactionToMonth = extractValues("transactionToMonth")[0] || "";
                const transactionFromValueDate = extractValues("transactionFromValueDate")[0] || "";
                const transactionToValueDate = extractValues("transactionToValueDate")[0] || "";
                const importedFromMonth = extractValues("importedFromMonth")[0] || "";
                const importedToMonth = extractValues("importedToMonth")[0] || "";
                const importedFromValueDate = extractValues("importedFromValueDate")[0] || "";
                const importedToValueDate = extractValues("importedToValueDate")[0] || "";


                //Fetch Data for controlls Tab
                const dateBalance = extractValues("dateBalance")[0] || "0";
                const duplicate = extractValues("duplicate")[0] || "0";
                const btcCheck = extractValues("btcCheck")[0] || "0";
                const balances = extractValues("balances")[0] || "0";
                const transaction = extractValues("transaction")[0] || "0";
                const transferStatus = extractValues("transferStatus")[0] || "0";
                const importedStatus = extractValues("importedStatus")[0] || "0";


                // Updating state with fetched data
                setModalStates((prev) => ({
                    ...prev,
                    company: { ...prev.company, selected: companyCode, tempSelected: companyCode },
                    statementType: { ...prev.statementType, selected: statementType, tempSelected: statementType },
                    statementFormat: { ...prev.statementFormat, selected: statementFormat, tempSelected: statementFormat },
                    legalEntity: { ...prev.legalEntity, selected: legalEntity, tempSelected: legalEntity },
                    companyCountry: { ...prev.companyCountry, selected: companyCountry, tempSelected: companyCountry },
                    bankAccountID: { ...prev.bankAccountID, selected: bankAccountID, tempSelected: bankAccountID },
                    bankCountry: { ...prev.bankCountry, selected: bankCountry, tempSelected: bankCountry },
                    bank: { ...prev.bank, selected: bank, tempSelected: bank },
                    bankBranch: { ...prev.bankBranch, selected: bankBranch, tempSelected: bankBranch },
                    bankBic: { ...prev.bankBic, selected: bankBic, tempSelected: bankBic },
                    buGroup: { ...prev.buGroup, selected: buGroup, tempSelected: buGroup },
                    userID: { ...prev.userID, selected: userID, tempSelected: userID },
                }));

                // Updating state for transaction and imported date fields
                setTransactionFromMonth(transactionFromMonth);
                setTransactionToMonth(transactionToMonth);
                setTransactionFromValueDate(transactionFromValueDate);
                setTransactionToValueDate(transactionToValueDate);
                setImportedFromMonth(importedFromMonth);
                setImportedToMonth(importedToMonth);
                setImportedFromValueDate(importedFromValueDate);
                setImportedToValueDate(importedToValueDate);
                setAssingBankAccountId(assingBankAccountId);

                // Updating state for controlls Tab
                setDateBalance(dateBalance);
                setDuplicate(duplicate);
                setBtcCheck(btcCheck);
                setBalances(balances);
                setTransaction(transaction);
                setTransferStatus(transferStatus);
                setImportedStatus(importedStatus);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const resetForm = () => {
        setProcessName("");
        setAssingBankAccountId("");
        setModalStates((prev) => ({
            ...prev,
            company: { ...prev.company, tempSelected: [], selected: [] },
            statementType: { ...prev.statementType, tempSelected: [], selected: [] },
            statementFormat: { ...prev.statementFormat, tempSelected: [], selected: [] },
            legalEntity: { ...prev.legalEntity, tempSelected: [], selected: [] },
            companyCountry: { ...prev.companyCountry, tempSelected: [], selected: [] },
            bankAccountID: { ...prev.bankAccountID, tempSelected: [], selected: [] },
            bankCountry: { ...prev.bankCountry, tempSelected: [], selected: [] },
            bank: { ...prev.bank, tempSelected: [], selected: [] },
            bankBranch: { ...prev.bankBranch, tempSelected: [], selected: [] },
            bankBic: { ...prev.bankBic, tempSelected: [], selected: [] },
            buGroup: { ...prev.buGroup, tempSelected: [], selected: [] },
            userID: { ...prev.userID, tempSelected: [], selected: [] },
        }));
        setTransactionFromMonth("");
        setTransactionToMonth("");
        setTransactionFromValueDate("");
        setTransactionToValueDate("");
        setImportedFromMonth("");
        setImportedToMonth("");
        setImportedFromValueDate("");
        setImportedToValueDate("");
        setDateBalance("0");
        setDuplicate("0");
        setBtcCheck("0");
        setBalances("0");
        setTransaction("0");
        setTransferStatus("0");
        setImportedStatus("0");
    }
    const handleUpdate = async () => {
        try {
            if (!processName) setErrors({ processName: "Process Name is required." });
            const data = {
                processName,
                assingBankAccountId,
                statementFormat: modalStates.statementFormat.tempSelected,
                statementType: modalStates.statementType.tempSelected,
                companyCode: modalStates.company.tempSelected,
                bank: modalStates.bank.tempSelected,
                legalEntity: modalStates.legalEntity.tempSelected,
                buGroup: modalStates.buGroup.tempSelected,
                companyCountry: modalStates.companyCountry.tempSelected,
                bankAccountID: modalStates.bankAccountID.tempSelected,
                bankCountry: modalStates.bankCountry.tempSelected,
                bankBranch: modalStates.bankBranch.tempSelected,
                bankBic: modalStates.bankBic.tempSelected,
                // New transaction and imported date fields
                transactionFromMonth,
                transactionToMonth,
                transactionFromValueDate,
                transactionToValueDate,
                importedFromMonth,
                importedToMonth,
                importedFromValueDate,
                importedToValueDate,
                // Controlls Tab
                userID: modalStates.userID.tempSelected,
                dateBalance,
                duplicate,
                btcCheck,
                balances,
                transaction,
                importedStatus,
                transferStatus
            };


            const response = await StatementAnalyzerService.updateProcessMt940BalanceDetails(data, menuId, iconId);
            if (response.status === 200) {
                toast("success", response.message);
            } else {
                toast("error", response.message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const validateMonth = (from, to, key) => {
        if (from && to && to < from) {
            toast("error", "To month cannot be earlier than From month.");
            setErrors(prev => ({ ...prev, [key]: "" }));
            if (key == "transactionToMonth") {
                setTransactionToMonth("");
            }
            else if (key == "importedToMonth") {
                setImportedToMonth("");
            }
        }
    };
    const validateDate = (from, to, key) => {
        if (from && to && new Date(to) < new Date(from)) {
            toast("error", "To date cannot be earlier than From date.");
            setErrors(prev => ({ ...prev, [key]: "" }));
            if (key == "transactionToDate") {
                setTransactionToValueDate("");
            }
            else if (key == "importedToDate") {
                setImportedToValueDate("");
            }
        }
    };


    const toggleModal = (key, isOpen) => {
        setModalStates((prev) => ({
            ...prev,
            [key]: { ...prev[key], isOpen },
        }));
    };

    const handleSelection = (key, value) => {
        setModalStates((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                tempSelected: prev[key].tempSelected.includes(value)
                    ? prev[key].tempSelected.filter((item) => item !== value)
                    : [...prev[key].tempSelected, value],
            },
        }));
    };

    const handleApplySelection = (key) => {
        setModalStates((prev) => ({
            ...prev,
            [key]: {
                ...prev[key],
                selected: [...prev[key].tempSelected],
                isOpen: false,
            },
        }));
    };

    const handleResetSelection = (key) => {
        setModalStates((prev) => ({
            ...prev,
            [key]: { ...prev[key], tempSelected: [] },
        }));
    };
    const [errors, setErrors] = useState({});

    const handleNavigateToAnalyser = async () => {
        const data = {
            processName,
            assingBankAccountId,
            statementFormat: modalStates.statementFormat.tempSelected,
            statementType: modalStates.statementType.tempSelected,
            companyCode: modalStates.company.tempSelected,
            bank: modalStates.bank.tempSelected,
            legalEntity: modalStates.legalEntity.tempSelected,
            buGroup: modalStates.buGroup.tempSelected,
            companyCountry: modalStates.companyCountry.tempSelected,
            bankAccountID: modalStates.bankAccountID.tempSelected,
            bankCountry: modalStates.bankCountry.tempSelected,
            bankBranch: modalStates.bankBranch.tempSelected,
            bankBic: modalStates.bankBic.tempSelected,
            userID: modalStates.userID.tempSelected,
            transactionFromMonth,
            transactionToMonth,
            transactionFromValueDate,
            transactionToValueDate,
            importedFromMonth,
            importedToMonth,
            importedFromValueDate,
            importedToValueDate,
            dateBalance,
            duplicate,
            btcCheck,
            balances,
            transaction,
            importedStatus,
            transferStatus
        }

        navigate("/statement-analyser-bs", { state: data });

    }

    const [openDropdown, setOpenDropdown] = useState(null);
    const handleToggle = (dropdownId) => {
        setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
    };

    return (

        <div>
            <div><Header
                title={`Bank Statements > MT940 > MT940 Balances`} onBreadcrumbClick={handleBreadcrumbClick} backRoute={"/bank-statements"} /></div>

            <Tabs
                tabs={tabs}
                activeTab={activeTab}
                errors={errorss}
                onTabClick={handleTabClick}
                isValidationTriggered={isValidationTriggered}
            />
            <TableWrapper maxHeight="550px">
            {activeTab === "masterdata" && (
                <div>
                    <div className="m-5 flex flex-col space-y-4 col-span-1 p-4 border  rounded-md">
                        <div className="2xl:flex 2xl:flex-row xl:grid xl:grid-cols-2 xl:gap-0 lg:grid lg:grid-cols-2 lg:gap-0">
                            <div>
                                <label className="flex items-center">
                                    <span className="w-48 font-small text-xs font-small text-customGray text-xs">Process Name <span className="text-red-500">*</span></span>

                                    <span className="ml-4">:</span>

                                    <input
                                        type="text"
                                        value={processName}
                                        readOnly={id ? true : false}
                                        onChange={(e) => setProcessName(e.target.value)}
                                        className={`border p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left text-xs flex items-center justify-between text-customGray ${errors.processName
                                            ? "border-red-500 hover:border-red-500"
                                            : "border-gray-300 hover:border-blue-400"
                                            }`}
                                    />
                                </label>

                            </div>
                            <div>
                                <button>
                                    <IoIosList className="ml-4 mt-1 border  h-6 w-6  hover hover:border-customBlue"
                                        onClick={() => navigate("/bs-process-list", { state: { iconId: iconId, subModule: menuId } })}
                                    />
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-2 gap-4">
                                <label className="flex items-center">
                                    <span className="w-48 font-small text-xs font-small text-customGray text-xs">Statement Format</span>
                                    <span className="ml-4">:</span>
                                    <button
                                        onClick={() => toggleModal("statementFormat", true)}
                                        className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                    >
                                        <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                            {modalStates.statementFormat.selected.length > 0 ? modalStates.statementFormat.selected.join(", ") : "Statement Format"}
                                            {/* {modalStates.statementFormat.selected.length > 0 ? modalStates.statementFormat.selected.map((value) => dataLists.statementFormat.find((option) => option.value === value)?.label || value).join(", ") : "Statement Format"} */}
                                        </span>
                                        <RxDropdownMenu className="ml-2" />
                                    </button>

                                </label>
                                <label className="flex items-center">
                                    <span className="w-48 font-small  text-customGray text-xs">Statement Type</span>
                                    <span className="ml-4">:</span>

                                    <button
                                        onClick={() => toggleModal("statementType", true)}
                                        className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                    >
                                        <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                            {modalStates.statementType.selected.length > 0 ? modalStates.statementType.selected.join(", ") : "Statement Type"}
                                            {/* {modalStates.statementType.selected.length > 0
                                                ? modalStates.statementType.selected
                                                    .map((selectedId) => {
                                                        const foundStatement = dataLists.statement.find((option) => option.id === selectedId);
                                                        return foundStatement ? foundStatement.statement_type : selectedId;
                                                    })
                                                    .join(", ")
                                                : "Statement Type"} */}
                                        </span>
                                        <RxDropdownMenu className="ml-2" />
                                    </button>
                                </label>

                            </div>
                            <CheckboxPopup
                                title="Select Statement Format"
                                isOpen={modalStates.statementFormat.isOpen}
                                onClose={() => toggleModal("statementFormat", false)}
                                onSave={() => handleApplySelection("statementFormat")}
                                onReset={() => handleResetSelection("statementFormat")}
                                searchPlaceholder="Search Statement Format..."
                                searchQuery={modalStates.statementFormat.searchQuery}
                                onSearchChange={(e) =>
                                    setModalStates((prev) => ({
                                        ...prev,
                                        statementFormat: { ...prev.statementFormat, searchQuery: e.target.value },
                                    }))
                                }
                                items={[...new Map(dataLists.statement.map(item => [item.statement_format, item])).values()]
                                    .map(code => ({ id: code.statement_format, name: code.statement_format || '' })) // Ensure name is always a string
                                    .filter(item => item.name?.toLowerCase().includes(modalStates.statementFormat.searchQuery?.toLowerCase() || ''))
                                }
                                selectedItems={modalStates.statementFormat.tempSelected}
                                onItemToggle={(val) => handleSelection("statementFormat", val)}
                            />
                            <CheckboxPopup
                                title="Select Statement Type"
                                isOpen={modalStates.statementType.isOpen}
                                onClose={() => toggleModal("statementType", false)}
                                onSave={() => handleApplySelection("statementType")}
                                onReset={() => handleResetSelection("statementType")}
                                searchPlaceholder="Search StatementType..."
                                searchQuery={modalStates.statementType.searchQuery}
                                onSearchChange={(e) => setModalStates((prev) => ({
                                    ...prev, statementType: { ...prev.statementType, searchQuery: e.target.value }
                                }))}
                                items={[...new Map(dataLists.statement.map(item => [item.statement_type, item])).values()]
                                    .map(code => ({ id: code.statement_type, name: code.statement_type || '' })) // Ensure name is always a string
                                    .filter(item => item.name?.toLowerCase().includes(modalStates.statementType.searchQuery?.toLowerCase() || ''))
                                }
                                selectedItems={modalStates.statementType.tempSelected}
                                onItemToggle={(val) => handleSelection("statementType", val)}
                            />
                        </div>
                        <label className="flex items-center">
                            <span className="w-48 font-small text-customGray text-xs">Assing Bank Account ID</span>
                            <span className="ml-4">:</span>
                            <CustomDropdown
                                value={
                                    assingBankaccountOptions.find(
                                        (option) => option.value === assingBankAccountId
                                    ) || null
                                }
                                options={assingBankaccountOptions}
                                onChange={(value) => { setAssingBankAccountId(value); setOpenDropdown(null); }}
                                isOpen={openDropdown === "assingBankaccount"}
                                onToggle={() => handleToggle("assingBankaccount")}
                                className="ml-8 border-gray-300"
                                placeholder="Assing Bank Account ID"
                                width="2xl:w-80 xl:w-[275px] lg:w-[218px]"
                            />
                            <div className="w-[555px] ml-4">&nbsp;</div>
                        </label>
                    </div>
                    {/* Second Block */}
                    <div className="flex flex-col space-y-4 col-span-1 p-4 border rounded-md m-5">
                        <div className="grid grid-cols-2 gap-x-4 ">
                            <label className="flex items-center">
                                <span className="w-48 text-sm font-medium text-customGray">Company</span>
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 text-sm font-medium text-customGray">Bank</span>
                            </label>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 ">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Company Code</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("company", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.company.selected.length > 0 ? modalStates.company.selected.join(", ") : "Company"}

                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Bank</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("bank", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.bank.selected.length > 0 ? modalStates.bank.selected.join(", ") : "Bank"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <div>
                                <CheckboxPopup
                                    title="Select Company Code"
                                    isOpen={modalStates.company.isOpen}
                                    onClose={() => toggleModal("company", false)}
                                    onSave={() => handleApplySelection("company")}
                                    onReset={() => handleResetSelection("company")}
                                    searchPlaceholder="Search Company..."
                                    searchQuery={modalStates.company.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, company: { ...prev.company, searchQuery: e.target.value }
                                    }))}
                                    items={[...new Map(dataLists.company.map(item => [item.companyCode, item])).values()]
                                        .map(code => ({ id: code.companyCode, name: code.companyCode || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.company.searchQuery?.toLowerCase() || ''))
                                    }
                                    selectedItems={modalStates.company.tempSelected}
                                    onItemToggle={(val) => handleSelection("company", val)}

                                />

                                <CheckboxPopup
                                    title="Select Bank"
                                    isOpen={modalStates.bank.isOpen}
                                    onClose={() => toggleModal("bank", false)}
                                    onSave={() => handleApplySelection("bank")}
                                    onReset={() => handleResetSelection("bank")}
                                    searchPlaceholder="Search Bank..."
                                    searchQuery={modalStates.bank.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, bank: { ...prev.bank, searchQuery: e.target.value }
                                    }))}

                                    items={[...new Map(dataLists.branch.map(item => [item.Bank, item])).values()]
                                        .map(code => ({ id: code.Bank, name: code.Bank || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.bank.searchQuery?.toLowerCase() || ''))
                                    }
                                    // items={dataLists.branch.map((code) => ({ id: code.Bank, name: code.Bank }))}
                                    selectedItems={modalStates.bank.tempSelected}
                                    onItemToggle={(val) => handleSelection("bank", val)}

                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 ">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Legal Entity</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("legalEntity", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.legalEntity.selected.length > 0 ? modalStates.legalEntity.selected.join(", ") : "Legal Entity"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Bank ID</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("bankID", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.bankID.selected.length > 0 ? modalStates.bankID.selected.join(", ") : "Bank ID"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <div>
                                <CheckboxPopup
                                    title="Select Legal Entity"
                                    isOpen={modalStates.legalEntity.isOpen}
                                    onClose={() => toggleModal("legalEntity", false)}
                                    onSave={() => handleApplySelection("legalEntity")}
                                    onReset={() => handleResetSelection("legalEntity")}
                                    searchPlaceholder="Search Legal Entity..."
                                    searchQuery={modalStates.legalEntity.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, legalEntity: { ...prev.legalEntity, searchQuery: e.target.value }
                                    }))}
                                    items={[...new Map(dataLists.legalEntity.map(item => [item.LGE_Code, item])).values()]
                                        .map(code => ({ id: code.LGE_Code, name: code.LGE_Code || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.legalEntity.searchQuery?.toLowerCase() || ''))
                                    }
                                    selectedItems={modalStates.legalEntity.tempSelected}
                                    onItemToggle={(val) => handleSelection("legalEntity", val)}

                                />

                                <CheckboxPopup
                                    title="Select Bank ID"
                                    isOpen={modalStates.bankID.isOpen}
                                    onClose={() => toggleModal("bankID", false)}
                                    onSave={() => handleApplySelection("bankID")}
                                    onReset={() => handleResetSelection("bankID")}
                                    searchPlaceholder="Search Bank ID..."
                                    searchQuery={modalStates.bankID.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, bankID: { ...prev.bankID, searchQuery: e.target.value }
                                    }))}

                                    items={[...new Map(dataLists.branch.map(item => [item.BankId, item])).values()]
                                        .map(code => ({ id: code.BankId, name: code.BankId || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.bankID.searchQuery?.toLowerCase() || '')).sort((a, b) => a.name.localeCompare(b.name))
                                    }

                                    selectedItems={modalStates.bankID.tempSelected}
                                    onItemToggle={(val) => handleSelection("bankID", val)}

                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 ">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Bu Group</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("buGroup", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.buGroup.selected.length > 0 ? modalStates.buGroup.selected.join(", ") : "Bu Group"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Bank Branch</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("bankBranch", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.bankBranch.selected.length > 0 ? modalStates.bankBranch.selected.join(", ") : "Bank Branch"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>

                            <div>
                                <CheckboxPopup
                                    title="Select Bu Group"
                                    isOpen={modalStates.buGroup.isOpen}
                                    onClose={() => toggleModal("buGroup", false)}
                                    onSave={() => handleApplySelection("buGroup")}
                                    onReset={() => handleResetSelection("buGroup")}
                                    searchPlaceholder="Search Legal Entity..."
                                    searchQuery={modalStates.buGroup.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, buGroup: { ...prev.buGroup, searchQuery: e.target.value }
                                    }))}
                                    items={[...new Map(dataLists.buGroup.map(item => [item.BU_Group, item])).values()]
                                        .map(code => ({ id: code.BU_Group, name: code.BU_Group || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.buGroup.searchQuery?.toLowerCase() || '')).sort((a, b) => a.name.localeCompare(b.name))
                                    }
                                    selectedItems={modalStates.buGroup.tempSelected}
                                    onItemToggle={(val) => handleSelection("buGroup", val)}
                                />
                                <CheckboxPopup
                                    title="Select Bank Branch"
                                    isOpen={modalStates.bankBranch.isOpen}
                                    onClose={() => toggleModal("bankBranch", false)}
                                    onSave={() => handleApplySelection("bankBranch")}
                                    onReset={() => handleResetSelection("bankBranch")}
                                    searchPlaceholder="Search Bank Branch..."
                                    searchQuery={modalStates.bankBranch.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, bankBranch: { ...prev.bankBranch, searchQuery: e.target.value }
                                    }))}

                                    items={[...new Map(dataLists.branch.map(item => [item.bankBranch, item])).values()]
                                        .map(code => ({ id: code.bankBranch, name: code.bankBranch || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.bankBranch.searchQuery?.toLowerCase() || '')) // Search filter
                                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                                    }

                                    selectedItems={modalStates.bankBranch.tempSelected}
                                    onItemToggle={(val) => handleSelection("bankBranch", val)}

                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4 ">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Company Country</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("companyCountry", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.companyCountry.selected.length > 0 ? modalStates.companyCountry.selected.join(", ") : "Company Country"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Bank Country</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("bankCountry", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.bankCountry.selected.length > 0 ? modalStates.bankCountry.selected.join(", ") : "Bank Country"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <div>
                                <CheckboxPopup
                                    title="Select Company Country"
                                    isOpen={modalStates.companyCountry.isOpen}
                                    onClose={() => toggleModal("companyCountry", false)}
                                    onSave={() => handleApplySelection("companyCountry")}
                                    onReset={() => handleResetSelection("companyCountry")}
                                    searchPlaceholder="Search Company Country..."
                                    searchQuery={modalStates.companyCountry.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, companyCountry: { ...prev.companyCountry, searchQuery: e.target.value }
                                    }))}
                                    items={[...new Set(
                                        dataLists.company.flatMap((company) =>
                                            company.addresses.map((addr) => addr.countryCode)
                                        )
                                    )]
                                        .map(code => ({ id: code, name: code || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.companyCountry.searchQuery?.toLowerCase() || '')) // Search filter
                                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                                    }


                                    selectedItems={modalStates.companyCountry.tempSelected}
                                    onItemToggle={(val) => handleSelection("companyCountry", val)}

                                />
                                <CheckboxPopup
                                    title="Select Bank Country"
                                    isOpen={modalStates.bankCountry.isOpen}
                                    onClose={() => toggleModal("bankCountry", false)}
                                    onSave={() => handleApplySelection("bankCountry")}
                                    onReset={() => handleResetSelection("bankCountry")}
                                    searchPlaceholder="Search Bank Country..."
                                    searchQuery={modalStates.bankCountry.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, bankCountry: { ...prev.bankCountry, searchQuery: e.target.value }
                                    }))}
                                    items={[...new Set(
                                        dataLists.branch.flatMap((branch) =>
                                            branch.addresses?.map((addr) => addr.countryCode).filter(Boolean) || []
                                        )
                                    )]
                                        .map(countryCode => ({ id: countryCode, name: countryCode || '' }))
                                        .filter(item =>
                                            modalStates.bankCountry?.searchQuery
                                                ? item.name.toLowerCase().includes(modalStates.bankCountry.searchQuery.toLowerCase())
                                                : true
                                        )}

                                    selectedItems={modalStates.bankCountry.tempSelected}
                                    onItemToggle={(val) => handleSelection("bankCountry", val)}

                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-4">
                            <div className="w-[600px] ml-4">&nbsp;</div>

                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Bank Account ID</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("bankAccountID", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.bankAccountID.selected.length > 0 ? modalStates.bankAccountID.selected.join(", ") : "Bank Account ID"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <div>
                                <CheckboxPopup
                                    title="Select Bank Account ID"
                                    isOpen={modalStates.bankAccountID.isOpen}
                                    onClose={() => toggleModal("bankAccountID", false)}
                                    onSave={() => handleApplySelection("bankAccountID")}
                                    onReset={() => handleResetSelection("bankAccountID")}
                                    searchPlaceholder="Search Bank Account ID..."
                                    searchQuery={modalStates.bankAccountID.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, bankAccountID: { ...prev.bankAccountID, searchQuery: e.target.value }
                                    }))}
                                    items={[...new Map(dataLists.bankAccountID.map(item => [item.bankAccountId, item])).values()]
                                        .map(code => ({ id: code.bankAccountId, name: code.bankAccountId || '' }))
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.bankAccountID.searchQuery?.toLowerCase() || ''))
                                    }
                                    selectedItems={modalStates.bankAccountID.tempSelected}
                                    onItemToggle={(val) => handleSelection("bankAccountID", val)}

                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-4">
                            <div className="w-[600px] ml-4">&nbsp;</div>

                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Bank Bic</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("bankBic", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.bankBic.selected.length > 0 ? modalStates.bankBic.selected.join(", ") : "Bank BIC"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <div>
                                <CheckboxPopup
                                    title="Select Bank Bic"
                                    isOpen={modalStates.bankBic.isOpen}
                                    onClose={() => toggleModal("bankBic", false)}
                                    onSave={() => handleApplySelection("bankBic")}
                                    onReset={() => handleResetSelection("bankBic")}
                                    searchPlaceholder="Search Bank Country..."
                                    searchQuery={modalStates.bankBic.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, bankBic: { ...prev.bankBic, searchQuery: e.target.value }
                                    }))}

                                    items={[...new Set(
                                        dataLists.branch.flatMap((branch) =>
                                            branch.identifier?.map((addr) => addr.bankBIC).filter(Boolean) || []
                                        )
                                    )]
                                        .map(bankBIC => ({ id: bankBIC, name: bankBIC || '' }))
                                        .filter(item =>
                                            modalStates.bankBic?.searchQuery
                                                ? item.name.toLowerCase().includes(modalStates.bankBic.searchQuery.toLowerCase())
                                                : true
                                        )}
                                    selectedItems={modalStates.bankBic.tempSelected}
                                    onItemToggle={(val) => handleSelection("bankBic", val)}
                                />
                            </div>
                        </div>
                    </div>

                </div>)}
            {activeTab === "yearmonth" && (
                <div className="m-2 p-4 border rounded-md mt-2 grid gap-6">
                    <div className="grid grid-cols-2 gap-x-4">
                        <label className="text-sm font-medium text-customGray">Transaction</label>
                        <label className="text-sm font-medium text-customGray">Imported Dates</label>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-x-20">
                        <label className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
                            <span className="text-xs w-36 text-gray-500">Month/Year </span>
                            <span className="sm:ml-4">:</span>
                            <div className="flex gap-2 w-full">

                                <input
                                    placeholder="From"
                                    value={transactionFromMonth}
                                    type="month"
                                    className="text-customGray border border-gray-300 p-2 rounded xl:w-[160px] lg:w-[130px] text-xs focus:outline-none hover:border-blue-400 cursor-pointer"
                                    onChange={(e) => {
                                        setTransactionFromMonth(e.target.value);
                                        validateMonth(e.target.value, transactionToMonth, "transactionFromMonth");
                                    }}
                                />
                                <input
                                    placeholder="To"
                                    value={transactionToMonth}
                                    type="month"
                                    className="text-customGray border border-gray-300 p-2 rounded xl:w-[160px] lg:w-[130px] text-xs focus:outline-none hover:border-blue-400 cursor-pointer"
                                    onChange={(e) => {
                                        setTransactionToMonth(e.target.value);
                                        validateMonth(transactionFromMonth, e.target.value, "transactionToMonth");
                                    }}
                                />
                            </div>

                        </label>
                        <label className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
                            <span className="w-36 text-xs text-gray-500">Month/Year </span>
                            <span className="sm:ml-4">:</span>
                            <div className="flex gap-2 w-full">
                                <input
                                    value={importedFromMonth}
                                    type="month"
                                    className="text-customGray border border-gray-300 p-2 rounded xl:w-[160px] lg:w-[130px] text-xs focus:outline-none hover:border-blue-400 cursor-pointer"
                                    onChange={(e) => {
                                        setImportedFromMonth(e.target.value);
                                        validateMonth(e.target.value, importedToMonth, "importedFromMonth");
                                    }}
                                />
                                <input
                                    value={importedToMonth}
                                    type="month"
                                    className="text-customGray border border-gray-300 p-2 rounded xl:w-[160px] lg:w-[130px] text-xs focus:outline-none hover:border-blue-400 cursor-pointer"
                                    onChange={(e) => {
                                        setImportedToMonth(e.target.value);
                                        validateMonth(importedFromMonth, e.target.value, "importedToMonth");
                                    }}
                                />
                            </div>
                        </label>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-x-20">
                        <label className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
                            <span className=" w-36 text-xs text-gray-500">Value Date </span>
                            <span className="sm:ml-4">:</span>
                            <div className="flex gap-2 w-full">
                                <input
                                    value={transactionFromValueDate}
                                    type="date"
                                    className="text-customGray border border-gray-300 p-2 rounded xl:w-[160px] lg:w-[130px] text-xs focus:outline-none hover:border-blue-400 cursor-pointer"
                                    onChange={(e) => {
                                        setTransactionFromValueDate(e.target.value);
                                        validateDate(e.target.value, transactionToValueDate, "transactionFromDate");
                                    }}
                                />
                                <input
                                    value={transactionToValueDate}
                                    type="date"
                                    className="text-customGray border border-gray-300 p-2 rounded xl:w-[160px] lg:w-[130px] text-xs focus:outline-none hover:border-blue-400 cursor-pointer"
                                    onChange={(e) => {
                                        setTransactionToValueDate(e.target.value);
                                        validateDate(transactionFromValueDate, e.target.value, "transactionToDate");
                                    }}
                                />
                            </div>
                        </label>
                        <label className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
                            <span className="w-36 text-xs text-gray-500 whitespace-nowrap">Imported Date</span>
                            <span className="sm:ml-4">:</span>
                            <div className="flex gap-2 w-full">
                                <input
                                    value={importedFromValueDate}
                                    type="date"
                                    className="text-customGray border border-gray-300 p-2 rounded xl:w-[160px] lg:w-[130px] text-xs focus:outline-none hover:border-blue-400 cursor-pointer"
                                    onChange={(e) => {
                                        setImportedFromValueDate(e.target.value);
                                        validateDate(e.target.value, importedToValueDate, "importedFromDate");
                                    }}
                                />
                                <input
                                    value={importedToValueDate}
                                    type="date"
                                    className="text-customGray border border-gray-300 p-2 rounded xl:w-[160px] lg:w-[130px] text-xs focus:outline-none hover:border-blue-400 cursor-pointer"
                                    onChange={(e) => {
                                        setImportedToValueDate(e.target.value);
                                        validateDate(importedFromValueDate, e.target.value, "importedToDate");
                                    }}
                                />
                            </div>
                        </label>
                    </div>
                </div>
            )}

            {activeTab === "statuscontrols" && (
                <div className="space-y-6 p-3">
                    {/* Status Section */}
                    <div className="p-4 border rounded-md">
                        <h2 className="text-sm font-medium text-customGray">Status</h2>
                        <div className="space-y-2 mt-4">
                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Imported Status</span>
                                <span className="ml-4">:</span>
                                <input type="checkbox" className="m-3 accent-blue-500" checked={importedStatus === "1"} onChange={() => setImportedStatus(importedStatus === "1" ? "0" : "1")} />
                            </label>

                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Transfer Status</span>
                                <span className="ml-4">:</span>
                                <input type="checkbox" className="m-3 accent-blue-500" checked={transferStatus === "1"} onChange={() => setTransferStatus(transferStatus === "1" ? "0" : "1")} />
                            </label>

                            <label className="flex items-center">
                                <span className="w-48 font-small text-customGray text-xs">Uploaded By</span>
                                <span className="ml-4">:</span>
                                <button
                                    onClick={() => toggleModal("userID", true)}
                                    className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                >
                                    <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                        {modalStates.userID.selected.length > 0 ? modalStates.userID.selected.join(", ") : "Created By"}
                                    </span>
                                    <RxDropdownMenu className="ml-2" />
                                </button>
                            </label>
                            <div>
                                <CheckboxPopup
                                    title="Select Created By"
                                    isOpen={modalStates.userID.isOpen}
                                    onClose={() => toggleModal("userID", false)}
                                    onSave={() => handleApplySelection("userID")}
                                    onReset={() => handleResetSelection("userID")}
                                    searchPlaceholder="Search Created By..."
                                    searchQuery={modalStates.userID.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, userID: { ...prev.userID, searchQuery: e.target.value }
                                    }))}
                                    items={[...new Map(dataLists.userID.map(item => [item.statement_imported_by, item])).values()]
                                        .map(code => ({ id: code.statement_imported_by, name: code.statement_imported_by || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.userID.searchQuery?.toLowerCase() || '')) // Search filter
                                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                                    }
                                    selectedItems={modalStates.userID.tempSelected}
                                    onItemToggle={(val) => handleSelection("userID", val)}

                                />
                            </div>
                        </div>
                    </div>

                    {/* Controls and Output Section */}
                    {/* <div className="grid grid-cols-2 gap-6 p-6 border rounded-md">
                        {/* Controls */}
                    <div className="p-4 ">
                        <h2 className="text-sm font-medium text-customGray">Controls</h2>
                        <div className="space-y-4 mt-3">
                            <label className="flex items-center">
                                <input type="checkbox" className="accent-blue-500" checked={dateBalance === "1"} onChange={() => setDateBalance(dateBalance === "1" ? "0" : "1")} />
                                <span className="ml-3 font-small text-customGray text-xs cursor-pointer">Date / Balance</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" className="accent-blue-500" checked={duplicate === "1"} onChange={() => setDuplicate(duplicate === "1" ? "0" : "1")} />
                                <span className="ml-3 font-small text-customGray text-xs cursor-pointer">Duplicate</span>
                            </label>
                            <label className="flex items-center">
                                <input type="checkbox" className="accent-blue-500" checked={btcCheck === "1"} onChange={() => setBtcCheck(btcCheck === "1" ? "0" : "1")} />
                                <span className="ml-3 font-small text-customGray text-xs cursor-pointer">BTC Check</span>
                            </label>
                        </div>
                    </div>

                    {/* Output *
                        <div>
                            <h2 className="text-gray-700 font-bold">Output</h2>
                            <div className="space-y-3 mt-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="accent-blue-500" checked={balances === "1"} onChange={() => setBalances(balances === "1" ? "0" : "1")} />
                                    <span className="ml-3 text-gray-500 cursor-pointer">Balances</span>
                                </label>
                                <label className="flex items-center">
                                    <input type="checkbox" className="accent-blue-500" checked={transaction === "1"} onChange={() => setTransaction(transaction === "1" ? "0" : "1")} />
                                    <span className="ml-3 text-gray-500 cursor-pointer">Transactions</span>
                                </label>
                            </div>
                        </div>
                    </div> */}
                </div>
            )}
            </TableWrapper>

            <div className="mt-10">
                <Footer>
                    <div>
                        {!id && <CustomButton
                            onClick={() => { handleSave(1) }}
                            title={"Save"}
                            className="mr-2"

                        />}
                        {!id && <HoverButton
                            title={"Save & Continue"}
                            className={"mr-2"}
                            onClick={() => { handleSave(2) }}
                        />}
                        {id && <CustomButton
                            onClick={handleUpdate}
                            title={"Save"}
                            className="mr-2"

                        />}
                        <HoverButton
                            title={"Run >"}
                            className={"mr-2"}
                            onClick={() => { handleNavigateToAnalyser() }}
                        />
                    </div>
                </Footer>
            </div>
        </div>
    )
}