
import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import BankStatementActions from "../../props/ActionButtons";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { IoMdRefresh } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import ReTable from "../../../../../components/Table";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../../components/footer";
import CustomButton from "../../../../../components/CustomButton";
import BankStatementServices from "../../../../../rest-services/BankStatementServices";
import Pagination from "../../../../../components/PageNation";
import { useToast } from "../../../../../components/toast/toast";

export default function StatementControls() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [modifiedRows, setModifiedRows] = useState([]);// Default page size
    const [assignee, setAssignee] = useState({
        clientId: "",
        id: "",
        assigner: "",
        userId: "",
    });

    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate()

    const { toast } = useToast();
    useEffect(() => {
        fetchAssignee();
    }, [])

    useEffect(() => {
        fetchDataForStatmentControls()
    }, [assignee.clientId, searchQuery])

    //FUnction For Fetch Assignee
    const fetchAssignee = async () => {
        try {
            const userDetailString = localStorage.getItem("userDetail");
            if (userDetailString) {
                const userDetail = JSON.parse(userDetailString);
                const assigneeData = {
                    clientId: userDetail.clientId || "",
                    id: userDetail.id || "",
                    assigner: userDetail.username || "",
                    userId: userDetail.userId || "",
                };
                setAssignee(assigneeData);
            } else {
            }
        } catch (error) { console.log(error) }
    };


    //Button Configs
    const buttons = bankStatementActionsConfig.screen3.map((button) => {
        let onClick;
        let title;
        if (button.icon === IoAddCircleOutline) {
            onClick = () => { };
            title = "Add";
        }
        else if (button.icon === IoMdRefresh) {
            onClick = () => { fetchDataForStatmentControls() };
            title = "Refresh";
        } else if (button.icon === MdOutlineFileDownload) {
            onClick = () => { handleDownload() };
            title = "Download";
        }
        return {
            ...button,
            onClick,
            title,
        };
    });

    const handleDownload = async () => {
        try {
            const response = await BankStatementServices.exportStatement(searchQuery);
            console.log(response)
            if (response) {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = "statement_data.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log(error)
        }
    }



    const fetchDataForStatmentControls = async () => {
        try {
            if (!assignee.clientId) {
                return;
            }
            const response = await BankStatementServices.getStatementControls(pageSize, currentPage
                , searchQuery
            );

            if (response.status === 200) {
                const totalCount = Number(response.data.total) || 0;
                const pages = Math.ceil(totalCount / (pageSize || 1));
                setData(response.data.data);
                setTotalCount(response.data.total);
                setTotalPages(pages);
            }
        } catch (error) {
            console.log(error)
        }
    }


    const columns = [
        {
            Header: "Bank Account Id",
            accessor: "bankAccountId",
            visible: true,
            width: ("Bank Account Id".length - 2) * 10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,

        },
        {
            Header: "Company Code",
            accessor: "companyCode",
            visible: true,
            width: ("Company Code".length - 2) * 10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Bank",
            accessor: "bank",
            visible: true,
            width: ("Bank".length - 2) * 10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        { Header: "Bank Branch", accessor: "bankBranch", visible: true, width: ("Bank Branch".length - 2) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
        { Header: "Bank Account No", accessor: "bankAccountNo", visible: true, width: ("Bank Account No".length - 2) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
        { Header: "IBAN", accessor: "iban", visible: true, width: ("IBAN".length - 2) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
        {
            Header: "Date",
            accessor: "isDate",
            visible: true,
            width: ("Date".length - 2) * 10,
            Cell: ({ value, row }) => (
                <input
                    type="checkbox"
                    checked={value === 1}
                    className="accent-customBlue"
                    onChange={(e) => {
                        handleCheckboxChange(row.original.id, "isDate", e.target.checked)
                        handleCheckboxChange(row.original.id, "isBalance", e.target.checked)
                    }
                    }
                />
            ),
        },
        {
            Header: "Balance",
            accessor: "isBalance",
            visible: true,
            width: ("Balance".length - 2) * 10,
            Cell: ({ value, row }) => (
                <input
                    type="checkbox"
                    checked={value === 1}
                    className="accent-customBlue"
                    onChange={(e) =>
                        handleCheckboxChange(row.original.id, "isBalance", e.target.checked)
                    }
                />
            ),
        },
        { Header: "Created By", accessor: "createdBy", visible: true, width: ("Created By".length - 2) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
        {
            Header: "Created Date",
            accessor: "creationDate",
            visible: true,
            width: ("Created Date".length - 2) * 10,
            Cell: ({ value }) => {
                if (!value) return;
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, "0")
                const month = String(date.getMonth() + 1).padStart(2, "0")
                const year = String(date.getFullYear())

                return <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>
            }
        },
        {
            Header: "Created Time",
            accessor: "creationTime",
            visible: true,
            width: ("Created Time".length - 2) * 10,
            Cell: ({ value }) => {
                if (!value) return ""; // Handle null or undefined values
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
            },
        },
        {
            Header: "Updated By",
            accessor: "changed_by",
            visible: true,
            width: ("Updated By".length - 2) * 10,
            canResize: true,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Updated Date",
            accessor: "changedDate",
            visible: true,
            width: ("Updated Date".length - 2) * 10,
            Cell: ({ value }) => {
                if (!value) return;
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, "0")
                const month = String(date.getMonth() + 1).padStart(2, "0")
                const year = String(date.getFullYear())

                return <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>
            }
        },
        {
            Header: "Updated Time",
            accessor: "changedTime",
            visible: true,
            width: ("Updated Time".length - 2) * 10,
            Cell: ({ value }) => {
                if (!value) return ""; // Handle null or undefined values
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
            },

        },
    ];
    const handleCheckboxChange = (id, field, value) => {
        if (field === "isBalance" && value === false) {
            const isDateChecked = data.find((row) => row.isDate === 1)
            if (isDateChecked) {
                setData((prevData) =>
                    prevData.map((row) =>
                        row.id === id ? { ...row, [field]: value ? 1 : 1 } : row
                    )
                );
                return
            }

        }
        setData((prevData) =>
            prevData.map((row) =>
                row.id === id ? { ...row, [field]: value ? 1 : 0 } : row
            )
        );

        setModifiedRows((prevModified) => {
            const modifiedRow = { id, [field]: value ? 1 : 0 };

            // Check if row is already modified
            const existingIndex = prevModified.findIndex((row) => row.id === id);

            if (existingIndex > -1) {
                // Merge changes if the row is already in the list
                const updatedRow = { ...prevModified[existingIndex], ...modifiedRow };
                return [
                    ...prevModified.slice(0, existingIndex),
                    updatedRow,
                    ...prevModified.slice(existingIndex + 1),
                ];
            } else {
                // Add the new modified row
                return [...prevModified, modifiedRow];
            }
        });
    };


    const handleSubmit = async () => {
        try {

            const response = await BankStatementServices.updateStatementControls(modifiedRows, assignee.userId);
            if (response.status === 200) {
                toast("success", response.message)
                setModifiedRows([]);
                fetchDataForStatmentControls();
            } else {
                toast("error", response.message)
            }
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleShowAll = () => {
        setPageSize(totalCount); // Set page size to total count to display all records
        setCurrentPage(1); // Reset to the first page
        fetchDataForStatmentControls(); // Fetch data with updated page size
    };
    const handleSelectRow = (rows) => {

    }
    const handleSelectAll = (rows) => {

    }
    const handleDoubleClick = (row) => {

    }


    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === "Bank Statements") {
            navigate("/bank-statements");
        } else if (crumb.title === "MT940") {
            navigate("/bank-statements");
        } else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };
    return (
        <div>
            <Header title="Bank Statements > MT940 > Statement Controls" onBreadcrumbClick={handleBreadcrumbClick} backRoute="/bank-statements" />

            <div className="flex justify-between items-center -ml-1 pl-4">
                <div className="flex items-center">
                    <SearchInput
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        // onChange={(e) => fetchBankAccount(searchQuery)}
                        placeholder="Search by Bank Account Id"
                    />

                    <BankStatementActions buttons={buttons} />
                </div>
                <div className="flex items-center mr-4">
                    <Pagination

                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        totalCount={totalCount}
                        onShowAll={handleShowAll}
                    />
                </div>

            </div>
            <div>
                <ReTable
                    data={data}
                    not20Records={true}
                    columns={columns}
                    selectedRows={selectedRows}
                    onSelectRow={handleSelectRow}
                    onSelectAll={handleSelectAll}
                    onRowDoubleClick={handleDoubleClick}
                />
            </div>
            <div>
                <Footer>
                    <CustomButton
                        title="Submit"
                        onClick={handleSubmit}
                    />
                </Footer>
            </div>
        </div>
    )
}