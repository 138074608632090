import React, { useState } from "react";
import ReTable from "../../../../../components/Table"; // Import your ReTable component
import { TbFlag3Filled } from "react-icons/tb";

export default function BankMasterDataTable() {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableData = [];

  const columns = React.useMemo(
    () => [
      {
        Header: "Status",
        accessor: "flagColor",
        Cell: ({ value }) => <TbFlag3Filled color={value} />,
        width: 50,
      },
      {
        Header: "Request No",
        accessor: "requestNo",
        width: 150,
      },
      {
        Header: "Account Status",
        accessor: "accountStatus",
        width: 200,
      },
      {
        Header: "Company Code",
        accessor: "companyCode",
        width: 200,
      },
      {
        Header: "Bank Account Code",
        accessor: "bankAccountCode",
        width: 200,
      },
      {
        Header: "Bank Account No",
        accessor: "bankAccountNo",
        width: 200,
      },
    ],
    []
  );

  // Function to handle row selection
  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  // Function to handle select all checkbox
  const onSelectAll = () => {
    if (selectedRows.length === tableData.length) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(tableData.map((row) => row.id)); // Select all
    }
  };

  // Function to handle double-click on a row
  const onRowDoubleClick = (rowId) => {
    console.log("Double clicked row with id:", rowId);
  };

  return (
    <div>
      <ReTable
        data={tableData}
        columns={columns}
        onSelectRow={onSelectRow}
        selectedRows={selectedRows}
        onSelectAll={onSelectAll}
        onRowDoubleClick={onRowDoubleClick}
      />
    </div>
  );
}
