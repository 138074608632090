import RestService from "./RestServices";

const BankBranchServices = {
  getAllBankBranch: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching BankBranch data:", error);
      return { error: true, message: "Error fetching BankBranch data" };
    }
  },
  getBankBranchById: (id) => {
    return RestService.GetByIdData(`/get-bankbranch`, id);
  },
  deleteBankBranch:(id)=>{
return RestService.DeleteData('/delete-bankbranch',id);
  },
  addBankBranch: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding BankBranch:", error);
      return { error: true, message: "Error adding BankBranch" };
    }
  },
  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },
};


export default BankBranchServices;
