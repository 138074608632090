import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { UserProvider } from './context/UserContext';
import { ContextProvider } from './context/AuthContext';
import { ToastProvider } from './components/toast/toast';
import { SearchProvider } from './context/SearchContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <ContextProvider value={500}>
      <ToastProvider>
      <SearchProvider>
        <App />
        </SearchProvider>
      </ToastProvider>
    </ContextProvider>
  </UserProvider>
);


