import React, { useEffect, useState } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import CashFlowActions from "../../../CashFlow/Components/CashFlowActions";
import { CashFlowbuttonsConfigs } from "../../../CashFlow/Components/CashFlowbuttonsConfig";
import { CiSearch } from "react-icons/ci";
import { IoMdRefresh } from "react-icons/io";
import { Checkbox } from "@mui/material";
import Mt940Service from "../../../../../rest-services/Mt940Services";
import Pagination from "../../../../../components/PageNation";
import { MdOutlineFileDownload } from "react-icons/md";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import BankStatementActions from "../../props/ActionButtons";
import { useLocation, useNavigate } from "react-router-dom";
import StatementAnalyzerService from "../../../../../rest-services/StatementAnalyserServices";
import CustomeLoading from "../../../../../components/CustomeLoading";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { useToast } from "../../../../../components/toast/toast";


export default function StatementAnalyzerNewBs() {
  const [isExpanded, setIsExpanded] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false)
  const { toast } = useToast();
  const [data, setData] = useState("");

  const toggleLayout = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const downloadExcel = async () => {
    try {
      const response = await StatementAnalyzerService.exportProcessData(analyserData);

      if (response) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "mt940balances.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast("success", "Downloaded Successfully");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      toast("error", "Failed to download data");
    }
  };

  const buttons = bankStatementActionsConfig.screen2.map((button) => {
    let onClick;
    let title;
    if (button.icon === CiSearch) {
      onClick = toggleLayout;
      title = "toggle";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downloadExcel();
      title = "download";
    } else if (button.icon === IoMdRefresh) {
      onClick = () => { setCurrentPage(1); setData([]); setTotalCount(0); fetchDataForTable(currentPage); }
      title = "download";
    }

    return {
      ...button,
      onClick,
      title,
    };
  });

  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    fetchDataForTable();
  }, [searchQuery]);
  // const fetchDataForTable = async (page) => {
  //   try {
  //     const response = await Mt940Service.getmt940s(pageSize, page);

  //     let { mt940, totalCount } = response.data.data;

  //     if (!mt940) {
  //       mt940 = [];
  //     } else if (!Array.isArray(mt940)) {
  //       mt940 = [mt940];
  //     }

  //     setData(mt940);
  //     setTotalCount(totalCount);
  //     setTotalPages(Math.ceil(totalCount / 20));
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const location = useLocation();
  const analyserData = location.state;

  const fetchDataForTable = async () => {
    try {
      setIsLoading(true)
      const response = await StatementAnalyzerService.getMt940Balances(analyserData, searchQuery);
      if (response.status === 200) {
        const mt940 = response.analyser;
        if (!mt940) {
          mt940 = [];
        } else if (!Array.isArray(mt940)) {
          mt940 = [mt940];
        }
        setIsLoading(false)
        setData(mt940);
        setTotalCount(mt940.length);
        setTotalPages(Math.ceil(mt940.length / pageSize));
        setCurrentPage(1); // Reset to first page
        // mt940 = null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false)
    }
    finally {
      setIsLoading(false)
    }
  };
  const paginatedData = data.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handleShowAll = () => {
    setPageSize(totalCount);
    setCurrentPage(1);
    fetchDataForTable();
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    // { Header: "S.NO", accessor: "sno", visible: true, width: ("Corporate BIC".length -1)*10, },
    { Header: "File Name", accessor: "file_name", visible: true, width: ("File Name".length - 1) * 10, },
    {
      Header: "MT940 Unique ID",
      accessor: "mt940_unique_id",
      visible: true,
      width: ("MT940 Unique ID".length - 1) * 10,
    },
    {
      Header: "Statement Type",
      accessor: "statement_type",
      visible: true,
      width: ("Statement Type".length - 1) * 10,
    },
    {
      Header: "Transaction indicator",
      accessor: "transaction_indicator",
      visible: true,
      width: ("Transaction indicator".length - 1) * 10,
    },
    {
      Header: "Corporate BIC",
      accessor: "corporate_bic",
      visible: true,
      width: ("Corporate BIC".length - 1) * 10,
    },
    { Header: "Bank BIC", accessor: "bank_bic", visible: true, width: ("Bank BIC".length - 1) * 10, },
    { Header: "Reference", accessor: "reference", visible: true, width: ("Reference".length - 1) * 10, },
    {
      Header: "Statement Identifier",
      accessor: "statement_identifier",
      visible: true,
      width: ("Statement Identifier".length - 1) * 10,
    },
    {
      Header: "Statement No",
      accessor: "statement_no",
      visible: true,
      width: ("Statement No".length - 1) * 10,
    },
    { Header: "Currency", accessor: "currency", visible: true, width: ("Currency".length - 1) * 10, },
    {
      Header: "Op Value Date",
      accessor: "op_value_date",
      visible: true,
      width: ("Op Value Date".length - 1) * 10,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
      //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
      //   const year = date.getFullYear();
      //   return <div style={{ textAlign: "center" }}> {`${day}-${month}-${year}`}</div>;
      // },
    },
    {
      Header: "Opening Balance",
      accessor: "opening_balance",
      visible: true,
      width: ("Opening Balance".length - 1) * 10,
      alignment: 'right',
    },
    {
      Header: "Credit Amount",
      accessor: "creditamount",
      visible: true,
      width: ("Credit Amount".length - 1) * 10,
      alignment: 'right',
    },

    {
      Header: "Debit Amount",
      accessor: "debitamount",
      visible: true,
      width: ("Debit Amount".length - 1) * 10,
      alignment: 'right',
    },
    {
      Header: "Closing Balance",
      accessor: "closing_balance",
      visible: true,
      width: ("Closing Balance".length - 1) * 10,
      alignment: 'right',
    },
    {
      Header: "Cp Value Date",
      accessor: "cp_value_date",
      visible: true,
      width: ("Cp Value Date".length - 1) * 10,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   const day = String(date.getDate()).padStart(2, "0");
      //   const month = String(date.getMonth() + 1).padStart(2, "0");
      //   const year = date.getFullYear();
      //   return <div style={{ textAlign: "center" }}> {`${day}-${month}-${year}`}</div>;
      // },
    },

    {
      Header: "CBD>OBD",
      accessor: "cb_op",
      visible: true,
      width: ("CBD>OBD".length - 1) * 10,
    },
    {
      Header: "No of Days",
      accessor: "no_of_days",
      visible: true,
      width: ("No of Days".length - 1) * 10,
    },

    {
      Header: "Statement Imported Date",
      accessor: "statement_imported_date",
      visible: true,
      width: ("Statement Imported Date".length - 1) * 10,
      // Cell: ({ value }) => {
      //   if (!value) return "";
      //   const date = new Date(value);
      //   const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
      //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
      //   const year = date.getFullYear();
      //   return <div style={{ textAlign: "center" }}> {`${day}-${month}-${year}`}</div>;
      // },
    },
    {
      Header: "Statement Imported by",
      accessor: "statement_imported_by",
      visible: true,
      width: ("Statement Imported by".length - 1) * 10,
    },
    {
      Header: "Statement Collection Date",
      accessor: "statment_collection_date",
      visible: true,
      width: ("Statement Collection Date".length - 1) * 10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "center" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Time Stamp",
      accessor: "time_stamp",
      visible: true,
      width: ("Time Stamp".length - 1) * 10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "center" }}>{formattedTime}</div>;
      },
    },
  ];

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width,
    }));

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
  };

  const handleDoubleClick = () => {
    console.log("double click");
  };
  const navigate = useNavigate()
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Statement Identifier") {
      navigate("/statement-identifire");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  if (isLoading)
    return (
      <div>
        <CustomeLoading />
      </div>
    );
  return (
    <div>
      <div>
        <Header title="Bank Statements > MT940 > MT940 Balances " onBreadcrumbClick={handleBreadcrumbClick} />
      </div>
      <div className="flex justify-between">

        {isExpanded && (
          <div className="flex mr-16 p-4">
            <button className=" mr-4 border border-blue-500 text-sm text-blue-500 py-1 px-2   rounded hover:bg-blue-100 transition duration-200 ease-in-out w-16 h-8 ">
              Reset
            </button>
            <button className="mr-4 bg-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 h-8 ">
              Save
            </button>
          </div>
        )}
      </div>
      <div
        className={`transition-opacity duration-900 ease-in-out ${isExpanded ? "opacity-100" : "opacity-0"
          }`}
      >
        {isExpanded && (
          <div className="grid grid-cols-2 gap-2 w-full  p-4">
            <div className="grid grid-cols-2 gap-2 col-span-2 h-48 ">
              <div className="border p-4 rounded-md bg-[#f3f4f6] ">
                <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      CompanyCode
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter main head"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Bank ID
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter sub heads"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Country
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Branch{" "}
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter additional info"
                    />
                  </label>
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Bank Country
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Account ID
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter additional info"
                    />
                  </label>
                </div>
              </div>
              <div className="border p-4 rounded-md bg-[#f3f4f6] ">
                <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Value Date
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter main head"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Statement Imported Date
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter sub heads"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Uploaded By
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2 col-span-1 h-48 rounded ">
              <div className="border p-4 rounded-md bg-[#f3f4f6] space-y-8">
                <div></div>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Cash Flow{" "}
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 "
                  />
                </label>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Cash Flow{" "}
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
              </div>
              <div className="border p-4 rounded-md bg-[#f3f4f6]">
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Manually Changed
                  </p>
                </div>
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Not Assigned
                  </p>
                </div>
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Manually Changed
                  </p>
                </div>
              </div>
            </div>

            <div className="col-span-1 border p-4 rounded-md bg-[#f3f4f6]">
              <div className="flex space-x-8">
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Company Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Company Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="p-1">
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onChange={(e) => fetchDataForTable()}
              placeholder="Search by Identifiers"
            />

            <BankStatementActions buttons={buttons} />
          </div>
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>

        </div>
        <div>
          <ReTable
            data={paginatedData}
            columns={modifiedColumns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            onRowDoubleClick={handleDoubleClick}
          />
        </div>
      </div>
    </div>
  );
}
