import RestService from "./RestServices";

const StateProvinceServices = {
  getAllStateprovince: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching StateProvince data:", error);
      return { error: true, message: "Error fetching StateProvince data" };
    }
  },

  getAllStateprovincewithPage: (pageSize, page) => {
    return RestService.GetAllData(
      `/get-stateprovince-all?limit=${pageSize}&offset=${(page - 1) * pageSize}`
    );
  },

  addStateProvince: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding StateProvince:", error);
      return { error: true, message: "Error adding StateProvince" };
    }
  },
  getStateProvince: (id) => {
    return RestService.GetByIdData(`/get-stateprovince`, id);
  },
  deleteStateProvince: (id) => {
    return RestService.DeleteData("/delete-stateprovince", id);
  },
};

export default StateProvinceServices;
