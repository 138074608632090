import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NoActionLayout from "../../../../../components/NoActionLayout";
import axios from "axios";
import AdminService from "../../../../../rest-services/AdminServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import Tabs from "../../../../../components/Tabs";
import Footer from "../../../../../components/footer";
import CustomButton from "../../../../../components/CustomButton";
import CustomDropdown from "../../../../../components/CustomDropdown";
import { set } from "lodash";
export default function PasswordScreen() {
  const navigate = useNavigate();
  const { iconId } = useParams();
  const [activeTab, setActiveTab] = useState("passwordPolicy");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { toast } = useToast();
  const [errorsPassword, setErrorsPassword] = useState({});
  const [errorsOtp, setErrorsOtp] = useState({});

  const [minPasswordLength, setMinPasswordLength] = useState();
  const [maxPasswordLength, setMaxPasswordLength] = useState();
  const [capitalLetters, setCapitalLetters] = useState();
  const [numericalCharacters, setNumericalCharacters] = useState();
  const [specialCharacters, setSpecialCharacters] = useState();
  const [checkPasswordHistory, setCheckPasswordHistory] = useState(false);
  const [checkPasswordComplexity, setCheckPasswordComplexity] = useState(false);
  const [passwordMustNotContainUserId, setPasswordMustNotContainUserId] =
    useState(false);
  const [enableOtpForLogin, setEnableOtpForLogin] = useState(false);
  const [maxWrongAttempts, setMaxWrongAttempts] = useState();
  const [lockTime, setLockTime] = useState();
  const [lockTimeUnit, setLockTimeUnit] = useState("Minutes");
  const [maxLockIterations, setMaxLockIterations] = useState();
  const [enablePasswordExpiry, setEnablePasswordExpiry] = useState(false);
  const [passwordExpiry, setPasswordExpiry] = useState();
  const [expirationWarning, setExpirationWarning] = useState();

  const [otpTypeFirstTime, setOtpTypeFirstTime] = useState("alphanumeric");
  const [otpLengthFirstTime, setOtpLengthFirstTime] = useState();
  const [otpExpiryTimeFirstTime, setOtpExpiryTimeFirstTime] = useState();
  const [otpExpiryUnitFirstTime, setOtpExpiryUnitFirstTime] =
    useState("Minutes");
  const [capitalLettersFirstTime, setCapitalLettersFirstTime] = useState();
  const [numericalCharactersFirstTime, setNumericalCharactersFirstTime] =
    useState();
  const [specialCharactersFirstTime, setSpecialCharactersFirstTime] =
    useState();

  const [otpTypeRegular, setOtpTypeRegular] = useState("alphanumeric");
  const [otpLengthRegular, setOtpLengthRegular] = useState();
  const [otpExpiryTimeRegular, setOtpExpiryTimeRegular] = useState();
  const [otpExpiryUnitRegular, setOtpExpiryUnitRegular] = useState("Minutes");
  const [capitalLettersRegular, setCapitalLettersRegular] = useState();
  const [numericalCharactersRegular, setNumericalCharactersRegular] =
    useState();
  const [specialCharactersRegular, setSpecialCharactersRegular] = useState();

  const [questions, setQuestions] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
    question9: "",
    question10: "",
  });
  const [user, setUser] = useState({ id: "", email: "", clientId: "" });
  const validateOtp = () => {
    const newErrorsOtp = {};

    // First-time OTP validation
    if (!otpTypeFirstTime)
      newErrorsOtp.otpTypeFirstTime = "Bank Code is required.";
    if (!otpLengthFirstTime)
      newErrorsOtp.otpLengthFirstTime = "OTP length is required.";
    // Sum validation for capital, special, and numerical characters
    const characterSumFirst =
      parseInt(capitalLettersFirstTime) +
      parseInt(specialCharactersFirstTime) +
      parseInt(numericalCharactersFirstTime);
    if (characterSumFirst < otpLengthFirstTime) {
      newErrorsOtp.capitalLettersFirstTime =
        "Sum of capital, special, and numerical characters must not less than the OTP length";
      newErrorsOtp.specialCharactersFirstTime =
        "Sum of capital, special, and numerical characters must not less than the OTP length";
      newErrorsOtp.numericalCharactersFirstTime =
        "Sum of capital, special, and numerical characters must not less than the OTP length";
    }
    if (characterSumFirst > otpLengthFirstTime) {
      newErrorsOtp.capitalLettersFirstTime =
        "Sum of capital, special, and numerical characters must not exceed the OTP length";
      newErrorsOtp.specialCharactersFirstTime =
        "Sum of capital, special, and numerical characters must not exceed the OTP length";
      newErrorsOtp.numericalCharactersFirstTime =
        "Sum of capital, special, and numerical characters must not exceed the OTP length";
    }

    // Ensure capital, special, and numerical characters are greater than 0
    if (capitalLettersFirstTime < 1) {
      newErrorsOtp.capitalLettersFirstTime =
        "Number of capital letters must be greater than 0";
    }
    if (specialCharactersFirstTime < 1) {
      newErrorsOtp.specialCharactersFirstTime =
        "Number of special characters must be greater than 0";
    }
    if (numericalCharactersFirstTime < 1) {
      newErrorsOtp.numericalCharactersFirstTime =
        "Number of numerical characters must be greater than 0";
    }

    if (!otpExpiryTimeFirstTime)
      newErrorsOtp.otpExpiryTimeFirstTime = "Expiry time is required.";
    if (!otpExpiryUnitFirstTime)
      newErrorsOtp.otpExpiryUnitFirstTime = "Expiry unit is required.";

    // Regular OTP validation
    if (!otpTypeRegular)
      newErrorsOtp.otpTypeFirstTime = "Bank Code is required.";
    if (!otpLengthRegular)
      newErrorsOtp.otpLengthRegular = "OTP length is required.";

    const characterSumRegular =
      parseInt(capitalLettersRegular) +
      parseInt(specialCharactersRegular) +
      parseInt(numericalCharactersRegular);
    if (characterSumRegular < otpLengthRegular) {
      newErrorsOtp.capitalLettersRegular =
        "Sum of capital, special, and numerical characters must not less than the OTP length";
      newErrorsOtp.specialCharactersRegular =
        "Sum of capital, special, and numerical characters must less than the OTP length";
      newErrorsOtp.numericalCharactersRegular =
        "Sum of capital, special, and numerical characters must less than ththe OTP length";
    }
    if (characterSumRegular > otpLengthRegular) {
      newErrorsOtp.capitalLettersRegular =
        "Sum of capital, special, and numerical characters must not exceed the OTP length";
      newErrorsOtp.specialCharactersRegular =
        "Sum of capital, special, and numerical characters must exceed the OTP length";
      newErrorsOtp.numericalCharactersRegular =
        "Sum of capital, special, and numerical characters must exceed the OTP length";
    }

    // Ensure capital, special, and numerical characters are greater than 0
    if (capitalLettersRegular < 1) {
      newErrorsOtp.capitalLettersRegular =
        "Number of capital letters must be greater than 0";
    }
    if (specialCharactersRegular < 1) {
      newErrorsOtp.specialCharactersRegular =
        "Number of special characters must be greater than 0";
    }
    if (numericalCharactersRegular < 1) {
      newErrorsOtp.numericalCharactersRegular =
        "Number of numerical characters must be greater than 0";
    }

    if (!otpExpiryTimeRegular)
      newErrorsOtp.otpExpiryTimeRegular = "Expiry time is required.";
    if (!otpExpiryUnitRegular)
      newErrorsOtp.otpExpiryUnitRegular = "Expiry unit is required.";

    setErrorsOtp(newErrorsOtp);
    return Object.keys(newErrorsOtp).length === 0;
  };

  const validatePassword = () => {
    const errorsPassword = {};

    // Check if all required fields have values
    if (!minPasswordLength) {
      errorsPassword.minPasswordLength = "Minimum password length is required";
    }
    if (!maxPasswordLength) {
      errorsPassword.maxPasswordLength = "Maximum password length is required";
    }
    if (!capitalLetters) {
      errorsPassword.capitalLetters = "Number of capital letters is required";
    }
    if (!specialCharacters) {
      errorsPassword.specialCharacters =
        "Number of special characters is required";
    }
    if (!numericalCharacters) {
      errorsPassword.numericalCharacters =
        "Number of numerical characters is required";
    }
    if (!maxWrongAttempts) {
      errorsPassword.maxWrongAttempts = "Maximum wrong attempts is required";
    }
    if (!lockTime) {
      errorsPassword.lockTime = "Lock time is required";
    }

    if (!maxLockIterations) {
      errorsPassword.maxLockIterations = "Maximum lock iterations is required";
    }
    if (enablePasswordExpiry && !passwordExpiry) {
      errorsPassword.passwordExpiry = "Password expiry time is required";
    }
    if (enablePasswordExpiry && !expirationWarning) {
      errorsPassword.expirationWarning = "Expiration warning is required";
    }

    // Additional validation logic
    if (minPasswordLength > maxPasswordLength) {
      errorsPassword.minPasswordLength =
        "Minimum password length must be less than the maximum password length";
    }

    // Sum validation for capital, special, and numerical characters
    const characterSum =
      parseInt(capitalLetters) +
      parseInt(specialCharacters) +
      parseInt(numericalCharacters);
    if (minPasswordLength < characterSum) {
      errorsPassword.capitalLetters =
        "should be equal or less than minimum length";
      errorsPassword.specialCharacters =
        "should be equal or less than minimum length";
      errorsPassword.numericalCharacters =
        "should be equal or less than minimum length";
    }
    if (characterSum > maxPasswordLength) {
      errorsPassword.capitalLetters =
        "should be equal or less than maximum length";
      errorsPassword.specialCharacters =
        "should be equal or less than maximum length";
      errorsPassword.numericalCharacters =
        "should be equal or less than maximum length";
    }


    // Ensure capital, special, and numerical characters are greater than 0
    if (capitalLetters < 1) {
      errorsPassword.capitalLetters =
        "Number of capital letters must be greater than 0";
    }
    if (specialCharacters < 1) {
      errorsPassword.specialCharacters =
        "Number of special characters must be greater than 0";
    }
    if (numericalCharacters < 1) {
      errorsPassword.numericalCharacters =
        "Number of numerical characters must be greater than 0";
    }

    // Update the state with errorsPassword if any
    setErrorsPassword(errorsPassword);

    // Return a boolean indicating if the validation passed or failed
    return Object.keys(errorsPassword).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestions((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  useEffect(() => {
    fetchUser();
    fetchOtpPolicy();
    fetchPasswordPolicy();
    fetchQuestions();
  }, []);


  const fetchUser = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUser({
          id: userDetail.userId || "",
          email: userDetail.email || "",
          OTP: userDetail.oneTimePassword,
          clientId: userDetail.clientId || "",
        });
      }
    } catch (error) { }
  };

  const fetchQuestions = async () => {
    try {
      const response = await AdminService.getAllQuestion();

      // Create a base object with empty strings for 10 questions
      const defaultQuestions = {
        question1: "", question2: "", question3: "", question4: "", question5: "",
        question6: "", question7: "", question8: "", question9: "", question10: ""
      };

      // Fill in available questions from API response
      response.data.data.questions.forEach((item, index) => {
        defaultQuestions[`question${index + 1}`] = item.question;
      });

      // Ensure all 10 fields are maintained
      setQuestions(defaultQuestions);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const fetchOtpPolicy = async () => {
    try {
      const response = await AdminService.getOtpPolicy();
      // console.log(response.data);
      if (response.data) {
        setOtpTypeFirstTime(
          response.data.otp_type_first_time || "alphanumeric"
        );
        setOtpLengthFirstTime(response.data.otp_length_first_time);
        setOtpExpiryTimeFirstTime(response.data.otp_expiry_time_first_time);
        setOtpExpiryUnitFirstTime(
          response.data.otp_expiry_unit_first_time || "Hours"
        );
        setCapitalLettersFirstTime(response.data.capital_letters_first_time);
        setNumericalCharactersFirstTime(
          response.data.numerical_characters_first_time
        );
        setSpecialCharactersFirstTime(
          response.data.special_characters_first_time
        );

        setOtpTypeRegular(response.data.otp_type_regular || "alphanumeric");
        setOtpTypeRegular(response.data.otp_type_regular);
        setOtpLengthRegular(response.data.otp_length_regular);
        setOtpExpiryTimeRegular(response.data.otp_expiry_time_regular);
        setOtpExpiryUnitRegular(
          response.data.otp_expiry_unit_regular || "Hours"
        );
        setCapitalLettersRegular(response.data.capital_letters_regular);
        setNumericalCharactersRegular(
          response.data.numerical_characters_regular
        );
        setSpecialCharactersRegular(response.data.special_characters_regular);
      }
    } catch (e) {
      console.error("Error fetching OTP policy data:", e);
    }
  };

  const fetchPasswordPolicy = async () => {
    try {
      const response = await AdminService.getPasswordPolicy();
      console.log(response.data);
      if (response.data) {
        setMinPasswordLength(response.data.password_length_min);
        setMaxPasswordLength(response.data.password_length_max);
        setCapitalLetters(response.data.capital_letters);
        setNumericalCharacters(response.data.numerical_characters);
        setSpecialCharacters(response.data.special_characters);
        setCheckPasswordHistory(response.data.check_password_history || false);
        setCheckPasswordComplexity(
          response.data.check_password_complexity || false
        );
        setPasswordMustNotContainUserId(
          response.data.check_password_uid_name || false
        );
        setEnableOtpForLogin(response.data.enable_otp_login || false);
        setMaxWrongAttempts(response.data.max_wrong_attempts);
        setLockTime(response.data.lock_time_in);
        setLockTimeUnit(response.data.lock_time_unit || "Minutes");
        setMaxLockIterations(response.data.max_lock_iterations);
        setEnablePasswordExpiry(response.data.enable_password_expiry || false);
        setPasswordExpiry(response.data.password_expiry);
        setExpirationWarning(response.data.expiration_warning);
      }
    } catch (error) {
      console.error("Error fetching password policy:", error);
    }
  };



  const [questionsEdited, setQuestionsEdited] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const handleSave = async () => {
    try {
      // Check if all questions are filled
      const isValid = Object.values(questions).every(
        (question) => question.trim() !== ""
      );

      if (!questionsEdited) {
        return;
      }

      if (!isValid) {
        toast("error", "Please fill all security questions before saving.");
        return;
      }

      // Prepare data
      const questionsList = Object.keys(questions).map((key, index) => ({
        id: index + 1,
        question: questions[key].trim(), // Trim to remove unnecessary spaces
      }));

      const data = {
        clientId: user.clientId,
        questionsList,
        createdBy: user.id,
        changedBy: user.id,
      };

      // Save questions
      const response = await AdminService.createQuestion(data);

      if (response.status === 200) {
        setUnsavedChanges(false);
        toast("success", "Questions saved successfully");
      }
    } catch (error) {
      console.error("Error saving questions:", error);
      toast("error", "An error occurred while saving questions.");
    }
  };

  const handleUpsertPasswordPolicy = async () => {
    if (!validatePassword()) {
      // Show error toast if any required fields are missing
      return; // Exit the function to prevent API call
    }

    // Prepare the data object for the password policy
    const data = {
      passwordPolicyData: {
        clientId: user.clientId,
        password_length_min: minPasswordLength,
        password_length_max: maxPasswordLength,
        capital_letters: capitalLetters,
        numerical_characters: numericalCharacters,
        special_characters: specialCharacters,
        check_password_history: checkPasswordHistory,
        check_password_complexity: checkPasswordComplexity,
        check_password_uid_name: passwordMustNotContainUserId,
        enable_otp_login: enableOtpForLogin,
        max_wrong_attempts: maxWrongAttempts,
        lock_time_in: lockTime,
        lock_time_unit: lockTimeUnit,
        max_lock_iterations: maxLockIterations,
        enable_password_expiry: enablePasswordExpiry,
        password_expiry: passwordExpiry,
        expiration_warning: expirationWarning,
      },
    };

    try {
      const response = await AdminService.createOrUpdatePasswordPolicy(data);

      if (response.status === 200) {
        setUnsavedChanges(false);
        toast("success", "Password Policy Saved Successfully");
      } else {
        console.warn("Unexpected response format:", response);
        toast("error", "Failed to save Password Policy");
      }
    } catch (e) {
      console.error("Error saving or updating Password policy:", e);
      toast("error", "Error occurred while saving Password policy");
    }
  };

  const tabs = [
    { value: "passwordPolicy", label: "Password Policy" },
    { value: "otpPolicy", label: "OTP" },
    { value: "securityQuestions", label: "Security Questions" },
  ];

  const handleUpsertOtpPolicy = async () => {
    if (!validateOtp()) {
      return;
    }

    const data = {
      otpPolicyData: {
        clientId: user.clientId,
        otp_type_first_time: otpTypeFirstTime,
        otp_length_first_time: otpLengthFirstTime,
        otp_expiry_time_first_time: otpExpiryTimeFirstTime,
        otp_expiry_unit_first_time: otpExpiryUnitFirstTime,
        capital_letters_first_time: capitalLettersFirstTime,
        numerical_characters_first_time: numericalCharactersFirstTime,
        special_characters_first_time: specialCharactersFirstTime,
        otp_type_regular: otpTypeRegular,
        otp_length_regular: otpLengthRegular,
        otp_expiry_time_regular: otpExpiryTimeRegular,
        otp_expiry_unit_regular: otpExpiryUnitRegular,
        capital_letters_regular: capitalLettersRegular,
        numerical_characters_regular: numericalCharactersRegular,
        special_characters_regular: specialCharactersRegular,
      },
    };

    try {
      const response = await AdminService.createOrUpdateOTPPolicy(data);
      if (response.status === 200) {
        setUnsavedChanges(false);

        toast("success", "OTP Policy Saved Successfully");
      } else {
        toast("error", "Failed to save OTP Policy");
      }
    } catch (e) {
      console.error("Error saving or updating OTP policy:", e);
      toast("error", "Error occurred while saving OTP policy");
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Admin") {
      navigate("/14/admin");
    } else if (crumb.title === "Password Policy") {
      console.log("Navigating to /password policy");
      navigate("/14/admin/passwordscreen/113");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  // const tabStyle = (tab) => ({
  //   backgroundColor: activeTab === tab ? "white" : "transparent",
  //   color: activeTab === tab ? "#0B60B0" : "white",
  //   padding: "2px 10px",
  //   cursor: "pointer",
  //   borderRadius: "5px 5px 0px 0px",
  //   border: "none",
  // });

  const otpFirstOption = [
    { value: "alphanumeric", label: "Alphanumeric" },
    { value: "numeric", label: "Numeric" },
  ];

  const [openDropdown, setOpenDropdown] = useState(null);
  const handleToggle = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
  };
  return (
    <div>
      {/* <div>{iconId}</div> */}
      <div>
        <Header
          title={"Admin > Password Policy"}
          onBreadcrumbClick={handleBreadcrumbClick}
          hasUnsavedChanges={unsavedChanges}
        />
      </div>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />

      {/* <div>
        <div className="ml-2 pl-8 pt-2 flex flex-row space-x-6 bg-[#0B60B0]">
          <h6
            className="font-small"
            style={tabStyle("passwordPolicy")}
            onClick={() => handleTabClick("passwordPolicy")}
          >
            Password Policy
          </h6>
          <h6
            className="font-small"
            style={tabStyle("otpPolicy")}
            onClick={() => handleTabClick("otpPolicy")}
          >
            OTP
          </h6>
          <h6
            className="font-small"
            style={tabStyle("securityQuestions")}
            onClick={() => handleTabClick("securityQuestions")}
          >
            Security Questions
          </h6>

        </div>
      </div> */}

      {/* Security Questions Tab Content */}
      {activeTab === "securityQuestions" && (
        <div className="p-4">
          <div>
            <h6 className="text-sm font-medium text-customGray ml-8">Security Questions</h6> </div>
          <div className="flex flex-col gap-y-4 mt-4 ml-8">
            {Object.keys(questions).map((questionKey, index) => (
              <label key={questionKey} className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Security Question {index + 1}
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  name={questionKey}
                  value={questions[questionKey]}
                  onChange={(e) => {
                    handleChange(e);
                    setQuestionsEdited(true);
                    setUnsavedChanges(true);
                  }}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
                {index === 0 && (
                  <p className="ml-16 w-80 font-small text-xs text-customGray font-left">
                    User will choose any three questions from 10 available
                    questions
                  </p>
                )}
              </label>
            ))}
          </div>
          <Footer>
            {/* <CustomButton onClick={handleSave} title={"Save"} /> */}
            <CustomButton onClick={handleSave} title={"Save"} icons={iconId} />
          </Footer>
        </div>
      )}

      {/* <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            State Name<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={stateName}
            onChange={(e) => {setStateName(e.target.value)
            if(errorsOtp){
              setErrors((prevErrors)=>({
                ...prevErrors,
                stateName:"",
              }))
 
            }
            }}
            className={`border ${
              errors.stateName ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
          />
        </label> */}

      {/* Password Policy Tab Content */}
      {activeTab === "passwordPolicy" && (
        <div className="p-4 mb-60">
          {/* First Time Password Section */}
          {/* Password Value Section */}
          <h2 className="text-sm font-medium text-customGray ml-8">
            First Time Password
          </h2>
          <div className="grid grid-cols-2 gap-4 mt-4 ml-8">
            {/* Left Column */}
            <div className="flex flex-col gap-y-4">
              {/* Password Length with Min and Max */}
              <label className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Password Length<span className="text-red-500">*</span>
                </span>
                <span className="ml-3 2xl:mr-0 mr-2">:</span>
                <div className="flex ml-4 ">
                  <input
                    type="number"
                    value={minPasswordLength}
                    onChange={(e) => {
                      setMinPasswordLength(e.target.value);
                      if (errorsPassword) {
                        setErrorsPassword((prevErrors) => ({
                          ...prevErrors,
                          minPasswordLength: "",
                        }));
                      }
                      setUnsavedChanges(true);
                    }}
                    className={`border ${errorsPassword.minPasswordLength
                      ? "border-red-500"
                      : "border-gray-300"
                      } p-1.5 rounded 2xl:w-32 w-28 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 `}
                    required
                    placeholder="Min"
                  />
                  <input
                    type="number"
                    value={maxPasswordLength}
                    onChange={(e) => {
                      setMaxPasswordLength(e.target.value);
                      if (errorsPassword) {
                        setErrorsPassword((prevErrors) => ({
                          ...prevErrors,
                          maxPasswordLength: "",
                        }));
                      }
                      setUnsavedChanges(true);
                    }}
                    className={`border ${errorsPassword.maxPasswordLength
                      ? "border-red-500"
                      : "border-gray-300"
                      } p-1.5 rounded 2xl:w-32 w-28 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-8`}
                    required
                    placeholder="Max"
                  />
                </div>
              </label>
              {/* Error message for Password Length */}
              {errorsPassword.minPasswordLength && (
                <p className="text-red-500 text-xs mt-1 ml-4">
                  {errorsPassword.minPasswordLength}
                </p>
              )}
              {errorsPassword.maxPasswordLength && (
                <p className="text-red-500 text-xs mt-1 ml-4">
                  {errorsPassword.maxPasswordLength}
                </p>
              )}

              {/* Number of Capital Letters */}
              <label className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Capital Letters<span className="text-red-500">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="number"
                  onChange={(e) => {
                    setCapitalLetters(e.target.value);
                    if (errorsPassword) {
                      setErrorsPassword((prevErrors) => ({
                        ...prevErrors,
                        capitalLetters: "",
                      }));
                    }
                    setUnsavedChanges(true);
                  }}
                  value={capitalLetters}
                  className={`border ${errorsPassword.capitalLetters
                    ? "border-red-500"
                    : "border-gray-300"
                    } p-1.5 rounded w-72 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-4`}
                  required
                />
              </label>

              {errorsPassword.capitalLetters && (
                <p className="text-red-500 text-xs mt-1 ml-4">
                  {errorsPassword.capitalLetters}
                </p>
              )}

              {/* Number of Numerical Characters */}
              <label className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Numerical Characters<span className="text-red-500">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="number"
                  onChange={(e) => {
                    setNumericalCharacters(e.target.value);
                    if (errorsPassword) {
                      setErrorsPassword((prevErrors) => ({
                        ...prevErrors,
                        numericalCharacters: "",
                      }));
                    }
                    setUnsavedChanges(true);
                  }}
                  value={numericalCharacters}
                  className={`border ${errorsPassword.numericalCharacters
                    ? "border-red-500"
                    : "border-gray-300"
                    } p-1.5 rounded w-72 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-4`}
                  required
                />
              </label>
              {errorsPassword.numericalCharacters && (
                <p className="text-red-500 text-xs mt-1 ml-4">
                  {errorsPassword.numericalCharacters}
                </p>
              )}
              {/* Number of Special Characters */}
              <label className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Special Characters<span className="text-red-500">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="number"
                  onChange={(e) => {
                    setSpecialCharacters(e.target.value);
                    if (errorsPassword) {
                      setErrorsPassword((prevErrors) => ({
                        ...prevErrors,
                        specialCharacters: "",
                      }));
                    }
                    setUnsavedChanges(true);
                  }}
                  value={specialCharacters}
                  className={`border ${errorsPassword.specialCharacters
                    ? "border-red-500"
                    : "border-gray-300"
                    } p-1.5 rounded w-72 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-4`}
                  required
                />
              </label>
              {errorsPassword.specialCharacters && (
                <p className="text-red-500 text-xs mt-1 ml-4">
                  {errorsPassword.specialCharacters}
                </p>
              )}
            </div>

            {/* Right Column */}
            <div className="flex flex-col gap-y-4">
              {/* Check Password History */}
              <label className="flex items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setCheckPasswordHistory(e.target.checked);
                    setUnsavedChanges(true);
                  }}
                  checked={checkPasswordHistory}
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <span className="ml-4 font-small text-customGray text-xs">
                  Check Password History
                </span>
              </label>

              {/* Check Password Complexity */}
              {/* <label className="flex items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setCheckPasswordComplexity(e.target.checked);
                    setUnsavedChanges(true);
                  }}
                  checked={checkPasswordComplexity}
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <span className="ml-4 font-small text-customGray text-xs">
                  Check Password Complexity
                </span>
              </label> */}

              {/* Password Must Not Contain User ID/Name */}
              <label className="flex items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setPasswordMustNotContainUserId(e.target.checked);
                    setUnsavedChanges(true);
                  }}
                  checked={passwordMustNotContainUserId}
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <span className="ml-4 font-small text-customGray text-xs">
                  Password Must Not Contain User ID/Name
                </span>
              </label>

              {/* Password Must Not Contain User ID/Name */}
              <label className="flex items-center">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setEnableOtpForLogin(e.target.checked);
                    setUnsavedChanges(true);
                  }}
                  checked={enableOtpForLogin}
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <span className="ml-4 font-small text-customGray text-xs">
                  Enable OTP for login
                </span>
              </label>
            </div>
          </div>

          {/* Password Limits Section */}
          <h2 className="text-sm font-medium text-customGray ml-8 mt-8">
            Password Limits
          </h2>
          <div className="flex flex-col gap-y-4 mt-4 ml-8">
            {/* Max Wrong Attempts */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Max Wrong Attempts<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                onChange={(e) => {
                  setMaxWrongAttempts(e.target.value);
                  if (errorsPassword) {
                    setErrorsPassword((prevErrors) => ({
                      ...prevErrors,
                      maxWrongAttempts: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                value={maxWrongAttempts}
                className={`border ${errorsPassword.maxWrongAttempts
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>

            {/* Lock Time */}

            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Lock Time<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={lockTime}
                onChange={(e) => {
                  setLockTime(e.target.value);
                  if (errorsPassword) {
                    setErrorsPassword((prevErrors) => ({
                      ...prevErrors,
                      lockTime: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.lockTime ? "border-red-500" : "border-gray-300"
                  } p-1.5 rounded w-32 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-4`}
                required
                placeholder="Time"
              />
              <CustomDropdown
                value={lockTimeUnit}
                options={[
                  { value: "hours", label: "Hours" },
                  { value: "minutes", label: "Minutes" },
                  { value: "seconds", label: "Seconds" },
                ]}
                onChange={(value) => {
                  setLockTimeUnit(value);
                  setUnsavedChanges(true);
                }}
                isOpen={openDropdown === "otpExpiryUnitFirstTime"}
                onToggle={() => handleToggle("otpExpiryUnitFirstTime")}
                className={`ml-8 ${errorsOtp.otpExpiryUnitFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  }`}
                placeholder="Select Unit"
                width="w-32"
              />
            </label>

            {/* Max Lock Iterations */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Max Lock Iterations<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                onChange={(e) => {
                  setMaxLockIterations(e.target.value);
                  if (errorsPassword) {
                    setErrorsPassword((prevErrors) => ({
                      ...prevErrors,
                      maxLockIterations: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                value={maxLockIterations}
                className={`border ${errorsPassword.maxLockIterations
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
          </div>

          {/* Password Expiry Section */}
          <h2 className="text-sm font-medium text-customGray ml-8 mt-8">
            Password Expiry
          </h2>
          <div className="flex flex-col gap-y-4 mt-4 ml-8">
            {/* Enable Password Expiry */}

            <label className="flex items-center">
              <input
                type="checkbox"
                onChange={(e) => {
                  setEnablePasswordExpiry(e.target.checked);
                  setUnsavedChanges(true);
                }}
                checked={enablePasswordExpiry}
                className="form-checkbox text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
              />
              <span className="ml-4 font-small text-customGray text-xs">
                Enable password expiry
              </span>
            </label>

            {/* Password Expiry Time */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Password Expiry <br />
                (in days)<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                onChange={(e) => {
                  setPasswordExpiry(e.target.value);
                  if (errorsPassword) {
                    setErrorsPassword((prevErrors) => ({
                      ...prevErrors,
                      passwordExpiry: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                value={passwordExpiry}
                className={`border ${errorsPassword.passwordExpiry
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-4`}
                required
                disabled={!enablePasswordExpiry} // Disable input if the checkbox is not checked
              />
            </label>

            {/* Expiration Warning */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Expiration Warning <br />
                (in days)<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                onChange={(e) => {
                  setExpirationWarning(e.target.value);
                  if (errorsPassword) {
                    setErrorsPassword((prevErrors) => ({
                      ...prevErrors,
                      expirationWarning: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                value={expirationWarning}
                className={`border ${errorsPassword.expirationWarning
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 text-xs text-customGray h-8 focus:outline-none hover:border-blue-400 ml-4`}
                required
                disabled={!enablePasswordExpiry} // Disable input if the checkbox is not checked
              />
            </label>
          </div>

          {/* Save Button */}
          <Footer>
            <CustomButton onClick={handleUpsertPasswordPolicy} title={"Save"} icons={iconId} />
          </Footer>
        </div>
      )}

      {/* OTP Policy Tab Content */}
      {activeTab === "otpPolicy" && (
        <div className="p-4 mb-40">
          <h2 className="text-sm font-medium text-customGray ml-8">
            First Time Log in
          </h2>
          <div className="flex flex-col gap-y-4 mt-4 ml-8">
            {/* OTP Type Dropdown */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                OTP Type<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>

              <CustomDropdown
                value={otpTypeFirstTime}
                options={otpFirstOption}
                onChange={(e) => {
                  setOtpTypeFirstTime(e);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      otpTypeFirstTime: "",
                    }));
                  }
                  setOpenDropdown(false);
                  setUnsavedChanges(true);
                }}
                isOpen={openDropdown === "otpFirstOption"}
                onToggle={() => handleToggle("otpFirstOption")}
                isDisabled={false}
                className={`ml-4 text-xs ${errorsOtp.otpTypeFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  }`}
                placeholder="Select OTP Type"
                width={"w-72"}
              />
            </label>

            {/* OTP Length Min and Max on one line */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                OTP Length<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={otpLengthFirstTime}
                onChange={(e) => {
                  setOtpLengthFirstTime(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      otpLengthFirstTime: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.otpLengthFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
            {/* Error message for Password Length */}
            {errorsOtp.otpLengthFirstTime && (
              <p className="text-red-500 text-xs mt-1 ml-4">
                {errorsOtp.otpLengthFirstTime}
              </p>
            )}

            {/* OTP Expiry */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                OTP Expiry<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={otpExpiryTimeFirstTime}
                onChange={(e) => {
                  setOtpExpiryTimeFirstTime(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      otpExpiryTimeFirstTime: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.otpExpiryTimeFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-32 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
                placeholder="Time"
              />
              <CustomDropdown
                value={otpExpiryUnitFirstTime}
                options={[
                  { value: "hours", label: "Hours" },
                  { value: "minutes", label: "Minutes" },
                  { value: "seconds", label: "Seconds" },
                ]}
                onChange={(value) => {
                  setOtpExpiryUnitFirstTime(value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      otpExpiryUnitFirstTime: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                isOpen={openDropdown === "otpExpiryUnitFirstTime"}
                onToggle={() => handleToggle("otpExpiryUnitFirstTime")}
                className={`ml-8  text-xs text-customGray ${errorsOtp.otpExpiryUnitFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  }`}
                placeholder="Select Unit"
                width="w-32"
              />
            </label>

            {/* Capital Letters Field */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Capital Letters<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={capitalLettersFirstTime}
                onChange={(e) => {
                  setCapitalLettersFirstTime(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      capitalLettersFirstTime: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.capitalLettersFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
            {/* Error message for Password Length */}
            {errorsOtp.capitalLettersFirstTime && (
              <p className="text-red-500 text-xs mt-1 ml-4">
                {errorsOtp.capitalLettersFirstTime}
              </p>
            )}

            {/* Numerical Characters */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Numerical Characters<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={numericalCharactersFirstTime}
                onChange={(e) => {
                  setNumericalCharactersFirstTime(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      numericalCharactersFirstTime: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.numericalCharactersFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
            {/* Error message for Password Length */}
            {errorsOtp.numericalCharactersFirstTime && (
              <p className="text-red-500 text-xs mt-1 ml-4">
                {errorsOtp.numericalCharactersFirstTime}
              </p>
            )}

            {/* Special Characters */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Special Characters<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={specialCharactersFirstTime}
                onChange={(e) => {
                  setSpecialCharactersFirstTime(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      specialCharactersFirstTime: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.specialCharactersFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
            {/* Error message for Password Length */}
            {errorsOtp.specialCharactersFirstTime && (
              <p className="text-red-500 text-xs mt-1 ml-4">
                {errorsOtp.specialCharactersFirstTime}
              </p>
            )}
          </div>

          <hr className="my-8" />

          <h2 className="text-sm font-medium text-customGray mt-6 ml-8">
            Regular
          </h2>
          <div className="flex flex-col gap-y-4 mt-4 ml-8">
            {/* OTP Type Dropdown */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                OTP Type<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <CustomDropdown
                value={otpTypeRegular}
                options={otpFirstOption}
                onChange={(e) => {
                  setOtpTypeRegular(e);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      otpTypeRegular: "",
                    }));
                  }
                  setOpenDropdown(false);
                  setUnsavedChanges(true);
                }}
                isOpen={openDropdown === "otpRegularOption"}
                onToggle={() => handleToggle("otpRegularOption")}
                isDisabled={false}
                className={`ml-4 ${errorsOtp.otpTypeFirstTime
                  ? "border-red-500"
                  : "border-gray-300"
                  }`}
                placeholder="Select OTP Type"
                width={"w-72"}
              />
            </label>

            {/* OTP Length Min and Max on one line */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                OTP Length<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={otpLengthRegular}
                onChange={(e) => {
                  setOtpLengthRegular(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      otpLengthRegular: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.otpLengthRegular
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
            {/* Error message for Password Length */}
            {errorsOtp.otpLengthRegular && (
              <p className="text-red-500 text-xs mt-1 ml-4">
                {errorsOtp.otpLengthRegular}
              </p>
            )}

            {/* OTP Expiry */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                OTP Expiry<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={otpExpiryTimeRegular}
                onChange={(e) => {
                  setOtpExpiryTimeRegular(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      otpExpiryTimeRegular: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.otpExpiryTimeRegular
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-32 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
                placeholder="Time"
              />
              <CustomDropdown
                value={otpExpiryUnitRegular}
                options={[
                  { value: "hours", label: "Hours" },
                  { value: "minutes", label: "Minutes" },
                  { value: "seconds", label: "Seconds" },
                ]}
                onChange={(value) => setOtpExpiryUnitRegular(value)}
                isOpen={openDropdown === "otpExpiryUnitRegular"}
                onToggle={() => handleToggle("otpExpiryUnitRegular")}
                className="ml-8 border-gray-300"
                placeholder="Select Unit"
                width="w-32"
              />
            </label>

            {/* Capital Letters Field */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Capital Letters<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={capitalLettersRegular}
                onChange={(e) => {
                  setCapitalLettersRegular(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      capitalLettersRegular: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.capitalLettersRegular
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
            {/* Error message for Password Length */}
            {errorsOtp.capitalLettersRegular && (
              <p className="text-red-500 text-xs mt-1 ml-4">
                {errorsOtp.capitalLettersRegular}
              </p>
            )}

            {/* Numerical Characters */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Numerical Characters<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={numericalCharactersRegular}
                onChange={(e) => {
                  setNumericalCharactersRegular(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      numericalCharactersRegular: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.numericalCharactersRegular
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
            {/* Error message for Password Length */}
            {errorsOtp.numericalCharactersRegular && (
              <p className="text-red-500 text-xs mt-1 ml-4">
                {errorsOtp.numericalCharactersRegular}
              </p>
            )}

            {/* Special Characters */}
            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Special Characters<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="number"
                value={specialCharactersRegular}
                onChange={(e) => {
                  setSpecialCharactersRegular(e.target.value);
                  if (errorsOtp) {
                    setErrorsOtp((prevErrors) => ({
                      ...prevErrors,
                      specialCharactersRegular: "",
                    }));
                  }
                  setUnsavedChanges(true);
                }}
                className={`border ${errorsOtp.specialCharactersRegular
                  ? "border-red-500"
                  : "border-gray-300"
                  } p-1.5 rounded w-72 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-4`}
                required
              />
            </label>
            {/* Error message for Password Length */}
            {errorsOtp.specialCharactersRegular && (
              <p className="text-red-500 text-xs mt-1 ml-4">
                {errorsOtp.specialCharactersRegular}
              </p>
            )}
          </div>
          <Footer>
            <CustomButton onClick={handleUpsertOtpPolicy} title={"Save"} icons={iconId} />
          </Footer>
        </div>
      )}
    </div>
  );
}
