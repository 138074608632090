import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import { FaCircleUser, FaScaleBalanced } from "react-icons/fa6";
import { MdScreenshotMonitor } from "react-icons/md"; // Import screenshot icon
import styled from "styled-components";
import clogo from "../assets/Bird HD.jpg";
import { AuthContext } from "../context/AuthContext";
import { Alert } from "@mui/material";
import html2canvas from "html2canvas"; // Add this for screenshot functionality
import { BsCashCoin } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { PiSlideshowBold } from "react-icons/pi";
import { LuClipboardList } from "react-icons/lu";
import { HiOutlineCash } from "react-icons/hi";
import { RiHome2Line } from "react-icons/ri";
import { HiArrowTrendingUp } from "react-icons/hi2";
import { IoIdCardOutline } from "react-icons/io5";
import { CiStar } from "react-icons/ci";
import { BsFileBarGraph } from "react-icons/bs";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { RiShieldUserLine } from "react-icons/ri";
import { BiCheckShield } from "react-icons/bi";
import { BsDatabase } from "react-icons/bs";
import { LiaFileSignatureSolid } from "react-icons/lia";
import UserService from "../rest-services/UserServices";






const ScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  /* WebKit-based browsers (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* No background for the track */
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5); /* Semi-transparent white thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.7); /* Slightly less transparent on hover */
  }

  /* For Firefox */
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgba(255, 255, 255, 0.5) transparent; /* Thumb color and track color */

  /* For Internet Explorer/Edge */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer/Edge */
  overflow: -moz-scrollbars-none; /* Hide scrollbar in older versions of Firefox */
`;



const Sidebar = () => {
  const [user, setUser] = useState({ id: "", email: "", userId: "" });
  const [selectedPath, setSelectedPath] = useState("/");
  const [isHovered, setIsHovered] = useState(false);
  const [hoverEnabled, setHoverEnabled] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUser({ id: userDetail.id || "", email: userDetail.email || "", userId: userDetail.userId || "" });
      } else {
        Alert.error("No user detail found in session storage.");
      }
    } catch (error) {
      Alert.error(`Error fetching user details: ${error.message}`);
    }
  };

  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);
  const Menus = [
    { title: "Search", icon: <FaSearch style={{ fontSize: "1.2rem" }} />, path: "/search" },
    { title: "Home", path: "/" },
    { title: "Primary Data", icon: <BsDatabase style={{ fontSize: "1.2rem" }} />, path: "/master-data" },
    { title: "BAM", icon: <PiSlideshowBold style={{ fontSize: "1.2rem" }} />, path: "/bam" },
    { title: "Signatory", icon: <LiaFileSignatureSolid style={{ fontSize: "1.2rem" }} />, path: "" },

    { title: "Bank Statements", icon: <LuClipboardList style={{ fontSize: "1.2rem" }} />, path: "/bank-statements" },
    { title: "Imprest Cash", icon: <BsCashCoin style={{ fontSize: "1.2rem" }} />, path: "" },

    { title: "Cash Transactions", icon: <HiOutlineCash style={{ fontSize: "1.2rem" }} />, path: "/cash-transactions" },
    { title: "Cash Balance", icon: <FaScaleBalanced style={{ fontSize: "1.2rem" }} />, path: "/cash-balance" },
    { title: "Cash Flow", icon: <HiArrowTrendingUp style={{ fontSize: "1.2rem" }} />, path: "/cash-flow" },
    { title: "Accounting", icon: <IoIdCardOutline style={{ fontSize: "1.2rem" }} />, path: "/accounting" },
    { title: "Reconciliation", icon: <CiStar style={{ fontSize: "1.2rem" }} />, path: "/reconciliation" },
    { title: "Report", icon: <BsFileBarGraph style={{ fontSize: "1.2rem" }} />, path: "/reports" },
    { title: "Task Monitor", icon: <IoMdCheckmarkCircleOutline style={{ fontSize: "1.2rem" }} />, path: "/task-monitor" },
    { title: "Admin", icon: <RiShieldUserLine style={{ fontSize: "1.2rem" }} />, path: "/14/admin" },
    { title: "Approvals", icon: <BiCheckShield style={{ fontSize: "1.2rem" }} />, path: "/approvals" },
  ];

  // const handleLogout = () => {
  //   sessionStorage.removeItem("lastPath"); 
  //   logout();
  //   navigate("/", { replace: true });
  // };
  const handleLogout = async () => {
    try {
      const userDetail = JSON.parse(sessionStorage.getItem("userDetail"));
      const id = userDetail?.id;
  console.log("logut userid",id)
      // Update the last_seen time before logging out
      if (id) {
        // Send a single userId
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/last-seen-update`,
          { id }  // Pass the single userId directly
        );
  
        console.log("Response status:", response.status);
      }
  
      // Clear session storage and proceed with logout
      sessionStorage.removeItem("lastPath");
      logout();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Error updating last seen time during logout:", error);
    }
  };
  
  
  
  

  // const handleIconClick = (path) => {
  //   setSelectedPath(path);
  //   navigate(path);
  // };
  useEffect(() => {
    fetchUser(); 
  
    const savedPath = sessionStorage.getItem("lastPath");
    if (savedPath) {
      setSelectedPath(savedPath);  
      navigate(savedPath);  
    }
  }, []);  
  
  const handleIconClick = (path) => {
    setSelectedPath(path);  
    sessionStorage.setItem("lastPath", path);  
    navigate(path); 
  };
  const handleForgotpassword = () => {
    navigate("/forgot-password");
  };

  const handleScreenshot = () => {
    html2canvas(document.body).then((canvas) => {
      const link = document.createElement("a");
      link.download = "screenshot.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  return (
    <div className="flex h-screen">
      <div
        className={`bg-[#0B60B0] border border-dark-gray shadow-md pt-5 transition-all duration-300 ${hoverEnabled && isHovered ? "w-80" : hoverEnabled ? "w-16" : "w-80"
          } flex flex-col relative`}
        onMouseEnter={() => hoverEnabled && setIsHovered(true)}
        onMouseLeave={() => hoverEnabled && setIsHovered(false)}
      >
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center transition-all duration-300 " style={{ padding: "10px 0" }}>
            <img
              src={clogo}
              alt="Logo"
              className={`cursor-pointer block float-left transition-all duration-300 rounded-full ${isHovered ? "w-9 mr-2 rotate-reverse-360" : "w-10 ml-2 rotate-360"
                }`}
            />
            {isHovered ? (
              <h1 className="text-orange-200 font-medium text-xl transition-all duration-300 whitespace-nowrap ml-8">
                NRG PHOENIX
              </h1>
            ) : null}

          </div>
        </div>

        <ScrollContainer>
          <ul>
            {Menus.map((menu, index) => (
              <li
                key={index}
                className={`flex items-center gap-x-4 cursor-pointer px-4 py-2 font-medium mt-1 relative mb-2 transition-all duration-300 ${selectedPath === menu.path
                    ? `bg-white text-customBlue ${isHovered ? "text-customBlue" : "text-black"}`
                    : `hover:bg-white hover:text-customBlue text-white ${isHovered ? "text-customBlue" : "text-white"}`
                  }`}
                onClick={() => handleIconClick(menu.path)}
              >
                <span className={`text-2xl block float-left relative group ${selectedPath === menu.path && !isHovered ? "text-customBlue" : ""}`}>
                  {menu.icon ? menu.icon : <RiHome2Line style={{ fontSize: "1.2rem" }} />}
                </span>
                {isHovered && (
                  <Link to={menu.path} className="text-sm font-medium flex-1 duration-300">
                    {menu.title}
                  </Link>
                )}
              </li>

            ))}
          </ul>
        </ScrollContainer>

        <div className={`border-t flex flex-col p-3 transition-all border border-customBlue   duration-300 ${isHovered ? "w-70" : "0"}`} style={{ backgroundColor: "#0B5299", zIndex: 10 }}>
  {isHovered ? (
    <div className="mt-0 ml-1 text-sm font-medium text-white" style={{ marginTop: "-8px" }}>
      <div className="flex  items-center ">
        <span>UserId <span className="ml-1">:</span></span>
        <span className="ml-2">{user.userId}</span>
        <div className="flex items-center ml-16">
          <Switch
            checked={hoverEnabled}
            onChange={() => setHoverEnabled(!hoverEnabled)}
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "orange",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "orange",
              },
            }}
            color="default"
            className="ml-6" // Adjust margin-left for the switch
          />
          <MdScreenshotMonitor
            className="cursor-pointer text-white text-xl ml-2 mr-4" // Adjust margin-left for the screenshot icon
            onClick={handleScreenshot}
            title="Take Screenshot"
          />
        </div>
      </div>
      <div className="flex items-center mb-2">
        <div className=" whitespace-nowrap">
          <span >Date <span className="ml-3.5">:</span></span>
          <span className="ml-2  whitespace-nowrap">{`${currentTime.getDate()}.${currentTime.getMonth() + 1}.${currentTime.getFullYear()}`}</span>
          </div>
        <div onClick={handleForgotpassword} className="text-blue-200  whitespace-nowrap cursor-pointer underline pt-2 mb-2 ml-8">
          Change Password
        </div>
      </div>
      <div className="flex items-center ">
        <div>
          <span>Time <span className="ml-3">:</span></span>
          <span style={{ width: "80px", display: "inline-block", textAlign: "left" }}className="ml-2 whitespace-nowrap">{currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</span> {/* Real-time time */}
        </div>
        <div onClick={handleLogout} className="text-red-400 cursor-pointer underline flex gap-x-4 ml-8 ">
          <BiLogOut className="text-2xl"/>
          Logout
        </div>
      </div>
    </div>
  ) : (
    <FaCircleUser className="w-10 rounded-md text-red-400" style={{ marginBottom: "8px" }} />
  )}
</div>




      </div>
    </div>
  );
};

export default Sidebar;
