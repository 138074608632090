import React from 'react';

const LabelComponent = ({
  label,
  value,
  labelwidth = "w-40",
  className = "",
  textColor = "text-customGray",
}) => {
  return (
    <label className={`flex items-center mb-6 ${className}`}>
      <span className={`${labelwidth} font-small text-xs ${textColor}`}>
        {label}
      </span>
      <span className={`ml-4 ${textColor}`}>:</span>
      <span className={`ml-4 text-xs ${textColor}`}>{value}</span>
    </label>
  );
};

export default LabelComponent;
