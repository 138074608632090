import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LanguageServices from "../../../../../rest-services/LanguagesServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";

export default function LanguageAdd() {
  const { languageId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view'; // Check if it's view mode

  const [languageCode, setLanguageCode] = useState("");
  const [languageName, setLanguageName] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (languageId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [languageId]);

  const validateForm = () => {
    const newErrors = {};
    if (!languageCode) newErrors.languageCode = "Language Code is required.";
    if (!languageName) newErrors.languageName = "Language Name is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const getDataForEdit = async () => {
    try {
      const response = await LanguageServices.getLanguageById(languageId);
      setLanguageCode(response.data.LE); // Adjust according to API response structure
      setLanguageName(response.data.Description); // Adjust according to API response structure
    } catch {
      setAlert({
        severity: "error",
        message: "Error fetching language data.",
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setAssignee({
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        });
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      id: languageId || null,
      LE: languageCode,
      Description: languageName,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
    };

    try {
      const response = await LanguageServices.addLanguage(`${process.env.REACT_APP_BASE_URL}/create-language`, data);
      if (response && !response.error) {
        toast("success", "Language added successfully");
        setLanguageCode(""); // Clear the form
        setLanguageName("");
      } else {
        toast("error", "Error adding language");
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error adding language: ${error.message}`,
      });
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'Language') {
      console.log("Navigating to /country");
      navigate('/language');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); 
    }
  };

  return (
    <div>
      <Header
        title={`Primary Data > Global Master Data > Language > ${languageId ? (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />

      <div className="flex flex-col space-y-12 col-span-1 p-10">
        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            LE <span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={languageCode}
            onChange={(e) => {
              setLanguageCode(e.target.value);
              if (errors.languageCode) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  languageCode: "",
                }));
              }
            }}
            className={`border ${errors.languageCode ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
            required
            disabled={isViewMode} // Disable input in view mode
          />
        </label>

        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Description<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={languageName}
            onChange={(e) => {
              setLanguageName(e.target.value);
              if (errors.languageName) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  languageName: "",
                }));
              }
            }}
            className={`border ${errors.languageName ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
            required
            disabled={isViewMode} // Disable input in view mode
          />
        </label>

        {/* Hide the Save button if in view mode */}
        {!isViewMode && (
          <button
            onClick={handleSubmit}
            className="mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
}
