import RestService from "./RestServices";

const CompanyServices = {
  getAllCompany: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching Company data:", error);
      return { error: true, message: "Error fetching Company data" };
    }
  },
  getCompanyById: (id) => {
    return RestService.GetByIdData(`/get-company`, id);
  },
  deleteCompany:(id)=>{
return RestService.DeleteData('/delete-company',id);
  },
  addCompany: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding Company:", error);
      return { error: true, message: "Error adding Company" };
    }
  },
  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },
};

export default CompanyServices;
