import React from 'react'
import Footer from '../../../../../../components/footer'

export default function ControlsContents() {
    return (
        <div className='p-4 mb-40'>
            <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">Minimum Balance</span>
              <span className="ml-3">:</span>
              <input
                type="number"  
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">Dormant</span>
              <span className="ml-3 ">:</span>
              <input
                type="number"  
                className="border p-1.5 rounded w-10 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              /><span className='ml-6 text-sm font-medium text-gray-500'>Months</span>
            </label>
            <label className="flex items-center mt-8 mb-4">
              <input
                type="checkbox"
                className="form-checkbox  text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
              />
              <span className="ml-4 text-sm font-semibold text-gray-600">Zero Balance Account</span>
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">ZBA Type</span>
              <span className="ml-3">:</span>
              <select
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">Main Bank Account</span>
              <span className="ml-3">:</span>
              <select
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <button
          className="bg-customBlue text-sm text-white py-1 px-2 mt-6 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 "
        >
          Next 
        </button>
            </div>
            <Footer>
        <button
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
        >
          Save
        </button>
        <button
          className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
        >
          Save & Continue
        </button>
        </Footer>
        </div>

    )
}
