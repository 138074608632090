import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminActions from '../../../../Admin/Components/AdminActions';
import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import UserService from '../../../../../../rest-services/UserServices';
import ReTable from '../../../../../../components/Table';
import XLSX from "xlsx-js-style";
import CustomeLoading from '../../../../../../components/CustomeLoading';

export default function CompanyUploadTableScreen() {
const navigate = useNavigate();   
const location = useLocation();
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

        const { iconId } = useParams();
        const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
    const [processedData, setProcessedData] = useState([]);
    const { tableData, displayOption, uploadOption  } = location.state || {}; // Retrieve tableData from state
  
    console.log("table data received from prop in Company is",tableData);
    console.log("displayOption data received from prop in Company is",displayOption);
    console.log("uploadOption data received from prop in Company is",uploadOption);
   
     const columns = [   
                      
                      { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1)*10, alignment: "left" },
                      { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -1)*10, alignment: "left" },
                      {
                                    Header: 'Update',
                                    accessor: (row) => {
                                      if (row.successfulrecords && uploadOption === '3') {
                                        return 'Yes';
                                      } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                                        return 'No';
                                      } else {
                                        return <span style={{ color: 'red' }}>No</span>;
                                      }
                                    },
                                    visible: true,
                                    width: ("Update".length -1)*10,
                                    alignment: 'left',
                                  },
                      { Header: 'CompanyCode', accessor: 'CompanyCode', visible: true, width: ("CompanyCode".length -1)*10, alignment: "left" },
                      { Header: 'CompanyName', accessor: 'CompanyName', visible: true, width: ("CompanyName".length -1)*10, alignment: "left" },
                      { Header: 'CompanyShortName', accessor: 'CompanyShortName', visible: true, width: ("CompanyShortName".length -1)*10, alignment: "left" },
                      { Header: 'LGECode', accessor: 'LGECode', visible: true, width: ("LGECode".length -1)*10, alignment: "left" },
                      { Header: 'BuGroupCode', accessor: 'BuGroupCode', visible: true, width: ("BuGroupCode".length -1)*10, alignment: "left" },
                      { Header: 'CurrencyCode', accessor: 'CurrencyCode', visible: true, width: ("CurrencyCode".length -1)*10, alignment: "left" },
                   { Header: 'CopyTheLGEAddress', accessor: 'CopyTheLGEAddress', visible: true, width: ("CopyTheLGEAddress".length -1)*10, alignment: "left" },
                   { Header: 'Door No', accessor: 'DoorNo', visible: true, width: ("Door No".length -1)*10, alignment: "left" },
                           { Header: 'Floor No', accessor: 'FloorNo', visible: true, width: ("Floor No".length -1)*10, alignment: "left" },
                           { Header: 'Building No', accessor: 'BuildingNo', visible: true, width: ("Building No".length -1)*10, alignment: "left" },
                           { Header: 'Building Name', accessor: 'BuildingName', visible: true, width: ("".length -1)*10, alignment: "left" },
                           { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1)*10, alignment: "left" },
                           { Header: 'Street No', accessor: 'StreetNo', visible: true, width: ("Street No".length -1)*10, alignment: "left" },
                           { Header: 'Street Name', accessor: 'StreetName', visible: true, width: ("Street Name".length -1)*10, alignment: "left" },
                           { Header: 'Road No', accessor: 'RoadNo', visible: true, width: ("Road No".length -1)*10, alignment: "left" },
                           { Header: 'Road Name', accessor: 'RoadName', visible: true, width: ("Road Name".length -1)*10, alignment: "left" },
                           { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1)*10, alignment: "left" },
                           // { Header: 'Area Name', accessor: 'Area Name', visible: true, width: ("".length -1)*10, alignment: "left" },
                           // { Header: 'streetName', accessor: 'streetName', visible: true, width: ("".length -1)*10, alignment: "left" },
                           { Header: 'areaName', accessor: 'areaName', visible: true, width: ("areaName".length -1)*10, alignment: "left" },
                           { Header: 'phase', accessor: 'phase', visible: true, width: ("phase".length -1)*10, alignment: "left" },
                           { Header: 'sector', accessor: 'sector', visible: true, width: ("sector".length -1)*10, alignment: "left" },
                           { Header: 'village', accessor: 'village', visible: true, width: ("village".length -1)*10, alignment: "left" },
                           { Header: 'town', accessor: 'town', visible: true, width: ("town".length -1)*10, alignment: "left" },
                           { Header: 'cityName', accessor: 'cityName', visible: true, width: ("cityName".length -1)*10, alignment: "left" },
                           // { Header: 'landmarkName', accessor: 'landmarkName', visible: true, width: ("".length -1)*10, alignment: "left" },
                           { Header: 'addressLine1', accessor: 'addressLine1', visible: true, width: ("addressLine1".length -1)*10, alignment: "left" },
                           { Header: 'addressLine2', accessor: 'addressLine2', visible: true, width: ("addressLine2".length -1)*10, alignment: "left" },
                           { Header: 'addressLine3', accessor: 'addressLine3', visible: true, width: ("addressLine3".length -1)*10, alignment: "left" },
                           { Header: 'countryId', accessor: 'countryId', visible: true, width: ("countryId".length -1)*10, alignment: "left" },
                           { Header: 'State/Province', accessor: 'StateProvince', visible: true, width: ("State/Province".length -1)*10, alignment: "left" },
                           { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1)*10, alignment: "left" },
                           { Header: 'Postal Code', accessor: 'PostalCode', visible: true, width: ("Postal Code".length -1)*10, alignment: "left" },
                           { Header: 'Zip Code', accessor: 'ZipCode', visible: true, width: ("Zip Code".length -1)*10, alignment: "left" },
                           { Header: 'PO Box', accessor: 'POBox', visible: true, width: ("PO Box".length -1)*10, alignment: "left" },
                          //  { Header: 'SSCurrencyCode', accessor: 'SSCurrencyCode', visible: true, width: ("".length -1)*10, alignment: "left" },
                          //  { Header: 'ChartOfAccountingCode', accessor: 'ChartOfAccountingCode', visible: true, width: ("".length -1)*10, alignment: "left" },
                          //  { Header: 'AccountingSystem', accessor: 'AccountingSystem', visible: true, width: ("".length -1)*10, alignment: "left" },
                          //  { Header: 'AccountingPeriodCode', accessor: 'AccountingPeriodCode', visible: true, width: ("".length -1)*10, alignment: "left" },
                           { Header: 'ContactPersonNumber', accessor: 'ContactPersonNumber', visible: true, width: ("ContactPersonNumber".length -1)*10, alignment: "left" },
                           { Header: 'MobileNumber', accessor: 'MobileNumber', visible: true, width: ("MobileNumber".length -1)*10, alignment: "left" },
                           { Header: 'Email', accessor: 'Email', visible: true, width: ("".length -1)*10, alignment: "left" },
                           { Header: 'Telephone', accessor: 'Telephone', visible: true, width: ("Telephone".length -1)*10, alignment: "left" },
                           { Header: 'FaxNo', accessor: 'FaxNo', visible: true, width: ("FaxNo".length -1)*10, alignment: "left" },
                          
                ];

                const handleDownload = () => {
                                          if (processedData.length === 0) {
                                            setAlert({
                                              severity: "warning",
                                              message: "No data available to export!",
                                            });
                                            return;
                                          }
                                        
                                          const extractText = (cellValue) => {
                                            if (Array.isArray(cellValue)) {
                                              return cellValue.join(", "); // Convert array to string
                                            } else if (typeof cellValue === "string") {
                                              return cellValue;
                                            } else if (React.isValidElement(cellValue)) {
                                              return cellValue.props.children;
                                            }
                                            return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                                          };
                                        
                                          const formattedUsers = processedData.map((user) => ({
                                            "Action": extractText(user.Action),
                                            "SLNo": extractText(user.SLNo),
                                            "CompanyCode": extractText(user.CompanyCode),
                                            "CompanyName": extractText(user.CompanyName),
                                            "CompanyShortName": extractText(user.CompanyShortName),
                                            "LGECode": extractText(user.LGECode),
                                            "BuGroupCode": extractText(user.BuGroupCode),
                                            "CurrencyCode": extractText(user.CurrencyCode),
                                            "CopyTheLGEAddress": extractText(user.CopyTheLGEAddress),
                                            "DoorNo": extractText(user.DoorNo),
                                            "FloorNo": extractText(user.FloorNo),
                                            "BuildingNo": extractText(user.BuildingNo),
                                            "BuildingName": extractText(user.BuildingName),
                                            "LandMark": extractText(user.LandMark),
                                            "StreetNo": extractText(user.StreetNo),
                                            "StreetName": extractText(user.StreetName),
                                            "RoadNo": extractText(user.RoadNo),
                                            "RoadName": extractText(user.RoadName),
                                            "Block": extractText(user.Block),
                                            "areaName": extractText(user.areaName),
                                            "phase": extractText(user.phase),
                                            "sector": extractText(user.sector),
                                            "village": extractText(user.village),
                                            "town": extractText(user.town),
                                            "cityName": extractText(user.cityName),
                                            "addressLine1": extractText(user.addressLine1 || ""),
                                            "addressLine2": extractText(user.addressLine2 || ""),
                                            "addressLine3": extractText(user.addressLine3 || ""),
                                            "countryId": extractText(user.countryId || ""),
                                            "StateProvince": extractText(user.StateProvince),
                                            "District": extractText(user.District),
                                            "PostalCode": extractText(user.PostalCode),
                                            "ZipCode": extractText(user.ZipCode),
                                            "POBox": extractText(user.POBox),
                                            "ContactPersonNumber": extractText(user.ContactPersonNumber),
                                            "MobileNumber": extractText(user.MobileNumber),
                                            "Email": extractText(user.Email),
                                            "Telephone": extractText(user.Telephone),
                                            "FaxNo": extractText(user.FaxNo),
                                            }));
                                        
                                  
                                    // Add second and third row
                                    const secondRow = {
                                      "Action": "R","SLNo": "R","CompanyCode":"R","CompanyName": "R","CompanyShortName":"R", "LGECode": "R","BuGroupCode": "R",  "CurrencyCode": "R", 
                                      "CopyTheLGEAddress":"Check box","DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
                                      "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "areaName": "", "phase":"","sector": "", "village":"", "town": "", "cityName": "",  "addressLine1": "", "addressLine2": "", "addressLine3": "", 
                                      "countryId": "R", "StateProvince": "Conditional", "District": "", "PostalCode": "", "ZipCode": "", "POBox": "","ContactPersonNumber":"","MobileNumber":"","Email":"","Telephone":"","FaxNo":"",
                                    };
                                  
                                    const thirdRow = {
                                       "Action": "1","SLNo": "5", "CompanyCode":"10","CompanyName": "150","CompanyShortName":"15", "LGECode": "10","BuGroupCode": "10",  "CurrencyCode": "3", 
                                       "CopyTheLGEAddress":"","DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
                                      "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "areaName": "", "phase":"","sector": "", "village":"", "town": "", "cityName": "",  "addressLine1": "50", "addressLine2": "50", "addressLine3": "50", 
                                      "countryId": "2", "StateProvince": "3", "District": "", "PostalCode": "", "ZipCode": "","POBox": "","ContactPersonNumber":"","MobileNumber":"","Email":"","Telephone":"","FaxNo":"",
                                    };
                                  
                                      // Insert second and third row at the beginning of the formatted data
                                      const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
                                  
                                          const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
                                        console.log("rowsToDownload data is",rowsToDownload);
                                   
                                          // Define border style
                                    const border = {
                                      top: { style: "thin", color: { rgb: "000000" } },
                                      right: { style: "thin", color: { rgb: "000000" } },
                                      bottom: { style: "thin", color: { rgb: "000000" } },
                                      left: { style: "thin", color: { rgb: "000000" } },
                                    };
                                  
                                        // Apply borders to the first three rows
                                        rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
                                          Object.keys(row).forEach((col, colIdx) => {
                                            const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
                                            if (worksheet[cellAddress]) {
                                              worksheet[cellAddress].s = { border };
                                            }
                                          });
                                        });
                                  
                                        
                                         // Additional cell styling for validation errors
                                          Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
                                            rowsToDownload.forEach((row, rowIdx) => {
                                              const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
                                              const cellValue = row[col];
                                        
                                              if (
                                                typeof cellValue === "string" && (
                                                  cellValue.includes("Shouldn't be blank") 
                                                  // ||
                                                  // cellValue.includes("Mismatched Data Type") ||
                                                  // cellValue.includes("Max. allowed character")
                                                )
                                              ) {
                                                worksheet[cellAddress] = {
                                                  v: cellValue,
                                                  s: { font: { color: { rgb: "FF0000" } } },
                                                };
                                              } else if (Array.isArray(row[col])) {
                                                worksheet[cellAddress] = {
                                                  v: extractText(row[col]),
                                                  s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
                                                };
                                              }
                                              else {
                                                worksheet[cellAddress] = { v: cellValue };
                                              }
                                            });
                                          });
                                        
                                          // AutoFit column width
                                          worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
                                            const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
                                            return { wch: maxLength + 2 }; // Add extra space
                                          });
                                  
                                  
                                          const workbook = XLSX.utils.book_new();
                                          XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
                                          XLSX.writeFile(workbook, "Company_Upload.xlsx");
                                        };
                

       const [columnSettings, setColumnSettings] = useState(columns);       
                  
          const modifiedColumns = columnSettings
          .filter((col) => col.visible)
          .map((col) => ({
            ...col,
            width: col.width, // This should hold the updated width
          }));


  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Company") {
      navigate("/2/master-data/9/company");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  const isRowValid = (row, index) => {
    // Common validations
    const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
    const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
    const isCompanyCodeValid = row.CompanyCode && String(row.CompanyCode).length <= 10;

    const isAddress1Valid = row.addressLine1 ? String(row.addressLine1).length <= 50 : true;
  const isAddress2Valid = row.addressLine2 ? String(row.addressLine2).length <= 50 : true;
  const isAddress3Valid = row.addressLine3 ? String(row.addressLine3).length <= 50 : true;
  const isContactPersonNoValid = row.ContactPersonNumber ? String(row.ContactPersonNumber).length <= 50 : true;
  const isMobileNumberValid = row.MobileNumber ? String(row.MobileNumber).length <= 13 : true;
  const isEmailValid = row.Email ? String(row.Email).length <= 80 : true;
  const isTelephoneValid = row.Telephone ? String(row.Telephone).length <= 13 : true;
  const isFaxNoValid = row.FaxNo ? String(row.FaxNo).length <= 13 : true;

  
    let errors = [];

    if (!isActionValid) errors.push("Invalid Action");
    if (!isSLNoValid) errors.push("Invalid SLNo");
    if (!isCompanyCodeValid) errors.push("Invalid CompanyCode");
    if(!isContactPersonNoValid) errors.push("Invalid ContactPersonNumber");
        if(!isMobileNumberValid) errors.push("Invalid MobileNumber");
        if(!isEmailValid) errors.push("Invalid Email");
        if(!isTelephoneValid) errors.push("Invalid Telephone");
        if(!isFaxNoValid) errors.push("Invalid FaxNo");

    
    if (!isAddress1Valid) errors.push("Invalid Address1 exceeds 50 characters");
  if (!isAddress2Valid) errors.push("Invalid Address2 exceeds 50 characters");
  if (!isAddress3Valid) errors.push("Invalid Address3 exceeds 50 characters");
  

    // Action "A" or "a" specific validations
    if (row.Action === "A" || row.Action === "a") {
        const isCompanyNameValid = row.CompanyName && String(row.CompanyName).length <= 150;
        const isCompanyShortNameValid = row.CompanyShortName && String(row.CompanyShortName).length <= 15;
        const isLGECodeValid = row.LGECode && String(row.LGECode).length <= 10;
        const isBuGroupCodeValid = row.BuGroupCode && String(row.BuGroupCode).length <= 10;
        const isCurrencyCodeValid = row.CurrencyCode && String(row.CurrencyCode).length <= 4;
        
        if(!row.CopyTheLGEAddress || String(row.CopyTheLGEAddress).trim() === ""){
          const hasRequiredInfo = !!(row.DoorNo || row.FloorNo || row.BuildingNo || row.BuildingName || 
            row.LandMark || row.StreetNo || row.StreetName || row.RoadNo || row.RoadName || row.Block ||
            row.areaName || row.phase || row.sector || row.village || row.town || row.cityName || 
            row.addressLine1 || row.addressLine2 || row.addressLine3 );
            
            const isCountryIdValid = row.countryId && String(row.countryId).length <= 3;
            if (!hasRequiredInfo) {
              errors.push("At least one of DoorNo, BuildingNo, BuildingName, or Address1 is required");
            }
            if (!isCountryIdValid) errors.push("Invalid CountryId");
            return (
              hasRequiredInfo &&
              isCountryIdValid
            );
        }
        
        // const isContactPersonNoValid = row.ContactPersonNumber ? String(row.ContactPersonNumber).length <= 50 : true;
        // const isMobileNumberValid = row.MobileNumber ? String(row.MobileNumber).length <= 13 : true;
        // const isEmailValid = row.Email ? String(row.Email).length <= 80 : true;
        // const isTelephoneValid = row.Telephone ? String(row.Telephone).length <= 13 : true;
        // const isFaxNoValid = row.FaxNo ? String(row.FaxNo).length <= 13 : true;

        if (!isCompanyNameValid) errors.push("Invalid CompanyName");
        if (!isCompanyShortNameValid) errors.push("Invalid isCompanyShortNameValid");
        if(!isLGECodeValid) errors.push("Invalid isLGECodeValid");
        if(!isBuGroupCodeValid) errors.push("Invalid isBuGroupCodeValid");
        if (!isCurrencyCodeValid) errors.push("Invalid CurrencyCode");
        // if(!isContactPersonNoValid) errors.push("Invalid ContactPersonNumber");
        // if(!isMobileNumberValid) errors.push("Invalid MobileNumber");
        // if(!isEmailValid) errors.push("Invalid Email");
        // if(!isTelephoneValid) errors.push("Invalid Telephone");
        // if(!isFaxNoValid) errors.push("Invalid FaxNo");

        if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }

        return (
            // isActionValid &&
            // isSLNoValid &&
            // isCompanyCodeValid &&
            isCompanyNameValid &&
            isCompanyShortNameValid &&
            isLGECodeValid &&
            isBuGroupCodeValid &&
            isCurrencyCodeValid
            
        );
    }

    // Action "E" or "e" specific validations
    if (row.Action === "E" || row.Action === "e") {
        const isCompanyNameValid = row.CompanyName ? String(row.CompanyName).length <= 150 : true;
        const isCompanyShortNameValid = row.CompanyShortName ? String(row.CompanyShortName).length <= 15 : true;
        const isLGECodeValid = row.LGECode ? String(row.LGECode).length <= 10 : true;
        const isBuGroupCodeValid = row.BuGroupCode ? String(row.BuGroupCode).length <= 10 : true;
        const isCurrencyCodeValid = row.CurrencyCode ? String(row.CurrencyCode).length <= 3 : true;
        const isCountryIdValid = row.countryId ? String(row.countryId).length <= 2 : true;

        // const isContactPersonNoValid = row.ContactPersonNumber ? String(row.ContactPersonNumber).length <= 50 : true;
        // const isMobileNumberValid = row.MobileNumber ? String(row.MobileNumber).length <= 13 : true;
        // const isEmailValid = row.Email ? String(row.Email).length <= 80 : true;
        // const isTelephoneValid = row.Telephone ? String(row.Telephone).length <= 13 : true;
        // const isFaxNoValid = row.FaxNo ? String(row.FaxNo).length <= 13 : true;

        if (!isCompanyNameValid) errors.push("Invalid CompanyName");
        if (!isCompanyShortNameValid) errors.push("Invalid isCompanyShortNameValid");
        if(!isLGECodeValid) errors.push("Invalid isLGECodeValid");
        if(!isBuGroupCodeValid) errors.push("Invalid isBuGroupCodeValid");
        if (!isCurrencyCodeValid) errors.push("Invalid CurrencyCode");
        if (!isCountryIdValid) errors.push("Invalid CountryId");
        // if(!isContactPersonNoValid) errors.push("Invalid ContactPersonNumber");
        // if(!isMobileNumberValid) errors.push("Invalid MobileNumber");
        // if(!isEmailValid) errors.push("Invalid Email");
        // if(!isTelephoneValid) errors.push("Invalid Telephone");
        // if(!isFaxNoValid) errors.push("Invalid FaxNo");

        if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }

        return (
            // isActionValid &&
            // isSLNoValid &&
            // isCompanyCodeValid &&
            isCompanyShortNameValid &&
            isBuGroupCodeValid &&
            isLGECodeValid &&
            isCompanyNameValid &&
            isCurrencyCodeValid &&
            isCountryIdValid
            // isContactPersonNoValid &&
            // isMobileNumberValid &&
            // isEmailValid &&
            // isTelephoneValid &&
            // isFaxNoValid
        );
    }

    if (errors.length > 0) {
        console.log(`Row ${index + 1} is invalid:`, errors);
    }

    return (isActionValid && 
    isSLNoValid && 
    isCompanyCodeValid &&
    isAddress1Valid &&
    isAddress2Valid &&
    isAddress3Valid &&
    isContactPersonNoValid &&
    isMobileNumberValid &&
    isEmailValid &&
    isTelephoneValid &&
    isFaxNoValid
    );
};

  const filterExistingUserIds = async (ExistCurrencyIdValidRows, existingUserIds) => {
    return ExistCurrencyIdValidRows.filter((row) => existingUserIds.includes(row.CompanyCode));
  };

  const filterExistingCountryIds = async (ExistCurrencyIdValidRows, existingCountryIds) => {
    return ExistCurrencyIdValidRows.filter((row) => existingCountryIds.includes(row.countryId));
  };

  const filterExistingstateIds =  async (ExistCountryIdValidRows, existingstateIds) => {
    return ExistCountryIdValidRows.filter((row) => existingstateIds.includes(row.StateProvince));
  };


  const filterExistingCurrencyCodes = async (withoutDuplicateBUGroupIDs, existingCurrencyIds) => {
    return withoutDuplicateBUGroupIDs.filter((row) => existingCurrencyIds.includes(row.CurrencyCode));
  };

  const filterExistingLGEIDs = async (validRows, existingLGEIDs) => {
    return validRows.filter((row) => existingLGEIDs.includes(row.LGECode));
  };
  const filterExistingBuGroupIDs = async (withoutDuplicateLGEIDs, existingBuGroupIds) => {
    // const existingBuGroupSet = new Set(existingBuGroupIds.map(item => item.BU_Group));
    return withoutDuplicateLGEIDs.filter((row) => existingBuGroupIds.includes(row.BuGroupCode));
  //   return tableData.filter((row) => !existingBuGroupSet.has(row.BuGroup));
  };

  const getExistingUserIds = async (tableData) => {
    try {
      console.log("userIds", tableData);
  
      // Make API call to fetch existing user IDs

      const validRows = tableData.filter((row, index) => isRowValid(row, index));
  console.log("valid rows checking in upload:", validRows);
     
  //  LE valid data get
  const response1 = await UserService.saveContactDetails(
    `${process.env.REACT_APP_BASE_URL}/get-LEGreen-duplicate/LEId`,
    { validRows}
  );
  console.log("errored records user id's : Response data", response1.data);
  const existingLGEIDs = response1.data; // Extract existing user IDs from API response

  // Remove duplicates based on API response
  const withoutDuplicateLGEIDs = await filterExistingLGEIDs(validRows, existingLGEIDs);


  //  BU group valid data get
  const response2 = await UserService.saveContactDetails(
    `${process.env.REACT_APP_BASE_URL}/get-BuGroupGreen-duplicate/BugroupId`,
    { validRows: withoutDuplicateLGEIDs }
  );
  console.log("errored records user id's : Response data", response2.data);
  const existingBuGroupIds = response2.data; // Extract existing user IDs from API response

  // Remove duplicates based on API response
  const withoutDuplicateBUGroupIDs = await filterExistingBuGroupIDs(withoutDuplicateLGEIDs, existingBuGroupIds);


   // Currency Code valid data get
   const response3 = await UserService.saveContactDetails(
    `${process.env.REACT_APP_BASE_URL}/get-currencyCode-exists-only`,
    { validRows:withoutDuplicateBUGroupIDs }
  );
  console.log("check currency code is exists", response3.data);
  const existingCurrencyIds = response3.data; // Extract existing user IDs from API response
 
  const ExistCurrencyIdValidRows = await filterExistingCurrencyCodes(withoutDuplicateBUGroupIDs, existingCurrencyIds);

  console.log("successful and row valid in currency filters 1", ExistCurrencyIdValidRows);


  
// Country Code valid data get
const response4 = await UserService.saveContactDetails(
`${process.env.REACT_APP_BASE_URL}/get-CountryID2-exists-only`,
{ validRows:ExistCurrencyIdValidRows }
);
console.log("check Country code is exists", response4.data);
const existingCountryIds = response4.data; // Extract existing user IDs from API response

const ExistCountryIdValidRows = await filterExistingCountryIds(ExistCurrencyIdValidRows, existingCountryIds);

console.log("successful and row valid in countryID filters 1", ExistCountryIdValidRows);



// State Code valid data get
const response5 = await UserService.saveContactDetails(
`${process.env.REACT_APP_BASE_URL}/get-StateID-exists-only`,
{ validRows:ExistCountryIdValidRows }
);
console.log("check state code is exists 1", response5.data);
const existingstateIds = response5.data; 

const ExiststateIdValidRows = await filterExistingstateIds(ExistCountryIdValidRows, existingstateIds);

console.log("successful and row valid in stateID filters 1", ExiststateIdValidRows);




      // Company Code valid data get
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-company-duplicate/companyId`,
        { validRows: ExiststateIdValidRows }
      );
      console.log("errored records user id's : Response data", response.data);
      const existingUserIds = response.data; // Extract existing user IDs from API response

      // Remove duplicates based on API response
      const withoutDuplicateUserIds = await filterExistingUserIds(ExiststateIdValidRows, existingUserIds);
    
      console.log("successfuland processed Records data:", withoutDuplicateUserIds);
  
      return withoutDuplicateUserIds;
  
    } catch (error) {
      console.error("Error fetching existing user IDs:", error);
      return [];
    }
  };

  const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
    let dataToDisplay;
    if (displayOption === "1") {
      console.log("Display option is 1");
      const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"
      console.log("existing users data is",existingUsers);

      const existingUserIds = new Set(existingUsers.map(user => user.CompanyCode));

      // Filter out the currentDataToDisplay row from tableData to get other 6 rows
      dataToDisplay = tableData.filter(user => !existingUserIds.has(user.CompanyCode));
    
       console.log("Filtered remaining data excluding currentDataToDisplay:", dataToDisplay);
  
    } else if (displayOption === "2") {
      console.log("Display option is 2");
      dataToDisplay = tableData; // Display tableData if displayOption is "2"
    } else if (uploadOption === "3") {
      console.log("upload option is 3");
      dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"

      // dataToDisplay = successfulRecords; // Display successfulRecords if uploadOption is "3"
      // dataToDisplay = await fetchAndFilterSuccessfulRecords(tableData); // Successful records for uploadOption "3"
  
    } else {
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
  
  // console.log("dataToDisplay data is",dataToDisplay);
  return dataToDisplay;
  };

  (async () => {
    const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
    
    if (!Array.isArray(dataToDisplay)) {
      console.error("getDataToDisplay did not return an array:", dataToDisplay);
      return;
    }
  
    console.log("Final dataToDisplay data is", dataToDisplay);
  
    // Call processData only when dataToDisplay is defined and valid
    await processData(dataToDisplay);
  
  })();
  
const isExistingUserId = async (CompanyCode) => {
  try {
    console.log("exisitng CompanyCode id",CompanyCode);
    const data = { CompanyCode };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-CompanyCode-duplicates`,
      data);

    console.log("existingUserId details:", response);

    return response && response.CompanyCode !== null; // Return true if userId exists, false otherwise
  } catch (error) {
    console.error("Error checking existing userId:", CompanyCode, error);
    return false;
  }
};


const isExistingStateId = async (StateProvince) =>{
  try {
      console.log("exisitng user id",StateProvince);
      const data = { StateCode:StateProvince };
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-StateCode-duplicates`,
        data);
      console.log("existing state details:", response);
  
      // if (response?.CountryID !== undefined) {
      //   return response.CountryID; // Return the CountryID if found
      // }
      // return false; // Return false if CountryID is null
      return response?.StateID ? response : false; 
    } catch (error) {
      console.error("Error checking existing userId:", StateProvince, error);
      return false;
    }
  };

const isExistingCountryId = async (countryId) =>{
  try {
      console.log("exisitng user id",countryId);
      const data = { CountryCode:countryId };
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-CountryCode-duplicates`,
        data);
      console.log("existingUserId details:", response);
  
      // if (response?.CountryID !== undefined) {
      //   return response.CountryID; // Return the CountryID if found
      // }
      // return false; // Return false if CountryID is null
      return response?.CountryID ? response : false; 
    } catch (error) {
      console.error("Error checking existing userId:", countryId, error);
      return false;
    }
  };
const isExistingCurrencyId = async (CurrencyCode) => {
  try {
    console.log("exisitng user id",CurrencyCode);
    const data = { CurrencyCode };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-CurrencyCode-duplicates`,
      data);
    console.log("existingUserId details:", response);

    // return response && response.UserID !== null; // Return true if userId exists, false otherwise
    return response?.CurrencyID ? response : false; // Return response only if UserID exists
  } catch (error) {
    console.error("Error checking existing userId:", CurrencyCode, error);
    return false;
  }
};
const isExistingGreenBUId = async (BuGroupCode) => {
  try {
    console.log("exisitng user id",BuGroupCode);
    const data = { BUGroupId: BuGroupCode };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BuGroupGreenID-duplicates`,
      data);
    console.log("existingUserId details:", response);
    console.log("existingUserId details:", response.BUGroupData);

    // return response && response.BUGroupData !== null; // Return true if BUGroup exists, false otherwise
    return response?.BUGroupData ? response : false;
  } catch (error) {
    console.error("Error checking existing BUGroup:", BuGroupCode, error);
    return false;
  }
};

const isExistingBUId = async (BuGroupCode) => {
  try {
    console.log("exisitng user id",BuGroupCode);
    const data = { BUGroupId: BuGroupCode };

    const response = await UserService.saveContactDetails(
      // `${process.env.REACT_APP_BASE_URL}/get-BuGroupGreenID-duplicates`,
      `${process.env.REACT_APP_BASE_URL}/get-BuGroup-duplicates`,
      data);
    console.log("existingUserId details:", response);
    console.log("existingUserId details:", response.BUGroupData);

    // return response && response.BUGroupData !== null; // Return true if BUGroup exists, false otherwise
    return response?.BUGroupData ? response : false;
  } catch (error) {
    console.error("Error checking existing BUGroup:", BuGroupCode, error);
    return false;
  }
};


const isExistingLGEGreenId = async (LGECode) => {
  try {
    console.log("exisitng user id",LGECode);
    const data = { LGECode };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-LGEGreenCode-duplicates`,
      data);

    console.log("existingUserId details:", response);

    // return response && response.LGECode !== null; // Return true if userId exists, false otherwise
    return response?.LGECode ? response : false; 
  } catch (error) {
    console.error("Error checking existing userId:", LGECode, error);
    return false;
  }
};

const isExistingLGEId = async (LGECode) => {
  try {
    console.log("exisitng user id",LGECode);
    const data = { LGECode };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-LGECode-duplicates`,
      // `${process.env.REACT_APP_BASE_URL}/get-LGEGreenCode-duplicates`,
      data);

    console.log("existingUserId details:", response);

    // return response && response.LGECode !== null; // Return true if userId exists, false otherwise
    return response?.LGECode ? response : false; 
  } catch (error) {
    console.error("Error checking existing userId:", LGECode, error);
    return false;
  }
};

async function checkDuplicateUserIdsFromDB(CompanyCodes) {
  try {
    const data = { CompanyCodes };
    console.log("CompanyCodes duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-CompanyCodes-duplicates`,
      data
    );

    // console.log("API Response for response details:", response);
    console.log("existingCompanyId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}
const validateEmail = (Email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for basic email validation
  return emailRegex.test(Email);
};

const processData = async (data) => {
  // Extract all userIds from dataToDisplay
  
  if (!Array.isArray(data)) {
    console.error("processData received invalid data:", data);
    return []; // Return an empty array to prevent further errors
  }
  console.log("processData received  data:", data);

  const CompanyCodes = data.map(row => row.CompanyCode).filter(Boolean);
  console.log("Extracted CompanyCode's:", CompanyCodes);

  const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(CompanyCodes);


   // Preprocess table data to add conditional rendering for userId and username
  //  return data.map((row, _, allRows) => {
    return Promise.all(data.map(async (row, _, allRows) => {
    // const rowInvalid = isRowInvalid(row);
    // Define the max allowed characters for each column (this can come from your column definitions)
  
      // Check for duplicate userId in dataToDisplay
  const isDuplicateUserId = allRows.filter(r => r.CompanyCode == row.CompanyCode).length > 1;
console.log("Duplicate CompanyCode id is ",isDuplicateUserId);

 // Check for duplicate userId in the database
 const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.CompanyCode);
console.log("Is duplicate in db",isDuplicateInDB);

const userExists = await isExistingUserId(row.CompanyCode);
const LGEExists = await isExistingLGEId(row.LGECode);
const LGEGreenExists = await isExistingLGEGreenId(row.LGECode);
const BUExists = await isExistingBUId(row.BuGroupCode);
const BUGreenExists = await isExistingGreenBUId(row.BuGroupCode);
const CurrencyExists = await isExistingCurrencyId(row.CurrencyCode);
const countryIDExists = await isExistingCountryId(row.countryId);
const StateIDExists = await isExistingStateId(row.StateProvince);

console.log(`CompanyCode ${row.CompanyCode} exists:`, userExists);
console.log(`lge code ${row.LGECode} exists:`, LGEExists);
console.log(`lge green code ${row.LGECode} exists:`, LGEGreenExists);
console.log(`bugroup ${row.BuGroupCode} exists:`,BUExists)
console.log(`bugroup green code ${row.BuGroupCode} exists:`,BUGreenExists)
console.log(`Currency code ${row.CurrencyCode} exists:`, CurrencyExists);
console.log(`Country ID is ${row.countryId} exists:`, countryIDExists);
 console.log(`state ID is ${row.StateProvince} exists:`, StateIDExists);

 // Check for errors (red text) and apply highlight based on that

const hasNotError = [
  row.SLNo,
  row.Action,
  row.CompanyCode,
  row.CompanyName,
  row.CompanyShortName,
  row.LGECode,
  row.BuGroupCode,
  row.CurrencyCode,
  // row.addressLine1,
  // row.addressLine2,
  // row.addressLine3,
  row.countryId,
  row.StateProvince,
  row.ContactPersonNumber,
  row.MobileNumber,
  row.Email,
  row.Telephone,
  row.FaxNo

].every(field => {
  let isValid = true;
  let reason = "";
  
  if (field === row.SLNo) {
    if (!field) { isValid = false; reason = "SLNo should not be blank."; }
    else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
    else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
  }
  
  else if (field === row.Action) {
    if (!field) { isValid = false; reason = "Action should not be blank."; }
    else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
  }
  
  
  else if (field === row.CompanyCode) {
    if (!field) { isValid = false; reason = "CompanyCode ID should not be blank."; }
    else if (String(field).length > 10) { isValid = false; reason = "CompanyCode ID exceeds max length of 10."; }
    else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
      isValid = false; reason = "CompanyCode ID is duplicate.";
    }else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
      isValid = false; reason = "CompanyCode ID does not exist.";
    }
  }

  else if (field === row.CompanyName) {
    if (row.Action === "E" || row.Action === "e") {
      if (!field) { isValid = true; reason = "CompanyName should be blank for Edit action."; }
      else if (String(field).length > 150) { isValid = false; reason = "CompanyName exceeds max length of 150."; }
    } else if (row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = false; reason = "CompanyName should not be blank for Add action."; }
      if (String(field).length > 150) { isValid = false; reason = "CompanyName exceeds max length of 150."; }
    }
  }

  else if (field === row.CompanyShortName) {
    if (row.Action === "E" || row.Action === "e") {
      if (!field) { isValid = true; reason = "CompanyShortName should be blank for Edit action."; }
      else if (String(field).length > 15) { isValid = false; reason = "CompanyShortName exceeds max length of 15."; }
    } else if (row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = false; reason = "CompanyShortName should not be blank for Add action."; }
      if (String(field).length > 15) { isValid = false; reason = "CompanyShortName exceeds max length of 15."; }
    }
  }


// else if (field === row.LGECode) {
//   if (!field) { isValid = false; reason = "LGECode ID should not be blank."; }
//   else if (String(field).length > 10) { isValid = false; reason = "LGECode ID exceeds max length of 10."; }
//   else if (row.Action === "A" || row.Action === "a") {
//     isValid = false; reason = "LGECode ID is duplicate.";
//   }else if (row.Action === "E" || row.Action === "e") {
//     isValid = false; reason = "LGECode ID does not exist.";
//   }
// }
// else if (field === row.LGECode) {
//   if (row.Action === "E" || row.Action === "e") {
//     if (!field) { isValid = true; reason = "LGECode should be blank for Edit action."; }
//     else if (String(field).length > 10) { isValid = false; reason = "LGECode exceeds max length of 10."; }
//   } else if (row.Action === "A" || row.Action === "a") {
//     if (!field) { isValid = false; reason = "LGECode should not be blank for Add action."; }
//     if (String(field).length > 10) { isValid = false; reason = "LGECode exceeds max length of 10."; }
//   }
// }

if (!row.LGECode || String(row.LGECode).trim() === "") {
  if (row.Action === "A" || row.Action === "a") {
    isValid = false;
    reason = "LGECode should not be empty.";
}
} else if (field === row.LGECode) {
  if(LGEExists){
    if(LGEGreenExists){
      isValid = true;
      reason = "LGE Code is exists";
    }
    else{
      isValid = false;
      reason = "LGE Code is not exists because of the flag status"
    }
    
  }
  else{
    isValid = false;
    reason = "LGE Code is not exists";
  }

    if (row.Action === "E" || row.Action === "e") {
        if (!field.trim()) {
            isValid = true;
            reason = "LGECode should be blank for Edit action.";
        } else if (String(field).trim().length > 10) {
            isValid = false;
            reason = "LGECode exceeds max length of 10.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (!field.trim()) {
            isValid = false;
            reason = "LGECode should not be blank for Add action.";
        } else if (String(field).trim().length > 10) {
            isValid = false;
            reason = "LGECode exceeds max length of 10.";
        }
    }
}

// else if (field === row.BuGroupCode) {
//   if (!field) { isValid = false; reason = "BuGroupCode ID should not be blank."; }
//   else if (String(field).length > 10) { isValid = false; reason = "BuGroupCode ID exceeds max length of 10."; }
//   else if (row.Action === "A" || row.Action === "a") {
//     isValid = false; reason = "BuGroupCode ID is duplicate.";
//   }else if (row.Action === "E" || row.Action === "e") {
//     isValid = false; reason = "BuGroupCode ID does not exist.";
//   }
// }


if (!row.BuGroupCode || String(row.BuGroupCode).trim() === "") {
  if (row.Action === "A" || row.Action === "a") {
    isValid = false;
    reason = "BuGroupCode should not be empty.";
}
} else if (field === row.BuGroupCode) {
  if(BUExists){
    if(BUGreenExists){
      isValid = true;
      reason = "BuGroup Code is exists";
    }
    else{
      isValid = false;
      reason = "BuGroup Code is not exists because of the flag status";
    }
  }
  else{
    isValid = false;
    reason = "BuGroup Code is not exists";
  }

    if (row.Action === "E" || row.Action === "e") {
        if (!field.trim()) {
            isValid = true;
            reason = "BuGroupCode should be blank for Edit action.";
        } else if (String(field).trim().length > 10) {
            isValid = false;
            reason = "BuGroupCode exceeds max length of 10.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (!field.trim()) {
            isValid = false;
            reason = "BuGroupCode should not be blank for Add action.";
        } else if (String(field).trim().length > 10) {
            isValid = false;
            reason = "BuGroupCode exceeds max length of 10.";
        }
    }
}


// if (field === row.BuGroupCode) {
//   if (row.Action === "E" || row.Action === "e") {
//     if (!field) { isValid = true; reason = "BuGroupCode should be blank for Edit action."; }
//     else if (String(field).length > 10) { isValid = false; reason = "BuGroupCode exceeds max length of 10."; }
//   } else if (row.Action === "A" || row.Action === "a") {
//     if (!field) { isValid = false; reason = "BuGroupCode should not be blank for Add action."; }
//     if (String(field).length > 10) { isValid = false; reason = "BuGroupCode exceeds max length of 10."; }
//   }
// }

if (!row.CurrencyCode || String(row.CurrencyCode).trim() === "") {
  if (row.Action === "A" || row.Action === "a") {
    isValid = false;
    reason = "CurrencyCode should not be empty.";
}
} else if (field === row.CurrencyCode) {
  if(CurrencyExists){
      isValid = true;
      reason = "Currency Code is exists";
  }
  else{
    isValid = false;
    reason = "Currency Code is not exists";
  }

    if (row.Action === "E" || row.Action === "e") {
        if (!field.trim()) {
            isValid = true;
            reason = "CurrencyCode should be blank for Edit action.";
        } else if (String(field).trim().length > 3) {
            isValid = false;
            reason = "CurrencyCode exceeds max length of 3.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (!field.trim()) {
            isValid = false;
            reason = "CurrencyCode should not be blank for Add action.";
        } else if (String(field).trim().length > 3) {
            isValid = false;
            reason = "CurrencyCode exceeds max length of 3.";
        }
    }
}

// else if (field === row.CurrencyCode) {
//   if (row.Action === "E" || row.Action === "e") {
//     if (!field) { isValid = true; reason = "CurrencyCode should be blank for Edit action."; }
//     else if (String(field).length > 3) { isValid = false; reason = "CurrencyCode exceeds max length of 3."; }

//   } else if (row.Action === "A" || row.Action === "a") {
//     if (!field) { isValid = false; reason = "CurrencyCode should not be blank for Add action."; }
//     if (String(field).length > 3) { isValid = false; reason = "CurrencyCode exceeds max length of 3."; }    
//   }

// }

if(!row.CopyTheLGEAddress || String(row.CopyTheLGEAddress).trim() === ""){
console.log("enter in the copy adddress condition");


if (row.addressLine1 && String(row.addressLine1).length > 50) {
isValid = false;
reason = "addressLine1 exceeds max length of 50.";
} else {
const missingFields = [
    row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
    row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
    row.areaName, row.phase, row.sector, row.village, row.town,
    row.cityName, row.addressLine1, row.addressLine2, row.addressLine3
].every(value => !value); // Check if all values are falsy

if (row.Action === "E" || row.Action === "e") {
    if (!field) {
        isValid = true;
        reason = "addressLine1 should be blank for Edit action.";
    }
    else if (field.length > 50) { 
      isValid = false; 
      reason = "addressLine1 exceeds max length of 50."; 
    }
} else if (row.Action === "A" || row.Action === "a") {
    console.log("Address field 1 enter log");

    if (missingFields) {
        isValid = false;
        reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and addressLine1 should not be blank for Add action.";
    }
}
}

if (field === row.addressLine2) {
if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  if (!field) { isValid = true; reason = "addressLine2 should be blank for Add/Edit action."; }
}
if (String(field).length > 50) { isValid = false; reason = "addressLine2 exceeds max length of 50."; }
}

if (field === row.addressLine3) {
if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  if (!field) { isValid = true; reason = "addressLine3 should be blank for Add/Edit action."; }
}
if (String(field).length > 50) { isValid = false; reason = "addressLine3 exceeds max length of 50."; }
}

}

else{
console.log("copy address is not entered");
}

// if (field === row.countryId) {
//   console.log("Entered in the country has error")
//   if (row.Action === "E" || row.Action === "e") {
//     console.log("Entered in country filed has E");
//     if (!field) { isValid = true; reason = "countryId should be blank for Edit action."; }
//     else if (String(field).length > 2) {
//       isValid = false;
//       reason = "countryId exceeds max length of 2.";
//     }
//     // if (String(field).length > 2) { isValid = false; reason = "countryId exceeds max length of 2."; }
//   } else if (row.Action === "A" || row.Action === "a") {
//     console.log("Entered in country filed has A");
//     if (!field) { isValid = false; reason = "countryId should not be blank for Add action."; }
//     if (String(field).length > 2) { isValid = false; reason = "countryId exceeds max length of 2."; }
// }
//   // console.log("Entered in country filed without action");
// }




   if (!row.countryId || String(row.countryId).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "countryId should not be empty.";
  }
  } else if (field === row.countryId) {
    if(countryIDExists){
      isValid = true;
      reason = "country Code is exists";
    }
    else{
      isValid = false;
      reason = "country Code is not exists";
    }
  
      if (row.Action === "E" || row.Action === "e") {
          if (!field.trim()) {
              isValid = true;
              reason = "CountryId should be blank for Edit action.";
          } else if (String(field).trim().length > 2) {
              isValid = false;
              reason = "CountryId exceeds max length of 2.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!field.trim()) {
              isValid = false;
              reason = "CountryId should not be blank for Add action.";
          } else if (String(field).trim().length > 2) {
              isValid = false;
              reason = "CountryId exceeds max length of 2.";
          }
      }
  }

  // if (!row.StateProvince || String(row.StateProvince).trim() === "") {
  //   if (row.Action === "A" || row.Action === "a") {
  //     isValid = false;
  //     reason = "StateProvince should not be empty.";
  // }
  // } else if (field === row.StateProvince) {
  //   if(countryIDExists){
  //     isValid = true;
  //     reason = "country Code is exists";
  //   }
  //   else{
  //     isValid = false;
  //     reason = "country Code is not exists";
  //   }
  
  //     if (row.Action === "E" || row.Action === "e") {
  //         if (!field.trim()) {
  //             isValid = true;
  //             reason = "StateProvince should be blank for Edit action.";
  //         } else if (String(field).trim().length > 2) {
  //             isValid = false;
  //             reason = "StateProvince exceeds max length of 2.";
  //         }
  //     } else if (row.Action === "A" || row.Action === "a") {
  //         if (!field.trim()) {
  //             isValid = false;
  //             reason = "StateProvince should not be blank for Add action.";
  //         } else if (String(field).trim().length > 2) {
  //             isValid = false;
  //             reason = "StateProvince exceeds max length of 2.";
  //         }
  //     }
  // }

  if (field === row.StateProvince) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
      else if (String(field).length > 3) { isValid = false; reason = "StateProvince exceeds max length of 3."; }
      // if(StateIDExists){
      //         isValid = true;
      //         reason = "StateProvince is exists";
      //       }
      //       else{
      //         isValid = false;
      //         reason = "StateProvince is not exists";
      //       }
    }
  }

  //   if (field === row.StateProvince) {
  //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {

  //     if(StateIDExists){
  //       isValid = true;
  //       reason = "StateProvince is exists";
  //     }
  //     else{
  //       isValid = false;
  //       reason = "StateProvince is not exists";
  //     }
      
  //     if (!field) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
  //     if (String(field).length > 3) { isValid = false; reason = "StateProvince exceeds max length of 3."; }

  //   }
  // }

  else if (field === row.ContactPersonNumber) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "ContactPersonNumber should be blank for Add/Edit action."; }
      else if (String(field).length > 50) { isValid = false; reason = "ContactPersonNumber exceeds max length of 50."; }
    }
  }

  else if (field === row.MobileNumber) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "MobileNumber should be blank for Add/Edit action."; }
      else if (String(field).length > 13) { isValid = false; reason = "MobileNumber exceeds max length of 13."; }
    }
  }
  
  else if (field === row.Email) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "Email should be blank for add/Edit action."; }
      else{
        if (String(field).length > 80) { isValid = false; reason = "Email exceeds max length of 80."; }
        if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid Email format."; }
      }
    } 
  }
  
  else if (field === row.Telephone) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "Telephone should be blank for Add/Edit action."; }
      else if (String(field).length > 13) { isValid = false; reason = "Telephone exceeds max length of 13."; }
    }
  }
  else if (field === row.FaxNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "FaxNo should be blank for Add/Edit action."; }
      else if (String(field).length > 13) { isValid = false; reason = "FaxNo exceeds max length of 13."; }
    }
  }

  if (!isValid) {
    console.log(`Validation Failed: ${reason}`);
  } else {
    console.log(`Validation Passed: ${field}`);
  }
  
  return isValid;
});

console.log(`Final hasNotError status: ${hasNotError}`);

    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error

      isDuplicateUserId,
      isDuplicateInDB,
      userExists,
      LGEExists,
      LGEGreenExists,
      BUExists,
      BUGreenExists,
      CurrencyExists,
      countryIDExists,
      StateIDExists,

      Action: row.Action ? (
        String(row.Action).length === 1 ? (
          ["A", "a", "E", "e"].includes(row.Action) ? (
            row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Only "A", "E" are allowed)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Max. allowed character is 1)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
  
        CompanyCode: row.CompanyCode ? (
          String(row.CompanyCode).length > 10 ? (
            <span style={{ color: "red" }}>
              {row.CompanyCode} (Max. allowed character is 10)
            </span>
          ) : row.Action === "A" || row.Action === "a" ? (
            // When Action is "A", show duplicate message if user ID is a duplicate
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span style={{ color: "red" }}>
                {row.CompanyCode} (CompanyCode Duplicate exists)
              </span>
            ) : (
              String(row.CompanyCode) // Otherwise, show CompanyCode normally
            )
          ) : row.Action === "E" || row.Action === "e" ? (
            // When Action is "E", show CompanyCode without the duplicate message
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span>
                {row.CompanyCode} 
              </span>
            ) : (
              // String(row.CompanyCode) // Otherwise, show CompanyCode normally
              <span style={{ color: "red" }}>
              {row.CompanyCode} (CompanyCode not exists)
            </span>
            )
          ) : (
            String(row.CompanyCode) // Default case, show LGECode normally
          )
        ) : (
          <span style={{ color: "red" }}>
            Shouldn't be blank
          </span>
        ),

        CompanyName: row.CompanyName ? (
          String(row.CompanyName).length > 150 ? (
            <span style={{ color: "red" }}>
              {row.CompanyName} (Max. allowed character is 150)
            </span>
          ) : (
            String(row.CompanyName) // Ensure CompanyName is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

        CompanyShortName: row.CompanyShortName ? (
          String(row.CompanyShortName).length > 15 ? (
            <span style={{ color: "red" }}>
              {row.CompanyShortName} (Max. allowed character is 15)
            </span>
          ) : (
            String(row.CompanyShortName) // Ensure CompanyShortName is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

         LGECode: row.LGECode ? (
  String(row.LGECode).length > 10 ? (
    <span style={{ color: "red" }}>
      {row.LGECode} (Max. allowed character is 10)
    </span>
  ) : LGEExists && displayOption !== '' ? (
    LGEGreenExists ?
    <span>{row.LGECode}</span> :
    <span style={{ color: "red" }}>
    {row.LGECode} (LGE Code is Not approved.)
  </span>

  ):
  (
    <span style={{ color: "red" }}>
    {row.LGECode} (LGE Code not exists.)
  </span>
  )
) : (
  row.Action === "A" || row.Action === "a" ? (
    <span style={{ color: "red" }}>
      Shouldn't be blank
    </span>
  ) : row.Action === "E" || row.Action === "e" ? (
    <span></span> // Empty span for "E"
  ) : null // Handle other cases, if any
),


       
BuGroupCode: row.BuGroupCode ? (
  String(row.BuGroupCode).length > 10 ? (
    <span style={{ color: "red" }}>
      {row.BuGroupCode} (Max. allowed character is 10)
    </span>
  ) : BUExists && displayOption !== '' ? (
    BUGreenExists ?
    <span>{row.BuGroupCode}</span> :
    <span style={{ color: "red" }}>
    {row.BuGroupCode} (BuGroup Code is Not approved.)
  </span>
  ):
  (
    <span style={{ color: "red" }}>
    {row.BuGroupCode} (BuGroup Code not exists.)
  </span>
  )
) : (
  row.Action === "A" || row.Action === "a" ? (
    <span style={{ color: "red" }}>
      Shouldn't be blank
    </span>
  ) : row.Action === "E" || row.Action === "e" ? (
    <span></span> // Empty span for "E"
  ) : null // Handle other cases, if any
),
      
CurrencyCode: row.CurrencyCode ? (
  String(row.CurrencyCode).length > 10 ? (
    <span style={{ color: "red" }}>
      {row.CurrencyCode} (Max. allowed character is 10)
    </span>
  ) : CurrencyExists && displayOption !== '' ? (
    <span>{row.CurrencyCode}</span>
  ):
  (
    <span style={{ color: "red" }}>
    {row.CurrencyCode} (Currency Code not exists.)
  </span>
  )
) : (
  row.Action === "A" || row.Action === "a" ? (
    <span style={{ color: "red" }}>
      Shouldn't be blank
    </span>
  ) : row.Action === "E" || row.Action === "e" ? (
    <span></span> // Empty span for "E"
  ) : null // Handle other cases, if any
),
        // CurrencyCode: row.CurrencyCode ? (
        //   String(row.CurrencyCode).length > 3 ? (
        //     <span style={{ color: "red" }}>
        //       {row.CurrencyCode} (Max. allowed character is 3)
        //     </span>
        //   ) :  (
        //     <span>{row.CurrencyCode}</span>
        //   )
        // ) : (
        //   row.Action === "A" || row.Action === "a" ? (
        //     <span style={{ color: "red" }}>
        //       Shouldn't be blank
        //     </span>
        //   ) : row.Action === "E" || row.Action === "e" ? (
        //     <span></span> // Empty span for "E"
        //   ) : null // Handle other cases, if any
        // ),


        addressLine1: !row.CopyTheLGEAddress || String(row.CopyTheLGEAddress).trim() === "" ? (
          row.addressLine1 ? (
          String(row.addressLine1).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.addressLine1} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.addressLine1) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            
            !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.areaName && !row.phase && !row.sector && !row.village && !row.town && !row.cityName && !row.addressLine2 && !row.addressLine3
            // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
            ? (
              <span style={{ color: "red" }}>
                  Either Address Section or Building, Street, and Area Section must be entered
              </span>
          ) : (
              <span></span>
          )
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          )
        ) : (
          // String(row.addressLine1) // If CopyTheLGEAddress is not empty, just display AddressLine1
          row.addressLine1 ? (
            String(row.addressLine1).length > 50 ? (
              <span style={{ color: "red"}}>
                {row.addressLine1} (Max. allowed character is 50)
              </span>
            ) : (
                String(row.addressLine1) // Ensure userId is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (                   
                <span></span> // Empty span for "E"
              ) : null // Handle other cases, if any
            )
        ),
  
        addressLine2: row.addressLine2 ? (
          String(row.addressLine2).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.addressLine2} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.addressLine2) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        addressLine3: row.addressLine3 ? (
          String(row.addressLine3).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.addressLine3} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.addressLine3) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
       countryId: row.countryId ? (
          String(row.countryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.countryId} (Max. allowed character is 2)
            </span>
          )  : countryIDExists && displayOption !== "" ? (
            <span>{row.countryId}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.countryId} (Country Code not exists)
            </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
      
      // countryId: row.countryId ? (
      //   String(row.countryId).length > 2 ? (
      //     <span style={{ color: "red"}}>
      //       {row.countryId} (Max. allowed character is 2)
      //     </span>
      //   ) : (
      //       String(row.countryId) // Ensure userId is treated as a string
      //   )
      // ) : (
      //   row.Action === "A" || row.Action === "a" ? (
      //     <span style={{ color: "red" }}>
      //       Shouldn't be blank
      //     </span>
      //   ) : row.Action === "E" || row.Action === "e" ? (
      //     <span></span> // Empty span for "E"
      //   ) : null // Handle other cases, if any
      // ),


        StateProvince: row.StateProvince ? (
          String(row.StateProvince).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.StateProvince} (Max. allowed character is 3)
            </span>
          ) : StateIDExists && displayOption !== "" ? (
            <span>{row.StateProvince}</span>
          ):(
            <span style={{ color: "red" }}>
              {row.StateProvince} (StateProvince not exists)
            </span>
          )
        ) : (
          <span>
           
          </span>
        ),
  
        // StateProvince: row.StateProvince ? (
        //   String(row.StateProvince).length > 3 ? (
        //     <span style={{ color: "red"}}>
        //       {row.StateProvince} (Max. allowed character is 3)
        //     </span>
        //   ) : (
        //       String(row.StateProvince) // Ensure userId is treated as a string
        //   )
        // ) : (
        //   <span>
           
        //   </span>
        // ),


        ContactPersonNumber: row.ContactPersonNumber ? (
          String(row.ContactPersonNumber).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.ContactPersonNumber} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.ContactPersonNumber) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),

        MobileNumber: row.MobileNumber ? (
          String(row.MobileNumber).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.MobileNumber} (Max. allowed character is 13)
            </span>
          ) : (
              String(row.MobileNumber) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),

        Email: row.Email ? (
          String(row.Email).length > 80 ? (
            <span style={{ color: "red"}}>
              {row.Email} (Max. allowed character is 80)
            </span>
          ) : !validateEmail(row.Email) ? (
            <span style={{ color: "red"}}>
              {row.Email} (Invalid Email format)
            </span>
          ) : (
            row.Email // If valid, display the Email normally
          )
        ) : (
          
            <span></span> // Empty span for "E"
       
        ),

        Telephone: row.Telephone ? (
          String(row.Telephone).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.Telephone} (Max. allowed character is 13)
            </span>
          ) : (
              String(row.Telephone) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),


        FaxNo: row.FaxNo ? (
          String(row.FaxNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.FaxNo} (Max. allowed character is 13)
            </span>
          ) : (
              String(row.FaxNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),



      };  


  }));
}

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);

          const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
          const processed = await processData(dataToDisplay);
          setProcessedData(processed);
          setLoading(false);

        };
      
        fetchData();
      }, []);
                   
        const existingButtons = AdminActionsConfig.screen7.map((button) => {
          let onClick;
          let title;
      
      
          if (button.icon === MdOutlineFileDownload) {
            onClick = handleDownload;
            title = "Download File";
          }
      
          else {
            onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
            title = button.title || ""; // Use existing title or default to an empty string
          }
      
          return {
            ...button,
            onClick, // Reference the function without invoking it
            title,   // Add the title property to the button configuration
          };
        });
              
          const extraButtons = [
  
          ];
                  
        const actionButtons = existingButtons.concat(
          extraButtons.map((button) => ({
            ...button,
            disabled: selectedRows.length === 0, // Disable button if no users are selected
            onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
          }))
        );

  return (
    <div>
      <Header
        title={`Primary Data > Company & Bank Data > Company > Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/2/master-data/9/company-upload"
      />

      {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

<AdminActions
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              />


<ReTable
          data={processedData}
          // data={tableData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        //   onRowDoubleClick={handleDoubleClick}
        />


    </div>
  )
}
