import React, { useEffect, useState } from 'react'
import Header from '../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoIosList } from 'react-icons/io';
import { RxDropdownMenu } from 'react-icons/rx';
import CheckboxPopup from '../../../../../components/CheckBoxComponent';
import CustomDropdown from '../../../../../components/CustomDropdown';
import Footer from '../../../../../components/footer';
import CustomButton from '../../../../../components/CustomButton';
import HoverButton from '../../../../../components/HoverButton';
import StatementAnalyzerService from '../../../../../rest-services/StatementAnalyserServices';
import GlobalProcessServices from '../../../../../rest-services/GlobalProcessServices';
import { useToast } from '../../../../../components/toast/toast';
import UserService from '../../../../../rest-services/UserServices';

export default function LicenseSummary() {
    const navigate = useNavigate();
    const { toast } = useToast();
    const [errors, setErrors] = useState({});
    const { iconId, menuId } = useParams();
    const [processName, setProcessName] = useState("");
    const [assingBankAccountId, setAssingBankAccountId] = useState(0);

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Admin') {
          navigate('/14/admin');
        } 
        else if (crumb.title === 'Licenses') {
            navigate('/14/admin');
        }

        else if (crumb.title === 'Licenses Summary') {
          navigate('/14/admin');
      }
         else if (crumb.path) {
          navigate(crumb.path); // Navigate to the path specified in the crumb
        }
      };

      const statusOptions = [
        { value: "0", label: "Created", className: "text-gray-500" },
        { value: "1", label: "Changed", className: "text-gray-500" },
        { value: "2", label: "Waiting for approval", className: "text-orange-400" },
        { value: "3", label: "Modified", className: "text-yellow-500" },
        { value: "4", label: "Approved", className: "text-[#29CB00]" },
        { value: "5", label: "Rejected", className: "text-red-500" },
        { value: "6", label: "Inactive", className: "text-black" },
      ];

          const [modalStates, setModalStates] = useState({
            userId: { isOpen: false, searchQuery: "", selected: [], tempSelected: [] },
              company: { isOpen: false, searchQuery: "", selected: [], tempSelected: [] },
        bankCountry: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },

    });

    const [dataLists, setDataLists] = useState({
        company: [],
        userId: [],
    });

    useEffect(() => {
            // if (!isCompanyModalOpen) return
            fetchCompanyCode();
            fetchuserId()
        }, []);

           const fetchCompanyCode = async () => {
                try {
                    const response = await StatementAnalyzerService.getCompanyCode();
                    if (response.status === 200) {
                        const data = response.data.data;
                        console.log('response fetch company id',data);

                        setDataLists((prev) => ({
                            ...prev,
                            company: data,
                        }));
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            const fetchuserId = async () => {
                try {
                    const response = await UserService.getUsers(
                        `${process.env.REACT_APP_BASE_URL}/get-all-userId`
                    );
                    if (response.status === 200) {
                        console.log('response fetch user id',response.data.data);
                        const data = response.data?.data || []; // Ensure it's an array
                        setDataLists((prev) => ({
                            ...prev,
                            userId: data,
                        }));
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        

      const toggleModal = (key, isOpen) => {
        console.log(key,isOpen)
        setModalStates((prev) => ({
            ...prev,
            [key]: { ...prev[key], isOpen },
        }));
    };

    
        const location = useLocation();
        const id = location.state?.id;
    
        useEffect(() => {
            if (id) fetchDataForField();
        }, [id]);

        const fetchDataForField = async () => {
            try {
                const response = await GlobalProcessServices.getProcessDetailsById(id, menuId, iconId);
                if (response.status === 200) {
                    const apiData = response.data.data;
    
                    const processName = apiData.processName || "";
                    setProcessName(processName);
    
                    // Extracting values from API response
                    const extractValues = (fieldName) =>
                        apiData.processListDetails
                            .filter((item) => item.fieldName === fieldName)
                            .map((item) => item.value) || [];
    
                    // Fetch data for existing fields
                    const companyCode = extractValues("companyCode");
                    const userId = extractValues("userId");
                    const bankCountry = extractValues("bankCountry");
    
                    // // Fetch data for new transaction and imported date fields
                    // const transactionFromMonth = extractValues("transactionFromMonth")[0] || "";
                    // const transactionToMonth = extractValues("transactionToMonth")[0] || "";
                    // const transactionFromValueDate = extractValues("transactionFromValueDate")[0] || "";
                    // const transactionToValueDate = extractValues("transactionToValueDate")[0] || "";
                    // const importedFromMonth = extractValues("importedFromMonth")[0] || "";
                    // const importedToMonth = extractValues("importedToMonth")[0] || "";
                    // const importedFromValueDate = extractValues("importedFromValueDate")[0] || "";
                    // const importedToValueDate = extractValues("importedToValueDate")[0] || "";
    
    
                    // //Fetch Data for controlls Tab
                    // const dateBalance = extractValues("dateBalance")[0] || "0";
                    // const duplicate = extractValues("duplicate")[0] || "0";
                    // const btcCheck = extractValues("btcCheck")[0] || "0";
                    // const balances = extractValues("balances")[0] || "0";
                    // const transaction = extractValues("transaction")[0] || "0";
                    // const transferStatus = extractValues("transferStatus")[0] || "0";
                    // const importedStatus = extractValues("importedStatus")[0] || "0";
    
    
                    // Updating state with fetched data
                    setModalStates((prev) => ({
                        ...prev,
                        company: { ...prev.company, selected: companyCode, tempSelected: companyCode },
                        bankCountry: { ...prev.bankCountry, selected: bankCountry, tempSelected: bankCountry },
                      
                        userId: { ...prev.userId, selected: userId, tempSelected: userId },
                    }));
    
                    // Updating state for transaction and imported date fields
                    // setTransactionFromMonth(transactionFromMonth);
                    // setTransactionToMonth(transactionToMonth);
                    // setTransactionFromValueDate(transactionFromValueDate);
                    // setTransactionToValueDate(transactionToValueDate);
                    // setImportedFromMonth(importedFromMonth);
                    // setImportedToMonth(importedToMonth);
                    // setImportedFromValueDate(importedFromValueDate);
                    // setImportedToValueDate(importedToValueDate);
                    setAssingBankAccountId(assingBankAccountId);
    
                    // // Updating state for controlls Tab
                    // setDateBalance(dateBalance);
                    // setDuplicate(duplicate);
                    // setBtcCheck(btcCheck);
                    // setBalances(balances);
                    // setTransaction(transaction);
                    // setTransferStatus(transferStatus);
                    // setImportedStatus(importedStatus);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const handleSelection = (key, value) => {
            setModalStates((prev) => ({
                ...prev,
                [key]: {
                    ...prev[key],
                    tempSelected: prev[key].tempSelected.includes(value)
                        ? prev[key].tempSelected.filter((item) => item !== value)
                        : [...prev[key].tempSelected, value],
                },
            }));
    };
            
        const handleApplySelection = (key) => {
            setModalStates((prev) => ({
                ...prev,
                [key]: {
                    ...prev[key],
                    selected: [...prev[key].tempSelected],
                    isOpen: false,
                },
            }));
        };
        
        const handleResetSelection = (key) => {
            setModalStates((prev) => ({
                ...prev,
                [key]: { ...prev[key], tempSelected: [] },
            }));
        };

        const handleSave = async (action) => {
            try {
                if (!processName) setErrors({ processName: "Process Name is required." });
                const data = {
                    processName,
                    assingBankAccountId,
                  
                    companyCode: modalStates.company.tempSelected,
                    bankCountry: modalStates.bankCountry.tempSelected,
                   
                    // Controlls Tab
                    userId: modalStates.userId.tempSelected,
                 
                };
    
                const response = await StatementAnalyzerService.addProcessDetails(data);
                if (response.status === 200) {
                    toast("success", response.message);
                    if (action === 1) {
                        navigate("/bank-statements");
                    } else {
                        resetForm();
                    }
                } else {
                    toast("error", response.message);
                }
            } catch (error) {
                console.log(error);
            }
        };

        const resetForm = () => {
            setProcessName("");
            setAssingBankAccountId("");
            setModalStates((prev) => ({
                ...prev,
                company: { ...prev.company, tempSelected: [], selected: [] },
                bankCountry: { ...prev.bankCountry, tempSelected: [], selected: [] },
               
                userId: { ...prev.userId, tempSelected: [], selected: [] },
            }));
            // setTransactionFromMonth("");
            // setTransactionToMonth("");
            // setTransactionFromValueDate("");
            // setTransactionToValueDate("");
            // setImportedFromMonth("");
            // setImportedToMonth("");
            // setImportedFromValueDate("");
            // setImportedToValueDate("");
            // setDateBalance("0");
            // setDuplicate("0");
            // setBtcCheck("0");
            // setBalances("0");
            // setTransaction("0");
            // setTransferStatus("0");
            // setImportedStatus("0");
        }

        const handleUpdate = async () => {
            try {
                if (!processName) setErrors({ processName: "Process Name is required." });
                const data = {
                    processName,
                    bankCountry: modalStates.bankCountry.tempSelected,
                  
                    companyCode: modalStates.company.tempSelected,
                   
                    // New transaction and imported date fields
                    userId: modalStates.userId.tempSelected,
                };
    
    
                const response = await StatementAnalyzerService.updateProcessDetails(data, menuId, iconId);
                if (response.status === 200) {
                    toast("success", response.message);
                } else {
                    toast("error", response.message);
                }
            } catch (error) {
                console.log(error);
            }
        };


        const handleNavigateToAnalyser = async () => {
            const data = {
                processName,
                assingBankAccountId,
                companyCode: modalStates.company.tempSelected,
                userCountry: modalStates.bankCountry.tempSelected,
           
                userId: modalStates.userId.tempSelected,
             
            }
            console.log('navigate data',data);
            navigate(`/license-summary/${iconId}`, { state: data });
    
        }

        
            const [openDropdown, setOpenDropdown] = useState(null);
            const handleToggle = (dropdownId) => {
                setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
            };

  return (
    <div>
       <Header title={"Admin > Licenses > Licenses Summary"} 
        onBreadcrumbClick={handleBreadcrumbClick}/>


        <div className="m-5 flex flex-col space-y-4 col-span-1 p-4 border  rounded-md">
                                <div className="2xl:flex 2xl:flex-row xl:grid xl:grid-cols-2 xl:gap-0 lg:grid lg:grid-cols-2 lg:gap-0">
                                    <div>
                                        <label className="flex items-center">
                                            <span className="w-48 font-small text-customGray text-xs">Process Name <span className="text-red-500">*</span></span>
        
                                            <span className="ml-4">:</span>
        
                                            <input
                                                type="text"
                                                // value={processName}
                                                // readOnly={id ? true : false}
                                                // onChange={(e) => setProcessName(e.target.value)}
                                                className={`border p-1 rounded w-80 h-8 text-xs focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray ${errors.processName
                                                    ? "border-red-500 hover:border-red-500"
                                                    : "border-gray-300 hover:border-blue-400"
                                                    }`}
                                            />
                                        </label>
        
                                    </div>
                                    <div>
                                        <button>
                                            <IoIosList className="ml-4 mt-1 border  h-6 w-6  hover hover:border-customBlue" 
                                            // onClick={() => navigate("/bs-process-list", { state: { iconId: iconId, subModule: menuId } })} 
                                            />
                                        </button>
                                    </div>
                                </div>

                                {/* First row */}
                                <div>
                                    <div className="grid grid-cols-2 gap-x-4">
                                        <label className="flex items-center">
                                            <span className="w-48 font-small text-customGray text-xs">User ID</span>
                                            <span className="ml-4">:</span>
                                            <button
                                                onClick={() => toggleModal("userId", true)}
                                                className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                            >
                                                <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                                    {modalStates.userId.selected.length > 0 ? modalStates.userId.selected.join(", ") : "User ID"}
                                                </span>
                                                <RxDropdownMenu className="ml-2" />
                                            </button>
        
                                        </label>
                                        <label className="flex items-center">
                                            <span className="w-48 font-small text-customGray text-xs">User Status</span>
                                            <span className="ml-4">:</span>
                                            <CustomDropdown
                                                value={
                                                    statusOptions.find(
                                                        (option) => option.value === assingBankAccountId
                                                    ) || null
                                                }
                                                options={statusOptions}
                                                onChange={(value) => { setAssingBankAccountId(value); setOpenDropdown(null); }}
                                                isOpen={openDropdown === "assingBankaccount"}
                                                onToggle={() => handleToggle("assingBankaccount")}
                                                className="ml-8 border-gray-300"
                                                placeholder="Select User Status"
                                                width="2xl:w-80 xl:w-[275px] lg:w-[218px]"
                                            />
                                        </label>
                                        <div>
                                <CheckboxPopup
                                    title="Select Company Code"
                                    isOpen={modalStates.company.isOpen}
                                    onClose={() => toggleModal("company", false)}
                                    onSave={() => handleApplySelection("company")}
                                    onReset={() => handleResetSelection("company")}
                                    searchPlaceholder="Search Company..."
                                    searchQuery={modalStates.company.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, company: { ...prev.company, searchQuery: e.target.value }
                                    }))}
                                    items={[...new Map(dataLists.company.map(item => [item.companyCode, item])).values()]
                                        .map(code => ({ id: code.companyCode, name: code.companyCode || '' })) // Ensure name is always a string
                                        .filter(item => item.name?.toLowerCase().includes(modalStates.company.searchQuery?.toLowerCase() || ''))
                                    }
                                    selectedItems={modalStates.company.tempSelected}
                                    onItemToggle={(val) => handleSelection("company", val)}

                                />

                                <CheckboxPopup
                                    title="Select User ID"
                                    isOpen={modalStates.userId.isOpen}
                                    onClose={() => toggleModal("userId", false)}
                                    onSave={() => handleApplySelection("userId")}
                                    onReset={() => handleResetSelection("userId")}
                                    searchPlaceholder="Search userId..."
                                    searchQuery={modalStates.userId.searchQuery}
                                    onSearchChange={(e) => setModalStates((prev) => ({
                                        ...prev, userId: { ...prev.userId, searchQuery: e.target.value }
                                    }))}
                                    // items={[...new Map(dataLists.userId.map(item => [item.userId, item])).values()]
                                    //     .map(code => ({ id: code.userId, name: code.userId || '' })) // Ensure name is always a string
                                    //     .filter(item => item.userId?.toLowerCase().includes(modalStates.userId.searchQuery?.toLowerCase() || ''))
                                    // }
                                    items={
                                        Array.isArray(dataLists.userId) // Ensure it's an array before mapping
                                            ? [...new Map(dataLists.userId.map(item => [item.userId, item])).values()]
                                                .map(code => ({
                                                    id: code.userId,
                                                    name: code.userId || ''
                                                }))
                                                .filter(item =>
                                                    item.name?.toLowerCase().includes(
                                                        modalStates.userId.searchQuery?.toLowerCase() || ''
                                                    )
                                                )
                                            : []
                                    }
                                    selectedItems={modalStates.userId.tempSelected}
                                    onItemToggle={(val) => handleSelection("userId", val)}

                                />
                            </div>

                                    </div>
                                </div>
                                
                                {/* Second row */}
                                <div>
                                    <div className="grid grid-cols-2 gap-x-4">
                                        <label className="flex items-center">
                                            <span className="w-48 font-small text-customGray text-xs">Company Code</span>
                                            <span className="ml-4">:</span>
                                            <button
                                                onClick={() => toggleModal("company", true)}
                                                className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                            >
                                                <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                                {modalStates.company.selected.length > 0 ? modalStates.company.selected.join(", ") : "Company"}
                                                </span>
                                                <RxDropdownMenu className="ml-2" />
                                            </button>
        
                                        </label>
                                        <label className="flex items-center">
                                            <span className="w-48 font-small text-customGray text-xs">Country</span>
                                            <span className="ml-4">:</span>
        
                                            <button
                                                onClick={() => toggleModal("bankCountry", true)}
                                                className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray"
                                            >
                                                <span className="font-small text-customGray text-xs truncate w-[calc(100%-1.5rem)]">
                                                {modalStates.bankCountry.selected.length > 0 ? modalStates.bankCountry.selected.join(", ") : "Select Country"}
                                                </span>
                                                <RxDropdownMenu className="ml-2" />
                                            </button>
                                           
                                        </label>
                                        <CheckboxPopup
                                                title="Select Country"
                                                isOpen={modalStates.bankCountry.isOpen}
                                                onClose={() => toggleModal("bankCountry", false)}
                                                onSave={() => handleApplySelection("bankCountry")}
                                                onReset={() => handleResetSelection("bankCountry")}
                                                searchPlaceholder="Search Country..."
                                                searchQuery={modalStates.bankCountry.searchQuery}
                                                onSearchChange={(e) => setModalStates((prev) => ({
                                                    ...prev, bankCountry: { ...prev.bankCountry, searchQuery: e.target.value }
                                                }))}
                                                items={[
                                                    ...new Set(
                                                        dataLists.userId
                                                        .flatMap((userID) => userID.Address?.countryCode ? [userID.Address.countryCode] : [])
                                                        .filter(Boolean)   
                                                    )
                                                ]
                                                .map(countryCode => ({
                                                    id: countryCode, 
                                                    name: countryCode || ''
                                                }))
                                                .filter(item =>
                                                    modalStates.bankCountry?.searchQuery
                                                        ? item.name.toLowerCase().includes(modalStates.bankCountry.searchQuery.toLowerCase())
                                                        : true
                                                )}
            
                                                selectedItems={modalStates.bankCountry.tempSelected}
                                                onItemToggle={(val) => handleSelection("bankCountry", val)}
            
                                            />
                                    </div>
                                </div>

                                {/* Third row */}
                                <div>
                                    <div className="grid grid-cols-2 gap-x-4">
                                        <label className="flex items-center">
                                            <span className="w-48 font-small text-customGray text-xs">Valid From</span>
                                            <span className="ml-4">:</span>

                                            <input
                                                    type="date"
                                                    className="border border-gray-300 p-1 rounded w-80 h-8 text-xs focus:outline-none hover:border-blue-400 ml-8 text-customGray"
                                                    // value={selectedDate} // Uncomment and set the state value here
                                                    // onChange={(e) => setSelectedDate(e.target.value)} // Uncomment and set the handler
                                                />
        
                                        </label>
                                        <label className="flex items-center">
                                            <span className="w-48 font-small text-customGray text-xs">Valid To</span>
                                            <span className="ml-4">:</span>
        
                                            <input
                                                    type="date"
                                                    className="border border-gray-300 p-1 rounded w-80 h-8 text-xs focus:outline-none hover:border-blue-400 ml-8 text-customGray"
                                                    // value={selectedDate} // Uncomment and set the state value here
                                                    // onChange={(e) => setSelectedDate(e.target.value)} // Uncomment and set the handler
                                                />
                                        </label>
        
                                    </div>
                                </div>
                               
                            </div>

                             <div className="mt-10">
                                            <Footer>
                                                <div>
                                                    {!id && <CustomButton
                                                        // onClick={() => { handleSave(1) }}
                                                        title={"Save"}
                                                        className="mr-2"
                            
                                                    />}
                                                    {!id && <HoverButton
                                                        title={"Save & Continue"}
                                                        className={"mr-2"}
                                                        // onClick={() => { handleSave(2) }}
                                                    />}
                                                    {id && <CustomButton
                                                        // onClick={handleUpdate}
                                                        title={"Save"}
                                                        className="mr-2"
                            
                                                    />}
                                                    <HoverButton
                                                        title={"Run >"}
                                                        className={"mr-2"}
                                                        onClick={() => { handleNavigateToAnalyser() }}
                                                    />
                                                </div>
                                            </Footer>
                                        </div>

    </div>
    
  )
}
