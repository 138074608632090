import React from "react";
import { RxDropdownMenu } from "react-icons/rx"; // Replace with the correct icon import if needed

const LabelWithButton = ({
  label,
  labelwidth = "w-48",
  isRequired = false,
  isDisabled = false,
  onClick,
  buttonText = "Select Option",
  error = false,
  className = "",
  width = "w-80", // default width
  marginLeft = "ml-4",
  spanMargin = "ml-4",
  marginbottom = "mb-4",
}) => {
  return (
    <div className={`flex items-center ${marginbottom} ${className}`}>
      {/* Label */}
      <label
        className={` ${labelwidth} w-48 text-xs font-small text-customGray`}
        disabled={isDisabled}
      >
        {label}
        {isRequired && <span className="text-red-500 ml-1">*</span>}
      </label>
      <span className={`${spanMargin}`}>:</span>
      {/* Button */}
      <button
        onClick={onClick}
        disabled={isDisabled}
        className={`border p-1 disabled:bg-[#FAFAFA] w-80  rounded h-8 focus:outline-none text-left flex items-center justify-between text-xs font-small text-customGray  ${width} ${marginLeft} ${error
            ? "border-red-500 hover:border-red-500"
            : "border-gray-300 hover:border-blue-400"
          }`}
      >
        <span className="text-xs">{buttonText}</span>
        <RxDropdownMenu className="ml-2" />
      </button>
    </div>
  );
};

export default LabelWithButton;
