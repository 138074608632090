const Card = ({ title, icon, onClick, isHighlighted }) => {
  return (
    <div
      className={`border rounded-xl flex flex-col items-center justify-center bg-gradient-to-tr from-blue-100 via-blue-50 to-blue-20 text-center cursor-pointer h-24 w-28 hover:bg-blue-100 hover:border-customBlue ${
        isHighlighted ? 'border-2 border-red-500 shadow-lg' : ''
      }`}
      style={{ margin: '0', padding: '0', boxSizing: 'border-box' }}
      onClick={onClick}
    >
      <div className="text-3xl mx-auto mb-2 font-bold items-center justify-center p-1 mt-2 text-customBlue">
        {icon}
      </div>
      <div className="text-xs font-medium text-customBlue text-center">
        {title}
      </div>
    </div>
  );
};

export default Card;