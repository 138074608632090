import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";

export default function CalendarAdd() {
  const { calenderId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [calendarCode, setCalendarCode] = useState("");
  const [calendarName, setCalendarName] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const{toast}=useToast();
  const navigate = useNavigate();
  useEffect(() => {
    if (calenderId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [calenderId]);

  const getDataForEdit = async () => {
    try {
      const response = await CalendarServices.getCalendarById(calenderId);
      setCalendarCode(response.data.Calendar);
      setCalendarName(response.data.Calendar_Name);
    } catch {}
  };

  const validateForm = () => {
    const newErrors = {};
    if (!calendarCode) newErrors.calendarCode = "Calendar Code is required.";
    if (!calendarName) newErrors.calendarName = "Calendar Name is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      id: calenderId || null,
      calendarField: calendarCode,
      Calendar_Name: calendarName,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
    };
console.log('cqlendr data',data);
    try {
      const response = await CalendarServices.addCalendar(
        `/create-calendar`,data,
    
      );

      if (response.message==="Calendar created successfully") {
        toast("success","Calendar Added successfully");   

        setCalendarCode("");
        setCalendarName("");
      } else {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const result = await response.json();
          setAlert({
            severity: "error",
            message: result.message || "An error occurred.",
          });
        } else {
          toast("error","Error in Adding Calendar");   

        }
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error adding Calendar: ${error.message}`,
      });
    }
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'Calendar') {
      console.log("Navigating to /country");
      navigate('/calendar');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
   <Header
        title={`Primary Data > Global Master Data > Calendar > ${calenderId ?  (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick} // Pass the click handler to Header
      />
     

      <div className="flex flex-col space-y-12 col-span-1 p-10">
        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Calendar Code<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={calendarCode}
            onChange={(e) =>{
              const inputValue = e.target.value.toUpperCase();
              if(errors.calendarCode){
                setErrors((prevErrors)=>({
                  ...prevErrors,
                  calendarCode:"",
                }));
              }
      // Allow only 3 characters
      if (inputValue.length <= 3) {
              setCalendarCode(inputValue);
            } }
            }
            className={`border ${
              errors.calendarCode ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>
       

        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Calendar Name<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={calendarName}
            onChange={(e) => {setCalendarName(e.target.value);
            if(errors.calendarName){
              setErrors((prevErrors)=>({
                ...prevErrors,
                calendarName:"",
              }))
            }}}
            className={`border ${
              errors.calendarName ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>
        

      {(!isViewMode && (  <CustomButton onClick={handleSubmit} title={"Save"}/>))}
      </div>
    </div>
  );
}
