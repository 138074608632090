import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../../../components/Header";
import { useEffect, useState } from "react";
import BamServices from "../../../../../rest-services/BamServices";
import { useToast } from "../../../../../components/toast/toast";
import Footer from "../../../../../components/footer";

export default function AccountClassificationAdd() {
    const navigate = useNavigate();
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const location = useLocation();
    const { mode, idview } = location.state || {};
    const isViewMode = mode === "view";
    const isEditMode = mode === "edit";


    const [assignee, setAssignee] = useState({
        clientId: "",
        assigner: "",
        userid: "",
    });

    const [accountClassification, setAccountClassification] = useState("");
    const [description, setDescription] = useState("");
    const { toast } = useToast();
    const [notes, setNotes] = useState("");
    const [errors, setErrors] = useState({});
    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === "BAM") {
            navigate("/bam", { replace: true });
        } else if (crumb.title === "Set Up") {
            navigate("/bam", { replace: true });
        } else if (crumb.title === "Account Classfication") {
            navigate("/3/BAM/135/account-classfication", { replace: true });
        } else if (crumb.title === "Add") {
            navigate("/account-classfication-add", { replace: true });
        } else if (crumb.path) {
            navigate(crumb.path, { replace: true });
        }
    };


    useEffect(() => {
        const fetchAssignee = async () => {
            try {
                const userDetailString = localStorage.getItem("userDetail");
                if (userDetailString) {
                    const userDetail = JSON.parse(userDetailString);
                    const assigneeData = {
                        assigner: userDetail.username || "",
                        userid: userDetail.userId || "",
                        clientId: userDetail.clientId || "",
                    };
                    setAssignee(assigneeData);
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        api: "No user detail found in session storage.",
                    }));
                }
            } catch (error) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    api: `Error fetching user details: ${error.message}`,
                }));
            }
        };

        fetchAssignee();
    }, []);

    useEffect(() => {
        if (idview) {
            handleView();
        }
    }, [isEditMode]);

    const handleEdit = async () => {
        try {
            const payload = {
                id: idview,
                account_classification: accountClassification,
                account_description: description,
                notes: notes,
                changed_by: assignee.userid || "Unknown",
            };
            const response = await BamServices.updateAccountClassification(payload);
            if (response.status === 200) {
                toast("success", "Data Edited Successfully");
                navigate("/3/BAM/135/account-classfication");
                setUnsavedChanges(false);
            } else {
                toast("error", "Failed to edit data. Please try again.");
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [accountExists, setAccountExists] = useState(false);



    const checkAcountTypeExist = async () => {
        try {
            if (isEditMode || isViewMode) return;
            const response = await BamServices.checkifAccountClassificationExist(accountClassification);
            if (response.status === 409) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    account: "Account Classification already exists.",
                }));
                toast("error", "Account Classification already exists")
                setAccountExists(true);

            } else {
                setAccountExists(false)
            }
        } catch (error) {
            console.log(error);
        }
    }


    const handleView = async () => {
        try {
            const response = await BamServices.getAccountClassificationById(
                idview
            );
            if (response && response.data) {
                setAccountClassification(response.data.data.account_classification);
                setDescription(response.data.data.description);
                setNotes(response.data.data.note);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const validateForm = () => {
        const newErrors = {};
        if (!accountClassification) newErrors.account = "Account type is required.";
        if (!description) newErrors.description = "Description is required.";
        if (!notes) newErrors.notes = "Notes is required.";
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            toast("error", "Missing Required Fields");
        }
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };




    const handleSaveDetails = async (navigateAfterSave = false) => {


        if (!validateForm()) {
            return;
        }
        if (accountExists) {
            toast("error", "Account Classification already exists")
            return;
        }

        const payload = {
            account_classification: accountClassification,
            account_description: description,
            notes: notes,
            created_by: assignee.userid || "System",
        };

        try {
            const response = await BamServices.createAccountClassifcation(payload);
            if (response) {
                toast("success", "Added successfully");
            }
            setAccountClassification("");
            setDescription("");
            setNotes("");
            setUnsavedChanges(false);

            if (navigateAfterSave) {
                navigate("/3/BAM/135/account-classfication", { replace: true });
            }
        } catch (error) {
            console.error("Error saving Account Types:", error);
            setErrors((prevErrors) => ({
                ...prevErrors,
                api: "Failed to create Account Types. Please try again later.",
            }));
        }
    };

    return (
        <div>
            <Header
                title={`BAM > Setups > Account Classification > ${isViewMode ? "View" : isEditMode ? "Edit" : "Add"
                    }`}
                onBreadcrumbClick={handleBreadcrumbClick}
                hasUnsavedChanges={unsavedChanges}
                backRoute={"/3/BAM/135/account-classfication"}
            />
            <div className="flex-grow p-10">
                <label className="flex items-center mb-4">
                    <span className="w-48 font-small text-customGray text-xs whitespace-nowrap">
                        Account Classification <span className="text-red-500 ml-1">*</span>
                    </span>
                    <span className="ml-3">:</span>
                    <input
                        type="text"
                        value={accountClassification}
                        onChange={(e) => {
                            setAccountClassification(e.target.value);
                            setUnsavedChanges(true);
                            if (errors.account) {
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    account: "",
                                }));
                            }
                            setAccountExists(false)
                        }}
                        onBlur={() => checkAcountTypeExist()}
                        maxLength={4}
                        required
                        readOnly={isViewMode || isEditMode}
                        placeholder="Account classification"
                        className={`border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5 text-xs
                       ${errors.account ? "border-red-500 hover:border-red-500" : ""}
                   `}
                    />
                    {/* {errors.account && (
                   <p className="text-red-500 text-sm ml-4">{errors.account}</p>
                 )} */}
                </label>

                <label className="flex items-center mb-4 py-3">
                    <span className="w-48 font-small text-customGray text-xs whitespace-nowrap ">
                        Account Description <span className="text-red-500 ml-1">*</span>
                    </span>
                    <span className="ml-3">:</span>
                    <input
                        type="text"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                            setUnsavedChanges(true);
                            if (errors.description) {
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    description: "",
                                }));
                            }
                        }}
                        maxLength={30}
                        required
                        placeholder="Description"
                        readOnly={isViewMode}
                        className={`border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5 text-xs
                   ${errors.description ? "border-red-500 hover:border-red-500" : ""}`}
                    />
                    {/* {errors.description && (
                        <p className="text-red-500 text-sm ml-4">{errors.description}</p>
                    )} */}
                </label>

                <label className="flex items-start mb-4">
                    <span className="w-48 font-small text-customGray text-xs ">
                        Notes <span className="text-red-500 ml-1">*</span>
                    </span>
                    <span className="ml-3">:</span>
                    <textarea
                        value={notes}
                        onChange={(e) => {
                            setNotes(e.target.value);
                            setUnsavedChanges(true);
                            if (errors.notes) {
                                setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    notes: "",
                                }));
                            }
                        }}
                        required
                        placeholder="Notes"
                        maxLength={150}
                        readOnly={isViewMode}
                        className={`border border-gray-300 p-1.5 rounded w-72 focus:outline-none hover:border-blue-400 ml-5 resize-none text-xs
         ${errors.notes ? "border-red-500 hover:border-red-500" : ""}`}
                        style={{
                            minHeight: "2rem",
                            maxHeight: "6rem",
                            overflowY: "auto",
                        }}
                    />


                    {/* {errors.notes && (
                        <p className="text-red-500 text-sm ml-4">{errors.notes}</p>
                    )} */}
                </label>
            </div>

            <Footer>
                {!isViewMode && (
                    <button
                        onClick={() => {
                            isEditMode ? handleEdit() : handleSaveDetails(true);
                        }}
                        className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
                    >
                        Save
                    </button>
                )}
                {!isViewMode && !isEditMode && (
                    <button
                        onClick={() => handleSaveDetails(false)}
                        className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
                    >
                        Save & Continue
                    </button>
                )}
            </Footer>
        </div>
    );
}