import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import CurrencyServices from "../../../../../rest-services/CurrencyServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CurrencyServicess from "../../../../../rest-services/CurrencyServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";

export default function CurrencyAdd() {
  const { currencyId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const{toast}=useToast();
  const navigate = useNavigate();
  useEffect(() => {
    if (currencyId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [currencyId]);

  const validateForm = () => {
    const newErrors = {};
    if (!currencyCode) newErrors.currencyCode = "Currency Code is required.";
    if (!currencyName) newErrors.currencyName = "Currency Name is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const getDataForEdit = async () => {
    try {
      const response = await CurrencyServicess.getCurrencyById(currencyId);
      setCurrencyCode(response.data.currencyCode);
      setCurrencyName(response.data.Currency_Name);
    } catch {}
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    const data = {
      id: currencyId || null, // Use 'id' for updates
      currencyCode,
      Currency_Name: currencyName,
      created_by: assignee.assigner,
      changed_by: assignee.assigner, // Will be set in the backend if updating
    };
  
    try {
      let response;
      if (currencyId) {
        // Updating the existing currency
        response = await CurrencyServices.addCurrency(
          `${process.env.REACT_APP_BASE_URL}/create-currency`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await CurrencyServices.addCurrency(
          `${process.env.REACT_APP_BASE_URL}/create-currency`,
          data
        );
      }
  
      if (response && !response.error) {
        toast("success", currencyId ? "Currency Updated successfully" : "Currency Added successfully");
        // Clear the form and navigate
        setCurrencyCode("");
        setCurrencyName("");
        navigate("/currency"); // Redirect after saving
      } else {
        toast("error", "Error adding/updating Currency");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Currency: ${error.message}`,
      });
    }
  };
  
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'Currency') {
      console.log("Navigating to /country");
      navigate('/currency');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
      <Header
        title={`Primary Data > Global Master Data > Currency > ${currencyId ?  (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />

     

      <div className="flex flex-col space-y-12 col-span-1 p-10">
        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Currency Code<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={currencyCode}
            onChange={(e) => {setCurrencyCode(e.target.value)
            if(errors.currencyCode){
              setErrors((prevErrors)=>({
                ...prevErrors,
                currencyCode:"",
              }))
            }
            }}
            className={`border ${
              errors.currencyCode ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
            required
            disabled={isViewMode}
          />
        </label>
      

        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Currency Name<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={currencyName}
            onChange={(e) => {setCurrencyName(e.target.value)
            if(errors.currencyName){
              setErrors((prevErrors)=>({
                ...prevErrors,
                currencyName:"",
              }))

            }
            }}
            className={`border ${
              errors.currencyName ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
            required
            disabled={isViewMode}
          />
        </label>
      

        {(!isViewMode && (<CustomButton onClick={handleSubmit} title={"Save"}/>))}
      </div>
    </div>
  );
}
