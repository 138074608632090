import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PrimaryActions from '../../../Components/ActionButton';
import { MdOutlineFileDownload } from 'react-icons/md';
import { PrimaryActionsConfig } from '../../../Components/ActionButtonConfig';
import ReTable from '../../../../../../components/Table';
import UserService from '../../../../../../rest-services/UserServices';
import XLSX from "xlsx-js-style";
import CustomeLoading from '../../../../../../components/CustomeLoading';

export default function CountryUploadTableScreen() {
  const navigate = useNavigate();
      const location = useLocation();
  
    const { iconId } = useParams();
      const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
      const { tableData, displayOption, uploadOption, taskMonitor  } = location.state || {}; // Retrieve tableData from state
const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processedData, setProcessedData] = useState([]);

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "Global Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Country") {
      navigate("/2/master-data/1/country");
    } else if (crumb.title === "Primary Data") {
      console.log("Navigating to /country");
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  
        const handleDownload = () => {
          if (processedData.length === 0) {
            setAlert({
              severity: "warning",
              message: "No data available to export!",
            });
            return;
          }
        
          const extractText = (cellValue) => {
            if (Array.isArray(cellValue)) {
              return cellValue.join(", "); // Convert array to string
            } else if (typeof cellValue === "string") {
              return cellValue;
            } else if (React.isValidElement(cellValue)) {
              return cellValue.props.children;
            }
            return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
          };
        
  const formattedUsers = processedData.map((user) => ({
    "Action": extractText(user.Action),
    "SLNo": extractText(user.SLNo),
    "CountryCode": extractText(user.CountryCode),
    "CountryName": extractText(user.CountryName),
   
    "Language": extractText(user.Language || ""),
    "DateFormat": extractText(user.DateFormat || ""),
    "TimeFormat": extractText(user.TimeFormat || ""),
    "TimeZone": extractText(user.TimeZone || ""),
    "AmountFormat": extractText(user.AmountFormat || ""),
    // "ThousandSeparator": extractText(user.ThousandSeparator),
    // "DecimalSeparator": extractText(user.DecimalSeparator),
    "AcceleratorForThousands": extractText(user.AcceleratorForThousands),
    "AcceleratorForLakhs": extractText(user.AcceleratorForLakhs),
    "AcceleratorForMillions": extractText(user.AcceleratorForMillions),
  }));


// Add second and third row
const secondRow = {
"Action": "R","SLNo": "R", "CountryCode": "R","CountryName": "R", "Language": "R", "DateFormat": "R", "TimeFormat": "R","TimeZone": "", "AmountFormat": "R", 
// "ThousandSeparator": "", "DecimalSeparator": "",
 "AcceleratorForThousands": "", "AacceleratorForLakhs":"","AcceleratorForMillions": ""
};

const thirdRow = {
"Action": "1","SLNo": "5", "CountryCode": "2","CountryName": "12","Language": "2", "DateFormat": "", "TimeFormat": "8", "TimeZone": "",  "AmountFormat": "19", 
// "ThousandSeparator": "1", "DecimalSeparator": "1", 
"AcceleratorForThousands": "1", "AacceleratorForLakhs":"1","AcceleratorForMillions": "1"
};


    // Insert second and third row at the beginning of the formatted data
    const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];

        const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
      console.log("rowsToDownload data is",rowsToDownload);
 
        // Define border style
  const border = {
    top: { style: "thin", color: { rgb: "000000" } },
    right: { style: "thin", color: { rgb: "000000" } },
    bottom: { style: "thin", color: { rgb: "000000" } },
    left: { style: "thin", color: { rgb: "000000" } },
  };

      // Apply borders to the first three rows
      rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
        Object.keys(row).forEach((col, colIdx) => {
          const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = { border };
          }
        });
      });

      
       // Additional cell styling for validation errors
        Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
          rowsToDownload.forEach((row, rowIdx) => {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
            const cellValue = row[col];
      
            if (
              typeof cellValue === "string" && (
                cellValue.includes("Shouldn't be blank") 
                ||
                cellValue.includes("Either Address Section or Building, Street, and Area Section must be entered")
                // cellValue.includes("Max. allowed character")
              )
            ) {
              worksheet[cellAddress] = {
                v: cellValue,
                s: { font: { color: { rgb: "FF0000" } } },
              };
            } else if (Array.isArray(row[col])) {
              worksheet[cellAddress] = {
                v: extractText(row[col]),
                s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
              };
            }
            else {
              worksheet[cellAddress] = { v: cellValue };
            }
          });
        });
      
        // AutoFit column width
        worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
          const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
          return { wch: maxLength + 2 }; // Add extra space
        });


        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
        XLSX.writeFile(workbook, "Users_Uploading_Data.xlsx");
      };

useEffect(() => {
  fetchAssignee();
}, []);

const fetchAssignee = async () => {
try {
  const userDetailString = localStorage.getItem("userDetail");

  if (userDetailString) {
    const userDetail = JSON.parse(userDetailString);
    const assigneeData = {
      id: userDetail.id || "",
      assigner: userDetail.username || "",
      clientId: userDetail.clientId || "",
      assignerId: userDetail.userId || "",
    };
    console.log("assigneeData is", assigneeData);

    setAssignee(assigneeData);

    // Log the id and assigner values to the console
    console.log("Assignee ID:", assigneeData.id);
    console.log("Assigner:", assigneeData.assigner);
    console.log("Assigner userId:", assigneeData.assignerId);
    console.log("Assigner clientId:", assigneeData.clientId);
  } else {
    setAlert({
      severity: "error",
      message: "No user detail found in session storage.",
    });
  }
} catch (error) {
  setAlert({
    severity: "error",
    message: `Error fetching user details: ${error.message}`,
  });
}
};
  
          const existingButtons = PrimaryActionsConfig.screen6.map((button) => {
            let onClick;
            let title;
        
        
            if (button.icon === MdOutlineFileDownload) {
              onClick = handleDownload;
              title = "Download File";
            }
        
            else {
              onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
              title = button.title || ""; // Use existing title or default to an empty string
            }
        
            return {
              ...button,
              onClick, // Reference the function without invoking it
              title,   // Add the title property to the button configuration
            };
          });

  const extraButtons = [

  ];

      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, // Disable button if no users are selected
          onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
        }))
      );
      
      const columns = [   
              
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -2)*10, alignment: "left" },
        { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -2)*10, alignment: "left" },
        // { Header: 'Update', accessor: '', visible: true, width: ("".length -2)*10, alignment: "left" },
        {
                      Header: 'Update',
                      accessor: (row) => {
                        if (row.successfulrecords && uploadOption === '3') {
                          return 'Yes';
                        } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                          return 'No';
                        } else {
                          return <span style={{ color: 'red' }}>No</span>;
                        }
                      },
                      visible: true,
                      width: ("Update".length -2)*10,
                      alignment: 'left',
                    },
        { Header: 'CountryCode', accessor: 'CountryCode', visible: true, width: ("CountryCode".length -2)*10, alignment: "left" },
        { Header: 'CountryName', accessor: 'CountryName', visible: true, width: ("CountryName".length -2)*10, alignment: "left" },
        { Header: 'Language', accessor: 'Language', visible: true, width: ("Language".length -2)*10, alignment: "left" },
        { Header: 'DateFormat', accessor: 'DateFormat', visible: true, width: ("DateFormat".length -2)*10, alignment: "left" },
        { Header: 'TimeFormat', accessor: 'TimeFormat', visible: true, width: ("TimeFormat".length -2)*10, alignment: "left" },
        { Header: 'TimeZone', accessor: 'TimeZone', visible: true, width: ("TimeZone".length -2)*10, alignment: "left" },
        { Header: 'AmountFormat', accessor: 'AmountFormat', visible: true, width: ("AmountFormat".length -2)*10, alignment: "left" },
        // { Header: 'ThousandSeparator', accessor: 'ThousandSeparator', visible: true, width: ("ThousandSeparator".length -2)*10, alignment: "left" },
        // { Header: 'DecimalSeparator', accessor: 'DecimalSeparator', visible: true, width: ("DecimalSeparator".length -2)*10, alignment: "left" },
        { Header: 'AcceleratorForThousands', accessor: 'AcceleratorForThousands', visible: true, width: ("AcceleratorForThousands".length -2)*10, alignment: "left" },
        { Header: 'AcceleratorForLakhs', accessor: 'AcceleratorForLakhs', visible: true, width: ("AcceleratorForLakhs".length -2)*10, alignment: "left" },
        { Header: 'AcceleratorForMillions', accessor: 'AcceleratorForMillions', visible: true, width: ("AcceleratorForMillions".length -2)*10, alignment: "left" },
      
      ];
                    
        const [columnSettings, setColumnSettings] = useState(columns);
      
      const modifiedColumns = columnSettings
      .filter((col) => col.visible)
      .map((col) => ({
        ...col,
        width: col.width, // This should hold the updated width
      }));
  
      const isRowValid = (row, index) => {
        // Common validations
        const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
        const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
        
        const isLanguageValid = row.Language ? String(row.Language).length <= 2 : true; 
        const isDateFormatValid = row.DateFormat ? String(row.DateFormat).length <= 10 : true;
        const isTimeFormatValid = row.TimeFormat ? String(row.TimeFormat).length <= 8 : true;
        const isAmountFormatValid = row.AmountFormat ? String(row.AmountFormat).length <= 19 : true;
        // const isThousandSeparatorValid = row.ThousandSeparator ? [".", ","].includes(row.ThousandSeparator) && String(row.ThousandSeparator).length === 1 : true;
        // const isDecimalSeparatorValid = row.DecimalSeparator ? [".", ","].includes(row.DecimalSeparator) && String(row.DecimalSeparator).length === 1 : true;
        const isAcceleratorForThousandsValid = row.AcceleratorForThousands ? ["K", "T"].includes(row.AcceleratorForThousands) && String(row.AcceleratorForThousands).length === 1 : true;
        const isAcceleratorForLakhsValid = row.AcceleratorForLakhs ? ["L"].includes(row.AcceleratorForLakhs) && String(row.AcceleratorForLakhs).length === 1 : true;
        const isAcceleratorForMillionsValid = row.AcceleratorForMillions ? ["M"].includes(row.AcceleratorForMillions) && String(row.AcceleratorForMillions).length === 1 : true;
  
        let errors = [];
        
        if (!isActionValid) errors.push("Invalid Action");
        if (!isSLNoValid) errors.push("Invalid SLNo");
      
        if (!isLanguageValid) errors.push("Invalid Language");
        if (!isDateFormatValid) errors.push("Invalid Date format");
        if (!isAmountFormatValid) errors.push("Invalid amount format");
        if (!isTimeFormatValid) errors.push("Invalid time format");
        // if (!isThousandSeparatorValid) errors.push("Invalid thousandseparator");
        // if (!isDecimalSeparatorValid) errors.push("Invalid decimal separator");
        if (!isAcceleratorForThousandsValid) errors.push("Invalid accelarator of thousands");
        if (!isAcceleratorForLakhsValid) errors.push("Invalid accelarator of lakhs");
        if (!isAcceleratorForMillionsValid) errors.push("Invalid accelarator of millions");
  
        // Action "A" or "a" specific validations
        if (row.Action === "A" || row.Action === "a") {
          const isCountryCodeValid = row.CountryCode && String(row.CountryCode).length <= 2;
         
          const isCountryNameValid = row.CountryName && String(row.CountryName).length <= 30;

          if (!isCountryNameValid) errors.push("Invalid Country name");
          if (!isCountryCodeValid) errors.push("Invalid A Country code");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
          return (
            isCountryNameValid &&
          isCountryCodeValid  
          );
        }
      
        // Action "E" or "e" specific validations
        if (row.Action === "E" || row.Action === "e") {
          const isCountryCodeValid = row.CountryCode && String(row.CountryCode).length <= 2;
          const isCountryNameValid = row.CountryName ? String(row.CountryName).length <= 30 : true;

          if (!isCountryNameValid) errors.push("Invalid Country name");
          if (!isCountryCodeValid) errors.push("Invalid E Country code");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
  
          return (
            isCountryNameValid &&
            isCountryCodeValid   );
        }
      
        
        if (errors.length > 0) {
          console.log(`Rows data ${index + 1} is invalid:`, errors);
      }
  
        // Default case if no matching Action
        return (
          isActionValid &&
          isSLNoValid &&
           
          isLanguageValid &&
          isDateFormatValid &&
          isAmountFormatValid &&
          isTimeFormatValid
           &&
          // isThousandSeparatorValid &&
          //   isDecimalSeparatorValid &&
            isAcceleratorForThousandsValid &&
            isAcceleratorForLakhsValid &&
            isAcceleratorForMillionsValid
         
        );
      
      };
      
      const getExistingUserIds = async (tableData) => {
        try {
          // console.log("userIds", tableData);
    
          const validRows = tableData.filter((row, index) => isRowValid(row, index));
          console.log("valid rows checking in upload:", validRows);
            
    
          // Make API call to fetch existing user IDs
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-Countrys-duplicate/countryCode`,
            { validRows }
          );
          console.log("errored records user id's : Response data", response.data);
          const existingUserIds = response.data; // Extract existing user IDs from API response
    
          const withoutDuplicateUserIds = await filterExistingUserIds(validRows, existingUserIds);
    
          console.log("successful and processed Records data:", withoutDuplicateUserIds);
    
          return withoutDuplicateUserIds;
    
        } catch (error) {
          console.error("Error fetching existing user IDs:", error);
          return [];
        }
      };
    
    
      const filterExistingUserIds = async (tableData, existingUserIds) => {
        return tableData.filter((row) => existingUserIds.includes(row.CountryCode));
      };

  // Displaying data based on options
  const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
  
    // Decide which data to display based on displayOption and uploadOption
    let dataToDisplay;
    if (displayOption === "1") {
      console.log("Display option is 1");
      const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"
  
       // Extract the current dataToDisplay (which is only one record currently)
       const existingUserIds = new Set(existingUsers.map(user => user.CountryCode));
  
       // Filter out the currentDataToDisplay row from tableData to get other 6 rows
       dataToDisplay = tableData.filter(user => !existingUserIds.has(user.CountryCode));
   
       console.log("Filtered remaining data excluding currentDataToDisplay:", dataToDisplay);
  
    } else if (displayOption === "2") {
      console.log("Display option is 2");
      dataToDisplay = tableData; // Display tableData if displayOption is "2"
    } else if (uploadOption === "3") {
      console.log("upload option is 3");
      dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
    } 
    else {
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
  
  console.log("dataToDisplay data is",dataToDisplay);
  return dataToDisplay;
  };
  
  

  
const isExistingUserId = async (CountryCode) => {
  try {
    console.log("exisitng user id",CountryCode);
    const data = { CountryCode };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-CountryCode-duplicates`,
      data);
    console.log("existingUserId details:", response);

    // if (response?.CountryID !== undefined) {
    //   return response.CountryID; // Return the CountryID if found
    // }
    // return false; // Return false if CountryID is null
    return response?.CountryID ? response : false; 
  } catch (error) {
    console.error("Error checking existing userId:", CountryCode, error);
    return false;
  }
};


async function checkDuplicateUserIdsFromDB(countrys) {
  try {
    const data = { countrys };
    // console.log("countrys duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-Countries-duplicates`,
      data
    );

    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}


  const processData = async (data) => {
  
    // console.log("processData received  data:", data);
    const countrys = data.map(row => row.CountryCode).filter(Boolean);
    console.log("Extracted countrys:", countrys);
  
    const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(countrys);
  
     // Preprocess table data to add conditional rendering for userId
      return Promise.all(data.map(async (row, _, allRows) => {
  
    // Check for duplicate same userId is repeated in the data
    const isDuplicateUserId = allRows.filter(r => r.CountryCode == row.CountryCode).length > 1;
    console.log("Duplicate user id is ",isDuplicateUserId);
  
   // Check for duplicate userId in the database
   const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.CountryCode);
  
   const userExists = await isExistingUserId(row.CountryCode);
   console.log(`User ${row.CountryCode} exists:`, userExists);
   // Check for errors (red text) and apply highlight based on that
  
   const hasNotError = [
    row.SLNo,
    row.Action,
    row.CountryCode,
    row.CountryName,

    row.Language,
    row.DateFormat,
    row.TimeFormat,
    row.AmountFormat,
    // row.ThousandSeparator,
    // row.DecimalSeparator,
    row.AcceleratorForThousands,
    row.AcceleratorForLakhs,
    row.AcceleratorForMillions,
  
  ].every(field => {
    let isValid = true;
    let reason = "";
    
    if (field === row.SLNo) {
      if (!field) { isValid = false; reason = "SLNo should not be blank."; }
      else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
      else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
    }
    
    else if (field === row.Action) {
      if (!field) { isValid = false; reason = "Action should not be blank."; }
      else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
    }
    
    //  if (field === row.CountryCode) {
    //   if (!field) { isValid = false; reason = "Country Code should not be blank."; }
    //   else if (String(field).length > 2) { isValid = false; reason = "Country Code exceeds max length of 2."; }
    //   else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
    //     isValid = false; reason = "Country Code is duplicate.";
    //   }else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
    //     isValid = false; reason = "Country Code does not exist.";
    //   }
    // }
    
    
  if (!row.CountryCode || String(row.CountryCode).trim() === "") {
    if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
      isValid = false;
      reason = "CountryCode should not be empty.";
  }
  } else if (field === row.CountryCode) {
    if (String(field).length > 2) { 
      isValid = false; reason = "Country Code exceeds max length of 2."; 
    }
    else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
      isValid = false; reason = "Country Code does not exist.";
    } 
    else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
      isValid = false; reason = "Country Code is duplicate.";
    }
  }


    if (!row.CountryName || String(row.CountryName).trim() === "") {
      if (row.Action === "A" || row.Action === "a") {
        isValid = false;
        reason = "CountryName should not be empty.";
    }
    } else if (field === row.CountryName) {
        if (row.Action === "E" || row.Action === "e") {
            if (!field.trim()) {
                isValid = true;
                reason = "CountryName should be blank for Edit action.";
            } else if (String(field).trim().length > 30) {
                isValid = false;
                reason = "CountryName exceeds max length of 30.";
            }
        } else if (row.Action === "A" || row.Action === "a") {
            if (!field.trim()) {
                isValid = false;
                reason = "CountryName should not be blank for Add action.";
            } else if (String(field).trim().length > 30) {
                isValid = false;
                reason = "CountryName exceeds max length of 30.";
            }
        }
    }
  
  
    else if (field === row.Language) {
      if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        if (!field) { isValid = true; reason = "Language should be blank for Add/Edit action."; }
        else if (String(field).length > 2) { isValid = false; reason = "Language exceeds max length of 2."; }
      }
    }
  
else if (field === row.DateFormat) {
  if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
    if (!field) { isValid = true; reason = "DateFormat should be blank for Add/Edit action."; }
    else if (String(field).length > 10) { isValid = false; reason = "DateFormat exceeds max length of 10."; }
  }
}
  
else if (field === row.TimeFormat) {
  if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
    if (!field) { isValid = true; reason = "TimeFormat should be blank for Add/Edit action."; }
    else if (String(field).length > 8) { isValid = false; reason = "TimeFormat exceeds max length of 8."; }
  }
}
  
else if (field === row.AmountFormat) {
  if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
    if (!field) { isValid = true; reason = "AmountFormat should be blank for Add/Edit action."; }
    else if (String(field).length > 19) { isValid = false; reason = "AmountFormat exceeds max length of 19."; }
  }
}


// else if (field === row.ThousandSeparator) {
//   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
//     if (!field) { isValid = true; reason = "thousandSeparator should be blank for Add/Edit action."; }
//     else{
//       if (String(field).length > 1) { isValid = false; reason = "thousandSeparator exceeds max length of 1."; }
//       if (![",", "."].includes(field)) { isValid = false; reason = "Only ',' or '.' allowed."; }
//       if (field === row.DecimalSeparator) { isValid = false; reason = "Thousand and decimal separators cannot be the same."; }
//     }
//   }
// }

// else if (field === row.DecimalSeparator) {
//   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
//     if (!field) { isValid = true; reason = "decimalSeparator should be blank for Add/Edit action."; }
//   else{
//     if (String(field).length > 1) { isValid = false; reason = "decimalSeparator exceeds max length of 1."; }
//     if (![",", "."].includes(field)) { isValid = false; reason = "Only ',' or '.' allowed."; }
//     if (field === row.ThousandSeparator) { isValid = false; reason = "decimal and Thousand separators cannot be the same."; }
//     }
//     }
// }

else if (field === row.AcceleratorForThousands) {
  if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
    if (!field) { isValid = true; reason = "AcceleratorForThousands should be blank for Add/Edit action."; }
  else{
    if (String(field).length > 1) { isValid = false; reason = "AcceleratorForThousands exceeds max length of 1."; }
    if (!["K", "T"].includes(String(field))) { isValid = false; reason = "Only 'K' or 'T' allowed."; }
    }
    }
}

else if (field === row.AcceleratorForLakhs) {
  if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
    if (!field) { isValid = true; reason = "AcceleratorForLakhs should be blank for Add/Edit action."; }
  else{
    if (String(field).length > 1) { isValid = false; reason = "AcceleratorForLakhs exceeds max length of 1."; }
    if (!["L"].includes(String(field))) { isValid = false; reason = "Only 'L' allowed."; }
    }
    }
}


else if (field === row.AcceleratorForMillions) {
  if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
    if (!field) { isValid = true; reason = "AcceleratorForMillions should be blank for Add/Edit action."; }
  else{
    if (String(field).length > 1) { isValid = false; reason = "AcceleratorForMillions exceeds max length of 1."; }
    if (!["M"].includes(String(field))) { isValid = false; reason = "Only 'M' allowed."; }
    }
    }
}

    if (!isValid) {
      console.log(`Validation Failed: ${reason}`);
    } else {
      console.log(`Validation Passed: ${field}`);
    }
    
    return isValid;
  });
  
  console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        isDuplicateUserId,
        isDuplicateInDB,
        userExists,
  
        Action: row.Action ? (
          String(row.Action).length === 1 ? (
            ["A", "a", "E", "e"].includes(row.Action) ? (
              row.Action // Valid Action ("A", "E", "a", "e")
            ) : (
              <span style={{ color: "red" }}>
                {row.Action} (Only "A", "E" are allowed)
              </span>
            )
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Max. allowed character is 1)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>Shouldn't be blank</span>
        ),
  
        SLNo: row.SLNo ? (
            isNaN(row.SLNo) ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Mismatched Data Type)
              </span>
            ) : String(row.SLNo).length > 5 ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Max. allowed character is 5)
              </span>
            ) : (
              row.SLNo // Valid SLNo
            )
          ) : (
            <span style={{ color: "red"}}>Shouldn't be blank</span>
          ),
          
  
          CountryCode: row.CountryCode ? (
            String(row.CountryCode).length > 2 ? (
              <span style={{ color: "red" }}>
                {row.CountryCode} (Max. allowed character is 2)
              </span>
            ) : row.Action === "A" || row.Action === "a" ? (
              // When Action is "A", show duplicate message if user ID is a duplicate
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span style={{ color: "red" }}>
                  {row.CountryCode} (Country Code Duplicate exists)
                </span>
              ) : (
                String(row.CountryCode) // Otherwise, show userId normally
              )
            ) : row.Action === "E" || row.Action === "e" ? (
              // When Action is "E", show userId without the duplicate message
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span>
                  {row.CountryCode} 
                </span>
              ) : (
                // String(row.CountryCode) // Otherwise, show CountryCode normally
                <span style={{ color: "red" }}>
                {row.CountryCode} (Country Code not exists)
              </span>
              )
            ) : (
              String(row.CountryCode) // Default case, show userId normally
            )
          ) : (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ),
  
          
          CountryName: row.CountryName ? (
            String(row.CountryName).length > 30 ? (
              <span style={{ color: "red" }}>
                {row.CountryName} (Max. allowed character is 30)
              </span>
            ) : (
              String(row.CountryName) // Ensure Username is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),
  
    
          Language: row.Language ? (
            String(row.Language).length > 3 ? (
              <span style={{ color: "red"}}>
                {row.Language} (Max. allowed character is 3)
              </span>
            ) : (
                String(row.Language) // Ensure userId is treated as a string
            )
          ) : (
            <span>
             
            </span>
          ),
          
          DateFormat: row.DateFormat ? (
            String(row.DateFormat).length > 10 ? (
              <span style={{ color: "red"}}>
                {row.DateFormat} (Max. allowed character is 10)
              </span>
            ) : (
                String(row.DateFormat) // Ensure userId is treated as a string
            )
          ) : (
            <span>
             
            </span>
          ),
    
          TimeFormat: row.TimeFormat ? (
            String(row.TimeFormat).length > 8 ? (
              <span style={{ color: "red"}}>
                {row.TimeFormat} (Max. allowed character is 8)
              </span>
            ) : (
                String(row.TimeFormat) // Ensure userId is treated as a string
            )
          ) : (
            <span>
             
            </span>
          ),
    
          AmountFormat: row.AmountFormat ? (
            String(row.AmountFormat).length > 19 ? (
              <span style={{ color: "red"}}>
                {row.AmountFormat} (Max. allowed character is 19)
              </span>
            ) : (
                String(row.AmountFormat) // Ensure userId is treated as a string
            )
          ) : (
            <span>
             
            </span>
          ),

    
          // ThousandSeparator: row.ThousandSeparator ? (
          //   String(row.ThousandSeparator).length > 1 ? (
          //       <span style={{ color: "red"}}>
          //       {row.ThousandSeparator} (Max. allowed character is 1)
          //       </span>
          //   ) : [",", "."].includes(row.ThousandSeparator) ? (
          //       row.ThousandSeparator === row.DecimalSeparator ? (
          //       <span style={{ color: "red"}}>
          //           Thousand and decimal separators cannot be the same.
          //       </span>
          //       ) : (
          //       row.ThousandSeparator // Valid case
          //       )
          //   ) : (
          //       <span style={{ color: "red"}}>
          //       {row.ThousandSeparator} (Only ',' or '.' allowed)
          //       </span>
          //   )
          //   ) : (
          //   <span>
               
          //   </span>
          //   ),
    
          //   DecimalSeparator: row.DecimalSeparator ? (
          //   String(row.DecimalSeparator).length > 1 ? (
          //       <span style={{ color: "red"}}>
          //       {row.DecimalSeparator} (Max. allowed character is 1)
          //       </span>
          //   ) : [",", "."].includes(row.DecimalSeparator) ? (
          //       row.DecimalSeparator === row.ThousandSeparator ? (
          //       <span style={{ color: "red"}}>
          //           Thousand and decimal separators cannot be the same.
          //       </span>
          //       ) : (
          //       row.DecimalSeparator // Valid case
          //       )
          //   ) : (
          //       <span style={{ color: "red"}}>
          //       {row.DecimalSeparator} (Only ',' or '.' allowed)
          //       </span>
          //   )
          //   ) : (
          //   <span>
              
          //   </span>
          //   ),
    
         
            AcceleratorForThousands: row.AcceleratorForThousands ? ( 
              String(row.AcceleratorForThousands).length > 1 ? (
                  <span style={{ color: "red"}}>
                      {row.AcceleratorForThousands} (Max. allowed character is 1)
                  </span>
              ) : !["K", "T"].includes(String(row.AcceleratorForThousands)) ? (
                  <span style={{ color: "red"}}>
                      {row.AcceleratorForThousands} (Only 'K' or 'T' allowed)
                  </span>
              ) : (
                  <span>{row.AcceleratorForThousands}</span>
              )
          ) : (
              <span></span>
          ),
  
    
              AcceleratorForLakhs: row.AcceleratorForLakhs ? (
                String(row.AcceleratorForLakhs).length > 1 ? (
                    <span style={{ color: "red"}}>
                    {row.AcceleratorForLakhs} (Max. allowed character is 1)
                    </span>
                ) : !["L"].includes(String(row.AcceleratorForLakhs)) ? (
                    <span style={{ color: "red"}}>
                    {row.AcceleratorForLakhs} (Only 'L' allowed)
                    </span>
                ): (
                  <span> {row.AcceleratorForLakhs} </span>
                )
                ) : (
                <span>
                  
                </span>
                ),
  
                AcceleratorForMillions: row.AcceleratorForMillions ? (
                  String(row.AcceleratorForMillions).length > 1 ? (
                      <span style={{ color: "red"}}>
                      {row.AcceleratorForMillions} (Max. allowed character is 1)
                      </span>
                  ) : !["M"].includes(String(row.AcceleratorForMillions)) ? (
                      <span style={{ color: "red"}}>
                      {row.AcceleratorForMillions} (Only 'M' allowed)
                      </span>
                  ) : (
                    <span> {row.AcceleratorForMillions}</span>
                  )
                  ) : (
                  <span>
                    
                  </span>
                  ),
      
        };  
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
      const processed = await processData(dataToDisplay);
      setProcessedData(processed);
      setLoading(false);
    };
  
    fetchData();
  }, []);

  return (
    <div>
       <Header
        title={`Primary Data > Global Data > Country > Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/2/master-data/1/country-upload"
      />
       {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

      <PrimaryActions
          icons={iconId}
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            selectIds={selectedRows}
          />

        <ReTable
          data={processedData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        />
    </div>
  )
}
