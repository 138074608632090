import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
  IoMdCreate,
  IoMdRefresh,
  IoMdTime,
  IoMdTrash,
} from "react-icons/io";
import {
  IoAddCircleOutline,
  IoMdDownload,
  IoSettingsOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices"; // Import new service
import { useToast } from "../../../../../components/toast/toast";
import { Checkbox } from "@mui/material";
import * as XLSX from "xlsx"; // Import xlsx library
import { MdOutlineFileDownload } from "react-icons/md";
import ReusableTable from "../../Components/Table";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";
import Footer from "../../../../../components/footer";
import Pagination from "../../../../../components/PageNation";

export default function StateprovinceScreen() {
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [stateCode, setStateCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const { toast } = useToast();
  const [filteredStateprovinces, setFilteredStateprovinces] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const navigate = useNavigate();
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // const handleDelete = async (id) => {
  //   const confirmed = window.confirm(
  //     "Are you sure you want to delete this stateprovinces?"
  //   );
  //   if (confirmed) {
  //     setLoading(true);
  //     try {
  //       await StateProvinceServices.deleteStateProvince(id);
  //       toast("success", "stateprovinces deleted successfully");
  //       // Refetch stateProvinces after deletion
  //       fetchStateProvinces();
  //     } catch (error) {
  //       console.error("Error deleting stateprovinces:", error);
  //       toast("error", "Failed to delete stateprovinces");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };
  const columns = [
    {
      Header: "State",
      accessor: "state",
      visible: true,
      width: 200,
      canResize: true,
    },
    {
      Header: "State Name",
      accessor: "State_Name",
      visible: true,
      width: 200,
      canResize: true,
    },
    {
      Header: "Country Code",
      accessor: "Country_Code",
      visible: true,
      width: 200,
      canResize: true,
    },
    {
      Header: "Created By",
      accessor: "created_by",
      visible: true,
      width: 200,
      canResize: true,
    },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      },
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
    {
      Header: "Updated By",
      accessor: "changed_by",
      visible: true,
      width: 200,
      canResize: true,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  useEffect(() => {
    fetchStateProvinces(currentPage);
  }, [currentPage]);

  useEffect(() => {
    // Filter stateProvinces based on search query
    const filtered = stateProvinces.filter(
      (state) => state.state?.toLowerCase().includes(searchQuery.toLowerCase()) // Use optional chaining
    );
    setFilteredStateprovinces(filtered);
  }, [searchQuery, stateProvinces]);
  const pageSize = 20;
  const fetchStateProvinces = async (page) => {
    setLoading(true);
    try {
      const response = await StateProvinceServices.getAllStateprovincewithPage(
        pageSize,
        page
      );
      let { state, totalCount } = response.data.data;
      if (!state) {
        state = [];
      } else if (!Array.isArray(state)) {
        state = [state];
      }
      setStateProvinces(state);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / 20));
    } catch (error) {
      console.error("Error fetching StateProvince data", error);
    } finally {
      setLoading(false);
    }
  };
  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };
  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const extraButtons = [
    {
      icon: IoMdCreate,
      onClick: () => {
        navigateToForm(stateCode);
      },
      title: "Edit StateProvince",
      disabled: selectedRows.length === 0,
    },
  ];

  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredStateprovinces.map((state) => ({
      State: state.state,
      "State Name": state.State_Name,
      "Country Code": state.Country_Code,
      "Created By": state.created_by,
      "Created Date": state.creationDate
        ? new Date(state.creationDate).toLocaleDateString()
        : "",
      "Created Time": state.creationTime
        ? new Date(state.creationTime).toLocaleTimeString()
        : "",
      "Changed By": state.changed_by,
      "Changed Date": state.changedDate
        ? new Date(state.changedDate).toLocaleDateString()
        : "",
      "Changed Time": state.changedTime
        ? new Date(state.changedTime).toLocaleTimeString()
        : "",
    }));

    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "StateProvinces");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "StateProvinces_Data.xlsx");
  };
  const handleReload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchStateProvinces();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };
  const existingButtons = PrimaryActionsConfig.screen1.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add StateProvince"
        : button.icon === MdOutlineFileDownload
        ? "Download"
        : button.icon === IoSettingsOutline
        ? "Settings"
            : button.icon === IoMdRefresh
      ? "Refresh"
        : button.title || "", // If a title already exists, use it, otherwise empty
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/state-province-add")
        : button.icon === MdOutlineFileDownload
        ? handleDownload
        : button.icon === IoSettingsOutline
        ? handleSettingsButtonClick
        : button.icon === IoMdRefresh
        ? handleReload
        : button.onClick || (() => {}),
  }));
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );
  const handleNavigate = (path) => {
    navigate(path);
  };

  

  // Handle individual checkbox change
  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setStateCode(id);
    setShowExtraIcons(updatedSelectedRows.length > 0);
  };

  // Handle "Select All" checkbox change
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = stateProvinces.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };

  const navigateToForm = (id) => {
    navigate(`/state-province-add/${id}`);
  };
  const handleDoubleClick = (id) => {
    navigate(`/state-province-view/${id}`, { state: { mode: "view" } });
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Global Master Data") {
      navigate("/master-data");
    } else if (crumb.title === "State/Province") {
      navigate("/state-province");
    } else if (crumb.title === "Primary Data") {
      console.log("Navigating to /country");
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  if (loading)
    return (
      <div>
      </div>
    );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <Header
        title={"Primary Data > Global Master Data > State/Province"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <div>
        <div className="flex justify-between">
          <PrimaryActions
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            isExtraButtonsVisible={showExtraIcons}
          />

          <div className="flex items-center">
            <p className="mr-4 text-customBlue">State :</p>

            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by State"
            />
          </div>
        </div>

        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
          />
        </div>

        <ReTable
          data={filteredStateprovinces}
          columns={modifiedColumns}
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      <Footer />
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import Header from "../../../../../components/Header";
// import PrimaryActions from "../../Components/ActionButton";
// import {
//   IoMdCheckmark,
//   IoMdClose,
//   IoMdCopy,
//   IoMdCreate,
//   IoMdTime,
//   IoMdTrash,
// } from "react-icons/io";
// import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
// import { Modal, Checkbox, Input } from "@mui/material"; // Import Modal, Checkbox and Input
// import { useNavigate } from "react-router-dom";
// import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
// import StateProvinceServices from "../../../../../rest-services/StateProvinceServices";
// import { useToast } from "../../../../../components/toast/toast";

// export default function StateprovinceScreen() {
//   const [showExtraIcons, setShowExtraIcons] = useState(false);
//   const [stateProvinces, setStateProvinces] = useState([]);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [columns, setColumns] = useState([
//     { key: "State", visible: true, width: 200 },
//     { key: "State_Name", visible: true, width: 15 },
//     { key: "Country_Code", visible: true, width: 15 },
//     { key: "created_by", visible: true, width: 15 },
//     { key: "creationDate", visible: true, width: 15 },
//     { key: "creationTime", visible: true, width: 15 },
//     { key: "changed_by", visible: true, width: 15 },
//     { key: "changedDate", visible: true, width: 15 },
//     { key: "changedTime", visible: true, width: 15 },
//   ]); // Add state to manage column visibility and width
//   const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
//   const { toast } = useToast();
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const handleColumnVisibilityChange = (index) => {
//     const updatedColumns = [...columns];
//     updatedColumns[index].visible = !updatedColumns[index].visible;
//     setColumns(updatedColumns);
//   };

//   const handleColumnWidthChange = (index, value) => {
//     const updatedColumns = [...columns];
//     updatedColumns[index].width = value;
//     setColumns(updatedColumns);
//   };

//   const handleModalOpen = () => {
//     setIsModalOpen(true);
//   };

//   const handleModalClose = () => {
//     setIsModalOpen(false);
//   };

//   const fetchStateProvinces = async () => {
//     setLoading(true);
//     try {
//       const response = await StateProvinceServices.getAllStateprovince(
//         `${process.env.REACT_APP_BASE_URL}/get-stateprovince`
//       );
//       if (response && response.data) {
//         setStateProvinces(response.data.data);
//       }
//     } catch (error) {
//       console.error("Error fetching StateProvince data", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchStateProvinces();
//   }, []);

//   return (
//     <div>
//       <Header title={"Primary Data > Global Master Data > State/Province"} />
//       <PrimaryActions
//         buttons={[
//           ...PrimaryActionsConfig.screen1,
//           { icon: IoSettingsOutline, onClick: handleModalOpen }, // Add settings button to open modal
//         ]}
//       />

//       {/* Table for State/Province */}
//       <div className="p-2">
//         <div className="overflow-x-auto" style={{ maxWidth: "1400px" }}>
//           <table className="table-fixed min-w-[800px] bg-white border border-customBlue">
//             <thead className="bg-customBlue text-primary-foreground text-left text-sm font-medium sticky top-0">
//               <tr>
//                 {columns.map(
//                   (column, index) =>
//                     column.visible && (
//                       <th
//                         key={index}
//                         className="py-2 px-4 text-center text-white font-medium border-r border-sky-100"
//                         style={{ width: `${column.width}px` }}
//                       >
//                         {column.key}
//                       </th>
//                     )
//                 )}
//               </tr>
//             </thead>
//             <tbody className="text-sm">
//               {stateProvinces.map((data) => (
//                 <tr key={data.id}>
//                   {columns.map(
//                     (column, index) =>
//                       column.visible && (
//                         <td
//                           key={index}
//                           className="py-2 px-4 text-center border-r border-sky-100 whitespace-nowrap"
//                           style={{ width: `${column.width}px` }}
//                         >
//                           {data[column.key] || ""}
//                         </td>
//                       )
//                   )}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>

//       {/* Modal for Column Settings */}
//       <Modal open={isModalOpen} onClose={handleModalClose}>
//         <div className="bg-white p-6 rounded-lg max-w-md mx-auto mt-16 shadow-lg">
//           <h2 className="text-lg font-bold mb-4">Customize Columns</h2>
//           {columns.map((column, index) => (
//             <div key={index} className="flex items-center mb-4">
//               <Checkbox
//                 checked={column.visible}
//                 onChange={() => handleColumnVisibilityChange(index)}
//               />
//               <span className="mr-4">{column.key}</span>
//               <Input
//                 type="number"
//                 value={column.width}
//                 onChange={(e) => handleColumnWidthChange(index, e.target.value)}
//                 placeholder="Width"
//                 className="w-20"
//               />
//             </div>
//           ))}
//           <button
//             onClick={handleModalClose}
//             className="bg-blue-500 text-white py-2 px-4 rounded mt-4"
//           >
//             Close
//           </button>
//         </div>
//       </Modal>
//     </div>
//   );
// }
