import React, { useEffect, useState } from "react";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import { useNavigate, useParams } from "react-router-dom";
import BankAccountContent from "../Add/BankAccountContent";
import BankAddressContent from "../Add/BankAddressContent";
import Header from "../../../../../../components/Header";
import Tabs from "../../../../../../components/Tabs";
import IdentifiersContent from "../Add/IdentifiersContent";
import BankstatementsContent from "../Add/BankstatementsContent";
import ValidityContents from "../Add/ValidityContents";
import OdCcContents from "../Add/OdCcContents";
import ControlsContents from "../Add/ControlsContents";
import TextfieldsContents from "../Add/TextfieldsContents";
import BankBranchServices from "../../../../../../rest-services/BankBranchServices";
import BamServices from "../../../../../../rest-services/BamServices";
import Footer from "../../../../../../components/footer";
import ReTable from "../../../../../../components/Table";

export default function BankAccountMasterAdd() {
  const navigate = useNavigate();
  const { actionId } = useParams();

  // State to track the active tab
  const [activeTab, setActiveTab] = useState("bankaccount");

  // Function to handle tab clicks
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    { value: "bankaccount", label: "Bank Account" },
    { value: "bankaddress", label: "Bank Address" },
    { value: "identifiers", label: "Identifiers" },
    { value: "bankstatements", label: "Bank Statements" },
    { value: "validity", label: "Validity" },
    { value: "odcc", label: "OD/CC" },
    { value: "controls", label: "Controls" },
    { value: "textfields", label: "Text Fields" },
  ];

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Bank Master Data") {
      navigate("/account-master");
    } else if (crumb.title === "Account Master") {
      navigate("/account-master");
    } else if (crumb.title === "Add") {
      navigate("/account-master-add");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  //Bank Account
  const isAcNoDisabled = actionId === "1";

  //DropDown Values
  const [branch, setBranch] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [zbaType, setZbaType] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [legalEntity, setLegaleEntity] = useState([]);
  const [company, setCompany] = useState([]);

  //To save the or assing value for Account details form
  const [reqNo, setReqNo] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [branchID, setBranchID] = useState("");
  const [bankID, setBankID] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankIDName, setBankIDName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bank, setBank] = useState("");
  const [currencyid, setCurrencyid] = useState("");
  const [accountId, setAccountId] = useState("");
  const [zbaid, setZbaid] = useState("");
  const [overDraft, setOverDraft] = useState("");
  const [notes, setNotes] = useState("");

  //For an Entity
  const [legalEntityID, setLegalEntityID] = useState("");
  const [legalEntityName, setLegalEntityName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [buGroup, setBuGroup] = useState("");
  const [buGroupName, setBuGroupName] = useState("");

  //To save the or assing value for Address details form
  const [doorNo, setDoorNo] = useState("");
  const [floorNo, setFloorNo] = useState("");
  const [buildingNo, setBuildingNo] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [landMark, setLandMark] = useState("");
  const [streetNo, setStreetNo] = useState("");
  const [roadNo, setRoadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [areaBlock, setAreaBlock] = useState("");
  const [areaName, setAreaName] = useState("");
  const [country, setCountry] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countriesName, setCountriesName] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [postalCode, setpostalCode] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setState] = useState("");
  const [stateName, setStateName] = useState("");

  useEffect(() => {
    fetchBranches();
    fetcAccountType();
    fetchZbaType();
    fetchCurrencies();
    fetchLegalEntity();
  }, []);

  // useEffect(() => {
  //   if (branchID && branch.length > 0) {
  //     const selectedBranch = branch.find(
  //       (item) => item.id.toString() === branchID
  //     );
  //     if (selectedBranch) {
  //       setBranchName(selectedBranch.branchName);
  //       setBankName(selectedBranch.BankName);
  //       setBank(selectedBranch.Bank);
  //       setBankID(selectedBranch.BankId);
  //     }
  //   }
  // }, [branchID, branch]);

  const fetchBranches = async () => {
    try {
      const response = await BankBranchServices.getAllBankBranch(
        "/get-all-bankbranch"
      );
      if (response && response.data) {
        setBranch(response.data);
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetcAccountType = async () => {
    try {
      const response = await BamServices.getAccountType();
      if (response && response.data) {
        setAccountType(response.data.data);
      }
    } catch (error) {}
  };

  const fetchZbaType = async () => {
    try {
      const response = await BamServices.getAllZBATypes();
      if (response && response.data) {
        setZbaType(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const generateReqNo = () => {
    const min = 100000;
    const max = 999999;
    const randomNo = Math.floor(Math.random() * (max - min + 1)) + min;
    setReqNo(randomNo.toString());
    if (isAcNoDisabled) {
      setAccountStatus("Requested");
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await BamServices.getAllCurrency();
      if (response && response.data) {
        setCurrency(response.data.data);
      }
    } catch (error) {}
  };

  const fetchLegalEntity = async () => {
    try {
      const response = await BamServices.getAllLegalEntity();
      if (response && response.data) {
        setLegaleEntity(response.data);
      }
    } catch (error) {}
  };
  const handleBranchChange = async (event) => {
    setBranchID(event.target.value);

    const selectedBranch = branch.find(
      (item) => item.id.toString() === event.target.value
    );
    setBranchName(selectedBranch.branchName);
    setBankName(selectedBranch.BankName);
    setBank(selectedBranch.Bank);
    setBankID(selectedBranch.BankId);
    if (selectedBranch.addresses.length > 0) {
      const address = selectedBranch.addresses[0];
      setDoorNo(address.doorNo || "");
      setFloorNo(address.floorNo || "");
      setBuildingNo(address.buildingNo || "");
      setBuildingName(address.buildingName || "");
      setLandMark(address.landMark || "");
      setStreetNo(address.streetNo || "");
      setRoadNo(address.roadNo || "");
      setRoadName(address.roadName || "");
      setStreetName(address.streetName || "");
      setAreaBlock(address.areaBlock || "");
      setAreaName(address.areaName || "");
      setStateProvinces(address.state || "");
      setCountry(address.countryCode || "");
      setZipCode(address.zipCode || "");
      setaddressLine1(address.addressLine1 || "");
      setaddressLine2(address.addressLine2 || "");
      setaddressLine3(address.addressLine3 || "");
      setdistrict(address.district || "");
      setpostalCode(address.postalCode || "");
      setZipCode(address.zipCode || "");
      setpoBox(address.poBox || "");
    }
  };

  const handleLegalEntityChange = async (event) => {
    setLegalEntityID(event.target.value);
    const selectedEntity = legalEntity.find(
      (item) => item.id.toString() === event.target.value
    );
    setLegalEntityName(selectedEntity.LGE_Description);

    const response = await BamServices.getAssociatedCompany(
      selectedEntity.LGE_Code
    );
    console.log(response.data);

    setCompany(Array.isArray(response.data) ? response.data : [response.data]);
  };

  const handleCompanyChange = async (event) => {
    setCompanyId(event.target.value);
    const selectedCompany = company.find(
      (item) => item.id.toString() === event.target.value
    );
    setCompanyName(selectedCompany.companyShortName);
    setBuGroup(selectedCompany.buGroupCode);
    setBuGroupName(selectedCompany.buGroupName);
  };

  //Tab Siwitching with data
  const nextToAddress = () => {
    setActiveTab("bankaddress");
  };

  const nextToIdentifiers = () => {
    setActiveTab("identifiers");
  };

  //Identfiers
  const [bankBic, setBankBic] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeType, setCountryCodeType] = useState("");
  const [micr, setMicr] = useState("");

  //Bank Statements
  const [statementId, setStatementId] = useState("");
  const [statementIdentifier, setStatementIdentifier] = useState("");
  const [lastStatementDate, setLastStatementDate] = useState("");
  const [lastStateClosingbalance, setLastStateClosingBalance] = useState("");
  const [lastStatementNo, setLastStatementNo] = useState("");

  // Validitiy
  const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows
  const [tableData, setTableData] = useState([
    {
      id: 1,
      BankAccountCode: "ICICI6237283",
      CompanyCode: "TATA Motors",
      Status: "01.07.2024",
      ValidFrom: "31.12.1999",
      ValidTo: "01.07.2024",
      UpdatedBy: "Admin1",
      UpdatedDate: "01.02.2024",
      UpdatedTime: "10:00 AM",
    },
    {
      id: 2,
      BankAccountCode: "HDFC7890456",
      CompanyCode: "Reliance",
      Status: "01.08.2024",
      ValidFrom: "01.01.2000",
      ValidTo: "01.08.2024",
      UpdatedBy: "Admin2",
      UpdatedDate: "05.03.2024",
      UpdatedTime: "11:00 AM",
    },
    {
      id: 2,
      BankAccountCode: "HDFC7890456",
      CompanyCode: "Reliance",
      Status: "01.08.2024",
      ValidFrom: "01.01.2000",
      ValidTo: "01.08.2024",
      UpdatedBy: "Admin2",
      UpdatedDate: "05.03.2024",
      UpdatedTime: "11:00 AM",
    },
    {
      id: 2,
      BankAccountCode: "HDFC7890456",
      CompanyCode: "Reliance",
      Status: "01.08.2024",
      ValidFrom: "01.01.2000",
      ValidTo: "01.08.2024",
      UpdatedBy: "Admin2",
      UpdatedDate: "05.03.2024",
      UpdatedTime: "11:00 AM",
    },
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Bank Account Code",
        accessor: "BankAccountCode",
        width: 150,
      },
      {
        Header: "Company Code",
        accessor: "CompanyCode",
        width: 150,
      },
      {
        Header: "Status",
        accessor: "Status",
        width: 200,
      },
      {
        Header: "Valid From",
        accessor: "ValidFrom",
        width: 200,
      },
      {
        Header: "Valid To",
        accessor: "ValidTo",
        width: 200,
      },
      {
        Header: "Updated By",
        accessor: "UpdatedBy",
        width: 200,
      },
      {
        Header: "Updated Date",
        accessor: "UpdatedDate",
        width: 200,
      },
      {
        Header: "Updated Time",
        accessor: "UpdatedTime",
        width: 200,
      },
    ],
    []
  );

  // Function to handle row selection
  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  // Function to handle select all checkbox
  const onSelectAll = () => {
    if (selectedRows.length === tableData.length) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(tableData.map((row) => row.id)); // Select all
    }
  };

  // Function to handle double-click on a row
  const onRowDoubleClick = (rowId) => {
    console.log("Double clicked row with id:", rowId);
  };

  //OD/CC
  const [odLimit, setOdLimit] = useState("");
  const [intrestIndicator, setIntrestIndicator] = useState("");
  const [odDate, setOdDate] = useState("");

  //Controls
  const [minimumBalance, setMinimumBalance] = useState("");
  const [dormant, setDormant] = useState("");
  const [isZbaType, setIsZbaType] = useState(0);
  const [zbaTypeControlls, setZbaTypeControlls] = useState("");
  const [mainBankAccount, setMainBankAccount] = useState("");

  //Text Fields
  const [textFields1, setTextFields1] = useState([]);
  const [textFields2, setTextFields2] = useState([]);
  const [textFields3, setTextFields3] = useState([]);
  const [textFields4, setTextFields4] = useState([]);
  const [textFields5, setTextFields5] = useState([]);

  const handleSave = async () => {
    try {
      const payload = {
        odLimit,
        intrestIndicator,
        odDate,
        // Controls
        minimumBalance,
        dormant,
        isZbaType,
        zbaTypeControlls,
        mainBankAccount,
        // Text Fields
        textFields1,
        textFields2,
        textFields3,
        textFields4,
        textFields5,
        bankBic,
        countryCode,
        countryCodeType,
        micr,
        // Bank Statements
        statementId,
        statementIdentifier,
        lastStatementDate,
        lastStateClosingbalance,
        lastStatementNo,
        // Address Information
        doorNo,
        floorNo,
        buildingNo,
        buildingName,
        landMark,
        streetNo,
        roadNo,
        roadName,
        streetName,
        areaBlock,
        areaName,
        country,
        addressLine1,
        addressLine2,
        addressLine3,
        countriesName,
        stateProvinces,
        zipCode,
        postalCode,
        district,
        poBox,
        state,
        stateName,
        // Additional Information
        reqNo,
        accountStatus,
        branchID,
        bankID,
        branchName,
        bankIDName,
        bankName,
        bank,
        currencyid,
        accountId,
        zbaid,
        overDraft,
        notes,
        // Legal Entity Information
        legalEntityID,
        legalEntityName,
        companyId,
        companyName,
        buGroup,
        buGroupName,
      };

      const response = await BamServices.addBankAccountMaster(payload);
      console.log("Save successful:", response);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <div>
      <div>
        <Header
          title={"BAM > Bank Master Data > Account Master > Add"}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
      {/*Bank Account*/}
      {activeTab === "bankaccount" && (
        <div className="p-4 mb-40">
          {/** Account Details */}
          <div className="mb-6 mx-10 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Account details
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  New Req No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={reqNo}
                  onClick={generateReqNo}
                  readOnly
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Account Status{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={accountStatus}
                  readOnly
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Branch ID
                </label>
                <span className="ml-4">:</span>
                <select
                  value={branchID}
                  onChange={(e) => handleBranchChange(e)}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                >
                  <option value="">Select Branch ID</option>
                  {branch.length > 0 ? (
                    branch.map((branch) => (
                      <option key={branch.id} value={branch.id}>
                        {branch.id}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </select>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank ID{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  placeholder="B name"
                  value={bankID}
                  readOnly
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Branch Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  placeholder="2A"
                  value={branchName}
                  readOnly
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank ID Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={bankName}
                  readOnly
                  type="text"
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Currency{" "}
                </label>
                <span className="ml-4">:</span>
                <select
                  value={currencyid}
                  onChange={(e) => setCurrencyid(e.target.value)}
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                >
                  <option value="" id="">
                    Select Currency
                  </option>
                  {currency.length > 0 ? (
                    currency.map((currency) => (
                      <option
                        key={currency.id}
                        id={currency.id}
                        value={currency.currencyCode}
                      >
                        {currency.currencyCode}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </select>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={bank}
                  readOnly
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-9 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Account Type{" "}
                </label>
                <span className="ml-4">:</span>
                <select
                  value={accountId}
                  onChange={(e) => setAccountId(e.target.value)}
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-9 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                >
                  <option value="">Select Account Type</option>
                  {accountType.length > 0 ? (
                    accountType.map((accountType) => (
                      <option key={accountType.id} value={accountType.id}>
                        {accountType.Account_Type}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </select>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={bankName}
                  readOnly
                  type="text"
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  ZBA Type{" "}
                </label>
                <span className="ml-4">:</span>
                <select
                  value={zbaid}
                  onChange={(e) => setZbaid(e.target.value)}
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                >
                  <option value="">Select ZBA Type</option>
                  {zbaType.length > 0 ? (
                    zbaType.map((zbaType) => (
                      <option key={zbaType.id} value={zbaType.id}>
                        {zbaType.ZBA_ID}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </select>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Over Draft{" "}
                </label>
                <span className="ml-4">:</span>
                <select
                  value={overDraft}
                  onChange={(e) => setOverDraft(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>

            <div className="flex items-center mt-4">
              <label className="w-52 2xl:w-48 text-sm font-medium text-gray-500">
                Notes
              </label>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 "
              />
            </div>
          </div>
          {/** Account No*/}
          <div className="mb-6 border mx-10 border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Account No
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank Account ID
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  disabled={isAcNoDisabled}
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Bank Account ID Description
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  disabled={isAcNoDisabled}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Account No
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  disabled={isAcNoDisabled}
                />
              </div>
            </div>
            <div className="flex items-center mt-4">
              <label className="w-52 2xl:w-48 text-sm font-medium text-gray-500">
                IBAN No
              </label>
              <span className="ml-4">:</span>
              <input
                type="text"
                className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                disabled={isAcNoDisabled}
              />
            </div>
          </div>
          {/* For An Entity */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg mx-10">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              For an Entity
            </h3>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Legal Entity <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <select
                  value={legalEntityID}
                  onChange={(e) => handleLegalEntityChange(e)}
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-9 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                >
                  <option value="">Select Legal Entity</option>
                  {legalEntity.length > 0 ? (
                    legalEntity.map((legalEntity) => (
                      <option key={legalEntity.id} value={legalEntity.id}>
                        {legalEntity.LGE_Code}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </select>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  LE Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={legalEntityName}
                  type="text"
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Company Code
                </label>
                <span className="ml-4">:</span>
                <select
                  value={companyId}
                  onChange={(e) => handleCompanyChange(e)}
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-9 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                >
                  <option value="">Select Company Code</option>
                  {company ? (
                    company.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.companyCode}
                      </option>
                    ))
                  ) : (
                    <option value="">Loading...</option>
                  )}
                </select>
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Company Short Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={companyName}
                  readOnly
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  BU Group{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={buGroup}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  BU Group Description{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={buGroupName}
                  readOnly
                  type="text"
                  placeholder="B name"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              nextToAddress();
            }}
            className="bg-customBlue text-sm text-white py-1 px-2 ml-10 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 "
          >
            Next
          </button>
          <Footer>
            <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
              Save
            </button>
            <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
              Save & Continue
            </button>
          </Footer>
        </div>
      )}
      {/*Bank Address*/}
      {activeTab === "bankaddress" && (
        <div className="p-4 mb-60">
          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Building
            </h3>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Door No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={doorNo}
                  readOnly
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Floor No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={floorNo}
                  readOnly
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Building No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={buildingNo}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Building Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={buildingName}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Landmark{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={landMark}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          {/** */}
          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Street</h3>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Street No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={streetNo}
                  readOnly
                  type="text"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Street Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={streetName}
                  readOnly
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Road No{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={roadNo}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Road Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={roadName}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Area</h3>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Block{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={areaBlock}
                  readOnly
                  type="text"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Area Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={areaName}
                  readOnly
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Address
            </h3>

            <div className="grid grid-cols-1 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Address 1{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={addressLine1}
                  type="text"
                  className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Address 2{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={addressLine2}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Address 3{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={addressLine3}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>

          <div className="mb-6 border border-gray-300 p-5 mx-10 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">
              Country
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Country Code <span className="text-red-500">*</span>
                </label>
                <span className="ml-4">:</span>
                <input
                  value={country}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
                {/* <select
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                /> */}
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Country Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={countriesName}
                  readOnly
                  type="text"
                  placeholder="4"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  State / Province<span className="text-red-500">*</span>{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={stateProvinces}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
                {/* <select
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                /> */}
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  State / Province Name{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={stateName}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  District{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={district}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Postal Code{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={postalCode}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  Zip Code{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={zipCode}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">
                  PO Box{" "}
                </label>
                <span className="ml-4">:</span>
                <input
                  value={poBox}
                  readOnly
                  type="text"
                  placeholder="2A"
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              nextToIdentifiers();
            }}
            className="bg-customBlue text-sm text-white py-1 px-2 ml-10 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 "
          >
            Next
          </button>
          <Footer>
            <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
              Save
            </button>
            <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
              Save & Continue
            </button>
          </Footer>
        </div>
      )}
      {/*Identifiers*/}
      {activeTab === "identifiers" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Bank BIC
              </span>
              <span className="ml-3">:</span>
              <input
                value={bankBic}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Country Code
              </span>
              <span className="ml-3">:</span>
              <input
                value={countryCode}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Country Code Type
              </span>
              <span className="ml-3">:</span>
              <input
                value={countryCodeType}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                MICR
              </span>
              <span className="ml-3">:</span>
              <input
                value={micr}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
          </div>
          <div className="ml-2">
            <button className="bg-customBlue text-sm text-white py-1 px-2 ml-8 mt-10 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
              Next
            </button>
          </div>
          <Footer>
            <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
              Save
            </button>
            <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
              Save & Continue
            </button>
          </Footer>
        </div>
      )}
      {/*Bank Statements*/}
      {activeTab === "bankstatements" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Statement Type<span className="text-red-500">*</span>
              </span>
              <span className="ml-3">:</span>
              <select
                type="number"
                className="border p-1.5 rounded w-72 h-9 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
                value={statementId}
                onChange={(e) => setStatementId(e.target.value)}
              >
                <option value="0">Select Statement Type</option>
                <option value="1">Opening</option>
                <option value="2">Closing</option>
              </select>
            </label>
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Statement identifier
              </span>
              <span className="ml-3">:</span>
              <input
                onChange={(e) => setStatementIdentifier(e.target.value)}
                value={statementIdentifier}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Last Statement date
              </span>
              <span className="ml-3">:</span>
              <input
                onChange={(e) => setLastStatementDate(e.target.value)}
                value={lastStatementDate}
                type="date"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Last statement Closing balance
              </span>
              <span className="ml-3">:</span>
              <input
                onChange={(e) => setLastStateClosingBalance(e.target.value)}
                value={lastStateClosingbalance}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-60 text-sm font-medium text-gray-500">
                Last Statement No
              </span>
              <span className="ml-3">:</span>
              <input
                onChange={(e) => setLastStatementNo(e.target.value)}
                value={lastStatementNo}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
          </div>
          <div className="ml-2">
            <button className="bg-customBlue text-sm text-white py-1 px-2 ml-8 mt-10 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
              Next
            </button>
          </div>
          <Footer>
            <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
              Save
            </button>
            <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
              Save & Continue
            </button>
          </Footer>
        </div>
      )}
      {/*Validity*/}
      {activeTab === "validity" && (
        <div className="mt-10">
          <ReTable
            data={tableData}
            columns={columns}
            onSelectRow={onSelectRow}
            selectedRows={selectedRows}
            onSelectAll={onSelectAll}
            onRowDoubleClick={onRowDoubleClick}
          />
          <Footer>
            <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
              Save
            </button>
            <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
              Save & Continue
            </button>
          </Footer>
        </div>
      )}
      {/*OD/CC*/}
      {activeTab === "odcc" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                OD limit
              </span>
              <span className="ml-3">:</span>
              <input
                value={odLimit}
                onChange={(e) => setOdLimit(e.target.value)}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Intrest Indicator
              </span>
              <span className="ml-3">:</span>
              <input
                value={intrestIndicator}
                onChange={(e) => setIntrestIndicator(e.target.value)}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                OD Date
              </span>
              <span className="ml-3">:</span>
              <input
                value={odDate}
                onChange={(e) => setOdDate(e.target.value)}
                type="Date"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <button className="bg-customBlue text-sm text-white py-1 px-2 mt-6 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
              Next
            </button>
          </div>
          <Footer>
            <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
              Save
            </button>
            <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
              Save & Continue
            </button>
          </Footer>
        </div>
      )}
      {/*Controls*/}
      {activeTab === "controls" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Minimum Balance
              </span>
              <span className="ml-3">:</span>
              <input
                value={minimumBalance}
                onChange={(e) => setMinimumBalance(e.target.value)}
                type="number"
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Dormant
              </span>
              <span className="ml-3 ">:</span>
              <input
                value={dormant}
                onChange={(e) => setDormant(e.target.value)}
                type="number"
                className="border p-1.5 rounded w-14 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
              <span className="ml-6 text-sm font-medium text-gray-500">
                Months
              </span>
            </label>
            <label className="flex items-center mt-8 mb-4">
              <input
                type="checkbox"
                checked={isZbaType === 1}
                onChange={(e) => setIsZbaType(e.target.checked ? 1 : 0)}
                className="form-checkbox text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
              />
              <span className="ml-4 text-sm font-semibold text-gray-600">
                Zero Balance Account
              </span>
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                ZBA Type
              </span>
              <span className="ml-3">:</span>
              <select
                onChange={(e) => setZbaType(e.target.value)}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Main Bank Account
              </span>
              <span className="ml-3">:</span>
              <select
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <button className="bg-customBlue text-sm text-white py-1 px-2 mt-6 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
              Next
            </button>
          </div>
          <Footer>
            <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
              Save
            </button>
            <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
              Save & Continue
            </button>
          </Footer>
        </div>
      )}
      {/*Textfields Contents*/}
      {activeTab === "textfields" && (
        <div className="p-4 mb-40">
          <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 1
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields1}
                type="text"
                onChange={(e) => setTextFields1(e.target.value)}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 2
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields2}
                type="text"
                onChange={(e) => setTextFields2(e.target.value)}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 3
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields3}
                type="text"
                onChange={(e) => setTextFields3(e.target.value)}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 4
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields4}
                type="text"
                onChange={(e) => setTextFields4(e.target.value)}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">
                Text 5
              </span>
              <span className="ml-3">:</span>
              <input
                value={textFields5}
                type="text"
                onChange={(e) => setTextFields5(e.target.value)}
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
          </div>
          <div className="ml-2"></div>
          <Footer>
            <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
              Save
            </button>
            <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
              Save & Continue
            </button>
          </Footer>
        </div>
      )}
    </div>
  );
}
