import React from 'react';

export default function CustomButton({ onClick, title, className }) {
  return (
    <button
      onClick={onClick}
      className={`w-16 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-[#0B5299] transition duration-200 ease-in-out ${className}`}
    >
      {title}
    </button>
  );
}
