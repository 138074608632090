import React, { useState } from "react";

import { BamButtonsConfigs } from "../../../Components/BamButtonsConfig";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import BamActions from "../../../Components/BamActions";
import { useNavigate } from "react-router-dom";
import { IoAddCircleOutline } from "react-icons/io5";
import BankMasterDataTable from "../../../Tables/BankMasterData/BankMasterDataTable";
import { IoMdCheckmark, IoMdClose, IoMdCreate } from "react-icons/io";
import Header from "../../../../../../components/Header";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdEdit, MdOutlineHistory } from "react-icons/md";
import { PiCopy } from "react-icons/pi";

export default function BankAccountMaster() {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Bank Master Data") {
      navigate("/account-master");
    } else if (crumb.title === "Account Master") {
      navigate("/account-master");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  const buttons = BamButtonsConfigs.screen5.map((button) => ({
    ...button,
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/account-master-add/1")
        : button.onClick,
  }));
  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setShowExtraIcons(updatedSelectedRows.length > 0);
  };
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const extraButtons = [
    {
      icon: IoMdCheckmark,
      title: "Approve",
      disabled: selectedRows.length !== 0,
    },
    {
      icon: IoMdClose,
      title: "Reject",
      disabled: selectedRows.length === 0,
    },
    {
      icon: FaRegEdit,
      onClick: () => console.log("Edit-frame"),
      title: "Special Edit",
      disabled: selectedRows.length === 0,
    },
    { icon: MdEdit, onClick: () => console.log("Edit") },
    { icon: PiCopy, onClick: () => console.log("Copy") },
    { icon: MdOutlineHistory, onClick: () => console.log("History") },
    { icon: MdDelete, onClick: () => console.log("Delete") },
  ];
  return (
    <div>
      <div>
        <Header
          title={"BAM > Bank Master Data > Account Master"}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>
      <div>
        <BamActions
          buttons={buttons}
          extraButtons={extraButtons}
          isExtraButtonsVisible={showExtraIcons}
        />
      </div>
      <BankMasterDataTable />
    </div>
  );
}
