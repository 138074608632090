import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import { Alert, Checkbox } from "@mui/material";
import UserService from "../../../../../../rest-services/UserServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../../../components/toast/toast";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ButtonGroup from "../../../../../../components/FooterButton";
import Header from "../../../../../../components/Header";
import Tabs from "../../../../../../components/Tabs";
import { RxDropdownMenu } from "react-icons/rx";
import Footer from "../../../../../../components/footer";
import LanguageServices from "../../../../../../rest-services/LanguagesServices";
import { TbFlag3Filled } from "react-icons/tb";
import LabelWithButton from "../../../../../../components/InputButtonField";
import ReusableModal from "../../../../../../components/PopupComponent";
import CustomDropdown from "../../../../../../components/CustomDropdown";
import CustomDatePicker from "../../../../../../components/DatePicker";
import DatePickerComponent from "../../../../../../components/DatePicker";
import TableWrapper from "../../../../../../components/ScrollBarComponent";
import MainHeader from "../../../../../../components/MainHeader";
import PasswordField from "../../../../../../components/Textfield";
import HomeService from "../../../../../../rest-services/HomeServices";
import CustomButton from "../../../../../../components/CustomButton";
import { IoAddCircleOutline, IoChevronDown, IoChevronForward, IoSettingsOutline } from "react-icons/io5";
import { IoMdCheckmark, IoMdClose, IoMdCreate, IoMdRefresh, IoMdTrash } from "react-icons/io";
import { PiCopy, PiNotEquals } from "react-icons/pi";
import { MdOutlineFileDownload, MdOutlineFileUpload, MdOutlineHistory, MdOutlineLock, MdOutlineMotionPhotosAuto, MdOutlineSaveAs } from "react-icons/md";
import { LuCopyCheck, LuKeyRound } from "react-icons/lu";
import { VscVmActive } from "react-icons/vsc";
import { GiSettingsKnobs } from "react-icons/gi";
import { BiSave } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { BsTags, BsUbuntu } from "react-icons/bs";
import { FiBox } from "react-icons/fi";
export default function UserAdd() {
  const navigate = useNavigate();
  const { iconId } = useParams();
  const { UserId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isProfileMode = mode === 'profile';
  const isViewMode = mode === 'view';
  const isEditMode = mode === 'Edit';
  const isCopyAddMode = mode === 'add';

  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("basicDetail");
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [userId, setUserId] = useState("");
  const [username, setUserName] = useState("");
  const [accountingSystemId, setAccountingSystemId] = useState("");
  const [salute, setSalute] = useState("");
  const [userType, setUserType] = useState(0);
  const [userTypes, setUserTypes] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [oneTimePassword, setOneTimePassword] = useState("");
  const [receiveSystemMail, setreceiveSystemMail] = useState("");
  const [downloadData, setdownloadData] = useState("");
  const [changesInCashflow, setchangesInCashflow] = useState("");
  const [changeVarient, setchangeVarient] = useState("");
  const [deleteAllRecords, setdeleteAllRecords] = useState("");
  const [changeAccountingCode, setchangeAccountingCode] = useState("");
  const [updateCountry, setupdateCountry] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [surname, setSurname] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [officeNo, setOfficeNo] = useState("");
  const [extension, setExtension] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [drivingLicenseNo, setDrivingLicenseNo] = useState("");
  const [localId, setLocalId] = useState("");
  const [nationality, setNationality] = useState("");
  const [employeeNo, setemployeeNo] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setstate] = useState("");
  const [stateName, setStateName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryId, setCountryId] = useState('');
  const [areaBlock, setareaBlock] = useState("");
  const [roadNo, setroadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetNo, setstreetNo] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [buildingNo, setbuildingNo] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [floorNo, setfloorNo] = useState("");
  const [department, setdepartment] = useState("");
  const [validFrom, setvalidFrom] = useState("");
  const [validTo, setvalidTo] = useState("");
  const [joiningDate, setjoiningDate] = useState("");
  const [resignationDate, setresignationDate] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [language, setlanguage] = useState("");
  const [languages, setlanguages] = useState([]);

  const [dateFormat, setdateFormat] = useState("DD-MM-YYYY");
  const [timeFormat, settimeFormat] = useState("");
  const [timeZone, settimeZone] = useState("");
  // const [countryId, setcountryId] = useState("");
  // const [countries, setCountry] = useState([]);
  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [companyCodes, setCompanyCodes] = useState([]);

  const [approved, setApproved] = useState("");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [amountFormat, setamountFormat] = useState("");
  const [postalCode, setpostalCode] = useState("");
  // const [thousandSeparator, setthousandSeparator] = useState("");
  // const [decimalSeparator, setdecimalSeparator] = useState("");

  const [acceleratorForThousands, setacceleratorThousands] = useState("");

  // Initialize these states only if UserId is not present
  //  const [suffixForThousands, setSuffixForThousands] = useState(
  //   !UserId ? 'K' : ''
  // );
  const [suffixForThousands, setSuffixForThousands] = useState('');

  const [errorThousands, setErrorThousands] = useState(
    !UserId ? false : null
  );

  // const [suffixForThousands, setSuffixForThousands] = useState('K'); // Holds the suffix ('K' or 'T')
  // const [errorThousands, setErrorThousands] = useState(false);

  const [acceleratorForLakhs, setacceleratorLakhs] = useState("");
  // const [suffixForLakhs, setSuffixForLakhs] = useState(
  //   !UserId ? 'L' : '');

  const [suffixForLakhs, setSuffixForLakhs] = useState('');

  const [errorLakhs, setErrorLakhs] = useState(!UserId ? false : null);

  const [acceleratorForMillions, setacceleratorMillions] = useState("");
  // const [suffixForMillions, setSuffixForMillions] = useState(
  //   !UserId ? 'M' : ''); 
  const [suffixForMillions, setSuffixForMillions] = useState('');
  const [errorMillions, setErrorMillions] = useState(!UserId ? false : null);

  const [loginMethod, setLoginMethod] = useState(0);

  const [alert, setAlert] = useState(null);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [selectedState, setSelectedState] = useState(state || "");
  const [companyCodeId, setcompanyCodeId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(companyCodeId || "");
  const [selectedlanguage, setSelectedLanguae] = useState("");

  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [searchQueryCompanyCode, setSearchQueryCompanyCode] = useState(""); // State for search query
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);

  const [openDropdown, setOpenDropdown] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [phase, setPhase] = useState("");
  const [sector, setSector] = useState("");
  const [village, setVillage] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");


  const [pdfMode, setPdfMode] = useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(null); // Store the PDF URL
  const [isModalOpenForModule, setIsModalOpenForModule] = useState(false);
  const [isModalOpenForSubModule, setIsModalOpenForSubModule] = useState(false);
  const [isModalOpenForIconModule, setIsModalOpenForIconModule] =
    useState(false);
  const [mainMenu, setMainMenu] = useState("");
  const [subMenu, setSubMenu] = useState("");
  const [iconMenu, setIconMenu] = useState("");
  const [searchQueryMainmodule, setSearchQueryMainmodule] = useState(""); // State for search query
  const [selectMainMenu, setselectMainMenu] = useState(mainMenu || "");
  const [selectSubMenu, setselectSubMenu] = useState("");
  const [selectIconMenu, setselectIconMenu] = useState("");
  const [Icons, setIcons] = useState([]);
  const [searchQuerySubmodule, setSearchQuerySubmodule] = useState(""); // State for search query
  const [searchQueryIconmodule, setSearchQueryIconmodule] = useState(""); // State for search query
  const [filteredIcons, setFilteredIcons] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false);
  const [expandedMainMenu, setExpandedMainMenu] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [expandedSubMenu, setExpandedSubMenu] = useState({});
  const [expandedIcon, setExpandedIcon] = useState([]);
  const [selectedIcons, setSelectedIcons] = useState({});
  const [permissionIcons, setPermissionIcons] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [checkboxState, setCheckboxState] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const [countryName, setCountryName] = useState(""); // Country Name


  useEffect(() => {
    if (selectedRole) {
      console.log("selected role", selectedRole);
      getDataForSelectedRole();
    }
  }, [selectedRole]);

  const getDataForSelectedRole = async () => {
    try {
      const data = { roleId: selectedRole };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-role-by-roleId`,
        data
      );

      console.log("Response from server fetching roles:", response);
      const responseData = response.data;
      console.log("set roles details", responseData);
      // setRoles(responseData);
      if (responseData) {
        console.log("set roles details name", responseData.name);
        console.log("set roles details desc", responseData.description);

        const initialIcons = {};
        const initialPermissions = {};

        responseData.rolesPermissionsTables.forEach((rolePermission) => {
          const { iconId, permissionsId } = rolePermission;

          initialIcons[iconId] = true;
          if (!initialPermissions[iconId]) initialPermissions[iconId] = {};
          initialPermissions[iconId][permissionsId] = true;
        });

        setSelectedIcons(initialIcons);
        setSelectedPermissions(initialPermissions);
      }

      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({
        severity: "error",
        message: `Error fetching roles data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchGetPermissionsData = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-permissions`
      );
      // console.log("Response from server fetching permissions data:", response);
      const responseData = response.data.data;
      setPermissionIcons(responseData);

      console.log("double check users permissons", responseData);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const handlePermissionChange = (iconId, permissionId) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [iconId]: {
        ...prev[iconId],
        [permissionId]: !prev[iconId]?.[permissionId] || false,
      },
    }));
  };

  const handleIconChange = (iconId) => {
    setSelectedIcons((prev) => ({
      ...prev,
      [iconId]: !prev[iconId],
    }));
  };

  function isPermissionDisabled(iconId, permissionId) {
    const disabledPermissionsByUserType = {
      1: {
        21: [1, 2, 4, 8, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32], // Permissions disabled for icon 21, userType 1
        22: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32], // Permissions disabled for icon 22, userType 1
        23: [1, 2, 4, 8, 11, 12, 13, 17, 18, 25, 26, 27, 28, 29, 30, 31, 32],
        // 24: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        25: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        135: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],

        44: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        45: [1, 2, 4, 5, 6, 7, 8, 9, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        46: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],

        108: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        109: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        110: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        111: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        112: [1, 2, 4, 8, 9, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, 31, 32],
        113: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        114: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        115: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]

      },
      2: {
        114: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        115: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      },
      3: {
        1: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32], // Permissions disabled for icon 1, userType 3
        2: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        3: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        4: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        5: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        6: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        7: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        8: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        9: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        10: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        11: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        12: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        13: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        14: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        15: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        21: [1, 2, 4, 8, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32],
        22: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32],
        23: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        // 24: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        25: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        135: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        26: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        44: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        45: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        46: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        47: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        48: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        49: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        50: [3, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        51: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        52: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        108: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        109: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        110: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        111: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        112: [1, 2, 4, 8, 9, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, 31, 32],
        113: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        114: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        115: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
      },
      4: {
        1: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32], // Permissions disabled for icon 1, userType 3
        2: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        3: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        4: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        5: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        6: [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        7: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        8: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        9: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        10: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        11: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        12: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        13: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        14: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        15: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        21: [1, 2, 4, 8, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32],
        22: [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32],
        23: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        // 24: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        25: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        135: [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        26: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        44: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        45: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        46: [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        47: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        48: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        49: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        50: [3, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        51: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        52: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        114: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        115: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      },
      7: {
        114: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        115: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      },
      8: {
        1: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        2: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        3: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        4: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        5: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        6: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        7: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        8: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        9: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        11: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        12: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        13: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        14: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        15: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        21: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32],
        22: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32],
        23: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        // 24: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        25: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        135: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        26: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        44: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        45: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        46: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        47: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        48: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        49: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        50: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        51: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        109: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32],
        110: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        111: [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
        134: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32]
        // Similarly for other icons
      },
    };

    // Disable all permissions for icons 1 to 50 if userType is 8
    // if (userType === 8 && iconId >= 1 && iconId <= 50) {
    //   return true;
    // }

    if (userType == 8 && [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
      41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 100, 101, 102, 103, 104, 105, 106, 107].includes(iconId)) {
      return true;
    }



    // Check if there are any disabled permissions for the given userType and iconId
    if (disabledPermissionsByUserType[userType]?.[iconId]) {
      return disabledPermissionsByUserType[userType][iconId].includes(permissionId);
    }

    // Default to not disabled
    return false;
  }


  function isPermissionDisplayed(iconId, permissionId) {
    const hiddenPermissions = {

      1: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      2: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      3: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      4: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      5: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      6: [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      7: [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      8: [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      9: [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      10: [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      11: [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      12: [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      13: [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      14: [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      15: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      21: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      22: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      23: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      // 24: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 16, 19, 20, 21, 22, 23, 24, 25],
      25: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      135: [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      26: [8, 12, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],

      44: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      45: [4, 5, 6, 7, 8, 9, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      46: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 27, 28, 29, 30, 31, 32],
      47: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 29, 30, 31],
      48: [4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      49: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
      50: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      51: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      52: [1, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],

      108: [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      109: [8, 9, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      110: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      111: [8, 9, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      112: [2, 8, 9, 12, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 31, 32],
      113: [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      114: [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      115: [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      134: [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      136: [6, 7, 8, 9, 11, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],


    };

    return !hiddenPermissions[iconId]?.includes(permissionId);
  }


  function toggleAllPermissions(iconId) {
    if (userType === 8 && iconId >= 1 && iconId <= 50) {
      // Prevent toggling if "ALL" is disabled for userType 8
      return;
    }

    // Filter permissions: enabled and displayed only
    const displayedEnabledPermissions = permissionIcons.filter(
      (permission) =>
        !isPermissionDisabled(iconId, permission.id) &&
        isPermissionDisplayed(iconId, permission.id) // Custom function for visibility
    );

    const areAllSelected = displayedEnabledPermissions.every(
      (permission) => selectedPermissions[iconId]?.[permission.id]
    );

    // Toggle all enabled and displayed permissions
    const newSelectedPermissions = { ...selectedPermissions };
    displayedEnabledPermissions.forEach((permission) => {
      if (!newSelectedPermissions[iconId]) newSelectedPermissions[iconId] = {};
      newSelectedPermissions[iconId][permission.id] = !areAllSelected;
    });

    setSelectedPermissions(newSelectedPermissions);

    // Update "ALL" checkbox state
    setCheckboxState((prevState) => ({
      ...prevState,
      [iconId]: !areAllSelected,
    }));
  }


  const iconMapping = {
    "Add": <IoAddCircleOutline size={18} />,  // Adjust size as needed
    "Edit": <IoMdCreate size={18} />,
    "View": <svg className="ml-2 text-customGray" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"></path></svg>,
    "Copy": <PiCopy size={18} />,
    "Audit Trail": <MdOutlineHistory size={18} />,
    "Approve": <IoMdCheckmark size={18} />,
    "Reject": <IoMdClose size={18} />,
    "Trigger OTP": <LuKeyRound size={18} />,
    "Upload": <MdOutlineFileUpload size={18} />,
    "Download": <MdOutlineFileDownload size={18} />,
    "Inactive": <VscVmActive size={18} />,
    "Lock": <MdOutlineLock size={18} />,
    "Delete": <IoMdTrash size={18} />,
    "Filter": <GiSettingsKnobs size={18} />,
    "Settings": <IoSettingsOutline size={18} />,
    "Refresh": <IoMdRefresh size={18} />,
    "Save": <BiSave size={18} />,
    "Save & Continue": <MdOutlineSaveAs size={18} />,
    "Bank Account Number Update": <FaRegEdit size={18} />,
    "Status Change": <BsTags size={18} />,
    "Company Change": <FiBox size={18} />,
    "Special Copy": <LuCopyCheck size={18} />,
    "Submit": <BiSave size={18} />,
    "Inconsistence User Types": <PiNotEquals size={18} />,
    "Assign": <BsUbuntu size={18} />,
    "Auto Assign": <MdOutlineMotionPhotosAuto size={18} />,

    // Add other icons with size adjustments
  };

  const handleSearchChangeSubmodule = (e) => {
    setSearchQuerySubmodule(e.target.value);
  };

  const handleCheckboxChangeForIconModule = (event) => {
    const selectedCode = event.target.value;
    console.log("selected Icons module", selectedCode);
    setselectIconMenu(selectedCode);
    setIconMenu(selectedCode);
  };
  const handleCheckboxChangeForSubModule = (event) => {
    const selectedCode = event.target.value;
    console.log("selected sub module", selectedCode);
    setselectSubMenu(selectedCode);
    setSubMenu(selectedCode);
  };

  const handleSearchChangeMainmodule = (e) => {
    setSearchQueryMainmodule(e.target.value);

  }
  const handleSearchChangeIconmodule = (e) => {
    setSearchQueryIconmodule(e.target.value);
  };

  const handleCheckboxChangeForModule = (event) => {
    const selectedCode = event.target.value;
    console.log("selected Main menu", selectedCode);
    setselectMainMenu(selectedCode);
    setMainMenu(selectedCode);

  };

  useEffect(() => {
    const filtereddata = Icons.filter((mainMenuItem) => {
      const matchesMainMenu = mainMenu ? mainMenuItem.name === mainMenu : true;

      if (matchesMainMenu) {
        const filteredSubMenus = mainMenuItem.subMenus
          .filter((subMenuItem) => {
            const matchesSubMenu = subMenu
              ? subMenuItem.name === subMenu
              : true;

            if (matchesSubMenu) {
              const matchingIcons = subMenuItem.icons.filter((icon) =>
                icon.name.toLowerCase().includes(searchQueryState.toLowerCase())
              );

              return (
                matchingIcons.length > 0 ||
                subMenuItem.name
                  .toLowerCase()
                  .includes(searchQueryState.toLowerCase())
              );
            }
            return false;
          })
          .map((subMenuItem) => ({
            ...subMenuItem,
            icons: subMenuItem.icons.filter((icon) =>
              icon.name.toLowerCase().includes(searchQueryState.toLowerCase())
            ),
          }));

        if (filteredSubMenus.length > 0) {
          return {
            ...mainMenuItem,
            subMenus: filteredSubMenus,
          };
        }
      }

      return null;
    }).filter(Boolean);

    // Update the state only if the filtered data changes
    setFilteredIcons(filtereddata);
  }, [Icons, mainMenu, subMenu, searchQueryState]);


  useEffect(() => {
    if (!subMenu) {
      console.error("No submenu selected");
      return;
    }

    const updatedFilteredIcons = filteredIcons.map((mainMenuItem) => {
      const filteredSubMenus = mainMenuItem.subMenus
        .filter((subMenuItem) => subMenuItem.name === subMenu)
        .map((matchingSubMenu) => ({
          ...matchingSubMenu,
          icons: matchingSubMenu.icons || [],
        }));

      if (filteredSubMenus.length > 0) {
        return {
          ...mainMenuItem,
          subMenus: filteredSubMenus,
        };
      }

      return null;
    }).filter(Boolean);

    // Only update state if data has changed
    if (JSON.stringify(updatedFilteredIcons) !== JSON.stringify(filteredIcons)) {
      if (updatedFilteredIcons.length > 0) {
        const matchingSubMenu = updatedFilteredIcons[0].subMenus[0];
        if (matchingSubMenu?.icons?.length > 0) {
          setselectSubMenu(matchingSubMenu.name); // Select the first matching submenu dynamically
        }
      }

      console.log("Filtered submenu with icons:", updatedFilteredIcons);
      setFilteredIcons(updatedFilteredIcons);
    }
  }, [subMenu, filteredIcons]);

  useEffect(() => {
    if (!iconMenu) {
      console.error("No iconMenu selected");
      return;
    }

    console.log("Icon selected", iconMenu);

    // Filter the main menu and submenus to update the filtered icons.
    const updatedFilteredIcons = filteredIcons.map((mainMenuItem) => {
      const updatedSubMenus = mainMenuItem.subMenus.map((subMenuItem) => {
        console.log("Current submenu being processed:", subMenuItem);

        // Check if the submenu contains the selected icon and filter accordingly
        if (subMenuItem.icons.some((icon) => icon.name === iconMenu)) {
          const filteredIcons = subMenuItem.icons.filter(
            (icon) => icon.name === iconMenu
          );
          return {
            ...subMenuItem,
            icons: filteredIcons,
          };
        }
        return subMenuItem;
      });

      return {
        ...mainMenuItem,
        subMenus: updatedSubMenus,
      };
    });

    // Only update state if data has changed
    if (JSON.stringify(updatedFilteredIcons) !== JSON.stringify(filteredIcons)) {
      setFilteredIcons(updatedFilteredIcons);

      // Dynamically set the icon selection if there are matching submenus
      const matchingSubMenu = updatedFilteredIcons.flatMap(mainMenuItem =>
        mainMenuItem.subMenus.filter(subMenu => subMenu.icons.some(icon => icon.name === iconMenu))
      )[0];

      if (matchingSubMenu?.icons?.length > 0) {
        setselectIconMenu(matchingSubMenu.icons[0].name); // Select the first matching icon dynamically
      }
    }
  }, [iconMenu, filteredIcons]);

  const toggleAllMenus = () => {
    setAllExpanded(!allExpanded);
  };
  const toggleMainMenu = (mainMenuId) => {
    setExpandedMainMenu((prev) => (prev === mainMenuId ? null : mainMenuId));
  };
  const toggleSubMenu = (mainMenuId, subMenuId) => {
    setSelectedSubMenu(subMenuId);
    setExpandedSubMenu((prev) => ({
      ...prev,
      [mainMenuId]: prev[mainMenuId] === subMenuId ? null : subMenuId,
    }));
  };
  const toggleIcon = (iconId) => {
    setExpandedIcon((prev) =>
      prev.includes(iconId)
        ? prev.filter((id) => id !== iconId)
        : [...prev, iconId]
    );
  };

  useEffect(() => {
    if (assignee.clientId) {
      fetchIconsData();
      fetchGetPermissionsData();
    }
  }, [assignee]);


  useEffect(() => {
    if (UserId) {
      getDataForEdit();
    }
  }, [UserId]);

  const getDataForEdit = async () => {
    try {
      const data = { userId: UserId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );

      console.log(
        "Response from server fetching roles & Permissions:",
        response
      );
      const responseData = response.data;
      console.log("set roles & permission details", responseData);
      // setRoles(responseData);
      if (responseData) {
        if (responseData.roleId) {
          setSelectedRole(responseData.roleId);
          console.log("Set roleId to state:", responseData.roleId);
        } else {
          console.warn("roleId is undefined in the responseData");
        }
        console.log("set roles Id", responseData.role_id);
      }

      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({
        severity: "error",
        message: `Error fetching roles data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchIconsData = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-icons-data`
      );
      console.log("Response from server fetching Icons data:", response);
      const responseData = response.data.data;
      setIcons(responseData);

      console.log("double check users", response.data.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const previewPDF = () => {
  }

  // const previewPDF = () => {
  //   setPdfMode(true); // Enable PDF mode with smaller fonts

  //   const element = document.querySelector(".max-w-8xl");

  //   const options = {
  //     margin: 10,
  //     filename: 'RoleDetails.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
  //   };

  //   html2pdf()
  //     .from(element)
  //     .set(options)
  //     .outputPdf('blob') // Output as a Blob
  //     .then((pdfBlob) => {
  //       const pdfUrl = URL.createObjectURL(pdfBlob); // Create URL from Blob
  //       setPdfUrl(pdfUrl); // Update state with PDF URL
  //       setPdfMode(false); // Reset PDF mode after generation
  //       setShowViewer(true); // Show the viewer
  //     })
  //     .catch(() => {
  //       setPdfMode(false); // Reset PDF mode on error
  //     });
  // };


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCountryReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
    setstate("");
    setStateName("")
  };
  const handleCountryNameReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountryCode("");
    setCountryName("");
    setstate("");
    setStateName("")
  };
  const handleStateReset = () => {
    setSearchQueryState(''); // Reset search query
    setSelectedState(""); // Reset selected bank ID
    setstate("");
    setStateName("")
  };
  const handleStateNameReset = () => {
    setSearchQueryState(''); // Reset search query
    setSelectedState(""); // Reset selected bank ID
    setstate("");
    setStateName("")
  };

  const handleLoginMethodChange = (e) => {
    setLoginMethod(Number(e.target.value));
  };

  useEffect(() => {
    fetchAssignee();
    fetchCompanyCode();
    fetchUserTypes();
    fetchcountry();
    fetchLanguages();
  }, []);

  useEffect(() => {
    if (UserId) {
      fetchAddressDetails();
      fetcheditUserDetails();
      fetchUserPrefernceDetails();
    }
  }, [UserId]);

  useEffect(() => {
    if (countryCode) {
      if (!UserId) {
        fetchUserPrefernceDetailsByCountry();
      }
      fetchstateprovice();
    }
  }, [countryCode]);



  const fetchAddressDetails = async () => {
    try {
      const data = { userId: UserId };
      console.log("Request Data for address details:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-address-details`,
        data
      );

      console.log("API Response for address details:", response);
      console.log("Response Data user address details:", response.data);

      if (response.data) {
        // Update state with fetched address details
        setdoorNo(response.data.doorNo || '');
        setfloorNo(response.data.floorNo || '');
        setbuildingNo(response.data.buildingNo || '');
        setbuildingName(response.data.buildingName || '');
        setlandMark(response.data.landMark || '');
        setstreetNo(response.data.streetNo || '');
        setstreetName(response.data.streetName || '');
        setroadNo(response.data.roadNo || '');
        setRoadName(response.data.roadName || '');
        setareaBlock(response.data.areaBlock || '');
        setareaName(response.data.areaName || '');
        setaddressLine1(response.data.addressLine1 || '');
        setaddressLine2(response.data.addressLine2 || '');
        setaddressLine3(response.data.addressLine3 || '');
        setCountryCode(response.data.countryCode || '');
        setCountryName(response.data.Country.country_name || '');
        setCountryId(response.data.countryId || '');
        setstate(response.data.state || '');
        setStateName(response.data.StateProvince.state_name || '');
        setpoBox(response.data.poBox || '');
        setdistrict(response.data.district || '');
        setpostalCode(response.data.postalCode || '');
        setzipCode(response.data.zipCode || '');
        setPhase(response.data.phase);
        setSector(response.data.sector);
        setVillage(response.data.village);
        setTown(response.data.town);
        setCity(response.data.city);
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };

  const fetcheditUserDetails = async () => {

    try {
      const data = { userId: UserId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-details`,
        data
      );

      console.log("API Response:", response);
      console.log("Response Data:", response.data);

      if (response.data) {
        if (!isCopyAddMode) {
          setUserId(response.data.userId || '');
          setUserName(response.data.username || '');
          setApproved(response.data.approved || '');
          setSalute(response.data.salute || '');
          setUserType(response.data.userType || '');
          setAccountingSystemId(response.data.accountingSystemId || '');
          setSurname(response.data.surname || '');
          setFirstName(response.data.firstName || '');
          setLastName(response.data.lastName || '');
          setMiddleName(response.data.middleName || '');
          setEmail(response.data.email || '');
          setMobileNo(response.data.mobileNo || '');
          setWhatsappNo(response.data.whatsappNo || '');
          setOneTimePassword(response.data.oneTimePassword || '');
          setOfficeNo(response.data.officeNo || '');
          setExtension(response.data.extension || '');
          setNationality(response.data.nationality || '');
          setPassportNo(response.data.passportNo || '');
          setDrivingLicenseNo(response.data.drivingLicenseNo || '');
          setLocalId(response.data.localId || '');
        }
        // Update state with fetched address details

        setemployeeNo(response.data.employeeNo || '');
        setcompanyCodeId(response.data.companyCodeId || '');
        setdepartment(response.data.department || '');
        setvalidFrom(response.data.validFrom || '');
        setvalidTo(response.data.validTo || '');
        setresignationDate(response.data.resignationDate || '');
        setjoiningDate(response.data.joiningDate || '');
        setLoginMethod(response.data.loginMethod || 0);



      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };
  const fetchUserPrefernceDetailsByCountry = async () => {
    try {
      const data = { country: countryCode };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-country`,
        data
      );

      console.log("API Response for country preference:", response);
      console.log("Response Data:", response.data);

      if (response.data) {
        // Update state with fetched address details
        setlanguage(response.data.language || '');
        setdateFormat(response.data.dateFormat || '');
        setamountFormat(response.data.amountFormat || '');
        // setthousandSeparator(response.data.thousandSeparator || '');
        // setdecimalSeparator(response.data.decimalSeparator || '');
        setacceleratorThousands(response.data.acceleratorForThousands || '');
        setacceleratorMillions(response.data.acceleratorForMillions || '');
        setacceleratorLakhs(response.data.acceleratorForLakhs || '');
        settimeFormat(response.data.timeFormat || '');
        settimeZone(response.data.timeZone || '');

        setreceiveSystemMail(response.data.receiveSystemMail || '');
        setdownloadData(response.data.downloadData || '');
        setchangesInCashflow(response.data.changesInCashflow || '');
        setupdateCountry(response.data.updateCountry || '');
        setchangeVarient(response.data.changeVarient || '');
        setdeleteAllRecords(response.data.deleteAllRecords || '');
        setchangeAccountingCode(response.data.changeAccountingCode || '');


      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };

  const fetchUserPrefernceDetails = async () => {
    try {
      const data = { userId: UserId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details`,
        data
      );

      console.log("API Response:", response);
      console.log("Response Data from user preference details:", response.data);

      if (response.data) {
        // Update state with fetched address details
        console.log("Language get", response.data.language);
        setlanguage(response.data.language || '');
        setdateFormat(response.data.dateFormat || '');
        setamountFormat(response.data.amountFormat || '');
        // setthousandSeparator(response.data.thousandSeparator || '');
        // setdecimalSeparator(response.data.decimalSeparator || '');
        setacceleratorThousands(response.data.acceleratorForThousands || '');
        setacceleratorMillions(response.data.acceleratorForMillions || '');
        setacceleratorLakhs(response.data.acceleratorForLakhs || '');
        settimeFormat(response.data.timeFormat || '');
        settimeZone(response.data.timeZone || '');

        setreceiveSystemMail(response.data.receiveSystemMail || '');
        setdownloadData(response.data.downloadData || '');
        setchangesInCashflow(response.data.changesInCashflow || '');
        setupdateCountry(response.data.updateCountry || '');
        setchangeVarient(response.data.changeVarient || '');
        setdeleteAllRecords(response.data.deleteAllRecords || '');
        setchangeAccountingCode(response.data.changeAccountingCode || '');


      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };

  const fetchLanguages = async () => {
    try {
      const response = await LanguageServices.getAllLanguages(`${process.env.REACT_APP_BASE_URL}/get-language`);
      if (response && response.data) {
        console.log("response data for language", response.data.data);
        setlanguages(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching Language data', error);
    }
  };

  // Use effect to update validTo when resignationDate changes
  useEffect(() => {
    if (resignationDate) {
      setvalidTo(resignationDate);
    }
  }, [resignationDate]);




  // Fetch user types from the API
  const fetchUserTypes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-alluser-type`

      );
      console.log("User types", response.data.data);

      setUserTypes(response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  const fetchcountry = async () => {
    try {
      const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-country`);
      console.log("country data fetch", response.data.data);
      setCountries(response.data.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };

  const handleSearchChangeCompanyCode = (e) => {
    setSearchQueryCompanyCode(e.target.value);
  };

  const fetchstateprovice = async () => {
    try {
      const data = { Country_Code: countryCode };
      console.log('fetching data country', countryCode);
      console.log("fetch country in state", countryCode);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-stateprovince-by-country`,
        data
      );
      // console.log("API Response:", response);
      console.log("state province Response Data:", response.data);

      setStateProvinces(response.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }

  }

  const fetchCompanyCode = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-Company-code-details`);
      // console.log("API Response:", response);
      console.log("Company code Response Data:", response.data.data);

      setCompanyCodes(response.data.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          userId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner clientId:", assigneeData.clientId);
        console.log("Assigner userId:", assigneeData.userId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleValidFromChange = (date) => {
    setUnsavedChanges(true);

    if (date && validTo && new Date(date) >= new Date(validTo)) {
      setErrors({
        ...errors,
        validFrom: "Valid From date cannot be after Valid To date",
      });
    } else {
      setErrors({ ...errors, validFrom: "" });
      setvalidFrom(date);
    }
  };

  const handleJoiningChange = (date) => {
    if (date && validFrom && new Date(date) <= new Date(validFrom)) {
      setErrors({
        ...errors,
        validTo: "Valid To date cannot be before Valid From date",
      });
    } else {
      setErrors({ ...errors, validTo: "" });
      setvalidTo(date);
    }
  };

  const handleValidToChange = (date) => {
    setUnsavedChanges(true);

    if (date && validFrom && new Date(date) <= new Date(validFrom)) {
      setErrors({
        ...errors,
        validTo: "Valid To date cannot be before Valid From date",
      });
    } else {
      setErrors({ ...errors, validTo: "" });
      setvalidTo(date);
    }
  };


  const checkDuplicateUseremail = async (name) => {
    try {
      const response = await UserService.checkDuplicateRoleName(
        `${process.env.REACT_APP_BASE_URL}/check-useremail-duplicate`,
        { email: name }  // Sending roleName to backend
      );

      console.log("Response from server:", response);

      if (response.message === "User email already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ email: ' ' });

        toast("error", "User email already exists.");

      }
      console.log("Response from external server:", response);

    } catch (error) {
      console.error("Error checking duplicate role name:", error);
      // Handle potential errors when making the API call
      setErrors(prev => ({ ...prev, email: "Error checking role name" }));
    }
  };

  // const handleThousandChange = (e) => {
  //   setUnsavedChanges(true);

  //   const value = e.target.value;
  //   // Only allow '.' or ',' in the thousand separator field
  //   if (value === '' || value === '.' || value === ',') {
  //     setthousandSeparator(value);
  //     setError((prevError) => ({ ...prevError, thousand: false }));  // Reset thousand separator error

  //     // Check if thousand and decimal separators are the same
  //     if (value === decimalSeparator && value !== '') {
  //       setError((prevError) => ({ ...prevError, same: true }));
  //     } 
  //     else {
  //       setError((prevError) => ({ ...prevError, same: false }));
  //     }
  //   } else {
  //     setError((prevError) => ({ ...prevError, thousand: true }));
  //   }
  // };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern

  const handleEmailChange = (e) => {
    setUnsavedChanges(true);
    const value = e.target.value;
    setEmail(value);

    setErrors(prev => ({ ...prev, userId: "" }));

    // Check for duplicate role names when typing
    if (value.trim() !== "") {
      // checkDuplicateUseremail(value);
    }


    // Validate email
    if (value.length > 80) {
      setErrors({ email: 'Email must not exceed 80 characters.' });
    } else if (!emailRegex.test(value)) {
      setErrors({ email: 'Please enter a valid email address.' });
    } else {
      setErrors({ email: '' });
    }
  };


  // const handleDecimalChange = (e) => {
  //   setUnsavedChanges(true);

  //   const value = e.target.value;
  //   // Only allow '.' or ',' in the decimal separator field
  //   if (value === '' || value === '.' || value === ',') {
  //     setdecimalSeparator(value);
  //     setError((prevError) => ({ ...prevError, decimal: false }));  // Reset decimal separator error

  //     // Check if thousand and decimal separators are the same
  //     if (value === thousandSeparator && value !== '') {
  //       setError((prevError) => ({ ...prevError, same: true }));
  //     } else {
  //       setError((prevError) => ({ ...prevError, same: false }));
  //     }
  //   } else {
  //     setError((prevError) => ({ ...prevError, decimal: true }));
  //   }
  // };

  const handleChangeforMillions = (e) => {
    setUnsavedChanges(true);

    let value = e.target.value;

    // Only allow 'M' or empty input
    if (value === '' || value === 'M') {
      setSuffixForMillions(value); // Update the valid suffix
      setErrorMillions(false); // Clear any errors
    } else {
      setErrorMillions(true); // Show error for invalid input
    }
  };

  const handleChangeforLakhs = (e) => {
    setUnsavedChanges(true);

    let value = e.target.value;

    // Only allow 'L' or empty input
    if (value === '' || value === 'L') {
      setSuffixForLakhs(value); // Update the valid suffix
      setErrorLakhs(false); // Clear any errors
    } else {
      setErrorLakhs(true); // Show error for invalid input
    }
  };


  const handleChangeforThousands = (e) => {
    setUnsavedChanges(true);

    let value = e.target.value;

    // Ensure the input contains only 'K' or 'T' or is empty
    if (value === '' || value === 'K' || value === 'T') {
      setSuffixForThousands(value); // Update suffix for valid input
      setErrorThousands(false); // Clear any errors
    } else {
      setErrorThousands(true); // Show error for invalid input
    }
  };

  const validateFields = () => {
    setIsValidationTriggered(true);

    const newErrors = {};

    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
    const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
    const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
    const isAreaSectionFilled = areaBlock || areaName;
    const iscitySectionFilled = city || town || village || sector || phase;
    const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled || iscitySectionFilled;

    if (!userId) newErrors.userId = " ";
    if (!username) newErrors.username = " ";
    if (!firstName) newErrors.firstName = " ";
    if (!lastName) newErrors.lastName = " ";
    if (!email) newErrors.email = " ";
    if (!mobileNo) newErrors.mobileNo = " Mobile Number Required";
    if (!nationality) newErrors.nationality = " ";
    if (!validFrom) newErrors.validFrom = " ";
    if (!validTo) newErrors.validTo = " ";

    if (stateProvinces.length > 0 && !state) {
      newErrors.state = "State is required.";
    }
    if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
      newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
    }

    // if (!countryId) newErrors.countryId = " ";
    // if (stateProvinces.length > 0) {
    //   console.log("State prv is show error");
    //   if (!state) newErrors.state = " ";
    // }
    if (!countryCode) newErrors.countryCode = " ";
    if (!language) newErrors.language = " ";
    if (!dateFormat) newErrors.dateFormat = " ";
    if (!timeFormat) newErrors.timeFormat = " ";

    setErrors(newErrors);

    // Show toast only if there are errors
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing required fields.");
      return false;
    }

    return true;
  };


  const handleSaveContinueDetails = async () => {
    if (!validateFields()) return;
    if (isEditMode) {
      try {
        console.log("entered");
        const data = {
          userId: UserId,
          username,
          accountingSystemId,
          salute,
          userType,
          firstName,
          middleName,
          surname,
          lastName,

          // module: 'Admin',
          // icon: 'User',
          // action: 'Edit',
          changedBy: assignee.userId,
          oneTimePassword: oneTimePassword || 0,
          // createdBy: assignee.userId,

          email, mobileNo, whatsappNo, officeNo, extension,
          passportNo, drivingLicenseNo, localId, nationality,
          language, dateFormat,
          //  thousandSeparator, decimalSeparator,
          acceleratorForThousands: acceleratorForThousands + suffixForThousands,
          acceleratorForMillions: acceleratorForMillions + suffixForMillions,
          acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
          timeFormat, timeZone, receiveSystemMail, downloadData, changesInCashflow, updateCountry, changeVarient, deleteAllRecords, changeAccountingCode,
          streetName, addressLine1, addressLine2, addressLine3, zipCode, postalCode,
          employeeNo, companyCodeId, department, validFrom, validTo, joiningDate, resignationDate,
          loginMethod: loginMethod || 0,
          doorNo, floorNo, buildingNo, buildingName, landMark, streetNo, roadNo, roadName, areaBlock, areaName, countryCode, state, poBox, district,
          phase,
          sector,
          village,
          town,
          city,
        };
        const response = await UserService.saveAllUserDetails(
          `${process.env.REACT_APP_BASE_URL}/edit-user`,
          data
        );
        console.log("response data", data);
        console.log("response", response);
        console.log("response status", response.status);
        if (response.status === 200) {
          setAlert({
            severity: "success",
            message: "Basic Details saved successfully.",
          });

          // Wait for the success message to be displayed before navigating
          toast("success", "User Edited successfully");
          handleTabClick("basicDetail");  // Navigate to loginMethod Details tab
        } else {
          setAlert({ severity: "error", message: "Not saved" });
        }
      } catch (error) {
        console.error("Error saving basic details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while saving.",
        });
      }
    }
    else {
      try {
        console.log("entered");
        const data = {
          userId,
          username,
          accountingSystemId,
          salute,
          userType,
          firstName,
          middleName,
          surname,
          lastName,
          oneTimePassword: oneTimePassword || 0,
          clientId: assignee.clientId,
          createdBy: assignee.userId,
          changedBy: null,
          changedDate: null,
          // changedTime:null,
          // module: 'Admin',
          // icon: 'User',
          // action: 'Add',
          email, mobileNo, whatsappNo, officeNo, extension,
          passportNo, drivingLicenseNo, localId, nationality,
          language, dateFormat,
          //  thousandSeparator, decimalSeparator,
          //  amountFormat,
          acceleratorForThousands: acceleratorForThousands + suffixForThousands,
          acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
          acceleratorForMillions: acceleratorForMillions + suffixForMillions,

          timeFormat, timeZone, receiveSystemMail, downloadData, changesInCashflow, changeVarient, deleteAllRecords, changeAccountingCode, updateCountry,
          streetName, addressLine1, addressLine2, addressLine3, countryId, zipCode, postalCode,
          employeeNo, companyCodeId, department, validFrom, validTo, joiningDate, resignationDate,
          loginMethod: loginMethod || 0,
          doorNo, floorNo, buildingNo, buildingName, landMark, streetNo, roadNo, roadName, areaBlock, areaName, countryCode, state, poBox, district,
          phase,
          sector,
          village,
          town,
          city,
        };
        const response = await UserService.saveAllUserDetails(
          `${process.env.REACT_APP_BASE_URL}/add-new-user`,
          data
        );
        console.log("response data", data);
        console.log("response", response);
        console.log("response status", response.status);
        if (response.status === 200) {
          setAlert({
            severity: "success",
            message: "Basic Details saved successfully.",
          });

          // Wait for the success message to be displayed before navigating
          toast("success", "User successfully Added");
          setUserId("");
          setUserName("");
          setAccountingSystemId("");
          setSalute("");
          setUserType(0);
          // setUserTypes([]);
          setFirstName("");
          setOneTimePassword("");
          setMiddleName("");
          setSurname("");
          setLastName("");
          setEmail("");
          setMobileNo("");
          setWhatsappNo("");
          setOfficeNo("");
          setExtension("");
          setPassportNo("");
          setDrivingLicenseNo("");
          setLocalId("");
          setNationality("");
          setlanguage("");
          // setlanguages([]);
          setSelectedLanguae("");
          setdateFormat("");
          // setthousandSeparator("");
          // setdecimalSeparator("");
          setamountFormat("");
          setacceleratorThousands("");
          setacceleratorLakhs("");
          setacceleratorMillions("");
          // setCountries([]);
          // setStateProvinces([]);
          // setCompanyCodes([]);
          setApproved("");
          setLoginMethod(0);
          setSuffixForThousands("");
          setSuffixForLakhs("");
          setSuffixForMillions("");
          settimeFormat(""); settimeZone(""); setreceiveSystemMail("");
          setdownloadData(""); setchangesInCashflow(""); setchangeVarient(""); setdeleteAllRecords("");
          setchangeAccountingCode(""); setupdateCountry("");
          setstreetName(""); setaddressLine1(""); setaddressLine2(""); setaddressLine3(""); setCountryId(""); setzipCode(""); setpostalCode("");
          setemployeeNo(""); setcompanyCodeId(""); setdepartment(""); setvalidFrom(""); setvalidTo(""); setjoiningDate(""); setresignationDate("");
          setdoorNo(""); setfloorNo(""); setbuildingNo(""); setbuildingName(""); setlandMark(""); setstreetNo(""); setroadNo(''); setRoadName("");
          setareaBlock(""); setareaName(""); setCountryCode(""); setstate(""); setpoBox(""); setdistrict("");
          setPhase("");
          setSector("");
          setVillage("");
          setTown("");
          setCity("");
          setSelectedCountry("");
          setSelectedState("");
          setStateName("");
          setCountryName("");
          setSelectedCompany("");
          setSelectedLanguae("");
          // Clear validation errors and stop showing validation
          setErrors({});
          setIsValidationTriggered(false);




          // handleTabClick("basicDetail");  // Navigate to loginMethod Details tab
        } else {
          setAlert({ severity: "error", message: "Not saved" });
        }
      } catch (error) {
        console.error("Error saving basic details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while saving.",
        });
      }
    }

  };


  const handleSaveBasicDetails = async () => {
    if (!validateFields()) return;
    if (isEditMode) {
      try {
        const data = {
          userId: UserId,
          username,
          accountingSystemId,
          salute,
          userType,
          firstName,
          middleName,
          surname,
          lastName,
          // module: 'Admin',
          // icon: 'User',
          // action: 'Edit',
          changedBy: assignee.userId,
          oneTimePassword: oneTimePassword || 0,
          // createdBy: assignee.userId,

          email, mobileNo, whatsappNo, officeNo, extension,
          passportNo, drivingLicenseNo, localId, nationality,
          language, dateFormat,amountFormat,
          //  thousandSeparator, decimalSeparator, 
          acceleratorForThousands: acceleratorForThousands + suffixForThousands,
          acceleratorForMillions: acceleratorForMillions + suffixForMillions,
          acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
          timeFormat, timeZone, receiveSystemMail, downloadData, changesInCashflow, updateCountry, changeVarient, deleteAllRecords, changeAccountingCode,
          streetName, addressLine1, addressLine2, addressLine3, zipCode, postalCode,
          employeeNo, companyCodeId, department, validFrom, validTo, joiningDate, resignationDate,
          loginMethod: loginMethod || 0,
          doorNo, floorNo, buildingNo, buildingName, landMark, streetNo, roadNo, roadName, areaBlock, areaName, countryCode, state, poBox, district,
          phase,
          sector,
          village,
          town,
          city,
        };
        const response = await UserService.saveAllUserDetails(
          `${process.env.REACT_APP_BASE_URL}/edit-user`,
          data
        );
        console.log("response", response);
        console.log("response status", response.status);
        if (response.status === 200) {
          setAlert({
            severity: "success",
            message: "Basic Details saved successfully.",
          });

          // Wait for the success message to be displayed before navigating
          toast("success", "User Edited successfully");
          navigate("/14/admin/108/userscreen"); // Navigate to loginMethod Details tab
        }
        else if (response.status === 400) {
          // setAlert({
          //   severity: "warning",
          //   message: "Missing required fields.",
          // });

          // Wait for the success message to be displayed before navigating
          toast("error", "Missing required fields.");
        }
        else if (response.status === 409) {
          setAlert({
            severity: "warning",
            message: "Email already exists.",
          });

          // Wait for the success message to be displayed before navigating
          toast("error", "Email already exists.");
        }
        else {
          setAlert({ severity: "error", message: "Not saved" });
        }
      } catch (error) {
        console.error("Error saving basic details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while saving.",
        });
      }

    }
    else {
      try {
        console.log("entered");
        const data = {
          userId,
          username,
          accountingSystemId,
          salute,
          userType,
          firstName,
          middleName,
          surname,
          clientId: assignee.clientId,
          // module: 'Admin',
          // icon: 'User',
          // action: 'Add',

          lastName,
          oneTimePassword: oneTimePassword || 0,
          createdBy: assignee.userId,
          changedBy: null,
          changedDate: null,
          // changedTime: null,
          email, mobileNo, whatsappNo, officeNo, extension,
          passportNo, drivingLicenseNo, localId, nationality,
          language, dateFormat,amountFormat,
          //  thousandSeparator, decimalSeparator, 
          acceleratorForThousands: acceleratorForThousands + suffixForThousands,
          acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
          acceleratorForMillions: acceleratorForMillions + suffixForMillions,
          timeFormat, timeZone, receiveSystemMail, downloadData, changesInCashflow, changeVarient, deleteAllRecords, changeAccountingCode, updateCountry,
          streetName, addressLine1, addressLine2, addressLine3, countryId, zipCode, postalCode,
          employeeNo, companyCodeId, department, validFrom, validTo, joiningDate, resignationDate,
          loginMethod: loginMethod || 0,
          doorNo, floorNo, buildingNo, buildingName, landMark, streetNo, roadNo, roadName, areaBlock, areaName, countryCode, state, poBox, district,
          phase,
          sector,
          village,
          town,
          city,
        };

        console.log("user save request", data);
        const response = await UserService.saveAllUserDetails(
          `${process.env.REACT_APP_BASE_URL}/add-new-user`,
          data
        );
        console.log("response data", data);
        console.log("response", response);
        console.log("response status", response.status);
        if (response.status === 200) {
          setAlert({
            severity: "success",
            message: "Basic Details saved successfully.",
          });

          // Wait for the success message to be displayed before navigating
          toast("success", "User successfully Added");
          navigate("/14/admin/108/userscreen"); // Navigate to loginMethod Details tab
        }
        else if (response.status === 400) {
          // setAlert({
          //   severity: "warning",
          //   message: "Missing required fields.",
          // });

          // Wait for the success message to be displayed before navigating
          toast("error", "Missing required fields.");
        }
        else if (response.status === 409) {
          setAlert({
            severity: "warning",
            message: "Email already exists.",
          });

          // Wait for the success message to be displayed before navigating
          toast("error", "Email already exists.");
        }

        else {
          setAlert({ severity: "error", message: "Not saved" });
          toast("error", "Not saved.");

        }
      } catch (error) {
        console.error("Error saving basic details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while saving.",
        });
      }
    }

  };


  const checkDuplicateUserId = async (name) => {
    try {
      const response = await UserService.checkDuplicateRoleName(
        `${process.env.REACT_APP_BASE_URL}/check-userId-duplicate`,
        {
          userId: name,
          clientId: assignee.clientId,
        }  // Sending roleName to backend
      );

      console.log("Response from server:", response);

      if (response.message === "User ID already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ userId: ' ' });

        toast("error", "User ID already exists.");

      }
      console.log("Response from external server:", response);

    } catch (error) {
      console.error("Error checking duplicate role name:", error);
      // Handle potential errors when making the API call
      setErrors(prev => ({ ...prev, roleName: "Error checking role name" }));
    }
  };

  const handleUserIdChange = (e) => {
    setUnsavedChanges(true);
    const name = e.target.value;
    setUserId(name);

    // Clear previous errors when typing
    setErrors(prev => ({ ...prev, userId: "" }));

    // Check for duplicate role names when typing
    if (name.trim() !== "") {
      checkDuplicateUserId(name);
    }
  };

  const tabStyle = (tab) => {
    const hasErrorsInBasicDetail = errors.userId || errors.username || errors.firstName || errors.lastName;
    const hasErrorsInContactTab = errors.email || errors.mobileNo; // Assuming contact tab fields
    const hasErrorInIdentityTab = errors.nationality;
    const hasErrorInOrganizationTab = errors.validFrom || errors.validTo;
    const hasErrorInAddressDetailTab = errors.addressSection;
    const hasErrorInUserPreference = errors.language || errors.dateFormat || errors.timeFormat;
    const isActiveTab = activeTab === tab;

    // Conditionally applying error styles based on the tab and errors in corresponding fields
    const tabHasError = (tab === "basicDetail" && hasErrorsInBasicDetail) ||
      (tab === "contactDetails" && hasErrorsInContactTab) ||
      (tab === "identity" && hasErrorInIdentityTab) ||
      (tab === "organization" && hasErrorInOrganizationTab) ||
      (tab === "addressDetails" && hasErrorInAddressDetailTab) ||
      (tab === "userPreferences" && hasErrorInUserPreference);


    return {
      backgroundColor: isActiveTab ? "white" : "transparent",
      color: isActiveTab ? "grey" : "white",
      padding: "2px 10px",
      cursor: "pointer",
      borderRadius: "5px",
      border: tabHasError ? "2px solid red" : "none", // Apply red border if there are errors in the tab
    };
  };
  const errorss = {
    basicDetail: !userId || !username || !firstName || !lastName, // Replace with your validation logic
    contactDetails: !email || !mobileNo,
    identity: !nationality,
    organization: !validFrom || !validTo,
    userPreferences: !language || !dateFormat || !timeFormat,
    addressDetails: (
      (!addressLine1 && !addressLine2 && !addressLine3 &&
        !doorNo && !floorNo && !buildingNo && !buildingName && !landMark &&
        !streetNo && !streetName && !roadNo && !roadName &&
        !areaBlock && !areaName && !phase && !sector && !village && !town && !city) ||
      (!countryCode && !countryName) ||
      (stateProvinces.length > 0 && !state && !stateName)
    ) // Only check state if there are filtered states
  };


  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Admin') {
      console.log("Navigating to /Admin");
      navigate('/14/admin');
    } else if (crumb.title === 'User & SOD') {
      console.log("Navigating to User & SOD");
      navigate('/14/admin');
    }
    else if (crumb.title === 'User') {
      console.log("Navigating to /User");
      navigate('/14/admin/108/userscreen');
    }
    else if (crumb.title === 'Add') {
      console.log("Navigating to /User");
      // navigate('/useradd');
    }
    else if (crumb.title === "View") {
      console.log("View");
      // navigate("/14/admin");
    }
    // else if (crumb.title === 'Edit') {
    //   console.log("Navigating to /Edit");
    // } 
    // else if (crumb.path) {
    //   navigate(crumb.path); // Navigate to the path specified in the crumb
    // }
  };

  const tabs = [
    { value: "basicDetail", label: "Basic Details" },
    { value: "contactDetails", label: "Contact details" },
    { value: "identity", label: "Identity" },
    { value: "organization", label: "Organization" },
    { value: "addressDetails", label: "Address Details" },
    { value: "userPreferences", label: "User Preferences" },
    // { value: "loginMethod", label: "Login Method" },
    ...(isProfileMode ? [{ value: "authorizations", label: "Permissions" }] : []),
    ...(isProfileMode ? [{ value: "keyData", label: "Key Data" }] : []),
  ];

  const handlesTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isModalOpenForCompany, setIsModalOpenForCompany] = useState(false);
  const [isModalOpenForStateName, setIsModalOpenForStateName] = useState(false);
  const [isModalOpenForCountryName, setIsModalOpenForCountryName] = useState(false);
  const [islanguageModalOpen, setIslanguageModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const toggleModalForCompanyCode = () => {
  //   setIsModalOpenForCompany(!isModalOpenForCompany);
  // }

  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);

  }
  const toggleModalForStateName = () => {
    setIsModalOpenForStateName(!isModalOpenForStateName);

  }

  const toggleModalforCountryName = () => {
    setIsModalOpenForCountryName(!isModalOpenForCountryName);

  }

  const handleCheckboxChange = (event) => {
    setUnsavedChanges(true);

    const selectedCode = event.target.value;
    console.log("selected country code", selectedCode);
    console.log("state apear or not", state);
    setCountryCode(selectedCode);
    setSelectedCountry(selectedCode);
    if (state) {
      setstate('');
      setStateName('');
    }

    const selectedCountryData = countries.find(country => country.country === selectedCode);
    if (selectedCountryData) {
      setCountryName(selectedCountryData.countryName);
    }
  };

  const handleCheckboxChangeforCountryName = (event) => {
    setUnsavedChanges(true);
    const selectedCode = event.target.value;
    console.log("selected country name", selectedCode);
    setCountryCode(selectedCode);
    setSelectedCountry(selectedCode);

    if (state) {
      setstate('');
      setStateName('');
    }

    const selectedCountryData = countries.find(country => country.country === selectedCode);
    if (selectedCountryData) {
      setCountryName(selectedCountryData.countryName);

    }
  };

  const handleLanguageCheckBoxChange = (event) => {
    setUnsavedChanges(true);

    console.log(`this is the language ${languages}`)
    setUnsavedChanges(true);
    const selectedName = event.target.value; // Get the selected country name
    setSelectedLanguae(selectedName);

    const selectedCountryData = languages.find(le => le.LE === selectedName);
    if (selectedCountryData) {
      setSelectedLanguae(selectedCountryData.LE); // Set the country code
      setlanguage(selectedCountryData.LE);
      // Filter states by the selected country code
      // const filteredStates = stateProvinces.filter(state => state.Country_Code === selectedCountryData.country);
    }
  };

  const handleCheckboxChangeForState = (event) => {
    setUnsavedChanges(true);
    const selectedCode = event.target.value;
    console.log("selected State code", selectedCode);
    setSelectedState(selectedCode);
    setstate(selectedCode);

    const selectedCountryData = stateProvinces.find(stateP => stateP.state === selectedCode);
    console.log('selected state country data', selectedCountryData);
    if (selectedCountryData) {
      console.log('check selected country data', selectedCountryData.State_Name);
      setStateName(selectedCountryData.State_Name);
    }
  };

  const handleToggle = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
  };

  const saluteOptions = [
    { value: "", label: "Select Salute" },
    { value: "Dr.", label: "Dr." },
    { value: "Miss", label: "Miss" },
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
  ];

  const timeZoneOptions = [
    { value: "", label: "Select Time Format" },
    { value: "UTC−12:00", label: "UTC−12:00" },
    { value: "UTC−11:00", label: "UTC−11:00" },
    { value: "UTC−10:00-(HST)", label: "UTC−10:00-(HST)" },
    { value: "UTC−9:00-AKST", label: "UTC−9:00-AKST" },
    { value: "UTC−8:00-PST", label: "UTC−8:00-PST" },
    { value: "UTC−7:00-MST", label: "UTC−7:00-MST" },
    { value: "UTC−6:00-CST", label: "UTC−6:00-CST" },
    { value: "UTC−5:00-(EST)", label: "UTC−5:00-(EST)" },
    { value: "UTC−4:00", label: "UTC−4:00" },
    { value: "UTC−3:00", label: "UTC−3:00" },
    { value: "UTC−2:00", label: "UTC−2:00" },
    { value: "UTC−1:00", label: "UTC−1:00" },
    { value: "UTC±0:00 –(GMT)", label: "UTC±0:00 –(GMT)" },
    { value: "UTC+1:00 –(CET)", label: "UTC+1:00 –(CET)" },
    { value: "UTC+2:00 –(EET)", label: "UTC+2:00 –(EET)" },
    { value: "UTC+3:00 ", label: "UTC+3:00 " },
    { value: "UTC+3:30 –(IRST)", label: "UTC+3:30 –(IRST)" },
    { value: "UTC+4:00 –(CET)", label: "UTC+4:00 –(CET)" },
    { value: "UTC+4:30 –(AFT)", label: "UTC+4:30 –(AFT)" },
    { value: "UTC+5:00 –(PKT)", label: "UTC+5:00 –(PKT)" },
    { value: "UTC+5:30 –(IST)", label: "UTC+5:30 –(IST)" },
    { value: "UTC+5:45 –(NPT)", label: "UTC+5:45 –(NPT)" },
    { value: "UTC+6:00 ", label: "UTC+6:00 " },
    { value: "UTC+6:30 –(MMT)", label: "UTC+6:30 –(MMT)" },
    { value: "UTC+7:00", label: "UTC+7:00" },
    { value: "UTC+8:00", label: "UTC+8:00" },
    { value: "UTC+8:45", label: "UTC+8:45" },
    { value: "UTC+9:00", label: "UTC+9:00" },
    { value: "UTC+9:30 –(ACST)", label: "UTC+9:30 –(ACST)" },
    { value: "UTC+10:00", label: "UTC+10:00" },
    { value: "UTC+10:30", label: "UTC+10:30" },
    { value: "UTC+11:00 )", label: "UTC+11:00 )" },
    { value: "UTC+12:00", label: "UTC+12:00" },
    { value: "UTC+12:45", label: "UTC+12:45" },
    { value: "UTC+13:00", label: "UTC+13:00" },


  ];
  const dateFormatOptions = [
    { value: "", label: "Select Date Format" },
    { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
    { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
    { value: "DD/MM/YY", label: "DD/MM/YY" },
    { value: "MM/DD/YY", label: "MM/DD/YY" },
    { value: "DD.MM.YYYY", label: "DD.MM.YYYY" },
    { value: "DDMMYY", label: "DDMMYY" },
    { value: "DD-MM-YYYY", label: "DD-MM-YYYY" },
  ];

  const amountFormatOptions = [
    { value: "", label: "Select Amount Format" },
    { value: "1", label: "1,234,567.89" },
    { value: "2", label: "1.234.567,890" },
    { value: "3", label: "12,34,567.890" },

  ];

  const timeFormatOptions = [
    { value: "", label: "Select Time Format" },
    { value: "24 hours", label: "24 hours" },
    { value: "12 hours", label: "12 hours" },

  ];

  const handleCheckboxChangeForCompanyCode = (event) => {
    setUnsavedChanges(true);
    const selectedCode = event.target.value;
    console.log("selected Company code", selectedCode);
    setSelectedCompany(selectedCode);
    setcompanyCodeId(selectedCode);

  };

  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (approved == 4) {
      color = "#29CB00"; // Green
    } else if (approved == 0) {
      color = "gray"; // Gray
    } else if (approved == 1) {
      color = "#AF52DE"; //Purple
    } else if (approved === 2) {
      color = "yellow"; // Yellow
    } else if (approved == 3) {
      color = "orange"; // Orange
    } else if (approved == 5) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };


  return (
    <div>
      <div>

        {
          isProfileMode ? (
            <MainHeader title={"User Profile"} />
          ) : (
            <Header title={`Admin > User & SOD > User >  ${isViewMode ? 'View' : isEditMode ? 'Edit' : 'Add'} > ${UserId ? UserId : ''} `}
              onBreadcrumbClick={handleBreadcrumbClick}
              hasUnsavedChanges={unsavedChanges}
              backRoute={"/14/admin/108/userscreen"}
              extraContent={isViewMode || isEditMode ? getApprovedIcon() : ''}

            />
          )
        }

      </div>
      <div>
        <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handlesTabClick} errors={errorss} isValidationTriggered={isValidationTriggered} />

      </div>

      {activeTab === "basicDetail" && (
        <div className="m-6 border border-gray-200 p-2  rounded-lg">
          <div className="p-2">
            <div className="grid grid-cols-2 gap-x-8 gap-y-2">
              <span className="flex items-center group">
                <span className="w-48 font-small text-customGray text-xs">
                  User ID <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={userId}
                  // onChange={(e) => setUserId(e.target.value)}
                  onChange={handleUserIdChange}
                  maxLength={12}
                  className={`border p-1.5 rounded text-xs w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 
                ${errors.userId ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                  placeholder="Enter main head"
                  required
                  disabled={isViewMode || isProfileMode || isEditMode}

                />

                {errors.userId && (
                  <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
                )}
              </span>



              <span className="flex items-center group">
                <span className="w-48 font-small text-customGray text-xs">
                  Username <span className="text-red-500 ml-1">*</span>{" "}
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={username}
                  maxLength={8}
                  onChange={(e) => {
                    setUserName(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  className={`border border-gray-300 text-xs p-1.5 rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                ${errors.username ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                  placeholder="Enter sub heads"
                  disabled={isViewMode || isProfileMode}

                />
                {errors.username && (
                  <p className="text-red-500 text-sm ml-4">{errors.username}</p>
                )}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-x-8 ">
              <div>{!(isViewMode || isProfileMode) && userId.length === 12 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}</div>
              <div>{!(isViewMode || isProfileMode) && username.length === 8 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}</div>
            </div>


            {/*  */}
            <div className="grid grid-cols-2 gap-x-8 mt-4">

              <label className="flex items-center ">
                <span className="w-48 font-small text-customGray text-xs">
                  User Type
                </span>
                <span className="ml-4">:</span>
                <select
                  value={userType}
                  onChange={(e) => {
                    const value = e.target.value;
                    setUserType(value == "0" ? null : value); // Set to null if value is "0"
                  }}
                  disabled
                  className="border border-gray-300 p-1 text-xs rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 bg-gray-100"
                >
                  <option value="0">Select user type</option>
                  {userTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </label>

              <span className="flex items-center group">
                <span className="w-48 font-small text-customGray text-xs">
                  Accounting System ID
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={accountingSystemId}
                  maxLength={12}
                  onChange={(e) => {
                    setAccountingSystemId(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                  disabled={isViewMode || isProfileMode}

                />
                {/* {errors.accountingSystemId && (
                <p className="text-red-500 text-sm ml-4">{errors.accountingSystemId}</p>
              )} */}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-x-8 ">
              <div className="w-[555px] ml-4">&nbsp;</div>
              <div>{!(isViewMode || isProfileMode) && accountingSystemId.length === 12 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}</div>
            </div>

            <div className="grid grid-cols-2 gap-x-8 ">
              <label className="flex items-center ">
                <span className="w-48 font-small text-customGray text-xs">
                  Salute
                </span>
                <span className="ml-4">:</span>
                {/* <select
                  value={salute}
            disabled={isViewMode}
            onChange={(e) => setSalute(e.target.value)}
                  className={`border border-gray-300 p-1 rounded w-72 h-8 ${isViewMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 `}
                >
                  <option value="">Select Salute</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Miss">Miss</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
               */}

                <CustomDropdown
                  value={salute}
                  options={saluteOptions}
                  onChange={(value) => {
                    setSalute(value);
                    setUnsavedChanges(true); // Mark as unsaved
                    setOpenDropdown(false);
                  }} isOpen={openDropdown === "salute"}
                  onToggle={() => handleToggle("salute")}
                  isDisabled={isViewMode || isProfileMode}
                  setUnsavedChanges={setUnsavedChanges}
                  width="2xl:w-72 xl:w-[268px] lg:w-[218px]"

                  className="ml-4"
                  placeholder="Select Salute"
                />

              </label>
            </div>
            <div className="grid grid-cols-2 gap-x-8  mt-4">

              <label className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Surname
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={surname}
                  onChange={(e) => {
                    setSurname(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  disabled={isViewMode || isProfileMode}
                  required
                  className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />

              </label>
            </div>

            <div className="grid grid-cols-2 gap-x-8  mt-4">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs ">
                  First Name<span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={firstName}
                  maxLength={20}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setUnsavedChanges(true);
                  }
                  }
                  disabled={isViewMode || isProfileMode}
                  required
                  className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-5
                  ${errors.firstName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm ml-4">{errors.firstName}</p>
                )}
              </span>
            </div>
            {!(isViewMode || isProfileMode) && firstName.length === 20 && (
              <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
            )}

            <div className="grid grid-cols-2 gap-x-8  mt-4">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Middle Name
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={middleName}
                  maxLength={20}
                  onChange={(e) => {
                    setMiddleName(e.target.value);
                    setUnsavedChanges(true);

                  }}
                  disabled={isViewMode || isProfileMode}
                  required
                  className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0 `}
                />

              </span>
            </div>
            {!(isViewMode || isProfileMode) && middleName.length === 20 && (
              <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
            )}

            <div className="grid grid-cols-2 gap-x-8  mt-4">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Last Name <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={lastName}
                  maxLength={20}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setUnsavedChanges(true);

                  }}
                  className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.lastName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                  disabled={isViewMode || isProfileMode}
                  required
                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm ml-4">{errors.lastName}</p>
                )}
              </span>
            </div>
            {!(isViewMode || isProfileMode) && lastName.length === 20 && (
              <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
            )}


          </div>
        </div>
      )}

      {activeTab === "contactDetails" && (
        <div className="m-6 border border-gray-200 p-2  rounded-lg">
          <div className="flex flex-col space-y-4 col-span-1 p-2">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-gray-500 text-xs">
                  Email ID <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  disabled={isViewMode || isProfileMode}
                  maxLength={80}
                  className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-9 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
      ${errors.email ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                />
              </span>
              {!(isViewMode || isProfileMode) && email.length === 80 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )}
            </label>

            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-gray-500 text-xs">
                  Mobile No <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-20">:</span>

                <PhoneInput
                  className={`ml-4 text-xs ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} ${errors.mobileNo ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'
                    }`}
                  inputStyle={{ width: '292px' }}
                  country={'in'}
                  disabled={isViewMode || isProfileMode}
                  value={mobileNo}

                  onChange={(phone) => {
                    setMobileNo(phone);
                    setUnsavedChanges(true);
                  }}
                />
              </span>

              {errors.mobileNo && (
                <p className="text-red-500 text-sm mt-1">{errors.mobileNo}</p>
              )}
            </label>




            <label className="flex flex-col items-start">
              <span className="flex items-center">

                <span className="w-48 font-small text-gray-500 text-xs">
                  Whatsapp No
                </span>
                <span className="ml-20">:</span>

                <PhoneInput
                  className={`ml-4 text-xs ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'}`}
                  inputStyle={{ width: '292px' }}
                  country={'in'} // Default country
                  disabled={isViewMode || isProfileMode}
                  value={whatsappNo}
                  onChange={(phone) => {
                    setWhatsappNo(phone);
                    setUnsavedChanges(true);

                  }}
                // inputClass={`border border-gray-300 p-1.5 text-xs rounded  h-8 focus:outline-none hover:border-blue-400 peer placeholder-transparent peer-focus:placeholder-opacity-0           `}
                />
              </span>

            </label>

            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-gray-500 text-xs">
                  Office No
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled={isViewMode || isProfileMode}
                  maxLength={12}
                  value={officeNo}
                  onChange={(e) => {
                    setOfficeNo(e.target.value);
                    setUnsavedChanges(true);

                  }}
                  className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-9 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </span>
              {!(isViewMode || isProfileMode) && officeNo.length === 12 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}
            </label>

            <label className="flex items-center">
              <span className="w-48 font-small text-gray-500 text-xs">
                Extension
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                disabled={isViewMode || isProfileMode}
                value={extension}
                onChange={(e) => {
                  setExtension(e.target.value);
                  setUnsavedChanges(true);

                }}
                className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-9 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />
            </label>
          </div>
          <div className="flex flex-row mt-12 ">

            {/* <button 
             onClick={nextTabMoveToIdentity}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button> */}

          </div>
        </div>
      )}

      {activeTab === "identity" && (
        <div className="m-6 border border-gray-200 p-2  rounded-lg">
          <div className="flex flex-col space-y-4 col-span-1 p-2">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}
            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Passport No
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled={isViewMode || isProfileMode}
                  maxLength={10}
                  value={passportNo}
                  onChange={(e) => {
                    setPassportNo(e.target.value);
                    setUnsavedChanges(true);

                  }}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-xs ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </span>
              {!(isViewMode || isProfileMode) && passportNo.length === 10 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}
            </label>

            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Driving Licence No
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  maxLength={20}
                  disabled={isViewMode || isProfileMode}
                  value={drivingLicenseNo}
                  onChange={(e) => {
                    setDrivingLicenseNo(e.target.value);
                    setUnsavedChanges(true);

                  }}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-xs ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </span>
              {!(isViewMode || isProfileMode) && drivingLicenseNo.length === 20 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}
            </label>

            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Local ID
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled={isViewMode || isProfileMode}
                  maxLength={12}
                  value={localId}
                  onChange={(e) => {
                    setLocalId(e.target.value);
                    setUnsavedChanges(true);

                  }}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-xs ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </span>
              {!(isViewMode || isProfileMode) && localId.length === 12 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}
            </label>

            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Nationality<span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled={isViewMode || isProfileMode}
                  maxLength={20}
                  value={nationality}
                  onChange={(e) => {
                    setNationality(e.target.value);
                    setUnsavedChanges(true);

                  }}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-xs ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                   ${errors.nationality ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}

                />
                {errors.nationality && (
                  <p className="text-red-500 text-sm ml-4">
                    {errors.nationality}
                  </p>
                )}
              </span>
              {!(isViewMode || isProfileMode) && nationality.length === 20 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}
            </label>

          </div>
          <div className="flex flex-row mt-12">

            {/* <button 
             onClick={nextTabMoveToOrganization}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button> */}

          </div>
        </div>
      )}

      {activeTab === "organization" && (
        <div className="m-6 border border-gray-200 p-2  rounded-lg">
          <div className="flex flex-col space-y-4 col-span-1 p-2">
            {/* {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )} */}

            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Employee No
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled={isViewMode || isProfileMode}
                  value={employeeNo}
                  maxLength={12}
                  onChange={(e) => {
                    setemployeeNo(e.target.value);
                    setUnsavedChanges(true);

                  }}
                  className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </span>
              {!(isViewMode || isProfileMode) && employeeNo.length === 12 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}
            </label>

            <LabelWithButton
              label="Company Code"
              isDisabled={isViewMode || isProfileMode}
              onClick={() => { setIsModalOpenForCompany(true); }
              }
              buttonText={companyCodeId || 'Select Company Code'}
              error={errors.companyCodeId}
              width="2xl:w-72 xl:w-[282px] lg:w-[285px]"
              marginLeft="ml-4"   // Set custom margin-left
            />


            <ReusableModal
              title="Select Company Code"
              onRefresh={fetchCompanyCode}
              isOpen={isModalOpenForCompany}
              onClose={() => {
                setIsModalOpenForCompany(false);
                setSearchQueryCompanyCode('');
              }}
              onSave={() => {
                setIsModalOpenForCompany(false);
                setSearchQueryCompanyCode('');
              }}
              searchPlaceholder="Search Company Code..."
              searchQuery={searchQueryCompanyCode}
              onSearchChange={handleSearchChangeCompanyCode}
              isViewMode={isViewMode || isProfileMode}
            >
              {companyCodes.length > 0 ? (
                // <div className="grid grid-cols-3 gap-4">
                // {
                companyCodes
                  .filter(
                    (company) =>
                      // company.State_Name.toLowerCase().includes(searchQueryCompanyCode.toLowerCase()) ||
                      company.companyCode.toLowerCase().includes(searchQueryCompanyCode.toLowerCase())
                  )
                  .map((company, index) => (
                    <div key={company.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                      <input
                        type="radio"
                        name="country"
                        value={company.companyCode}
                        checked={selectedCompany === company.companyCode}
                        onChange={handleCheckboxChangeForCompanyCode}
                        disabled={isViewMode || isProfileMode}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{company.companyCode}</label>
                    </div>
                  ))
                // }
                // </div>
              ) : (
                <p>Company Code Not Found...</p>
              )}

            </ReusableModal>


            {/* <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">Company Code</label>
            <span className="ml-4">:</span>
              <button
            onClick={toggleModalForCompanyCode}
            className={`border border-gray-300 p-1 rounded w-72 h-8 ${isViewMode|| isProfileMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4
             text-left flex items-center justify-between `}
             disabled={ isViewMode|| isProfileMode}
          >
            <span>{companyCodeId || 'Select Company Code'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>
          {isModalOpenForCompany && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-customGray">Select Company Code</h2>

                      <input
              type="text"
              placeholder="Search Company Code..."
              value={searchQueryCompanyCode}
              onChange={handleSearchChangeCompanyCode}
              className="border border-gray-300 p-1 rounded text-customGray w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
            />
                  <button onClick={toggleModalForCompanyCode} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
                </div>
                <div className="flex-1 overflow-auto p-4">

                  {companyCodes.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                      {companyCodes
                      .filter((company) =>
                        // company.State_Name.toLowerCase().includes(searchQueryCompanyCode.toLowerCase()) ||
                      company.companyCode.toLowerCase().includes(searchQueryCompanyCode.toLowerCase())
                      )
                      .map((company, index) => (
                        <div key={company.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={company.companyCode}
                            checked={selectedCompany === company.companyCode}
                            onChange={handleCheckboxChangeForCompanyCode}
                            className="mr-2"
                          />
                          <label className="text-customGray">{company.companyCode}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Company Code Not Found...</p>
                  )}
                </div>
                
                  <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                  <button
                    onClick={toggleModalForCompanyCode}
                    className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                  >
                    Save
                  </button>
                </div>
              
                
              </div>
            </div>
          )}
            </div> */}

            <label className="flex flex-col items-start">
              <span className="flex items-center">
                <span className="w-48 font-small text-customGray text-xs">
                  Department
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled={isViewMode || isProfileMode}
                  value={department}
                  maxLength={6}
                  onChange={(e) => {
                    setdepartment(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  className={`border border-gray-300 text-xs p-1.5 rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                />
              </span>
              {!(isViewMode || isProfileMode) && department.length === 6 && (
                <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
              )}
            </label>


            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Valid From<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                disabled={isViewMode || isProfileMode}
                value={validFrom}
                onChange={(e) => {
                  const selectedDate = e.target.value;
                  setvalidFrom(selectedDate);
                  setErrors({ ...errors, validFrom: '' });

                  // Validation check
                  if (validTo && new Date(selectedDate) >= new Date(validTo)) {
                    setErrors({
                      ...errors,
                      validFrom: 'Valid From date cannot be after Valid To date',
                      validTo: ''
                    });
                  } else {
                    setErrors({ ...errors, validFrom: '', validTo: '' });
                  }
                }}
                className={`border border-gray-300 p-1.5 rounded text-xs w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
      ${errors.validFrom ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
              />


              {/* <DatePickerComponent
    selectedDate={validFrom}
    onChange={handleValidFromChange}
    placeholder="dd.mm.yyyy"
    disabled={isViewMode|| isProfileMode}
    error={errors.validFrom}
    /> */}
              {errors.validFrom && (
                <p className="text-red-500 text-sm ml-4">{errors.validFrom}</p>
              )}

            </label>



            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Valid To<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              {/* <DatePickerComponent
          selectedDate={validTo}
          onChange={handleValidToChange}
          placeholder="dd.mm.yyyy"
          disabled={isViewMode|| isProfileMode}
          error={errors.validTo}
        /> */}
              <input
                type="date"
                disabled={isViewMode || isProfileMode}
                value={validTo}
                onChange={(e) => {
                  const selectedDate = e.target.value;
                  setvalidTo(selectedDate);

                  // Validation check
                  if (validFrom && new Date(selectedDate) <= new Date(validFrom)) {
                    setErrors({
                      ...errors,
                      validTo: 'Valid To date cannot be before Valid From date',
                      validFrom: ''
                    });
                  } else {
                    setErrors({ ...errors, validFrom: '', validTo: '' });
                  }
                }}
                className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
      ${errors.validTo ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
              />

              {errors.validTo && <p className="text-red-500 text-sm ml-4">{errors.validTo}</p>}

            </label>


            <label className="flex items-center mt-4">
              <span className="w-48 font-small text-customGray text-xs">
                Joining Date
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={joiningDate}
                onChange={(e) => {
                  setjoiningDate(e.target.value);
                  setErrors({ ...errors, joiningDate: '' });
                }}
                max={validFrom ? validFrom : ''} // Set the minimum date for Joining Date to Valid From
                className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.joiningDate ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                disabled={!validFrom || isViewMode || isProfileMode} // Disable the Joining Date input if Valid From is not set

              />

              {/* <DatePickerComponent
  selectedDate={joiningDate}
  onChange={(date) => {
    setjoiningDate(date);
    setUnsavedChanges(true);
    setErrors({ ...errors, joiningDate: "" });
  }}
  placeholder="dd.mm.yyyy"
  disabled={!validFrom || isViewMode|| isProfileMode}
  maxDate={validFrom ? new Date(validFrom.split('.').reverse().join('-')) : null} // Pass the maxDate prop
/> */}



              {errors.joiningDate && (
                <p className="text-red-500 text-sm ml-4">{errors.joiningDate}</p>
              )}
            </label>

            <label className="flex items-center">
              <span className="w-48 font-small text-customGray text-xs">
                Resignation date
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                disabled={isViewMode || isProfileMode}
                value={resignationDate}
                onChange={(e) => setresignationDate(e.target.value)}
                className={`border border-gray-300 p-1.5 text-xs rounded w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
              />

              {/* <DatePickerComponent
                selectedDate={resignationDate}
                onChange={(date) => {setresignationDate(date);
                setUnsavedChanges(true);

                }}
                placeholder="dd.mm.yyyy"
                disabled={isViewMode|| isProfileMode}
              /> */}


            </label>
          </div>

        </div>
      )}

      {activeTab === "addressDetails" && (
        <div className="m-6 border border-gray-200 p-5  rounded-lg">
          <TableWrapper maxHeight="70vh">

            <div className="p-1">
              {/* Building Section */}
              <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                <h3 className="text-sm font-medium text-customGray  mb-4">Building</h3>

                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48  font-small text-customGray text-xs">Door No </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      maxLength={5}
                      value={doorNo}
                      onChange={(e) => {
                        setdoorNo(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      className={`border border-gray-300 p-1.5 rounded text-xs ml-4 peer w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'
                        } focus:outline-none hover:border-blue-400`} />
                  </div>

                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Floor No </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      maxLength={5}
                      value={floorNo}
                      onChange={(e) => {
                        setfloorNo(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="4" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>

                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>{!(isViewMode || isProfileMode) && doorNo.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                  <div>{!(isViewMode || isProfileMode) && floorNo.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Building No </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      maxLength={5}
                      value={buildingNo}
                      onChange={(e) => {
                        setbuildingNo(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="2A" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>


                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Building Name </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      value={buildingName}
                      maxLength={50}
                      onChange={(e) => {
                        setbuildingName(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="B name" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>

                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>{!(isViewMode || isProfileMode) && buildingNo.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                  <div>{!(isViewMode || isProfileMode) && buildingName.length === 50 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                </div>

                <div className="flex items-center mt-4">
                  <label className="w-48 font-small text-customGray text-xs">Landmark</label>
                  <span className="ml-4">:</span>
                  <input type="text"
                    disabled={isViewMode || isProfileMode}
                    value={landMark}
                    maxLength={50}
                    onChange={(e) => {
                      setlandMark(e.target.value);
                      setUnsavedChanges(true);

                    }}

                    placeholder="" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  <div className="w-[555px] ml-4">&nbsp;</div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {!isViewMode && landMark.length === 50 && (
                    <p className="text-red-500 text-xs pt-2 ">You have used the maximum character limit.</p>
                  )}
                </div>
              </div>

              {/* Street Section */}
              <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                <h3 className="text-sm font-medium text-customGray  mb-4">Street</h3>

                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Street No </label>
                    <span className="ml-4">:</span>
                    <input
                      disabled={isViewMode || isProfileMode}
                      maxLength={5}
                      value={streetNo}
                      onChange={(e) => {
                        setstreetNo(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      type="text" placeholder="2/626" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>


                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Street Name</label>
                    <span className="ml-4">:</span>
                    <input type="text" value={streetName}
                      disabled={isViewMode || isProfileMode}
                      maxLength={30}
                      onChange={(e) => {
                        setstreetName(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />

                  </div>

                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>{!(isViewMode || isProfileMode) && streetNo.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                  <div>{!(isViewMode || isProfileMode) && streetName.length === 30 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                </div>


                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Road No </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      value={roadNo}
                      maxLength={5}
                      onChange={(e) => {
                        setroadNo(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="2A" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>


                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Road Name </label>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      disabled={isViewMode || isProfileMode}
                      maxLength={30}
                      value={roadName}
                      onChange={(e) => {
                        setRoadName(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="B name"
                      className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                    />
                  </div>

                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>{!(isViewMode || isProfileMode) && roadNo.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                  <div>{!(isViewMode || isProfileMode) && roadName.length === 30 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                </div>
              </div>

              {/* Area Section */}
              <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                <h3 className="text-sm font-medium text-customGray  mb-4">Area</h3>

                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Block </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      value={areaBlock}
                      maxLength={5}
                      onChange={(e) => {
                        setareaBlock(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="2/626" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>


                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Area Name </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      maxLength={30}
                      value={areaName}
                      onChange={(e) => {
                        setareaName(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="4" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>

                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>{!(isViewMode || isProfileMode) && areaBlock.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                  <div>{!(isViewMode || isProfileMode) && areaName.length === 30 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                </div>
              </div>

              {/* City section */}
              <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                <h3 className="text-md font-medium text-customGray mb-4">City</h3>

                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-sm">Phase </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={phase}
                      maxLength={5}

                      onChange={(e) => {
                        setPhase(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      disabled={isViewMode || isProfileMode}
                      className="border border-gray-300 p-1.5 rounded text-xs w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm text-customGray font-small text-customGray text-sm" />
                  </div>

                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-sm">Sector </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={sector}
                      maxLength={5}

                      disabled={isViewMode || isProfileMode}
                      onChange={(e) => {
                        setSector(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      placeholder="4" className="border border-gray-300 p-1.5 rounded text-xs w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm " />
                  </div>

                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>{!(isViewMode || isProfileMode) && phase.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                  <div>{!(isViewMode || isProfileMode) && sector.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                </div>


                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-sm">Village </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={village}
                      maxLength={5}

                      disabled={isViewMode || isProfileMode}
                      onChange={(e) => {
                        setVillage(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      placeholder="2A" className="border border-gray-300 p-1.5 rounded text-xs w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                  </div>


                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-sm">Town </label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      value={town}
                      maxLength={50}

                      disabled={isViewMode || isProfileMode}
                      onChange={(e) => {
                        setTown(e.target.value)
                        setUnsavedChanges(true);
                      }}
                      placeholder="B name" className="border border-gray-300 p-1.5 rounded text-xs w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm font-small text-customGray text-sm" />
                  </div>
                  {/* {!isViewMode|| isProfileMode && town.length === 5 && (
                      <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                    )} */}
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>{!(isViewMode || isProfileMode) && village.length === 5 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}</div>
                  <div>
                    {!(isViewMode || isProfileMode) && town.length === 50 && (
                      <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                    )}
                  </div>
                </div>

                <div className="flex items-center mt-4">
                  <label className="w-48 font-small text-customGray text-sm">City</label>
                  <span className="ml-4">:</span>
                  <input type="text"
                    value={city}
                    maxLength={50}

                    onChange={(e) => {
                      setCity(e.target.value)
                      setUnsavedChanges(true);
                    }}
                    disabled={isViewMode || isProfileMode}
                    placeholder="" className="border border-gray-300 p-1.5 rounded text-xs w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-sm" />
                  <div className="w-[555px] ml-4">&nbsp;</div>
                </div>
                {!(isViewMode || isProfileMode) && city.length === 50 && (
                  <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                )}
              </div>

              {/* Display the error message for address section */}
              {errors.addressSection && (
                <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
              )}

              {/* Address Section */}
              <div className="mb-6 border border-gray-300 p-5 rounded-lg">
                <h3 className="text-sm font-medium text-customGray  mb-4">Address</h3>
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex items-center">
                    <label className="w-56 font-small text-customGray text-xs">Address 1 </label>
                    <span className="ml-4">:</span>
                    <input type="text" value={addressLine1}
                      maxLength={100}
                      disabled={isViewMode || isProfileMode}
                      onChange={(e) => {
                        setaddressLine1(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="2/626"
                      className={`border border-gray-300 p-1.5 rounded text-xs w-full h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
                    />
                  </div>
                  {!(isViewMode || isProfileMode) && addressLine1.length === 100 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}
                </div>

                <div className="grid grid-cols-1 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-56 font-small text-customGray text-xs">Address 2 </label>
                    <span className="ml-4">:</span>
                    <input type="text" value={addressLine2}
                      maxLength={100}
                      disabled={isViewMode || isProfileMode}
                      onChange={(e) => {
                        setaddressLine2(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="4" className={`border border-gray-300 p-1.5 rounded text-xs w-full h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>
                  {!(isViewMode || isProfileMode) && addressLine2.length === 100 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}

                </div>

                <div className="grid grid-cols-1 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-56 font-small text-customGray text-xs">Address 3 </label>
                    <span className="ml-4">:</span>
                    <input type="text" value={addressLine3}
                      maxLength={100}
                      disabled={isViewMode || isProfileMode}
                      onChange={(e) => {
                        setaddressLine3(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="2A" className={`border border-gray-300 p-1.5 rounded text-xs w-full h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>
                  {!(isViewMode || isProfileMode) && addressLine3.length === 100 && (
                    <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                  )}

                </div>
              </div>


              {/* Country Section */}
              <div className="border border-gray-300 p-5 rounded-lg">
                <h3 className="text-sm font-medium text-customGray  mb-4">Country</h3>


                <div className="grid grid-cols-2 gap-4">


                  <LabelWithButton
                    label="Country Code"
                    isRequired={true}
                    isDisabled={isViewMode || isProfileMode}
                    onClick={() => { setIsModalOpen(true); }
                    }
                    buttonText={countryCode || 'Select Country Code'}
                    error={errors.countryCode}
                    marginLeft="ml-4"   // Set custom margin-left
                  />

                  <ReusableModal
                    title="Select Country Code"
                    onRefresh={fetchcountry}
                    isOpen={isModalOpen}
                    onClose={() => {
                      setIsModalOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsModalOpen(false);
                      setSearchQuery('');
                    }}
                    showReset={true}
                    onReset={handleCountryReset}
                    searchPlaceholder="Search Country Code..."
                    searchQuery={searchQuery}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode || isProfileMode}
                  >
                    {countries.length > 0 ? (
                      // <div className="grid grid-cols-3 gap-4">
                      //   {
                      countries
                        .filter((country) =>

                          country.country.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map((country, index) => (
                          <div key={country.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                            <input
                              type="radio"
                              name="country"
                              value={country.country}
                              checked={selectedCountry === country.country}
                              onChange={handleCheckboxChange}
                              className="mr-2 text-blue-200"
                            />
                            <label className="text-customGray">{country.country}</label>
                          </div>
                        ))
                      //   }
                      // </div>
                    ) : (
                      <p>Countries Not Found...</p>
                    )}

                  </ReusableModal>


                  <LabelWithButton
                    label="Country Name"
                    isRequired={true}
                    isDisabled={isViewMode || isProfileMode}
                    onClick={() => { setIsModalOpenForCountryName(true); }
                    }
                    buttonText={countryName || 'Select Country Name'}
                    error={errors.countryName}
                    marginLeft="ml-4"   // Set custom margin-left
                  />
                  <ReusableModal
                    onRefresh={fetchcountry}
                    title="Select Country Name"
                    isOpen={isModalOpenForCountryName}
                    onClose={() => {
                      setIsModalOpenForCountryName(false);
                      setSearchQuery('');
                    }}
                    showReset={true}
                    onReset={handleCountryNameReset}
                    onSave={() => {
                      setIsModalOpenForCountryName(false);
                      setSearchQuery('');
                    }}
                    searchPlaceholder="Search Country Name..."
                    searchQuery={searchQuery}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode || isProfileMode}
                  >
                    {countries.length > 0 ? (
                      // <div className="grid grid-cols-3 gap-4">
                      //   {
                      countries
                        .filter((country) =>
                          country.country.toLowerCase().includes(searchQuery.toLowerCase())
                        )
                        .map((country, index) => (
                          <div key={country.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                            <input
                              type="radio"
                              name="country"
                              value={country.country}
                              checked={selectedCountry === country.country}
                              onChange={handleCheckboxChangeforCountryName}
                              className="mr-2 text-blue-200"
                            />
                            <label className="text-customGray">{country.countryName}</label>
                          </div>
                        ))
                      //   }
                      // </div>
                    ) : (
                      <p>Countries Not Found...</p>
                    )}
                  </ReusableModal>

                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <LabelWithButton
                    label="State / Province"
                    isRequired={true}
                    isDisabled={!countryCode || isViewMode || isProfileMode}
                    onClick={() => { setIsModalOpenForState(true); }
                    }
                    buttonText={state || 'Select State / Province Code'}
                    error={errors.state}
                    marginLeft="ml-4"   // Set custom margin-left
                  />


                  <ReusableModal
                    title="Select State / Province Code"
                    onRefresh={fetchstateprovice}
                    isOpen={isModalOpenForState}
                    onClose={() => {
                      setIsModalOpenForState(false);
                      setSearchQueryState('');
                    }}
                    onSave={() => {
                      setIsModalOpenForState(false);
                      setSearchQueryState('');
                    }}
                    showReset={true}
                    onReset={handleStateReset}
                    searchPlaceholder="Search State / Province Code..."
                    searchQuery={searchQueryState}
                    onSearchChange={handleSearchChangeState}
                    isViewMode={isViewMode || isProfileMode}
                  >
                    {stateProvinces.length > 0 ? (
                      // <div className="grid grid-cols-3 gap-4">
                      //   {
                      stateProvinces
                        .filter((stateP) =>
                          stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                        )
                        .map((stateP, index) => (
                          <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                            <input
                              type="radio"
                              name="country"
                              value={stateP.state}
                              checked={selectedState === stateP.state}
                              onChange={handleCheckboxChangeForState}
                              className="mr-2"
                            />
                            <label className="text-customGray">{stateP.state}</label>
                          </div>
                        ))
                      //   }
                      // </div>
                    ) : (
                      <p>Countries Not Found...</p>
                    )}
                  </ReusableModal>


                  {/* <div className="flex items-center">
          <label className="w-48 font-small text-customGray text-xs">State / Province Name</label>
          <span className="ml-4">:</span>
           <button
            onClick={toggleModalForStateName}
            disabled={!countryCode || isViewMode|| isProfileMode}

            className={`border border-gray-300 p-1 rounded w-80 h-8 ${isViewMode|| isProfileMode|| !countryCode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between 
            ${errors.stateName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{stateName || 'Select State / Province Name'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>
          {isModalOpenForStateName && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-customGray">Select State / Province Name</h2>

                      <input
              type="text"
              placeholder="Search state province..."
              value={searchQueryState}
              onChange={handleSearchChangeState}
              className="border border-gray-300 p-1 rounded text-customGray w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
            />
                  <button onClick={toggleModalForStateName} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
                </div>
                <div className="flex-1 overflow-auto p-4">

                  {stateProvinces.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                      {stateProvinces
                      .filter((stateP) =>
                        stateP.State_Name.toLowerCase().includes(searchQueryState.toLowerCase()) 
                      // || stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                      )
                      .map((stateP, index) => (
                        <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={stateP.state}
                            checked={selectedState === stateP.state}
                            onChange={handleCheckboxChangeForState}
                            className="mr-2"
                          />
                          <label className="text-customGray">{stateP.State_Name}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Countries Not Found...</p>
                  )}
                </div>
                
                  <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                  <button
                    onClick={toggleModalForStateName}
                    className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                  >
                    Save
                  </button>
                </div>
              
                
              </div>
            </div>
          )}

        </div> */}

                  <LabelWithButton
                    label="State / Province Name"
                    isRequired={true}
                    isDisabled={!countryCode || isViewMode || isProfileMode}
                    onClick={() => { setIsModalOpenForStateName(true); }
                    }
                    buttonText={stateName || 'Select State / Province Name'}
                    error={errors.stateName}
                    marginLeft="ml-4"   // Set custom margin-left
                  />


                  <ReusableModal
                    title="Select State / Province Name"
                    isOpen={isModalOpenForStateName}
                    onRefresh={fetchstateprovice}
                    onClose={() => {
                      setIsModalOpenForStateName(false);
                      setSearchQueryState('');
                    }}
                    onSave={() => {
                      setIsModalOpenForStateName(false);
                      setSearchQueryState('');
                    }}
                    showReset={true}
                    onReset={handleStateNameReset}
                    searchPlaceholder="Search State / Province Name..."
                    searchQuery={searchQueryState}
                    onSearchChange={handleSearchChangeState}
                    isViewMode={isViewMode || isProfileMode}
                  >
                    {stateProvinces.length > 0 ? (
                      // <div className="grid grid-cols-3 gap-4">
                      //   {
                      stateProvinces
                        .filter((stateP) =>
                          stateP.State_Name.toLowerCase().includes(searchQueryState.toLowerCase())
                          // || stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                        )
                        .map((stateP, index) => (
                          <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                            <input
                              type="radio"
                              name="country"
                              value={stateP.state}
                              checked={selectedState === stateP.state}
                              onChange={handleCheckboxChangeForState}
                              className="mr-2"
                            />
                            <label className="text-customGray">{stateP.State_Name}</label>
                          </div>
                        ))
                      //   }
                      // </div>
                    ) : (
                      <p>Countries Not Found...</p>
                    )}

                  </ReusableModal>

                </div>


                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">District</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      value={district}
                      maxLength={30}
                      onChange={(e) => {
                        setdistrict(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="2A"
                      className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>


                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Postal Code</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      maxLength={10}
                      value={postalCode}
                      onChange={(e) => {
                        setpostalCode(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="B name" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />

                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {!(isViewMode || isProfileMode) && district.length === 30 && (
                      <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                    )}
                  </div>
                  <div>
                    {!(isViewMode || isProfileMode) && postalCode.length === 10 && (
                      <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">Zip Code</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      maxLength={10}
                      value={zipCode}
                      onChange={(e) => {
                        setzipCode(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="2A" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>


                  <div className="flex items-center">
                    <label className="w-48 font-small text-customGray text-xs">PO Box</label>
                    <span className="ml-4">:</span>
                    <input type="text"
                      disabled={isViewMode || isProfileMode}
                      maxLength={10}
                      value={poBox}
                      onChange={(e) => {
                        setpoBox(e.target.value);
                        setUnsavedChanges(true);

                      }}
                      placeholder="B name" className={`border border-gray-300 p-1.5 rounded text-xs w-80 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`} />
                  </div>


                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {!(isViewMode || isProfileMode) && zipCode.length === 10 && (
                      <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                    )}
                  </div>
                  <div>
                    {!(isViewMode || isProfileMode) && poBox.length === 10 && (
                      <p className="text-red-500 text-sm">You have used the maximum character limit.</p>
                    )}
                  </div>
                </div>

              </div>


            </div>
          </TableWrapper>
        </div>
      )}
      {/* <div className="flex-grow"> */}
      {activeTab === "userPreferences" && (
        <div className="flex flex-col ">
          <div className="m-6 mb-6 border border-gray-200 p-5  rounded-lg ">
            <TableWrapper maxHeight="80vh">
              <div className="flex-grow p  ">
                <div className="grid grid-cols-2 gap-x-8 gap-y-8 mb-6 ">
                  <div className="flex flex-col space-y-6 mb-3">
                    {errors.userId && (
                      <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
                    )}


                    <LabelWithButton
                      label="Language "
                      isDisabled={isViewMode || isProfileMode}
                      onClick={() => { setIslanguageModalOpen(true); }
                      }
                      isRequired={true}
                      buttonText={language || 'Select language '}
                      error={errors.language}
                      width="2xl:w-72 xl:w-[296px] lg:w-[318px]"
                      marginLeft="ml-4"   // Set custom margin-left
                      className="mb-[-2px]"
                    />

                    <ReusableModal
                      title="Select Language Code"
                      onRefresh={fetchLanguages}
                      isOpen={islanguageModalOpen}
                      onClose={() => {
                        setIslanguageModalOpen(false);
                        setSearchQuery('');
                      }}
                      onSave={() => {
                        setIslanguageModalOpen(false);
                        setSearchQuery('');
                      }}
                      searchPlaceholder="Search Language..."
                      searchQuery={searchQuery}
                      onSearchChange={handleSearchChange}
                      isViewMode={isViewMode || isProfileMode}
                    >
                      {languages.length > 0 ? (
                        languages
                          .filter(
                            (language) =>
                              language.LE &&
                              language.LE.toLowerCase().includes(
                                searchQuery.toLowerCase()
                              )
                          )
                          .map((language, index) => (
                            <div
                              key={language.id}
                              className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                                }`}
                            >
                              <input
                                type="radio"
                                name="language"
                                value={language.LE}
                                checked={
                                  selectedlanguage ===
                                  language.LE
                                }
                                onChange={handleLanguageCheckBoxChange}
                                className="mr-2 accent-customBlue"
                                disabled={isViewMode || isProfileMode}
                              />
                              <label>{language.LE}</label>
                            </div>
                          ))

                      ) : (
                        <p>Loading Currencies...</p>
                      )}
                    </ReusableModal>


                    <label className="flex items-center">
                      <span className="w-48 font-small text-customGray text-xs">
                        Date Format<span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-3">:</span>
                      {/* <select
            disabled={isViewMode|| isProfileMode}
            value={dateFormat}
    onChange={(e) => setdateFormat(e.target.value)}
    className={`border border-gray-300 p-1 rounded w-72 h-8 ${isViewMode|| isProfileMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4
        ${errors.dateFormat ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
  >
    <option value="">Select Date Format</option>

    <option value="1">DD/MM/YYYY</option>
    <option value="2">MM/DD/YYYY</option>
    <option value="3">DD/MM/YY</option>
    <option value="4">MM/DD/YY</option>

    <option value="5">DD.MM.YYYY</option>
    <option value="6">DDMMYY</option>

  </select> */}

                      <CustomDropdown
                        value={dateFormat}
                        options={dateFormatOptions}
                        onChange={(value) => {
                          setdateFormat(value);
                          setUnsavedChanges(true); // Mark as unsaved
                          setOpenDropdown(false);
                        }}
                        isDisabled
                        // isDisabled={isViewMode|| isProfileMode}
                        isOpen={openDropdown === "dateFormat"}
                        onToggle={() => handleToggle("dateFormat")}
                        width="2xl:w-72 xl:w-[282px] lg:w-[235px]"
                        error={errors.dateFormat}
                        setUnsavedChanges={setUnsavedChanges}
                        className="ml-4"
                        placeholder="Select Date Format"
                      />

                      {errors.dateFormat && (
                        <p className="text-red-500 text-sm ml-4">
                          {errors.dateFormat}
                        </p>
                      )}
                    </label>

                    <label className="flex items-center">
                      <span className="w-48 font-small text-customGray text-xs">
                        Amount Format <span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-3">:</span>

                      {/* <select
                value={amountFormat}
            disabled={isViewMode|| isProfileMode}
            onChange={(e) => setamountFormat(e.target.value)}
                className={`border border-gray-300 p-1 rounded w-72 h-8 ${isViewMode|| isProfileMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4`}
              >
                <option value="">Select Amount Format</option>
            
                <option value="'1,234,567.89">'1,234,567.89</option>
                <option value="1.234.567,890">1.234.567,890</option>
                <option value="12,34,567.890">12,34,567.890</option>            
              </select> */}

                      <CustomDropdown
                        value={amountFormatOptions.find((opt) => opt.value === amountFormat)}
                        options={amountFormatOptions}
                        onChange={(value) => {
                          setamountFormat(value);
                          setUnsavedChanges(true); // Mark as unsaved
                          setOpenDropdown(false);
                        }}
                        isDisabled={isViewMode || isProfileMode}
                        isOpen={openDropdown === "amountFormat"}
                        onToggle={() => handleToggle("amountFormat")}
                        setUnsavedChanges={setUnsavedChanges}
                        width="2xl:w-72 xl:w-[282px] lg:w-[235px]"
                        error={errors.amountFormat}
                        className="ml-4"
                        placeholder="Select Amount Format"
                      />

                      {errors.amountFormat && (
                        <p className="text-red-500 text-sm ml-4">
                          {errors.amountFormat}
                        </p>
                      )}

                    </label>


                    {/* <label className="flex items-center">
                      <span className="w-48 font-small text-customGray text-xs">
                        Thousand Separator
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        disabled={isViewMode || isProfileMode}
                        value={thousandSeparator}
                        onChange={handleThousandChange}
                        className={`border p-1.5 rounded text-xs w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none ml-4 ${error.thousand || error.same ? 'border-red-500' : 'border-gray-300 hover:border-blue-400'
                          }`}
                      />
                    </label>
                    {error.thousand && (
                      <p className="text-red-500 text-sm mt-1">
                        Only '.' and ',' are allowed for thousand separator.
                      </p>
                    )}

                    <label className="flex items-center mt-4">
                      <span className="w-48 font-small text-customGray text-xs">
                        Decimal Separator
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        disabled={isViewMode || isProfileMode}
                        value={decimalSeparator}
                        onChange={handleDecimalChange}
                        className={`border p-1.5 rounded text-xs w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none ml-4 ${error.decimal || error.same ? 'border-red-500' : 'border-gray-300 hover:border-blue-400'
                          }`}
                      />
                    </label>
                    {error.decimal && (
                      <p className="text-red-500 text-sm mt-1">
                        Only '.' and ',' are allowed for decimal separator.
                      </p>
                    )}

                    {error.same && (
                      <p className="text-red-500 text-sm mt-1">
                        Thousand and decimal separators cannot be the same.
                      </p>
                    )}*/}


                    <label className="flex items-center">
                      <span className="w-48 font-small text-customGray text-xs">
                        Accelerator for Thousands
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        disabled={isViewMode || isProfileMode}
                        value={acceleratorForThousands + suffixForThousands} // Append current suffix ('K' or 'T')
                        onChange={handleChangeforThousands}
                        className={`border p-1.5 rounded text-xs w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none ml-4 ${errorThousands ? 'border-red-500' : 'border-gray-300 hover:border-blue-400'
                          }`}
                      />
                    </label>
                    {errorThousands && (
                      <p className="text-red-500 text-sm mt-1">
                        Only 'K' or 'T' are allowed.
                      </p>
                    )}


                    <label className="flex items-center">
                      <span className="w-48 font-small text-customGray text-xs">
                        Accelerator for Lakhs
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        disabled={isViewMode || isProfileMode}
                        value={acceleratorForLakhs + suffixForLakhs}
                        onChange={handleChangeforLakhs}
                        className={`border border-gray-300 p-1.5 rounded text-xs w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4`}
                      />
                    </label>
                    {errorLakhs && (
                      <p className="text-red-500 text-sm mt-1">
                        Only 'L' are allowed.
                      </p>
                    )}

                    <label className="flex items-center">
                      <span className="w-48 font-small text-customGray text-xs">
                        Accelerator for Millions
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        disabled={isViewMode || isProfileMode}
                        type="text"
                        value={acceleratorForMillions + suffixForMillions}
                        onChange={handleChangeforMillions}
                        className={`border border-gray-300 p-1.5 rounded text-xs w-72 h-8 ${isViewMode || isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4`}
                      />
                    </label>
                    {errorMillions && (
                      <p className="text-red-500 text-sm mt-1">
                        Only 'M' are allowed.
                      </p>
                    )} 

                    <label className="flex items-center">
                      <span className="w-48 font-small text-customGray text-xs">
                        Time Format <span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-3">:</span>
                      {/* <select
                  value={timeFormat}
            disabled={isViewMode|| isProfileMode}
            onChange={(e) => settimeFormat(e.target.value)}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode|| isProfileMode ? 'text-gray-500 bg-gray-100':'text-customGray'} focus:outline-none hover:border-blue-400 ml-4
                      ${errors.timeFormat ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                >
                  <option value="2">24 Hours</option>
                  <option value="1">12 Hours</option>
                  <option value="">Select Time Format</option>
                </select> */}

                      <CustomDropdown
                        value={timeFormat}
                        options={timeFormatOptions}
                        onChange={(value) => {
                          settimeFormat(value);
                          setUnsavedChanges(true); // Mark as unsaved
                          setOpenDropdown(false);
                        }} isDisabled={isViewMode || isProfileMode}
                        width="2xl:w-72 xl:w-[282px] lg:w-[235px]"
                        error={errors.timeFormat}
                        isOpen={openDropdown === "timeFormat"}
                        onToggle={() => handleToggle("timeFormat")}
                        setUnsavedChanges={setUnsavedChanges}
                        className="ml-4"
                        placeholder="Select Time Format"
                      />

                      {errors.timeFormat && (
                        <p className="text-red-500 text-sm ml-4">
                          {errors.timeFormat}
                        </p>
                      )}
                    </label>


                    <label className="flex items-center ">
                      <span className="w-48 font-small text-customGray text-xs ">
                        Time Zone
                      </span>
                      <span className="ml-3">:</span>
                      {/* <input
                        type="text"
                        value={timeZone}
                        disabled={isViewMode|| isProfileMode}
                        onChange={(e) => {
                          settimeZone(e.target.value);
                          setUnsavedChanges(true);

                        }}
                        className={`border border-gray-300 p-1.5 rounded w-72 h-8 ${isViewMode|| isProfileMode ? 'text-gray-500' : 'text-customGray'} focus:outline-none hover:border-blue-400 ml-4`}
                      /> */}
                      <CustomDropdown
                        value={timeZone}
                        options={timeZoneOptions}
                        onChange={(value) => {
                          settimeZone(value);
                          setUnsavedChanges(true); // Mark as unsaved
                          setOpenDropdown(false);
                        }} isDisabled={isViewMode}
                        width="2xl:w-72 xl:w-[282px] lg:w-[235px]"

                        isOpen={openDropdown === "timeZone"}
                        onToggle={() => handleToggle("timeZone")}
                        setUnsavedChanges={setUnsavedChanges}
                        className="ml-4"
                        placeholder="Select Time Zone"
                      />
                    </label>

                  </div>

                  <div className="flex flex-col space-y-6">
                    <label className="flex items-center">
                      <input
                        disabled={isViewMode || isProfileMode}
                        type="checkbox"
                        checked={receiveSystemMail === 1} // Check if the value is 1
                        onChange={(e) => {
                          setreceiveSystemMail(e.target.checked ? 1 : 0);
                          setUnsavedChanges(true);

                        }} // Set value to 1 if checked, otherwise 0
                        className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500 "
                      />
                      <p className="ml-4 font-small text-customGray text-xs">
                        Don't want to receive System emails
                      </p>
                    </label>

                    <label className="flex items-center">
                      <input
                        disabled={isViewMode || isProfileMode}
                        type="checkbox"
                        checked={downloadData === 1} // Check if the value is 1
                        onChange={(e) => {
                          setdownloadData(e.target.checked ? 1 : 0);
                          setUnsavedChanges(true);

                        }} // Set value to 1 if checked, otherwise 0
                        className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                      />
                      <p className="ml-4 font-small text-customGray text-xs">
                        Download the Data
                      </p>
                    </label>

                    <label className="flex items-center">
                      <input
                        disabled={isViewMode || isProfileMode}
                        type="checkbox"
                        checked={changesInCashflow === 1} // Check if the value is 1
                        onChange={(e) => {
                          setchangesInCashflow(e.target.checked ? 1 : 0);
                          setUnsavedChanges(true);

                        }} // Set value to 1 if checked, otherwise 0
                        className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                      />
                      <p className="ml-4 font-small text-customGray text-xs">
                        Change the Cash flow code
                      </p>
                    </label>

                    <label className="flex items-center">
                      <input
                        disabled={isViewMode || isProfileMode}
                        type="checkbox"
                        checked={changeVarient === 1} // Check if the value is 1
                        onChange={(e) => {
                          setchangeVarient(e.target.checked ? 1 : 0);
                          setUnsavedChanges(true);

                        }} // Set value to 1 if checked, otherwise 0
                        className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                      />
                      <p className="ml-4 font-small text-customGray text-xs">
                        Can Change the varient
                      </p>
                    </label>

                    <label className="flex items-center">
                      <input
                        disabled={isViewMode || isProfileMode}
                        type="checkbox"
                        checked={deleteAllRecords === 1} // Check if the value is 1
                        onChange={(e) => {
                          setdeleteAllRecords(e.target.checked ? 1 : 0);
                          setUnsavedChanges(true);

                        }} // Set value to 1 if checked, otherwise 0
                        className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                      />
                      <p className="ml-4 font-small text-customGray text-xs">
                        Can Delete all records
                      </p>
                    </label>

                    <label className="flex items-center">
                      <input
                        disabled={isViewMode || isProfileMode}
                        type="checkbox"
                        checked={changeAccountingCode === 1} // Check if the value is 1
                        onChange={(e) => {
                          setchangeAccountingCode(e.target.checked ? 1 : 0);
                          setUnsavedChanges(true);

                        }} // Set value to 1 if checked, otherwise 0
                        className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                      />
                      <p className="ml-4 font-small text-customGray text-xs">
                        Change the Accounting Code
                      </p>
                    </label>
                    <label className="flex items-center">
                      <input
                        disabled={isViewMode || isProfileMode}
                        type="checkbox"
                        checked={updateCountry === 1} // Check if the value is 1
                        onChange={(e) => {
                          setupdateCountry(e.target.checked ? 1 : 0);
                          setUnsavedChanges(true);

                        }} // Set value to 1 if checked, otherwise 0
                        className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                      />
                      <p className="ml-4 font-small text-customGray text-xs">
                        Do Not Update Country
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </TableWrapper>
          </div>
        </div>
      )}
      {/* </div> */}

      {isProfileMode ? (
        <>
          {activeTab === "authorizations" && (
            <div className="m-6 border border-gray-200 p-5  rounded-lg">
              {/* Display error message if userId has errors */}
              <div className="flex flex-col space-y-8 col-span-1">
                {errors.userId && (
                  <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
                )}

                {/* Authorizations Content */}
                <div className={`max-w-8xl ${pdfMode ? "text-[10px]" : "text-base"}`}>

                  {/* Filters */}
                  <div className=" py-1.5 px-3 grid grid-cols-[1fr_1fr_1fr_auto] gap-x-4 gap-y-4 items-center shadow-sm mb-6">
                    {/* Module Field */}

                    <LabelWithButton
                      label="Module"
                      labelwidth='w-32'
                      onClick={() => { setIsModalOpenForModule(true); }
                      }
                      buttonText={mainMenu || 'Select Module Name'}
                      marginLeft="ml-2"   // Set custom margin-left
                      width='w-full'
                    />

                    <ReusableModal
                      title="Select Module Name"
                      isOpen={isModalOpenForModule}
                      onClose={() => {
                        setIsModalOpenForModule(false);
                        setSearchQueryMainmodule('');
                      }}
                      onSave={() => {
                        setIsModalOpenForModule(false);
                        setSearchQueryMainmodule('');
                      }}
                      onReset={() => {
                        setMainMenu('');
                        setselectMainMenu('');
                        setSubMenu("");
                        setselectSubMenu("");
                        setIconMenu("");
                        setselectIconMenu("");
                      }}
                      showReset={true} // Only show Reset button in this modal
                      searchPlaceholder="Search Module Name..."
                      searchQuery={searchQueryMainmodule}
                      onSearchChange={handleSearchChangeMainmodule}
                    // isViewMode={isViewMode}
                    >
                      {Icons.length > 0 ? (
                        // <div className="grid grid-cols-3 gap-4">
                        //   {
                        Icons.
                          filter((Icon) => Icon.name.toLowerCase().includes(searchQueryMainmodule.toLowerCase()))
                          .map((Icon, index) => (
                            <div key={Icon.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                              <input
                                type="radio"
                                name="country"
                                value={Icon.name}
                                checked={selectMainMenu === Icon.name}
                                onChange={handleCheckboxChangeForModule}
                                className="mr-2"
                              />
                              <label className="text-customGray">{Icon.name}</label>
                            </div>
                          ))
                        // }
                        // </div>
                      ) : (
                        <p>Icons Not Found...</p>
                      )}

                    </ReusableModal>

                    <LabelWithButton
                      label="Sub Module"
                      labelwidth='w-32'
                      onClick={() => { setIsModalOpenForSubModule(true); }
                      }
                      buttonText={subMenu || 'Select Sub Module Name'}
                      marginLeft="ml-2"   // Set custom margin-left
                      width='w-full'
                    />


                    <ReusableModal
                      title="Select Sub Module Name"
                      isOpen={isModalOpenForSubModule}
                      onClose={() => {
                        setIsModalOpenForSubModule(false);
                        setSearchQuerySubmodule('');
                      }}
                      onSave={() => {
                        setIsModalOpenForSubModule(false);
                        setSearchQuerySubmodule('');
                      }}
                      onReset={() => {
                        setSubMenu("");
                        setselectSubMenu("");
                        setIconMenu("");
                        setselectIconMenu("");
                      }}
                      showReset={true} // Only show Reset button in this modal
                      searchPlaceholder="Search Sub Module Name..."
                      searchQuery={searchQuerySubmodule}
                      onSearchChange={handleSearchChangeSubmodule}
                    // isViewMode={isViewMode}
                    >
                      {(() => {
                        const matchingMainMenu = filteredIcons.find(
                          (icon) =>
                            icon.name === mainMenu
                        );
                        const allSubMenus = matchingMainMenu?.subMenus || [];
                        console.log("In submodule get all submenus data", allSubMenus);



                        const filteredSubMenus = searchQuerySubmodule
                          ? allSubMenus.filter((sub) =>
                            sub.name
                              .toLowerCase()
                              .includes(searchQuerySubmodule.toLowerCase())
                          )
                          : allSubMenus;

                        return allSubMenus.length > 0 ? (
                          // <div className="grid grid-cols-3 gap-4">
                          //   {
                          filteredSubMenus.map((subMenuItem) => (
                            <div key={subMenuItem.id} className="flex items-center">
                              <input
                                type="radio"
                                name="subMenu"
                                value={subMenuItem.name}
                                checked={selectSubMenu === subMenuItem.name}
                                onChange={handleCheckboxChangeForSubModule}
                                className="mr-2"
                              />
                              <label className="text-sm text-gray-600">
                                {subMenuItem.name}
                              </label>
                            </div>
                          ))
                          // }
                          // </div>
                        ) : (
                          <p>Sub Modules Not Found...</p>
                        );
                      })()}

                    </ReusableModal>

                    <LabelWithButton
                      label="Icons"
                      className="border-r-2 pr-4"
                      labelwidth='w-32'
                      onClick={() => { setIsModalOpenForIconModule(true); }
                      }
                      buttonText={iconMenu || 'Select Icon Name'}
                      marginLeft="ml-2"   // Set custom margin-left
                      width='w-full'
                    />

                    <ReusableModal
                      title="Select Icon Name"
                      isOpen={isModalOpenForIconModule}
                      onClose={() => {
                        setIsModalOpenForIconModule(false);
                        setSearchQueryIconmodule('');
                      }}
                      onSave={() => {
                        setIsModalOpenForIconModule(false);
                        setSearchQueryIconmodule('');
                      }}
                      onReset={() => {
                        // setSubMenu("");       // Clear the previously selected submenu
                        // setselectSubMenu(""); // Clear submenu selection
                        setIconMenu("");
                        setselectIconMenu("");
                      }}
                      // showReset={true} // Only show Reset button in this modal
                      searchPlaceholder="Search Icon Name..."
                      searchQuery={searchQueryIconmodule}
                      onSearchChange={handleSearchChangeIconmodule}
                    // isViewMode={isViewMode}
                    >
                      {(() => {
                        // Log filteredIcons and subMenu to verify the data
                        console.log("Getting all / Selected Main Menu Icons:", filteredIcons);
                        console.log("Selected SubMenu:", subMenu);
                        console.log("Selected Icon Menu:", iconMenu);



                        const matchingMainMenu = filteredIcons.find(
                          (icon) =>
                            icon.name === mainMenu
                        );

                        // Log the matched main menu
                        console.log("In Icon Module get all submenus data:", matchingMainMenu);


                        const matchingSubMenu = matchingMainMenu?.subMenus?.find(
                          (subMenuItem) => subMenuItem.name === subMenu
                        );

                        // Log the matched submenu
                        console.log("In Icon Module get all Icons data:", matchingSubMenu);



                        const allIcons = matchingSubMenu?.icons || [];

                        // Log all icons under the selected submenu
                        console.log("In Icon module get All Icons:", allIcons);




                        // Filter icons based on the search query
                        const filteredIconsList = searchQueryIconmodule
                          ? allIcons.filter((icon) =>
                            icon.name
                              .toLowerCase()
                              .includes(searchQueryIconmodule.toLowerCase())
                          )
                          : allIcons;

                        // Log the filtered icons list after search filtering
                        console.log("Filtered Icons List:", filteredIconsList);

                        // Check if there are any icons to display
                        return allIcons.length > 0 ? (
                          // <div className="grid grid-cols-3 gap-4">
                          //   {
                          filteredIconsList.map((iconItem) => (
                            <div key={iconItem.id} className="flex items-center">
                              <input
                                type="radio"
                                name="iconMenu"
                                value={iconItem.name}
                                checked={selectIconMenu === iconItem.name}
                                onChange={handleCheckboxChangeForIconModule}
                                className="mr-2"
                              />
                              <label className="text-sm text-gray-600">
                                {iconItem.name}
                              </label>
                            </div>
                          ))
                          // }
                          // </div>
                        ) : (
                          <p>Icons Not Found...</p>
                        );
                      })()}

                    </ReusableModal>


                    {/* Expand Button */}
                    <div className="flex justify-end">
                      <button
                        className="flex items-center justify-center bg-white text-sm border border-gray-300 text-customGray py-2 px-4 rounded hover:bg-gray-100 transition duration-200"
                        onClick={toggleAllMenus} // Add this onClick to trigger the toggle
                      >
                        {allExpanded ? (
                          <IoChevronForward className="w-5 h-5 text-gray-500" />
                        ) : (
                          <IoChevronDown className="w-5 h-5 text-gray-500" />
                        )}
                      </button>
                    </div>

                  </div>

                  <div className="border border-gray-200 rounded-lg ">
                    <TableWrapper maxHeight="70vh">

                      {/* Permission Data started */}
                      <div className="bg-white rounded-lg shadow-sm">
                        {filteredIcons.map((mainMenu) => (
                          <div key={mainMenu.id} className="mb-4">
                            {/* Main Menu Level */}
                            <div
                              className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
                              onClick={() => toggleMainMenu(mainMenu.id)}
                            >
                              {allExpanded || expandedMainMenu === mainMenu.id ? (
                                <IoChevronDown className="w-5 h-5 text-gray-500" />
                              ) : (
                                <IoChevronForward className="w-5 h-5 text-gray-500" />
                              )}
                              <span className="text-md font-medium text-customGray">{mainMenu.name}</span>
                            </div>

                            {/* Submenus */}
                            {(allExpanded || expandedMainMenu === mainMenu.id || searchTerm) &&
                              mainMenu.subMenus.map((subMenu) => (
                                <div key={subMenu.id} className="ml-4 mt-2">
                                  {/* Submenu Level */}
                                  <div
                                    className="bg-blue-50 px-2 py-1.5 flex items-center justify-start cursor-pointer rounded space-x-2"
                                    onClick={() => toggleSubMenu(mainMenu.id, subMenu.id)} // Handle submenu toggle
                                  >
                                    {allExpanded || expandedSubMenu[mainMenu.id] === subMenu.id ? (
                                      <IoChevronDown className="w-5 h-5 text-gray-500" />
                                    ) : (
                                      <IoChevronForward className="w-5 h-5 text-gray-500" />
                                    )}
                                    <span className="text-md font-medium text-customGray">{subMenu.name}</span>
                                  </div>

                                  {/* Icons and expanded submenu content */}
                                  {(allExpanded || expandedSubMenu[mainMenu.id] === subMenu.id || searchTerm) && (
                                    <div className="ml-8 mt-2">
                                      {/* Icons Level */}
                                      {subMenu.icons.map((icon) => (
                                        <div key={icon.id} className="flex flex-col mt-2">
                                          <div
                                            className="bg-blue-50 px-2 flex items-center justify-start cursor-pointer rounded space-x-2"
                                            onClick={() => toggleIcon(icon.id)} // Toggle icon
                                          >
                                            {allExpanded || expandedIcon.includes(icon.id) ? (
                                              <IoChevronDown className="w-5 h-5 text-gray-500" />
                                            ) : (
                                              <IoChevronForward className="w-5 h-5 text-gray-500" />
                                            )}
                                            <span className="text-md font-medium text-customGray">
                                              {icon.name}
                                            </span>
                                            {/* <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded accent-Green"
                        checked={selectedIcons[icon.id] || false}
                        onClick={(e) => e.stopPropagation()}
                        disabled={isViewMode }
                        onChange={() => handleIconChange(icon.id)}
                      /> */}
                                            <Checkbox
                                              checked={selectedIcons[icon.id] || false}
                                              onClick={(e) => e.stopPropagation()}
                                              disabled
                                              onChange={() => handleIconChange(icon.id)}
                                              sx={{
                                                transform: 'scale(1.0)', // Reduces the size further
                                                color: 'customGray',
                                                '& .MuiSvgIcon-root': {
                                                  backgroundColor: (isViewMode || !selectedIcons[icon.id] || (userType === 8 && icon.id >= 1 && icon.id <= 50))
                                                    ? '#F2F4F9' // Disabled: gray background
                                                    : 'white', // Enabled: white background
                                                  borderRadius: '4px', // Round the corners slightly
                                                  fontSize: 18, // Adjust the size of the checkbox
                                                },
                                                '&.Mui-checked': {
                                                  color: (isViewMode || !selectedIcons[icon.id] || (userType === 8 && icon.id >= 1 && icon.id <= 50))
                                                    ? 'rgba(41, 203, 0, 0.5)' // Dimmed green for disabled checked state
                                                    : 'rgba(41, 203, 0, 0.5)', // Bright green when checked and enabled
                                                },
                                                '&.Mui-disabled .MuiSvgIcon-root': {
                                                  backgroundColor: '#F2F4F9', // Explicitly set disabled bg color
                                                },
                                              }}
                                            />



                                            {/* "All" Checkbox */}
                                            <div className={`flex items-center space-x-2 px-2 ml-5 `} >

                                              <div className={`${isViewMode || !selectedIcons[icon.id] ? 'cursor-default' : 'cursor-pointer'} `}
                                                onClick={(e) => e.stopPropagation()} // Prevent accordion toggle
                                              >

                                                <span className="text-md font-medium text-customGray">
                                                  ALL
                                                </span>
                                                <Checkbox
                                                  checked={
                                                    checkboxState[icon.id] !== undefined
                                                      ? checkboxState[icon.id] // Use explicit checkbox state if available
                                                      : Object.keys(selectedPermissions[icon.id] || {}).length ===
                                                      permissionIcons.filter(
                                                        (permission) => !isPermissionDisabled(icon.id, permission.id) &&
                                                          isPermissionDisplayed(icon.id, permission.id) // Filter only visible permissions
                                                      ).length
                                                  }
                                                  onChange={() => {
                                                    toggleAllPermissions(icon.id);
                                                  }}
                                                  disabled
                                                  sx={{
                                                    transform: 'scale(1.0)', // Reduces the size further
                                                    color: 'customGray',
                                                    '& .MuiSvgIcon-root': {
                                                      backgroundColor: (isViewMode || !selectedIcons[icon.id] || (userType === 8 && icon.id >= 1 && icon.id <= 50))
                                                        ? '#F2F4F9' // Disabled: gray background
                                                        : 'white', // Enabled: white background
                                                      borderRadius: '4px', // Round the corners slightly
                                                      fontSize: 18, // Adjust the size of the checkbox
                                                    },
                                                    '&.Mui-checked': {
                                                      color: (isViewMode || !selectedIcons[icon.id] || (userType === 8 && icon.id >= 1 && icon.id <= 50))
                                                        ? 'rgba(41, 203, 0, 0.5)' // Dimmed green for disabled checked state
                                                        : 'rgba(41, 203, 0, 0.5)', // Bright green when checked and enabled
                                                    },
                                                    '&.Mui-disabled .MuiSvgIcon-root': {
                                                      backgroundColor: '#F2F4F9', // Explicitly set disabled bg color
                                                    },
                                                  }}
                                                />

                                              </div>

                                            </div>

                                          </div>

                                          {/* Expanded content for icons */}
                                          {allExpanded || expandedIcon.includes(icon.id) ? (
                                            <div className="px-4 py-2 grid grid-cols-4 gap-4 items-center bg-white rounded">
                                              {permissionIcons.map((permission) => {
                                                const isDisabled =
                                                  userType == 1 &&
                                                  (
                                                    (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    // (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 135 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||

                                                    (icon.id === 44 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 45 && [1, 2, 4, 5, 6, 7, 8, 9, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||

                                                    (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 110 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 111 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 112 && [1, 2, 4, 8, 9, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 113 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 114 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 115 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id))

                                                  ) ||
                                                  //  usertype SuperUser
                                                  (userType == 2 &&
                                                    ((icon.id === 114 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 115 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id))
                                                    )
                                                  ) ||
                                                  //  usertype Approver
                                                  (userType == 3 &&
                                                    (
                                                      // Country
                                                      (icon.id === 1 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // State provice
                                                      (icon.id === 2 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Calender
                                                      (icon.id === 3 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Currency
                                                      (icon.id === 4 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank
                                                      (icon.id === 5 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Language
                                                      (icon.id === 6 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Legal entity
                                                      (icon.id === 7 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bu group
                                                      (icon.id === 8 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Company
                                                      (icon.id === 9 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank Id
                                                      (icon.id === 10 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Branch
                                                      (icon.id === 11 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 12 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 13 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 14 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 15 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account status
                                                      (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account no logic
                                                      (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // ZBA type
                                                      (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // DB area
                                                      // (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account types
                                                      (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account Classification
                                                      (icon.id === 135 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank account master
                                                      (icon.id === 26 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement controls
                                                      (icon.id === 44 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement format
                                                      (icon.id === 45 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement identifier
                                                      (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Mt940 uploads
                                                      (icon.id === 47 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // BTC
                                                      (icon.id === 48 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement analyser
                                                      (icon.id === 49 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].includes(permission.id)) ||
                                                      // Statement analyser 2
                                                      (icon.id === 50 && [3, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Transfer to BS
                                                      (icon.id === 51 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Manage statement controls
                                                      (icon.id === 52 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // User Icon
                                                      (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Key data Icon
                                                      (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Maker and checker Icons
                                                      (icon.id === 110 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Roles Icon
                                                      (icon.id === 111 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Permission Icon
                                                      (icon.id === 112 && [1, 2, 4, 8, 9, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||

                                                      (icon.id === 113 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 114 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 115 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)))

                                                  ) ||
                                                  //  usertype Admin
                                                  (userType == 4 &&
                                                    (
                                                      // Country
                                                      (icon.id === 1 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // State provice
                                                      (icon.id === 2 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Calender
                                                      (icon.id === 3 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Currency
                                                      (icon.id === 4 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank
                                                      (icon.id === 5 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Language
                                                      (icon.id === 6 && [1, 2, 4, 5, 6, 7, 8, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Legal entity
                                                      (icon.id === 7 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bu group
                                                      (icon.id === 8 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Company
                                                      (icon.id === 9 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank Id
                                                      (icon.id === 10 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Branch
                                                      (icon.id === 11 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 12 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 13 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 14 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 15 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account status
                                                      (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account no logic
                                                      (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // ZBA type
                                                      (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // DB area
                                                      // (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                      // Account types
                                                      (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account Classification
                                                      (icon.id === 135 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank account master
                                                      (icon.id === 26 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement controls
                                                      (icon.id === 44 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement format
                                                      (icon.id === 45 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement identifier
                                                      (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Mt940 uploads
                                                      (icon.id === 47 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // BTC
                                                      (icon.id === 48 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement analyser
                                                      (icon.id === 49 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].includes(permission.id)) ||
                                                      // Statement analyser 2
                                                      (icon.id === 50 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Transfer to BS
                                                      (icon.id === 51 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Manage statement controls
                                                      (icon.id === 52 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||

                                                      // User Type
                                                      (icon.id === 115 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // module assignment
                                                      (icon.id === 114 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id))

                                                    )
                                                  ) ||
                                                  //  usertype System
                                                  (userType == 7 &&
                                                    ((icon.id === 114 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      (icon.id === 115 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id))
                                                    )
                                                  ) ||
                                                  //  usertype Kickstart
                                                  (userType == 8 &&
                                                    (
                                                      // Country
                                                      (icon.id === 1 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // State provice
                                                      (icon.id === 2 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Calender
                                                      (icon.id === 3 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Currency
                                                      (icon.id === 4 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank
                                                      (icon.id === 5 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Language
                                                      (icon.id === 6 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Legal entity
                                                      (icon.id === 7 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bu group
                                                      (icon.id === 8 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Company
                                                      (icon.id === 9 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank Id
                                                      (icon.id === 10 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Branch
                                                      (icon.id === 11 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // MD Source
                                                      (icon.id === 12 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Source
                                                      (icon.id === 13 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Currency rate
                                                      (icon.id === 14 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Uploads
                                                      (icon.id === 15 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account status
                                                      (icon.id === 21 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account no logic
                                                      (icon.id === 22 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // ZBA type
                                                      (icon.id === 23 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // DB area
                                                      // (icon.id === 24 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account types
                                                      (icon.id === 25 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Account Classification
                                                      (icon.id === 135 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Bank account master
                                                      (icon.id === 26 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement controls
                                                      (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement format
                                                      (icon.id === 45 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement identifier
                                                      (icon.id === 46 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Mt940 uploads
                                                      (icon.id === 47 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // BTC
                                                      (icon.id === 48 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement analyser
                                                      (icon.id === 49 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Statement analyser 2
                                                      (icon.id === 50 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Transfer to BS
                                                      (icon.id === 51 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Manage statement controls
                                                      (icon.id === 52 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||


                                                      // Key data Icon
                                                      (icon.id === 109 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Maker and checker Icons
                                                      (icon.id === 110 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Roles Icon
                                                      (icon.id === 111 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                      // Task Monitor
                                                      (icon.id === 134 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id))

                                                    )
                                                  )
                                                  ;

                                                const isChecked = !isDisabled && (selectedPermissions[icon.id]?.[permission.id] || false);

                                                const handleCheckboxChange = () => {
                                                  if (!isDisabled) {
                                                    handlePermissionChange(icon.id, permission.id, !isChecked);
                                                  }
                                                };

                                                return (
                                                  // Below the Action Icons are Not Needed, Permission table Id
                                                  (icon.id === 1 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 2 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 3 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 4 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 5 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 6 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 7 && [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 8 && [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 9 && [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 10 && [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 11 && [8, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 12 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 13 && [5, 6, 7, 8, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 14 && [5, 6, 7, 8, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 15 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 21 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 22 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 23 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    // (icon.id === 24 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 16, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                    (icon.id === 25 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 135 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 26 && [8, 12, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||

                                                    (icon.id === 44 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 45 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 46 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 47 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 29, 30, 31].includes(permission.id)) ||
                                                    (icon.id === 48 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 49 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28].includes(permission.id)) ||
                                                    (icon.id === 50 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 51 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31].includes(permission.id)) ||
                                                    (icon.id === 52 && [1, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||

                                                    (icon.id === 108 && [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 109 && [8, 9, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 110 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 111 && [8, 9, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 112 && [2, 8, 9, 12, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 113 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 114 && [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 115 && [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||
                                                    (icon.id === 136 && [6, 7, 8, 9, 11, 12, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id)) ||

                                                    (icon.id === 134 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32].includes(permission.id))
                                                    ? null : (
                                                      <div key={`${icon.id}-${permission.id}`} className="flex items-center space-x-2">
                                                        <Checkbox
                                                          disabled
                                                          checked={isChecked}
                                                          onChange={handleCheckboxChange}
                                                          sx={{
                                                            transform: 'scale(1.0)', // Reduces the size further
                                                            color: 'customGray', '& .MuiSvgIcon-root': {
                                                              backgroundColor: 'white', // Background color for the checkbox
                                                              borderRadius: '4px', // Optional: round the corners slightly
                                                              fontSize: 18, // Adjust the size of the checkbox
                                                              // border: '1px solid #ccc', // Custom border
                                                            }, '&.Mui-checked': {
                                                              color: (isViewMode || !selectedIcons[icon.id])
                                                                ? 'rgba(41, 203, 0, 0.5)' // Dimmed green for disabled checked state
                                                                : 'rgba(41, 203, 0, 0.5)', // Bright green when checked and enabled
                                                            },
                                                            '&.Mui-disabled .MuiSvgIcon-root': {
                                                              backgroundColor: '#F2F4F9', // Explicitly set disabled bg color
                                                            },
                                                          }}
                                                        />
                                                        <span className="text-md text-customGray">{permission.name}</span>
                                                        <span className="ml-2 text-customGray">{iconMapping[permission.name] || null}</span>
                                                      </div>
                                                    )
                                                );
                                              })}
                                            </div>
                                          ) : null}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>

                    </TableWrapper>

                  </div>


                </div>


              </div>
            </div>
          )}

          {activeTab === "keyData" && (
            <div className="m-6 border border-gray-200 p-5  rounded-lg">
              {/* Display error message if userId has errors */}
              <div className="flex flex-col space-y-8 col-span-1 p-6">
                {errors.userId && (
                  <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
                )}

                {/* keyData Content */}
              </div>
            </div>
          )}


        </>
      ) : null}


      {
        activeTab === "loginMethod" && (
          <div className="p-12">
            {/* Display error message if userId has errors */}
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            {/* Login Method Selection */}
            <div className="flex flex-col space-y-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  value="0"
                  checked={loginMethod === 0}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2 text-customGray">Password</span>
              </label>

              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  value="1"
                  checked={loginMethod === 1}
                  disabled
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2 text-customGray">Multi-Factor Authentication</span>
              </label>

              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  value="2"
                  checked={loginMethod === 2}
                  disabled
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2 text-customGray">OTP</span>
              </label>
            </div>

            {/* Additional UI elements */}
            <div className="flex flex-row mt-12"></div>
          </div>
        )
      }
      {
        isProfileMode ? (
          null
        ) : (
          <Footer>
            {(!isViewMode) && (
              <ButtonGroup
                onSave={handleSaveBasicDetails}
                onSaveContinue={handleSaveContinueDetails} hasId={!!UserId} icons={iconId}
              />
            )}

          </Footer>
        )
      }
    </div>
  );
}
