import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import { Alert } from "@mui/material";
import UserService from "../../../../../../rest-services/UserServices";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useToast } from "../../../../../../components/toast/toast";
import { TbFlag3Filled } from "react-icons/tb";
import PhoneInput from 'react-phone-input-2';
import Header from "../../../../../../components/Header";
import Tabs from "../../../../../../components/Tabs";
import { RxDropdownMenu } from "react-icons/rx";
export default function UserEdit() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const location = useLocation();
  const user = location.state?.user; // Safely access user from location.state


  // const selectedUserIds = location.state?.selectedUserIds || [];
  const [surname, setSurname] = useState("");
const [approved, setApproved] = useState("");
const [accountingSystemId, setAccountingSystemId] = useState("");
  const [activeTab, setActiveTab] = useState("basicDetail");
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [userId, setUserId] = useState("");
  const [salute, setSalute] = useState("");
  const [userType, setUserType] = useState(0);
  const [userTypes, setUserTypes] = useState([]);
  const [addressDetails, setAddressDetails] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [oneTimePassword, setOneTimePassword] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [officeNo, setOfficeNo] = useState("");
  const [extension, setExtension] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [drivingLicenseNo, setDrivingLicenseNo] = useState("");
  const [localId, setLocalId] = useState("");
  const [nationality, setNationality] = useState("");
  const [employeeNo, setemployeeNo] = useState("");
  const [companyCodeId, setcompanyCodeId] = useState("");
  const [department, setdepartment] = useState("");
  const [validFrom, setvalidFrom] = useState("");
  const [validTo, setvalidTo] = useState("");
  const [joiningDate, setjoiningDate] = useState("");
  const [resignationDate, setresignationDate] = useState("");
  const [language, setlanguage] = useState("");
  const [dateFormat, setdateFormat] = useState("");
  const [timeFormat, settimeFormat] = useState("");
  const [timeZone, settimeZone] = useState("");
  const [receiveSystemMail, setreceiveSystemMail] = useState("");
  const [downloadData, setdownloadData] = useState("");
  const [changesInCashflow, setchangesInCashflow] = useState("");
  const [updateCountry, setupdateCountry] = useState("");
  const [changeVarient, setchangeVarient] = useState("");
  const [deleteAllRecords, setdeleteAllRecords] = useState("");
  const [changeAccountingCode, setchangeAccountingCode] = useState("");

  const [countries, setCountry] = useState([]);
  const [amountFormat, setamountFormat] = useState("");
  const [cityName, setcityName] = useState("");
  const [thousandSeparator, setthousandSeparator] = useState("");
  const [decimalSeparator, setdecimalSeparator] = useState("");

  const [suffixForThousands, setSuffixForThousands] = useState('');
  const [errorThousands, setErrorThousands] = useState(false);
  const [acceleratorForThousands, setacceleratorThousands] = useState("");


  const [acceleratorForMillions, setacceleratorMillions] = useState("");
  const [suffixForMillions, setSuffixForMillions] = useState('');
  const [errorMillions, setErrorMillions] = useState(false);

  const [acceleratorForLakhs, setacceleratorLakhs] = useState("");
  const [suffixForLakhs, setSuffixForLakhs] = useState(''); // Holds the suffix ('K' or 'T')
  const [errorLakhs, setErrorLakhs] = useState(false);
  const [loginMethod, setLoginMethod] = useState('');

  const [username, setUserName] = useState("");

  // Define the state variables
const [doorNo, setDoorNo] = useState('');
const [floorNo, setFloorNo] = useState('');
const [buildingNo, setBuildingNo] = useState('');
const [buildingName, setBuildingName] = useState('');
const [landMark, setLandMark] = useState('');
const [streetNo, setStreetNo] = useState('');
const [streetName, setStreetName] = useState('');
const [roadNo, setRoadNo] = useState('');
const [roadName, setRoadName] = useState('');
const [areaBlock, setAreaBlock] = useState('');
const [areaName, setAreaName] = useState('');
const [addressLine1, setAddressLine1] = useState('');
const [addressLine2, setAddressLine2] = useState('');
const [addressLine3, setAddressLine3] = useState('');
const [countryCode, setCountryCode] = useState('');
const [countryId, setCountryId] = useState('');
// const [state, setState] = useState('');
const [poBox, setPoBox] = useState('');
const [district, setDistrict] = useState('');
const [postalCode, setPostalCode] = useState('');
const [zipCode, setZipCode] = useState('');
 const [state, setstate] = useState("");
 const [stateProvinces, setStateProvinces] = useState([]);
 const [stateName, setStateName] = useState(''); 
// Define similar handlers for other fields

  const [alert, setAlert] = useState(null);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };

  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  
  const nextabMoveToMethod = async () => {
    handleTabClick("loginMethod"); // Navigate to contactDetails tab 
  }

  const nextabMoveToPreferance = async () => {
    handleTabClick("userPreferences"); // Navigate to contactDetails tab 
  }

  const nextabMoveToAddress = async () => {
    handleTabClick("addressDetails"); // Navigate to contactDetails tab 
  }
  const nextabMoveToOrganization = async () => {
    handleTabClick("organization"); // Navigate to contactDetails tab 
  }
  const nextabMoveToIdentity = async () => {
    handleTabClick("identity"); // Navigate to contactDetails tab 
  }
  const nextabMoveToContact =async () => {
              handleTabClick("contactDetails"); // Navigate to contactDetails tab   
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);

  }
  const [countryName, setCountryName] = useState(""); // Country Name
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [selectedState, setSelectedState] = useState(state || "");
  

  const handleCheckboxChange = (event) => {
    const selectedCode = event.target.value;
    console.log("edit selected code: ",selectedCode);
    setSelectedCountry(selectedCode);
    setCountryCode(selectedCode);
    
    const selectedCountryData = countries.find(country => country.country === selectedCode);
    console.log("selected edit country code: ",selectedCountryData);
    if (selectedCountryData) {

      setCountryName(selectedCountryData.countryName);
      console.log("edit selected country name code: ",selectedCountryData.countryName);

    }
  };
  
  const handleCheckboxChangeForState = (event) =>{
    const selectedCode = event.target.value;
  console.log("selected State code",selectedCode);
    setSelectedState(selectedCode);
    setstate(selectedCode);
    
    const selectedCountryData = stateProvinces.find(stateP => stateP.state === selectedCode);
    if (selectedCountryData) {
    console.log('check selected country data',selectedCountryData.State_Name);
      setStateName(selectedCountryData.State_Name);
    }
  };

  const handleLoginMethodChange = (e) => {
    setLoginMethod(Number(e.target.value));
  };

  useEffect(() => {
    fetchAssignee();
    fetchUserTypes();
    fetchcountry();

  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email
    if (value.length > 80) {
      setErrors({ email: 'Email must not exceed 80 characters.' });
    } else if (!emailRegex.test(value)) {
      setErrors({ email: 'Please enter a valid email address.' });
    } else {
      setErrors({ email: '' });
    }
  };

  const fetchUserPrefernceDetails = async () => {
    try {
      const data = { userId: user.userId };
      console.log("Request Data:", data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details`,
        data
      );
      
      console.log("API Response:", response);
      console.log("Response Data:", response.data);
    
      if (response.data) {
        // Update state with fetched address details
        setlanguage(response.data.language || '');
        setdateFormat(response.data.dateFormat || '');
        setamountFormat(response.data.amountFormat || '');
        setthousandSeparator(response.data.thousandSeparator || '');
        setdecimalSeparator(response.data.decimalSeparator || '');
        setacceleratorThousands(response.data.acceleratorForThousands || '');
        setacceleratorMillions(response.data.acceleratorForMillions || '');
        setacceleratorLakhs(response.data.acceleratorForLakhs || '');
        settimeFormat(response.data.timeFormat || '');
        settimeZone(response.data.timeZone || '');

        setreceiveSystemMail(response.data.receiveSystemMail || '');
        setdownloadData(response.data.downloadData || '');
        setchangesInCashflow(response.data.changesInCashflow || '');
        setupdateCountry(response.data.updateCountry || '');
        setchangeVarient(response.data.changeVarient || '');
        setdeleteAllRecords(response.data.deleteAllRecords || '');
        setchangeAccountingCode(response.data.changeAccountingCode || '');
      
       
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };

  const handleChangeforMillions = (e) => {
    let value = e.target.value;

    // Extract numeric part and any 'K' or 'T'
    const numericPart = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    const suffixPart3 = value.replace(/\d/g, ''); // Extract suffix (K or T)

    // Only allow 'K', 'T', or empty input for the suffix
    if (suffixPart3 === '' || suffixPart3 === 'M') {
      setSuffixForMillions(suffixPart3);
      setErrorMillions(false);
    } else {
      setErrorMillions(true);
    }

    setacceleratorMillions(numericPart);
  };

  const handleChangeforLakhs = (e) => {
    let value = e.target.value;

    // Extract numeric part and any 'K' or 'T'
    const numericPart = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    const suffixPart2 = value.replace(/\d/g, ''); // Extract suffix (K or T)

    // Only allow 'K', 'T', or empty input for the suffix
    if (suffixPart2 === '' || suffixPart2 === 'L') {
      setSuffixForLakhs(suffixPart2);
      setErrorLakhs(false);
    } else {
      setErrorLakhs(true);
    }

    setacceleratorLakhs(numericPart);
  };
  


  const handleChangeforThousands = (e) => {
    let value = e.target.value;

    // Extract numeric part and any 'K' or 'T'
    const numericPart = value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    const suffixPart = value.replace(/\d/g, ''); // Extract suffix (K or T)

    // Only allow 'K', 'T', or empty input for the suffix
    if (suffixPart === '' || suffixPart === 'K' || suffixPart === 'T') {
      setSuffixForThousands(suffixPart);
      setErrorThousands(false);
    } else {
      setErrorThousands(true);
    }

    setacceleratorThousands(numericPart);
  };


  const fetchstateprovice = async () => {
    try {
      const data = { Country_Code: countryCode };
  console.log('fetching data country',countryCode);
      console.log("fetch country in state", countryCode);
  
      const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-stateprovince-by-country`,
            data
          );
          // console.log("API Response:", response);
          console.log("state province edit Response Data:", response.data);
  
      setStateProvinces(response.data);
  } catch (error) {
  console.error("Error fetching countries:", error);
  }
  
  };

  // const handleStateProvinceChange  = (e) => {

  //   const selectedId = e.target.value;
  //   setstate(selectedId);
  
  //   // Find the corresponding state/province object and set stateName
  //   const selectedState = stateProvinces.find((stateP) => stateP.id === parseInt(selectedId));
  //   if (selectedState) {
  //     setStateName(selectedState.State_Name);
  //   } else {
  //     setStateName('');
  //   }
  // };

  
  const fetchAddressDetails = async () => {
    try {
      const data = { userId: user.userId };
      console.log("Request Data:", data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-address-details`,
        
        data
      );
      
      console.log("API Response:", response);
      console.log("Response Data:", response.data);
    
      if (response.data) {
        // Update state with fetched address details
        setDoorNo(response.data.doorNo || '');
        setFloorNo(response.data.floorNo || '');
        setBuildingNo(response.data.buildingNo || '');
        setBuildingName(response.data.buildingName || '');
        setLandMark(response.data.landMark || '');
        setStreetNo(response.data.streetNo || '');
        setStreetName(response.data.streetName || '');
        setRoadNo(response.data.roadNo || '');
        setRoadName(response.data.roadName || '');
        setAreaBlock(response.data.areaBlock || '');
        setAreaName(response.data.areaName || '');
        setAddressLine1(response.data.addressLine1 || '');
        setAddressLine2(response.data.addressLine2 || '');
        setAddressLine3(response.data.addressLine3 || '');
        setCountryCode(response.data.countryCode || '');
        setCountryName(response.data.Country.countryName || '');
        setCountryId(response.data.countryId || '');
        setstate(response.data.state || '');
        setStateName(response.data.StateProvince.State_Name || '');
        setPoBox(response.data.poBox || '');
        setDistrict(response.data.district || '');
        setPostalCode(response.data.postalCode || '');
        setZipCode(response.data.zipCode || '');
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };

      // Handle when country code changes
// const handleCountryCodeChange = (e) => {
//   const selectedCode = e.target.value;
//   setCountryCode(selectedCode);

//   // Find the corresponding country object and set countryId
//   const selectedCountry = countries.find((country) => country.id == selectedCode);
//   if (selectedCountry) {
//     setCountryId(selectedCountry.id);
//   }
// };

  const fetcheditUserDetails = async () => {

    try {
        const data = { userId: user.userId };
        console.log("Request Data:", data);
        
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-edit-user-details`,
          data
        );
        
        console.log("API Response:", response);
        console.log("Response Data:", response.data);
      
        if (response.data) {
          // Update state with fetched address details
          setUserName(response.data.username || '');
          setApproved(response.data.approved || '');
          setSalute(response.data.salute || '');
          setUserType(response.data.userType || '');
          setSurname(response.data.surname || '');
          setAccountingSystemId(response.data.accountingSystemId || '');
          setFirstName(response.data.firstName || '');
          setLastName(response.data.lastName || '');
          setMiddleName(response.data.middleName || '');
          setEmail(response.data.email || '');
          setMobileNo(response.data.mobileNo || '');
          setWhatsappNo(response.data.whatsappNo || '');
          setOneTimePassword(response.data.oneTimePassword || '');
          setOfficeNo(response.data.officeNo || '');
          setExtension(response.data.extension || '');
          setNationality(response.data.nationality || '');
          setPassportNo(response.data.passportNo || '');
          setDrivingLicenseNo(response.data.drivingLicenseNo || '');
          setLocalId(response.data.localId || '');
          setemployeeNo(response.data.employeeNo || '');
          setcompanyCodeId(response.data.companyCodeId || '');
          setdepartment(response.data.department || '');
          setvalidFrom(response.data.validFrom || '');
          setvalidTo(response.data.validTo || '');
          // setlanguage(response.data.language || '');
          // setdateFormat(response.data.dateFormat || '');
          // setdateFormat(response.data.dateFormat || '');
          // setthousandSeparator(response.data.thousandSeparator || '');
          // setdecimalSeparator(response.data.decimalSeparator || '');
          // setacceleratorThousands(response.data.acceleratorForThousands || '');
          // settimeFormat(response.data.timeFormat || '');
          // settimeZone(response.data.timeZone || '');
          // setreceiveSystemMail(response.data.receiveSystemMail || '');
          // setdownloadData(response.data.downloadData || '');
          // setchangesInCashflow(response.data.changesInCashflow || '');
          // setchangeVarient(response.data.changeVarient || '');
          // setdeleteAllRecords(response.data.deleteAllRecords || '');
          // setchangeAccountingCode(response.data.changeAccountingCode || '');
          // setupdateCountry(response.data.updateCountry || '');

          // setStreetName(response.data.streetName || '');
          // setAddressLine1(response.data.addressLine1 || '');
          // setAddressLine2(response.data.addressLine2 || '');
          // setAddressLine3(response.data.addressLine3 || '');
          // setCountryId(response.data.countryId || '');
          // setZipCode(response.data.zipCode || '');
          // setPostalCode(response.data.postalCode || '');
          setemployeeNo(response.data.employeeNo || '');
          setcompanyCodeId(response.data.companyCodeId || '');
          setdepartment(response.data.department || '');
          setvalidFrom(response.data.validFrom || '');
          setvalidTo(response.data.validTo || '');
          setresignationDate(response.data.resignationDate || '');
          setjoiningDate(response.data.joiningDate || '');
          setLoginMethod(response.data.loginMethod || '');
          

          
        }
      } catch (error) {
        console.error("Error fetching address details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while fetching address details.",
        });
      }
    };

  useEffect(() => {
    if (user?.userId) {
      setUserId(user.userId);
      fetchAddressDetails();
      fetcheditUserDetails();
      fetchUserPrefernceDetails();
    }
  }, [user]);
  

    // Fetch user types from the API
    const fetchUserTypes = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/get-user-type`
        );
        setUserTypes(response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    const fetchcountry = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/get-country`
        );
        setCountry(response.data.data);
        console.log('edit fetch country data',response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    useEffect(() => {
      fetchstateprovice();
    },[countryCode])


  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        
        setAssignee(assigneeData);
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
  
  const handleThousandChange = (e) => {
    const value = e.target.value;
    // Only allow '.' or ',' in the thousand separator field
    if (value === '' || value === '.' || value === ',') {
      setthousandSeparator(value);
      setError((prevError) => ({ ...prevError, thousand: false }));  // Reset thousand separator error

      // Check if thousand and decimal separators are the same
      if (value === decimalSeparator && value !== '') {
        setError((prevError) => ({ ...prevError, same: true }));
      } else {
        setError((prevError) => ({ ...prevError, same: false }));
      }
    } else {
      setError((prevError) => ({ ...prevError, thousand: true }));
    }
  };

  const handleDecimalChange = (e) => {
    const value = e.target.value;
    // Only allow '.' or ',' in the decimal separator field
    if (value === '' || value === '.' || value === ',') {
      setdecimalSeparator(value);
      setError((prevError) => ({ ...prevError, decimal: false }));  // Reset decimal separator error

      // Check if thousand and decimal separators are the same
      if (value === thousandSeparator && value !== '') {
        setError((prevError) => ({ ...prevError, same: true }));
      } else {
        setError((prevError) => ({ ...prevError, same: false }));
      }
    } else {
      setError((prevError) => ({ ...prevError, decimal: true }));
    }
  };



  const validateFields = () => {
    const newErrors = {};
  
    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
    const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
    const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
    const isAreaSectionFilled = areaBlock || areaName;
    const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled;
  
    if (!userId) newErrors.userId = " ";
    if (!username) newErrors.username = " ";
    if (!firstName) newErrors.firstName = " ";
    if (!lastName) newErrors.lastName = " ";
    if (!email) newErrors.email = " ";
    if (!mobileNo) newErrors.mobileNo = " ";
    if (!nationality) newErrors.nationality = " ";
    if (!validFrom) newErrors.validFrom = " ";
    if (!validTo) newErrors.validTo = " ";
  
    if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
      newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
    }
  
    // if (!countryId) newErrors.countryId = " ";
    if (!state) newErrors.state = " ";
    if (!countryCode) newErrors.countryCode = " ";
    if (!language) newErrors.language = " ";
    if (!dateFormat) newErrors.dateFormat = " ";
    if (!timeFormat) newErrors.timeFormat = " ";
  
    setErrors(newErrors);
  
    // Show toast only if there are errors
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing required fields.");
      return false;
    }
  
    return true;
  };
  

const handleSaveContinueDetails = async () => {

if (!validateFields()) return;
    try {
      console.log("entered");
      const data = {
        userId,
        username,
        accountingSystemId,
        salute,
        userType,
        firstName,
        middleName,
        surname,
        lastName,

        module: 'Admin',
        icon: 'User',
        action: 'Edit',
        changedBy: assignee.assigner,
        oneTimePassword: oneTimePassword || 0,
        // createdBy: assignee.assigner,
      
        email, mobileNo, whatsappNo ,officeNo,extension,
        passportNo, drivingLicenseNo,localId,nationality,
        language, dateFormat,thousandSeparator,decimalSeparator,
        acceleratorForThousands: acceleratorForThousands + suffixForThousands,
        acceleratorForMillions: acceleratorForMillions + suffixForMillions,
        acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
        timeFormat,timeZone,receiveSystemMail,downloadData,changesInCashflow,updateCountry,changeVarient,deleteAllRecords,changeAccountingCode,
        streetName, addressLine1, addressLine2,addressLine3, zipCode, postalCode,
        employeeNo, companyCodeId,department,validFrom,validTo,joiningDate,resignationDate,
        loginMethod, 
        doorNo,floorNo,buildingNo,buildingName,landMark,streetNo,roadNo,roadName,areaBlock,areaName,countryCode,state,poBox, district
      };
      const response = await UserService.saveAllUserDetails(
        `${process.env.REACT_APP_BASE_URL}/edit-user`,
        data
      );
      console.log("response data", data);
      console.log("response", response);
      console.log("response status", response.status);
      if (response.status === 200) {
        setAlert({
          severity: "success",
          message: "Basic Details saved successfully.",
        });

          // Wait for the success message to be displayed before navigating
          toast("success", "User Edited successfully");
          handleTabClick("basicDetail");  // Navigate to loginMethod Details tab
      } else {
        setAlert({ severity: "error", message: "Not saved" });
      }
    } catch (error) {
      console.error("Error saving basic details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while saving.",
      });
    }
  };


 
  const handleSaveBasicDetails = async () => {
    if (!validateFields()) return;
    try {
      console.log("entered");
      const data = {
        userId,
        username,
        accountingSystemId,
        salute,
        userType,
        firstName,
        middleName,
        surname,
        lastName,

        module: 'Admin',
        icon: 'User',
        action: 'Edit',
        changedBy: assignee.assigner,
        oneTimePassword: oneTimePassword || 0,
        // createdBy: assignee.assigner,
       
        email, mobileNo, whatsappNo ,officeNo,extension,
        passportNo, drivingLicenseNo,localId,nationality,
        language, dateFormat,thousandSeparator,decimalSeparator,amountFormat,
        acceleratorForThousands: acceleratorForThousands + suffixForThousands,
        acceleratorForMillions: acceleratorForMillions + suffixForMillions,
        acceleratorForLakhs: acceleratorForLakhs + suffixForLakhs,
        timeFormat,timeZone,receiveSystemMail,downloadData,changesInCashflow,updateCountry,changeVarient,deleteAllRecords,changeAccountingCode,
        streetName, addressLine1, addressLine2,addressLine3, zipCode, postalCode,
        employeeNo, companyCodeId,department,validFrom,validTo,joiningDate,resignationDate,
        loginMethod, 
        doorNo,floorNo,buildingNo,buildingName,landMark,streetNo,roadNo,roadName,areaBlock,areaName,countryCode,state,poBox, district
      };
      const response = await UserService.saveAllUserDetails(
        `${process.env.REACT_APP_BASE_URL}/edit-user`,
        data
      );
      console.log("response data", data);
      console.log("response", response);
      console.log("response status", response.status);
      if (response.status === 200) {
        setAlert({
          severity: "success",
          message: "Basic Details saved successfully.",
        });

          // Wait for the success message to be displayed before navigating
          toast("success", "User Edited successfully");
            navigate("/14/admin/105/userscreen"); // Navigate to loginMethod Details tab
      }
      else if (response.status === 400) {
        // setAlert({
        //   severity: "warning",
        //   message: "Missing required fields.",
        // });

          // Wait for the success message to be displayed before navigating
          toast("warning", "Missing required fields.");
      } 
      else if (response.status === 409) {
        setAlert({
          severity: "warning",
          message: "Email already exists.",
        });

          // Wait for the success message to be displayed before navigating
          toast("warning", "Email already exists.");
      } 
       else {
        setAlert({ severity: "error", message: "Not saved" });
      }
    } catch (error) {
      console.error("Error saving basic details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while saving.",
      });
    }
  };


  const tabStyle = (tab) => {
    const hasErrorsInBasicDetail = errors.userId || errors.username || errors.firstName || errors.lastName;
    const hasErrorsInContactTab = errors.email || errors.mobileNo; // Assuming contact tab fields
    const hasErrorInIdentityTab = errors.nationality;
    const hasErrorInOrganizationTab = errors.validFrom || errors.validTo;
    const hasErrorInAddressDetailTab = errors.addressSection;
    const hasErrorInUserPreference = errors.language || errors.dateFormat || errors.timeFormat;
    const isActiveTab = activeTab === tab;
  
    // Conditionally applying error styles based on the tab and errors in corresponding fields
    const tabHasError = (tab === "basicDetail" && hasErrorsInBasicDetail) || 
                        (tab === "contactDetails" && hasErrorsInContactTab) ||
                        (tab === "identity" && hasErrorInIdentityTab)||
                        (tab === "organization" && hasErrorInOrganizationTab)||
                        (tab === "addressDetails" && hasErrorInAddressDetailTab)||
                        (tab === "userPreferences" && hasErrorInUserPreference);

  
    return {
      backgroundColor: isActiveTab ? "white" : "transparent",
      color: isActiveTab ? "grey" : "white",
      padding: "2px 10px",
      cursor: "pointer",
      borderRadius: "5px",
      border: tabHasError ? "2px solid red" : "none", // Apply red border if there are errors in the tab
    };
  };
  // // Handle input changes

  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (approved == 3) {
      color = "#29CB00"; // Green
    } else if (approved == 0) {
      color = "gray"; // Gray
    } else if (approved == 1) {
      color = "yellow"; // Yellow
    } else if (approved == 2) {
      color = "orange"; // Orange
    } else if (approved == 4) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };
  
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Admin') {
      console.log("Navigating to /Admin");
      navigate('/14/admin');
    } else if (crumb.title === 'User & SOD') {
      console.log("Navigating to /User & SOD");
      navigate('/14/admin');
    }
    else if (crumb.title === 'User') {
      console.log("Navigating to /User");
      navigate('/14/admin/105/userscreen');
    } 
    else if (crumb.title === 'Edit') {
      console.log("Navigating to /User");
      navigate(`/user-edit-screen/${userId}`);
    } 
    
    else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  const tabs = [
    { value: "basicDetail", label: "Basic Details" },
    { value: "contactDetails", label: "Contact details" },
    { value: "identity", label: "Identity" },
    { value: "organization", label: "Organization" },
    { value: "addressDetails", label: "Address Details" },
    { value: "userPreferences", label: "User Preferences" },
    { value: "loginMethod", label: "Login Method" },
  ];

  const handlesTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div>

      {/* <NoActionLayout 
  title={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{`Admin > User & SOD > User > Edit > ${userId} `}</span>
                    {approved == 3 ? (
                      <TbFlag3Filled style={{ color: "#29CB00", marginLeft: '8px' }} />
                    ) : approved == 0 ? (
                      <TbFlag3Filled style={{ color: "gray", marginLeft: '8px' }} />
                    ) : approved == 1 ? (
                      <TbFlag3Filled style={{ color: "yellow", marginLeft: '8px' }} />
                    ) : approved == 2 ? (
                      <TbFlag3Filled style={{ color: "orange", marginLeft: '8px' }} />
                    ) : approved == 4 ? (
                      <TbFlag3Filled style={{ color: "red", marginLeft: '8px' }} />
                    ) : (
                      <TbFlag3Filled style={{ color: "black", marginLeft: '8px' }} />
                    )}

    </div>
  }
/> */}

    
      <Header title={`Admin > User & SOD > User > Edit > ${userId}`
 }

        onBreadcrumbClick={handleBreadcrumbClick}
        extraContent={getApprovedIcon()} 
      />

      </div>

      <div>
      {/* <h1 className="ml-2 text-green-700 font-md p-2 ">Edit Users</h1> */}

        {/* <div>
          <p>User ID: {user.userId}</p>
        </div> */}
      

    </div>

      <div>
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handlesTabClick} />

        {/* <div className="ml-2 pl-8 pt-2 flex flex-row space-x-6 bg-customBlue ">
          <h6
            className="font-small"
            style={tabStyle("basicDetail")}
            onClick={() => handleTabClick("basicDetail")}
          >
            Basic Details
          </h6>
          <h6
            className="font-small"
            style={tabStyle("contactDetails")}
            onClick={() => handleTabClick("contactDetails")}
          >
            Contact details
          </h6>
          <h6
            className="font-small"
            style={tabStyle("identity")}
            onClick={() => handleTabClick("identity")}
          >
            Identity
          </h6>
          <h6
            className="font-small"
            style={tabStyle("organization")}
            onClick={() => handleTabClick("organization")}
          >
            Organization
          </h6>
          <h6
            className="font-small"
            style={tabStyle("addressDetails")}
            onClick={() => handleTabClick("addressDetails")}
          >
            Address Details
          </h6>
          <h6
            className="font-small"
            style={tabStyle("userPreferences")}
            onClick={() => handleTabClick("userPreferences")}
          >
            User Preferences
          </h6>
          <h6
            className="font-small"
            style={tabStyle("loginMethod")}
            onClick={() => handleTabClick("loginMethod")}
          >
            Login Method
          </h6>
        </div> */}
      </div>
        <div>
            {/* <div key={user.id}> */}
              {activeTab === "basicDetail" && (
                <div className="p-12">
                  <div className="grid grid-cols-2 gap-x-8 gap-y-8">
                    <label className="flex items-center group">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        User ID <span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={userId}
                        readOnly
                        onChange={(e) => setUserName(e.target.value)}
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 cursor-not-allowed"
                        placeholder="Enter username"
                      />
                    </label>

                    <label className="flex items-center group">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        Username <span className="text-red-500 ml-1">*</span>{" "}
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                        className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                ${errors.username ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                          
                        placeholder="Enter username"
                      />
                      {errors.username && (
                        <p className="text-red-500 text-sm ml-4">{errors.username}</p>
                      )}
                    </label>

                  

                    <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-gray-500">
                User Type
              </span>
              <span className="ml-4">:</span>
              <select
                value={userType}
                disabled
                onChange={(e) => setUserType(e.target.value)}
                className="border border-gray-300 p-1 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 cursor-not-allowed"
              >
                <option value="0">
                  Select user type
                </option>
                {userTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            
            </label>

            <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-gray-500">
                Accounting System ID
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={accountingSystemId}
                onChange={(e) => setAccountingSystemId(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              
              />
              {/* {errors.salute && (
                <p className="text-red-500 text-sm ml-4">{errors.salute}</p>
              )} */}
            </label>
            </div>
            <div className="flex flex-col   gap-y-6 mt-6">

            {/* <label className="flex items-center group">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        Salute <span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={salute}
                        onChange={(e) => setSalute(e.target.value)}
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                        required
                      />
                      {errors.salute && (
                        <p className="text-red-500 text-sm ml-4">{errors.salute}</p>
                      )}
                    </label> */}
                    
                    <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-gray-500">
                Salute 
              </span>
              <span className="ml-4">:</span>
              {/* <input type="text" value={salute} onChange={(e) => setSalute(e.target.value)} className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" required/> */}
              <select
                  value={salute}
                  onChange={(e) => setSalute(e.target.value)}
                  className="border border-gray-300 p-1 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4"
                >
                  <option value="">Select Salute</option>
                  <option value="Dr.">Dr.</option>
                  <option value="Miss">Miss</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Ms.">Ms.</option>
                </select>
              {/* {errors.salute && (
                <p className="text-red-500 text-sm ml-4">{errors.salute}</p>
              )} */}
            </label>
            
                    <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Surname
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  required
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
             
            </label>
            <label className="flex items-center">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        First Name<span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-5
                          ${errors.firstName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-sm ml-4">{errors.firstName}</p>
                      )}

            </label>

            <label className="flex items-center">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        Middle Name
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                        required
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      />
                    </label>

                    <label className="flex items-center">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        Last Name <span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0
                          ${errors.lastName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                        required
                        />
                      {errors.lastName && (
                        <p className="text-red-500 text-sm ml-4">{errors.lastName}</p>
                      )}
                    </label>
                    <div className="flex flex-row mt-12">
                    {/* <button
              // onClick={handleContactDetails}
              className=" mt-0 ml-0 bg-blue-500 text-white border border-blue-500 text-sm text-blue-500 py-1 px-2   rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30 "
            >
              Retrigger otp
            </button> */}

                  </div>
                  </div>
                  <div className="flex flex-row mt-12 ">

<button 
   onClick={nextabMoveToContact}
   className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
Next
</button>
</div>

                </div>
              )}

              
            {/* </div> */}

            {/* <div key={user.id}> */}
          {activeTab === "contactDetails" && (
        <div className="p-12">
          <div className="flex flex-col   gap-y-12 mt-6">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Email ID <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // onChange={handleEmailChange}
                maxLength={80}
                className={`border border-gray-300 p-1.5 rounded w-72 h-9 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.email ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
              />
              {email.length === 80 && (
                <p className="text-yellow-500 text-sm ml-4">You have used the maximum character limit.</p>
              )}
              {errors.email && (
                <p className="text-red-500 text-sm ml-4">{errors.email}</p>
              )}
            </label>



            <label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Mobile No <span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-12">:</span>
  <PhoneInput
    className="ml-3 text-customGray"
    country={'us'} // Default country
    value={mobileNo}
    onChange={(value) => setMobileNo(value)} // Updated handler
  />
  {errors.mobileNo && (
    <p className="text-red-500 text-sm ml-4">{errors.mobileNo}</p>
  )}
</label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Whatsapp No
              </span>
              <span className="ml-12">:</span>
              <PhoneInput
                className="ml-3 text-customGray"
                country={'us'} // Default country
                value={whatsappNo}
                onChange={(value) => setWhatsappNo(value)} 
                />
              {/* <input
                type="number"
                value={whatsappNo}
                onChange={(e) => setWhatsappNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              /> */}
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Office No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={officeNo}
                onChange={(e) => setOfficeNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Extension
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={extension}
                onChange={(e) => setExtension(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGrayfocus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
          </div>

          <div className="flex flex-row mt-12 ">

<button 
   onClick={nextabMoveToIdentity}
   className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
Next
</button>

</div>

          <div className="flex flex-row mt-12 ">
 
          </div>
        </div>
      )}
      {/* </div> */}
      {/* <div key={user.id}> */}
      {activeTab === "identity" && (
        <div className="p-8">
          <div className="flex flex-col   gap-y-12 mt-6">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Passport No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={passportNo}
                onChange={(e) => setPassportNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Driving Licence No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={drivingLicenseNo}
                onChange={(e) => setDrivingLicenseNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Local ID
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={localId}
                onChange={(e) => setLocalId(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
              Nationality<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.nationality ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}

             />
              {errors.nationality && (
                <p className="text-red-500 text-sm ml-4">
                  {errors.nationality}
                </p>
              )}
            </label>
          </div>


          <div className="flex flex-row mt-12">
          <button 
             onClick={nextabMoveToOrganization}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button>


          </div>
        </div>
      )}
      {/* </div> */}

      {/* <div key={user.id}> */}

      {activeTab === "organization" && (
        <div className="p-12">
          <div className="flex flex-col   gap-y-8 mt-6">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Employee No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={employeeNo}
                onChange={(e) => setemployeeNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Company Code
              </span>
              <span className="ml-3">:</span>
              {/* <input
                type="text"
                value={companyCodeId}
                onChange={(e) => setcompanyCodeId(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              /> */}
              <select
                value={companyCodeId}
                onChange={(e) => setcompanyCodeId(e.target.value)}
                className={`border border-gray-300 p-1 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4
                    `}
              >
                <option value="">Select Company code</option>
            
                <option value="CMD09">CMD09</option>
                <option value="CMD10">CMD10</option>
                <option value="CMD11">CMD11</option>
                <option value="CMD12">CMD12</option>
            
                {/* <option value="5">DD.MM.YYYY</option>
                <option value="6">DDMMYY</option> */}
            
              </select>
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Department
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={department}
                onChange={(e) => setdepartment(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Valid From <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={validFrom}
                onChange={(e) => setvalidFrom(e.target.value)}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.validFrom ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
             />
             {errors.validFrom && (
                <p className="text-red-500 text-sm ml-4">
                  {errors.validFrom}
                </p>
              )}
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Valid To <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={validTo}
                onChange={(e) => setvalidTo(e.target.value)}
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.validTo ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
              />
               {errors.validTo && (
                <p className="text-red-500 text-sm ml-4">
                  {errors.validTo}
                </p>
              )}
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Joining Date
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={joiningDate}
                // onChange={(e) => setjoiningDate(e.target.value)}
                // className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                onChange={(e) => {
                  setjoiningDate(e.target.value);
                  setErrors({ ...errors, joiningDate: '' });
                }}
                max={validFrom ? validFrom : ''} // Set the minimum date for Joining Date to Valid From
                className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0
                  ${errors.joiningDate ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
                disabled={!validFrom} // Disable the Joining Date input if Valid From is not set
              />

            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Resignation date
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={resignationDate}
                onChange={(e) => setresignationDate(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
          </div>
          <div className="flex flex-row my-12 ">
          <button 
             onClick={nextabMoveToAddress}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button>
           
          </div>
        </div>
      )}
      {/* </div> */}


      {/* <div key={user.id}> */}
      {activeTab === "addressDetails" && (
  <div className="p-12">
    {errors.userId && (
      <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
    )}

    {/* Building Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Building</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Door No </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={doorNo} 
            onChange={(e) => setDoorNo(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Floor No </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={floorNo} 
            onChange={(e) => setFloorNo(e.target.value)}
            placeholder="4" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Building No </label>
          <span className="ml-4">:</span>
          <input 
            type="text"
            value={buildingNo} 
            onChange={(e) => setBuildingNo(e.target.value)}
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Building Name </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={buildingName} 
            onChange={(e) => setBuildingName(e.target.value)}
            placeholder="B name" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>

      <div className="flex items-center mt-4">
        <label className="w-48 text-sm font-medium text-gray-500">Landmark</label>
        <span className="ml-4">:</span>
        <input 
          type="text" 
          value={landMark} 
          onChange={(e) => setLandMark(e.target.value)}
          className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
        />
      </div>
    </div>

    {/* Street Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Street</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Street No </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={streetNo} 
            onChange={(e) => setStreetNo(e.target.value)}
            placeholder="2/626" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Street Name </label>
          <span className="ml-4">:</span>
          <input 
            type="text"  
            value={streetName} 
            onChange={(e) => setStreetName(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
          {/* {errors.streetName && (
            <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
          )} */}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Road No </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={roadNo} 
            onChange={(e) => setRoadNo(e.target.value)}
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Road Name </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={roadName} 
            onChange={(e) => setRoadName(e.target.value)}
            placeholder="B name" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>
    </div>
  {/* Display the error message for address section */}
  {errors.addressSection && (
                <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
              )}

    {/* Area Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Area</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Block </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={areaBlock} 
            onChange={(e) => setAreaBlock(e.target.value)}
            placeholder="2/626" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Area Name </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={areaName} 
            onChange={(e) => setAreaName(e.target.value)}
            placeholder="4" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>
    </div>

    {/* Address Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Address</h3>
      
      <div className="grid grid-cols-1 gap-4">
        <div className="flex items-center">
          <label className="w-56 text-sm font-medium text-gray-500">Address 1 </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={addressLine1} 
            onChange={(e) => setAddressLine1(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
          {errors.addressLine1 && (
            <p className="text-red-500 text-sm ml-4">
              {errors.addressLine1}
            </p>
          )}
        </div>
        <div className="flex items-center">
          <label className="w-56 text-sm font-medium text-gray-500">Address 2 </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={addressLine2} 
            onChange={(e) => setAddressLine2(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-56 text-sm font-medium text-gray-500">Address 3 </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={addressLine3} 
            onChange={(e) => setAddressLine3(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>
    </div>

    {/* Country Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Country</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Country code  <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
            onClick={toggleModal}
            className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{countryCode || 'Select Country Code'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>


           {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-CustomBlue">Select Country Code</h2>
                  <input
                    type="text"
                    placeholder="Search country..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
                  />

                  <button onClick={toggleModal} className="text-gray-500 hover:text-gray-700">&times;</button>
                </div>
                <div className="flex-1 overflow-auto p-4">
                {countries.length > 0 ? (
                  <div className="grid grid-cols-3 gap-4">
                    {countries
                    .filter((country) =>
                      country.countryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      country.country.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((country, index) => (
                      <div key={country.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                        <input
                          type="radio"
                          name="country"
                          value={country.country}
                          checked={selectedCountry === country.country}
                          onChange={handleCheckboxChange}
                          className="mr-2"
                        />
                        <label>{country.country}</label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>Loading countries...</p>
                )}
               </div>
               <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                      <button
                        onClick={toggleModal}
                        className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                      >
                    Save
                  </button>
                </div>
             
                
              </div>
            </div>
          )}

        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Country Name </label>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={countryName}
            // readOnly
            disabled
            className="border border-gray-300 p-1 rounded w-80 h-8 text-customGray text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
          />
       
        </div>
      </div>


      <div className="grid grid-cols-2 gap-4 mt-4">
      
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">State / Province <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
            onClick={toggleModalForState}
            className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{state || 'Select State / Province'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>

          {isModalOpenForState && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
              <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-CustomBlue">Select State / Province</h2>
                  <input
              type="text"
              placeholder="Search state province..."
              value={searchQueryState}
              onChange={handleSearchChangeState}
              className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
            />

                  <button onClick={toggleModalForState} className="text-gray-500 hover:text-gray-700">&times;</button>
                </div>

                <div className="flex-1 overflow-auto p-4">
                {stateProvinces.length > 0 ? (
                  <div className="grid grid-cols-3 gap-4">
                    {stateProvinces
                    .filter((stateP) =>
                      stateP.State_Name.toLowerCase().includes(searchQueryState.toLowerCase()) ||
                    stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                    )
                    .map((stateP, index) => (
                      <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                        <input
                          type="radio"
                          name="StateProvince"
                          value={stateP.state}
                          checked={selectedState === stateP.state}
                          onChange={handleCheckboxChangeForState}
                          className="mr-2"
                        />
                        <label>{stateP.state}</label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>Loading countries...</p>
                )}
                </div>

                  <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                  <button
                    onClick={toggleModalForState}
                    className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                  >
                    Save
                  </button>
                </div>
              
                
              </div>
            </div>
          )}
        </div>


        <div className="flex items-center">
    <label className="w-48 text-sm font-medium text-gray-500">
    State / Province Name
    </label>
    <span className="ml-4">:</span>
    <input
    type="text"
    value={stateName}
    disabled
    placeholder="State Name"
    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
  />

{/* <select
              value={state}
              disabled
              // onChange={handleStateProvinceChange}
              onChange={(e) => setstate(e.target.value)}
              className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray text-gray-500 focus:outline-none hover:border-blue-400 ml-4
                ${errors.state ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
            >
              <option value="" disabled>Select State / Province</option>
              {stateProvinces.map((statePName) => (
                <option key={statePName.id} value={statePName.id}>
                  {statePName.State_Name}
                </option>
              ))}
            </select> */}

    {/* {errors.countryId && <p className="text-red-500 text-sm ml-4">{errors.countryId}</p>} */}
  </div>
  </div> 



      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">District</label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={district} 
            onChange={(e) => setDistrict(e.target.value)}
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Postal Code</label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={postalCode} 
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder="B name" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Zip Code</label>
          <span className="ml-4">:</span>
          <input 
            type="text"  
            value={zipCode} 
            onChange={(e) => setZipCode(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>

        {/* <div className="grid grid-cols-2 gap-4"> */}
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">PO Box</label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={poBox} 
            onChange={(e) => setPoBox(e.target.value)}
            placeholder="B name" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      {/* </div> */}

      </div>

      
    </div>
   
    <div className="flex flex-row mt-2 mb-10">
      <button 
        onClick={nextabMoveToPreferance}
        className=" mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 "
      >
        Next
      </button>
    </div>
  </div>
)}

      {/* </div> */}

      {/* <div key={user.id}> */}
      {activeTab === "userPreferences" && (
        <div className="p-12">
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 mt-6">
            <div className="flex flex-col space-y-6">
              {errors.userId && (
                <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
              )}

              {/* <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Language<span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={language}
                  onChange={(e) => setlanguage(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4"
                />
                {errors.language && (
                  <p className="text-red-500 text-sm ml-4">{errors.language}</p>
                )}
              </label> */}
<label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Language<span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-3">:</span>
  <select
    value={language}
    onChange={(e) => setlanguage(e.target.value)}
    className={`border border-gray-300 p-1 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4
      ${errors.language ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
>
    <option value="">Select Language</option>
    <option value="En">En</option>
    <option value="Es">Es</option>
    <option value="Fr">Fr</option>
    <option value="De">De</option>
    <option value="It">It</option>

    <option value="Pt">Pt</option>
    <option value="zh">zh</option>
    <option value="Ja">Ja</option>
    <option value="Ko">Ko</option>

    <option value="Ru">Ru</option>
    <option value="Ar">Ar</option>
    <option value="Hi">Hi</option>
    <option value="Bn">Bn</option>
    <option value="Nl">Nl</option>

    <option value="Sv">Sv</option>
    <option value="Pl">Pl</option>
    <option value="Tr">Tr</option>

    <option value="Uk">Uk</option>
    <option value="He">He</option>
    <option value="Th">Th</option>

    <option value="Others">Others</option>
  </select>
  {errors.language && (
    <p className="text-red-500 text-sm ml-4">{errors.language}</p>
  )}
</label>

              <label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Date Format<span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-3">:</span>
  <select
    value={dateFormat}
    onChange={(e) => setdateFormat(e.target.value)}
    className={`border border-gray-300 p-1 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4
      ${errors.dateFormat ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
>
        <option value="">Select Date Format</option>
    <option value="1">DD-MM-YYYY</option>
    <option value="2">MM-DD-YYYY</option>
    <option value="3">DD-MM-YY</option>
    <option value="4">MM-DD-YY</option>
    <option value="5">DD.MM.YYYY</option>
    <option value="6">DDMMYY</option>
  </select>
  {errors.dateFormat && (
    <p className="text-red-500 text-sm ml-4">
      {errors.dateFormat}
    </p>
  )}
</label>


<label className="flex items-center">
        <span className="w-48 text-sm font-medium text-gray-500">
          Amount Format
        </span>
        <span className="ml-3">:</span>
        {/* <input
          type="text"
          value={amountFormat}
          onChange={(e) => setamountFormat(e.target.value)}

          className="border border-gray-300 p-1 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4"
        /> */}
         <select
                value={amountFormat}
                onChange={(e) => setamountFormat(e.target.value)}
                className={`border border-gray-300 p-1 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4`}
              >
                <option value="">Select Amount Format</option>
            
                <option value="'1,234,567.89">'1,234,567.89</option>
                <option value="1.234.567,890">1.234.567,890</option>
                <option value="12,34,567.890">12,34,567.890</option>            
              </select>
      </label>


              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Thousand Separator
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={thousandSeparator}
                  onChange={handleThousandChange}
                  // onChange={(e) => setthousandSeparator(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>
              {error.thousand && (
        <p className="text-red-500 text-sm mt-1">
          Only '.' and ',' are allowed for thousand separator.
        </p>
      )}

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Decimal Seperator
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={decimalSeparator}
                  // onChange={(e) => setdecimalSeparator(e.target.value)}
                  onChange={handleDecimalChange}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>
              {error.decimal && (
        <p className="text-red-500 text-sm mt-1">
          Only '.' and ',' are allowed for decimal separator.
        </p>
      )}
 {error.same && (
        <p className="text-red-500 text-sm mt-1">
          Thousand and decimal separators cannot be the same.
        </p>
      )}

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Accelerator for thousands
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={acceleratorForThousands + suffixForThousands}
                  onChange={handleChangeforThousands}
                  // onChange={(e) => setacceleratorThousands(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>
              {errorThousands  && (
        <p className="text-red-500 text-sm mt-1">
          Only 'K' or 'T' are allowed.
        </p>
      )}

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Accelerator for Lakhs
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={acceleratorForLakhs + suffixForLakhs}
                  onChange={handleChangeforLakhs}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>
              {errorLakhs  && (
        <p className="text-red-500 text-sm mt-1">
          Only 'L' are allowed.
        </p>
      )}
      
              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Accelerator for Millions
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={acceleratorForMillions + suffixForMillions}
                  // onChange={(e) => setacceleratorMillions(e.target.value)}
                  onChange={handleChangeforMillions}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>
              {errorMillions  && (
        <p className="text-red-500 text-sm mt-1">
          Only 'M' are allowed.
        </p>
      )}
              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Time Format <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                <select
                  value={timeFormat}
                  onChange={(e) => settimeFormat(e.target.value)}
                  className={`border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4
                    ${errors.timeFormat ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
              >
                  <option value="2">24 Hours</option>
                  <option value="1">12 Hours</option>
                  <option value="">Select Time Format</option>
                </select>
                {errors.timeFormat && (
                  <p className="text-red-500 text-sm ml-4">
                    {errors.timeFormat}
                  </p>
                )}
              </label>


              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Time Zone
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={timeZone}
                  onChange={(e) => settimeZone(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>
            </div>

            <div className="flex flex-col space-y-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={receiveSystemMail === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setreceiveSystemMail(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500 "
                />
                <p className="ml-4 font-small text-gray-600">
                  Dont want to receive System emails
                </p>
                {/* <input
                  type="text"
                  value={receiveSystemMail}
                  onChange={(e) => setreceiveSystemMail(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4"
                /> */}
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={downloadData === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setdownloadData(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Download the Data
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={changesInCashflow === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setchangesInCashflow(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Change the Cash flow code
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  // value={changeVarient}
                  // onChange={(e) => setchangeVarient(e.target.value)}
                  checked={changeVarient === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setchangeVarient(e.target.checked ? 1 : 0)} // Update state based on checkbox state

                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Can Change the varient
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  // value={deleteAllRecords}
                  // OnChange={(e) => setdeleteAllRecords(e.target.value)}
                  checked={deleteAllRecords === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setdeleteAllRecords(e.target.checked ? 1 : 0)} // Update state based on checkbox state

                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Can Delete all records
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={changeAccountingCode === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setchangeAccountingCode(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Change the Accounting Code
                </p>
              </label>
              <label className="flex items-center">
                <input
                // value={changeAccountingCode}
                // onChange={(e) => setchangeAccountingCode(e.target.value)}
                checked={updateCountry === 1} // Check if receiveSystemMail is 1
                onChange={(e) => setupdateCountry(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  type="checkbox"
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Do Not Update Country
                </p>
              </label>
            </div>
          </div>

          <div className="flex flex-row mt-12">
          <button 
             onClick={nextabMoveToMethod}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2 mb-5  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button>
           
          </div>
        </div>
      )}
      {/* </div> */}
      {/* <div key={user.id}> */}
      {activeTab === "loginMethod" && (
        <div className="p-12">
          {/* <div className="mt-8 space-y-8">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <h3>Login Method</h3>
            <div className="flex flex-col space-y-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  value={oneTimePassword} // Value mapped to 0 for Password
                  checked={loginMethod == 0}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2">Password</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  value={oneTimePassword} // Value mapped to 1 for Multi-Factor Authentication
                  checked={loginMethod == 1}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2">Multi-Factor Authentication</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  value={oneTimePassword} // Value mapped to 2 for OTP
                  checked={loginMethod == 2}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2">OTP</span>
              </label>
            </div>
          </div> */}
          <div className="flex flex-col space-y-4"> 
  <label className="flex items-center">
    <input
      type="radio"
      name="loginMethod"
      value={0}  // Value mapped to 0 for Password
      checked={loginMethod == 0}
      onChange={handleLoginMethodChange}
      className="form-radio text-blue-500 w-4 h-4"
    />
    <span className="ml-2 text-customGray">Password</span>
  </label>

  <label className="flex items-center">
    <input
      type="radio"
      name="loginMethod"
      value={1}  // Value mapped to 1 for Multi-Factor Authentication
      checked={loginMethod == 1}
      onChange={handleLoginMethodChange}
      className="form-radio text-blue-500 w-4 h-4"
    />
    <span className="ml-2 text-customGray">Multi-Factor Authentication</span>
  </label>

  <label className="flex items-center">
    <input
      type="radio"
      name="loginMethod"
      value={2}  // Value mapped to 2 for OTP
      checked={loginMethod == 2}
      onChange={handleLoginMethodChange}
      className="form-radio text-blue-500 w-4 h-4"
    />
    <span className="ml-2 text-customGray">OTP</span>
  </label>
</div>

          <div className="flex flex-row mt-12">
          
          </div>
        </div>
      )}


      {/* </div> */}

        </div>

      
      
        <div className="flex flex-row items-center">
      <div className="mt-50px flex w-full bg-gray-50 dark:bg-zinc-800 p-4 fixed bottom-0 ">
        <button
          onClick={handleSaveBasicDetails}
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
        >
          Save
        </button>
        <button
          onClick={handleSaveContinueDetails}
          className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
        >
          Save & Continue
        </button>
      </div>
    </div>
      
    </div>
  );
}
