import React, { useState, useEffect } from "react";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import BamActions from "../../Components/BamActions";
import { useNavigate, useParams } from "react-router-dom";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import { GiSettingsKnobs } from "react-icons/gi";
import ReTable from "../../../../../components/Table";
import FilterComponent from "../../Components/FilterComponent";
import Header from "../../../../../components/Header";
import axios from "axios";
import * as XLSX from "xlsx";
import CustomeUpLoader from "../../../../../components/CustomeLoader";

export default function DBarea() {
  const { iconId } = useParams();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDBArea = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get-db-area`
        );
        const dbData = response.data.data.map((item) => ({
          id: item.id,
          db: item.DB,
          description: item.DB_Description,
          createdBy: item.created_by,
          createdDate: new Date(item.creationDate).toLocaleDateString(),
          createdTime: item.creationTime,
          changedBy: item.changed_by,
          changedDate: new Date(item.changed_date).toLocaleDateString(),
          changedTime: item.changedTime,
        }));
        setData(dbData);
      } catch (error) {
        console.error("Error fetching DB area:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDBArea();
  }, []);

  const handleDownload = () => {
    const dataToExport = data.map((item) => ({
      DB: item.db,
      DB_Description: item.description,
      "Created By": item.createdBy,
      "Created Date": item.createdDate || "",
      "Created Time": item.createdTime || "",
      "Changed By": item.changedBy,
      "Changed Date": item.changedDate || "",
      "Changed Time": item.changedTime || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DB Area");

    XLSX.writeFile(workbook, "DBarea_Data.xlsx");
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const buttons = BamButtonsConfigs.AccountStatus.map((button) => ({
    ...button,
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/DB-area-add")
        : button.icon === GiSettingsKnobs
        ? () => setShowFilter((prev) => !prev)
        : button.icon === MdOutlineFileDownload
        ? handleDownload
        : button.onClick,
  }));

  const handleFilter = (filters) => {
    console.log("Filtering with:", filters);
  };

  const handleReset = () => {
    console.log("Filters reset");
  };

  const columns = [
    { Header: "DB", accessor: "db", width: ("DB".length -2)*10 },
    { Header: "DB Description", accessor: "description", width: ("DB Description".length -2)*10 },
    { Header: "Created by", accessor: "createdBy", width: ("Created by".length -2)*10 },
    { Header: "Created date", accessor: "createdDate", width: ("Created date".length -2)*10 },
    { Header: "Created time", accessor: "createdTime", width: ("Created time".length -2)*10 },
    { Header: "Changed by", accessor: "changedBy", width: ("Changed by".length -2)*10 },
    { Header: "Changed date", accessor: "changedDate", width: ("Changed date".length -2)*10 },
    { Header: "Changed time", accessor: "changedTime", width: ("Changed time".length -2)*10 },
  ];

  const handleSelectRow = (rowId) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowId)
        ? prevSelected.filter((id) => id !== rowId)
        : [...prevSelected, rowId]
    );
  };

  const handleSelectAll = () => {
    setSelectedRows(
      selectedRows.length === data.length ? [] : data.map((row) => row.id)
    );
  };

  const handleRowDoubleClick = (rowId) => {
    console.log("Row double-clicked:", rowId);
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Set Up") {
      navigate("/bam");
    } else if (crumb.title === "DB Area") {
      navigate("/3/BAM/24/DB-area");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  if (loading)
    return (
      <div>
        <CustomeUpLoader />
      </div>
    );

  return (
    <div>
      <Header
        title={"BAM > Set Up > DB Area"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <BamActions 
      icons={iconId}
      buttons={buttons} 
      selectedCount={selectedRows.length}
      selectIds={selectedRows}
      />
      {showFilter && (
        <FilterComponent onFilter={handleFilter} onReset={handleReset} />
      )}
      <ReTable
        data={data}
        columns={columns}
        onSelectRow={handleSelectRow}
        selectedRows={selectedRows}
        onSelectAll={handleSelectAll}
        onRowDoubleClick={handleRowDoubleClick}
      />
    </div>
  );
}
