import React, { useEffect, useState } from "react";
import StatementServices from "../../../../../rest-services/StatementIdentiServices";
import BankStatementActions from "../../props/ActionButtons";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { IoMdRefresh } from "react-icons/io";
import Pagination from "../../../../../components/PageNation";
import { MdClose, MdOutlineFileDownload, MdOutlineMotionPhotosAuto } from "react-icons/md";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../../components/toast/toast";
import CustomeLoading from "../../../../../components/CustomeLoading";
import { BsUbuntu } from "react-icons/bs";
import HoverButton from "../../../../../components/HoverButton";
import CustomButton from "../../../../../components/CustomButton";
import CustomDropdown from "../../../../../components/CustomDropdown";
import BamServices from "../../../../../rest-services/BamServices";
import { RxDropdownMenu } from "react-icons/rx";
import ReusableModal from "../../../../../components/PopupComponent";
import Footer from "../../../../../components/footer";

function StatementIdentifier() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate()
  const { toast } = useToast()
  const [manualAssing, setManualAssing] = useState(false)
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    fetchAssignee();
  }, []);
  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    userId: "",
    assigner: "",
  });
  const fetchData = async (page) => {
    try {
      const response = await StatementServices.getAllStatement(pageSize, page);

      let { identifier, totalCount } = response.data.data;

      if (!identifier) {
        identifier = [];
      } else if (!Array.isArray(identifier)) {
        identifier = [identifier];
      }

      setData(identifier);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / 20));
    } catch (error) {
      console.error("Error fetching statement identifier data:", error);
    }
  };
  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchData(1); // Fetch data with updated page size
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };

  const downloadExcel = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/identifier/export-data`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Error downloading file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "statement_identifier.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const autoAssign = async () => {
    try {
      if (!assignee.clientId) return;
      setIsLoading(true);
      const response = await StatementServices.autoAssign(assignee.clientId);
      console.log(response.status);
      if (response.status === 200) {
        fetchData(currentPage)
        setIsLoading(false);
        toast("success", "Auto Assign Successfully");
      } else {
        setIsLoading(false);
        toast("error", "No matching account details found.");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (searchQuery.length > 0) {
      handleSearch(searchQuery)
    }

  }, [searchQuery])
  const handleSearch = async (searchQuery) => {
    try {
      setIsLoading(true)
      const response = await StatementServices.searchStatement(searchQuery, pageSize, currentPage);
      if (response.status === 200) {

        setData(response.data.statement);
        setIsLoading(false);
        setTotalCount(totalCount);
        setTotalPages(Math.ceil(totalCount / 20));
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const buttons = bankStatementActionsConfig.screen5.map((button) => {
    let onClick;
    let title;
    if (button.icon === IoMdRefresh) {
      onClick = () => fetchData(currentPage);
      title = "fetch";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downloadExcel();
      title = "download";
    }
    else if (button.icon === MdOutlineMotionPhotosAuto) {
      onClick = () => autoAssign();
      title = "Auto Assign";
    }

    return {
      ...button,
      onClick,
      title,
    };
  });

  const extraButtons = [
    {
      icon: BsUbuntu,
      title: "Manual Assing",
      onClick: () => setManualAssing(true),
    },
  ];
  const [modifiedRows, setModifiedRows] = useState([]);
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);


  const columns = React.useMemo(() => [
    {
      Header: "Statement Type",
      accessor: "statement_type",
      width: ("Statement Type".length-2)*10,
    },
    {
      Header: "Statement Identifier",
      accessor: "statement_identifier",

      width: ("Statement Identifier".length-2)*10,
    },
    {
      Header: "Corporate BIC",
      accessor: "corporate_bic",

      width: ("Corporate BIC".length-2)*10,
    },
    { Header: "Bank BIC", accessor: "bank_bic", width: ("Bank BIC".length-2)*10 },
    {
      Header: "Active",
      accessor: "status",
      width: ("Active".length-2)*10,
      Cell: ({ value, row }) => (
        <input
          type="checkbox"
          checked={value === 1}
          className="accent-customBlue"
          onChange={(e) =>
            handleCheckboxChange(row.original.id, "status", e.target.checked)
          }
        />
      ),
    },
    {
      Header: "Bank account Id",
      accessor: "bank_account_id",
      width: ("Bank account Id".length-2)*10,
    },
    {
      Header: " Company Code",
      accessor: "companyCode",
      width: ("Company Code".length-2)*10,
    },
    {
      Header: "Bank",
      accessor: "bank",
      width: ("Bank".length-2)*10,
    },
    {
      Header: "Bank Branch",
      accessor: "bankBranch",
      width: ("Bank Branch".length-2)*10,
    },
    {
      Header: "Bank Account No",
      accessor: "bankAccountNo",
      width: ("Bank Account No".length-2)*10,
    },
    {
      Header: "IBAN",
      accessor: "iban",
      width: ("IBAN".length-2)*10,
    },
    {
      Header: "Bank Statement Updated",
      accessor: "bankStatementUpdate",
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === 1} readOnly />
      ),
      width: ("Bank Statement Updated".length-2)*10,
    },
    {
      Header: "Manual Updated",
      accessor: "manualUpdate",
      Cell: ({ value }) => (
        <input type="checkbox" checked={value === 1} readOnly />
      ),
      width: ("Manual Updated".length-2)*10,
    },
    { Header: "Created By", accessor: "createdBy", width: ("Created By".length-2)*10 },
    {
      Header: "Created Date",
      accessor: "creationDate",
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      width: ("Created Time".length-2)*10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
    {
      Header: "Updated By",
      accessor: "changedBy",

      width: ("Updated By".length-2)*10,
     canResize: true,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      width: ("Updated Date".length-2)*10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      width: ("Updated Time".length-2)*10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
  ]);

  const handleCheckboxChange = (id, field, value) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, [field]: value ? 1 : 0 } : row
      )
    );
    setModifiedRows((prevModified) => {
      const modifiedRow = { id, [field]: value ? 1 : 0 };

      // Check if row is already modified
      const existingIndex = prevModified.findIndex((row) => row.id === id);

      if (existingIndex > -1) {
        // Merge changes if the row is already in the list
        const updatedRow = { ...prevModified[existingIndex], ...modifiedRow };
        return [
          ...prevModified.slice(0, existingIndex),
          updatedRow,
          ...prevModified.slice(existingIndex + 1),
        ];
      } else {
        // Add the new modified row
        return [...prevModified, modifiedRow];
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };
  const handleSelectRow = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];
    setSelectedRows(updatedSelectedRows);
    setShowExtraIcons(updatedSelectedRows.length === 1);
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Statement Identifier") {
      navigate("/statement-identifire");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const ManualAssingModel = ({ isOpen, onclose, statmentId, data }) => {
    const [mainAccount, setMainAccount] = useState([]);
    const [selectedMainAccount, setSelectedMainAccount] = useState("");
    const [selectedDescription, setSelectedDescription] = useState("")
    const [identifier, setIdentifier] = useState("");
    useEffect(() => {
      if (!isOpen) return;
      fetchMainBankAccount();
    }, [isOpen]);
    const fetchMainBankAccount = async () => {
      try {
        const selecteValue = data.find((account) => account.id === statmentId);
        setIdentifier(selecteValue.statement_identifier);
        const response = await BamServices.bankAccountID();

        if (response.status === 200) {
          setMainAccount(response.data.bankAccounts);
        }
      } catch (error) {
        console.error("Error fetching main bank account:", error);
      }
    };
    const handleMainAccountSelect = (e) => {
      const selectedValue = mainAccount.find((account) => account.bankAccountId === e.target.value || account.bankAccountDescription === e.target.value);
      if (!selectedValue) return;
      setSelectedDescription(selectedValue.bankAccountDescription);
      setSelectedMainAccount(selectedValue.bankAccountId);
    };

    const handleMainAccountChange = async () => {
      try {
        setIsLoading(true)
        const data = {
          id: statmentId,
          bankAccountId: selectedMainAccount,
          bankAccountDescription: selectedDescription,
        }
        const response = await StatementServices.manualAssign(data);

        if (response.status === 200) {
          toast("success", response.message);
          onclose();
          fetchData(currentPage);
          setIsLoading(false)
        } else {
          toast("error", response.message);
          setIsLoading(false)
        }
      } catch (error) {
        console.error("Error fetching main bank account:", error);
        setIsLoading(false)
      }
    }
    const [isBankAccountModalOpen, setIsBankAccountModalOpen] = useState(false);
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };
    return (
      <div>
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-4xl z-50">
            <div className="flex justify-between items-center mt-2">
              <h2 className="text-customBlue text-lg font-semibold mb-5 ">
                Manual Assign
              </h2>
            </div>
            <div className="flex mt-2 p-2 text-gray-500">
              <label className="text-sm font-medium text-gray-500 whitespace-nowrap">
                Statment Identifier
              </label>
              <span className="ml-4">:</span>
              <h3 className="ml-4">{identifier}</h3>
            </div>
            <div className="flex  align-center">
              <div className="grid grid-cols-2 gap-4 p-2">
                <div className="flex items-center mb-2">
                  <label className="w-48 text-sm font-medium text-gray-500 whitespace-nowrap">
                    Bank Account ID
                  </label>
                  <span className="ml-4">:</span>
                  <button
                    onClick={() => setIsBankAccountModalOpen(true)}

                    className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray 
                       border-gray-300 hover:border-blue-400
                        }`}
                  >
                    <span className="font-small text-customGray text-sm">
                      {selectedMainAccount ? selectedMainAccount : "Select BankAccount"}
                    </span>
                    <RxDropdownMenu className="ml-2" />
                  </button>

                  <ReusableModal
                    title="Select Bank Account ID"
                    isOpen={isBankAccountModalOpen}
                    onRefresh={fetchMainBankAccount}
                    onClose={() => {
                      setIsBankAccountModalOpen(false);
                      setSearchQuery("");
                    }}
                    onSave={() => {
                      setIsBankAccountModalOpen(false);
                      setSearchQuery("");
                    }}
                    searchPlaceholder="Search Bank Account ID..."
                    searchQuery={searchQuery}
                    onSearchChange={handleSearchChange}
                    isViewMode={false}
                    showReset={true}
                  >
                    {mainAccount && mainAccount.length > 0 ? (
                      mainAccount

                        .filter((branch) =>
                          branch.bankAccountId
                            ?.toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).sort((a, b) =>
                          a.bankAccountId.localeCompare(b.bankAccountId, undefined, { sensitivity: 'base' })
                        )
                        .map((branch, index) => (
                          <div
                            key={branch.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                          >
                            <input
                              type="radio"
                              name="BranchID"
                              value={branch.bankAccountId}
                              checked={selectedMainAccount === branch.bankAccountId}
                              onChange={
                                (e) => { handleMainAccountSelect(e) }
                              }
                              className="mr-2 accent-customBlue"
                            />
                            <label>{branch.bankAccountId || "N/A"}</label>
                          </div>
                        ))
                    ) : (
                      <p>{"No Branch Found"}</p>
                    )}

                  </ReusableModal>
                </div>
                <div className="flex items-center mb-2">
                  <label className="w-48 text-sm font-medium text-gray-500 whitespace-nowrap">
                    BankAccount Description
                  </label>
                  <span className="ml-4">:</span>
                  <button
                    onClick={() => setIsDescriptionModalOpen(true)}

                    className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray 
                       border-gray-300 hover:border-blue-400
                        }`}
                  >
                    <span className="font-small text-customGray text-sm whitespace-nowrap">
                      {selectedDescription ? selectedDescription : "BankAccount Description"}
                    </span>
                    <RxDropdownMenu className="ml-2" />
                  </button>

                  <ReusableModal
                    title="Select Bank Account"
                    isOpen={isDescriptionModalOpen}
                    onRefresh={fetchMainBankAccount}
                    onClose={() => {
                      setIsDescriptionModalOpen(false);
                      setSearchQuery("");
                    }}
                    onSave={() => {
                      setIsDescriptionModalOpen(false);
                      setSearchQuery("");
                    }}
                    searchPlaceholder="Search Bank Account Description..."
                    searchQuery={searchQuery}
                    onSearchChange={handleSearchChange}
                    isViewMode={false}
                    showReset={true}
                  >
                    {mainAccount && mainAccount.length > 0 ? (
                      mainAccount

                        .filter((branch) =>
                          branch.bankAccountDescription
                            ?.toString()
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).sort((a, b) =>
                          a.bankAccountDescription.localeCompare(b.bankAccountDescription, undefined, { sensitivity: 'base' })
                        )
                        .map((branch, index) => (
                          <div
                            key={branch.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                          >
                            <input
                              type="radio"
                              name="BranchID"
                              value={branch.bankAccountDescription}
                              checked={selectedDescription === branch.bankAccountDescription}
                              onChange={
                                (e) => { handleMainAccountSelect(e) }
                              }
                              className="mr-2 accent-customBlue"
                            />
                            <label>{branch.bankAccountDescription || "N/A"}</label>
                          </div>
                        ))
                    ) : (
                      <p>{"No Branch Found"}</p>
                    )}

                  </ReusableModal>
                </div>

              </div>
            </div>
            <div className="flex justify-end mt-4">
              <HoverButton
                title={"Cancel"}
                className={"mr-2"}
                onClick={onclose}
              />
              <CustomButton
                onClick={() => {
                  handleMainAccountChange()
                }}
                title={"Save"}
              />
            </div>
          </div>
        </div>
      </div >
    )
  }
  const onRowDoubleClick = (row) => {
    console.log("Double clicked row:", row);
  };

  const handleSubmit = async () => {
    try {
      const response = await StatementServices.updateStatus(modifiedRows);
      if (response.status === 200) {
        toast("success", response.message)
        setModifiedRows([]);
        fetchData(currentPage);
      } else {
        toast("error", response.message)
      }
    } catch (error) {
      console.error("Error updating data:", error);
      setModifiedRows([]);
    }
  };

  return (
    <div>
      <Header title="Bank Statements > MT940 > Statement Identifier" onBreadcrumbClick={handleBreadcrumbClick} />
      <div >
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Identifiers"
            />

            <BankStatementActions buttons={buttons} extraButtons={extraButtons} isExtraButtonsVisible={showExtraIcons} />
          </div>
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>

        </div>
        <div>
          <ReTable
            data={data}
            not20Records={true}
            columns={columns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
        <div>{manualAssing && <ManualAssingModel isOpen={manualAssing} onclose={() => setManualAssing(false)} statmentId={selectedRows[0]} data={data} />}</div>
        <div>{isLoading && <CustomeLoading />}</div>
        <div>
          <Footer>
            <CustomButton
              title="Submit"
              onClick={handleSubmit}
            />
          </Footer>
        </div>
      </div>
    </div>
  );
}

export default StatementIdentifier;
