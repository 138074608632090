import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbFlag3Filled } from "react-icons/tb";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import MdSourceServices from "../../../../../rest-services/MdSourceServices";

export default function ExchangeRateTypeAdd() {
  const { mdSourceId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [source, setSource] = useState("");
  const [sourceCompanyName, setSourceCompanyName] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const [status, setStatus] = useState("");
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!mdSourceId;

  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const { source, sourceCompanyName } = location.state.copiedData;
      setSource(source);
      setSourceCompanyName(sourceCompanyName);
    } else if (mdSourceId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [mdSourceId, location.state]);

  const getDataForEdit = async () => {
    try {
      const response = await MdSourceServices.getMdSourceById(mdSourceId);
      setSource(response.data.source);
      setSourceCompanyName(response.data.sourceCompanyName);
    } catch { }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!source) newErrors.source = "source  is required.";
    if (!sourceCompanyName) newErrors.sourceCompanyName = "Source Company name Description is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    // Check for duplicate BU Group code
    try {
      // Skip duplication check for edit mode
      if (!mdSourceId || isCopyMode) {
        const duplicateCheckResponse = await MdSourceServices.checkDuplicateMdSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatemdsource`,
          { source: source }
        );

        if (duplicateCheckResponse.message === "source type already exists") {
          toast("error", "source type already exists.");
          setErrors((prev) => ({
            ...prev,
            source: "source type already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }

      // Prepare the data for submission
      const data = {
        id: isCopyMode ? null : mdSourceId,
        source,
        sourceCompanyName,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };

      console.log("BuGroup data", data);


      let response;
      if (isCopyMode || !mdSourceId) {
        // Create a new BU Group
        response = await MdSourceServices.addMdSource(
          `${process.env.REACT_APP_BASE_URL}/create-mdsource`,
          data
        );
      } else {
        // Update an existing BU Group
        response = await MdSourceServices.addMdSource(
          `${process.env.REACT_APP_BASE_URL}/create-mdsource`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          mdSourceId && !isCopyMode
            ? "MD Source Updated successfully"
            : "MD Source Added successfully"
        );

        // Clear the form and navigate
        setSource("");
        setSourceCompanyName("");


        navigate("/2/master-data/8/md-source"); // Redirect after saving, only if Save and Continue is false

      } else {
        toast("error", "Error adding/updating Md Source.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Md Source: ${error.message}`,
      });
    }
  };
  const handleSaveandContinue = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    // Check for duplicate BU Group code
    try {
      // Skip duplication check for edit mode
      if (!mdSourceId || isCopyMode) {
        const duplicateCheckResponse = await MdSourceServices.checkDuplicateMdSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatemdsource`,
          { source: source }
        );

        if (duplicateCheckResponse.message === "source type already exists") {
          toast("error", "source type already exists.");
          setErrors((prev) => ({
            ...prev,
            source: "source type already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }

      // Prepare the data for submission
      const data = {
        id: isCopyMode ? null : mdSourceId,
        source,
        sourceCompanyName,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };

      console.log("BuGroup data", data);


      let response;
      if (isCopyMode || !mdSourceId) {
        // Create a new BU Group
        response = await MdSourceServices.addMdSource(
          `${process.env.REACT_APP_BASE_URL}/create-mdsource`,
          data
        );
      } else {
        // Update an existing BU Group
        response = await MdSourceServices.addMdSource(
          `${process.env.REACT_APP_BASE_URL}/create-mdsource`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          mdSourceId && !isCopyMode
            ? "MD Source Updated successfully"
            : "MD Source Added successfully"
        );

        // Clear the form and navigate
        setSource("");
        setSourceCompanyName("");



      } else {
        toast("error", "Error adding/updating Md Source.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Md Source: ${error.message}`,
      });
    }
  };

  const checkDuplicateSourcecode = async (code) => {
    try {
      const response = await MdSourceServices.checkDuplicateMdSourcecode(
        `/checkduplicatemdsource`,
        { source: code }
      );
      console.log("Response from server:", response);

      if (response.message === "source type already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ source: " " });

        toast("error", "Source Type already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate buGroup:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, source: "Error checking role name" }));
    }
  };

  const handleSourceCodeChange = (e) => {
    const code = e.target.value;
    setSource(code);
    setUnsavedChanges(true);
    // Clear previous errors when typing
    setErrors(prev => ({ ...prev, source: "" }));

    // Check for duplicate LGE Code locally
    if (code.trim() !== "") {
      checkDuplicateSourcecode(code);
    }
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Market Data') {
      navigate('/2/master-data');
    } else if (crumb.title === 'MD Source') {
      navigate('/md-source');
    }
    else if (crumb.title === 'Primary Data') {
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (status === 4) {
      color = "#29CB00"; // Green
    } else if (status === 0) {
      color = "gray"; // Gray
    } else if (status === 1) {
      color = "#AF52DE"; 
    } else if (status === 2) {
      color = "yellow"; 
    } else if (status === 3) {
      color = "orange"; 
    }
    else if (status === 5) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };
  return (
    <div>
<Header
  title={`Primary Data > Market Data > MD Source > ${
    isCopyMode 
      ? 'Add' 
      : mdSourceId 
        ? (isViewMode ? "View" : "Edit") 
        : "Add"
  }${mdSourceId && (isViewMode || !isCopyMode) ? ` > ${source}` : ''}`}
  onBreadcrumbClick={handleBreadcrumbClick} 
    hasUnsavedChanges={unsavedChanges}
    backRoute={"/2/master-data/12/md-source"}
  />
          <div className="m-6 border border-gray-200 p-2  rounded-lg">

        <div className="flex flex-col space-y-4 col-span-1 p-2">
          <label className="flex items-center">
            <span className="w-48 font-small text-customGray text-xs">
              Source<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={source}
              maxLength={10}

              onChange={handleSourceCodeChange}

              className={`border font-small text-customGray text-xs ${errors.source ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
              disabled={((isEditMode && !isCopyMode) || isViewMode)}
            />
          </label>
          {!isViewMode && source.length === 10 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}


          <label className="flex items-center">
            <span className="w-48 font-small text-customGray text-xs">
              Source Company Name<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={sourceCompanyName}
              maxLength={20}

              onChange={(e) => {
                setSourceCompanyName(e.target.value);
                setUnsavedChanges(true);
                if (errors.sourceCompanyName) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    sourceCompanyName: "",
                  }))
                }
              }}
              className={`border font-small text-customGray text-xs ${errors.sourceCompanyName ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
              disabled={isViewMode}
            />
          </label>
          {!isViewMode && sourceCompanyName.length === 20 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}


        </div>

      </div>
      <Footer>
        {(!isViewMode && (<ButtonGroup onSave={handleSubmit} onSaveContinue={handleSaveandContinue} hasId={!!mdSourceId} />))}
      </Footer>
    </div>
  );
}
