import React from "react";
import styled from "styled-components";
import CustomButton from "./CustomButton";
import HoverButton from "./HoverButton";
import Draggable from "react-draggable";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: 300px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8aa6c1;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #6b8db2;
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f4f8;
    border-radius: 6px;
  }

  scrollbar-width: thin;
  scrollbar-color: #8aa6c1 #f0f4f8;
`;

const RadioPopUp = ({
    title = "Select Items",
    isOpen,
    onClose,
    onSave,
    onReset,
    searchPlaceholder = "Search...",
    searchQuery,
    onSearchChange,
    items = [],
    selectedItems = [],
    onItemToggle,
}) => {
    if (!isOpen) return null;

    const rows = [];
    const columnsPerRow = 4;
    for (let i = 0; i < items.length; i += columnsPerRow) {
        rows.push(items.slice(i, i + columnsPerRow));
    }
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <Draggable handle=".popup-header">
                <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden absolute">
                    {/* Header */}
                    <div className="popup-header flex justify-between items-center p-4 shadow bg-white sticky top-0 z-10 cursor-move">
                        <h2 className="text-lg text-customBlue">{title}</h2>
                        <input
                            type="text"
                            placeholder={searchPlaceholder}
                            value={searchQuery}
                            onChange={onSearchChange}
                            className="border border-gray-300 p-1 rounded w-60 ml-60 placeholder-customGray placeholder-text-xs hover:border-customBlue text-customGray focus:outline-none font-small text-xs"
                        />
                        <button onClick={onClose} className="text-customBlue text-2xl">
                            &times;
                        </button>
                    </div>

                    {/* Checkbox Grid */}
                    <TableWrapper>
                        {rows.length > 0 ? (
                            rows.map((row, rowIndex) => {
                                const isEvenRow = rowIndex % 2 === 0;
                                return (
                                    <div
                                        key={rowIndex}
                                        className={`grid grid-cols-4 gap p-2 ${isEvenRow ? "bg-sky-50" : ""
                                            }`}
                                    >
                                        {row.map((item, index) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className="flex items-center p-1 rounded"
                                                >
                                                    <input
                                                        type="radio"
                                                        checked={selectedItems.includes(item.id)}
                                                        onChange={() => onItemToggle(item.id)}
                                                        className="mr-2 accent-customBlue"
                                                    />
                                                    <label className="text-customGray text-xs">{item.name}</label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })
                        ) : (
                            <div className="flex items-center justify-center h-full text-customGray text-sm">
                                No Data available
                            </div>
                        )}
                    </TableWrapper>

                    {/* Footer - Fixed at the bottom */}
                    <div className="flex justify-end p-4 bg-white border-t absolute bottom-0 left-0 right-0">
                        <HoverButton title="Reset" onClick={onReset} />
                        <CustomButton title="Apply" onClick={onSave} className="ml-4" />
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default RadioPopUp;