import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdRefresh,
  IoMdTrash,
} from "react-icons/io";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import { useToast } from "../../../../../components/toast/toast";
import { MdOutlineFileDownload, MdOutlineFileUpload, MdOutlineHistory } from "react-icons/md";
import * as XLSX from "xlsx";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";

import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbFlag3Filled } from "react-icons/tb";
import { GiSettingsKnobs } from "react-icons/gi";
import CustomButton from "../../../../../components/CustomButton";
import HoverButton from "../../../../../components/HoverButton";
import { RxDropdownMenu } from "react-icons/rx";
import { VscVmActive } from "react-icons/vsc";
import ConfirmationDialog from "../../../../../components/Deletionpopup";
import Pagination from "../../../../../components/PageNation";
import { debounce } from "lodash";
import CompanyServices from "../../../../../rest-services/CompanyServices";
import { PiCopy } from "react-icons/pi";
import UserService from "../../../../../rest-services/UserServices";
import SourceServices from "../../../../../rest-services/SourceServices";
import CheckboxPopup from "../../../../../components/CheckBoxComponent";
import CustomDropdown from "../../../../../components/CustomDropdown";
import { Filters } from "handsontable/plugins";

export default function SourceCode() {

  const [sourceId, setSourceId] = useState(null); // To store the selected calendar ID
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [source, setSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [filteredSource, setFilteredSource] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const [alert, setAlert] = useState(null);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [isbugroupPopupOpen, setIsbugroupPopupOpen] = useState(false); // Controls popup visibility
  const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
  const [filterType, setFilterType] = useState("1"); // 'createdBy' or 'updatedBy'
  const [rolesPermissions, setRolesPermissions] = useState([]);
const[openDropdown, setOpenDropdown] = useState(false);

  const [filters, setFilters] = useState({
    source: [],
    sourceCode: [],
    ourCode: [],
    dataType: [],
    rateType: [],
    mdType : [],	
    updated_by: "",
    updated_date_from: "",
    updated_date_to: "",
    created_by: "",
    created_date_from: "",
    created_date_to: "",
  });

  const { iconId } = useParams();

  const [selectedbugroup, setSelectedbugroup] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);
  const [bugroupall, setbugroupall] = useState([]); // For fetched company codes
  const [filteredbugroupall, setFilteredbugroupall] = useState([]);
  const [bugroupallearchQuery, setbugroupallearchQuery] = useState("");

  const [updatedbydata, setupdatedbydata] = useState([]); // For fetched company codes
  const [filteredupdatedbydata, setfilteredupdatedbydata] = useState([]);
  const [updatedbysearchquery, setupdatedbysearchquery] = useState("");
  const [createdbydatas, setcreatedbydatas] = useState([]); // For fetched company codes
  const [filteredcreatedbydatas, setFilteredcreatedbydatas] = useState([]);
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [tempSelectedbugroupall, setTempSelectedbugroupall] = useState(selectedbugroup);
  const [tempSelectedupdatedby, setTempSelectedupdatedby] = useState(selectedUpdatedBy);
  const [tempSelectedcreatedby, setTempSelectedcreatedby] = useState(selectedCreatedBy);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20); // Default page size
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
 const [issourcetypePopupOpen, setIssourcetypePopupOpen] = useState(false); // Controls popup visibility
  const [sourcetypeall, setsourcetypeall] = useState([]); // For fetched company codes
  const [selectedsourcetype, setSelectedsourcetype] = useState([]);
  const [filteredsourcetypeall, setFilteredsourcetypeall] = useState([]);
  const [sourcetypeallearchQuery, setsourcetypeallearchQuery] = useState("");
  const [tempSelectedsourcetypeall, setTempSelectedsourcetypeall] = useState(selectedsourcetype);

  const [issourcecodePopupOpen, setIssourcecodePopupOpen] = useState(false); // Controls popup visibility
  const [sourcecodeall, setsourcecodeall] = useState([]); // For fetched company codes
  const [selectedsourcecode, setSelectedsourcecode] = useState([]);
  const [filteredsourcecodeall, setFilteredsourcecodeall] = useState([]);
  const [sourcecodeallearchQuery, setsourcecodeallearchQuery] = useState("");
  const [tempSelectedsourcecodeall, setTempSelectedsourcecodeall] = useState(selectedsourcecode);

  const [isourcodePopupOpen, setIsourcodePopupOpen] = useState(false); // Controls popup visibility
  const [ourcodeall, setourcodeall] = useState([]); // For fetched company codes
  const [selectedourcode, setSelectedourcode] = useState([]);
  const [filteredourcodeall, setFilteredourcodeall] = useState([]);
  const [ourcodeallearchQuery, setourcodeallearchQuery] = useState("");
  const [tempSelectedourcodeall, setTempSelectedourcodeall] = useState(selectedourcode);

  const [isdatatypePopupOpen, setIsdatatypePopupOpen] = useState(false); // Controls popup visibility
  const [datatypeall, setdatatypeall] = useState([]); // For fetched company codes
  const [selecteddatatype, setSelecteddatatype] = useState([]);
  const [filtereddatatypeall, setFiltereddatatypeall] = useState([]);
  const [datatypeallearchQuery, setdatatypeallearchQuery] = useState("");
  const [tempSelecteddatatypeall, setTempSelecteddatatypeall] = useState(selectedsourcetype);
  const columns = [
  //   {
  //     Header: <TbFlag3Filled style={{ color: "#ffff" }} />,
  //     accessor: "status",
  //     visible: true,
  //     width: 50,
  //  Cell: ({ value }) => {
  //          switch (value) {
  //            case 4:
  //              return <TbFlag3Filled style={{ color: "#29CB00" }} />; 
  //            case 0:
  //              return <TbFlag3Filled style={{ color: "gray" }} />; 
  //              case 1:
  //                return <TbFlag3Filled style={{ color: "#AF52DE" }} />;
  //            case 2:
  //              return <TbFlag3Filled style={{ color: "yellow" }} />; 
  //            case 3:
  //              return <TbFlag3Filled style={{ color: "orange" }} />; 
  //            case 5:
  //              return <TbFlag3Filled style={{ color: "red" }} />; 
               
  //            default:
  //              return <TbFlag3Filled style={{ color: "black" }} />; 
  //          }
  //        },
  //   },
    { Header: "Source Type", accessor: "source", visible: true, width:("Source Type".length -2)*10, alignment: "left" },
    {
      Header: "Source Company Name",
      accessor: "sourceCompanyName",
      visible: true,
      width:("Source Company Name".length -2)*10,
      alignment: "left"

    },
    { Header: "Source Code", accessor: "sourceCode", visible: true, width:("Source Code".length -2)*10, alignment: "left" },
    { Header: "Source Code Description", accessor: "sourceCodeDescription", visible: true, width:("Source Code Description".length -2)*10, alignment: "left" },
    { Header: "Our Code", accessor: "ourCode", visible: true, width:("Our Code".length -2)*10, alignment: "left" },
    { Header: "Our Code Description", accessor: "ourCodeDescription", visible: true, width:("Our Code Description".length -2)*10, alignment: "left" },
    { Header: "Rate Type", accessor: "rateType", visible: true, width:("Rate Type".length -2)*10, alignment: "left" },
    { Header: "Ratio", accessor: "ratio", visible: true, width:("Ratio".length -2)*10, alignment: "left" },
    { Header: "Data Type", accessor: "dataType", visible: true, width:("Data Type".length -2)*10, alignment: "left" },
    { Header: "MD Type", accessor: "mdType", visible: true, width:("MD Type".length -2)*10, alignment: "left" },
    { Header: "Created By", accessor: "created_by", visible: true, width:("Created By".length -2)*10, alignment: "left" },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width:("Created Date".length -2)*10,
      canResize: true,
            Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width:("Created Time".length -2)*10,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",

          hour12: true,
        });
      },
    },
    {
      Header: "Updated By",
      accessor: "changed_by",
      visible: true,
      width:("Updated By".length -2)*10,
      canResize: true,
      alignment: "left"
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width:("Updated Date".length -2)*10,
      canResize: true,
            Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width:("Updated Time".length -2)*10,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',

          hour12: true,
        });
      }
    },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));
  useEffect(() => {
    fetchSource();
    fetchAssignee();
    fetchDatatypes();
    fetchSourceCodes();
    fetchourCodes();
    fetchSourceTypes();
    fetchCreatedbydata();
    fetchupdatedbydata();
  }, []);


  useEffect(() => {
    if (sourcetypeallearchQuery.trim() === "") {
      setFilteredsourcetypeall(sourcetypeall); // Show all if search query is empty
    } else {
      const filtered = sourcetypeall.filter((code) =>
        code.source
          .toLowerCase()
          .includes(sourcetypeallearchQuery.toLowerCase())
      );
      setFilteredsourcetypeall(filtered);
    }
  }, [sourcetypeallearchQuery, sourcetypeall]);

  useEffect(() => {
    if (sourcecodeallearchQuery.trim() === "") {
      setFilteredsourcecodeall(sourcecodeall); // Show all if search query is empty
    } else {
      const filtered = sourcecodeall.filter((code) =>
        code.sourceCode
          .toLowerCase()
          .includes(sourcecodeallearchQuery.toLowerCase())
      );
      setFilteredsourcecodeall(filtered);
    }
  }, [sourcecodeallearchQuery, sourcecodeall]);

  useEffect(() => {
    if (ourcodeallearchQuery.trim() === "") {
      setFilteredourcodeall(ourcodeall); // Show all if search query is empty
    } else {
      const filtered = ourcodeall.filter((code) =>
        code.ourCode
          .toLowerCase()
          .includes(ourcodeallearchQuery.toLowerCase())
      );
      setFilteredourcodeall(filtered);
    }
  }, [ourcodeallearchQuery, ourcodeall]);

  useEffect(() => {
    if (datatypeallearchQuery.trim() === "") {
      setFiltereddatatypeall(datatypeall); // Show all if search query is empty
    } else {
      const filtered = datatypeall.filter((code) =>
        code.dataType
          .toLowerCase()
          .includes(datatypeallearchQuery.toLowerCase())
      );
      setFiltereddatatypeall(filtered);
    }
  }, [datatypeallearchQuery, datatypeall]);

  useEffect(() => {
    if (createdbydatasearchQuery.trim() === "") {
      setFilteredcreatedbydatas(createdbydatas); // Show all if search query is empty
    } else {
      const filtered = createdbydatas.filter((code) =>
        code.created_by
          .toLowerCase()
          .includes(createdbydatasearchQuery.toLowerCase())
      );
      setFilteredcreatedbydatas(filtered);
    }
  }, [createdbydatasearchQuery, createdbydatas]);
  useEffect(() => {
    if (updatedbysearchquery.trim() === "") {
      setfilteredupdatedbydata(updatedbydata); // Show all if search query is empty
    } else {
      const filtered = updatedbydata.filter((code) =>
        code.changed_by
          .toLowerCase()
          .includes(updatedbysearchquery.toLowerCase())
      );
      setfilteredupdatedbydata(filtered);
    }
  }, [updatedbysearchquery, updatedbydata]);

  const fetchSource = async (page = 1, searchQuery = "") => {
    setLoading(true);
    try {
      const response = await SourceServices.getAllSourcewithPage(
        pageSize,
        page,
        searchQuery
      );
      let { source, totalCount } = response.data.data;
      if (!source) {
        source = [];
      } else if (!Array.isArray(source)) {
        source = [source];
      }
      setSource(source);
      setFilteredSource(source);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching StateProvince data", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced fetch function (to avoid too many API calls)
  const debouncedFetchStateProvinces = useCallback(
    debounce((page, query) => {
      fetchSource(page, query);
    }, 500), // 500ms debounce delay
    []
  );

  // UseEffect to trigger API call on query or page change
  useEffect(() => {
    debouncedFetchStateProvinces(currentPage, searchQuery);
    // Cleanup debounce on unmount
    return () => debouncedFetchStateProvinces.cancel();
  }, [currentPage, searchQuery, debouncedFetchStateProvinces]);
  const fetchSourceTypes = async () => {
    setLoading(true);
    try {
      const response = await SourceServices.getAllSource(`/getsourcetypes`);
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setsourcetypeall(responseData); // assuming responseData contains an array of legal entities
        setFilteredsourcetypeall(responseData);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchSourceCodes = async () => {
    setLoading(true);
    try {
      const response = await SourceServices.getAllSource(`/getsourcecodes`);
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setsourcecodeall(responseData); // assuming responseData contains an array of legal entities
        setFilteredsourcecodeall(responseData);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };


  const fetchourCodes = async () => {
    setLoading(true);
    try {
      const response = await SourceServices.getAllSource(`/getourcodes`);
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setourcodeall(responseData); // assuming responseData contains an array of legal entities
        setFilteredourcodeall(responseData);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };


  const fetchDatatypes = async () => {
    setLoading(true);
    try {
      const response = await SourceServices.getAllSource(`/getdatatypes`);
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setdatatypeall(responseData); // assuming responseData contains an array of legal entities
        setFiltereddatatypeall(responseData);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchSource(1, searchQuery); // Fetch data with updated page size
  };
  const fetchCreatedbydata = async () => {
    setLoading(true);
    try {
      const response = await BuGroupServices.getAllBuGroup(`/getsourcecreatedby`);
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setcreatedbydatas(responseData); // assuming responseData contains an array of legal entities
        setFilteredcreatedbydatas(responseData);

      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchupdatedbydata = async () => {
    setLoading(true);
    try {
      const response = await BuGroupServices.getAllBuGroup(`/getsourceupdatedby`);
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData && Array.isArray(responseData)) {
        setupdatedbydata(responseData); // assuming responseData contains an array of legal entities
        setfilteredupdatedbydata(responseData);

      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching legal entities: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  // Apply Filters

   useEffect(() => {
      if (assignee.assigner) {
        console.log("Assigner ID has changed:", assignee.assigner);
        fetchUserPermissionDetails();
      }
    }, [assignee.assigner]);

  
const fetchUserPermissionDetails = async () => {
  try {
    const data = { userId: assignee.assigner };
    console.log("Request Data:", data);

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
      data
    );
    console.log("user permissions details", response);
    const responseData = response.data;
    setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
    console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
  } catch (error) {
    console.error(error.message); // Set error message
  } finally {
    setLoading(false); // Reset loading state
  }
};


  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  // Function to delete calendar

  // Function to delete BuGroup(s)  
  // const handleDelete = async () => {
  //   if (selectedRows.length === 0) return; // No rows selected to delete
  //   setShowConfirmation(true); // Show the confirmation dialog
  // };

  // const confirmDeletion = async () => {
  //   setShowConfirmation(false); // Close the dialog
  //   setLoading(true);
  //   try {
  //     for (const id of selectedRows) {
  //       const response = await BuGroupServices.deleteBuGroup(id);


  //       if (response.status !== 200) {
  //         toast("error", `Bu Group  cannot be deleted due to its status.`);
  //       }
  //       if (response.status === 200) {
  //         toast("success", "Selected Bugroup deleted successfully");
  //       }
  //     }
  //     // Optionally show success toast
  //     // toast("success", "Selected BuGroup(s) deleted successfully");
  //     setSelectedRows([]); // Clear selection
  //     fetchBuGroups(); // Refresh the list
  //   } catch (error) {
  //     console.error("Error deleting BuGroup:", error);
  //     // Optionally show error toast
  //     // toast("error", "Failed to delete BuGroup(s)");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const cancelDeletion = () => {
  //   setShowConfirmation(false); // Close the dialog
  // };
  // const handleApproveClick = async () => {
  //   if (selectedRows.length > 0) {
  //     try {
  //       // Assuming selectedRows contains the selected  Bu Group IDs

  //       // Pass the array of selected IDs to the approval service
  //       const response = await BuGroupServices.saveapproved(
  //         `${process.env.REACT_APP_BASE_URL}/approvebugroup`,
  //         { id: selectedRows, created_by: assignee.assigner } // Pass selectedBu GroupIds in the body
  //       );

  //       console.log("Response status:", response);

  //       if (response.status === 200) {
  //         toast("success", "Bu Group successfully Approved");

  //         // Success logic, refresh the  Bu Groupes
  //         fetchBuGroups();
  //       } else if (response.status === 500) {
  //         toast("warning", "Bu Group is unable to approve.");
  //       } else {
  //         console.log("Error updating Bu Groups.");
  //       }
  //     } catch (error) {
  //       console.error("Error approving  Bu Groupes:", error);
  //     }
  //   } else {
  //     console.log("Please select at least one  Bu Group to approve.");
  //   }
  // };

  // const handleRejectClick = async () => {
  //   if (selectedRows.length > 0) {
  //     try {
  //       // Assuming selectedRows contains the selected  Bu Group IDs

  //       // Pass the array of selected IDs to the approval service
  //       const response = await BuGroupServices.saveapproved(
  //         `${process.env.REACT_APP_BASE_URL}/rejectbugroup`,
  //         { id: selectedRows, created_by: assignee.assigner } // Pass selectedBu GroupIds in the body
  //       );

  //       console.log("Response status:", response.status);

  //       if (response.status === 200) {
  //         toast("error", "Bu Group successfully Rejected");
  //         fetchBuGroups();
  //       } else if (response.status === 500) {
  //         toast("warning", "Bu Group is unable to Reject.");
  //       } else {
  //         console.log("Error updating Bu Groups.");
  //       }
  //     } catch (error) {
  //       console.error("Error approving  Bu Groupes:", error);
  //     }
  //   } else {
  //     console.log("Please select at least one  Bu Group to approve.");
  //   }
  // };
  // const handleInactiveClick = async () => {
  //   if (selectedRows.length > 0) {
  //     try {
  //       // Assuming selectedRows contains the selected  Bu Group IDs

  //       // Pass the array of selected IDs to the approval service
  //       const response = await BuGroupServices.saveapproved(
  //         `${process.env.REACT_APP_BASE_URL}/inactivebugroup`,
  //         { id: selectedRows, created_by: assignee.assigner } // Pass selectedBu GroupIds in the body
  //       );

  //       console.log("Response status:", response);

  //       if (response.status === 200) {
  //         toast("success", "Bu Group successfully Change Status.");
  //         fetchBuGroups();
  //       } else if (response.status === 400) {
  //         toast("warning", "The Bu Group cannot be deactivated at this time.");
  //       } else {
  //         console.log("Error approving  Bu Group.");
  //       }
  //     } catch (error) {
  //       console.error("Error approving  Bu Group:", error);
  //     }
  //   } else {
  //     console.log("Please select at least one  Bu Group to approve.");
  //   }
  // };
  // const handleAuditTrail = () => {
  //   if (selectedRows.length === 1) {
  //     const selectedUser = filteredBuGroup.find(
  //       (bu) => bu.id === selectedRows[0]
  //     );
  //     console.log("Selected Bu group  ", selectedUser);

  //     if (selectedUser) {
  //       // Show the selected user's details using toast
  //       // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

  //       // Navigate to the edit screen, passing the selected user as state
  //       navigate(`/audit-trail-bugroup/${8}/${selectedRows[0]}`);
  //     } else {
  //       toast("warning", "Selected user not found.");
  //     }
  //   } else {
  //     toast("warning", "Please select exactly one user to edit.");
  //   }
  // };

  // const handleAddCopyClick = () => {
  //   if (selectedRows.length === 1) {
  //     const selectedUser = filteredBuGroup.find(
  //       (bu) => bu.id === selectedRows[0]
  //     );
  //     console.log("Selected Bu group to copy:", selectedUser);

  //     if (selectedUser) {
  //       // Navigate to the add page with the selected user data
  //       navigate(`bugroup-add/${buGroupId}`, {
  //         state: { copiedData: selectedUser },
  //       });
  //     } else {
  //       toast("warning", "Selected user not found.");
  //     }
  //   } else {
  //     toast("warning", "Please select exactly one user to copy.");
  //   }
  // };
  const handleApplyFilter = async () => {
    try {
      const response = await BuGroupServices.getFilterBugroup(
        "/getfiltersource",
        filters
      );
      if (!response.error) {
        setFilteredSource(response.data);
        setHasFiltersApplied(true);

      } else {
        console.error("Error:", response.message);
      }
    } catch (error) {
      console.error("Error fetching filtered companies:", error);
    }
  };
  const handleAddcopyClick = () => {
    if (selectedRows.length === 1) {
      const selectedUser = filteredSource.find((rateid) => rateid.id === selectedRows[0]);
      console.log('Selected Bu group to copy:', selectedUser);

      if (selectedUser) {
        // Navigate to the add page with the selected user data
        navigate(`/source-code-add/${sourceId}`, { state: { copiedData: selectedUser } });
      } else {
        toast("warning", "Selected user not found.");
      }
    } else {
      toast("warning", "Please select exactly one user to copy.");
    }
  };
  // Extra buttons that appear when rows are selected
  const extraButtons = [
    // {
    //   icon: IoMdCheckmark, // Pass the component itself
    //   onClick: handleApproveClick,
    //   title: "Approve",
    //   disabled: selectedRows.length === 0,
    // },
    // {
    //   icon: IoMdClose,
    //   onClick: handleRejectClick,
    //   title: "Reject",
    //   disabled: selectedRows.length === 0,
    // },
    {
      icon: IoMdCreate,
      onClick: () => handleNavigate(`/source-code-add/${sourceId}`),
      title: "Edit Source",
      disabled: selectedRows.length === 0,
    },

    {
      icon: PiCopy,
      onClick: handleAddcopyClick,
      title: "Copy",
      disabled: selectedRows.length === 0,
    },
    // {
    //   icon: MdOutlineHistory,
    //   onClick: handleAuditTrail,
    //   title: "Audit Trail",
    //   disabled: selectedRows.length === 0,
    // },
    // {
    //   icon: IoMdTrash,
    //   onClick: handleDelete,
    //   title: "Delete",
    //   disabled: selectedRows.length === 0,
    // },
    // {
    //   icon: VscVmActive,
    //   onClick: handleInactiveClick,
    //   title: "Inactive/Active",
    //   disabled: selectedRows.length === 0,
    // },
  ];
  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredSource.map((source) => ({
      "Source Type": source.source,
      "Source Company Name": source.sourceCompanyName,
      "Source Code": source.sourceCode,
      "Source Code Description": source.sourceCodeDescription,
      "Our Code": source.ourCode,
      "Our Code Description": source.ourCodeDescription,
      "Rate Type": source.rateType,
      "Rati0": source.ratio,
      "MD Type": source.mdType,
     
      "Created By": source.created_by,
      "Created Date": source.creationDate
        ? new Date(source.creationDate).toLocaleDateString()
        : "",
      "Created Time": source.creationTime
        ? new Date(source.creationTime).toLocaleTimeString()
        : "",
      "Updated By": source.changed_by,
      "Updated Date": source.changedDate
        ? new Date(source.changedDate).toLocaleDateString()
        : "",
      "Updated Time": source.changedTime || "",
       
    }));

    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Source");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Source_Data.xlsx");
  };
  const handleReload = async () => {
    setLoading(true); // Start loading
    try {
      await fetchSource(); // Fetch users
    } catch (error) {
      console.error("Error reloading users:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const toggleFilterPopup = () => {
    setIsFilterPopupOpen(!isFilterPopupOpen);
  };
  // Configuring existing buttons
  const existingButtons = PrimaryActionsConfig.screen2.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add Source"
        : button.icon === GiSettingsKnobs
          ? "Filter"
          : button.icon === MdOutlineFileDownload
            ? "Download"
            : button.icon === MdOutlineFileUpload
              ? "Upload"
              : button.icon === IoSettingsOutline
                ? "Settings"
                : button.icon === IoMdRefresh
                  ? "Refresh"
                  : button.title || "",
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/source-code-add")
        : button.icon === MdOutlineFileDownload
          ? handleDownload
          : button.icon === IoSettingsOutline
            ? handleSettingsButtonClick
            : button.icon === IoMdRefresh
              ? handleReload
              : button.icon === GiSettingsKnobs
                ? toggleFilterPopup
                 : button.icon === MdOutlineFileUpload
                              ?() => handleNavigate("/2/master-data/14/source-upload")
                : button.onClick || (() => { }),
  }));

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );
  // Navigation helper function
  const handleNavigate = (path) => {
    navigate(path);
  };
  const handleDoubleClick = (id) => {
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );

    console.log("Check user permissions with iconId", hasPermission);

    if (hasPermission) {
    navigate(`/source-code-view/${id}`, { state: { mode: "view" } });
    console.log("Navigated to user view screen.");
  } else {
    console.log(
      "No permission with permissionsId === 3 and matching iconId, navigation skipped."
    );
  }
  };


  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

    setSelectedRows(updatedSelectedRows);
    setSourceId(id); // Set the selected calendar ID for navigation or further actions
    setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = source.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };
  if (loading) return <div></div>;
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Market Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Source") {
      navigate("/2/master-data/13/source-code");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  const handleFilterChange = (e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: e.target.value,
    }));
  };

  const handleFilterDateFrom = (e) => {
    console.log(filterType);
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_from: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_from: e.target.value,
      }));
    }
  };
  const handleFilterDateto = (e) => {
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_to: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_to: e.target.value,
      }));
    }
  };



  const handleResetFilters = () => {
    setFilters({
      source: [],
      sourceCode: [],
      ourCode: [],
      dataTypes: [],
      rateType: [],
      mdType: [],
      sourcetype: [],
      updated_by: "",
      updated_date_from: "",
      updated_date_to: "",
      created_by: "",
      created_date_from: "",
      created_date_to: "",
    });
    setHasFiltersApplied(false)

    setSelecteddatatype([]);
    setSelectedourcode([]);
    setSelectedsourcecode([]);
    setSelectedsourcetype([]);
    setSelectedCreatedBy([]);
    setSelectedUpdatedBy([]);
    // ithu apply click panni than rest pannanum na intha function call theva illa
    fetchSource();
  };

  const handlesourcetypeSelection = (sourcetype) => {
    setTempSelectedsourcetypeall((prev) => {
      // Add or remove sourcetype from temporary selection list
      return prev.includes(sourcetype)
        ? prev.filter((item) => item !== sourcetype) // Remove if already selected
        : [...prev, sourcetype]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handlesourcetypeReset = () => {
    setTempSelectedsourcetypeall([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplysourcetype = () => {
    setSelectedsourcetype(tempSelectedsourcetypeall); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      source: tempSelectedsourcetypeall, // Set the filter
    }));
    setIssourcetypePopupOpen(false); // Close the popup
  };


  const handlesourcecodeSelection = (sourcecode) => {
    setTempSelectedsourcecodeall((prev) => {
      // Add or remove sourcecode from temporary selection list
      return prev.includes(sourcecode)
        ? prev.filter((item) => item !== sourcecode) // Remove if already selected
        : [...prev, sourcecode]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handlesourcecodeReset = () => {
    setTempSelectedsourcecodeall([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplysourcecode = () => {
    setSelectedsourcecode(tempSelectedsourcecodeall); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      sourceCode: tempSelectedsourcecodeall, // Set the filter
    }));
    setIssourcecodePopupOpen(false); // Close the popup
  };

  const handleourcodeSelection = (ourcode) => {
    setTempSelectedourcodeall((prev) => {
      // Add or remove ourcode from temporary selection list
      return prev.includes(ourcode)
        ? prev.filter((item) => item !== ourcode) // Remove if already selected
        : [...prev, ourcode]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handleourcodeReset = () => {
    setTempSelectedourcodeall([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplyourcode = () => {
    setSelectedourcode(tempSelectedourcodeall); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      ourCode: tempSelectedourcodeall, // Set the filter
    }));
    setIsourcodePopupOpen(false); // Close the popup
  };


  const handledatatypeSelection = (datatype) => {
    setTempSelecteddatatypeall((prev) => {
      // Add or remove datatype from temporary selection list
      return prev.includes(datatype)
        ? prev.filter((item) => item !== datatype) // Remove if already selected
        : [...prev, datatype]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handledatatypeReset = () => {
    setTempSelecteddatatypeall([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplydatatype = () => {
    setSelecteddatatype(tempSelecteddatatypeall); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataType: tempSelecteddatatypeall, // Set the filter
    }));
    setIsdatatypePopupOpen(false); // Close the popup
  };



  const handleCreatedBySelection = (createdBy) => {
    setTempSelectedcreatedby((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(createdBy)
        ? prev.filter((item) => item !== createdBy) // Remove if already selected
        : [...prev, createdBy]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handleCreatedbyReset = () => {
    setTempSelectedcreatedby([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplyCreatedby = () => {
    setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      created_by: tempSelectedcreatedby, // Set the filter
    }));
    setIsCreatedByPopupOpen(false); // Close the popup
  };




  const handleUpdateBySelection = (changedby) => {
    setTempSelectedupdatedby((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(changedby)
        ? prev.filter((item) => item !== changedby) // Remove if already selected
        : [...prev, changedby]; // Add if not selected
    });
  };

  // Reset temporary selection when resetting popup
  const handleUpdatedbyReset = () => {
    setTempSelectedupdatedby([]); // Reset selection
  };

  // Apply filter and close the popup
  const handleApplyUpdatedby = () => {
    setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      updated_by: tempSelectedupdatedby, // Set the filter
    }));
    setIsUpdatedByPopupOpen(false); // Close the popup
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleToggle = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
  };
  const rateTypeOptions = [
    { value: "", label: "Select rateType " },
    { value: "SPT", label: "SPT" },
    { value: "FWD", label: "FWD" },
    { value: "SER", label: "SER" },
    { value: "LON", label: "LON" },

  ];
  const mdTypeOption = [
    { value: "", label: "Select mdType" },
    { value: "EXT", label: "EXT" },
    { value: "CHG", label: "CHG" },
    { value: "INT", label: "INT" },

  ];
  return (
    <div>
      <Header
        title={"Primary Data > Market Data > Source"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/2/master-data"}
      />

      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">

            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Source Code"
              maxLength={20}
            />


            <PrimaryActions
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              isExtraButtonsVisible={showExtraIcons}
              selectIds={selectedRows}
              icons={iconId}
              hasFiltersApplied={hasFiltersApplied}
            />
          </div>
          <div className="flex items-center mr-4">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
            onShowAll={handleShowAll}
            
          />
          
        </div>
        </div>

        <div>

        </div>
        <ReTable
          data={filteredSource}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      {isFilterPopupOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50">
      <div className="flex justify-between items-center mt-2">
        <h2 className="text-customBlue font-semibold mb-5">
          Apply Filters
        </h2>
        <HoverButton
          onClick={handleResetFilters}
          title={"Reset Filter"}
        />
      </div>

      {/* Grid Layout */}
      <div className="grid grid-cols-2 gap-4">
        {/* Left-Side Single Column Fields */}
        <div className="col-span-2 sm:col-span-1">
        <div className="flex gap-4">
          <div className="flex items-center mb-2">
            <label className="text-customGray text-xs mr-2 w-[140px] ">
              Source Type:
            </label>
            <button
              className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
              onClick={() => {
                setTempSelectedsourcetypeall(selectedsourcetype);
                setIssourcetypePopupOpen(true);
                // Reset filteredsourcetype to show all groups when the popup is opened
              }}
            >
              <span className="truncate ">
                {selectedsourcetype.length > 0
                  ? selectedsourcetype.join(", ")
                  : "Source Type"}
              </span>
              <RxDropdownMenu className="ml-2 text-lg" />
            </button>
          </div>

          <CheckboxPopup
  title="Select SourceType "
  isOpen={issourcetypePopupOpen}
  onClose={() => setIssourcetypePopupOpen(false)}
  onSave={handleApplysourcetype}
  onReset={handlesourcetypeReset}
  searchPlaceholder="Search sourcetype "
  searchQuery={sourcetypeallearchQuery}
  onSearchChange={(e) => setsourcetypeallearchQuery(e.target.value)}
  items={filteredsourcetypeall.map((code) => ({
    id: code.source,
    name: code.source,
  }))}
  selectedItems={tempSelectedsourcetypeall}
  onItemToggle={handlesourcetypeSelection}
/>

<div className="flex items-center mb-2">
            <label className="text-customGray text-xs mr-2 w-[140px]">
              Rate Type:
            </label>
           
      

            <CustomDropdown
  value={filters.rateType[0] || ""} // Pass the first element of the array (or empty string)
  options={rateTypeOptions}
  onChange={(value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      rateType: value ? [value] : [], // Ensure it's an array
    }));
  }}
  isOpen={openDropdown === "rateType"}
  onToggle={() => handleToggle("rateType")}
  width="w-[165px]"
  placeholder="Select rate Type"
/>
                          </div>
</div>
        <div className="flex gap-4">
          <div className="flex items-center mb-2">
            <label className="text-customGray text-xs mr-2 w-[140px]">
              Source Code:
            </label>
            <button
              className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
              onClick={() => {
                setTempSelectedsourcecodeall(selectedsourcecode);
                setIssourcecodePopupOpen(true);
                // Reset filteredsourcecode to show all groups when the popup is opened
              }}
            >
              <span className="truncate">
                {selectedsourcecode.length > 0
                  ? selectedsourcecode.join(", ")
                  : "Source Code"}
              </span>
              <RxDropdownMenu className="ml-2 text-lg" />
            </button>
          </div>

          <CheckboxPopup
  title="Select sourcecode "
  isOpen={issourcecodePopupOpen}
  onClose={() => setIssourcecodePopupOpen(false)}
  onSave={handleApplysourcecode}
  onReset={handlesourcecodeReset}
  searchPlaceholder="Search sourcecode "
  searchQuery={sourcecodeallearchQuery}
  onSearchChange={(e) => setsourcecodeallearchQuery(e.target.value)}
  items={filteredsourcecodeall.map((code) => ({
    id: code.sourceCode,
    name: code.sourceCode,
  }))}
  selectedItems={tempSelectedsourcecodeall}
  onItemToggle={handlesourcecodeSelection}
/>

<div className="flex items-center mb-2">
            <label className="text-customGray text-xs mr-2 w-[140px]">
              MD Type:
            </label>
           
      
            <CustomDropdown
  value={filters.mdType[0] || ""} // Pass the first element of the array (or empty string)
  options={mdTypeOption}
  onChange={(value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      mdType: value ? [value] : [], // Ensure it's an array
    }));
  }}
  isOpen={openDropdown === "mdType"}
  onToggle={() => handleToggle("mdType")}
  width="w-[165px]"
  placeholder="Select Md Type"
/>
                          </div>
</div>
        <div className="flex gap-4">
          <div className="flex items-center mb-2">
            <label className="text-customGray text-xs mr-2 w-[140px]">
              Our code:
            </label>
            <button
              className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
              onClick={() => {
                setTempSelectedourcodeall(selectedourcode);
                setIsourcodePopupOpen(true);
                // Reset filteredourcode to show all groups when the popup is opened
              }}
            >
              <span className="truncate">
                {selectedourcode.length > 0
                  ? selectedourcode.join(", ")
                  : "Our Code"}
              </span>
              <RxDropdownMenu className="ml-2 text-lg" />
            </button>
          </div>

          <CheckboxPopup
  title="Select ourcode "
  isOpen={isourcodePopupOpen}
  onClose={() => setIsourcodePopupOpen(false)}
  onSave={handleApplyourcode}
  onReset={handleourcodeReset}
  searchPlaceholder="Search ourcode "
  searchQuery={ourcodeallearchQuery}
  onSearchChange={(e) => setourcodeallearchQuery(e.target.value)}
  items={filteredourcodeall.map((code) => ({
    id: code.ourCode,
    name: code.ourCode,
  }))}
  selectedItems={tempSelectedourcodeall}
  onItemToggle={handleourcodeSelection}
/>

<div className="flex items-center mb-2">
            <label className="text-customGray text-xs mr-2 w-[140px]">
              Data Type:
            </label>
            <button
              className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
              onClick={() => {
                setTempSelecteddatatypeall(selecteddatatype);
                setIsdatatypePopupOpen(true);
                // Reset filtereddatatype to show all groups when the popup is opened
              }}
            >
              <span className="truncate">
                {selecteddatatype.length > 0
                  ? selecteddatatype.join(", ")
                  : "Data Type"}
              </span>
              <RxDropdownMenu className="ml-2 text-lg" />
            </button>
          </div>

          <CheckboxPopup
  title="Select datatype "
  isOpen={isdatatypePopupOpen}
  onClose={() => setIsdatatypePopupOpen(false)}
  onSave={handleApplydatatype}
  onReset={handledatatypeReset}
  searchPlaceholder="Search datatype "
  searchQuery={datatypeallearchQuery}
  onSearchChange={(e) => setdatatypeallearchQuery(e.target.value)}
  items={filtereddatatypeall.map((code) => ({
    id: code.dataType,
    name: code.dataType,
  }))}
  selectedItems={tempSelecteddatatypeall}
  onItemToggle={handledatatypeSelection}
/>
</div>
          <div className="flex items-center">
            {/* Dropdown for selecting 'Created By' or 'Updated By' */}
            <select
              name="filterType"
              value={filterType} // State to track the selected filter option
              onChange={(e) => {
                setFilterType(e.target.value); // Update the selected filter
              }}
              className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[132px] h-[40px] focus:outline-none hover:border-blue-400"
            >
              <option value="1">Created By</option>
              <option value="2">Updated By</option>
            </select>

            {/* Button to open the popup */}
            <button
              className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-4"
              onClick={() => {
                if (filterType === "1") {
                  setIsCreatedByPopupOpen(true);
                  setTempSelectedcreatedby(selectedCreatedBy);// Open the Created By popup
                } else if (filterType === "2") {
                  setIsUpdatedByPopupOpen(true); // Open the Updated By popup
                  setTempSelectedupdatedby(selectedUpdatedBy); // Set the selected data correctly before opening the popup
                }

              }}
              disabled={!filterType} // Disable button if no filter is selected
            >
              <span className="truncate">
                {filterType === "1"
                  ? selectedCreatedBy.length > 0
                    ? selectedCreatedBy.join(", ")
                    : "Created By"
                  : filterType === "2"
                    ? selectedUpdatedBy.length > 0
                      ? selectedUpdatedBy.join(", ")
                      : "Updated By"
                    : "Select Filter"}
              </span>
              <RxDropdownMenu className="ml-2 text-lg" />
            </button>
          </div>
          <CheckboxPopup
  title="Select Created By "
  isOpen={isCreatedByPopupOpen}
  onClose={() => setIsCreatedByPopupOpen(false)}
  onSave={handleApplyCreatedby}
  onReset={handleCreatedbyReset}
  searchPlaceholder="Search Created By "
  searchQuery={createdbydatasearchQuery}
  onSearchChange={(e) => setcreatedbydatasearchQuery(e.target.value)}
  items={filteredcreatedbydatas
    .sort((a, b) => a.created_by.localeCompare(b.created_by))

  .map((code) => ({
    id: code.created_by,
    name: code.created_by,
  }))}
  selectedItems={tempSelectedcreatedby}
  onItemToggle={handleCreatedBySelection}
/>
        

<CheckboxPopup
  title="Select Updated By "
  isOpen={isUpdatedByPopupOpen}
  onClose={() => setIsUpdatedByPopupOpen(false)}
  onSave={handleApplyUpdatedby}
  onReset={handleUpdatedbyReset}
  searchPlaceholder="Search Updated By "
  searchQuery={updatedbysearchquery}
  onSearchChange={(e) => setupdatedbysearchquery(e.target.value)}
  items={filteredupdatedbydata
    .sort((a, b) => a.changed_by.localeCompare(b.changed_by))
  .map((code) => ({
    id: code.changed_by,
    name: code.changed_by,
  }))}
  selectedItems={tempSelectedupdatedby}
  onItemToggle={handleUpdateBySelection}
/>
        </div>
        {filterType && (
          <div className="col-span-2 flex gap-4 items-center">
            <div className="flex items-center">
              <label className="text-customGray text-xs mr-2 w-[140px]">
                {filterType === "createdBy"
                  ? " Date From:"
                  : " Date From:"}
              </label>
              <input
                type="date"
                name="dateFrom"
                value={
                  filterType === "1"
                    ? filters.created_date_from
                    : filters.updated_date_from
                }
                onChange={(e) => handleFilterDateFrom(e)}
                className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
              />
            </div>
            <div className="flex items-center">
              <label className="text-customGray text-xs mr-2 w-[140px]">
                {filterType === "createdBy" ? " Date To:" : " Date To:"}
              </label>
              <input
                type="date"
                name="dateTo"
                value={
                  filterType === "1"
                    ? filters.created_date_to
                    : filters.updated_date_to
                }
                onChange={(e) => handleFilterDateto(e)}
                className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
              />
            </div>
          </div>
        )}
      </div>

      {/* Buttons */}
      <div className="mt-6 flex justify-end space-x-3">
        <HoverButton
          onClick={toggleFilterPopup}
          title={"Cancel"}
        />
        <CustomButton
          title={"Apply"}
          onClick={() => {
            handleApplyFilter();
            toggleFilterPopup();
          }}

        />
      </div>
    </div>
  </div>
)}
      
      {/* <ConfirmationDialog
        open={showConfirmation}
        title="Confirm Deletion"
        message="Are you sure you want to delete the selected BuGroup ?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      /> */}
    </div>
  );
}

























