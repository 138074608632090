import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BamButtonsConfigs } from '../../Components/BamButtonsConfig';
import BamActions from '../../Components/BamActions';
import ReTable from '../../../../../components/Table';
import { Checkbox } from '@mui/material';
import Header from '../../../../../components/Header';


export default function AccountStatus() {
  const navigate = useNavigate();

  const buttons = BamButtonsConfigs.screen1.map(button => ({
    ...button,
  }));

  const initialTableData = [
    { id: 1, status: 'Open', receiveMT940: false, ftout: false, ftin: false, cashAccount: false },
    { id: 2, status: 'Dormant', receiveMT940: false, ftout: false, ftin: false, cashAccount: false },
    { id: 3, status: 'Close', receiveMT940: false, ftout: false, ftin: false, cashAccount: false },
  ];

  const [tableData, setTableData] = useState(initialTableData);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length === tableData.length ? [] : tableData.map(row => row.id));
  };

  const handleRowDoubleClick = (id) => {
    console.log(`Row with ID ${id} was double clicked.`);
  };

  const columns = [
    { Header: 'SL No', accessor: 'id', width: 50 },
    { Header: 'Status', accessor: 'status', width: 100 },
    { Header: 'Receive MT940', accessor: 'receiveMT940', width: 100, Cell: ({ value }) => <Checkbox size="small" checked={value} readOnly /> },
    { Header: 'FTOUT', accessor: 'ftout', width: 100, Cell: ({ value }) => <Checkbox size="small" checked={value} readOnly /> },
    { Header: 'FTIN', accessor: 'ftin', width: 100, Cell: ({ value }) => <Checkbox size="small" checked={value} readOnly /> },
    { Header: 'Cash Accounting', accessor: 'cashAccount', width: 100, Cell: ({ value }) => <Checkbox size="small" checked={value} readOnly /> },
    { Header: 'Reconciliation', accessor: 'reconciliation', width: 100, Cell: ({ value }) => <Checkbox size="small" checked={value} readOnly /> },
    { Header: 'OD Calculation', accessor: 'odCalculation', width: 100, Cell: ({ value }) => <Checkbox size="small" checked={value} readOnly /> },
    { Header: 'Loan Repayments', accessor: 'loanRepayments', width: 100, Cell: ({ value }) => <Checkbox size="small" checked={value} readOnly /> },
    { Header: 'LC Repayments', accessor: 'lcRepayments', width: 100, Cell: ({ value }) => <Checkbox size="small" checked={value} readOnly /> },

  ];

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'BAM') {
      console.log("Navigating to /bam");
      navigate('/bam');
    } else if (crumb.title === 'Set Up') {
      console.log("Navigating to /bam");
      navigate('/bam');
    } 
    else if (crumb.title === 'Account Status') {
      console.log("Navigating to /account-status");
      navigate('/account-status');
    
  }else if (crumb.path) {
      navigate(crumb.path); 
    }
  };

  return (
    <div>

        <div>
        <Header title={"BAM > Set Up > Account Status"}
        onBreadcrumbClick={handleBreadcrumbClick}
        />

      </div>
     
      <div>
        <BamActions buttons={buttons} />
      </div>
      <div>
        <ReTable 
          data={tableData}
          columns={columns}
          onSelectRow={handleSelectRow}
          selectedRows={selectedRows}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleRowDoubleClick}
        />
      </div>

      <diiv>
      <p className="text-customBlue font-medium px-6">+ Add New</p>
      </diiv>
    </div>
  );
}
