import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import UserService from "../../../../../rest-services/UserServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CountryServices from "../../../../../rest-services/CountryServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import Tabs from "../../../../../components/Tabs";
import ButtonGroup from "../../../../../components/FooterButton";
import Footer from "../../../../../components/footer";
import CustomButton from "../../../../../components/CustomButton";
import LanguageServices from "../../../../../rest-services/LanguagesServices";

const AddCountry = () => {
  const { countryId } = useParams();
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [amountFormat, setAmountFormat] = useState("");
  const [language, setlanguage] = useState("");
  const [languages, setlanguages] = useState([]);

  const [dateFormat, setDateFormat] = useState("");
  const [thousandSeparator, setThousandSeparator] = useState("");
  const [decimalSeparator, setDecimalSeparator] = useState("");
  const [acceleratorForThousands, setAcceleratorForThousands] = useState("");
  const [acceleratorForLakhs, setAcceleratorForLakhs] = useState("");
  const [acceleratorForMillions, setAcceleratorForMillions] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const [activeTab, setActiveTab] = useState("addCountry");
  const navigate = useNavigate();
  const { toast } = useToast();
  const [errors, setErrors] = useState({
    thousand: false,
    decimal: false,
    same: false,
    timeFormat: false,
  });
  const [suffixForThousands, setSuffixForThousands] = useState("");
  const [suffixForLakhs, setSuffixForLakhs] = useState("");
  const [suffixForMillions, setSuffixForMillions] = useState("");
  const [errorThousands, setErrorThousands] = useState(false);
  const [errorLakhs, setErrorLakhs] = useState(false);
  const [errorMillions, setErrorMillions] = useState(false);
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === "view";

  useEffect(() => {
    if (countryId) {
      fetchCountryData(countryId);
    }
    fetchLanguages();
    fetchAssignee();
  }, [countryId]);

  const fetchLanguages = async () => {
    try {
      const response = await LanguageServices.getAllLanguages(
        `${process.env.REACT_APP_BASE_URL}/get-language`
      );
      if (response && response.data) {
        console.log("response data for language", response.data.data);
        setlanguages(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Language data", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!country) newErrors.country = "Country is required.";
    if (!countryName) newErrors.countryName = "Country Name is required.";
    // Add other field validations as needed

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }

    return Object.keys(newErrors).length === 0;
  };

  const fetchCountryData = async (id) => {
    try {
      const response = await CountryServices.getCountries(id);
      console.log(response.data); // Check this carefully
      if (response.data) {
        setCountry(response.data.country);
        setCountryName(response.data.countryName);
        setAmountFormat(response.data.amountFormat);
        setlanguage(response.data.language);
        setDateFormat(response.data.dateFormat);
        setThousandSeparator(response.data.thousandSeparator);
        setDecimalSeparator(response.data.decimalSeparator);
        setAcceleratorForThousands(response.data.acceleratorForThousands);
        setAcceleratorForLakhs(response.data.acceleratorForLakhs);
        setAcceleratorForMillions(response.data.acceleratorForMillions);
        setTimeFormat(response.data.timeFormat);
        setTimeZone(response.data.timeZone);
      } else {
        console.error("No data found");
      }
    } catch (error) {
      console.error("Failed to fetch country data", error);
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setAssignee({
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        });
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    const data = {
      countryId,
      country,
      countryName,
      amountFormat,
      language,
      dateFormat,
      thousandSeparator,
      decimalSeparator,
      acceleratorForThousands,
      acceleratorForLakhs,
      acceleratorForMillions,
      timeFormat,
      timeZone,
      changed_by: assignee.assigner,
      ...(countryId === null && { createdBy: assignee.assigner }),
    };

    try {
      let response;
      if (countryId) {
        // Updating the existing country
        response = await UserService.saveCountry(
          `${process.env.REACT_APP_BASE_URL}/countries`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new country
        response = await UserService.saveCountry(
          `${process.env.REACT_APP_BASE_URL}/countries`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          countryId
            ? "Country Updated successfully"
            : "Country Added successfully"
        );
        // Clear the form and navigate
        setCountry("");
        setCountryName("");
        navigate("/country"); // Redirect after saving
      } else {
        toast("error", "Error adding/updating Country");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating country: ${error.message}`,
      });
    }
  };

  const handleSaveAndContinue = () => {
    // Validate required fields
    const isValid = validateForm(); // Call your validation function

    if (isValid) {
      // Only change the tab if validation passes
      setActiveTab("userPreferences");
    } else {
      // Show an error message or handle the error state as needed
      toast("error", "Country code and country name are required.");
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleThousandChange = (e) => {
    const value = e.target.value;

    // Only allow '.' or ',' in the thousand separator field
    if (value === "" || value === "." || value === ",") {
      setThousandSeparator(value);
      setErrors((prevError) => ({ ...prevError, thousand: false })); // Reset thousand separator error

      // Check if thousand and decimal separators are the same
      if (value === decimalSeparator && value !== "") {
        setErrors((prevError) => ({ ...prevError, same: true }));
      } else {
        setErrors((prevError) => ({ ...prevError, same: false }));
      }
    } else {
      setErrors((prevError) => ({ ...prevError, thousand: true }));
    }
  };

  const handleDecimalChange = (e) => {
    const value = e.target.value;
    // Only allow '.' or ',' in the decimal separator field
    if (value === "" || value === "." || value === ",") {
      setDecimalSeparator(value);
      setErrors((prevError) => ({ ...prevError, decimal: false })); // Reset decimal separator error

      // Check if thousand and decimal separators are the same
      if (value === thousandSeparator && value !== "") {
        setErrors((prevError) => ({ ...prevError, same: true }));
      } else {
        setErrors((prevError) => ({ ...prevError, same: false }));
      }
    } else {
      setErrors((prevError) => ({ ...prevError, decimal: true }));
    }
  };

  const handleChangeforMillions = (e) => {
    const value = e.target.value;
    const numericPart = value.replace(/[^\d]/g, ""); // Remove non-numeric characters
    const suffixPart = value.replace(/\d/g, ""); // Extract suffix

    if (suffixPart === "" || suffixPart === "M") {
      setSuffixForMillions(suffixPart);
      console.log("Thousands:", numericPart, suffixPart);
      setErrorMillions(false);
    } else {
      setErrorMillions(true);
    }

    setAcceleratorForMillions(numericPart);
  };

  const handleChangeforLakhs = (e) => {
    const value = e.target.value;
    const numericPart = value.replace(/[^\d]/g, "");
    const suffixPart = value.replace(/\d/g, "");

    if (suffixPart === "" || suffixPart === "L") {
      setSuffixForLakhs(suffixPart);
      setErrorLakhs(false);
    } else {
      setErrorLakhs(true);
    }

    setAcceleratorForLakhs(numericPart);
  };

  const handleChangeforThousands = (e) => {
    const value = e.target.value;
    const numericPart = value.replace(/[^\d]/g, "");
    const suffixPart = value.replace(/\d/g, "");

    if (suffixPart === "" || suffixPart === "K" || suffixPart === "T") {
      setSuffixForThousands(suffixPart);
      setErrorThousands(false);
    } else {
      setErrorThousands(true);
    }

    setAcceleratorForThousands(numericPart); // Use numericPart here
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Global Master Data") {
      console.log("Navigating to /master-data");
      navigate("/master-data");
    } else if (crumb.title === "Country") {
      console.log("Navigating to /country");
      navigate("/country");
    } else if (crumb.title === "Primary Data") {
      console.log("Navigating to /country");
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const tabs = [
    { value: "addCountry", label: "Add Country" },
    { value: "userPreferences", label: "Country Preferences" },
  ];
  const nextToAddress = () => {
    setActiveTab("userPreferences");
  };
  return (
    <div className="min-h-screen flex flex-col">
      <Header
        title={`Primary Data > Global Master Data > Country > ${
          countryId ? (isViewMode ? "View" : "Edit") : "Add"
        }`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />

      {/* Tabs */}
      {/* <div className="ml-2 pl-8 pt-2 flex flex-row space-x-6 bg-customBlue">
        <h6
          className="font-small"
          style={tabStyle("addCountry")}
          onClick={() => handleTabClick("addCountry")}
        >
          Add Country
        </h6>
        <h6
          className="font-small"
          style={tabStyle("userPreferences")}
          onClick={() => handleTabClick("userPreferences")}
        >
          Country Preferences
        </h6>
      </div> */}

      {/* Content */}
      {/* Content */}
      <div className="flex-grow">
        {activeTab === "addCountry" && (
          <div className="flex flex-col space-y-12 col-span-1 p-10">
            {/* Country Field */}
            <label className="flex items-center">
              <span className="w-48 font-small text-gray-500">
                Country <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={country}
                onChange={(e) => {
                  const inputValue = e.target.value.toUpperCase();
                  if (inputValue.length <= 3) {
                    setCountry(inputValue);
                  }
                }}
                className={`border ${
                  errors.country ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
                required
                disabled={isViewMode}
              />
            </label>

            {/* Country Name Field */}
            <label className="flex items-center">
              <span className="w-48 font-small text-gray-500">
                Country Name <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
                className={`border ${
                  errors.countryName ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
                required
                disabled={isViewMode}
              />
            </label>
            <CustomButton onClick={nextToAddress} title={"Next"} />
          </div>
        )}

        {activeTab === "userPreferences" && (
          <div className="flex flex-col min-h-screen">
            <div className="flex-grow pl-8 pr-8 overflow-auto">
              <div className="grid grid-cols-2 gap-x-6 gap-y-6 mt-2">
                {/* Language Field */}
                <div className="flex flex-col space-y-6">
                  <label className="flex items-center text-customGray">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Language
                    </span>
                    <span className="ml-3">:</span>

                    <select
                      value={language}
                      onChange={(e) => setlanguage(e.target.value)}
                      className={`border border-gray-300 p-1 rounded w-72 h-8 ${
                        isViewMode
                          ? "text-gray-500 bg-gray-100"
                          : "text-customGray"
                      } focus:outline-none hover:border-blue-400 ml-4`}
                    >
                      <option value="">Select Language</option>
                      {languages.map((type) => (
                        <option key={type.id} value={type.LE}>
                          {type.LE}
                        </option>
                      ))}
                    </select>

                    {errors.language && (
                      <p className="text-red-500 text-sm ml-4">
                        {errors.language}
                      </p>
                    )}
                  </label>
                  <label className="flex items-center">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Amount Format
                    </span>
                    <span className="ml-3">:</span>
                    {/* <input
          type="text"
          value={amountFormat}
          onChange={(e) => setamountFormat(e.target.value)}

          className="border border-gray-300 p-1 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4"
        /> */}
                    <select
                      value={amountFormat}
                      onChange={(e) => setAmountFormat(e.target.value)}
                      className={`border border-gray-300 text-customGray p-1 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4`}
                      disabled={isViewMode}
                    >
                      <option value="">Select Amount Format</option>

                      <option value="'1,234,567.89">'1,234,567.89</option>
                      <option value="1.234.567,890">1.234.567,890</option>
                      <option value="12,34,567.890">12,34,567.890</option>
                    </select>
                  </label>

                  {/* Date Format Field */}
                  <label className="flex items-center">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Date Format
                    </span>
                    <span className="ml-3">:</span>
                    <select
                      value={dateFormat}
                      onChange={(e) => setDateFormat(e.target.value)}
                      className="border border-gray-300 text-customGray p-1 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4"
                      required
                      disabled={isViewMode}
                    >
                      <option value="">Select Date Format</option>
                      <option value="1">DD/MM/YYYY</option>
                      <option value="2">MM/DD/YYYY</option>
                      <option value="3">DD/MM/YY</option>
                      <option value="4">MM/DD/YY</option>
                      <option value="5">DD.MM.YYYY</option>
                      <option value="6">DDMMYY</option>
                    </select>
                  </label>

                  {/* Thousand Separator Field */}

                  <label className="flex items-center">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Thousand Separator
                    </span>
                    <span className="ml-3">:</span>
                    <input
                      type="text"
                      value={thousandSeparator}
                      onChange={handleThousandChange}
                      className={`border p-1.5 rounded w-72 h-8 focus:outline-none ml-4 ${
                        errors.thousand || errors.same
                          ? "border-red-500"
                          : "border-gray-300 hover:border-blue-400"
                      }`}
                      disabled={isViewMode}
                    />
                  </label>
                  {errors.thousand && (
                    <p className="text-red-500 text-sm mt-1">
                      Only '.' and ',' are allowed for thousand separator.
                    </p>
                  )}

                  {/* Decimal Separator Field */}
                  <label className="flex items-center mt-4">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Decimal Separator
                    </span>
                    <span className="ml-3">:</span>
                    <input
                      type="text"
                      value={decimalSeparator}
                      onChange={handleDecimalChange}
                      className={`border p-1.5 rounded w-72 h-8 focus:outline-none ml-4 ${
                        errors.decimal || errors.same
                          ? "border-red-500"
                          : "border-gray-300 hover:border-blue-400"
                      }`}
                      disabled={isViewMode}
                    />
                  </label>
                  {errors.decimal && (
                    <p className="text-red-500 text-sm mt-1">
                      Only '.' and ',' are allowed for decimal separator.
                    </p>
                  )}

                  {errors.same && (
                    <p className="text-red-500 text-sm mt-1">
                      Thousand and decimal separators cannot be the same.
                    </p>
                  )}

                  <label className="flex items-center">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Accelerator for Thousands
                    </span>
                    <span className="ml-3">:</span>
                    <input
                      type="text"
                      value={acceleratorForThousands}
                      onChange={(e) => {
                        const value = e.target.value.toUpperCase(); // Convert input to uppercase
                        // Only allow 'K' or 'T'
                        if (value === "K" || value === "T" || value === "") {
                          setAcceleratorForThousands(value); // Set the state if valid
                          setErrorThousands(false); // Reset the error state
                        } else {
                          setErrorThousands(true); // Set error state if invalid
                        }
                      }}
                      className={`border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${
                        errorThousands ? "border-red-500" : "border-gray-300"
                      }`}
                      disabled={isViewMode}
                    />
                  </label>
                  {errorThousands && (
                    <p className="text-red-500 text-sm mt-1">
                      Only uppercase 'K' or 'T' is allowed.
                    </p>
                  )}

                  <label className="flex items-center">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Accelerator for Lakhs
                    </span>
                    <span className="ml-3">:</span>
                    <input
                      type="text"
                      value={acceleratorForLakhs}
                      onChange={(e) => {
                        const value = e.target.value.toUpperCase(); // Convert input to uppercase
                        // Only allow 'L' or empty
                        if (value === "L" || value === "") {
                          setAcceleratorForLakhs(value); // Set the state if valid
                          setErrorLakhs(false); // Reset error if input is valid
                        } else {
                          setErrorLakhs(true); // Set error if input is invalid
                        }
                      }}
                      className={`border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${
                        errorLakhs ? "border-red-500" : "border-gray-300"
                      }`}
                      disabled={isViewMode}
                    />
                  </label>
                  {errorLakhs && (
                    <p className="text-red-500 text-sm mt-1">
                      Only uppercase 'L' is allowed.
                    </p>
                  )}

                  <label className="flex items-center">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Accelerator for Millions
                    </span>
                    <span className="ml-3">:</span>
                    <input
                      type="text"
                      value={acceleratorForMillions}
                      onChange={(e) => {
                        const value = e.target.value.toUpperCase(); // Convert input to uppercase
                        // Only allow 'M' or empty
                        if (value === "M" || value === "") {
                          setAcceleratorForMillions(value); // Set the state if valid
                          setErrorMillions(false); // Reset error if input is valid
                        } else {
                          setErrorMillions(true); // Set error if input is invalid
                        }
                      }}
                      className={`border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${
                        errorMillions ? "border-red-500" : "border-gray-300"
                      }`}
                      disabled={isViewMode}
                    />
                  </label>
                  {errorMillions && (
                    <p className="text-red-500 text-sm mt-1">
                      Only uppercase 'M' is allowed.
                    </p>
                  )}

                  {/* Time Format Field */}
                  <label className="flex items-center">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Time Format <span className="text-red-500 ml-1">*</span>
                    </span>
                    <span className="ml-3">:</span>
                    <select
                      value={timeFormat}
                      onChange={(e) => setTimeFormat(e.target.value)}
                      className={`border border-gray-300 text-customGray p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4
                      ${
                        errors.timeFormat
                          ? "border-red-500 hover:border-red-500"
                          : "border-gray-300 hover:border-blue-400"
                      }`}
                      disabled={isViewMode}
                    >
                      <option value="2">24 Hours</option>
                      <option value="1">12 Hours</option>
                      <option value="">Select Time Format</option>
                    </select>
                    {errors.timeFormat && (
                      <p className="text-red-500 text-sm ml-4">
                        {errors.timeFormat}
                      </p>
                    )}
                  </label>

                  {/* Time Zone Field */}
                  <label className="flex items-center">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Time Zone
                    </span>
                    <span className="ml-3">:</span>
                    <input
                      type="text"
                      value={timeZone}
                      onChange={(e) => setTimeZone(e.target.value)}
                      className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4"
                      disabled={isViewMode}
                    />
                  </label>
                </div>
                <div className="">
                  <div className="grid grid-cols-2 gap-x-8 gap-y-8 ">
                    <div className="flex flex-col space-y-6">
                      {/* Existing checkboxes */}
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500 whitespace-nowrap"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Dont want to receive System emails
                        </p>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Download the Data
                        </p>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Change the Cash flow code
                        </p>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Can Change the varient
                        </p>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Can Delete all records
                        </p>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Change the Accounting Code
                        </p>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Do Not Update Country
                        </p>
                      </label>

                      {/* New checkboxes */}
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Can Access Reports
                        </p>
                      </label>

                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                          disabled={isViewMode}
                        />
                        <p className="ml-4 font-small text-gray-600 whitespace-nowrap">
                          Can Export Data
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSave}
            onSaveContinue={handleSaveAndContinue}
          />
        )}
      </Footer>
    </div>
  );
};

export default AddCountry;
