// SearchContext.js
import { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [highlightInfo, setHighlightInfo] = useState(null);

  const clearHighlight = () => {
    setHighlightInfo(null);
  };

  return (
    <SearchContext.Provider value={{ highlightInfo, setHighlightInfo, clearHighlight }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);