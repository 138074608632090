import React, { useState, useEffect } from 'react';
import Header from '../../../../../components/Header';
import PrimaryActions from '../../Components/ActionButton';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdRefresh, IoMdTime, IoMdTrash } from 'react-icons/io';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { PrimaryActionsConfig } from '../../Components/ActionButtonConfig';
import LanguageServices from '../../../../../rest-services/LanguagesServices';
import { useToast } from '../../../../../components/toast/toast';
import { Checkbox } from '@mui/material';
import * as XLSX from 'xlsx'; // Import xlsx library
import { MdOutlineFileDownload } from 'react-icons/md';
import ColumnSettingsPopup from '../../Components/ColumnSettingPopup';
import ReusableTable from '../../Components/Table';
import CustomeUpLoader from '../../../../../components/CustomeLoader';
import ReTable from '../../../../../components/Table';
import SearchInput from '../../../../../components/SearchInput';

export default function LanguageScreen() {
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");


  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);

  
  const columns = [
    { Header: "LE", accessor: "LE", visible: true, width: 200 },
    { Header: "Description", accessor: "Description", visible: true, width: 200 },
    { Header: "Created by", accessor: "created_by", visible: true, width: 200 },
    {
      
        Header: "Created Date",
        accessor: "creationDate",
        visible: true,
        width: 200,
        canResize: true,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
        }
      },
      {
        Header: "Created Time",
        accessor: "creationTime", 
        visible: true,
        width: 200,
        canResize: true,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return date.toLocaleTimeString("en-GB", {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',

            hour12: true, 
          });
        }
      },
      { Header: "Updated By", accessor: "changed_by", visible: true, width: 200, canResize: true },
      {
        Header: "Updated Date",
        accessor: "changedDate",
        visible: true,
        width: 200,
        canResize: true,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
        }
      },
      {
        Header: "Updated Time",
        accessor: "changedTime", 
        visible: true,
        width: 200,
        canResize: true,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return date.toLocaleTimeString("en-GB", {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',

            hour12: true, 
          });
        }
      },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    const filtered = languages.filter((language) =>
      language.LE.toLowerCase().includes(searchQuery.toLowerCase()) 
    );
    setFilteredLanguages(filtered);
  }, [searchQuery, languages]);

  const fetchLanguages = async () => {
    setLoading(true);
    try {
      const response = await LanguageServices.getAllLanguages(`${process.env.REACT_APP_BASE_URL}/get-language`);
      if (response && response.data) {
        setLanguages(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching Language data', error);
    } finally {
      setLoading(false);
    }
  };
  const modifiedColumns = columnSettings.filter(col => col.visible).map(col => ({
    ...col,
    width: col.width // This should hold the updated width
  }));
  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };
  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleNavigate = (path) => {
    navigate(path);
  };

  // Move handleDownload before existingButtons
const handleDownload = () => {
  // Create an array of data to download
  const dataToExport = filteredLanguages.map((language) => ({
    "Language Code": language.LE,
    "Language Name": language.Description,
    "Created By": language.created_by,
    "Created Date": language.creationDate
      ? new Date(language.creationDate).toLocaleDateString()
      : "",
    "Created Time": language.creationTime
      ? new Date(language.creationTime).toLocaleTimeString()
      : "",
    "Changed By": language.changed_by,
    "Changed Date": language.changedDate
      ? new Date(language.changedDate).toLocaleDateString()
      : "",
    "Changed Time": language.changedTime
      ? new Date(language.changedTime).toLocaleTimeString()
      : "",
  }));

  // Create a new worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(dataToExport);

  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Languages");

  // Generate Excel file and trigger download
  XLSX.writeFile(workbook, "Languages_Data.xlsx");
};
const handleReload = async () => {
  setLoading(true);  // Start loading
  try {
    await fetchLanguages();  // Fetch users
  } catch (error) {
    console.error('Error reloading users:', error);
  } finally {
    setLoading(false);  // Stop loading
  }
};
const extraButtons = [
  { icon: IoMdCreate, onClick: () => selectedLanguageId && navigateToForm(selectedLanguageId) ,title:"Edit language",disabled: selectedRows.length === 0, }
];
// Now define existingButtons
const existingButtons = PrimaryActionsConfig.screen1.map((button) => ({
  ...button,
  title:
    button.icon === IoAddCircleOutline
      ? "Add Language"
      : button.icon === MdOutlineFileDownload
      ? "Download"
      : button.icon === IoSettingsOutline
      ? "Settings"
          : button.icon === IoMdRefresh
      ? "Refresh"
      : button.title || "", // If a title already exists, use it, otherwise empty
  onClick:
    button.icon === IoAddCircleOutline
      ? () => handleNavigate("/language-add")
      : button.icon === MdOutlineFileDownload 
      ? handleDownload
      : button.icon === IoSettingsOutline
      ? handleSettingsButtonClick
      : button.icon === IoMdRefresh
      ? handleReload
      : button.onClick || (() => {}),
}));
const actionButtons = existingButtons.concat(
  extraButtons.map((button) => ({
    ...button,
    disabled: selectedRows.length === 0, // Disable button if no users are selected
    onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
  }))
);



  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setSelectedLanguageId(id);
    setShowExtraIcons(updatedSelectedRows.length > 0);
  };

  const navigateToForm = (id) => {
    navigate(`/language-add/${id}`);
  };
  const handleDoubleClick = (id) => {
    navigate(`/language-view/${id}`, { state: { mode: 'view' } });  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = languages.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  if (loading) return <div></div>;
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'language') {
      console.log("Navigating to /country");
      navigate('/language');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); 
    }
  };
  return (
    <div>
    <div>
      <Header title={"Primary Data > Global Master Data > Language"}
      onBreadcrumbClick={handleBreadcrumbClick} />
      
      <div className="flex justify-between">
        <PrimaryActions
           buttons={actionButtons} 
           selectedCount={selectedRows.length} 
          isExtraButtonsVisible={showExtraIcons}
        />
        <div className="flex items-center">
        <p className="mr-4 text-customBlue">LE :</p>

        <SearchInput 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            placeholder="Search by LE" 
          />
        </div>
      </div>
      <ReTable
          data={filteredLanguages}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
    </div>
  );
}
