import RestService from "./RestServices";

const CurrencyRateServicesServices      = {
  getAllCurrencyRate: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching CurrencyRate data:", error);
      return { error: true, message: "Error fetching CurrencyRate data" };
    }
  },
  getCurrencyRateById: (id) => {
    return RestService.GetByIdData(`/get-currencyrate-id`, id);
  },
  deleteCurrencyRate:(id)=>{
return RestService.DeleteData('/delete-currencyrate',id);
  },
  getAllCurrencyRatewithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/get-currencyrate-page?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  addCurrencyRate: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding CurrencyRate:", error);
      return { error: true, message: "Error adding CurrencyRate" };
    }
  },
  checkDuplicateCurrencyRatecode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching CurrencyRate code:", error);
      return { error: true, message: "Error fetching CurrencyRate code" };
    }
  },
};

export default CurrencyRateServicesServices;      ;
