import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Custom eye icons

const PasswordField = ({
  label,
  placeholder,
  onChange,
  className,
  style,
  onBlur,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Toggle between showing and hiding the password
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {label && <label>{label}</label>}
      <input
        type={passwordVisible ? "text" : "password"}
        placeholder={placeholder}
        onChange={onChange}
        className={className}
        style={{ paddingRight: "40px", ...style }}
        autoComplete="off"
        onBlur={onBlur}
      />
      <span
        onClick={togglePasswordVisibility}
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
      >
        {passwordVisible ? <FaEye /> : <FaEyeSlash />}{" "}
        {/* Conditionally render icon */}
      </span>
    </div>
  );
};

export default PasswordField;
