import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices";
import { useToast } from "../../../../../components/toast/toast";
import UserService from "../../../../../rest-services/UserServices";
import { RxDropdownMenu } from "react-icons/rx";
import Header from "../../../../../components/Header";
export default function StateProvinceAdd() {
  const { statementId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [state, setState] = useState(""); // State
  const [stateName, setStateName] = useState(""); // State Name
  const [countryCode, setCountryCode] = useState(""); // Country Code
  const [countryName, setCountryName] = useState(""); // Country Name
  const [errors, setErrors] = useState({}); // Validation errors
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const [alert, setAlert] = useState(null); 
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const { toast } = useToast();
  const navigate = useNavigate(); 
useEffect(()=>{
  fetchcountry();
},[]);
  useEffect(() => {
    if (countries.length > 0 && statementId) {
      getDataForEdit();
    }
    fetchAssignee();
    
  }, [countries, statementId]);

  const getDataForEdit = async () => {
    try {
      const response = await StateProvinceServices.getStateProvince(statementId);
      setState(response.data.state);
      setStateName(response.data.State_Name);
      setCountryCode(response.data.Country_Code);
      
      // Set the country name based on the country code from the response
      const country = countries.find(c => c.country === response.data.Country_Code);
      
      if (country) {
        setCountryName(country.countryName);
        setSelectedCountry(country.countryName); // Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }
    } catch (error) {
      console.error("Error fetching state/province data:", error);
    }
  };
  

  const validateForm = () => {
    const newErrors = {};
    if (!state) newErrors.state = "State is required.";
    if (!stateName) newErrors.stateName = "State Name is required.";
    if (!countryCode) newErrors.countryCode = "Country Code is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      id: statementId,
      State: state,
      State_Name: stateName,
      Country_Code: countryCode,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/create-stateprovince`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      if (response.ok) {
        toast("success", "State/Province added successfully");
        setState(""); // Clear the form
        setStateName("");
        setCountryCode("");
        setCountryName(""); // Clear the country name as well
      } else {
        setAlert({
          severity: "error",
          message: result.message || "An error occurred.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error adding State/Province: ${error.message}`,
      });
    }
  };

  const fetchcountry = async () => {
    try {
      const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-country`);
      console.log("Fetched countries:", response.data.data); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleCountryCodeChange = (e) => {
    if(errors.countryCode){
      setErrors((prevErrors)=>({
        ...prevErrors,
        countryCode:"",
      }))
    }
    const selectedCode = e.target.value;
    console.log("Selected country code:", selectedCode); // Log the selected country code
    setCountryCode(selectedCode);

    const selectedCountry = countries.find((country) => country.country === selectedCode);
    if (selectedCountry) {
      setCountryId(selectedCountry.id); // Store country ID if needed
      setCountryName(selectedCountry.countryName); // Set the corresponding country name
    } else {
      setCountryName(""); // Clear the country name if no match is found
    }
  };


// const handleCheckboxChange = (event) => {
//   const selectedCode = event.target.value;
//   setSelectedCountry(selectedCode);
//   setCountryCode(selectedCode);
  
//   const selectedCountryData = countries.find(country => country.country === selectedCode);
//   if (selectedCountryData) {
//     setCountryName(selectedCountryData.countryName);
//   }
// };
const handleCheckboxChange = (event) => {
  const selectedName = event.target.value; // Change from value to country name
  setSelectedCountry(selectedName);
  
  // Find the selected country data by country name instead of code
  const selectedCountryData = countries.find(country => country.countryName === selectedName);
  if (selectedCountryData) {
    setCountryCode(selectedCountryData.country); // Set the country code based on the selected country name
    setCountryName(selectedCountryData.countryName);
  }
};
const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
    
      navigate('/master-data');
    } else if (crumb.title === 'State/Province') {
     
      navigate('/state-province');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  }
     else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
      <Header
        title={`Primary Data > Global Master Data > State/Province > ${statementId ? (isViewMode ? 'View' : 'Edit') : 'Add'}`
        }
        onBreadcrumbClick={handleBreadcrumbClick}
      />

<div className="flex flex-col space-y-12 col-span-1 p-10">
        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">State<span className="text-red-500 ml-1">*</span></span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={state}
            onChange={(e) => {
              setState(e.target.value);
              if (errors.state) {
                setErrors(prevErrors => ({ ...prevErrors, state: "" }));
              }
            }}
            className={`border ${errors.state ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
            required
            disabled={isViewMode}
          />
        </label>

        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">State Name<span className="text-red-500 ml-1">*</span></span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={stateName}
            onChange={(e) => {
              setStateName(e.target.value);
              if (errors.stateName) {
                setErrors(prevErrors => ({ ...prevErrors, stateName: "" }));
              }
            }}
            className={`border ${errors.stateName ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
            required
            disabled={isViewMode}
          />
        </label>

        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500" disabled={isViewMode}>Country Name <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
            onClick={toggleModal}
            className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span className="text-customGray">{selectedCountry || 'Select Country Name'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>

          {isModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden"> {/* Added rounded-lg for border-radius */}
      {/* Header */}
      <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
        <h2 className="text-lg font-semibold text-customBlue">Select Country</h2>
        <input
          type="text"
          placeholder="Search country..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue text-customGray"
          disabled={isViewMode}
        />
        <button onClick={toggleModal} className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-auto p-4">
        {countries.length > 0 ? (
          <div className="grid grid-cols-3 gap-4">
            {countries
              .filter((country) =>
                country.countryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                country.country.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((country, index) => (
                <div key={country.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                  <input
                    type="radio"
                    name="Country Name"
                    value={country.countryName} // Set the value to countryName
                    checked={selectedCountry === country.countryName} // Check based on countryName
                    onChange={handleCheckboxChange}
                    className="mr-2 text-blue-200"
                    disabled={isViewMode}
                  />
                  <label>{country.countryName}</label> {/* Display countryName */}
                </div>
              ))}
          </div>
        ) : (
          <p>Loading countries...</p>
        )}
      </div>

      {/* Footer */}
      {!isViewMode && (
      <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
        <button
          onClick={toggleModal}
          className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20 "
          disabled={isViewMode}
        >
          Save
        </button>
      </div>
    )}
    </div>
  </div>
)}

        </div>

        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Country Code</label>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={countryCode}
            readOnly
            className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none ml-8 text-customGray"
          />
        </div>
{(!isViewMode && (
  <button
          onClick={handleSubmit}
          className="mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
        >
          Save
        </button>
))}
       
      </div>
    </div>
  );
}
