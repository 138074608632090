import React, { useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../../components/footer';
import CustomDropdown from '../../../../components/CustomDropdown';
import StatementFormatServices from '../../../../rest-services/StatementFormatServices';
import { useToast } from '../../../../components/toast/toast';
import { use } from 'react';
import { RxDropdownMenu } from 'react-icons/rx';
import ReusableModal from '../../../../components/PopupComponent';
import CurrencyServicess from '../../../../rest-services/CurrencyServices';
import { set } from 'lodash';

export default function AddStatementFormat() {
    const navigate = useNavigate();
    const { toast } = useToast();
    const { actionId, statementFormatId } = useParams();
    const location = useLocation();
    const { mode } = location.state || {};

    const isViewMode = mode === "view";
    const isEditMode = mode === "edit";



    const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        statementType: "",
        description: "",
        statementFormat: "",
        formatDescription: "",
        country: "",

    });
    //Assingner
    const [assignee, setAssignee] = useState({
        clientId: "",
        id: "",
        assigner: "",
        userId: "",
    });



    useEffect(() => {
        fetchAssignee();
    }, [])
    useEffect(() => {
        if (statementFormatId) {
            fetchDataForField()
        }

    }, [assignee.clientId])
    const fetchDataForField = async () => {
        try {
            if (!assignee.clientId) return;

            const response = await StatementFormatServices.getStatementFormatByID(statementFormatId, assignee.clientId);

            if (response.status === 200) {
                const apiData = response.data.data;
                setFormData({
                    statementType: apiData.statement_type || "",
                    description: apiData.statement_description || "",
                    statementFormat: apiData.statement_format || "",
                    formatDescription: apiData.format_description || "",
                    country: apiData.country || "",
                });
                setSelectedCountry(apiData.country);

                console.log("Form data set successfully:", apiData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };




    const handleChange = (value, name) => {
        console.log(value, name);
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const fetchAssignee = async () => {
        try {
            const userDetailString = localStorage.getItem("userDetail");
            if (userDetailString) {
                const userDetail = JSON.parse(userDetailString);
                const assigneeData = {
                    clientId: userDetail.clientId || "",
                    id: userDetail.id || "",
                    assigner: userDetail.username || "",
                    userId: userDetail.userId || "",
                };
                setAssignee(assigneeData);
                await fetchcountry()
            } else {
            }
        } catch (error) { }
    };

    const [selectedCountry, setSelectedCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const validateEditForm = () => {

        const newErrors = {};
        if (!formData.statementFormat) newErrors.statementFormat = "StatementFormat is required.";
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            toast("error", "Missing Required Fields");
        }

        return Object.keys(newErrors).length === 0;
    };
    const handleEdit = async (e) => {
        try {
            e.preventDefault();
            if (!validateEditForm()) return;
            const response = await StatementFormatServices.editStatementFormat(formData, statementFormatId, assignee.clientId, assignee.userId);
            if (response.status === 200) {
                toast("success", "Statment Format Updated Successfully")
                navigate("/statement-formatt")
            }
        } catch (error) {

        }
    }

    const validateForm = () => {

        const newErrors = {};
        if (!formData.statementType) newErrors.statementType = "StatementType is required.";
        if (!formData.statementFormat) newErrors.statementFormat = "StatementFormat is required.";
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            toast("error", "Missing Required Fields");
        }

        return Object.keys(newErrors).length === 0; // Validation result
    };

    const handleSubmit = async (e, action) => {
        e.preventDefault();
        if (!validateForm()) return;
        const response = await StatementFormatServices.addStatementFormat(formData, assignee.clientId, assignee.userId)
        if (response.status === 200) {
            if (action === 2) {
                setFormData({
                    statementType: "",
                    description: "",
                    statementFormat: "",
                    formatDescription: "",
                    country: "",
                })
                toast("success", "Statment Format Added Successfully")


            } else {
                navigate("/statement-formatt")
                toast("success", "Statment Type Added Successfully")
            }
            setSelectedCountry("");
        } else {
            toast("error", `${response.message}`)

        }
    };
    const statmentFormatOptions = [
        { value: "940 WHF", label: "940 WHF" },
        { value: "940 HF", label: "940 HF" },
        { value: "Portal", label: "Portal" },
    ];
    const [openDropdown, setOpenDropdown] = useState(null);
    const handleToggle = (dropdownId) => {
        setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
    };


    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Statement Format") {
            navigate("/statement-formatt");
        } else if (crumb.title === "Setups") {
            navigate("/bank-statements");
        }
        else if (crumb.path) {
            navigate(crumb.path);
        }
    };


    const handleCountryReset = () => {
        setSearchQuery(''); // Reset search query
        setSelectedCountry(""); // Reset selected bank ID

    };
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleCountryCheckBoxChange = (event) => {

        const selectedName = event.target.value;
        setSelectedCountry(selectedName);
        handleChange(selectedName, "country")
    };

    const fetchcountry = async () => {
        try {
            const response = await CurrencyServicess.getAllCalendar(
                `${process.env.REACT_APP_BASE_URL}/get-country`
            );
            setCountries(response.data.data);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };
    return (
        <div>
            <Header title="Bank Statements > Setups > Statement Format > Add" onBreadcrumbClick={handleBreadcrumbClick} backRoute={"/statement-formatt"} />

            <div className="mb-6 mx-10 border border-gray-300 p-5 rounded-lg">
                <div className="mb-4 flex items-center ">
                    <label className="w-60 text-xs font-small text-customGray ">
                        Statement Type <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-3">:</span>
                    <input
                        type="text"
                        name="statementType"
                        value={formData.statementType}
                        onChange={(e) => handleChange(e.target.value, "statementType")}
                        className={`border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray text-xs focus:outline-none hover:border-blue-400 ml-4  text-customGray  ${errors.statementType
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                            }`} required
                        readOnly={isViewMode || isEditMode}
                    />
                </div>

                <div className="mb-4 flex items-center ">
                    <label className="w-60 text-xs font-small text-customGray ">Description</label>
                    <span className="ml-3">:</span>
                    <input
                        type="text"
                        name="description"
                        value={formData.description}
                        readOnly={isViewMode}
                        onChange={(e) => handleChange(e.target.value, "description")}
                        className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray text-xs focus:outline-none hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryCode
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                            }`} />
                </div>

                <div className="mb-4 flex items-center ">
                    <label className="w-60 text-xs font-small text-customGray ">
                        Statement Format <span className="text-red-500">*</span>
                    </label>
                    <span className="ml-3">:</span>
                    <CustomDropdown
                        value={
                            statmentFormatOptions.find(
                                (option) => option.value === formData.statementFormat
                            ) || null
                        }
                        error={errors.statementFormat}
                        options={statmentFormatOptions}
                        onChange={(label) => handleChange(label, "statementFormat")}
                        isOpen={openDropdown === "format"}
                        onToggle={() => handleToggle("format")}
                        className={` ml-4 ${errors.statementFormat
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                            } `}
                        placeholder="Select Statement Format"
                        width='w-80'
                        height='h-8'
                        isDisabled={isViewMode}
                    />

                </div>

                <div className="mb-4 flex items-center ">
                    <label className="w-60 text-xs font-small text-customGray ">Format Description</label>
                    <span className="ml-3">:</span>
                    <input
                        type="text"
                        readOnly={isViewMode}
                        name="formatDescription"
                        value={formData.formatDescription}
                        onChange={(e) => handleChange(e.target.value, "formatDescription")}
                        className={`border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none text-xs hover:border-blue-400 ml-4 text-customGray 
                            border-gray-300 hover:border-blue-400`
                        } />
                </div>

                <div className="mb-4 flex items-center ">
                    <label className="w-60 text-xs font-small text-customGray ">
                        Country
                    </label>
                    <span className="ml-3">:</span>
                    <button
                        onClick={() => setIsCountryModalOpen(true)}
                        disabled={isViewMode}
                        className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none text-xs hover:border-blue-400 ml-4 text-left text-customGray flex items-center justify-between ${errors.countryCode
                            ? "border-red-500 hover:border-red-500"
                            : "border-gray-300 hover:border-blue-400"
                            }`}
                    >
                        <span className="font-small text-customGray text-xs">
                            {selectedCountry || "Select Country Code"}
                        </span>
                        <RxDropdownMenu className="ml-2" />
                    </button>
                    <ReusableModal
                        title="Select Country Code"
                        isOpen={isCountryModalOpen}
                        onClose={() => {
                            setIsCountryModalOpen(false);
                            setSearchQuery("");
                        }}
                        onSave={() => {
                            setIsCountryModalOpen(false);
                            setSearchQuery("");
                        }}
                        showReset={true}
                        onReset={handleCountryReset}
                        searchPlaceholder="Search country..."
                        searchQuery={searchQuery}
                        onSearchChange={handleSearchChange}
                        isViewMode={isViewMode}
                    >
                        {countries.length > 0 ? (
                            countries
                                .filter((country) =>
                                    country.country
                                        .toLowerCase()
                                        .includes(searchQuery.toLowerCase())
                                )
                                .sort((a, b) => a.country.localeCompare(b.country)) // Sort in alphabetical order

                                .map((country) => (
                                    <div
                                        key={country.id}
                                        className="flex items-center"
                                    >
                                        <input
                                            type="radio"
                                            name="country"
                                            value={country.country}
                                            checked={selectedCountry === country.country}
                                            onChange={handleCountryCheckBoxChange}
                                            className="mr-2 accent-customBlue"
                                            disabled={isViewMode}
                                        />
                                        <label>{country.country}</label>
                                    </div>
                                ))
                        ) : (
                            <p>Loading countries...</p>
                        )}
                    </ReusableModal>

                </div>
            </div>
            <div> <Footer>
                {!isViewMode && <button
                    onClick={(e) => {
                        if (isEditMode) {
                            handleEdit(e)
                        } else { handleSubmit(e, 1) }
                    }}
                    className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
                >
                    Save
                </button>}
                {!(isEditMode || isViewMode) && <button
                    onClick={(e) => handleSubmit(e, 2)}
                    className="bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out ml-4 w-30"
                >
                    Save & Continue
                </button>
                }
            </Footer></div>
        </div>

    )
}

