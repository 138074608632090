import RestService from "./RestServices";
const basePath = "/btc";
const BtcServices = {
  saveBtcCode: (data) => {
    return RestService.CreateData(`${basePath}/create`, data);
  },
  getAllData: (pageSize, page, clientId) => {
    return RestService.GetAllData(
      `${basePath}/get-all-btc?limit=${pageSize}&offset=${(page - 1) * pageSize
      }`
    );
  },
  updateBtcCode: (id, data) => {
    return RestService.UpdateData(`${basePath}/update-btc-code?id=${id}`, data);
  },
  exportBTC: () => {
    return RestService.ExportData(
      `${basePath}/export-data`
    );
  },

  getBtcServiceBysearch: (searchQuery, pageSize, page) => {
    return RestService.GetAllData(
      `${basePath}/get-search-btc?searchQuery=${searchQuery}&limit=${pageSize}&offset=${(page - 1) * pageSize
      }`
    );
  },
  getBtcCodeForFields: (id) => {
    return RestService.GetAllData(
      `${basePath}/get-btc-code-fields?id=${id}`
    );
  },
  isBTCExist: (code) => {
    return RestService.GetAllData(
      `${basePath}/check-btc-code?btcCode=${code}`
    );
  }
};

export default BtcServices;
