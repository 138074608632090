import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PrimaryActions from '../../../Components/ActionButton';
import ReTable from '../../../../../../components/Table';
import { PrimaryActionsConfig } from '../../../Components/ActionButtonConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import XLSX from "xlsx-js-style";
import UserService from '../../../../../../rest-services/UserServices';
import CustomeLoading from '../../../../../../components/CustomeLoading';

export default function StateProvinceTableScreen() {
    const navigate = useNavigate();
 const location = useLocation();
  
    const { iconId } = useParams();
      const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
      const { tableData, displayOption, uploadOption  } = location.state || {}; // Retrieve tableData from state
const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processedData, setProcessedData] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [stateAndCountryCodes, setStateAndCountryCodes] = useState([]);
  const [stateCodes, setStateCodes] = useState([]);

    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === "Global Data") {
          navigate("/2/master-data");
        } else if (crumb.title === "State/Province") {
          navigate("/2/master-data/2/state-province");
        } else if (crumb.title === "Primary Data") {
          console.log("Navigating to /country");
          navigate("/2/master-data");
        } else if (crumb.path) {
          navigate(crumb.path); // Navigate to the path specified in the crumb
        }
      };

      useEffect(() => {
        fetchCountryCodes();
        fetchStateProvinceCode();
    }, []);

  const handleDownload = () => {
          if (processedData.length === 0) {
            setAlert({
              severity: "warning",
              message: "No data available to export!",
            });
            return;
          }
        
          const extractText = (cellValue) => {
            if (Array.isArray(cellValue)) {
              return cellValue.join(", "); // Convert array to string
            } else if (typeof cellValue === "string") {
              return cellValue;
            } else if (React.isValidElement(cellValue)) {
              return cellValue.props.children;
            }
            return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
          };
        
  const formattedUsers = processedData.map((user) => ({
    "Action": extractText(user.Action),
    "SLNo": extractText(user.SLNo),
    "StateCode": extractText(user.StateCode),
    "StateName": extractText(user.StateName),
    "CountryCode": extractText(user.CountryCode),
   
  }));


// Add second and third row
const secondRow = {
"Action": "R","SLNo": "R","StateCode":"R", "StateName": "R","CountryCode": "R"
};

const thirdRow = {
"Action": "1","SLNo": "5","StateCode":"3", "StateName": "30","CountryCode": "2"
};


    // Insert second and third row at the beginning of the formatted data
    const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];

        const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
      console.log("rowsToDownload data is",rowsToDownload);
 
        // Define border style
  const border = {
    top: { style: "thin", color: { rgb: "000000" } },
    right: { style: "thin", color: { rgb: "000000" } },
    bottom: { style: "thin", color: { rgb: "000000" } },
    left: { style: "thin", color: { rgb: "000000" } },
  };

      // Apply borders to the first three rows
      rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
        Object.keys(row).forEach((col, colIdx) => {
          const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = { border };
          }
        });
      });

      
       // Additional cell styling for validation errors
        Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
          rowsToDownload.forEach((row, rowIdx) => {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
            const cellValue = row[col];
      
            if (
              typeof cellValue === "string" && (
                cellValue.includes("Shouldn't be blank") 
                // ||
                // cellValue.includes("Either Address Section or Building, Street, and Area Section must be entered")
                // cellValue.includes("Max. allowed character")
              )
            ) {
              worksheet[cellAddress] = {
                v: cellValue,
                s: { font: { color: { rgb: "FF0000" } } },
              };
            } else if (Array.isArray(row[col])) {
              worksheet[cellAddress] = {
                v: extractText(row[col]),
                s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
              };
            }
            else {
              worksheet[cellAddress] = { v: cellValue };
            }
          });
        });
      
        // AutoFit column width
        worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
          const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
          return { wch: maxLength + 2 }; // Add extra space
        });


        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
        XLSX.writeFile(workbook, "State_Province_Upload.xlsx");
      };

          const existingButtons = PrimaryActionsConfig.screen6.map((button) => {
            let onClick;
            let title;
        
        
            if (button.icon === MdOutlineFileDownload) {
              onClick = handleDownload;
              title = "Download File";
            }
        
            else {
              onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
              title = button.title || ""; // Use existing title or default to an empty string
            }
        
            return {
              ...button,
              onClick, // Reference the function without invoking it
              title,   // Add the title property to the button configuration
            };
          });

  const extraButtons = [

  ];

      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, // Disable button if no users are selected
          onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
        }))
      );
      
       const columns = [   
                    
              { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -2)*10, alignment: "left" },
              { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -2)*10, alignment: "left" },
              // { Header: 'Update', accessor: '', visible: true, width: ("".length -2)*10, alignment: "left" },
              {
                            Header: 'Update',
                            accessor: (row) => {
                              if (row.successfulrecords && uploadOption === '3') {
                                return 'Yes';
                              } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                                return 'No';
                              } else {
                                return <span style={{ color: 'red' }}>No</span>;
                              }
                            },
                            visible: true,
                            width: ("Update".length -2)*10,
                            alignment: 'left',
                          },
              { Header: 'StateCode', accessor: 'StateCode', visible: true, width: ("StateCode".length -2)*10, alignment: "left" },
              { Header: 'StateName', accessor: 'StateName', visible: true, width: ("StateName".length -2)*10, alignment: "left" },
              { Header: 'CountryCode', accessor: 'CountryCode', visible: true, width: ("CountryCode".length -2)*10, alignment: "left" },
             
    ];


 const [columnSettings, setColumnSettings] = useState(columns);
      
      const modifiedColumns = columnSettings
      .filter((col) => col.visible)
      .map((col) => ({
        ...col,
        width: col.width, // This should hold the updated width
      }));
  
      const isRowValid = (row, index) => {
        // Common validations
        const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
        const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
        
        let errors = [];
        
        if (!isActionValid) errors.push("Invalid Action");
        if (!isSLNoValid) errors.push("Invalid SLNo");
      
        // Action "A" or "a" specific validations
        if (row.Action === "A" || row.Action === "a") {
          const isStateValid = row.StateCode && String(row.StateCode).length <= 3;
          const isCountryCodeValid = row.CountryCode && String(row.CountryCode).length <= 2;
         
          const isStateNameValid = row.StateName && String(row.StateName).length <= 30;

          if (!isStateNameValid) errors.push("Invalid State name");
          if (!isStateValid) errors.push("Invalid A State code");
          if (!isCountryCodeValid) errors.push("Invalid A Country code");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
          return (
            isStateNameValid &&
          isStateValid  &&
          isCountryCodeValid
          );
        }
      
        // Action "E" or "e" specific validations
        if (row.Action === "E" || row.Action === "e") {
          const isStateValid = row.StateCode && String(row.StateCode).length <= 3;

          const isCountryCodeValid = row.CountryCode && String(row.CountryCode).length <= 2;
          const isStateNameValid = row.StateName && String(row.StateName).length <= 30;

          
          if (!isStateValid) errors.push("Invalid A State code");
          if (!isStateNameValid) errors.push("Invalid Country name");
          if (!isCountryCodeValid) errors.push("Invalid E Country code");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
  
          return (
            isStateValid &&
            isStateNameValid &&
            isCountryCodeValid   );
        }
      
        
        if (errors.length > 0) {
          console.log(`Rows data ${index + 1} is invalid:`, errors);
      }
  
        // Default case if no matching Action
        return (
          isActionValid &&
          isSLNoValid
        );
      
      };

      const getExistingUserIds = async (tableData) => {
        try {
          // console.log("userIds", tableData);
    
          const validRows = tableData.filter((row, index) => isRowValid(row, index));
          console.log("valid rows checking in upload:", validRows);
            
    
          
        const response1 = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-countryCode-exists-only`,
            { validRows:validRows }
          );
          // console.log("errored records Country id's : Respons1", response1);
          console.log("check country code is exists", response1.data);
          const existingCountryIds = response1.data; // Extract existing user IDs from API response
         
          const ExistCountryIdValidRows = await filterExistingCountryIds(validRows, existingCountryIds);
    
          console.log("successful and row valid in country filters", ExistCountryIdValidRows);


          // Make API call to fetch existing user IDs
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-States-duplicate/stateCode`,
            { ExistCountryIdValidRows }
          );
          console.log("errored records user id's : Response data", response.data);
          const existingUserIds = response.data; // Extract existing user IDs from API response
    
          const withoutDuplicateUserIds = await filterExistingUserIds(ExistCountryIdValidRows, existingUserIds);
    
          console.log("successful and processed Records data:", withoutDuplicateUserIds);
    
          return withoutDuplicateUserIds;
    
        } catch (error) {
          console.error("Error fetching existing user IDs:", error);
          return [];
        }
      };
    
    
      
      // const filterExistingUserIds = async (ExistCountryIdValidRows, existingUserIds) => {
      //   return ExistCountryIdValidRows.filter(
      //     (row) =>
      //       existingUserIds.some(
      //         (existing) => existing.state === row.StateCode && existing.country === row.CountryCode
      //       )
      //   );
      // };
      
      const filterExistingUserIds = async (ExistCountryIdValidRows, existingUserIds) => {
        // ✅ Filter only successful records based on existingUserIds
        const filteredRecords = ExistCountryIdValidRows.filter(
          (row) =>
            existingUserIds.some(
              (existing) =>
                existing.state === row.StateCode &&
                existing.country === row.CountryCode
            )
        );

        const uniqueRecords = filteredRecords.filter(
          (row, index, self) =>
            self.findIndex(
              (r) =>
                r.StateCode === row.StateCode &&
                r.CountryCode === row.CountryCode
            ) === index
        );
      
        return uniqueRecords;
      };
      
      const filterExistingCountryIds = async (tableData, existingCountryIds) => {
        return tableData.filter((row) => existingCountryIds.includes(row.CountryCode));
      };


  const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
  
    // Decide which data to display based on displayOption and uploadOption
    let dataToDisplay;
    if (displayOption === "1") {
      console.log("Display option is 1");
      const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"
  
       // Extract the current dataToDisplay (which is only one record currently)
       const existingUserIds = new Set(existingUsers.map(user => user.StateCode));
  
       // Filter out the currentDataToDisplay row from tableData to get other 6 rows
       dataToDisplay = tableData.filter(user => !existingUserIds.has(user.StateCode));
   
       console.log("Filtered remaining data excluding currentDataToDisplay:", dataToDisplay);
  
    } else if (displayOption === "2") {
      console.log("Display option is 2");
      dataToDisplay = tableData; // Display tableData if displayOption is "2"
    } else if (uploadOption === "3") {
      console.log("upload option is 3");
      dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
    } 
    else {
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
  
  console.log("dataToDisplay data is",dataToDisplay);
  return dataToDisplay;
  };
  
  
  async function checkDuplicateUserIdsFromDB(statesProvinces) {
    try {
      const data = { statesProvinces };
      // console.log("countrys duplicated DATA IS",data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-StatesProvinces-duplicates`,
        data
      );
  
      console.log("existingUserId API Response for duplicates details:", response.duplicates);
      return response.duplicates; // Return an array of duplicate userIds
    } catch (error) {
      console.error("Error checking duplicate userIds from DB:", error);
      return [];
    }
  } 

  const processData = async (data) => {
    
    const statesProvinces = data
    .filter(row => row.StateCode && row.CountryCode) // Ensure both values exist
    .map(row => ({ state: row.StateCode, country: row.CountryCode }));
  
  console.log("Extracted statesProvinces:", statesProvinces);

      const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(statesProvinces);
    console.log("Duplicates in state province",duplicateUserIdsFromDB);



       // Preprocess table data to add conditional rendering for userId
        return Promise.all(data.map(async (row, _, allRows) => {
    


      // Check for duplicate same userId is repeated in the data
      const isDuplicateUserId  = allRows.filter(
        r => r.StateCode === row.StateCode && r.CountryCode === row.CountryCode
      ).length > 1;
      console.log("Duplicate user id is ",isDuplicateUserId);


     // Check for duplicate userId in the database
     const isDuplicateInDB = Array.isArray(duplicateUserIdsFromDB) && duplicateUserIdsFromDB.some(
        (entry) => entry.StateCode === row.StateCode && entry.CountryCode === row.CountryCode
      );
    // const isDuplicateInDB = Array.isArray(stateAndCountryCodes) &&
    // stateAndCountryCodes.some(
    //   (entry) =>
    //     entry.state === row.StateCode && 
    //     entry.Country_Code === row.CountryCode
    // );

  console.log("Duplicate State Province id is ", isDuplicateInDB);


        //  const userExists = await isExistingUserId(row.StateCode);
    const userExists = stateCodes.some((entry) =>
      entry.state === row.StateCode &&
    entry.CountryCode === row.CountryCode
    )

     const countryIDExists = countryCodes
     .map(code => String(code).trim().toUpperCase()) // Normalize countryCodes
     .includes(String(row.CountryCode).trim().toUpperCase()); // Normalize row.CountryCode

     console.log(`User Exists for State: ${row.StateCode}, Country: ${row.CountryCode} → ${userExists}`);

    //  console.log(`User ${row.StateCode} exists:`, userExists);
    //  console.log(`Country ID is ${row.CountryCode} exists:`, countryIDExists);
     // Check for errors (red text) and apply highlight based on that
    
     const hasNotError = [
      row.SLNo,
      row.Action,
      row.StateCode,
      row.CountryCode,
      row.CountryName,
    
    ].every(field => {
      let isValid = true;
      let reason = "";
      
      if (field === row.SLNo) {
        if (!field) { isValid = false; reason = "SLNo should not be blank."; }
        else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
        else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
      }
      
      else if (field === row.Action) {
        if (!field) { isValid = false; reason = "Action should not be blank."; }
        else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
      }
      
      if (!row.StateCode) {
        isValid = false;
        reason = "State Code should not be empty.";
      } else if (String(row.StateCode).length > 3) {
        isValid = false;
        reason = "State Code exceeds max length of 3.";
      } else if ((row.Action === "E" || row.Action === "e") && !userExists) {
        isValid = false;
        reason = "State Code does not exist.";
      } else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
        isValid = false;
        reason = "State Code is duplicate.";
      }
      
      
      if (!row.StateName || String(row.StateName).trim() === "") {
        if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
          isValid = false;
          reason = "StateName should not be empty.";
      }
      } else if (field === row.StateName) {
            if (String(field).trim().length > 30) {
                  isValid = false;
                  reason = "StateName exceeds max length of 30.";
              }
      }
    
    
      if (!row.CountryCode || String(row.CountryCode).trim() === "") {
        if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
          isValid = false;
          reason = "CountryCode should not be empty.";
        }
    } else if (field === row.CountryCode) {
        if (countryIDExists) {
                isValid = true;
                reason = "Country code is exists."; 
        } else {
            isValid = false;
            reason = "Country code is Not exists."; 
        }
        
        // if (row.Action === "E" || row.Action === "e") {
        //     if (!field.trim()) {
        //         isValid = true;
        //         reason = "CountryCode should be blank for Edit action.";
        //     } else if (String(field).trim().length > 2) {
        //         isValid = false;
        //         reason = "CountryCode exceeds max length of 2.";
        //     }
        // } else 
        // if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
            // if (!field.trim()) {
            //     isValid = false;
            //     reason = "CountryCode should not be blank for Add/Edit action.";
            // } else 
            if (String(field).trim().length > 2) {
                isValid = false;
                reason = "CountryCode exceeds max length of 2.";
            }
        // }
    }
    
    
    
    
      if (!isValid) {
        console.log(`Validation Failed: ${reason}`);
      } else {
        console.log(`Validation Passed: ${field}`);
      }
      
      return isValid;
    });
    
    console.log(`Final hasNotError status: ${hasNotError}`);
    
    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error
      isDuplicateUserId,
      isDuplicateInDB,
      userExists,
      countryIDExists,

      Action: row.Action ? (
        String(row.Action).length === 1 ? (
          ["A", "a", "E", "e"].includes(row.Action) ? (
            row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Only "A", "E" are allowed)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Max. allowed character is 1)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
        

        // StateCode: row.StateCode ? (
        //   String(row.StateCode).length > 3 ? (
        //     <span style={{ color: "red" }}>
        //       {row.StateCode} (Max. allowed character is 3)
        //     </span>
        //   ) : row.Action === "A" || row.Action === "a" ? (
        //     // When Action is "A", show duplicate message if user ID is a duplicate
        //     (uploadOption !== '' && displayOption !== '' && (isDuplicateInDB || isDuplicateUserId)) || 
        //     (displayOption !== '' && (isDuplicateInDB || isDuplicateUserId)) ? (
        //       <span style={{ color: "red" }}>
        //         {row.StateCode} (State Code Duplicate exists)
        //       </span>
        //     ) : (
        //       String(row.StateCode) // Otherwise, show userId normally
        //     )
        //   ) : row.Action === "E" || row.Action === "e" ? (
        //     // When Action is "E", show userId without the duplicate message
        //     (uploadOption !== '' && displayOption !== '' && (isDuplicateInDB || isDuplicateUserId)) || 
        //     (displayOption !== '' && (isDuplicateInDB || isDuplicateUserId)) ? (
        //       <span>
        //         {row.StateCode} 
        //       </span>
        //     ) : (
        //       // String(row.StateCode) // Otherwise, show StateCode normally
        //       <span style={{ color: "red" }}>
        //       {row.StateCode} (State Code not exists)
        //     </span>
        //     )
        //   ) : (
        //     String(row.StateCode) // Default case, show userId normally
        //   )
        // ) : (
        //   <span style={{ color: "red" }}>
        //     Shouldn't be blank
        //   </span>
        // ),
        
    
        StateCode: row.StateCode ? (
          String(row.StateCode).length > 3 ? (
            <span style={{ color: "red" }}>
              {row.StateCode} (Max. allowed character is 3)
            </span>
          ) : isDuplicateUserId || isDuplicateInDB ? (
            <span style={{ color: "red" }}>
              {row.StateCode} (State Code Duplicate exists)
            </span>
          ) : (
            row.StateCode
          )
        ) : (
          <span style={{ color: "red" }}>Shouldn't be blank</span>
        ),

        StateName: row.StateName ? (
          String(row.StateName).length > 30 ? (
            <span style={{ color: "red" }}>
              {row.StateName} (Max. allowed character is 30)
            </span>
          ) : (
            String(row.StateName) // Ensure Username is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : null // Handle other cases, if any
        ),

  
        
        CountryCode: row.CountryCode ? (
          String(row.CountryCode).length > 2 ? (
            <span style={{ color: "red" }}>
              {row.CountryCode} (Max. allowed character is 2)
            </span>
          ) : countryIDExists && displayOption !== "" ? (
              <span>{row.CountryCode}</span>
            ) : (
              <span style={{ color: "red" }}>
                {row.CountryCode} (Country Code not exists)
              </span>
            )
        ) : (
          row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : null
        ),
          
  
        

    
      };   
      }));
    }


 const fetchStateProvinceCode = async () => {
  try {
    const response = await UserService.getUsers(
      `${process.env.REACT_APP_BASE_URL}/get-stateprovince-code`
    );
    console.log("response. state data",response.data.data);
    setStateCodes(response.data.data);
    // console.log("Set user types data", response.data.data);
  } catch (error) {
    console.error("Error fetching user types:", error);
  }
  };



  const fetchCountryCodes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-country-code`
      );
      // console.log("response.data.data",response.data.data);
      setCountryCodes(response.data.data);
      // console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

 

useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
      // await fetchCountryCodes();
      
      if(countryCodes && stateCodes){
          const processed = await processData(dataToDisplay);
          setProcessedData(processed);
      }
      setLoading(false);
    };
  
    fetchData();
  }, [countryCodes, stateCodes ]);

  return (
    <div>
       <Header
        title={`Primary Data > Global Data > State/Province > Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/2/master-data/2/stateProvince-upload"
      />
       {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}
      <PrimaryActions
          icons={iconId}
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            selectIds={selectedRows}
          />

        <ReTable
          data={processedData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        />

    </div>
  )
}
