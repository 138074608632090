import React, { useEffect, useState } from "react";
import Tabs from "../../../../../components/Tabs";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import Header from "../../../../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LegalEntityServices from "../../../../../rest-services/LegalEntityServices";
import { useToast } from "../../../../../components/toast/toast";
import { RxDropdownMenu } from "react-icons/rx";
import CurrencyServicess from "../../../../../rest-services/CurrencyServices";
import CountryServices from "../../../../../rest-services/CountryServices";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices";
import CompanyServices from "../../../../../rest-services/CompanyServices";
import { Checkbox } from "@mui/material";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";

export default function CompanyAdd() {
  const [activeTab, setActiveTab] = useState("addcompany");
  const navigate = useNavigate();
  const { companyId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {};
  const isViewMode = mode === "view";
  const [errors, setErrors] = useState({}); // Validation errors
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const { toast } = useToast();
  const [alert, setAlert] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [companyCode, setCompanyCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyShortName, setCompanyShortName] = useState("");
  const [legalEntities, setLegalEntities] = useState([]);
  const [buGroups, setBugroups] = useState([]);
  const [sourceSystemCurrencyCode, setSourceSystemCurrencyCode] = useState("");
  const [chartOfAccountingCode, setChartOfAccountingCode] = useState("");
  const [accountingSystem, setAccountingSystem] = useState("");
  const [accountingPeriodCode, setAccountingPeroidCode] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [faxNo, setFaxNo] = useState("");
  const [status, setStatus] = useState("");
  const [lgeCode, setLgeCode] = useState("");
  const [lgeDescription, setLgeDescription] = useState("");
  const [buGroupCode, setBuGroupCode] = useState("");
  const [bugroupName, setBuGroupName] = useState("");

  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [countryCode, setCountryCode] = useState(""); // Country Code
  const [countryName, setCountryName] = useState("");
  const [district, setdistrict] = useState("");
  const [poBox, setpoBox] = useState("");
  const [state, setstate] = useState("");
  const [stateName, setStateName] = useState("");
  // const [countryCode, setcountryCode] = useState("");
  const [countryId, setCountryId] = useState("");
  const [areaBlock, setareaBlock] = useState("");
  const [roadNo, setroadNo] = useState("");
  const [roadName, setRoadName] = useState("");
  const [streetNo, setstreetNo] = useState("");
  const [buildingName, setbuildingName] = useState("");
  const [buildingNo, setbuildingNo] = useState("");
  const [doorNo, setdoorNo] = useState("");
  const [floorNo, setfloorNo] = useState("");
  const [addressLine1, setaddressLine1] = useState("");
  const [addressLine2, setaddressLine2] = useState("");
  const [addressLine3, setaddressLine3] = useState("");
  const [countries, setCountries] = useState([]);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
  const [postalCode, setpostalCode] = useState("");
  const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
  const [selectedState, setSelectedState] = useState(state || "");
  const [selectedBuGroup, setSelectedBugroup] = useState(state || "");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [filteredStateProvinces, setFilteredStateProvinces] = useState([]);
  const [isLgeModalOpen, setIsLgeModalOpen] = useState(false);
  const [isBuGroupModalOpen, setIsBuGroupModalOpen] = useState(false);
  const [selectedLge, setSelectedLge] = useState(false);
  const [isAddressChecked, setIsAddressChecked] = useState(false);
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);

  useEffect(() => {
    fetchcountry();
    fetchcurrency();
    fetchStateProvinces();
   fetchLegalEntity();
   fetchBuGroups();
    fetchAssignee();
  }, []);
  useEffect(() => {
    if (companyId) {
      getDataForEdit();
    }
  }, [companyId, currencies, countries, stateProvinces,legalEntities,buGroups]);

  // const handleCheckboxChange = (event) => {
  //   const selectedName = event.target.value; // Change from value to country name
  //   setSelectedCountry(selectedName);

  //   // Find the selected country data by country name instead of code
  //   const selectedCountryData = countries.find(country => country.countryName === selectedName);
  //   if (selectedCountryData) {
  //     setCountryCode(selectedCountryData.country); // Set the country code based on the selected country name
  //     setCountryName(selectedCountryData.countryName);
  //   }
  // };
  const getDataForEdit = async () => {
    try {
      const response = await CompanyServices.getCompanyById(companyId);
      console.log("Response data:", response);
      const address = response.addresses[0];
      const account = response.account[0];
      const contact =response.contact[0];

      // Existing data handling
      setCompanyCode(response.companyCode);
      setCompanyName(response.companyName);
      setCompanyShortName(response.companyShortName);
setBuGroupCode(response.buGroupCode);
      setLgeCode(response.legalEntityCode); // Adjust according to API response structure
      setCountryCode(address.countryCode); // Set country code
      setCurrencyCode(response.currency); // Set currency code
      setdoorNo(address.doorNo);
      setfloorNo(address.floorNo);
      setbuildingNo(address.buildingNo);
      setbuildingName(address.buildingName);
      setlandMark(address.landMark);
      setstreetNo(address.streetNo);
      setstreetName(address.streetName);
      setroadNo(address.roadName);
      setRoadName(address.roadName);
      setareaBlock(address.areaBlock);
      setareaName(address.areaName);
      setaddressLine1(address.addressLine1);
      setaddressLine2(address.addressLine2);
      setaddressLine3(address.addressLine3);
      setstate(address.state);
      setdistrict(address.district);
      setpostalCode(address.postalCode);
      setzipCode(address.zipCode);
      setpoBox(address.poBox);
      setSourceSystemCurrencyCode(account.sourceSystemCurrencyCode);
      setChartOfAccountingCode(account.chartOfAccountingCode);
      setAccountingSystem(account.accountingSystem);
      setAccountingPeroidCode(account.accountingPeriodCode);
      setContactPersonName(contact.contactPersonName);
      setMobileNo(contact.mobileNo);
      setEmail(contact.email);
      setTelephone(contact.telephone);
      setFaxNo(contact.faxNo);

      // Country lookup based on country code from response
      const country = countries.find(
        (c) => c.country === address.countryCode
      );
      console.log("country code from response:", address.countryCode);
      console.log("Available countries:", countries);
      if (countries) {
        setCountryName(country.countryName); // Set currency name for display
        setSelectedCountry(country.countryName); // Set the selected currency based on currency name
        // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }

      // for state
      const fetchedState = stateProvinces.find(
        (c) => c.state === address.state
      );
      console.log("State code from response:", address.state);
      console.log("Available states:", stateProvinces);
      console.log("the fetched console", fetchedState.State_Name);
      if (fetchedState) {
        setStateName(fetchedState.State_Name); // Set state name for display
      } else {
        console.error(
          "State not found for the provided state code:",
          response.state
        );
      }

      // Currency lookup based on currency code from response
      const currency = currencies.find(
        (c) => c.currencyCode === response.currency
      );
      console.log("Currency code from response:", response.Currency);
      console.log("Available currencies:", currencies);
      if (currencies) {
        setCurrencyName(currency.Currency_Name); // Set currency name for display
        setSelectedCurrency(currency.Currency_Name); // Set the selected currency based on currency name
        setCurrencyCode(currency.currencyCode); // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }
      const lge = legalEntities.find(
        (c) => c.LGE_Code === response.legalEntityCode
      );
      console.log("Currency code from response:", response.legalEntityCode);
      console.log("Available currencies:", legalEntities);
      if (lge) {
        setLgeDescription(lge.LGE_Description); // Set currency name for display
        setSelectedLge(lge.LGE_Description); // Set the selected currency based on currency name
        setLgeCode(lge.LGE_Code); // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }
      const bu = buGroups.find(
        (c) => c.BU_Group === response.buGroupCode
      );
      console.log("bu code from response:", response.buGroupCode);
      console.log("Available bu:", buGroups);
      if (bu) {
        setBuGroupName(bu.BU_Group_Description); // Set currency name for display
        setSelectedBugroup(bu.BU_Group_Description); // Set the selected currency based on currency name
        setBuGroupCode(bu.BU_Group); // Set the currency code// Set the selected country based on countryName
      } else {
        console.error("bu not found for the provided bu code.");
      }
    }
     catch (error) {
      console.error("Error fetching legal entity data:", error);
      setAlert({
        severity: "error",
        message: "Error fetching legal entity data.",
      });
    }
    
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchcountry = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-country`
      );
      console.log("Fetched countries:", response); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchStateProvinces = async () => {
    try {
      const response = await StateProvinceServices.getAllStateprovince(
        `${process.env.REACT_APP_BASE_URL}/get-stateprovince`
      );
      console.log("Fetched StateProvinces:", response); // Add this log
      setStateProvinces(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchcurrency = async () => {
    try {
      const response = await CurrencyServicess.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-currency`
      );
      console.log("Fetched Currencies:", response); // Add this log
      setCurrencies(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const fetchLegalEntity = async () => {
    try {
      const response = await LegalEntityServices.getAllLegalEntity(
        `${process.env.REACT_APP_BASE_URL}/get-all-legal-entity`
      );
      console.log("Fetched Legal Entity:", response); // Add this log
      setLegalEntities(response.data); 
      console.log("helooooo",legalEntities)// Ensure this is correct
    } catch (error) {
      console.error("Error fetching LGE:", error);
    }
  };
  const fetchBuGroups = async () => {
    try {
      const response = await BuGroupServices.getAllBuGroup(
        `${process.env.REACT_APP_BASE_URL}/get-bugroup`
      );
      console.log("Fetched BuGROUPS:", response); // Add this log
      setBugroups(response.data.data); 
      console.log("helooooo",buGroups);// Ensure this is correct
    } catch (error) {
      console.error("Error fetching BuGroups:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      id: companyId, // Use 'id' for updates
      legalEntityCode: lgeCode,
      buGroupCode,
      companyCode,
      companyName,
      companyShortName,
      currency: currencyCode,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
      doorNo,
      floorNo,
      buildingNo,
      buildingName,
      landMark,
      streetNo,
      streetName,
      roadNo,
      roadName,
      areaBlock,
      areaName,
      addressLine1,
      addressLine2,
      addressLine3,
      countryCode,
      state,
      district,
      postalCode,
      zipCode,
      poBox,
      sourceSystemCurrencyCode,
      chartOfAccountingCode,
      accountingSystem,
      accountingPeriodCode,
      contactPersonName,
      mobileNo,
      email,
      telephone,
      faxNo,
    };
console.log("helooooo",data);
    try {
      let response;
      if (companyId) {
        // Updating the existing currency
        response = await CompanyServices.addCompany(
          `${process.env.REACT_APP_BASE_URL}/update-company`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await CompanyServices.addCompany(
          `${process.env.REACT_APP_BASE_URL}/create-company`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          companyId
            ? "Company Updated successfully"
            : "Company Added successfully"
        );
        // Clear the form and navigate

        navigate("/company"); // Redirect after saving
      } else {
        toast("error", "Error adding/updating Company");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Company: ${error.message}`,
      });
    }
  };
  const handleSaveAndContinue = () => {
    // Validate required fields
    const isValid = validateForm(); // Call your validation function

    if (isValid) {
      // Only change the tab if validation passes
      setActiveTab("companyaddress");
    } else {
      // Show an error message or handle the error state as needed
      toast("error", "Required Fields are Missing.");
    }
  };
  // Separate handler for country selection
  const handleCountryCheckBoxChange = (event) => {
    console.log(`this is the Legal Entity ${countries}`);

    const selectedName = event.target.value; // Get the selected country name
    setSelectedCountry(selectedName);

    const selectedCountryData = countries.find(
      (country) => country.countryName === selectedName
    );
    if (selectedCountryData) {
      setCountryCode(selectedCountryData.country); // Set the country code
      setCountryName(selectedCountryData.countryName); // Set the country name

      // Filter states by the selected country code
      const filteredStates = stateProvinces.filter(
        (state) => state.Country_Code === selectedCountryData.country
      );
      setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
    }
  };

  
  // Separate handler for Legal Entity selection
  const handleCurrencyCheckBoxChange = (event) => {
    currencies.forEach((currency) => {
      console.log(currency);
    });
    const selectedName = event.target.value; // Get the selected currency name
    setSelectedCurrency(selectedName);

    const selectedCurrencyData = currencies.find(
      (currency) => currency.Currency_Name === selectedName
    );

    if (selectedCurrencyData) {
      setCurrencyCode(selectedCurrencyData.currencyCode); // Set the currency code
      setCurrencyName(selectedCurrencyData.Currency_Name);
      // Set the currency name
    }
  };
  const handleLgeCheckBoxChange = (event) => {
 
    const selectedName = event.target.value; // Get the selected currency name
    setSelectedLge(selectedName);

    const selectedlgeData = legalEntities.find(
      (lge) => lge.LGE_Description === selectedName
    );

    if (selectedlgeData) {
      setLgeCode(selectedlgeData.LGE_Code); // Set the currency code
      setLgeDescription(selectedlgeData.LGE_Description);
      // Set the currency name
    }
  };
  const handleBuGroupCheckBoxChange = (event) => {
    buGroups.forEach((buGroup) => {
      console.log(buGroup);
    });
    const selectedName = event.target.value; // Get the selected currency name
    setSelectedBugroup(selectedName);

    const selectedBugroupData = buGroups.find(
      (buGroup) => buGroup.BU_Group_Description === selectedName
    );

    if (selectedBugroupData) {
      setBuGroupCode(selectedBugroupData.BU_Group); // Set the currency code
      setBuGroupName(selectedBugroupData.BU_Group_Description);
      // Set the currency name
    }
  };
  const handleCheckboxChangeForState = (event) => {
    const selectedCode = event.target.value;
    console.log("selected State code", selectedCode);
    setSelectedState(selectedCode);
    setstate(selectedCode);

    const selectedCountryData = stateProvinces.find(
      (stateP) => stateP.state === selectedCode
    );
    console.log("selected state country data", selectedCountryData);
    if (selectedCountryData) {
      console.log(
        "check selected country data",
        selectedCountryData.State_Name
      );
      setStateName(selectedCountryData.State_Name);
    }
  };
  const handleCheckBoxForAddress = (event) => { 
    if (event.target.checked) {
      console.log("Selected lgeCode:", lgeCode); // Check if lgeCode is being set properly
  
      // Find the legal entity by its LGE_Code
      const targetLge = legalEntities.find((lge) => lge.LGE_Code === lgeCode);
  
      console.log("Matching legal entity:", targetLge);
  
      if (targetLge) {
        // Ensure you are accessing the addresses array
        if (targetLge.addresses && targetLge.addresses.length > 0) {
          const address = targetLge.addresses[0]; // Access the first address
  
          console.log("Address found:", address); // Log the address object
  
          // Set the address fields
          setdoorNo(address.doorNo || "");
          setfloorNo(address.floorNo || "");
          setbuildingNo(address.buildingNo || "");
          setbuildingName(address.buildingName || "");
          setlandMark(address.landMark || "");
          setstreetNo(address.streetNo || "");
          setstreetName(address.streetName || "");
          setroadNo(address.roadNo || "");
          setRoadName(address.roadName || "");
          setareaBlock(address.areaBlock || "");
          setareaName(address.areaName || "");
          setaddressLine1(address.addressLine1 || "");
          setaddressLine2(address.addressLine2 || "");
          setaddressLine3(address.addressLine3 || "");
          setCountryCode(address.countryCode || "");
          setstate(address.state || "");
          setdistrict(address.district || "");
          setpostalCode(address.postalCode || "");
          setzipCode(address.zipCode || "");
          setpoBox(address.poBox || "");
  
          // Fetch and set country details
          const selectedCountryData = countries.find(
            (country) => country.country === address.countryCode
          );
  
          if (selectedCountryData) {
            setCountryCode(selectedCountryData.country);
            setCountryName(selectedCountryData.countryName);
  
            // Filter states by the selected country code
            const filteredStates = stateProvinces.filter(
              (state) => state.Country_Code === selectedCountryData.country
            );
            setFilteredStateProvinces(filteredStates);
          }
  
          // Fetch and set state details
          const selectedStateData = stateProvinces.find(
            (state) => state.state === address.state
          );
  
          if (selectedStateData) {
            setStateName(selectedStateData.State_Name);
          }
  
        } else {
          console.error("No address available for the selected legal entity.");
        }
      } else {
        console.error("No matching legal entity found for the provided lgeCode");
      }
    } else {
      // Clear address fields if unchecked
      setdoorNo("");
      setfloorNo("");
      setbuildingNo("");
      setbuildingName("");
      setlandMark("");
      setstreetNo("");
      setstreetName("");
      setCountryCode("");
      setStateName("");
      setRoadName("");
      setareaBlock("");
      setareaName("");
      setaddressLine1("");
      setaddressLine2("");
      setaddressLine3("");
      setCountryCode("");
      setstate("");
      setdistrict("");
      setpostalCode("");
      setzipCode("");
      setpoBox("");


    }
  };
  
  
  
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };

  const validateForm = () => {
    setIsValidationTriggered(true);

    let newErrors = {}; 
    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
  const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
  const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
  const isAreaSectionFilled = areaBlock || areaName;
  const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled;
  if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
    newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
  }
    if (!companyCode) newErrors.companyCode = "lge Code Code is required.";
    if (!companyName) newErrors.companyName = "lge Code Code is required.";
    if (!companyShortName)
      newErrors.companyShortName = "lge Code Code is required.";
    if (!bugroupName) newErrors.bugroupName = "lge Code Code is required.";
    if (!lgeDescription)
      newErrors.lgeDescription = "lge Description is required.";
    if (!currencyName) newErrors.currencyName = " Currency Code is required.";
    if (filteredStateProvinces.length > 0 && !state) {
      newErrors.state = "state is required.";
    }
  
    if (!countryCode) newErrors.countryCode = "Country Code   is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Bank&Company masterData") {
      navigate("/master-data");
    } else if (crumb.title === "Company") {
      navigate("/company");
    } else if (crumb.title === "Primary Data") {
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  const toggleModalForState = () => {
    setIsModalOpenForState(!isModalOpenForState);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const tabs = [
    { value: "addcompany", label: "Basic Details" },
    { value: "companyaddress", label: "Company Address" },
    { value: "account", label: "Account" },
    { value: "contact", label: "Contact" },
  ];
  const errorss = {
    addcompany: !companyCode || !companyName || !companyShortName || !lgeDescription || !bugroupName || !currencyName  , // Replace with your validation logic
    companyaddress: !addressLine1,
   
  };
  const nextToAddress = () => {
    setActiveTab("companyaddress")
  
}
const nextToAccount = () => {
  setActiveTab("account")

}
const nextToContact= () => {
  setActiveTab("contact")

}
  return (
    <div>
      <Header
        title={`Primary Data > Bank&Company masterData > Company > ${
          companyId ? (isViewMode ? "View" : "Edit") : "Add"
        }`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} isValidationTriggered={isValidationTriggered} errors={errorss} />
      <div className="flex-grow">
        {activeTab === "addcompany" && (
          <div className="flex flex-col space-y-8 col-span-1 p-10">
            <div className=" space-y-8">
              {/* First row: Single field (LGE) */}
              <div className="flex items-center">
                <label className="flex items-center w-full">
                  <span className="w-48 font-small text-customGray">
                    Company Code <span className="text-red-500 ml-1">*</span>
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={companyCode}
                    onChange={(e) => {
                      setCompanyCode(e.target.value);
                      if (errors.companyCode) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          companyCode: "",
                        }));
                      }
                    }}
                    className={`border text-customGray ${
                      errors.companyCode ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                    required
                    disabled={isViewMode}
                  />
                </label>
              </div>

              {/* Second row: Two fields (LGE Code and LGE Description) */}
              <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 font-small text-customGray">
                      Company Name<span className="text-red-500 ml-1">*</span>
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        if (errors.companyName) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            companyName: "",
                          }));
                        }
                      }}
                      className={`border text-customGray ${
                        errors.companyName
                          ? "border-red-500"
                          : "border-gray-300"
                      } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                      required
                      disabled={isViewMode}
                    />
                  </label>
                </div>

                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 font-small text-customGray">
                      Company Short Name
                      <span className="text-red-500 ml-1">*</span>
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={companyShortName}
                      onChange={(e) => {
                        setCompanyShortName(e.target.value);
                        if (errors.companyShortName) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            companyShortName: "",
                          }));
                        }
                      }}
                      className={`border text-customGray ${
                        errors.companyShortName
                          ? "border-red-500"
                          : "border-gray-300"
                      } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
                      required
                      disabled={isViewMode}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
                <label
                  className="w-48 text-md font-small text-customGray"
                  disabled={isViewMode}
                >
                  Legal Entity Name <span className="text-red-500 ml-1">*</span>
                </label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsLgeModalOpen(true)}
                  disabled={isViewMode}
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray ${
                    errors.lgeDescription
                      ? "border-red-500 hover:border-red-500"
                      : "border-gray-300 hover:border-blue-400"
                  }`}
                >
                  <span>{selectedLge || "Select LGE Name"}</span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                {isLgeModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                      {" "}
                      {/* Added rounded-lg for border-radius */}
                      {/* Header */}
                      <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                        <h2 className="text-lg font-semibold text-customBlue">
                          Select LGE Name
                        </h2>
                        <input
                          type="text"
                          placeholder="Search Lge..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-customGray focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue text-customGray"
                          disabled={isViewMode}
                        />
                        <button
                          onClick={() => setIsLgeModalOpen(false)}
                          className="text-customGray hover:text-gray-700 text-2xl"
                        >
                          &times;
                        </button>
                      </div>
                      {/* Scrollable Content */}
                      <div className="flex-1 overflow-auto p-4">
                      {legalEntities && legalEntities.length > 0 ? (
                          <div className="grid grid-cols-3 gap-4">
                            {legalEntities
                              .filter(
                                (lge) =>
                                  (lge.LGE_Description &&
                                    lge.LGE_Description.trim() &&
                                    lge.LGE_Description
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase())) ||
                                  (lge.LGE_Code &&
                                    lge.LGE_Code.trim() &&
                                    lge.LGE_Code
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase()))
                              )
                              .map((lge, index) => (
                                <div
                                  key={lge.id}
                                  className={`flex items-center ${
                                    index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="bugroup Name"
                                    value={lge.LGE_Description}
                                    checked={
                                      selectedLge === lge.LGE_Description
                                    }
                                    onChange={handleLgeCheckBoxChange}
                                    className="mr-2 text-blue-200"
                                    disabled={isViewMode}
                                  />
                                  <label>{lge.LGE_Description}</label>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <p>Loading lge...</p>
                        )}
                      </div>
                      {/* Footer */}
                      {!isViewMode && (
                        <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                          <button
                            onClick={() => setIsLgeModalOpen(false)}
                            className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                            disabled={isViewMode}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center">
                <label className="w-48 text-md font-small  text-customGray">
                  Legal Entity Code
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={lgeCode}
                  readOnly
                  className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none ml-8 text-customGray"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
                <label
                  className="w-48 text-md font-small text-customGray"
                  disabled={isViewMode}
                >
                  BU Group Name <span className="text-red-500 ml-1">*</span>
                </label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsBuGroupModalOpen(true)}
                  disabled={isViewMode}
                  className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray ${
                    errors.bugroupName
                      ? "border-red-500 hover:border-red-500"
                      : "border-gray-300 hover:border-blue-400"
                  }`}
                >
                  <span>{selectedBuGroup || "Select BUGroup Name"}</span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                {isBuGroupModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                      {" "}
                      {/* Added rounded-lg for border-radius */}
                      {/* Header */}
                      <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                        <h2 className="text-lg font-semibold text-customBlue">
                          Select Country
                        </h2>
                        <input
                          type="text"
                          placeholder="Search country..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-customGray focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue text-customGray"
                          disabled={isViewMode}
                        />
                        <button
                          onClick={() => setIsBuGroupModalOpen(false)}
                          className="text-customGray hover:text-gray-700 text-2xl"
                        >
                          &times;
                        </button>
                      </div>
                      {/* Scrollable Content */}
                      <div className="flex-1 overflow-auto p-4">
                        {buGroups.length > 0 ? (
                          <div className="grid grid-cols-3 gap-4">
                            {buGroups
                              .filter(
                                (bugroup) =>
                                  (bugroup.BU_Group_Description &&
                                    bugroup.BU_Group_Description.trim() &&
                                    bugroup.BU_Group_Description
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase())) ||
                                  (bugroup.BU_Group &&
                                    bugroup.BU_Group.trim() &&
                                    bugroup.BU_Group
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase()))
                              )
                              .map((bugroup, index) => (
                                <div
                                  key={bugroup.id}
                                  className={`flex items-center ${
                                    index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="Country Name"
                                    value={bugroup.BU_Group_Description}
                                    checked={
                                      selectedBuGroup === bugroup.BU_Group_Description
                                    }
                                    onChange={handleBuGroupCheckBoxChange}
                                    className="mr-2 text-blue-200"
                                    disabled={isViewMode}
                                  />
                                  <label>{bugroup.BU_Group_Description}</label>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <p>Loading countries...</p>
                        )}
                      </div>
                      {/* Footer */}
                      {!isViewMode && (
                        <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                          <button
                            onClick={() => setIsBuGroupModalOpen(false)}
                            className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                            disabled={isViewMode}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center">
                <label className="w-48 text-md font-small  text-customGray">
                  BU Group Code
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={buGroupCode}
                  readOnly
                  className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none ml-8 text-customGray"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
                <label
                  className="w-48 text-md font-small text-customGray"
                  disabled={isViewMode}
                >
                  Currency Name <span className="text-red-500 ml-1">*</span>
                </label>
                <span className="ml-4">:</span>
                <button
                  onClick={() => setIsCurrencyModalOpen(true)}
                  disabled={isViewMode}
                  className={` text-customGray border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between text-customGray ${
                    errors.currencyName
                      ? "border-red-500 hover:border-red-500"
                      : "border-gray-300 hover:border-blue-400"
                  }`}
                >
                  <span>{selectedCurrency || "Select Currency Name"}</span>
                  <RxDropdownMenu className="ml-2" />
                </button>

                {isCurrencyModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                      {/* Modal Header */}
                      <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                        <h2 className="text-lg font-semibold text-customBlue">
                          Select Currency
                        </h2>
                        <input
                          type="text"
                          placeholder="Search currency..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-customGray focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue text-customGray"
                          disabled={isViewMode}
                        />
                        <button
                          onClick={() => setIsCurrencyModalOpen(false)}
                          className="text-customGray hover:text-gray-700 text-2xl"
                        >
                          &times;
                        </button>
                      </div>

                      {/* Modal Content */}
                      <div className="flex-1 overflow-auto p-4">
                        {currencies.length > 0 ? (
                          <div className="grid grid-cols-3 gap-4">
                            {currencies
                              .filter(
                                (currency) =>
                                  currency.Currency_Name &&
                                  currency.Currency_Name.toLowerCase().includes(
                                    searchQuery.toLowerCase()
                                  )
                              )
                              .map((currency, index) => (
                                <div
                                  key={currency.id}
                                  className={`flex items-center ${
                                    index % 2 === 0 ? "bg-sky-blue-200" : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="Currency"
                                    value={currency.Currency_Name}
                                    checked={
                                      selectedCurrency ===
                                      currency.Currency_Name
                                    }
                                    onChange={handleCurrencyCheckBoxChange}
                                    className="mr-2 text-blue-200"
                                    disabled={isViewMode}
                                  />
                                  <label>{currency.Currency_Name}</label>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <p>Loading Currencies...</p>
                        )}
                      </div>

                      {/* Modal Footer */}
                      {!isViewMode && (
                        <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                          <button
                            onClick={() => setIsCurrencyModalOpen(false)}
                            className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                            disabled={isViewMode}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center">
                <label className="w-48 text-md font-small text-customGray">
                  Currency Group
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={currencyCode}
                  readOnly
                  className="border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none ml-8 text-customGray"
                />
              </div>
              {/* {(!isViewMode && (
  <button
          onClick={handleSubmit}
          className="mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
        >
          Save
        </button>
))} */}
            </div>
            {!isViewMode && (
            <div className="flex">
            <Checkbox 
    checked={isAddressChecked} // Control the checkbox with state
    onChange={(event) => {
      setIsAddressChecked(event.target.checked); // Update the checkbox state
      handleCheckBoxForAddress(event); // Call your existing function
    }} 
  />

              <p className="p-4 text-xs text-customGray">
                Copy the Legal Entity Address
              </p>
            </div>
            )}
            <CustomButton onClick={nextToAddress} title={"Next"}/>
          </div>
        )}

        {activeTab === "companyaddress" && (
          <div className="p-12">
            {/* Building Section */}
            <div className="mb-6 border border-gray-300 p-5 rounded-lg">
              <h3 className="text-lg font-semibold text-customGray mb-4">
                Building
              </h3>

              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Door No{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={doorNo}
                    onChange={(e) => setdoorNo(e.target.value)}
                    disabled={isViewMode}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Floor No{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={floorNo}
                    disabled={isViewMode}
                    onChange={(e) => setfloorNo(e.target.value)}
                    placeholder="4"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Building No{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={buildingNo}
                    disabled={isViewMode}
                    onChange={(e) => setbuildingNo(e.target.value)}
                    placeholder="2A"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Building Name{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={buildingName}
                    disabled={isViewMode}
                    onChange={(e) => setbuildingName(e.target.value)}
                    placeholder="B name"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
              </div>

              <div className="flex items-center mt-4">
                <label className="w-48 text-sm font-medium text-customGray">
                  Landmark
                </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={landMark}
                  onChange={(e) => setlandMark(e.target.value)}
                  disabled={isViewMode}
                  placeholder=""
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>

            {/* Street Section */}
            <div className="mb-6 border border-gray-300 p-5 rounded-lg">
              <h3 className="text-lg font-semibold text-customGray mb-4">
                Street
              </h3>

              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Street No{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    value={streetNo}
                    onChange={(e) => setstreetNo(e.target.value)}
                    disabled={isViewMode}
                    type="text"
                    placeholder="2/626"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Street Name
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={streetName}
                    disabled={isViewMode}
                    onChange={(e) => setstreetName(e.target.value)}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                  {/* {errors.streetName && (
                    <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
                  )} */}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Road No{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={roadNo}
                    onChange={(e) => setroadNo(e.target.value)}
                    disabled={isViewMode}
                    placeholder="2A"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Road Name{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={roadName}
                    onChange={(e) => setRoadName(e.target.value)}
                    placeholder="B name"
                    disabled={isViewMode}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
              </div>
            </div>

            {/* Area Section */}
            <div className="mb-6 border border-gray-300 p-5 rounded-lg">
              <h3 className="text-lg font-semibold text-customGray mb-4">
                Area
              </h3>

              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Block{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={areaBlock}
                    onChange={(e) => setareaBlock(e.target.value)}
                    placeholder="2/626"
                    disabled={isViewMode}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Area Name{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={areaName}
                    onChange={(e) => setareaName(e.target.value)}
                    placeholder="4"
                    disabled={isViewMode}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
              </div>
            </div>
            {/* Display the error message for address section */}
            {errors.addressSection && (
              <p className="text-red-500 text-sm mb-4">
                {errors.addressSection}
              </p>
            )}

            {/* Address Section */}
            <div className="mb-6 border border-gray-300 p-5 rounded-lg">
              <h3 className="text-lg font-semibold text-customGray mb-4">
                Address
              </h3>
              <div className="grid grid-cols-1 gap-4">
                <div className="flex items-center">
                  <label className="w-56 text-sm font-medium text-customGray">
                    Address 1{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={addressLine1}
                    onChange={(e) => setaddressLine1(e.target.value)}
                    placeholder="2/626"
                    disabled={isViewMode}
                    className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />

                  {/* {errors.addressLine1 && (
                    <p className="text-red-500 text-sm ml-4">
                      {errors.addressLine1}
                    </p>
                  )} */}
                </div>
                {/* <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-customGray">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => setaddressLine2(e.target.value)}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div> */}
              </div>

              <div className="grid grid-cols-1 gap-4 mt-4">
                <div className="flex items-center">
                  <label className="w-56 text-sm font-medium text-customGray">
                    Address 2{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={addressLine2}
                    onChange={(e) => setaddressLine2(e.target.value)}
                    placeholder="4"
                    disabled={isViewMode}
                    className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 mt-4">
                <div className="flex items-center">
                  <label className="w-56 text-sm font-medium text-customGray">
                    Address 3{" "}
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={addressLine3}
                    onChange={(e) => setaddressLine3(e.target.value)}
                    placeholder="2A"
                    disabled={isViewMode}
                    className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
              </div>
            </div>

            {/* Country Section */}
            <div className="mb-6 border border-gray-300 p-5 rounded-lg">
              <h3 className="text-lg font-semibold text-customGray mb-4">
                Country
              </h3>

              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Country Code <span className="text-red-500 ml-1">*</span>
                  </label>
                  <span className="ml-4">:</span>
                  <button
                    onClick={() => setIsCountryModalOpen(true)}
                    disabled={isViewMode}
                    className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between ${
                      errors.countryCode
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                    }`}
                  >
                    <span>{countryCode || "Select Country Code"}</span>
                    <RxDropdownMenu className="ml-2" />
                  </button>

                  {isCountryModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                      <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                        <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                          <h2 className="text-lg font-semibold text-CustomBlue">
                            Select Country Code
                          </h2>
                          <input
                            type="text"
                            placeholder="Search country..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            disabled={isViewMode}
                            className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-customGray focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
                          />
                          <button
                            onClick={() => setIsCountryModalOpen(false)}
                            className="text-customGray hover:text-gray-700"
                          >
                            &times;
                          </button>
                        </div>

                        {/* Scrollable Content */}
                        <div className="flex-1 overflow-auto p-4">
                          {countries.length > 0 ? (
                            <div className="grid grid-cols-3 gap-4">
                              {countries
                                .filter(
                                  (country) =>
                                    country.countryName
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase()) ||
                                    country.country
                                      .toLowerCase()
                                      .includes(searchQuery.toLowerCase())
                                )
                                .map((country, index) => (
                                  <div
                                    key={country.id}
                                    className={`flex items-center ${
                                      index % 2 === 0 ? "bg-sky-blue-200" : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="country"
                                      value={country.countryName}
                                      checked={
                                        selectedCountry === country.countryName
                                      }
                                      onChange={handleCountryCheckBoxChange}
                                      className="mr-2 text-blue-200"
                                      disabled={isViewMode}
                                    />
                                    <label>{country.countryName}</label>
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <p>Loading countries...</p>
                          )}
                        </div>

                        <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                          {!isViewMode && (
                            <button
                              onClick={() => setIsCountryModalOpen(false)}
                              className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Country Name
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={countryName}
                    // readOnly
                    disabled={isViewMode}
                    className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-400 focus:outline-none hover:border-blue-400 ml-4"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    State / Province{" "}
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <span className="ml-4">:</span>
                  <button
                    onClick={() => setIsStateModalOpen(true)}
                    disabled={isViewMode}
                    className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between ${
                      errors.state
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                    }`}
                  >
                    <span>{state || "Select State / Province"}</span>
                    <RxDropdownMenu className="ml-2" />
                  </button>

                  {isStateModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                      <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                        <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                          <h2 className="text-lg font-semibold text-CustomBlue">
                            Select State / Province
                          </h2>

                          <input
                            type="text"
                            placeholder="Search state province..."
                            value={searchQueryState}
                            onChange={handleSearchChangeState}
                            className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-customGray focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
                          />
                          <button
                            onClick={() => setIsStateModalOpen(false)}
                            className="text-customGray hover:text-gray-700 text-2xl"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="flex-1 overflow-auto p-4">
                          {filteredStateProvinces.length > 0 ? (
                            <div className="grid grid-cols-3 gap-4">
                              {filteredStateProvinces
                                .filter(
                                  (stateP) =>
                                    stateP.State_Name.toLowerCase().includes(
                                      searchQueryState.toLowerCase()
                                    ) ||
                                    stateP.state
                                      .toLowerCase()
                                      .includes(searchQueryState.toLowerCase())
                                )
                                .map((stateP, index) => (
                                  <div
                                    key={stateP.id}
                                    className={`flex items-center ${
                                      index % 2 === 0 ? "bg-sky-blue-200" : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="country"
                                      value={stateP.state}
                                      checked={selectedState === stateP.state}
                                      onChange={handleCheckboxChangeForState}
                                      className="mr-2"
                                      disabled={isViewMode}
                                    />
                                    <label>{stateP.state}</label>
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <p>Loading State...</p>
                          )}
                        </div>

                        <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                          {!isViewMode && (
                            <button
                              onClick={() => setIsStateModalOpen(false)}
                              className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    State / Province Name
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={stateName}
                    disabled={isViewMode}
                    // readOnly

                    className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-400 focus:outline-none hover:border-blue-400 ml-4"
                  />
                  {/* <select
                  disabled
                    value={state}
                    onChange={(e) => setstate(e.target.value)}
                    className="border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4"
                  >
                    <option value="" disabled>Select State / Province</option>
                    {stateProvinces.map((statePName) => (
                      <option key={statePName.id} value={statePName.id}>
                        {statePName.State_Name}
                      </option>
                    ))}
                  </select> */}
                  {/* {errors.countryId && <p className="text-red-500 text-sm ml-4">{errors.countryId}</p>} */}
                </div>
              </div>

              {/* <div className="grid grid-cols-2 gap-4 mt-2">
            </div> */}

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    District
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={district}
                    onChange={(e) => setdistrict(e.target.value)}
                    disabled={isViewMode}
                    placeholder="2A"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Postal Code
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={postalCode}
                    onChange={(e) => setpostalCode(e.target.value)}
                    disabled={isViewMode}
                    placeholder="B name"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    Zip Code
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={zipCode}
                    onChange={(e) => setzipCode(e.target.value)}
                    disabled={isViewMode}
                    placeholder="2A"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>

                <div className="flex items-center">
                  <label className="w-48 text-sm font-medium text-customGray">
                    PO Box
                  </label>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={poBox}
                    onChange={(e) => setpoBox(e.target.value)}
                    disabled={isViewMode}
                    placeholder="B name"
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </div>
              </div>
            </div>
            <CustomButton onClick={nextToAccount} title={"Next"} className={"mb-12"}/>

          </div>
        )}
        {activeTab === "account" && (
          <div className="flex flex-col space-y-8 col-span-1 p-10">
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
                <label className="flex items-center w-full">
                  <span className="w-48 font-small text-customGray ">
                    Source System Currency Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={sourceSystemCurrencyCode}
                    onChange={(e) => {
                      setSourceSystemCurrencyCode(e.target.value);
                    }}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  
                    disabled={isViewMode}
                  />
                </label>
              </div>

              <div className="flex items-center">
                <label className="flex items-center w-full">
                  <span className="w-48 font-small text-customGray whitespace-nowrap">
                    Chart Of Account Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={chartOfAccountingCode}
                    onChange={(e) => {
                      setChartOfAccountingCode(e.target.value);
                    }}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"

                    disabled={isViewMode}
                  />
                </label>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div className="flex items-center">
                <label className="flex items-center w-full">
                  <span className="w-48 font-small text-customGray whitespace-nowrap">
                  Accounting System 
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={accountingSystem}
                    onChange={(e) => {
                      setAccountingSystem(e.target.value);
                    }}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  
                    disabled={isViewMode}
                  />
                </label>
              </div>

              <div className="flex items-center">
                <label className="flex items-center w-full">
                  <span className="w-48 font-small text-customGray whitespace-nowrap">
                    Accouting Period Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    value={accountingPeriodCode}
                    onChange={(e) => {
                      setAccountingPeroidCode(e.target.value);
                    }}
                    className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"

                    disabled={isViewMode}
                  />
                </label>
              </div>
            </div>
            <CustomButton onClick={nextToContact} title={"Next"}/>

          </div>
        )}
        {activeTab === "contact" && (
          <div className="flex flex-col space-y-8 col-span-1 p-10">
          <label className="flex items-center w-full">
              <span className="w-48 font-small text-customGray">Contact Person Number</span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={contactPersonName}
                onChange={(e) => {
                  setContactPersonName(e.target.value);
                }}
                className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"

                disabled={isViewMode}
              />
            </label>
            <label className="flex items-center w-full">
              <span className="w-48 font-small text-customGray">Mobile No</span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={mobileNo}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                }}
                className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"

                disabled={isViewMode}
              />
            </label>
            <label className="flex items-center w-full">
              <span className="w-48 font-small text-customGray">Email</span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"

                disabled={isViewMode}
              />
            </label>
            <label className="flex items-center w-full">
              <span className="w-48 font-small text-customGray">Telephone</span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={telephone}
                onChange={(e) => {
                  setTelephone(e.target.value);
                }}
                className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"

                disabled={isViewMode}
              />
            </label>
            <label className="flex items-center w-full">
              <span className="w-48 font-small text-customGray">Fax No</span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={faxNo}
                onChange={(e) => {
                  setFaxNo(e.target.value);
                }}
                className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"

                disabled={isViewMode}
              />
            </label>
          </div>
        )}
      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
          />
        )}
      </Footer>
    </div>
  );
}
