import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate } from 'react-router-dom';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import UploadComponent from '../../../../../../components/UploadComponent';
import * as XLSX from "xlsx";
import { useToast } from '../../../../../../components/toast/toast';
import UserService from '../../../../../../rest-services/UserServices';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';

export default function BankBranchUpload() {
    const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
      const [isError, setIsError] = useState(false);
      const [buttonText, setButtonText] = useState("Upload from desktop");
      const [isUploaded, setIsUploaded] = useState(false);
      const [tableData, setTableData] = useState([]);
      const [showConfirmation, setShowConfirmation] = useState(false);
    const [assignee, setAssignee] = useState({ id: "", username: "" });
    const [alert, setAlert] = useState(null);
    
      const [isDisplayChecked, setIsDisplayChecked] = useState(false); // State for checkbox
      const [isUploadChecked, setIsUploadChecked] = useState(false); // State for checkbox
      const [displayOption, setDisplayOption] = useState(""); // Default value
      const [uploadOption, setUploadOption] = useState(""); // Default value
        const [stateCodes, setStateCodes] = useState([]);
      

    const allowedFormat = ["Action","SLNo","BankBranch","BankBranchName","BankId","CopyTheBankIDAddress","DoorNo","FloorNo","BuildingNo","BuildingName","LandMark","StreetNo","StreetName","RoadNo","RoadName","Block","AreaName","Phase","Sector","Village","Town","CityName","AddressLine1","AddressLine2","AddressLine3","CountryId","StateProvince","District","PostalCode","ZipCode","POBox","BankBic","CountryCode","CountryCodeType","CustomerCode","MICR","BankCode","BranchCode","Min","Max","IBANRequired"]; 

    
    useEffect(() => {
      fetchAssignee();
    fetchStateProvinceCode();

    }, []);
        
    const fetchAssignee = async () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            id: userDetail.id || "",
            clientId: userDetail.clientId || "",
            assigner: userDetail.username || "",
            userId: userDetail.userId || "",
  
          };
  
          setAssignee(assigneeData);
  
          // Log the id and assigner values to the console
          // console.log("Assignee ID:", assigneeData.id);
          // console.log("Assigner:", assigneeData.assigner);
          // console.log("Assigner clientId:", assigneeData.clientId);
          // console.log("Assigner userId:", assigneeData.userId);
  
        } else {
          setAlert({
            severity: "error",
            message: "No user detail found in session storage.",
          });
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching user details: ${error.message}`,
        });
      }
    };

    const fetchStateProvinceCode = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/get-stateprovince-code`
        );
        console.log("response. state data",response.data.data);
        setStateCodes(response.data.data);
        // console.log("Set user types data", response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
      };

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Company & Bank Data") {
          navigate("/2/master-data");
        } else if (crumb.title === "Bank Branch") {
          navigate("/2/master-data/11/bankbranch");
        } else if (crumb.title === "Primary Data") {
          navigate("/2/master-data");
        } else if (crumb.path) {
          navigate(crumb.path);
        }
      };
      const cancelDeletion = () => {
        setShowConfirmation(false);
      };
      
      const confirmDeletion = async () => {
        console.log("Download the excel sheet");
        // Simulate the download by creating an anchor element
        const fileUrl = "/Bank_Branch_Upload.xlsx"; 
        console.log("file ur is",fileUrl);
        const anchor = document.createElement("a");
        anchor.href = fileUrl;
        anchor.download = "Bank_Branch_Upload.xlsx"; // Set the file name for download
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }


          const handleFileChange = (e) => {
                      const file = e.target.files[0];
                      if (!file) return;
                  
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: "array" });
                        const sheetName = workbook.SheetNames[0]; // Get first sheet
                        const sheet = workbook.Sheets[sheetName];
                  
                        // Ensure we read only headers
                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                  
                        // console.log("Raw Sheet Data:", jsonData); // Debugging: Check the first row
                  
                        if (jsonData.length === 0) {
                          setButtonText("File Format is Incorrect !");
                          setIsUploaded(false);
                          setIsError(true);
                          return;
                        }
                  
                        // Extract headers from the first row
                        const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
                        const expectedHeaders = allowedFormat.map(header => header.trim().toLowerCase());
                  
                        // console.log("Uploaded Headers:", uploadedHeaders);
                        // console.log("Expected Headers:", expectedHeaders);
                  
                        // Validate headers
                        const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));
                  
                        if (isValidFormat) {
                          // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
                          const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);
                  
                        // Convert Excel date serial numbers to readable dates
                        const formattedRows = rows.map((row) => {
                          const formattedRow = { ...row };
                
                      
                          return formattedRow;
                        });
                      
                  
                        console.log("set Table Data:", formattedRows);
                  
                        setTableData(formattedRows);
                          setButtonText("File Uploaded");
                          setIsUploaded(true);
                          setIsError(false);
                        } else {
                          setButtonText("File Format is Incorrect !");
                          setIsUploaded(false);
                          setIsError(true);
                          setShowConfirmation(true);
                        }
                  
                        setTimeout(() => setIsUploaded(false), 1000);
                      };
                      reader.readAsArrayBuffer(file);
                    };

                    
                    const getExistingUserIds = async (tableData) => {
                      try {
                        console.log("userIds", tableData);
            
                          // Filter valid rows from the table data
                          const validRows = tableData.filter((row, index) => isRowValid(row, index));
                          console.log("valid rows checking in upload:", validRows);
                    
                          // BankID valid data get
                        const response1 = await UserService.saveContactDetails(
                          `${process.env.REACT_APP_BASE_URL}/get-BankIDGreen-duplicate/BankId`,
                          { validRows}
                        );
                        console.log("Bank id's : Response data", response1.data);
                        const existingBankIDs = response1.data; // Extract existing user IDs from API response
                      
                        // Remove duplicates based on API response
                        const CheckBoxBasedCountryStateValidRows = await filterData(validRows, existingBankIDs);
            
                        console.log("CheckBox Based Country State ValidRows  removed data",CheckBoxBasedCountryStateValidRows);

            
                        
                        // Bank Branch valid Get data 
                        // Make API call to fetch existing user IDs
                        const response = await UserService.saveContactDetails(
                          `${process.env.REACT_APP_BASE_URL}/get-BankBranch-duplicate/BankBranch`,
                          { validRows:CheckBoxBasedCountryStateValidRows }
                        );
              
                        console.log("errored records Bank id's : Response data", response.data);
                        const existingUserIds = response.data; // Extract existing user IDs from API response
                        console.log("existing Bank Branch exists",existingUserIds);

                        const withoutDuplicateUserIds = await filterExistingUserIds(CheckBoxBasedCountryStateValidRows, existingUserIds);
                   
                        // Further filter the results to ensure only valid rows are returned
                        // const validWithoutDuplicateUserIds = withoutDuplicateUserIds.filter((row) =>
                        //   isRowValid(row)
                        // );
                    
                        console.log("successfuland processed Records data:", withoutDuplicateUserIds);
                    
                        return withoutDuplicateUserIds;
                    
                      } catch (error) {
                        console.error("Error fetching existing user IDs:", error);
                        return [];
                      }
                    };
              
                    const filterExistingUserIds = async (CheckBoxBasedCountryStateValidRows, existingUserIds) => {
                      return CheckBoxBasedCountryStateValidRows.filter((row) => existingUserIds.includes(row.BankBranch));
                    };
                    const filterExistingBankIds = async (validRows, existingBankIDs) => {
                      return validRows.filter((row) => existingBankIDs.includes(row.BankId));
                    }

                    const filterData = async (validRows, existingBankIDs) => {
                      // Remove duplicates based on API response
                      const withoutDuplicateBankIDs = await filterExistingBankIds(validRows, existingBankIDs);
                      console.log("BankId removed data", withoutDuplicateBankIDs);
                    
                      // Step 1: Extract BankBranch values where CopyTheBankIDAddress exists
                      const directBankBranches = withoutDuplicateBankIDs
                        .filter(row => {
                            if (row.StateProvince && !row.CountryId) {
                                return false;
                            }
                            if (row.CountryId && row.StateProvince) {
                              return false; // Exclude rows where both CountryId and StateProvince exist
                          }
                            return row.CopyTheBankIDAddress;
                        })
                        .map(row => row.BankBranch);
                      
                      console.log("Direct bank branches (from CopyTheBankIDAddress):", directBankBranches);
                    
                      // Step 2: Process when CopyTheBankIDAddress is empty
                      const toProcessRows = withoutDuplicateBankIDs.filter(row => !row.CopyTheBankIDAddress);
                      // const toProcessRows = withoutDuplicateBankIDs.filter(row => !row.CopyTheBankIDAddress || (row.row.CountryId && row.StateProvince && row.CopyTheBankIDAddress) || (row.row.CountryId && row.CopyTheBankIDAddress));

                      console.log("Step 2 process data",toProcessRows);
                      
                      let existsCountryStateValidBankBranch = [];
                    
                      if (toProcessRows.length > 0) {
                       
                        // Country Code valid data get
                        const response4 = await UserService.saveContactDetails(
                          `${process.env.REACT_APP_BASE_URL}/get-CountryID-exists-only`,
                          { validRows: toProcessRows }
                        );
                        console.log("Check Country code is exists", response4.data);
                        const existingCountryIds = response4.data;
                    
                        const ExistCountryIdValidRows = await filterExistingCountryIds(toProcessRows, existingCountryIds);
                        console.log("Successful and row valid in countryID filters", ExistCountryIdValidRows);
                    
                        const getExistingCountryNotExistingStateData = ExistCountryIdValidRows
                        .filter(row => row.CountryId && !row.StateProvince)
                        .map(row => row.BankBranch);
                      
                      console.log("Direct bank branches (from country Id):", getExistingCountryNotExistingStateData);
                    
                        
                        // State Code and Country valid data get
                        const response5 = await UserService.saveContactDetails(
                          `${process.env.REACT_APP_BASE_URL}/get-StateID-exists-only-1`,
                          { validRows: ExistCountryIdValidRows }
                        );
                        console.log("Check state code is exists", response5.data);
                        const existingstateIds = response5.data;
                    
                        const ExiststateIdValidRows = await filterExistingstateIds(ExistCountryIdValidRows, existingstateIds);
                        console.log("Successful and row valid in stateID filters", ExiststateIdValidRows);
                    
                        // Step 3: Extract BankBranch values from processed data
                        // existsCountryStateValidBankBranch = ExiststateIdValidRows.map(row => row.BankBranch);
                        existsCountryStateValidBankBranch = [
                          ...getExistingCountryNotExistingStateData,
                          ...ExiststateIdValidRows.map(row => row.BankBranch)
                        ];

                        console.log("Exists country state valid BankBranch",existsCountryStateValidBankBranch);
                      }
                    
                      // Step 4: Merge both arrays
                      const finalBankBranches = [...new Set([...directBankBranches, ...existsCountryStateValidBankBranch])];
                      console.log("Final Bank Branches (merged):", finalBankBranches);
                    
                      // Step 5: Filter withoutDuplicateBankIDs based on finalBankBranches
                      const filteredData = withoutDuplicateBankIDs.filter(row =>
                        finalBankBranches.includes(row.BankBranch)
                      );
                    
                      console.log("Final Filtered Data:", filteredData);
                    
                      return filteredData;
                    };
                    
              
                    const filterExistingCountryIds = async (withoutDuplicateBankIDs, existingCountryIds) => {
                      return withoutDuplicateBankIDs.filter((row) => existingCountryIds.includes(row.CountryId));
                    };
                  
                    // const filterExistingstateIds =  async (ExistCountryIdValidRows, existingstateIds) => {
                    //   return ExistCountryIdValidRows.filter((row) => existingstateIds.includes(row.StateProvince));
                    // };

                    const filterExistingstateIds = async (ExistCountryIdValidRows, existingstateIds) => {
                      // Convert existing state-country pairs into a Set for quick lookup
                      const existingStateCountrySet = new Set(
                        existingstateIds.map(({ state, country }) => `${state}-${country}`)
                      );
                    
                      // Filter valid rows based on matching state and country
                      return ExistCountryIdValidRows.filter(
                        (row) => existingStateCountrySet.has(`${row.StateProvince}-${row.CountryId}`)
                      );
                    };

      const isRowValid = (row, index) => {
        // Common validations
        const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
        const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
        // const isBankBranchValid = row.BankBranch && String(row.BankBranch).length <= 10;
        const isBankBranchValid = row.BankBranch && String(row.BankBranch).trim().length <= 10;
        const isAddress1Valid = row.AddressLine1 ? String(row.AddressLine1).length <= 50 : true;
        const isAddress2Valid = row.AddressLine2 ? String(row.AddressLine2).length <= 50 : true;
        const isAddress3Valid = row.AddressLine3 ? String(row.AddressLine3).length <= 50 : true;
         
        let errors = [];
    
        if (!isActionValid) errors.push("Invalid Action");
        if (!isSLNoValid) errors.push("Invalid SLNo");
        if (!isBankBranchValid) errors.push("Invalid BankBranch");
         
        if (!isAddress1Valid) errors.push("Invalid Address1");
        if (!isAddress2Valid) errors.push("Invalid Address2");
        if (!isAddress3Valid) errors.push("Invalid Address3");

      


        // Action "A" or "a" specific validations
        if (row.Action === "A" || row.Action === "a") {
          const isBankBranchNameValid = row.BankBranchName && String(row.BankBranchName).length <= 150;
          const isBankIdValid = row.BankId && String(row.BankId).length <= 10;

          
        
            // Check required address fields only if CopyTheBankIDAddress is NOT present
            if (!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === "") {
                // Check if at least one of DoorNo, BuildingNo, BuildingName, or Address1 is filled
                const hasRequiredInfo = !!(row.DoorNo || row.FloorNo || row.BuildingNo || row.BuildingName || 
                  row.LandMark || row.StreetNo || row.StreetName || row.RoadNo || row.RoadName || row.Block ||
                  row.AreaName || row.Phase || row.Sector || row.Village || row.Town || row.CityName || 
                  row.AddressLine1 || row.AddressLine2 || row.AddressLine3 );
                  const isCountryIdValid = row.CountryId && String(row.CountryId).length <= 2;

              if (!hasRequiredInfo) {
                  errors.push("At least one of DoorNo, FloorNo, BuildingNo, or BuildingName is required");
              }
              if (!isCountryIdValid) errors.push("Invalid CountryId");

              return (
                hasRequiredInfo &&
                isCountryIdValid

            );
          }

          // if (!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === "") {
          //   const isCountryIdValid = row.CountryId && String(row.CountryId).length <= 2;
          //     if (!isCountryIdValid) errors.push("Invalid CountryId");
          //       return (
          //         isCountryIdValid
          //       );
          // }
        // Only check required address fields when CopyTheBankIDAddress is NOT present
        // if (!row.CopyTheBankIDAddress && !hasRequiredInfo) {
        //   errors.push("At least one of DoorNo, FloorNo, BuildingNo, or BuildingName is required");
        // }

          if (!isBankBranchNameValid) errors.push("Invalid Bank Branch Name");
          if (!isBankIdValid) errors.push("Invalid BankId");
    
          
            if (errors.length > 0) {
                console.log(`Row ${index + 1} is invalid:`, errors);
            }
    
            return (
                isBankBranchNameValid &&
                isBankIdValid 
                 
            );
        }
    
        // Action "E" or "e" specific validations
        if (row.Action === "E" || row.Action === "e") {
          console.log("enter in the Action E");

            const isBankBranchNameValid = row.BankBranchName ? String(row.BankBranchName).length <= 150 : true;
            const isBankIdValid = row.BankId ? String(row.BankId).length <= 30 : true;
            const isCountryIdValid = row.CountryId ? String(row.CountryId).length <= 2 : true;
    
            if (!isBankBranchNameValid) errors.push("Invalid LegalEntityName");
            if (!isBankIdValid) errors.push("Invalid BankId");
            if (!isCountryIdValid) errors.push("Invalid CountryId");
    
            if (errors.length > 0) {
                console.log(`Row ${index + 1} is invalid:`, errors);
            }
    
            return (
                isBankBranchNameValid &&
                isBankIdValid &&
                isCountryIdValid &&
                isAddress1Valid &&
                isAddress2Valid &&
                isAddress3Valid
            );
        }

    //     if(!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === ""){
    //       console.log("inside the copy bank id address");

    //       if(row.Action === 'A' || row.Action === 'a' || row.Action === 'E' || row.Action === 'e'){
    //         console.log("inside the action button");

            
    //       }
    //     }
    // else{
    //   console.log("Outside the copy address");
    // }
        if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
    
        return isActionValid && isSLNoValid && isBankBranchValid;
    };

    
      const getDataToDisplay = async () => {

        let dataToDisplay;
        if (uploadOption === "3") {
        console.log("displayOption is 3");
        dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
        // dataToDisplay = successfulRecords; // Display successfulRecords if uploadOption is "3"
        } else {
        console.log("displayOption not selected");
        dataToDisplay = tableData; // Default to tableData if no conditions are met
        }
        
      console.log("dataToDisplay data is",dataToDisplay);
      return dataToDisplay;
      };

// const dataToDisplay = getDataToDisplay(displayOption, uploadOption, tableData);



const isExistingStateId = async (StateProvince) =>{
  try {
      console.log("exisitng user id",StateProvince);
      const data = { StateCode:StateProvince };
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-StateCode-duplicates`,
        data);
      console.log("existing state details:", response);
  
      // if (response?.CountryID !== undefined) {
      //   return response.CountryID; // Return the CountryID if found
      // }
      // return false; // Return false if CountryID is null
      return response?.StateID ? response : false; 
    } catch (error) {
      console.error("Error checking existing userId:", StateProvince, error);
      return false;
    }
  };

const isExistingCountryId = async (CountryId) =>{
  try {
      console.log("exisitng user id",CountryId);
      const data = { CountryCode:CountryId };
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-CountryCode-duplicates`,
        data);
      console.log("existingUserId details:", response);
  
      // if (response?.CountryID !== undefined) {
      //   return response.CountryID; // Return the CountryID if found
      // }
      // return false; // Return false if CountryID is null
      return response?.CountryID ? response : false; 
    } catch (error) {
      console.error("Error checking existing userId:", CountryId, error);
      return false;
    }
  };

const isExistingGreenBankId = async (BankId) => {
  try {
    console.log("exisitng user id",BankId);
    const data = { BankId };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BankIDGreenCode-duplicates`,
      data);

    console.log("existingUserId details:", response);

    // return response && response.BankId !== null; // Return true if userId exists, false otherwise
    return response?.BankId ? response : false; 
  } catch (error) {
    console.error("Error checking existing userId:", BankId, error);
    return false;
  }
};
const  isExistingBankId = async (BankId) => {
  try {
    console.log("exisitng New Bank id",BankId);
    const data = { BankId };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BankId-duplicates`,
      data);

    console.log("existingBankId details:", response.BankId);
    return response?.BankId ? response : false; 
    // return response && response.BankId !== null; // Return true if userId exists, false otherwise
  } catch (error) {
    console.error("Error checking existing userId:", BankId, error);
    return false;
  }
};

const isExistingUserId = async (BankBranch) => {
  try {
    console.log("exisitng bank branch id",BankBranch);
    const data = { BankBranchid: BankBranch };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BankBranch-duplicates`,
      data);

    console.log("existingUserId details:", response.BankBranch);

    return response && response.BankBranch !== null; // Return true if userId exists, false otherwise
  } catch (error) {
    console.error("Error checking existing userId:", BankBranch, error);
    return false;
  }
};



async function checkDuplicateUserIdsFromDB(BankBranchs) {
  try {
    const data = { BankBranchs };
    console.log("BankBranchs duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BankBranches-duplicates`,
      data
    );

    // console.log("API Response for response details:", response);
    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}
const processData = async (data) => {
  // Extract all userIds from dataToDisplay
  
  if (!Array.isArray(data)) {
    console.error("processData received invalid data:", data);
    return []; // Return an empty array to prevent further errors
  }
  console.log("processData received  data:", data);

  const BankBranchs = data.map(row => row.BankBranch).filter(Boolean);
  console.log("Extracted BankBranch's:", BankBranchs);

  const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(BankBranchs);


   // Preprocess table data to add conditional rendering for userId and username
  //  return data.map((row, _, allRows) => {
    return Promise.all(data.map(async (row, _, allRows) => {
    // const rowInvalid = isRowInvalid(row);
    // Define the max allowed characters for each column (this can come from your column definitions)
  
      // Check for duplicate userId in dataToDisplay
  const isDuplicateUserId = allRows.filter(r => r.BankBranch == row.BankBranch).length > 1;
console.log("Duplicate user id is ",isDuplicateUserId);

 // Check for duplicate userId in the database
 const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.BankBranch);

 const userExists = await isExistingUserId(row.BankBranch);
 const BankIDExists = await isExistingBankId(row.BankId);
 const BankIDGreenExists = await isExistingGreenBankId(row.BankId);
 const countryIDExists = await isExistingCountryId(row.CountryId);
//  const StateIDExists = await isExistingStateId(row.StateProvince);
const StateIDExists = stateCodes.some(
  (code) => 
    code.state.trim().toUpperCase() === row.StateProvince?.trim().toUpperCase() &&
    code.countryCode.trim().toUpperCase() === row.CountryId?.trim().toUpperCase()
);


 console.log(`BankBranch id ${row.BankBranch} exists:`, userExists);
 console.log(`Bank id ${row.BankId} exists:`, BankIDExists);
 console.log(`Bank approved id ${row.BankId} exists:`, BankIDGreenExists);
console.log(`Country ID is ${row.CountryId} exists:`, countryIDExists);
console.log(`state ID is ${row.StateProvince} exists:`, StateIDExists);
 // Check for errors (red text) and apply highlight based on that

const hasNotError = [
  row.SLNo,
  row.Action,
  row.BankBranch,
  row.BankBranchName,
  row.BankId,
  row.AddressLine1,
  row.AddressLine2,
  row.AddressLine3,
  row.CountryId,
  row.StateProvince,
  
].every(field => {
  let isValid = true;
  let reason = "";
  
  if (field === row.SLNo) {
    if (!field) { isValid = false; reason = "SLNo should not be blank."; }
    else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
    else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
  }
  
  else if (field === row.Action) {
    if (!field) { isValid = false; reason = "Action should not be blank."; }
    else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
  }
  
  
//   if (!row.BankBranch || String(row.BankBranch).trim() === "") {
//     if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
//       isValid = false;
//       reason = "BankBranch should not be empty.";
//   }
// else if (field === row.BankBranch) {
//   if (String(field).length > 10) { isValid = false; reason = "BankBranch ID exceeds max length of 10."; }
//   else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
//     isValid = false; reason = "BankBranch ID does not exist.";
//   }
//   else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
//     isValid = false; reason = "BankBranch ID is duplicate.";
//   }
// }
// }

if (!row.BankBranch || String(row.BankBranch).trim() === "") {
  isValid = false;
  reason = "BankBranch should not be empty.";
}
// if (!field) { isValid = false; reason = "BankBranch ID should not be blank."; }
else if (String(row.BankBranch).length > 10) { isValid = false; reason = "BankBranch ID exceeds max length of 10."; }
else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
isValid = false; reason = "BankBranch ID does not exist.";
}
else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
isValid = false; reason = "BankBranch ID is duplicate.";
}


if (!row.BankBranchName || String(row.BankBranchName).trim() === "") {
  if (row.Action === "A" || row.Action === "a") {
    isValid = false;
    reason = "BankBranchName should not be empty.";
}
} else if (field === row.BankBranchName) {
    if (row.Action === "E" || row.Action === "e") {
        if (!field.trim()) {
            isValid = true;
            reason = "BankBranchName should be blank for Edit action.";
        } else if (String(field).trim().length > 150) {
            isValid = false;
            reason = "BankBranchName exceeds max length of 150.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (!field.trim()) {
            isValid = false;
            reason = "BankBranchName should not be blank for Add action.";
        } else if (String(field).trim().length > 150) {
            isValid = false;
            reason = "BankBranchName exceeds max length of 150.";
        }
    }
}


if (!row.BankId || String(row.BankId).trim() === "") {
  if (row.Action === "A" || row.Action === "a") {
    isValid = false;
    reason = "BankId should not be empty.";
}
} else if (field === row.BankId) {
  if(BankIDExists){
    if(BankIDGreenExists){
      isValid = true;
      reason = "BANKID is exists";
    }
    else{
      isValid = false;
      reason = "BANKID is not exists because of the flag status"
    }
  }
  else{
    isValid = false;
      reason = "BANKID is not exists "
  }
    if (row.Action === "E" || row.Action === "e") {
        if (!field.trim()) {
            isValid = true;
            reason = "BankId should be blank for Edit action.";
        } else if (String(field).trim().length > 10) {
            isValid = false;
            reason = "BankId exceeds max length of 10.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (!field.trim()) {
            isValid = false;
            reason = "BankId should not be blank for Add action.";
        } else if (String(field).trim().length > 10) {
            isValid = false;
            reason = "BankId exceeds max length of 10.";
        }
    }
  }

// else if (field === row.BankName) {
// if (row.Action === "E" || row.Action === "e") {
// if (!field) { isValid = true; reason = "BankName should be blank for Edit action."; }
// else if (String(field).length > 150) { isValid = false; reason = "BankName exceeds max length of 150."; }
// } else if (row.Action === "A" || row.Action === "a") {
// if (!field) { isValid = false; reason = "BankName should not be blank for Add action."; }
// if (String(field).length > 150) { isValid = false; reason = "BankName exceeds max length of 150."; }
// }
// }


  // else if (field === row.addressLine1) {
  //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //     if (!field) { isValid = true; reason = "addressLine1 should be blank for Add/Edit action."; }
  //   }
  //   if (String(field).length > 50) { isValid = false; reason = "addressLine1 exceeds max length of 50."; }
  // }
  // console.log("row of copy address",row.CopyTheBankIDAddress);

  // if(!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === ""){
  //   console.log("enter in the copy adddress condition");

    
  //   if (row.AddressLine1 && String(row.AddressLine1).length > 50) {
  //     isValid = false;
  //     reason = "addressLine1 exceeds max length of 50.";
  // } else {
  //     const missingFields = [
  //         row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
  //         row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
  //         row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
  //         row.CityName, row.AddressLine1, row.AddressLine2, row.AddressLine3
  //     ].every(value => !value); // Check if all values are falsy

  //     if (row.Action === "E" || row.Action === "e") {
  //         if (!field) {
  //             isValid = true;
  //             reason = "addressLine1 should be blank for Edit action.";
  //         }
  //         else if (field.length > 50) { 
  //           isValid = false; 
  //           reason = "addressLine1 exceeds max length of 50."; 
  //         }
  //     } else if (row.Action === "A" || row.Action === "a") {
  //         console.log("Address field 1 enter log");

  //         if (missingFields) {
  //             isValid = false;
  //             reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and addressLine1 should not be blank for Add action.";
  //         }
  //     }
  // }


  //   if (field === row.AddressLine2) {
  //     if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //       if (!field) { isValid = true; reason = "AddressLine2 should be blank for Add/Edit action."; }
  //     }
  //     if (String(field).length > 50) { isValid = false; reason = "AddressLine2 exceeds max length of 50."; }
  //   }
  
  //   if (field === row.AddressLine3) {
  //     if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //       if (!field) { isValid = true; reason = "AddressLine3 should be blank for Add/Edit action."; }
  //     }
  //     if (String(field).length > 50) { isValid = false; reason = "AddressLine3 exceeds max length of 50."; }
  //   }

  //   }

  //   else{
     
    
  // if (row.AddressLine1 && String(row.AddressLine1).length > 50) {
  //   isValid = false;
  //   reason = "addressLine1 exceeds max length of 50.";
  //   } else {
  //   // field = row.addressLine1 || "";  
    
  //   const missingFields = [
  //     row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
  //     row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
  //     row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
  //     row.CityName,  row.AddressLine1, row.AddressLine2, row.AddressLine3
  //   ].every(value => !value); // Check if all values are falsy (undefined, null, empty, false)
    
  //   if (row.Action === "E" || row.Action === "e") {
  //     if (!field) { 
  //         isValid = true; 
  //         reason = "addressLine1 should be blank for Edit action."; 
  //     } else if (field.length > 50) { 
  //         isValid = false; 
  //         reason = "addressLine1 exceeds max length of 50."; 
  //     }
  //   } else if (row.Action === "A" || row.Action === "a") {
  //     console.log("Address field 1 enter log");
  //     console.log("check door no:", row.DoorNo);
    
  //     if (missingFields) { 
  //         isValid = false; 
  //         reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and Address1 should not be blank for Add action."; 
  //     }
  //   }
  //   }
  //   }





    if(!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === ""){
      console.log("enter in the copy adddress condition");
      
      
      if (row.AddressLine1 && String(row.AddressLine1).length > 50) {
      isValid = false;
      reason = "AddressLine1 exceeds max length of 50.";
      } else {
      const missingFields = [
          row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
          row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
          row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
          row.CityName, row.AddressLine1, row.AddressLine2, row.AddressLine3,
      ].every(value => !value); // Check if all values are falsy
      
      if (row.Action === "E" || row.Action === "e") {
          if (!field) {
              isValid = true;
              reason = "AddressLine1 should be blank for Edit action.";
          }
          else if (field.length > 50) { 
            isValid = false; 
            reason = "AddressLine1 exceeds max length of 50."; 
          }
      } else if (row.Action === "A" || row.Action === "a") {
          console.log("Address field 1 enter log");
      
          if (missingFields) {
              isValid = false;
              reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and AddressLine1 should not be blank for Add action.";
          }
      }
      }
      
      if (field === row.AddressLine2) {
      if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        if (!field) { isValid = true; reason = "AddressLine2 should be blank for Add/Edit action."; }
      }
      if (String(field).length > 50) { isValid = false; reason = "AddressLine2 exceeds max length of 50."; }
      }
      
      if (field === row.AddressLine3) {
      if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        if (!field) { isValid = true; reason = "AddressLine3 should be blank for Add/Edit action."; }
      }
      if (String(field).length > 50) { isValid = false; reason = "AddressLine3 exceeds max length of 50."; }
      }
      

      if (!row.CountryId || String(row.CountryId).trim() === "") {
        if (row.Action === "A" || row.Action === "a") {
          isValid = false;
          reason = "CountryId should not be empty.";
      }
      } else if (field === row.CountryId) {
        if(countryIDExists){
          isValid = true;
          reason = "country Code is exists";
        }
        else{
          isValid = false;
          reason = "country Code is not exists";
        }
      
          if (row.Action === "E" || row.Action === "e") {
              if (!field.trim()) {
                  isValid = true;
                  reason = "CountryId should be blank for Edit action.";
              } else if (String(field).trim().length > 2) {
                  isValid = false;
                  reason = "CountryId exceeds max length of 2.";
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (!field.trim()) {
                  isValid = false;
                  reason = "CountryId should not be blank for Add action.";
              } else if (String(field).trim().length > 2) {
                  isValid = false;
                  reason = "CountryId exceeds max length of 2.";
              }
          }
      }
  
     if (field === row.StateProvince) {
      if ((row.Action === "A" || row.Action === "a") && (!row.CountryId || String(row.CountryId).trim() === "")) {
        isValid = false;
        reason = "StateProvince should not be blank for Add action.";
    } else 
      if ((row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") && (row.CountryId)) {
        if (!field) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
        else {
          if (!StateIDExists) { 
            isValid = false;
            reason = "State/Province is Not exists."; 
          } 
          if (String(field).length > 3) { isValid = false; reason = "StateProvince exceeds max length of 3."; }
        }
      }
      
    }


      }
      else{
        if (row.StateProvince) {
          if ((row.Action === "A" || row.Action === "a") && (!row.CountryId || String(row.CountryId).trim() === "")) {
            isValid = false;
            reason = "StateProvince should not be blank for Add action.";
        } else 
          if ((row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") && (row.CountryId)) {
            if (!row.StateProvince) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
            else {
              if (!StateIDExists) { 
                isValid = false;
                reason = "State/Province is Not exists."; 
              } 
              if (String(row.StateProvince).length > 3) { isValid = false; reason = "StateProvince exceeds max length of 3."; }
            }
          }
          
        }
      
      }
      








  if (!isValid) {
    console.log(`Validation Failed: ${reason}`);
  } else {
    console.log(`Validation Passed: ${field}`);
  }
  
  return isValid;
});

console.log(`Final hasNotError status: ${hasNotError}`);

    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error

      isDuplicateUserId,
      isDuplicateInDB,
      userExists,
      BankIDExists,
      BankIDGreenExists,
      countryIDExists,
      StateIDExists,

      Action: row.Action ? (
        String(row.Action).length === 1 ? (
          ["A", "a", "E", "e"].includes(row.Action) ? (
            row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Only "A", "E" are allowed)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Max. allowed character is 1)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
  
        BankBranch: row.BankBranch ? (
          String(row.BankBranch).length > 10 ? (
            <span style={{ color: "red" }}>
              {row.BankBranch} (Max. allowed character is 10)
            </span>
          ) : row.Action === "A" || row.Action === "a" ? (
            // When Action is "A", show duplicate message if user ID is a duplicate
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span style={{ color: "red" }}>
                {row.BankBranch} (BankBranch Duplicate exists)
              </span>
            ) : (
              String(row.BankBranch) // Otherwise, show BankBranch normally
            )
          ) : row.Action === "E" || row.Action === "e" ? (
            // When Action is "E", show BankBranch without the duplicate message
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span>
                {row.BankBranch} 
              </span>
            ) : (
              // String(row.BankBranch) // Otherwise, show BankBranch normally
              <span style={{ color: "red" }}>
              {row.BankBranch} (BankBranch not exists)
            </span>
            )
          ) : (
            String(row.BankBranch) // Default case, show BankBranch normally
          )
        ) : (
          <span style={{ color: "red" }}>
            Shouldn't be blank
          </span>
        ),

        BankBranchName: row.BankBranchName ? (
          String(row.BankBranchName).length > 150 ? (
            <span style={{ color: "red" }}>
              {row.BankBranchName} (Max. allowed character is 150)
            </span>
          ) : (
            String(row.BankBranchName) // Ensure BankBranchName is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

//         BankId: row.BankId ? (
//           String(row.BankId).length > 30 ? (
//             <span style={{ color: "red" }}>
//               {row.BankId} (Max. allowed character is 30)
//             </span>
//           ) : BankIDExists && displayOption !== '' ? (
//             BankIDGreenExists ?
//             <span>{row.BankId}</span> :
// <span style={{ color: "red" }}>
//     {row.BankId} (Bank Id is Not approved.)
//   </span>
          
//         ) : (
//           row.Action === "A" || row.Action === "a" ? (
//             <span style={{ color: "red" }}>
//               Shouldn't be blank
//             </span>
//           ) : row.Action === "E" || row.Action === "e" ? (
//             <span></span> // Empty span for "E"
//           ) : null // Handle other cases, if any
//         ),


        BankId: row.BankId ? (
          String(row.BankId).length > 30 ? (
            <span style={{ color: "red" }}>
              {row.BankId} (Max. allowed characters: 30)
            </span>
          ) : BankIDExists && displayOption !== "" ? (
            BankIDGreenExists ? (
              <span>{row.BankId}</span>
            ) : (
              <span style={{ color: "red" }}>
                {row.BankId} (Bank ID is not approved.)
              </span>
            )
          ) :(
            <span style={{ color: "red" }}>
            {row.BankId} (Bank ID not exists.)
          </span>
          ) )
          :
          (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),

        
          AddressLine1: !row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === "" ? (
            row.AddressLine1 ? (
            String(row.AddressLine1).length > 50 ? (
              <span style={{ color: "red"}}>
                {row.AddressLine1} (Max. allowed character is 50)
              </span>
            ) : (
                String(row.AddressLine1) // Ensure userId is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
            
            !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.AreaName && !row.Phase && !row.Sector && !row.Village && !row.Town && !row.CityName && !row.AddressLine2 && !row.AddressLine3
            // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
            ? (
              <span style={{ color: "red" }}>
                  Either Address Section or Building, Street, and Area Section must be entered
              </span>
          ) : (
              <span></span>
          )
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          )
        ) : (
          row.AddressLine1 ? (
            String(row.AddressLine1).length > 50 ? (
              <span style={{ color: "red"}}>
                {row.AddressLine1} (Max. allowed character is 50)
              </span>
            ) : (
                String(row.AddressLine1) // Ensure userId is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (                   
                <span></span> // Empty span for "E"
              ) : null // Handle other cases, if any
            )
        ),

        AddressLine2: row.AddressLine2 ? (
          String(row.AddressLine2).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.AddressLine2} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.AddressLine2) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        AddressLine3: row.AddressLine3 ? (
          String(row.AddressLine3).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.AddressLine3} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.AddressLine3) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        CountryId: !row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === "" ? (

        row.CountryId ? (
          String(row.CountryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.CountryId} (Max. allowed character is 2)
            </span>
          )  : countryIDExists && displayOption !== "" ? (
            <span>{row.CountryId}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.CountryId} (Country Code not exists)
            </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        )
      ):(
        row.CountryId ? (
          String(row.CountryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.CountryId} (Max. allowed character is 2)
            </span>
          )  : countryIDExists && displayOption !== "" ? (
            <span>{row.CountryId}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.CountryId} (Country Code not exists)
            </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        )

      ),
      
        StateProvince: row.StateProvince ? (
          String(row.StateProvince).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.StateProvince} (Max. allowed character is 3)
            </span>
          ) : StateIDExists && displayOption !== "" ? (
            <span>{row.StateProvince}</span>
          ):(
            <span style={{ color: "red" }}>
              {row.StateProvince} (StateProvince not exists)
            </span>
          )
        ) : (
          <span>
           
          </span>
        ),
  
      };  
  }));
}
        const storeArrayDataForUsers= async () => {
            // console.log("store successfulRecords data is",dataToDisplay);
        
                    setLoading(true);
                    try {
                    const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
                    console.log("store successfulRecords data 2 is", dataToDisplay);
                
                  let processed=[];

                  if(stateCodes.length > 0){
                    processed = await processData(tableData);
                    // console.log("stateCodes data in store",stateCodes);
                    // console.log("display datas",processed);
                  }
                  console.log("display datas 2",processed);

                    const extractText = (cellValue) => {
                                if (Array.isArray(cellValue)) {
                                return cellValue.join(", "); // Convert array to string
                                } else if (typeof cellValue === "string") {
                                return cellValue;
                                } else if (React.isValidElement(cellValue)) {
                                return cellValue.props.children;
                                }
                                return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                            };
                
                            const formattedUsers = processed.map((user) => {
                
                                return {
                                "Action": extractText(user.Action),
                                "SLNo": extractText(user.SLNo),
                                "BankBranch": extractText(user.BankBranch),
                                "BankBranchName": extractText(user.BankBranchName),
                                "BankId": extractText(user.BankId),
                                "CopyTheBankIDAddress": extractText(user.CopyTheBankIDAddress),
                                "DoorNo": extractText(user.DoorNo),
                                "FloorNo": extractText(user.FloorNo),
                                "BuildingNo": extractText(user.BuildingNo),
                                "BuildingName": extractText(user.BuildingName),
                                "LandMark": extractText(user.LandMark),
                                "StreetNo": extractText(user.StreetNo),
                                "StreetName": extractText(user.StreetName),
                                "RoadNo": extractText(user.RoadNo),
                                "RoadName": extractText(user.RoadName),
                                "Block": extractText(user.Block),
                                "AreaName": extractText(user.AreaName),
                                "Phase": extractText(user.Phase),
                                "Sector": extractText(user.Sector),
                                "Village": extractText(user.Village),
                                "Town": extractText(user.Town),
                                "CityName": extractText(user.CityName),
                                "AddressLine1": extractText(user.AddressLine1 || ""),
                                "AddressLine2": extractText(user.AddressLine2 || ""),
                                "AddressLine3": extractText(user.AddressLine3 || ""),
                                "CountryId": extractText(user.CountryId || ""),
                                "StateProvince": extractText(user.StateProvince),
                                "District": extractText(user.District),
                                "PostalCode": extractText(user.PostalCode),
                                "ZipCode": extractText(user.ZipCode),
                                "POBox": extractText(user.POBox),
                                "BankBic": extractText(user.BankBic),
                                "CountryCode": extractText(user.CountryCode),
                                "CountryCodeType": extractText(user.CountryCodeType),
                                "CustomerCode": extractText(user.CustomerCode),
                                "MICR": extractText(user.MICR),
                                "BankCode": extractText(user.BankCode),
                                "BranchCode": extractText(user.BranchCode),
                                "Min": extractText(user.Min),
                                "Max": extractText(user.Max),
                                "IBANRequired": extractText(user.IBANRequired),
        
                            };
                            });
                            
                                // Insert second and third row at the beginning of the formatted data
                                const rowsToDisplay = [ ...formattedUsers];
                        
                                console.log("Formatted USERS Data:", formattedUsers);
                                console.log("Formatted Data:", rowsToDisplay);
                        
                                
                                const updatedData = rowsToDisplay.map(user => {
                        
                                const isObjectField = ["Action",
                                    "SLNo",
                                    "BankBranch",
                                    "BankBranchName",
                                    "BankId",
                                    "CopyTheBankIDAddress",
                                    "DoorNo",
                                    "FloorNo",
                                    "BuildingNo",
                                    "BuildingName",
                                    "LandMark",
                                    "StreetNo",
                                    "StreetName",
                                    "RoadNo",
                                    "RoadName",
                                    "Block",
                                    "AreaName",
                                    "Phase",
                                    "Sector",
                                    "Village",
                                    "Town",
                                    "CityName",
                                    "AddressLine1",
                                    "AddressLine2",
                                    "AddressLine3",
                                    "CountryId",
                                    "StateProvince",
                                    "District",
                                    "PostalCode",
                                    "ZipCode",
                                    "POBox",
                                    "BankBic",
                                    "CountryCode",
                                    "CountryCodeType",
                                    "CustomerCode",
                                    "MICR",
                                    "BankCode",
                                    "BranchCode",
                                    "Min",
                                    "Max",
                                    "IBANRequired"
        
                                ].some(field => typeof user[field] === 'object' || user[field] === "Shouldn't be blank" || user[field] === "Either Address Section or Building, Street, and Area Section must be entered" );
                                // some(field => typeof user[field] === 'object' );
                
                                    return {
                                    // update: typeof user.UserID === 'object' ? "No" : "Yes",
                                    update: isObjectField ? "No" : "Yes",
                                            ...user,
                                        };
                                    });
                        console.log("updatedData data",updatedData);
                
                        
                        const data = {
                        clientId: assignee.clientId,
                        dataToDisplay,
                        createdBy: assignee.userId,
                        allrecords: updatedData,
                        }
                        console.log("storeArrayDataForBank branch dataToDisplay is",data);
                        const response = await UserService.saveContactDetails(
                        `${process.env.REACT_APP_BASE_URL}/store-array-bankbranch-data`,
                        data
                        );
                        console.log("Roles Response data", response);
                    
                        if (dataToDisplay.length === 0) { 
                        // toast("error", "Please upload the correct file OR containing the data.");
                        toast("error", "Error fields exist.");
                        return;
                    }
                    else{
                        if (response.status === 200) {
                        toast("success", "Uploaded Bank Branch successfully");
                        }
                        else if(response.status === 400){
                        toast("error", "Invalid or empty array of Bank Branch");
                        }
                        else if(response.status === 404){
                        toast("error", "Missing required fields");
                        }
                        else if(response.status === 500){
                        toast("error", "Duplicate Bank Branch IDs found.");
                        console.log("response duplicate Bank Branch id data",response.duplicates)
                        }
                    }
                    } catch (error) {
                        console.error("Error fetching array Bank Branch data", error);
                    }
                    finally {
                            setLoading(false);
                        }
                    };

    const handleSubmit = async () => {
        console.log("submit table data",tableData);
        console.log("table data length is",tableData.length);
    
        if(buttonText === 'Upload from desktop'){
            toast("error", "Please upload a file.");
            return;
        }
        else if (tableData.length === 0) { 
            // alert("Please upload a file before submitting."); // Validation alert
            toast("error", "Please upload the correct file containing the data.");
            // toast("error", "Please upload a file containing at least one record.");
            return;
        }
    
            // Check if either of the checkboxes is selected
        if (!isDisplayChecked && !isUploadChecked) {
            toast("error", "Please check at least one checkbox (Display or Upload).");
            return;
        }
    
    
        // console.log("tableData send to the prop",tableData);
        // console.log("displayOption selected", displayOption);
        // console.log("uploadOption selected", uploadOption);
    
        if(uploadOption !== '' && displayOption === '' ){
        console.log("User arry upload data");
        storeArrayDataForUsers();
        }
        else {
        if(uploadOption != ''){
            storeArrayDataForUsers();
        }
        navigate(`/2/master-data/10/bankbranch-upload/display-screen`, {
                state: { tableData, displayOption, uploadOption } // Passing tableData via state
            });
        }
    }

  return (
    <div>
       <Header title={`Primary Data > Company & Bank Data > Bank Branch > Upload `}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/2/master-data/11/bankbranch"}
        />
        {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

        
              <UploadComponent
                downloadPath="/Bank_Branch_Upload.xlsx"  // Replace with a dynamic file path if needed
                handleFileChange={handleFileChange}  // Function to handle file selection
                buttonText={buttonText}
                isUploaded={isUploaded}
                isError={isError}
                handleSubmit={handleSubmit}
                isDisplayChecked={isDisplayChecked}
          setIsDisplayChecked={setIsDisplayChecked}
          isUploadChecked={isUploadChecked}
          setIsUploadChecked={setIsUploadChecked}
          displayOption={displayOption}
          setDisplayOption={setDisplayOption}
          uploadOption={uploadOption}
          setUploadOption={setUploadOption}
              />

      <ConfirmationDialog
                open={showConfirmation}
                title="Upload File"
                message="Do you need to download the latest upload format?"
                onConfirm={confirmDeletion}
                onCancel={cancelDeletion}
              />
    </div>
  )
}
