import React, { useEffect, useRef, useState } from "react";
import Header from "../../../../../components/Header";

import { useLocation, useNavigate } from "react-router-dom";

import { IoIosList } from "react-icons/io";

import { useToast } from "../../../../../components/toast/toast";

import CustomeLoading from "../../../../../components/CustomeLoading";

import Footer from "../../../../../components/footer";

import LabelWithButton from "../../../../../components/InputButtonField";

import TableWrapper from "../../../../../components/ScrollBarComponent";
import { set } from "lodash";

import CashBalanceServices from "../../../../../rest-services/CashBalanceServices";
import CheckboxPopup from "../../../../../components/CheckBoxComponent";
import CustomButton from "../../../../../components/CustomButton";
import HoverButton from "../../../../../components/HoverButton";
import GlobalProcessServices from "../../../../../rest-services/GlobalProcessServices";


export default function CompanywiseAdd() {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [processName, setProcessName] = useState("");
  const [statementType, setStatementType] = useState("");
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [transactionFromMonth, setTransactionFromMonth] = useState("")
  const [transactionToMonth, setTransactionToMonth] = useState("")
  const [transactionFromValueDate, setTransactionFromValueDate] = useState("")
  const [transactionToValueDate, setTransactionToValueDate] = useState("")
  const [assignee, setAssignee] = useState({ id: "", assigner: "", clientId: "" });

  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [modalStates, setModalStates] = useState({
    company: { isOpen: false, searchQuery: "", selected: [], tempSelected: [] },
    bank: { isOpen: false, searchQuery: "", selected: [], tempSelected: [] },

    legalEntity: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    companyCountry: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    buGroup: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankID: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankBranch: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankCountry: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankAccountID: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankCurrencyID: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankBic: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
  });

  const [dataLists, setDataLists] = useState({
    company: [],
    branch: [],
    legalEntity: [],
    buGroup: [],
    bankAccountID: [],

  });

  useEffect(() => {
    fetchAssignee();
    fetchCompanyCode();
    fetchAllBankAccountId();
    fetchBranch();
    fetchLegalEnity();
    fetchBugroup();
  }, []);



  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    if (id) fetchDataForField();
  }, [id]);

  const fetchDataForField = async () => {
    try {
      const response = await GlobalProcessServices.getProcessDetailsById(id, "18", "64");
      if (response.status === 200) {
        const apiData = response.data.data;

        const processName = apiData.processName || "";
        setProcessName(processName);

        // Extracting values from API response
        const extractValues = (fieldName) =>
          apiData.processListDetails
            .filter((item) => item.fieldName === fieldName)
            .map((item) => item.value) || [];

        // Fetch data for existing fields
        const companyCode = extractValues("companyCode");
        const legalEntity = extractValues("legalEntity");
        const companyCountry = extractValues("companyCountry");
        const bankAccountID = extractValues("bankAccountID");
        const bankCountry = extractValues("bankCountry");
        const bank = extractValues("bank");
        const bankBranch = extractValues("bankBranch");
        const bankBic = extractValues("bankBic");
        const buGroup = extractValues("buGroup");
        const bankCurrencyID = extractValues("bankCurrencyID");


        // Fetch data for new transaction and imported date fields
        const transactionFromMonth = extractValues("transactionFromMonth")[0] || "";
        const transactionToMonth = extractValues("transactionToMonth")[0] || "";
        const transactionFromValueDate = extractValues("transactionFromValueDate")[0] || "";
        const transactionToValueDate = extractValues("transactionToValueDate")[0] || "";


        // Updating state with fetched data
        setModalStates((prev) => ({
          ...prev,
          company: { ...prev.company, selected: companyCode, tempSelected: companyCode },
          legalEntity: { ...prev.legalEntity, selected: legalEntity, tempSelected: legalEntity },
          companyCountry: { ...prev.companyCountry, selected: companyCountry, tempSelected: companyCountry },
          bankAccountID: { ...prev.bankAccountID, selected: bankAccountID, tempSelected: bankAccountID },
          bankCountry: { ...prev.bankCountry, selected: bankCountry, tempSelected: bankCountry },
          bank: { ...prev.bank, selected: bank, tempSelected: bank },
          bankBranch: { ...prev.bankBranch, selected: bankBranch, tempSelected: bankBranch },
          bankBic: { ...prev.bankBic, selected: bankBic, tempSelected: bankBic },
          buGroup: { ...prev.buGroup, selected: buGroup, tempSelected: buGroup },
          bankCurrencyID: { ...prev.bankCurrencyID, tempSelected: bankCurrencyID, selected: bankCurrencyID }
        }));

        // Updating state for transaction and imported date fields
        setTransactionFromMonth(transactionFromMonth);
        setTransactionToMonth(transactionToMonth);
        setTransactionFromValueDate(transactionFromValueDate);
        setTransactionToValueDate(transactionToValueDate);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleUpdate = async () => {
    try {
      if (!processName) setErrors({ processName: "Process Name is required." });
      const data = {
        processName,
        companyCode: modalStates.company.tempSelected,
        bank: modalStates.bank.tempSelected,
        legalEntity: modalStates.legalEntity.tempSelected,
        buGroup: modalStates.buGroup.tempSelected,
        companyCountry: modalStates.companyCountry.tempSelected,
        bankAccountID: modalStates.bankAccountID.tempSelected,
        bankCountry: modalStates.bankCountry.tempSelected,
        bankBranch: modalStates.bankBranch.tempSelected,
        bankBic: modalStates.bankBic.tempSelected,
        bankCurrencyID: modalStates.bankCurrencyID.tempSelected,
        // New transaction and imported date fields
        transactionFromMonth,
        transactionToMonth,
        transactionFromValueDate,
        transactionToValueDate
      };


      const response = await CashBalanceServices.updateProcessDetails(data, 18, 64);
      if (response.status === 200) {
        toast("success", response.message);
      } else {
        toast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleIconClick = () => {
    navigate("/cash-balance-process-list", { state: { iconId: "64", subModule: "18" } })
  };

  const fetchCompanyCode = async () => {
    try {
      const response = await CashBalanceServices.getCompanyCode();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          company: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchBranch = async () => {
    try {
      const response = await CashBalanceServices.getAllBranchData();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          branch: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchAllBankAccountId = async () => {
    try {
      const response = await CashBalanceServices.getAllBankAccountId();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          bankAccountID: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }


  const fetchLegalEnity = async () => {
    try {
      const response = await CashBalanceServices.getAllLegalEnity();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          legalEntity: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchBugroup = async () => {
    try {
      const response = await CashBalanceServices.getAllBugroup();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          buGroup: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { console.log(error) }
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Cash Balances') {
      navigate('/cash-balances');
    }
    if (crumb.title === 'Cash in Bank') {
      navigate('/cash-balances');
    } else if (crumb.title === 'Company Wise') {
      // navigate('/currency-rate');
    }
    else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const toggleModal = (key, isOpen) => {
    setModalStates((prev) => ({
      ...prev,
      [key]: { ...prev[key], isOpen },
    }));
  };

  const handleSelection = (key, value) => {
    setModalStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        tempSelected: prev[key].tempSelected.includes(value)
          ? prev[key].tempSelected.filter((item) => item !== value)
          : [...prev[key].tempSelected, value],
      },
    }));
  };

  const handleApplySelection = (key) => {
    setModalStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        selected: [...prev[key].tempSelected],
        isOpen: false,
      },
    }));
  };

  const handleResetSelection = (key) => {
    setModalStates((prev) => ({
      ...prev,
      [key]: { ...prev[key], tempSelected: [] },
    }));
  };


  const validateRange = (from, to, key, isMonth = false) => {
    if (from && to && (isMonth ? to < from : new Date(to) < new Date(from))) {
      toast("error", `To ${isMonth ? "month" : "date"} cannot be earlier than From ${isMonth ? "month" : "date"}.`);
      setErrors(prev => ({ ...prev, [key]: "" }));

      const resetters = {
        transactionToMonth: () => setTransactionToMonth(""),
        importedToMonth: () => setTransactionFromMonth(""),
        transactionToDate: () => setTransactionToValueDate(""),
        importedToDate: () => setTransactionFromValueDate(""),
      };

      resetters[key]?.();
    }
  };

  const validateAndSet = (setter, value, isMonthSelected, resetMonthSetter) => {
    if (isMonthSelected) {
      resetMonthSetter();
      toast("info", "You can select either a month range or a date range, not both!");
    }
    setter(value);
  };

  const handleDateOrMonthChange = (setter, value, isMonth, resetSetter) => {
    validateAndSet(setter, value, isMonth, resetSetter);
  };

  const transactionFromDateChange = (e) =>
    handleDateOrMonthChange(setTransactionFromValueDate, e, transactionFromMonth || transactionToMonth, () => {
      setTransactionFromMonth("");
      setTransactionToMonth("");
    });

  const transactionToDateChange = (e) =>
    handleDateOrMonthChange(setTransactionToValueDate, e, transactionFromMonth || transactionToMonth, () => {
      setTransactionFromMonth("");
      setTransactionToMonth("");
    });

  const transactionFromMonthChange = (e) =>
    handleDateOrMonthChange(setTransactionFromMonth, e, transactionFromValueDate || transactionToValueDate, () => {
      setTransactionFromValueDate("");
      setTransactionToValueDate("");
    });

  const transactionToMonthChange = (e) =>
    handleDateOrMonthChange(setTransactionToMonth, e, transactionFromValueDate || transactionToValueDate, () => {
      setTransactionFromValueDate("");
      setTransactionToValueDate("");
    });

  const handleSave = async (action) => {
    try {
      if (!processName) setErrors({ processName: "Process Name is required." });
      const data = {
        processName,
        companyCode: modalStates.company.tempSelected,
        bank: modalStates.bank.tempSelected,
        legalEntity: modalStates.legalEntity.tempSelected,
        buGroup: modalStates.buGroup.tempSelected,
        companyCountry: modalStates.companyCountry.tempSelected,
        bankAccountID: modalStates.bankAccountID.tempSelected,
        bankCountry: modalStates.bankCountry.tempSelected,
        bankBranch: modalStates.bankBranch.tempSelected,
        bankBic: modalStates.bankBic.tempSelected,
        bankCurrencyID: modalStates.bankCurrencyID.tempSelected,
        // New transaction and imported date fields
        transactionFromMonth,
        transactionToMonth,
        transactionFromValueDate,
        transactionToValueDate,
      };

      const response = await CashBalanceServices.saveProcessDetails(data);
      if (response.status === 201) {
        toast("success", response.message);
        if (action === 1) {
          navigate("/cash-balances");
        } else {
          resetForm();
        }
      } else {
        toast("error", response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = () => {
    setProcessName("");
    setModalStates((prev) => ({
      ...prev,
      company: { ...prev.company, tempSelected: [], selected: [] },

      legalEntity: { ...prev.legalEntity, tempSelected: [], selected: [] },
      companyCountry: { ...prev.companyCountry, tempSelected: [], selected: [] },
      bankAccountID: { ...prev.bankAccountID, tempSelected: [], selected: [] },
      bankCountry: { ...prev.bankCountry, tempSelected: [], selected: [] },
      bank: { ...prev.bank, tempSelected: [], selected: [] },
      bankBranch: { ...prev.bankBranch, tempSelected: [], selected: [] },
      bankBic: { ...prev.bankBic, tempSelected: [], selected: [] },
      buGroup: { ...prev.buGroup, tempSelected: [], selected: [] },
      bankCurrencyID: { ...prev.bankCurrencyID, tempSelected: [], selected: [] }
    }));
    setTransactionFromMonth("");
    setTransactionToMonth("");
    setTransactionFromValueDate("");
    setTransactionToValueDate("");
  }

  const handleGetDataAndNavigate = async () => {
    try {
      const data = {
        companyCode: modalStates.company.tempSelected,
        bank: modalStates.bank.tempSelected,
        legalEntity: modalStates.legalEntity.tempSelected,
        buGroup: modalStates.buGroup.tempSelected,
        companyCountry: modalStates.companyCountry.tempSelected,
        bankAccountID: modalStates.bankAccountID.tempSelected,
        bankCountry: modalStates.bankCountry.tempSelected,
        bankBranch: modalStates.bankBranch.tempSelected,
        bankBic: modalStates.bankBic.tempSelected,
        transactionFromMonth,
        transactionToMonth,
        transactionFromValueDate,
        transactionToValueDate,
      }
      navigate("/company-wise", { state: data })
      console.log("Nabigated");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <Header title={"Cash Balances > Cash in Bank > Company Wise > Add"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/cash-balances"}
      />
      <div>
        <TableWrapper maxHeight="550px">
          <div className="px-6 pb-6">
            <div className="flex flex-col  col-span-1 p-4 border  rounded-md">
              <div className="2xl:flex 2xl:flex-row xl:grid xl:grid-cols-2 xl:gap-0 lg:grid lg:grid-cols-2 lg:gap-0">
                <div>
                  <label className="flex items-center">
                    <span className="w-48 font-small text-gray-500 text-xs">Process Name</span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={processName}
                      onChange={(e) => setProcessName(e.target.value)}
                      className={`border p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray ${errors.processName
                        ? "border-red-500 hover:border-red-500"
                        : "border-gray-300 hover:border-blue-400"
                        }`}
                    />
                  </label>
                </div>
                <div>
                  <button onClick={handleIconClick}>
                    <IoIosList className="ml-4 mt-1 border  h-6 w-6  hover hover:border-customBlue" />
                  </button>
                </div>
              </div>
              {errors.ntMainHead && (
                <p className="text-red-500 text-sm mt-1">{errors.ntMainHead}</p>
              )}
              <div>
                <div className="grid grid-cols-2 gap-0">
                  {/* Transaction Date */}
                  <label className="flex items-center mt-4">
                    <span className="w-48 font-small text-left text-gray-500 text-xs">
                      Transaction Date
                    </span>
                    <span className="ml-4">:</span>

                    <input
                      type="date"
                      placeholder="From"
                      value={transactionFromValueDate}
                      onChange={(e) => {
                        transactionFromDateChange(e.target.value);
                        validateRange(e.target.value, transactionToValueDate, "transactionFromDate", false);
                      }}
                      className={`border ${errors.transactionFromDate ? "border-red-500" : "border-gray-300"
                        } p-1.5 rounded lg:w-28  xl:w-[148px]  h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500 text-xs`}
                      required
                    />

                    <input
                      type="date"
                      placeholder="To"
                      value={transactionToValueDate}
                      onChange={(e) => {
                        transactionToDateChange(e.target.value);
                        validateRange(transactionFromValueDate, e.target.value, "transactionToDate", false);
                      }}
                      className={`border ${errors.transactionToDate ? "border-red-500" : "border-gray-300"
                        } p-1.5 rounded lg:w-28  xl:w-[148px] h-8 focus:outline-none hover:border-blue-400 xl:ml-6 lg:ml-2 text-gray-500 text-xs`}
                      required
                    />
                  </label>

                  {/* Error Message */}
                  {errors.transactionFromDate && (
                    <p className="text-red-500 text-sm mt-1">{errors.transactionFromDate}</p>
                  )}

                  {/* Month/Year Selection */}
                  <label className="flex items-center mt-4 pl-6">
                    <span className="w-40 font-small text-left text-gray-500 text-xs">
                      Month/Year
                    </span>
                    <span className="ml-4">:</span>

                    <input
                      type="month"
                      value={transactionFromMonth}
                      onChange={(e) => {
                        transactionFromMonthChange(e.target.value);
                        validateRange(e.target.value, transactionToMonth, "transactionFromMonth", true);
                      }}
                      className={`border ${errors.transactionFromMonth ? "border-red-500" : "border-gray-300"
                        } p-1.5 rounded lg:w-28 xl:w-32 2xl:w-40 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500 text-xs`}
                      required
                    />

                    <input
                      type="month"
                      value={transactionToMonth}
                      onChange={(e) => {
                        transactionToMonthChange(e.target.value);
                        validateRange(transactionFromMonth, e.target.value, "transactionToMonth", true);
                      }}
                      className={`border ${errors.transactionToMonth ? "border-red-500" : "border-gray-300"
                        } p-1.5 rounded lg:w-28 xl:w-32 2xl:w-40 h-8 focus:outline-none hover:border-blue-400 xl:ml-6 lg:ml-2 text-gray-500 text-xs`}
                      required
                    />
                  </label>

                  {/* Error Message */}
                  {errors.transactionFromMonth && (
                    <p className="text-red-500 text-sm mt-1">{errors.transactionFromMonth}</p>
                  )}
                </div>
              </div>
            </div>

            {loading === true ? <CustomeLoading /> : null}


          </div>
          <div className="px-6">
            <div className="flex flex-col  col-span-1 p-4 border  rounded-md">
              <div className="grid grid-cols-2 gap-x-4 mb-4">
                <label className="flex items-center">
                  <span className="w-48 text-sm font-medium text-customGray">Company</span>
                </label>
                <label className="flex items-center">
                  <span className="w-48 text-sm font-medium text-customGray">Bank</span>
                </label>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <LabelWithButton
                  label="Company Code"
                  onClick={() => toggleModal("company", true)}
                  buttonText={modalStates.company.selected.length > 0 ? modalStates.company.selected.join(", ") : "Select Company"}
                  error={errors.lgeCode}
                // className="w-[480px] text-xs"
                />
                <CheckboxPopup
                  title="Select Company Code"
                  isOpen={modalStates.company.isOpen}
                  onClose={() => toggleModal("company", false)}
                  onSave={() => handleApplySelection("company")}
                  onReset={() => handleResetSelection("company")}
                  searchPlaceholder="Search Company..."
                  searchQuery={modalStates.company.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, company: { ...prev.company, searchQuery: e.target.value }
                  }))}
                  items={[...new Map(dataLists.company.map(item => [item.companyCode, item])).values()]
                    .map(code => ({ id: code.companyCode, name: code.companyCode || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.company.searchQuery?.toLowerCase() || ''))
                  }
                  selectedItems={modalStates.company.tempSelected}
                  onItemToggle={(val) => handleSelection("company", val)}
                />

                <LabelWithButton
                  label="Bank"
                  onClick={() => toggleModal("bank", true)
                  }
                  buttonText={modalStates.bank.selected.length > 0 ? modalStates.bank.selected.join(", ") : "Select Bank"}
                  error={errors.bank}
                // className="w-[480px]"

                />
                <CheckboxPopup
                  title="Select Bank"
                  isOpen={modalStates.bank.isOpen}
                  onClose={() => toggleModal("bank", false)}
                  onSave={() => handleApplySelection("bank")}
                  onReset={() => handleResetSelection("bank")}
                  searchPlaceholder="Search Bank..."
                  searchQuery={modalStates.bank.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, bank: { ...prev.bank, searchQuery: e.target.value }
                  }))}

                  items={[...new Map(dataLists.branch.map(item => [item.Bank, item])).values()]
                    .map(code => ({ id: code.Bank, name: code.Bank || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.bank.searchQuery?.toLowerCase() || ''))
                  }
                  // items={dataLists.branch.map((code) => ({ id: code.Bank, name: code.Bank }))}
                  selectedItems={modalStates.bank.tempSelected}
                  onItemToggle={(val) => handleSelection("bank", val)}

                />

              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <LabelWithButton
                  label="LGE Code"
                  // isRequired={true}
                  //   isDisabled={isViewMode}
                  onClick={() => toggleModal("legalEntity", true)
                  }
                  buttonText={modalStates.legalEntity.selected.length > 0 ? modalStates.legalEntity.selected.join(", ") : "Selecte Legal Entity"}
                  error={errors.lgeCode}
                // className="w-[480px] text-xs"

                />
                <CheckboxPopup
                  title="Select Legal Entity"
                  isOpen={modalStates.legalEntity.isOpen}
                  onClose={() => toggleModal("legalEntity", false)}
                  onSave={() => handleApplySelection("legalEntity")}
                  onReset={() => handleResetSelection("legalEntity")}
                  searchPlaceholder="Search Legal Entity..."
                  searchQuery={modalStates.legalEntity.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, legalEntity: { ...prev.legalEntity, searchQuery: e.target.value }
                  }))}
                  items={[...new Map(dataLists.legalEntity.map(item => [item.LGE_Code, item])).values()]
                    .map(code => ({ id: code.LGE_Code, name: code.LGE_Code || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.legalEntity.searchQuery?.toLowerCase() || ''))
                  }
                  selectedItems={modalStates.legalEntity.tempSelected}
                  onItemToggle={(val) => handleSelection("legalEntity", val)}

                />
                <LabelWithButton
                  label="Bank ID"
                  onClick={() => toggleModal("bankID", true)
                  }
                  buttonText={modalStates.bankID.selected.length > 0 ? modalStates.bankID.selected.join(", ") : "Selecte Bank ID"}
                  error={errors.lgeCode}
                // className="w-[480px]"

                />

                <CheckboxPopup
                  title="Select Bank ID"
                  isOpen={modalStates.bankID.isOpen}
                  onClose={() => toggleModal("bankID", false)}
                  onSave={() => handleApplySelection("bankID")}
                  onReset={() => handleResetSelection("bankID")}
                  searchPlaceholder="Search Bank ID..."
                  searchQuery={modalStates.bankID.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, bankID: { ...prev.bankID, searchQuery: e.target.value }
                  }))}

                  items={[...new Map(dataLists.branch.map(item => [item.BankId, item])).values()]
                    .map(code => ({ id: code.BankId, name: code.BankId || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.bankID.searchQuery?.toLowerCase() || '')).sort((a, b) => a.name.localeCompare(b.name))
                  }

                  selectedItems={modalStates.bankID.tempSelected}
                  onItemToggle={(val) => handleSelection("bankID", val)}

                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <LabelWithButton
                  label="BU Group"
                  // isRequired={true}
                  //   isDisabled={isViewMode}
                  onClick={() => toggleModal("buGroup", true)}
                  buttonText={modalStates.buGroup.selected.length > 0 ? modalStates.buGroup.selected.join(", ") : "Bu Group"}
                  error={errors.lgeCode}
                // className="w-[480px]"

                />

                <CheckboxPopup
                  title="Select Bu Group"
                  isOpen={modalStates.buGroup.isOpen}
                  onClose={() => toggleModal("buGroup", false)}
                  onSave={() => handleApplySelection("buGroup")}
                  onReset={() => handleResetSelection("buGroup")}
                  searchPlaceholder="Search Legal Entity..."
                  searchQuery={modalStates.buGroup.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, buGroup: { ...prev.buGroup, searchQuery: e.target.value }
                  }))}
                  items={[...new Map(dataLists.buGroup.map(item => [item.BU_Group, item])).values()]
                    .map(code => ({ id: code.BU_Group, name: code.BU_Group || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.buGroup.searchQuery?.toLowerCase() || '')).sort((a, b) => a.name.localeCompare(b.name))
                  }
                  selectedItems={modalStates.buGroup.tempSelected}
                  onItemToggle={(val) => handleSelection("buGroup", val)}
                />
                <LabelWithButton
                  label="Bank Branch"

                  onClick={() => toggleModal("bankBranch", true)}
                  buttonText={modalStates.bankBranch.selected.length > 0 ? modalStates.bankBranch.selected.join(", ") : "Bank Branch"}
                  error={errors.lgeCode}
                // className="w-[480px]"

                />

                <CheckboxPopup
                  title="Select Bank Branch"
                  isOpen={modalStates.bankBranch.isOpen}
                  onClose={() => toggleModal("bankBranch", false)}
                  onSave={() => handleApplySelection("bankBranch")}
                  onReset={() => handleResetSelection("bankBranch")}
                  searchPlaceholder="Search Bank Branch..."
                  searchQuery={modalStates.bankBranch.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, bankBranch: { ...prev.bankBranch, searchQuery: e.target.value }
                  }))}

                  items={[...new Map(dataLists.branch.map(item => [item.bankBranch, item])).values()]
                    .map(code => ({ id: code.bankBranch, name: code.bankBranch || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.bankBranch.searchQuery?.toLowerCase() || '')) // Search filter
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                  }

                  selectedItems={modalStates.bankBranch.tempSelected}
                  onItemToggle={(val) => handleSelection("bankBranch", val)}

                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <LabelWithButton
                  label="Company Country"
                  // isRequired={true}
                  //   isDisabled={isViewMode}
                  onClick={() => toggleModal("companyCountry", true)
                  }
                  buttonText={modalStates.companyCountry.selected.length > 0 ? modalStates.companyCountry.selected.join(", ") : "Company Country"}
                  error={errors.lgeCode}
                // className="w-[480px]"

                />

                <CheckboxPopup
                  title="Select Company Country"
                  isOpen={modalStates.companyCountry.isOpen}
                  onClose={() => toggleModal("companyCountry", false)}
                  onSave={() => handleApplySelection("companyCountry")}
                  onReset={() => handleResetSelection("companyCountry")}
                  searchPlaceholder="Search Company Country..."
                  searchQuery={modalStates.companyCountry.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, companyCountry: { ...prev.companyCountry, searchQuery: e.target.value }
                  }))}
                  items={[...new Set(
                    dataLists.company.flatMap((company) =>
                      company.addresses.map((addr) => addr.countryCode)
                    )
                  )]
                    .map(code => ({ id: code, name: code || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.companyCountry.searchQuery?.toLowerCase() || '')) // Search filter
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                  }


                  selectedItems={modalStates.companyCountry.tempSelected}
                  onItemToggle={(val) => handleSelection("companyCountry", val)}

                />

                <LabelWithButton
                  label="Bank Country"
                  // isRequired={true}
                  //   isDisabled={isViewMode}
                  onClick={() => toggleModal("bankCountry", true)
                  }
                  buttonText={modalStates.bankCountry.selected.length > 0 ? modalStates.bankCountry.selected.join(", ") : "Bank Country"}
                  error={errors.lgeCode}
                // className="w-[480px]"

                />
                <CheckboxPopup
                  title="Select Bank Country"
                  isOpen={modalStates.bankCountry.isOpen}
                  onClose={() => toggleModal("bankCountry", false)}
                  onSave={() => handleApplySelection("bankCountry")}
                  onReset={() => handleResetSelection("bankCountry")}
                  searchPlaceholder="Search Bank Country..."
                  searchQuery={modalStates.bankCountry.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, bankCountry: { ...prev.bankCountry, searchQuery: e.target.value }
                  }))}
                  items={[...new Set(
                    dataLists.branch.flatMap((branch) =>
                      branch.addresses?.map((addr) => addr.countryCode).filter(Boolean) || []
                    )
                  )]
                    .map(countryCode => ({ id: countryCode, name: countryCode || '' }))
                    .filter(item =>
                      modalStates.bankCountry?.searchQuery
                        ? item.name.toLowerCase().includes(modalStates.bankCountry.searchQuery.toLowerCase())
                        : true
                    )}

                  selectedItems={modalStates.bankCountry.tempSelected}
                  onItemToggle={(val) => handleSelection("bankCountry", val)}

                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <div className="w-[600px] ml-4">&nbsp;</div>
                <LabelWithButton
                  label="Bank Account ID"

                  onClick={() => toggleModal("bankAccountID", true)
                  }
                  buttonText={modalStates.bankAccountID.selected.length > 0 ? modalStates.bankAccountID.selected.join(", ") : "Bank Account ID"}
                  error={errors.lgeCode}
                // className="w-[480px]"

                />

                <CheckboxPopup
                  title="Select Bank Account ID"
                  isOpen={modalStates.bankAccountID.isOpen}
                  onClose={() => toggleModal("bankAccountID", false)}
                  onSave={() => handleApplySelection("bankAccountID")}
                  onReset={() => handleResetSelection("bankAccountID")}
                  searchPlaceholder="Search Bank Account ID..."
                  searchQuery={modalStates.bankAccountID.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, bankAccountID: { ...prev.bankAccountID, searchQuery: e.target.value }
                  }))}
                  items={[...new Map(dataLists.bankAccountID.map(item => [item.bankAccountId, item])).values()]
                    .map(code => ({ id: code.bankAccountId, name: code.bankAccountId || '' }))
                    .filter(item => item.name?.toLowerCase().includes(modalStates.bankAccountID.searchQuery?.toLowerCase() || ''))
                  }
                  selectedItems={modalStates.bankAccountID.tempSelected}
                  onItemToggle={(val) => handleSelection("bankAccountID", val)}

                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <div className="w-[600px] ml-4">&nbsp;</div>
                <LabelWithButton
                  label="Bank Currency Code"

                  onClick={() => toggleModal("bankCurrencyID", true)
                  }
                  buttonText={modalStates.bankCurrencyID.selected.length > 0 ? modalStates.bankCurrencyID.selected.join(", ") : "Bank Currency"}
                  error={errors.lgeCode}
                // className="w-[480px]"

                />

                <CheckboxPopup
                  title="Select Bank Currency"
                  isOpen={modalStates.bankCurrencyID.isOpen}
                  onClose={() => toggleModal("bankCurrencyID", false)}
                  onSave={() => handleApplySelection("bankCurrencyID")}
                  onReset={() => handleResetSelection("bankCurrencyID")}
                  searchPlaceholder="Search Bank Account ID..."
                  searchQuery={modalStates.bankCurrencyID.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, bankCurrencyID: { ...prev.bankCurrencyID, searchQuery: e.target.value }
                  }))}
                  items={[...new Map(dataLists.bankAccountID.map(item => [item.currencyID, item])).values()]
                    .map(code => ({ id: code.currencyID, name: code.currencyID || '' }))
                    .filter(item => item.name?.toLowerCase().includes(modalStates.bankCurrencyID.searchQuery?.toLowerCase() || ''))
                  }
                  selectedItems={modalStates.bankCurrencyID.tempSelected}
                  onItemToggle={(val) => handleSelection("bankCurrencyID", val)}

                />
              </div>

            </div>

            {loading === true ? <CustomeLoading /> : null}


          </div>
        </TableWrapper>
      </div>
      <div className="mt-10">
        <Footer>
          <div>
            {!id && <CustomButton
              onClick={() => { handleSave(1) }}
              title={"Save"}
              className="mr-2"

            />}
            {!id && <HoverButton
              title={"Save & Continue"}
              className={"mr-2"}
              onClick={() => { handleSave(2) }}
            />}
            {id && <CustomButton
              onClick={handleUpdate}
              title={"Save"}
              className="mr-2"

            />}
            <HoverButton
              title={"Show"}
              className={"mr-2"}
              onClick={() => { handleGetDataAndNavigate(currentPage) }}
            />
          </div>
        </Footer>
      </div>
    </div>
  );
}
