import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbFlag3Filled } from "react-icons/tb";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import SourceServices from "../../../../../rest-services/SourceServices";
import { set } from "lodash";
import MdSourceServices from "../../../../../rest-services/MdSourceServices";
import { use } from "react";
import ReusableModal from "../../../../../components/PopupComponent";
import LabelWithButton from "../../../../../components/InputButtonField";
import CustomDropdown from "../../../../../components/CustomDropdown";

export default function SourceCodeAdd() {
    const { sourceId } = useParams();
    const location = useLocation();
    const { mode } = location.state || {}; // Get the mode from the state
    const isViewMode = mode === 'view';
    const[mdSource,setMdSource]=useState([]);   
    const [source, setSource] = useState("");
    const [sourceCompanyName, setSourceCompanyName] = useState(""); 
    const [sourceCode, setSourceCode] = useState(""); 
    const [sourceCodeDescription, setSourceCodeDescription] = useState(""); 
    const [ourCode, setOurCode] = useState(""); 
    const [ourCodeDescription, setOurCodeDescription] = useState(""); 
    const [rateType, setRateType] = useState(""); 
    const [dataType, setDataType] = useState(""); 
    const [ratio, setRatio] = useState("1.1"); 
    const [mdType, setMdType] = useState(""); 
    const [errors, setErrors] = useState({});
    const [assignee, setAssignee] = useState({ id: "", assigner: "" });
    const [alert, setAlert] = useState(null);
    const { toast } = useToast();
    const [status, setStatus] = useState("");
    const [isCopyMode, setIsCopyMode] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const isEditMode = !!sourceId;
    const[isSourceModalOpen, setIsSourceModalOpen] = useState(false);
    const[isSourceCompanyNameModalOpen, setIsSourceCompanyNameModalOpen] = useState(false);
    const[selectedSourceType, setSelectedSourceType] = useState(false);
    const[selectedSourceCompanyName, setSelectedSourceCompanyName] = useState(false);
    const[searchQuery, setSearchQuery] = useState("");
const[openDropdown, setOpenDropdown] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (location.state?.copiedData) {
            setIsCopyMode(true);
      const copiedData = location.state.copiedData;
            setSource(copiedData.source);
            setSourceCompanyName(copiedData.sourceCompanyName);
            setOurCode(copiedData.ourCode);
            setOurCodeDescription(copiedData.ourCodeDescription || "");
            setSourceCode(copiedData.sourceCode);
            setSourceCodeDescription(copiedData.sourceCodeDescription || "");
            setRateType(copiedData.rateType);
            setDataType(copiedData.dataType || "");
            setMdType(copiedData.mdType || "");
            setRatio(copiedData.ratio || "");
            // setSourceCode(copiedData);
            const selectedSourceData = mdSource.find(
              (md) => md.source === copiedData.source
            );
            if (selectedSourceData) {
              setSelectedSourceType(selectedSourceData.source);
              setSelectedSourceCompanyName(selectedSourceData.sourceCompanyName);
              
            } else {
            }
        } else if (sourceId) {
            getDataForEdit();
        }
        fetchAssignee();
    }, [sourceId, location.state,mdSource]);
useEffect(() => {
    fetchMdSource();
},[]);
    const getDataForEdit = async () => {
        try {
            const response = await SourceServices.getSourceById(sourceId);
            setSource(response.data.source);
            setSourceCompanyName(response.data.sourceCompanyName);
            setSourceCode(response.data.sourceCode);
            setSourceCodeDescription(response.data.sourceCodeDescription || "");
            setOurCode(response.data.ourCode);
            setOurCodeDescription(response.data.ourCodeDescription || "");
            setRateType(response.data.rateType);
            setDataType(response.data.dataType || "");
            setRatio(response.data.ratio);
            setMdType(response.data.mdType);
            console.log("response.data.source", response.data.source);
            if (response.data.source) {
                const selectedmd = mdSource.find(
                  (md) => md.source === response.data.source
                );
        
                if (selectedmd) {
                  setSource(selectedmd.source); // Set the legal entity code
                  setSourceCompanyName(selectedmd.sourceCompanyName); // Set the legal entity description
                  setSelectedSourceCompanyName(selectedmd.sourceCompanyName); // Update UI-selected value
                  setSelectedSourceType(selectedmd.source);
                } else {
                  console.warn('Legal entity not found for code:', response.legalEntityCode);
                }
              }
        } catch { }
    };
    const fetchMdSource = async () => {
        try {
          const response = await MdSourceServices.getAllMdSource(
            `${process.env.REACT_APP_BASE_URL}/get-mdsource`
          );
          console.log("Fetched MdSource:", response); // Add this log
          setMdSource(response.data.data);
          console.log("helooooo", mdSource);// Ensure this is correct
        } catch (error) {
          console.error("Error fetching MdSource:", error);
        }
      };
    const validateForm = () => {
        const newErrors = {};
        if (!source) newErrors.source = "source  is required.";
        if (!sourceCompanyName) newErrors.sourceCompanyName = "sourceCompanyName Description is required.";
        if (!sourceCode) newErrors.sourceCode = "sourceCode Description is required.";
        if (!ourCode) newErrors.ourCode = "ourCode Description is required.";
        if (!rateType) newErrors.rateType = "rateType Description is required.";
        if (!ratio) newErrors.ratio = "ratio Description is required.";
        if (!mdType) newErrors.mdType = "mdType Description is required.";
if(!dataType) newErrors.dataType = "dataType Description is required.";
if(!sourceCodeDescription) newErrors.sourceCodeDescription = "sourceCodeDescription Description is required.";
if(!ourCodeDescription) newErrors.ourCodeDescription = "ourCodeDescription Description is required.";
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            toast("error", "Missing Required Fields");
        }
        return Object.keys(newErrors).length === 0;
    };

    const fetchAssignee = async () => {
        try {
            const userDetailString = localStorage.getItem("userDetail");
            if (userDetailString) {
                const userDetail = JSON.parse(userDetailString);
                const assigneeData = {
                    id: userDetail.id || "",
                    assigner: userDetail.userId || "",
                };
                setAssignee(assigneeData);
            } else {
                setAlert({
                    severity: "error",
                    message: "No user detail found in session storage.",
                });
            }
        } catch (error) {
            setAlert({
                severity: "error",
                message: `Error fetching user details: ${error.message}`,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form
        if (!validateForm()) return;

        // Check for duplicate BU Group code
        try {
            // Skip duplication check for edit mode
            if (!sourceId || isCopyMode) {
              const duplicateCheckResponse = await SourceServices.checkDuplicateSourcecode(
                  `${process.env.REACT_APP_BASE_URL}/checkduplicatesource`,
                  { source,sourceCode,ourCode }
              );

              if (duplicateCheckResponse.message === "A record with the same source, sourceCode, and ourCode already exists") {
                  toast("error", "A record with the same source, sourceCode, and ourCode already exists.");
                  setErrors((prev) => ({
                      ...prev,
                      sourceCode: "source Code already exists.",
                      ourCode: "our Code already exists.",
                  }));
                  return; // Stop further execution if duplicate exists
              }
          }

            // Prepare the data for submission
            const data = {
                id: isCopyMode ? null : sourceId,
                source,
                sourceCompanyName,
                sourceCode,
                sourceCodeDescription,
                ourCode,
                ourCodeDescription,
                rateType,
                dataType,
                ratio,
                mdType,
                created_by: assignee.assigner,
                changed_by: assignee.assigner,     
                };

            console.log("BuGroup data", data);


            let response;
            if (isCopyMode || !sourceId) {
                // Create a new BU Group
                response = await SourceServices.addSource(
                    `${process.env.REACT_APP_BASE_URL}/create-source`,
                    data
                );
            } else {
                // Update an existing BU Group
                response = await SourceServices.addSource(
                    `${process.env.REACT_APP_BASE_URL}/create-source`,
                    data
                );
            }

            if (response && !response.error) {
                toast(
                    "success",
                    sourceId && !isCopyMode
                        ? "Source Updated successfully"
                        : "Source Added successfully"
                );

                // Clear the form and navigate
                setSource("");
                setSourceCompanyName("");
                setSourceCode("");
                setSourceCodeDescription("");
                setOurCode("");
                setOurCodeDescription("");
                setDataType("");
                setRateType("");
                setRatio("");
                setMdType("");


                navigate("/2/master-data/8/source-code"); // Redirect after saving, only if Save and Continue is false

            } else {
                toast("error", "Error adding/updating Source.");
            }
        } catch (error) {
            console.error("Request error:", error);
            setAlert({
                severity: "error",
                message: `Error adding/updating Source: ${error.message}`,
            });
        }
    };
    const handleSaveandContinue = async (e) => {
      e.preventDefault();

      // Validate the form
      if (!validateForm()) return;

      // Check for duplicate BU Group code
      try {
          // Skip duplication check for edit mode
          if (!sourceId || isCopyMode) {
            const duplicateCheckResponse = await SourceServices.checkDuplicateSourcecode(
                `${process.env.REACT_APP_BASE_URL}/checkduplicatesource`,
                { source,sourceCode,ourCode }
            );

            if (duplicateCheckResponse.message === "A record with the same source, sourceCode, and ourCode already exists") {
                toast("error", "A record with the same source, sourceCode, and ourCode already exists.");
                setErrors((prev) => ({
                    ...prev,
                    sourceCode: "source Code already exists.",
                    ourCode: "our Code already exists.",
                }));
                return; // Stop further execution if duplicate exists
            }
        }

          // Prepare the data for submission
          const data = {
              id: isCopyMode ? null : sourceId,
              source,
              sourceCompanyName,
              sourceCode,
              sourceCodeDescription,
              ourCode,
              ourCodeDescription,
              rateType,
              dataType,
              ratio,
              mdType,
              created_by: assignee.assigner,
              changed_by: assignee.assigner,     
              };

          console.log("BuGroup data", data);


          let response;
          if (isCopyMode || !sourceId) {
              // Create a new BU Group
              response = await SourceServices.addSource(
                  `${process.env.REACT_APP_BASE_URL}/create-source`,
                  data
              );
          } else {
              // Update an existing BU Group
              response = await SourceServices.addSource(
                  `${process.env.REACT_APP_BASE_URL}/create-source`,
                  data
              );
          }

          if (response && !response.error) {
              toast(
                  "success",
                  sourceId && !isCopyMode
                      ? "Source Updated successfully"
                      : "Source Added successfully"
              );

              // Clear the form and navigate
              setSource("");
              setSourceCompanyName("");
              setSourceCode("");
              setSourceCodeDescription("");
              setOurCode("");
              setOurCodeDescription("");
              setDataType("");
              setRateType("");
              setSelectedSourceType("");
              setSelectedSourceCompanyName("");
              setMdType("");



          } else {
              toast("error", "Error adding/updating Source.");
          }
      } catch (error) {
          console.error("Request error:", error);
          setAlert({
              severity: "error",
              message: `Error adding/updating Source: ${error.message}`,
          });
      }
  };
    const checkDuplicateSource = async (source, sourceCode, ourCode) => {
      try {
          const response = await SourceServices.checkDuplicateSourcecode(
              `/checkduplicatesource`,
              { source, sourceCode, ourCode }
          );
  
          if (response.isDuplicate) {
              toast("error", "A record with the same source, sourceCode, and ourCode already exists.");
              setErrors((prev) => ({
                  ...prev,
                  sourceCode: "Duplicate source code found",
                  ourCode: "Duplicate our code found",
              }));
          } else {
              setErrors((prev) => ({
                  ...prev,
                  sourceCode: "",
                  ourCode: "",
              }));
          }
      } catch (error) {
          console.error("Error checking duplicate source:", error);
          setErrors((prev) => ({
              ...prev,
              sourceCode: "Error checking duplicate",
              ourCode: "Error checking duplicate",
          }));
      }
  };
  
    const handleSourceCheckBoxChange = (event) => {

        const selectedValue = event.target.value; // Value from the checkbox (currency code or name)
      
        // Find the selected currency object
        const selectedSource = mdSource.find(
          (md) => md.source === selectedValue || md.sourceCompanyName === selectedValue
        );
      
        if (selectedSource) {
          if (selectedValue === selectedSource.source) {
            // Handle selection for currency code
            setSelectedSourceType(selectedSource.source);
            setSelectedSourceCompanyName(selectedSource.sourceCompanyName);
            setSource(selectedSource.source);
            setSourceCompanyName(selectedSource.sourceCompanyName);
          } else if (selectedValue === selectedSource.sourceCompanyName) {
            // Handle selection for currency name
            setSelectedSourceType(selectedSource.source);
            setSelectedSourceCompanyName(selectedSource.sourceCompanyName);
            setSource(selectedSource.source);
            setSourceCompanyName(selectedSource.sourceCompanyName);
          }
          setUnsavedChanges(true); // Mark unsaved changes
        } else {
          console.error("Selected value not found in the list.");
        }
      };
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Market Data') {
            navigate('/2/master-data');
        } else if (crumb.title === 'Source') {
            navigate('/source-code');
        }
        else if (crumb.title === 'Primary Data') {
            navigate('/2/master-data');

        } else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };
    const getApprovedIcon = () => {
        let color = "black"; // Default color
        // Determine the color based on the approved status
        if (status === 4) {
            color = "#29CB00"; // Green
        } else if (status === 0) {
            color = "gray"; // Gray
        } else if (status === 1) {
            color = "#AF52DE";
        } else if (status === 2) {
            color = "yellow";
        } else if (status === 3) {
            color = "orange";
        }
        else if (status === 5) {
            color = "red"; // Red
        }

        return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
    };
    const handleRefreshSource = async () => {
        try {
          await fetchMdSource();  // Fetch Branchs
        } catch (error) {
          console.error('Error reloading Branchs:', error);
        } finally {
        }
      };
      const handleSourceReset = () => {
        setSearchQuery(''); // Reset search query
        setSelectedSourceCompanyName(""); // Reset selected bank ID
        setSelectedSourceType("");
        setSource("");
        setSourceCompanyName("");
      };
      const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };
      const rateTypeOptions = [
        { value: "", label: "Select rateType " },
        { value: "SPT", label: "SPT" },
        { value: "FWD", label: "FWD" },
        { value: "SER", label: "SER" },
        { value: "LON", label: "LON" },
    
      ];
      const mdTypeOption = [
        { value: "", label: "Select mdType" },
        { value: "EXT", label: "EXT" },
        { value: "CHG", label: "CHG" },
        { value: "INT", label: "INT" },
    
      ];
      const ratioOptions = [
        { value: "", label: "Select ratio" },
        { value: "1.1", label: "1.1" },
       
      ];
      const handleToggle = (dropdownId) => {
        setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
      };
      const handleSourceCodeChange = (e) => {
        const code = e.target.value;
        setSourceCode(code);
        setUnsavedChanges(true);
        setErrors((prev) => ({ ...prev, sourceCode: "" }));
    
        if (code.trim() !== "" && ourCode.trim() !== "") {
            checkDuplicateSource(selectedSourceType, code, ourCode);
        }
    };
    
    const handleOurCodeChange = (e) => {
        const code = e.target.value;
        setOurCode(code);
        setUnsavedChanges(true);
        setErrors((prev) => ({ ...prev, ourCode: "" }));
    
        if (sourceCode.trim() !== "" && code.trim() !== "") {
            checkDuplicateSource(selectedSourceType, sourceCode, code);
        }
    };
    
      
    return (
        <div>
            <Header
                title={`Primary Data > Market Data > Source > ${isCopyMode
                    ? 'Add'
                    : sourceId
                        ? (isViewMode ? "View" : "Edit")
                        : "Add"
                    }${sourceId && (isViewMode || !isCopyMode) ? ` > ${sourceCode}` : ''}`}
                onBreadcrumbClick={handleBreadcrumbClick}
                hasUnsavedChanges={unsavedChanges}
                backRoute={"/2/master-data/13/source-code"}
            />
            <div className="m-6 border border-gray-200 px-5 py-3  rounded-lg">
                
       
                <h3 className="text-sm font-medium text-customGray mb-4">
                    Source
                </h3>

                <div className="grid grid-cols-2 gap-4">
                  <LabelWithButton
                    label="Source Type"
                    isRequired={true}
                    isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
                    onClick={() => { setIsSourceModalOpen(true); }
                    }
                    buttonText={selectedSourceType || 'Select Source Type'}
                    error={errors.source}
                  />

                  <ReusableModal
                    title="Select Source Type"
                    isOpen={isSourceModalOpen}
                    onClose={() => {
                      setIsSourceModalOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsSourceModalOpen(false);
                      setSearchQuery('');
                    }}
                    searchPlaceholder="Search Source Type..."
                    searchQuery={searchQuery}
                    showReset={true}
                    onReset={handleSourceReset}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode}
                    onRefresh={handleRefreshSource}
                  >
                    {mdSource && mdSource.length > 0 ? (
                      mdSource
                        .filter(
                          (md) =>
                            (md.source &&
                              md.source.trim() &&
                              md.source
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()))

                        )
                        .sort((a, b) => a.source.localeCompare(b.source)) // Sort in alphabetical order

                        .map((md, index) => (
                          <div
                            key={md.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              name="Source Type"
                              value={md.source}
                              checked={
                                selectedSourceType === md.source
                              }
                              onChange={handleSourceCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{md.source}</label>
                          </div>
                        ))

                    ) : (
                      <p>Loading lge...</p>
                    )}
                  </ReusableModal>
                  <LabelWithButton
                    label="Source Company Name"
                    isRequired={true}
                    isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
                    onClick={() => { setIsSourceCompanyNameModalOpen(true); }
                    }
                    buttonText={selectedSourceCompanyName || 'Select Source Company Name'}
                    error={errors.sourceCompanyName}
                  />

                  <ReusableModal
                    title="Select Source Company Name"
                    isOpen={isSourceCompanyNameModalOpen}
                    onClose={() => {
                      setIsSourceCompanyNameModalOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsSourceCompanyNameModalOpen(false);
                      setSearchQuery('');
                    }}
                    searchPlaceholder="Search Source Company Name..."
                    searchQuery={searchQuery}
                    showReset={true}
                    onReset={handleSourceReset}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode}
                    onRefresh={handleRefreshSource}
                    
                  >
                    {mdSource && mdSource.length > 0 ? (
                      mdSource
                        .filter(
                          (md) =>
                            (md.sourceCompanyName &&
                              md.sourceCompanyName.trim() &&
                              md.sourceCompanyName
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()))

                        )
                        .sort((a, b) => a.sourceCompanyName.localeCompare(b.sourceCompanyName)) // Sort in alphabetical order

                        .map((md, index) => (
                          <div
                            key={md.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              name="md Name"
                              value={md.sourceCompanyName}
                              checked={
                                selectedSourceCompanyName === md.sourceCompanyName
                              }
                              onChange={handleSourceCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{md.sourceCompanyName}</label>
                          </div>
                        ))

                    ) : (
                      <p>Loading md...</p>
                    )}
                  </ReusableModal>

                
              </div>

                
                {/* </div> */}


            </div>
            <div className="m-6 border border-gray-200 px-5 py-3  rounded-lg">
            <div className="flex flex-col space-y-2">              
                  
                <h3 className="text-sm font-medium text-customGray mb-4">
                Code Mapping
            </h3>

                <div className="grid grid-cols-2 gap-4">

                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Source code<span className="text-red-500 ml-1">*</span>
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={sourceCode}
                            maxLength={20}
                            onChange={handleSourceCodeChange}
                              

                            disabled={((isEditMode && !isCopyMode) || isViewMode)}
                            className={`border  ${errors.sourceCode ? "border-red-500" : "border-gray-300"
                            } p-1.5 rounded w-80 h-8  focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs text-customGray font-small text-customGray text-xs`}
                        />
                   
                        </div>
                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Our Code <span className="text-red-500 ml-1">*</span>
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={ourCode}
                            maxLength={20}
                            disabled={((isEditMode && !isCopyMode) || isViewMode)}
                            onChange={handleOurCodeChange}
                            className={`border ${errors.ourCode ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs `}
                        />
                    </div>
                    
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>{!isViewMode && sourceCode.length === 20 &&  (
                      <p className="text-red-500 text-xs pt-2">You have used the maximum character limit.</p>
                    )}</div>
                  <div>{!isViewMode && ourCode.length === 20 &&  (
                      <p className="text-red-500 text-xs pt-2">You have used the maximum character limit.</p>
                    )}</div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">

                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Source Code Description <span className="text-red-500 ml-1">*</span>
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={sourceCodeDescription}
                            maxLength={50}
                            disabled={isViewMode}
                            onChange={(e) => {
                                  setSourceCodeDescription(e.target.value);
                                setUnsavedChanges(true);
                            }}
                            className={`border ${errors.sourceCodeDescription ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs`}
                        />
                    </div>
                    

                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Our Code Description <span className="text-red-500 ml-1">*</span>
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={ourCodeDescription}
                            maxLength={50}
                            disabled={isViewMode}
                            onChange={(e) => {
                                  setOurCodeDescription(e.target.value);
                                setUnsavedChanges(true);
                            }}
                            className={`border ${errors.ourCodeDescription ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs`}
                        />
                    </div>
                    
                </div>
                <div className="grid grid-cols-2 gap-4 ">
                  <div>{!isViewMode && sourceCodeDescription.length === 50 &&  (
                      <p className="text-red-500 text-xs pt-2">You have used the maximum character limit.</p>
                    )}</div>
                  <div>{!isViewMode && ourCodeDescription.length === 50 &&  (
                      <p className="text-red-500 text-xs pt-2">You have used the maximum character limit.</p>
                    )}</div>
                </div>
                
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Rate Type <span className="text-red-500 ml-1">*</span>
                        </label>
                        <span className="ml-4">:</span>
                        <CustomDropdown
                          value={rateType}
                          options={rateTypeOptions}
                          onChange={(value) => {
                            setRateType(value);
                            setUnsavedChanges(true); // Mark as unsaved
                          }}
                          error={!!errors.rateType}	
                          // isDisabled={isViewMode}
                      

                          isOpen={openDropdown === "rateType"}
                          onToggle={() => handleToggle("rateType")}
                          width="2xl:w-80 xl:w-[302px] lg:w-[250px]"

                          isDisabled={isViewMode}

                          setUnsavedChanges={setUnsavedChanges}
                          className="ml-4"
                          placeholder="Select Rate Type"
                        />
                    </div>

                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Data Type  <span className="text-red-500 ml-1">*</span>
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={dataType}
                            maxLength={3}
                            disabled={isViewMode}
                            onChange={(e) => {
                                  setDataType(e.target.value);
                                setUnsavedChanges(true);
                            }}
                            className={`border ${errors.dataType ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs`}
                        />
                    </div>
                    
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div></div>
                  <div>{!isViewMode && dataType.length === 3 &&  (
                      <p className="text-red-500 text-xs pt-2">You have used the maximum character limit.</p>
                    )}</div>
                </div>

                
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Ratio{" "}
                        </label>
                        <span className="ml-4">:</span>
                        <CustomDropdown
                          value={ratio}
                          options={ratioOptions}
                          onChange={(value) => {
                            setRatio(value);
                            setUnsavedChanges(true); // Mark as unsaved
                          }}
                          // isDisabled={isViewMode}
                          isDisabled={true}
                          isOpen={openDropdown === "ratio"}
                          onToggle={() => handleToggle("ratio")}
                          width="2xl:w-80 xl:w-[302px] lg:w-[250px]"

error={!!errors.ratio}

                          setUnsavedChanges={setUnsavedChanges}
                          className="ml-4"
                          placeholder="Select ratio"
                        />
                    </div>
                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            MD Type <span className="text-red-500 ml-1">*</span>
                        </label>
                        <span className="ml-4">:</span>
                        <CustomDropdown
                          value={mdType}
                          options={mdTypeOption}
                          onChange={(value) => {
                            setMdType(value);
                            setUnsavedChanges(true); // Mark as unsaved
                          }}
                          // isDisabled={isViewMode}
                          isDisabled={isViewMode}
error={!!errors.mdType}
                          isOpen={openDropdown === "mdType"}
                          onToggle={() => handleToggle("mdType")}
                          width="2xl:w-80 xl:w-[302px] lg:w-[250px]"



                          setUnsavedChanges={setUnsavedChanges}
                          className="ml-4"
                          placeholder="Select Md Type"
                        />
                    </div>
                </div>
              </div>
            </div>
            <Footer>
                {(!isViewMode && (<ButtonGroup onSave={handleSubmit} onSaveContinue={handleSaveandContinue} hasId={!!sourceId} />))}
            </Footer>
        </div>
    );
}
