import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdRefresh,

} from "react-icons/io";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import { useToast } from "../../../../../components/toast/toast";
import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";
import Pagination from "../../../../../components/PageNation";
import CashBalanceServices from "../../../../../rest-services/CashBalanceServices";
import CustomeLoading from "../../../../../components/CustomeLoading";
import { MdOutlineFileDownload } from "react-icons/md";

export default function CompanyWise() {
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [buGroup, setBuGroup] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input

  const { toast } = useToast();

  const { iconId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20); // Default page size
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);

  const columns = [
    { Header: "Company Code", accessor: "companyCode", visible: true, width: ("Company Code".length -2)*10, alignment: "left" },
    { Header: "Company Short Name", accessor: "companyShortName", visible: true, width: ("Company Short Name".length -2)*10, alignment: "left" },
    { Header: "Company Full Name", accessor: "companyName", visible: true, width: ("Company Full Name".length -2)*10, alignment: "left" },
    { Header: "Bank ID", accessor: "bankId", visible: true, width: ("Bank ID".length -2)*10, alignment: "left" },
    { Header: "Bank Account ID", accessor: "bankAccountId", visible: true, width: ("Bank Account ID".length -2)*10, alignment: "left" },
    { Header: "Bank country", accessor: "bankCountry", visible: true, width: ("Bank country".length -2)*10, alignment: "left" },
    { Header: "Branch ID", accessor: "bankBranch", visible: true, width: ("Branch ID".length -2)*10, alignment: "left" },
    { Header: "Branch Name", accessor: "branchName", visible: true, width: ("Branch Name".length -2)*10, alignment: "left" },
    { Header: "Account No", accessor: "bankAccountNo", visible: true, width: ("Account No".length -2)*10, alignment: "left" },
    { Header: "Currency", accessor: "currency", visible: true, width: ("Currency".length -2)*10, alignment: "left" },
    {
      Header: "Balance Date", accessor: "transactionDate", visible: true, width: ("Balance Date".length -2)*10, alignment: "center",
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    { Header: "Opening Balance", accessor: "openingBalance", visible: true, width: ("Opening Balance".length -2)*10, alignment: "right" },
    { Header: "Debits", accessor: "totalDebitAmount", visible: true, width: ("Debits".length -2)*10, alignment: "right" },
    { Header: "Credits", accessor: "totalCreditAmount", visible: true, width: ("Credits".length -2)*10, alignment: "right" },
    { Header: "Closing Balance", accessor: "closingBalance", visible: true, width: ("Closing Balance".length -2)*10, alignment: "right" },
    { Header: "Closing Balance Calculated", accessor: "closingBalanceCalculate", visible: true, width: ("Closing Balance Calculated".length -2)*10, alignment: "right" },
  ];


  const location = useLocation();
  const analyserData = location.state;
  const [data, setData] = useState("");
  const [fullData, setFullData] = useState([]);
  useEffect(() => {
    fetchDataForTable();
  }, []);

  useEffect(() => {
    updatePageData(currentPage);
  }, [fullData]);

  const handleShowAll = () => {
    setPageSize(totalCount);
    setCurrentPage(1);
    fetchDataForTable(1);
  };



  const fetchDataForTable = async () => {
    try {
      if (!analyserData) return;
      setLoading(true);
      const response = await CashBalanceServices.getCompanyWiseReport(analyserData);
      if (response.status === 200) {
        const totalCount = response.count
        setFullData(response.formattedStatements);
        setTotalCount(totalCount);
        setTotalPages(Math.ceil(totalCount / 20));;
        setLoading(false);
      } else {
        toast("error", "No Data Available");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDonwload = async () => {
    try {
      const response = await CashBalanceServices.exportCompanyWiseReport(
        analyserData
      );

      if (response) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "company_wise_data.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast("success", "Downloaded Successfully");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      toast("error", "Failed to download data");
    }

  }
  // Function to update displayed data based on the selected page
  const updatePageData = (page) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setData(fullData.slice(startIndex, endIndex));
    setCurrentPage(page);
  };

  // Call this function when user clicks on next/prev page
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      updatePageData(newPage);
    }
  };




  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };

  const existingButtons = PrimaryActionsConfig.screen6.map((button) => {
    let onClick = button.onClick;
    let title = button.title;

    if (button.icon === IoMdRefresh) {
      onClick = () => {
        fetchDataForTable();
      };
      title = "Refresh";
    }
    if (button.icon === MdOutlineFileDownload) {
      title = "Download";
      onClick = () => handleDonwload();
    }
    return {
      ...button,
      onClick,
      title,
    };
  });


  const handleCheckboxChange = (id) => {
    // Toggle the selection state for the given row ID
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

    // Update the selected rows state
    setSelectedRows(updatedSelectedRows);

  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = buGroup.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };
  if (loading) return <div></div>;
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Cash Balances") {
      navigate("/2/master-data");
    } else if (crumb.title === "Cash in Bank") {
      navigate("/company-wise-add");
    } else if (crumb.title === "Company Wise") {
      //   navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };


  const handleDoubleClick = () => {
    console.log("")
  }
  return (
    <div>
      <Header
        title={"Cash Balances > Cash in Bank > Company Wise"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/company-wise-add"}
      />

      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">

            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Company wise"
            />
            <PrimaryActions
              buttons={existingButtons}
              selectedCount={selectedRows.length}
              isExtraButtonsVisible={showExtraIcons}
              selectIds={selectedRows}
              icons={iconId}
              hasFiltersApplied={hasFiltersApplied}
            />
          </div>
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}

            />
          </div>
        </div>
      </div>
      <div>

        <ReTable
          data={data}
          columns={columns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      <div>
        {/* {loading && <CustomeLoading />} */}
      </div>
    </div >
  );
}
