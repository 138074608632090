import RestService from "./RestServices";

const CalendarServices = {
  getAllCalendar: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching Calendar data:", error);
      return { error: true, message: "Error fetching Calendar data" };
    }
  },
  getCalendarById: (id) => {
    return RestService.GetByIdData(`/get-calender-id`, id);
  },
  deleteCalendar:(id)=>{
return RestService.DeleteData('/delete-calendar',id);
  },
  addCalendar: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding Calendar:", error);
      return { error: true, message: "Error adding Calendar" };
    }
  },
};

export default CalendarServices;
