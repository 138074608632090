import React, { useCallback, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import AdminActions from "../../../Components/AdminActions";
import { AdminActionsConfig } from "../../../Components/ActionConfig";
import { useParams, useNavigate } from "react-router-dom";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { TbDeviceDesktopCheck, TbFlag3Filled } from "react-icons/tb";
import { LuKeyRound } from "react-icons/lu";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdTrash,
  IoMdRefresh,
} from "react-icons/io";
import exportTableToExcel from "../../../Components/exportToExcel";

import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineHistory,
  MdOutlineLock,
  MdOutlineLockOpen,
} from "react-icons/md";
import axios from "axios";
import XLSX from "xlsx-js-style"; // Import the xlsx library
import UserService from "../../../../../../rest-services/UserServices";
import { useToast } from "../../../../../../components/toast/toast";
import { GiSettingsKnobs } from "react-icons/gi";
import Header from "../../../../../../components/Header";
import ReTable from "../../../../../../components/Table";
import ColumnSettingsPopup from "../../../../Masterdata/Components/ColumnSettingPopup";
import { RxDropdownMenu } from "react-icons/rx";
import CustomeLoading from "../../../../../../components/CustomeLoading";
import HoverButton from "../../../../../../components/HoverButton";
import CustomButton from "../../../../../../components/CustomButton";
import LegalEntityServices from "../../../../../../rest-services/LegalEntityServices";
import { VscVmActive, VscVmConnect, VscVmOutline } from "react-icons/vsc";
import SearchInput from "../../../../../../components/SearchInput";
import Pagination from "../../../../../../components/PageNation";
import { debounce } from "lodash";
import FilterDropdown from "../../../../../../components/FilterDropdown";
import { PiCopy } from "react-icons/pi";
import ConfirmationDialog from "../../../../../../components/Deletionpopup";
import Draggable from "react-draggable";
import CheckboxPopup from "../../../../../../components/CheckBoxComponent";
import DynamicCustomDropdown from "../../../../../../components/DynamicCustomDropdown";

export default function User() {
  const { iconId } = useParams();
  const navigate = useNavigate();
  const refreshTable = useRef(null);
  const [file, setFile] = useState(null);
  const [userTypes, setUserTypes] = useState([]);
  const [userType, setUserType] = useState('');
  const [usersfilter, setUsersState] = useState([]);
  const [userStatus, setUserStatus] = useState('');

  const [alert, setAlert] = useState(null);
  const [users, setUsers] = useState([]);
  const [filteredusers, setFilteredusers] = useState([]);
  const [filteredcountries, setFilteredcountries] = useState([]);
  const [filteredcompanies, setFilteredcompanies] = useState([]);
  const [filteredCreatedBy, setFilteredCreatedBy] = useState([]);
  const [filteredChangedBy, setFilteredChangedBy] = useState([]);
  const [user, setUser] = useState(null); // To store the selected calendar ID
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs

  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [companyCodeModalopen, setIsCompanyCodeModalopen] = useState(false);
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // Add refreshKey state
  const [userPreference, setUserPreference] = useState("");
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const [usersToShow, setUsersToShow] = useState([]);
  const [countryToShow, setCountryToShow] = useState([]);
  const [companyToShow, setCompanyToShow] = useState([]);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [checkingPswdPolicy, setCheckingPswdPolicy] = useState("");
  const [checkingOtpPolicy, setCheckingOtpPolicy] = useState("");
  const [checkingquestion, setCheckingquestion] = useState("");
  const [filterType, setFilterType] = useState("1"); // 'createdBy' or 'updatedBy'
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
  const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationForLock, setShowConfirmationForLock] = useState(false);
    const [updatedbysearchquery, setupdatedbysearchquery] = useState("");

  const [tempSelectedcreatedby, setTempSelectedcreatedby] =
    useState(selectedCreatedBy);

  const [tempSelectedcountries, setTempSelectedcountries] =
    useState(selectedCountries);

    const [tempSelectedcompanies, setTempSelectedcompanies] =
    useState(selectedCompanies);


  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);
  const [tempSelectedupdatedby, setTempSelectedupdatedby] =
    useState(selectedUpdatedBy);
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [countryCodeSearchQuery, setCountryCodeSearchQuery] = useState("");
  const [companyCodeSearchQuery, setCompanyCodeSearchQuery] = useState("");
      const [countryCodes, setCountryCodes] = useState([]);
        const [stateCodes, setStateCodes] = useState([]);
      const[getExistsLanguageids,setgetExistsLanguageids] = useState([]);

  const [pageSize, setPageSize] = useState(20); // Default page size
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchUsers(1, searchQuery); // Fetch data with updated page size
  };

  // Updatedby popup filter submit function
  const handleApplyUpdatedby = () => {
    setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      updated_by: tempSelectedupdatedby, // Set the filter
    }));
    setIsUpdatedByPopupOpen(false); // Close the popup
  };

// Updatedby popup filter, select or unselect the checkbox changes
  const handleUpdateBySelection = (changedby) => {
    setTempSelectedupdatedby((prev) => {
      return prev.includes(changedby)
        ? prev.filter((item) => item !== changedby)
        : [...prev, changedby];
    });
  };

  useEffect(() => {
    if (assignee.clientId && rolesPermissions.length > 0) {
      fetchUsers();
    }
  }, [assignee.clientId, rolesPermissions]);

  useEffect(() => {
    fetchAssignee();
    fetchUserTypes();
    fetchPasswordPolicy();
    fetchCountryCodes();
    fetchStateProvinceCode();
    fetchExistingLanguageIds();
    fetchOTPPolicy();
    fetchquestion();
  }, []);


  // While creating new user that time need to check OTP policy, if OTP policy is not created means the Error message will comes
  // OTP Policy check funtion
  const fetchOTPPolicy = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/getting-otp-policy`
      );
      console.log("Response from server fetching OTP policy:", response);
      const responseData = response.data.data;
      console.log("checking policy", responseData);
      setCheckingOtpPolicy(responseData);
      console.log("double check users", response.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchquestion = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/getting-question`
      );
      console.log("Response from server fetching OTP policy:", response);
      const responseData = response.data.data;
      console.log("checking policy", responseData);
  setCheckingquestion(responseData);
  console.log("double check users", response.data);
  if (responseData.error) {
    console.error("Error fetching Users data:", responseData.message);
    setAlert({ severity: "error", message: responseData.message });
    return;
  }
} catch (error) {
  console.error("Error fetching Users data", error);
  setAlert({
    severity: "error",
    message: `Error fetching Users data, kindly check the connection ${error.message}`,
  });
}
};

// UserId popup filter Reset function
  const handelUserIdReset = () => {
    setSelectedUsers([]); // Reset selection
  };
  
  // While creating new user that time need to check PASSWORD policy, if PASSWORD policy is not created means the Error message will comes
  // PASSWORD Policy check funtion
  const fetchPasswordPolicy = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/getting-password-policy`
      );
      console.log("Response from server fetching users:", response);
      const responseData = response.data.data;
      console.log("checking policy", responseData);
      setCheckingPswdPolicy(responseData);
      console.log("double check users", response.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
  };

  const fetchCountryCodes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-country-code`
      );
      // console.log("response.data.data",response.data.data);
      setCountryCodes(response.data.data);
      // console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  const fetchStateProvinceCode = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-stateprovince-code`
      );
      console.log("response. state data",response.data.data);
      setStateCodes(response.data.data);
      // console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
    };

    const fetchExistingLanguageIds = async () => {
      try {
          const response = await UserService.getUsers(
            `${process.env.REACT_APP_BASE_URL}/get-language-code`
          );
          console.log("response. Request Number data",response.data);
          setgetExistsLanguageids(response.data.data);
          
          // console.log("Set user types data", response.data.data);
        } catch (error) {
          console.error("Error fetching user types:", error);
        }
        };

// Get All Users with pagination
  const fetchUsers = async (page, searchQuery) => {
    setLoading(true);
    try {
      if (!assignee.clientId) {
        return false;
      }
      console.log("searchQuery is", searchQuery);

      const data = {
        clientId: assignee.clientId,
        limit: pageSize || 10,
        offset: page && pageSize ? (page - 1) * pageSize : 0,
        searchQuery: searchQuery || "",
      };
      // console.log("Request Data for users:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/user/get-user`,
        data
      );
      // console.log("Response from server fetching users:", response);
      let { searchUsers, totalCount } = response;


      // console.log("Check user permisions is", rolesPermissions);
      // const hasPermission = rolesPermissions.some(
      //   (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
      // );

      // console.log("Check user permissions to user view list", hasPermission);

      // if(hasPermission){

      if (!searchUsers) {
        searchUsers = [];
      } else if (!Array.isArray(searchUsers)) {
        searchUsers = [searchUsers];
      }

      // }
      // else{
      //   searchUsers = [];
      // }


      // const responseData = response.data;
      console.log("users data", searchUsers);
      setUsers(searchUsers);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));

    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  // USER SCREEN Global SEARCH FILTER USEEFFECT
   useEffect(() => {
      if (searchQuery.length === 0) {
        fetchUsers(currentPage);
      } else {
        fetchSearchedUsers(searchQuery);
      }
    }, [searchQuery]);

    // CreatedBy Popup Search Filter USEEFFECT
    useEffect(() => {
      if (createdbydatasearchQuery.trim() === "") {
        setFilteredCreatedBy(users); // Show all if search query is empty
      } else {
        const filtered = users.filter((code) =>
          code.createdBy
            .toLowerCase()
            .includes(createdbydatasearchQuery.toLowerCase())
        );
        console.log("set filtered created by record is ",filtered);
        setFilteredCreatedBy(filtered);
      }
    }, [createdbydatasearchQuery, users]);

    // UpdatedBy Popup search filter USEEFFECT
    useEffect(() => {
      if (updatedbysearchquery.trim() === "") {
        setFilteredChangedBy(users); // Show all if search query is empty
      } else {
        console.log("Its is enter in the updated search")
        const filtered = users.filter((code) =>
          (code.changedBy || "") // Ensure it's not null or undefined
            .toLowerCase()
            .includes(updatedbysearchquery.toLowerCase())
        );
        console.log("set filtered update by record is ",filtered);
        setFilteredChangedBy(filtered);
      }
    }, [updatedbysearchquery, users]);

    // User Popup search filter USEEFFECT
     useEffect(() => {
            if (searchTerm.trim() === "") {
              setFilteredusers(users); // Show all if search query is empty
            } else {
              const filtered = users.filter((code) =>
                code.userId
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              );
              setFilteredusers(filtered);
            }
          }, [searchTerm, users]);

    // County Popup search filter USEEFFECT
    useEffect(() => {
      if (countryCodeSearchQuery.trim() === "") {
        setFilteredcountries(users); // Show all if search query is empty
      } else {
        const filtered = users.filter((code) =>
          code.Address.countryCode
            .toLowerCase()
            .includes(countryCodeSearchQuery.toLowerCase())
        );
        setFilteredcountries(filtered);
      }
    }, [countryCodeSearchQuery, users]);


    // Company code Popup search filter USEEFFECT
    useEffect(() => {
      if (companyCodeSearchQuery.trim() === "") {
        setFilteredcompanies(users); // Show all if search query is empty
      } else {
        const filtered = users.filter((code) =>
          code.companyCodeId
            .toLowerCase()
            .includes(companyCodeSearchQuery.toLowerCase())
        );
        setFilteredcompanies(filtered);
      }
    }, [companyCodeSearchQuery, users]);

    

    // USER SCREEN Global SEARCH FILTER function
    const fetchSearchedUsers = async (searchQuery) => {
      // console.log("searchQuery Us", searchQuery);
      const data = { searchQuery };
      console.log("Request Data for search query:", data);
      
      setLoading(true);
      try {
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/search-by-userId`,
          data);
          console.log("search response",response);

        if (response && response.data) {
          const totalCount = Number(response.totalCount) || 0;
          const pages = Math.ceil(totalCount / (pageSize || 1));
          setUsers(response.data);
          setTotalCount(totalCount);
          setTotalPages(pages);
        }
      } catch (error) {
        console.error("Error fetching Bank Account Data", error);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchUsers(currentPage);
      }, [currentPage]);

  // Get all user Types function
  const fetchUserTypes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-alluser-type`
      );
      console.log("set user types", response.data.data);
      setUserTypes(response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };

  // Get Logged in User details function
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  // Filters function for Date from field
  const handleFilterDateFrom = (e) => {
    console.log(filterType);
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_from: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_from: e.target.value,
      }));
    }
  };

  // Filters function for Date To field
  const handleFilterDateto = (e) => {
    if (filterType === "1") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        created_date_to: e.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        updated_date_to: e.target.value,
      }));
    }
  };

  // Logged in User USEEFFECT
  useEffect(() => {
    if (assignee.assignerId) {
      console.log("Assigner ID has changed:", assignee.assignerId);
      fetchUserPermissionDetails();
      fetchUserPreferenceDetails();
    }
  }, [assignee.assignerId]);

  // Get Logged in User Permission details function
  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.assignerId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("user permissions details", response);
      const responseData = response.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
    } catch (error) {
      console.error(error.message); // Set error message
    }
    //  finally {
    //   setLoading(false); // Reset loading state
    // }
  };

  // Get Logged in User Preferred Date and Time format Detail fetching
  const fetchUserPreferenceDetails = async () => {
    try {
      const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
      console.log("Requestfor user pref Data:", data);

      // setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log("user prefference details", response.data);
      setUserPreference(response.data); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    }
    //  finally {
    //   setLoading(false); // Reset loading state
    // }
  };


  const handleCheckboxChangefilter = (userId) => {
    setSelectedUsers((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(userId)
        ? prev.filter((item) => item !== userId) // Remove if already selected
        : [...prev, userId]; // Add if not selected
    });
  };


  const handleCheckboxChange = (rowId) => {
    console.log("checked user rowId", rowId);
    fetchArrayUsers(rowId);
    
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(rowId);
      const nextSelectedRows = isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId];
      setShowExtraIcons(nextSelectedRows.length === 1);

      return nextSelectedRows;
    });
 };

  const fetchArrayUsers = async (userIds) => {
    try {
      // Ensure userIds is in array format
    const formattedUserIds = Array.isArray(userIds) ? userIds : [userIds];

    console.log("Request Data from admin userids actions:", formattedUserIds);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-array-users`,
        { userIds: formattedUserIds  } // Send the correctly formatted request data
      );

      console.log("API Response from users actions data:", response.data);

      const approved = response.data[0]?.approved;

      // Log the extracted approveds
      console.log("Extracted approveds:", approved);
      setUserStatus(approved);
    } catch (error) {
      console.error("Error fetching user details:", error);
      // Optionally handle the error state
    }
  };

  const handleApplyCreatedby = () => {
    setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
    setFilters((prevFilters) => ({
      ...prevFilters,
      created_by: tempSelectedcreatedby, // Set the filter
    }));
    setIsCreatedByPopupOpen(false); // Close the popup
  };

  const handleCreatedbyReset = () => {
    setTempSelectedcreatedby([]); // Reset selection
  };

  const resetSelectedUpdatedBy =() => {
    setTempSelectedupdatedby([]); // Reset selection
  };

  const handleCompanySelection = (companyCode) => {
    console.log("Selected company for filter",companyCode);

    setTempSelectedcompanies((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(companyCode)
        ? prev.filter((item) => item !== companyCode) // Remove if already selected
        : [...prev, companyCode]; // Add if not selected
    });
  };

  const handleCountrySelection = (countryCode) => {
    console.log("Selected county for filter",countryCode);

    setTempSelectedcountries((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(countryCode)
        ? prev.filter((item) => item !== countryCode) // Remove if already selected
        : [...prev, countryCode]; // Add if not selected
    });
  };

  const handleCreatedBySelection = (createdBy) => {
    console.log("select created by",createdBy);
    setTempSelectedcreatedby((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(createdBy)
        ? prev.filter((item) => item !== createdBy) // Remove if already selected
        : [...prev, createdBy]; // Add if not selected
    });
  };



  const handleSubmitUsers = () => {
     // console.log("selected users:", selectedUsers);
    setUsersToShow(selectedUsers); // Set selected IDs to be shown in the form
    setIsUserModalOpen(false) // Close modal after selection
  };

  // Store filters data in local storage USEEFFECT
  useEffect(() => {
    const savedFilters = localStorage.getItem("filterData");
    const savedUsersToShow = localStorage.getItem("usersToShow");
    const savedUsers = localStorage.getItem("fetchUsersData");
    if (savedFilters) {
      // If filters exist in session storage, load them
      setFilters(JSON.parse(savedFilters));
    }

    if (savedUsersToShow) {
      setUsersToShow(JSON.parse(savedUsersToShow)); // Restore selected users from session storage
    }

    if (savedUsers) {
      // If data exists in session storage, load it
      setUsers(JSON.parse(savedUsers));
    } else {
      // Otherwise, fetch the users from the server
      fetchUsers();
    }
  }, []);

  const handleTriggerOTPClick = async () => {
    if (selectedRows.length > 0) {
      setLoading(true);
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-trigger-otp`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("success", "User successfully sent OTP.");
           // Remove deleted users from the selectedRows
           setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchUsers();
        } else if (response.status === 550) {
          toast("error", "The user cannot send mail to this recipient.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
      finally {
        setLoading(false);
      }
    } else {
      console.log("Please select at least one user to Inactive.");
    }
  };
     

  const handleInactiveClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-inactive`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("success", "User Status Changed successfully.");
          // toast("success", "User successfully Change Status");
           // Remove deleted users from the selectedRows
           setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          
          await fetchUsers(currentPage);
          setRefreshKey((prevKey) => prevKey + 1); // Update refreshKey to trigger AdminActions refresh
          await reload();
        } else if (response.status === 400) {
          toast("error", "The user cannot be deactivated at this time.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Inactive.");
    }
  };

  const handleDeleteClick = async () => {
    // if (selectedRows.length === 0) return;
    setShowConfirmation(true);
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };

  const confirmDeletion = async () => {
    setShowConfirmation(false);
    setLoading(true);

    try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/delete-user`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("error", "User successfully Deleted.");

          // Remove deleted users from the selectedRows
          setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchUsers(currentPage);
          await reload();
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      } finally {
        setLoading(false);
      }
    };

    const handleLockUserClick = async () => {
      setShowConfirmationForLock(true);
    }
    const cancelLockStatus = () => {
      setShowConfirmationForLock(false);
    };

    const confirmLockStatus = async () => {
      setShowConfirmationForLock(false);
      setLoading(true);
      try {
        console.log("selected user rows id", selectedRows);
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-lock-user`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("success", "User has successfully changed the lock Status.");
           // Remove deleted users from the selectedRows
           setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchUsers();
          await reload();
          // if (refreshTable.current) {
          //   refreshTable.current();  // Call the refresh function to reload the user data
          // }
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      } finally {
        setLoading(false);
      }
    };

  const handleCancelClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-rejected`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("error", "User successfully Rejected");
           // Remove deleted users from the selectedRows
           setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchUsers(currentPage);
          await reload();
        } else if (response.status === 400) {
          toast("error", "User is unable to Reject.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Reject.");
    }
  };

  const handleApproveClick = async () => {
    if (selectedRows.length > 0) {
      setLoading(true); // Start loading

      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-approved`,
          { userIds: selectedRows, createdBy: assignee.assignerId } // Pass selectedUserIds in the body
        );
        console.log("Response status:", response.status);
        if (response.status === 200) {
          toast("success", "User successfully Approved");

           // Remove deleted users from the selectedRows
           setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchUsers(currentPage);
          await reload();
          // if (refreshTable.current) {
          //   refreshTable.current();  // Call the refresh function to reload the user data
          // }
        } else if (response.status === 400) {
          toast("error", "User is unable to approve.");
        } else if (response.status === 550) {
          toast("warning", "User Email is Not Sent.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      console.log("Please select at least one user to Approve.");
    }
  };



  const handleAuditTrail = () => {
    if (selectedRows.length === 1) {
      const selectedUser = filteredUsers.find(
        (user) => user.userId === selectedRows[0]
      );
      console.log("user id", selectedRows[0]);
      console.log("Icons id", iconId);

      if (selectedUser) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/audit-trail-screen/${iconId}/${selectedRows[0]}`);
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to edit.");
    }
  };

  const handleEditClick = () => {
    // console.log('edit user id',selectedUserIds);
    if (selectedRows.length === 1) {
      console.log("entered user id");
      // console.log()
      const selectedUser = filteredUsers.find(
        (user) => user.userId === selectedRows[0]
      );
      console.log("get user id", selectedUser);
      // console.log('single user id', selectedUser);

      if (selectedUser) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/user-edit-screen/${selectedRows[0]}`, {
          state: { mode: "Edit" },
        });
        // navigate(`/user-edit-screen/${selectedRows[0]}`, { state: { mode: 'edit' } });
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to edit.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };

  const handleAddcopyClick = () => {
    if (selectedRows.length === 1) {
      console.log("check user ids", selectedRows[0]);
      const selectedUser = filteredUsers.find(
        (user) => user.userId === selectedRows[0]
      );
      console.log("user id", selectedUser);

      if (selectedUser) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/user-copy-Add-screen/${selectedRows[0]}`, {
          state: { mode: "add" },
        });
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to Copy.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };

  const handleDownload = async () => {
    if (users.length === 0) {
      setAlert({
        severity: "warning",
        message: "No data available to export!",
      });
      return;
    }

    // Prepare data for export
    const formattedUsers = filteredUsers.map((user) => ({
      "User ID": user.userId,
      Status:
        user.approved === 0
          ? "Created"
          : user.approved === 1
            ? "Changed"
            : user.approved === 2
            ? "Waiting for Approval"
            : user.approved === 3
            ? "Modified"
            : user.approved === 4
            ? "Approved"
            : user.approved === 5
                  ? "Rejected"
                  : "Inactive",
      Lock: user.userStatus === 0 ? "Unlock" : "Lock",
      "Employee No": user.employeeNo,
      Salute: user.salute,
      Username: user.username,
      "First Name": user.firstName,
      "Middle Name": user.middleName,
      "Last Name": user.lastName,
      Language: user.UserPreference?.language || "",
      "Time Format": user.UserPreference?.timeFormat || "N/A",
      "Time Zone": user.UserPreference?.timeZone || "",
      "User Type": user.UserType?.name || "N/A",
      "Company Code": user.companyCodeId,
      Department: user.department,
      // "Valid From": user.validFrom,
      "Valid From": formatDate(user.validFrom, dateFormat),

      "Valid To": formatDate(user.validTo, dateFormat),
      "Email ID": user.email,
      "Address 1": user.Address?.addressLine1 || "",
      "Address 2": user.Address?.addressLine2 || "",
      "Address 3": user.Address?.addressLine3 || "",
      Country: user.Address?.Country?.country_name ?? "",
      "Mobile No": user.mobileNo,
      Nationality: user.nationality,
      "Passport No": user.passportNo,
      "Driving License No": user.drivingLicenseNo,
      "Local ID": user.localId,
      "Joining Date": user.joiningDate,
      "Resign Date": user.resignationDate,
      // "Amount Format": user.UserPreference?.amountFormat || "N/A",
      "Amount Format": amountFormatDisplayMap[user.UserPreference?.amountFormat] || "N/A",
      "Date Format": user.UserPreference?.dateFormat || "N/A",

      // 'Date Format': user.userPreference?.dateFormat || '',
      "Login Status": user.loginStatus === 0 ? "Offline" : "Online",
      // "Last Login Date": user.lastSeenDate ? user.lastSeenDate.split("-").reverse().join(".") : "",
      "Last Login Date": formatDate(user.lastSeenDate, dateFormat),

      "Last Login Time": user.lastSeenTime
        ? new Date(user.lastSeenTime)
          .toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })
          .toLowerCase()
        : "",
      // 'Creation Date': user.creationDate,
      "Creation By": user.createdBy,

      "Creation Date": formatDate(user.creationDate, dateFormat),
      // "Creation Date": user.creationDate ? user.creationDate.split("-").reverse().join(".") : "",
      "Creation Time": user.creationTime
        ? new Date(user.creationTime)
          .toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })
          .toLowerCase()
        : "",

      // 'Creation Time':user.creationTime,
      // 'Update Date': user.changedDate,
      // 'Update Date': user.changedDate ? user.changedDate.split('-').reverse().join('.') : '',
      "Update By": user.changedBy ? user.changedBy : "",

      // "Update Date": user.changedDate ? user.changedDate.split(" ")[0].split("-").reverse().join(".") : "",
      "Update Date": formatDate(user.changedDate, dateFormat),

      "Update Time": user.changedTime
        ? new Date(user.changedTime)
          .toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })
          .toLowerCase()
        : "",

    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedUsers); // Converts JSON to sheet


    
        // Define border style
      const border = {
        top: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
      };
    
         // Apply borders to the first three rows
         formattedUsers.slice(0, 1).forEach((row, rowIdx) => {
              Object.keys(row).forEach((col, colIdx) => {
                const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
                if (worksheet[cellAddress]) {
                  worksheet[cellAddress].s = { border };
                }
              });
            });
      
             // Auto-fit column widths
      const columnWidths = Object.keys(formattedUsers[0]).map((key) => ({
        wch: Math.max(
          key.length, // Column header width
          ...formattedUsers.map((row) => (row[key] ? row[key].toString().length : 0)) // Max content width
        ),
      }));
    
      worksheet["!cols"] = columnWidths;
      
      
    const workbook = XLSX.utils.book_new(); // Creates a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users"); // Appends the sheet to the workbook

    // Export as an Excel file
    XLSX.writeFile(workbook, "Users_Data.xlsx");
  };

  const passwordPolicy = async () => {
    toast("error", "Password Policy Needed.");
  };

  const CountryNotExists = async () => {
    toast("error", "Country Needed.");
  };
  const SecurityQuestionNotExists = async () => {
    toast("error", "Security Question Needed.");
  };
  const StateNotExists = async() => {
    toast("error", "State Needed.");

  };

  const LanguageNotExists= async() => {
    toast("error", "Language Needed.");
  };

  const otpPolicy = async () => {
    toast("error", "OTP Policy Needed.");
  };

  const reload = async () => {
    setLoading(true); // Start loading
    try {
      await fetchUsers(currentPage);
      await fetchPasswordPolicy(); // Fetch users
      setRefreshKey((prevKey) => prevKey + 1); // Update refreshKey to trigger AdminActions refresh
    } catch (error) {
      console.error("Error reloading users:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const [filters, setFilters] = useState({
    status: "",
    userId: "",
    country: "",
    companyCode: "",
    countryCode: [],
    userType: "",
    validFrom: "",
    validTo: "",
    createdBy: "",
    created_date_from: "",
    created_date_to: "",

    changedBy: "",
    updated_date_from: "",
    updated_date_to: "",
  });

  const handleCountryFilterPopup = () => {
    console.log("entered");
    setIsCountryModalOpen(true);
  };

  const handlecompanyCodeFilterPopup = ()=> {
    setIsCompanyCodeModalopen(true);
  }
  const closeCountryModal = () => {
    setIsCountryModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("set usertypes list data", value);
    setUserType(value);
    // If you want to update filters as well:
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const resetFilters = () => {
    setFilters({
      status: "",
      userId: "",
      companyCode: "",
      country: "",
      userType: "",
      validFrom: "",
      countryCode: [],
      validTo: "",
      createdBy: "",
      created_date_from: "",
      created_date_to: "",
        
      changedBy: "",
      updated_date_from: "",
      updated_date_to: "",
    });
    // Clear selected users
    setHasFiltersApplied(false);
    setUsersToShow([]);
    setTempSelectedcreatedby([]);
    setTempSelectedupdatedby([]);
    setSelectedCreatedBy([]);
    setSelectedUpdatedBy([]);
    setSelectedUsers({}); // Reset selected users (uncheck all checkboxes)
    setSearchTerm("");
    localStorage.removeItem("filterData");
    localStorage.removeItem("fetchUsersData");
    localStorage.removeItem("usersToShow");
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const existingButtons = AdminActionsConfig.screen1.map((button) => {
    let onClick;
    let title;

    if (button.icon === IoMdRefresh) {
      onClick = reload; // Reference the reload function, not invoking it
      title = "Refresh";
    } else if (button.icon === IoAddCircleOutline) {
      if (checkingPswdPolicy.length === 0) {
        onClick = passwordPolicy;
      }
      else if(checkingOtpPolicy.length === 0){
        onClick = otpPolicy;
      }
      else if(checkingquestion.length === 0){
        onClick = SecurityQuestionNotExists;
      }
      // else if(countryCodes.length === 0){
      //   onClick = CountryNotExists;
      // }
      // else if(stateCodes.length === 0){
      //   onClick = StateNotExists;
      // }
      // else if(getExistsLanguageids.length === 0){
      //   onClick = LanguageNotExists;
      // }
      else {
        onClick = () => handleNavigate("/14/admin/108/useradd");
      }
      title = "Add User";
    } else if (button.icon === IoSettingsOutline) {
      onClick = handleSettingsButtonClick;
      title = "Table Settings";
    } else if (button.icon === GiSettingsKnobs) {
      onClick = () => {
        setIsModalOpen(true);
      }
      title = "Filter";
    } else if (button.icon === MdOutlineFileUpload) {
      if (checkingPswdPolicy.length === 0) {
        onClick = passwordPolicy;
      }  
      else if(checkingOtpPolicy.length === 0){
        onClick = otpPolicy;
      }
      else if(checkingquestion.length === 0){
        onClick = SecurityQuestionNotExists;
      }
      // else if(countryCodes.length === 0){
      //   onClick = CountryNotExists;
      // }
      // else if(stateCodes.length === 0){
      //   onClick = StateNotExists;
      // }
      // else if(getExistsLanguageids.length === 0){
      //   onClick = LanguageNotExists;
      // }
   
      else{
        onClick = () => handleNavigate("/14/admin/108/user-upload");
      }
      title = "Upload File";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = handleDownload;
      title = "Download File";
    } else {
      onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
      title = button.title || ""; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title, // Add the title property to the button configuration
    };
  });

  // Extra buttons: they are always rendered, but disabled until at least one user is selected
  const extraButtons = [
    {
      icon: IoMdCheckmark, // Pass the component itself
      onClick: handleApproveClick,
      title: "Approve",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdClose,
      onClick: handleCancelClick,
      title: "Reject",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdCreate,
      onClick: handleEditClick,
      title: "Edit",
      disabled: selectedRows.length === 0,
    },
    {
      icon: PiCopy,
      onClick: handleAddcopyClick,
      title: "Copy",
      disabled: selectedRows.length === 0,
    },
   
    {
      icon: LuKeyRound,
      onClick: handleTriggerOTPClick,
      title: "Trigger OTP",
      disabled: selectedRows.length === 0,
    },
    {
      icon: MdOutlineLock, // Pass the component itself
      onClick: handleLockUserClick,
      title: "Lock/Unlock",
      disabled: selectedRows.length === 0,
    },
    {
      icon: MdOutlineHistory,
      onClick: handleAuditTrail,
      title: "Audit Trail",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTrash,
      onClick: handleDeleteClick,
      title: "Delete",
      disabled: selectedRows.length === 0,
    },
    {
      // icon: VscVmActive,
      // icon: VscVmConnect,
      icon: userStatus === '6' ? VscVmActive : VscVmConnect,
      onClick: handleInactiveClick,
      title:  userStatus === '6' ? "Active" : "Inactive",
      disabled: selectedRows.length === 0,
    },
  ];

  // Pass in extra buttons with dynamic disabled state
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );

  // Store Global filter user data in local storage USEEFFECT
  useEffect(() => {
    const savedFetchUsers = localStorage.getItem("fetchUsersData");
    if (savedFetchUsers) {
      setFilteredUsers(JSON.parse(savedFetchUsers));
    }
  }, []);

  // submit Filters data function
  const handleApplyFilter = async () => {
    try {
      setIsModalOpen(false);
      // console.log("selected CreatedBy",selectedCreatedBy);
      // Prepare the filter payload with dynamic filters from state

      // localStorage.setItem('fetchUsersData', JSON.stringify(filteredUsers));
      // console.log()
      const filterPayload = {
        clientId: assignee.clientId,
        approved: filters.status,
        userId: usersToShow,
        country: countryToShow,
        companyCodeId: companyToShow,
        userType: filters.userType,
        validFrom: filters.validFrom,
        validTo: filters.validTo,
        createdBy: selectedCreatedBy,
        changedBy: selectedUpdatedBy,
        created_date_from: filters.created_date_from,
        created_date_to: filters.created_date_to,
        updated_date_from:  filters.updated_date_from,
      updated_date_to:  filters.updated_date_to,
      filterType
      };
      setHasFiltersApplied(true);
      // console.log("filters userid", usersToShow);
      console.log("Filter payload:", filterPayload);

      // Fetch data from the server using the filter payload
      const response = await UserService.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/get-user-filter`,
        filterPayload
      );

      console.log("Response from server for filtered data:", response);

      // const { data, error, message } = response.data;

      // Handle errors if any
      // if (error) {
      //   console.error("Error fetching Users data:", message);
      //   setAlert({ severity: "error", message });
      //   return;
      // }

      console.log("testing 1", response.searchUsers);
      // setUsers(response.data); // Set user preference data
      setUsers(response.searchUsers);
      setTotalCount(response.totalCount);
      setTotalPages(Math.ceil(response.totalCount / pageSize));

      // Store filtered users in session storage
      localStorage.setItem("filterData", JSON.stringify(filters));
      // localStorage.setItem("fetchUsersData", JSON.stringify(response.data));
      localStorage.setItem("usersToShow", JSON.stringify(usersToShow));
    } catch (error) {
      console.error("Error fetching Users data:", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data. Please check your connection: ${error.message}`,
      });
    }
  };

  // After selected Countries these function will display Country +more records will show
  const getSelectDisplayLabelForCountry = () => {
    const count = countryToShow.length;
    console.log("count for selected country ", count);
    console.log("selected country's ", countryToShow);

    if (count === 1) {
      console.log("equal to one count");
      // Show the single selected user ID when only one is selected
      return countryToShow[0];
    } else if (count > 1) {
      console.log("more than 1 count");
      console.log(
        `more than 1 count display ${countryToShow[0]} +${count - 1}`
      );
      // Show the first user ID and the number of remaining users
      return `${countryToShow[0]} +${count - 1}`;
    } else {
      // Default text when no users are selected
      return "Select Country";
    }
  };

  const getSelectDisplayLabelForcompanyCode = () =>{
    const count = companyToShow.length;
    console.log("count for selected country ", count);
    console.log("selected country's ", companyToShow);

    if (count === 1) {
      console.log("equal to one count");
      // Show the single selected user ID when only one is selected
      return companyToShow[0];
    } else if (count > 1) {
      console.log("more than 1 count");
      console.log(
        `more than 1 count display ${companyToShow[0]} +${count - 1}`
      );
      // Show the first user ID and the number of remaining users
      return `${companyToShow[0]} +${count - 1}`;
    } else {
      // Default text when no users are selected
      return "Select Company";
    }
  };

  // After selected Users these function will display Users +more records will show
  const getSelectDisplayLabel = () => {
    const count = usersToShow.length;
    console.log("count", count);

    if (count === 1) {
      console.log("equal to one count");
      // Show the single selected user ID when only one is selected
      return usersToShow[0];
    } else if (count > 1) {
      console.log("more than 1 count");
      console.log(`more than 1 count display ${usersToShow[0]} +${count - 1}`);
      // Show the first user ID and the number of remaining users
      return `${usersToShow[0]} +${count - 1}`;
    } else {
      // Default text when no users are selected
      return "Select User";
    }
  };

  // Bread Crumbs navigation
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "User & SOD") {
      console.log("Navigating to /admin");
      navigate("/14/admin");
    } else if (crumb.title === "User") {
      console.log("Navigating to /User");
      navigate("/14/admin/108/userscreen");
    } else if (crumb.title === "Admin") {
      console.log("Navigating to /14/admin");
      navigate("/14/admin");
    }
    else if (crumb.title === "View") {
      console.log("View");
      // navigate("/14/admin");
    }
    else if (crumb.title === "Add") {
      console.log("Add");
      // navigate("/14/admin");
    }
    //  else if (crumb.path) {
    //   navigate(crumb.path); // Navigate to the path specified in the crumb
    // }
  };

  
const formatDate = (dateString, format) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const shortYear = String(year).slice(-2);

  const formatMap = {
      "DD/MM/YYYY": `${day}/${month}/${year}`,
      "MM/DD/YYYY": `${month}/${day}/${year}`,
      "DD/MM/YY": `${day}/${month}/${shortYear}`,
      "MM/DD/YY": `${month}/${day}/${shortYear}`,
      "DD.MM.YYYY": `${day}.${month}.${year}`,
      "DDMMYY": `${day}${month}${shortYear}`,
      "DD-MM-YYYY": `${day}-${month}-${year}`,
  };

  return formatMap[format] || dateString;
};

const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format


const amountFormatDisplayMap = {
  "1": "1,234,567.89",
  "2": "1.234.567,890",
  "3": "12,34,567.890"
};

  // Table columns
  const columns = [
    {
      Header: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TbFlag3Filled style={{ color: "#ffff" }} />
        </div>
      ),
      accessor: "approved",
      visible: true,
      width: 100,
      Cell: ({ row }) => {
        const { approved } = row.original;
        const colorMap = {
          0: "gray",
          1: "#AF52DE",
          2: "yellow",
          3: "#FF9500 ", //orange color
          4: "#29CB00",
          5: "red",
        };
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TbFlag3Filled style={{ color: colorMap[approved] || "black" }} />
          </div>
        );
      },
    },

    {
      Header: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MdOutlineLock style={{ color: "#ffff" }} />
        </div>
      ),
      accessor: "userStatus",
      visible: true,
      width: 200,
      Cell: ({ row }) => {
        const user = row.original;
        return user.userStatus == 0 ? (
          <MdOutlineLockOpen />
        ) : (
          <MdOutlineLock style={{ color: "red" }} />
        );
      },
    },

    // {
    //   Header: 'Status',
    //   accessor: 'status', // This is the new last seen/online status field
    //   visible: true,
    //   width: 200,
    //   Cell: ({ row }) => {
    //     const user = row.original;
    //     return user.status;
    //   }
    // },

    // Use the icon directly here
    // { Header: 'Client ID', accessor: 'clientId' , visible: true, width: 200 },
    { Header: "User ID", accessor: "userId", visible: true, width: ("User ID".length +2)*10, alignment: "left" },
    // { Header: 'User Last seen', accessor: 'lastSeen' , visible: true, width: 200 },
    {
      Header: "Employee No",
      accessor: "employeeNo",
      visible: true,
      width: ("Employee No".length +2)*10, alignment: "left"
    },
    { Header: "Salute", accessor: "salute", visible: true, width: ("Salute".length +2)*10, alignment: "left" },
    { Header: 'Accounting system id', accessor: 'accountingSystemId', visible: true, width: ("Accounting system id".length +2)*10, alignment: "left" },
    
    { Header: "Username", accessor: "username", visible: true, width: ("Username".length +2)*10, alignment: "left" },
    { Header: "First Name", accessor: "firstName", visible: true, width: ("First Name".length +2)*10, alignment: "left" },
    {
      Header: "Middle Name",
      accessor: "middleName",
      visible: true,
      width: ("Middle Name".length +2)*10, alignment: "left"
    },
    { Header: "Last Name", accessor: "lastName", visible: true, width: ("Last Name".length +2)*10, alignment: "left" },
    {
      Header: "Language",
      accessor: "UserPreference.language",
      visible: true,
      width: ("Language".length +2)*10, alignment: "left"
    },
    {
      Header: "Time Format",
      accessor: "UserPreference.timeFormat",
      visible: true,
      width: ("Time Format".length +2)*10,
    },
    // { Header: 'Time Format', accessor: 'UserPreference.timeFormat' , visible: true, width: ("".length +2)*10, Cell: ({ row }) => { const user = row.original;  return user.UserPreference.timeFormat == 1 ? ('12 Hours' ) : ('24 Hours' );  } },
    // {
    //   Header: 'Time Format',
    //   accessor: 'UserPreference.timeFormat',
    //   visible: true,
    //   width: ("".length +2)*10,
    //   Cell: ({ row }) => {
    //     const user = row.original;
    //     const timeFormat = user.UserPreference?.timeFormat; // Check if UserPreference exists
    //     return timeFormat == 1 ? '12 Hours' : timeFormat == 2 ? '24 Hours' : 'N/A';
    //   }
    // },

    {
      Header: "Time Zone",
      accessor: "UserPreference.timeZone",
      visible: true,
      width: ("Time Zone".length +2)*10,
    },
    // { Header: 'User Type', accessor: 'userType', visible: true, width: ("".length +2)*10, Cell: ({ row }) => { const user = row.original; return user.userType == 1 ? 'User' : user.userType == 2 ? 'Super User' : user.userType == 3 ? 'Approvals' : user.userType == 4 ? 'Admin' : ' '; } },
    {
      Header: "User Type",
      accessor: "userType",
      visible: true,
      width: ("User Type".length +2)*10,
      Cell: ({ row }) => {
        const user = row.original;
        return user.userType == 1
          ? "User"
          : user.userType == 2
            ? "Super User"
            : user.userType == 3
              ? "Approvals"
              : user.userType == 4
                ? "Admin"
                : user.userType == 5
                  ? "Consultant"
                  : user.userType == 6
                    ? "Support"
                    : user.userType == 7
                      ? "System"
                      : user.userType == 8
                        ? "KICKSTART"
                        : "N/A";
      },
    },

    {
      Header: "Company Code",
      accessor: "companyCodeId",
      visible: true,
      width: ("Company Code".length +2)*10, alignment: "left"
    },
    { Header: "Department", accessor: "department", visible: true, width: ("Department".length +2)*10, alignment: "left" },
    // { Header: 'Valid From', accessor: 'validFrom' , visible: true, width: ("".length +2)*10, canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;  } },
    { Header: "Valid From", accessor: "validFrom", visible: true, width: ("Valid From".length +2)*10,  
      Cell: ({ value }) => formatDate(value, dateFormat),
   },
    { Header: "Valid To", accessor: "validTo", visible: true, width: ("Valid To".length +2)*10,
      Cell: ({ value }) => formatDate(value, dateFormat),
     },

    // { Header: 'Valid To', accessor: 'validTo' , visible: true, width: ("".length +2)*10, canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; } },
    { Header: "Email ID", accessor: "email", visible: true, width: ("Email ID".length +2)*10, alignment: "left" },
    { Header: "Mobile No", accessor: "mobileNo", visible: true, width: ("Mobile No".length +2)*10, alignment: "left" },
    {
      Header: "Nationality",
      accessor: "nationality",
      visible: true,
      width: ("Nationality".length +2)*10, alignment: "left"
    },
    {
      Header: "Passport No",
      accessor: "passportNo",
      visible: true,
      width: ("Passport No".length +2)*10, alignment: "left"
    },
    {
      Header: "Driving License No",
      accessor: "drivingLicenseNo",
      visible: true,
      width: ("Driving License No".length +2)*10, alignment: "left"
    },
    { Header: "Local ID", accessor: "localId", visible: true, width: ("Local ID".length +2)*10, alignment: "left" },
    {
      Header: "Joining Date",
      accessor: "joiningDate",
      visible: true,
      width: ("Joining Date".length +2)*10,
      Cell: ({ value }) => formatDate(value, dateFormat),
    },
    {
      Header: "Resign Date",
      accessor: "resignationDate",
      visible: true,
      width: ("Resign Date".length +2)*10,
      Cell: ({ value }) => formatDate(value, dateFormat),
    },

    {
      Header: "Door No",
      accessor: "Address.door_no",
      visible: true,
      width: ("DoorNo".length +2)*10, alignment: "left"
    },
    {
      Header: "Floor No",
      accessor: "Address.floor_no",
      visible: true,
      width: ("FloorNo".length +2)*10, alignment: "left"
    },
    {
      Header: "Building No",
      accessor: "Address.building_no",
      visible: true,
      width: ("BuildingNo".length +2)*10, alignment: "left"
    },{
      Header: "Building Name",
      accessor: "Address.building_name",
      visible: true,
      width: ("BuildingName".length +2)*10, alignment: "left"
    },{
      Header: "LandMark",
      accessor: "Address.landMark",
      visible: true,
      width: ("LandMark".length +2)*10, alignment: "left"
    },{
      Header: "Street No",
      accessor: "Address.streetNo",
      visible: true,
      width: ("StreetNo".length +2)*10, alignment: "left"
    },{
      Header: "Street Name",
      accessor: "Address.streetName",
      visible: true,
      width: ("StreeName".length +2)*10, alignment: "left"
    },{
      Header: "Road No",
      accessor: "Address.roadNo",
      visible: true,
      width: ("RoadNo".length +2)*10, alignment: "left"
    },{
      Header: "Road Name",
      accessor: "Address.roadName",
      visible: true,
      width: ("RoadNamw".length +2)*10, alignment: "left"
    },{
      Header: "Block",
      accessor: "Address.areaBlock",
      visible: true,
      width: ("Block".length +2)*10, alignment: "left"
    },{
      Header: "Area Name",
      accessor: "Address.areaName",
      visible: true,
      width: ("AreaName".length +2)*10, alignment: "left"
    },{
      Header: "Phase",
      accessor: "Address.phase",
      visible: true,
      width: ("Phase".length +2)*10, alignment: "left"
    },{
      Header: "Sector",
      accessor: "Address.sector",
      visible: true,
      width: ("Sector".length +2)*10, alignment: "left"
    },{
      Header: "Village",
      accessor: "Address.village",
      visible: true,
      width: ("Village".length +2)*10, alignment: "left"
    },{
      Header: "Town",
      accessor: "Address.town",
      visible: true,
      width: ("Town".length +2)*10, alignment: "left"
    },{
      Header: "City Name",
      accessor: "Address.city",
      visible: true,
      width: ("CityName".length +2)*10, alignment: "left"
    },
    {
      Header: "Address 1",
      accessor: "Address.addressLine1",
      visible: true,
      width: ("Address 1".length +2)*10, alignment: "left"
    },    
    {
      Header: "Address 2",
      accessor: "Address.addressLine2",
      visible: true,
      width: ("Address 2".length +2)*10, alignment: "left"
    },
    {
      Header: "Address 3",
      accessor: "Address.addressLine3",
      visible: true,
      width: ("Address 3".length +2)*10, alignment: "left"
    },
    {
      Header: "Country",
      accessor: "Address.countryCode",
      visible: true,
      width: ("Country".length +2)*10, alignment: "left"
    },
    { Header: 'State Province', accessor: 'Address.state', visible: true, width: ("StateProvince".length +2)*10, alignment: "left" },
    { Header: 'District', accessor: 'Address.district', visible: true, width: ("District".length +2)*10, alignment: "left" },
    { Header: 'Postal Code', accessor: 'Address.postalCode', visible: true, width: ("PostalCode".length +2)*10, alignment: "left" },
    { Header: 'Zip Code', accessor: 'Address.zipCode', visible: true, width: ("ZipCode".length +2)*10, alignment: "left" },
    { Header: 'PO Box', accessor: 'Address.poBox', visible: true, width: ("  POBox".length +2)*10, alignment: "left" },
    
    // { Header: 'Joining Date', accessor: 'joiningDate' , visible: true, width: 200 , canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;  } },
   

    // { Header: 'Resign Date', accessor: 'resignationDate' , visible: true, width: 200 , canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;  } },
   
    {
      Header: "Date Format",
      accessor: "UserPreference.dateFormat",
      visible: true,
      width: ("Date Format".length +2)*10, alignment: "left",
    },
     {
      Header: "Amount Format",
      accessor: "UserPreference.amountFormat",
      visible: true,
      width: ("Amount Format".length +2)*10,
      Cell: ({ value }) => amountFormatDisplayMap[value] || value // Map stored value to display format
    },
    
    // {
    //   Header: 'Date Format',
    //   accessor: 'UserPreference.dateFormat',
    //   visible: true,
    //   width: ("".length +2)*10,
    //   Cell: ({ row }) => {
    //     const user = row.original;
    //     const dateFormat = user.UserPreference?.dateFormat; // Check if UserPreference exists
    //     return dateFormat == 1 ? 'DD/MM/YYYY' :
    //            dateFormat == 2 ? 'MM/DD/YYYY' :
    //            dateFormat == 3 ? 'DD/MM/YY' :
    //            dateFormat == 4 ? 'MM/DD/YY' :
    //            dateFormat == 5 ? 'DD.MM.YYYY' :
    //            dateFormat == 6 ? 'DDMMYY' :
    //            'N/A'; // Default when there's no date format
    //   }
    // },

    // { Header: 'Login Status', accessor: 'loginStatus', visible: true, width: ("".length +2)*10, Cell: ({ row }) => { const user = row.original; return user.loginStatus == 0 ? 'Offline' : user.loginStatus == 1 ? 'Online'  : 'N/A'; } },
    {
      Header: "Login Status",
      accessor: "loginStatus",
      visible: true,
      width: ("Login Status".length +2)*10,
      Cell: ({ row }) => {
        const user = row.original;
        let statusText = "N/A";
        let statusStyle = {}; // Default style

        if (user.loginStatus === 0) {
          statusText = "Offline";
          statusStyle = { fontWeight: 200, color: "grey" }; // Offline style
        } else if (user.loginStatus === 1) {
          statusText = "Online";
          statusStyle = { fontWeight: 600, color: "#29CB00" }; // Online style (green text)
        }

        return <span style={statusStyle}>{statusText}</span>;
      },
    },

    // {
    //   Header: "Last Login Date",
    //   accessor: "lastSeenDate",
    //   visible: true,
    //   width: ("".length +2)*10,
    //   canResize: true,
    //   Cell: ({ value }) => {
    //     if (!value) return "";
    //     const date = new Date(value);
    //     return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    //   },
    // },

    {
      Header: "Last Login Date",
      accessor: "lastSeenDate",
          visible: true,
          width: ("Last Login Date".length +2)*10,
          canResize: true,
          Cell: ({ value }) => formatDate(value, dateFormat),
      },

    // {
    //   Header: "Last Login Time",
    //   accessor: "lastSeenTime",
    //   visible: true,
    //   width: ("".length +2)*10,
    // },

    {
      Header: "Last Login Time",
      accessor: "lastSeenTime",
      visible: true,
      width: ("Last Login Time".length +2)*10,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },

    // { Header: 'Last Login Time', accessor: 'lastSeenTime' ,visible: true, width: ("".length +2)*10,canResize: true, Cell: ({ value }) => {if (!value) return ""; const date = new Date(value);return date.toLocaleTimeString("en-GB", { hour: '2-digit',minute: '2-digit',second: '2-digit', hour12: true, }); }},
    { Header: "Created By", accessor: "createdBy", visible: true, width: ("Created By".length +2)*10, alignment: "left" },
 
    {
      Header: "Creation Date",
      accessor: "creationDate",
      visible: true,
      width: ("Creation Date".length +2)*10,
      canResize: true,
      Cell: ({ value }) => formatDate(value, dateFormat),
  },
    {
      Header: "Creation Time",
      accessor: "creationTime",
      visible: true,
      width: ("Created Time".length +2)*10,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
    { Header: "Update By", accessor: "changedBy", visible: true, width: ("Update By".length +2)*10, alignment: "left" },
    // {
    //   Header: "Update Date",
    //   accessor: "changedDate",
    //   visible: true,
    //   width: ("".length +2)*10,
    //   canResize: true,
    //   Cell: ({ value }) => {
    //     if (!value) return "";
    //     const date = new Date(value);
    //     return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    //   },
    // },

    {
      Header: "Update Date",
      accessor: "changedDate",
      visible: true,
      width: ("Update Date".length +2)*10,
      canResize: true,
      Cell: ({ value }) => formatDate(value, dateFormat),
  },

  {
    Header: "Update Time",
    accessor: "changedTime",
    visible: true,
    width: ("Update Time".length +2)*10,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
    },
  },
    // {
    //   Header: "Update Time",
    //   accessor: "changedTime",
    //   visible: true,
    //   width: ("".length +2)*10,
    // },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showExtraIcons, setShowExtraIcons] = useState(false);


  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));
  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  // Refetch filtered data upon a filter change or reload

  const handleSubmitCountry = () => {
    console.log("ENter in the submit country field");
    console.log("submit selected country",tempSelectedcountries);
    setCountryToShow(tempSelectedcountries); // Set selected IDs to be shown in the form
    closeCountryModal(); // Close modal after selection
  };
  
  const handleSubmitCompany = () => {
    console.log("ENter in the submit country field");
    console.log("submit selected country",tempSelectedcompanies);
    setCompanyToShow(tempSelectedcompanies); // Set selected IDs to be shown in the form
    setIsCompanyCodeModalopen(false); // Close modal after selection
  };

  const handleCountryReset = () => {
    setTempSelectedcountries([]); // Reset selection
    setSelectedCountry([]);
  };

  const handleCompanyReset = () => {
    setTempSelectedcountries([]); // Reset selection
    setSelectedCountry([]);
  };

  useEffect(() => {
    console.log("check users filtered", users);
    console.log("check users data", users);

    if (users && users) {
      const filtered = users.filter(
        (user) =>
          user.userId &&
          user.userId.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchQuery, users]);

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = filteredUsers.map((item) => item.userId); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };

  const handleDoubleClick = (id) => {
    console.log("Check user id", id);
    console.log("Check iconId from params", iconId);
    console.log("Check permissions for view", rolesPermissions);

    // Check if permissionsId is 5 and iconId matches the iconId from params
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );

    console.log("Check user permissions with iconId", hasPermission);

    if (hasPermission) {
      navigate(`/user-view-screen/${id}`, { state: { mode: "view" } });
      console.log("Navigated to user view screen.");
    } else {
      console.log(
        "No permission with permissionsId === 3 and matching iconId, navigation skipped."
      );
    }
  };


  const statusOptions = [
    { value: "0", label: "Created", className: "text-gray-500" },
    { value: "1", label: "Changed", className: "text-gray-500" },
    { value: "2", label: "Waiting for approval", className: "text-orange-400" },
    { value: "3", label: "Modified", className: "text-yellow-500" },
    { value: "4", label: "Approved", className: "text-[#29CB00]" },
    { value: "5", label: "Rejected", className: "text-red-500" },
    { value: "6", label: "Inactive", className: "text-black" },
  ];

  

  return (
    <div>
      <Header
        title={"Admin > User & SOD > User"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/14/admin"}
      />     
      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onChange={(e) => fetchUsers(searchQuery)}
              placeholder="Search User Id"
              maxLength={12}
            />

            <AdminActions
              key={refreshKey} // Force re-render by updating key
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows}
              hasFiltersApplied={hasFiltersApplied}

            />
          </div>

          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
          <Draggable handle=".modal-header">   
          {/* Backdrop */}
          <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50">
          {/* Modal Content */}
          <div className="relative bg-white rounded-lg p-6 w-10/12 max-w-3xl shadow-lg">
         <div className="modal-header flex justify-between items-center cursor-move p-4 bg-white-100 rounded-t-lg">
               <h2 className="text-customBlue font-semibold mb-5">
                Apply Filters
              </h2>
              <HoverButton
                onClick={resetFilters}
                title={"Reset Filter"}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              {/* Form grid */}
              <div className="col-span-2 sm:col-span-1">
                {/* Status */}
                <div className="flex items-center mb-4">
                  <label className="text-customGray text-xs mr-2 w-[140px]">
                    Status:
                  </label>
                  <select
                   name="status"
                   value={filters.status}
                   onChange={handleChange}
                   className="text-customGray text-xs border border-gray-300 pl-1 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 "
                 >
                 <option value="" className=" text-blue-500">
                     Select Status
                   </option>
                   <option value="0" className=" text-gray-500">
                     Created
                   </option>
                   <option value="1" className=" text-[#AF52DE]">
                     Changed
                   </option>
                  
                   <option value="2 " className=" text-yellow-500">
                     Waiting for Approval
                   </option>
                   <option value="3" className=" text-orange-400">
                     Modified
                   </option>
                   <option value="4" className=" text-[#29CB00]">
                     Approved
                   </option>
                   <option value="5" className=" text-red-500">
                     Rejected
                   </option>
                   <option value="6" className=" text-black">
                     Inactive
                   </option>
                 </select>

                  {/* <FilterDropdown
                    name="status"
                    value={filters.status}
                    onChange={handleChange}
                    options={statusOptions}
                    placeholder="Select Status"
                    className="bg-white text-sm"
                  /> */}
                </div>

                {/* User ID - Searchable and Multi-Select */}
                <div className="flex items-center">
                  <label className="text-customGray text-xs mr-2 w-[140px]">
                    User ID:
                  </label>
                  <button
                    onClick={() =>{
                      setSelectedUsers(usersToShow);
                      setIsUserModalOpen(true);
                      }} // Trigger modal or selection dropdown
                      className={`border border-gray-300 p-2 rounded-md w-[165px] h-[40px] text-customGray text-xs focus:outline-none hover:border-blue-400 text-left flex items-center justify-between border-gray-300 hover:border-blue-400'}`}
                  >
                    <span className="truncate text-xs">
                      {Array.isArray(filters?.userId) && filters.userId.length > 0
                        ? filters.userId.join(", ") // Display selected users
                        : getSelectDisplayLabel() || "Select User"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                </div>
              </div>

              <div className="col-span-2 flex justify-between items-center">
                {/* Company Code */}
                <div className="flex items-center">
                  <label className="text-customGray text-xs mr-2 w-[140px]">
                    Company Code:
                  </label>
                  {/* <input
                    type="text"
                    name="companyCode"
                    value={filters.companyCode}
                    onChange={handleChange}
                    placeholder="Input"
                    className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                  /> */}
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={handlecompanyCodeFilterPopup}
                  >
                  <span className="truncate text-xs">
                    {Array.isArray(filters?.companyCode) && filters.companyCode.length > 0
                      ? filters.companyCode.join(", ") // Display selected users
                      : getSelectDisplayLabelForcompanyCode() || "Select Company"}
                  </span>

                    <RxDropdownMenu className="ml-2 text-lg text-sm" />
                  </button>
                </div>

                {/* Country Code */}
                <div className="flex items-center">
                  <label className="text-customGray text-xs mr-2 w-[140px]">
                    Country :
                  </label>
                  <button
                    className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
                    onClick={handleCountryFilterPopup}
                  >
                  <span className="truncate text-xs">
                    {Array.isArray(filters?.country) && filters.country.length > 0
                      ? filters.country.join(", ") // Display selected users
                      : getSelectDisplayLabelForCountry() || "Select Country"}
                  </span>

                    <RxDropdownMenu className="ml-2 text-lg text-sm" />
                  </button>
                </div>
              </div>

              {/* User Type */}
              <div className="flex items-center">
                <label className="text-customGray text-xs mr-2 w-[140px]">
                  User Type:
                </label>
                <select
                  name="userType"
                  value={filters.userType}
                  onChange={handleChange}
                  className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                >
                  <option className="text-sm" value="">Select user type</option>
                  {userTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>

{/* <DynamicCustomDropdown
         userTypes={userTypes}      // Pass userTypes data
         userType={userType}        // Pass selected userType
         setUserType={setUserType}  // Pass the setter function
        //  isViewMode={isViewMode}    // Pass the view mode state
        //  isEditMode={isEditMode}    // Pass the edit mode state
          width="w-40"
          height="h-10"
          className="ml-1"
          placeholder="Select user type"
       /> */}

              </div>

              <div className="col-span-2 flex justify-between items-center">
                {/* Valid From */}
                <div className="flex items-center">
                  <label className="text-customGray text-xs mr-2 w-[140px]">
                    Valid From:
                  </label>
                  <input
                    type="date"
                    name="validFrom"
                    value={filters.validFrom}
                    onChange={handleChange}
                    className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                  />     
                </div>

                {/* Valid To */}
                <div className="flex items-center">
                  <label className="text-customGray text-xs text-sm mr-2 w-[140px]">
                    Valid To:
                  </label>
                  <input
                    type="date"
                    name="validTo"
                    value={filters.validTo}
                    onChange={handleChange}
                    className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                  />
                </div>
              </div>

              {/* Created/Updated by */}
              <div className="flex items-center">
                {/* Dropdown for selecting 'Created By' or 'Updated By' */}
                <select
                  name="filterType"
                  value={filterType} // State to track the selected filter option
                  onChange={(e) => {
                    setFilterType(e.target.value); // Update the selected filter
                  }}
                  className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[132px] h-[40px] focus:outline-none hover:border-blue-400"
                >
                  <option value="1">Created By</option>
                  <option value="2">Updated By</option>
                </select>

                {/* Button to open the popup */}
                <button
                  className="text-customGray  text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-4"
                  onClick={() => {
                    if (filterType == "1") {
                      setIsCreatedByPopupOpen(true);
                      setTempSelectedcreatedby(selectedCreatedBy);// Open the Created By popup
                    } else if (filterType == "2") {
                      setIsUpdatedByPopupOpen(true); // Open the Updated By popup
                      setTempSelectedupdatedby(selectedUpdatedBy); // Set the selected data correctly before opening the popup
                    }

                  }}
                  disabled={!filterType} // Disable button if no filter is selected
                >
                  <span className="truncate">
                    {filterType === "1"
                      ? selectedCreatedBy.length > 0
                        ? selectedCreatedBy.join(", ")
                        : "Created By"
                      : filterType === "2"
                        ? selectedUpdatedBy.length > 0
                          ? selectedUpdatedBy.join(", ")
                          : "Updated By"
                        : "Select Filter"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
              </div>

              {/* Date From and Date TO */}
              {filterType && (
                  <div className="col-span-2 flex justify-between items-center">
                    <div className="flex items-center">
                      <label className="text-customGray text-xs mr-2 w-[140px]">
                        {filterType === "createdBy"
                          ? " Date From:"
                          : " Date From:"}
                      </label>
                      <input
                        type="date"
                        name="dateFrom"
                        value={
                          filterType === "1"
                            ? filters.created_date_from
                            : filters.updated_date_from
                        }
                        onChange={(e) => handleFilterDateFrom(e)}
                        className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="text-customGray text-xs mr-2 w-[140px]">
                        {filterType === "createdBy" ? " Date To:" : " Date To:"}
                      </label>
                      <input
                        type="date"
                        name="dateTo"
                        value={
                          filterType === "1"
                            ? filters.created_date_to
                            : filters.updated_date_to
                        }
                        onChange={(e) => handleFilterDateto(e)}
                        className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                      />
                    </div>
                  </div>
                )}

              <CheckboxPopup
                title="Select Created By"
                isOpen={isCreatedByPopupOpen}
                onClose={() => setIsCreatedByPopupOpen(false)}
                onSave={handleApplyCreatedby}
                onReset={handleCreatedbyReset}
                searchPlaceholder="Search User Id"
                searchQuery={createdbydatasearchQuery}
                onSearchChange={(e) => setcreatedbydatasearchQuery(e.target.value)}
                items={[...new Set(
                  filteredCreatedBy
                    .map((user) => user.createdBy) // Extract createdBy
                    .filter((createdBy) => createdBy) // Filter out empty or falsy values
                )]
                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                .map((uniqueCreatedBy) => ({
                  id: uniqueCreatedBy,
                  name: uniqueCreatedBy,
                }))
              }
                selectedItems={tempSelectedcreatedby}
                onItemToggle={handleCreatedBySelection}
              />

              <CheckboxPopup
                title="Select Updated By"
                isOpen={isUpdatedByPopupOpen}
                onClose={() => setIsUpdatedByPopupOpen(false)}
                onSave={handleApplyUpdatedby}
                onReset={resetSelectedUpdatedBy}
                searchPlaceholder="Search User Id"
                searchQuery={updatedbysearchquery}
                onSearchChange={(e) => setupdatedbysearchquery(e.target.value)}
                items={[...new Set(
                  filteredChangedBy
                    .map((user) => user.changedBy) // Extract changedBy
                    .filter((changedBy) => changedBy) // Filter out empty or falsy values
                )]
                .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
                .map((uniquechangedBy) => ({
                  id: uniquechangedBy,
                  name: uniquechangedBy,
                }))
              }
                selectedItems={tempSelectedupdatedby}
                onItemToggle={handleUpdateBySelection}
              />                
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <HoverButton
                onClick={closeModal}
                title={"Cancel"}
              />
              <CustomButton
                title={"Apply"}
                onClick={() => {
                  handleApplyFilter();
                }}
              />
            </div>

          </div>
          </div>
      </Draggable>
      </div>
      )}


      <CheckboxPopup
        title="Select User"
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
        onSave={handleSubmitUsers}
        onReset={handelUserIdReset}
        searchPlaceholder="Search User"
        searchQuery={searchTerm}
        onSearchChange={(e) => setSearchTerm(e.target.value)}
        items={filteredusers
          .sort((a, b) => a.userId.localeCompare(b.userId))

        .map((code) => ({
          id: code.userId,
          name: code.userId,
        }))}
        selectedItems={selectedUsers}
        onItemToggle={handleCheckboxChangefilter}
      />

      <CheckboxPopup
        title="Select Country"
        isOpen={isCountryModalOpen}
        onClose={() => setIsCountryModalOpen(false)}
        onSave={handleSubmitCountry}
        onReset={handleCountryReset}
        searchPlaceholder="Search Country Code"
        searchQuery={countryCodeSearchQuery}
        onSearchChange={(e) => setCountryCodeSearchQuery(e.target.value)}
        items={[...new Set(
          filteredcountries
          .map((user) => user.Address?.countryCode) // Safely access countryCode
          .filter((countryCode) => countryCode) // Remove empty, null, or undefined
        )]
        .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
        .map((uniquecountryId) => ({
          id: uniquecountryId,
          name: uniquecountryId,
        }))
      }
        selectedItems={tempSelectedcountries}
        onItemToggle={handleCountrySelection}
      />

<CheckboxPopup
        title="Select Company"
        isOpen={companyCodeModalopen}
        onClose={() => setIsCompanyCodeModalopen(false)}
        onSave={handleSubmitCompany}
        onReset={handleCompanyReset}
        searchPlaceholder="Search Company Code"
        searchQuery={companyCodeSearchQuery}
        onSearchChange={(e) => setCompanyCodeSearchQuery(e.target.value)}
        items={[...new Set(
          filteredcompanies
          .map((user) => user.companyCodeId) // Safely access countryCode
          .filter((companyCodeId) => companyCodeId) // Remove empty, null, or undefined
        )]
        .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
        .map((uniquecompanyCodeId) => ({
          id: uniquecompanyCodeId,
          name: uniquecompanyCodeId,
        }))
      }
        selectedItems={tempSelectedcompanies}
        onItemToggle={handleCompanySelection}
      />

      <div style={{minWidth: "330px"}}>
        {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

        <ReTable
          data={filteredUsers}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
          rowKey="userId" // Pass userId as the key for row selection
          userTableWidth={"330px"}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
       <ConfirmationDialog
              open={showConfirmation}
              title="Confirm Deletion"
              message="Are you sure you want to delete this selected User ID?"
              onConfirm={confirmDeletion}
              onCancel={cancelDeletion}
            />

        <ConfirmationDialog
              open={showConfirmationForLock}
              title="Confirm Locked Status"
              message="Are you sure you want to Lock/Unlock this selected User ID?"
              onConfirm={confirmLockStatus}
              onCancel={cancelLockStatus}
            />
    </div>
  );
}
