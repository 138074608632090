import React from "react";
import Loadgif from "../assets/Loading.gif";
function CustomeLoading() {
  console.log("Loading");

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <img src={Loadgif} alt="Loading..." className="w-50 h-auto" />
    </div>
  );
}

export default CustomeLoading;
