// routes.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginComponent from "./pages/login/LoginComponent";
import { AuthContext } from "./context/AuthContext";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Screens/Home";

import BankStatement from "./pages/Screens/BankStatement/BankStatement";

import ForgotPasswordScreen from "./pages/login/ForgotPasswordScreen";
// import Layout from './components/layout';

import CashBalance from "./pages/Screens/CashBalance/CashBalance";
// import AccountPortal from "./pages/Screens/CashBalance/cashInBankscreens/AccountPortal";
// import CompanyWise from "./pages/Screens/CashBalance/cashInBankscreens/CompanyWise";
// import BankWise from "./pages/Screens/CashBalance/cashInBankscreens/BankWise";
// import CountryWise from "./pages/Screens/CashBalance/cashInBankscreens/CountryWise";
// import Transactions from "./pages/Screens/CashBalance/cashInBankscreens/Transactions";
// import ODBalances from "./pages/Screens/CashBalance/cashInBankscreens/ODBalances";
// import CurrencyWise from "./pages/Screens/CashBalance/cashInBankscreens/CurrencyWise";
// import Negativebalances from "./pages/Screens/CashBalance/ExceptionalScreens/Negativebalances";
// import Dormant from "./pages/Screens/CashBalance/ExceptionalScreens/Dormant";
// import Minimum from "./pages/Screens/CashBalance/ExceptionalScreens/Minimum";
// import Bsyetto from "./pages/Screens/CashBalance/ExceptionalScreens/bs-yetto";

import Cash from "./pages/Screens/CashBalance/Tables/CashInHand/Cash";
import CashFlowScreen from "./pages/Screens/CashFlow/CashFlowScreen";
import FlowCodeHeads from "./pages/Screens/CashFlow/Screens/Setup/FlopwCodeHeads/FlowCodeHeads";
import FlowCodeAdd from "./pages/Screens/CashFlow/Screens/Setup/FlopwCodeHeads/FlowCodeAdd";
import NTCodes from "./pages/Screens/CashFlow/Screens/Setup/NTCodes/NTCodes";
import NTCodesAdd from "./pages/Screens/CashFlow/Screens/Setup/NTCodes/NTCodesAdd";
import MappingRuleID from "./pages/Screens/CashFlow/Screens/Setup/MappingRuleID/MappingRuleID";
import MappingRuleAdd from "./pages/Screens/CashFlow/Screens/Setup/MappingRuleID/MappingRuleAdd";
import CashFlows from "./pages/Screens/CashFlow/Screens/FlowAnalysis/CashFlows";
import FundTransfer from "./pages/Screens/CashFlow/Screens/FlowAnalysis/FundTransfer";
import InterCompanyFT from "./pages/Screens/CashFlow/Screens/FlowAnalysis/InterCompanyFT";
import NotAssigned from "./pages/Screens/CashFlow/Screens/FlowAnalysis/NotAssigned";
import Standing from "./pages/Screens/CashFlow/Screens/FlowAnalysis/Standing";
import AdvancedAnalysis from "./pages/Screens/CashFlow/Screens/FlowAnalysis/AdvancedAnalysis";
import NTCodeAutomation from "./pages/Screens/CashFlow/Screens/Setup/MappingRuleID/NTCodeAutomation";
import NTCodeAutomationAdd from "./pages/Screens/CashFlow/Screens/Setup/MappingRuleID/NTCodeAutomationAdd";
import MasterDataComponent from "./pages/Screens/Masterdata/MasterData";
import MasterCountry from "./pages/Screens/Masterdata/Tables/GlobalmasterDatatables/MasterCountry";
// import MasterCalendar from './pages/Screens/Masterdata/Tables/GlobalmasterDatatables/MasterCalendar';
import AddCountry from "./pages/Screens/Masterdata/Screens/globalmasterData/AddCountry";
import ModuleAssignment from "./pages/Screens/Admin/Tables/ModuleAssignment";
import AdminScreen from "./pages/Screens/Admin/AdminScreen";
import UserAdd from "./pages/Screens/Admin/Screens/UserMaster/user/UserAdd";
import User from "./pages/Screens/Admin/Screens/UserMaster/user/UserScreen";
import PasswordScreen from "./pages/Screens/Admin/Screens/PasswordPolicy/Password";
import PopupScreenn from "./pages/Screens/Admin/Components/popup";
import StatementIdentifier from "./pages/Screens/BankStatement/Tables/MT940/StatementIdentifier";
import BTC from "./pages/Screens/BankStatement/Tables/MT940/BTC";
import StatementAnalyzerr from "./pages/Screens/BankStatement/Tables/MT940/StatementAnalyzer";
import StatementFormat from "./pages/Screens/BankStatement/Tables/Setups/StatementFormat";
import Reports from "./pages/Screens/Report/Reports";
import CashTransaction from "./pages/Screens/CashTransactions/CashTransaction";
import Approvals from "./pages/Screens/Approvals/Approvals";
import TaskMonitor from "./pages/Screens/TaskMonitor/TaskMonitor";
import Reconcilliation from "./pages/Screens/Reconciliation/Reconcilliation";
import Accounting from "./pages/Screens/Accounting/Accounting";
// import UserEdit from "./pages/Screens/Admin/Screens/UserMaster/user/UserEditScreen";
import StateprovinceScreen from "./pages/Screens/Masterdata/Tables/GlobalmasterDatatables/Stateprovince";
import StateProvinceAdd from "./pages/Screens/Masterdata/Screens/globalmasterData/StateProvinceAdd";
// import UserCopy from "./pages/Screens/Admin/Screens/UserMaster/user/UserCopy";
// import UserViewScreen from "./pages/Screens/Admin/Screens/UserMaster/user/UserView";
import LanguageScreen from "./pages/Screens/Masterdata/Tables/GlobalmasterDatatables/Language";
import LanguageAdd from "./pages/Screens/Masterdata/Screens/globalmasterData/AddLanguages";
import CalendarScreen from "./pages/Screens/Masterdata/Tables/GlobalmasterDatatables/Calendar";
import CalendarAdd from "./pages/Screens/Masterdata/Screens/globalmasterData/AddCalendar";
import BankScreen from "./pages/Screens/Masterdata/Tables/GlobalmasterDatatables/Bank";
import BankAdd from "./pages/Screens/Masterdata/Screens/globalmasterData/AddBank";
import CurrencyScreen from "./pages/Screens/Masterdata/Tables/GlobalmasterDatatables/Currency";
import CurrencyAdd from "./pages/Screens/Masterdata/Screens/globalmasterData/AddCurrency";
import Mt940Table from "./pages/Screens/BankStatement/Tables/MT940/Mt940Table";
import AddMT940 from "./pages/Screens/BankStatement/Screens/AddMT940";
import UserAuditTrail from "./pages/Screens/Admin/Tables/UserAuditTrail";
import StatementAnalyzerNewBs from "./pages/Screens/BankStatement/Tables/MT940/StamentAnalyzerNewBs";

import AccountNumberLogic from "./pages/Screens/BAM/Tables/Setup/AccountNumberLogic";
import AccountTypes from "./pages/Screens/BAM/Tables/Setup/AccountTypes";
import DBarea from "./pages/Screens/BAM/Tables/Setup/DBarea";
import ZBAtypes from "./pages/Screens/BAM/Tables/Setup/ZBAtypes";
import AccountStatus from "./pages/Screens/BAM/Tables/Setup/AccountStatus";
import BankAccountMaster from "./pages/Screens/BAM/Screens/BankMasterData/BankAccountMaster/BankAccountMaster";
import ZBAtypesAdd from "./pages/Screens/BAM/Screens/Setup/ZBAtypesAdd";
import DBareaAdd from "./pages/Screens/BAM/Screens/Setup/DBareaAdd";
import AccountTypesAdd from "./pages/Screens/BAM/Screens/Setup/AccountTypesAdd";
import BankAccountMasterAdd from "./pages/Screens/BAM/Screens/BankMasterData/BankAccountMaster/BankAccountMasterAdd";

// import layout from '../src/components/layout';
import BamScreen from "./pages/Screens/BAM/BamScreen";
import UserType from "./pages/Screens/Admin/Screens/Licenses/UserType";
import MakerAndChecker from "./pages/Screens/Admin/Screens/UserMaster/maker&checker/MakerAndChecker";
import RolesAndPermission from "./pages/Screens/Admin/Screens/UserMaster/roles&permission/RolesAndPermission";
import RolesScreen from "./pages/Screens/Admin/Screens/UserMaster/roles&permission/Roles/RolesScreen";
import RolesAdd from "./pages/Screens/Admin/Screens/UserMaster/roles&permission/Roles/RolesAdd";
import LegalEntity from "./pages/Screens/Masterdata/Tables/BankandCompanyMasterData/LegalEntity";
import LegalEntityAdd from "./pages/Screens/Masterdata/Screens/CompanyandBankMasterData/LegalEntityAdd";
import BuGroup from "./pages/Screens/Masterdata/Tables/BankandCompanyMasterData/BuGroup";
import Company from "./pages/Screens/Masterdata/Tables/BankandCompanyMasterData/Company";
import BankId from "./pages/Screens/Masterdata/Tables/BankandCompanyMasterData/BankId";
import BankBranch from "./pages/Screens/Masterdata/Tables/BankandCompanyMasterData/BankBranch";
import BuGroupAdd from "./pages/Screens/Masterdata/Screens/CompanyandBankMasterData/BuGroupAdd";
import RolesAndPermissionAdd from "./pages/Screens/Admin/Screens/UserMaster/roles&permission/RolesAndPermissionAdd";
import CompanyAdd from "./pages/Screens/Masterdata/Screens/CompanyandBankMasterData/CompanyAdd";
import BankIdAdd from "./pages/Screens/Masterdata/Screens/CompanyandBankMasterData/BankIdAdd";
import BankBranchAdd from "./pages/Screens/Masterdata/Screens/CompanyandBankMasterData/BankBranchAdd";
import BankAuditTail from "./pages/Screens/BAM/Tables/BankMasterData/BankAuditTrail";
import AddBTC from "./pages/Screens/BankStatement/Screens/AddBTC";
import AddStatementFormat from "./pages/Screens/BankStatement/Screens/AddStatementFormat";
import ManageStatementControlls from "./pages/Screens/BankStatement/Tables/MT940/ManageStatementControlls";
import StatementControls from "./pages/Screens/BankStatement/Tables/Setups/StatementControlls";
import AddTransferToBs from "./pages/Screens/BankStatement/Screens/AddTransferToBs";
import AddStatementAnalyzer from "./pages/Screens/BankStatement/Screens/AddStatementAnalyzer";
import AddStatementAnalyzerBs from "./pages/Screens/BankStatement/Screens/AddStatementAnalyzerBs";
import AddManageStatementControlls from "./pages/Screens/BankStatement/Screens/AddManageStatmentControlls";
import ExchangeRateTypeAdd from "./pages/Screens/Masterdata/Screens/MarketData/ExchangeRateTypeAdd";
import SourceCode from "./pages/Screens/Masterdata/Tables/MarketData/SourceCode";
import SourceCodeAdd from "./pages/Screens/Masterdata/Screens/MarketData/SourceCodeAdd";
import CurrencyRate from "./pages/Screens/Masterdata/Tables/MarketData/CurrencyRate";
import CurrencyRateAdd from "./pages/Screens/Masterdata/Screens/MarketData/CurrencyRateAdd";
import UserUpload from "./pages/Screens/Admin/Screens/UserMaster/user/UserUpload";
import UserUploadTableScreen from "./pages/Screens/Admin/Screens/UserMaster/user/UserUploadTableScreen";
import MarketDataUploads from "./pages/Screens/Masterdata/Tables/MarketData/MarketDataUploads";
import MarketDataUploadsAdd from "./pages/Screens/Masterdata/Screens/MarketData/MarketDataUploadsAdd";
import MDSource from "./pages/Screens/Masterdata/Tables/MarketData/MDSource";
import CompanywiseAdd from "./pages/Screens/CashBalance/Screens/CashInBank/CompanywiseAdd";
import CompanyWise from "./pages/Screens/CashBalance/Tables/CashInBank/CompanyWise";
import CountrywiseAdd from "./pages/Screens/CashBalance/Screens/CashInBank/CountrywiseAdd";
import CountryWise from "./pages/Screens/CashBalance/Tables/CashInBank/CountryWise";
import TransactionWiseAdd from "./pages/Screens/CashBalance/Screens/CashInBank/TransactionwiseAdd";
import OdBalancesAdd from "./pages/Screens/CashBalance/Screens/CashInBank/OdBalanceswiseAdd";
import CurrencyWiseAdd from "./pages/Screens/CashBalance/Screens/CashInBank/CurrencywiseAdd";
import AccountPortal from "./pages/Screens/CashBalance/Tables/CashInBank/AccountPortal";
import NegativeBalancesAdd from "./pages/Screens/CashBalance/Screens/Irregularities/NegativeBalancesAdd";
import DormantAdd from "./pages/Screens/CashBalance/Screens/Irregularities/DormantAdd";
import MinimumAdd from "./pages/Screens/CashBalance/Screens/Irregularities/MinimumAdd";
import BSYetToAdd from "./pages/Screens/CashBalance/Screens/Irregularities/BSYetTo";
import ZBAAdd from "./pages/Screens/CashBalance/Screens/Irregularities/ZBAAdd";
import BankwiseAdd from "./pages/Screens/CashBalance/Screens/CashInBank/BankWiseAdd";
import ClosingAvailBalanceAdd from "./pages/Screens/CashBalance/Screens/CashInBank/ClosingAvailBalanceAdd";
import ProcessListTable from "./pages/Screens/BankStatement/Tables/MT940/ProcessDetails";
import TaskMonitorLog from "./components/TaskMonitorLog";
import BUGroupsUpload from "./pages/Screens/Masterdata/Uploads/CompanyAndBank/BuGroups/BUGroupsUpload";
import BuGroupUploadScreen from "./pages/Screens/Masterdata/Uploads/CompanyAndBank/BuGroups/BuGroupUploadScreen";
import LegalEntityUpload from "./pages/Screens/Masterdata/Uploads/CompanyAndBank/LegalEntityUploads/LegalEntityUpload";
import LegalEntityUploadTableScreen from "./pages/Screens/Masterdata/Uploads/CompanyAndBank/LegalEntityUploads/LegalEntityUploadTableScreen";
import CompanyUpload from "./pages/Screens/Masterdata/Uploads/CompanyAndBank/Company/CompanyUpload";
import CompanyUploadTableScreen from "./pages/Screens/Masterdata/Uploads/CompanyAndBank/Company/CompanyUploadTableScreen";
import BankIDUpload from "./pages/Screens/Masterdata/Uploads/BankID/BankIDUpload";
import BankIDUploadTableScreen from "./pages/Screens/Masterdata/Uploads/BankID/BankIDUploadTableScreen";
import { TranserToBsTable } from "./pages/Screens/BankStatement/Tables/MT940/TransferToBSTable";
import BankBranchUploadTableScreen from "./pages/Screens/Masterdata/Uploads/CompanyAndBank/BankBranch/BankBranchUploadTableScreen";
import BankBranchUpload from "./pages/Screens/Masterdata/Uploads/CompanyAndBank/BankBranch/BankBranchUpload";
import CurrencyRateUpload from "./pages/Screens/Masterdata/Uploads/MarketData/CurrencyRate/CurrencyRateUpload";
import CurrencyRateUploadTableScreen from "./pages/Screens/Masterdata/Uploads/MarketData/CurrencyRate/CurrencyRateUploadTableScreen";
import CashBalanceProcessListTable from "./pages/Screens/CashBalance/Screens/CashBalanceProcess";
import CountryUpload from "./pages/Screens/Masterdata/Uploads/GlobalData/Country/CountryUpload";
import CountryUploadTableScreen from "./pages/Screens/Masterdata/Uploads/GlobalData/Country/CountryUploadTableScreen";
import StateProvinceUpload from "./pages/Screens/Masterdata/Uploads/GlobalData/StateProvince/StateProvinceUpload";
import StateProvinceTableScreen from "./pages/Screens/Masterdata/Uploads/GlobalData/StateProvince/StateProvinceTableScreen";
import LanguageUpload from "./pages/Screens/Masterdata/Uploads/GlobalData/Language/LanguageUpload";
import LanguageTableScreen from "./pages/Screens/Masterdata/Uploads/GlobalData/Language/LanguageTableScreen";
import BankUpload from "./pages/Screens/Masterdata/Uploads/GlobalData/Bank/BankUpload";
import BankTableScreen from "./pages/Screens/Masterdata/Uploads/GlobalData/Bank/BankTableScreen";
import CurrencyUpload from "./pages/Screens/Masterdata/Uploads/GlobalData/Currency/CurrencyUpload";
import CurrencyTableScreen from "./pages/Screens/Masterdata/Uploads/GlobalData/Currency/CurrencyTableScreen";
import SourceUpload from "./pages/Screens/Masterdata/Uploads/MarketData/SourceScreen/SourceDisplay";
import SourceDisplayScreen from "./pages/Screens/Masterdata/Uploads/MarketData/SourceScreen/SourceUpload";
import BAMUploads from "./pages/Screens/BAM/Uploads/BankAccountMaster/BankAccountMaster/BAMUploads";
import LicenseSummary from "./pages/Screens/Admin/Screens/Licenses/LicenseSummary";
import AccountClassification from "./pages/Screens/BAM/Tables/Setup/AccountClassification";
import AccountClassificationAdd from "./pages/Screens/BAM/Screens/Setup/AccountClassificationAdd";
import LicenseSummaryTableScreen from "./pages/Screens/Admin/Screens/Licenses/LicenseSummaryTableScreen";
import BTCUploadScreen from "./pages/Screens/Masterdata/Uploads/BankStatement/BTC/BTCUpload";
import BTCDisplayScreen from "./pages/Screens/Masterdata/Uploads/BankStatement/BTC/BTCDisplay";
import BAMUploadTableScreen from "./pages/Screens/BAM/Uploads/BankAccountMaster/BankAccountMaster/BAMUploadTableScreen";
import PortalUploadScreen from "./pages/Screens/Masterdata/Uploads/BankStatement/PortalUpload/PortalUploads";
import PortalDisplayScreen from "./pages/Screens/Masterdata/Uploads/BankStatement/PortalUpload/PortalDisplay";

import BAMAcctNoUpdateTableScreen from "./pages/Screens/BAM/Uploads/BankAccountMaster/BankAccountMaster/BAMAcctNoUpdateTableScreen";
import ClientSftpUserId from "./pages/Screens/Admin/Screens/Interface Setup/Client SFTP/ClientSftpUserIdScreen";
import ClientSftpUserIdAdd from "./pages/Screens/Admin/Screens/Interface Setup/Client SFTP/ClientSftpUserIdAdd";
import HolidayCalenderScreen from "./pages/Screens/Admin/Screens/Others/Holiday Calender/HolidayCalenderScreen";
import HolidayCalenderAdd from "./pages/Screens/Admin/Screens/Others/Holiday Calender/HolidayCalenderAdd";
import ScheduleScreen from "./pages/Screens/Admin/Screens/Interface Setup/Schedule/ScheduleScreen";
import ScheduleAdd from "./pages/Screens/Admin/Screens/Interface Setup/Schedule/ScheduleAdd";
import BAMMigrationTableScreen from "./pages/Screens/BAM/Uploads/BankAccountMaster/BankAccountMaster/BAMMigrationTableScreen";


const AppRoutes = () => {
  const { state } = React.useContext(AuthContext);

  if (!state.isLoggedIn) {
    return <LoginComponent />;
  }
  return (
    <Router>
      <div className="flex">
        <Sidebar />
        <div className="flex-1 h-screen overflow-y-auto">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
            <Route path="/bank-statements" element={<BankStatement />} />
            <Route path="/2/master-data" element={<MasterDataComponent />} />

            <Route path="/bam" element={<BamScreen />} />
            <Route path="/:menuId/BAM/:iconId/account-status" element={<AccountStatus />} />
            <Route
              path="/:menuId/BAM/:iconId/account-number-logic"
              element={<AccountNumberLogic />}
            />

            <Route path="/:menuId/BAM/:iconId/ZBA-types" element={<ZBAtypes />} />
            <Route path="/ZBA-types-add" element={<ZBAtypesAdd />} />
            <Route path="/:menuId/BAM/:iconId/DB-area" element={<DBarea />} />
            <Route path="/DB-area-add" element={<DBareaAdd />} />
            <Route path="/:menuId/BAM/:iconId/account-types" element={<AccountTypes />} />
            <Route path="/account-types-add" element={<AccountTypesAdd />} />
            <Route path="/:menuId/BAM/:iconId/account-classfication" element={<AccountClassification />} />
            <Route path="/:menuId/BAM/:iconId/account-classfication-add" element={<AccountClassificationAdd />} />
            <Route path="/:menuId/BAM/:iconId/account-master" element={<BankAccountMaster />} />
            <Route
              path="/bam-audit-trail/:bankAccountId/:iconId"
              element={<BankAuditTail />}
            />

            <Route
              path="/:menuId/BAM/:iconId/account-master-add/:actionId"
              element={<BankAccountMasterAdd />}
            />
            {/* <Route
              path="/account-master-add/:actionId/:accountIds"
              element={<BankAccountMasterAdd />}
            /> */}
            {/* /3/BAM/26/account-master-add/3/ */}

            <Route
              path="/:menuId/BAM/:iconId/account-master-add/:actionId/:accountIds"
              element={<BankAccountMasterAdd />}
            />

            <Route path="/:menuId/admin" element={<AdminScreen />} />
            <Route path="/:menuId/admin/:iconId/useradd" element={<UserAdd />} />
            <Route path="/user-edit-screen/:UserId" element={<UserAdd />} />
            {/* <Route path="/user-edit-screen/:userId" element={<UserEdit />} /> */}
            <Route
              path="/user-audit-trail-screen/:UserId"
              element={<UserAuditTrail />}
            />
            <Route
              path="/audit-trail-screen/:iconId/:UserId"
              element={<UserAuditTrail />}
            />
            <Route path="/:menuId/admin/:iconId/user-upload" element={<UserUpload />} />
            <Route path="/:menuId/master-data/:iconId/legalentity-upload" element={<LegalEntityUpload />} />
            <Route path="/:menuId/master-data/:iconId/bankId-upload" element={<BankIDUpload />} />
            <Route path="/:menuId/master-data/:iconId/legalentity-upload/display-screen" element={<LegalEntityUploadTableScreen />} />
            <Route path="/:menuId/master-data/:iconId/bankID-upload/display-screen" element={<BankIDUploadTableScreen />} />
            <Route path="/:menuId/master-data/:iconId/bankbranch-upload/display-screen" element={<BankBranchUploadTableScreen />} />
            <Route path="/:menuId/master-data/:iconId/bankBranch-upload" element={<BankBranchUpload />} />

            <Route path="/:menuId/master-data/:iconId/bugroup-upload" element={<BUGroupsUpload />} />
            <Route path="/:menuId/master-data/:iconId/stateProvince-upload" element={<StateProvinceUpload />} />
            <Route path="/:menuId/master-data/:iconId/stateProvince-upload/display-screen" element={<StateProvinceTableScreen />} />

            <Route path="/:menuId/BAM/:iconId/account-master-upload" element={<BAMUploads />} />
            <Route path="/:menuId/BAM/:iconId/account-master-upload/display-screen" element={<BAMUploadTableScreen />} />
            <Route path="/:menuId/BAM/:iconId/account-number-update-upload/display-screen" element={<BAMAcctNoUpdateTableScreen />} />
            <Route path="/:menuId/BAM/:iconId/account-master-migration-upload/display-screen" element={<BAMMigrationTableScreen />} />

            <Route path="/:menuId/master-data/:iconId/country-upload" element={<CountryUpload />} />
            <Route path="/:menuId/master-data/:iconId/country-upload/display-screen" element={<CountryUploadTableScreen />} />

            <Route path="/:menuId/master-data/:iconId/language-upload" element={<LanguageUpload />} />
            <Route path="/:menuId/master-data/:iconId/language-upload/display-screen" element={<LanguageTableScreen />} />

            <Route path="/:menuId/master-data/:iconId/currency-upload" element={<CurrencyUpload />} />
            <Route path="/:menuId/master-data/:iconId/currency-upload/display-screen" element={<CurrencyTableScreen />} />

            <Route path="/:menuId/master-data/:iconId/bank-upload" element={<BankUpload />} />
            <Route path="/:menuId/master-data/:iconId/bank-upload/display-screen" element={<BankTableScreen />} />

            <Route path="/:menuId/master-data/:iconId/company-upload" element={<CompanyUpload />} />
            <Route path="/:menuId/master-data/:iconId/company-upload/display-screen" element={<CompanyUploadTableScreen />} />
            <Route path="/:menuId/admin/:iconId/user-upload/display-screen" element={<UserUploadTableScreen />} />
            <Route path="/:menuId/master-data/:iconId/bugroup-upload/display-screen" element={<BuGroupUploadScreen />} />
            <Route path="/:iconId/task-monitor/display-screen/:moduleId" element={<TaskMonitorLog />} />

            <Route path="/user-view-screen/:UserId" element={<UserAdd />} />
            <Route path="/user-profile-screen/:UserId" element={<UserAdd />} />
            <Route path="/user-copy-Add-screen/:UserId" element={<UserAdd />} />
            <Route
              path="/:menuId/admin/:iconId/userscreen"
              element={<User />}
            />
            <Route path="/popupscreens" element={<PopupScreenn />} />
            {/* <Route path="/passwordscreen" element={<PasswordScreen />} /> */}
            <Route
              path="/:menuId/admin/passwordscreen/:iconId"
              element={<PasswordScreen />}
            />
            <Route
              path="/:menuId/admin/user-type-screen/:iconId"
              element={<UserType />}
            />
            <Route
              path="/:menuId/admin/module-assignment/:iconId"
              element={<ModuleAssignment />}
            />

            <Route
              path="/:menuId/admin/license-summary/:iconId"
              element={<LicenseSummary />}
            />

            <Route
              path="/:menuId/admin/:iconId/maker-and-checker"
              element={<MakerAndChecker />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission"
              element={<RolesAndPermission />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission/add"
              element={<RolesAndPermissionAdd />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission/view/:UserId"
              element={<RolesAndPermissionAdd />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission/edit/:UserId"
              element={<RolesAndPermissionAdd />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission/roles"
              element={<RolesScreen />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission/roles/add"
              element={<RolesAdd />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission/roles-view/:roleId"
              element={<RolesAdd />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission/roles-edit/:roleId"
              element={<RolesAdd />}
            />
            <Route
              path="/:menuId/admin/:iconId/roles-and-permission/roles-copy-add/:roleId"
              element={<RolesAdd />}
            />
             <Route
              path="/:menuId/admin/:iconId/client-sftp-user-screen/:iconId"
              element={<ClientSftpUserId />}
            />
            <Route
              path="/:menuId/admin/:iconId/client-sftp-user-screen/:iconId"
              element={<ClientSftpUserId />}
            />
            <Route path="/client-sftp-user-id-add" element={<ClientSftpUserIdAdd/>} />
            <Route path="/client-sftp-user-id-view/:id" element={<ClientSftpUserIdAdd />} />
            <Route path="/client-sftp-user-id-edit/:id" element={<ClientSftpUserIdAdd />} />  
            <Route
              path="/:menuId/admin/schedule-screen"
              element={<ScheduleScreen />}
            />
            <Route path="/schedule-add" element={<ScheduleAdd/>} />
            <Route path="/schedule-view/:id" element={<ScheduleAdd />} />
            <Route path="/schedule-edit/:id" element={<ScheduleAdd />} />
            <Route
              path="/:menuId/admin/:iconId/holiday-calender/:iconId"
              element={<HolidayCalenderScreen />}
            />
            <Route path="/holiday-calendar-add" element={<HolidayCalenderAdd/>} />
            <Route path="/holiday-calendar-view/:id" element={<HolidayCalenderAdd />} />
            <Route path="/holiday-calendar-edit/:id" element={<HolidayCalenderAdd />} /> 
            <Route
              path="/forgot-passoword"
              element={<ForgotPasswordScreen />}
            />
            <Route path="/:menuId/master-data/:iconId/country" element={<MasterCountry />} />
            <Route path="/add-country" element={<AddCountry />} />
            <Route
              path="/statement-identifire"
              element={<StatementIdentifier />}
            />
            <Route path="/add-country/:countryId" element={<AddCountry />} />
            <Route path="/add-country/:countryId" element={<AddCountry />} />
            <Route path="/country-view/:countryId" element={<AddCountry />} />

            <Route path="/add-statement-format/:actionId" element={<AddStatementFormat />} />

            <Route path="/add-statement-format/:actionId/:statementFormatId" element={<AddStatementFormat />} />

            <Route path="/btc-add" element={<AddBTC />} />
            <Route path="/btc-view/:id" element={<AddBTC />} />

            <Route path="/cash-balances" element={<CashBalance />} />
            <Route path="/company-wise-add" element={<CompanywiseAdd />} />
            <Route path="/company-wise" element={<CompanyWise />} />
            <Route path="/country-wise-add" element={<CountrywiseAdd />} />
            <Route path="/country-wise" element={<CountryWise />} />
            <Route path="/transaction-wise-add" element={<TransactionWiseAdd />} />
            <Route path="/od-balances-add" element={<OdBalancesAdd />} />
            <Route path="/currency-wise-add" element={<CurrencyWiseAdd />} />
            <Route path="/bank-wise-add" element={<BankwiseAdd />} />
            <Route path="/account-portal" element={<AccountPortal />} />
            <Route path="/closing-avail-balance-add" element={<ClosingAvailBalanceAdd />} />
            <Route path="/negative-balances-add" element={<NegativeBalancesAdd />} />
            <Route path="/dormant-add" element={<DormantAdd />} />
            <Route path="/minimum-add" element={<MinimumAdd />} />
            <Route path="/bs-yetto-add" element={<BSYetToAdd />} />
            <Route path="/zba-add" element={<ZBAAdd />} />

            {/*
            <Route path="/bank-wise" element={<BankWise />} /> */}
            <Route path="/cashscreen" element={<Cash />} />
            <Route path="/cash-flow" element={<CashFlowScreen />} />
            <Route path="/flow-code-heads" element={<FlowCodeHeads />} />
            <Route path="/flow-code-add" element={<FlowCodeAdd />} />
            <Route path="/nt-codes" element={<NTCodes />} />
            <Route path="/nt-codes-add" element={<NTCodesAdd />} />
            <Route path="/mapping-rule-id" element={<MappingRuleID />} />
            <Route path="/mapping-rule-add" element={<MappingRuleAdd />} />
            <Route path="/cash-flows" element={<CashFlows />} />
            <Route path="/fund-transfer" element={<FundTransfer />} />
            <Route path="/inter-company-ft" element={<InterCompanyFT />} />
            <Route path="/not-assigned" element={<NotAssigned />} />
            <Route path="/standing" element={<Standing />} />
            <Route path="/advanced-analysis" element={<AdvancedAnalysis />} />
            <Route path="/ntcodeautomation" element={<NTCodeAutomation />} />
            <Route
              path="/ntcodeautomationadd"
              element={<NTCodeAutomationAdd />}
            />

            {/* Bank  Statement Routes */}
            <Route
              path="/:menuId/mt940/:iconId/add-transfer-bs"
              element={<AddTransferToBs />} />
            <Route
              path="/transfer-bs"
              element={<TranserToBsTable />} />
            <Route
              path="/:menuId/mt940/:iconId/add-statement-analyser"
              element={<AddStatementAnalyzer />}
            />
            <Route
              path="/statement-analyser"
              element={<StatementAnalyzerr />}
            />
            <Route
              path="/license-summary/:iconId"
              element={<LicenseSummaryTableScreen />}
            />
            <Route
              path="/statement-controll"
              element={<StatementControls />}
            />
            <Route path="/manage-statement-controlls" element={<ManageStatementControlls />} />
            <Route path="/edit-manage-statement-controlls/:id" element={<AddManageStatementControlls />} />
            <Route
              path="/add-mt940-balances"
              element={<AddStatementAnalyzerBs />}
            />
            <Route
              path="/statement-analyser-bs"
              element={<StatementAnalyzerNewBs />}
            />
            <Route
              path="/bs-process-list"
              element={<ProcessListTable />}
            />
            <Route
              path="/cash-balance-process-list"
              element={<CashBalanceProcessListTable />}
            />
            <Route path="/statement-formatt" element={<StatementFormat />} />
            <Route path="/btc" element={<BTC />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/cash-transactions" element={<CashTransaction />} />
            <Route path="/approvals" element={<Approvals />} />
            <Route path="/:iconId/task-monitor" element={<TaskMonitor />} />
            <Route path="/reconciliation" element={<Reconcilliation />} />
            <Route path="/accounting" element={<Accounting />} />
            <Route path="/:menuId/master-data/:iconId/state-province" element={<StateprovinceScreen />} />
            <Route path="/state-province-add" element={<StateProvinceAdd />} />
            <Route
              path="/state-province-add/:statementId"
              element={<StateProvinceAdd />}
            />
            <Route
              path="/state-province-view/:statementId"
              element={<StateProvinceAdd />}
            />

            <Route path="/:menuId/master-data/:iconId/language" element={<LanguageScreen />} />
            <Route path="/language-add" element={<LanguageAdd />} />
            <Route path="/language-add/:languageId" element={<LanguageAdd />} />
            <Route
              path="/language-view/:languageId"
              element={<LanguageAdd />}
            />

            <Route path="/:menuId/master-data/:iconId/calendar" element={<CalendarScreen />} />
            <Route path="/calendar-add" element={<CalendarAdd />} />
            <Route path="/calendar-add/:calenderId" element={<CalendarAdd />} />
            <Route
              path="/calendar-view/:calenderId"
              element={<CalendarAdd />}
            />

            <Route path="/:menuId/master-data/:iconId/bank" element={<BankScreen />} />
            <Route path="/bank-add" element={<BankAdd />} />
            <Route path="/bank-add/:bankId" element={<BankAdd />} />
            <Route path="/bank-view/:bankId" element={<BankAdd />} />

            <Route path="/:menuId/master-data/:iconId/currency" element={<CurrencyScreen />} />
            <Route path="/currency-add" element={<CurrencyAdd />} />
            <Route path="/currency-add/:currencyId" element={<CurrencyAdd />} />
            <Route
              path="/currency-view/:currencyId"
              element={<CurrencyAdd />}
            />

            <Route path="/mt940" element={<Mt940Table />} />
            <Route path="/mt940-add" element={<AddMT940 />} />

            <Route path="/:menuId/master-data/:iconId/legalentity" element={<LegalEntity />} />
            <Route path="/legalentity-add" element={<LegalEntityAdd />} />
            <Route
              path="/legalentity-add/:legalentityId"
              element={<LegalEntityAdd />}
            />
            <Route
              path="/legalentity-view/:legalentityId"
              element={<LegalEntityAdd />}
            />
            <Route
              path="/audit-trail-lge/:iconId/:legalentityId"
              element={<UserAuditTrail />}
            />
            <Route path="/:menuId/master-data/:iconId/bugroup" element={<BuGroup />} />
            <Route path="/bugroup-add" element={<BuGroupAdd />} />
            <Route path="/bugroup-add/:buGroupId" element={<BuGroupAdd />} />
            <Route path="/bugroup-view/:buGroupId" element={<BuGroupAdd />} />
            <Route
              path="/audit-trail-bugroup/:iconId/:buGroupId"
              element={<UserAuditTrail />}
            />
            <Route path="/:menuId/master-data/:iconId/company" element={<Company />} />
            <Route path="/company-add" element={<CompanyAdd />} />
            <Route path="/company-add/:companyId" element={<CompanyAdd />} />

            <Route path="/company-add/:companyId" element={<CompanyAdd />} />
            <Route path="/company-view/:companyId" element={<CompanyAdd />} />
            <Route
              path="/audit-trail-company/:iconId/:companyId"
              element={<UserAuditTrail />}
            />
            <Route path="/:menuId/master-data/:iconId/bankidscreen" element={<BankId />} />
            <Route path="/bankidscreen-add" element={<BankIdAdd />} />
            <Route
              path="/bankidscreen-edit/:bankidId"
              element={<BankIdAdd />}
            />
            <Route path="/bankidscreen-add/:bankidId" element={<BankIdAdd />} />
            <Route
              path="/bankidscreen-view/:bankidId"
              element={<BankIdAdd />}
            />
            <Route
              path="/audit-trail-bankid/:iconId/:bankidId"
              element={<UserAuditTrail />}
            />
            <Route path="/:menuId/master-data/:iconId/bankbranch" element={<BankBranch />} />
            <Route path="/bankbranch-add" element={<BankBranchAdd />} />
            <Route
              path="/bankbranch-add/:bankbranchId"
              element={<BankBranchAdd />}
            />
            <Route
              path="/bankbranch-view/:bankbranchId"
              element={<BankBranchAdd />}
            />
            <Route
              path="/audit-trail-branch/:iconId/:bankbranchId"
              element={<UserAuditTrail />}
            />
            <Route path="/:menuId/master-data/:iconId/md-source" element={<MDSource />} />
            <Route path="/md-source-add" element={<ExchangeRateTypeAdd />} />
            <Route path="/md-source-add/:mdSourceId" element={<ExchangeRateTypeAdd />} />
            <Route path="/md-source-view/:mdSourceId" element={<ExchangeRateTypeAdd />} />
            <Route path="/:menuId/master-data/:iconId/source-code" element={<SourceCode />} />
            <Route path="/source-code-add" element={<SourceCodeAdd />} />
            <Route path="/source-code-add/:sourceId" element={<SourceCodeAdd />} />
            <Route path="/source-code-view/:sourceId" element={<SourceCodeAdd />} />
            <Route path="/:menuId/master-data/:iconId/currency-rate" element={<CurrencyRate />} />
            <Route path="/currency-rate-add" element={<CurrencyRateAdd />} />
            <Route path="/currency-rate-add/:currencyRateId" element={<CurrencyRateAdd />} />
            <Route path="/currency-rate-view/:currencyRateId" element={<CurrencyRateAdd />} />
            <Route path="/:menuId/master-data/:iconId/market-data-uploads-add" element={<MarketDataUploadsAdd />} />
            <Route path="/market-data-uploads" element={<MarketDataUploads />} />
            <Route path="/:menuId/master-data/:iconId/currencyrate-upload" element={<CurrencyRateUpload />} />
            <Route path="/:menuId/master-data/:iconId/currencyrate-upload/display-screen" element={<CurrencyRateUploadTableScreen />} />
            <Route path="/:menuId/master-data/:iconId/source-upload" element={<SourceUpload />} />
            <Route path="/:menuId/master-data/:iconId/source-upload/display-screen" element={<SourceDisplayScreen />} />
            <Route path="/:menuId/bank-statement/:iconId/btc-upload" element={<BTCUploadScreen />} />
            <Route path="/:menuId/bank-statement/:iconId/btc-upload/display-screen" element={<BTCDisplayScreen />} />
            <Route path="/:menuId/bank-statement/:iconId/portal-upload" element={<PortalUploadScreen />} />
            <Route path="/:menuId/bank-statement/:iconId/portal-upload/preview-screen" element={<PortalDisplayScreen />} />
            {/*  <Route path="/setups" element={<Setups />} />
            <Route path="/report" element={<Report />} />
            <Route path="/task-monitor" element={<TaskMonitor />} />
            
            <Route path="/cash-flow" element={<CashFlow />} /> */}

            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default AppRoutes;



