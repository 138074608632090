// import React, { useEffect, useState } from 'react'
// import Header from '../../../../../../components/Header'
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import UserService from '../../../../../../rest-services/UserServices';
// import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdTime, IoMdTrash } from 'react-icons/io';
// import { IoAddCircleOutline } from 'react-icons/io5';
// import { LuKeyRound } from 'react-icons/lu';
// import { MdOutlineFileDownload, MdOutlineFileUpload, MdOutlineLock } from 'react-icons/md';
// import { TbDeviceDesktopCancel } from 'react-icons/tb';
// import Footer from '../../../../../../components/footer';
// import ButtonGroup from '../../../../../../components/FooterButton';
// import { useToast } from '../../../../../../components/toast/toast';
// import { RxDropdownMenu } from 'react-icons/rx';

// export default function RolesAndPermissionAdd() {
//     const navigate = useNavigate();
//     const { UserId } = useParams();
//     const location = useLocation();
//     const { mode } = location.state || {}; // Get the mode from the state
//     const isViewMode = mode === 'view';
//     const isEditMode = mode === 'edit';
//     const [errors, setErrors] = useState({});
//     const [alert, setAlert] = useState(null);
//     const[roles,setRoles] = useState([]);
//     const { toast } = useToast();
//   const [isUserModalOpen, setIsUserModalOpen] = useState(false);
//   const [users, setUsers] = useState([]);
//   const [selectedUsers, setSelectedUsers] = useState({});
//   const [selectedUserId, setSelectedUserId] = useState(""); 
//   const [searchTerm, setSearchTerm] = useState("");
//   const [usersToShow, setUsersToShow] = useState([]); 
//   const [userName, setUserName] = useState(""); // Country Name
//   const [userType, setUserType] = useState("");
//   const [userTypes, setUserTypes] = useState([]);
//   const [assignee, setAssignee] = useState({ id: "", username: "" });

//   const [userId, setUserId] = useState(""); // Country Name
//   const [selectedRole, setSelectedRole] = useState(1);
//     const [isAdminChecked, setIsAdminChecked] = useState(false);
//   const [Icons, setIcons] = useState([]);
//   const [permissionIcons, setPermissionIcons] = useState([]);
//   const [selectedIcons, setSelectedIcons] = useState({});
//   const [selectedPermissions, setSelectedPermissions] = useState({});


//     const [permissions, setPermissions] = useState({
//         create: false,
//         download: false,
//         copy: false,
//         change: false,
//         view: false,
//         approve: false,
//         reject: false,
//         auditTrail: false,
//         upload: false,
//         inactive: false,
//         triggerOTP: false,
//         lock:false,
//         delete:false,
//       });
      
//       const permissionValues = {
//         create: 1,
//         download: 2,
//         copy: 3,
//         change: 4,
//         view: 5,
//         approve: 6,
//         reject: 7,
//         auditTrail: 8,
//         upload: 9,
//         inactive: 10,
//         triggerOTP:11,
//         lock:12,
//         delete: 13,
//       };
      
      
//       const handleAdminCheckboxChange = () => {
//         setIsAdminChecked(!isAdminChecked);
//       };

//       const handleCheckboxChangeForUser = (event) => {
//         const selectedUserId = event.target.value; // Get the selected user's ID
//         console.log("selected user id data",selectedUserId);
//         setSelectedUserId(selectedUserId); // Update the state to reflect the selected user

//         const selectedUserData = users.find(user => user.userId === selectedUserId);
//         if (selectedUserData) {
//             setUserName(selectedUserData.username);
//             setUserId(selectedUserData.userId);
//             setUserType(selectedUserData.userType);
//         }

//       };

//       const handleCheckboxChange = (permission) => {
//         setPermissions({ ...permissions, [permission]: !permissions[permission] });
//       };

//     const handleNavigate = (path) => {
//         navigate(path);
//       };

//       const handleRoleChange = (e) => {
//         const newRole = e.target.value;
//         setSelectedRole(newRole);
//         // Reset permissions when the role changes
//         setPermissions({
//           create: false,
//           download: false,
//           copy: false,
//           change: false,
//           view: false,
//           approve: false,
//           reject: false,
//           auditTrail: false,
//           upload: false,
//           inactive: false,
//           triggerOTP: false,
//           lock: false,
//           delete: false,
//         });
//       };



//       useEffect(() => {
//         if (selectedRole) {
//             console.log("selected role",selectedRole);
//           getDataForSelectedRole();
//         }
//       }, [selectedRole]);


//       useEffect(() => {
//         if (UserId) {
//           getDataForEdit();
//         }
//       }, [UserId]);

      
//       const getDataForEdit = async () => {
//         try {
//           const data = { userId: UserId };
//           console.log("Request Data:", data);

//           const response = await UserService.saveContactDetails(
//             `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`, data);      

//           console.log("Response from server fetching Permission:", response);
//           const responseData = response.data;
//           console.log('set Permission details',responseData);
//           // setRoles(responseData);
//           if(responseData){

//           setUserName(responseData.username);
//           setUserType(responseData.userType);
//           setSelectedRole(responseData.role_id);
//           console.log('set roles Id',responseData.role_id);

          
//           if (responseData.usersPermissionsTables && responseData.usersPermissionsTables.length > 0) {
//             const updatedPermissions = { ...permissions }; // Clone the current permissions state

//             // Loop through the permissions from the API and map them to your permission values
//             responseData.usersPermissionsTables.forEach(permission => {
//                 const permissionId = permission.permissionsId;

//                 // Find the key (like 'create', 'download') for the permissionId in the permissionValues object
//                 const permissionKey = Object.keys(permissionValues).find(key => permissionValues[key] === permissionId);

//                 if (permissionKey) {
//                     // Set the corresponding permission to true
//                     updatedPermissions[permissionKey] = true;
//                 }
//             });

//             // Update the permissions state with the new values
//             setPermissions(updatedPermissions);
//         }

//         console.log('Updated permissions:', permissions);
//     }



//           if (responseData.error) {
//             console.error("Error fetching roles data:", responseData.message);
//             setAlert({ severity: "error", message: responseData.message });
//             return;
//           }
//         } catch (error) {
//           console.error("Error fetching roles data", error);
//           setAlert({ severity: "error", message: `Error fetching roles data, kindly check the connection ${error.message}` });
//         }
//       };

   
//       const getDataForSelectedRole = async () => {
//         try {
//           const data = { roleId: selectedRole };
//           console.log("Request Data:", data);

//           const response = await UserService.saveContactDetails(
//             `${process.env.REACT_APP_BASE_URL}/get-role-by-roleId`, data);      

//           console.log("Response from server fetching roles:", response);
//           const responseData = response.data;
//           console.log('set roles details',responseData);
//           // setRoles(responseData);
//           if(responseData){

//           console.log('set roles details name',responseData.name);
//           console.log('set roles details desc',responseData.description);

          
       
//           const initialIcons = {};
//           const initialPermissions = {};
  
//           responseData.rolesPermissionsTables.forEach((rolePermission) => {
//             const { iconId, permissionsId } = rolePermission;
  
//             initialIcons[iconId] = true;
//             if (!initialPermissions[iconId]) initialPermissions[iconId] = {};
//             initialPermissions[iconId][permissionsId] = true;
//           });
  
//           setSelectedIcons(initialIcons);
//           setSelectedPermissions(initialPermissions);
//     }



//           if (responseData.error) {
//             console.error("Error fetching roles data:", responseData.message);
//             setAlert({ severity: "error", message: responseData.message });
//             return;
//           }
//         } catch (error) {
//           console.error("Error fetching roles data", error);
//           setAlert({ severity: "error", message: `Error fetching roles data, kindly check the connection ${error.message}` });
//         }
//       };


//       useEffect(() => {
//         fetchallRoles();
//         fetchUsers();
//         fetchIconsData();
//         fetchGetPermissionsData();
//         fetchUserTypes();
//         fetchAssignee();
//       }, []);

//       const fetchAssignee = async () => {
//         try {
//           const userDetailString = sessionStorage.getItem("userDetail");
    
//           if (userDetailString) {
//             const userDetail = JSON.parse(userDetailString);
//             const assigneeData = {
//               id: userDetail.id || "",
//               assigner: userDetail.username || "",
//               assignerId: userDetail.userId || "",
//             };
            
//             setAssignee(assigneeData);
            
//             // Log the id and assigner values to the console
//             console.log("Assignee ID:", assigneeData.id);
//             console.log("Assigner:", assigneeData.assigner);
//             console.log("Assigner userId:", assigneeData.assignerId);
            
//           } else {
//             setAlert({
//               severity: "error",
//               message: "No user detail found in session storage.",
//             });
//           }
//         } catch (error) {
//           setAlert({
//             severity: "error",
//             message: `Error fetching user details: ${error.message}`,
//           });
//         }
//       };

//       const fetchUserTypes = async () => {
//         try {
//           const response = await UserService.getUsers(
//             `${process.env.REACT_APP_BASE_URL}/get-user-type`
            
//           );
//           setUserTypes(response.data.data);
//           console.log("Set user types data",response.data.data);
//         } catch (error) {
//           console.error("Error fetching user types:", error);
//         }
//       };

//       const selectedUserTypeName = userTypes.find(type => type.id === userType)?.name || "";

//       const handlePermissionChange = (iconId, permissionId) => {
//         setSelectedPermissions((prev) => ({
//           ...prev,
//           [iconId]: {
//             ...prev[iconId],
//             [permissionId]: !prev[iconId]?.[permissionId],
//           },
//         }));
//       };

//       const handleIconChange = (iconId) => {
//         setSelectedIcons((prev) => ({
//           ...prev,
//           [iconId]: !prev[iconId],
//         }));
//       };

//       const fetchIconsData = async () => {
//         try {
//           const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-icons-data`);      
//           console.log("Response from server fetching users:", response);
//           const responseData = response.data.data;
//           setIcons(responseData);
    
    
//           console.log("double check users",response.data.data);
//           if (responseData.error) {
//             console.error("Error fetching Users data:", responseData.message);
//             setAlert({ severity: "error", message: responseData.message });
//             return;
//           }
//         } catch (error) {
//           console.error("Error fetching Users data", error);
//           setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
//         }
//       };

//     const fetchGetPermissionsData = async () => {
//       try {
//         const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-permissions`);      
//         console.log("Response from server fetching permissions data:", response);
//         const responseData = response.data.data;
//         setPermissionIcons(responseData);
  
  
//         console.log("double check users permissons",responseData);
//         if (responseData.error) {
//           console.error("Error fetching Users data:", responseData.message);
//           setAlert({ severity: "error", message: responseData.message });
//           return;
//         }
//       } catch (error) {
//         console.error("Error fetching Users data", error);
//         setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
//       }
//     };

//       const fetchallRoles = async () => {
//         try {
//           const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-roles`);      
//           console.log("Response from server fetching roles:", response);
//           const responseData = response.data.data;
//           console.log('add last seen and roles data',responseData);
//           setRoles(responseData);
        
//           if (responseData.error) {
//             console.error("Error fetching roles data:", responseData.message);
//             setAlert({ severity: "error", message: responseData.message });
//             return;
//           }
//         } catch (error) {
//           console.error("Error fetching roles data", error);
//           setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
//         }
//       };

//     const handleBreadcrumbClick = (crumb) => {
//         console.log("Breadcrumb clicked:", crumb.title);
//         if (crumb.title === 'User & SOD') {
//           navigate('/14/admin');
//         } else if (crumb.title === 'Admin') {
//           navigate('/14/admin');
//         }
//         else if (crumb.title === 'Permissions') {
//             navigate('/14/admin/108/roles-and-permission');
//         }
//         else if (crumb.path) {
//             navigate(crumb.path); // Navigate to the specified path in the breadcrumb
//         }
//       };

//       const fetchUsers = async () => {
//         try {
//           const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/user/get-user/approved`);      
//           console.log("Response from server fetching users:", response);
//           const responseData = response.data.data;
//           console.log('Set users data',responseData);
//           setUsers(responseData);
//           if (responseData.error) {
//             console.error("Error fetching Users data:", responseData.message);
//             setAlert({ severity: "error", message: responseData.message });
//             return;
//           }
//         } catch (error) {
//           console.error("Error fetching Users data", error);
//           setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
//         }
//       };

//       const handleCheckboxChangefilter = (e) => {
//         const { name, checked } = e.target;
//         setSelectedUsers((prev) => ({ ...prev, [name]: checked }));
//       };
    
      
//       const validateFields = () => {
//         const newErrors = {};
//         if (!userName) newErrors.userName = " ";

//         setErrors(newErrors);
  
//         // Show toast only if there are errors
//         if (Object.keys(newErrors).length > 0) {
//           toast("warning", "Missing required fields.");
//           return false;
//         }

//         return true;
//       };

//       const handleSaveContinueDetails = async () => {
//         if (!validateFields()) return;
    
//         if(UserId){
//           console.log("its comes on user id console")
//           try {
            
           
//     const permissionsData = [];
//     Object.keys(selectedIcons).forEach((iconId) => {
//       if (selectedIcons[iconId]) {
//         Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
//           if (selectedPermissions[iconId][permissionId]) {
//             permissionsData.push({
//               role_id: selectedRole,
//               icon_id: parseInt(iconId),
//               permission_id: parseInt(permissionId),
//               user_id: UserId, // Use the new roleId from the role submission
//               updatedby: assignee.assigner
//         });
//           }
//         });
//       }
//     });

//           const permissionsResponse = await UserService.saveAllRolesDetails(
//                 `${process.env.REACT_APP_BASE_URL}/update-user-permissions`, { permissions: permissionsData }
//             );
//             console.log("console permissions Response",permissionsResponse);
//             if (permissionsResponse.status === 200) {
//                 // alert('Permissions and role saved successfully');
//               toast("success", "User Permissions Updated Successfully");
      
//                 navigate(`/14/admin/108/roles-and-permission/edit/${UserId}`);
//             } else {
//                 // alert('Failed to save permissions');
//                 toast("warning", "Failed to save permissions");
      
//             }
      
//           } catch (error) {
//             console.error('Error submitting permissions and role details:', error);
//             // alert('Error submitting permissions and role details');
//             toast("warning", "Error submitting permissions and role details");
      
//         }
//         }
//         else{
//           try {
          
//             // Prepare the selected permissions to include the new roleId
//             const permissionsData = [];
//             Object.keys(selectedIcons).forEach((iconId) => {
//               if (selectedIcons[iconId]) {
//                 Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
//                   if (selectedPermissions[iconId][permissionId]) {
//                     permissionsData.push({
//                       role_id: selectedRole,
//                       icon_id: parseInt(iconId),
//                       permission_id: parseInt(permissionId),
//                       user_id: userId, // Use the new roleId from the role submission
//                       createdBy: assignee.assigner
//                 });
//                   }
//                 });
//               }
//             });

//             // Now submit the permissions with the correct role_id
//             const permissionsResponse = await UserService.saveAllRolesDetails(
//                 `${process.env.REACT_APP_BASE_URL}/insert-user-permissions`, { permissions: permissionsData }
//             );

//             if (permissionsResponse.status === 200) {
//                 // alert('Permissions and role saved successfully');
//               toast("success", "Roles Saved Successfully");

//               window.location.reload();
//               navigate("/14/admin/108/roles-and-permission/add");

//             } else {
//                 // alert('Failed to save permissions');
//                 toast("warning", "Failed to save permissions");

//             }
      
//     } catch (error) {
//         console.error('Error submitting permissions and role details:', error);
//         // alert('Error submitting permissions and role details');
//         toast("warning", "Error submitting permissions and role details");

//     }
//         }
       
//     };
  
//       const handleSubmit = async () => {
//         if (!validateFields()) return;
//     if(UserId){
//       console.log("its comes on user id console")
//     try {
     
//     const permissionsData = [];
//     Object.keys(selectedIcons).forEach((iconId) => {
//       if (selectedIcons[iconId]) {
//         Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
//           if (selectedPermissions[iconId][permissionId]) {
//             permissionsData.push({
//               role_id: selectedRole,
//               icon_id: parseInt(iconId),
//               permission_id: parseInt(permissionId),
//               user_id: UserId, // Use the new roleId from the role submission
//               updatedby: assignee.assigner
//             });
//           }
//         });
//       }
//     });
// console.log("update permission array",permissionsData);

//     const permissionsResponse = await UserService.saveAllRolesDetails(
//           `${process.env.REACT_APP_BASE_URL}/update-user-permissions`, { permissions: permissionsData }
//       );
//       console.log("console permissions Response",permissionsResponse);
//       if (permissionsResponse.status === 200) {
//           // alert('Permissions and role saved successfully');
//         toast("success", "User Permissions Updated Successfully");

//           navigate('/14/admin/108/roles-and-permission');
//       } else {
//           // alert('Failed to save permissions');
//           toast("warning", "Failed to save permissions");

//       }

//     } catch (error) {
//       console.error('Error submitting permissions and role details:', error);
//       // alert('Error submitting permissions and role details');
//       toast("warning", "Error submitting permissions and role details");

//   }
//     }
//     else{
//       try {
//         // First, submit the role details
//        console.log("selected role also needed",selectedRole);
//             // Prepare the selected permissions to include the new roleId

//             const permissionsData = [];
//             Object.keys(selectedIcons).forEach((iconId) => {
//               if (selectedIcons[iconId]) {
//                 Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
//                   if (selectedPermissions[iconId][permissionId]) {
//                     permissionsData.push({
//                       role_id: selectedRole,
//                       icon_id: parseInt(iconId),
//                       permission_id: parseInt(permissionId),
//                       user_id: userId, // Use the new roleId from the role submission
//                       createdBy: assignee.assigner
//                 });
//                   }
//                 });
//               }
//             });


//           //   const selectedPermissions = Object.keys(permissions)
//           //       .filter(permission => permissions[permission])
//           //       .map(permission => ({
//           //           permission_id: permissionValues[permission],
//           //           icon_id: 105, // Manually setting icon_id to 1
//           //           role_id: selectedRole,
//           //         user_id: userId, // Use the new roleId from the role submission
//           //       }));
//           console.log("now stored in permissions data",permissionsData);

//           const permissionsResponse = await UserService.saveAllRolesDetails(
//                 `${process.env.REACT_APP_BASE_URL}/insert-user-permissions`, { permissions: permissionsData }
//             );

//             if (permissionsResponse.status === 200) {
//                 // alert('Permissions and role saved successfully');
//               toast("success", "User Permissions Saved Successfully");

//                 navigate('/14/admin/108/roles-and-permission');
//             } else {
//                 // alert('Failed to save permissions');
//                 toast("warning", "Failed to save permissions");

//             }
       
//     } catch (error) {
//         console.error('Error submitting permissions and role details:', error);
//         // alert('Error submitting permissions and role details');
//         toast("warning", "Error submitting permissions and role details");

//     }
//     }
       
//     };

//     const handleUserFilterPopup = () => {
//         console.log("entered");
//         setIsUserModalOpen(true);
//       }

//       const closeUserModal = () => {
//         setIsUserModalOpen(false);
    
//       }

//       const handleSubmitUsers = () => {
//         // Collect selected user IDs and close modal
//         const selectedUserIds  = Object.keys(selectedUsers).filter(
//           (userId) => selectedUsers[userId]
//         );
//         console.log("selected users:",selectedUsers);
//         console.log("selected :",selectedUserIds );
//         setUsersToShow(selectedUserIds ); // Set selected IDs to be shown in the form
//         closeUserModal(); // Close modal after selection
//       };

//       const iconMapping = {
//         "Add": <IoAddCircleOutline size={18} />,  // Adjust size as needed
//         "Edit": <IoMdCreate size={18} />,
//         "View": <svg className="ml-2 text-customGray" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"></path></svg>,
//         "Copy": <IoMdCopy size={18} />,
//         "Audit Trail": <IoMdTime size={18} />,
//         "Approve": <IoMdCheckmark size={18} />,
//         "Reject": <IoMdClose size={18} />,
//         "Trigger OTP": <LuKeyRound size={18} />,
//         "Upload": <MdOutlineFileUpload size={18} />,
//         "Download": <MdOutlineFileDownload size={18} />,
//         "Inactive": <TbDeviceDesktopCancel size={18} />,
//         "Lock": <MdOutlineLock size={18} />,
//         "Delete": <IoMdTrash size={18} />,
//         // Add other icons with size adjustments
//       };

//   return (
//     <div>
//       <Header 
//         title={`Admin > User & SOD > Permissions > ${UserId ?  (isViewMode ? 'View' : 'Edit') : 'Add'}`}
//         onBreadcrumbClick={handleBreadcrumbClick}
//       />
//       <div className="p-6">
//       <div className="max-w-8xl">
//       <div className="grid grid-cols-3 gap-x-8 gap-y-8 mb-6">
//       <label className="flex items-center group">
//     <span className="w-48 text-sm font-medium text-customGray">User ID<span className="text-red-500 ml-1">*</span></span>
//     <span className="ml-4">:</span>
//            <button
//             onClick={handleUserFilterPopup} // Trigger modal or selection dropdown
//             className={`border p-1.5 rounded-md w-72 h-8 focus:outline-none ml-4 hover:border-blue-400 text-left flex items-center justify-between 
//               ${isViewMode||isEditMode ? 'text-gray-500 bg-gray-100':'text-customGray'} 
//                ${errors.userName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
//           disabled={isViewMode || isEditMode}

//         >
//         <span>{userName || 'Select User'} </span>
//             <RxDropdownMenu className="ml-2" />
//             </button>
// </label>

// <label className="flex items-center group">
//               <span className="w-48 text-sm font-medium text-customGray">
//                 User Type
//               </span>
//               <span className="ml-4">:</span>
//               <input
//                 type="text"
//                 value={selectedUserTypeName}
//                 className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none ml-4"
//                 disabled
//               />
//             </label>


//             <label className="flex items-center group">
//               <span className="w-48 text-sm font-medium text-customGray">
//                 Roles
//               </span>
//               <span className="ml-4">:</span>
//               <select
//                   value={userType}
//                   onChange={(e) => {
//                     const value = e.target.value;
//                     setUserType(value == "0" ? null : value); // Set to null if value is "0"
//                   }}
//                   disabled={isViewMode}
//                   className={`border border-gray-300 p-1 text-customGray rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4
//                     ${isViewMode ? 'bg-gray-100':''}
//                     `}
//                 >
//                   <option value="0">Select Role</option>
//                   {roles.map((role) => (
//                     <option key={role.id} value={role.id}>
//                       {role.name}
//                     </option>
//                   ))}
//                 </select>

//             </label>

//         </div>

//         <div className="mb-6 bg-white rounded-lg shadow-sm">
//         <div className="bg-blue-50 p-4 text-base text-customBlue font-medium flex items-center">
//           <span className="mr-2">Roles</span>

//         </div>

//         <div className="p-4 bg-blue-50">
//         <div className="grid grid-cols-6 gap-4 ">
//         {roles.length > 0 ? (
//   roles.map((role) => (
//     <label key={role.id} className="flex items-center text-customGray">
//       <input
//         type="radio"
//         name="role"
//         value={role.id}
//         onChange={handleRoleChange}
//         className="mr-2"
//         checked={selectedRole == role.id}
//         disabled={isViewMode}
//       />
//       <span>{role.name}</span>
//     </label>
//   ))
// ) : (
//     <span className="text-red-500 ml-1">
//     Roles are not created
//       </span>
// )}

//         </div>
//       </div>
//         </div>

// <div className="mb-6 bg-white rounded-lg shadow-sm">
//             {Icons.map((icon) => (
//               <div key={icon.id} className="text-sm font-medium">
//                 <div className=" bg-blue-50 p-4 flex items-center mb-2">
//                   <span className="mr-2 text-customGray">
//                     {icon.name} - ({icon.mainMenu.name} - {icon.subMenu.name})
//                   </span>
//                   <input
//                     type="checkbox"
//                     className="h-4 w-4 text-blue-600 border-gray-300 rounded"
//                     disabled
//                     // disabled={isViewMode}
//                     checked={selectedIcons[icon.id] || false}
//                     onChange={() => handleIconChange(icon.id)}
//                   />
//                 </div>

//                 <div className="p-4 grid grid-cols-4 gap-4 items-center">
//                   {permissionIcons.map((permission) => (
//                     (icon.id === 108 && [8, 9, 12].includes(permission.id)) ? null : ( // Exclude specific permission IDs
//                     <div key={`${icon.id}-${permission.id}`} className="flex items-center space-x-2">
//                       <input
//                         type="checkbox"
//                         className="h-4 w-4 text-blue-600 border-gray-300 rounded"
//                         disabled
//                         // disabled={isViewMode || !selectedIcons[icon.id]}
//                         checked={selectedPermissions[icon.id]?.[permission.id] || false}
//                         onChange={() => handlePermissionChange(icon.id, permission.id)}
//                       />
//                       <div className="flex items-center">
//             <span className="text-sm text-customGray">{permission.name}</span>
//             <span className="ml-2 text-customGray"> {iconMapping[permission.name] || null}</span>
//           </div>
//                     </div>
//                     )
//                   ))}
//                 </div>
//               </div>
//             ))}
//           </div>


// {isUserModalOpen && (
//   <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//     <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
//       <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
//         <h2 className="text-lg font-semibold text-CustomBlue">Select User</h2>
//         <input
//           type="text"
//           placeholder="Search Username..."
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
//         />
//         <button onClick={closeUserModal} className="text-gray-500 hover:text-gray-700">&times;</button>
//       </div>

//       {/* Scrollable Content */}
//       <div className="flex-1 overflow-auto p-4">
//         {users.length > 0 ? (
//           <div className="grid grid-cols-3 gap-4">
//             {users
//               .filter((user) =>
//                 user.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 user.username.toLowerCase().includes(searchTerm.toLowerCase())
//               )
//               .map((user, index) => (
//                 <div key={user.userId} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
//                   <input
//                     type="radio"
//                     name="username"
//                     value={user.userId}
//                     checked={selectedUserId === user.userId} // Check if the user is selected
//                     onChange={handleCheckboxChangeForUser}
//                     className="mr-2 text-blue-200"
//                   />
//                   <label>{user.username}</label>
//                 </div>
//               ))}
//           </div>
//         ) : (
//           <p>No Users Found</p>
//         )}
//       </div>
               
//                     <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
//                       <button
//                         onClick={closeUserModal}
//                         className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
//                       >
//                         Save
//                       </button>
//                     </div>
             
                
//               </div>
//             </div>
//           )}


//         </div>
//       </div>

//       <Footer> 
//       {(!isViewMode && (

//       <ButtonGroup
//         onSave={handleSubmit}
//         onSaveContinue={handleSaveContinueDetails}
//       /> 
//     ))}
//     </Footer>
//     </div>
//   )
// }























import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UserService from '../../../../../../rest-services/UserServices';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdTime, IoMdTrash } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import { LuKeyRound } from 'react-icons/lu';
import { MdOutlineFileDownload, MdOutlineFileUpload, MdOutlineLock } from 'react-icons/md';
import { TbDeviceDesktopCancel } from 'react-icons/tb';
import Footer from '../../../../../../components/footer';
import ButtonGroup from '../../../../../../components/FooterButton';
import { useToast } from '../../../../../../components/toast/toast';
import { RxDropdownMenu } from 'react-icons/rx';

export default function RolesAndPermissionAdd() {
    const navigate = useNavigate();
    const { UserId } = useParams();
    const location = useLocation();
    const { mode } = location.state || {}; // Get the mode from the state
    const isViewMode = mode === 'view';
    const isEditMode = mode === 'edit';
    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState(null);
    const[roles,setRoles] = useState([]);
    const { toast } = useToast();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(""); 
  const [searchTerm, setSearchTerm] = useState("");
  const [usersToShow, setUsersToShow] = useState([]); 
  const [userName, setUserName] = useState(""); // Country Name
  const [userType, setUserType] = useState("");
  const [userTypes, setUserTypes] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });

  const [userId, setUserId] = useState(""); // Country Name
  const [selectedRole, setSelectedRole] = useState('');
    const [isAdminChecked, setIsAdminChecked] = useState(false);
  const [Icons, setIcons] = useState([]);
  const [permissionIcons, setPermissionIcons] = useState([]);
  const [selectedIcons, setSelectedIcons] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState({});


    const [permissions, setPermissions] = useState({
        create: false,
        download: false,
        copy: false,
        change: false,
        view: false,
        approve: false,
        reject: false,
        auditTrail: false,
        upload: false,
        inactive: false,
        triggerOTP: false,
        lock:false,
        delete:false,
      });
      
      const permissionValues = {
        create: 1,
        download: 2,
        copy: 3,
        change: 4,
        view: 5,
        approve: 6,
        reject: 7,
        auditTrail: 8,
        upload: 9,
        inactive: 10,
        triggerOTP:11,
        lock:12,
        delete: 13,
      };
      
      
      const handleAdminCheckboxChange = () => {
        setIsAdminChecked(!isAdminChecked);
      };

      const handleCheckboxChangeForUser = (event) => {
        const selectedUserId = event.target.value; // Get the selected user's ID
        console.log("selected user id data",selectedUserId);
        setSelectedUserId(selectedUserId); // Update the state to reflect the selected user

        const selectedUserData = users.find(user => user.userId === selectedUserId);
        if (selectedUserData) {
            setUserName(selectedUserData.username);
            setUserId(selectedUserData.userId);
            setUserType(selectedUserData.userType);
        }

      };

      const handleCheckboxChange = (permission) => {
        setPermissions({ ...permissions, [permission]: !permissions[permission] });
      };

    const handleNavigate = (path) => {
        navigate(path);
      };

      const handleRoleChange = (e) => {
        const newRole = e.target.value;
        setSelectedRole(newRole);
        // Reset permissions when the role changes
        setPermissions({
          create: false,
          download: false,
          copy: false,
          change: false,
          view: false,
          approve: false,
          reject: false,
          auditTrail: false,
          upload: false,
          inactive: false,
          triggerOTP: false,
          lock: false,
          delete: false,
        });
      };



      useEffect(() => {
        if (selectedRole) {
            console.log("selected role",selectedRole);
          getDataForSelectedRole();
        }
      }, [selectedRole]);


      useEffect(() => {
        if (UserId) {
          getDataForEdit();
        }
      }, [UserId]);

      
      const getDataForEdit = async () => {
        try {
          const data = { userId: UserId };
          console.log("Request Data:", data);

          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`, data);      

          console.log("Response from server fetching roles & Permissions:", response);
          const responseData = response.data;
          console.log('set roles & permission details',responseData);
          // setRoles(responseData);
          if(responseData){

          setUserName(responseData.username);
          setUserType(responseData.userType);
          setSelectedRole(responseData.role_id);
          console.log('set roles Id',responseData.role_id);

          
          if (responseData.usersPermissionsTables && responseData.usersPermissionsTables.length > 0) {
            const updatedPermissions = { ...permissions }; // Clone the current permissions state

            // Loop through the permissions from the API and map them to your permission values
            responseData.usersPermissionsTables.forEach(permission => {
                const permissionId = permission.permissionsId;

                // Find the key (like 'create', 'download') for the permissionId in the permissionValues object
                const permissionKey = Object.keys(permissionValues).find(key => permissionValues[key] === permissionId);

                if (permissionKey) {
                    // Set the corresponding permission to true
                    updatedPermissions[permissionKey] = true;
                }
            });

            // Update the permissions state with the new values
            setPermissions(updatedPermissions);
        }

        console.log('Updated permissions:', permissions);
    }



          if (responseData.error) {
            console.error("Error fetching roles data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching roles data", error);
          setAlert({ severity: "error", message: `Error fetching roles data, kindly check the connection ${error.message}` });
        }
      };

   
      const getDataForSelectedRole = async () => {
        try {
          const data = { roleId: selectedRole };
          console.log("Request Data:", data);

          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-role-by-roleId`, data);      

          console.log("Response from server fetching roles:", response);
          const responseData = response.data;
          console.log('set roles details',responseData);
          // setRoles(responseData);
          if(responseData){

          console.log('set roles details name',responseData.name);
          console.log('set roles details desc',responseData.description);

          
       
          const initialIcons = {};
          const initialPermissions = {};
  
          responseData.rolesPermissionsTables.forEach((rolePermission) => {
            const { iconId, permissionsId } = rolePermission;
  
            initialIcons[iconId] = true;
            if (!initialPermissions[iconId]) initialPermissions[iconId] = {};
            initialPermissions[iconId][permissionsId] = true;
          });
  
          setSelectedIcons(initialIcons);
          setSelectedPermissions(initialPermissions);
    }



          if (responseData.error) {
            console.error("Error fetching roles data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching roles data", error);
          setAlert({ severity: "error", message: `Error fetching roles data, kindly check the connection ${error.message}` });
        }
      };


      useEffect(() => {
        fetchallRoles();
        fetchUsers();
        fetchIconsData();
        fetchGetPermissionsData();
        fetchUserTypes();
        fetchAssignee();
      }, []);

      const fetchAssignee = async () => {
        try {
          const userDetailString = sessionStorage.getItem("userDetail");
    
          if (userDetailString) {
            const userDetail = JSON.parse(userDetailString);
            const assigneeData = {
              id: userDetail.id || "",
              assigner: userDetail.username || "",
              assignerId: userDetail.userId || "",
            };
            
            setAssignee(assigneeData);
            
            // Log the id and assigner values to the console
            console.log("Assignee ID:", assigneeData.id);
            console.log("Assigner:", assigneeData.assigner);
            console.log("Assigner userId:", assigneeData.assignerId);
            
          } else {
            setAlert({
              severity: "error",
              message: "No user detail found in session storage.",
            });
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching user details: ${error.message}`,
          });
        }
      };

      const fetchUserTypes = async () => {
        try {
          const response = await UserService.getUsers(
            `${process.env.REACT_APP_BASE_URL}/get-user-type`
            
          );
          setUserTypes(response.data.data);
          console.log("Set user types data",response.data.data);
        } catch (error) {
          console.error("Error fetching user types:", error);
        }
      };

      const selectedUserTypeName = userTypes.find(type => type.id === userType)?.name || "";

      const handlePermissionChange = (iconId, permissionId) => {
        setSelectedPermissions((prev) => ({
          ...prev,
          [iconId]: {
            ...prev[iconId],
            [permissionId]: !prev[iconId]?.[permissionId],
          },
        }));
      };

      const handleIconChange = (iconId) => {
        setSelectedIcons((prev) => ({
          ...prev,
          [iconId]: !prev[iconId],
        }));
      };

      const fetchIconsData = async () => {
        try {
          const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-icons-data`);      
          console.log("Response from server fetching users:", response);
          const responseData = response.data.data;
          setIcons(responseData);
    
    
          console.log("double check users",response.data.data);
          if (responseData.error) {
            console.error("Error fetching Users data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching Users data", error);
          setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
        }
      };

    const fetchGetPermissionsData = async () => {
      try {
        const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-permissions`);      
        console.log("Response from server fetching permissions data:", response);
        const responseData = response.data.data;
        setPermissionIcons(responseData);
  
  
        console.log("double check users permissons",responseData);
        if (responseData.error) {
          console.error("Error fetching Users data:", responseData.message);
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
      } catch (error) {
        console.error("Error fetching Users data", error);
        setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
      }
    };

      const fetchallRoles = async () => {
        try {
          const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-roles`);      
          console.log("Response from server fetching roles:", response);
          const responseData = response.data.data;
          console.log('add last seen and roles data',responseData);
          setRoles(responseData);
        
          if (responseData.error) {
            console.error("Error fetching roles data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching roles data", error);
          setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
        }
      };

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'User Master') {
          navigate('/14/admin');
        } else if (crumb.title === 'Admin') {
          navigate('/14/admin');
        }
        else if (crumb.title === 'Roles & Permissions') {
            navigate('/14/admin/108/roles-and-permission');
        }
        else if (crumb.path) {
            navigate(crumb.path); // Navigate to the specified path in the breadcrumb
        }
      };

      const fetchUsers = async () => {
        try {
          const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/user/get-user/approved`);      
          console.log("Response from server fetching users:", response);
          const responseData = response.data.data;
          console.log('Set users data',responseData);
          setUsers(responseData);
          if (responseData.error) {
            console.error("Error fetching Users data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching Users data", error);
          setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
        }
      };

      const handleCheckboxChangefilter = (e) => {
        const { name, checked } = e.target;
        setSelectedUsers((prev) => ({ ...prev, [name]: checked }));
      };
    
      
      const validateFields = () => {
        const newErrors = {};
        if (!userName) newErrors.userName = " ";

        setErrors(newErrors);
  
        // Show toast only if there are errors
        if (Object.keys(newErrors).length > 0) {
          toast("warning", "Missing required fields.");
          return false;
        }

        return true;
      };

      const handleSaveContinueDetails = async () => {
        if (!validateFields()) return;
    
        if(UserId){
          console.log("its comes on user id console")
          try {
            
           
    const permissionsData = [];
    Object.keys(selectedIcons).forEach((iconId) => {
      if (selectedIcons[iconId]) {
        Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
          if (selectedPermissions[iconId][permissionId]) {
            permissionsData.push({
              role_id: selectedRole,
              icon_id: parseInt(iconId),
              permission_id: parseInt(permissionId),
              user_id: UserId, // Use the new roleId from the role submission
              updatedby: assignee.assigner
        });
          }
        });
      }
    });

          const permissionsResponse = await UserService.saveAllRolesDetails(
                `${process.env.REACT_APP_BASE_URL}/update-user-permissions`, { permissions: permissionsData }
            );
            console.log("console permissions Response",permissionsResponse);
            if (permissionsResponse.status === 200) {
                // alert('Permissions and role saved successfully');
              toast("success", "User Permissions Updated Successfully");
      
                navigate(`/14/admin/108/roles-and-permission/edit/${UserId}`);
            } else {
                // alert('Failed to save permissions');
                toast("warning", "Failed to save permissions");
      
            }
      
          } catch (error) {
            console.error('Error submitting permissions and role details:', error);
            // alert('Error submitting permissions and role details');
            toast("warning", "Error submitting permissions and role details");
      
        }
        }
        else{
          try {
          
            // Prepare the selected permissions to include the new roleId
            const permissionsData = [];
            Object.keys(selectedIcons).forEach((iconId) => {
              if (selectedIcons[iconId]) {
                Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                  if (selectedPermissions[iconId][permissionId]) {
                    permissionsData.push({
                      role_id: selectedRole,
                      icon_id: parseInt(iconId),
                      permission_id: parseInt(permissionId),
                      user_id: userId, // Use the new roleId from the role submission
                      createdBy: assignee.assigner
                });
                  }
                });
              }
            });

            // Now submit the permissions with the correct role_id
            const permissionsResponse = await UserService.saveAllRolesDetails(
                `${process.env.REACT_APP_BASE_URL}/insert-user-permissions`, { permissions: permissionsData }
            );

            if (permissionsResponse.status === 200) {
                // alert('Permissions and role saved successfully');
              toast("success", "Roles Saved Successfully");

              window.location.reload();
              navigate("/14/admin/108/roles-and-permission/add");

            } else {
                // alert('Failed to save permissions');
                toast("warning", "Failed to save permissions");

            }
      
    } catch (error) {
        console.error('Error submitting permissions and role details:', error);
        // alert('Error submitting permissions and role details');
        toast("warning", "Error submitting permissions and role details");

    }
        }
       
    };
  
      const handleSubmit = async () => {
        if (!validateFields()) return;
    if(UserId){
      console.log("its comes on user id console")
    try {
     
    const permissionsData = [];
    Object.keys(selectedIcons).forEach((iconId) => {
      if (selectedIcons[iconId]) {
        Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
          if (selectedPermissions[iconId][permissionId]) {
            permissionsData.push({
              role_id: selectedRole,
              icon_id: parseInt(iconId),
              permission_id: parseInt(permissionId),
              user_id: UserId, // Use the new roleId from the role submission
              updatedby: assignee.assigner
            });
          }
        });
      }
    });
console.log("update permission array",permissionsData);

    const permissionsResponse = await UserService.saveAllRolesDetails(
          `${process.env.REACT_APP_BASE_URL}/update-user-permissions`, { permissions: permissionsData }
      );
      console.log("console permissions Response",permissionsResponse);
      if (permissionsResponse.status === 200) {
          // alert('Permissions and role saved successfully');
        toast("success", "User Permissions Updated Successfully");

          navigate('/14/admin/108/roles-and-permission');
      } else {
          // alert('Failed to save permissions');
          toast("warning", "Failed to save permissions");

      }

    } catch (error) {
      console.error('Error submitting permissions and role details:', error);
      // alert('Error submitting permissions and role details');
      toast("warning", "Error submitting permissions and role details");

  }
    }
    else{
      try {
        // First, submit the role details
       console.log("selected role also needed",selectedRole);
            // Prepare the selected permissions to include the new roleId

            const permissionsData = [];
            Object.keys(selectedIcons).forEach((iconId) => {
              if (selectedIcons[iconId]) {
                Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                  if (selectedPermissions[iconId][permissionId]) {
                    permissionsData.push({
                      role_id: selectedRole,
                      icon_id: parseInt(iconId),
                      permission_id: parseInt(permissionId),
                      user_id: userId, // Use the new roleId from the role submission
                      createdBy: assignee.assigner
                });
                  }
                });
              }
            });


          //   const selectedPermissions = Object.keys(permissions)
          //       .filter(permission => permissions[permission])
          //       .map(permission => ({
          //           permission_id: permissionValues[permission],
          //           icon_id: 105, // Manually setting icon_id to 1
          //           role_id: selectedRole,
          //         user_id: userId, // Use the new roleId from the role submission
          //       }));
          console.log("now stored in permissions data",permissionsData);

          const permissionsResponse = await UserService.saveAllRolesDetails(
                `${process.env.REACT_APP_BASE_URL}/insert-user-permissions`, { permissions: permissionsData }
            );

            if (permissionsResponse.status === 200) {
                // alert('Permissions and role saved successfully');
              toast("success", "User Permissions Saved Successfully");

                navigate('/14/admin/108/roles-and-permission');
            } else {
                // alert('Failed to save permissions');
                toast("warning", "Failed to save permissions");

            }
       
    } catch (error) {
        console.error('Error submitting permissions and role details:', error);
        // alert('Error submitting permissions and role details');
        toast("warning", "Error submitting permissions and role details");

    }
    }
       
    };

    const handleUserFilterPopup = () => {
        console.log("entered");
        setIsUserModalOpen(true);
      }

      const closeUserModal = () => {
        setIsUserModalOpen(false);
    
      }

      const handleSubmitUsers = () => {
        // Collect selected user IDs and close modal
        const selectedUserIds  = Object.keys(selectedUsers).filter(
          (userId) => selectedUsers[userId]
        );
        console.log("selected users:",selectedUsers);
        console.log("selected :",selectedUserIds );
        setUsersToShow(selectedUserIds ); // Set selected IDs to be shown in the form
        closeUserModal(); // Close modal after selection
      };

      const iconMapping = {
        "Add": <IoAddCircleOutline size={18} />,  // Adjust size as needed
        "Edit": <IoMdCreate size={18} />,
        "View": <svg className="ml-2 text-customGray" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"></path></svg>,
        "Copy": <IoMdCopy size={18} />,
        "Audit Trail": <IoMdTime size={18} />,
        "Approve": <IoMdCheckmark size={18} />,
        "Reject": <IoMdClose size={18} />,
        "Trigger OTP": <LuKeyRound size={18} />,
        "Upload": <MdOutlineFileUpload size={18} />,
        "Download": <MdOutlineFileDownload size={18} />,
        "Inactive": <TbDeviceDesktopCancel size={18} />,
        "Lock": <MdOutlineLock size={18} />,
        "Delete": <IoMdTrash size={18} />,
        // Add other icons with size adjustments
      };

  return (
    <div>
      <Header 
        title={`Admin > User Master > Roles & Permissions > ${UserId ?  (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <div className="p-6">
      <div className="max-w-8xl">
      <div className="grid grid-cols-2 gap-x-8 gap-y-8 mb-6">
      <label className="flex items-center group">
    <span className="w-48 text-sm font-medium text-customGray">Username<span className="text-red-500 ml-1">*</span></span>
    <span className="ml-4">:</span>
           <button
            onClick={handleUserFilterPopup} // Trigger modal or selection dropdown
            className={`border p-1.5 rounded-md w-72 h-8 focus:outline-none ml-4 hover:border-blue-400 text-left flex items-center justify-between 
              ${isViewMode||isEditMode ? 'text-gray-500 bg-gray-100':'text-customGray'} 
               ${errors.userName ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          disabled={isViewMode || isEditMode}

        >
        <span>{userName || 'Select User'} </span>
            <RxDropdownMenu className="ml-2" />
            </button>
</label>

<label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-customGray">
                User Type
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={selectedUserTypeName}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray focus:outline-none ml-4"
                disabled
              />
            </label>

        </div>

        <div className="mb-6 bg-white rounded-lg shadow-sm">
        <div className="bg-blue-50 p-4 text-base text-customBlue font-medium flex items-center">
          <span className="mr-2">Roles</span>

        </div>

        <div className="p-4 bg-blue-50">
        <div className="grid grid-cols-6 gap-4 ">
        {roles.length > 0 ? (
  roles.map((role) => (
    <label key={role.id} className="flex items-center text-customGray">
      <input
        type="radio"
        name="role"
        value={role.id}
        onChange={handleRoleChange}
        className="mr-2"
        checked={selectedRole == role.id}
        disabled={isViewMode}
      />
      <span>{role.name}</span>
    </label>
  ))
) : (
    <span className="text-red-500 ml-1">
    Roles are not created
      </span>
)}

        </div>
      </div>
        </div>

<div className="mb-6 bg-white rounded-lg shadow-sm">
            {Icons.map((icon) => (
              <div key={icon.id} className="text-sm font-medium">
                <div className=" bg-blue-50 p-4 flex items-center mb-2">
                  <span className="mr-2 text-customGray">
                    {icon.name} - ({icon.mainMenu.name} - {icon.subMenu.name})
                  </span>
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                    disabled
                    // disabled={isViewMode}
                    checked={selectedIcons[icon.id] || false}
                    onChange={() => handleIconChange(icon.id)}
                  />
                </div>

                <div className="p-4 grid grid-cols-4 gap-4 items-center">
                  {permissionIcons.map((permission) => (
                    (icon.id === 108 && [8, 9, 10, 12].includes(permission.id)) ? null : ( // Exclude specific permission IDs
                    <div key={`${icon.id}-${permission.id}`} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                        disabled
                        // disabled={isViewMode || !selectedIcons[icon.id]}
                        checked={selectedPermissions[icon.id]?.[permission.id] || false}
                        onChange={() => handlePermissionChange(icon.id, permission.id)}
                      />
                      <div className="flex items-center">
            <span className="text-sm text-customGray">{permission.name}</span>
            <span className="ml-2 text-customGray"> {iconMapping[permission.name] || null}</span>
          </div>
                    </div>
                    )
                  ))}
                </div>
              </div>
            ))}
          </div>


{isUserModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
      <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
        <h2 className="text-lg font-semibold text-CustomBlue">Select User</h2>
        <input
          type="text"
          placeholder="Search Username..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
        />
        <button onClick={closeUserModal} className="text-gray-500 hover:text-gray-700">&times;</button>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-auto p-4">
        {users.length > 0 ? (
          <div className="grid grid-cols-3 gap-4">
            {users
              .filter((user) =>
                user.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.username.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((user, index) => (
                <div key={user.userId} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                  <input
                    type="radio"
                    name="username"
                    value={user.userId}
                    checked={selectedUserId === user.userId} // Check if the user is selected
                    onChange={handleCheckboxChangeForUser}
                    className="mr-2 text-blue-200"
                  />
                  <label>{user.username}</label>
                </div>
              ))}
          </div>
        ) : (
          <p>No Users Found</p>
        )}
      </div>
               
                    <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                      <button
                        onClick={closeUserModal}
                        className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                      >
                        Save
                      </button>
                    </div>
             
                
              </div>
            </div>
          )}


        </div>
      </div>

      <Footer> 
      {(!isViewMode && (

      <ButtonGroup
        onSave={handleSubmit}
        onSaveContinue={handleSaveContinueDetails}
      /> 
    ))}
    </Footer>
    </div>
  )
}
