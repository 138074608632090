import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalProcessServices from "../../../../rest-services/GlobalProcessServices";
import { TbArrowAutofitDown } from "react-icons/tb";
import SearchInput from "../../../../components/SearchInput";
import BankStatementActions from "../../BankStatement/props/ActionButtons";
import Pagination from "../../../../components/PageNation";
import ReTable from "../../../../components/Table";
import { MdOutlineFileDownload } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { bankStatementActionsConfig } from "../../BankStatement/props/ActionButtonConfig";
import { IoMdRefresh } from "react-icons/io";
import Header from "../../../../components/Header";


export default function CashBalanceProcessListTable() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [pageSize, setPageSize] = useState(20);
    const [data, setData] = useState("");
    const [showExtraIcons, setShowExtraIcons] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();
    const iconId = location.state.iconId;
    const subModule = location.state.subModule;

    const handleShowAll = () => {
        setPageSize(totalCount); // Set page size to total count to display all records
        setCurrentPage(1); // Reset to the first page
        fetchDataForTable(1); // Fetch data with updated page size
    };

    const buttons = bankStatementActionsConfig.screen6.map((button) => {
        let onClick;
        let title;
        if (button.icon === CiSearch) {
            // onClick = toggleLayout;
            title = "toggle";
        } else if (button.icon === MdOutlineFileDownload) {
            // onClick = () => downloadExcel();
            title = "download";
        } else if (button.icon === IoMdRefresh) {
            onClick = () => fetchDataForTable(currentPage);
            title = "refresh";
        }

        return {
            ...button,
            onClick,
            title,
        };
    });


    useEffect(() => {
        fetchDataForTable(currentPage);
    }, [currentPage]);

    const fetchDataForTable = async (page) => {
        try {
            const response = await GlobalProcessServices.getAllProcessDetails(pageSize, page, subModule, iconId);
            const totalCount = response.data.totalCount;
            if (response.status === 200) {
                setData(response.data.data);
            }

            setTotalCount(totalCount);
            setTotalPages(Math.ceil(totalCount / pageSize));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const columns = useMemo(() => [
        {
            Header: "SL NO",
            accessor: "id",
            width: ("SL NO".length -2)*10,
            Cell: ({ row }) => {
                return <div style={{ textAlign: "left" }}>{row.index + 1}</div>;
            },
        },

        {
            Header: "Process Name",
            accessor: "processName",
            width: ("Process Name".length -2)*10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value || "-"}</div>,
        },


        {
            Header: "Created By",
            accessor: "createdBy",
            width: ("Created By".length -2)*10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value || "-"}</div>,
        },
        {
            Header: "Created Date",
            accessor: "createdDate",
            width: ("Created Date".length -2)*10,
            Cell: ({ value }) => {
                if (!value) return "";
                const date = new Date(value);
                return <div style={{ textAlign: "left" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
            },
        },
        {
            Header: "Created Time",
            accessor: "createdTime",
            width: ("Created Time".length -2)*10,
            Cell: ({ value }) => {
                if (!value) return ""; // Handle null or undefined values
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
            },
        },
        {
            Header: "Updated By",
            accessor: "updateBy",
            width: ("Updated By".length -2)*10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value || "-"}</div>,
        },
        {
            Header: "Updated Date",
            accessor: "updatedDate",
            width: ("Updated Date".length -2)*10,
            Cell: ({ value }) => {
                if (!value) return "";
                const date = new Date(value);
                return <div style={{ textAlign: "left" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
            },
        },
        {
            Header: "Updated Time",
            accessor: "updatedTime",
            width: ("Updated Time".length -2)*10,
            Cell: ({ value }) => {
                if (!value) return ""; // Handle null or undefined values
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
            },
        },
    ], []);

    const onSelectRow = (id) => {
        const updatedSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter((rowId) => rowId !== id)
            : [...selectedRows, id];
        setSelectedRows(updatedSelectedRows);
        setShowExtraIcons(updatedSelectedRows.length === 1);
    };

    const onSelectAll = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(data.map((row) => row.id));
        }
    };
    const handleDoubleClick = (id) => {
        // navigate(`/statement-analyser`, {
        //     state: { id: id },
        // });
    };

    const extraButtons = [
        {
            icon: TbArrowAutofitDown,
            title: "Manual Assing",
            onClick: () => {
                if (iconId === "64") {
                    navigate(`/company-wise-add`, {
                        state: { id: selectedRows[0], mode: "view" },
                    })
                }
                else if (iconId === "51") {
                    navigate(`/11/mt940/51/add-transfer-bs`, {
                        state: { id: selectedRows[0], mode: "view" },
                    })
                }
            }
        },
    ];

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Cash Balances') {
            navigate('/cash-balances');
        }
        if (crumb.title === 'Cash in Bank') {
            navigate('/cash-balances');
        } else if (crumb.title === 'Company Wise') {
            navigate('/company-wise-add');
        }
        else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };
    return <div>
        <div>
            <Header title={`Cash Balances > Cash in Bank > ${iconId === "64" ? "Company Wise" : ""}`} onBreadcrumbClick={handleBreadcrumbClick} backRoute={`${iconId === "64" ? "/company-wise-add" : ""}`} />
        </div>
        <div className="p-0">
            <div className="flex justify-between items-center -ml-1 pl-4">
                <div className="flex items-center">
                    <SearchInput
                        // searchQuery={searchQuery}
                        // setSearchQuery={setSearchQuery}
                        // onChange={(e) => fetchBankAccount(searchQuery)}
                        placeholder="Search by Identifiers"
                    />

                    <BankStatementActions buttons={buttons} extraButtons={extraButtons} isExtraButtonsVisible={showExtraIcons} />
                </div>
                <div className="flex items-center mr-4">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        totalCount={totalCount}
                        onShowAll={handleShowAll}
                    />
                </div>
            </div>
            <div>
                <ReTable
                    data={data}
                    columns={columns}
                    selectedRows={selectedRows}
                    onSelectRow={onSelectRow}
                    onSelectAll={onSelectAll}
                    onRowDoubleClick={handleDoubleClick}
                />
            </div>
        </div>
    </div>
}