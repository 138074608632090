import React from "react";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";

const BankStatementActions = ({
  buttons = [],
  extraButtons = [],
  isExtraButtonsVisible = false,
}) => {
  const getIconColor = (icon, isVisible) => {
    if (!isVisible) {
      return "text-gray-400"; // Grey color for disabled state
    }
    switch (icon) {
      case IoMdCheckmark:
        return "text-green-500";
      case IoMdClose:
        return "text-red-500";
      default:
        return "text-sky-900";
    }
  };

  const getIconSize = (isVisible) => {
    return isVisible ? "text-3xl" : "text-3xl"; // Reduced size for disabled state
  };

  return (
    <div className="px-4">
      <div className="flex items-center space-x-2">
        {/* Existing buttons aligned to the start */}
        <div className="flex items-center space-x-2">
          {buttons.map((button, index) => (
            <button
              key={index}
              className="text-secondary-foreground p-1 rounded-lg"
              onClick={button.onClick}
            >
              <button.icon
                className={`border border-blue-100 rounded-lg cursor-pointer hover:border-customBlue hover:shadow-md p-1 ${button.rotate ? "rotate-90" : ""
                  } ${getIconColor(button.icon, true)} ${getIconSize(true)}`}
              />
            </button>
          ))}
        </div>

        {/* Extra buttons always visible but in light grey */}
        <div className="flex items-center space-x-2 ml-2">
          {extraButtons.map((button, index) => (
            <button
              key={index}
              className={`text-secondary-foreground p-1 rounded-lg $
            bg-secondary rounded-lg`
              }
              onClick={isExtraButtonsVisible ? button.onClick : undefined}
              disabled={!isExtraButtonsVisible}
            >
              <button.icon
                className={`border border-dark-gray p-1 rounded-lg ${isExtraButtonsVisible
                  ? "hover:border-customBlue shadow hover:shadow-md"
                  : "text-2xl"
                  } ${getIconColor(
                    button.icon,
                    isExtraButtonsVisible
                  )} ${getIconSize(isExtraButtonsVisible)}`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BankStatementActions;
