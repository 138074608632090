import React from 'react'
import Footer from '../../../../../../components/footer'

export default function TextfieldsContents() {
    return (
        <div className='p-4 mb-40'>
                      <div className="flex flex-col gap-y-4 mt-4 ml-10">
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">Text 1</span>
              <span className="ml-3">:</span>
              <input
                type="number"  
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">Text 2</span>
              <span className="ml-3">:</span>
              <input
                type="number"  
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">Text 3</span>
              <span className="ml-3">:</span>
              <input
                type="number"  
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">Text 4</span>
              <span className="ml-3">:</span>
              <input
                type="number"  
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            <label className="flex items-center">
              <span className="w-24 text-sm font-medium text-gray-500">Text 5</span>
              <span className="ml-3">:</span>
              <input
                type="number"  
                className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                required
              />
            </label>
            </div>
            <div className='ml-2'>
            
        </div>
        <Footer>
        <button
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
        >
          Save
        </button>
        <button
          className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
        >
          Save & Continue
        </button>
        </Footer>
        </div>
    )
}
