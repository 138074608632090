import React, { useEffect, useState } from 'react';
import UploadComponent from '../../../../../../components/UploadComponent';
import * as XLSX from 'xlsx';
import Header from '../../../../../../components/Header';
import { useNavigate } from 'react-router-dom';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import { useToast } from '../../../../../../components/toast/toast';
import UserService from '../../../../../../rest-services/UserServices';
import { set, update } from 'lodash';
import SourceServices from '../../../../../../rest-services/SourceServices';

export default function BTCUploadScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [isError, setIsError] = useState(false);
  const [buttonText, setButtonText] = useState('Upload from desktop');
  const [isUploaded, setIsUploaded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [assignee, setAssignee] = useState({ id: '', username: '' });
  const [alert, setAlert] = useState(null);

  const [isDisplayChecked, setIsDisplayChecked] = useState(false);
  const [isUploadChecked, setIsUploadChecked] = useState(false);
  const [displayOption, setDisplayOption] = useState('');
  const [uploadOption, setUploadOption] = useState('');
  const[statementTypes,setStatementTypes]= useState([]);

  // Define the allowed columns for currency rate upload
 // Define the allowed columns for currency rate upload
const allowedFormat = [
    'SourceType', 
    'SourceCode', 
    'OurCode', 
    'SourceCodeDescription', 
    'OurCodeDescription', 
    'DateType', 
    'RateType', 
    'Ratio', 
    'MDType', 
  ];
  
  useEffect(() => {
    fetchAssignee();
  }, []);
  
  
  // Fetch the logged-in user details
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem('userDetail');
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || '',
          clientId: userDetail.clientId || '',
          assigner: userDetail.username || '',
          userId: userDetail.userId || '',
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: 'error',
          message: 'No user detail found in session storage.',
        });
      }
    } catch (error) {
      setAlert({
        severity: 'error',
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
  
  // Convert Excel date to JavaScript date
  const excelDateToJSDate = (date) => {
    if (!isNaN(date)) {
      const excelEpoch = new Date(Date.UTC(1899, 11, 30));
      const jsDate = new Date(excelEpoch.getTime() + date * 86400000);
  
      // Format to DD-MM-YYYY
      const day = String(jsDate.getUTCDate()).padStart(2, '0');
      const month = String(jsDate.getUTCMonth() + 1).padStart(2, '0');
      const year = jsDate.getUTCFullYear();
  
      return `${day}-${month}-${year}`;
    }
    return date;
  };
  
  // Handle file upload and validation
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
  
      // Convert sheet to JSON with headers
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      if (jsonData.length === 0) {
        setButtonText('File Format is Incorrect!');
        setIsUploaded(false);
        setIsError(true);
        toast('error', 'The uploaded file is empty or invalid.');
        return;
      }
  
      // Define the new headers
      const newHeaders = [
        'Action',
        'SLNo',
        'BTCCode',
        'BTCDescription',
        'StatementType',
        'Inflow',
        'Outflow',
        
      ];
  
      // Check if the file format matches the allowed format
      const headers = jsonData[0].map((header) => header.trim().toLowerCase());
      const isSourceFormat = newHeaders
        .map((header) => header.trim().toLowerCase())
        .every((header) => headers.includes(header));
  
      if (!isSourceFormat) {
        setButtonText('File Format is Incorrect!');
        setIsUploaded(false);
        setIsError(true);
        toast('error', 'The uploaded file does not match the required format.');
        return;
      }
  
      // Skip the first two rows (header and any additional row)
      const rows = jsonData.slice(3);
  
      // Process and format rows
      const formattedRows = rows.map((row) => {
        const formattedRow = {};
        newHeaders.forEach((header, index) => {
          formattedRow[header] = row[index] || null; // Use null for missing values
        });
  
        // Handle date format for 'RateDate' (if applicable)
        if (formattedRow.RateDate) {
          formattedRow.RateDate = excelDateToJSDate(formattedRow.RateDate);
        }
  
        return formattedRow;
      });
  
      console.log("Formatted Rows:", formattedRows);
      setTableData(formattedRows);
      setButtonText("File Uploaded");
      setIsUploaded(true);
      setIsError(false);
  
      setTimeout(() => setIsUploaded(false), 1000);
    };
  
    reader.readAsArrayBuffer(file);
  };
  
// I’ve added the date format handling back in! Let me know if this works for you. 🚀

// Let me know if you’d like me to tweak anything! 🚀

useEffect(() => {
  fetchStatementTypes();
}, []);
const fetchStatementTypes = async () => {
  setLoading(true);
  try {
    const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
    const responseData = response.data.data;
console.log("Response Data:", responseData);
    if (responseData.error) {
      setAlert({ severity: "error", message: responseData.message });
      return;
    }

    setStatementTypes(responseData);
    console.log("Statement Types:", statementTypes);
  } catch (error) {
    setAlert({
      severity: "error",
      message: `Error fetching legal entities: ${error.message}`,
    });
  } finally {
    setLoading(false);
  }
};
const isRowValid = async (row, index) => {
  console.log("Row Object in isRowValid:", row);
  
  // 1. Fetch valid types
  let validStatementTypes = [];
  try {
    const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
    validStatementTypes = response.data.data || [];
    console.log("Valid Statement Types:", validStatementTypes);
  } catch (error) {
    console.error("Error fetching statement types:", error);
  }

  // 2. Validate all fields
  const isActionValid = row.Action && ["A", "a","E","e"].includes(row.Action) && String(row.Action).length === 1;
  const isSLNoValid = row.SLNo === undefined || row.SLNo === '' || (!isNaN(row.SLNo) && String(row.SLNo).length <= 5);
  const isBTCCodeValid = row.BTCCode && String(row.BTCCode).length <= 6;
  const isBTCDescriptionValid = row.BTCDescription && String(row.BTCDescription).length <= 50;
  
  // 3. Use the fetched validStatementTypes (FIXED THIS LINE)
  const isStatementTypeValid = !row.StatementType || validStatementTypes.includes(row.StatementType);
  const isInflowValid = !row.Inflow || ['X', 'x'].includes(row.Inflow);
  const isOutflowValid = !row.Outflow || ['X', 'x'].includes(row.Outflow);

  // 4. Collect errors with more detailed messages
  let errors = [];
  if (!isActionValid) errors.push("Invalid Action");
  if (!isSLNoValid) errors.push("Invalid Serial No");
  if (!isBTCCodeValid) errors.push("Invalid BTC Code");
  if (!isBTCDescriptionValid) errors.push("Invalid BTC Description");
  if (!isStatementTypeValid) errors.push(
    `Invalid Statement Type '${row.StatementType}'. Valid types: ${validStatementTypes.join(', ') || 'None'}`
  );
  if (!isInflowValid) errors.push("Invalid Inflow");
  if (!isOutflowValid) errors.push("Invalid Outflow");

  if (errors.length > 0) {
    console.log(`Row ${index + 1} is invalid:`, errors);
    console.log("Provided StatementType:", row.StatementType);
    console.log("Valid Types:", validStatementTypes);
  }
  console.log("Final Valid Row:", row);
  return (
    isSLNoValid &&
    isActionValid &&
    isBTCCodeValid &&
    isBTCDescriptionValid &&
    isStatementTypeValid &&
    isInflowValid &&
    isOutflowValid
  );
};

const getExistingUserIds = async (tableData) => {
  try {
    // First fetch valid statement types ONCE
    let validStatementTypes = [];
    try {
      const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
      validStatementTypes = response.data.data || [];
      console.log("Valid Statement Types:", validStatementTypes);
    } catch (error) {
      console.error("Error fetching statement types:", error);
    }

    // Async validation of all rows including dataset duplicates check
    const validationResults = await Promise.all(
      tableData.map(async (row, rowIndex) => {
        // Check for duplicates within current dataset first
        const isDuplicateInDataset = tableData.some(
          (r, index) => index !== rowIndex && r.BTCCode === row.BTCCode
        );
        
        if (isDuplicateInDataset) {
          console.log(`Duplicate BTCCode in dataset: ${row.BTCCode}`);
          return { row, isValid: false, isDuplicate: true };
        }

        // Validate other fields if no dataset duplicate
        const isValid = await isRowValid(row, rowIndex, validStatementTypes);
        return { row, isValid, isDuplicate: false };
      })
    );

    // Filter valid rows (not duplicates and passed validation)
    let validRows = validationResults
      .filter(result => result.isValid && !result.isDuplicate)
      .map(result => result.row);
    
    console.log("Initial valid rows after dataset duplicate check:", validRows);

    // Then check for duplicates in DB
    const validatedRows = await Promise.all(
      validRows.map(async (row) => {
        const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/btc/getduplicatebtc`,
          { btcCode: row.BTCCode }
        );

        const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
        if (isDuplicateInDB) {
          console.log(`Duplicate found in DB for BTCCode: ${row.BTCCode}`);
          return null;
        }
        return row;
      })
    );

    // Final valid rows
    validRows = validatedRows.filter(row => row !== null);
    console.log("Final valid rows after all checks:", validRows);
    return validRows;

  } catch (error) {
    console.error("Error in getExistingUserIds:", error);
    return [];
  }
};
  const getDataToDisplay = async () => {
    let dataToDisplay;
    if (uploadOption === "3") {
      console.log("displayOption is 3");
      dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"

      // dataToDisplay = await processData(tableData); // Display processed data if uploadOption is "3"
    } else {
      console.log("displayOption not selected");
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
    
    console.log("dataToDisplay data is", dataToDisplay);
    return dataToDisplay;
  };

  const processData = async (data) => {
    if (!Array.isArray(data)) {
      console.error("processData received invalid data:", data);
      return []; // Return an empty array to prevent further errors
    }
    console.log("processData received data:", data);
    let validStatementTypes = [];
    try {
      const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
      validStatementTypes = response.data.data || [];
      console.log("Valid Statement Types:", validStatementTypes);
    } catch (error) {
      console.error("Error fetching statement types:", error);
    }
    return Promise.all(
      data.map(async (row, rowIndex) => {
        // Check for duplicate BTCCode in the current dataset
        const isDuplicateUserId = data.some(
          (r, index) =>
            index !== rowIndex && // Exclude the current row by index
            r.BTCCode === row.BTCCode // Compare BTCCode directly
        );
        console.log(`Row ${row.BTCCode}: isDuplicateUserId = ${isDuplicateUserId}`);
  
        // Check for duplicate BTCCode in the database
        const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/btc/getduplicatebtc`,
          {
            btcCode: row.BTCCode,
          }
        );
  
        console.log("isDuplicateInDB API Response:", isDuplicateInDBResponse);
  
        // Extract the `isDuplicate` property from the response
        const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
        console.log(`Row ${row.BTCCode}: isDuplicateInDB = ${isDuplicateInDB}`);
  
        // Validate all fields
        const hasNotError = [
          row.Action,
          row.SLNo,
          row.BTCCode,
          row.BTCDescription,
          row.StatementType,
          row.Inflow,
          row.Outflow,
        ].every((field, index) => {
          let isValid = true;
          let reason = "";
  
          switch (index) {
            case 0: // Action
              if (!field) {
                isValid = false;
                reason = "Action should not be blank.";
              } else if (!['A', 'a', 'E', 'e'].includes(field)) {
                isValid = false;
                reason = "Action should be either 'A', 'a', 'E', or 'e'.";
              } else if (String(field).length > 1) {
                isValid = false;
                reason = "Action exceeds max length of 1.";
              }
              break;
  
            case 1: // SLNo
              if (row.SLNo !== undefined && row.SLNo !== '') {
                if (isNaN(row.SLNo)) {
                  isValid = false;
                  reason = "SLNo should be a valid number.";
                } else if (String(row.SLNo).length > 5) {
                  isValid = false;
                  reason = "SLNo exceeds max length of 5.";
                }
              }
              break;
  
            case 2: // BTCCode
              if (!field) {
                isValid = false;
                reason = "BTCCode should not be blank.";
              } else if (String(field).length > 6) {
                isValid = false;
                reason = "BTCCode exceeds max length of 6.";
              } else if (isDuplicateUserId || isDuplicateInDB) {
                isValid = false;
                reason = "Duplicate BTCCode.";
              }
              break;
  
            case 3: // BTCDescription
              if (!field) {
                isValid = false;
                reason = "BTCDescription should not be blank.";
              } else if (String(field).length > 50) {
                isValid = false;
                reason = "BTCDescription exceeds max length of 50.";
              }
              break;
              case 4: // StatementType (optional)
              if (field && !validStatementTypes.includes(field)) {
                isValid = false;
                reason = `Invalid Statement Type. Valid types: ${validStatementTypes.join(', ')}`;
              }
              break;
  
            case 5: // Inflow (optional)
              if (field && !['X', 'x'].includes(field)) {
                isValid = false;
                reason = "Inflow should be either 'X' or 'x'.";
              }
              break;
  
            case 6: // Outflow (optional)
              if (field && !['X', 'x'].includes(field)) {
                isValid = false;
                reason = "Outflow should be either 'X' or 'x'.";
              }
              break;
  
            default:
              break;
          }
  
          if (!isValid) {
            console.log(`Row ${row.BTCCode}: Validation Failed - ${reason}`);
          } else {
            console.log(`Row ${row.BTCCode}: Validation Passed - ${field}`);
          }
  
          return isValid;
        });
  
        console.log(`Row ${row.BTCCode}: Final hasNotError status = ${hasNotError}`);
  
        return {
          ...row,
          isDuplicateUserId,
          isDuplicateInDB,
          successfulrecords: hasNotError,
  
          'Action': row['Action'] ? (
            ['A', 'a', 'E', 'e'].includes(row['Action']) ? (
              row['Action']
            ) : (
              <span style={{ color: 'red' }}>{row['Action']} (Invalid Action)</span>
            )
          ) : (
            <span style={{ color: 'red' }}> Shouldn't be blank</span>
          ),
          'SLNo': row.SLNo ? (
            !isNaN(row.SLNo) && String(row.SLNo).length <= 5 ? (
              row.SLNo
            ) : (
              <span style={{ color: 'red' }}>{row.SLNo} (Invalid SLNo)</span>
            )
          ) : (
            <span style={{ color: 'red' }}></span>
          ),
          'BTCCode': row.BTCCode ? (
            String(row.BTCCode).length <= 6 ? (
              isDuplicateUserId || isDuplicateInDB ? (
                <span style={{ color: 'red' }}>{row.BTCCode}, Duplicate BTCCode</span>
              ) : (
                row.BTCCode
              )
            ) : (
              <span style={{ color: 'red' }}>{row.BTCCode}, Max. allowed character is 6</span>
            )
          ) : (
            <span style={{ color: 'red' }}>Shouldn't be blank</span>
          ),
          'BTCDescription': row.BTCDescription ? (
            String(row.BTCDescription).length <= 50 ? (
              row.BTCDescription
            ) : (
              <span style={{ color: 'red' }}>{row.BTCDescription} (Max. allowed character is 50)</span>
            )
          ) : (
            <span style={{ color: 'red' }}>Shouldn't be blank</span>
          ),
       'StatementType': row['StatementType'] ? (
        validStatementTypes.includes(row['StatementType']) ? (
          row['StatementType']
        ) : (
          <span style={{ color: 'red' }}>
            {row['StatementType']} (Invalid. Valid: {validStatementTypes.join(', ')})
          </span>
        )
      ) : (
        <span></span>
      ),
          'Inflow': row['Inflow'] ? (
            ['X', 'x'].includes(row['Inflow']) ? (
              row['Inflow']
            ) : (
              <span style={{ color: 'red' }}>{row['Inflow']} (Invalid Inflow)</span>
            )
          ) : (
            <span></span> // Empty span for optional field
          ),
          'Outflow': row['Outflow'] ? (
            ['X', 'x'].includes(row['Outflow']) ? (
              row['Outflow']
            ) : (
              <span style={{ color: 'red' }}>{row['Outflow']} (Invalid Outflow)</span>
            )
          ) : (
            <span></span> // Empty span for optional field
          ),
        };
      })
    );
  };

  const storeArrayDataForUsers = async () => {
    // console.log("store successfulRecords data is", getDataToDisplay);

    setLoading(true);
    try {
        const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
        console.log("table data",tableData);
        console.log("store successfulRecords data 2 is", dataToDisplay);

       const processed = await processData(tableData);
                 console.log("display datas",processed);
       
                const extractText = (cellValue) => {
                             if (Array.isArray(cellValue)) {
                               return cellValue.join(", "); // Convert array to string
                             } else if (typeof cellValue === "string") {
                               return cellValue;
                             } else if (React.isValidElement(cellValue)) {
                               return cellValue.props.children;
                             }
                             return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                           };
               
                           const formattedUsers = processed.map((user) => {
               
                             return {
                              "Action": extractText(user.Action),
                              "SLNo": extractText(user.SLNo),
                              "BTCCode": extractText(user.BTCCode),
                              "BTCDescription": extractText(user.BTCDescription),
                              "StatementType": extractText(user.StatementType),
                              "Inflow": extractText(user.Inflow),
                             "Outflow": extractText(user.Outflow),
                             
                          
                           };
                         });
                           
                             // Insert second and third row at the beginning of the formatted data
                             const rowsToDisplay = [ ...formattedUsers];
                     
                             console.log("Formatted USERS Data:", formattedUsers);
                             console.log("Formatted Data:", rowsToDisplay);
                     
                             
                             const updatedData = rowsToDisplay.map(user => {
                              const hasErrorField = [
                                "Action",
                                "SLNo",
                                "BTCCode",
                                "BTCDescription",
                                "StatementType",
                                "Inflow",
                                "Outflow",
                               
                                
                              ].some(field => typeof user[field] === 'object' || user[field]?.includes("(Invalid") || user[field]?.includes("Shouldn't be blank") || user[field]?.includes("Duplicate SourceCode") || user[field]?.includes("SourceCode does not exist"));
                            
                              return {
                                update: hasErrorField ? "No" : "Yes",
                                ...user,
                              };
                            });
                            
                       console.log("updatedData data",updatedData);
                       const mappedData = dataToDisplay.map((row) => ({
                                Action: row.Action,
                                SLNo: row.SLNo,
                                btcCode: row.BTCCode,
                                description: row.BTCDescription, // Map SourceType to source
                                statement: row.StatementType,
                                inflow: row.Inflow,
                                outflow: row.Outflow,
                                createdBy:assignee.userId
                                
                              }));
                           
        // Prepare the final data object
        const data = {
            dataToDisplay:mappedData, // Only valid rows
            created_by: assignee.userId,
            allrecords: updatedData, // All rows with update flag
        };

        console.log("storeArrayDataForCurrencyRates dataToDisplay is", data);

        // Send the data to the server
        const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/btc/bulk-create-btc`,
            data
        );
if (dataToDisplay.length === 0) {
  toast("error", "Error fields exist.");
  return;
}
        else if (response.status === 200) {
            toast('success', 'Uploaded Source successfully');
        } else if (response.status === 400) {
            toast('error', 'Invalid or empty array of Source');
        } else if (response.status === 404) {
            toast('error', 'Missing required fields');
        } else if (response.status === 500) {
            toast('error', 'Duplicate Source found.');
        }
    } catch (error) {
        console.error('Error uploading Source:', error);
    } finally {
        setLoading(false);
    }
};
  
  

  const handleSubmit = async () => {
    if (buttonText === 'Upload from desktop') {
      toast('error', 'Please upload a file.');
      return;
    } else if (tableData.length === 0) {
      toast('error', 'Please upload the correct file containing the data.');
      return;
    }

    // const validRows = tableData.filter((row) => isRowValid(row));
    // if (validRows.length === 0) {
    //   toast('error', 'No valid rows found in the uploaded file.');
    //   return;
    // }

    if (!isDisplayChecked && !isUploadChecked) {
      toast('error', 'Please check at least one checkbox (Display or Upload).');
      return;
    }

    if (uploadOption !== '' && displayOption === '') {
      storeArrayDataForUsers();
    } else {
      if (uploadOption !== '') {
        storeArrayDataForUsers();
      }
      navigate(`/5/bank-statement/14/btc-upload/display-screen`, {
        state: { tableData, displayOption, uploadOption }, // Only pass valid rows
      });
    }
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };


  const confirmDeletion = async () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Define the headers for the Source format
    const headers = ['RateType', 'RateDate', 'SourceType', 'SourceCode', 'Bid', 'Ask'];
  
    // Get the current date
    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  
    // Example: Fill 10 rows with current date
    const rowCount = 10; // You can change this or make it dynamic
    const worksheetData = [headers];
  
    for (let i = 0; i < rowCount; i++) {
      worksheetData.push(['FX', formattedDate, 'SOURCE_TYPE', 'INRKWD=CBKK', '', '']);
    }
  
    // Create a worksheet with headers and data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
    // Create a Blob and trigger the download
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'BTC_Format_Download.xlsx';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  };
  
  // Now, every time the user downloads, the date will automatically update in every row! 🚀
  
  // Let me know if you want me to make any tweaks or add dynamic row counts! ✨
  

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BTC") {
      navigate("/btc");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  return (
    <div>
      <Header
        title={`Bank Statement > MT940 > BTC > BTC upload`}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={'/btc'}
      />
      {loading && <CustomeLoading />}

      <UploadComponent
 downloadPath="/BTC_Format_Upload.xlsx"
        handleFileChange={handleFileChange}
        buttonText={buttonText}
        isUploaded={isUploaded}
        isError={isError}
        handleSubmit={handleSubmit}
        isDisplayChecked={isDisplayChecked}
        setIsDisplayChecked={setIsDisplayChecked}
        isUploadChecked={isUploadChecked}
        setIsUploadChecked={setIsUploadChecked}
        displayOption={displayOption}
        setDisplayOption={setDisplayOption}
        uploadOption={uploadOption}
        setUploadOption={setUploadOption}
       
        downloadText='Download BTC Format' // Dynamic text
      />

      <ConfirmationDialog
        open={showConfirmation}
        title="Upload File"
        message="Do you need to download the latest upload format?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      />
    </div>
  );
}



