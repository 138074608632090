import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdRefresh,
  IoMdTrash,
} from "react-icons/io";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import BankServices from "../../../../../rest-services/BankServices";
import { useToast } from "../../../../../components/toast/toast";
import { Checkbox } from "@mui/material";
import axios from "axios";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import ReusableTable from "../../Components/Table";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import * as XLSX from "xlsx";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";
import Pagination from "../../../../../components/PageNation";
import { debounce } from "lodash";
import UserService from "../../../../../rest-services/UserServices";

export default function BankScreen() {
    const { iconId } = useParams();
  const [bankId, setBankId] = useState(null); // Track selected bank ID for editing
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [banks, setBanks] = useState([]);
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const navigate = useNavigate();
  const { toast } = useToast();
  const [logo, setLogo] = useState({});
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [rolesPermissions, setRolesPermissions] = useState([]);
      const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    fetchAssignee();
  }, []);

const fetchAssignee = async () => {
  try {
    const userDetailString = localStorage.getItem("userDetail");

    if (userDetailString) {
      const userDetail = JSON.parse(userDetailString);
      const assigneeData = {
        id: userDetail.id || "",
        assigner: userDetail.username || "",
        clientId: userDetail.clientId || "",
        assignerId: userDetail.userId || "",
      };
      console.log("assigneeData is", assigneeData);

      setAssignee(assigneeData);

      // Log the id and assigner values to the console
      console.log("Assignee ID:", assigneeData.id);
      console.log("Assigner:", assigneeData.assigner);
      console.log("Assigner userId:", assigneeData.assignerId);
      console.log("Assigner clientId:", assigneeData.clientId);
    } else {
      setAlert({
        severity: "error",
        message: "No user detail found in session storage.",
      });
    }
  } catch (error) {
    setAlert({
      severity: "error",
      message: `Error fetching user details: ${error.message}`,
    });
  }
};

  useEffect(() => {
    if (assignee.assignerId) {
      console.log("Assigner ID has changed:", assignee.assignerId);
      fetchUserPermissionDetails();
    }
  }, [assignee.assignerId]);

const fetchUserPermissionDetails = async () => {
  try {
    const data = { userId: assignee.assignerId };
    console.log("Request Data:", data);

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
      data
    );
    console.log("user permissions details", response);
    const responseData = response.data;
    setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
    console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
  } catch (error) {
    console.error(error.message); // Set error message
  } finally {
    setLoading(false); // Reset loading state
  }
};

  const columns = [
    { Header: "Bank Code", accessor: "bankCode", visible: true, width:("Bank Code".length -2)*10, alignment: "left" },
    { Header: "Description", accessor: "Description", visible: true, width:("Description".length -2)*10, alignment: "left" },
    // { Header: "Logo", accessor: "logo", visible: true, width:("".length -2)*10 },
    { Header: "Created By", accessor: "created_by", visible: true, width:("Created By".length -2)*10, alignment: "left" },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width:("Created Date".length -2)*10,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Change here
      }
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width:("Created Time".length -2)*10,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',

          hour12: true,
        });
      }
    },
    { Header: "Updated By", accessor: "changed_by", visible: true, width:("Updated By".length -2)*10, canResize: true, alignment: "left" },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width:("Updated Date".length -2)*10,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Change here
      }
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width:("Updated Time".length -2)*10,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',

          hour12: true,
        });
      }
    },
  ];

  const handleDoubleClick = (id) => {
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );

    console.log("Check user permissions with iconId", hasPermission);

    if (hasPermission) {
    navigate(`/bank-view/${id}`, { state: { mode: 'view' } });
    console.log("Navigated to user view screen.");
  } else {
    console.log(
      "No permission with permissionsId === 3 and matching iconId, navigation skipped."
    );
  }
  };

  const [columnSettings, setColumnSettings] = useState(columns);
 useEffect(() => {
    fetchBanks(currentPage, searchQuery);
  }, [pageSize, currentPage]);

  // Filter banks based on search query
  useEffect(() => {
    const filtered = banks.filter(
      (bank) =>
        bank.bankCode.toLowerCase().includes(searchQuery.toLowerCase()))


    setFilteredBanks(filtered);
  }, [searchQuery, banks]);

  // Fetch images for filtered banks
  useEffect(() => {
    filteredBanks.forEach((bank) => {
      if (bank.logo && !logo[bank.id]) {
        fetchImage(bank.id, bank.logo);
      }
    });
  }, [filteredBanks]);


  const fetchBanks = async (page = 1, searchQuery = "") => {
    setLoading(true);
    try {
      const response = await BankServices.getAllbankwithPage(
        pageSize,
        page,
        searchQuery
      );
      let { bank, totalCount } = response.data.data;
      if (!bank) {
        bank = [];
      } else if (!Array.isArray(bank)) {
        bank = [bank];
      }
      setFilteredBanks(bank);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching StateProvince data", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced fetch function (to avoid too many API calls)
  const debouncedFetchbanks = useCallback(
    debounce((page, query) => {
      fetchBanks(page, query);
    }, 500), // 500ms debounce delay
    []
  );

  // UseEffect to trigger API call on query or page change
  useEffect(() => {
    debouncedFetchbanks(currentPage, searchQuery);
    // Cleanup debounce on unmount
    return () => debouncedFetchbanks.cancel();
  }, [currentPage, searchQuery, debouncedFetchbanks]);
  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchBanks(1, searchQuery); // Fetch data with updated page size
  };
  const fetchImage = async (id, image) => {
    const encodedCredentials = localStorage.getItem("encodedCredentials");
    const imageUrl = `${process.env.REACT_APP_BASE_URL}/uploads/${image}`;

    try {
      const response = await axios.get(imageUrl, {
        headers: {
          Authorization: `Bearer ${encodedCredentials}`,
        },
        responseType: "blob",
      });

      const imageBlob = URL.createObjectURL(response.data);

      // Store the image in state using the bank id as the key
      setLogo((prev) => ({ ...prev, [id]: imageBlob }));
    } catch (error) {
      console.error("Error fetching the image:", error);
    }
  };
  const modifiedColumns = columnSettings.filter(col => col.visible).map(col => ({
    ...col,
    width: col.width
  }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this bank?"
    );
    if (confirmed) {
      setLoading(true);
      try {
        await BankServices.deleteBank(id);
        toast("success", "Bank deleted successfully");
        fetchBanks();
      } catch (error) {
        console.error("Error deleting bank:", error);
        toast("error", "Failed to delete bank");
      } finally {
        setLoading(false);
      }
    }
  };
  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredBanks.map((bank) => ({
      "Bank Code": bank.bankCode,
      "Description": bank.Description,
      "Created By": bank.created_by,
      "Created Date": bank.creationDate
        ? new Date(bank.creationDate).toLocaleDateString()
        : "",
      "Created Time": bank.creationTime
        ? new Date(bank.creationTime).toLocaleTimeString()
        : "",
      "Updated By": bank.changed_by,
      "Updated Date": bank.changedDate
        ? new Date(bank.changedDate).toLocaleDateString()
        : "",
      "Updated Time": bank.changedTime
        ? new Date(bank.changedTime).toLocaleTimeString()
        : "",
    }));

    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Banks");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Banks_Data.xlsx");
  };
  const extraButtons = [
    {
      icon: IoMdCreate,
      onClick: () => {
        handleNavigate(`/bank-add/${bankId}`);
      },
      title: "Edit Bank",
      disabled: selectedRows.length === 0,
    },
  ];
  const handleReload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchBanks();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };
  // const existingButtons = PrimaryActionsConfig.screen5.map((button) => ({
  //   ...button,
  //   title:
  //     button.icon === IoAddCircleOutline
  //       ? "Add Bank"
  //       : button.icon === MdOutlineFileDownload
  //         ? "Download"
  //         : button.icon === IoSettingsOutline
  //           ? "Settings"
  //           : button.icon === IoMdRefresh
  //             ? "Refresh"
  //             : button.title || "", // If a title already exists, use it, otherwise empty
  //   onClick:
  //     button.icon === IoAddCircleOutline
  //       ? () => handleNavigate("/bank-add")
  //       : button.icon === MdOutlineFileDownload
  //         ? handleDownload
  //         : button.icon === IoSettingsOutline
  //           ? handleSettingsButtonClick
  //           : button.icon === IoMdRefresh
  //             ? handleReload
  //             : button.onClick || (() => { }),
  // }));

   const existingButtons = PrimaryActionsConfig.screen5.map((button) => {
      let onClick;
      let title;
  
      if (button.icon === IoMdRefresh) {
        onClick = handleReload; // Reference the reload function, not invoking it
        title = "Refresh";
      } else if (button.icon === IoAddCircleOutline) {
          onClick = () => handleNavigate("/bank-add");
        title = "Add Bank";
      } else if (button.icon === IoSettingsOutline) {
        onClick = handleSettingsButtonClick;
        title = "Table Settings";
      }  else if (button.icon === MdOutlineFileUpload) {
         onClick = () => handleNavigate("/2/master-data/5/bank-upload");
        title = "Upload File";
      } else if (button.icon === MdOutlineFileDownload) {
        onClick = handleDownload;
        title = "Download File";
      } else {
        onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
        title = button.title || ""; // Use existing title or default to an empty string
      }
  
      return {
        ...button,
        onClick, // Reference the function without invoking it
        title, // Add the title property to the button configuration
      };
    });

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );
  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleCheckboxChange = (id) => {
    setBankId(id); // Set the selected bank ID for further actions
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = filteredBanks.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  if (loading) return <div></div>;

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {
      console.log("Navigating to /2/master-data");
      navigate('/2/master-data');
    } else if (crumb.title === 'Bank') {
      console.log("Navigating to /country");
      navigate('/2/master-data/5/bank');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>

      <Header title={"Primary Data > Global Data > Bank"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/2/master-data"}
      />
      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">

            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Bank"
            />


            <PrimaryActions
             icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              isExtraButtonsVisible={showExtraIcons}
              selectIds={selectedRows}
            />
          </div>
          <div className="flex items-center mr-4">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
            onShowAll={handleShowAll}
          />
          
        </div>
        </div>

        <div>

        </div>

        <ReTable
          data={filteredBanks}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
    </div>

  );
}
