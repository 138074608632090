import RestService from "./RestServices";

const CurrencyServicess = {
  getAllCalendar: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching Currency data:", error);
      return { error: true, message: "Error fetching Currency data" };
    }
  },
  deleteCurrency:(id)=>{
return RestService.DeleteData("/delete-currency",id);
  },
  getCurrencyById: (id) => {
    return RestService.GetByIdData("/get-currency-id", id);
  },
  addCurrency: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding Currency:", error);
      return { error: true, message: "Error adding Currency" };
    }
  },
};

export default CurrencyServicess;
