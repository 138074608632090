import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReTable from '../../../../../../components/Table';
import UserService from '../../../../../../rest-services/UserServices';
import AdminActions from '../../../../Admin/Components/AdminActions';
import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import CustomeLoading from '../../../../../../components/CustomeLoading';

export default function BuGroupUploadScreen() {
const navigate = useNavigate();
    const { iconId } = useParams();
    const location = useLocation();
  const [processedData, setProcessedData] = useState([]);
  const [loading, setLoading] = useState(false);
      const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs

const { tableData, displayOption, uploadOption, taskMonitor  } = location.state || {}; // Retrieve tableData from state

    
const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
        navigate("/2/master-data");
      } else if (crumb.title === "Bu Group") {
        // navigate("/2/master-data/8/bugroup");
      } else if (crumb.title === "Primary Data") {
        navigate("/2/master-data");
      } else if (crumb.path) {
        navigate(crumb.path);
      }
      else if (crumb.title === 'Upload') {
        // console.log("Navigating to /User");
        navigate('/2/master-data/8/bugroup-upload');
      }
    };
  
    
        const columns = [   
            
            { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -2)*10, alignment: "left" },
            { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -2)*10, alignment: "left" },
            // { 
            //     Header: 'Update', 
            //     accessor: (row) => (uploadOption === '3' && row.successfulrecords ? 'Yes' : 'No'), 
            //     visible: true, 
            //     width: ("Min".length -2)*10, 
            //     alignment: "left" 
            // },
            {
              Header: 'Update',
              accessor: (row) => {
                if (row.successfulrecords && uploadOption === '3') {
                  return 'Yes';
                } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                  return 'No';
                } else {
                  return <span style={{ color: 'red' }}>No</span>;
                }
              },
              visible: true,
              width: ("Update".length -2)*10,
              alignment: 'left',
            },
            { Header: 'BU Group', accessor: 'BuGroup', visible: true, width: ("BU Group".length -2)*10, alignment: "left" },
            { Header: 'BUGroup Description', accessor: 'BuGroupDescription', visible: true, width: ("BUGroup Description".length -2)*10, alignment: "left" },
         
      ];

      
              const existingButtons = AdminActionsConfig.screen7.map((button) => {
                let onClick;
                let title;
            
            
                if (button.icon === MdOutlineFileDownload) {
                //   onClick = handleDownload;
                  title = "Download File";
                }
            
                else {
                  onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
                  title = button.title || ""; // Use existing title or default to an empty string
                }
            
                return {
                  ...button,
                  onClick, // Reference the function without invoking it
                  title,   // Add the title property to the button configuration
                };
              });

      const extraButtons = [

      ];
    
          const actionButtons = existingButtons.concat(
            extraButtons.map((button) => ({
              ...button,
              disabled: selectedRows.length === 0, // Disable button if no users are selected
              onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
            }))
          );

          
  const [columnSettings, setColumnSettings] = useState(columns);

      
  
    const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));

    const getExistingBugroupIds = async (tableData) => {
      try {
        console.log("BugroupIds", tableData);
        
        const validRows = tableData.filter((row, index) => isRowValid(row, index));
    
        // Make API call to fetch existing user IDs
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-BuGroup-duplicate/BugroupId`,
          { validRows }
        );
        console.log("errored records user id's : Response data", response.data);
        const existingBuGroupIds = response.data; // Extract existing user IDs from API response
    
        // Remove duplicates based on API response
        const withoutDuplicateUserIds = await filterExistingUserIds(validRows, existingBuGroupIds);
    
        // Further filter the results to ensure only valid rows are returned
        // const validWithoutDuplicateUserIds = withoutDuplicateUserIds.filter((row) =>
        //   isRowValid(row)
        // );
    
        console.log("successfuland processed Records data:", withoutDuplicateUserIds);
    
        return withoutDuplicateUserIds;
    
      } catch (error) {
        console.error("Error fetching existing user IDs:", error);
        return [];
      }
    };
    
    
    const filterExistingUserIds = async (tableData, existingBuGroupIds) => {
      // const existingBuGroupSet = new Set(existingBuGroupIds.map(item => item.BU_Group));
      return tableData.filter((row) => existingBuGroupIds.includes(row.BuGroup));
    //   return tableData.filter((row) => !existingBuGroupSet.has(row.BuGroup));
    };

    const isRowValid = (row, index) => {
      // Common validations
      const isActionValid = row.Action && ["A", "a"].includes(row.Action) && String(row.Action).length === 1;
      const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
      
      let errors = [];
      
      if (!isActionValid) errors.push("Invalid Action");
      if (!isSLNoValid) errors.push("Invalid SLNo");
    
      // Action "A" or "a" specific validations
      if (row.Action === "A" || row.Action === "a") {
        const isBuGroupValid = row.BuGroup && String(row.BuGroup).length <= 10;
        const isBuGroupDescriptionValid = row.BuGroupDescription && String(row.BuGroupDescription).length <= 40;
       
       
        if (!isBuGroupValid) errors.push("Invalid A BuGroup Code");
        if (!isBuGroupDescriptionValid) errors.push("Invalid A BuGroup Description");

        if (errors.length > 0) {
          console.log(`Row ${index + 1} is invalid:`, errors);
      }
        return (
        isBuGroupValid  &&
        isBuGroupDescriptionValid
        );
      }
    
      // Action "E" or "e" specific validations
      if (row.Action === "E" || row.Action === "e") {
          const isBuGroupValid = row.BuGroup && String(row.BuGroup).length <= 10;

        const isBuGroupDescriptionValid = row.BuGroupDescription ? String(row.BuGroupDescription).length <= 40 : true;
       
        if (!isBuGroupValid) errors.push("Invalid E BuGroup");
        if (!isBuGroupDescriptionValid) errors.push("Invalid E BuGroup Description");

        if (errors.length > 0) {
          console.log(`Row ${index + 1} is invalid:`, errors);
      }

        return (
          isBuGroupValid &&
          isBuGroupDescriptionValid   );
      }
    
      
      if (errors.length > 0) {
        console.log(`Rows data ${index + 1} is invalid:`, errors);
    }

      // Default case if no matching Action
      return (
        isActionValid &&
        isSLNoValid
      );
    
    };

      
  // const successfulRecords = tableData.filter((row) => isRowValid(row));
  // console.log("successfulRecords data filtered",successfulRecords);

  const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
  
    // Decide which data to display based on displayOption and uploadOption
    let dataToDisplay;
    if (displayOption === "1") {
      console.log("Display option is 1");
      const existingUsers = await getExistingBugroupIds(tableData); // Display filteredData if displayOption is "1"      

       const existingUserIds = new Set(existingUsers.map(user => user.BuGroup));

       // Filter out the currentDataToDisplay row from tableData to get other 6 rows
       dataToDisplay = tableData.filter(user => !existingUserIds.has(user.BuGroup));
   
       console.log("Filtered remaining data excluding currentDataToDisplay:", dataToDisplay);

       
    } else if (displayOption === "2") {
      console.log("Display option is 2");
      dataToDisplay = tableData; // Display tableData if displayOption is "2"
    } else if (uploadOption === "3") {
      console.log("upload option is 3");
      dataToDisplay = await getExistingBugroupIds(tableData); // Display successfulRecords if uploadOption is "3"
      // dataToDisplay = await fetchAndFilterSuccessfulRecords(tableData); // Successful records for uploadOption "3"
  
    }
    else {
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
  
  console.log("dataToDisplay data is",dataToDisplay);
  return dataToDisplay;
  };

  
// Example usage
(async () => {
    const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
    
    if (!Array.isArray(dataToDisplay)) {
      console.error("getDataToDisplay did not return an array:", dataToDisplay);
      return;
    }
  
    console.log("Final dataToDisplay data is", dataToDisplay);
  
    // Call processData only when dataToDisplay is defined and valid
    await processData(dataToDisplay);
  
  })();

  const isExistingUserId = async (BuGroup) => {
    try {
      console.log("exisitng user id",BuGroup);
      const data = { BUGroupId: BuGroup };
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-BuGroup-duplicates`,
        data);
      console.log("existingUserId details:", response);
      console.log("existingUserId details:", response.BUGroupData);
  
      // return response && response.BUGroupData !== null; // Return true if BUGroup exists, false otherwise
      return response?.BUGroupData ? response : false;
    } catch (error) {
      console.error("Error checking existing BUGroup:", BuGroup, error);
      return false;
    }
  };
  
  
  async function checkDuplicateUserIdsFromDB(BUGroupIds) {
    try {
      const data = { BUGroupIds };
      // console.log("UserIds duplicated DATA IS",data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-BUGroups-duplicates`,
        data
      );
  
      // console.log("API Response for response details:", response);
      console.log("existingUserId API Response for duplicates details:", response.duplicates);
      return response.duplicates; // Return an array of duplicate userIds
    } catch (error) {
      console.error("Error checking duplicate userIds from DB:", error);
      return [];
    }
  }
  
  const processData = async (data) => {
    // Extract all userIds from dataToDisplay
    
    // if (!Array.isArray(data)) {
    //   console.error("processData received invalid data:", data);
    //   return []; // Return an empty array to prevent further errors
    // }
    console.log("processData received  data:", data);
  
    const BUGroupIds = data.map(row => row.BuGroup).filter(Boolean);
    console.log("Extracted Bu:", BUGroupIds);
  
    const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(BUGroupIds);
  
  
     // Preprocess table data to add conditional rendering for userId and username
    //  return data.map((row, _, allRows) => {
      return Promise.all(data.map(async (row, _, allRows) => {
      // const rowInvalid = isRowInvalid(row);
      // Define the max allowed characters for each column (this can come from your column definitions)
    
        // Check for duplicate userId in dataToDisplay
    const isDuplicateUserId = allRows.filter(r => r.BuGroup == row.BuGroup).length > 1;
  console.log("Duplicate user id is ",isDuplicateUserId);
  
   // Check for duplicate userId in the database
   const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.BuGroup);
  
   const userExists = await isExistingUserId(row.BuGroup);
   console.log(`BuUser ${row.BuGroup} exists:`, userExists);
   // Check for errors (red text) and apply highlight based on that
  
  const hasNotError = [
    row.SLNo,
    row.Action,
    row.BuGroup,
    row.BuGroupDescription,
   
  ].every(field => {
    let isValid = true;
    let reason = "";
    
    if (field === row.SLNo) {
      if (!field) { isValid = false; reason = "SLNo should not be blank."; }
      else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
      else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
    }
    
    else if (field === row.Action) {
      if (!field) { isValid = false; reason = "Action should not be blank."; }
      else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
    }
  
    if (!row.BuGroup || String(row.BuGroup).trim() === "") {
      if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
        isValid = false;
        reason = "BuGroup should not be empty.";
    }
    } else if (field === row.BuGroup) {
      if (String(field).length > 10) { 
        isValid = false; reason = "BuGroup Code exceeds max length of 10."; 
      }
      else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
        isValid = false; reason = "BuGroup Code does not exist.";
      } 
      else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
        isValid = false; reason = "BuGroup Code is duplicate.";
      }
    }
  
    // else if (field === row.BuGroup) {
    //   if (!field) { isValid = false; reason = "BUGroup should not be blank."; }
    //   else if (String(field).length > 10) { isValid = false; reason = "BUGroup exceeds max length of 10."; }
    //   else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
    //     isValid = false; reason = "User ID is duplicate.";
    //   }else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
    //     isValid = false; reason = "User ID does not exist.";
    //   }
    // }
    
    
    if (!row.BuGroupDescription || String(row.BuGroupDescription).trim() === "") {
      if (row.Action === "A" || row.Action === "a") {
        isValid = false;
        reason = "BuGroupDescription should not be empty.";
    }
    } else if (field === row.BuGroupDescription) {
        if (row.Action === "E" || row.Action === "e") {
            if (!field.trim()) {
                isValid = true;
                reason = "BuGroupDescription should be blank for Edit action.";
            } else if (String(field).trim().length > 40) {
                isValid = false;
                reason = "BuGroupDescription exceeds max length of 40.";
            }
        } else if (row.Action === "A" || row.Action === "a") {
            if (!field.trim()) {
                isValid = false;
                reason = "BuGroupDescription should not be blank for Add action.";
            } else if (String(field).trim().length > 30) {
                isValid = false;
                reason = "BuGroupDescription exceeds max length of 30.";
            }
        }
    }
  
    // else if (field === row.BuGroupDescription) {
    //   if (row.Action === "E" || row.Action === "e") {
    //     if (!field) { isValid = true; reason = "BuGroupDescription should be blank for Edit action."; }
    //   } else if (row.Action === "A" || row.Action === "a") {
    //     if (!field) { isValid = false; reason = "BuGroupDescription should not be blank for Add action."; }
    //   }
    //   if (String(field).length > 40) { isValid = false; reason = "BuGroupDescription exceeds max length of 40."; }
    // }
  
    if (!isValid) {
      console.log(`Validation Failed: ${reason}`);
    } else {
      console.log(`Validation Passed: ${field}`);
    }
    
    return isValid;
  });
  
  console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        isDuplicateUserId,
        isDuplicateInDB,
        userExists,
  
        Action: row.Action ? (
          String(row.Action).length === 1 ? (
            ["A", "a", "E", "e"].includes(row.Action) ? (
              row.Action // Valid Action ("A", "E", "a", "e")
            ) : (
              <span style={{ color: "red" }}>
                {row.Action} (Only "A", "E" are allowed)
              </span>
            )
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Max. allowed character is 1)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>Shouldn't be blank</span>
        ),
  
        SLNo: row.SLNo ? (
            isNaN(row.SLNo) ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Mismatched Data Type)
              </span>
            ) : String(row.SLNo).length > 5 ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Max. allowed character is 5)
              </span>
            ) : (
              row.SLNo // Valid SLNo
            )
          ) : (
            <span style={{ color: "red"}}>Shouldn't be blank</span>
          ),
          
  
          // BuGroup: row.BuGroup ? (
          //   String(row.BuGroup).length > 10 ? (
          //     <span style={{ color: "red" }}>
          //       {row.BuGroup} (Max. allowed character is 10)
          //     </span>
          //   ) : row.Action === "A" || row.Action === "a" ? (
          //     // When Action is "A", show duplicate message if user ID is a duplicate
          //     (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
          //     (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
          //       <span style={{ color: "red" }}>
          //         {row.BuGroup} (BuGroup Duplicate exists)
          //       </span>
          //     ) : (
          //       String(row.BuGroup) // Otherwise, show userId normally
          //     )
          //   ) : row.Action === "E" || row.Action === "e" ? (
          //     // When Action is "E", show userId without the duplicate message
          //     (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
          //     (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
          //       <span>
          //         {row.BuGroup} 
          //       </span>
          //     ) : (
          //       // String(row.userId) // Otherwise, show userId normally
          //       <span style={{ color: "red" }}>
          //       {row.BuGroup} (BU Group not exists)
          //     </span>
          //     )
          //   ) : (
          //     String(row.BuGroup) // Default case, show userId normally
          //   )
          // ) : (
          //   <span style={{ color: "red" }}>
          //     Shouldn't be blank
          //   </span>
          // ),
  
          // BuGroup: row.BuGroup ? (
          //   String(row.BuGroup).length > 10 ? (
          //     <span style={{ color: "red" }}>
          //       {row.BuGroup} (Max. allowed character is 10)
          //     </span>
          //   ) : typeof userExists === "string" && userExists.toLowerCase() === row.BuGroup.toLowerCase() ? ( // Safe check
          //     <span>{row.BuGroup}</span> 
          //   ) : (
          //     <span style={{ color: "red" }}>
          //       {row.BuGroup} (BuGroup Code not exists.)
          //     </span>
          //   )
          // ) : (
          //   row.Action?.toLowerCase() === "a" || row.Action?.toLowerCase() === "e" ? (
          //     <span style={{ color: "red" }}>
          //       Shouldn't be blank
          //     </span>
          //   ) : null
          // ),
          
              
  
          BuGroup: row.BuGroup ? (
            String(row.BuGroup).length > 10 ? (
              <span style={{ color: "red" }}>
                {row.BuGroup} (Max. allowed character is 10)
              </span>
            ) : row.Action === "A" || row.Action === "a" ? (
              // When Action is "A", show duplicate message if user ID is a duplicate
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span style={{ color: "red" }}>
                  {row.BuGroup} (BuGroup Code Duplicate exists)
                </span>
              ) : (
                String(row.BuGroup) // Otherwise, show userId normally
              )
            ) : row.Action === "E" || row.Action === "e" ? (
              // When Action is "E", show userId without the duplicate message
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span>
                  {row.BuGroup} 
                </span>
              ) : (
                // String(row.BuGroup) // Otherwise, show BuGroup normally
                <span style={{ color: "red" }}>
                {row.BuGroup} (BuGroup Code not exists)
              </span>
              )
            ) : (
              String(row.BuGroup) // Default case, show userId normally
            )
          ) : (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ),
  
  
          BuGroupDescription: row.BuGroupDescription ? (
            String(row.BuGroupDescription).length > 40 ? (
              <span style={{ color: "red" }}>
                {row.BuGroupDescription} (Max. allowed character is 40)
              </span>
            ) : (
              String(row.BuGroupDescription) // Ensure Username is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),
  
      
        };  
    }));
  }
  

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
        const processed = await processData(dataToDisplay);
        setProcessedData(processed);
        setLoading(false);
      };
    
      fetchData();
    }, []);

  return (
    <div>
          {/* <h2>User Upload Display option{displayOption} </h2>
      <h2>User Upload upload option{uploadOption} </h2>  */}
      {/* <h2>User Upload taskMonitor option{taskMonitor} </h2> */}
   

      <Header
        title={`Primary Data > Company & Bank Data > Bu Group > Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/2/master-data/8/bugroup-upload"
      />

{loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

<AdminActions
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              />


<ReTable
          data={processedData}
        //   data={tableData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        //   onRowDoubleClick={handleDoubleClick}
        />

    </div>
  )
}
