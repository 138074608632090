// import React from "react";
// import { AutoSizer, Table, Column } from "react-virtualized";
// import "react-virtualized/styles.css"; // Import default styles

// const VirtualizedReTable = ({ data, columns, onRowDoubleClick }) => {
//     return (
//         <div style={{ width: "100%", height: "600px" }}> {/* Set a fixed height */}
//             <AutoSizer>
//                 {({ width, height }) => (
//                     <Table
//                         width={width}
//                         height={height}
//                         headerHeight={40}
//                         rowHeight={50}
//                         rowCount={data.length}
//                         rowGetter={({ index }) => data[index]}
//                         onRowDoubleClick={({ rowData }) => onRowDoubleClick(rowData)}
//                     >
//                         {columns.map((col, index) => (
//                             <Column
//                                 key={index}
//                                 label={col.header}
//                                 dataKey={col.accessor}
//                                 width={col.width || 150} // Default column width
//                             />
//                         ))}
//                     </Table>
//                 )}
//             </AutoSizer>
//         </div>
//     );
// };

// export default VirtualizedReTable;
import React, { useState } from "react";
import { AutoSizer, Table, Column } from "react-virtualized";
import "react-virtualized/styles.css"; // Import default styles

const VirtualizedReTable = ({ data, columns, onRowDoubleClick }) => {
    const [selectedRows, setSelectedRows] = useState(new Set());

    const handleRowClick = ({ index }) => {
        const newSelectedRows = new Set(selectedRows);
        if (newSelectedRows.has(index)) {
            newSelectedRows.delete(index);
        } else {
            newSelectedRows.add(index);
        }
        setSelectedRows(newSelectedRows);
    };

    const rowClassName = ({ index }) => {
        return selectedRows.has(index) ? "selected-row" : "";
    };

    return (
        <div style={{ width: "100%", height: "600px" }}> {/* Set a fixed height */}
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        width={width}
                        height={height}
                        headerHeight={40}
                        rowHeight={50}
                        rowCount={data.length}
                        rowGetter={({ index }) => data[index]}
                        rowClassName={rowClassName}
                        onRowClick={handleRowClick}
                        onRowDoubleClick={({ rowData }) => onRowDoubleClick(rowData)}
                    >
                        {/* Checkbox Column */}
                        <Column
                            label="Select"
                            dataKey="checkbox"
                            width={50}
                            cellRenderer={({ rowIndex }) => (
                                <input
                                    type="checkbox"
                                    checked={selectedRows.has(rowIndex)}
                                    onChange={() => handleRowClick({ index: rowIndex })}
                                />
                            )}
                        />
                        
                        {/* Data Columns */}
                        {columns.map((col, index) => (
                            <Column
                                key={index}
                                label={col.header}
                                dataKey={col.accessor}
                                width={col.width || 150} // Default column width
                                flexGrow={col.flexGrow || 1} // Allow columns to expand
                                headerRenderer={({ label }) => (
                                    <div style={{ fontWeight: "bold", textAlign: "center" }}>
                                        {label}
                                    </div>
                                )}
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
        </div>
    );
};

export default VirtualizedReTable;
