import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../../../components/Header';
import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import AdminActions from '../../../../Admin/Components/AdminActions';
import ReTable from '../../../../../../components/Table';
import UserService from '../../../../../../rest-services/UserServices';
import XLSX from 'xlsx-js-style';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import PrimaryActions from '../../../Components/ActionButton';
import SourceServices from '../../../../../../rest-services/SourceServices';

export default function CurrencyRateUploadTableScreen() {
  const location = useLocation();
  const { iconId } = useParams();
  const navigate = useNavigate();
  const { tableData, displayOption, uploadOption } = location.state || {}; // Retrieve tableData from state
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [processedData, setProcessedData] = useState([]);
  const [assignee, setAssignee] = useState({ id: '', username: '' });
  const [alert, setAlert] = useState(null);
const [loading, setLoading] = useState(false);

  console.log('table data received from prop in currency rate is', tableData);
  console.log('displayOption data received from prop in currency rate is', displayOption);
  console.log('uploadOption data received from prop in currency rate is', uploadOption);

  useEffect(() => {
    fetchAssignee();
  }, []);

  const handleBreadcrumbClick = (crumb) => {
    console.log('Breadcrumb clicked:', crumb.title);
    if (crumb.title === 'Market Data') {
      navigate('/2/master-data');
    } else if (crumb.title === 'Currency Rate') {
      navigate('/2/master-data/14/currency-rate');
    } else if (crumb.title === 'Primary Data') {
      navigate('/2/master-data');
    } else if (crumb.path) {
      navigate(crumb.path);
    } else if (crumb.title === 'Upload Rate') {
      navigate('/2/master-data/14/currencyrate-upload');
    }
  };

  const columns = [
    {
      Header: 'Update',
      accessor: (row) => {
        if (row.successfulrecords && uploadOption === '3') {
          return 'Yes';
        } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
          return 'No';
        } else {
          return <span style={{ color: 'red' }}>No</span>;
        }
      },
      visible: true,
      width: ("Update".length -2)*10,
      alignment: 'left',
    },
        { Header: 'Rate Type', accessor: 'RateType', visible: true, width: ("Rate Type".length -2)*10, alignment: 'left' },
    { Header: 'Rate Date', accessor: 'RateDate', visible: true, width: ("Rate Date".length -2)*10, alignment: 'left' },
    { Header: 'Source Type', accessor: 'SourceType', visible: true, width: ("Source Type".length -2)*10, alignment: 'left' },
    { Header: 'SourceCode', accessor: 'SourceCode', visible: true, width: ("SourceCode".length -2)*10, alignment: 'left' },
    { Header: 'Bid', accessor: 'Bid', visible: true, width: ("Bid".length -2)*10, alignment: 'left' },
    { Header: 'Ask', accessor: 'Ask', visible: true, width: ("Ask".length -2)*10, alignment: 'left' },
  ];

  const getExistingUserIds = async (tableData) => {
    try {
      // Filter rows based on basic validation
      let validRows = tableData.filter((row, index) => isRowValid(row, index));
      console.log("Initial valid rows:", validRows);
  
      // Check for existing SourceCode in the database
      const validatedRows = await Promise.all(
        validRows.map(async (row) => {
          const sourceCodeExistsResponse = await SourceServices.checkDuplicateSourcecode(
            `${process.env.REACT_APP_BASE_URL}/checkexistingsourcecode`,
            { sourceCode: row.SourceCode }
          );
  
          const sourceCodeExists = sourceCodeExistsResponse?.exists;
  
          // If SourceCode does not exist, mark the row as invalid
          if (!sourceCodeExists) {
            console.log(`SourceCode ${row.SourceCode} does not exist in the database.`);
            return null; // Return null for invalid rows
          }
  
          return row; // Return the row if it is valid
        })
      );
  
      // Filter out null values (invalid rows)
      validRows = validatedRows.filter(row => row !== null);
      console.log("Final valid rows after SourceCode validation:", validRows);
  
      return validRows;
    } catch (error) {
      console.error("Error fetching existing user IDs:", error);
      return [];
    }
  };

  // Displaying data based on options
  const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
  
    // Decide which data to display based on displayOption and uploadOption
    let dataToDisplay;
    if (displayOption === "1") {
      console.log("Display option is 1");
      const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"
  
      console.log("table data",tableData);
      console.log("existing users is ",existingUsers);

      const existingUserKeys = new Set(existingUsers.map(user => 
        `${user.RateType}-${user.RateDate}-${user.SourceType}-${user.SourceCode}`
      ));
      
      dataToDisplay = tableData.filter(user => 
        !existingUserKeys.has(`${user.RateType}-${user.RateDate}-${user.SourceType}-${user.SourceCode}`)
      );
      
      console.log("Filtered remaining data excluding existingUsers:", dataToDisplay);
      
    } else if (displayOption === "2") {
      console.log("Display option is 2");
      dataToDisplay = tableData; // Display tableData if displayOption is "2"
    } else if (uploadOption === "3") {
      console.log("upload option is 3");
      dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
    } 
    else {
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
  
  console.log("dataToDisplay data is",dataToDisplay);
  return dataToDisplay;
  };


  // Example usage
  // (async () => {
  //   const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
    
  //   if (!Array.isArray(dataToDisplay)) {
  //     console.error("getDataToDisplay did not return an array:", dataToDisplay);
  //     return;
  //   }
  
  //   console.log("Final dataToDisplay data is", dataToDisplay);
  
  //   // Call processData only when dataToDisplay is defined and valid
  //   await processData(dataToDisplay);
  
  // })();

  const handleDownload = () => {
    if (processedData.length === 0) {
      setAlert({
        severity: 'warning',
        message: 'No data available to export!',
      });
      return;
    }
  
    const extractText = (cellValue) => {
      if (Array.isArray(cellValue)) {
        return cellValue.join(', '); // Convert array to string
      } else if (typeof cellValue === 'string') {
        return cellValue;
      } else if (React.isValidElement(cellValue)) {
        return cellValue.props.children;
      }
      return cellValue !== null && cellValue !== undefined ? cellValue.toString() : '';
    };
  
    // Define headers
    const headers = [
      'RateType',
      'RateDate',
      'SourceType',
      'SourceCode',
      'Bid',
      'Ask',
    ];
  
    // Format data for download
    const formattedUsers = processedData.map((user) => ({
      'RateType': extractText(user.RateType),
      'RateDate': extractText(user.RateDate),
      'SourceType': extractText(user.SourceType),
      'SourceCode': extractText(user.SourceCode),
      'Bid': extractText(user.Bid || ''),
      'Ask': extractText(user.Ask || ''),
    }));
  
    // Combine headers and data
    const rowsToDownload = [headers, ...formattedUsers.map((row) => Object.values(row))];
  
    // Create a new worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(rowsToDownload);
  
    // Define border style
    const border = {
      top: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
    };
  
    // Apply styles to cells
    Object.keys(formattedUsers[0]).forEach((col, colIdx) => {
      formattedUsers.forEach((row, rowIdx) => {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx }); // +1 to skip header row
        const cellValue = row[col];
  
        // Create a new cell object
        const cell = { v: cellValue };
  
        // Apply styles based on conditions
        if (typeof cellValue === 'string' && cellValue.includes("Shouldn't be blank")) {
          cell.s = { font: { color: { rgb: 'FF0000' } } };
        } else if (Array.isArray(row[col])) {
          cell.s = { font: { color: { rgb: 'FF0000' } } };
        }
  
        // Add border to the cell
        cell.s = cell.s || {};
        cell.s.border = border;
  
        // Add the cell to the worksheet
        worksheet[cellAddress] = cell;
      });
    });
  
    // Style the header row
    const headerRowIndex = 0; // Header is the first row
    headers.forEach((header, colIdx) => {
      const cellAddress = XLSX.utils.encode_cell({ r: headerRowIndex, c: colIdx });
      worksheet[cellAddress] = {
        v: header,
        s: {
          font: { bold: true, color: { rgb: 'FFFFFF' } },
          fill: { fgColor: { rgb: '4F81BD' } }, // Blue background for headers
          alignment: { horizontal: 'center' },
          border: border,
        },
      };
    });
  
    // AutoFit column width
    worksheet['!cols'] = headers.map((col) => {
      const maxLength = Math.max(...formattedUsers.map((row) => extractText(row[col]).length), col.length);
      return { wch: maxLength + 2 }; // Add extra space
    });
  
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'CurrencyRates');
  
    // Write the workbook to a file
    XLSX.writeFile(workbook, 'CurrencyRates_Upload.xlsx');
  };

  const existingButtons = AdminActionsConfig.screen7.map((button) => {
    let onClick;
    let title;

    if (button.icon === MdOutlineFileDownload) {
      onClick = handleDownload;
      title = 'Download File';
    } else {
      onClick = button.onClick || (() => {}); // Provide a default no-op function if onClick is undefined
      title = button.title || ''; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title, // Add the title property to the button configuration
    };
  });

  const extraButtons = [];

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem('userDetail');
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || '',
          userId: userDetail.userId || '',
          clientId: userDetail.clientId || '',
          assigner: userDetail.username || '',
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log('Assignee ID:', assigneeData.id);
        console.log('Assigner:', assigneeData.assigner);
        console.log('Assigner clientId:', assigneeData.clientId);
        console.log('Assigner userId:', assigneeData.userId);
      } else {
        setAlert({
          severity: 'error',
          message: 'No user detail found in session storage.',
        });
      }
    } catch (error) {
      setAlert({
        severity: 'error',
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const isRowValid = (row, index) => {
    console.log("Row Object in isRowValid:", row);
  
    // Validate required fields
    const isRateTypeValid = row.RateType && ['FX', 'IN'].includes(row['RateType']);
    const isRateDateValid = row.RateDate && String(row.RateDate).length <= 10;
    const isSourceValid = row.SourceType && String(row.SourceType).length <= 10;
    const isSourceCodeValid = row.SourceCode && String(row.SourceCode).length <= 20;
  
    // Validate Bid and Ask fields
    const isBidValid = row.Bid ? !isNaN(row.Bid) && String(row.Bid).length <= 20 : true;
    const isAskValid = row.Ask ? !isNaN(row.Ask) && String(row.Ask).length <= 20 : true;
  
    // Collect errors
    let errors = [];
    if (!isRateTypeValid) errors.push("Invalid RateType");
    if (!isRateDateValid) errors.push("Invalid RateDate");
    if (!isSourceValid) errors.push("Invalid Source");
    if (!isSourceCodeValid) errors.push("Invalid Source Code");
    if (!isBidValid) errors.push("Invalid Bid (must be numeric)");
    if (!isAskValid) errors.push("Invalid Ask (must be numeric)");
  
    if (errors.length > 0) {
      console.log(`Row ${index + 1} is invalid:`, errors);
    }
  
    return (
      isRateTypeValid &&
      isRateDateValid &&
      isSourceValid &&
      isSourceCodeValid &&
      isBidValid &&
      isAskValid
    );
  };

  const processData = async (data) => {
    if (!Array.isArray(data)) {
      console.error("processData received invalid data:", data);
      return []; // Return an empty array to prevent further errors
    }
    console.log("processData received data:", data);
  
    return Promise.all(data.map(async (row) => {
      const isDuplicateUserId = data.filter(
        (r) =>
          r.RateType === row.RateType &&
          r.RateDate === row.RateDate &&
          r.SourceType === row.SourceType &&
          r.SourceCode === row.SourceCode
      ).length > 1;
      console.log("Duplicate user id is ", isDuplicateUserId);
  
      // Check if the sourceCode exists in the database
      const sourceCodeExistsResponse = await SourceServices.checkDuplicateSourcecode(
        `${process.env.REACT_APP_BASE_URL}/checkexistingsourcecode`,
        { sourceCode: row.SourceCode }
      );
  
      const sourceCodeExists = sourceCodeExistsResponse?.exists;
  
      // Validate all fields
      const hasNotError = [
        row.RateType,
        row.RateDate,
        row.SourceType,
        row.SourceCode,
        row.Bid,
        row.Ask,
      ].every((field, index) => {
        let isValid = true;
        let reason = "";
  
        switch (index) {
          case 0: // RateType
            if (!field) {
              isValid = false;
              reason = "RateType should not be blank.";
            } else if (!['FX', 'IN'].includes(field)) {
              isValid = false;
              reason = "RateType should be either 'FX' or 'IN'.";
            }
            break;
  
          case 1: // RateDate
            if (!field) {
              isValid = false;
              reason = "RateDate should not be blank.";
            } else if (!String(field)) {
              isValid = false;
              reason = "RateDate should be a valid date.";
            }
            break;
  
          case 2: // SourceType
            if (!field) {
              isValid = false;
              reason = "SourceType should not be blank.";
            } else if (String(field).length > 10) {
              isValid = false;
              reason = "SourceType exceeds max length of 10.";
            }
            break;
  
          case 3: // SourceCode
            if (!field) {
              isValid = false;
              reason = "SourceCode should not be blank.";
            } else if (field && isDuplicateUserId) {
              isValid = false;
              reason = "Duplicate entry.";
            } else if (String(field).length > 20) {
              isValid = false;
              reason = "SourceCode exceeds max length of 20.";
            } else if (!sourceCodeExists) {
              isValid = false;
              reason = "SourceCode does not exist in the database.";
            }
            break;
  
          case 4: // Bid
            if (field && (isNaN(field) || String(field).length > 20)) {
              isValid = false;
              reason = "Bid must be numeric and within 20 characters.";
            }
            break;
  
          case 5: // Ask
            if (field && (isNaN(field) || String(field).length > 20)) {
              isValid = false;
              reason = "Ask must be numeric and within 20 characters.";
            }
            break;
  
          default:
            break;
        }
  
        if (!isValid) {
          console.log(`Validation Failed: ${reason}`);
        } else {
          console.log(`Validation Passed: ${field}`);
        }
  
        return isValid;
      });
  
      console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        'RateType': row['RateType'] ? (
          ['FX', 'IN'].includes(row['RateType']) ? (
            row['RateType']
          ) : (
            <span style={{ color: 'red' }}>{row['RateType']} (Invalid Rate Type)</span>
          )
        ) : (
          <span style={{ color: 'red' }}> Shouldn't be blank</span>
        ),
  
        'RateDate': row['RateDate'] ? (
          String(row['RateDate']) ? (
            row['RateDate']
          ) : (
            <span style={{ color: 'red' }}>{row['RateDate']} (Invalid Rate Date)</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Shouldn't be blank</span>
        ),
  
        'SourceType': row['SourceType'] ? (
          String(row['SourceType']).length <= 10 ? (
            row['SourceType']
          ) : (
            <span style={{ color: 'red' }}>{row['SourceType']} (Max. allowed character is 10)</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Shouldn't be blank</span>
        ),
  
        'SourceCode': row.SourceCode ? (
          String(row.SourceCode).length <= 20 ? (
            isDuplicateUserId ? ( // Check for duplication
              <span style={{ color: 'red' }}>{row.SourceCode}, Duplicate SourceCode</span>
            ) : (
              sourceCodeExists ? ( // Check if sourceCode exists in the database
                row.SourceCode // No error, return the SourceCode
              ) : (
                <span style={{ color: 'red' }}>{row.SourceCode}, SourceCode does not exist</span>
              )
            )
          ) : (
            <span style={{ color: 'red' }}>{row.SourceCode}, Max. allowed character is 20</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Shouldn't be blank</span>
        ),
  
        'Bid': row.Bid ? (
          !isNaN(row.Bid) && String(row.Bid).length <= 20 ? (
            row.Bid
          ) : (
            <span style={{ color: 'red' }}>{row.Bid} (Invalid Bid)</span>
          )
        ) : (
          <span style={{ color: 'red' }}></span>
        ),
  
        'Ask': row.Ask ? (
          !isNaN(row.Ask) && String(row.Ask).length <= 20 ? (
            row.Ask
          ) : (
            <span style={{ color: 'red' }}>{row.Ask} (Invalid Ask)</span>
          )
        ) : (
          <span style={{ color: 'red' }}></span>
        ),
      };
    }));
  };
   

  // useEffect(() => {
      
    
  //     fetchData();
  //   }, []);

  //   const fetchData = async () => {
  //     setLoading(true);
  //     const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
  //     console.log("data to display is",dataToDisplay);

  //     const processed = await processData(dataToDisplay);
  //     console.log("Processed data is ",processed);

  //     setProcessedData(processed);
  //     setLoading(false);
  //   };

  useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
        console.log("dataTo display is get",dataToDisplay);
        
        const processed = await processData(dataToDisplay);
        setProcessedData(processed);
        setLoading(false);
      };
    
      fetchData();
    }, []);

  return (
    <div>
      <Header
        title={`Primary Data > Market Data > Currency Rate > Upload Rate > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute="/2/master-data/14/currencyrate-upload"
      />
 {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

      <PrimaryActions icons={iconId} buttons={actionButtons} selectedCount={selectedRows.length} selectIds={selectedRows} />

      <ReTable
        data={processedData}
        columns={modifiedColumns} // Use modified columns based on visibility
        selectedRows={[]}
        onSelectRow={() => {}}
        onSelectAll={() => {}}
        onRowDoubleClick={() => {}}
        showCheckbox={false}
      />
    </div>
  );
}