import React, { useEffect, useState } from 'react'
import Header from '../../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../../../../../components/footer';
import { IoAddCircleOutline } from 'react-icons/io5';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdTime, IoMdTrash } from 'react-icons/io';
import { MdLocalPrintshop, MdOutlineFileDownload, MdOutlineFileUpload, MdOutlineLock } from 'react-icons/md';
import { TbDeviceDesktopCancel } from 'react-icons/tb';
import { LuKeyRound } from 'react-icons/lu';
import UserService from '../../../../../../../rest-services/UserServices';
import { useToast } from '../../../../../../../components/toast/toast';
import ButtonGroup from '../../../../../../../components/FooterButton';

export default function RolesAdd() {
    const navigate = useNavigate();
    const { roleId } = useParams();
    const location = useLocation();
    const { mode } = location.state || {}; // Get the mode from the state
    const isViewMode = mode === 'view';
    const isCopyAddMode = mode === 'add';
    const isEditMode = mode === 'Edit';
    const { toast } = useToast();
    const [roleName, setRoleName] = useState("");
    const [roleID, setRoleID] = useState("");
    const [roleDesc, setRoleDesc] = useState("");
    const [isDuplicateRoleName, setIsDuplicateRoleName] = useState('');
    const [errors, setErrors] = useState({});
    const[roles,setRoles] = useState([]);
  const [alert, setAlert] = useState(null);
  const [Icons, setIcons] = useState([]);
  const [permissionIcons, setPermissionIcons] = useState([]);
  const [selectedIcons, setSelectedIcons] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [userType, setUserType] = useState("");
  const [userTypes, setUserTypes] = useState([]);

  const [expandedIcon, setExpandedIcon] = useState(null);

    const [isAdminChecked, setIsAdminChecked] = useState(false);
    const [isRolesPermissionChecked, setIsRolesPermissionChecked] = useState(false);
    const [permissions, setPermissions] = useState({
        create: false,
        download: false,
        copy: false,
        change: false,
        view: false,
        approve: false,
        reject: false,
        auditTrail: false,
        upload: false,
        inactive: false,
        triggerOTP: false,
        lock:false,
        delete:false,
      });

      const toggleAccordion = (iconId) => {
        setExpandedIcon((prev) => (prev === iconId ? null : iconId));
      };

      const handleAdminCheckboxChange = () => {
        setIsAdminChecked(!isAdminChecked);
      };
      const handleRolesPermissionCheckboxChange = () => {
        setIsRolesPermissionChecked(!isRolesPermissionChecked);
      };
// Bread crumbs navigation
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'User & SOD') {
          navigate('/14/admin');
        } else if (crumb.title === 'Admin') {
          navigate('/14/admin');
        }
        //  else if (crumb.title === 'Permissions') {
        //     navigate('/14/admin/108/roles-and-permission');
        //   } 
          else if (crumb.title === 'Roles') {
            navigate('/14/admin/108/roles-and-permission/roles');
          } 
        else if (crumb.path) {
          navigate(crumb.path); // Navigate to the specified path in the breadcrumb
        }
      };

      const permissionValues = {
        create: 1,
        download: 2,
        copy: 3,
        change: 4,
        view: 5,
        approve: 6,
        reject: 7,
        auditTrail: 8,
        upload: 9,
        inactive: 10,
        triggerOTP:11,
        lock:12,
        delete: 13,
      };

      const handleCheckboxChange = (permission) => {
        setPermissions({ ...permissions, [permission]: !permissions[permission] });
      };

  
      useEffect(() => {
        fetchIconsData();
        fetchGetPermissionsData();
        fetchUserTypes();
      }, []);

      
      useEffect(() => {
        if (roleId) {
          getDataForSelectedRole();
        }
      }, [roleId]);

      // Retrieve data from the database based on the role ID and Set the role name, description and permissions.
     
  const getDataForSelectedRole = async () => {
    try {
      const data = { roleId: roleId };
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-role-by-roleId`,
        data
      );

      const responseData = response.data;
console.log("role id data",responseData);
      if (responseData) {
        if (!isCopyAddMode) {
          setRoleName(responseData.name);
          setRoleID(responseData.roleId);
          setRoleDesc(responseData.description);
          setUserType(responseData.userType);
        }

        const initialIcons = {};
        const initialPermissions = {};

        responseData.rolesPermissionsTables.forEach((rolePermission) => {
          const { iconId, permissionsId } = rolePermission;

          initialIcons[iconId] = true;
          if (!initialPermissions[iconId]) initialPermissions[iconId] = {};
          initialPermissions[iconId][permissionsId] = true;
        });

        setSelectedIcons(initialIcons);
        setSelectedPermissions(initialPermissions);
      }

      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
    }
  };


  
      const fetchUserTypes = async () => {
        try {
          const response = await UserService.getUsers(
            `${process.env.REACT_APP_BASE_URL}/get-user-type`
            
          );
          setUserTypes(response.data.data);
        } catch (error) {
          console.error("Error fetching user types:", error);
        }
      };

      const fetchIconsData = async () => {
        try {
          const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-icons-data`);      
          console.log("Response from server fetching Icons data:", response);
          const responseData = response.data.data;
          setIcons(responseData);
    
    
          console.log("double check users",response.data.data);
          if (responseData.error) {
            console.error("Error fetching Users data:", responseData.message);
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
        } catch (error) {
          console.error("Error fetching Users data", error);
          setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
        }
      };

    const fetchGetPermissionsData = async () => {
      try {
        const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-permissions`);      
        console.log("Response from server fetching permissions data:", response);
        const responseData = response.data.data;
        setPermissionIcons(responseData);
  
  
        console.log("double check users permissons",responseData);
        if (responseData.error) {
          console.error("Error fetching Users data:", responseData.message);
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
      } catch (error) {
        console.error("Error fetching Users data", error);
        setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
      }
    };

    const handleIconChange = (iconId) => {
      setSelectedIcons((prev) => ({
        ...prev,
        [iconId]: !prev[iconId],
      }));
    };


      const checkDuplicateRoleID = async (name) => {
              try {
                const response = await UserService.checkDuplicateRoleName(
                  `${process.env.REACT_APP_BASE_URL}/check-role-ID-duplicate`,
                  { roleID: name }  // Sending roleName to backend
                );
                
                console.log("Response from server:", response);
            
                if (response.message === "Role ID already exists") {
                  // Check if it's a duplicate
                console.log("Response from internal server:", response);
            setErrors({ roleID: ' ' });

            toast("warning", "Role ID already exists.");
                
                }
                console.log("Response from external server:", response);

              } catch (error) {
                console.error("Error checking duplicate role ID:", error);
                // Handle potential errors when making the API call
                setErrors(prev => ({ ...prev, roleName: "Error checking role ID" }));
              }
      };

      const checkDuplicateRoleName = async (name) => {
        try {
          const response = await UserService.checkDuplicateRoleName(
            `${process.env.REACT_APP_BASE_URL}/check-role-duplicate`,
            { roleName: name }  // Sending roleName to backend
          );
          
          console.log("Response from server:", response);
      
          if (response.message === "Role name already exists") {
            // Check if it's a duplicate
          console.log("Response from internal server:", response);
      // setErrors({ roleName: ' ' });

      //  toast("warning", "Role name already exists.");
           
          }
          console.log("Response from external server:", response);

        } catch (error) {
          console.error("Error checking duplicate role name:", error);
          // Handle potential errors when making the API call
          setErrors(prev => ({ ...prev, roleName: "Error checking role name" }));
        }
      };

      const handleRoleIdChange = (e) => {
        const name = e.target.value;
        setRoleID(name);
        
        // Clear previous errors when typing
        setErrors(prev => ({ ...prev, roleID: "" }));
      
        // Check for duplicate role names when typing
        if (name.trim() !== "") {
          checkDuplicateRoleID(name);
        }
      };

      const handleRoleNameChange = (e) => {
        const name = e.target.value;
        setRoleName(name);
        
        // Clear previous errors when typing
        setErrors(prev => ({ ...prev, roleName: "" }));
      
        // Check for duplicate role names when typing
        if (name.trim() !== "") {
          checkDuplicateRoleName(name);
        }
      };
     
      const validateFields = () => {
        const newErrors = {};
        if (!roleID) newErrors.roleID = " ";
        if (!roleName) newErrors.roleName = " ";
        if (!userType) newErrors.userType = " ";

        setErrors(newErrors);
  
        // Show toast only if there are errors
        if (Object.keys(newErrors).length > 0) {
          toast("warning", "Missing required fields.");
          return false;
        }

        return true;
      };


    const handleSaveContinueDetails = async () => {
      if (!validateFields()) return;
  
      if(isEditMode)
        {
  
            try {
              const roleData = {
                roleId,
                roleName,
                roleDesc,
                userType: userType === "" ? null : parseInt(userType, 10) // Convert userType to integer or set to null
              };
            const roleResponse = await UserService.saveAllUserDetails(
              `${process.env.REACT_APP_BASE_URL}/update-role`, roleData
          );
          if (roleResponse.status === 200) {
            console.log("role data stored now storing permissions");
                const roleId = roleResponse.roleId; // Assuming the role ID is returned in the response
              console.log("stroed role id",roleId);
                // Prepare the selected permissions to include the new roleId
                const permissionsData = [];
                Object.keys(selectedIcons).forEach((iconId) => {
                  if (selectedIcons[iconId]) {
                    Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                      if (selectedPermissions[iconId][permissionId]) {
                        permissionsData.push({
                          role_id: roleId,
                          icon_id: parseInt(iconId),
                          permission_id: parseInt(permissionId),
                        });
                      }
                    });
                  }
                });
                // Now submit the permissions with the correct role_id
                const permissionsResponse = await UserService.saveAllRolesDetails(
                    `${process.env.REACT_APP_BASE_URL}/update-role-permissions`, { permissions: permissionsData }
                );
    
                if (permissionsResponse.status === 200) {
                    // alert('Permissions and role saved successfully');
                  toast("success", "Roles Updated successfully");
  
                  navigate(`/14/admin/108/roles-and-permission/roles-edit/${roleId}`);
                } else {
                    // alert('Failed to save permissions');
                    toast("warning", "Failed to save permissions");
  
                }
  
          }
          else {
            toast("warning", "Failed to save role details");
          }  
            } catch (error) {
              console.error('Error submitting permissions and role details:', error);
              // alert('Error submitting permissions and role details');
              toast("warning", "Error submitting permissions and role details");
    
          }
  
        }
        else{

          try {
            // First, submit the role details
            const roleData = {
                roleID,
                roleName,
                roleDesc,
                userType: userType === "" ? null : parseInt(userType, 10) // Convert userType to integer or set to null
              };
    
            const roleResponse = await UserService.saveAllUserDetails(
                `${process.env.REACT_APP_BASE_URL}/insert-role`, roleData
            );
            console.log("check role status response",roleResponse);
            if (roleResponse.status === 200) {
              console.log("role data stored now storing permissions");
                const roleId = roleResponse.roleId; // Assuming the role ID is returned in the response
              console.log("stroed role id",roleId);
                // Prepare the selected permissions to include the new roleId
                const permissionsData = [];
                Object.keys(selectedIcons).forEach((iconId) => {
                  if (selectedIcons[iconId]) {
                    Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                      if (selectedPermissions[iconId][permissionId]) {
                        permissionsData.push({
                          role_id: roleId,
                          icon_id: parseInt(iconId),
                          permission_id: parseInt(permissionId),
                        });
                      }
                    });
                  }
                });
                // Now submit the permissions with the correct role_id
                const permissionsResponse = await UserService.saveAllRolesDetails(
                    `${process.env.REACT_APP_BASE_URL}/insert-role-permissions`, { permissions: permissionsData }
                );
    
                if (permissionsResponse.status === 200) {
                    // alert('Permissions and role saved successfully');
                  toast("success", "Roles saved successfully");
  
                    navigate('/14/admin/108/roles-and-permission/roles/add');
                } else {
                    // alert('Failed to save permissions');
                    toast("warning", "Failed to save permissions");
  
                }
            } else {
              toast("warning", "Failed to save role details");
  
                // alert('Failed to save role details');
            }
        } catch (error) {
            console.error('Error submitting permissions and role details:', error);
            // alert('Error submitting permissions and role details');
            toast("warning", "Error submitting permissions and role details");
  
        }

        }
     
  };
  
  const handlePermissionChange = (iconId, permissionId) => {
    setSelectedPermissions((prev) => ({
      ...prev,
      [iconId]: {
        ...prev[iconId],
        [permissionId]: !prev[iconId]?.[permissionId],
      },
    }));
  };

    const handleSubmit = async () => {
      if (!validateFields()) return;
        console.log("Role id is ",roleId);
       if(isEditMode)
      {

          try {
            const roleData = {
              roleId,
              roleName,
              roleDesc,
              userType: userType === "" ? null : parseInt(userType, 10) // Convert userType to integer or set to null

          };
          const roleResponse = await UserService.saveAllUserDetails(
            `${process.env.REACT_APP_BASE_URL}/update-role`, roleData
        );
        if (roleResponse.status === 200) {
          console.log("role data stored now storing permissions");
              const roleId = roleResponse.roleId; // Assuming the role ID is returned in the response
            console.log("stroed role id",roleId);
              // Prepare the selected permissions to include the new roleId
              const permissionsData = [];
              Object.keys(selectedIcons).forEach((iconId) => {
                if (selectedIcons[iconId]) {
                  Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                    if (selectedPermissions[iconId][permissionId]) {
                      permissionsData.push({
                        role_id: roleId,
                        icon_id: parseInt(iconId),
                        permission_id: parseInt(permissionId),
                      });
                    }
                  });
                }
              });
              // Now submit the permissions with the correct role_id
              const permissionsResponse = await UserService.saveAllRolesDetails(
                  `${process.env.REACT_APP_BASE_URL}/update-role-permissions`, { permissions: permissionsData }
              );
  
              if (permissionsResponse.status === 200) {
                  // alert('Permissions and role saved successfully');
                toast("success", "Roles Updated successfully");

                  navigate('/14/admin/108/roles-and-permission/roles');
              } else {
                  // alert('Failed to save permissions');
                  toast("warning", "Failed to save permissions");
              }
        }
        else {
          toast("warning", "Failed to save role details");
        }
          } catch (error) {
            console.error('Error submitting permissions and role details:', error);
            // alert('Error submitting permissions and role details');
            toast("warning", "Error submitting permissions and role details");
          }

      }
      else{
        try {
          // First, submit the role details
          const roleData = {
              roleID,
              roleName,
              roleDesc,
              userType: userType === "" ? null : parseInt(userType, 10) // Convert userType to integer or set to null
          };
  console.log("store req data",roleData);
          const roleResponse = await UserService.saveAllUserDetails(
              `${process.env.REACT_APP_BASE_URL}/insert-role`, roleData
          );
          console.log("check role status response",roleResponse);
          if (roleResponse.status === 200) {

            console.log("role data stored now storing permissions");
              const roleId = roleResponse.roleId; // Assuming the role ID is returned in the response
            console.log("stroed role id",roleId);
              // Prepare the selected permissions to include the new roleId
             const permissionsData = [];
              Object.keys(selectedIcons).forEach((iconId) => {
                if (selectedIcons[iconId]) {
                  Object.keys(selectedPermissions[iconId] || {}).forEach((permissionId) => {
                    if (selectedPermissions[iconId][permissionId]) {
                      permissionsData.push({
                        role_id: roleId,
                        icon_id: parseInt(iconId),
                        permission_id: parseInt(permissionId),
                      });
                    }
                  });
                }
              });
console.log("Storing permissions data",permissionsData);
              // Submit permissions
              const permissionsResponse = await UserService.saveAllRolesDetails(
                `${process.env.REACT_APP_BASE_URL}/insert-role-permissions`, { permissions: permissionsData }
              );

              if (permissionsResponse.status === 200) {
                  // alert('Permissions and role saved successfully');
                toast("success", "Roles saved successfully");

                  navigate('/14/admin/108/roles-and-permission/roles');
              } else {
                  // alert('Failed to save permissions');
                  toast("warning", "Failed to save permissions");

              }
          } else {
            toast("warning", "Failed to save role details");
          }
      } catch (error) {
          console.error('Error submitting permissions and role details:', error);
          // alert('Error submitting permissions and role details');
          toast("warning", "Error submitting permissions and role details");

      }


      }
     
  };
  
  const iconMapping = {
    "Add": <IoAddCircleOutline size={18} />,  // Adjust size as needed
    "Edit": <IoMdCreate size={18} />,
    "View": <svg className="ml-2 text-customGray" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.3em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"></path></svg>,
    "Copy": <IoMdCopy size={18} />,
    "Audit Trail": <IoMdTime size={18} />,
    "Approve": <IoMdCheckmark size={18} />,
    "Reject": <IoMdClose size={18} />,
    "Trigger OTP": <LuKeyRound size={18} />,
    "Upload": <MdOutlineFileUpload size={18} />,
    "Download": <MdOutlineFileDownload size={18} />,
    "Inactive": <TbDeviceDesktopCancel size={18} />,
    "Lock": <MdOutlineLock size={18} />,
    "Delete": <IoMdTrash size={18} />,
    // Add other icons with size adjustments
  };
  
  


  return (
    <div>
      <Header
        title={`Admin > User & SOD > Roles > ${isViewMode ? 'View' : isEditMode ? 'Edit' : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />

<div className="p-6">
        <div className="max-w-8xl">
          <div className="grid grid-cols-3 gap-x-8 gap-y-8 mb-6">
            <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-customGray">
                Role ID<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={roleID}
                // onChange={(e) => setRoleID(e.target.value)}
                onChange={handleRoleIdChange}
                className={`border p-1.5 rounded w-96 h-8 text-customGray focus:outline-none ml-4 ${
                  errors.roleID ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="Enter role ID"
                required
                disabled={isViewMode || isEditMode}
              />
            </label>

            <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-customGray">
                Role name<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={roleName}
                onChange={handleRoleNameChange}
                className={`border p-1.5 rounded w-96 h-8 text-customGray focus:outline-none ml-4 ${
                  errors.roleName ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="Enter role name"
                required
                disabled={isViewMode}
              />
            </label>


            <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-customGray">
                User Type<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-4">:</span>
            

<select
    value={userType}
    onChange={(e) => {
      const value = e.target.value;
      setUserType(value == "0" ? null : value); // Set to null if value is "0"
    }}
    disabled={isViewMode}
    className={`border p-1 rounded w-96 h-8 text-customGray focus:outline-none ml-4
      ${
        errors.userType ? "border-red-500" : "border-gray-300"
      }
      ${isViewMode ? 'bg-gray-100':''}
      `}
  >
    <option value="">Select user type</option>
    {userTypes.map((type) => (
      <option key={type.id} value={type.id}>
        {type.name}
      </option>
    ))}
  </select>

            </label>
          </div>

<div className="mb-6 bg-white rounded-lg shadow-sm">
  {Icons.map((icon) => (
    <div key={icon.id} className="text-sm font-medium">
      <div className="bg-blue-50 p-4 flex items-center mb-2 cursor-pointer"
       onClick={() => toggleAccordion(icon.id)}
       >
        <span className="mr-2 text-customGray">
          {icon.name} - ({icon.mainMenu.name} - {icon.subMenu.name})
        </span>
        <input
          type="checkbox"
          className="h-4 w-4 text-blue-600 border-gray-300 rounded"
          disabled={isViewMode}
          checked={selectedIcons[icon.id] || false}
          onChange={() => handleIconChange(icon.id)}
        />
      </div>

      {expandedIcon === icon.id && (

      <div className="p-4 grid grid-cols-4 gap-4 items-center">
        {permissionIcons.map((permission) => (
         (icon.id === 108 && [8, 9, 12].includes(permission.id)) ? null : ( // Exclude specific permission IDs
            <div key={`${icon.id}-${permission.id}`} className="flex items-center space-x-2">
              <input
                type="checkbox"
                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                disabled={isViewMode || !selectedIcons[icon.id]}
                checked={selectedPermissions[icon.id]?.[permission.id] || false}
                onChange={() => handlePermissionChange(icon.id, permission.id)}
              />
              <div className="flex items-center">
                <span className="text-sm text-customGray">{permission.name}</span>
                <span className="ml-2 text-customGray">{iconMapping[permission.name] || null}</span>
              </div>
            </div>
          )
        ))}
      </div>

      )}

    </div>
  ))}
</div>


        </div>
      </div>
    <Footer> 
    {(!isViewMode && (

      <ButtonGroup
        onSave={handleSubmit}
        onSaveContinue={handleSaveContinueDetails}
      /> 
      ))}

    </Footer>

    </div>
  )
}
