import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange, totalCount }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="flex justify-between items-center mt-4">
      <div>
        Showing {currentPage} of {totalPages} pages, Total records: {totalCount}
      </div>
      <div className="flex items-center">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="mx-1 px-3 py-1 border rounded bg-white"
        >
          &lt;
        </button>
        <span className="mx-2">
          {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="mx-1 px-3 py-1 border rounded bg-white"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Pagination;
