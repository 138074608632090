import RestService from "./RestServices";

const BankServices = {
  getAllBank: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error('Error fetching Bank data:', error);
      return { error: true, message: "Error fetching Bank data" };
    }
  },
  getBankById: (id) => {
    return RestService.GetByIdData(`/get-bank-id`, id);
  },
  deleteBank:(id)=>{
    return RestService.DeleteData("/delete-bank",id);
      },
  addBank: async (path, data) => {
    try {
      console.log('Sending data:', data);
      const response = await RestService.CreateData(path, data);
      console.log('Response from Server:', response);
      return response;
    } catch (error) {
      console.error('Error adding Bank:', error);
      return { error: true, message: "Error adding Bank" };
    }
  }
};



export default BankServices;
