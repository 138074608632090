import React, { useEffect, useState } from 'react'
import Header from '../../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminActions from '../../../../Components/AdminActions';
import { AdminActionsConfig } from '../../../../Components/ActionConfig';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdRefresh, IoMdTime, IoMdTrash } from 'react-icons/io';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import ReTable from '../../../../../../../components/Table';
import UserService from '../../../../../../../rest-services/UserServices';
import { useToast } from '../../../../../../../components/toast/toast';
import ColumnSettingsPopup from '../../../../../Masterdata/Components/ColumnSettingPopup';
import { MdOutlineFileDownload } from 'react-icons/md';
import { TbFlag3Filled } from 'react-icons/tb';

export default function RolesScreen() {
    const navigate = useNavigate();
  const { iconId } = useParams(); 

const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state

    const handleNavigate = (path) => {
        navigate(path);
      };
    const columns = [
        
      
  {
    Header: (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <TbFlag3Filled style={{ color: "#ffff" }} />
      </div>
    ),
    accessor: "status",
    visible: true,
    width: 100,
    Cell: ({ row }) => {
      const { status } = row.original;
      const colorMap = {
        0: "gray",
        1: "yellow",
        2: "orange",
        3: "#29CB00",
        4: "red"
      };
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TbFlag3Filled style={{ color: colorMap[status] || "black" }} />
        </div>
      );
    }
  },
        { Header: 'Role ID', accessor: 'roleId', visible: true, width: 200 },
        { Header: 'Role Name', accessor: 'name', visible: true, width: 200 },
        { Header: 'User Type', accessor: 'userType', visible: true, width: 200, Cell: ({ row }) => { const user = row.original; return user.userType == 1 ? 'User' : user.userType == 2 ? 'Super User' : user.userType == 3 ? 'Approvals' : user.userType == 4 ? 'Admin' : user.userType == 5 ? 'Consultant' : user.userType == 6 ? 'Support' : user.userType == 7 ? 'System' : ' '; } },
        { Header: 'Permissions', accessor: 'permissionsCount', visible: true, width: 200 },
      ];

    const [columnSettings, setColumnSettings] = useState(columns);
    const [selectedRows, setSelectedRows] = useState([]);
    const [Icons, setIcons] = useState([]);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const[roles,setRoles] = useState([]);
    const [alert, setAlert] = useState(null);
  const [assignee, setAssignee] = useState({ id: "", username: "" });

    const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);

  
  useEffect(() => {
    fetchRolesDetails();
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        
        setAssignee(assigneeData);
        
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };
  
    
  const handleCancelClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-rejected-roles`,
          { roleIds: selectedRows[0], createdBy: assignee.assigner, }  // Pass selectedUserIds in the body
        );
  
        if (response.status === 200) {
          toast("error", "Roles successfully Rejected");
          // navigate("/userscreen");
          await fetchRolesDetails();
        }
        else if (response.status === 400){
          toast("warning", "User is unable to Reject.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Reject.");
    }
  };

  const handleApproveClick = async () => {
    if (selectedRows.length > 0) {
      try {
        console.log("selected roles is",selectedRows[0]);
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-roles-approved`,
          { roleIds: selectedRows[0],  createdBy: assignee.assigner, }  // Pass selectedUserIds in the body
        );
        console.log("Response status:", response.status);

        if (response.status === 200) {
          toast("success", "Role successfully Approved");
          // navigate("/userscreen");
          await fetchRolesDetails();
          // if (refreshTable.current) {
          //   refreshTable.current();  // Call the refresh function to reload the user data
          // }
        }  
        else if (response.status === 400) {
          toast("warning", "User is unable to approve.");
         } 
         else if (response.status === 550) {
          toast("warning", "User Email is Not Sent.");
         } 
         else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Approve.");
    }
  };


  const handleEditClick = () => {
    if (selectedRows.length === 1) {
      console.log("entered user id");
      // console.log()
      const selectedRole = roles.find((role) => role.id === selectedRows[0]);
      console.log('get roles', selectedRole);
      console.log('get role id', selectedRows[0]);
      // console.log('single user id', selectedUser);
  
      if (selectedRows[0]) {

        navigate(`/14/admin/108/roles-and-permission/roles-edit/${selectedRows[0]}`,  { state: { mode: 'Edit' } } );
      } else {
        toast("warning", "Selected user not found.");
      }
    } else {
      toast("warning", "Please select exactly one user to edit.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };

  const handleAddcopyClick = () => {
    if (selectedRows.length === 1) {
      console.log('check user ids',selectedRows);
      const selectedRole = roles.find((role) => role.id === selectedRows[0]);
      console.log('user id', selectedRole);
  
      if (selectedRows[0]) {
        navigate(`/14/admin/108/roles-and-permission/roles-copy-add/${selectedRows[0]}`, { state: { mode: 'add' } });
      } else {
        toast("warning", "Selected user not found.");
      }
    } else {
      toast("warning", "Please select exactly one user to Copy.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };
  

  const handleDeleteClick = async () => {
    if (selectedRows.length === 1) {
      try {
console.log("selected role is for delete ", selectedRows);
console.log("selected role is for delete single row", selectedRows[0]);
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/delete-role`,
          { roleId: selectedRows[0], createdBy: assignee.assigner}  // Pass selectedUserIds in the body
        );
  
        if (response.status === 200) {
          toast("error", "Role successfully Deleted.");

          // Remove deleted users from the selectedRows
          setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchRolesDetails();

        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Delete.");
    }
  };



  const fetchRolesDetails = async () => {
    try {
      const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-roles`);      
      console.log("Response from server fetching roles:", response);
      const responseData = response.data.data;
      console.log('set roles details',responseData);
      setRoles(responseData);
      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({ severity: "error", message: `Error fetching roles data, kindly check the connection ${error.message}` });
    }
  };

  const reload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchRolesDetails();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };

    const extraButtons = [
      
            {
              icon: IoMdCheckmark, // Pass the component itself
              onClick: handleApproveClick,
              title: "Approve",
              disabled: selectedRows.length === 0,
              
            },
            {
              icon: IoMdClose,
              onClick: handleCancelClick,
              title: "Reject",
              disabled: selectedRows.length === 0,
            },
      
      {
        icon: IoMdCreate,
        onClick: handleEditClick,
        title: "Edit",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdCopy,
        onClick: handleAddcopyClick,
        title: "Copy",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdTime,
        // onClick: handleAuditTrail,
        title: "Audit Trail",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdTrash,
        onClick: handleDeleteClick,
        title: "Delete",
        disabled: selectedRows.length === 0,
      },
     
    ];

    const handleSettingsButtonClick = () => {
      setIsSettingsPopupOpen(true);
    };

    const existingButtons = AdminActionsConfig.screen4.map((button) => {
        let onClick;
        let title;
      
       
        if (button.icon === IoMdRefresh) {
          onClick = reload; // Reference the reload function, not invoking it
          title = "Refresh";
        }
        else if (button.icon === IoAddCircleOutline) {
            onClick = () => handleNavigate("/14/admin/108/roles-and-permission/roles/add");
            title = "Add Roles";
        }
        else if(button.icon === IoSettingsOutline){
          onClick = handleSettingsButtonClick;
          title ="Table Settings";
        }
        else if (button.icon === MdOutlineFileDownload) {
          // onClick = handleDownload;
          title = "Download File";
        } 

           else {
          onClick = button.onClick || (() => {}); // Provide a default no-op function if onClick is undefined
          title = button.title || ""; // Use existing title or default to an empty string
        }
      
        return {
          ...button,
          onClick, // Reference the function without invoking it
          title,   // Add the title property to the button configuration
        };
      });

      
    const modifiedColumns = columnSettings
    .filter(col => col.visible)
    .map(col => ({ ...col, width: col.width }));

    const handleCheckboxChange = (id) => {
      console.log('checked user id',id);
      const updatedSelectedRows = selectedRows.includes(id)
        ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
        : [...selectedRows, id]; // Add to selection if not selected
  
      setSelectedRows(updatedSelectedRows);
      console.log('check box:', updatedSelectedRows);
    
    };


    const handleSelectAll = () => {
      handleSelectAllChange();
    };

    const handleSelectAllChange = () => {
      if (isAllSelected) {
        setSelectedRows([]); // Deselect all
      } else {
        const allIds = roles.map((item) => item.userId); // Select all IDs
        setSelectedRows(allIds);
      }
      setIsAllSelected(!isAllSelected); // Toggle select all state
    };


    const handleSelectRow = (id) => {
        handleCheckboxChange(id);
      };

     

    const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, // Disable button if no users are selected
          onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
        }))
      );

      


  

  const handleDoubleClick = (id) => {
    console.log("check roles id",id);
    navigate(`/14/admin/108/roles-and-permission/roles-view/${id}`, { state: { mode: 'view' } });  };


    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'User & SOD') {
          navigate('/14/admin');
        } else if (crumb.title === 'Admin') {
          navigate('/14/admin');
        } 
        // else if (crumb.title === 'Permission') {
        //     navigate('/14/admin/108/roles-and-permission');
        //   } 
        else if (crumb.path) {
          navigate(crumb.path); // Navigate to the specified path in the breadcrumb
        }
      };

  return (
    <div>
      <Header 
        title="Admin > User & SOD > Roles"
        onBreadcrumbClick={handleBreadcrumbClick}
      />
            <div className="flex justify-start ml-5 mt-5 items-center">
                <span
                    className={`text-xl font-md cursor-pointer text-[#0B5299]`}
                >
                Available Roles
                </span>
            </div>

            <div className="flex justify-between mt-5">
                <AdminActions
  icons={iconId}
  buttons={actionButtons} 
                // extraButtons={extraButtons}
                selectedCount={selectedRows.length} 
                selectUserIds={selectedRows}/>
            </div>

            <div>
                {loading && <div className="loading-spinner">Loading...</div>}

                {/* Table Component */}
                <ReTable
                data={roles}
                columns={modifiedColumns} // Use modified columns based on visibility
                selectedRows={selectedRows}
                onSelectRow={handleSelectRow}
                onSelectAll={handleSelectAll}
                onRowDoubleClick={handleDoubleClick}
                customWidth="80%" // Set 50% width
                rowKey="roleId"  // Pass userId as the key for row selection
                
                />
            </div>
            {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}

    </div>
  )
}
