import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CiViewTable } from "react-icons/ci";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { LuUpload } from "react-icons/lu";
import { TbAtom2 } from "react-icons/tb";
import { TbDatabaseExport } from "react-icons/tb";
import { RiFolderShield2Line } from "react-icons/ri";
import { GoArrowSwitch } from "react-icons/go";
import { SlGlobe } from "react-icons/sl";
import { HiOutlineWrench } from "react-icons/hi2";
import { BiJoystickButton } from "react-icons/bi";
import MainHeader from "../../../components/MainHeader";
import Dashboard from "../../../components/Dashboard";
import { useSearch } from "../../../context/SearchContext";
import { useToast } from "../../../components/toast/toast";
import UserService from "../../../rest-services/UserServices";

export default function BankStatement() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const { highlightInfo } = useSearch();
  const [highlightedCard, setHighlightedCard] = useState(null);
  const navigate = useNavigate();
    const [assignee, setAssignee] = useState({ id: "", username: "" });
const { toast } = useToast();

const [hasPermissionForStatementControls,SethasPermissionForStatementControls]= useState("");
const [hasPermissionForStatementFormat,SethasPermissionForStatementFormat]= useState("");
const [hasPermissionForStatementIdentifier,SethasPermissionForStatementIdentifier]= useState("");
const [hasPermissionForMT940Uploads,SethasPermissionForMT940Uploads]= useState("");
const [hasPermissionForBTC,SethasPermissionForBTC]= useState("");
const [hasPermissionForStatementAnalyser,SethasPermissionForStatementAnalyser]= useState("");
const [hasPermissionForMt940Balances,SethasPermissionForMt940Balances]= useState("");
const [hasPermissionForTransferToBS,SethasPermissionForTransferToBS]= useState("");
const [hasPermissionForManageStatementControls,SethasPermissionForManageStatementControls]= useState("");
const [hasPermissionForUploads,SethasPermissionForUploads]= useState("");
  // Handle search highlighting
  useEffect(() => {
    if (highlightInfo?.dashboard === 'bank-statements') {
      const term = highlightInfo.term.toLowerCase();
      const cardTitles = [
        "task monitor", "statement controls", "statement format", 
        "statement identifier", "mt940 uploads", "btc", "statement analyser",
        "mt940 balances", "transfer to bs", "manage statement controls",
        "portal uploads", "statement changes", "statement tracker"
      ];
      
      const matchedTitle = cardTitles.find(title => 
        title.toLowerCase().includes(term) || term.includes(title.toLowerCase())
      );

      if (matchedTitle) {
        setHighlightedCard(matchedTitle);
        const timer = setTimeout(() => {
          setHighlightedCard(null);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [highlightInfo]);

  useEffect(() => {
    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    // Fetch sub-menu data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(menu => menu.mainMenuId === 5);
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    // Fetch module assignment data
    if (userId) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
        .then((response) => {
          const filteredAssignments = response.data.filter(
            (assignment) =>
              assignment.userId === String(userId) && assignment.isChecked === true
          );
          setModuleAssignments(filteredAssignments);
        })
        .catch((error) => console.error("Error fetching module assignments:", error));
    }
  }, [userId]);


  
useEffect(() => {
  fetchAssignee();
}, []);

const fetchAssignee = async () => {
  try {
    const userDetailString = localStorage.getItem("userDetail");

    if (userDetailString) {
      const userDetail = JSON.parse(userDetailString);
      const assigneeData = {
        id: userDetail.id || "",
        assigner: userDetail.username || "",
        clientId: userDetail.clientId || "",
        assignerId: userDetail.userId || "",
      };
      console.log("assigneeData is", assigneeData);

      setAssignee(assigneeData);

      // Log the id and assigner values to the console
      console.log("Assignee ID:", assigneeData.id);
      console.log("Assigner:", assigneeData.assigner);
      console.log("Assigner userId:", assigneeData.assignerId);
      console.log("Assigner clientId:", assigneeData.clientId);
    } else {
      console.error({
        severity: "error",
        message: "No user detail found in session storage.",
      });
    }
  } catch (error) {
    console.error({
      severity: "error",
      message: `Error fetching user details: ${error.message}`,
    });
  }
};

  useEffect(() => {
      if (assignee.assignerId) {
        console.log("Assigner ID has changed:", assignee.assignerId);
        fetchUserIconAccessPermission();
      }
    }, [assignee.assignerId]);

    const fetchUserIconAccessPermission = async () => {
      try {
        const data = { userId: assignee.assignerId };
        // console.log("Request Data:", data);
  
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-Icon-access-permission-by-userId`,
          data
        );
        const responseData = response.data;
        console.log("user icon permissions details", responseData);  
        
        const hasPermissionForStatementControls = responseData.some(
          (Icon) => Icon.iconId == 44 && Icon.isChecked == 1
        );
      
        const hasPermissionForStatementFormat = responseData.some(
          (Icon) => Icon.iconId == 45 && Icon.isChecked == 1
        );

        const hasPermissionForStatementIdentifier = responseData.some(
          (Icon) => Icon.iconId == 46 && Icon.isChecked == 1
        );

        const hasPermissionForMT940Uploads = responseData.some(
          (Icon) => Icon.iconId == 47 && Icon.isChecked == 1
        );

        const hasPermissionForBTC = responseData.some(
          (Icon) => Icon.iconId == 48 && Icon.isChecked == 1
        );

        const hasPermissionForStatementAnalyser = responseData.some(
          (Icon) => Icon.iconId == 49 && Icon.isChecked == 1
        ); 

        const hasPermissionForMt940Balances = responseData.some(
          (Icon) => Icon.iconId == 50 && Icon.isChecked == 1
        );

        const hasPermissionForTransferToBS = responseData.some(
          (Icon) => Icon.iconId == 51 && Icon.isChecked == 1
        );

        const hasPermissionForManageStatementControls = responseData.some(
          (Icon) => Icon.iconId == 52 && Icon.isChecked == 1
        );

        const hasPermissionForUploads = responseData.some(
          (Icon) => Icon.iconId == 53 && Icon.isChecked == 1
        );

        SethasPermissionForStatementControls(hasPermissionForStatementControls);
        SethasPermissionForStatementFormat(hasPermissionForStatementFormat);
        SethasPermissionForStatementIdentifier(hasPermissionForStatementIdentifier);
        SethasPermissionForMT940Uploads(hasPermissionForMT940Uploads);
        SethasPermissionForBTC(hasPermissionForBTC);
        SethasPermissionForStatementAnalyser(hasPermissionForStatementAnalyser);
        SethasPermissionForMt940Balances(hasPermissionForMt940Balances);
        SethasPermissionForTransferToBS(hasPermissionForTransferToBS);
        SethasPermissionForManageStatementControls(hasPermissionForManageStatementControls);
        SethasPermissionForUploads(hasPermissionForUploads);
        
      } catch (error) {
        console.error(error.message); // Set error message
      }
    };
    const StatementControlsPermission = async () => {
      toast("error", "You don't have permission to view this Statement Controls icon.");
    };

    const StatementFormatPermission = async () => {
      toast("error", "You don't have permission to view this Statement Format icon.");
    };
    const StatementIdentifierPermission = async () => {
      toast("error", "You don't have permission to view this Statement Identifier icon.");
    };
    const MT940UploadsPermission = async () => {
      toast("error", "You don't have permission to view this MT940 Uploads icon.");
    };
    const BTCPermission = async () => {
      toast("error", "You don't have permission to view this BTC icon.");
    };
    const StatementAnalyserPermission = async () => {
      toast("error", "You don't have permission to view this Statement Analyser icon.");
    };
    const Mt940BalancesPermission = async () => {
      toast("error", "You don't have permission to view this Mt940 Balances icon.");
    };
    const TransferToBSPermission = async () => {
      toast("error", "You don't have permission to view this Transfer To BS icon.");
    };
    const ManageStatementControlsPermission = async () => {
      toast("error", "You don't have permission to view this Manage Statement Controls icon.");
    };
    const UploadsPermission = async () => {
      toast("error", "You don't have permission to view this Uploads icon.");
    };

  const handleCardClick = (route) => {
    navigate(route);
  };

  // Move the sections creation logic to a separate function
  const createSections = (menus, highlighted) => {
    return menus.map((menu) => {
      let cards;

      if (menu.name === "Setups") {
        cards = [
          {
            title: "Statement Controls",
            icon: <HiOutlineWrench />,
            isHighlighted: highlighted === "statement controls",
            onClick: hasPermissionForStatementControls === false
            ? StatementControlsPermission
            : () => handleCardClick("/statement-controll"),
          },
          {
            title: "Statement Format",
            icon: <CiViewTable />,
            isHighlighted: highlighted === "statement format",
            onClick: hasPermissionForStatementFormat === false
            ? StatementFormatPermission
            : () => handleCardClick("/statement-formatt"),
          },
          // {
          //   title: "Task Monitor",
          //   icon: <BiJoystickButton />,
          //   isHighlighted: highlighted === "task monitor",
          //   onClick: () => handleCardClick("/task-monitor"),
          // }
        ];
      } else if (menu.name === "MT940") {
        cards = [
          {
            title: "Statement Identifier",
            icon: <BsFileEarmarkBarGraph />,
            isHighlighted: highlighted === "statement identifier",
            onClick: hasPermissionForStatementIdentifier === false
            ? StatementIdentifierPermission
            : () => handleCardClick("/statement-identifire"),
          },
          {
            title: "MT940 Uploads",
            icon: <LuUpload />,
            isHighlighted: highlighted === "mt940 uploads",
            onClick: hasPermissionForMT940Uploads === false
            ? MT940UploadsPermission
            : () => handleCardClick("/mt940-add"),
          },
          {
            title: "BTC",
            icon: <SlGlobe />,
            isHighlighted: highlighted === "btc",
            onClick: hasPermissionForBTC === false
            ? BTCPermission
            : () => handleCardClick("/btc"),
          },
          {
            title: "Statement Analyzer",
            icon: <TbAtom2 />,
            isHighlighted: highlighted === "statement analyser",
            onClick: hasPermissionForStatementAnalyser === false
            ? StatementAnalyserPermission
            : () => handleCardClick("/11/mt940/49/add-statement-analyser"),
          },
          {
            title: "MT940 Balances",
            icon: <TbAtom2 />,
            isHighlighted: highlighted === "mt940 balances",
            onClick: hasPermissionForMt940Balances === false
            ? Mt940BalancesPermission
            : () => handleCardClick("/add-mt940-balances"),
          },
          {
            title: "Transfer To BS",
            icon: <TbDatabaseExport />,
            isHighlighted: highlighted === "transfer to bs",
            onClick: hasPermissionForTransferToBS === false
            ? TransferToBSPermission
            : () => handleCardClick("/11/mt940/51/add-transfer-bs"),
          },
          {
            title: "Manage Statement Controls",
            icon: <BiJoystickButton />,
            isHighlighted: highlighted === "manage statement controls",
            onClick: hasPermissionForManageStatementControls === false
            ? ManageStatementControlsPermission
            : () => handleCardClick("/manage-statement-controlls"),
          }
        ];
      } else if (menu.name === "Portal") {
        cards = [
          {
            title: "Portal Uploads",
            icon: <LuUpload />,
            isHighlighted: highlighted === "portal uploads",
            onClick: 
             () => handleCardClick("/5/bank-statement/53/portal-upload"),
          }
        ];
      } else if (menu.name === "Reports") {
        cards = [
          {
            title: "Statement Changes",
            icon: <GoArrowSwitch />,
            onClick: () => handleCardClick("/negative-balances"),
            isHighlighted: highlighted === "statement changes"
          },
          {
            title: "Statement Tracker",
            icon: <RiFolderShield2Line />,
            onClick: () => handleCardClick("/negative-balances"),
            isHighlighted: highlighted === "statement tracker"
          }
        ];
      } else {
        cards = [];
      }

      return {
        title: menu.name,
        subMenuId: menu.id,
        cards: cards,
      };
    });
  };

  // Filter subMenus based on moduleAssignments
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  // Define an order for submenus to control their display order
  const submenuOrder = ["Setups", "MT940", "Portal", "Reports"];

  // Sort the filteredSections by the defined submenuOrder
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  // Create sections with current highlighted card
  const sections = createSections(orderedSections, highlightedCard);

  return (
    <div>
      <MainHeader title={"Bank Statement"} />
      <Dashboard sections={sections} />
    </div>
  );
}