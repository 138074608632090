import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import { RxDropdownMenu } from "react-icons/rx";
import CountryServices from "../../../../../rest-services/CountryServices";
import UserService from "../../../../../rest-services/UserServices";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import ReusableModal from "../../../../../components/PopupComponent";
import CustomDropdown from "../../../../../components/CustomDropdown";
import { set } from "lodash";

export default function CalendarAdd() {
  const { calenderId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [calendarCode, setCalendarCode] = useState("");
  const [calendarName, setCalendarName] = useState("");
  const [errors, setErrors] = useState({});
  const [Country, setCountry] = useState(""); // Country Code
  const [CountryName, setCountryName] = useState(""); // Country Code

  const [countryId, setCountryId] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [iscountrynameModalOpen, setIscountynameModalOpen] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState("");

  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!calenderId;

  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const[calendarType,setCalendarType] = useState('');
  const [selectedMonth, setSelectedMonth] = useState("January");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    fetchcountry();

  }, []);
  useEffect(() => {
    if (calenderId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [calenderId, countries]);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getDataForEdit = async () => {
    try {
      const response = await CalendarServices.getCalendarById(calenderId);
      const country = countries.find(c => c.country === response.data.Country);

      if (country) {
        setCountry(country.country);
        setCountryName(country.countryName);
        setSelectedCountryName(country.countryName);
        setSelectedCountry(country.country); // Set the selected country based on countryName
      } else {
        console.error("Country not found for the provided country code.");
      }
      setCalendarCode(response.data.Calendar);
      setCalendarName(response.data.Calendar_Name);
      setCalendarType(response.data.calendarType);
      setSelectedMonth(response.data.selectedMonth);
      // setCountry(response.data.Country);
      
    } catch (error) {
      console.error("Error fetching state/province data:", error);
    }
  };
  const fetchcountry = async () => {
    try {
      const response = await CalendarServices.getAllCalendar(`/get-country`);
      console.log("Fetched countries:", response); // Add this log
      setCountries(response.data.data); // Ensure this is correct
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const validateForm = async () => {
    const newErrors = {};
  
    // Check for missing fields first
    if (!calendarCode) newErrors.calendarCode = "Calendar Code is required.";
    if (!calendarName) newErrors.calendarName = "Calendar Name is required.";
    if (!Country) newErrors.country = "Country is required.";
    if (!CountryName) newErrors.countryName = "Country Name is required.";
  
    // If there are missing fields, set errors and return early
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast("error", "Missing Required Fields");
      return false; // Stop further validation
    }
  
    // Skip duplication check for edit mode
    if (!calenderId && Country && selectedMonth) {
      const isDuplicate = await checkDuplicateMonth(Country, selectedMonth);
      if (isDuplicate) {
        newErrors.selectedMonth = "This month already exists for the selected country.";
        setErrors(newErrors);
        toast("error", "This month already exists for the selected country.");
        return false; // Stop further validation
      }
    }
  
    // If no errors, return true
    return true;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate the form
    const isValid = await validateForm();
    if (!isValid) return; // Stop if validation fails
  
    try {
      // Skip duplication check for edit mode
      if (!calenderId) {
        const duplicateCheckResponse = await CalendarServices.checkDuplicateCalendarcode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatecalendar`,
          { calendar: calendarCode }
        );
  
        if (duplicateCheckResponse.isDuplicate) {
          toast("error", "Calendar already exists.");
          setErrors((prev) => ({
            ...prev,
            calendarCode: "Calendar already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
  
      const data = {
        id: calenderId || null,
        calendarField: calendarCode,
        Calendar_Name: calendarName,
        Country,
        selectedMonth,
        calendarType,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };
  
      console.log('calendar data', data);
  
      let response;
      if (!unsavedChanges) {
        toast("info", "No Calendar changes detected. Nothing to save.");
        navigate("/2/master-data/3/calendar");
        return false;
      }
  
      if (calenderId) {
        response = await CalendarServices.addCalendar(`/create-calendar`, data);
      } else {
        response = await CalendarServices.addCalendar(`/create-calendar`, data);
      }
  
      if (response && !response.error) {
        toast("success", calenderId ? "Calendar Updated successfully" : "Calendar Added successfully");
        setCalendarCode("");
        setCalendarName("");
        navigate("/2/master-data/3/calendar");
      } else {
        toast("error", "Error adding/updating Calendar");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Calendar: ${error.message}`,
      });
    }
  };
  
  const handleSaveAndContinue = async (e) => {
    e.preventDefault();
  
    // Validate the form
    const isValid = await validateForm();
    if (!isValid) return; // Stop if validation fails
  
    try {
      // Skip duplication check for edit mode
      if (!calenderId) {
        const duplicateCheckResponse = await CalendarServices.checkDuplicateCalendarcode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatecalendar`,
          { calendar: calendarCode }
        );
  
        if (duplicateCheckResponse.isDuplicate) {
          toast("error", "Calendar already exists.");
          setErrors((prev) => ({
            ...prev,
            calendarCode: "Calendar already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
  
      const data = {
        id: calenderId || null,
        calendarField: calendarCode,
        Calendar_Name: calendarName,
        Country,
        selectedMonth,
        calendarType,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };
  
      console.log('calendar data', data);
  
      let response;
      if (calenderId) {
        response = await CalendarServices.addCalendar(`/create-calendar`, data);
      } else {
        response = await CalendarServices.addCalendar(`/create-calendar`, data);
      }
  
      if (response && !response.error) {
        toast("success", calenderId ? "Calendar Updated successfully" : "Calendar Added successfully");
        setCalendarCode("");
        setCalendarName("");
        setSelectedCountry("");
        setCountry("");
        setCalendarType('');
        setSelectedMonth("January");
      } else {
        toast("error", "Error adding/updating Calendar");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Calendar: ${error.message}`,
      });
    }
  };
  const checkDuplicateCalendarcode = async (code) => {
    try {
      const response = await CalendarServices.checkDuplicateCalendarcode(
        `/checkduplicatecalendar`,
        { calendar: code } // Include selectedMonth
      );
  
      console.log("Response from server:", response);
  
      if (response.isDuplicate) {
        setErrors({ calendarCode: "Calendar already exists." });
        toast("error", "Calendar already exists.");
      }
    } catch (error) {
      console.error("Error checking duplicate calendarCode:", error);
      setErrors((prev) => ({ ...prev, calendarCode: "Error checking calendar code" }));
    }
  };
  const checkDuplicateMonth = async (country, month) => {
    try {
      const response = await CalendarServices.checkDuplicateCalendarcode(
        `/checkduplicatemonth`,
        { Country: country, selectedMonth: month }
      );
  
      console.log("Response from server:", response);
  
      if (response.isDuplicate) {
        return true; // Duplicate exists
      }
      return false; // No duplicate
    } catch (error) {
      console.error("Error checking duplicate month:", error);
      setErrors((prev) => ({ ...prev, selectedMonth: "Error checking month duplication." }));
      return false;
    }
  };

  const handleCalendarChange = (e) => {
    const code = e.target.value.toUpperCase(); // Ensure input is uppercase
    setUnsavedChanges(true);
  
    if (errors.calendarCode) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        calendarCode: "",
      }));
      return; // Stop further processing
    }
  
    setCalendarCode(code);
  
    // Check for duplicate Calendar Code and selectedMonth
      checkDuplicateCalendarcode(code);
    
  };

  const handleCountryCheckBoxChange = (event) => {
    const selectedName = event.target.value; // Change from value to country name
    const selectedCountryData = countries.find(country => country.country === selectedName || country.countryName === selectedName);
    if (selectedCountryData) {
      if(selectedName ===selectedCountryData.country){
        setSelectedCountry(selectedCountryData.country);
        setCountryName(selectedCountryData.countryName);
        setCountry(selectedCountryData.country);
        setSelectedCountryName(selectedCountryData.countryName);
      } else if(selectedName ===selectedCountryData.countryName){
        setSelectedCountry(selectedCountryData.country);
        setCountryName(selectedCountryData.countryName);
        setCountry(selectedCountryData.country);
        setSelectedCountryName(selectedCountryData.countryName);
      }
  setUnsavedChanges(true);
    }
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {
      console.log("Navigating to /2/master-data");
      navigate('/2/master-data');
    } else if (crumb.title === 'Calendar') {
      console.log("Navigating to /country");
      navigate('/2/master-data/3/calendar');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const handleReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedCountry(""); // Reset selected bank ID
    setCountry("");

  };
  const handleRefreshcountry = async () => {
    try {
      await fetchcountry();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  const CalendarTable = () => {
    const allMonths = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const monthOptions = allMonths.map((month) => ({
      label: month,
      value: month,
    }));
  
    const generateCalendarData = (startMonth) => {
      const startIndex = allMonths.indexOf(startMonth);
      const months = [
        ...allMonths.slice(startIndex),
        ...allMonths.slice(0, startIndex),
      ];
  
      return months.map((month, index) => {
        let quarter = "";
        let halfYear = "";
  
        if (index < 3) {
          quarter = index === 0 ? "Quarter 1" : "";
          halfYear = index === 0 ? "1st half" : "";
        } else if (index < 6) {
          quarter = index === 3 ? "Quarter 2" : "";
        } else if (index < 9) {
          quarter = index === 6 ? "Quarter 3" : "";
          halfYear = index === 6 ? "2nd half" : "";
        } else {
          quarter = index === 9 ? "Quarter 4" : "";
        }
  
        return {
          id: index + 1,
          quarter,
          halfYear,
          month,
        };
      });
    };
  
    const [calendarData, setCalendarData] = useState(generateCalendarData(selectedMonth));
  
    const handleFirstRowMonthChange = async (newMonth) => {
      setSelectedMonth(newMonth);
      setCalendarData(generateCalendarData(newMonth));
     
    
      // Clear any existing selectedMonth error
      setErrors((prev) => ({ ...prev, selectedMonth: "" }));
    
      // Check for duplicate month for the selected country (only if not in edit mode)
      if (!calenderId && Country) {
        const isDuplicate = await checkDuplicateMonth(Country, newMonth);
        if (isDuplicate) {
          setErrors((prev) => ({ ...prev, selectedMonth: "This month already exists for the selected country." }));
        }
      }
      setUnsavedChanges(true);
    };
    return (
      <div className="-mt-4">
        <table className="w-[600px] h-[200px] border border-customBlue text-sm">
          <thead className="bg-customBlue text-white font-small text-xs">
            <tr>
              <th className="py-1 px-4 border-r">S.No</th>
              <th className="py-1 px-4 border-r">Quarter</th>
              <th className="py-1 px-4 border-r">Half Year</th>
              <th className="py-1 px-4">Month</th>
            </tr>
          </thead>
          <tbody className="text-xs text-center">
            {calendarData.map((row, index) => (
              <tr
                key={row.id}
                className={(row.id >= 4 && row.id <= 6) || (row.id >= 10 && row.id <= 12) ? "bg-sky-50" : ""}
              >
                <td className="py-1 px-4 border-r text-customGray">{row.id}</td>
                <td className="py-1 px-4 border-r text-customGray">{row.quarter}</td>
                <td className="py-1 px-4 border-r text-customGray">{row.halfYear}</td>
                <td className="py-1 px-4 text-customGray text-center">
                  {index === 0 ? (
                    <CustomDropdown
                      value={selectedMonth}
                      options={monthOptions}
                      onChange={handleFirstRowMonthChange}
                      isOpen={isDropdownOpen}
                      onToggle={() => setDropdownOpen((prev) => !prev)}
                      placeholder="Select Month"
                      className="text-center pl-2"
                      isDisabled={isViewMode}
                    />
                  ) : (
                    row.month
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  return (
    <div>
      <Header
        title={`Primary Data > Global Data > Calendar > ${calenderId ? (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/2/master-data/3/calendar"}
      />

      <div className="m-6 border border-gray-200 px-2 pb-5 pt-2  rounded-lg">

        <div className="flex flex-col space-y-12 col-span-1 px-2 pb-5 pt-2">
        <div className="grid grid-cols-2 gap-2">

          {/* <label className="flex items-center"> */}
          <div className="flex flex-col">
                <span className="flex items-center">
            <span className="w-48 font-small text-xs text-customGray">
              Calendar Code<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={calendarCode}
              onChange={handleCalendarChange}
              maxLength={2}

              className={`border font-small text-xs text-customGray ${errors.calendarCode ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
              disabled={isViewMode || isEditMode}
            />

          </span>
          {!isViewMode && calendarCode.length === 2 &&  (
                      <p className="text-red-500 text-xs ">You have used the maximum character limit.</p>
                    )}
</div>
<div className="flex flex-col">
          {/* <label className="flex items-center ml-4"> */}
          <span className="flex items-center">
            <span className="w-48 font-small text-xs text-customGray">
              Calendar Name<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={calendarName}
              maxLength={30}

              onChange={(e) => {
                setCalendarName(e.target.value);
                setUnsavedChanges(true);
                if (errors.calendarName) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    calendarName: "",
                  }));
                }
              }}
              className={`border font-small text-xs text-customGray ${errors.calendarName ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
              disabled={isViewMode}
            />
          </span>
          {!isViewMode && calendarName.length === 30 &&  (
                      <p className="text-red-500 text-xs ">You have used the maximum character limit.</p>
                    )}
                    </div>  
                    </div>   
           <div className="grid grid-cols-2 gap-2">

          <div className="flex items-center -mt-8">
            <label className="w-48 font-small text-xs text-customGray" disabled={isViewMode}>Country <span className="text-red-500 ml-1">*</span></label>
            <span className="ml-4">:</span>
            <button
              onClick={() => setIsCountryModalOpen(true)}
              disabled={isViewMode}

              className={`border border-gray-300 p-1 disabled:bg-[#FAFAFA] rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between font-small text-xs text-customGray ${errors.country ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
            >
              <span className="font-small text-xs text-customGray">{selectedCountry || 'Select Country '}</span>
              <RxDropdownMenu className="ml-2" />
            </button>
            <ReusableModal
              title="Select Country Code"
              isOpen={isCountryModalOpen}
              onClose={() => {
                setIsCountryModalOpen(false);
                setSearchQuery('');
              }}
              onSave={() => {
                setIsCountryModalOpen(false);
                setSearchQuery('');
              }}
              showReset={true}
              onReset={handleReset}
              searchPlaceholder="Search country..."
              searchQuery={searchQuery}
              onRefresh={handleRefreshcountry}
              onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
            >
              {countries.length > 0 ? (
                countries
                  .filter((country) =>
                    country.country.toLowerCase().includes(searchQuery.toLowerCase())

                  )
                  .sort((a, b) => a.country.localeCompare(b.country)) // Sort in alphabetical order

                  .map((country) => (
                    <div key={country.id} className="flex items-center">
                      <input
                        type="radio"
                        name="country"
                        value={country.country}
                        checked={selectedCountry === country.country}
                        onChange={handleCountryCheckBoxChange}
                        className="mr-2 accent-customBlue"
                        disabled={isViewMode}
                      />
                      <label>{country.country}</label>
                    </div>
                  ))
              ) : (
                <p>Loading countries...</p>
              )}
            </ReusableModal>
          </div>
          <div className="flex items-center -mt-8">
            <label className="w-48 font-small text-xs text-customGray" disabled={isViewMode}>Country Name <span className="text-red-500 ml-1">*</span></label>
            <span className="ml-4">:</span>
            <button
onClick={() => setIscountynameModalOpen(true)}
disabled={isViewMode}
              className={`border border-gray-300 disabled:bg-[#FAFAFA] p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
            >
              <span className="text-customGray text-xs font-small">{selectedCountryName || 'Select Country Name'}</span>
              <RxDropdownMenu className="ml-2" />
            </button>

            <ReusableModal
              title="Select Country Name"
              isOpen={iscountrynameModalOpen}
              onClose={() => {
                setIscountynameModalOpen(false);
                setSearchQuery('');
              }}
              onSave={() => {
                setIscountynameModalOpen(false);
                setSearchQuery('');
              }}
              searchPlaceholder="Search CountryName..."
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
              showReset={true}
              onRefresh={handleRefreshcountry}
              onReset={handleReset}
            >
              {countries.length > 0 ? (
                countries
                  .filter((country) =>
                    country.countryName.toLowerCase().includes(searchQuery.toLowerCase())

                  )
                  .sort((a, b) => a.countryName.localeCompare(b.countryName)) // Sort in alphabetical order

                  .map((country) => (
                    <div key={country.id} className="flex items-center">
                      <input
                        type="radio"
                        name="country"
                        value={country.countryName}
                        checked={selectedCountryName === country.countryName}
                        onChange={handleCountryCheckBoxChange}
                        
                        className="mr-2 accent-customBlue text-customGray text-xs font-small"
                        disabled={isViewMode}
                      />
                      <label>{country.countryName}</label>
                    </div>
                  ))
              ) : (
                <p>Loading countries...</p>
              )}
            </ReusableModal>


          </div>
</div>
           <div className="grid grid-cols-2 gap-2">

 <span className="flex items-center -mt-6">
            <span className="w-48 font-small text-xs text-customGray">
              Calendar Type description
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={calendarType}
              onChange={(e) => {
                setCalendarType(e.target.value);
                setUnsavedChanges(true);
                if (errors.calendarType) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    calendarType: "",
                  }));
                }
              }}              maxLength={20}

              className={`border font-small text-xs text-customGray ${errors.calendarType ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
              required
              disabled={isViewMode}
            />

          </span>
          
          <div></div>
</div>

        </div>

        <CalendarTable/>

      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
            hasId={!!calenderId}
          />
        )}
      </Footer>
    </div>
  );
}
