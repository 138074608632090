import React, {useEffect, useState } from "react";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import { Alert } from "@mui/material";
import UserService from "../../../../../../rest-services/UserServices";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useToast } from "../../../../../../components/toast/toast";
import { TbFlag3Filled } from "react-icons/tb";
import PhoneInput from 'react-phone-input-2';
import Header from "../../../../../../components/Header";
import Tabs from "../../../../../../components/Tabs";
import { RxDropdownMenu } from "react-icons/rx";

const UserViewScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { userId } = useParams();
  // const { userId } = location.state || {};  // Access userId from the state
//   const user = location.state?.user; // Safely access user from location.state



  // const selectedUserIds = location.state?.selectedUserIds || [];
  const [surname, setSurname] = useState("");
  const [accountingSystemId, setAccountingSystemId] = useState("");

  const [activeTab, setActiveTab] = useState("basicDetail");
  const [assignee, setAssignee] = useState({ id: "", username: "" });
//   const [userId, setUserId] = useState("");
  const [salute, setSalute] = useState("");
  const [userType, setUserType] = useState(0);
  const [userTypes, setUserTypes] = useState([]);
  const [addressDetails, setAddressDetails] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [oneTimePassword, setOneTimePassword] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [officeNo, setOfficeNo] = useState("");
  const [extension, setExtension] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [drivingLicenseNo, setDrivingLicenseNo] = useState("");
  const [localId, setLocalId] = useState("");
  const [nationality, setNationality] = useState("");
  const [employeeNo, setemployeeNo] = useState("");
  const [companyCodeId, setcompanyCodeId] = useState("");
  const [department, setdepartment] = useState("");
  const [validFrom, setvalidFrom] = useState("");
  const [validTo, setvalidTo] = useState("");
  const [joiningDate, setjoiningDate] = useState("");
  const [resignationDate, setresignationDate] = useState("");
  const [language, setlanguage] = useState("");
  const [dateFormat, setdateFormat] = useState("");
  const [amountFormat, setamountFormat] = useState("");
  const [timeFormat, settimeFormat] = useState("");
  const [timeZone, settimeZone] = useState("");
  const [receiveSystemMail, setreceiveSystemMail] = useState("");
  const [downloadData, setdownloadData] = useState("");
  const [changesInCashflow, setchangesInCashflow] = useState("");
  const [updateCountry, setupdateCountry] = useState("");
  const [changeVarient, setchangeVarient] = useState("");
  const [deleteAllRecords, setdeleteAllRecords] = useState("");
  const [changeAccountingCode, setchangeAccountingCode] = useState("");

  const [countries, setCountry] = useState([]);

  const [cityName, setcityName] = useState("");
  const [thousandSeparator, setthousandSeparator] = useState("");
  const [decimalSeparator, setdecimalSeparator] = useState("");

  const [acceleratorForThousands, setacceleratorThousands] = useState("");
  const [acceleratorForLakhs, setacceleratorForLakhs] = useState("");
  const [acceleratorForMillions, setacceleratorMillions] = useState("");
  const [stateProvinces, setStateProvinces] = useState([]);
  const [loginMethod, setLoginMethod] = useState(0);

  const [username, setUserName] = useState("");
const [approved, setApproved] = useState("");
  // Define the state variables
const [doorNo, setDoorNo] = useState('');
const [floorNo, setFloorNo] = useState('');
const [buildingNo, setBuildingNo] = useState('');
const [buildingName, setBuildingName] = useState('');
const [landMark, setLandMark] = useState('');
const [streetNo, setStreetNo] = useState('');
const [streetName, setStreetName] = useState('');
const [roadNo, setRoadNo] = useState('');
const [roadName, setRoadName] = useState('');
const [areaBlock, setAreaBlock] = useState('');
const [areaName, setAreaName] = useState('');
const [addressLine1, setAddressLine1] = useState('');
const [addressLine2, setAddressLine2] = useState('');
const [addressLine3, setAddressLine3] = useState('');
const [countryCode, setCountryCode] = useState('');
const [countryName, setCountryName] = useState('');
const [countryId, setCountryId] = useState('');
const [state, setstate] = useState("");
const [poBox, setPoBox] = useState('');
const [district, setDistrict] = useState('');
const [postalCode, setPostalCode] = useState('');
const [zipCode, setZipCode] = useState('');
const [stateName, setStateName] = useState(''); 


// Define similar handlers for other fields

  const [alert, setAlert] = useState(null);
  const [errors, setErrors] = useState({});




  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  
  const nextabMoveToMethod = async () => {
    handleTabClick("loginMethod"); // Navigate to contactDetails tab 
  }

  const nextabMoveToPreferance = async () => {
    handleTabClick("userPreferences"); // Navigate to contactDetails tab 
  }

  const nextabMoveToAddress = async () => {
    handleTabClick("addressDetails"); // Navigate to contactDetails tab 
  }
  const nextabMoveToOrganization = async () => {
    handleTabClick("organization"); // Navigate to contactDetails tab 
  }
  const nextabMoveToIdentity = async () => {
    handleTabClick("identity"); // Navigate to contactDetails tab 
  }
  const nextabMoveToContact =async () => {
              handleTabClick("contactDetails"); // Navigate to contactDetails tab   
  }


  const handleLoginMethodChange = (e) => {
    setLoginMethod(Number(e.target.value));
  };

  useEffect(() => {
    fetchAssignee();
    fetchUserTypes();
    fetchcountry();
    fetchstateprovice();

  }, []);

  const fetchUserPrefernceDetails = async () => {
    try {
      const data = { userId: userId };
      console.log("Request Data:", data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details`,
        data
      );
      
      console.log("API Response:", response);
      console.log("Response Data:", response.data);
    
      if (response.data) {
        // Update state with fetched address details
        setlanguage(response.data.language || '');
        setdateFormat(response.data.dateFormat || '');
        setamountFormat(response.data.amountFormat || '');
        setthousandSeparator(response.data.thousandSeparator || '');
        setdecimalSeparator(response.data.decimalSeparator || '');
        setacceleratorThousands(response.data.acceleratorForThousands || '');
        setacceleratorMillions(response.data.acceleratorForMillions || '');
        setacceleratorForLakhs(response.data.acceleratorForLakhs || '');
        settimeFormat(response.data.timeFormat || '');
        settimeZone(response.data.timeZone || '');

        setreceiveSystemMail(response.data.receiveSystemMail || '');
        setdownloadData(response.data.downloadData || '');
        setchangesInCashflow(response.data.changesInCashflow || '');
        setupdateCountry(response.data.updateCountry || '');
        setchangeVarient(response.data.changeVarient || '');
        setdeleteAllRecords(response.data.deleteAllRecords || '');
        setchangeAccountingCode(response.data.changeAccountingCode || '');
      
       
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };


  const fetchAddressDetails = async () => {
    try {
      const data = { userId: userId };
      console.log("Request Data:", data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-address-details`,
        data
      );
      
      console.log("API Response:", response);
      console.log("Response Data:", response.data);
    
      if (response.data) {
        // Update state with fetched address details
        setDoorNo(response.data.doorNo || '');
        setFloorNo(response.data.floorNo || '');
        setBuildingNo(response.data.buildingNo || '');
        setBuildingName(response.data.buildingName || '');
        setLandMark(response.data.landMark || '');
        setStreetNo(response.data.streetNo || '');
        setStreetName(response.data.streetName || '');
        setRoadNo(response.data.roadNo || '');
        setRoadName(response.data.roadName || '');
        setAreaBlock(response.data.areaBlock || '');
        setAreaName(response.data.areaName || '');
        setAddressLine1(response.data.addressLine1 || '');
        setAddressLine2(response.data.addressLine2 || '');
        setAddressLine3(response.data.addressLine3 || '');
        setCountryCode(response.data.countryCode || '');
        setCountryName(response.data.Country.countryName || '');
        setCountryId(response.data.countryId || '');
        setstate(response.data.state || '');
        setStateName(response.data.StateProvince.State_Name || '');
        setPoBox(response.data.poBox || '');
        setDistrict(response.data.district || '');
        setPostalCode(response.data.postalCode || '');
        setZipCode(response.data.zipCode || '');
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };

  const fetcheditUserDetails = async () => {

    try {
        const data = { userId: userId };
        console.log("Request Data:", data);
        
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-edit-user-details`,
          data
        );
        
        console.log("API Response:", response);
        console.log("Response Data:", response.data);
      
        if (response.data) {
          // Update state with fetched address details
          setUserName(response.data.username || '');
          setApproved(response.data.approved || '');
          setSalute(response.data.salute || '');
          setUserType(response.data.userType || '');
          setAccountingSystemId(response.data.accountingSystemId || '');
          setSurname(response.data.surname || '');
          setFirstName(response.data.firstName || '');
          setLastName(response.data.lastName || '');
          setMiddleName(response.data.middleName || '');
          setEmail(response.data.email || '');
          setMobileNo(response.data.mobileNo || '');
          setWhatsappNo(response.data.whatsappNo || '');
          setOneTimePassword(response.data.oneTimePassword || '');
          setOfficeNo(response.data.officeNo || '');
          setExtension(response.data.extension || '');
          setNationality(response.data.nationality || '');
          setPassportNo(response.data.passportNo || '');
          setDrivingLicenseNo(response.data.drivingLicenseNo || '');
          setLocalId(response.data.localId || '');
          setemployeeNo(response.data.employeeNo || '');
          setcompanyCodeId(response.data.companyCodeId || '');
          setdepartment(response.data.department || '');
          setvalidFrom(response.data.validFrom || '');
          setvalidTo(response.data.validTo || '');
        //   setlanguage(response.data.language || '');
        //   setdateFormat(response.data.dateFormat || '');
        //   setdateFormat(response.data.dateFormat || '');
        //   setthousandSeparator(response.data.thousandSeparator || '');
        //   setdecimalSeparator(response.data.decimalSeparator || '');
        //   setacceleratorThousands(response.data.acceleratorForThousands || '');
        //   settimeFormat(response.data.timeFormat || '');
        //   settimeZone(response.data.timeZone || '');
        //   setreceiveSystemMail(response.data.receiveSystemMail || '');
        //   setdownloadData(response.data.downloadData || '');
        //   setchangesInCashflow(response.data.changesInCashflow || '');
        //   setchangeVarient(response.data.changeVarient || '');
        //   setdeleteAllRecords(response.data.deleteAllRecords || '');
        //   setchangeAccountingCode(response.data.changeAccountingCode || '');
        //   setupdateCountry(response.data.updateCountry || '');

        //   setStreetName(response.data.streetName || '');
        //   setAddressLine1(response.data.addressLine1 || '');
        //   setAddressLine2(response.data.addressLine2 || '');
        //   setAddressLine3(response.data.addressLine3 || '');
        //   setCountryId(response.data.countryId || '');
        //   setZipCode(response.data.zipCode || '');
        //   setPostalCode(response.data.postalCode || '');
        //   setemployeeNo(response.data.employeeNo || '');
        //   setcompanyCodeId(response.data.companyCodeId || '');
        //   setdepartment(response.data.department || '');
        //   setvalidFrom(response.data.validFrom || '');
        //   setvalidTo(response.data.validTo || '');
          setresignationDate(response.data.resignationDate || '');
          setjoiningDate(response.data.joiningDate || '');
          setLoginMethod(response.data.loginMethod || '');
          

          
        }
      } catch (error) {
        console.error("Error fetching address details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while fetching address details.",
        });
      }
    };

  useEffect(() => {
    if (userId) {
    //   setUserId(userId);
      fetchAddressDetails();
      fetcheditUserDetails();
      fetchUserPrefernceDetails();
    }
  }, [userId]);
  

    // Fetch user types from the API
    const fetchUserTypes = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/get-user-type`
        );
        setUserTypes(response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    const fetchcountry = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/get-country`
        );
        setCountry(response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        
        setAssignee(assigneeData);
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
  
  // const handleCountryCodeChange = (e) => {
  //   const selectedCode = e.target.value;
  //   setCountryCode(selectedCode);
  
  //   // Find the corresponding country object and set countryId
  //   const selectedCountry = countries.find((country) => country.id === selectedCode);
  //   if (selectedCountry) {
  //     setCountryId(selectedCountry.id);
  //   }
  // };

  const validateFields = () => {
    const newErrors = {};
  
    const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
    const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
    const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
    const isAreaSectionFilled = areaBlock || areaName;
    const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled && isStreetSectionFilled && isAreaSectionFilled;
  
    if (!userId) newErrors.userId = "User ID is required";
    if (!salute) newErrors.salute = "Salute is required";
    if (!username) newErrors.username = "User Name is required";
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!lastName) newErrors.lastName = "Last Name is required";
    if (!email) newErrors.email = "Email Id is required";
    if (!mobileNo) newErrors.mobileNo = "Mobile Number is required";
    if (!nationality) newErrors.nationality = "Nationality is required";
  
    if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
      newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
    }
  
    if (!countryId) newErrors.countryId = "Country Id is required";
    if (!countryCode) newErrors.countryCode = "Country Code is required";
    if (!language) newErrors.language = "Language is required";
    if (!dateFormat) newErrors.dateFormat = "Date Format is required";
    if (!timeFormat) newErrors.timeFormat = "Time Format is required";
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


const handleSaveContinueDetails = async () => {

if (!validateFields()) return;
    try {
      console.log("entered");
      const data = {
        userId,
        username,
        salute,
        userType,
        firstName,
        middleName,
        surname,
        lastName,
        oneTimePassword: oneTimePassword || 0,
        createdBy: assignee.assigner,
        changedBy: assignee.assigner,
        email, mobileNo, whatsappNo ,officeNo,extension,
        passportNo, drivingLicenseNo,localId,nationality,
        language, dateFormat,thousandSeparator,decimalSeparator,acceleratorForThousands,acceleratorForMillions,timeFormat,timeZone,receiveSystemMail,downloadData,changesInCashflow,updateCountry,changeVarient,deleteAllRecords,changeAccountingCode,
        streetName, addressLine1, addressLine2,addressLine3,areaName, landMark ,countryId, zipCode, postalCode,
        employeeNo, companyCodeId,department,validFrom,validTo,joiningDate,resignationDate,
        loginMethod, 
      };
      const response = await UserService.saveAllUserDetails(
        `${process.env.REACT_APP_BASE_URL}/edit-user`,
        data
      );
      console.log("response data", data);
      console.log("response", response);
      console.log("response status", response.status);
      if (response.status === 200) {
        setAlert({
          severity: "success",
          message: "Basic Details saved successfully.",
        });

          // Wait for the success message to be displayed before navigating
          toast("success", "User successfully Added");
          handleTabClick("basicDetail");  // Navigate to loginMethod Details tab
      } else {
        setAlert({ severity: "error", message: "Not saved" });
      }
    } catch (error) {
      console.error("Error saving basic details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while saving.",
      });
    }
  };

  // const handleStateProvinceChange  = (e) => {

  //   const selectedId = e.target.value;
  //   setstate(selectedId);
  
  //   // Find the corresponding state/province object and set stateName
  //   const selectedState = stateProvinces.find((stateP) => stateP.id === parseInt(selectedId));
  //   if (selectedState) {
  //     setStateName(selectedState.State_Name);
  //   } else {
  //     setStateName('');
  //   }
  // };
 
  const handleSaveBasicDetails = async () => {
    if (!validateFields()) return;
    try {
      console.log("entered");
      const data = {
        userId,
        username,
        salute,
        userType,
        firstName,
        middleName,
        surname,
        lastName,
        oneTimePassword: oneTimePassword || 0,
        createdBy: assignee.assigner,
        changedBy: assignee.assigner,
        email, mobileNo, whatsappNo ,officeNo,extension,
        passportNo, drivingLicenseNo,localId,nationality,
        language, dateFormat,thousandSeparator,decimalSeparator,acceleratorForThousands,acceleratorForLakhs,acceleratorForMillions,timeFormat,timeZone,receiveSystemMail,downloadData,changesInCashflow,updateCountry,changeVarient,deleteAllRecords,changeAccountingCode,
        streetName, addressLine1, addressLine2,addressLine3,areaName, landMark ,countryId, zipCode, postalCode,
        employeeNo, companyCodeId,department,validFrom,validTo,joiningDate,resignationDate,
        loginMethod, 
      };
      const response = await UserService.saveAllUserDetails(
        `${process.env.REACT_APP_BASE_URL}/edit-user`,
        data
      );
      console.log("response data", data);
      console.log("response", response);
      console.log("response status", response.status);
      if (response.status === 200) {
        setAlert({
          severity: "success",
          message: "Basic Details saved successfully.",
        });

          // Wait for the success message to be displayed before navigating
          toast("success", "User successfully Added");
            navigate("/14/admin/105/userscreen"); // Navigate to loginMethod Details tab
      } else {
        setAlert({ severity: "error", message: "Not saved" });
      }
    } catch (error) {
      console.error("Error saving basic details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while saving.",
      });
    }
  };

  useEffect(() => {
    fetchstateprovice();
  },[countryCode])

  const fetchstateprovice = async () => {
    try {
      const data = { Country_Code: countryCode };
  console.log('fetching data2 country',countryCode);
      console.log("fetch country in state", countryCode);
  
      const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-stateprovince-by-country`,
            data
          );
          // console.log("API Response:", response);
          console.log("state province Response Data:", response.data);
  
      setStateProvinces(response.data);
  } catch (error) {
  console.error("Error fetching countries:", error);
  }
  
  }

  const tabStyle = (tab) => ({
    backgroundColor: activeTab === tab ? "white" : "transparent",
    color: activeTab === tab ? "grey" : "black",
    padding: "2px 10px",
    cursor: "pointer",
    borderRadius: "5px",
    border: "none",
  });
  // // Handle input changes

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Admin') {
      console.log("Navigating to /Admin");
      navigate('/14/admin');
    } else if (crumb.title === 'User & SOD') {
      console.log("Navigating to /User & SOD");
      navigate('/14/admin');
    }
    else if (crumb.title === 'User') {
      console.log("Navigating to /User");
      navigate('/14/admin/105/userscreen');
    } 
    else if (crumb.title === 'View') {
      console.log("Navigating to /User");
      navigate(`/user-view-screen/${userId}`);
    } 
    
    else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  useEffect(() => {
    console.log("check approved", approved);
  }, [approved]); // This will log the approved value whenever it changes

  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (approved == 3) {
      color = "#29CB00"; // Green
    } else if (approved == 0) {
      color = "gray"; // Gray
    } else if (approved == 1) {
      color = "yellow"; // Yellow
    } else if (approved == 2) {
      color = "orange"; // Orange
    } else if (approved == 4) {
      color = "red"; // Red
    }

    return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
  };


  const tabs = [
    { value: "basicDetail", label: "Basic Details" },
    { value: "contactDetails", label: "Contact details" },
    { value: "identity", label: "Identity" },
    { value: "organization", label: "Organization" },
    { value: "addressDetails", label: "Address Details" },
    { value: "userPreferences", label: "User Preferences" },
    { value: "loginMethod", label: "Login Method" },
  ];
  const handlesTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div>
<Header title={`Admin > User & SOD > User > View > ${userId} `
 }

        onBreadcrumbClick={handleBreadcrumbClick}
        extraContent={getApprovedIcon()} 
      />

      </div>
      <div>
        {/* <div className="ml-2 pl-8 pt-2 flex flex-row space-x-6 bg-gray-300">
          <h6
            className="font-small"
            style={tabStyle("basicDetail")}
            onClick={() => handleTabClick("basicDetail")}
          >
            Basic Details
          </h6>
          <h6
            className="font-small"
            style={tabStyle("contactDetails")}
            onClick={() => handleTabClick("contactDetails")}
          >
            Contact details
          </h6>
          <h6
            className="font-small"
            style={tabStyle("identity")}
            onClick={() => handleTabClick("identity")}
          >
            Identity
          </h6>
          <h6
            className="font-small"
            style={tabStyle("organization")}
            onClick={() => handleTabClick("organization")}
          >
            Organization
          </h6>
          <h6
            className="font-small"
            style={tabStyle("addressDetails")}
            onClick={() => handleTabClick("addressDetails")}
          >
            Address Details
          </h6>
          <h6
            className="font-small"
            style={tabStyle("userPreferences")}
            onClick={() => handleTabClick("userPreferences")}
          >
            User Preferences
          </h6>
          <h6
            className="font-small"
            style={tabStyle("loginMethod")}
            onClick={() => handleTabClick("loginMethod")}
          >
            Login Method
          </h6>
        </div> */}
            <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handlesTabClick} />

      </div>
        <div>
            {/* <div key={user.id}> */}
              {activeTab === "basicDetail" && (
                <div className="p-12">
                  <div className="grid grid-cols-2 gap-x-8 gap-y-8">
    
                    <label className="flex items-center group">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        User ID <span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={userId}
                        disabled
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                        placeholder="Enter username"
                      />
                    </label>

                    <label className="flex items-center group">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        Username <span className="text-red-500 ml-1">*</span>{" "}
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={username}
                        disabled
                        onChange={(e) => setUserName(e.target.value)}
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                        placeholder="Enter username"
                      />
                      {errors.username && (
                        <p className="text-red-500 text-sm ml-4">{errors.username}</p>
                      )}
                    </label>

                    

                    <label className="flex items-center group">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        User Type
                      </span>
                      <span className="ml-4">:</span>
                      <select
                      disabled
                        value={userType}
                        onChange={(e) => setUserType(e.target.value)}
                        className="border border-gray-300 p-1 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 bg-gray-100"
                      >
                        <option value="">Select user type</option>
                        {userTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </label>

                    <label className="flex items-center group">
              <span className="w-48 text-sm font-medium text-gray-500">
                Accounting System ID 
              </span>
              <span className="ml-4">:</span>
              <input
                type="text"
                value={accountingSystemId}
                disabled
                onChange={(e) => setAccountingSystemId(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                required
              />
                 {errors.accountingSystemId && (
                <p className="text-red-500 text-sm ml-4">{errors.accountingSystemId}</p>
              )}
            </label>
            </div>
            <div className="flex flex-col   gap-y-6 mt-6">

            <label className="flex items-center group">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        Salute 
                      </span>
                      <span className="ml-4">:</span>
                      <input
                        type="text"
                        value={salute}
                        disabled
                        onChange={(e) => setSalute(e.target.value)}
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                        required
                      />
                      {errors.salute && (
                        <p className="text-red-500 text-sm ml-4">{errors.salute}</p>
                      )}
                    </label>

                    <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Surname
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={surname}
                  disabled
                  onChange={(e) => setSurname(e.target.value)}
                  required
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
             
            </label>
            <label className="flex items-center">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        First Name<span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        value={firstName}
                        disabled
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-5"
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-sm ml-4">{errors.firstName}</p>
                      )}

            </label>

            <label className="flex items-center">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        Middle Name
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        value={middleName}
                        disabled
                        onChange={(e) => setMiddleName(e.target.value)}
                        required
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      />
                    </label>

                    <label className="flex items-center">
                      <span className="w-48 text-sm font-medium text-gray-500">
                        Last Name <span className="text-red-500 ml-1">*</span>
                      </span>
                      <span className="ml-3">:</span>
                      <input
                        type="text"
                        value={lastName}
                        disabled
                        onChange={(e) => setLastName(e.target.value)}
                        className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-5 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                        required
                      />
                      {errors.lastName && (
                        <p className="text-red-500 text-sm ml-4">{errors.lastName}</p>
                      )}
                    </label>
                    <div className="flex flex-row mt-12">
                    {/* <button
              // onClick={handleContactDetails}
              className=" mt-0 ml-0 bg-blue-500 text-white border border-blue-500 text-sm text-blue-500 py-1 px-2   rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30 "
            >
              Reset password
            </button> */}

                  </div>
                  </div>
                  <div className="flex flex-row mt-12 ">

<button 
   onClick={nextabMoveToContact}
   className="mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16">
Next
</button>
</div>

                </div>
              )}

              
            {/* </div> */}

            {/* <div key={user.id}> */}
          {activeTab === "contactDetails" && (
        <div className="p-12">
          <div className="flex flex-col   gap-y-12 mt-6">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Email ID <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                disabled
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
              {errors.email && (
                <p className="text-red-500 text-sm ml-4">{errors.email}</p>
              )}
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500 ">
                Mobile No <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-12">:</span>
              
              <PhoneInput
                className="ml-3 text-gray-500"
                country={'us'} // Default country
                value={mobileNo}
                onChange={() => {}} // No need to change the value
                disabled={true} // Make it read-only
              />

              {/* {errors.mobileNo && (
                <p className="text-red-500 text-sm ml-4">{errors.mobileNo}</p>
              )} */}
            </label>


<label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Mobile No <span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-12">:</span>
  
  <PhoneInput
    className="ml-3 text-gray-500"
    country={'us'} // Default country
    value={whatsappNo}
    onChange={() => {}} // No need to change the value
    disabled={true} // Make it read-only
  />

  {/* {errors.mobileNo && (
    <p className="text-red-500 text-sm ml-4">{errors.mobileNo}</p>
  )} */}
</label>

       

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Office No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={officeNo}
                disabled
                onChange={(e) => setOfficeNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Extension
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={extension}
                disabled
                onChange={(e) => setExtension(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
          </div>

          <div className="flex flex-row mt-12 ">

<button 
   onClick={nextabMoveToIdentity}
   className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
Next
</button>

</div>

          <div className="flex flex-row mt-12 ">
 
          </div>
        </div>
      )}
      {/* </div> */}
      {/* <div key={user.id}> */}
      {activeTab === "identity" && (
        <div className="p-8">
          <div className="flex flex-col   gap-y-12 mt-6">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Passport No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={passportNo}
                disabled
                onChange={(e) => setPassportNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Driving Licence No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={drivingLicenseNo}
                disabled
                onChange={(e) => setDrivingLicenseNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Local ID
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={localId}
                disabled
                onChange={(e) => setLocalId(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
              Nationality<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={nationality}
                disabled
                onChange={(e) => setNationality(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
              {errors.nationality && (
                <p className="text-red-500 text-sm ml-4">
                  {errors.nationality}
                </p>
              )}
            </label>
          </div>


          <div className="flex flex-row mt-12">
          <button 
             onClick={nextabMoveToOrganization}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button>


          </div>
        </div>
      )}
      {/* </div> */}

      {/* <div key={user.id}> */}

      {activeTab === "organization" && (
        <div className="p-12">
          <div className="flex flex-col   gap-y-8 mt-6">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Employee No
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={employeeNo}
                disabled
                onChange={(e) => setemployeeNo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Company Code
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={companyCodeId}
                disabled
                onChange={(e) => setcompanyCodeId(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Department
              </span>
              <span className="ml-3">:</span>
              <input
                type="text"
                value={department}
                disabled
                onChange={(e) => setdepartment(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Valid From <span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={validFrom}
                disabled
                onChange={(e) => setvalidFrom(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Valid To<span className="text-red-500 ml-1">*</span>
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={validTo}
                disabled
                onChange={(e) => setvalidTo(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>

            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Joining Date
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={joiningDate}
                disabled
                onChange={(e) => setjoiningDate(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
            <label className="flex items-center">
              <span className="w-48 text-sm font-medium text-gray-500">
                Resignation date
              </span>
              <span className="ml-3">:</span>
              <input
                type="date"
                value={resignationDate}
                disabled
                onChange={(e) => setresignationDate(e.target.value)}
                className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              />
            </label>
          </div>
          <div className="flex flex-row my-12 ">
          <button 
             onClick={nextabMoveToAddress}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button>
           
          </div>
        </div>
      )}
      {/* </div> */}


      {/* <div key={user.id}> */}
      {activeTab === "addressDetails" && (
  <div className="p-12">
    {errors.userId && (
      <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
    )}

    {/* Building Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Building</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Door No </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={doorNo} 
            disabled
            onChange={(e) => setDoorNo(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Floor No </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={floorNo} 
            disabled
            onChange={(e) => setFloorNo(e.target.value)}
            placeholder="4" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Building No </label>
          <span className="ml-4">:</span>
          <input 
            type="text"
            value={buildingNo} 
            disabled
            onChange={(e) => setBuildingNo(e.target.value)}
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Building Name </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={buildingName} 
            disabled
            onChange={(e) => setBuildingName(e.target.value)}
            placeholder="B name" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>

      <div className="flex items-center mt-4">
        <label className="w-48 text-sm font-medium text-gray-500">Landmark</label>
        <span className="ml-4">:</span>
        <input 
          type="text" 
          value={landMark} 
          disabled
          onChange={(e) => setLandMark(e.target.value)}
          className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
        />
      </div>
    </div>

    {/* Street Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Street</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Street No </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            value={streetNo} 
            disabled
            onChange={(e) => setStreetNo(e.target.value)}
            placeholder="2/626" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Street Name </label>
          <span className="ml-4">:</span>
          <input 
            type="text"  
            disabled
            value={streetName} 
            onChange={(e) => setStreetName(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
          {/* {errors.streetName && (
            <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
          )} */}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Road No </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={roadNo} 
            onChange={(e) => setRoadNo(e.target.value)}
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Road Name </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={roadName} 
            onChange={(e) => setRoadName(e.target.value)}
            placeholder="B name" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>
    </div>
  {/* Display the error message for address section */}
  {errors.addressSection && (
                <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
              )}

    {/* Area Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Area</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Block </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={areaBlock} 
            onChange={(e) => setAreaBlock(e.target.value)}
            placeholder="2/626" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Area Name </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={areaName} 
            onChange={(e) => setAreaName(e.target.value)}
            placeholder="4" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>
    </div>

    {/* Address Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Address</h3>
      
      <div className="grid grid-cols-1 gap-4">
        <div className="flex items-center">
          <label className="w-56 text-sm font-medium text-gray-500">Address 1 </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={addressLine1} 
            onChange={(e) => setAddressLine1(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
          {errors.addressLine1 && (
            <p className="text-red-500 text-sm ml-4">
              {errors.addressLine1}
            </p>
          )}
        </div>
        <div className="flex items-center">
          <label className="w-56 text-sm font-medium text-gray-500">Address 2 </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={addressLine2} 
            onChange={(e) => setAddressLine2(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-56 text-sm font-medium text-gray-500">Address 3 </label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={addressLine3} 
            onChange={(e) => setAddressLine3(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>
    </div>

    {/* Country Section */}
    <div className="mb-6 border border-gray-300 p-5 rounded-lg">
      <h3 className="text-lg font-semibold text-gray-500 mb-4">Country</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
      <label className="w-48 text-sm font-medium text-gray-500">Country code  <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          {/* <input 
            type="text" 
            disabled
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            placeholder="2/626" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          /> */}

          <button
            className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray bg-gray-100 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{countryCode || 'Select Country Code'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>



      {/* <select
              value={countryCode}
              disabled
              // onChange={handleCountryCodeChange}
              className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-500 bg-gray-100 focus:outline-none hover:border-blue-400 ml-4"
            >
              <option value=""disabled>Select Country Code</option>
              {countries.map((country) => (
                <option  key={country.id} value={country.id}>
                  {country.country}
                </option>
              ))}
            </select> */}

            {/* {errors.countryCode && (
                    <p className="text-red-500 text-sm ml-4">{errors.countryCode}</p>
                  )} */}
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Country Name <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={countryName}
            // onChange={(e) => setCountryCode(e.target.value)}
            placeholder="2/626" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          /> 


          {/* <select
            value={countryId} 
            disabled
            onChange={(e) => setCountryId(e.target.value)}
            className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-500 bg-gray-100 focus:outline-none hover:border-blue-400 ml-4"
          >
            <option value="" disabled>
              Select Country
            </option>
            {countries.map((type) => (
              <option key={type.id} value={type.id}>
                {type.countryName}
              </option>
            ))}
          </select> */}
          {errors.countryId && (
            <p className="text-red-500 text-sm ml-4">{errors.countryId}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">State / Province<span className="text-red-500 ml-1">*</span> </label>
          <span className="ml-4">:</span>
          <button
            className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray bg-gray-100 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{state || 'Select State / Province'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>

        </div>

        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">State / Province Name</label>
          <span className="ml-4">:</span>
          <input 
            type="text"
            disabled
            value={stateName} 
            // onChange={(e) => setState(e.target.value)}
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>

        
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">District</label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={district} 
            onChange={(e) => setDistrict(e.target.value)}
            placeholder="2A" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Postal Code</label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={postalCode} 
            onChange={(e) => setPostalCode(e.target.value)}
            placeholder="B name" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Zip Code</label>
          <span className="ml-4">:</span>
          <input 
            type="text"  
            disabled
            value={zipCode} 
            onChange={(e) => setZipCode(e.target.value)}
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">PO Box</label>
          <span className="ml-4">:</span>
          <input 
            type="text" 
            disabled
            value={poBox} 
            onChange={(e) => setPoBox(e.target.value)}
            placeholder="B name" 
            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" 
          />
        </div>
      </div>
    </div>
   
    <div className="flex flex-row mt-2 mb-10">
      <button 
        onClick={nextabMoveToPreferance}
        className=" mt-4 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 "
      >
        Next
      </button>
    </div>
  </div>
)}

      {/* </div> */}

      {/* <div key={user.id}> */}
      {activeTab === "userPreferences" && (
        <div className="p-12">
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 mt-6">
            <div className="flex flex-col space-y-6">
              {errors.userId && (
                <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
              )}

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Language<span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled
                  value={language}
                  onChange={(e) => setlanguage(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                />
                {errors.language && (
                  <p className="text-red-500 text-sm ml-4">{errors.language}</p>
                )}
              </label>

              <label className="flex items-center">
  <span className="w-48 text-sm font-medium text-gray-500">
    Date Format<span className="text-red-500 ml-1">*</span>
  </span>
  <span className="ml-3">:</span>
  <select
    value={dateFormat}
    disabled
    onChange={(e) => setdateFormat(e.target.value)}
    className="border border-gray-300 p-1 rounded w-72 h-8 text-gray-500 bg-gray-100 focus:outline-none hover:border-blue-400 ml-4"
  >
        <option value="">Select Date Format</option>
    <option value="1">DD-MM-YYYY</option>
    <option value="2">MM-DD-YYYY</option>
    <option value="3">DD-MM-YY</option>
    <option value="4">MM-DD-YY</option>
    <option value="5">DD.MM.YYYY</option>
    <option value="6">DDMMYY</option>
  </select>
  {errors.dateFormat && (
    <p className="text-red-500 text-sm ml-4">
      {errors.dateFormat}
    </p>
  )}
</label>


<label className="flex items-center">
        <span className="w-48 text-sm font-medium text-gray-500">
          Amount Format
        </span>
        <span className="ml-3">:</span>
         <select
                value={amountFormat}
                disabled
                onChange={(e) => setamountFormat(e.target.value)}
                className={`border border-gray-300 p-1 rounded w-72 h-8 text-gray-500 bg-gray-100 focus:outline-none hover:border-blue-400 ml-4`}
              >
                <option value="">Select Amount Format</option>
            
                <option value="'1,234,567.89">'1,234,567.89</option>
                <option value="1.234.567,890">1.234.567,890</option>
                <option value="12,34,567.890">12,34,567.890</option>            
              </select>
      </label>


              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Thousand Separator
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled
                  value={thousandSeparator}
                  onChange={(e) => setthousandSeparator(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Decimal Seperator
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled
                  value={decimalSeparator}
                  onChange={(e) => setdecimalSeparator(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Accelerator for thousands
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled
                  value={acceleratorForThousands}
                  onChange={(e) => setacceleratorThousands(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Accelerator for Lakhs
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled
                  value={acceleratorForLakhs}
                  onChange={(e) => setacceleratorForLakhs(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Accelerator for Millions
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  disabled
                  value={acceleratorForMillions}
                  onChange={(e) => setacceleratorMillions(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>

              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Time Format <span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-3">:</span>
                <select
                disabled
                  value={timeFormat}
                  onChange={(e) => settimeFormat(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 bg-gray-100 focus:outline-none hover:border-blue-400 ml-4"
                >
                  <option value="2">24 Hours</option>
                  <option value="1">12 Hours</option>
                  <option value="">Select Time Format</option>
                </select>
                {errors.timeFormat && (
                  <p className="text-red-500 text-sm ml-4">
                    {errors.timeFormat}
                  </p>
                )}
              </label>


              <label className="flex items-center">
                <span className="w-48 text-sm font-medium text-gray-500">
                  Time Zone
                </span>
                <span className="ml-3">:</span>
                <input
                  type="text"
                  value={timeZone}
                  disabled
                  onChange={(e) => settimeZone(e.target.value)}
                  className="border border-gray-300 p-1.5 rounded w-72 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                />
              </label>
            </div>

            <div className="flex flex-col space-y-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  disabled 
                  checked={receiveSystemMail === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setreceiveSystemMail(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500 "
                />
                <p className="ml-4 font-small text-gray-600">
                  Dont want to receive System emails
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  disabled 
                  checked={downloadData === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setdownloadData(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Download the Data
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  disabled 
                  checked={changesInCashflow === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setchangesInCashflow(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Change the Cash flow code
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  disabled 
                  // value={changeVarient}
                  // onChange={(e) => setchangeVarient(e.target.value)}
                  checked={changeVarient === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setchangeVarient(e.target.checked ? 1 : 0)} // Update state based on checkbox state

                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Can Change the varient
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  disabled 
                  // value={deleteAllRecords}
                  // OnChange={(e) => setdeleteAllRecords(e.target.value)}
                  checked={deleteAllRecords === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setdeleteAllRecords(e.target.checked ? 1 : 0)} // Update state based on checkbox state

                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Can Delete all records
                </p>
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  disabled 
                  checked={changeAccountingCode === 1} // Check if receiveSystemMail is 1
                  onChange={(e) => setchangeAccountingCode(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Change the Accounting Code
                </p>
              </label>
              <label className="flex items-center">
                <input
                // value={changeAccountingCode}
                // onChange={(e) => setchangeAccountingCode(e.target.value)}
                checked={updateCountry === 1} // Check if receiveSystemMail is 1
                onChange={(e) => setupdateCountry(e.target.checked ? 1 : 0)} // Update state based on checkbox state
                  type="checkbox"
                  disabled 
                  className="form-checkbox ml-2 text-blue-500 border-blue-300 rounded checked:bg-blue-500 checked:border-blue-500"
                />
                <p className="ml-4 font-small text-gray-600">
                  Do Not Update Country
                </p>
              </label>
            </div>
          </div>

          <div className="flex flex-row mt-12">
          <button 
             onClick={nextabMoveToMethod}
             className=" mt-4 bg-customBlue text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ">
    Next
  </button>
           
          </div>
        </div>
      )}
      {/* </div> */}
      {/* <div key={user.id}> */}
      {activeTab === "loginMethod" && (
        <div className="p-12">
          <div className="mt-8 space-y-8">
            {errors.userId && (
              <p className="text-red-500 text-sm ml-4">{errors.userId}</p>
            )}

            <h3>Login Method</h3>
            <div className="flex flex-col space-y-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  disabled 
                  value={oneTimePassword} // Value mapped to 0 for Password
                  checked={loginMethod == 0}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2">Password</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  disabled 
                  value={oneTimePassword} // Value mapped to 1 for Multi-Factor Authentication
                  checked={loginMethod == 1}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2">Multi-Factor Authentication</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="loginMethod"
                  disabled 
                  value={oneTimePassword} // Value mapped to 2 for OTP
                  checked={loginMethod == 2}
                  onChange={handleLoginMethodChange}
                  className="form-radio text-blue-500 w-4 h-4"
                />
                <span className="ml-2">OTP</span>
              </label>
            </div>
          </div>
          <div className="flex flex-row mt-12">
          
          </div>
        </div>
      )}


      {/* </div> */}

        </div>

      
      
{/* <div className="flex flex-col items-center">
      <div className="mt-50px flex w-full bg-gray-50 dark:bg-zinc-800 p-4 fixed bottom-0 ">
        <button
          onClick={handleSaveBasicDetails}
          className="bg-blue-500 text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-48"
        >
          Save
        </button>
        <button
          onClick={handleSaveContinueDetails}
          className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
        >
          Save & Continue
        </button>
      </div>
    </div> */}
      
    </div>
  );
}


export default UserViewScreen;
