import RestService from "./RestServices";

const basePath = "/process";


const GlobalProcessServices = {
    getProcessDetailsById: (id, subModule, iconId) => {
        return RestService.GetAllData(`${basePath}/get-process-byID?id=${id}&subModule=${subModule}&iconId=${iconId}`);
    },
    getAllProcessDetails: (pageSize, page, subModule, iconId) => {
        return RestService.GetAllData(`${basePath}/get-all-process?limit=${pageSize}&offset=${(page - 1) * pageSize}&subModule=${subModule}&iconId=${iconId}`);
    },
    downloadData: (subModule, iconId) => {
        return RestService.ExportData(`${basePath}/download-process?subModule=${subModule}&iconId=${iconId}`);
    },
}
export default GlobalProcessServices;