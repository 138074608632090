import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {

  IoMdCreate,
  IoMdRefresh,

} from "react-icons/io";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import { MdOutlineFileDownload } from "react-icons/md";
import * as XLSX from "xlsx";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";

import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";
import Pagination from "../../../../../components/PageNation";
import { debounce } from "lodash";
import UserService from "../../../../../rest-services/UserServices";

export default function CalendarScreen() {
  const { iconId } = useParams();
  const [calendarId, setCalendarId] = useState(null); // To store the selected calendar ID
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const{toast}=useToast();
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [filteredCalendars, setFilteredCalendars] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);
    const [rolesPermissions, setRolesPermissions] = useState([]);
    const [assignee, setAssignee] = useState({ id: "", username: "" });
const [alert, setAlert] = useState(null);

    useEffect(() => {
      fetchAssignee();
    }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

    useEffect(() => {
      if (assignee.assignerId) {
        console.log("Assigner ID has changed:", assignee.assignerId);
        fetchUserPermissionDetails();
      }
    }, [assignee.assignerId]);

  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.assignerId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("user permissions details", response);
      const responseData = response.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
    } catch (error) {
      console.error(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const columns = [
  { Header: "Calendar", accessor: "Calendar", visible: true, width:("Calendar".length -2)*10 ,alignment: "left" },
  { Header: "Calendar Name", accessor: "Calendar_Name", visible: true, width:("Calendar Name".length -2)*10 ,alignment: "left"},
  { Header: "Calendar Type", accessor: "calendarType", visible: true, width:("Calendar Type".length -2)*10 ,alignment: "left"},
  { Header: "Created By", accessor: "created_by", visible: true, width:("Created By".length -2)*10 ,alignment: "left"},
  {
    Header: "Created Date",
    accessor: "creationDate",
    visible: true,
    width:("Created Date".length -2)*10,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Change here
    }
  },
  {
    Header: "Created Time",
    accessor: "creationTime", 
    visible: true,
    width:("Created Time".length -2)*10,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("en-GB", {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',

        hour12: true, 
      });
    }
  },
  { Header: "Updated By", accessor: "changed_by", visible: true, width:("Updated By".length -2)*10, canResize: true ,alignment: "left"},
  {
    Header: "Updated Date",
    accessor: "changedDate",
    visible: true,
    width:("Updated Date".length -2)*10,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Change here
    }
  },
  {
    Header: "Updated Time",
    accessor: "changedTime", 
    visible: true,
    width:("Updated Time".length -2)*10,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("en-GB", {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',

        hour12: true, 
      });
    }
  },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  useEffect(() => {
     fetchCalendars(currentPage, searchQuery);
   }, [pageSize, currentPage]);

  const fetchCalendars = async (page = 1, searchQuery = "") => {
    setLoading(true);
    try {
      const response = await CalendarServices.getAllCalendarwithPage(
        pageSize,
        page,
        searchQuery
      );
      let { calendar, totalCount } = response.data.data;
      if (!calendar) {
        calendar = [];
      } else if (!Array.isArray(calendar)) {
        calendar = [calendar];
      }
      setFilteredCalendars(calendar);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching Calendar data", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced fetch function (to avoid too many API calls)
  const debouncedFetchCalendars = useCallback(
    debounce((page, query) => {
      fetchCalendars(page, query);
    }, 500), // 500ms debounce delay
    []
  );

  // UseEffect to trigger API call on query or page change
  useEffect(() => {
    debouncedFetchCalendars(currentPage, searchQuery);
    // Cleanup debounce on unmount
    return () => debouncedFetchCalendars.cancel();
  }, [currentPage, searchQuery, debouncedFetchCalendars]);
  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchCalendars(1, searchQuery); // Fetch data with updated page size
  };
  // Handle checkbox change (selection)
  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

    setSelectedRows(updatedSelectedRows);
    setCalendarId(id); // Set the selected calendar ID for navigation or further actions
    setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };

  const modifiedColumns = columnSettings.filter(col => col.visible).map(col => ({
    ...col,
    width: col.width // This should hold the updated width
  }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  // Function to delete calendar
  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this calendar?");
    if (confirmed) {
      setLoading(true);
      try {
         CalendarServices.deleteCalendar(id);
        toast("success","Calendar deleted successfully");
        // Refetch calendars after deletion
        fetchCalendars();
      } catch (error) {
        console.error("Error deleting calendar:", error);
        toast("Failed to delete calendar");
      } finally {
        setLoading(false);
      }
    }
  };

  // Extra buttons that appear when rows are selected
  const extraButtons = [
   
    {
      icon: IoMdCreate,
      onClick: () => handleNavigate(`/calendar-add/${calendarId}`),
      title:"Edit calendar",
      disabled: selectedRows.length === 0, 
    },
 
    // {
    //   icon: IoMdTrash,
    //   className: "text-red-500", // Optional: Add red color to trash icon
    //   onClick: () => handleDelete(calendarId), // Call delete function
    // },
  ];
  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredCalendars.map((calendar) => ({
      "Calendar": calendar.Calendar,
      "Calendar Name": calendar.Calendar_Name,
      "Created By": calendar.created_by,
      "Created Date": calendar.creationDate
        ? new Date(calendar.creationDate).toLocaleDateString()
        : "",
      "Created Time": calendar.creationTime
        ? new Date(calendar.creationTime).toLocaleTimeString()
        : "",
      "Updated By": calendar.changed_by,
      "Updated Date": calendar.changedDate
        ? new Date(calendar.changedDate).toLocaleDateString()
        : "",
      "Updated Time": calendar.changedTime
        ? new Date(calendar.changedTime).toLocaleTimeString()
        : "",
    }));
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Calendars");
  
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Calendars_Data.xlsx");
  };
  const handleReload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchCalendars();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };
  // Configuring existing buttons
  const existingButtons = PrimaryActionsConfig.screen5.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add Calendar"
        : button.icon === MdOutlineFileDownload
        ? "Download"
        : button.icon === IoSettingsOutline
        ? "Settings"
            : button.icon === IoMdRefresh
      ? "Refresh"
        : button.title || "", // If a title already exists, use it, otherwise empty
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/calendar-add")
        : button.icon === MdOutlineFileDownload 
        ? handleDownload
        : button.icon === IoSettingsOutline
        ? handleSettingsButtonClick
        : button.icon === IoMdRefresh
        ? handleReload
        : button.onClick || (() => {}),
  }));
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );
  // Navigation helper function
  const handleNavigate = (path) => {
    navigate(path);
    
  };
  const handleDoubleClick = (id) => {
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );

    console.log("Check user permissions with iconId", hasPermission);

    if (hasPermission) {
    navigate(`/calendar-view/${id}`, { state: { mode: 'view' } });
    console.log("Navigated to user view screen.");
  } else {
    console.log(
      "No permission with permissionsId === 3 and matching iconId, navigation skipped."
    );
  }
  };

  // Fetch calendars on component mount
   const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = filteredCalendars.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };
  if (loading) return <div></div>;
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {
      console.log("Navigating to /2/master-data");
      navigate('/2/master-data');
    } else if (crumb.title === 'Calendar') {
      console.log("Navigating to /country");
      navigate('/2/master-data/3/calendar');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
   
      <Header title={"Primary Data > Global Data > Calendar"
      } onBreadcrumbClick={handleBreadcrumbClick}
      backRoute={"/2/master-data"}
      />
     
     <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">

        <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Calendar"
              maxLength={2}
            />


          <PrimaryActions
          icons={iconId}
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            isExtraButtonsVisible={showExtraIcons}
            selectIds={selectedRows}
          />
          </div>
          <div className="flex items-center mr-4">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
            onShowAll={handleShowAll}
          />
          
        </div>
        </div>

        <div>
         
        </div>
        <ReTable
          data={filteredCalendars}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      
    </div>
    
  );
}
