import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { RiMenuSearchLine, RiUser2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { BsCheckSquare } from "react-icons/bs";
import { LiaUserLockSolid } from "react-icons/lia";
import { TbLockCheck } from "react-icons/tb";
import { MdOutlineAssignmentReturn } from "react-icons/md";
import { TbUserHeart } from "react-icons/tb";
import { FaClipboardUser } from "react-icons/fa6";
import { MdOutlineBookmarkAdded, MdOutlineGroups, MdCurrencyYen, MdOutlinePentagon } from "react-icons/md";
import { TbArrowsUpDown } from "react-icons/tb";
import { FaListCheck } from "react-icons/fa6";
import { GoWorkflow } from "react-icons/go";
import { LuCalendarClock, LuRedoDot } from "react-icons/lu";
import { IoCalendarOutline, IoPersonAddOutline, IoTimeOutline } from "react-icons/io5";
import { FaRegAddressCard, FaRegUser } from "react-icons/fa6";
import { useSearch } from "../../../context/SearchContext";
import Card from "../../../components/Card";
import MainHeader from "../../../components/MainHeader";
import Dashboard from "../../../components/Dashboard";
import { useToast } from "../../../components/toast/toast";
import UserService from "../../../rest-services/UserServices";

import { PiSuitcaseSimple } from "react-icons/pi";
export default function AdminScreen() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const [usermasterOpen, setUsermasterOpen] = useState(true);
  const [passwordPolicyOpen, setPasswordPolicyOpen] = useState(true);
  const [licensesOpen, setLicensesOpen] = useState(true);
  const [workflowOpen, setWorkflowOpen] = useState(true);
  const { highlightInfo } = useSearch();
  const [highlightedCard, setHighlightedCard] = useState(null);
  const navigate = useNavigate();
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const { toast } = useToast();

  const [hasPermissionForUser, SethasPermissionForUser] = useState("");
  const [hasPermissionForMakerAndChecker, SethasPermissionForMakerAndChecker] = useState("");
  const [hasPermissionForRoles, SethasPermissionForRoles] = useState("");
  const [hasPermissionForPermissions, SethasPermissionForPermissions] = useState("");
  const [hasPermissionForKeyData, SethasPermissionForKeyData] = useState("");
  const [hasPermissionForPasswordPolicy, SethasPermissionForPasswordPolicy] = useState("");
  const [hasPermissionForUserType, SethasPermissionForUserType] = useState("");
  const [hasPermissionForModuleAssignment, SethasPermissionForModuleAssignment] = useState("");
  const [hasPermissionForLicenseSummary, SethasPermissionForLicenseSummary] = useState("");

  // Handle highlight when component mounts or highlightInfo changes
  useEffect(() => {
    if (highlightInfo?.dashboard === 'admin') {
      const term = highlightInfo.term.toLowerCase();
      const cardTitles = [
        "user", "maker & checker", "roles", "permissions", "key data",
        "password policy", "user type", "module assignment", "licenses summary",
        "wf id", "approve type", "approval group", "approval sequence",
        "approval status", "workflow", "approval tracker"
      ];

      const matchedTitle = cardTitles.find(title =>
        title.toLowerCase() === term
      );

      if (matchedTitle) {
        setHighlightedCard(matchedTitle);
        const timer = setTimeout(() => {
          setHighlightedCard(null);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [highlightInfo]);

  // Fetch data on component mount
  useEffect(() => {
    const fetchUserId = () => {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    axios.get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(menu => menu.mainMenuId === 14);
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
      .then((response) => {
        const filteredAssignments = response.data.filter(
          (assignment) =>
            assignment.userId === String(userId) && assignment.isChecked === true
        );
        setModuleAssignments(filteredAssignments);
      })
      .catch((error) => console.error("Error fetching module assignments:", error));
  }, [userId]);


  useEffect(() => {
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        console.error({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      console.error({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  useEffect(() => {
    if (assignee.assignerId) {
      console.log("Assigner ID has changed:", assignee.assignerId);
      fetchUserIconAccessPermission();
    }
  }, [assignee.assignerId]);

  const fetchUserIconAccessPermission = async () => {
    try {
      const data = { userId: assignee.assignerId };
      // console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-Icon-access-permission-by-userId`,
        data
      );
      const responseData = response.data;
      console.log("user icon permissions details", responseData);

      const hasPermissionForUser = responseData.some(
        (Icon) => Icon.iconId == 108 && Icon.isChecked == 1
      );

      const hasPermissionForMakerAndChecker = responseData.some(
        (Icon) => Icon.iconId == 110 && Icon.isChecked == 1
      );

      const hasPermissionForRoles = responseData.some(
        (Icon) => Icon.iconId == 111 && Icon.isChecked == 1
      );

      const hasPermissionForPermissions = responseData.some(
        (Icon) => Icon.iconId == 112 && Icon.isChecked == 1
      );

      const hasPermissionForKeyData = responseData.some(
        (Icon) => Icon.iconId == 109 && Icon.isChecked == 1
      );

      const hasPermissionForPasswordPolicy = responseData.some(
        (Icon) => Icon.iconId == 113 && Icon.isChecked == 1
      );

      const hasPermissionForUserType = responseData.some(
        (Icon) => Icon.iconId == 115 && Icon.isChecked == 1
      );

      const hasPermissionForModuleAssignment = responseData.some(
        (Icon) => Icon.iconId == 114 && Icon.isChecked == 1
      );

      const hasPermissionForLicenseSummary = responseData.some(
        (Icon) => Icon.iconId == 136 && Icon.isChecked == 1
      );

      SethasPermissionForUser(hasPermissionForUser);
      SethasPermissionForMakerAndChecker(hasPermissionForMakerAndChecker);
      SethasPermissionForRoles(hasPermissionForRoles);
      SethasPermissionForPermissions(hasPermissionForPermissions);
      SethasPermissionForKeyData(hasPermissionForKeyData);
      SethasPermissionForPasswordPolicy(hasPermissionForPasswordPolicy);
      SethasPermissionForUserType(hasPermissionForUserType);
      SethasPermissionForModuleAssignment(hasPermissionForModuleAssignment);
      SethasPermissionForLicenseSummary(hasPermissionForLicenseSummary);

    } catch (error) {
      console.error(error.message); // Set error message
    }
  };

  const UserPermission = async () => {
    toast("error", "You don't have permission to view this User icon.");
  };

  const MakerAndCheckerPermission = async () => {
    toast("error", "You don't have permission to view this Maker And Checker icon.");
  };
  const RolesPermission = async () => {
    toast("error", "You don't have permission to view this Roles icon.");
  };

  const PermissionsPermission = async () => {
    toast("error", "You don't have permission to view this Permissions icon.");
  };

  const KeyDataPermission = async () => {
    toast("error", "You don't have permission to view this Key Data icon.");
  };
  const PasswordPolicyPermission = async () => {
    toast("error", "You don't have permission to view this Password Policy icon.");
  };
  const UserTypePermission = async () => {
    toast("error", "You don't have permission to view this User Type icon.");
  };

  const ModuleAssignmentPermission = async () => {
    toast("error", "You don't have permission to view this Module Assignment icon.");
  };

  const LicenseSummaryPermission = async () => {
    toast("error", "You don't have permission to view this License Summary icon.");
  };

  const handleCardClick = (route) => {
    navigate(route);
  };

  const toggleUserMaster = () => setUsermasterOpen(!usermasterOpen);
  const togglePasswordPolicy = () => setPasswordPolicyOpen(!passwordPolicyOpen);
  const toggleLicenses = () => setLicensesOpen(!licensesOpen);
  const toggleWorkflow = () => setWorkflowOpen(!workflowOpen);

  // Filter and sort sections
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  const submenuOrder = ["User & SOD", "Password Policy", "Licenses", "Workflow", "Interface Setup", "Others"];
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  // Create sections with highlighted cards
  const sections = orderedSections.map((menu) => {
    let cards;

    if (menu.name === "User & SOD") {
      cards = [
        {
          title: "User",
          icon: <IoPersonAddOutline />,
          isHighlighted: highlightedCard === "user",
          onClick: hasPermissionForUser === false
            ? UserPermission
            : () => handleCardClick("/14/admin/108/userscreen"),
        },
        {
          title: "Maker & Checker",
          icon: <BsCheckSquare />,
          isHighlighted: highlightedCard === "maker & checker",
          onClick: hasPermissionForMakerAndChecker === false
            ? MakerAndCheckerPermission
            : () => handleCardClick("/14/admin/110/maker-and-checker"),
        },
        {
          title: "Roles",
          icon: <FaRegUser />,
          isHighlighted: highlightedCard === "roles",
          // onClick: hasPermissionForRoles === false
          // ? RolesPermission
          // : () => handleCardClick("/14/admin/111/roles-and-permission/roles"),
          onClick: () => handleCardClick("/14/admin/111/roles-and-permission/roles"),
        },
        {
          title: "Permissions",
          icon: <LiaUserLockSolid />,
          isHighlighted: highlightedCard === "permissions",
          // onClick: hasPermissionForPermissions === false
          // ? PermissionsPermission
          // : () => handleCardClick("/14/admin/112/roles-and-permission"),
          onClick: () => handleCardClick("/14/admin/112/roles-and-permission"),
        },
        {
          title: "Key Data",
          icon: <RiMenuSearchLine />,
          isHighlighted: highlightedCard === "key data",
          onClick: hasPermissionForKeyData === false
            ? KeyDataPermission
            : () => handleCardClick("/"),
        },
      ];
    }
    else if (menu.name === "Password Policy") {
      cards = [
        {
          title: "Password Policy",
          icon: <TbLockCheck />,
          isHighlighted: highlightedCard === "password policy",
          onClick: hasPermissionForPasswordPolicy === false
            ? PasswordPolicyPermission
            : () => handleCardClick("/14/admin/passwordscreen/113"),
        },
      ];
    }
    else if (menu.name === "Licenses") {
      cards = [
        {
          title: "User Type",
          icon: <TbUserHeart />,
          isHighlighted: highlightedCard === "user type",
          onClick: hasPermissionForUserType === false
            ? UserTypePermission
            : () => handleCardClick("/14/admin/user-type-screen/115"),
        },
        {
          title: "Module Assignment",
          icon: <MdOutlineAssignmentReturn />,
          isHighlighted: highlightedCard === "module assignment",
          onClick: hasPermissionForModuleAssignment === false
            ? ModuleAssignmentPermission
            : () => handleCardClick("/14/admin/module-assignment/114"),
        },
        {
          title: "Licenses Summary",
          icon: <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} viewBox="0 0 20 20"><path fill="currentColor" d="M1 7h18v2H1zm0 4h14v2H1z"></path></svg>,
          isHighlighted: highlightedCard === "licenses summary",
          onClick: hasPermissionForLicenseSummary === false
            ? LicenseSummaryPermission
            : () => handleCardClick("/14/admin/license-summary/136"),
        },
      ];
    }
    else if (menu.name === "Workflow") {
      cards = [
        {
          title: "WF ID",
          icon: <FaRegAddressCard />,
          onClick: () => handleCardClick("/"),
          isHighlighted: highlightedCard === "wf id"
        },
        {
          title: "Approve Type",
          icon: <MdOutlineBookmarkAdded />,
          onClick: () => handleCardClick("/"),
          isHighlighted: highlightedCard === "approve type"
        },
        {
          title: "Approval Group",
          icon: <MdOutlineGroups />,
          onClick: () => handleCardClick("/"),
          isHighlighted: highlightedCard === "approval group"
        },
        {
          title: "Approval Sequence",
          icon: <TbArrowsUpDown />,
          onClick: () => handleCardClick("/"),
          isHighlighted: highlightedCard === "approval sequence"
        },
        {
          title: "Approval Status",
          icon: <FaListCheck />,
          onClick: () => handleCardClick("/"),
          isHighlighted: highlightedCard === "approval status"
        },
        {
          title: "WorkFlow",
          icon: <GoWorkflow />,
          onClick: () => handleCardClick("/"),
          isHighlighted: highlightedCard === "workflow"
        },
        {
          title: "Approval Tracker",
          icon: <LuRedoDot />,
          onClick: () => handleCardClick("/"),
          isHighlighted: highlightedCard === "approval tracker"
        },
      ];
    } else if (menu.name === "Interface Setup") {
      cards = [
        { title: "Client SFTP User ID", icon: <RiUser2Line />, onClick: () => handleCardClick("/14/admin/137/client-sftp-user-screen/137") },
        { title: "Job Creation", icon: <PiSuitcaseSimple />, onClick: () => handleCardClick("/") },
        { title: "Times", icon: <IoTimeOutline />, onClick: () => handleCardClick("/") },
        { title: "Schedule", icon: <LuCalendarClock />, onClick: () => handleCardClick("/14/admin/schedule-screen") },
       
      ];
    } else if (menu.name === "Others") {
      cards = [
        { title: "Holiday Calender", icon: <IoCalendarOutline />, onClick: () => handleCardClick("/14/admin/141/holiday-calender/141") },
      ];
    } else {
      cards = [];
    }

    return {
      title: menu.name,
      subMenuId: menu.id,
      cards: cards,
    };
  });

  return (
    <>
      <div>
        <MainHeader title={"Admin"} />
        <Dashboard sections={sections} />
      </div>
    </>
  );
}
