import React, { useEffect, useRef, useState } from "react";
import Header from "../../../../../components/Header";
import { FiWind } from "react-icons/fi";
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BiDroplet } from "react-icons/bi";
import { RiDropdownList } from "react-icons/ri";
import { IoIosList } from "react-icons/io";
import axios from "axios";
import { useToast } from "../../../../../components/toast/toast";
import Mt940Service from "../../../../../rest-services/Mt940Services";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import CustomeLoading from "../../../../../components/CustomeLoading";
import CustomDropdown from "../../../../../components/CustomDropdown";
import Footer from "../../../../../components/footer";
import CustomInput from "../../../../../components/CustomInput";
import LabelWithButton from "../../../../../components/InputButtonField";
import ReusableModal from "../../../../../components/PopupComponent";
import TableWrapper from "../../../../../components/ScrollBarComponent";
import { set } from "lodash";
import FooterBtnWithShow from "../../../../../components/FooterBtnWithShow";


export default function TransactionWiseAdd() {
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [processName, setProcessName] = useState("");
    const [statementType, setStatementType] = useState("");
    const [accountIdCheck, setAccountIdCheck] = useState(0);
    const [ttoBsCheck, setTtoBsCheck] = useState(0);
    const [runFlowCheck, setRunFlowCheck] = useState(0);
    const [runAccountCheck, setRunAccountCheck] = useState(0);
    const [assignee, setAssignee] = useState({ id: "", assigner: "" });
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [alert, setAlert] = useState(null);
    const { toast } = useToast();
    const [loading, setLoading] = useState(false);
    const [path, setPath] = useState("");
    const [isCompanyCodeModelOpen, setIsCompanyCodeModelOpen] = useState(false);
    const [isLgeCodeModelOpen, setIsLgeCodeModelOpen] = useState(false);
    const [isBuGroupModelOpen, setIsBuGroupModelOpen] = useState(false);
    const [isCountryModelOpen, setIsCountryModelOpen] = useState(false);
    const [isBankModelOpen, setIsBankModelOpen] = useState(false);
    const [isBankIdModelOpen, setIsBankIdModelOpen] = useState(false);
    const [isBankBranchModelOpen, setIsBankBranchModelOpen] = useState(false);
    const [isBankCountryModelOpen, setIsBankCountryModelOpen] = useState(false);
    const [isBankAccountIdModelOpen, setIsBankAccountIdModelOpen] = useState(false);
    const [isBankCurrencyCodeModelOpen, setIsBankCurrencyCodeModelOpen] = useState(false);

    useEffect(() => {
        fetchAssignee();
    }, []);

    const handleFileChange = (e) => {
        e.preventDefault();

        const selectedFiles = Array.from(e.target.files);
        console.log("Selected Files:", selectedFiles);
        selectedFiles.forEach((file) => {
            console.log(
                `File: ${file.name}, Relative Path: ${file.webkitRelativePath}`
            );
        });

        if (selectedFiles.length > 0) {
            const firstFile = selectedFiles[0];
            const folderPath = firstFile.webkitRelativePath.split("/")[0];

            setPath(folderPath);
            const finFiles = selectedFiles.filter(
                (file) => file.name.endsWith(".fin") || file.name.endsWith(".txt")
            );

            if (finFiles.length === 0) {
                console.error("No .fin or .txt files found in the folder.");
                return;
            }

            setFiles(finFiles);
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        setLoading(true);
        const formData = new FormData();

        files.forEach((file) => {
            formData.append("files", file);

            const modifiedPath = `application/downloads/${file.webkitRelativePath}`;
            formData.append("filePaths", modifiedPath);
        });
        formData.append("clientId", assignee.clientId);
        formData.append("processName", processName);
        formData.append("statementType", statementType);
        formData.append("accountIdCheck", accountIdCheck);
        formData.append("ttoBsCheck", ttoBsCheck);
        formData.append("runFlowCheck", runFlowCheck);
        formData.append("runAccountCheck", runAccountCheck);
        formData.append("createdBy", assignee.assigner);
        formData.append("changedBy", assignee.assigner);

        try {
            const response = await Mt940Service.uploadMt940(formData);
            console.log(response);

            if (response.includes("Files uploaded")) {
                setLoading(false);
                toast("success", "Files uploaded successfully");
            }
        } catch (err) {
            console.error("Upload failed: ", err);
            setLoading(false);
            toast("error", "File upload failed");
        }
    };

    const handleClick = (e) => {
        fileInputRef.current.click();
    };
    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked ? 1 : 0);
    };

    const handleIconClick = () => {
        navigate("/company-wise");
    };

    const handleButtonClick = (path) => {
        console.log(`Button clicked: ${path}`);
    };
    const fetchAssignee = async () => {
        try {
            const userDetailString = localStorage.getItem("userDetail");
            if (userDetailString) {
                const userDetail = JSON.parse(userDetailString);
                const assigneeData = {
                    clientId: userDetail.clientId || "",
                    id: userDetail.id || "",
                    assigner: userDetail.username || "",
                    userId: userDetail.userId || "",
                };
                setAssignee(assigneeData);
            } else {
            }
        } catch (error) { console.log(error) }
    };
    const validateForm = () => {
        const newErrors = {};
        if (!processName) newErrors.processName = "Process Name is required.";
        if (!statementType)
            newErrors.statementType = "Statement Format is required.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const statmentFormatOptions = [
        { value: "0", label: "940 WHF" },
        { value: "1", label: "940 HF" },
        { value: "3", label: "Portal" },
    ];
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Cash Balances') {
            navigate('/cash-balances');
        }
        if (crumb.title === 'Cash in Bank') {
            navigate('/cash-balances');
        } else if (crumb.title === 'Transaction Wise') {
            // navigate('/currency-rate');
        }
        else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };
    const handleToggle = (dropdownId) => {
        setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
    };
    const handleChange = (value) => {
        setStatementType(value,
            setOpenDropdown(false)
        );
    };
    return (
        <div>
            <Header title={"Cash Balances > Cash in Bank > Transaction Wise > Add"}
                onBreadcrumbClick={handleBreadcrumbClick}
                backRoute={"/cash-balances"}
            />
             <div>
             <TableWrapper maxHeight="550px">
            <div className="px-6 pb-6">
                <div className="flex flex-col  col-span-1 p-4 border  rounded-md">
                <div className="2xl:flex 2xl:flex-row xl:grid xl:grid-cols-2 xl:gap-0 lg:grid lg:grid-cols-2 lg:gap-0">
                  <div>
                    <label className="flex items-center">
                        <span className="w-48 font-small text-gray-500 text-xs">Process Name</span>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            onChange={(e) => setProcessName(e.target.value)}
                            className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-customGray`}
                            required
                        />
                        </label>
                        </div>
                        <div>
                        <button onClick={handleIconClick}>
                            <IoIosList className="ml-4 mt-1 border  h-6 w-6  hover hover:border-customBlue" />
                        </button>
                        </div>
                        </div>
                    {errors.ntMainHead && (
                        <p className="text-red-500 text-sm mt-1">{errors.ntMainHead}</p>
                    )}
                    <div>
                    <div className="grid grid-cols-2 gap-0">
                        <label className="flex items-center mt-4">
                            <span className="w-48  font-small text-left text-gray-500 text-xs">
                                Transaction Date
                            </span>
                            <span className="ml-4">:</span>


                            <input
                                type="date"
                                placeholder="From"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded lg:w-28  xl:w-[148px] h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500 text-xs`}

                                required
                            />
                            <input
                                type="date"
                                placeholder="To"
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded lg:w-28  xl:w-[148px] h-8 focus:outline-none hover:border-blue-400 xl:ml-6 lg:ml-2 text-gray-500 text-xs`}

                                required
                            />

                        </label>

                        {errors.ntMainHead && (
                            <p className="text-red-500 text-sm mt-1">{errors.ntMainHead}</p>
                        )}

                        <label className="flex items-center mt-4 pl-6">
                            <span className="w-40 font-small text-left text-gray-500 text-xs">
                                Month/Year
                            </span>
                            <span className="ml-4">:</span>


                            <select
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded lg:w-28 xl:w-32 2xl:w-40 h-8 focus:outline-none hover:border-blue-400 ml-8 text-gray-500 text-xs`}

                                required
                            />
                            <select
                                // onChange={(e) => setProcessName(e.target.value)}
                                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                                    } p-1.5 rounded lg:w-28 xl:w-32 2xl:w-40 h-8 focus:outline-none hover:border-blue-400 xl:ml-6 lg:ml-2 text-gray-500 text-xs`}

                                required
                            />

                        </label>
                        </div>
                    </div>
                </div>

                {loading === true ? <CustomeLoading /> : null}


            </div>
            <div className="px-6 ">
                <div className="flex flex-col   col-span-1 p-4 border  rounded-md">
                <div className="grid grid-cols-2 gap-x-4 mb-4">
                            <label className="flex items-center">
                                <span className="w-48 text-sm font-medium text-customGray ">Company</span>
                            </label>
                            <label className="flex items-center">
                                <span className="w-48 text-sm font-medium text-customGray  ">Bank</span>
                            </label>
                        </div>
                    <div className="grid grid-cols-2 gap-x-4">
                                <LabelWithButton
                                    label="Company Code"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsCompanyCodeModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                    // width="2xl:w-72 xl:w-[282px] lg:w-[285px]"
                                />

                                <ReusableModal
                                    title="Company Code"
                                      isOpen={isCompanyCodeModelOpen}
                                    onClose={() => {
                                        setIsCompanyCodeModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsCompanyCodeModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search Company Code"
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                                <LabelWithButton
                                    label="Bank"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsLgeCodeModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                    // width="2xl:w-72 xl:w-[282px] lg:w-[285px]"
                // labelwidth="w-48"
                                />

                                <ReusableModal
                                    title="Select Bank"
                                      isOpen={isLgeCodeModelOpen}
                                    onClose={() => {
                                        setIsLgeCodeModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsLgeCodeModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search Bank..."
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4">
                                <LabelWithButton
                                    label="LGE Code"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsLgeCodeModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                                                 
                                />

                                <ReusableModal
                                    title=" Select LGE Code"
                                      isOpen={isLgeCodeModelOpen}
                                    onClose={() => {
                                        setIsLgeCodeModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsLgeCodeModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search LGE Code"
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                                <LabelWithButton
                                    label="Bank Id"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsBankIdModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                
                                />

                                <ReusableModal
                                    title="Select Bank Id"
                                      isOpen={isBankIdModelOpen}
                                    onClose={() => {
                                        setIsBankIdModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsBankIdModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search Bank Id..."
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                            </div>      
                             <div className="grid grid-cols-2 gap-x-4">
                                <LabelWithButton
                                    label="BU Group"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsBuGroupModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                                                  
                                />

                                <ReusableModal
                                    title="Select Bu Group"
                                      isOpen={isBuGroupModelOpen}
                                    onClose={() => {
                                        setIsBuGroupModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsBuGroupModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search BU Group..."
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                                <LabelWithButton
                                    label="Bank Branch"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsBankBranchModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                   
                                />

                                <ReusableModal
                                    title="Select Bank Branch"
                                      isOpen={isBankBranchModelOpen}
                                    onClose={() => {
                                        setIsBankBranchModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsBankBranchModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search Bank Branch..."
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                            </div>    
                        <div className="grid grid-cols-2 gap-x-4">
                                <LabelWithButton
                                    label="Country "
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsCountryModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                                                 
                                />

                                <ReusableModal
                                    title="Select Country"
                                      isOpen={isCountryModelOpen}
                                    onClose={() => {
                                        setIsCountryModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsCountryModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search Country..."
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                                <LabelWithButton
                                    label="Bank Country"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsBankCountryModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                   
                                />

                                <ReusableModal
                                    title="Select Bank Country"
                                      isOpen={isBankCountryModelOpen}
                                    onClose={() => {
                                        setIsBankCountryModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsBankCountryModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search Bank Country..."
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                            </div>                    
                            <div className="grid grid-cols-2 gap-x-4">
                            <div className="w-[600px] ml-4">&nbsp;</div>

                                <LabelWithButton
                                    label="Bank Account Id"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsBankAccountIdModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                  
                                />

                                <ReusableModal
                                    title="Select Bank Account Id"
                                      isOpen={isBankAccountIdModelOpen}
                                    onClose={() => {
                                        setIsBankAccountIdModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsBankAccountIdModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search Bank Account Id..."
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4">
                            <div className="w-[600px] ml-4">&nbsp;</div>

                                <LabelWithButton
                                    label="Bank Currency Code"
                                    // isRequired={true}
                                    //   isDisabled={isViewMode}
                                      onClick={() => { setIsBankCurrencyCodeModelOpen(true); }
                                      }
                                    //   buttonText={selectedLge || 'Select LGE Code'}
                                    error={errors.lgeCode}
                                   
                                />

                                <ReusableModal
                                    title="Select Bank Currency Code"
                                      isOpen={isBankCurrencyCodeModelOpen}
                                    onClose={() => {
                                        setIsBankCurrencyCodeModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    onSave={() => {
                                        setIsBankCurrencyCodeModelOpen(false);
                                        // setSearchQuery('');
                                    }}
                                    searchPlaceholder="Search Bank Currency Code..."
                                    //   searchQuery={searchQuery}
                                    showReset={true}
                                //   onReset={handlelgeReset}
                                //   onSearchChange={handleSearchChange}
                                //   isViewMode={isViewMode}
                                //   onRefresh={handleRefreshlge}
                                >
                                    {/* {legalEntities && legalEntities.length > 0 ? (
            legalEntities
              .filter(
                (lge) =>
                  lge.status === 4 &&
                  (lge.LGE_Code &&
                    lge.LGE_Code.trim() &&
                    lge.LGE_Code
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()))

              )
              .sort((a, b) => a.LGE_Code.localeCompare(b.LGE_Code)) // Sort in alphabetical order

              .map((lge, index) => (
                <div
                  key={lge.id}
                  className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                    }`}
                >
                  <input
                    type="radio"
                    name="Lge Code"
                    value={lge.LGE_Code}
                    checked={
                      selectedLge === lge.LGE_Code
                    }
                    // onChange={handleLgeCheckBoxChange}
                    className="mr-2 accent-customBlue"
                    disabled={isViewMode}
                  />
                  <label>{lge.LGE_Code}</label>
                </div>
              ))

          ) : (
            <p>Loading lge...</p>
          )} */}
                                </ReusableModal>
                            </div>  
                                     
                            
                </div>

                {loading === true ? <CustomeLoading /> : null}


            </div>
            </TableWrapper>
            </div>
            <Footer>
                {/* <button
                    onClick={handleSubmit}
                    className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-42  whitespace-nowrap"
                >
                    Upload & process{" "}
                </button> */}
                <FooterBtnWithShow/>
            </Footer>
        </div>
    );
}
