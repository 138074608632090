import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import Card from "./Card";
import TableWrapper from "./ScrollBarComponent";



const DashboardSection = ({ title, cards }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mt-2 ml-2">
      <div className="bg-muted rounded-lg mb-4">
        <div
          className="flex justify-between cursor-pointer border bg-sky-50 w-full px-4 py-1"
          onClick={toggleSection}
        >
          <h2 className="text-left text-md font-medium text-muted-foreground text-[#616161] ">
            {title}
          </h2>
          <span className="p-1 text-customGray">
            {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </span>
        </div>
        <div
          className={`overflow-hidden transition-opacity duration-900 ${
            isOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
          }`}
        >
          <div className=" -mt-4">
            {isOpen && (
              <div className="mt-4 flex flex-wrap gap-6 mt-1 p-3">
                {cards.map((card, index) => (
                  <Card
                    key={index}
                    title={card.title}
                    icon={card.icon}
                    onClick={card.onClick}
                    isHighlighted={card.isHighlighted} 
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = ({ sections }) => {
  return (
    <div>
    <TableWrapper maxHeight="90vh">
      {sections.map((section, index) => (
        <DashboardSection key={index} title={section.title} cards={section.cards} />
      ))}
      </TableWrapper>
    </div>
  );
};

export default Dashboard;
