import React from 'react';
import CustomButton from './CustomButton';
import HoverButton from './HoverButton';
 

export default function FooterBtnWithShow({
  onSave,
  onSaveContinue,
  onShow,
  saveTitle = "Save",
  saveContinueTitle = "Save & Continue",
  showTitle = "Show",
  hasId, // Pass the ID presence as a prop
  icons

}) {
  return (
    <div className="flex flex-row items-center">
     
        <CustomButton
          onClick={onSave}
          icons={icons}
          title={saveTitle}
          className="w-16 ml-6"
        />
        {!hasId && ( // Render the HoverButton only if `hasId` is falsy
        <HoverButton
          onClick={onSaveContinue}
          title={saveContinueTitle}
          icons={icons}
          className="ml-4 w-30"
        />
      )}
      {!hasId && ( // Render the HoverButton only if `hasId` is falsy
        <HoverButton
          onClick={onShow}
          title={showTitle}
          icons={icons}
          className="ml-4 w-20"
        />
      )}
     
    </div>
  );
}
