import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoMdRefresh } from "react-icons/io";
import Pagination from "../../../../../components/PageNation";
import { MdOutlineFileDownload } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import BankStatementActions from "../../props/ActionButtons";
import StatementAnalyzerService from "../../../../../rest-services/StatementAnalyserServices";
import { useToast } from "../../../../../components/toast/toast";
import { bankStatementActionsConfig } from "../../props/ActionButtonConfig";
import { PiGlobeXBold, PiSortDescendingBold } from "react-icons/pi";
import CustomeLoading from "../../../../../components/CustomeLoading";
import VirtualizedReTable from "../../../../../components/Virutualized";

export default function StatementAnalyzerr() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const analyserData = location.state;

  const toggleLayout = () => {
    setIsExpanded((prevState) => !prevState);
  };
  const { toast } = useToast();
  const downloadExcel = async () => {
    try {
      const response = await StatementAnalyzerService.exporAll(analyserData);

      if (response) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "statement_data.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast("success", "Downloaded Successfully");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      toast("error", "Failed to download data");
    }
  };

  const buttons = bankStatementActionsConfig.screen2.map((button) => {
    let onClick;
    let title;
    if (button.icon === CiSearch) {
      onClick = toggleLayout;
      title = "toggle";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downloadExcel();
      title = "download";
    } else if (button.icon === IoMdRefresh) {
      onClick = () => fetchDataForTable(currentPage);
      title = "download";
    }
    else if (button.icon === PiGlobeXBold) {
      onClick = () => removeBtc();
      title = "BTC Check";
    }
    else if (button.icon === PiSortDescendingBold) {
      //onClick = () => fetchDataForTable(currentPage);
      title = "DuplicateCheck";
    }

    return {
      ...button,
      onClick,
      title,
    };
  });

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchDataForTable(currentPage);
  }, [currentPage, searchQuery]);

  const handleShowAll = () => {
    setPageSize(totalCount);
    setLoading(true);
    setCurrentPage(1);
    fetchDataForTable(1);
  };

  const fetchDataForTable = async (page) => {
    try {
      if (!analyserData) return;
      const response = await StatementAnalyzerService.getProcessedRunData(analyserData, pageSize, page, searchQuery);
      if (response.analyser) {
        const totalCount = response.count;
        setLoading(false);
        setData(response.analyser);
        setTotalCount(totalCount);
        setTotalPages(Math.ceil(totalCount / (pageSize || 1)));
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  };

  const removeBtc = async () => {
    try {
      const response = await StatementAnalyzerService.removeBtc(analyserData);
      if (response.status === 200) {
        toast("success", response.message);
        fetchDataForTable(currentPage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { Header: "File Name", accessor: "file_name", visible: true, width: ("File Name".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "MT940 Unique ID", accessor: "mt940_unique_id", visible: true, width: ("MT940 Unique ID".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Statement Type", accessor: "statement_type", visible: true, width: ("Statement Type".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tags", accessor: "tags", visible: true, width: ("Tags".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Transaction indicator", accessor: "transaction_indicator", visible: true, width: ("Transaction indicator".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Corporate BIC", accessor: "corprate_bic", visible: true, width: ("Corporate BIC".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Bank BIC", accessor: "bank_bic", visible: true, width: ("Bank BIC".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Reference", accessor: "reference", visible: true, width: ("Reference".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Statement Identifier", accessor: "statement_identifier", visible: true, width: ("Statement Identifier".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Statement No", accessor: "statement_no", visible: true, width: ("Statement No".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Dr/Cr", accessor: "dr_cr", visible: true, width: ("Dr/Cr".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Fund Code", accessor: "funCode", visible: true, width: ("Fund Code".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    {
      Header: "Value Date",
      accessor: "value_date",
      visible: true,
      width: ("Value Date".length - 1) * 10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return <div style={{ textAlign: "left" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
      },
    },
    {
      Header: "Entry Date",
      accessor: "entry_date",
      visible: true,
      width: ("Entry Date".length - 1) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>
    },
    { Header: "Currency", accessor: "currency", visible: true, width: ("Currency".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    {
      Header: "Amount", accessor: "amount", visible: true, width: ("Amount".length - 1) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div>
    },
    { Header: "BTC Code", accessor: "btc_code", visible: true, width: ("BTC Code".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag61Description1", accessor: "tag61_description1", visible: true, width: ("Tag61Description1".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag61 Reference", accessor: "tag61_reference", visible: true, width: ("Tag61 Reference".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag61Description2", accessor: "tag61_description2", visible: true, width: ("Tag61Description2".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag86Description1", accessor: "tag81_description1", visible: true, width: ("Tag86Description1".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag86Description2", accessor: "tag81_description2", visible: true, width: ("Tag86Description2".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag86Description3", accessor: "tag81_description3", visible: true, width: ("Tag86Description3".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag86Description4", accessor: "tag81_description4", visible: true, width: ("Tag86Description3".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag86Description5", accessor: "tag81_description5", visible: true, width: ("Tag86Description3".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Tag86Description6", accessor: "tag81_description6", visible: true, width: ("Tag86Description3".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "BTCE", accessor: "btce", visible: true, width: ("BTCE".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    {
      Header: "Duplicate",
      accessor: "duplicate",
      visible: true,
      width: ("Imported Date".length - 1) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>
    },
    {
      Header: "Group No",
      accessor: "ds_sl_no",
      visible: true,
      width: ("Group No".length - 1) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>
    },
    {
      Header: "Statement Imported Date",
      accessor: "statement_imported_date",
      visible: true,
      width: ("Statement Imported Date".length - 1) * 10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return <div style={{ textAlign: "left" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
      },
    },
    {
      Header: "Time Stamp", accessor: "time_stamp", visible: true, width: ("Time Stamp".length - 1) * 10, Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
    {
      Header: "Imported Status", accessor: "imported_status", visible: true, width: ("Imported Status".length - 1) * 10, Cell: ({ row }) => (
        <input
          type="checkbox"
          checked={row.original.imported_status === 1}
          readOnly
        />
      ),
    },

    { Header: "Statement Imported by", accessor: "statement_imported_by", visible: true, width: ("Statement Imported by".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Statement Status", accessor: "statement_status", visible: true, width: ("Statement Status".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Bank Account ID", accessor: "bank_account_id", visible: true, width: ("Bank Account ID".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Company Code", accessor: "company_code", visible: true, width: ("Company Code".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Legal Entity", accessor: "legalEntityCode", visible: true, width: ("Legal Entity".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Bu Group", accessor: "buGroupCode", visible: true, width: ("Bu Group".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Country", accessor: "country", visible: true, width: ("Country".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Bank", accessor: "bank", visible: true, width: ("Bank".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Bank Branch", accessor: "bank_branch", visible: true, width: ("Bank Branch".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Bank Country", accessor: "bank_country", visible: true, width: ("Bank Country".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    {
      Header: "Transfer Status", accessor: "is_transfered", visible: true, width: ("Transfer Status".length - 1) * 10, Cell: ({ row }) => (
        <input
          type="checkbox"
          checked={row.original.is_transfered === 1}
          readOnly
        />
      ),
    },

    {
      Header: "Transfered Date", accessor: "transfer_date", visible: true, width: ("Transfered Date".length - 1) * 10, Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return <div style={{ textAlign: "left" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
      },
    },
    {
      Header: "Transfered Time", accessor: "transfer_time", visible: true, width: ("Transfered Time".length - 1) * 10, Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
    { Header: "Transfered By", accessor: "transfer_by", visible: true, width: ("Transfered By".length - 1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
  ];



  const handleDoubleClick = (id) => {
    // navigate(`/bank-view/${id}`, { state: { mode: "view" } });
  };

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width,
    }));

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    // setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };
  const navigate = useNavigate()
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Process") {
      navigate("/11/mt940/49/add-statement-analyser");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
      <div>
        <Header title="Bank Statements > MT940 > Statement Analyser " onBreadcrumbClick={handleBreadcrumbClick} backRoute={"/11/mt940/49/add-statement-analyser"} />
      </div>
      {/* <div className="flex justify-between">

        {isExpanded && (
          <div className="flex mr-16 p-4">
            <button className=" mr-4 border border-blue-500 text-sm text-blue-500 py-1 px-2   rounded hover:bg-blue-100 transition duration-200 ease-in-out w-16 h-8 ">
              Reset
            </button>
            <button className="mr-4 bg-blue-500 text-sm text-white py-1 px-2   rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 h-8 ">
              Save
            </button>
          </div>
        )}
      </div>
      <div
        className={`transition-opacity duration-900 ease-in-out ${isExpanded ? "opacity-100" : "opacity-0"
          }`}
      >
        {isExpanded && (
          <div className="grid grid-cols-2 gap-2 w-full  p-4">
            <div className="grid grid-cols-2 gap-2 col-span-2 h-48 ">
              <div className="border p-4 rounded-md bg-[#f3f4f6] ">
                <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      CompanyCode
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter main head"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Bank ID
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter sub heads"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Country
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Branch{" "}
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter additional info"
                    />
                  </label>
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Bank Country
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Account ID
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter additional info"
                    />
                  </label>
                </div>
              </div>
              <div className="border p-4 rounded-md bg-[#f3f4f6] ">
                <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Value Date
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter main head"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Statement Imported Date
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                      placeholder="Enter sub heads"
                    />
                  </label>

                  <label className="flex items-center group">
                    <span className="w-48 text-sm font-medium text-gray-500">
                      Uploaded By
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2 col-span-1 h-48 rounded ">
              <div className="border p-4 rounded-md bg-[#f3f4f6] space-y-8">
                <div></div>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Cash Flow{" "}
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 "
                  />
                </label>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Cash Flow{" "}
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
              </div>
              <div className="border p-4 rounded-md bg-[#f3f4f6]">
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Manually Changed
                  </p>
                </div>
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Not Assigned
                  </p>
                </div>
                <div className="flex ">
                  <Checkbox className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75" />
                  <p className="ml-4 mt-2 font-small text-gray-600">
                    Manually Changed
                  </p>
                </div>
              </div>
            </div>

            <div className="col-span-1 border p-4 rounded-md bg-[#f3f4f6]">
              <div className="flex space-x-8">
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Company Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
                <label className="flex items-center group">
                  <span className="w-48 text-sm font-medium text-gray-500">
                    Company Code
                  </span>
                  <span className="ml-4">:</span>
                  <input
                    type="text"
                    className="border border-gray-300 p-1.5 rounded w-28 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                  />
                </label>
              </div>
            </div>
          </div>
        )}
      </div> */}

      <div className="p-0">
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onChange={(e) => fetchDataForTable()}
              placeholder="Search by Identifiers"
            />

            <BankStatementActions buttons={buttons} />
          </div>
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>

        </div>
        <div>
          {/* <VirtualizedReTable
            data={data}
            columns={modifiedColumns}
            onRowDoubleClick={handleDoubleClick}
          /> */}
          <ReTable
            data={data}
            columns={modifiedColumns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            onRowDoubleClick={handleDoubleClick}
          />
        </div>
      </div>
      <div>
        {
          loading && <CustomeLoading />
        }
      </div>
    </div>
  );
}
