import React, { useState, useEffect, useRef } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import BankServices from "../../../../../rest-services/BankServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../../components/toast/toast";
import axios from "axios";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";

export default function BankAdd() {
  const { bankId } = useParams();
  const [bankCode, setBankCode] = useState("");
  const [description, setDescription] = useState(""); // Updated to use description
  const [logo, setLogo] = useState("");
  const [editlogo, setEditLogo] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const location = useLocation();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!bankId;

  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const navigate = useNavigate();
  const logoInputRef = useRef(null);
  useEffect(() => {
    if (bankId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [bankId]);

  const validateForm = () => {
    const newErrors = {};
    if (!bankCode) newErrors.bankCode = "Bank Code is required.";
    if (!description) newErrors.description = "Description is required."; // Validate description
    // if (!logo) newErrors.logo = "Logo is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  // Example usage in your component
  const getDataForEdit = async () => {
    try {
      const response = await BankServices.getBankById(bankId);
      if (response && response.data) {
        setBankCode(response.data.bankCode || "");
        setDescription(response.data.Description || "");
        fetchImage(response.data.logo);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching bank details: ${error.message}`,
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchImage = async (image) => {
    const encodedCredentials = localStorage.getItem("encodedCredentials");
    const imageUrl = image;

    try {
      const response = await axios.get(imageUrl, {
        headers: {
          Authorization: `Bearer ${encodedCredentials}`,
        },
        responseType: "blob",
      });

      const imageBlob = URL.createObjectURL(response.data);
      setEditLogo(imageBlob);
      // You can now use `imageBlob` as the `src` for an <img> tag
    } catch (error) {
      console.error("Error fetching the image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) return;
  
    try {
      if (!bankId) {
        const duplicateCheckResponse = await BankServices.checkDuplicateBankcode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatebank`,
          { bankCode: bankCode }
        );
  
        if (duplicateCheckResponse.message === "bank code already exists") {
          toast("error", "Bank already exists.");
          setErrors((prev) => ({
            ...prev,
            bankID: "Bank already exists.",
          }));
          return;
        }
      }
  
      const formData = new FormData();
      formData.append("bankCode", bankCode);
      formData.append("Description", description);
      formData.append("created_by", assignee.assigner);
      formData.append("changed_by", assignee.assigner);
  
      if (!bankId) {
        if (logo instanceof File) {
          formData.append("logo", logo);
        }
      } else {
        const existingBank = await BankServices.getBankById(bankId);
        if (logo instanceof File) {
          formData.append("logo", logo);
        } else {
          formData.append("logo", existingBank.data.logo);
        }
        formData.append("id", bankId);
      }
      if (!unsavedChanges) {
        toast(
          "info",
          "No Bank changes detected. Nothing to save."
        );
        navigate("/2/master-data/5/bank");
        return false;
      }
      const response = await BankServices.addBank(
        `${process.env.REACT_APP_BASE_URL}/create-bank`,
        formData
      );
  
      if (response && response.message === "An error occurred while processing your request") {
        toast("error","Error in adding/updating bank");
      } else if (response && response.status !== 500) {
        toast(
          "success",
          bankId ? "Bank updated successfully" : "Bank added successfully"
        );
        setBankCode("");
        setDescription("");
        setLogo("");
        setEditLogo("");
        if (logoInputRef.current) {
          logoInputRef.current.value = "";
        }
        navigate("/2/master-data/5/bank");
      } else {
        toast("error", "Unexpected error occurred");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating bank: ${error.response?.data?.message || error.message}`,
      });
    }
  };
  
  const handleSaveAndContinue = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) return;
  
    try {
      if (!bankId) {
        const duplicateCheckResponse = await BankServices.checkDuplicateBankcode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatebank`,
          { bankCode: bankCode }
        );
  
        if (duplicateCheckResponse.message === "bank code already exists") {
          toast("error", "Bank already exists.");
          setErrors((prev) => ({
            ...prev,
            bankID: "Bank already exists.",
          }));
          return;
        }
      }
  
      const formData = new FormData();
      formData.append("bankCode", bankCode);
      formData.append("Description", description);
      formData.append("created_by", assignee.assigner);
      formData.append("changed_by", assignee.assigner);
  
      if (!bankId) {
        if (logo instanceof File) {
          formData.append("logo", logo);
        }
      } else {
        const existingBank = await BankServices.getBankById(bankId);
        if (logo instanceof File) {
          formData.append("logo", logo);
        } else {
          formData.append("logo", existingBank.data.logo);
        }
        formData.append("id", bankId);
      }
  
      const response = await BankServices.addBank(
        `${process.env.REACT_APP_BASE_URL}/create-bank`,
        formData
      );
  
      if (response && response.message === "An error occurred while processing your request") {
        toast("error","Error in adding/updating bank");
      } else if (response && response.status !== 500) {
        toast(
          "success",
          bankId ? "Bank updated successfully" : "Bank added successfully"
        );
        setBankCode("");
        setDescription("");
        setLogo("");
        setEditLogo("");
        if (logoInputRef.current) {
          logoInputRef.current.value = "";
        }
      } else {
        toast("error", "Unexpected error occurred");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating bank: ${error.response?.data?.message || error.message}`,
      });
    }
  };
  const checkDuplicatebankcode = async (code) => {
    try {
      const response = await BankServices.checkDuplicateBankcode(
        `/getduplicatebank`,
        { bankCode: code }  // Sending roleName to backend
      );

      console.log("Response from server:", response);

      if (response.message === "bank code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ bankCode: " " });

        toast("error", "Bank already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate bankCode:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, bankCode: "Error checking role name" }));
    }
  };

  const handleBankChange = (e) => {
    const code = e.target.value.toUpperCase(); // Ensure input is uppercase
    setUnsavedChanges(true);
    // Limit input to 3 letters


    // Clear errors if any
    setErrors((prevErrors) => ({
      ...prevErrors,
      bankCode: "",
    }));

    // Update the calendar code
    setBankCode(code);

    // Check for duplicate Calendar Code locally
    if (code.trim() !== "") {
      checkDuplicatebankcode(code);
    }
  };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {
      console.log("Navigating to /2/master-data");
      navigate('/2/master-data');
    } else if (crumb.title === 'Bank') {
      console.log("Navigating to /country");
      navigate('/2/master-data/5/bank');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const handleUploadClick = () => {
    logoInputRef.current.click();
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUnsavedChanges(true);

    if (file) {
      if (file.type.startsWith("image/")) {
        setLogo(file);
        setErrors((prev) => ({ ...prev, logo: "" }));
      } else {
        setErrors((prev) => ({
          ...prev,
          logo: "Please upload a valid image file.",
        }));
      }
    }
  };
  return (
    <div>
      <Header
        title={`Primary Data > Global Data > Bank > ${bankId ? (isViewMode ? "View" : "Edit") : "Add"
          }`}
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/2/master-data/5/bank"}
      />

      <div className="m-6 border border-gray-200 p-2  rounded-lg">


        <div className="flex flex-col space-y-4 p-2">
          <div className="flex flex-row space-x-8">
            {/* Left Column - Bank Code and Description */}
            <div className="flex flex-col space-y-4 w-1/2">

              <label className="flex items-center">
                <span className="w-48 font-small text-xs text-customGray">
                  Bank Code<span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={bankCode}
                  maxLength={30}

                  onChange={handleBankChange}
                  className={`border ${errors.bankCode ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 font-small text-xs text-customGray`}
                  required
                  disabled={isViewMode || isEditMode}
                />
              </label>
              {!isViewMode && bankCode.length === 30 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}

              <label className="flex items-center">
                <span className="w-48 font-small text-xs text-customGray">
                  Description<span className="text-red-500 ml-1">*</span>
                </span>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  maxLength={30}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setUnsavedChanges(true);
                    if (errors.description) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        description: "",
                      }));
                    }
                  }}
                  className={`border ${errors.description ? "border-red-500" : "border-gray-300"
                    } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 font-small text-xs text-customGray`}
                  required
                  disabled={isViewMode}
                />
              </label>
              {!isViewMode && description.length === 30 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}
            </div>

            {/* Right Column - Upload and Logo */}
            <div className="flex flex-col space-y-4 w-1/2">
              <label className="flex items-center">
                <span className="w-48 font-small text-xs text-customGray">
                  Logo
                </span>
                <span className="ml-4">:</span>

                {/* Hidden File Input */}
                <input
                  type="file"
                  ref={logoInputRef}
                  disabled={isViewMode}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />

                {/* Upload Button */}
                <button
                  type="button"
                  onClick={handleUploadClick}
                  disabled={isViewMode}
                  className="ml-8 bg-sky-100 w-60 text-customGray px-4 py-1.5 rounded-full text-xs font-small hover:bg-sky-200 focus:outline-none"
                >
                  Upload Logo
                </button>
              </label>

              {(bankId ? editlogo : logo) && (
                <div className="mt-2">
                  <img
                    src={bankId ? editlogo : URL.createObjectURL(logo)}
                    alt="Bank Logo"
                    className="h-20 w-20 object-contain"
                  />
                </div>
              )}
            </div>
          </div>

        </div>

      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
            hasId={!!bankId}
          />
        )}
      </Footer>
    </div>

  );
}
