import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../../../../components/footer';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import { IoIosCodeDownload, IoIosDownload, IoIosList, IoMdCloudDownload, IoMdCodeDownload } from 'react-icons/io';
import { Checkbox } from '@mui/material';
import * as XLSX from "xlsx";
import { useToast } from '../../../../../../components/toast/toast';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import UserService from '../../../../../../rest-services/UserServices';
import UploadComponent from '../../../../../../components/UploadComponent';
export default function UserUpload() {
const navigate = useNavigate();
const { toast } = useToast();
  const { iconId } = useParams();

const fileInputRef = useRef(null);
const [tableData, setTableData] = useState([]);
const [buttonText, setButtonText] = useState("Upload from desktop");
const [isUploaded, setIsUploaded] = useState(false);
const [displayOption, setDisplayOption] = useState(""); // Default value
const [uploadOption, setUploadOption] = useState(""); // Default value
const [isDisplayChecked, setIsDisplayChecked] = useState(false); // State for checkbox
const [isUploadChecked, setIsUploadChecked] = useState(false); // State for checkbox
const [isError, setIsError] = useState(false);
const [showConfirmation, setShowConfirmation] = useState(false);
const [assignee, setAssignee] = useState({ id: "", username: "" });
const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

     const[getExistsUserids,setgetExistsUserids] = useState([]);
        const[getExistsLanguageids,setgetExistsLanguageids] = useState([]);
        const [countryCodes, setCountryCodes] = useState([]);
          const [stateCodes, setStateCodes] = useState([]);
        
        const [companies, setCompanies] = useState([]);
        const [companiesGreen, setCompaniesGreen] = useState([]);
const [accountNoUpdatesuccessfulRecords, setAccountNoUpdateSuccessfulRecords] = useState([]);
  

// Allowed format (Replace with actual validation if needed)
const allowedFormat = ["Action","SLNo","UserID","Username",	"Salute","FirstName","MiddleName","LastName","AccountingSystemId","Email","MobileNo","WhatsAppNo","OfficeNo","Extension","PassportNo","DrivingLicenseNo","LocalId","Nationality","EmployeeNo","CompanyCodeId","Department","ValidFrom","ValidTo","ResignationDate","JoiningDate","DoorNo","FloorNo","BuildingNo","BuildingName","LandMark","StreetNo","StreetName","RoadNo","RoadName","Block","AreaName","Phase","Sector","Village","Town","CityName","Address1","Address2","Address3","CountryId","StateProvince","District","PostalCode","ZipCode","POBox","Language","DateFormat","TimeFormat","TimeZone","AmountFormat",
  // "ThousandSeparator","DecimalSeparator",
  "AcceleratorForThousands","AcceleratorForLakhs","AcceleratorForMillions"
]; 

// const expectedDataTypes = {
//   Action: "string",
//   SLNo: "string",
//   UserID: "string",
//   Salute: "string",
//   FirstName: "string",
//   MiddleName: "string",
//   LastName: "string",
//   AccountingSystemId: "string",
//   AccountNo: "string",
//   Username: "string",
//   Email: "string",
//   MobileNo: "string",
//   WhatsAppNo: "string",
//   OfficeNo: "string",
//   Extension: "string",
//   PassportNo: "string",
//   DrivingLicenseNo: "string",
//   LocalId: "string",
//   Nationality: "string",
//   EmployeeNo: "string",
//   CompanyCodeId: "string",
//   Department: "string",
//   ValidFrom: "string",
//   ValidTo: "string",
//   JoiningDate: "string",
//   ResignationDate: "string",
//   DoorNo: "string",
//   FloorNo: "string",
//   BuildingNo: "string",
//   BuildingName: "string",
//   LandMark: "string",
//   StreetNo: "string",
//   StreetName: "string",
//   RoadNo: "string",
//   RoadName: "string",
//   Block: "string",
//   AreaName: "string",
//   Phase: "string",
//   Sector: "string",
//   Village: "string",
//   Town: "string",
//   CityName: "string",
//   Address1: "string",
//   Address2: "string",
//   Address3: "string",
//   CountryId: "string",
//   StateProvince: "string",
//   District: "string",
//   PostalCode: "string",
//   ZipCode: "string",
//   POBox: "string",
//   Language: "string",
//   DateFormat: "string",
//   TimeFormat: "string",
//   TimeZone: "string",
//   AmountFormat: "Text",
//   ThousandSeparator: "string",
//   DecimalSeparator: "string",
//   AcceleratorForThousands: "string",
//   AcceleratorForLakhs: "string",
//   AcceleratorForMillions: "string",
// };

    const cancelDeletion = () => {
    setShowConfirmation(false);
    };

    const confirmDeletion = async () => {
      console.log("Download the excel sheet");
      // Simulate the download by creating an anchor element
      const fileUrl = "/User_Upload.xlsx"; 
      console.log("file ur is",fileUrl);
      const anchor = document.createElement("a");
      anchor.href = fileUrl;
      anchor.download = "User_Upload.xlsx"; // Set the file name for download
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }




    const handleBreadcrumbClick = (crumb) => {
      console.log("Breadcrumb clicked:", crumb.title);
      if (crumb.title === 'Admin') {
        console.log("Navigating to /Admin");
        navigate('/14/admin');
      } else if (crumb.title === 'User & SOD') {
        console.log("Navigating to User & SOD");
        navigate('/14/admin');
      }
      else if (crumb.title === 'User') {
        console.log("Navigating to /User");
        navigate('/14/admin/108/userscreen');
      }
      else if (crumb.title === 'Add') {
        console.log("Navigating to /User");
        // navigate('/useradd');
      }
      else if (crumb.title === "View") {
        console.log("View");
        // navigate("/14/admin");
      }
    };

    const excelDateToJSDate = (excelDate) => {
      const startDate = new Date(Date.UTC(1900, 0, 1)); // Excel base date is 1900-01-01
      const correctedDate = new Date(startDate.getTime() + (excelDate - 2) * 24 * 60 * 60 * 1000); // Adjust for leap year bug
      return correctedDate;
    };

    const isValidExcelDate = (value) => {
      return !isNaN(value) && value > 0;
    };

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Get first sheet
        const sheet = workbook.Sheets[sheetName];

        // Ensure we read only headers
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // console.log("Raw Sheet Data:", jsonData); // Debugging: Check the first row

        if (jsonData.length === 0) {
          setButtonText("File Format is Incorrect !");
          setIsUploaded(false);
          setIsError(true);
          return;
        }

        // Extract headers from the first row
        const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
        const expectedHeaders = allowedFormat.map(header => header.trim().toLowerCase());

        // console.log("Uploaded Headers:", uploadedHeaders);
        // console.log("Expected Headers:", expectedHeaders);

        // Validate headers
        const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));

        if (isValidFormat) {
          // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
          const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);

          // Validate data types
          let isTypeMismatch = false;

        // Convert Excel date serial numbers to readable dates
        const formattedRows = rows.map((row, rowIndex) => {
          const formattedRow = { ...row };

          // Object.keys(expectedDataTypes).forEach((key) => {
          //   if (row[key] !== undefined) {
          //     const expectedType = expectedDataTypes[key];
  
          //     const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 2, c: uploadedHeaders.indexOf(key.toLowerCase()) });
          //   const cell = sheet[cellRef];

          //   if (key === "AmountFormat") {
          //     if (cell) {
          //       console.log(`Row ${rowIndex + 3}, Column ${key}: Detected Format →`, cell.t);
                
          //       if (cell.t !== "s") { // If cell is not in Text format
          //         console.error(`❌ Cell format mismatch at row ${rowIndex + 3}, column ${key}: Expected Text format`);
          //         isTypeMismatch = true;
          //       }
          //     } else {
          //       console.warn(`⚠️ Missing cell data at row ${rowIndex + 3}, column ${key}`);
          //       isTypeMismatch = true;
          //     }
          //   }

            
          //   }
          // });

          
          // Convert date fields
          if (formattedRow.ValidFrom && !isNaN(formattedRow.ValidFrom)) {
            const date = excelDateToJSDate(formattedRow.ValidFrom);
            formattedRow.ValidFrom = date.toISOString().split("T")[0]; // Format: YYYY-MM-DD
          }
          if (formattedRow.ValidTo && !isNaN(formattedRow.ValidTo)) {
            const date = excelDateToJSDate(formattedRow.ValidTo);
            formattedRow.ValidTo = date.toISOString().split("T")[0];
          }
          if (formattedRow.JoiningDate && !isNaN(formattedRow.JoiningDate)) {
            const date = excelDateToJSDate(formattedRow.JoiningDate);
            formattedRow.JoiningDate = date.toISOString().split("T")[0];
          }
          if (formattedRow.ResignationDate && !isNaN(formattedRow.ResignationDate)) {
            const date = excelDateToJSDate(formattedRow.ResignationDate);
            formattedRow.ResignationDate = date.toISOString().split("T")[0];
          }
      
          return formattedRow;
        });
      
        console.log("isTypeMismatch:", isTypeMismatch);

        if (isTypeMismatch === true) {
          setButtonText("Format Cells is Incorrect !");
          setIsUploaded(false);
          setIsError(true);
          return;
        }

        console.log("set Table Data:", formattedRows);

        setTableData(formattedRows);
          setButtonText("File Uploaded");
          setIsUploaded(true);
          setIsError(false);
        } else {
          setButtonText("File Format is Incorrect !");
          setIsUploaded(false);
          setIsError(true);
          setShowConfirmation(true);
        }

        setTimeout(() => setIsUploaded(false), 1000);
      };
      reader.readAsArrayBuffer(file);
    };

 
  useEffect(() => {
    fetchAssignee();

    fetchExistingUserids();
  fetchExistingLanguageIds();
  fetchCountryCodes();
  fetchStateProvinceCode();
  fetchCompanyCodes();
  fetchCompanyGreenCodes();
  }, []);
      
  const fetchExistingUserids = async () => {
    try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/get-userIds-code`
        );
        console.log("response. Request Number data",response.data);
        setgetExistsUserids(response.data.data);
        
        // console.log("Set user types data", response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
      };
  
      const fetchExistingLanguageIds = async () => {
        try {
            const response = await UserService.getUsers(
              `${process.env.REACT_APP_BASE_URL}/get-language-code`
            );
            console.log("response. Request Number data",response.data);
            setgetExistsLanguageids(response.data.data);
            
            // console.log("Set user types data", response.data.data);
          } catch (error) {
            console.error("Error fetching user types:", error);
          }
          };
          
          const fetchCountryCodes = async () => {
            try {
              const response = await UserService.getUsers(
                `${process.env.REACT_APP_BASE_URL}/get-country-code`
              );
              // console.log("response.data.data",response.data.data);
              setCountryCodes(response.data.data);
              // console.log("Set user types data", response.data.data);
            } catch (error) {
              console.error("Error fetching user types:", error);
            }
          };
          const fetchStateProvinceCode = async () => {
            try {
              const response = await UserService.getUsers(
                `${process.env.REACT_APP_BASE_URL}/get-stateprovince-code`
              );
              console.log("response. state data",response.data.data);
              setStateCodes(response.data.data);
              // console.log("Set user types data", response.data.data);
            } catch (error) {
              console.error("Error fetching user types:", error);
            }
            };
  
      const fetchCompanyCodes = async () => {
        try {
          const response = await UserService.getUsers(
            `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-code`
          );
          console.log("response. Company data",response.data.data);
          setCompanies(response.data.data);
          // console.log("Set user types data", response.data.data);
        } catch (error) {
          console.error("Error fetching user types:", error);
        }
        };
  
        const fetchCompanyGreenCodes = async () => {
          try {
            const response = await UserService.getUsers(
              `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-green-code`
            );
            console.log("response. LGE data",response.data.data);
            setCompaniesGreen(response.data.data);
            // console.log("Set user types data", response.data.data);
          } catch (error) {
            console.error("Error fetching user types:", error);
          }
          };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",

        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        // console.log("Assignee ID:", assigneeData.id);
        // console.log("Assigner:", assigneeData.assigner);
        // console.log("Assigner clientId:", assigneeData.clientId);
        // console.log("Assigner userId:", assigneeData.userId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for basic email validation
    return emailRegex.test(email);
  };



  const getDataToDisplay = async ( uploadOption, tableData) => {
    
    let dataToDisplay;
    if (displayOption === "1") {
      console.log("Display option is 1");
  
      let processed=[];


      if(getExistsUserids && getExistsLanguageids && companies && companiesGreen && countryCodes && stateCodes){
        processed = await SuccessOnlyprocessData(tableData);
      }
  
      
      const existingUserIds = new Set(processed.map(user => user.UserID));
  
      dataToDisplay = tableData.filter(user => !existingUserIds.has(user.UserID));
         
  
    } else if (displayOption === "2") {
      console.log("Display option is 2");
      dataToDisplay = tableData; // Display tableData if displayOption is "2"
    } 

    if (uploadOption === "3") {
    console.log("user upload displayOption is 3");
    // dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
    let processed=[];

    if(getExistsUserids && getExistsLanguageids && companies && companiesGreen && countryCodes && stateCodes){
      processed = await SuccessOnlyprocessData(tableData);
    }

    dataToDisplay = processed; 
    } else {
    dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
    
  console.log("dataToDisplay data is",dataToDisplay);
  return dataToDisplay;
};


async function checkDuplicateUserIdsFromDB(userIds) {
  try {
    const data = { userIds };
    // console.log("UserIds duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-Users-duplicates`,
      data
    );

    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}

const SuccessOnlyprocessData = async (data) => {
  const successfulRecordsArray = [];
  
  const userIds = data.map(row => row.UserID).filter(Boolean);
  const countryIds = data.map(row => row.CountryId).filter(Boolean);
  console.log("Extracted userIds:", userIds);
  console.log("Extracted countryIds:", countryIds);

  const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(userIds);

  await Promise.all(data.map(async (row, _, allRows) => {

  // Check for duplicate same userId is repeated in the data
  const isDuplicateUserId = allRows.filter(r => r.UserID == row.UserID).length > 1;
  console.log("Duplicate user id is ",isDuplicateUserId);

 // Check for duplicate userId in the database
 const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.UserID);

const normalize = (value) => String(value).trim().toUpperCase();
const userExists = getExistsUserids.map(normalize).includes(normalize(row.UserID));
const languageExists = getExistsLanguageids.map(normalize).includes(normalize(row.Language));
const CompanyExists = companies.map(normalize).includes(normalize(row.CompanyCodeId));
const CompanyGreenExists = companiesGreen.map(normalize).includes(normalize(row.CompanyCodeId));
const countryIDExists = countryCodes.map(normalize).includes(normalize(row.CountryId));
// const stateIDExists = stateCodes.map(normalize).includes(normalize(row.StateProvince));
const stateIDExists = stateCodes.some((entry) =>
  normalize(entry.state) === normalize(row.StateProvince) &&
  normalize(entry.countryCode) === normalize(row.CountryId)
);

 const hasNotError = [
  row.SLNo,
  row.Action,
  row.UserID,
  row.Username,
  row.FirstName,
  row.MiddleName,
  row.LastName,
  row.AccountingSystemId,
  row.MobileNo,
  row.WhatsAppNo,
  row.OfficeNo,
  row.Extension,
  row.PassportNo,
  row.DrivingLicenseNo,
  row.LocalId,
  row.Nationality,
  row.EmployeeNo,
  row.CompanyCodeId,
  row.Department,
  row.ValidFrom,
  row.ValidTo,
  row.ResignationDate,
  row.JoiningDate,
  row.Address1,
  row.Address2,
  row.Address3,
  row.CountryId,
  row.StateProvince,
  row.Language,
  row.DateFormat,
  row.TimeFormat,
  row.AmountFormat,
  // row.ThousandSeparator,
  // row.DecimalSeparator,
  row.AcceleratorForThousands,
  row.AcceleratorForLakhs,
  row.AcceleratorForMillions,
  row.Email

].every(field => {
  let isValid = true;
  let reason = "";
  
  if (field === row.SLNo) {
    if (!field) { isValid = false; reason = "SLNo should not be blank."; }
    else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
    else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
  }
  
  else if (field === row.Action) {
    if (!field) { isValid = false; reason = "Action should not be blank."; }
    else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
  }
  
  
  if (!row.UserID || String(row.UserID).trim() === "") {
    if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
      isValid = false;
      reason = "User ID should not be empty.";
  }
  } else if (field === row.UserID) {
      if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
        isValid = false; reason = "User ID does not exist.";
      } else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
        isValid = false; reason = "User ID is duplicate.";
      }
  }

  else if (field === row.Username) {
    if (row.Action === "E" || row.Action === "e") {
      if (!field) { isValid = true; reason = "Username should be blank for Edit action."; }
      else if (String(field).length > 12) { isValid = false; reason = "Username exceeds max length of 12."; }
    } else if (row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = false; reason = "Username should not be blank for Add action."; }
      if (String(field).length > 12) { isValid = false; reason = "Username exceeds max length of 12."; }
    }
  }
  
  else if (field === row.FirstName) {
    if (row.Action === "E" || row.Action === "e") {
      if (!field) { isValid = true; reason = "firstName should be blank for Edit action."; }
      else if (String(field).length > 20) { isValid = false; reason = "firstName exceeds max length of 20."; }
    } else if (row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = false; reason = "firstName should not be blank for Add action."; }
      if (String(field).length > 20) { isValid = false; reason = "firstName exceeds max length of 20."; }
    }
  }

  else if (field === row.MiddleName) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "middleName should be blank for Add/Edit action."; }
      else if (String(field).length > 20) { isValid = false; reason = "middleName exceeds max length of 20."; }
    }
  }

  if (!row.LastName || String(row.LastName).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "LastName should not be empty.";
  }
  } else if (field === row.LastName) {
      if (row.Action === "E" || row.Action === "e") {
        if (!String(field || "").trim()) {
              isValid = true;
              reason = "LastName should be blank for Edit action.";
          } else if (String(field).trim().length > 20) {
              isValid = false;
              reason = "LastName exceeds max length of 20.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
        if (!String(field || "").trim()) {
              isValid = false;
              reason = "LastName should not be blank for Add action.";
          } else if (String(field).trim().length > 20) {
              isValid = false;
              reason = "LastName exceeds max length of 20.";
          }
      }
  }

  else if (field === row.AccountingSystemId) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AccountingSystemId should be blank for Add/Edit action."; }
      else if (String(field).length > 12) { isValid = false; reason = "AccountingSystemId exceeds max length of 12."; }
    }
  }

  if (!row.MobileNo || String(row.MobileNo).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "MobileNo should not be empty.";
  }
} else if (field === row.MobileNo) {
    const mobileNoStr = String(field); // Ensure it's a string

    if (row.Action === "E" || row.Action === "e") {
        if (mobileNoStr.trim() === "") {
            isValid = true;
            reason = "Mobile No should be blank for Edit action.";
        } else {
            if (isNaN(mobileNoStr)) {
                isValid = false;
                reason = "Mobile No should be a valid number.";
            }
            if (mobileNoStr.length > 13) {
                isValid = false;
                reason = "Mobile No exceeds max length of 13.";
            }
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (mobileNoStr.trim() === "") {
            isValid = false;
            reason = "Mobile No should not be blank for Add action.";
        } else {
            if (isNaN(mobileNoStr)) {
                isValid = false;
                reason = "Mobile No should be a valid number.";
            }
            if (mobileNoStr.length > 13) {
                isValid = false;
                reason = "Mobile No exceeds max length of 13.";
            }
        }
    }
}

  
  else if (field === row.WhatsAppNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "whatsApp No should be blank for Edit action."; }
      else{
        if (isNaN(field)) { isValid = false; reason = "whatsApp No should be a valid number."; }
        if (String(field).length > 13) { isValid = false; reason = "whatsApp No exceeds max length of 13."; }
      }
    } 
  }

  else if (field === row.OfficeNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "office No should be blank for Edit action."; }
      else{
      if (isNaN(field)) { isValid = false; reason = "office No should be a valid number."; }
      if (String(field).length > 13) { isValid = false; reason = "office No exceeds max length of 13."; }
    } 
  } 
  }

  else if (field === row.Extension) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "extension should be blank for Edit action."; }
      else{
      if (isNaN(field)) { isValid = false; reason = "extension should be a valid number."; }
      if (String(field).length > 10) { isValid = false; reason = "extension exceeds max length of 10."; }
    } 
  } 
  }

  else if (field === row.PassportNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "passportNo should be blank for Add/Edit action."; }
      else if (String(field).length > 10) { isValid = false; reason = "passportNo exceeds max length of 10."; }

    }
  }

  else if (field === row.DrivingLicenseNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "drivingLicense No should be blank for Add/Edit action."; }
      else if (String(field).length > 20) { isValid = false; reason = "drivingLicense No exceeds max length of 20."; }
    }
  }

  else if (field === row.LocalId) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "localId should be blank for Add/Edit action."; }
      else if (String(field).length > 20) { isValid = false; reason = "localId exceeds max length of 20."; }
    }
  }

  
  if (!row.Nationality || String(row.Nationality).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "Nationality should not be empty.";
  }
  } else if (field === row.Nationality) {
      if (row.Action === "E" || row.Action === "e") {
          if (!String(field || "").trim()) {
              isValid = true;
              reason = "Nationality should be blank for Edit action.";
          } else if (String(field).trim().length > 20) {
              isValid = false;
              reason = "Nationality exceeds max length of 20.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!String(field || "").trim()) {
              isValid = false;
              reason = "Nationality should not be blank for Add action.";
          } else if (String(field).trim().length > 20) {
              isValid = false;
              reason = "Nationality exceeds max length of 20.";
          }
      }
  }

  else if (field === row.EmployeeNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "employeeNo should be blank for Add/Edit action."; }
      else if (String(field).length > 12) { isValid = false; reason = "employeeNo exceeds max length of 12."; }
    }
  }

  if (row.CompanyCodeId) {
    if (String(field).length > 10) { isValid = false; reason = "companyCodeId exceeds max length of 10."; }

    if(CompanyExists){
      if(!CompanyGreenExists){
         isValid = false;
         reason = "CompanyCode is not exists because of the flag status";
      }
    }
    else{
      isValid = false;
      reason = "CompanyCode is not exists";
    }

  }

  else if (field === row.Department) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "department should be blank for Add/Edit action."; }
      else if (String(field).length > 6) { isValid = false; reason = "department exceeds max length of 6."; }
    }
  }

  if (!row.ValidFrom || String(row.ValidFrom).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "ValidFrom should not be empty.";
  }
} else if (field === row.ValidFrom) {
    const validFromStr = String(field).trim(); // Ensure it's a string

    if (row.Action === "E" || row.Action === "e") {
        if (validFromStr === "") {
            isValid = true;
            reason = "ValidFrom should be blank for Edit action.";
        } else if (validFromStr.length > 10) {
            isValid = false;
            reason = "ValidFrom exceeds max length of 10.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (validFromStr === "") {
            isValid = false;
            reason = "ValidFrom should not be blank for Add action.";
        } else if (validFromStr.length > 10) {
            isValid = false;
            reason = "ValidFrom exceeds max length of 10.";
        }
    }
}


  if (!row.ValidTo || String(row.ValidTo).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "ValidTo should not be empty.";
  }
} else if (field === row.ValidTo) {
    const ValidToStr = String(field).trim(); // Ensure it's a string

    if (row.Action === "E" || row.Action === "e") {
        if (ValidToStr === "") {
            isValid = true;
            reason = "ValidTo should be blank for Edit action.";
        } else if (ValidToStr.length > 10) {
            isValid = false;
            reason = "ValidTo exceeds max length of 10.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (ValidToStr === "") {
            isValid = false;
            reason = "ValidTo should not be blank for Add action.";
        } else if (ValidToStr.length > 10) {
            isValid = false;
            reason = "ValidTo exceeds max length of 10.";
        }
    }
}

else if (field === row.JoiningDate) {
  if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
    if (!field) { isValid = true; reason = "joiningDate should be blank for Add/Edit action."; }
    else if (String(field).length > 10) { isValid = false; reason = "joiningDate exceeds max length of 10."; }
  }
}

// if (row.JoiningDate) {
//   const JoiningDateStr = String(row.JoiningDate).trim(); // Ensure it's a string

//   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
//       if (JoiningDateStr.length > 10) {
//           isValid = false;
//           reason = "JoiningDate exceeds max length of 10.";
//       }
//   }
// }

  else if (field === row.ResignationDate) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "ResignationDate should be blank for Add/Edit action."; }
      else if (String(field).length > 10) { isValid = false; reason = "ResignationDate exceeds max length of 10."; }
    }
  }



  if (row.Address1 && String(row.Address1).length > 50) {
    isValid = false;
    reason = "Address1 exceeds max length of 50.";
} else {
// field = row.Address1 || "";  

    const missingFields = [
      row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
      row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
      row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
      row.CityName,  row.Address1, row.Address2, row.Address3
  ].every(value => !value); // Check if all values are falsy (undefined, null, empty, false)
  
  if (row.Action === "E" || row.Action === "e") {
      if (!field) { 
          isValid = true; 
          reason = "Address1 should be blank for Edit action."; 
      } else if (field.length > 50) { 
          isValid = false; 
          reason = "Address1 exceeds max length of 50."; 
      }
  } else if (row.Action === "A" || row.Action === "a") {
      console.log("Address field 1 enter log");
      console.log("check door no:", row.DoorNo);
  
      if (missingFields) { 
          isValid = false; 
          reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and Address1 should not be blank for Add action."; 
      }
  }
  }
   
  // Ensure field is explicitly assigned to row.Address1 if available

// Check if all address-related fields are missing


  if (field === row.Address2) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "addressLine2 should be blank for Add/Edit action."; }
      else if (String(field).length > 50) { isValid = false; reason = "addressLine2 exceeds max length of 50."; }
    }
  }

  if (field === row.Address3) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "addressLine3 should be blank for Add/Edit action."; }
      else if (String(field).length > 50) { isValid = false; reason = "addressLine3 exceeds max length of 50."; }
    }
  }


  if (!row.CountryId || String(row.CountryId).trim() === "") {
    if (row.Action === "A" || row.Action === "a" ) {
      isValid = false;
      reason = "CountryId should not be empty.";
    }
} else if (field === row.CountryId) {
    if (!countryIDExists) {
        isValid = false;
        reason = "Country code is Not exists."; 
    }
    if (String(field).trim().length > 2) {
      isValid = false;
      reason = "CountryId exceeds max length of 2.";
  }
}



// if (!row.StateProvince || String(row.StateProvince).trim() === "") {
//   if (row.Action === "A" || row.Action === "a" ) {
//     isValid = false;
//     reason = "StateProvince should not be empty.";
//   }
// } else 
if (row.StateProvince) {
  if (!stateIDExists) {
      isValid = false;
      reason = "StateProvince is Not exists."; 
  }
  if (String(row.StateProvince).trim().length > 2) {
    isValid = false;
    reason = "StateProvince exceeds max length of 2.";
}
}

  //  if (field === row.StateProvince) {
  //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //     if (!field) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
  //     else if (String(field).length > 2) { isValid = false; reason = "StateProvince exceeds max length of 2."; }
  //   }
  // }


  if ((!row.Language || String(row.Language).trim() === "") && (row.Action === "A" || row.Action === "a")) {
      isValid = false;
      reason = "Language should not be empty.";
  
  } else if (field === row.Language) {
    if(!languageExists){
      isValid = false;
      reason = "language is not exists. ";
  }
      if (row.Action === "E" || row.Action === "e") {
          if (!String(field || "").trim()) {
              isValid = true;
              reason = "Language should be blank for Edit action.";
          } else if (String(field).trim().length > 2) {
              isValid = false;
              reason = "Language exceeds max length of 2.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
       

          if (!String(field || "").trim()) {
              isValid = false;
              reason = "Language should not be blank for Add action.";
          } else if (String(field).trim().length > 2) {
              isValid = false;
              reason = "Language exceeds max length of 2.";
          }
      }
  }


  if (!row.DateFormat || String(row.DateFormat).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "DateFormat should not be empty.";
  }
  } else if (field === row.DateFormat) {
      if (row.Action === "E" || row.Action === "e") {
          if (!String(field || "").trim()) {
              isValid = true;
              reason = "DateFormat should be blank for Edit action.";
          } else if (String(field).trim().length > 10) {
              isValid = false;
              reason = "DateFormat exceeds max length of 10.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!String(field || "").trim()) {
              isValid = false;
              reason = "DateFormat should not be blank for Add action.";
          } else if (String(field).trim().length > 10) {
              isValid = false;
              reason = "DateFormat exceeds max length of 10.";
          }
      }
  }

  if (!row.TimeFormat || String(row.TimeFormat).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "TimeFormat should not be empty.";
  }
  } else if (field === row.TimeFormat) {
      if (row.Action === "E" || row.Action === "e") {
          if (!String(field || "").trim()) {
              isValid = true;
              reason = "TimeFormat should be blank for Edit action.";
          } else if (String(field).trim().length > 8) {
              isValid = false;
              reason = "TimeFormat exceeds max length of 8.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!String(field || "").trim()) {
              isValid = false;
              reason = "TimeFormat should not be blank for Add action.";
          } else if (String(field).trim().length > 8) {
              isValid = false;
              reason = "TimeFormat exceeds max length of 8.";
          }
      }
  }

  if (!row.AmountFormat || String(row.AmountFormat).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "AmountFormat should not be empty.";
  }
  } else if (field === row.AmountFormat) {
      if (row.Action === "E" || row.Action === "e") {
        if (!String(field || "").trim()) {
              isValid = true;
              reason = "AmountFormat should be blank for Edit action.";
          } else if (String(field).trim().length > 19) {
              isValid = false;
              reason = "AmountFormat exceeds max length of 19.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
        if (!String(field || "").trim()) {
              isValid = false;
              reason = "AmountFormat should not be blank for Add action.";
          } else if (String(field).trim().length > 19) {
              isValid = false;
              reason = "AmountFormat exceeds max length of 19.";
          }
      }
  }

  else if (field === row.AcceleratorForThousands) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForThousands should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForThousands exceeds max length of 1."; }
      if (!["K", "T"].includes(String(field))) { isValid = false; reason = "Only 'K' or 'T' allowed."; }
      }
      }
  }

  else if (field === row.AcceleratorForLakhs) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForLakhs should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForLakhs exceeds max length of 1."; }
      if (!["L"].includes(String(field))) { isValid = false; reason = "Only 'L' allowed."; }
      }
      }
  }

  
  else if (field === row.AcceleratorForMillions) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForMillions should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForMillions exceeds max length of 1."; }
      if (!["M"].includes(String(field))) { isValid = false; reason = "Only 'M' allowed."; }
      }
      }
  }

  else if (field === row.Email) {
    if (row.Action === "E" || row.Action === "e") {
      if (!field) { isValid = true; reason = "email should be blank for Edit action."; }
      else{
        if (String(field).length > 80) { isValid = false; reason = "email exceeds max length of 80."; }
        if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid email format."; }
      }
    } else if (row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = false; reason = "email should not be blank for Add action."; }
      if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid email format."; }
      if (String(field).length > 80) { isValid = false; reason = "email exceeds max length of 80."; }
    }
  }


  if (!isValid) {
    console.log(`Validation Failed: ${reason}`);
  } else {
    console.log(`Validation Passed: ${field}`);
  }
  
  return isValid;
});

if (hasNotError) {
  successfulRecordsArray.push(row);
}

console.log(`Final hasNotError status: ${hasNotError}`);

    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error

      isDuplicateUserId,
      isDuplicateInDB,
      userExists,

      Action: row.Action ? (
        String(row.Action).length === 1 ? (
          ["A", "a", "E", "e"].includes(row.Action) ? (
            row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Only "A", "E" are allowed)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Max. allowed character is 1)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
        

        UserID: row.UserID ? (
          String(row.UserID).length > 12 ? (
            <span style={{ color: "red" }}>
              {row.UserID} (Max. allowed character is 12)
            </span>
          ) : row.Action === "A" || row.Action === "a" ? (
            // When Action is "A", show duplicate message if user ID is a duplicate
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span style={{ color: "red" }}>
                {row.UserID} (User ID Duplicate exists)
              </span>
            ) : (
              String(row.UserID) // Otherwise, show userId normally
            )
          ) : row.Action === "E" || row.Action === "e" ? (
            // When Action is "E", show userId without the duplicate message
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span>
                {row.UserID} 
              </span> 
            ) : (
              // String(row.userId) // Otherwise, show userId normally
              <span style={{ color: "red" }}>
              {row.UserID} (User ID not exists)
            </span>
            )
          ) : (
            String(row.UserID) // Default case, show userId normally
          )
        ) : (
          <span style={{ color: "red" }}>
            Shouldn't be blank
          </span>
        ),

        
        Username: row.Username ? (
          String(row.Username).length > 12 ? (
            <span style={{ color: "red" }}>
              {row.Username} (Max. allowed character is 12)
            </span>
          ) : (
            String(row.Username) // Ensure Username is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

  
        FirstName: row.FirstName ? (
          String(row.FirstName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.FirstName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.FirstName) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        MiddleName: row.MiddleName ? (
          String(row.MiddleName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.MiddleName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.MiddleName) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        AccountingSystemId: row.AccountingSystemId ? (
          String(row.AccountingSystemId).length > 12 ? (
            <span style={{ color: "red"}}>
              {row.AccountingSystemId} (Max. allowed character is 12)
            </span>
          ) : (
              String(row.AccountingSystemId) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        LastName: row.LastName ? (
          String(row.LastName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.LastName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.LastName) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        MobileNo: row.MobileNo ? (
          isNaN(row.MobileNo) ? (
            <span style={{ color: "red"}}>
              {row.MobileNo} (Mismatched Data Type)
            </span>
          ) : String(row.MobileNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.MobileNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.MobileNo // Valid MobileNo
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        WhatsAppNo: row.WhatsAppNo ? (
          isNaN(row.WhatsAppNo) ? (
            <span style={{ color: "red"}}>
              {row.WhatsAppNo} (Mismatched Data Type)
            </span>
          ) : String(row.WhatsAppNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.WhatsAppNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.WhatsAppNo // Valid WhatsAppNo
          )
        ) : (
          <span> </span>
        ),
  
        OfficeNo: row.OfficeNo ? (
          isNaN(row.OfficeNo) ? (
            <span style={{ color: "red"}}>
              {row.OfficeNo} (Mismatched Data Type)
            </span>
          ) : String(row.OfficeNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.OfficeNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.OfficeNo // Valid OfficeNo
          )
        ) : (
          <span> </span>
        ),
        Extension: row.Extension ? (
          isNaN(row.Extension) ? (
            <span style={{ color: "red"}}>
              {row.Extension} (Mismatched Data Type)
            </span>
          ) : String(row.Extension).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.Extension} (Max. allowed character is 10)
            </span>
          ) : (
            row.Extension // Valid Extension
          )
        ) : (
          <span> </span>
        ),
  
        PassportNo: row.PassportNo ? (
          String(row.PassportNo).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.PassportNo} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.PassportNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        DrivingLicenseNo: row.DrivingLicenseNo ? (
          String(row.DrivingLicenseNo).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.DrivingLicenseNo} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.DrivingLicenseNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        LocalId: row.LocalId ? (
          isNaN(row.LocalId) ? (
            <span style={{ color: "red"}}>
              {row.LocalId} (Mismatched Data Type)
            </span>
          ) : String(row.LocalId).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.LocalId} (Max. allowed character is 20)
            </span>
          ) : (
            row.LocalId // Valid LocalId
          )
        ) : (
          <span> </span>
        ),
  
        Nationality: row.Nationality ? (
          String(row.Nationality).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.Nationality} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.Nationality) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        EmployeeNo: row.EmployeeNo ? (
          String(row.EmployeeNo).length > 12 ? (
            <span style={{ color: "red"}}>
              {row.EmployeeNo} (Max. allowed character is 12)
            </span>
          ) : (
              String(row.EmployeeNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
         
          </span>
        ),
  
        CompanyCodeId: row.CompanyCodeId ? (
          String(row.CompanyCodeId).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.CompanyCodeId} (Max. allowed character is 10)
            </span>
          ) : CompanyExists ? (
            CompanyGreenExists ? (
            <span>{row.CompanyCodeId}</span>
          ): (
            <span style={{ color: "red" }}>
              {row.CompanyCodeId} (Company is not approved.)
            </span>
          )
        ) : (
           <span style={{ color: "red" }}>
              {row.CompanyCodeId} (Company is not exists.)
          </span>
        )
      ):(
        <span></span>
      ),


        Department: row.Department ? (
          String(row.Department).length > 6 ? (
            <span style={{ color: "red"}}>
              {row.Department} (Max. allowed character is 6)
            </span>
          ) : (
              String(row.Department) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
        ValidFrom: row.ValidFrom ? (
          String(row.ValidFrom).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.ValidFrom} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.ValidFrom) // Ensure userId is treated as a string
          )
        ) : (
          
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
          

        ),
  
        ValidTo: row.ValidTo ? (
          String(row.ValidTo).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.ValidTo} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.ValidTo) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
     
        ResignationDate: row.ResignationDate ? (
          String(row.ResignationDate).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.ResignationDate} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.ResignationDate) // Ensure userId is treated as a string
          )
        ) : (
          <span>
          
          </span>
        ),
  
        JoiningDate: row.JoiningDate ? (
          String(row.JoiningDate).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.JoiningDate} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.JoiningDate) // Ensure userId is treated as a string
          )
        ) : (
          <span>
          
          </span>
        ),
  
        Address1: row.Address1 ? (
          String(row.Address1).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.Address1} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.Address1) // Ensure userId is treated as a string
          )
        ) : (

          row.Action === "A" || row.Action === "a" ? (
            
          !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.AreaName && !row.Phase && !row.Sector && !row.Village && !row.Town && !row.CityName && !row.Address2 && !row.Address3
          // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
          ? (
            <span style={{ color: "red" }}>
                Either Address Section or Building, Street, and Area Section must be entered
            </span>
        ) : (
            <span></span>
        )
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any

          
        ),
  
        Address2: row.Address2 ? (
          String(row.Address2).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.Address2} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.Address2) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        Address3: row.Address3 ? (
          String(row.Address3).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.Address3} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.Address3) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        CountryId: row.CountryId ? (
          String(row.CountryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.CountryId} (Max. allowed character is 2)
            </span>
          ) : countryIDExists && displayOption !== "" ? (
            <span>{row.CountryId}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.CountryId} (Country Code not exists)
            </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
      
        StateProvince: row.StateProvince ? (
          String(row.StateProvince).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.StateProvince} (Max. allowed character is 3)
            </span>
          ) :  stateIDExists && displayOption !== "" ? (
            <span>{row.StateProvince}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.StateProvince} (StateProvince Code not exists)
            </span>
          )
        ) : (
          <span>
           
          </span>
        ),
  
        Language: row.Language ? (
          String(row.Language).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.Language} (Max. allowed character is 3)
            </span>
          ) : languageExists ?
              ( <span>{row.Language}</span> ) :
              (
                <span style={{ color: "red" }}>
                {row.Language} (Language Code not exists)
              </span>
              )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
        
        DateFormat: row.DateFormat ? (
          String(row.DateFormat).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.DateFormat} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.DateFormat) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        TimeFormat: row.TimeFormat ? (
          String(row.TimeFormat).length > 8 ? (
            <span style={{ color: "red"}}>
              {row.TimeFormat} (Max. allowed character is 8)
            </span>
          ) : (
              String(row.TimeFormat) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        AmountFormat: row.AmountFormat ? (
          String(row.AmountFormat).length > 19 ? (
            <span style={{ color: "red"}}>
              {row.AmountFormat} (Max. allowed character is 19)
            </span>
          ) : (
              String(row.AmountFormat) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
          AcceleratorForThousands: row.AcceleratorForThousands ? ( 
            String(row.AcceleratorForThousands).length > 1 ? (
                <span style={{ color: "red"}}>
                    {row.AcceleratorForThousands} (Max. allowed character is 1)
                </span>
            ) : !["K", "T"].includes(String(row.AcceleratorForThousands)) ? (
                <span style={{ color: "red"}}>
                    {row.AcceleratorForThousands} (Only 'K' or 'T' allowed)
                </span>
            ) : (
                <span>{row.AcceleratorForThousands}</span>
            )
        ) : (
            <span></span>
        ),

  
            // AcceleratorForLakhs: row.AcceleratorForLakhs ? (
            //   String(row.AcceleratorForLakhs).length > 1 ? (
            //       <span style={{ color: "red"}}>
            //       {row.AcceleratorForLakhs} (Max. allowed character is 1)
            //       </span>
            //   ) : !["L"].includes(String(row.AcceleratorForLakhs)) ? (
            //       <span style={{ color: "red"}}>
            //       {row.AcceleratorForLakhs} (Only 'L' allowed)
            //       </span>
            //   ): (
            //     <span> {row.AcceleratorForLakhs} </span>
            //   )
            //   ) : (
            //   <span>
                
            //   </span>
            //   ),

            AcceleratorForLakhs: row.AcceleratorForLakhs ? (
              String(row.AcceleratorForLakhs).trim() === "L" ? (
                  <span>{row.AcceleratorForLakhs}</span>
              ) : (
                  <span style={{ color: "red" }}>
                      {row.AcceleratorForLakhs} (Only 'L' allowed)
                  </span>
              )
          ) : (
              <span></span>
          ),

              // AcceleratorForMillions: row.AcceleratorForMillions ? (
              //   String(row.AcceleratorForMillions).length > 1 ? (
              //       <span style={{ color: "red"}}>
              //       {row.AcceleratorForMillions} (Max. allowed character is 1)
              //       </span>
              //   ) : !["M"].includes(String(row.AcceleratorForMillions)) ? (
              //       <span style={{ color: "red"}}>
              //       {row.AcceleratorForMillions} (Only 'M' allowed)
              //       </span>
              //   ) : (
              //     <span> {row.AcceleratorForMillions}</span>
              //   )
              //   ) 
              //   :
              //    (
              //   <span>
                  
              //   </span>
              //   ),
              AcceleratorForMillions: row.AcceleratorForMillions ? (
                String(row.AcceleratorForMillions).trim() === "M" ? (
                    <span>{row.AcceleratorForMillions}</span>
                ) : (
                    <span style={{ color: "red" }}>
                        {row.AcceleratorForMillions} (Only 'M' allowed)
                    </span>
                )
            ) : (
                <span></span>
            ),



        Email: row.Email ? (
          String(row.Email).length > 80 ? (
            <span style={{ color: "red"}}>
              {row.Email} (Max. allowed character is 80)
            </span>
          ) : !validateEmail(row.Email) ? (
            <span style={{ color: "red"}}>
              {row.Email} (Invalid Email format)
            </span>
          ) : (
            row.Email // If valid, display the email normally
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ), 
    
      };  
  }));
  setAccountNoUpdateSuccessfulRecords(successfulRecordsArray);
  return successfulRecordsArray;

}

const FullprocessData = async (data) => {
  const successfulRecordsArray = [];
  
  const userIds = data.map(row => row.UserID).filter(Boolean);
  const countryIds = data.map(row => row.CountryId).filter(Boolean);
  console.log("Extracted userIds:", userIds);
  console.log("Extracted countryIds:", countryIds);

  const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(userIds);

  const results = await Promise.all(data.map(async (row, _, allRows) => {

  // Check for duplicate same userId is repeated in the data
  const isDuplicateUserId = allRows.filter(r => r.UserID == row.UserID).length > 1;
  console.log("Duplicate user id is ",isDuplicateUserId);

 // Check for duplicate userId in the database
 const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.UserID);

const normalize = (value) => String(value).trim().toUpperCase();
const userExists = getExistsUserids.map(normalize).includes(normalize(row.UserID));
const languageExists = getExistsLanguageids.map(normalize).includes(normalize(row.Language));
const CompanyExists = companies.map(normalize).includes(normalize(row.CompanyCodeId));
const CompanyGreenExists = companiesGreen.map(normalize).includes(normalize(row.CompanyCodeId));
const countryIDExists = countryCodes.map(normalize).includes(normalize(row.CountryId));
// const stateIDExists = stateCodes.map(normalize).includes(normalize(row.StateProvince));
const stateIDExists = stateCodes.some((entry) =>
  normalize(entry.state) === normalize(row.StateProvince) &&
  normalize(entry.countryCode) === normalize(row.CountryId)
);

 const hasNotError = [
  row.SLNo,
  row.Action,
  row.UserID,
  row.Username,
  row.FirstName,
  row.MiddleName,
  row.LastName,
  row.AccountingSystemId,
  row.MobileNo,
  row.WhatsAppNo,
  row.OfficeNo,
  row.Extension,
  row.PassportNo,
  row.DrivingLicenseNo,
  row.LocalId,
  row.Nationality,
  row.EmployeeNo,
  row.CompanyCodeId,
  row.Department,
  row.ValidFrom,
  row.ValidTo,
  row.ResignationDate,
  row.JoiningDate,
  row.Address1,
  row.Address2,
  row.Address3,
  row.CountryId,
  row.StateProvince,
  row.Language,
  row.DateFormat,
  row.TimeFormat,
  row.AmountFormat,
  // row.ThousandSeparator,
  // row.DecimalSeparator,
  row.AcceleratorForThousands,
  row.AcceleratorForLakhs,
  row.AcceleratorForMillions,
  row.Email

].every(field => {
  let isValid = true;
  let reason = "";
  
  if (field === row.SLNo) {
    if (!field) { isValid = false; reason = "SLNo should not be blank."; }
    else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
    else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
  }
  
  else if (field === row.Action) {
    if (!field) { isValid = false; reason = "Action should not be blank."; }
    else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
  }
  
  
  if (!row.UserID || String(row.UserID).trim() === "") {
    if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
      isValid = false;
      reason = "User ID should not be empty.";
  }
  } else if (field === row.UserID) {
      if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
        isValid = false; reason = "User ID does not exist.";
      } else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
        isValid = false; reason = "User ID is duplicate.";
      }
  }

  else if (field === row.Username) {
    if (row.Action === "E" || row.Action === "e") {
      if (!field) { isValid = true; reason = "Username should be blank for Edit action."; }
      else if (String(field).length > 12) { isValid = false; reason = "Username exceeds max length of 12."; }
    } else if (row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = false; reason = "Username should not be blank for Add action."; }
      if (String(field).length > 12) { isValid = false; reason = "Username exceeds max length of 12."; }
    }
  }
  
  else if (field === row.FirstName) {
    if (row.Action === "E" || row.Action === "e") {
      if (!field) { isValid = true; reason = "firstName should be blank for Edit action."; }
      else if (String(field).length > 20) { isValid = false; reason = "firstName exceeds max length of 20."; }
    } else if (row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = false; reason = "firstName should not be blank for Add action."; }
      if (String(field).length > 20) { isValid = false; reason = "firstName exceeds max length of 20."; }
    }
  }

  else if (field === row.MiddleName) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "middleName should be blank for Add/Edit action."; }
      else if (String(field).length > 20) { isValid = false; reason = "middleName exceeds max length of 20."; }
    }
  }

  if (!row.LastName || String(row.LastName).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "LastName should not be empty.";
  }
  } else if (field === row.LastName) {
      if (row.Action === "E" || row.Action === "e") {
        if (!String(field || "").trim()) {
              isValid = true;
              reason = "LastName should be blank for Edit action.";
          } else if (String(field).trim().length > 20) {
              isValid = false;
              reason = "LastName exceeds max length of 20.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
        if (!String(field || "").trim()) {
              isValid = false;
              reason = "LastName should not be blank for Add action.";
          } else if (String(field).trim().length > 20) {
              isValid = false;
              reason = "LastName exceeds max length of 20.";
          }
      }
  }

  else if (field === row.AccountingSystemId) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AccountingSystemId should be blank for Add/Edit action."; }
      else if (String(field).length > 12) { isValid = false; reason = "AccountingSystemId exceeds max length of 12."; }
    }
  }

  if (!row.MobileNo || String(row.MobileNo).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "MobileNo should not be empty.";
  }
} else if (field === row.MobileNo) {
    const mobileNoStr = String(field); // Ensure it's a string

    if (row.Action === "E" || row.Action === "e") {
        if (mobileNoStr.trim() === "") {
            isValid = true;
            reason = "Mobile No should be blank for Edit action.";
        } else {
            if (isNaN(mobileNoStr)) {
                isValid = false;
                reason = "Mobile No should be a valid number.";
            }
            if (mobileNoStr.length > 13) {
                isValid = false;
                reason = "Mobile No exceeds max length of 13.";
            }
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (mobileNoStr.trim() === "") {
            isValid = false;
            reason = "Mobile No should not be blank for Add action.";
        } else {
            if (isNaN(mobileNoStr)) {
                isValid = false;
                reason = "Mobile No should be a valid number.";
            }
            if (mobileNoStr.length > 13) {
                isValid = false;
                reason = "Mobile No exceeds max length of 13.";
            }
        }
    }
}

  
  else if (field === row.WhatsAppNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "whatsApp No should be blank for Edit action."; }
      else{
        if (isNaN(field)) { isValid = false; reason = "whatsApp No should be a valid number."; }
        if (String(field).length > 13) { isValid = false; reason = "whatsApp No exceeds max length of 13."; }
      }
    } 
  }

  else if (field === row.OfficeNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "office No should be blank for Edit action."; }
      else{
      if (isNaN(field)) { isValid = false; reason = "office No should be a valid number."; }
      if (String(field).length > 13) { isValid = false; reason = "office No exceeds max length of 13."; }
    } 
  } 
  }

  else if (field === row.Extension) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "extension should be blank for Edit action."; }
      else{
      if (isNaN(field)) { isValid = false; reason = "extension should be a valid number."; }
      if (String(field).length > 10) { isValid = false; reason = "extension exceeds max length of 10."; }
    } 
  } 
  }

  else if (field === row.PassportNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "passportNo should be blank for Add/Edit action."; }
      else if (String(field).length > 10) { isValid = false; reason = "passportNo exceeds max length of 10."; }

    }
  }

  else if (field === row.DrivingLicenseNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "drivingLicense No should be blank for Add/Edit action."; }
      else if (String(field).length > 20) { isValid = false; reason = "drivingLicense No exceeds max length of 20."; }
    }
  }

  else if (field === row.LocalId) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "localId should be blank for Add/Edit action."; }
      else if (String(field).length > 20) { isValid = false; reason = "localId exceeds max length of 20."; }
    }
  }

  
  if (!row.Nationality || String(row.Nationality).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "Nationality should not be empty.";
  }
  } else if (field === row.Nationality) {
      if (row.Action === "E" || row.Action === "e") {
          if (!String(field || "").trim()) {
              isValid = true;
              reason = "Nationality should be blank for Edit action.";
          } else if (String(field).trim().length > 20) {
              isValid = false;
              reason = "Nationality exceeds max length of 20.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!String(field || "").trim()) {
              isValid = false;
              reason = "Nationality should not be blank for Add action.";
          } else if (String(field).trim().length > 20) {
              isValid = false;
              reason = "Nationality exceeds max length of 20.";
          }
      }
  }

  else if (field === row.EmployeeNo) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "employeeNo should be blank for Add/Edit action."; }
      else if (String(field).length > 12) { isValid = false; reason = "employeeNo exceeds max length of 12."; }
    }
  }

  if (row.CompanyCodeId) {
    if (String(field).length > 10) { isValid = false; reason = "companyCodeId exceeds max length of 10."; }

    if(CompanyExists){
      if(!CompanyGreenExists){
         isValid = false;
         reason = "CompanyCode is not exists because of the flag status";
      }
    }
    else{
      isValid = false;
      reason = "CompanyCode is not exists";
    }

  }

  else if (field === row.Department) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "department should be blank for Add/Edit action."; }
      else if (String(field).length > 6) { isValid = false; reason = "department exceeds max length of 6."; }
    }
  }

  if (!row.ValidFrom || String(row.ValidFrom).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "ValidFrom should not be empty.";
  }
} else if (field === row.ValidFrom) {
    const validFromStr = String(field).trim(); // Ensure it's a string

    if (row.Action === "E" || row.Action === "e") {
        if (validFromStr === "") {
            isValid = true;
            reason = "ValidFrom should be blank for Edit action.";
        } else if (validFromStr.length > 10) {
            isValid = false;
            reason = "ValidFrom exceeds max length of 10.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (validFromStr === "") {
            isValid = false;
            reason = "ValidFrom should not be blank for Add action.";
        } else if (validFromStr.length > 10) {
            isValid = false;
            reason = "ValidFrom exceeds max length of 10.";
        }
    }
}


  if (!row.ValidTo || String(row.ValidTo).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "ValidTo should not be empty.";
  }
} else if (field === row.ValidTo) {
    const ValidToStr = String(field).trim(); // Ensure it's a string

    if (row.Action === "E" || row.Action === "e") {
        if (ValidToStr === "") {
            isValid = true;
            reason = "ValidTo should be blank for Edit action.";
        } else if (ValidToStr.length > 10) {
            isValid = false;
            reason = "ValidTo exceeds max length of 10.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (ValidToStr === "") {
            isValid = false;
            reason = "ValidTo should not be blank for Add action.";
        } else if (ValidToStr.length > 10) {
            isValid = false;
            reason = "ValidTo exceeds max length of 10.";
        }
    }
}

  else if (field === row.ResignationDate) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "ResignationDate should be blank for Add/Edit action."; }
      else if (String(field).length > 10) { isValid = false; reason = "ResignationDate exceeds max length of 10."; }
    }
  }

  else if (field === row.JoiningDate) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "joiningDate should be blank for Add/Edit action."; }
      else if (String(field).length > 10) { isValid = false; reason = "joiningDate exceeds max length of 10."; }
    }
  }

  if (row.Address1 && String(row.Address1).length > 50) {
    isValid = false;
    reason = "Address1 exceeds max length of 50.";
} else {
// field = row.Address1 || "";  

    const missingFields = [
      row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
      row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
      row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
      row.CityName,  row.Address1, row.Address2, row.Address3
  ].every(value => !value); // Check if all values are falsy (undefined, null, empty, false)
  
  if (row.Action === "E" || row.Action === "e") {
      if (!field) { 
          isValid = true; 
          reason = "Address1 should be blank for Edit action."; 
      } else if (field.length > 50) { 
          isValid = false; 
          reason = "Address1 exceeds max length of 50."; 
      }
  } else if (row.Action === "A" || row.Action === "a") {
      console.log("Address field 1 enter log");
      console.log("check door no:", row.DoorNo);
  
      if (missingFields) { 
          isValid = false; 
          reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and Address1 should not be blank for Add action."; 
      }
  }
  }
   
  // Ensure field is explicitly assigned to row.Address1 if available

// Check if all address-related fields are missing


  if (field === row.Address2) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "addressLine2 should be blank for Add/Edit action."; }
      else if (String(field).length > 50) { isValid = false; reason = "addressLine2 exceeds max length of 50."; }
    }
  }

  if (field === row.Address3) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "addressLine3 should be blank for Add/Edit action."; }
      else if (String(field).length > 50) { isValid = false; reason = "addressLine3 exceeds max length of 50."; }
    }
  }


  if (!row.CountryId || String(row.CountryId).trim() === "") {
    if (row.Action === "A" || row.Action === "a" ) {
      isValid = false;
      reason = "CountryId should not be empty.";
    }
} else if (field === row.CountryId) {
    if (!countryIDExists) {
        isValid = false;
        reason = "Country code is Not exists."; 
    }
    if (String(field).trim().length > 2) {
      isValid = false;
      reason = "CountryId exceeds max length of 2.";
  }
}



// if (!row.StateProvince || String(row.StateProvince).trim() === "") {
//   if (row.Action === "A" || row.Action === "a" ) {
//     isValid = false;
//     reason = "StateProvince should not be empty.";
//   }
// } else 
if (row.StateProvince) {
  if (!stateIDExists) {
      isValid = false;
      reason = "StateProvince is Not exists."; 
  }
  if (String(row.StateProvince).trim().length > 2) {
    isValid = false;
    reason = "StateProvince exceeds max length of 2.";
}
}

  //  if (field === row.StateProvince) {
  //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //     if (!field) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
  //     else if (String(field).length > 2) { isValid = false; reason = "StateProvince exceeds max length of 2."; }
  //   }
  // }


  if ((!row.Language || String(row.Language).trim() === "") && (row.Action === "A" || row.Action === "a")) {
      isValid = false;
      reason = "Language should not be empty.";
  
  } else if (field === row.Language) {
    if(!languageExists){
      isValid = false;
      reason = "language is not exists. ";
  }
      if (row.Action === "E" || row.Action === "e") {
          if (!String(field || "").trim()) {
              isValid = true;
              reason = "Language should be blank for Edit action.";
          } else if (String(field).trim().length > 2) {
              isValid = false;
              reason = "Language exceeds max length of 2.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
       

          if (!String(field || "").trim()) {
              isValid = false;
              reason = "Language should not be blank for Add action.";
          } else if (String(field).trim().length > 2) {
              isValid = false;
              reason = "Language exceeds max length of 2.";
          }
      }
  }


  if (!row.DateFormat || String(row.DateFormat).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "DateFormat should not be empty.";
  }
  } else if (field === row.DateFormat) {
      if (row.Action === "E" || row.Action === "e") {
          if (!String(field || "").trim()) {
              isValid = true;
              reason = "DateFormat should be blank for Edit action.";
          } else if (String(field).trim().length > 10) {
              isValid = false;
              reason = "DateFormat exceeds max length of 10.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!String(field || "").trim()) {
              isValid = false;
              reason = "DateFormat should not be blank for Add action.";
          } else if (String(field).trim().length > 10) {
              isValid = false;
              reason = "DateFormat exceeds max length of 10.";
          }
      }
  }

  if (!row.TimeFormat || String(row.TimeFormat).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "TimeFormat should not be empty.";
  }
  } else if (field === row.TimeFormat) {
      if (row.Action === "E" || row.Action === "e") {
          if (!String(field || "").trim()) {
              isValid = true;
              reason = "TimeFormat should be blank for Edit action.";
          } else if (String(field).trim().length > 8) {
              isValid = false;
              reason = "TimeFormat exceeds max length of 8.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!String(field || "").trim()) {
              isValid = false;
              reason = "TimeFormat should not be blank for Add action.";
          } else if (String(field).trim().length > 8) {
              isValid = false;
              reason = "TimeFormat exceeds max length of 8.";
          }
      }
  }

  if (!row.AmountFormat || String(row.AmountFormat).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "AmountFormat should not be empty.";
  }
  } else if (field === row.AmountFormat) {
      if (row.Action === "E" || row.Action === "e") {
        if (!String(field || "").trim()) {
              isValid = true;
              reason = "AmountFormat should be blank for Edit action.";
          } else if (String(field).trim().length > 19) {
              isValid = false;
              reason = "AmountFormat exceeds max length of 19.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
        if (!String(field || "").trim()) {
              isValid = false;
              reason = "AmountFormat should not be blank for Add action.";
          } else if (String(field).trim().length > 19) {
              isValid = false;
              reason = "AmountFormat exceeds max length of 19.";
          }
      }
  }

  else if (field === row.AcceleratorForThousands) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForThousands should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForThousands exceeds max length of 1."; }
      if (!["K", "T"].includes(String(field))) { isValid = false; reason = "Only 'K' or 'T' allowed."; }
      }
      }
  }

  else if (field === row.AcceleratorForLakhs) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForLakhs should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForLakhs exceeds max length of 1."; }
      if (!["L"].includes(String(field))) { isValid = false; reason = "Only 'L' allowed."; }
      }
      }
  }

  
  else if (field === row.AcceleratorForMillions) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForMillions should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForMillions exceeds max length of 1."; }
      if (!["M"].includes(String(field))) { isValid = false; reason = "Only 'M' allowed."; }
      }
      }
  }

  else if (field === row.Email) {
    if (row.Action === "E" || row.Action === "e") {
      if (!field) { isValid = true; reason = "email should be blank for Edit action."; }
      else{
        if (String(field).length > 80) { isValid = false; reason = "email exceeds max length of 80."; }
        if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid email format."; }
      }
    } else if (row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = false; reason = "email should not be blank for Add action."; }
      if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid email format."; }
      if (String(field).length > 80) { isValid = false; reason = "email exceeds max length of 80."; }
    }
  }


  if (!isValid) {
    console.log(`Validation Failed: ${reason}`);
  } else {
    console.log(`Validation Passed: ${field}`);
  }
  
  return isValid;
});

if (hasNotError) {
  successfulRecordsArray.push(row);
}

console.log(`Final hasNotError status: ${hasNotError}`);

    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error

      isDuplicateUserId,
      isDuplicateInDB,
      userExists,

      Action: row.Action ? (
        String(row.Action).length === 1 ? (
          ["A", "a", "E", "e"].includes(row.Action) ? (
            row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Only "A", "E" are allowed)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Max. allowed character is 1)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
        

        UserID: row.UserID ? (
          String(row.UserID).length > 12 ? (
            <span style={{ color: "red" }}>
              {row.UserID} (Max. allowed character is 12)
            </span>
          ) : row.Action === "A" || row.Action === "a" ? (
            // When Action is "A", show duplicate message if user ID is a duplicate
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span style={{ color: "red" }}>
                {row.UserID} (User ID Duplicate exists)
              </span>
            ) : (
              String(row.UserID) // Otherwise, show userId normally
            )
          ) : row.Action === "E" || row.Action === "e" ? (
            // When Action is "E", show userId without the duplicate message
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span>
                {row.UserID} 
              </span> 
            ) : (
              // String(row.userId) // Otherwise, show userId normally
              <span style={{ color: "red" }}>
              {row.UserID} (User ID not exists)
            </span>
            )
          ) : (
            String(row.UserID) // Default case, show userId normally
          )
        ) : (
          <span style={{ color: "red" }}>
            Shouldn't be blank
          </span>
        ),

        
        Username: row.Username ? (
          String(row.Username).length > 12 ? (
            <span style={{ color: "red" }}>
              {row.Username} (Max. allowed character is 12)
            </span>
          ) : (
            String(row.Username) // Ensure Username is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

  
        FirstName: row.FirstName ? (
          String(row.FirstName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.FirstName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.FirstName) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        MiddleName: row.MiddleName ? (
          String(row.MiddleName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.MiddleName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.MiddleName) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        AccountingSystemId: row.AccountingSystemId ? (
          String(row.AccountingSystemId).length > 12 ? (
            <span style={{ color: "red"}}>
              {row.AccountingSystemId} (Max. allowed character is 12)
            </span>
          ) : (
              String(row.AccountingSystemId) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        LastName: row.LastName ? (
          String(row.LastName).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.LastName} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.LastName) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        MobileNo: row.MobileNo ? (
          isNaN(row.MobileNo) ? (
            <span style={{ color: "red"}}>
              {row.MobileNo} (Mismatched Data Type)
            </span>
          ) : String(row.MobileNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.MobileNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.MobileNo // Valid MobileNo
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        WhatsAppNo: row.WhatsAppNo ? (
          isNaN(row.WhatsAppNo) ? (
            <span style={{ color: "red"}}>
              {row.WhatsAppNo} (Mismatched Data Type)
            </span>
          ) : String(row.WhatsAppNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.WhatsAppNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.WhatsAppNo // Valid WhatsAppNo
          )
        ) : (
          <span> </span>
        ),
  
        OfficeNo: row.OfficeNo ? (
          isNaN(row.OfficeNo) ? (
            <span style={{ color: "red"}}>
              {row.OfficeNo} (Mismatched Data Type)
            </span>
          ) : String(row.OfficeNo).length > 13 ? (
            <span style={{ color: "red"}}>
              {row.OfficeNo} (Max. allowed character is 13)
            </span>
          ) : (
            row.OfficeNo // Valid OfficeNo
          )
        ) : (
          <span> </span>
        ),
        Extension: row.Extension ? (
          isNaN(row.Extension) ? (
            <span style={{ color: "red"}}>
              {row.Extension} (Mismatched Data Type)
            </span>
          ) : String(row.Extension).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.Extension} (Max. allowed character is 10)
            </span>
          ) : (
            row.Extension // Valid Extension
          )
        ) : (
          <span> </span>
        ),
  
        PassportNo: row.PassportNo ? (
          String(row.PassportNo).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.PassportNo} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.PassportNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        DrivingLicenseNo: row.DrivingLicenseNo ? (
          String(row.DrivingLicenseNo).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.DrivingLicenseNo} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.DrivingLicenseNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        LocalId: row.LocalId ? (
          isNaN(row.LocalId) ? (
            <span style={{ color: "red"}}>
              {row.LocalId} (Mismatched Data Type)
            </span>
          ) : String(row.LocalId).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.LocalId} (Max. allowed character is 20)
            </span>
          ) : (
            row.LocalId // Valid LocalId
          )
        ) : (
          <span> </span>
        ),
  
        Nationality: row.Nationality ? (
          String(row.Nationality).length > 20 ? (
            <span style={{ color: "red"}}>
              {row.Nationality} (Max. allowed character is 20)
            </span>
          ) : (
              String(row.Nationality) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        EmployeeNo: row.EmployeeNo ? (
          String(row.EmployeeNo).length > 12 ? (
            <span style={{ color: "red"}}>
              {row.EmployeeNo} (Max. allowed character is 12)
            </span>
          ) : (
              String(row.EmployeeNo) // Ensure userId is treated as a string
          )
        ) : (
          <span>
         
          </span>
        ),
  
        CompanyCodeId: row.CompanyCodeId ? (
          String(row.CompanyCodeId).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.CompanyCodeId} (Max. allowed character is 10)
            </span>
          ) : CompanyExists ? (
            CompanyGreenExists ? (
            <span>{row.CompanyCodeId}</span>
          ): (
            <span style={{ color: "red" }}>
              {row.CompanyCodeId} (Company is not approved.)
            </span>
          )
        ) : (
           <span style={{ color: "red" }}>
              {row.CompanyCodeId} (Company is not exists.)
          </span>
        )
      ):(
        <span></span>
      ),


        Department: row.Department ? (
          String(row.Department).length > 6 ? (
            <span style={{ color: "red"}}>
              {row.Department} (Max. allowed character is 6)
            </span>
          ) : (
              String(row.Department) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
        ValidFrom: row.ValidFrom ? (
          String(row.ValidFrom).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.ValidFrom} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.ValidFrom) // Ensure userId is treated as a string
          )
        ) : (
          
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
          

        ),
  
        ValidTo: row.ValidTo ? (
          String(row.ValidTo).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.ValidTo} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.ValidTo) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
     
        ResignationDate: row.ResignationDate ? (
          String(row.ResignationDate).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.ResignationDate} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.ResignationDate) // Ensure userId is treated as a string
          )
        ) : (
          <span>
          
          </span>
        ),
  
        JoiningDate: row.JoiningDate ? (
          String(row.JoiningDate).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.JoiningDate} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.JoiningDate) // Ensure userId is treated as a string
          )
        ) : (
          <span>
          
          </span>
        ),
  
        Address1: row.Address1 ? (
          String(row.Address1).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.Address1} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.Address1) // Ensure userId is treated as a string
          )
        ) : (

          row.Action === "A" || row.Action === "a" ? (
            
          !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.AreaName && !row.Phase && !row.Sector && !row.Village && !row.Town && !row.CityName && !row.Address2 && !row.Address3
          // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
          ? (
            <span style={{ color: "red" }}>
                Either Address Section or Building, Street, and Area Section must be entered
            </span>
        ) : (
            <span></span>
        )
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any

          
        ),
  
        Address2: row.Address2 ? (
          String(row.Address2).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.Address2} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.Address2) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        Address3: row.Address3 ? (
          String(row.Address3).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.Address3} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.Address3) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        CountryId: row.CountryId ? (
          String(row.CountryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.CountryId} (Max. allowed character is 2)
            </span>
          ) : countryIDExists && displayOption !== "" ? (
            <span>{row.CountryId}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.CountryId} (Country Code not exists)
            </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
      
        StateProvince: row.StateProvince ? (
          String(row.StateProvince).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.StateProvince} (Max. allowed character is 3)
            </span>
          ) :  stateIDExists && displayOption !== "" ? (
            <span>{row.StateProvince}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.StateProvince} (StateProvince Code not exists)
            </span>
          )
        ) : (
          <span>
           
          </span>
        ),
  
        Language: row.Language ? (
          String(row.Language).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.Language} (Max. allowed character is 3)
            </span>
          ) : languageExists ?
              ( <span>{row.Language}</span> ) :
              (
                <span style={{ color: "red" }}>
                {row.Language} (Language Code not exists)
              </span>
              )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
        
        DateFormat: row.DateFormat ? (
          String(row.DateFormat).length > 10 ? (
            <span style={{ color: "red"}}>
              {row.DateFormat} (Max. allowed character is 10)
            </span>
          ) : (
              String(row.DateFormat) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        TimeFormat: row.TimeFormat ? (
          String(row.TimeFormat).length > 8 ? (
            <span style={{ color: "red"}}>
              {row.TimeFormat} (Max. allowed character is 8)
            </span>
          ) : (
              String(row.TimeFormat) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
        AmountFormat: row.AmountFormat ? (
          String(row.AmountFormat).length > 19 ? (
            <span style={{ color: "red"}}>
              {row.AmountFormat} (Max. allowed character is 19)
            </span>
          ) : (
              String(row.AmountFormat) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
  
          AcceleratorForThousands: row.AcceleratorForThousands ? ( 
            String(row.AcceleratorForThousands).length > 1 ? (
                <span style={{ color: "red"}}>
                    {row.AcceleratorForThousands} (Max. allowed character is 1)
                </span>
            ) : !["K", "T"].includes(String(row.AcceleratorForThousands)) ? (
                <span style={{ color: "red"}}>
                    {row.AcceleratorForThousands} (Only 'K' or 'T' allowed)
                </span>
            ) : (
                <span>{row.AcceleratorForThousands}</span>
            )
        ) : (
            <span></span>
        ),

  
            // AcceleratorForLakhs: row.AcceleratorForLakhs ? (
            //   String(row.AcceleratorForLakhs).length > 1 ? (
            //       <span style={{ color: "red"}}>
            //       {row.AcceleratorForLakhs} (Max. allowed character is 1)
            //       </span>
            //   ) : !["L"].includes(String(row.AcceleratorForLakhs)) ? (
            //       <span style={{ color: "red"}}>
            //       {row.AcceleratorForLakhs} (Only 'L' allowed)
            //       </span>
            //   ): (
            //     <span> {row.AcceleratorForLakhs} </span>
            //   )
            //   ) : (
            //   <span>
                
            //   </span>
            //   ),
            AcceleratorForLakhs: row.AcceleratorForLakhs ? (
              String(row.AcceleratorForLakhs).trim() === "L" ? (
                  <span>{row.AcceleratorForLakhs}</span>
              ) : (
                  <span style={{ color: "red" }}>
                      {row.AcceleratorForLakhs} (Only 'L' allowed)
                  </span>
              )
          ) : (
              <span></span>
          ),

              // AcceleratorForMillions: row.AcceleratorForMillions ? (
              //   String(row.AcceleratorForMillions).length > 1 ? (
              //       <span style={{ color: "red"}}>
              //       {row.AcceleratorForMillions} (Max. allowed character is 1)
              //       </span>
              //   ) : !["M"].includes(String(row.AcceleratorForMillions)) ? (
              //       <span style={{ color: "red"}}>
              //       {row.AcceleratorForMillions} (Only 'M' allowed)
              //       </span>
              //   ) : (
              //     <span> {row.AcceleratorForMillions}</span>
              //   )
              //   ) 
              //   :
              //    (
              //   <span>
                  
              //   </span>
              //   ),
              AcceleratorForMillions: row.AcceleratorForMillions ? (
                String(row.AcceleratorForMillions).trim() === "M" ? (
                    <span>{row.AcceleratorForMillions}</span>
                ) : (
                    <span style={{ color: "red" }}>
                        {row.AcceleratorForMillions} (Only 'M' allowed)
                    </span>
                )
            ) : (
                <span></span>
            ),


        Email: row.Email ? (
          String(row.Email).length > 80 ? (
            <span style={{ color: "red"}}>
              {row.Email} (Max. allowed character is 80)
            </span>
          ) : !validateEmail(row.Email) ? (
            <span style={{ color: "red"}}>
              {row.Email} (Invalid Email format)
            </span>
          ) : (
            row.Email // If valid, display the email normally
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ), 
    
      };  
  }));
  setAccountNoUpdateSuccessfulRecords(successfulRecordsArray);
  return results;

}

  const storeArrayDataForUsers= async () => {
    setLoading(true);
    try {
      const dataToDisplay = await getDataToDisplay(uploadOption, tableData);
    console.log("store successfulRecords data 2 is", dataToDisplay);

    let processed=[];

    if(getExistsUserids && getExistsLanguageids && companies && companiesGreen && countryCodes && stateCodes){
      processed = await FullprocessData(tableData);
      console.log("display full datas",processed);

    }

      const extractText = (cellValue) => {
              if (Array.isArray(cellValue)) {
                return cellValue.join(", "); // Convert array to string
              } else if (typeof cellValue === "string") {
                return cellValue;
              } else if (React.isValidElement(cellValue)) {
                return cellValue.props.children;
              }
              return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
            };

            const formattedUsers = processed.map((user) => {

              return {
              // "successfulrecords": extractText(user.successfulrecords),
              "Action": extractText(user.Action),
              "SLNo": extractText(user.SLNo),
              "UserID": extractText(user.UserID),
              "EmployeeNo": extractText(user.EmployeeNo),
              "Salute": extractText(user.Salute),
              "AccountingSystemId": extractText(user.AccountingSystemId),
              "Username": extractText(user.Username),
              "FirstName": extractText(user.FirstName),
              "MiddleName": extractText(user.MiddleName),
              "LastName": extractText(user.LastName),
              "Language": extractText(user.Language || ""),
              "TimeFormat": extractText(user.TimeFormat || ""),
              "TimeZone": extractText(user.TimeZone || ""),
              "CompanyCode": extractText(user.CompanyCodeId),
              "Department": extractText(user.Department),
              "ValidFrom": extractText(user.ValidFrom || ''),
              "ValidTo": extractText(user.ValidTo || ''),
              "Email": extractText(user.Email),
              "MobileNo": extractText(user.MobileNo),
              "WhatsAppNo": extractText(user.WhatsAppNo),
              "Nationality": extractText(user.Nationality),
              "PassportNo": extractText(user.PassportNo),
              "DrivingLicenseNo": extractText(user.DrivingLicenseNo),
              "LocalID": extractText(user.LocalID),
              "OfficeNo": extractText(user.OfficeNo),
              "Extension": extractText(user.Extension),
              "JoiningDate": extractText(user.JoiningDate),
              "ResignationDate": extractText(user.ResignationDate),
              "DoorNo": extractText(user.DoorNo),
              "FloorNo": extractText(user.FloorNo),
              "BuildingNo": extractText(user.BuildingNo),
              "BuildingName": extractText(user.BuildingName),
              "LandMark": extractText(user.LandMark),
              "StreetNo": extractText(user.StreetNo),
              "StreetName": extractText(user.StreetName),
              "RoadNo": extractText(user.RoadNo),
              "RoadName": extractText(user.RoadName),
              "Block": extractText(user.Block),
              "AreaName": extractText(user.AreaName),
              "Phase": extractText(user.Phase),
              "Sector": extractText(user.Sector),
              "Village": extractText(user.Village),
              "Town": extractText(user.Town),
              "CityName": extractText(user.CityName),
              "Address1": extractText(user.Address1 || ""),
              "Address2": extractText(user.Address2 || ""),
              "Address3": extractText(user.Address3 || ""),
              "CountryId": extractText(user.CountryId || ""),
              "StateProvince": extractText(user.StateProvince),
              "District": extractText(user.District),
              "PostalCode": extractText(user.PostalCode),
              "ZipCode": extractText(user.ZipCode),
              "POBox": extractText(user.POBox),
              "DateFormat": extractText(user.DateFormat || ""),
              "AmountFormat": extractText(user.AmountFormat || ""),
              // "ThousandSeparator": extractText(user.ThousandSeparator),
              // "DecimalSeparator": extractText(user.DecimalSeparator),
              "AcceleratorForThousands": extractText(user.AcceleratorForThousands),
              "AcceleratorForLakhs": extractText(user.AcceleratorForLakhs),
              "AcceleratorForMillions": extractText(user.AcceleratorForMillions),
            };
    });
      
        // Insert second and third row at the beginning of the formatted data
        const rowsToDisplay = [ ...formattedUsers];

        console.log("Formatted USERS Data:", formattedUsers);
        console.log("Formatted Data:", rowsToDisplay);

        
      //   const updatedData = rowsToDisplay.map(row => ({
      //     Update: row.successfulrecords === 'true' ? 'Yes' : 'No',
      //     ...row,
      // }));
      

        const updatedData = rowsToDisplay.map(user => {

          const isObjectField = ["Action",
"SLNo",
"UserID",
"EmployeeNo",
"Salute",
"AccountingSystemId",
"Username",
"FirstName",
"MiddleName",
"LastName",
"Language",
"TimeFormat",
"TimeZone",
"CompanyCode",
"Department",
"ValidFrom",
"ValidTo",
"Email",
"MobileNo",
"WhatsAppNo",
"Nationality",
"PassportNo",
"DrivingLicenseNo",
"LocalID",
"OfficeNo",
"Extension",
"JoiningDate",
"ResignationDate",
"DoorNo",
"FloorNo",
"BuildingNo",
"BuildingName",
"LandMark",
"StreetNo",
"StreetName",
"RoadNo",
"RoadName",
"Block",
"AreaName",
"Phase",
"Sector",
"Village",
"Town",
"CityName",
"Address1",
"Address2",
"Address3",
"CountryId",
"StateProvince",
"District",
"PostalCode",
"ZipCode",
"POBox",
"DateFormat",
"AmountFormat",
// "ThousandSeparator",
// "DecimalSeparator",
"AcceleratorForThousands",
"AcceleratorForLakhs",
"AcceleratorForMillions"
].some(field => typeof user[field] === 'object' || user[field] === "Shouldn't be blank" ||  user[field] === "Either Address Section or Building, Street, and Area Section must be entered");

          
          return {
            // Update: row.successfulrecords === 'true' ? 'Yes' : 'No',
            update: isObjectField ? "No" : "Yes",
                  ...user,
                };
            });
console.log("updatedData data",updatedData);


      const data = {
        clientId: assignee.clientId,
        dataToDisplay,
        createdBy: assignee.userId,
        allrecords: updatedData,
      }
      console.log("storeArrayDataForUsers dataToDisplay is",data);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/store-array-users-data`,
        data
      );
      console.log("Roles Response data", response);


      if (dataToDisplay.length === 0) { 
        // toast("error", "Please upload the correct file OR containing the data.");
        toast("error", "Error fields exist.");
        return;
    }
    else{
      if (response.status === 200) {
        toast("success", "Uploaded Users successfully");
      }
      else if(response.status === 400){
        toast("error", "Invalid or empty array of users");
      }
      else if(response.status === 404){
        toast("error", "Missing required fields");
      }
      else if(response.status === 500){
        toast("error", "Duplicate user IDs found.");
        console.log("response duplicate user id data",response.duplicates)
      }
    }


      
    } catch (error) {
      console.error("Error fetching array users data", error);
    }
    finally {
          setLoading(false);
        }
  };


  const handleSubmit = async () => {

    if(buttonText === 'Upload from desktop'){
        toast("error", "Please upload a file.");
        return;
    }
    else if (tableData.length === 0) { 
        // toast("error", "Please upload the correct file OR containing the data.");
        toast("error", "Please upload a file containing at least one record.");
        return;
    }

         // Check if either of the checkboxes is selected
    if (!isDisplayChecked && !isUploadChecked) {
        toast("error", "Please check at least one checkbox (Display or Upload).");
        return;
    }


    // console.log("tableData send to the prop",tableData);
    // console.log("displayOption selected", displayOption);
    // console.log("uploadOption selected", uploadOption);

    if(uploadOption !== '' && displayOption === '' ){
      console.log("User arry upload data");
      storeArrayDataForUsers();
    }
    else {
      if(uploadOption != ''){
        storeArrayDataForUsers();
      }
      console.log("exit and navigate to the upload table screen");
      console.log("exit and navigate tableData",tableData);
      console.log("exit and navigate to displayOption",displayOption);
      console.log("exit and navigate to uploadOption",uploadOption);
      navigate(`/14/admin/108/user-upload/display-screen`, {
            state: { tableData, displayOption, uploadOption } // Passing tableData via state
        });
    }
  }

  return (
    <div>
      <div>
        <Header title={`Admin > User & SOD > User > Upload `}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/14/admin/108/userscreen"}
        />

         {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

        {/* <div className="p-8 pr-24">
          <div className="flex flex-col space-y-8 p-10 border rounded-md">    
            <div className="flex items-center">
              <label className="flex items-center">
                <span className="w-20 font-medium text-gray-600">File</span>
                <span className="ml-4">:</span>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept=".xlsx, .xls"
                />
              </label>
              
              <button
                type="button"
                onClick={() => handleClick()}
                className={`border border-gray-300 p-2 w-80 h-10 focus:outline-none hover:border-blue-400 ml-6 rounded-full text-center
                  ${isUploaded ? "text-green-500 animate-pulse" : isError ? "text-red-500" : "text-gray-700"}
                  ${buttonText === 'File Uploaded' ? "text-green-500" : ''}`}
              >
                {buttonText}
              </button>
              
              <a
                href="/User_Upload.xlsx"
                download
                style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none', marginLeft: '16px' }}
              >
                <span className=""  style={{
                      color: '#42A0EE', 
                      textDecoration: 'none', 
                      background: 'none', 
                      border: 'none', 
                      cursor: 'pointer'
                    }} 
                    onMouseEnter={(e) => e.target.style.textDecoration = 'underline'} // Underline on hover
                    onMouseLeave={(e) => e.target.style.textDecoration = 'none'} // Remove underline when hover ends
       
       >Download Excel format</span>
                <IoMdCloudDownload style={{ color: '#42A0EE', marginLeft: '8px' }} />
              </a>
            </div>

            <div className="flex flex-col space-y-2 py-3">
              <div className="flex items-center">
                <Checkbox
                    checked={isDisplayChecked}
                    onChange={handleCheckboxChange} // Handle checkbox state change
                    className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
                />
                <span className="font-medium text-gray-700">ERROR CHECK/DISPLAY</span>
              </div>
              <div className="flex space-x-6 ml-4">
             
                <label className="flex items-center">
                  <input
                type="radio"
                name="displayOption"
                value="2" // Value to track
                className="mr-2 accent-customBlue"
                checked={displayOption === "2"} // Checked if selected
                onChange={handleRadioChange} // Update state on change
                disabled={!isDisplayChecked} // Disable if checkbox is not checked
              />
                  <span>All</span>
                </label>

                <label className="flex items-center">
                  <input type="radio" value="1" name="displayOption" className="mr-2 accent-customBlue" 
                  checked={displayOption === "1"} // Checked if selected
                  onChange={handleRadioChange} // Update state on change
                  disabled={!isDisplayChecked} // Disable if checkbox is not checked
                  />
                  <span>Errors Only</span>
                </label>
                
              </div>
            </div>

            <div className="flex flex-col space-y-2 py-3">
              <div className="flex items-center">
                <Checkbox
                              onChange={handleCheckboxChange2}
                              checked={isUploadChecked}
                              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
                            />
                <span className="font-medium text-gray-700">UPDATE (ONLY SUCCESSFUL RECORDS WILL PROCESS)
                </span>
              </div>
          
            </div>

          </div> 
        </div> */}

      <UploadComponent
        downloadPath="/User_Upload.xlsx"  // Replace with a dynamic file path if needed
        handleFileChange={handleFileChange}  // Function to handle file selection
        buttonText={buttonText}
        isUploaded={isUploaded}
        isError={isError}
        handleSubmit={handleSubmit}
        isDisplayChecked={isDisplayChecked}
        setIsDisplayChecked={setIsDisplayChecked}
        isUploadChecked={isUploadChecked}
        setIsUploadChecked={setIsUploadChecked}
        displayOption={displayOption}
        setDisplayOption={setDisplayOption}
        uploadOption={uploadOption}
        setUploadOption={setUploadOption}
        icons={iconId}
      />

        <ConfirmationDialog
          open={showConfirmation}
          title="Upload File"
          message="Do you need to download the latest upload format?"
          onConfirm={confirmDeletion}
          onCancel={cancelDeletion}
        />
        <Footer>
          <button
            onClick={handleSubmit}
            className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
          >
            Run
          </button>
        </Footer>

      </div>
    </div>
  )
}
