import React, { useState } from 'react';
import HoverButton from '../../../../components/HoverButton';
import CustomButton from '../../../../components/CustomButton';
import { TbFlag3Filled } from 'react-icons/tb';

const ColumnSettingsPopup = ({ columns, onClose, onUpdate }) => {
  const [columnSettings, setColumnSettings] = useState(columns);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (accessor) => {
    setColumnSettings((prev) =>
      prev.map((col) =>
        col.accessor === accessor ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleColumnWidthChange = (accessor, newWidth) => {
    const widthValue = parseFloat(newWidth) || 0; // Ensure it's a number, including decimals
    setColumnSettings((prev) =>
      prev.map((col) =>
        col.accessor === accessor ? { ...col, width: widthValue } : col
      )
    );
  };

  const handleSave = () => {
    onUpdate(columnSettings); // Update parent state with new settings
    onClose();
  };

  const handleReset = () => {
    const defaultSettings = columns.map((col) => ({
      ...col,
      visible: true,
      width: col.width, // Use the original width from the columns array
    }));
    setColumnSettings(defaultSettings);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setColumnSettings((prev) =>
      prev.map((col) => ({ ...col, visible: !selectAll }))
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-0 w-[400px] h-[500px] flex flex-col rounded-2xl overflow-hidden" style={{ maxHeight: "500px", overflowY: "auto" }}>
        <div className="flex justify-between items-center ">
          <h2 className="text-sm font-md text-customBlue ml-4 p-2 mt-4">Column Settings</h2>
          <HoverButton
            onClick={handleReset}
            className={"mr-4 mt-2 h-7 p-2"}
            title={"Reset"}
            // className="bg-white border border-customBlue text-customBlue px-2  rounded hover:bg-customBlue hover:text-white transition h-6 whitespace-nowrap text-xs mr-6"
          />
        
        </div>

        {/* Header Row */}
        <div className="flex-1 overflow-auto p-4">
  {columnSettings.map((column, index) => (
    <div key={column.accessor} className={`flex items-center mb-1 ${index % 2 === 0 ? 'bg-sky-50' : ''}`}>
      <input
        type="checkbox"
        checked={column.visible}
        onChange={() => handleCheckboxChange(column.accessor)}
        className="form-checkbox h-3 w-4 text-blue-600 p-2 ml-2 accent-customBlue "
      />
      <label className="text-xs flex-grow text-center whitespace-nowrap flex items-center justify-center text-customGray">
        {column.accessor === "status" ? (
          <TbFlag3Filled style={{ color: "#29CB00", marginRight: "5px" }} />
        ) : null}
        {column.Header}
      </label>
      <input
        type="text"
        value={column.width}
        onChange={(e) => handleColumnWidthChange(column.accessor, e.target.value)}
        className="ml-2 border border-gray-300 rounded w-20 h-8 px-2 text-xs text-center text-customGray"
        placeholder="Width"
      />
    </div>
  ))}
</div>

        {/* Column Settings */}
       
        <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
          <hr className="my-4 border-gray-300" />
          <div className="flex justify-end mt-4">
            <p className='mr-48 p-2  whitespace-nowrap text-xs text-gray-500 font-md'></p>
            <HoverButton
              onClick={onClose}
              title='Close'
              className={"mr-4"}
            />
           
            <CustomButton
              title="Save"
              onClick={handleSave}
            />
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnSettingsPopup;