import React, { useEffect, useState } from 'react';
import UploadComponent from '../../../../../../components/UploadComponent';
import * as XLSX from 'xlsx';
import Header from '../../../../../../components/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import { useToast } from '../../../../../../components/toast/toast';
import UserService from '../../../../../../rest-services/UserServices';
import { set, update } from 'lodash';
import SourceServices from '../../../../../../rest-services/SourceServices';
import LabelWithButton from '../../../../../../components/InputButtonField';
import ReusableModal from '../../../../../../components/PopupComponent';
import TableWrapper from '../../../../../../components/ScrollBarComponent';
import Footer from '../../../../../../components/footer';

export default function PortalUploadScreen() {
  const navigate = useNavigate();
  const fileInputRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [isError, setIsError] = useState(false);
  const [buttonText, setButtonText] = useState('Upload from desktop');
  const [isUploaded, setIsUploaded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [assignee, setAssignee] = useState({ id: '', username: '' });
  const [alert, setAlert] = useState(null);
    const location = useLocation();
  
  const { mode } = location.state || {}; // Get the mode from the state

  const isViewMode = mode === "view";

  const [isDisplayChecked, setIsDisplayChecked] = useState(false);
  const [isUploadChecked, setIsUploadChecked] = useState(false);
  const [displayOption, setDisplayOption] = useState('');
  const [uploadOption, setUploadOption] = useState('');
  const[statementTypes,setStatementTypes]= useState([]);
  const [isSourceCodeDescriptionModalOpen, setIsSourceCodeDescriptionModalOpen] = useState(false);
  const [isSourceCodeModalOpen, setIsSourceCodeModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  
  const [source, setSource] = useState("");
  const [TransactionDate, setTransactionDate] = useState("");
  const [ourCode, setOurCode] = useState("");
  const [rateType, setRateType] = useState("");
  const [quote, setQuote] = useState("");
  const [ratio, setRatio] = useState("");
  const [tenor, setTenor] = useState("");
  const [sourceCode, setSourceCode] = useState("");
  const [bid, setBid] = useState("");
  const [ask, setAsk] = useState("");
  const [errors, setErrors] = useState({});
 
  const [status, setStatus] = useState("");
  const [selectedSourceType, setSelectedSourceType] = useState(false);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [mdSource, setMdSource] = useState([]);
  const [Portal, setPortal] = useState([]);
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [isOurCodeModalOpen, setIsOurCodeModalOpen] = useState(false);
  const [isSourceCompanyModalOpen, setIsSourceCompanyModalOpen] = useState(false);
  const [isOurCodeDescriptionModalOpen, setIsOurCodeDescriptionModalOpen] = useState(false);
  const [selectedOurCode, setSelectedOurCode] = useState(false);
  const [selectedSourceCompanyName, setSelectedSourceCompanyName] = useState(false);
  const [selectedOurCodeDescription, setSelectedOurCodeDescription] = useState(false);
  const [ourCodeDescription, setourCodeDescription] = useState("");
  const [sourceCompanyName, setsourceCompanyName] = useState("");
  const [sourceCodeDescription, setsourceCodeDescription] = useState("");
  const [selectedBankAccountId, setSelectedBankAccountId] = useState('');
  const [SelectedBankAccountIdDescription, setSelectedBankAccountIdDescription] = useState('');
  const [BankId, setBankId] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [Bank, setBank] = useState("");
  const [IBANNO, setIBANNO] = useState("");
  const [CompanyCode, setCompanyCode] = useState("");
  const [CompanyCodeName, setCompanyCodeName] = useState("");
  const [OpeningBalance, setOpeningBalance] = useState("");
  const [ClosingBalance, setClosingBalance] = useState("");
  const [StatementNO, setStatementNO] = useState("");

  const [openDropdown, setOpenDropdown] = useState(null);
  const [mdType, setMdType] = useState("");
  const [dataType, setDataType] = useState("");
  // Define the allowed columns for currency rate upload
 // Define the allowed columns for currency rate upload
const allowedFormat = [
    'Transaction Date', 
    'Bank Statement Reference', 
    'Bank Description', 
    'Customer Reference', 
    'Debit', 
    'Credit', 
    'Flow Code', 
    
  ];
  
  useEffect(() => {
    fetchAssignee();
  }, []);
  
    useEffect(() => {
      fetchportal();
    }, []);
  
  // Fetch the logged-in user details
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem('userDetail');
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || '',
          clientId: userDetail.clientId || '',
          assigner: userDetail.username || '',
          userId: userDetail.userId || '',
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: 'error',
          message: 'No user detail found in session storage.',
        });
      }
    } catch (error) {
      setAlert({
        severity: 'error',
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
  const fetchportal = async () => {
    try {
      const response = await SourceServices.getAllSource(
        `${process.env.REACT_APP_BASE_URL}/bam/get-all-accounts-match-portal`
      );
      setPortal(response.data.data);
    } catch (error) {
      console.error("Error fetching MdSource:", error);
    }
  };
  const handleBankAccountIdCheckBoxChange = (event) => {
    const selectedValue = event.target.value;
  
    const selectedSource = Portal.find(
      (Source) => Source.bankAccountId === selectedValue || Source.bankAccountDescription === selectedValue
    );
  
    if (selectedSource) {
      if (selectedValue === selectedSource.bankAccountId) {
        // Use setState functions instead of direct calls
        setSelectedBankAccountId(selectedSource.bankAccountId);
        setSelectedBankAccountIdDescription(selectedSource.bankAccountDescription);
        setBank(selectedSource.BankBranch?.Bank);
        setBankId(selectedSource.BankBranch?.BankId);
        setAccountNumber(selectedSource.accountNo);
        setIBANNO(selectedSource.iBanNo || '');
        setCompanyCode(selectedSource.companyID);
        setCompanyCodeName(selectedSource.companyName);
        
      } else if (selectedValue === selectedSource.bankAccountDescription) {
        setSelectedBankAccountId(selectedSource.bankAccountId);
        setSelectedBankAccountIdDescription(selectedSource.bankAccountDescription);
        setBank(selectedSource.BankBranch?.Bank);
        setBankId(selectedSource.BankBranch?.BankId);
        setAccountNumber(selectedSource.accountNo);
        setIBANNO(selectedSource.iBanNo || '');
        setCompanyCode(selectedSource.companyID);
        setCompanyCodeName(selectedSource.companyName);
      }
      setUnsavedChanges(true);
    } else {
      console.error("Selected currency not found in the list.");
    }
};
  // Convert Excel date to JavaScript date
  // const excelDateToJSDate = (date) => {
  //   if (!isNaN(date)) {
  //     const excelEpoch = new Date(Date.UTC(1899, 11, 30));
  //     const jsDate = new Date(excelEpoch.getTime() + date * 86400000);
  
  //     // Format to DD-MM-YYYY
  //     const day = String(jsDate.getUTCDate()).padStart(2, '0');
  //     const month = String(jsDate.getUTCMonth() + 1).padStart(2, '0');
  //     const year = jsDate.getUTCFullYear();
  
  //     return `${day}-${month}-${year}`;
  //   }
  //   return date;
  // };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Improved Excel date to JS date conversion
const excelDateToJSDate = (excelDate) => {
  // If it's already a string (formatted date), return as-is
  if (typeof excelDate === 'string') {
    return excelDate;
  }

  // If it's a number (Excel serial date), convert it
  if (typeof excelDate === 'number') {
    // Excel's epoch is January 1, 1900 (with some quirks)
    const excelEpoch = new Date(1900, 0, 1);
    // Adjust for Excel's incorrect assumption that 1900 was a leap year
    const daysOffset = excelDate > 60 ? excelDate - 2 : excelDate - 1;
    const jsDate = new Date(excelEpoch.getTime() + daysOffset * 86400000);

    // Format to DD-MM-YYYY
    const day = String(jsDate.getDate()).padStart(2, '0');
    const month = String(jsDate.getMonth() + 1).padStart(2, '0');
    const year = jsDate.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // For other cases (null/undefined), return empty string or handle as needed
  return excelDate !== null && excelDate !== undefined ? excelDate.toString() : '';
};
  // Handle file upload and validation
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
  
      // Convert sheet to JSON with headers
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      if (jsonData.length === 0) {
        setButtonText('File Format is Incorrect!');
        setIsUploaded(false);
        setIsError(true);
        toast('error', 'The uploaded file is empty or invalid.');
        return;
      }
  
      // Define the new headers
      const newHeaders = [
        'Transaction Date', 
    'Bank Statement Reference', 
    'Bank Description', 
    'Customer Reference', 
    'Debit', 
    'Credit', 
    'Flow Code', 
        
      ];
  
      // Check if the file format matches the allowed format
      const headers = jsonData[0].map((header) => header.trim().toLowerCase());
      const isSourceFormat = newHeaders
        .map((header) => header.trim().toLowerCase())
        .every((header) => headers.includes(header));
  
      if (!isSourceFormat) {
        setButtonText('File Format is Incorrect!');
        setIsUploaded(false);
        setIsError(true);
        toast('error', 'The uploaded file does not match the required format.');
        return;
      }
  
      // Skip the first two rows (header and any additional row)
      const rows = jsonData.slice(1);
  
      // Process and format rows
      // Process and format rows
const formattedRows = rows.map((row) => {
  const formattedRow = {};
  newHeaders.forEach((header, index) => {
    // Special handling for date column
    if (header === 'Transaction Date') {
      formattedRow[header] = excelDateToJSDate(row[index]);
    } else {
      formattedRow[header] = row[index] || null;
    }
  });
  return formattedRow;
});
  
      console.log("Formatted Rows:", formattedRows);
      setTableData(formattedRows);
      setButtonText("File Uploaded");
      setIsUploaded(true);
      setIsError(false);
  
      setTimeout(() => setIsUploaded(false), 1000);
    };
  
    reader.readAsArrayBuffer(file);
  };
  
// I’ve added the date format handling back in! Let me know if this works for you. 🚀

// Let me know if you’d like me to tweak anything! 🚀

useEffect(() => {
  fetchStatementTypes();
}, []);
const fetchStatementTypes = async () => {
  setLoading(true);
  try {
    const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
    const responseData = response.data.data;
console.log("Response Data:", responseData);
    if (responseData.error) {
      setAlert({ severity: "error", message: responseData.message });
      return;
    }

    setStatementTypes(responseData);
    console.log("Statement Types:", statementTypes);
  } catch (error) {
    setAlert({
      severity: "error",
      message: `Error fetching legal entities: ${error.message}`,
    });
  } finally {
    setLoading(false);
  }
};
const isRowValid = (row, index) => {
  console.log("Row Object in isRowValid:", row);

  // Validate all fields based on requirements
  const isTransactionDateValid = row['Transaction Date'] && 
                               /^\d{2}-\d{2}-\d{4}$/.test(row['Transaction Date']) && 
                               row['Transaction Date'].length <= 10;
  
  const isBankReferenceValid = row['Bank Statement Reference'] && 
                             typeof row['Bank Statement Reference'] === 'string' && 
                             row['Bank Statement Reference'].length <= 30;
  
  const isBankDescriptionValid = row['Bank Description'] && 
                               typeof row['Bank Description'] === 'string' && 
                               row['Bank Description'].length <= 300;
  
  const isCustomerReferenceValid = !row['Customer Reference'] ||  // Optional
                                 (typeof row['Customer Reference'] === 'string' && 
                                  row['Customer Reference'].length <= 30);
  
  const isDebitValid = !row['Debit'] ||  // Optional
                      (!isNaN(parseFloat(row['Debit'])) && 
                       String(row['Debit']).length <= 20);
  
  const isCreditValid = !row['Credit'] ||  // Optional
                       (!isNaN(parseFloat(row['Credit'])) && 
                        String(row['Credit']).length <= 20);
  
  const isFlowCodeValid = !row['Flow Code'] ||  // Optional
                         (typeof row['Flow Code'] === 'string' && 
                          row['Flow Code'].length <= 10);

  // Collect errors with detailed messages
  let errors = [];
  if (!isTransactionDateValid) errors.push("Invalid Transaction Date (DD-MM-YYYY format, max 10 chars)");
  if (!isBankReferenceValid) errors.push("Invalid Bank Reference (required, max 30 chars)");
  if (!isBankDescriptionValid) errors.push("Invalid Bank Description (required, max 300 chars)");
  if (!isCustomerReferenceValid) errors.push("Invalid Customer Reference (optional, max 30 chars)");
  if (!isDebitValid) errors.push("Invalid Debit amount (optional, numeric, max 20 chars)");
  if (!isCreditValid) errors.push("Invalid Credit amount (optional, numeric, max 20 chars)");
  if (!isFlowCodeValid) errors.push("Invalid Flow Code (optional, max 10 chars)");

  if (errors.length > 0) {
    console.log(`Row ${index + 1} is invalid:`, errors);
  }

  return (
    isTransactionDateValid &&
    isBankReferenceValid &&
    isBankDescriptionValid &&
    isCustomerReferenceValid &&
    isDebitValid &&
    isCreditValid &&
    isFlowCodeValid
  );
};

const getExistingUserIds = async (tableData) => {
  try {
    // First fetch valid statement types ONCE
    let validStatementTypes = [];
    try {
      const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
      validStatementTypes = response.data.data || [];
      console.log("Valid Statement Types:", validStatementTypes);
    } catch (error) {
      console.error("Error fetching statement types:", error);
    }

    // Async validation of all rows including dataset duplicates check
    const validationResults = await Promise.all(
      tableData.map(async (row, rowIndex) => {
        // Check for duplicates within current dataset first
        const isDuplicateInDataset = tableData.some(
          (r, index) => index !== rowIndex && r.BTCCode === row.BTCCode
        );
        
        if (isDuplicateInDataset) {
          console.log(`Duplicate BTCCode in dataset: ${row.BTCCode}`);
          return { row, isValid: false, isDuplicate: true };
        }

        // Validate other fields if no dataset duplicate
        const isValid = await isRowValid(row, rowIndex, validStatementTypes);
        return { row, isValid, isDuplicate: false };
      })
    );

    // Filter valid rows (not duplicates and passed validation)
    let validRows = validationResults
      .filter(result => result.isValid && !result.isDuplicate)
      .map(result => result.row);
    
    console.log("Initial valid rows after dataset duplicate check:", validRows);

    // Then check for duplicates in DB
    const validatedRows = await Promise.all(
      validRows.map(async (row) => {
        const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/btc/getduplicatebtc`,
          { btcCode: row.BTCCode }
        );

        const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
        if (isDuplicateInDB) {
          console.log(`Duplicate found in DB for BTCCode: ${row.BTCCode}`);
          return null;
        }
        return row;
      })
    );

    // Final valid rows
    validRows = validatedRows.filter(row => row !== null);
    console.log("Final valid rows after all checks:", validRows);
    return validRows;

  } catch (error) {
    console.error("Error in getExistingUserIds:", error);
    return [];
  }
};
  const getDataToDisplay = async () => {
    let dataToDisplay;
    if (uploadOption === "3") {
      console.log("displayOption is 3");
      dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"

      // dataToDisplay = await processData(tableData); // Display processed data if uploadOption is "3"
    } else {
      console.log("displayOption not selected");
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
    
    console.log("dataToDisplay data is", dataToDisplay);
    return dataToDisplay;
  };

  const processData = async (data) => {
    if (!Array.isArray(data)) {
      console.error("processData received invalid data:", data);
      return []; // Return an empty array to prevent further errors
    }
    console.log("processData received data:", data);
  
    return data.map((row, rowIndex) => {
      // Validate all fields
      const hasNotError = [
        row['Transaction Date'],
        row['Bank Statement Reference'],
        row['Bank Description'],
        row['Customer Reference'],
        row['Debit'],
        row['Credit'],
        row['Flow Code']
      ].every((field, index) => {
        let isValid = true;
        let reason = "";
  
        switch (index) {
          case 0: // Transaction Date
            if (!field) {
              isValid = false;
              reason = "Transaction Date should not be blank.";
            } else if (!/^\d{2}-\d{2}-\d{4}$/.test(field)) {
              isValid = false;
              reason = "Transaction Date should be in DD-MM-YYYY format.";
            } else if (String(field).length > 10) {
              isValid = false;
              reason = "Transaction Date exceeds max length of 10.";
            }
            break;
  
          case 1: // Bank Statement Reference
            if (!field) {
              isValid = false;
              reason = "Bank Statement Reference should not be blank.";
            } else if (typeof field !== 'string') {
              isValid = false;
              reason = "Bank Statement Reference should be a string.";
            } else if (field.length > 30) {
              isValid = false;
              reason = "Bank Statement Reference exceeds max length of 30.";
            }
            break;
  
          case 2: // Bank Description
            if (!field) {
              isValid = false;
              reason = "Bank Description should not be blank.";
            } else if (typeof field !== 'string') {
              isValid = false;
              reason = "Bank Description should be a string.";
            } else if (field.length > 300) {
              isValid = false;
              reason = "Bank Description exceeds max length of 300.";
            }
            break;
  
          case 3: // Customer Reference (optional)
            if (field && (typeof field !== 'string' || field.length > 30)) {
              isValid = false;
              reason = "Customer Reference should be a string with max length of 30.";
            }
            break;
  
          case 4: // Debit (optional)
            if (field && (isNaN(parseFloat(field)) || String(field).length > 20)) {
              isValid = false;
              reason = "Debit should be a valid number with max length of 20.";
            }
            break;
  
          case 5: // Credit (optional)
            if (field && (isNaN(parseFloat(field)) || String(field).length > 20)) {
              isValid = false;
              reason = "Credit should be a valid number with max length of 20.";
            }
            break;
  
          case 6: // Flow Code (optional)
            if (field && (typeof field !== 'string' || field.length > 10)) {
              isValid = false;
              reason = "Flow Code should be a string with max length of 10.";
            }
            break;
  
          default:
            break;
        }
  
        if (!isValid) {
          console.log(`Row ${rowIndex + 1}: Validation Failed - ${reason}`);
        }
  
        return isValid;
      });
  
      console.log(`Row ${rowIndex + 1}: Final hasNotError status = ${hasNotError}`);
  
      return {
        ...row,
        successfulrecords: hasNotError,
  
        'Transaction Date': row['Transaction Date'] ? (
          /^\d{2}-\d{2}-\d{4}$/.test(row['Transaction Date']) && row['Transaction Date'].length <= 10 ? (
            row['Transaction Date']
          ) : (
            <span style={{ color: 'red' }}>{row['Transaction Date']} (Invalid format: DD-MM-YYYY)</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Required field</span>
        ),
        
        'Bank Statement Reference': row['Bank Statement Reference'] ? (
          typeof row['Bank Statement Reference'] === 'string' && row['Bank Statement Reference'].length <= 30 ? (
            row['Bank Statement Reference']
          ) : (
            <span style={{ color: 'red' }}>{row['Bank Statement Reference']} (Max 30 characters)</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Required field</span>
        ),
        
        'Bank Description': row['Bank Description'] ? (
          typeof row['Bank Description'] === 'string' && row['Bank Description'].length <= 300 ? (
            row['Bank Description']
          ) : (
            <span style={{ color: 'red' }}>{row['Bank Description']} (Max 300 characters)</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Required field</span>
        ),
        
        'Customer Reference': row['Customer Reference'] ? (
          typeof row['Customer Reference'] === 'string' && row['Customer Reference'].length <= 30 ? (
            row['Customer Reference']
          ) : (
            <span style={{ color: 'red' }}>{row['Customer Reference']} (Max 30 characters)</span>
          )
        ) : (
          <span></span> // Optional field
        ),
        
        'Debit': row['Debit'] ? (
          !isNaN(parseFloat(row['Debit'])) && String(row['Debit']).length <= 20 ? (
            row['Debit']
          ) : (
            <span style={{ color: 'red' }}>{row['Debit']} (Invalid amount)</span>
          )
        ) : (
          <span></span> // Optional field
        ),
        
        'Credit': row['Credit'] ? (
          !isNaN(parseFloat(row['Credit'])) && String(row['Credit']).length <= 20 ? (
            row['Credit']
          ) : (
            <span style={{ color: 'red' }}>{row['Credit']} (Invalid amount)</span>
          )
        ) : (
          <span></span> // Optional field
        ),
        
        'Flow Code': row['Flow Code'] ? (
          typeof row['Flow Code'] === 'string' && row['Flow Code'].length <= 10 ? (
            row['Flow Code']
          ) : (
            <span style={{ color: 'red' }}>{row['Flow Code']} (Max 10 characters)</span>
          )
        ) : (
          <span></span> // Optional field
        )
      };
    });
  };

  const storeArrayDataForUsers = async () => {
    setLoading(true);
    try {
        const processed = await processData(tableData);
        console.log("Processed data:", processed);
       
        const extractText = (cellValue) => {
            if (Array.isArray(cellValue)) {
                return cellValue.join(", ");
            } else if (typeof cellValue === "string") {
                return cellValue;
            } else if (React.isValidElement(cellValue)) {
                return cellValue.props.children;
            }
            return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
        };
        
        // Filter valid rows for submission
        const validRows = processed.filter(row => row.successfulrecords);
        
        const formattedData = validRows.map((row) => ({
            transactionDate: row['Transaction Date'],
            bankReference: row['Bank Statement Reference'],
            bankDescription: row['Bank Description'],
            customerReference: row['Customer Reference'] || null,
            debitAmount: row['Debit'] ? parseFloat(row['Debit']) : null,
            creditAmount: row['Credit'] ? parseFloat(row['Credit']) : null,
            flowCode: row['Flow Code'] || null,
            createdBy: assignee.userId
        }));
        
        // Prepare all rows with update flag
        const allRowsWithUpdateFlag = processed.map(row => {
            const hasError = !row.successfulrecords;
            return {
                update: hasError ? "No" : "Yes",
                'Transaction Date': extractText(row['Transaction Date']),
                'Bank Statement Reference': extractText(row['Bank Statement Reference']),
                'Bank Description': extractText(row['Bank Description']),
                'Customer Reference': extractText(row['Customer Reference']),
                'Debit': extractText(row['Debit']),
                'Credit': extractText(row['Credit']),
                'Flow Code': extractText(row['Flow Code']),
                'Errors': row.errors || ''
            };
        });
        
        // Prepare the final data object
        const data = {
            dataToDisplay: formattedData, // Only valid rows
            created_by: assignee.userId,
            allrecords: allRowsWithUpdateFlag, // All rows with update flag
        };

        console.log("Data to be submitted:", data);

        // Send the data to the server
        const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/bank-transactions/bulk-create`,
            data
        );

        if (formattedData.length === 0) {
            toast("error", "Error fields exist.");
            return;
        } else if (response.status === 200) {
            toast('success', 'Bank transactions uploaded successfully');
        } else if (response.status === 400) {
            toast('error', 'Invalid or empty array of transactions');
        } else if (response.status === 404) {
            toast('error', 'Missing required fields');
        } else if (response.status === 500) {
            toast('error', 'Error processing transactions');
        }
    } catch (error) {
        console.error('Error uploading bank transactions:', error);
        toast('error', 'Failed to upload transactions');
    } finally {
        setLoading(false);
    }
};
  
const handleTransactionDateChange = (e) => {
    const code = e.target.value;
    setTransactionDate(code);
    setUnsavedChanges(true);
  
    
  };

  const handleSubmit = async () => {
    if (buttonText === 'Upload from desktop') {
      toast('error', 'Please upload a file.');
      return;
    } else if (tableData.length === 0) {
      toast('error', 'Please upload the correct file containing the data.');
      return;
    }
  
    // Process the data for display including the update column
    const processedData = tableData.map(row => {
      const extractText = (cellValue) => {
        if (Array.isArray(cellValue)) {
          return cellValue.join(", ");
        } else if (typeof cellValue === "string") {
          return cellValue;
        } else if (React.isValidElement(cellValue)) {
          return cellValue.props.children;
        }
        return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
      };
  
      // Determine if the row has errors (similar to your original logic)
      const hasError = row.errors && row.errors.length > 0;
  
      return {
        update: hasError ? "No" : "Yes",  // Add the update column
        'Transaction Date': extractText(row['Transaction Date']),
        'Bank Statement Reference': extractText(row['Bank Statement Reference']),
        'Bank Description': extractText(row['Bank Description']),
        'Customer Reference': extractText(row['Customer Reference']),
        'Debit': extractText(row['Debit']),
        'Credit': extractText(row['Credit']),
        'Flow Code': extractText(row['Flow Code']),
        'Errors': row.errors || ''
      };
    });
  
    // Navigate to display screen with all data including update column
    navigate(`/5/bank-statement/53/portal-upload/preview-screen`, {
      state: { 
        tableData: processedData,
        // Include any other needed data here
      }
    });
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };


  const confirmDeletion = async () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Define the headers for the Source format
    const headers = ['RateType', 'TransactionDate', 'SourceType', 'SourceCode', 'Bid', 'Ask'];
  
    // Get the current date
    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  
    // Example: Fill 10 rows with current date
    const rowCount = 10; // You can change this or make it dynamic
    const worksheetData = [headers];
  
    for (let i = 0; i < rowCount; i++) {
      worksheetData.push(['FX', formattedDate, 'SOURCE_TYPE', 'INRKWD=CBKK', '', '']);
    }
  
    // Create a worksheet with headers and data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
    // Create a Blob and trigger the download
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'BTC_Format_Download.xlsx';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  };
  
  // Now, every time the user downloads, the date will automatically update in every row! 🚀
  
  // Let me know if you want me to make any tweaks or add dynamic row counts! ✨
  

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BTC") {
      navigate("/btc");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "Portal") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const handleOurCodeReset = () => {
    setSearchQuery(''); // Reset search query
    setSelectedBankAccountId("");
    setSelectedBankAccountIdDescription('');
    setBankId('');
    setBank('');
    setAccountNumber('');
    setIBANNO('');
    setCompanyCode('');
    setCompanyCodeName('');
   
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleRefreshOurCode = async () => {
    try {
      await fetchportal();  // Fetch Branchs
    } catch (error) {
      console.error('Error reloading Branchs:', error);
    } finally {
    }
  };
  return (
    <div>
      <Header
        title={`Bank Statements > Portal > Portal Upload`}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={'/bank-statements'}
      />
      {loading && <CustomeLoading />}
      
      <div className="m-6 border border-gray-200 p-4  rounded-lg -mb-8 gap-4 ">
               
               {/* <div className="mb-6 border border-gray-300 p-5 rounded-lg"> */}
               {/* <h3 className="text-sm font-medium text-customGray mb-8">
                   Rate
               </h3> */}

               <div className="grid grid-cols-2 gap-4 mb-8">
               <LabelWithButton
marginbottom ="0px"
label="Bank Account ID "
isRequired={true}
onClick={() => { setIsSourceCodeModalOpen(true); }
}
buttonText={selectedBankAccountId || 'Select Bank Account ID '}
/>

<ReusableModal
title="Select Bank Account ID "
isOpen={isSourceCodeModalOpen}
onClose={() => {
 setIsSourceCodeModalOpen(false);
 setSearchQuery('');
}}
onSave={() => {
 setIsSourceCodeModalOpen(false);
 setSearchQuery('');
}}
searchPlaceholder="Search Bank Account ID..."
searchQuery={searchQuery}
showReset={true}
onReset={handleOurCodeReset}
onSearchChange={handleSearchChange}
onRefresh={handleRefreshOurCode}
>
{Portal && Portal.length > 0 ? (
 Portal .filter(
     (md) =>
       (md.bankAccountId &&
         md.bankAccountId.trim() &&
         md.bankAccountId
           .toLowerCase()
           .includes(searchQuery.toLowerCase()))

   )
   .sort((a, b) => a.bankAccountId.localeCompare(b.bankAccountId)) // Sort in alphabetical order

   .map((md, index) => (
     <div
       key={md.id}
       className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
         }`}
     >
       <input
         type="radio"
         name="Bank Account ID "
         value={md.bankAccountId}
         checked={
          selectedBankAccountId === md.bankAccountId
         }
         onChange={handleBankAccountIdCheckBoxChange}
         className="mr-2 accent-customBlue"
       />
       <label>{md.bankAccountId}</label>
     </div>
   ))

) : (
 <p>Loading sourceCode...</p>
)}
</ReusableModal>

<LabelWithButton
marginbottom ="0px"
label="Bank Account ID Description"
isRequired={true}
onClick={() => { setIsSourceCodeDescriptionModalOpen(true); }
}
buttonText={SelectedBankAccountIdDescription || 'Select Bank Account ID Description '}
/>

<ReusableModal
title="Select Bank Account ID Description"
isOpen={isSourceCodeDescriptionModalOpen}
onClose={() => {
 setIsSourceCodeDescriptionModalOpen(false);
 setSearchQuery('');
}}
onSave={() => {
 setIsSourceCodeDescriptionModalOpen(false);
 setSearchQuery('');
}}
searchPlaceholder="Search Bank Account ID Description..."
searchQuery={searchQuery}
showReset={true}
onReset={handleOurCodeReset}
onSearchChange={handleSearchChange}
onRefresh={handleRefreshOurCode}
>
{Portal && Portal.length > 0 ? (
 Portal .filter(
     (md) =>
       (md.bankAccountDescription &&
         md.bankAccountDescription.trim() &&
         md.bankAccountDescription
           .toLowerCase()
           .includes(searchQuery.toLowerCase()))

   )
   .sort((a, b) => a.bankAccountDescription.localeCompare(b.bankAccountDescription)) // Sort in alphabetical order

   .map((md, index) => (
     <div
       key={md.id}
       className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
         }`}
     >
       <input
         type="radio"
         name="Bank Account ID Description"
         value={md.bankAccountDescription}
         checked={
          SelectedBankAccountIdDescription === md.bankAccountDescription
         }
         onChange={handleBankAccountIdCheckBoxChange}
         className="mr-2 accent-customBlue"
       />
       <label>{md.bankAccountDescription}</label>
     </div>
   ))

) : (
 <p>Loading bankAccountDescription...</p>
)}
</ReusableModal>
</div>
<div className="grid grid-cols-2 gap-4 mt-4 mb-8">

<div className="flex items-center">
                       <label className="w-48 font-small text-customGray text-xs">
                           Bank ID
                       </label>
                       <span className="ml-4">:</span>
                       <input
                           type="text"
                           value={BankId}
                           disabled={true}
                        
                           placeholder="B name"
                           className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                       />
                   </div>

<div className="flex items-center">
                       <label className="w-48 font-small text-customGray text-xs">
                         Account Number
                       </label>
                       <span className="ml-4">:</span>
                       <input
                           type="text"
                           value={AccountNumber}
                           disabled={true}
                        
                           placeholder="B name"
                           className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                       />
                   </div>
</div>
<div className="grid grid-cols-2 gap-4 mt-4 mb-8">



<div className="flex items-center">
                       <label className="w-48 font-small text-customGray text-xs">
                           Bank
                       </label>
                       <span className="ml-4">:</span>
                       <input
                           type="text"
                           value={Bank}
                           disabled={true}
                        
                           placeholder="B name"
                           className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                       />
                   </div>
                   <div className="flex items-center">
                       <label className="w-48 font-small text-customGray text-xs">
                           IBAN NO{" "}
                       </label>
                       <span className="ml-4">:</span>
                       <input
                           type="text"
                           value={IBANNO}
                           disabled={true}
                          
                           placeholder="B name"
                           className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                       />
                   </div>
</div>
<div className="grid grid-cols-2 gap-4 mt-4 mb-16">



<div className="flex items-center">
                       <label className="w-48 font-small text-customGray text-xs">
                           Company Code
                       </label>
                       <span className="ml-4">:</span>
                       <input
                           type="text"
                           value={CompanyCode}
                           disabled={true}
                        
                           placeholder="B name"
                           className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                       />
                   </div>
                   <div className="flex items-center">
                       <label className="w-48 font-small text-customGray text-xs">
                           Company Code Name{" "}
                       </label>
                       <span className="ml-4">:</span>
                       <input
                           type="text"
                           value={CompanyCodeName}
                           disabled={true}
                          
                           placeholder="B name"
                           className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                       />
                   </div>
                 
</div>
<div className="grid grid-cols-2 gap-4 mt-4 mb-8">



<div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Statement NO{" "}
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={StatementNO}
                            maxLength={2}
                            disabled={isViewMode}
                           onChange={(e) => {
                                  setTenor(e.target.value);
                                setUnsavedChanges(true);
                           }}
                            placeholder="2A"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
                    </div>
                   <div className="flex items-center">
    <label className="w-48 font-small text-customGray text-xs">
        Statement Date 
    </label>
    <span className="ml-4">:</span>
    <input
        type="date"
        placeholder="dd.mm.yyyy"
        value={TransactionDate}
        onChange={handleTransactionDateChange}
        className={`border ${errors.TransactionDate ? "border-red-500" : "border-gray-300"} border-gray-300 p-1.5 xl:text-xs lg:text-xs rounded w-80 h-8 
            ${isViewMode  ? 'text-gray-500' : 'text-customGray'} 
            focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
    />
</div>
                 
</div>
<div className="grid grid-cols-2 gap-4 mt-4 mb-8">



<div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Opening Balance{" "}
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={OpeningBalance}
                            maxLength={2}
                            disabled={isViewMode}
                           onChange={(e) => {
                                  setTenor(e.target.value);
                                setUnsavedChanges(true);
                           }}
                            placeholder="2A"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
                    </div>
                   <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Closing Balance{" "}
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={ClosingBalance}
                            maxLength={2}
                            disabled={isViewMode}
                           onChange={(e) => {
                                  setTenor(e.target.value);
                                setUnsavedChanges(true);
                           }}
                            placeholder="2A"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
                    </div>
                 
</div>
<div className="grid grid-cols-2 gap-4 mt-4">


<div className="flex items-center mt-4">
           <label className="w-48 font-small text-customGray text-xs">
             File
             </label>
             <span className="ml-4">:</span>
             <input
  type="file"
  ref={fileInputRef}
  style={{ display: "none" }}
  onChange={handleFileChange}
  accept=".xlsx, .xls"
/>


<button
  type="button"
  onClick={handleButtonClick}  // Changed from handleFileChange
  className={`border border-gray-300 p-2 w-80 h-10 text-sm focus:outline-none hover:border-blue-400 ml-4 rounded-full text-center
    ${isUploaded ? "text-green-500 animate-pulse" : isError ? "text-red-500" : "text-gray-700"}
    ${buttonText === "File Uploaded" ? "text-green-500" : ""}`}
>
  {buttonText}
</button>
          </div>

               
                 
</div>


               
               {/* </div> */}


           </div>
           {/* <UploadComponent
 downloadPath="/Portal_Format_Upload.xlsx"
        handleFileChange={handleFileChange}
        buttonText={buttonText}
        isUploaded={isUploaded}
        isError={isError}
        handleSubmit={handleSubmit}
        isDisplayChecked={isDisplayChecked}
        setIsDisplayChecked={setIsDisplayChecked}
        isUploadChecked={isUploadChecked}
        setIsUploadChecked={setIsUploadChecked}
        displayOption={displayOption}
        setDisplayOption={setDisplayOption}
        uploadOption={uploadOption}
        setUploadOption={setUploadOption}
       
        downloadText='Download Portal upload Format' // Dynamic text
      /> */}
       <Footer>
   <button
     onClick={handleSubmit}
     className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
   >
     Run
   </button>
 </Footer>
      <ConfirmationDialog
        open={showConfirmation}
        title="Upload File"
        message="Do you need to download the latest upload format?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      />
    </div>
  );
}







// import React, { useEffect, useState } from 'react';
// import * as XLSX from 'xlsx';
// import Header from '../../../../../../components/Header';
// import { useLocation, useNavigate } from 'react-router-dom';
// import CustomeLoading from '../../../../../../components/CustomeLoading';
// import ConfirmationDialog from '../../../../../../components/Deletionpopup';
// import { useToast } from '../../../../../../components/toast/toast';
// import UserService from '../../../../../../rest-services/UserServices';
// import { set, update } from 'lodash';
// import SourceServices from '../../../../../../rest-services/SourceServices';
// import LabelWithButton from '../../../../../../components/InputButtonField';
// import ReusableModal from '../../../../../../components/PopupComponent';
// import TableWrapper from '../../../../../../components/ScrollBarComponent';
// import Footer from '../../../../../../components/footer';

// export default function PortalUploadScreen() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const { toast } = useToast();
//   const [isError, setIsError] = useState(false);
//   const [buttonText, setButtonText] = useState('Upload from desktop');
//   const [isUploaded, setIsUploaded] = useState(false);
//   const [tableData, setTableData] = useState([]);
//   const [showConfirmation, setShowConfirmation] = useState(false);
//   const [assignee, setAssignee] = useState({ id: '', username: '' });
//   const [alert, setAlert] = useState(null);
//   const location = useLocation();
  
//   const { mode } = location.state || {}; // Get the mode from the state

//   const isViewMode = mode === "view";

//   const [isDisplayChecked, setIsDisplayChecked] = useState(false);
//   const [isUploadChecked, setIsUploadChecked] = useState(false);
//   const [displayOption, setDisplayOption] = useState('');
//   const [uploadOption, setUploadOption] = useState('');
//   const[statementTypes,setStatementTypes]= useState([]);
//   const [isSourceCodeDescriptionModalOpen, setIsSourceCodeDescriptionModalOpen] = useState(false);
//   const [isSourceCodeModalOpen, setIsSourceCodeModalOpen] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedSource, setSelectedSource] = useState(false);
//   const [selectedSourceCodeDescription, setSelectedSourceCodeDescription] = useState(false);
//   const [source, setSource] = useState("");
//   const [TransactionDate, setTransactionDate] = useState("");
//   const [ourCode, setOurCode] = useState("");
//   const [rateType, setRateType] = useState("");
//   const [quote, setQuote] = useState("");
//   const [ratio, setRatio] = useState("");
//   const [tenor, setTenor] = useState("");
//   const [sourceCode, setSourceCode] = useState("");
//   const [bid, setBid] = useState("");
//   const [ask, setAsk] = useState("");
//   const [errors, setErrors] = useState({});
 
//   const [status, setStatus] = useState("");
//   const [selectedSourceType, setSelectedSourceType] = useState(false);
//   const [isCopyMode, setIsCopyMode] = useState(false);
//   const [unsavedChanges, setUnsavedChanges] = useState(false);
//   const [mdSource, setMdSource] = useState([]);
//   const [Sources, setPortal] = useState([]);
//   const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
//   const [isOurCodeModalOpen, setIsOurCodeModalOpen] = useState(false);
//   const [isSourceCompanyModalOpen, setIsSourceCompanyModalOpen] = useState(false);
//   const [isOurCodeDescriptionModalOpen, setIsOurCodeDescriptionModalOpen] = useState(false);
//   const [selectedOurCode, setSelectedOurCode] = useState(false);
//   const [selectedSourceCompanyName, setSelectedSourceCompanyName] = useState(false);
//   const [selectedOurCodeDescription, setSelectedOurCodeDescription] = useState(false);
//   const [ourCodeDescription, setourCodeDescription] = useState("");
//   const [sourceCompanyName, setsourceCompanyName] = useState("");
//   const [sourceCodeDescription, setsourceCodeDescription] = useState("");
//   const [selectedBankAccountId, setselectedBankAccountId] = useState("");
//   const [SelectedBankAccountIdDescription, setSelectedBankAccountIdDescription] = useState("");
//   const [BankId, setBankId] = useState("");
//   const [AccountNumber, setAccountNumber] = useState("");
//   const [Bank, setBank] = useState("");
//   const [IBANNO, setIBANNO] = useState("");
//   const [CompanyCode, setCompanyCode] = useState("");
//   const [CompanyCodeName, setCompanyCodeName] = useState("");
//   const [OpeningBalance, setOpeningBalance] = useState("");
//   const [ClosingBalance, setClosingBalance] = useState("");
//   const [StatementNO, setStatementNO] = useState("");

//   const [openDropdown, setOpenDropdown] = useState(null);
//   const [mdType, setMdType] = useState("");
//   const [dataType, setDataType] = useState("");
//   // Define the allowed columns for currency rate upload
//  // Define the allowed columns for currency rate upload
// const allowedFormat = [
//     'SourceType', 
//     'SourceCode', 
//     'OurCode', 
//     'SourceCodeDescription', 
//     'OurCodeDescription', 
//     'DateType', 
//     'RateType', 
//     'Ratio', 
//     'MDType', 
//   ];
  
//   useEffect(() => {
//     fetchAssignee();
//   }, []);
  
//     useEffect(() => {
//       fetchportal();
//     }, []);
  
//   // Fetch the logged-in user details
//   const fetchAssignee = async () => {
//     try {
//       const userDetailString = localStorage.getItem('userDetail');
//       if (userDetailString) {
//         const userDetail = JSON.parse(userDetailString);
//         const assigneeData = {
//           id: userDetail.id || '',
//           clientId: userDetail.clientId || '',
//           assigner: userDetail.username || '',
//           userId: userDetail.userId || '',
//         };
//         setAssignee(assigneeData);
//       } else {
//         setAlert({
//           severity: 'error',
//           message: 'No user detail found in session storage.',
//         });
//       }
//     } catch (error) {
//       setAlert({
//         severity: 'error',
//         message: `Error fetching user details: ${error.message}`,
//       });
//     }
//   };
//   const fetchportal = async () => {
//     try {
//       const response = await SourceServices.getAllSource(
//         `${process.env.REACT_APP_BASE_URL}/bam/get-all-accounts-match-portal`
//       );
//       setPortal(response.data.data);
//     } catch (error) {
//       console.error("Error fetching MdSource:", error);
//     }
//   };
//   const handleBankAccountIdCheckBoxChange = (event) => {

//     const selectedValue = event.target.value; // Value from the checkbox (currency code or name)
  
//     // Find the selected currency object
//     const selectedSource = Sources.find(
//       (Source) => Source.sourceCode === selectedValue || Source.sourceCodeDescription === selectedValue
//     );
  
//     if (selectedSource) {
//       if (selectedValue === selectedSource.sourceCode) {
//         // Handle selection for currency code
//         setSelectedSource(selectedSource.sourceCode);
//         setSourceCode(selectedSource.sourceCode);
//         setSelectedOurCode(selectedSource.ourCode);
//         setOurCode(selectedSource.ourCode);
//         setRatio(selectedSource.ratio);
//         setSelectedOurCodeDescription(selectedSource.ourCodeDescription);
//         setSelectedSourceCodeDescription(selectedSource.sourceCodeDescription);
//         setDataType(selectedSource.dataType);
//         setRateType(selectedSource.rateType);
//         setMdType(selectedSource.mdType);
//       } else if (selectedValue === selectedSource.sourceCodeDescription) {
//         // Handle selection for currency name
//         setSelectedSource(selectedSource.sourceCode);
//         setSourceCode(selectedSource.sourceCode);
//         setSelectedOurCode(selectedSource.ourCode);
//         setOurCode(selectedSource.ourCode);
//         setRatio(selectedSource.ratio);
//         setRateType(selectedSource.rateType);
//         setSelectedOurCodeDescription(selectedSource.ourCodeDescription);
//         setSelectedSourceCodeDescription(selectedSource.sourceCodeDescription);
//         setDataType(selectedSource.dataType);
//         setMdType(selectedSource.mdType);
//       }
//       setUnsavedChanges(true); // Mark unsaved changes
//     } else {
//       console.error("Selected currency not found in the list.");
//     }
//   };
//   // Convert Excel date to JavaScript date
//   const excelDateToJSDate = (date) => {
//     if (!isNaN(date)) {
//       const excelEpoch = new Date(Date.UTC(1899, 11, 30));
//       const jsDate = new Date(excelEpoch.getTime() + date * 86400000);
  
//       // Format to DD-MM-YYYY
//       const day = String(jsDate.getUTCDate()).padStart(2, '0');
//       const month = String(jsDate.getUTCMonth() + 1).padStart(2, '0');
//       const year = jsDate.getUTCFullYear();
  
//       return `${day}-${month}-${year}`;
//     }
//     return date;
//   };
  
//   // Handle file upload and validation
//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (!file) return;
  
//     const reader = new FileReader();
//     reader.onload = (event) => {
//       const data = new Uint8Array(event.target.result);
//       const workbook = XLSX.read(data, { type: 'array' });
//       const sheetName = workbook.SheetNames[0];
//       const sheet = workbook.Sheets[sheetName];
  
//       // Convert sheet to JSON with headers
//       const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1});
  
//       if (jsonData.length === 0) {
//         setButtonText('File Format is Incorrect!');
//         setIsUploaded(false);
//         setIsError(true);
//         toast('error', 'The uploaded file is empty or invalid.');
//         return;
//       }
  
//       // Define the new headers
//       const newHeaders = [
//         'Action',
//         'SLNo',
//         'BTCCode',
//         'BTCDescription',
//         'StatementType',
//         'Inflow',
//         'Outflow',
        
//       ];
  
//       // Check if the file format matches the allowed format
//       const headers = jsonData[0].map((header) => header.trim().toLowerCase());
//       const isSourceFormat = newHeaders
//         .map((header) => header.trim().toLowerCase())
//         .every((header) => headers.includes(header));
  
//       if (!isSourceFormat) {
//         setButtonText('File Format is Incorrect!');
//         setIsUploaded(false);
//         setIsError(true);
//         toast('error', 'The uploaded file does not match the required format.');
//         return;
//       }
  
//       // Skip the first two rows (header and any additional row)
//       const rows = jsonData.slice(3);
  
//       // Process and format rows
//       const formattedRows = rows.map((row) => {
//         const formattedRow = {};
//         newHeaders.forEach((header, index) => {
//           formattedRow[header] = row[index] || null; // Use null for missing values
//         });
  
//         // Handle date format for 'TransactionDate' (if applicable)
//         if (formattedRow.TransactionDate) {
//           formattedRow.TransactionDate = excelDateToJSDate(formattedRow.TransactionDate);
//         }
  
//         return formattedRow;
//       });
  
//       console.log("Formatted Rows:", formattedRows);
//       setTableData(formattedRows);
//       setButtonText("File Uploaded");
//       setIsUploaded(true);
//       setIsError(false);
  
//       setTimeout(() => setIsUploaded(false), 1000);
//     };
  
//     reader.readAsArrayBuffer(file);
//   };
  
// // I've added the date format handling back in! Let me know if this works for you. 🚀

// // Let me know if you'd like me to tweak anything! 🚀

// useEffect(() => {
//   fetchStatementTypes();
// }, []);
// const fetchStatementTypes = async () => {
//   setLoading(true);
//   try {
//     const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
//     const responseData = response.data.data;
// console.log("Response Data:", responseData);
//     if (responseData.error) {
//       setAlert({ severity: "error", message: responseData.message });
//       return;
//     }

//     setStatementTypes(responseData);
//     console.log("Statement Types:", statementTypes);
//   } catch (error) {
//     setAlert({
//       severity: "error",
//       message: `Error fetching legal entities: ${error.message}`,
//     });
//   } finally {
//     setLoading(false);
//   }
// };
// const isRowValid = async (row, index) => {
//   console.log("Row Object in isRowValid:", row);
  
//   // 1. Fetch valid types
//   let validStatementTypes = [];
//   try {
//     const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
//     validStatementTypes = response.data.data || [];
//     console.log("Valid Statement Types:", validStatementTypes);
//   } catch (error) {
//     console.error("Error fetching statement types:", error);
//   }

//   // 2. Validate all fields
//   const isActionValid = row.Action && ["A", "a","E","e"].includes(row.Action) && String(row.Action).length === 1;
//   const isSLNoValid = row.SLNo === undefined || row.SLNo === '' || (!isNaN(row.SLNo) && String(row.SLNo).length <= 5);
//   const isBTCCodeValid = row.BTCCode && String(row.BTCCode).length <= 6;
//   const isBTCDescriptionValid = row.BTCDescription && String(row.BTCDescription).length <= 50;
  
//   // 3. Use the fetched validStatementTypes (FIXED THIS LINE)
//   const isStatementTypeValid = !row.StatementType || validStatementTypes.includes(row.StatementType);
//   const isInflowValid = !row.Inflow || ['X', 'x'].includes(row.Inflow);
//   const isOutflowValid = !row.Outflow || ['X', 'x'].includes(row.Outflow);

//   // 4. Collect errors with more detailed messages
//   let errors = [];
//   if (!isActionValid) errors.push("Invalid Action");
//   if (!isSLNoValid) errors.push("Invalid Serial No");
//   if (!isBTCCodeValid) errors.push("Invalid BTC Code");
//   if (!isBTCDescriptionValid) errors.push("Invalid BTC Description");
//   if (!isStatementTypeValid) errors.push(
//     `Invalid Statement Type '${row.StatementType}'. Valid types: ${validStatementTypes.join(', ') || 'None'}`
//   );
//   if (!isInflowValid) errors.push("Invalid Inflow");
//   if (!isOutflowValid) errors.push("Invalid Outflow");

//   if (errors.length > 0) {
//     console.log(`Row ${index + 1} is invalid:`, errors);
//     console.log("Provided StatementType:", row.StatementType);
//     console.log("Valid Types:", validStatementTypes);
//   }
//   console.log("Final Valid Row:", row);
//   return (
//     isSLNoValid &&
//     isActionValid &&
//     isBTCCodeValid &&
//     isBTCDescriptionValid &&
//     isStatementTypeValid &&
//     isInflowValid &&
//     isOutflowValid
//   );
// };

// const getExistingUserIds = async (tableData) => {
//   try {
//     // First fetch valid statement types ONCE
//     let validStatementTypes = [];
//     try {
//       const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
//       validStatementTypes = response.data.data || [];
//       console.log("Valid Statement Types:", validStatementTypes);
//     } catch (error) {
//       console.error("Error fetching statement types:", error);
//     }

//     // Async validation of all rows including dataset duplicates check
//     const validationResults = await Promise.all(
//       tableData.map(async (row, rowIndex) => {
//         // Check for duplicates within current dataset first
//         const isDuplicateInDataset = tableData.some(
//           (r, index) => index !== rowIndex && r.BTCCode === row.BTCCode
//         );
        
//         if (isDuplicateInDataset) {
//           console.log(`Duplicate BTCCode in dataset: ${row.BTCCode}`);
//           return { row, isValid: false, isDuplicate: true };
//         }

//         // Validate other fields if no dataset duplicate
//         const isValid = await isRowValid(row, rowIndex, validStatementTypes);
//         return { row, isValid, isDuplicate: false };
//       })
//     );

//     // Filter valid rows (not duplicates and passed validation)
//     let validRows = validationResults
//       .filter(result => result.isValid && !result.isDuplicate)
//       .map(result => result.row);
    
//     console.log("Initial valid rows after dataset duplicate check:", validRows);

//     // Then check for duplicates in DB
//     const validatedRows = await Promise.all(
//       validRows.map(async (row) => {
//         const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
//           `${process.env.REACT_APP_BASE_URL}/btc/getduplicatebtc`,
//           { btcCode: row.BTCCode }
//         );

//         const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
//         if (isDuplicateInDB) {
//           console.log(`Duplicate found in DB for BTCCode: ${row.BTCCode}`);
//           return null;
//         }
//         return row;
//       })
//     );

//     // Final valid rows
//     validRows = validatedRows.filter(row => row !== null);
//     console.log("Final valid rows after all checks:", validRows);
//     return validRows;

//   } catch (error) {
//     console.error("Error in getExistingUserIds:", error);
//     return [];
//   }
// };
//   const getDataToDisplay = async () => {
//     let dataToDisplay;
//     if (uploadOption === "3") {
//       console.log("displayOption is 3");
//       dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"

//       // dataToDisplay = await processData(tableData); // Display processed data if uploadOption is "3"
//     } else {
//       console.log("displayOption not selected");
//       dataToDisplay = tableData; // Default to tableData if no conditions are met
//     }
    
//     console.log("dataToDisplay data is", dataToDisplay);
//     return dataToDisplay;
//   };

//   const processData = async (data) => {
//     if (!Array.isArray(data)) {
//       console.error("processData received invalid data:", data);
//       return []; // Return an empty array to prevent further errors
//     }
//     console.log("processData received data:", data);
//     let validStatementTypes = [];
//     try {
//       const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
//       validStatementTypes = response.data.data || [];
//       console.log("Valid Statement Types:", validStatementTypes);
//     } catch (error) {
//       console.error("Error fetching statement types:", error);
//     }
//     return Promise.all(
//       data.map(async (row, rowIndex) => {
//         // Check for duplicate BTCCode in the current dataset
//         const isDuplicateUserId = data.some(
//           (r, index) =>
//             index !== rowIndex && // Exclude the current row by index
//             r.BTCCode === row.BTCCode // Compare BTCCode directly
//         );
//         console.log(`Row ${row.BTCCode}: isDuplicateUserId = ${isDuplicateUserId}`);
  
//         // Check for duplicate BTCCode in the database
//         const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
//           `${process.env.REACT_APP_BASE_URL}/btc/getduplicatebtc`,
//           {
//             btcCode: row.BTCCode,
//           }
//         );
  
//         console.log("isDuplicateInDB API Response:", isDuplicateInDBResponse);
  
//         // Extract the `isDuplicate` property from the response
//         const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
//         console.log(`Row ${row.BTCCode}: isDuplicateInDB = ${isDuplicateInDB}`);
  
//         // Validate all fields
//         const hasNotError = [
//           row.Action,
//           row.SLNo,
//           row.BTCCode,
//           row.BTCDescription,
//           row.StatementType,
//           row.Inflow,
//           row.Outflow,
//         ].every((field, index) => {
//           let isValid = true;
//           let reason = "";
  
//           switch (index) {
//             case 0: // Action
//               if (!field) {
//                 isValid = false;
//                 reason = "Action should not be blank.";
//               } else if (!['A', 'a', 'E', 'e'].includes(field)) {
//                 isValid = false;
//                 reason = "Action should be either 'A', 'a', 'E', or 'e'.";
//               } else if (String(field).length > 1) {
//                 isValid = false;
//                 reason = "Action exceeds max length of 1.";
//               }
//               break;
  
//             case 1: // SLNo
//               if (row.SLNo !== undefined && row.SLNo !== '') {
//                 if (isNaN(row.SLNo)) {
//                   isValid = false;
//                   reason = "SLNo should be a valid number.";
//                 } else if (String(row.SLNo).length > 5) {
//                   isValid = false;
//                   reason = "SLNo exceeds max length of 5.";
//                 }
//               }
//               break;
  
//             case 2: // BTCCode
//               if (!field) {
//                 isValid = false;
//                 reason = "BTCCode should not be blank.";
//               } else if (String(field).length > 6) {
//                 isValid = false;
//                 reason = "BTCCode exceeds max length of 6.";
//               } else if (isDuplicateUserId || isDuplicateInDB) {
//                 isValid = false;
//                 reason = "Duplicate BTCCode.";
//               }
//               break;
  
//             case 3: // BTCDescription
//               if (!field) {
//                 isValid = false;
//                 reason = "BTCDescription should not be blank.";
//               } else if (String(field).length > 50) {
//                 isValid = false;
//                 reason = "BTCDescription exceeds max length of 50.";
//               }
//               break;
//               case 4: // StatementType (optional)
//               if (field && !validStatementTypes.includes(field)) {
//                 isValid = false;
//                 reason = `Invalid Statement Type. Valid types: ${validStatementTypes.join(', ')}`;
//               }
//               break;
  
//             case 5: // Inflow (optional)
//               if (field && !['X', 'x'].includes(field)) {
//                 isValid = false;
//                 reason = "Inflow should be either 'X' or 'x'.";
//               }
//               break;
  
//             case 6: // Outflow (optional)
//               if (field && !['X', 'x'].includes(field)) {
//                 isValid = false;
//                 reason = "Outflow should be either 'X' or 'x'.";
//               }
//               break;
  
//             default:
//               break;
//           }
  
//           if (!isValid) {
//             console.log(`Row ${row.BTCCode}: Validation Failed - ${reason}`);
//           } else {
//             console.log(`Row ${row.BTCCode}: Validation Passed - ${field}`);
//           }
  
//           return isValid;
//         });
  
//         console.log(`Row ${row.BTCCode}: Final hasNotError status = ${hasNotError}`);
  
//         return {
//           ...row,
//           isDuplicateUserId,
//           isDuplicateInDB,
//           successfulrecords: hasNotError,
  
//           'Action': row['Action'] ? (
//             ['A', 'a', 'E', 'e'].includes(row['Action']) ? (
//               row['Action']
//             ) : (
//               <span style={{ color: 'red' }}>{row['Action']} (Invalid Action)</span>
//             )
//           ) : (
//             <span style={{ color: 'red' }}> Shouldn't be blank</span>
//           ),
//           'SLNo': row.SLNo ? (
//             !isNaN(row.SLNo) && String(row.SLNo).length <= 5 ? (
//               row.SLNo
//             ) : (
//               <span style={{ color: 'red' }}>{row.SLNo} (Invalid SLNo)</span>
//             )
//           ) : (
//             <span style={{ color: 'red' }}></span>
//           ),
//           'BTCCode': row.BTCCode ? (
//             String(row.BTCCode).length <= 6 ? (
//               isDuplicateUserId || isDuplicateInDB ? (
//                 <span style={{ color: 'red' }}>{row.BTCCode}, Duplicate BTCCode</span>
//               ) : (
//                 row.BTCCode
//               )
//             ) : (
//               <span style={{ color: 'red' }}>{row.BTCCode}, Max. allowed character is 6</span>
//             )
//           ) : (
//             <span style={{ color: 'red' }}>Shouldn't be blank</span>
//           ),
//           'BTCDescription': row.BTCDescription ? (
//             String(row.BTCDescription).length <= 50 ? (
//               row.BTCDescription
//             ) : (
//               <span style={{ color: 'red' }}>{row.BTCDescription} (Max. allowed character is 50)</span>
//             )
//           ) : (
//             <span style={{ color: 'red' }}>Shouldn't be blank</span>
//           ),
//        'StatementType': row['StatementType'] ? (
//         validStatementTypes.includes(row['StatementType']) ? (
//           row['StatementType']
//         ) : (
//           <span style={{ color: 'red' }}>
//             {row['StatementType']} (Invalid. Valid: {validStatementTypes.join(', ')})
//           </span>
//         )
//       ) : (
//         <span></span>
//       ),
//           'Inflow': row['Inflow'] ? (
//             ['X', 'x'].includes(row['Inflow']) ? (
//               row['Inflow']
//             ) : (
//               <span style={{ color: 'red' }}>{row['Inflow']} (Invalid Inflow)</span>
//             )
//           ) : (
//             <span></span> // Empty span for optional field
//           ),
//           'Outflow': row['Outflow'] ? (
//             ['X', 'x'].includes(row['Outflow']) ? (
//               row['Outflow']
//             ) : (
//               <span style={{ color: 'red' }}>{row['Outflow']} (Invalid Outflow)</span>
//             )
//           ) : (
//             <span></span> // Empty span for optional field
//           ),
//         };
//       })
//     );
//   };

//   const storeArrayDataForUsers = async () => {
//     // console.log("store successfulRecords data is", getDataToDisplay);

//     setLoading(true);
//     try {
//         const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
//         console.log("table data",tableData);
//         console.log("store successfulRecords data 2 is", dataToDisplay);

//        const processed = await processData(tableData);
//                  console.log("display datas",processed);
       
//                 const extractText = (cellValue) => {
//                              if (Array.isArray(cellValue)) {
//                                return cellValue.join(", "); // Convert array to string
//                              } else if (typeof cellValue === "string") {
//                                return cellValue;
//                              } else if (React.isValidElement(cellValue)) {
//                                return cellValue.props.children;
//                              }
//                              return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
//                            };
               
//                            const formattedUsers = processed.map((user) => {
               
//                              return {
//                               "Action": extractText(user.Action),
//                               "SLNo": extractText(user.SLNo),
//                               "BTCCode": extractText(user.BTCCode),
//                               "BTCDescription": extractText(user.BTCDescription),
//                               "StatementType": extractText(user.StatementType),
//                               "Inflow": extractText(user.Inflow),
//                              "Outflow": extractText(user.Outflow),
                             
                          
//                            };
//                          });
                           
//                              // Insert second and third row at the beginning of the formatted data
//                              const rowsToDisplay = [ ...formattedUsers];
                     
//                              console.log("Formatted USERS Data:", formattedUsers);
//                              console.log("Formatted Data:", rowsToDisplay);
                     
                             
//                              const updatedData = rowsToDisplay.map(user => {
//                               const hasErrorField = [
//                                 "Action",
//                                 "SLNo",
//                                 "BTCCode",
//                                 "BTCDescription",
//                                 "StatementType",
//                                 "Inflow",
//                                 "Outflow",
                               
                                
//                               ].some(field => typeof user[field] === 'object' || user[field]?.includes("(Invalid") || user[field]?.includes("Shouldn't be blank") || user[field]?.includes("Duplicate SourceCode") || user[field]?.includes("SourceCode does not exist"));
                            
//                               return {
//                                 update: hasErrorField ? "No" : "Yes",
//                                 ...user,
//                               };
//                             });
                            
//                        console.log("updatedData data",updatedData);
//                        const mappedData = dataToDisplay.map((row) => ({
//                                 Action: row.Action,
//                                 SLNo: row.SLNo,
//                                 btcCode: row.BTCCode,
//                                 description: row.BTCDescription, // Map SourceType to source
//                                 statement: row.StatementType,
//                                 inflow: row.Inflow,
//                                 outflow: row.Outflow,
//                                 createdBy:assignee.userId
                                
//                               }));
                           
//         // Prepare the final data object
//         const data = {
//             dataToDisplay:mappedData, // Only valid rows
//             created_by: assignee.userId,
//             allrecords: updatedData, // All rows with update flag
//         };

//         console.log("storeArrayDataForCurrencyRates dataToDisplay is", data);

//         // Send the data to the server
//         const response = await UserService.saveContactDetails(
//             `${process.env.REACT_APP_BASE_URL}/btc/bulk-create-btc`,
//             data
//         );
// if (dataToDisplay.length === 0) {
//   toast("error", "Error fields exist.");
//   return;
// }
//         else if (response.status === 200) {
//             toast('success', 'Uploaded Source successfully');
//         } else if (response.status === 400) {
//             toast('error', 'Invalid or empty array of Source');
//         } else if (response.status === 404) {
//             toast('error', 'Missing required fields');
//         } else if (response.status === 500) {
//             toast('error', 'Duplicate Source found.');
//         }
//     } catch (error) {
//         console.error('Error uploading Source:', error);
//     } finally {
//         setLoading(false);
//     }
// };
  
// const handleTransactionDateChange = (e) => {
//     const code = e.target.value;
//     setTransactionDate(code);
//     setUnsavedChanges(true);
  
    
//   };

//   const handleSubmit = async () => {
//     if (buttonText === 'Upload from desktop') {
//       toast('error', 'Please upload a file.');
//       return;
//     } else if (tableData.length === 0) {
//       toast('error', 'Please upload the correct file containing the data.');
//       return;
//     }

//     if (!isDisplayChecked && !isUploadChecked) {
//       toast('error', 'Please check at least one checkbox (Display or Upload).');
//       return;
//     }

//     if (uploadOption !== '' && displayOption === '') {
//       storeArrayDataForUsers();
//     } else {
//       if (uploadOption !== '') {
//         storeArrayDataForUsers();
//       }
//       navigate(`/5/bank-statement/14/btc-upload/display-screen`, {
//         state: { tableData, displayOption, uploadOption }, // Only pass valid rows
//       });
//     }
//   };

//   const cancelDeletion = () => {
//     setShowConfirmation(false);
//   };


//   const confirmDeletion = async () => {
//     // Create a new workbook
//     const workbook = XLSX.utils.book_new();
  
//     // Define the headers for the Source format
//     const headers = ['RateType', 'TransactionDate', 'SourceType', 'SourceCode', 'Bid', 'Ask'];
  
//     // Get the current date
//     const currentDate = new Date();
//     const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  
//     // Example: Fill 10 rows with current date
//     const rowCount = 10; // You can change this or make it dynamic
//     const worksheetData = [headers];
  
//     for (let i = 0; i < rowCount; i++) {
//       worksheetData.push(['FX', formattedDate, 'SOURCE_TYPE', 'INRKWD=CBKK', '', '']);
//     }
  
//     // Create a worksheet with headers and data
//     const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
//     // Add the worksheet to the workbook
//     XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
//     // Generate the Excel file
//     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
//     // Create a Blob and trigger the download
//     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
//     const url = URL.createObjectURL(blob);
//     const anchor = document.createElement('a');
//     anchor.href = url;
//     anchor.download = 'BTC_Format_Download.xlsx';
//     document.body.appendChild(anchor);
//     anchor.click();
//     document.body.removeChild(anchor);
//     URL.revokeObjectURL(url);
//   };
  
//   // Now, every time the user downloads, the date will automatically update in every row! 🚀
  
//   // Let me know if you want me to make any tweaks or add dynamic row counts! ✨
  

//   const handleBreadcrumbClick = (crumb) => {
//     console.log("Breadcrumb clicked:", crumb.title);
//     if (crumb.title === "BTC") {
//       navigate("/btc");
//     } else if (crumb.title === "Bank Statements") {
//       navigate("/bank-statements");
//     } else if (crumb.title === "Portal") {
//       navigate("/bank-statements");
//     } else if (crumb.path) {
//       navigate(crumb.path); // Navigate to the path specified in the crumb
//     }
//   };
//   const handleOurCodeReset = () => {
//     setSearchQuery(''); // Reset search query
//     setSelectedSource("");
   
//   };
//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };
//   const handleRefreshOurCode = async () => {
//     try {
//       await fetchportal();  // Fetch Branchs
//     } catch (error) {
//       console.error('Error reloading Branchs:', error);
//     } finally {
//     }
//   };
//   return (
//     <div>
//       <Header
//         title={`Bank Statement > Portal > BTC > Portal Upload`}
//         onBreadcrumbClick={handleBreadcrumbClick}
//         backRoute={'/btc'}
//       />
//       {loading && <CustomeLoading />}
//       <div className="m-6 border border-gray-200 p-4  rounded-lg  ">
               
//                {/* <div className="mb-6 border border-gray-300 p-5 rounded-lg"> */}
//                {/* <h3 className="text-sm font-medium text-customGray mb-8">
//                    Rate
//                </h3> */}

//                <div className="grid grid-cols-2 gap-4">
//                <LabelWithButton
// marginbottom ="0px"
// label="Bank Account ID "
// isRequired={true}
// onClick={() => { setIsSourceCodeModalOpen(true); }
// }
// buttonText={selectedBankAccountId || 'Select Bank Account ID '}
// />

// <ReusableModal
// title="Select Bank Account ID "
// isOpen={isSourceCodeModalOpen}
// onClose={() => {
//  setIsSourceCodeModalOpen(false);
//  setSearchQuery('');
// }}
// onSave={() => {
//  setIsSourceCodeModalOpen(false);
//  setSearchQuery('');
// }}
// searchPlaceholder="Search Bank Account ID..."
// searchQuery={searchQuery}
// showReset={true}
// onReset={handleOurCodeReset}
// onSearchChange={handleSearchChange}
// onRefresh={handleRefreshOurCode}
// >
// {Sources && Sources.length > 0 ? (
//  [...new Map(Sources.map((md) => [md.sourceCode, md])).values()] // Remove duplicates by 'ourCode'
//  .filter(
//      (md) =>
//        (md.sourceCode &&
//          md.sourceCode.trim() &&
// //          md.sourceCode
//            .toLowerCase()
//            .includes(searchQuery.toLowerCase()))

//    )
//    .sort((a, b) => a.sourceCode.localeCompare(b.sourceCode)) // Sort in alphabetical order

//    .map((md, index) => (
//      <div
//        key={md.id}
//        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
//          }`}
//      >
//        <input
//          type="radio"
//          name="Bank Account ID "
//          value={md.sourceCode}
//          checked={
//            selectedSource === md.sourceCode
//          }
//          onChange={handleBankAccountIdCheckBoxChange}
//          className="mr-2 accent-customBlue"
//        />
//        <label>{md.sourceCode}</label>
//      </div>
//    ))

// ) : (
//  <p>Loading sourceCode...</p>
// )}
// </ReusableModal>

// <LabelWithButton
// marginbottom ="0px"
// label="Bank Account ID Description"
// isRequired={true}
// onClick={() => { setIsSourceCodeDescriptionModalOpen(true); }
// }
// buttonText={SelectedBankAccountIdDescription || 'Select Bank Account ID Description '}
// />

// <ReusableModal
// title="Select Bank Account ID Description"
// isOpen={isSourceCodeDescriptionModalOpen}
// onClose={() => {
//  setIsSourceCodeDescriptionModalOpen(false);
//  setSearchQuery('');
// }}
// onSave={() => {
//  setIsSourceCodeDescriptionModalOpen(false);
//  setSearchQuery('');
// }}
// searchPlaceholder="Search Bank Account ID Description..."
// searchQuery={searchQuery}
// showReset={true}
// onReset={handleOurCodeReset}
// onSearchChange={handleSearchChange}
// onRefresh={handleRefreshOurCode}
// >
// {Sources && Sources.length > 0 ? (
//  [...new Map(Sources.map((md) => [md.sourceCodeDescription, md])).values()] // Remove duplicates by 'ourCode'
//  .filter(
//      (md) =>
//        (md.sourceCodeDescription &&
//          md.sourceCodeDescription.trim() &&
// //          md.sourceCodeDescription
//            .toLowerCase()
//            .includes(searchQuery.toLowerCase()))

//    )
//    .sort((a, b) => a.sourceCodeDescription.localeCompare(b.sourceCodeDescription)) // Sort in alphabetical order

//    .map((md, index) => (
//      <div
//        key={md.id}
//        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
//          }`}
//      >
//        <input
//          type="radio"
//          name="Bank Account ID Description"
//          value={md.sourceCodeDescription}
//          checked={
//            selectedSourceCodeDescription === md.sourceCodeDescription
//          }
//          onChange={handleBankAccountIdCheckBoxChange}
//          className="mr-2 accent-customBlue"
//        />
//        <label>{md.sourceCodeDescription}</label>
//      </div>
//    ))

// ) : (
//  <p>Loading sourceCodeDescription...</p>
// )}
// </ReusableModal>
// </div>
// <div className="grid grid-cols-2 gap-4 mt-4">

// <div className="flex items-center">
//                        <label className="w-48 font-small text-customGray text-xs">
//                            Bank ID
//                        </label>
//                        <span className="ml-4">:</span>
//                        <input
//                            type="text"
//                            value={BankId}
//                            disabled={true}
                        
//                            placeholder="B name"
//                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//                        />
//                    </div>

// <div className="flex items-center">
//                        <label className="w-48 font-small text-customGray text-xs">
//                          Account Number
//                        </label>
//                        <span className="ml-4">:</span>
//                        <input
//                            type="text"
//                            value={AccountNumber}
//                            disabled={true}
                        
//                            placeholder="B name"
//                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//                        />
//                    </div>
// </div>
// <div className="grid grid-cols-2 gap-4 mt-4"><div className="flex items-center">
//                        <label className="w-48 font-small text-customGray text-xs">
//                            Bank
//                        </label>
//                        <span className="ml-4">:</span>
//                        <input
//                            type="text"
//                            value={Bank}
//                            disabled={true}
                        
//                            placeholder="B name"
//                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//                        />
//                    </div>
//                    <div className="flex items-center">
//                        <label className="w-48 font-small text-customGray text-xs">
//                            IBAN NO{" "}
//                        </label>
//                        <span className="ml-4">:</span>
//                        <input
//                            type="text"
//                            value={IBANNO}
//                            disabled={true}
                          
//                            placeholder="B name"
//                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//                        />
//                    </div>
// </div>
// <div className="grid grid-cols-2 gap-4 mt-4"><div className="flex items-center">
//   <label className="w-48 font-small text-customGray text-xs">
//     Company Code
//   </label>
//   <span className="ml-4">:</span>
//   <input
//     type="text"
//     value={CompanyCode}
//     disabled={true}
//     placeholder="B name"
//     className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//   />
// </div>
// <div className="flex items-center">
//   <label className="w-48 font-small text-customGray text-xs">
//     Company Code Name{" "}
//   </label>
//   <span className="ml-4">:</span>
//   <input
//     type="text"
//     value={CompanyCodeName}
//     disabled={true}
//     placeholder="B name"
//     className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//   />
// </div>
// </div>
// <div className="grid grid-cols-2 gap-4 mt-4">

// <div className="flex items-center">
//   <label className="w-48 font-small text-customGray text-xs">
//     Statement NO{" "}
//   </label>
//   <span className="ml-4">:</span>
//   <input
//     type="text"
//     value={StatementNO}
//     maxLength={2}
//     disabled={isViewMode}
//     onChange={(e) => {
//       setTenor(e.target.value);
//       setUnsavedChanges(true);
//     }}
//     placeholder="2A"
//     className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//   />
// </div>
// <div className="flex items-center">
//   <label className="w-48 font-small text-customGray text-xs">
//     Statement Date 
//   </label>
//   <span className="ml-4">:</span>
//   <input
//     type="date"
//     placeholder="dd.mm.yyyy"
//     value={TransactionDate}
//     onChange={handleTransactionDateChange}
//     className={`border ${errors.TransactionDate ? "border-red-500" : "border-gray-300"} border-gray-300 p-1.5 xl:text-xs lg:text-xs rounded w-80 h-8 
//         ${isViewMode  ? 'text-gray-500' : 'text-customGray'} 
//         focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
//   />
// </div>
// </div>
// <div className="grid grid-cols-2 gap-4 mt-4">

// <div className="flex items-center">
//   <label className="w-48 font-small text-customGray text-xs">
//     Opening Balance{" "}
//   </label>
//   <span className="ml-4">:</span>
//   <input
//     type="text"
//     value={OpeningBalance}
//     maxLength={2}
//     disabled={isViewMode}
//     onChange={(e) => {
//       setTenor(e.target.value);
//       setUnsavedChanges(true);
//     }}
//     placeholder="2A"
//     className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//   />
// </div>
// <div className="flex items-center">
//   <label className="w-48 font-small text-customGray text-xs">
//     Closing Balance{" "}
//   </label>
//   <span className="ml-4">:</span>
//   <input
//     type="text"
//     value={ClosingBalance}
//     maxLength={2}
//     disabled={isViewMode}
//     onChange={(e) => {
//       setTenor(e.target.value);
//       setUnsavedChanges(true);
//     }}
//     placeholder="2A"
//     className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
//   />
// </div>
// </div>

// <div className="flex items-center mt-4">
//           <label className="w-48 font-small text-customGray text-xs">
//             <span className="w-20 font-small text-sm text-customGray">File</span>
//             <span className="ml-4">:</span>
//             <input
//               type="file"
//               // ref={fileInputRef}
//               style={{ display: "none" }}
//               onChange={handleFileChange}
//               accept=".xlsx, .xls"
//             />
//           </label>

//           <button
//             type="button"
//             onClick={handleFileChange}
//             className={`border border-gray-300 p-2 w-80 h-10 text-sm focus:outline-none hover:border-blue-400 ml-6 rounded-full text-center
//               ${isUploaded ? "text-green-500 animate-pulse" : isError ? "text-red-500" : "text-gray-700"}
//               ${buttonText === "File Uploaded" ? "text-green-500" : ""}`}
//           >
//             {buttonText}
//           </button>
//           </div>
// </div>

// <Footer>
//   <button
//     onClick={handleSubmit}
//     className="bg-customBlue text-sm text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200 ease-in-out"
//   >
//     Run
//   </button>
// </Footer>

// <ConfirmationDialog
//   open={showConfirmation}
//   title="Upload File"
//   message="Do you need to download the latest upload format?"
//   onConfirm={confirmDeletion}
//   onCancel={cancelDeletion}
// />
// </div>
// );
// }