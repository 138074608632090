import RestService from "./RestServices";

const SourceServices      = {
  getAllSource: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error("Error fetching Source data:", error);
      return { error: true, message: "Error fetching Source data" };
    }
  },
  getSourceById: (id) => {
    return RestService.GetByIdData(`/get-source-id`, id);
  },
  deleteSource:(id)=>{
return RestService.DeleteData('/delete-source',id);
  },
  getAllSourcewithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
    return RestService.GetAllData(
      `/get-Source-page?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  addSource: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error adding Source:", error);
      return { error: true, message: "Error adding Source" };
    }
  },
  checkDuplicateSourcecode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching Source code:", error);
      return { error: true, message: "Error fetching Source code" };
    }
  },
};

export default SourceServices;      ;
