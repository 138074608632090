import React, { useState, useEffect } from 'react';
import { useToast } from '../../../../../components/toast/toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../../../../../components/Header';

export default function DBareaAdd() {
  const navigate = useNavigate();

  const [dbName, setDBname] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ assigner: '' });

  useEffect(() => {
    const fetchAssignee = async () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            dbName: userDetail.dbName || "",
            assigner: userDetail.username || "",
          };
          setAssignee(assigneeData);
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            api: "No user detail found in session storage.",
          }));
        }
      } catch (error) {
        setErrors(prevErrors => ({
          ...prevErrors,
          api: `Error fetching user details: ${error.message}`,
        }));
      }
    };

    fetchAssignee();
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  // const handleSaveBasicDetails = async () => {
  //   setErrors({});

  //   if (!dbName || !description) {
  //     const newErrors = {};
  //     if (!dbName) newErrors.dbName = 'DB name is required.';
  //     if (!description) newErrors.description = 'Description is required.';
  //     setErrors(newErrors);
  //     return;
  //   }

  //   const payload = {
  //     DB: dbName,
  //     DB_Description: description,
  //     created_by: assignee.assigner || "Unknown",
  //     changed_by: assignee.assigner || "Unknown"
  //   };

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/create-db-area`, payload, {
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     });

  //     console.log(response.data); 
  //     toast("success", "Added successfully");

  //     setDBname('');
  //     setDescription('');
  //     handleNavigate('/DB-area');
  //   } catch (error) {
  //     console.error("Error saving DB Area:", error);
  //     setErrors(prevErrors => ({
  //       ...prevErrors,
  //       api: 'Failed to create DB Area. Please try again later.'
  //     }));
  //   }
  // };

  // const handleSaveContinueDetails = async () => {
  //   setErrors({});

  //   if (!dbName || !description) {
  //     const newErrors = {};
  //     if (!dbName) newErrors.dbName = 'DB name is required.';
  //     if (!description) newErrors.description = 'Description is required.';
  //     setErrors(newErrors);
  //     return;
  //   }

  //   const payload = {
  //     DB: dbName,
  //     DB_Description: description,
  //     created_by: assignee.assigner || "Unknown",
  //     changed_by: assignee.assigner || "Unknown"
  //   };

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/create-db-area`, payload, {
  //       headers: {
  //         "Content-Type": "application/json"
  //       }
  //     });

  //     console.log(response.data); 
  //     toast("success", "Added successfully");

  //     setDBname('');
  //     setDescription('');
  //   } catch (error) {
  //     console.error("Error saving DB Area:", error);
  //     setErrors(prevErrors => ({
  //       ...prevErrors,
  //       api: 'Failed to create DB Area. Please try again later.'
  //     }));
  //   }
  // };

  const handleSaveDetails = async (navigateAfterSave = false) => {
    setErrors({});

    if (!dbName || !description) {
      const newErrors = {};
      if (!dbName) newErrors.dbName = 'DB name is required.';
      if (!description) newErrors.description = 'Description is required.';
      setErrors(newErrors);
      return;
    }

    const payload = {
      DB: dbName,
      DB_Description: description,
      created_by: assignee.assigner || "Unknown",
      changed_by: assignee.assigner || "Unknown"
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/create-db-area`, payload, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      console.log(response.data);
      toast("success", "Added successfully");

      setDBname('');
      setDescription('');

      if (navigateAfterSave) {
        navigate('/3/BAM/24/DB-area', { replace: true });
      }
    } catch (error) {
      console.error("Error saving DB Area:", error);
      setErrors(prevErrors => ({
        ...prevErrors,
        api: 'Failed to create DB Area. Please try again later.'
      }));
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'BAM') {
      console.log("Navigating to /bam");
      navigate('/bam');
    } else if (crumb.title === 'Set Up') {
      console.log("Navigating to /bam");
      navigate('/bam');
    }
    else if (crumb.title === 'DB Area') {
      console.log("Navigating to /DB-area");
      navigate('/3/BAM/24/DB-area');
    }
    else if (crumb.title === 'Add') {
      console.log("Navigating to /DB-area-add");
      navigate('/DB-area-add');
    }
    else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div>
        <Header title={"BAM > Set Up > DB Area > Add"}
          onBreadcrumbClick={handleBreadcrumbClick}
        />
      </div>

      <div className='flex-grow p-10'>
        <label className="flex items-center mb-4 ">
          <span className="w-48 text-sm font-medium text-gray-500">DB <span className="text-red-500 ml-1">*</span></span>
          <span className="ml-3">:</span>
          <input
            type="text"
            value={dbName}
            onChange={(e) => setDBname(e.target.value)}
            required
            placeholder='DB'
            className="border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5"
          />
        </label>

        <label className="flex items-center mb-4 py-3">
          <span className="w-48 text-sm font-medium text-gray-500">
            DB Description <span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-3">:</span>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            placeholder='Description'
            className={`border border-gray-300 p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-5
            ${errors.description ? 'border-red-500 hover:border-red-500' : ''}`}
          />
          {errors.description && (
            <p className="text-red-500 text-sm ml-4">{errors.description}</p>
          )}
        </label>
      </div>

      <footer className="flex justify-start p-4 border-t border-gray-300">
        <button
          onClick={() => handleSaveDetails(true)}
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16"
        >
          Save
        </button>
        <button
          onClick={() => handleSaveDetails(false)}
          className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
        >
          Save & Continue
        </button>
      </footer>

    </div>
  )
}
