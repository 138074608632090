import React, { useEffect, useState } from 'react'
import Header from '../../../../../components/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdminActionsConfig } from '../../Components/ActionConfig';
import { IoMdRefresh } from 'react-icons/io';
import { MdOutlineFileDownload, MdOutlineLock, MdOutlineLockOpen } from 'react-icons/md';
import { GiSettingsKnobs } from 'react-icons/gi';
import ReTable from '../../../../../components/Table';
import Pagination from '../../../../../components/PageNation';
import AdminActions from '../../Components/AdminActions';
import { TbFlag3Filled } from 'react-icons/tb';
import UserService from '../../../../../rest-services/UserServices';
import { useToast } from '../../../../../components/toast/toast';
import HoverButton from '../../../../../components/HoverButton';
import CustomButton from '../../../../../components/CustomButton';
import { IoSettingsOutline } from 'react-icons/io5';
import ColumnSettingsPopup from '../../../Masterdata/Components/ColumnSettingPopup';
import XLSX from "xlsx-js-style"; // Import the xlsx library

export default function LicenseSummaryTableScreen() {
    const navigate = useNavigate();
  const { iconId } = useParams();
const { toast } = useToast();
  const location = useLocation();
  const analyserData = location.state;
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
    const [userPreference, setUserPreference] = useState("");
    const [assignee, setAssignee] = useState({ id: "", username: "" });
    const [alert, setAlert] = useState(null);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
  
    const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
const [pageSize, setPageSize] = useState(20);

const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
      fetchDataForTable(currentPage);
    }, [currentPage, searchQuery]);

  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchDataForTable(1);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedData, setSelectedData] = useState([]);


const openModal = async (id) => {
    try {
      console.log("checking the valuable id",id);

        const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/module-assignment/${id}`);
console.log("response for get module assignment based on the id",response.data.data);

      setSelectedData(response.data.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

const closeModal = () => {
  setIsModalOpen(false);
  setSelectedData([]);
};


  const fetchDataForTable = async (page) => {
    try {
      if (!analyserData) return;
      const data = {
        data: analyserData,
        limit: pageSize || 10,
        offset: page && pageSize ? (page - 1) * pageSize : 0,
        searchQuery: searchQuery || "",
      };

      console.log("fetch data for table", data);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-all-userid-by-licenseSummary`,data);
        console.log("Response data for license summary table screen",response);
        if (response.status === 200) {
      const totalCount = response.totalCount;
      setData(response.analyser);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / (pageSize || 1)));
        }
        else if (response.status === 400) {
            toast("error", "Error fetching data.");
          }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

    useEffect(() => {
      fetchAssignee();
  }, []);


  
  // Get Logged in User details function
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
    useEffect(() => {
      if (assignee.assignerId) {
  fetchUserPreferenceDetails();
}
}, [assignee.assignerId]);

   const fetchUserPreferenceDetails = async () => {
      try {
        const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
        console.log("Requestfor user pref Data:", data);
  
        // setLoading(true); // Set loading state
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
          data
        );
        console.log("user prefference details", response.data);
        setUserPreference(response.data); // Set user preference data
      } catch (error) {
        setError(error.message); // Set error message
      }
      //  finally {
      //   setLoading(false); // Reset loading state
      // }
    };
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Admin') {
          navigate('/14/admin');
        } 
        else if (crumb.title === 'Licenses') {
            navigate('/14/admin');
        }

        else if (crumb.title === 'Licenses Summary') {
          navigate('/14/admin');
      }
         else if (crumb.path) {
          navigate(crumb.path); // Navigate to the path specified in the crumb
        }
      };

      const handleSettingsButtonClick = () => {
        setIsSettingsPopupOpen(true);
      };
      
         const handleDownload = async () => {
            if (data.length === 0) {
              setAlert({
                severity: "warning",
                message: "No data available to export!",
              });
              return;
            }
        
            // Prepare data for export
            const formattedUsers = data.map((user) => ({
  
              "User ID": user.userId,
              Status:
                user.approved === 0
                  ? "Created"
                  : user.approved === 1
                    ? "Changed"
                    : user.approved === 2
                    ? "Waiting for Approval"
                    : user.approved === 3
                    ? "Modified"
                    : user.approved === 4
                    ? "Approved"
                    : user.approved === 5
                          ? "Rejected"
                          : "Inactive",
              Lock: user.userStatus === 0 ? "Unlock" : "Lock",
              Username: user.username,
              "User Type": user.UserType?.name || "N/A",
              "Valid From": formatDate(user.validFrom, dateFormat),
        
              "Valid To": formatDate(user.validTo, dateFormat),
              "License": user.moduleAssignmentCount,
              "Email ID": user.email,
              "Company Code": user.companyCodeId,
              Country: user.Address?.countryCode,
              "State/Province": user.Address?.state,
              "First Name": user.firstName,
              "Middle Name": user.middleName,
              "Last Name": user.lastName,
            }));


          const worksheet = XLSX.utils.json_to_sheet(formattedUsers); // Converts JSON to sheet
      
      
          
              // Define border style
            const border = {
              top: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
            };
          
               // Apply borders to the first three rows
               formattedUsers.slice(0, 1).forEach((row, rowIdx) => {
                    Object.keys(row).forEach((col, colIdx) => {
                      const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
                      if (worksheet[cellAddress]) {
                        worksheet[cellAddress].s = { border };
                      }
                    });
                  });
            
                   // Auto-fit column widths
            const columnWidths = Object.keys(formattedUsers[0]).map((key) => ({
              wch: Math.max(
                key.length, // Column header width
                ...formattedUsers.map((row) => (row[key] ? row[key].toString().length : 0)) // Max content width
              ),
            }));
          
            worksheet["!cols"] = columnWidths;
            
            
          const workbook = XLSX.utils.book_new(); // Creates a new workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, "Users"); // Appends the sheet to the workbook
      
          // Export as an Excel file
          XLSX.writeFile(workbook, "License_Summary_Data.xlsx");
        };

        const existingButtons = AdminActionsConfig.screen9.map((button) => {
          let onClick;
          let title;
          if (button.icon === GiSettingsKnobs) {
            // onClick = toggleLayout;
            title = "Filter";
          } else if (button.icon === IoSettingsOutline) {
                onClick = handleSettingsButtonClick;
                title = "Table Settings";
              } 
              else if (button.icon === MdOutlineFileDownload) {
            onClick = handleDownload;
            title = "Download";
          } else if (button.icon === IoMdRefresh) {
            onClick = () => fetchDataForTable(currentPage);
            title = "Refresh";
          }
      
          return {
            ...button,
            onClick,
            title,
          };
        });
      
        const extraButtons = [
        ];

        const actionButtons = existingButtons.concat(
            extraButtons.map((button) => ({
              ...button,
              disabled: selectedRows.length === 0, // Disable button if no users are selected
              onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
            }))
          );

          const formatDate = (dateString, format) => {
            if (!dateString) return "";
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const shortYear = String(year).slice(-2);
          
            const formatMap = {
                "DD/MM/YYYY": `${day}/${month}/${year}`,
                "MM/DD/YYYY": `${month}/${day}/${year}`,
                "DD/MM/YY": `${day}/${month}/${shortYear}`,
                "MM/DD/YY": `${month}/${day}/${shortYear}`,
                "DD.MM.YYYY": `${day}.${month}.${year}`,
                "DDMMYY": `${day}${month}${shortYear}`,
                "DD-MM-YYYY": `${day}-${month}-${year}`,
            };
          
            return formatMap[format] || dateString;
          };
          
          const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format
          
          const columns2 = [
            // { Header: "User ID", accessor: "userId", visible: true, width: 12 * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
            { Header: "Module", accessor: "subMenu.mainMenu.name", visible: true, width: 12 * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
            { Header: "Sub Module", accessor: "subMenu.name", visible: true, width: 12 * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
            { Header: "License", accessor: "isChecked", visible: true, width: 12 * 10, 
                Cell: ({ row }) => (
                    <div style={{ textAlign: "center" }}>
                      {row.original.isChecked ? "1" : "0"}
                    </div>
                  ),
             },

          ];

        const columns = [
            {
                Header: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TbFlag3Filled style={{ color: "#ffff" }} />
                  </div>
                ),
                accessor: "approved",
                visible: true,
                width: ("approved".length -1) * 10,                          Cell: ({ row }) => {
                  const { approved } = row.original;
                  const colorMap = {
                    0: "gray",
                    1: "#AF52DE",
                    2: "yellow",
                    3: "#FF9500 ", //orange color
                    4: "#29CB00",
                    5: "red",
                  };
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TbFlag3Filled style={{ color: colorMap[approved] || "black" }} />
                    </div>
                  );
                },
              },
              {
                Header: (
                <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "100%",
                    }}
                >
                    <MdOutlineLock style={{ color: "#ffff" }} />
                </div>
                ),
                accessor: "userStatus",
                visible: true,
                width: ("userStatus".length -1) * 10,                    Cell: ({ row }) => {
                const user = row.original;
                return user.userStatus == 0 ? (
                    <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                              <MdOutlineLockOpen />
                          </div>
                ) : (
                    <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                    <MdOutlineLock style={{ color: "red" }} />
                          </div>
                );
                },
                },
                { Header: "User ID", accessor: "userId", visible: true, width: ("User ID".length -1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
                { Header: "User Name", accessor: "username", visible: true, width: ("User Name".length -1) * 10,Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
                { Header: "User Type", accessor: "UserType.name", visible: true, width: ("User Type".length -1) * 10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
              
                   
                         { Header: "Valid From", accessor: "validFrom", visible: true, width: ("Valid From".length -1) * 10,  
                              Cell: ({ value }) => formatDate(value, dateFormat),
                           },
                            { Header: "Valid To", accessor: "validTo", visible: true, width: ("Valid To".length -1) * 10,
                              Cell: ({ value }) => formatDate(value, dateFormat),
                             },
                            { Header: "License", accessor: "moduleAssignmentCount", visible: true, width: ("License".length -1) * 10,alignment: "center",
                                Cell: ({ row }) => (
                                    <span
                                      style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                                      onClick={() => openModal(row.original.id)} // Pass the id to the modal
                                    >
                                      {row.original.moduleAssignmentCount}
                                    </span>
                                  ),
                             },
                            { Header: "Email ID", accessor: "email", visible: true, width: ("Email ID".length -1) * 10,gnment: "left" },
                            {
                              Header: "Company Code",
                              accessor: "companyCodeId",
                              visible: true,
                              width: ("Company Code".length -1) * 10, alignment: "left"
                            },
                            {
                                Header: "Country",
                                accessor: "Address.countryCode",
                                visible: true,
                                width: ("Country".length -1) * 10,alignment: "left"
                            },
                            {
                              Header: "State/Province",
                              accessor: "Address.state",
                              visible: true,
                              width: ("State/Province".length -1) * 10,alignment: "left"
                          },
                            { Header: "First Name", accessor: "firstName", visible: true, width: ("First Name".length -1) * 10, alignment: "left" },
                                {
                                  Header: "Middle Name",
                                  accessor: "middleName",
                                  visible: true,
                                  width: ("Middle Name".length -1) * 10, alignment: "left"
                                },
                                { Header: "Last Name", accessor: "lastName", visible: true, width: ("Last Name".length -1) * 10, alignment: "left" },
                                
            
        ];

  const [columnSettings, setColumnSettings] = useState(columns);
    const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  
  const modifiedColumns = columnSettings
  .filter((col) => col.visible)
  .map((col) => ({
    ...col,
    width: col.width,
  }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const [columnSettings2, setColumnSettings2] = useState(columns2);
  const modifiedColumns2 = columnSettings2
  .filter((col) => col.visible)
  .map((col) => ({
    ...col,
    width: col.width,
  }));


  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    // setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };

  return (
    <div>
       <Header title="Admin > Licenses > Licenses Summary" onBreadcrumbClick={handleBreadcrumbClick} backRoute={"/14/admin/license-summary/136"} />

       <div className="p-0">
       <div className="flex justify-between items-center -ml-1 pl-4">
       <div className="flex items-center">
       <AdminActions
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows}
            //   hasFiltersApplied={hasFiltersApplied}

            />
        </div>
       <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>
          </div>
          <div>
          <ReTable
            data={data}
            columns={modifiedColumns}
            selectedRows={selectedRows}
            onSelectRow={handleSelectRow}
            onSelectAll={handleSelectAll}
            onRowDoubleClick={() => {}}
          />
 {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
{/* {isModalOpen && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
    <div className="bg-white p-4 rounded-md">
      <h2 className="text-lg font-bold">Module Details</h2>
      <p>Selected ID: {selectedId}</p>
      <button onClick={closeModal} className="mt-2 bg-blue-500 text-white px-4 py-2 rounded">
        Close
      </button>
    </div>
  </div>
)} */}

{isModalOpen && (
                        <>
                        {/* Backdrop */}
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 z-40"
                            onClick={closeModal} // Closes modal when clicking outside
                        />

                        {/* Modal Content */}
                        <div
                            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-6/12 max-w-md z-50"
                            onClick={(e) => e.stopPropagation()} // Prevents close on modal content click
                        >
                            <div className="flex justify-between items-center mb-4 px-5">
                            <h2 className="text-customBlue text-lg font-semibold text-center">License Summary</h2>
                            <button onClick={closeModal} className="text-gray-500 hover:text-gray-700">
                                ✕
                            </button>
                            </div>

                            {/* Table inside the modal */}
                         
                            <ReTable
                            data={selectedData}
                            columns={modifiedColumns2} // Use modified columns based on visibility
                            selectedRows={selectedRows}
                            onSelectRow={handleSelectRow}
                            onSelectAll={handleSelectAll}
                                onRowDoubleClick={() => {}}
                            showCheckbox={false}
                            />
                          
                        </div>
                        </>
                    )}
        </div>
        </div>

    </div>
  )
}
