import styled from 'styled-components';

const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: ${(props) => props.maxHeight || '300px'}; /* Use default or passed height */
  

  /* For WebKit-based browsers (Chrome, Edge, Safari) */
  ::-webkit-scrollbar {
    width: 6px; /* Vertical scrollbar width */
    height: 6px; /* Horizontal scrollbar height */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8aa6c1;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #6b8db2; /* Slightly darker for hover */
  }

  ::-webkit-scrollbar-track {
    background-color: #f0f4f8;
    border-radius: 6px;
  }

  /* For Firefox */
  scrollbar-width: thin; /* Applies to both horizontal and vertical */
  scrollbar-color: #8aa6c1 #f0f4f8; /* Thumb and track colors */
`;

export default TableWrapper;
