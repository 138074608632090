import React, { useEffect, useState } from 'react'
import Header from '../../../../../components/Header'
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../../../../components/SearchInput';
import PrimaryActions from '../../Components/ActionButton';
import { PrimaryActionsConfig } from '../../Components/ActionButtonConfig';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdRefresh, IoMdTime, IoMdTrash } from 'react-icons/io';
import LegalEntityServices from '../../../../../rest-services/LegalEntityServices';
import CustomeUpLoader from '../../../../../components/CustomeLoader';
import ColumnSettingsPopup from '../../Components/ColumnSettingPopup';
import ReTable from '../../../../../components/Table';
import * as XLSX from 'xlsx';
import { TbDeviceDesktopCancel, TbFlag3Filled } from 'react-icons/tb';
import { GiSettingsKnobs } from 'react-icons/gi';
import { useToast } from "../../../../../components/toast/toast";

export default function LegalEntity() {
    const columns = [
      { Header: <TbFlag3Filled style={{ color: "#ffff" }} />, 
      accessor: "status", 
      visible: true, 
      width: 200,
      Cell: ({ value }) => {
        switch (value) {
          case 3:
            return <TbFlag3Filled style={{ color: "#29CB00" }} />; // Green for status 3
          case 0:
            return <TbFlag3Filled style={{ color: "gray" }} />; // Gray for status 0
          case 1:
            return <TbFlag3Filled style={{ color: "yellow" }} />; // Yellow for status 1
          case 2:
            return <TbFlag3Filled style={{ color: "orange" }} />; // Orange for status 2
          case 4:
            return <TbFlag3Filled style={{ color: "red" }} />; // Red for status 4
          default:
            return <TbFlag3Filled style={{ color: "black" }} />; // Default black flag
        }
      },
    },
        { Header: "LGE Code", accessor: "LGE_Code", visible: true, width: 200 },
        { Header: "LGE Description", accessor: "LGE_Description", visible: true, width: 200 },
        { Header: "Country", accessor: "Country", visible: true, width: 200 },
        { Header: "Currency", accessor: "Currency", visible: true, width: 200 },
        { Header: "Created By", accessor: "created_by", visible: true, width: 200 },
        {
          Header: "Created Date",
          accessor: "creationDate",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
          },
        },
        {
          Header: "Created Time",
          accessor: "creationTime",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return date.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });
          },
        },
        { Header: "Updated By", accessor: "changed_by", visible: true, width: 200 },
        {
          Header: "Updated Date",
          accessor: "changedDate",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
          },
        },
        {
          Header: "Updated Time",
          accessor: "changedTime",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return date.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });
          },
        },
      ];
      
    const [legalEntity, setLegalEntity] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const navigate = useNavigate();
    // const { toast } = useToast();
    const [legalEntityId, setLegalEntityId] = useState(null);
    const [filteredlegalEntity, setFilteredLegalEntity] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showExtraIcons, setShowExtraIcons] = useState(false);
    const [columnSettings, setColumnSettings] = useState(columns);
    const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
    const { toast } = useToast();
    const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
    

 
      const modifiedColumns = columnSettings
      .filter((col) => col.visible)
      .map((col) => ({
        ...col,
        width: col.width,
      }));
      useEffect(() => {
        fetchLegalEntity();
        fetchAssignee();
      }, []);
      
      useEffect(() => {
        if (searchQuery.trim() === "") {
          setFilteredLegalEntity(legalEntity);
        } else {
          const filtered = legalEntity.filter((legalentity) => 
            legalentity.LGE_Code &&
            legalentity.LGE_Code.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setFilteredLegalEntity(filtered);
        }
      }, [searchQuery, legalEntity]);
      
      const fetchLegalEntity = async () => {
        setLoading(true);
        try {
          const response = await LegalEntityServices.getAllLegalEntity(`/get-all-legal-entity`);
          const responseData = response.data;
      
          if (responseData.error) {
            setAlert({ severity: "error", message: responseData.message });
            return;
          }
      
          if (responseData && Array.isArray(responseData)) {
            setLegalEntity(responseData);  // assuming responseData contains an array of legal entities
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching legal entities: ${error.message}`,
          });
        } finally {
          setLoading(false);
        }
      };
      const fetchAssignee = async () => {
        try {
          const userDetailString = sessionStorage.getItem("userDetail");
          if (userDetailString) {
            const userDetail = JSON.parse(userDetailString);
            const assigneeData = {
              id: userDetail.id || "",
              assigner: userDetail.username || "",
            };
            setAssignee(assigneeData);
          } else {
            setAlert({
              severity: "error",
              message: "No user detail found in session storage.",
            });
          }
        } catch (error) {
          setAlert({
            severity: "error",
            message: `Error fetching user details: ${error.message}`,
          });
        }
      };
    
      const handleDelete = async () => {
        if (selectedRows.length === 0) return; // No rows selected to delete
        
        const confirmed = window.confirm("Are you sure you want to delete the selected LegalEntity(s)?");
        if (confirmed) {
          setLoading(true);
          try {
            // Loop through each selected row ID and delete
            for (const id of selectedRows) {
              await LegalEntityServices.deleteLegalEntity(id);
            }
            // toast("success", "Selected LegalEntity(s) deleted successfully");
      
            // Clear selection after deletion and refresh the list
            setSelectedRows([]);
            fetchLegalEntity();
          } catch (error) {
            console.error("Error deleting LegalEntity(s):", error);
            // toast("error", "Failed to delete LegalEntity(s)");
          } finally {
            setLoading(false);
          }
        }
      };
      const handleApproveClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await LegalEntityServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/approvelegalentity`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response);
      
            if (response.status === 200) {
              
              toast("success", "Branch successfully Approved");
      
              // Success logic, refresh the bank branches
               fetchLegalEntity();
            }  else if (response.status === 400) {
              toast("warning", "Branch is unable to approve.");
             } 
             else if (response.status === 550) {
              toast("warning", "Branch Email is Not Sent.");
             } 
             else {
              console.log("Error updating Branchs.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
      
      const handleRejectClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await LegalEntityServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/rejectlegalentity`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response.status);
      
            if (response.status === 200) {
              toast("error", "Branch successfully Rejected");
              fetchLegalEntity();
            }  else if (response.status === 400){
              toast("warning", "Branch is unable to Reject.");
            } else {
              console.log("Error updating Branchs.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
      const handleInactiveClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await LegalEntityServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/inactivelegalentity`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response);
      
            if (response.status ===  200) {
              toast("success", "Branch successfully Change Status.");
              fetchLegalEntity();
            } 
            else if (response.status === 400){
              toast("warning", "The Branch cannot be deactivated at this time.");
            } else {
              console.log("Error approving bank branches.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
    const extraButtons = [
      {
        icon: IoMdCheckmark, // Pass the component itself
        onClick: handleApproveClick,
        title: "Approve",
        disabled: selectedRows.length === 0,
        
      },
      {
        icon: IoMdClose,
        onClick: handleRejectClick,
        title: "Reject",
        disabled: selectedRows.length === 0,
      },
        {
          icon: IoMdCreate,
          onClick: () => legalEntityId && navigateToForm(legalEntityId),
          title:"Edit Country",
          disabled: selectedRows.length === 0,  
    
        },
        {
          icon: IoMdCopy,
          // onClick: handleAddcopyClick,
          title: "Copy",
          disabled: selectedRows.length === 0,
        },
        {
          icon: IoMdTime,
          // onClick: handleAuditTrail,
          title: "Audit Trail",
          disabled: selectedRows.length === 0,
        },
        {
          icon: IoMdTrash,
          onClick: handleDelete,
      
          title: "Delete",
          disabled: selectedRows.length === 0,
        },
        {
          icon: TbDeviceDesktopCancel,
          onClick: handleInactiveClick,
          title: "Inactive/Active",
          disabled: selectedRows.length === 0,
        },
      ];
      const navigateToForm = (id) => {
        navigate(`/legalentity-add/${id}`);
      };
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Bank&Company masterData") {
          navigate("/master-data");
        } else if (crumb.title === "LegalEntity") {
          navigate("/LegalEntity");
        } else if (crumb.title === "Primary Data") {
          navigate("/master-data");
        } else if (crumb.path) {
          navigate(crumb.path); 
        }
      };
      const handleSettingsButtonClick = () => {
        setIsSettingsPopupOpen(true);
      };
      const handleDownload = () => {
        const dataToExport = filteredlegalEntity.map((legalentity) => ({
          "LGE Code": legalentity.LGE_Code,
          "LGE Description": legalentity.LGE_Description,
          "Country": legalentity.Country,
          "Currency": legalentity.Currency,
          "Door No": legalentity.addresses?.[0]?.doorNo || "", // Ensure addresses array exists
          "Floor No": legalentity.addresses?.[0]?.floorNo || "",
          "Building No": legalentity.addresses?.[0]?.buildingNo || "", // Ensure addresses array exists
          "Building Name": legalentity.addresses?.[0]?.buildingName || "", // Ensure addresses array exists
          "Land Mark Name": legalentity.addresses?.[0]?.landMark || "",
          "Street No": legalentity.addresses?.[0]?.streetNo || "",
          "Street Name": legalentity.addresses?.[0]?.streetName || "",
          "Road No": legalentity.addresses?.[0]?.streetName || "",
          "Road Name": legalentity.addresses?.[0]?.streetName || "",
          "Street Name": legalentity.addresses?.[0]?.streetName || "",
          "Street Name": legalentity.addresses?.[0]?.streetName || "",
          "Area Block": legalentity.addresses?.[0]?.areaBlock || "", // Ensure addresses array exists
          "Area Name": legalentity.addresses?.[0]?.areaName || "",
          "Address Line1": legalentity.addresses?.[0]?.addressLine1 || "",
          "Address Line2": legalentity.addresses?.[0]?.addressLine2 || "",
          "Address Line3": legalentity.addresses?.[0]?.addressLine3 || "",
          "Country Code": legalentity.identifier?.[0]?.country_Code || "", 
          "State Name": legalentity.addresses?.[0]?.stateName || "", // Ensure state name from addresses
          "District": legalentity.addresses?.[0]?.district || "",
          "Postal Code": legalentity.addresses?.[0]?.postalCode || "",
          "Zip Code": legalentity.addresses?.[0]?.zipCode || "",
          "Po Box": legalentity.addresses?.[0]?.poBox || "",
          "Created By": legalentity.created_by,
          "Created Date": legalentity.creationDate ? new Date(legalentity.creationDate).toLocaleDateString()
          : "",
          "Created Time": legalentity.creationTime  ? new Date(legalentity.creationTime).toLocaleDateString()
          : "",
          "Updated By": legalentity.changed_by,
          "Updated Date": legalentity.changedDate
            ? new Date(legalentity.changedDate).toLocaleDateString()
            : "",
          "Updated Time": legalentity.changedTime
            ? new Date(legalentity.changedTime).toLocaleTimeString()
            : "",
         
        }));
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Legal Entity");
        XLSX.writeFile(workbook, "LegalEntity_Data.xlsx");
      };
      const handleReload = async () => {
        setLoading(true);  // Start loading
        try {
          await fetchLegalEntity();  // Fetch users
        } catch (error) {
          console.error('Error reloading users:', error);
        } finally {
          setLoading(false);  // Stop loading
        }
      };
      const existingButtons = PrimaryActionsConfig.screen2.map((button) => ({
        ...button,
        title:
          button.icon === IoAddCircleOutline
            ? "Add Country"
             : button.icon === MdOutlineFileDownload
            ? "Filter"
            : button.icon === GiSettingsKnobs
            ? "Download"
             : button.icon === MdOutlineFileUpload
            ? "Upload"
            : button.icon === IoSettingsOutline
            ? "Settings"
                  : button.icon === IoMdRefresh
      ? "Refresh"
            : button.title || "", 
        onClick:
          button.icon === IoAddCircleOutline
            ? () => handleNavigate("/legalentity-add")
            : button.icon === MdOutlineFileDownload 
            ? handleDownload
            : button.icon === IoSettingsOutline
            ? handleSettingsButtonClick
            : button.icon === IoMdRefresh
            ? handleReload
            : button.onClick || (() => {}),
      }));
      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, 
          onClick: selectedRows.length > 0 ? button.onClick : null, 
        }))
      );
    
      
      const handleNavigate = (path) => {
        navigate(path);
      };
      const handleSelectRow = (id) => {
        handleCheckboxChange(id);
      };
      const handleSelectAll = () => {
        handleSelectAllChange();
      };
      const handleDoubleClick = (id) => {
        navigate(`/legalentity-view/${id}`, { state: { mode: "view" } });
      };
      const handleCheckboxChange = (id) => {
        const updatedSelectedRows = selectedRows.includes(id)
          ? selectedRows.filter((rowId) => rowId !== id)
          : [...selectedRows, id];
    
        setSelectedRows(updatedSelectedRows);
        setLegalEntityId(id);
        setShowExtraIcons(updatedSelectedRows.length > 0);
      };
    const handleUpdateColumnSettings = (updatedSettings) => {
        setColumnSettings(updatedSettings);
      };
    
   
    const handleSelectAllChange = () => {
        if (isAllSelected) {
          setSelectedRows([]);
        } else {
          const allIds = legalEntity.map((item) => item.id);
          setSelectedRows(allIds);
        }
        setIsAllSelected(!isAllSelected);
      };
      if (loading)
        return (
          <div>
          </div>
        );
  return (
    <div>
        <Header
        title={"Primary Data > Bank&Company masterData > LegalEntity"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <div>
        <div className="flex justify-between -ml-1">
          <PrimaryActions
            // buttons={[...existingButtons]}
            buttons={actionButtons} 
            selectedCount={selectedRows.length} 

            // extraButtons={extraButtons}
            isExtraButtonsVisible={showExtraIcons}
          />
          <div className="flex items-center">
            <p className="mr-4 text-customBlue">LGE Code :</p>

            <SearchInput 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            placeholder="Search by LGE Code" 
          />
          </div>
        </div>
        </div>
        <ReTable
          data={filteredlegalEntity}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
     
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
    </div>
  )
}
