import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Dashboard from "../../../components/Dashboard";
import MainHeader from "../../../components/MainHeader";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { MdCurrencyYen } from "react-icons/md";
import { MdOutlineFileUpload } from "react-icons/md";
import { AiFillBank } from "react-icons/ai";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { IoGitBranchOutline } from "react-icons/io5";
import { useSearch } from "../../../context/SearchContext";
import UserService from "../../../rest-services/UserServices";
import { useToast } from "../../../components/toast/toast";

export default function BamScreen() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const { highlightInfo } = useSearch();
  const [highlightedCard, setHighlightedCard] = useState(null);
  const navigate = useNavigate();
const { toast } = useToast();

  const [assignee, setAssignee] = useState({ id: "", username: "" });

  const [hasPermissionForAccountStatus,SethasPermissionForAccountStatus]= useState("");
const [hasPermissionForAccountNoLogic,SethasPermissionForAccountNoLogic]= useState("");
const [hasPermissionForZBAType,SethasPermissionForZBAType]= useState("");
const [hasPermissionForDBArea,SethasPermissionForDBArea]= useState("");
const [hasPermissionForAccountTypes,SethasPermissionForAccountTypes]= useState("");
const [hasPermissionForAccountClassification,SethasPermissionForAccountClassification]= useState("");
const [hasPermissionForBankAccountMaster,SethasPermissionForBankAccountMaster]= useState("");

  useEffect(() => {
      fetchAssignee();
    }, []);
  
    const fetchAssignee = async () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
  
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            id: userDetail.id || "",
            assigner: userDetail.username || "",
            clientId: userDetail.clientId || "",
            assignerId: userDetail.userId || "",
          };
          console.log("assigneeData is", assigneeData);
  
          setAssignee(assigneeData);
  
          // Log the id and assigner values to the console
          console.log("Assignee ID:", assigneeData.id);
          console.log("Assigner:", assigneeData.assigner);
          console.log("Assigner userId:", assigneeData.assignerId);
          console.log("Assigner clientId:", assigneeData.clientId);
        } else {
          console.error({
            severity: "error",
            message: "No user detail found in session storage.",
          });
        }
      } catch (error) {
        console.error({
          severity: "error",
          message: `Error fetching user details: ${error.message}`,
        });
      }
    };

    useEffect(() => {
      if (assignee.assignerId) {
        console.log("Assigner ID has changed:", assignee.assignerId);
        fetchUserIconAccessPermission();
      }
    }, [assignee.assignerId]);

    const fetchUserIconAccessPermission = async () => {
      try {
        const data = { userId: assignee.assignerId };
        // console.log("Request Data:", data);
  
        const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-Icon-access-permission-by-userId`,
          data
        );
        const responseData = response.data;
        console.log("user icon permissions details", responseData);  
        
        const hasPermissionForAccountStatus = responseData.some(
          (Icon) => Icon.iconId == 21 && Icon.isChecked == 1
        );
      
        const hasPermissionForAccountNoLogic = responseData.some(
          (Icon) => Icon.iconId == 22 && Icon.isChecked == 1
        );

        const hasPermissionForZBAType = responseData.some(
          (Icon) => Icon.iconId == 23 && Icon.isChecked == 1
        );

        const hasPermissionForDBArea = responseData.some(
          (Icon) => Icon.iconId == 24 && Icon.isChecked == 1
        );

        const hasPermissionForAccountTypes = responseData.some(
          (Icon) => Icon.iconId == 25 && Icon.isChecked == 1
        );

        const hasPermissionForAccountClassification = responseData.some(
          (Icon) => Icon.iconId == 135 && Icon.isChecked == 1
        ); 

        const hasPermissionForBankAccountMaster = responseData.some(
          (Icon) => Icon.iconId == 26 && Icon.isChecked == 1
        );

        

        SethasPermissionForAccountStatus(hasPermissionForAccountStatus);
        SethasPermissionForAccountNoLogic(hasPermissionForAccountNoLogic);
        SethasPermissionForZBAType(hasPermissionForZBAType);
        SethasPermissionForDBArea(hasPermissionForDBArea);
        SethasPermissionForAccountTypes(hasPermissionForAccountTypes);
        SethasPermissionForAccountClassification(hasPermissionForAccountClassification);
        SethasPermissionForBankAccountMaster(hasPermissionForBankAccountMaster);
      
      } catch (error) {
        console.error(error.message); // Set error message
      }
    };

    const AccountStatusPermission = async () => {
      toast("error", "You don't have permission to view this Account Status icon.");
    };

    const AccountNoLogicPermission = async () => {
      toast("error", "You don't have permission to view this Account Number Logic icon.");
    };

    const ZBATypePermission = async () => {
      toast("error", "You don't have permission to view this ZBA Type icon.");
    };

    const DBAreaPermission = async () => {
      toast("error", "You don't have permission to view this DB Area icon.");
    };

    const AccountTypesPermission = async () => {
      toast("error", "You don't have permission to view this Account Types icon.");
    };
    const AccountClassificationPermission = async () => {
      toast("error", "You don't have permission to view this Account Classification icon.");
    };
    const BankAccountMasterPermission = async () => {
      toast("error", "You don't have permission to view this Bank Account Master icon.");
    };
  // Handle search highlighting
  useEffect(() => {
    if (highlightInfo?.dashboard === 'bam') {
      const term = highlightInfo.term.toLowerCase();
      const cardTitles = [
        "account status", "account number logic", "zba types", "db area",
        "account types", "account classification", "account master"
      ];
      
      const matchedTitle = cardTitles.find(title => 
        title.toLowerCase().includes(term) || term.includes(title.toLowerCase())
      );

      if (matchedTitle) {
        setHighlightedCard(matchedTitle);
        const timer = setTimeout(() => {
          setHighlightedCard(null);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [highlightInfo]);

  useEffect(() => {
    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    // Fetch sub-menu data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(menu => menu.mainMenuId === 3);
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    // Fetch module assignment data
    if (userId) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
        .then((response) => {
          const filteredAssignments = response.data.filter(
            (assignment) =>
              assignment.userId === String(userId) && assignment.isChecked === true
          );
          setModuleAssignments(filteredAssignments);
        })
        .catch((error) => console.error("Error fetching module assignments:", error));
    }
  }, [userId]);

  const handleCardClick = (route) => {
    navigate(route);
  };

  // Move the sections creation logic to a separate function
  const createSections = (menus, highlighted) => {
    return menus.map((menu) => {
      let cards;

      if (menu.name === "Set up") {
        cards = [
          {
            title: "Account Status",
            icon: <BsFillPersonLinesFill />,
            isHighlighted: highlighted === "account status",
            onClick: hasPermissionForAccountStatus === false
            ? AccountStatusPermission
            : () => handleCardClick("/3/BAM/21/account-status"),
          },
          {
            title: "Account Number Logic",
            icon: <HiOutlineClipboardCheck />,
            isHighlighted: highlighted === "account number logic",
            onClick: hasPermissionForAccountNoLogic === false
            ? AccountNoLogicPermission
            : () => handleCardClick("/3/BAM/22/account-number-logic"),
          },
          {
            title: "ZBA Types",
            icon: <MdCurrencyYen />,
            isHighlighted: highlighted === "zba types",
            onClick: hasPermissionForZBAType === false
            ? ZBATypePermission
            : () => handleCardClick("/3/BAM/23/ZBA-types"),
          },
          // {
          //   title: "DB Area",
          //   icon: <MdOutlineFileUpload />,
          //   isHighlighted: highlighted === "db area",
          //   onClick: hasPermissionForDBArea=== false
          //   ? DBAreaPermission
          //   : () => handleCardClick("/3/BAM/24/DB-area"),
          // },
          {
            title: "Account Types",
            icon: <AiFillBank />,
            isHighlighted: highlighted === "account types",
            onClick: hasPermissionForAccountTypes === false
            ? AccountTypesPermission
            : () => handleCardClick("/3/BAM/25/account-types"),
          },
          {
            title: "Account Classification",
            icon: <IoGitBranchOutline />,
            isHighlighted: highlighted === "account classification",
            onClick: hasPermissionForAccountClassification === false
            ? AccountClassificationPermission
            : () => handleCardClick("/3/BAM/135/account-classfication"),
          },
        ];
      } else if (menu.name === "Bank Master Data") {
        cards = [
          {
            title: "Bank Account Master",
            icon: <BsFillJournalBookmarkFill />,
            isHighlighted: highlighted === "account master",
            onClick: hasPermissionForBankAccountMaster === false
            ? BankAccountMasterPermission
            : () => handleCardClick("/3/BAM/26/account-master"),
          },
        ];
      } else {
        cards = [];
      }

      return {
        title: menu.name,
        subMenuId: menu.id,
        cards: cards,
      };
    });
  };

  // Filter subMenus based on moduleAssignments
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  // Define an order for submenus to control their display order
  const submenuOrder = ["Set up", "Bank Master Data"];

  // Sort the filteredSections by the defined submenuOrder
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  // Create sections with current highlighted card
  const sections = createSections(orderedSections, highlightedCard);

  return (
    <div>
      <MainHeader title={"BAM"} />
      <Dashboard sections={sections} />
    </div>
  );
}