import React, { useEffect, useRef, useState } from "react";
import Header from "../../../../components/Header";
import { FiWind } from "react-icons/fi";
import { Checkbox } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BiDroplet } from "react-icons/bi";
import { RiDropdownList } from "react-icons/ri";
import { IoIosList } from "react-icons/io";
import axios from "axios";
import { useToast } from "../../../../components/toast/toast";
import Mt940Service from "../../../../rest-services/Mt940Services";
import CustomeUpLoader from "../../../../components/CustomeLoader";
import CustomeLoading from "../../../../components/CustomeLoading";
import CustomDropdown from "../../../../components/CustomDropdown";
import Footer from "../../../../components/footer";
import CustomButton from "../../../../components/CustomButton";
import HoverButton from "../../../../components/HoverButton";
import GlobalProcessServices from "../../../../rest-services/GlobalProcessServices";

export default function AddMT940() {
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [processName, setProcessName] = useState("");
  const [statementType, setStatementType] = useState("");
  const [accountIdCheck, setAccountIdCheck] = useState("0");
  const [ttoBsCheck, setTtoBsCheck] = useState("0");
  const [runFlowCheck, setRunFlowCheck] = useState("0");
  const [runAccountCheck, setRunAccountCheck] = useState("0");
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath("test folder")
    fetchAssignee();
  }, []);





  const handleFileChange = (e) => {
    e.preventDefault();

    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length > 0) {
      const firstFile = selectedFiles[0];
      const folderPath = firstFile.webkitRelativePath.split("/")[0];

      console.log("Folder Path:", folderPath);
      setPath(folderPath);
      const finFiles = selectedFiles.filter(
        (file) => file.name.endsWith(".fin") || file.name.endsWith(".txt")
      );

      if (finFiles.length === 0) {
        console.error("No .fin or .txt files found in the folder.");
        return;
      }

      setFiles(finFiles);
    }
  };


  const handleSave = async (action) => {
    try {
      if (!validateForm()) return;
      setLoading(true);
      let data;
      let metaPath;
      if (files.length > 0) {
        const firstFile = files[0];
        const relativePath = firstFile.webkitRelativePath;
        const lastSlashIndex = relativePath.lastIndexOf("/");
        metaPath = `application/downloads/${relativePath.substring(0, lastSlashIndex)}`;
      }
      data = {
        clientId: assignee.clientId,
        processName: processName,
        statementType: statementType,
        accountIdCheck: accountIdCheck,
        ttoBsCheck: ttoBsCheck,
        runFlowCheck: runFlowCheck,
        runAccountCheck: runAccountCheck,
        createdBy: assignee.userId,
        MetaPath: metaPath,
      }

      const response = await Mt940Service.processSave(data);
      if (response.status === 200) {
        setLoading(false);
        toast("success", "Files uploaded successfully");
      }
    } catch (err) {
      console.error("Upload failed: ", err);
      setLoading(false);
      toast("error", "File upload failed");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!validateForm()) return;
    setLoading(true);
    let data = {};
    if (files.length > 0) {
      const firstFile = files[0];
      const relativePath = firstFile.webkitRelativePath;
      const lastSlashIndex = relativePath.lastIndexOf("/");
      const metaPath = `application/downloads/${relativePath.substring(0, lastSlashIndex)}`;

      data = {
        MetaPath: metaPath, // Add MetaPath if available
      };
    }

    // Merge the common properties while keeping the MetaPath if it was set earlier
    data = {
      ...data, // Preserve existing properties (like MetaPath)
      clientId: assignee.clientId,
      processName: processName,
      statementType: statementType,
      accountIdCheck: accountIdCheck,
      ttoBsCheck: ttoBsCheck,
      runFlowCheck: runFlowCheck,
      runAccountCheck: runAccountCheck,
      createdBy: assignee.userId,
    };

    try {
      const response = await Mt940Service.updateProcess(data, 11, 47);
      if (response.status === 200) {
        setLoading(false);
        toast("success", "Files uploaded successfully");
      }
    } catch (err) {
      console.error("Upload failed: ", err);
      setLoading(false);
      toast("error", "File upload failed");
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    const chunkSize = 900;
    const totalChunks = Math.ceil(files.length / chunkSize);

    const uploadChunk = async (chunk, chunkIndex) => {
      const formData = new FormData();

      if (chunk.length > 0) {
        const firstFile = chunk[0];
        const relativePath = firstFile.webkitRelativePath;

        const lastSlashIndex = relativePath.lastIndexOf("/");
        const metaPath = `application/downloads/${relativePath.substring(0, lastSlashIndex)}`;

        formData.append("MetaPath", metaPath);
      }

      chunk.forEach((file) => {
        formData.append("files", file);
        const modifiedPath = `application/downloads/${file.webkitRelativePath}`;
        formData.append("filePaths", modifiedPath);
      });

      formData.append("clientId", assignee.clientId);
      formData.append("processName", processName);
      formData.append("statementType", statementType);
      formData.append("accountIdCheck", accountIdCheck);
      formData.append("ttoBsCheck", ttoBsCheck);
      formData.append("runFlowCheck", runFlowCheck);
      formData.append("runAccountCheck", runAccountCheck);
      formData.append("createdBy", assignee.userId);

      try {
        const response = await Mt940Service.uploadMt940(formData);
        console.log(`Chunk ${chunkIndex + 1}/${totalChunks}:`, response);

        if (response.includes("Files uploaded")) {
          // toast("success", `Chunk ${chunkIndex + 1} uploaded successfully`);
        }
      } catch (err) {
        console.error(`Upload failed for chunk ${chunkIndex + 1}: `, err);
        toast("error", `File upload failed for chunk ${chunkIndex + 1}`);
      }
    };

    // Split files into chunks of 900 and upload them sequentially
    for (let i = 0; i < files.length; i += chunkSize) {
      const fileChunk = files.slice(i, i + chunkSize);
      await uploadChunk(fileChunk, i / chunkSize);
    }

    toast("success", `File uploaded successfully`);

    setLoading(false);
  };


  // const handleSubmit = async () => {
  //   // if (!validateForm()) return;
  //   setLoading(true);
  //   const formData = new FormData();
  //   if (files.length > 0) {
  //     const firstFile = files[0];
  //     const relativePath = firstFile.webkitRelativePath;

  //     const lastSlashIndex = relativePath.lastIndexOf("/");
  //     const metaPath = `application/downloads/${relativePath.substring(0, lastSlashIndex)}`;

  //     formData.append("MetaPath", metaPath);
  //   }
  //   files.forEach((file) => {
  //     formData.append("files", file);

  //     const modifiedPath = `application/downloads/${file.webkitRelativePath}`;
  //     formData.append("filePaths", modifiedPath);
  //   });
  //   formData.append("clientId", assignee.clientId);
  //   formData.append("processName", processName);
  //   formData.append("statementType", statementType);
  //   formData.append("accountIdCheck", accountIdCheck);
  //   formData.append("ttoBsCheck", ttoBsCheck);
  //   formData.append("runFlowCheck", runFlowCheck);
  //   formData.append("runAccountCheck", runAccountCheck);
  //   formData.append("createdBy", assignee.userId);

  //   try {
  //     const response = await Mt940Service.uploadMt940(formData);
  //     console.log(response);

  //     if (response.includes("Files uploaded")) {
  //       setLoading(false);
  //       toast("success", "Files uploaded successfully");
  //     }
  //   } catch (err) {
  //     console.error("Upload failed: ", err);
  //     setLoading(false);
  //     toast("error", "File upload failed");
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const location = useLocation();
  const id = location.state?.id;



  useEffect(() => {
    if (id) fetchDataForField();
  }, [id]);

  const fetchDataForField = async () => {
    try {
      const response = await GlobalProcessServices.getProcessDetailsById(id, 11, 47);
      if (response.status === 200) {
        const apiData = response.data.data;
        const processName = apiData.processName || "";
        setProcessName(processName);
        const extractValues = (fieldName) =>
          apiData.processListDetails
            .filter((item) => item.fieldName === fieldName)
            .map((item) => item.value) || [];
        const statementype = extractValues("statementType")[0] || "";
        const accountidcheck = extractValues("accountIdCheck")[0] || "";
        const ttobscheck = extractValues("ttoBsCheck")[0] || ""
        const runFlowCheck = extractValues("runFlowCheck")[0] || ""
        const runAccountCheck = extractValues("runAccountCheck")[0] || ""

        setStatementType(statementype)
        setAccountIdCheck(accountidcheck)
        setTtoBsCheck(ttobscheck)
        setRunAccountCheck(runAccountCheck)
        setRunFlowCheck(runFlowCheck)

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClick = (e) => {
    fileInputRef.current.click();
  };
  const handleCheckboxChange = (setter) => (e) => {
    setter(e.target.checked ? "1" : "0");
  };

  const handleIconClick = () => {
    navigate("/bs-process-list", { state: { iconId: "47", subModule: "11" } });
  };

  const handleButtonClick = (path) => {
    console.log(`Button clicked: ${path}`);
  };
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { console.log(error) }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!processName) newErrors.processName = "Process Name is required.";
    if (!statementType)
      newErrors.statementFormat = "Statement Format is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const statmentFormatOptions = [
    { value: "0", label: "940 WHF" },
    { value: "1", label: "940 HF" },
    // { value: "3", label: "Portal" },
  ];
  const [openDropdown, setOpenDropdown] = useState(null);
  const handleToggle = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
  };
  const handleChange = (value) => {
    setStatementType(value,
    );
    setOpenDropdown(false)
  }
  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "Process") {
      navigate("/11/mt940/49/add-statement-analyser");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  return (
    <div>
      <Header title={"Bank Statements > MT940 > MT940 Uploads"} onBreadcrumbClick={handleBreadcrumbClick} backRoute={"/bank-statements"} />
      <div className="p-8 pr-2">
        <div className="flex flex-col space-y-4 col-span-1 p-4 border  rounded-md">
          <label className="flex items-center">
            <span className="w-48 font-small text-customGray text-xs">Process Name</span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={processName}
              onChange={(e) => setProcessName(e.target.value)}
              className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 text-xs text-customGray focus:outline-none hover:border-blue-400 ml-8`}
              required
            />
            <button onClick={handleIconClick}>
              <IoIosList className="ml-6 border  h-6 w-6  hover hover:border-customBlue" />
            </button>
          </label>
          {errors.ntMainHead && (
            <p className="text-red-500 text-sm mt-1">{errors.ntMainHead}</p>
          )}

          <label className="flex items-center mt-4">
            <span className="w-48 font-small text-xs text-left text-customGray">
              Statement Format<span className="text-red-500"> *</span>
            </span>
            <span className="ml-4">:</span>
            <CustomDropdown
              value={
                statmentFormatOptions.find(
                  (option) => option.value === statementType
                ) || null
              }

              options={statmentFormatOptions}
              onChange={(value) => handleChange(value)}
              isOpen={openDropdown === "format"}
              onToggle={() => handleToggle("format")}
              className={` ml-8 ${errors.statementFormat
                ? "border-red-500 p-1.5 hover:border-red-500"
                : "border-gray-300 hover:border-blue-400"
                } `}
              placeholder="Select Statement Format"
              width='w-80'
              height='h-8'
            />
          </label>

          <div className="grid grid-cols-2 gap-4">
            <label className="flex items-center">
              <span className="w-48 font-small text-xs text-gray-500">File path</span>
              <span className="ml-4">:</span>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                webkitdirectory="true"
                directory="true"
                multiple
              />
              <button
                type="button"
                onClick={() => handleClick()}
                className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"
                  } p-2 w-80 h-10 focus:outline-none hover:border-blue-400 ml-8 rounded-full text-xs text-customGray cursor-pointer text-center`}
              >
                {files.length === 0
                  ? "Desktop"
                  : `application/downloads/${path}`}
              </button>
            </label>
            {errors.ntMainHead && (
              <p className="text-red-500 text-sm mt-1">{errors.ntMainHead}</p>
            )}

            <label className="flex items-center ml-4">
              <button
                type="button"
                onClick={() => handleButtonClick("Application Downloads")}
                className="border border-gray-300  p-2 lg:w-56 xl:w-80 h-10 focus:outline-none hover:border-blue-400 ml-8 rounded-full text-xs text-customGray cursor-pointer text-center"
              >
                Application
              </button>
            </label>
          </div>
        </div>

        {loading === true ? <CustomeLoading /> : null}

        <div className="col-span-1 border p-4 rounded-md mt-2">
          <div className="flex items-center">
            <p className="w-48  font-small text-xs text-customGray">
              Assign Bank Account ID
            </p><span className="ml-4">:</span>
            <Checkbox
              onChange={handleCheckboxChange(setAccountIdCheck)}
              checked={accountIdCheck === "1"}
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            />
          </div>

          <div className="flex items-center">
            <p className="w-48  font-small text-xs text-customGray">
              Transfer to BS
            </p><span className="ml-4">:</span>
            <Checkbox
              onChange={handleCheckboxChange(setTtoBsCheck)}
              checked={ttoBsCheck === "1"}
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            />
          </div>

          <div className="flex items-center">
            <p className="w-48 text-xs font-small text-gray-600">
              Run Flow Mapping
            </p><span className="ml-4">:</span>
            <Checkbox
              onChange={handleCheckboxChange(setRunFlowCheck)}
              checked={runFlowCheck === "1"}
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            />
          </div>

          <div className="flex items-center">
            <p className="w-48 text-xs font-small text-gray-600">
              Run Account Mapping
            </p><span className="ml-4">:</span>
            <Checkbox
              onChange={handleCheckboxChange(setRunAccountCheck)}
              checked={runAccountCheck === "1"}
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75 accent-customBlue"
            />
          </div>
        </div>
      </div>

      <div className="mt-10">
        <Footer>
          <div>
            {!id && <CustomButton
              onClick={() => { handleSave(1) }}
              title={"Save"}
              className="mr-2"

            />}
            {!id && <HoverButton
              title={"Save & Continue"}
              className={"mr-2"}
              onClick={() => { handleSave(2) }}
            />}
            {id && <CustomButton
              onClick={handleUpdate}
              title={"Save"}
              className="mr-2"

            />}
            <HoverButton
              title={"Run >"}
              className={"mr-2"}
              onClick={() => { handleSubmit() }}
            />
          </div>
        </Footer>
      </div>
    </div>
  );
}
