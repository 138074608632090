import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import UploadComponent from '../../../../../../components/UploadComponent';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import Footer from '../../../../../../components/footer';
import * as XLSX from "xlsx";
import { useToast } from '../../../../../../components/toast/toast';
import UserService from '../../../../../../rest-services/UserServices';

export default function CountryUpload() {
    const navigate = useNavigate();
const { iconId } = useParams();
const { toast } = useToast();
const [tableData, setTableData] = useState([]);
const [buttonText, setButtonText] = useState("Upload from desktop");
const [isUploaded, setIsUploaded] = useState(false);
const [displayOption, setDisplayOption] = useState(""); // Default value
const [uploadOption, setUploadOption] = useState(""); // Default value
const [isDisplayChecked, setIsDisplayChecked] = useState(false); // State for checkbox
const [isUploadChecked, setIsUploadChecked] = useState(false); // State for checkbox
const [isError, setIsError] = useState(false);
const [showConfirmation, setShowConfirmation] = useState(false);
const [assignee, setAssignee] = useState({ id: "", username: "" });
const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

// Allowed format (Replace with actual validation if needed)
const allowedFormat = ["Action","SLNo","CountryCode","CountryName","Language","DateFormat","TimeFormat","TimeZone","AmountFormat",
  // "ThousandSeparator","DecimalSeparator",
  "AcceleratorForThousands","AcceleratorForLakhs","AcceleratorForMillions"
]; 

  useEffect(() => {
    fetchAssignee();
  }, []);
      
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",

        };

        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };


    const cancelDeletion = () => {
    setShowConfirmation(false);
    };

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = (event) => {
    const data = new Uint8Array(event.target.result);
    const workbook = XLSX.read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0]; // Get first sheet
    const sheet = workbook.Sheets[sheetName];

    // Ensure we read only headers
    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    // console.log("Raw Sheet Data:", jsonData); // Debugging: Check the first row

    if (jsonData.length === 0) {
      setButtonText("File Format is Incorrect !");
      setIsUploaded(false);
      setIsError(true);
      return;
    }

    // Extract headers from the first row
    const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
    const expectedHeaders = allowedFormat.map(header => header.trim().toLowerCase());

    // console.log("Uploaded Headers:", uploadedHeaders);
    // console.log("Expected Headers:", expectedHeaders);

    // Validate headers
    const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));

    if (isValidFormat) {
      // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
      const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);

    // Convert Excel date serial numbers to readable dates
    const formattedRows = rows.map((row) => {
      const formattedRow = { ...row };

  
      return formattedRow;
    });
  

    console.log("set Table Data:", formattedRows);

    setTableData(formattedRows);
      setButtonText("File Uploaded");
      setIsUploaded(true);
      setIsError(false);
    } else {
      setButtonText("File Format is Incorrect !");
      setIsUploaded(false);
      setIsError(true);
      setShowConfirmation(true);
    }

    setTimeout(() => setIsUploaded(false), 1000);
  };
  reader.readAsArrayBuffer(file);
};
          

    const confirmDeletion = async () => {
      console.log("Download the excel sheet");
      // Simulate the download by creating an anchor element
      const fileUrl = "/Country_Upload.xlsx"; 
      console.log("file ur is",fileUrl);
      const anchor = document.createElement("a");
      anchor.href = fileUrl;
      anchor.download = "Country_Upload.xlsx"; // Set the file name for download
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }

    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === "Global Data") {
          navigate("/2/master-data");
        } else if (crumb.title === "Country") {
          navigate("/2/master-data/1/country");
        } else if (crumb.title === "Primary Data") {
          console.log("Navigating to /country");
          navigate("/2/master-data");
        } else if (crumb.path) {
          navigate(crumb.path); // Navigate to the path specified in the crumb
        }
      };

      const isRowValid = (row, index) => {
        // Common validations
        const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
        const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
        
        const isLanguageValid = row.Language ? String(row.Language).length <= 2 : true; 
        const isDateFormatValid = row.DateFormat ? String(row.DateFormat).length <= 10 : true;
        const isTimeFormatValid = row.TimeFormat ? String(row.TimeFormat).length <= 8 : true;
        const isAmountFormatValid = row.AmountFormat ? String(row.AmountFormat).length <= 19 : true;
        // const isThousandSeparatorValid = row.ThousandSeparator ? [".", ","].includes(row.ThousandSeparator) && String(row.ThousandSeparator).length === 1 : true;
        // const isDecimalSeparatorValid = row.DecimalSeparator ? [".", ","].includes(row.DecimalSeparator) && String(row.DecimalSeparator).length === 1 : true;
        const isAcceleratorForThousandsValid = row.AcceleratorForThousands ? ["K", "T"].includes(row.AcceleratorForThousands) && String(row.AcceleratorForThousands).length === 1 : true;
        const isAcceleratorForLakhsValid = row.AcceleratorForLakhs ? ["L"].includes(row.AcceleratorForLakhs) && String(row.AcceleratorForLakhs).length === 1 : true;
        const isAcceleratorForMillionsValid = row.AcceleratorForMillions ? ["M"].includes(row.AcceleratorForMillions) && String(row.AcceleratorForMillions).length === 1 : true;
  
        let errors = [];
        
        if (!isActionValid) errors.push("Invalid Action");
        if (!isSLNoValid) errors.push("Invalid SLNo");
      
        if (!isLanguageValid) errors.push("Invalid Language");
        if (!isDateFormatValid) errors.push("Invalid Date format");
        if (!isAmountFormatValid) errors.push("Invalid amount format");
        if (!isTimeFormatValid) errors.push("Invalid time format");
        // if (!isThousandSeparatorValid) errors.push("Invalid thousandseparator");
        // if (!isDecimalSeparatorValid) errors.push("Invalid decimal separator");
        if (!isAcceleratorForThousandsValid) errors.push("Invalid accelarator of thousands");
        if (!isAcceleratorForLakhsValid) errors.push("Invalid accelarator of lakhs");
        if (!isAcceleratorForMillionsValid) errors.push("Invalid accelarator of millions");
  
        // Action "A" or "a" specific validations
        if (row.Action === "A" || row.Action === "a") {
          const isCountryCodeValid = row.CountryCode && String(row.CountryCode).length <= 2;
         
          const isCountryNameValid = row.CountryName && String(row.CountryName).length <= 30;

          if (!isCountryNameValid) errors.push("Invalid Country name");
          if (!isCountryCodeValid) errors.push("Invalid A Country code");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
          return (
            isCountryNameValid &&
          isCountryCodeValid  
          );
        }
      
        // Action "E" or "e" specific validations
        if (row.Action === "E" || row.Action === "e") {
          const isCountryCodeValid = row.CountryCode && String(row.CountryCode).length <= 2;
          const isCountryNameValid = row.CountryName ? String(row.CountryName).length <= 30 : true;

          if (!isCountryNameValid) errors.push("Invalid Country name");
          if (!isCountryCodeValid) errors.push("Invalid E Country code");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
  
          return (
            isCountryNameValid &&
            isCountryCodeValid   );
        }
      
        
        if (errors.length > 0) {
          console.log(`Rows data ${index + 1} is invalid:`, errors);
      }
  
        // Default case if no matching Action
        return (
          isActionValid &&
          isSLNoValid &&
           
          isLanguageValid &&
          isDateFormatValid &&
          isAmountFormatValid &&
          isTimeFormatValid 
          &&
          // isThousandSeparatorValid &&
          //   isDecimalSeparatorValid &&
            isAcceleratorForThousandsValid &&
            isAcceleratorForLakhsValid &&
            isAcceleratorForMillionsValid
         
        );
      
      };

      const getExistingUserIds = async (tableData) => {
        try {
          // console.log("userIds", tableData);
    
          const validRows = tableData.filter((row, index) =>  isRowValid(row, index));
          console.log("valid rows checking in upload:", validRows);
            
    
          // Make API call to fetch existing user IDs
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-Countrys-duplicate/countryCode`,
            { validRows }
          );
          console.log("errored records user id's : Response data", response.data);
          const existingUserIds = response.data; // Extract existing user IDs from API response
    
          const withoutDuplicateUserIds = await filterExistingUserIds(validRows, existingUserIds);
    
          console.log("successful and processed Records data:", withoutDuplicateUserIds);
    
          return withoutDuplicateUserIds;
    
        } catch (error) {
          console.error("Error fetching existing user IDs:", error);
          return [];
        }
      };
    
    
      const filterExistingUserIds = async (tableData, existingUserIds) => {
        return tableData.filter((row) => existingUserIds.includes(row.CountryCode));
      };

      const getDataToDisplay = async ( uploadOption, tableData) => {
    
        let dataToDisplay;
        if (uploadOption === "3") {
        console.log("displayOption is 3");
        dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
        } else {
        dataToDisplay = tableData; // Default to tableData if no conditions are met
        }
        
      console.log("dataToDisplay data is",dataToDisplay);
      return dataToDisplay;
    };

    
const isExistingUserId = async (CountryCode) => {
  try {
    console.log("exisitng user id",CountryCode);
    const data = { CountryCode };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-CountryCode-duplicates`,
      data);
    console.log("existingUserId details:", response);

    // return response && response.UserID !== null; // Return true if userId exists, false otherwise
    return response?.CountryID ? response : false; // Return response only if UserID exists
  } catch (error) {
    console.error("Error checking existing userId:", CountryCode, error);
    return false;
  }
};


async function checkDuplicateUserIdsFromDB(countrys) {
  try {
    const data = { countrys };
    // console.log("countrys duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-Countries-duplicates`,
      data
    );

    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}

    const processData = async (data) => {
  
      // console.log("processData received  data:", data);
      const countrys = data.map(row => row.CountryCode).filter(Boolean);
      console.log("Extracted countrys:", countrys);
    
      const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(countrys);
    
       // Preprocess table data to add conditional rendering for userId
        return Promise.all(data.map(async (row, _, allRows) => {
    
      // Check for duplicate same userId is repeated in the data
      const isDuplicateUserId = allRows.filter(r => r.CountryCode == row.CountryCode).length > 1;
      console.log("Duplicate user id is ",isDuplicateUserId);
    
     // Check for duplicate userId in the database
     const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.CountryCode);
    
     const userExists = await isExistingUserId(row.CountryCode);
     console.log(`User ${row.CountryCode} exists:`, userExists);
     // Check for errors (red text) and apply highlight based on that
    
     const hasNotError = [
      row.SLNo,
      row.Action,
      row.CountryCode,
      row.CountryName,

      row.Language,
      row.DateFormat,
      row.TimeFormat,
      row.AmountFormat,
      // row.ThousandSeparator,
      // row.DecimalSeparator,
      row.AcceleratorForThousands,
      row.AcceleratorForLakhs,
      row.AcceleratorForMillions,
    
    ].every(field => {
      let isValid = true;
      let reason = "";
      
      if (field === row.SLNo) {
        if (!field) { isValid = false; reason = "SLNo should not be blank."; }
        else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
        else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
      }
      
      else if (field === row.Action) {
        if (!field) { isValid = false; reason = "Action should not be blank."; }
        else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
      }
      
  if (!row.CountryCode || String(row.CountryCode).trim() === "") {
    if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
      isValid = false;
      reason = "CountryCode should not be empty.";
  }
  } else if (field === row.CountryCode) {
    if (String(field).length > 2) { 
      isValid = false; reason = "Country Code exceeds max length of 2."; 
    }
    else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
      isValid = false; reason = "Country Code does not exist.";
    } 
    else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
      isValid = false; reason = "Country Code is duplicate.";
    }
  }
      
      
      if (!row.CountryName || String(row.CountryName).trim() === "") {
        if (row.Action === "A" || row.Action === "a") {
          isValid = false;
          reason = "CountryName should not be empty.";
      }
      } else if (field === row.CountryName) {
          if (row.Action === "E" || row.Action === "e") {
              if (!field.trim()) {
                  isValid = true;
                  reason = "CountryName should be blank for Edit action.";
              } else if (String(field).trim().length > 30) {
                  isValid = false;
                  reason = "CountryName exceeds max length of 30.";
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (!field.trim()) {
                  isValid = false;
                  reason = "CountryName should not be blank for Add action.";
              } else if (String(field).trim().length > 30) {
                  isValid = false;
                  reason = "CountryName exceeds max length of 30.";
              }
          }
      }
    
    
      else if (field === row.Language) {
        if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
          if (!field) { isValid = true; reason = "Language should be blank for Add/Edit action."; }
          else if (String(field).length > 2) { isValid = false; reason = "Language exceeds max length of 2."; }
        }
      }


  // if (!row.Language || String(row.Language).trim() === "") {
  //   if (row.Action === "A" || row.Action === "a") {
  //     isValid = false;
  //     reason = "Language should not be empty.";
  // }
  // } else if (field === row.Language) {
  //     if (row.Action === "E" || row.Action === "e") {
  //         if (!field.trim()) {
  //             isValid = true;
  //             reason = "Language should be blank for Edit action.";
  //         } else if (String(field).trim().length > 2) {
  //             isValid = false;
  //             reason = "Language exceeds max length of 2.";
  //         }
  //     } else if (row.Action === "A" || row.Action === "a") {
  //         if (!field.trim()) {
  //             isValid = false;
  //             reason = "Language should not be blank for Add action.";
  //         } else if (String(field).trim().length > 2) {
  //             isValid = false;
  //             reason = "Language exceeds max length of 2.";
  //         }
  //     }
  // }
    

  else if (field === row.DateFormat) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "DateFormat should be blank for Add/Edit action."; }
      else if (String(field).length > 10) { isValid = false; reason = "DateFormat exceeds max length of 10."; }
    }
  }

  // if (!row.DateFormat || String(row.DateFormat).trim() === "") {
  //   if (row.Action === "A" || row.Action === "a") {
  //     isValid = false;
  //     reason = "DateFormat should not be empty.";
  // }
  // } else if (field === row.DateFormat) {
  //     if (row.Action === "E" || row.Action === "e") {
  //         if (!field.trim()) {
  //             isValid = true;
  //             reason = "DateFormat should be blank for Edit action.";
  //         } else if (String(field).trim().length > 10) {
  //             isValid = false;
  //             reason = "DateFormat exceeds max length of 10.";
  //         }
  //     } else if (row.Action === "A" || row.Action === "a") {
  //         if (!field.trim()) {
  //             isValid = false;
  //             reason = "DateFormat should not be blank for Add action.";
  //         } else if (String(field).trim().length > 10) {
  //             isValid = false;
  //             reason = "DateFormat exceeds max length of 10.";
  //         }
  //     }
  // }
    
  // if (!row.TimeFormat || String(row.TimeFormat).trim() === "") {
  //   if (row.Action === "A" || row.Action === "a") {
  //     isValid = false;
  //     reason = "TimeFormat should not be empty.";
  // }
  // } else if (field === row.TimeFormat) {
  //     if (row.Action === "E" || row.Action === "e") {
  //         if (!field.trim()) {
  //             isValid = true;
  //             reason = "TimeFormat should be blank for Edit action.";
  //         } else if (String(field).trim().length > 8) {
  //             isValid = false;
  //             reason = "TimeFormat exceeds max length of 8.";
  //         }
  //     } else if (row.Action === "A" || row.Action === "a") {
  //         if (!field.trim()) {
  //             isValid = false;
  //             reason = "TimeFormat should not be blank for Add action.";
  //         } else if (String(field).trim().length > 8) {
  //             isValid = false;
  //             reason = "TimeFormat exceeds max length of 8.";
  //         }
  //     }
  // }
  else if (field === row.TimeFormat) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "TimeFormat should be blank for Add/Edit action."; }
      else if (String(field).length > 8) { isValid = false; reason = "TimeFormat exceeds max length of 8."; }
    }
  }
  

  else if (field === row.AmountFormat) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AmountFormat should be blank for Add/Edit action."; }
      else if (String(field).length > 19) { isValid = false; reason = "AmountFormat exceeds max length of 19."; }
    }
  }

  // if (!row.AmountFormat || String(row.AmountFormat).trim() === "") {
  //   if (row.Action === "A" || row.Action === "a") {
  //     isValid = false;
  //     reason = "AmountFormat should not be empty.";
  // }
  // } else if (field === row.AmountFormat) {
  //     if (row.Action === "E" || row.Action === "e") {
  //         if (!field.trim()) {
  //             isValid = true;
  //             reason = "AmountFormat should be blank for Edit action.";
  //         } else if (String(field).trim().length > 19) {
  //             isValid = false;
  //             reason = "AmountFormat exceeds max length of 19.";
  //         }
  //     } else if (row.Action === "A" || row.Action === "a") {
  //         if (!field.trim()) {
  //             isValid = false;
  //             reason = "AmountFormat should not be blank for Add action.";
  //         } else if (String(field).trim().length > 19) {
  //             isValid = false;
  //             reason = "AmountFormat exceeds max length of 19.";
  //         }
  //     }
  // }

  
  // else if (field === row.ThousandSeparator) {
  //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //     if (!field) { isValid = true; reason = "thousandSeparator should be blank for Add/Edit action."; }
  //     else{
  //       if (String(field).length > 1) { isValid = false; reason = "thousandSeparator exceeds max length of 1."; }
  //       if (![",", "."].includes(field)) { isValid = false; reason = "Only ',' or '.' allowed."; }
  //       if (field === row.DecimalSeparator) { isValid = false; reason = "Thousand and decimal separators cannot be the same."; }
  //     }
  //   }
  // }

  // else if (field === row.DecimalSeparator) {
  //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //     if (!field) { isValid = true; reason = "decimalSeparator should be blank for Add/Edit action."; }
  //   else{
  //     if (String(field).length > 1) { isValid = false; reason = "decimalSeparator exceeds max length of 1."; }
  //     if (![",", "."].includes(field)) { isValid = false; reason = "Only ',' or '.' allowed."; }
  //     if (field === row.ThousandSeparator) { isValid = false; reason = "decimal and Thousand separators cannot be the same."; }
  //     }
  //     }
  // }

  else if (field === row.AcceleratorForThousands) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForThousands should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForThousands exceeds max length of 1."; }
      if (!["K", "T"].includes(String(field))) { isValid = false; reason = "Only 'K' or 'T' allowed."; }
      }
      }
  }

  else if (field === row.AcceleratorForLakhs) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForLakhs should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForLakhs exceeds max length of 1."; }
      if (!["L"].includes(String(field))) { isValid = false; reason = "Only 'L' allowed."; }
      }
      }
  }

  
  else if (field === row.AcceleratorForMillions) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!field) { isValid = true; reason = "AcceleratorForMillions should be blank for Add/Edit action."; }
    else{
      if (String(field).length > 1) { isValid = false; reason = "AcceleratorForMillions exceeds max length of 1."; }
      if (!["M"].includes(String(field))) { isValid = false; reason = "Only 'M' allowed."; }
      }
      }
  }

      if (!isValid) {
        console.log(`Validation Failed: ${reason}`);
      } else {
        console.log(`Validation Passed: ${field}`);
      }
      
      return isValid;
    });
    
    console.log(`Final hasNotError status: ${hasNotError}`);
    
        return {
          ...row,
          successfulrecords: hasNotError, // Mark as successful if no error
    
          isDuplicateUserId,
          isDuplicateInDB,
          userExists,
    
          Action: row.Action ? (
            String(row.Action).length === 1 ? (
              ["A", "a", "E", "e"].includes(row.Action) ? (
                row.Action // Valid Action ("A", "E", "a", "e")
              ) : (
                <span style={{ color: "red" }}>
                  {row.Action} (Only "A", "E" are allowed)
                </span>
              )
            ) : (
              <span style={{ color: "red" }}>
                {row.Action} (Max. allowed character is 1)
              </span>
            )
          ) : (
            <span style={{ color: "red" }}
            
            >Shouldn't be blank</span>
          ),
    
          SLNo: row.SLNo ? (
              isNaN(row.SLNo) ? (
                <span style={{ color: "red"}}>
                  {row.SLNo} (Mismatched Data Type)
                </span>
              ) : String(row.SLNo).length > 5 ? (
                <span style={{ color: "red"}}>
                  {row.SLNo} (Max. allowed character is 5)
                </span>
              ) : (
                row.SLNo // Valid SLNo
              )
            ) : (
              <span style={{ color: "red"}}>Shouldn't be blank</span>
            ),
            
    
            CountryCode: row.CountryCode ? (
              String(row.CountryCode).length > 2 ? (
                <span style={{ color: "red" }}>
                  {row.CountryCode} (Max. allowed character is 2)
                </span>
              ) : row.Action === "A" || row.Action === "a" ? (
                // When Action is "A", show duplicate message if user ID is a duplicate
                (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
                (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                  <span style={{ color: "red" }}>
                    {row.CountryCode} (Country Code Duplicate exists)
                  </span>
                ) : (
                  String(row.CountryCode) // Otherwise, show userId normally
                )
              ) : row.Action === "E" || row.Action === "e" ? (
                // When Action is "E", show userId without the duplicate message
                (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
                (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                  <span>
                    {row.CountryCode} 
                  </span>
                ) : (
                  // String(row.CountryCode) // Otherwise, show CountryCode normally
                  <span style={{ color: "red" }}>
                  {row.CountryCode} (Country Code not exists)
                </span>
                )
              ) : (
                String(row.CountryCode) // Default case, show userId normally
              )
            ) : (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ),
    
            
            CountryName: row.CountryName ? (
              String(row.CountryName).length > 30 ? (
                <span style={{ color: "red" }}>
                  {row.CountryName} (Max. allowed character is 30)
                </span>
              ) : (
                String(row.CountryName) // Ensure Username is treated as a string
              )
            ) : (
              row.Action === "A" || row.Action === "a" ? (
                <span style={{ color: "red" }}>
                  Shouldn't be blank
                </span>
              ) : row.Action === "E" || row.Action === "e" ? (
                <span></span> // Empty span for "E"
              ) : null // Handle other cases, if any
            ),
    
      
            Language: row.Language ? (
              String(row.Language).length > 3 ? (
                <span style={{ color: "red"}}>
                  {row.Language} (Max. allowed character is 3)
                </span>
              ) : (
                  String(row.Language) // Ensure userId is treated as a string
              )
            ) : (
              <span>
               
              </span>
            ),
            

            DateFormat: row.DateFormat ? (
              String(row.DateFormat).length > 10 ? (
                <span style={{ color: "red"}}>
                  {row.DateFormat} (Max. allowed character is 10)
                </span>
              ) : (
                  String(row.DateFormat) // Ensure userId is treated as a string
              )
            ) : (
              <span>
               
              </span>
            ),

            // DateFormat: row.DateFormat ? (
            //   String(row.DateFormat).length > 10 ? (
            //     <span style={{ color: "red"}}>
            //       {row.DateFormat} (Max. allowed character is 10)
            //     </span>
            //   ) : (
            //       String(row.DateFormat) // Ensure userId is treated as a string
            //   )
            // ) : (
            //   row.Action === "A" || row.Action === "a" ? (
            //     <span style={{ color: "red" }}>
            //       Shouldn't be blank
            //     </span>
            //   ) : row.Action === "E" || row.Action === "e" ? (
            //     <span></span> // Empty span for "E"
            //   ) : null // Handle other cases, if any
            // ),
      
            TimeFormat: row.TimeFormat ? (
              String(row.TimeFormat).length > 8 ? (
                <span style={{ color: "red"}}>
                  {row.TimeFormat} (Max. allowed character is 8)
                </span>
              ) : (
                  String(row.TimeFormat) // Ensure userId is treated as a string
              )
            ) : (
              <span>
               
              </span>
            ),

            // TimeFormat: row.TimeFormat ? (
            //   String(row.TimeFormat).length > 8 ? (
            //     <span style={{ color: "red"}}>
            //       {row.TimeFormat} (Max. allowed character is 8)
            //     </span>
            //   ) : (
            //       String(row.TimeFormat) // Ensure userId is treated as a string
            //   )
            // ) : (
            //   row.Action === "A" || row.Action === "a" ? (
            //     <span style={{ color: "red" }}>
            //       Shouldn't be blank
            //     </span>
            //   ) : row.Action === "E" || row.Action === "e" ? (
            //     <span></span> // Empty span for "E"
            //   ) : null // Handle other cases, if any
            // ),
      
            AmountFormat: row.AmountFormat ? (
              String(row.AmountFormat).length > 19 ? (
                <span style={{ color: "red"}}>
                  {row.AmountFormat} (Max. allowed character is 19)
                </span>
              ) : (
                  String(row.AmountFormat) // Ensure userId is treated as a string
              )
            ) : (
              <span>
               
              </span>
            ),

            // AmountFormat: row.AmountFormat ? (
            //   String(row.AmountFormat).length > 19 ? (
            //     <span style={{ color: "red"}}>
            //       {row.AmountFormat} (Max. allowed character is 19)
            //     </span>
            //   ) : (
            //       String(row.AmountFormat) // Ensure userId is treated as a string
            //   )
            // ) : (
            //   row.Action === "A" || row.Action === "a" ? (
            //     <span style={{ color: "red" }}>
            //       Shouldn't be blank
            //     </span>
            //   ) : row.Action === "E" || row.Action === "e" ? (
            //     <span></span> // Empty span for "E"
            //   ) : null // Handle other cases, if any
            // ),
      
            // ThousandSeparator: row.ThousandSeparator ? (
            //   String(row.ThousandSeparator).length > 1 ? (
            //       <span style={{ color: "red"}}>
            //       {row.ThousandSeparator} (Max. allowed character is 1)
            //       </span>
            //   ) : [",", "."].includes(row.ThousandSeparator) ? (
            //       row.ThousandSeparator === row.DecimalSeparator ? (
            //       <span style={{ color: "red"}}>
            //           Thousand and decimal separators cannot be the same.
            //       </span>
            //       ) : (
            //       row.ThousandSeparator // Valid case
            //       )
            //   ) : (
            //       <span style={{ color: "red"}}>
            //       {row.ThousandSeparator} (Only ',' or '.' allowed)
            //       </span>
            //   )
            //   ) : (
            //   <span>
                 
            //   </span>
            //   ),
      
            //   DecimalSeparator: row.DecimalSeparator ? (
            //   String(row.DecimalSeparator).length > 1 ? (
            //       <span style={{ color: "red"}}>
            //       {row.DecimalSeparator} (Max. allowed character is 1)
            //       </span>
            //   ) : [",", "."].includes(row.DecimalSeparator) ? (
            //       row.DecimalSeparator === row.ThousandSeparator ? (
            //       <span style={{ color: "red"}}>
            //           Thousand and decimal separators cannot be the same.
            //       </span>
            //       ) : (
            //       row.DecimalSeparator // Valid case
            //       )
            //   ) : (
            //       <span style={{ color: "red"}}>
            //       {row.DecimalSeparator} (Only ',' or '.' allowed)
            //       </span>
            //   )
            //   ) : (
            //   <span>
                
            //   </span>
            //   ),
      
           
              AcceleratorForThousands: row.AcceleratorForThousands ? ( 
                String(row.AcceleratorForThousands).length > 1 ? (
                    <span style={{ color: "red"}}>
                        {row.AcceleratorForThousands} (Max. allowed character is 1)
                    </span>
                ) : !["K", "T"].includes(String(row.AcceleratorForThousands)) ? (
                    <span style={{ color: "red"}}>
                        {row.AcceleratorForThousands} (Only 'K' or 'T' allowed)
                    </span>
                ) : (
                    <span>{row.AcceleratorForThousands}</span>
                )
            ) : (
                <span></span>
            ),
    
      
                AcceleratorForLakhs: row.AcceleratorForLakhs ? (
                  String(row.AcceleratorForLakhs).length > 1 ? (
                      <span style={{ color: "red"}}>
                      {row.AcceleratorForLakhs} (Max. allowed character is 1)
                      </span>
                  ) : !["L"].includes(String(row.AcceleratorForLakhs)) ? (
                      <span style={{ color: "red"}}>
                      {row.AcceleratorForLakhs} (Only 'L' allowed)
                      </span>
                  ): (
                    <span> {row.AcceleratorForLakhs} </span>
                  )
                  ) : (
                  <span>
                    
                  </span>
                  ),
    
                  AcceleratorForMillions: row.AcceleratorForMillions ? (
                    String(row.AcceleratorForMillions).length > 1 ? (
                        <span style={{ color: "red"}}>
                        {row.AcceleratorForMillions} (Max. allowed character is 1)
                        </span>
                    ) : !["M"].includes(String(row.AcceleratorForMillions)) ? (
                        <span style={{ color: "red"}}>
                        {row.AcceleratorForMillions} (Only 'M' allowed)
                        </span>
                    ) : (
                      <span> {row.AcceleratorForMillions}</span>
                    )
                    ) : (
                    <span>
                      
                    </span>
                    ),
        
          };  
      }));
    }

      const storeArrayDataForUsers= async () => {
        setLoading(true);
        try {
          const dataToDisplay = await getDataToDisplay(uploadOption, tableData);
        console.log("store successfulRecords data 2 is", dataToDisplay);
    
        const processed = await processData(tableData);
        console.log("display datas",processed);
    
          const extractText = (cellValue) => {
                  if (Array.isArray(cellValue)) {
                    return cellValue.join(", "); // Convert array to string
                  } else if (typeof cellValue === "string") {
                    return cellValue;
                  } else if (React.isValidElement(cellValue)) {
                    return cellValue.props.children;
                  }
                  return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                };
    
                const formattedUsers = processed.map((user) => {
    
                  return {
                  "Action": extractText(user.Action),
                  "SLNo": extractText(user.SLNo),
                  "CountryCode": extractText(user.CountryCode),
                  "CountryName": extractText(user.CountryName),
                  
                  "Language": extractText(user.Language || ""),
                  "TimeFormat": extractText(user.TimeFormat || ""),
                  "TimeZone": extractText(user.TimeZone || ""),
                  "DateFormat": extractText(user.DateFormat || ""),
                  "AmountFormat": extractText(user.AmountFormat || ""),
                  // "ThousandSeparator": extractText(user.ThousandSeparator),
                  // "DecimalSeparator": extractText(user.DecimalSeparator),
                  "AcceleratorForThousands": extractText(user.AcceleratorForThousands),
                  "AcceleratorForLakhs": extractText(user.AcceleratorForLakhs),
                  "AcceleratorForMillions": extractText(user.AcceleratorForMillions),
                };
        });
          
            // Insert second and third row at the beginning of the formatted data
            const rowsToDisplay = [ ...formattedUsers];
    
            console.log("Formatted USERS Data:", formattedUsers);
            console.log("Formatted Data:", rowsToDisplay);
    
            
            const updatedData = rowsToDisplay.map(user => {
    
              const isObjectField = ["Action",
    "SLNo",
    "CountryCode",
    "CountryName",

    "Language",
    "TimeFormat",
    "TimeZone",
    "DateFormat",
    "AmountFormat",
    // "ThousandSeparator",
    // "DecimalSeparator",
    "AcceleratorForThousands",
    "AcceleratorForLakhs",
    "AcceleratorForMillions"
    ].some(field => typeof user[field] === 'object' || user[field] === "Shouldn't be blank");
    
              
              return {
                // update: typeof user.UserID === 'object' ? "No" : "Yes",
                update: isObjectField ? "No" : "Yes",
                      ...user,
                    };
                });
    console.log("updatedData data",updatedData);
    
    
          const data = {
            clientId: assignee.clientId,
            dataToDisplay,
            createdBy: assignee.userId,
            allrecords: updatedData,
          }
          console.log("storeArrayDataForUsers dataToDisplay is",data);
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/store-array-country-data`,
            data
          );
          console.log("Roles Response data", response);
    
    
          if (dataToDisplay.length === 0) { 
            // toast("error", "Please upload the correct file OR containing the data.");
            toast("error", "Error fields exist.");
            return;
        }
        else{
          if (response.status === 200) {
            toast("success", "Uploaded Country successfully");
          }
          else if(response.status === 400){
            toast("error", "Invalid or empty array of Country");
          }
          else if(response.status === 404){
            toast("error", "Missing required fields");
          }
          else if(response.status === 500){
            toast("error", "Duplicate Country IDs found.");
            console.log("response duplicate Country id data",response.duplicates)
          }
        }
    
    
          
        } catch (error) {
          console.error("Error fetching array Country data", error);
        }
        finally {
              setLoading(false);
            }
      };

      const handleSubmit = async () => {

        if(buttonText === 'Upload from desktop'){
            toast("error", "Please upload a file.");
            return;
        }
        else if (tableData.length === 0) { 
            // toast("error", "Please upload the correct file OR containing the data.");
            toast("error", "Please upload a file containing at least one record.");
            return;
        }
    
             // Check if either of the checkboxes is selected
        if (!isDisplayChecked && !isUploadChecked) {
            toast("error", "Please check at least one checkbox (Display or Upload).");
            return;
        }
    
    
        // console.log("tableData send to the prop",tableData);
        // console.log("displayOption selected", displayOption);
        // console.log("uploadOption selected", uploadOption);
    
        if(uploadOption !== '' && displayOption === '' ){
          console.log("User arry upload data");
          storeArrayDataForUsers();
        }
        else {
          if(uploadOption != ''){
            storeArrayDataForUsers();
          }
          console.log("exit and navigate to the upload table screen");
          console.log("exit and navigate tableData",tableData);
          console.log("exit and navigate to displayOption",displayOption);
          console.log("exit and navigate to uploadOption",uploadOption);
          navigate(`/2/master-data/1/country-upload/display-screen`, {
                state: { tableData, displayOption, uploadOption } // Passing tableData via state
            });
        }
      }

  return (
    
    <div>
         <Header title={`Primary Data > Global Data > Country > Upload `}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/2/master-data/1/country"}
        />
  {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}
      
            <UploadComponent
              downloadPath="/Country_Upload.xlsx"  // Replace with a dynamic file path if needed
              handleFileChange={handleFileChange}  // Function to handle file selection
              buttonText={buttonText}
              isUploaded={isUploaded}
              isError={isError}
              handleSubmit={handleSubmit}
              isDisplayChecked={isDisplayChecked}
              setIsDisplayChecked={setIsDisplayChecked}
              isUploadChecked={isUploadChecked}
              setIsUploadChecked={setIsUploadChecked}
              displayOption={displayOption}
              setDisplayOption={setDisplayOption}
              uploadOption={uploadOption}
              setUploadOption={setUploadOption}
              icons={iconId}
            />
      
              <ConfirmationDialog
                open={showConfirmation}
                title="Upload File"
                message="Do you need to download the latest upload format?"
                onConfirm={confirmDeletion}
                onCancel={cancelDeletion}
              />
              <Footer>
                <button
                  onClick={handleSubmit}
                  className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
                >
                  Run
                </button>
              </Footer>
      
            </div>
        )
      }
      