import RestService from "./RestServices";

const basePath = "/statement-format";

const StatementFormatServices = {
    getAllStatementFormat: async (id) => {
        return RestService.GetAllData(`${basePath}/get-statement-format?clientId=${id}`);
    },
    addStatementFormat: async (data, id, createdBy) => {
        return RestService.CreateData(`${basePath}/create-statement-format?clientId=${id}&createdBy=${createdBy}`, data)
    },
    getStatementFormatByID: async (id, clientId) => {
        return RestService.GetAllData(`${basePath}/get-statement-byId?id=${id}&clientId=${clientId}`);
    },
    editStatementFormat: async (data, id, clientId, updatedBy) => {
        return RestService.UpdateData(`${basePath}/edit-statement-format?id=${id}&clientId=${clientId}&updatedBy=${updatedBy}`, data);
    },
    exportData: async (clientId) => {
        return RestService.ExportData(`${basePath}/export-statement-format?clientId=${clientId}`);
    }
}

export default StatementFormatServices;