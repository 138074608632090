import RestService from "./RestServices";

const LegalEntityServices = {
  getAllLegalEntity: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error('Error fetching LegalEntity data:', error);
      return { error: true, message: "Error fetching LegalEntity data" };
    }
  },
  getLegalEntityById: (id) => {
    return RestService.GetByIdData("/get-legal-entity", id);
  },
  deleteLegalEntity: (id) => {
    return RestService.DeleteData("/delete-legal-entity", id);
  },
  addLegalEntity: async (path, data) => {
    try {
      console.log('Sending data:', data);
      const response = await RestService.CreateData(path, data);
      console.log('Response from Server:', response);
      return response;
    } catch (error) {
      console.error('Error adding LegalEntity:', error);
      return { error: true, message: "Error adding LegalEntity" };
    }
  },
  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },
  checkDuplicatelgecode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching lge code:", error);
      return { error: true, message: "Error fetching lge code" };
    }
  },
};



export default LegalEntityServices;
