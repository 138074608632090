import React, { useEffect, useState } from 'react'
import Header from '../../../../../components/Header'
import AdminActions from '../../../Admin/Components/AdminActions'
import ReTable from '../../../../../components/Table'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdminActionsConfig } from '../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import UserService from '../../../../../rest-services/UserServices';
import CustomeLoading from '../../../../../components/CustomeLoading';

export default function BankIDUploadTableScreen() {
  const navigate = useNavigate();   
  const location = useLocation();
  
      const { iconId } = useParams();
  const { tableData, displayOption, uploadOption  } = location.state || {}; // Retrieve tableData from state
      const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [processedData, setProcessedData] = useState([]);
const [isExistsCurrencyCode, setIsExistsCurrencyCode] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

    console.log("table data received from prop in BANKID is",tableData);
    console.log("displayOption data received from prop in BANKID is",displayOption);
    console.log("uploadOption data received from prop in BANKID is",uploadOption);
   
     useEffect(() => {
          fetchAssignee();
        }, []);
    const fetchAssignee = async () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            id: userDetail.id || "",
            userId: userDetail.userId || "",
            clientId: userDetail.clientId || "",
            assigner: userDetail.username || "",
          };
  
          setAssignee(assigneeData);
  
          // Log the id and assigner values to the console
          console.log("Assignee ID:", assigneeData.id);
          console.log("Assigner:", assigneeData.assigner);
          console.log("Assigner clientId:", assigneeData.clientId);
          console.log("Assigner userId:", assigneeData.userId);
  
        } else {
          setAlert({
            severity: "error",
            message: "No user detail found in session storage.",
          });
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching user details: ${error.message}`,
        });
      }
    };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Bank ID") {
      navigate("/2/master-data/10/bankidscreen");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  const columns = [   
                    
                    { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1)*10, alignment: "left" },
                    { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1)*10, alignment: "left" },
                    {
                                  Header: 'Update',
                                  accessor: (row) => {
                                    if (row.successfulrecords && uploadOption === '3') {
                                      return 'Yes';
                                    } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                                      return 'No';
                                    } else {
                                      return <span style={{ color: 'red' }}>No</span>;
                                    }
                                  },
                                  visible: true,
                                  width: ("Update".length -1)*10,
                                  alignment: 'left',
                                },
                    { Header: 'BankId', accessor: 'BankId', visible: true, width: ("BankId".length -1)*10, alignment: "left" },
                    { Header: 'BankName', accessor: 'BankName', visible: true, width: ("BankName".length -1)*10, alignment: "left" },
                    { Header: 'Bank', accessor: 'Bank', visible: true, width: ("Bank".length -1)*10, alignment: "left" },
                 { Header: 'DoorNo', accessor: 'DoorNo', visible: true, width: ("DoorNo".length -1)*10, alignment: "left" },
                         { Header: 'FloorNo', accessor: 'FloorNo', visible: true, width: ("FloorNo".length -1)*10, alignment: "left" },
                         { Header: 'BuildingNo', accessor: 'BuildingNo', visible: true, width: ("BuildingNo".length -1)*10, alignment: "left" },
                         { Header: 'BuildingName', accessor: 'BuildingName', visible: true, width: ("BuildingName".length -1)*10, alignment: "left" },
                         { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1)*10, alignment: "left" },
                         { Header: 'StreetNo', accessor: 'StreetNo', visible: true, width: ("StreetNo".length -1)*10, alignment: "left" },
                         { Header: 'StreetName', accessor: 'StreetName', visible: true, width: ("StreetName".length -1)*10, alignment: "left" },
                         { Header: 'RoadNo', accessor: 'RoadNo', visible: true, width: ("RoadNo".length -1)*10, alignment: "left" },
                         { Header: 'RoadName', accessor: 'RoadName', visible: true, width: ("RoadName".length -1)*10, alignment: "left" },
                         { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1)*10, alignment: "left" },
                         // { Header: 'Area Name', accessor: 'Area Name', visible: true, width: ("".length -1)*10, alignment: "left" },
                         // { Header: 'streetName', accessor: 'streetName', visible: true, width: ("".length -1)*10, alignment: "left" },
                         { Header: 'AreaName', accessor: 'AreaName', visible: true, width: ("AreaName".length -1)*10, alignment: "left" },
                         { Header: 'Phase', accessor: 'Phase', visible: true, width: ("Phase".length -1)*10, alignment: "left" },
                         { Header: 'Sector', accessor: 'Sector', visible: true, width: ("Sector".length -1)*10, alignment: "left" },
                         { Header: 'Village', accessor: 'Village', visible: true, width: ("Village".length -1)*10, alignment: "left" },
                         { Header: 'Town', accessor: 'Town', visible: true, width: ("Town".length -1)*10, alignment: "left" },
                         { Header: 'CityName', accessor: 'CityName', visible: true, width: ("CityName".length -1)*10, alignment: "left" },
                         // { Header: 'landmarkName', accessor: 'landmarkName', visible: true, width: ("".length -1)*10, alignment: "left" },
                         { Header: 'AddressLine1', accessor: 'AddressLine1', visible: true, width: ("AddressLine1".length -1)*10, alignment: "left" },
                         { Header: 'AddressLine2', accessor: 'AddressLine2', visible: true, width: ("AddressLine2".length -1)*10, alignment: "left" },
                         { Header: 'AddressLine3', accessor: 'AddressLine3', visible: true, width: ("AddressLine3".length -1)*10, alignment: "left" },
                         { Header: 'CountryId', accessor: 'CountryId', visible: true, width: ("CountryId".length -1)*10, alignment: "left" },
                         { Header: 'StateProvince', accessor: 'StateProvince', visible: true, width: ("StateProvince".length -1)*10, alignment: "left" },
                         { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1)*10, alignment: "left" },
                         { Header: 'Postal Code', accessor: 'PostalCode', visible: true, width: ("Postal Code".length -1)*10, alignment: "left" },
                         { Header: 'ZipCode', accessor: 'ZipCode', visible: true, width: ("ZipCode".length -1)*10, alignment: "left" },
                         { Header: 'POBox', accessor: 'POBox', visible: true, width: ("POBox".length -1)*10, alignment: "left" },
                        
              ];

        const existingButtons = AdminActionsConfig.screen7.map((button) => {
          let onClick;
          let title;
      
      
          if (button.icon === MdOutlineFileDownload) {
            // onClick = handleDownload;
            title = "Download File";
          }
      
          else {
            onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
            title = button.title || ""; // Use existing title or default to an empty string
          }
      
          return {
            ...button,
            onClick, // Reference the function without invoking it
            title,   // Add the title property to the button configuration
          };
        });
              
          const extraButtons = [
  
          ];
                  
        const actionButtons = existingButtons.concat(
          extraButtons.map((button) => ({
            ...button,
            disabled: selectedRows.length === 0, // Disable button if no users are selected
            onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
          }))
        );
              
                        
        const [columnSettings, setColumnSettings] = useState(columns);       
                
        const modifiedColumns = columnSettings
        .filter((col) => col.visible)
        .map((col) => ({
          ...col,
          width: col.width, // This should hold the updated width
        }));
  

        const isRowValid = (row, index) => {
          // Common validations
          const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
          const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
          const isBankIdValid = row.BankId && String(row.BankId).length <= 10;
          const isAddress1Valid = row.AddressLine1 ? String(row.AddressLine1).length <= 50 : true;
          const isAddress2Valid = row.AddressLine2 ? String(row.AddressLine2).length <= 50 : true;
          const isAddress3Valid = row.AddressLine3 ? String(row.AddressLine3).length <= 50 : true;
       
          
          let errors = [];
      
          if (!isActionValid) errors.push("Invalid Action");
          if (!isSLNoValid) errors.push("Invalid SLNo");
          if (!isBankIdValid) errors.push("Invalid BankId");
          if (!isAddress1Valid) errors.push("Invalid Address1");
          if (!isAddress2Valid) errors.push("Invalid Address2");
          if (!isAddress3Valid) errors.push("Invalid Address3");
  
          // Action "A" or "a" specific validations
          if (row.Action === "A" || row.Action === "a") {
              const isBankNameValid = row.BankName && String(row.BankName).length <= 150;
              const isBankValid = row.Bank && String(row.Bank).length <= 30;
              const isCountryIdValid = row.CountryId && String(row.CountryId).length <= 2;
      
              if (!isBankNameValid) errors.push("Invalid BankName");
              if (!isBankValid) errors.push("Invalid Bank");
              if (!isCountryIdValid) errors.push("Invalid CountryId");
      
              if (errors.length > 0) {
                  console.log(`Row ${index + 1} is invalid:`, errors);
              }
      
              return (
                  isActionValid &&
                  isSLNoValid &&
                  isBankIdValid &&
                  isBankNameValid &&
                  isBankValid &&
                  isCountryIdValid
              );
          }
      
          // Action "E" or "e" specific validations
          if (row.Action === "E" || row.Action === "e") {
              const isBankNameValid = row.BankName ? String(row.BankName).length <= 150 : true;
              const isBankValid = row.Bank ? String(row.Bank).length <= 30 : true;
              const isCountryIdValid = row.CountryId ? String(row.CountryId).length <= 2 : true;
      
              if (!isBankNameValid) errors.push("Invalid LegalEntityName");
              if (!isBankValid) errors.push("Invalid Bank");
              if (!isCountryIdValid) errors.push("Invalid CountryId");
      
              if (errors.length > 0) {
                  console.log(`Row ${index + 1} is invalid:`, errors);
              }
      
              return (
                  isActionValid &&
                  isSLNoValid &&
                  isBankIdValid &&
                  isBankNameValid &&
                  isBankValid &&
                  isCountryIdValid
              );
          }
      
          if (errors.length > 0) {
              console.log(`Row ${index + 1} is invalid:`, errors);
          }
      
          return isActionValid && isSLNoValid && isBankIdValid &&
          isAddress1Valid &&
          isAddress2Valid &&
          isAddress3Valid ;
      };
  
        const filterExistingUserIds = async (filteredRows, existingUserIds) => {
          return filteredRows.filter((row) => existingUserIds.includes(row.BankId));
        };
  
        const filterExistingCountryIds = async (ExistCurrencyIdValidRows, existingCountryIds) => {
          return ExistCurrencyIdValidRows.filter((row) => existingCountryIds.includes(row.CountryId));
        };
  
        const filterExistingstateIds =  async (ExistCountryIdValidRows, existingstateIds) => {
          return ExistCountryIdValidRows.filter((row) => existingstateIds.includes(row.StateProvince));
        };
  
        const filterExistingBankCode = async (validRows, existingBankCode) => {
          return validRows.filter((row) => existingBankCode.includes(row.Bank) || row.Action === 'E');
        };

        const getExistingUserIds = async (tableData) => {
          try {
            console.log("userIds", tableData);
        
  
              // Filter valid rows from the table data
              const validRows = tableData.filter((row, index) => isRowValid(row, index));
              console.log("valid rows checking in upload:", validRows);
        
              // Bank Code valid data get
              const response1 = await UserService.saveContactDetails(
                `${process.env.REACT_APP_BASE_URL}/get-Banks1-duplicate/BankCode`,
                { validRows }
              );
              console.log("errored records Bank Code's : Response data", response1.data);
  
              const existingBankCode = response1.data; // Extract existing user IDs from API response
              const withoutDuplicateBankCode = await filterExistingBankCode(validRows, existingBankCode);
              console.log("successfuland processed Bank data:", withoutDuplicateBankCode);
       
              
              // Country Code valid data get
  const response3 = await UserService.saveContactDetails(
    `${process.env.REACT_APP_BASE_URL}/get-CountryID-exists-only`,
    { validRows:withoutDuplicateBankCode }
  );
  console.log("check Country code is exists", response3.data);
  const existingCountryIds = response3.data; // Extract existing user IDs from API response
  
  const ExistCountryIdValidRows = await filterExistingCountryIds(withoutDuplicateBankCode, existingCountryIds);
  
  console.log("successful and row valid in countryID filters 1", ExistCountryIdValidRows);
  
  
  
  // State Code valid data get
  const response4 = await UserService.saveContactDetails(
    `${process.env.REACT_APP_BASE_URL}/get-StateID-exists-only`,
    { validRows:ExistCountryIdValidRows }
  );
  console.log("check state code is exists 1", response4.data);
  const existingstateIds = response4.data; 
  
  const ExiststateIdValidRows = await filterExistingstateIds(ExistCountryIdValidRows, existingstateIds);
  
  console.log("successful and row valid in stateID filters 1", ExiststateIdValidRows);
  
  
  
  
            // Bank ID valid data get
            // Make API call to fetch existing user IDs
            const response = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-BankId-duplicate/BankId`,
              { validRows: ExiststateIdValidRows }
            );
  
            console.log("errored records Bank id's : Response data", response.data);
            const existingUserIds = response.data; // Extract existing user IDs from API response
            const withoutDuplicateUserIds = await filterExistingUserIds(ExiststateIdValidRows, existingUserIds);
       
            // Further filter the results to ensure only valid rows are returned
            // const validWithoutDuplicateUserIds = withoutDuplicateUserIds.filter((row) =>
            //   isRowValid(row)
            // );
        
            console.log("successfuland processed Records data:", withoutDuplicateUserIds);
        
            return withoutDuplicateUserIds;
        
          } catch (error) {
            console.error("Error fetching existing user IDs:", error);
            return [];
          }
        };
  
        const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
          let dataToDisplay;
          if (displayOption === "1") {
            console.log("Display option is 1");
            const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"
        
             // Extract the current dataToDisplay (which is only one record currently)
             const currentDataToDisplay = existingUsers.length ? existingUsers[0] : null;
        
             // Filter out the currentDataToDisplay row from tableData to get other 6 rows
             dataToDisplay = tableData.filter(row => row.BankId !== currentDataToDisplay?.BankId);
         
             console.log("Filtered remaining data excluding currentDataToDisplay:", dataToDisplay);
        
          } else if (displayOption === "2") {
            console.log("Display option is 2");
            dataToDisplay = tableData; // Display tableData if displayOption is "2"
          } else if (uploadOption === "3") {
            console.log("upload option is 3");
            dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
  
            // dataToDisplay = successfulRecords; // Display successfulRecords if uploadOption is "3"
            // dataToDisplay = await fetchAndFilterSuccessfulRecords(tableData); // Successful records for uploadOption "3"
        
          } else {
            dataToDisplay = tableData; // Default to tableData if no conditions are met
          }
        
        // console.log("dataToDisplay data is",dataToDisplay);
        return dataToDisplay;
        };
  
  
        (async () => {
          const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
          
          if (!Array.isArray(dataToDisplay)) {
            console.error("getDataToDisplay did not return an array:", dataToDisplay);
            return;
          }
        
          console.log("Final dataToDisplay data is", dataToDisplay);
        
          // Call processData only when dataToDisplay is defined and valid
          await processData(dataToDisplay);
        
        })();
  
  
const isExistingStateId = async (StateProvince) =>{
  try {
      console.log("exisitng user id",StateProvince);
      const data = { StateCode:StateProvince };
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-StateCode-duplicates`,
        data);
      console.log("existing state details:", response);
  
      // if (response?.CountryID !== undefined) {
      //   return response.CountryID; // Return the CountryID if found
      // }
      // return false; // Return false if CountryID is null
      return response?.StateID ? response : false; 
    } catch (error) {
      console.error("Error checking existing userId:", StateProvince, error);
      return false;
    }
  };

const isExistingCountryId = async (CountryId) =>{
  try {
      console.log("exisitng user id",CountryId);
      const data = { CountryCode:CountryId };
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-CountryCode-duplicates`,
        data);
      console.log("existingUserId details:", response);
  
      // if (response?.CountryID !== undefined) {
      //   return response.CountryID; // Return the CountryID if found
      // }
      // return false; // Return false if CountryID is null
      return response?.CountryID ? response : false; 
    } catch (error) {
      console.error("Error checking existing userId:", CountryId, error);
      return false;
    }
  };
        const isExistingBank = async (Bank) => {
          try {
          console.log("exisitng Bank id",Bank);
          const data = { BankCode: Bank };
        
          const response = await UserService.saveContactDetails(
          `${process.env.REACT_APP_BASE_URL}/get-BankCode-duplicates`,
          data);
          console.log("existing Bank details:", response);
        
          // return response && response.UserID !== null; // Return true if userId exists, false otherwise
          return response?.BankCodeID ? response : false; // Return response only if UserID exists
          } catch (error) {
          console.error("Error checking existing userId:", Bank, error);
          return false;
          }
        };

  const isExistingUserId = async (BankId) => {
    try {
      console.log("exisitng New Bank id",BankId);
      const data = { BankId };
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-BankId-duplicates`,
        data);
  
      console.log("existingBankId details:", response.BankId);
  
      return response && response.BankId !== null; // Return true if userId exists, false otherwise
    } catch (error) {
      console.error("Error checking existing userId:", BankId, error);
      return false;
    }
  };
  
  
  async function checkDuplicateUserIdsFromDB(BankIds) {
    try {
      const data = { BankIds };
      console.log("BankIds duplicated DATA IS",data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-BankIds-duplicates`,
        data
      );
  
      // console.log("API Response for response details:", response);
      console.log("existingUserId API Response for duplicates details:", response.duplicates);
      return response.duplicates; // Return an array of duplicate userIds
    } catch (error) {
      console.error("Error checking duplicate userIds from DB:", error);
      return [];
    }
  }
  
  const processData = async (data) => {
    // Extract all userIds from dataToDisplay
    
    if (!Array.isArray(data)) {
      console.error("processData received invalid data:", data);
      return []; // Return an empty array to prevent further errors
    }
    console.log("processData received  data:", data);
  
    const BankIds = data.map(row => row.BankId).filter(Boolean);
    console.log("Extracted BankId's:", BankIds);
  
    const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(BankIds);
  
  
     // Preprocess table data to add conditional rendering for userId and username
    //  return data.map((row, _, allRows) => {
      return Promise.all(data.map(async (row, _, allRows) => {
      // const rowInvalid = isRowInvalid(row);
      // Define the max allowed characters for each column (this can come from your column definitions)
    
        // Check for duplicate userId in dataToDisplay
    const isDuplicateUserId = allRows.filter(r => r.BankId == row.BankId).length > 1;
  console.log("Duplicate user id is ",isDuplicateUserId);
  
   // Check for duplicate userId in the database
   const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.BankId);
  
   const userExists = await isExistingUserId(row.BankId);
   const BankExists = await isExistingBank(row.Bank);
   const countryIDExists = await isExistingCountryId(row.CountryId);
   const StateIDExists = await isExistingStateId(row.StateProvince);
  
   console.log(`Bank id ${row.BankId} exists:`, userExists);
   console.log(`Bank code ${row.Bank} exists:`, BankExists);
   console.log(`Country ID is ${row.CountryId} exists:`, countryIDExists);
   console.log(`state ID is ${row.StateProvince} exists:`, StateIDExists);
   // Check for errors (red text) and apply highlight based on that
  
  const hasNotError = [
    row.SLNo,
    row.Action,
    row.BankId,
    row.BankName,
    row.Bank,
    row.AddressLine1,
    row.AddressLine2,
    row.AddressLine3,
    row.CountryId,
    row.StateProvince,
    
  ].every(field => {
    let isValid = true;
    let reason = "";
    
    if (field === row.SLNo) {
      if (!field) { isValid = false; reason = "SLNo should not be blank."; }
      else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
      else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
    }
    
    else if (field === row.Action) {
      if (!field) { isValid = false; reason = "Action should not be blank."; }
      else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
    }
    
    
  if (!row.BankId || String(row.BankId).trim() === "") {
  if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
    isValid = false;
    reason = "BankId should not be empty.";
  }
  } else if (field === row.BankId) {
  if (String(field).length > 10) { isValid = false; reason = "BankId ID exceeds max length of 10."; }
    if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
      isValid = false; reason = "BankId does not exist.";
    } else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
      isValid = false; reason = "BankId is duplicate.";
    }
  }
  
  
  // else if (field === row.BankName) {
  // if (row.Action === "E" || row.Action === "e") {
  // if (!field) { isValid = true; reason = "BankName should be blank for Edit action."; }
  // else if (String(field).length > 150) { isValid = false; reason = "BankName exceeds max length of 150."; }
  // } else if (row.Action === "A" || row.Action === "a") {
  // if (!field) { isValid = false; reason = "BankName should not be blank for Add action."; }
  // if (String(field).length > 150) { isValid = false; reason = "BankName exceeds max length of 150."; }
  // }
  // }
  
  if (!row.BankName || String(row.BankName).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "BankName should not be empty.";
  }
  } else if (field === row.BankName) {
      if (row.Action === "E" || row.Action === "e") {
          if (!field.trim()) {
              isValid = true;
              reason = "BankName should be blank for Edit action.";
          } else if (String(field).trim().length > 150) {
              isValid = false;
              reason = "BankName exceeds max length of 150.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!field.trim()) {
              isValid = false;
              reason = "BankName should not be blank for Add action.";
          } else if (String(field).trim().length > 150) {
              isValid = false;
              reason = "BankName exceeds max length of 150.";
          }
      }
  }
  
  
  // else if (field === row.Bank) {
  // if (row.Action === "E" || row.Action === "e") {
  // if (!field) { isValid = true; reason = "Bank should be blank for Edit action."; }
  // else if (String(field).length > 30) { isValid = false; reason = "Bank exceeds max length of 30."; }
  
  // } else if (row.Action === "A" || row.Action === "a") {
  // if (!field) { isValid = false; reason = "Bank should not be blank for Add action."; }
  // if (String(field).length > 30) { isValid = false; reason = "Bank exceeds max length of 30."; }
  
  // // if (isExistsCurrencyCode.some(code => code == row.CurrencyCode)) { 
  // //   isValid = true; 
  // //   reason = "CurrencyCode exists.";
  // // } else {
  // //   isValid = false;  
  // //   reason = "CurrencyCode does not exist.";
  // // }
  
  // }
  
  // }
  



  if (!row.Bank || String(row.Bank).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "Bank should not be empty.";
  }
  } else if (field === row.Bank) {
    if(!BankExists){
     
      isValid = false;
      reason = "Bank is not exists";
    }
  
      if (row.Action === "E" || row.Action === "e") {
          if (!field.trim()) {
              isValid = true;
              reason = "Bank should be blank for Edit action.";
          } else {
            if (String(field).trim().length > 30) {
              isValid = false;
              reason = "Bank exceeds max length of 30.";
          }
        }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!field.trim()) {
              isValid = false;
              reason = "Bank should not be blank for Add action.";
          } else {
            if (String(field).trim().length > 30) {
              isValid = false;
              reason = "Bank exceeds max length of 30.";
          }
        }
      }
  }
  
  // if (!row.Bank || String(row.Bank).trim() === "") {
  //   if (row.Action === "A" || row.Action === "a") {
  //     isValid = false;
  //     reason = "Bank should not be empty.";
  // }
  // } else if (field === row.Bank) {
  //     if (row.Action === "E" || row.Action === "e") {
  //         if (!field.trim()) {
  //             isValid = true;
  //             reason = "Bank should be blank for Edit action.";
  //         } else if (String(field).trim().length > 30) {
  //             isValid = false;
  //             reason = "Bank exceeds max length of 30.";
  //         }
  //     } else if (row.Action === "A" || row.Action === "a") {
  //         if (!field.trim()) {
  //             isValid = false;
  //             reason = "Bank should not be blank for Add action.";
  //         } else if (String(field).trim().length > 30) {
  //             isValid = false;
  //             reason = "Bank exceeds max length of 30.";
  //         }
  //     }
  // }
  
  
    // else if (field === row.addressLine1) {
    //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
    //     if (!field) { isValid = true; reason = "addressLine1 should be blank for Add/Edit action."; }
    //   }
    //   if (String(field).length > 50) { isValid = false; reason = "addressLine1 exceeds max length of 50."; }
    // }
  
    
    
  if (row.AddressLine1 && String(row.AddressLine1).length > 50) {
  isValid = false;
  reason = "addressLine1 exceeds max length of 50.";
  } else {
  // field = row.addressLine1 || "";  
  
  const missingFields = [
    row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
    row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
    row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
    row.CityName,  row.AddressLine1, row.AddressLine2, row.AddressLine3
  ].every(value => !value); // Check if all values are falsy (undefined, null, empty, false)
  
  if (row.Action === "E" || row.Action === "e") {
    if (!field) { 
        isValid = true; 
        reason = "addressLine1 should be blank for Edit action."; 
    } else if (field.length > 50) { 
        isValid = false; 
        reason = "addressLine1 exceeds max length of 50."; 
    }
  } else if (row.Action === "A" || row.Action === "a") {
    console.log("Address field 1 enter log");
    console.log("check door no:", row.DoorNo);
  
    if (missingFields) { 
        isValid = false; 
        reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and Address1 should not be blank for Add action."; 
    }
  }
  }
  
     if (field === row.AddressLine2) {
      if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        if (!field) { isValid = true; reason = "AddressLine2 should be blank for Add/Edit action."; }
      }
      if (String(field).length > 50) { isValid = false; reason = "AddressLine2 exceeds max length of 50."; }
    }
  
     if (field === row.AddressLine3) {
      if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        if (!field) { isValid = true; reason = "AddressLine3 should be blank for Add/Edit action."; }
      }
      if (String(field).length > 50) { isValid = false; reason = "AddressLine3 exceeds max length of 50."; }
    }
  
   
    if (!row.CountryId || String(row.CountryId).trim() === "") {
      if (row.Action === "A" || row.Action === "a") {
        isValid = false;
        reason = "CountryId should not be empty.";
    }
    } else if (field === row.CountryId) {
      if(countryIDExists){
        isValid = true;
        reason = "country Code is exists";
      }
      else{
        isValid = false;
        reason = "country Code is not exists";
      }
    
        if (row.Action === "E" || row.Action === "e") {
            if (!field.trim()) {
                isValid = true;
                reason = "CountryId should be blank for Edit action.";
            } else if (String(field).trim().length > 2) {
                isValid = false;
                reason = "CountryId exceeds max length of 2.";
            }
        } else if (row.Action === "A" || row.Action === "a") {
            if (!field.trim()) {
                isValid = false;
                reason = "CountryId should not be blank for Add action.";
            } else if (String(field).trim().length > 2) {
                isValid = false;
                reason = "CountryId exceeds max length of 2.";
            }
        }
    }
  
     if (field === row.StateProvince) {
  
      if (String(field).length > 3) { isValid = false; reason = "StateProvince exceeds max length of 3."; }
      if(!StateIDExists){
        isValid = false;
        reason = "StateProvince is not exists";
      }
      
    }
    else{
      if (!row.BankId || String(row.BankId).trim() === "") {
        if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
          isValid = false;
          reason = "BankId should not be empty.";
        }
        } else if (field === row.BankId) {
        if (String(field).length > 10) { isValid = false; reason = "BankId ID exceeds max length of 10."; }
          if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
            isValid = false; reason = "BankId does not exist.";
          } else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
            isValid = false; reason = "BankId is duplicate.";
          }
        }


      else if (!row.Bank || String(row.Bank).trim() === "") {
        if (row.Action === "A" || row.Action === "a") {
          isValid = false;
          reason = "Bank should not be empty.";
      }
      } else if (field === row.Bank) {
        if(!BankExists){
         
          isValid = false;
          reason = "Bank is not exists";
        }
      
          if (row.Action === "E" || row.Action === "e") {
              if (!field.trim()) {
                  isValid = true;
                  reason = "Bank should be blank for Edit action.";
              } else {
                if (String(field).trim().length > 30) {
                  isValid = false;
                  reason = "Bank exceeds max length of 30.";
              }
            }
          } else if (row.Action === "A" || row.Action === "a") {
              if (!field.trim()) {
                  isValid = false;
                  reason = "Bank should not be blank for Add action.";
              } else {
                if (String(field).trim().length > 30) {
                  isValid = false;
                  reason = "Bank exceeds max length of 30.";
              }
            }
          }
      }
    }
 
  
  
    if (!isValid) {
      console.log(`Validation Failed: ${reason}`);
    } else {
      console.log(`Validation Passed: ${field}`);
    }
    
    return isValid;
  });
  
  console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        isDuplicateUserId,
        isDuplicateInDB,
        userExists,
        BankExists,
        countryIDExists,
        StateIDExists,
  
        Action: row.Action ? (
          String(row.Action).length === 1 ? (
            ["A", "a", "E", "e"].includes(row.Action) ? (
              row.Action // Valid Action ("A", "E", "a", "e")
            ) : (
              <span style={{ color: "red" }}>
                {row.Action} (Only "A", "E" are allowed)
              </span>
            )
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Max. allowed character is 1)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>Shouldn't be blank</span>
        ),
  
        SLNo: row.SLNo ? (
            isNaN(row.SLNo) ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Mismatched Data Type)
              </span>
            ) : String(row.SLNo).length > 5 ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Max. allowed character is 5)
              </span>
            ) : (
              row.SLNo // Valid SLNo
            )
          ) : (
            <span style={{ color: "red"}}>Shouldn't be blank</span>
          ),
    
          BankId: row.BankId ? (
            String(row.BankId).length > 10 ? (
              <span style={{ color: "red" }}>
                {row.BankId} (Max. allowed character is 10)
              </span>
            ) : row.Action === "A" || row.Action === "a" ? (
              // When Action is "A", show duplicate message if user ID is a duplicate
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span style={{ color: "red" }}>
                  {row.BankId} (BankId Duplicate exists)
                </span>
              ) : (
                String(row.BankId) // Otherwise, show BankId normally
              )
            ) : row.Action === "E" || row.Action === "e" ? (
              // When Action is "E", show BankId without the duplicate message
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span>
                  {row.BankId} 
                </span>
              ) : (
                // String(row.BankId) // Otherwise, show BankId normally
                <span style={{ color: "red" }}>
                {row.BankId} (BankId not exists)
              </span>
              )
            ) : (
              String(row.BankId) // Default case, show BankId normally
            )
          ) : (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ),
  
          BankName: row.BankName ? (
            String(row.BankName).length > 150 ? (
              <span style={{ color: "red" }}>
                {row.BankName} (Max. allowed character is 150)
              </span>
            ) : (
              String(row.BankName) // Ensure BankName is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),
  
          // CurrencyCode: row.CurrencyCode ? (
          //   String(row.CurrencyCode).length > 3 ? (
          //     <span style={{ color: "red" }}>
          //       {row.CurrencyCode} (Max. allowed character is 3)
          //     </span>
          //   ) : (
          //     String(row.CurrencyCode) // Ensure CurrencyCode is treated as a string
          //   )
          // ) : (
          //   row.Action === "A" || row.Action === "a" ? (
          //     <span style={{ color: "red" }}>
          //       Shouldn't be blank
          //     </span>
          //   ) : row.Action === "E" || row.Action === "e" ? (
          //     <span></span> // Empty span for "E"
          //   ) : null // Handle other cases, if any
          // ),
  
          Bank: row.Bank ? (
            String(row.Bank).length > 30 ? (
              <span style={{ color: "red" }}>
                {row.Bank} (Max. allowed character is 30)
              </span>
            ) :  (
              BankExists ?
              <span>{row.Bank}</span> :
              <span style={{ color: "red" }}>
              {row.Bank} (Bank Code not exists.)
            </span>
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),
  
  
          // addressLine1: row.addressLine1 ? (
          //   String(row.addressLine1).length > 50 ? (
          //     <span style={{ color: "red"}}>
          //       {row.addressLine1} (Max. allowed character is 50)
          //     </span>
          //   ) : (
          //       String(row.addressLine1) // Ensure userId is treated as a string
          //   )
          // ) : (
          //   <span>
             
          //   </span>
          // ),
          AddressLine1: row.AddressLine1 ? (
            String(row.AddressLine1).length > 50 ? (
              <span style={{ color: "red"}}>
                {row.AddressLine1} (Max. allowed character is 50)
              </span>
            ) : (
                String(row.AddressLine1) // Ensure userId is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
              
              !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.AreaName && !row.Phase && !row.Sector && !row.Village && !row.Town && !row.CityName && !row.AddressLine2 && !row.AddressLine3
              // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
              ? (
                <span style={{ color: "red" }}>
                    Either Address Section or Building, Street, and Area Section must be entered
                </span>
            ) : (
                <span></span>
            )
              ) : row.Action === "E" || row.Action === "e" ? (
                <span></span> // Empty span for "E"
              ) : null // Handle other cases, if any
    
              
          ),
  
          AddressLine2: row.AddressLine2 ? (
            String(row.AddressLine2).length > 50 ? (
              <span style={{ color: "red"}}>
                {row.AddressLine2} (Max. allowed character is 50)
              </span>
            ) : (
                String(row.AddressLine2) // Ensure userId is treated as a string
            )
          ) : (
            <span>
             
            </span>
          ),
    
          AddressLine3: row.AddressLine3 ? (
            String(row.AddressLine3).length > 50 ? (
              <span style={{ color: "red"}}>
                {row.AddressLine3} (Max. allowed character is 50)
              </span>
            ) : (
                String(row.AddressLine3) // Ensure userId is treated as a string
            )
          ) : (
            <span>
             
            </span>
          ),
    
          CountryId: row.CountryId ? (
            String(row.CountryId).length > 2 ? (
              <span style={{ color: "red"}}>
                {row.CountryId} (Max. allowed character is 2)
              </span>
            )  : countryIDExists && displayOption !== "" ? (
              <span>{row.CountryId}</span>
            ) : (
              <span style={{ color: "red" }}>
                {row.CountryId} (Country Code not exists)
              </span>
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),
        
        
          StateProvince: row.StateProvince ? (
            String(row.StateProvince).length > 3 ? (
              <span style={{ color: "red"}}>
                {row.StateProvince} (Max. allowed character is 3)
              </span>
            ) : StateIDExists ? (
              <span>{row.StateProvince}</span>
            ):(
              <span style={{ color: "red" }}>
                {row.StateProvince} (StateProvince not exists)
              </span>
            )
          ) : (
            <span>
             
            </span>
          ),
    
        };  
    }));
  }
  
        useEffect(() => {
          const fetchData = async () => {
            setLoading(true);
            const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
            const processed = await processData(dataToDisplay);
            setProcessedData(processed);
            setLoading(false);
          };
        
          fetchData();
        }, []);
  
            

  return (
    <div>
      
      <Header
        title={`Primary Data > Company & Bank Data > Bank ID > Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/2/master-data/10/bankId-upload"
      />

{loading && (
          <div>
            <CustomeLoading />
          </div>
        )}
<AdminActions
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              />


<ReTable
          data={processedData}
          // data={tableData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        //   onRowDoubleClick={handleDoubleClick}
        />
    </div>
  )
}
