import React, { useState, useEffect, useRef } from 'react';
import UserService from '../../../../rest-services/UserServices';
import { Alert } from "@mui/material";
import { TbFlag3Filled } from "react-icons/tb";
import { MdOutlineLockOpen, MdOutlineLock } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function Usertables({ onSelectionChange,filterUsers,setUsers, refreshTable  }) {
  const navigate = useNavigate();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [alert, setAlert] = useState(null);
  const [users, setUsersState] = useState([]);
  const [columnWidths, setColumnWidths] = useState({});
  const resizerRef = useRef();
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [language, setlanguage] = useState("");
  const [dateFormat, setdateFormat] = useState("");
  const [timeFormat, settimeFormat] = useState("");
  const [timeZone, settimeZone] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPreference, setUserPreference] = useState("");
  const [error, setError] = useState(null);
  const [amountFormat, setamountFormat] = useState("");
  // State for search term
  const [searchTerm, setSearchTerm] = useState('');

  
  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        
        setAssignee(assigneeData);
        
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  // If filterUsers updates, set the users state
  useEffect(() => {
    console.log('filter user data',filterUsers);
    if (filterUsers) {
      setUsersState(filterUsers);
     refreshTable.current = filterUsers;

    }
  }, [filterUsers]);

  
  useEffect(() => {
    // setUsersState(filterUsers);
    if (assignee.id) {
      fetchUserPreferenceDetails();
    }
  }, [assignee]);

  const fetchUserPreferenceDetails = async () => {
    try {
      const data = { id: assignee.id }; // Assuming assignee.id is the user ID
      console.log("Request Data:", data);

      setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log(response.data.preferenceDetails);
      setUserPreference(response.data.preferenceDetails); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // Handle checkbox selection
  const handleCheckboxChange = (userId) => {
    const updatedSelection = selectedUserIds.includes(userId)
      ? selectedUserIds.filter(id => id !== userId)
      : [...selectedUserIds, userId];
    setSelectedUserIds(updatedSelection);
    onSelectionChange(updatedSelection);
  };

  // Fetch users when component mounts
  useEffect(() => {
    fetchUsers();
    fetchAssignee();
  }, []);

  // Fetch users from the API
  const fetchUsers = async () => {
    try {  
      const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/user/get-user`);
      console.log("Response from server:", response);
      console.log(`user prefernce:${userPreference}`);
      const responseData = response.data;
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
      if (responseData.data && Array.isArray(responseData.data)) {
        setUsersState(responseData.data);
        setUsers(responseData.data);
         // Update parent component if necessary
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
    }
  };

  // Handle column resizing
  const handleMouseDown = (e, index) => {
    resizerRef.current = { startX: e.clientX, colIndex: index, startWidth: columnWidths[index] || 200 };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!resizerRef.current) return;

    const { startX, colIndex, startWidth } = resizerRef.current;
    const diffX = e.clientX - startX;
    const newWidth = Math.max(50, startWidth + diffX);

    setColumnWidths((prev) => ({
      ...prev,
      [colIndex]: newWidth
    }));
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    resizerRef.current = null;
  };

  // Date formatting functions
  const formatDateTime = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', options);
  };

  const formatDate = (dateString, format) => {
    const date = new Date(dateString);
    
    switch (format) {
      case '1': // DD-MM-YYYY
        return date.toLocaleDateString('en-GB');
      case '2': // MM-DD-YYYY
        return date.toLocaleDateString('en-US');
      case '3': // DD-MM-YY
        return date.toLocaleDateString('en-GB').slice(0, 8) + date.toLocaleDateString('en-GB').slice(-2);
      case '4': // MM-DD-YY
        return date.toLocaleDateString('en-US').slice(0, 8) + date.toLocaleDateString('en-US').slice(-2);
      case '5': // DD.MM.YYYY
        return date.toLocaleDateString('en-GB').replace(/\//g, '.');
      case '6': // DDMMYY
        return date.toLocaleDateString('en-GB').replace(/\//g, '').slice(0, 6);
      default:
        return date.toDateString(); // Fallback format
    }
  };
  
  
  // Table columns
  // const columns = [
  //   { Header: '', accessor: 'checkbox',visible: true, width: 150 },
  //   { Header: <TbFlag3Filled style={{ color: "#ffff" }} />, accessor: 'approved',visible: true, width: 150 },
  //   { Header:  <MdOutlineLock style={{ color: "#ffff" }} />, accessor: 'userStatus',visible: true, width: 150 }, // Use the icon directly here
  //   { Header: 'User ID', accessor: 'userId',visible: true, width: 150 },
  //   { Header: 'Employee No', accessor: 'employeeNo',visible: true, width: 150 },
  //   { Header: 'Salute', accessor: 'salute',visible: true, width: 150 },
  //   { Header: 'Username', accessor: 'username',visible: true, width: 150 },
  //   { Header: 'First Name', accessor: 'firstName',visible: true, width: 150 },
  //   { Header: 'Last Name', accessor: 'lastName',visible: true, width: 150 },
  //   { Header: 'Language', accessor: 'language',visible: true, width: 150 },
  //   { Header: 'Time Format', accessor: 'timeFormat',visible: true, width: 150 },
  //   { Header: 'Time Zone', accessor: 'timeZone',visible: true, width: 150 },
  //   { Header: 'User Type', accessor: 'name',visible: true, width: 150 },
  //   { Header: 'Company Code', accessor: 'companyCodeId',visible: true, width: 150 },
  //   { Header: 'Department', accessor: 'department',visible: true, width: 150 },
  //   { Header: 'Valid From', accessor: 'validFrom',visible: true, width: 150 },
  //   { Header: 'Valid To', accessor: 'validTo',visible: true, width: 150 },
  //   { Header: 'Email ID', accessor: 'email',visible: true, width: 150 },
  //   { Header: 'Address 1', accessor: 'addressLine1',visible: true, width: 150 },
  //   { Header: 'Address 2', accessor: 'addressLine2',visible: true, width: 150 },
  //   { Header: 'Address 3', accessor: 'addressLine3',visible: true, width: 150 },
  //   { Header: 'Country', accessor: 'country_name',visible: true, width: 150 },
  //   { Header: 'Mobile No', accessor: 'mobileNo',visible: true, width: 150 },
  //   { Header: 'Nationality', accessor: 'nationality',visible: true, width: 150 },
  //   { Header: 'Passport No', accessor: 'passportNo',visible: true, width: 150 },
  //   { Header: 'Driving License No', accessor: 'drivingLicenseNo',visible: true, width: 150 },
  //   { Header: 'Local ID', accessor: 'localId',visible: true, width: 150 },
  //   { Header: 'Joining Date', accessor: 'joiningDate',visible: true, width: 150 },
  //   { Header: 'Resign Date', accessor: 'resignationDate',visible: true, width: 150 },
  //   { Header: 'Amount Format', accessor: 'amountFormat',visible: true, width: 150 },
  //   { Header: 'Date Format', accessor: 'dateFormat',visible: true, width: 150 },
  //   { Header: 'Creation Date & Time', accessor: 'creationDate',visible: true, width: 150 },
  //   // { Header: 'Creation Time', accessor: 'creationTime',visible: true, width: 150 },
  //   { Header: 'Created By', accessor: 'createdBy',visible: true, width: 150 },
  //   { Header: 'Change Date & Time', accessor: 'changedDate',visible: true, width: 150 },
  //   // { Header: 'Change Time', accessor: 'changedTime',visible: true, width: 150 },
  //   { Header: 'Changed By', accessor: 'changedBy',visible: true, width: 150 },
  //   // Add more columns if needed
  // ];

  // Table columns
  const columns = [
    { label: '', accessor: 'checkbox' },
    { label: <TbFlag3Filled style={{ color: "#ffff" }} />, accessor: 'approved' },
    { label:  <MdOutlineLock style={{ color: "#ffff" }} />, accessor: 'userStatus' }, // Use the icon directly here
    { label: 'User ID', accessor: 'userId' },
    { label: 'Employee No', accessor: 'employeeNo' },
    { label: 'Salute', accessor: 'salute' },
    { label: 'Username', accessor: 'username' },
    { label: 'First Name', accessor: 'firstName' },
    { label: 'Middle Name', accessor: 'middleName' },
    { label: 'Last Name', accessor: 'lastName' },
    { label: 'Language', accessor: 'language' },
    { label: 'Time Format', accessor: 'timeFormat' },
    { label: 'Time Zone', accessor: 'timeZone' },
    { label: 'User Type', accessor: 'name' },
    { label: 'Company Code', accessor: 'companyCodeId' },
    { label: 'Department', accessor: 'department' },
    { label: 'Valid From', accessor: 'validFrom' },
    { label: 'Valid To', accessor: 'validTo' },
    { label: 'Email ID', accessor: 'email' },
    { label: 'Address 1', accessor: 'addressLine1' },
    { label: 'Address 2', accessor: 'addressLine2' },
    { label: 'Address 3', accessor: 'addressLine3' },
    { label: 'Country', accessor: 'country_name' },
    { label: 'Mobile No', accessor: 'mobileNo' },
    { label: 'Nationality', accessor: 'nationality' },
    { label: 'Passport No', accessor: 'passportNo' },
    { label: 'Driving License No', accessor: 'drivingLicenseNo' },
    { label: 'Local ID', accessor: 'localId' },
    { label: 'Joining Date', accessor: 'joiningDate' },
    { label: 'Resign Date', accessor: 'resignationDate' },
    { label: 'Amount Format', accessor: 'amountFormat' },
    { label: 'Date Format', accessor: 'dateFormat' },
    { label: 'Creation Date', accessor: 'creationDate' },
    { label: 'Creation Time', accessor: 'creationTime' },
    { label: 'Created By', accessor: 'createdBy' },
    { label: 'Update Date', accessor: 'changedDate' },
    { label: 'Update Time', accessor: 'changedTime' },
    { label: 'Update By', accessor: 'changedBy' },
    // Add more columns if needed
  ];

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(user =>
    user.userId && user.userId.toLowerCase().includes(searchTerm.toLowerCase())
  );

   // Expose a function to refresh the user data
   refreshTable.current = fetchUsers;
 
   
  return (
    <div className='p-4'>
     
      {/* Search Input */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', marginTop: '-60px' }}>
        <label htmlFor="searchUserId" style={{ marginRight: '16px', marginTop: '6px' }}>User ID:</label>
        <input
          type="text"
          id="searchUserId"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Enter User ID"
          style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </div>

      {/* Table */}
      <div className="overflow-x-auto" style={{ maxWidth: '1600px' }}>
        <div className="overflow-y-auto" style={{ maxHeight: '690px' }}>
          <table className="table-fixed min-w-[800px] bg-white border border-customBlue">
            <thead className="bg-customBlue text-primary-foreground text-left text-sm font-medium sticky top-0">
              <tr className="bg-primary">
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className="py-2 px-4 text-white font-medium border-r border-white whitespace-nowrap"
                    style={{ width: columnWidths[index] || 200, position: 'relative' }}
                  >
                    {col.label}
                    <div
                      className="resizer"
                      onMouseDown={(e) => handleMouseDown(e, index)}
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: '5px',
                        cursor: 'col-resize',
                        zIndex: 1
                      }}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-sm'>
              {filteredUsers.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="text-center py-4">
                    No Users found.
                  </td>
                </tr>
              )}
              {filteredUsers.map((user, index) => (
                <tr 
                key={user.id} 
                onClick={(e) => {
                  if (e.target.type !== 'checkbox') {
                    navigate(`/user-view-screen/${user.userId}`, { state: { userId: user.userId } });
                  }
                }}
                className={`border-b ${index % 2 === 0 ? 'bg-[#FFFFFF]' : 'bg-sky-50'}`}
              >
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
                    <input
                      type="checkbox"
                      className="form-checkbox cursor-pointer "
                      checked={selectedUserIds.includes(user.id)}
                      onChange={(e) => handleCheckboxChange(user.id)}
                    />
                  </td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
                    {user.approved == 3 ? (
                      <TbFlag3Filled style={{ color: "#29CB00" }} />
                    ) : user.approved == 0 ? (
                      <TbFlag3Filled style={{ color: "gray" }} />
                    ) : user.approved == 1 ? (
                      <TbFlag3Filled style={{ color: "yellow" }} />
                    ) : user.approved == 2 ? (
                      <TbFlag3Filled style={{ color: "orange" }} />
                    ) : user.approved == 4 ? (
                      <TbFlag3Filled style={{ color: "red" }} />
                    ) : (
                      <TbFlag3Filled style={{ color: "black" }} />
                    )}
                  </td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
                    {user.userStatus == 0 ? (
                      <MdOutlineLockOpen />
                    ) : (
                      <MdOutlineLock style={{ color: "red" }} />
                    )}
                  </td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.userId}</td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.employeeNo}</td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.salute}</td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.username}</td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.firstName}</td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.middleName}</td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.lastName}</td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.UserPreference?.language || ''}</td>
                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
                    {user.UserPreference?.timeFormat === '1' ? '12 hours' : 
                    user.UserPreference?.timeFormat === '2' ? '24 hours' : ''}
                  </td>

                  <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.UserPreference?.timeZone || ''}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.UserType?.name || ''}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.companyCodeId}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.department}</td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
  {user.validFrom ? (
    userPreference.dateFormat !== null 
      ? formatDate(user.validFrom, userPreference.dateFormat)
      : user.validFrom
  ) : (
    ''
  )}
</td>



    

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
      {user.validTo? (
        userPreference.dateFormat !== null ? formatDate(user.validTo, userPreference.dateFormat) : user.validTo
      ):(
        ''
      )}
    </td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.email}</td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.Address?.addressLine1 || ''}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.Address?.addressLine2 || ''}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.Address?.addressLine3 || ''}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.Address?.Country?.country_name || ''}</td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.mobileNo}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.nationality}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.passportNo}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.drivingLicenseNo}</td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.localId}</td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
  {user.joiningDate ? (
    userPreference.dateFormat !== null ? formatDate(user.joiningDate, userPreference.dateFormat) : user.joiningDate
  ) : (
    ''
  )}
</td>
 

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
      {user.resignationDate? (
      userPreference.dateFormat !== null ? formatDate(user.resignationDate, userPreference.dateFormat) : user.resignationDate
        ): (
          ''
        ) } </td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.UserPreference?.amountFormat }</td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
      {user.UserPreference?.dateFormat === '1' ? 'DD/MM/YYYY' :
      user.UserPreference?.dateFormat === '2' ? 'MM/DD/YYYY' :
      user.UserPreference?.dateFormat === '3' ? 'DD/MM/YY' :
      user.UserPreference?.dateFormat === '4' ? 'MM/DD/YY' : 
      user.UserPreference?.dateFormat === '5' ? 'DD.MM.YYYY' : 
      user.UserPreference?.dateFormat === '6' ? 'DDMMYY' : 
      ''}
    </td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
  {userPreference.dateFormat !== null
    ? formatDate(user.creationDate, userPreference.dateFormat)
    : user.creationDate }
</td>


    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{new Date(user.creationTime).toLocaleTimeString()}
    </td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.createdBy}</td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
    {user.changedDate && user.changedDate !== ' ' ? (
  userPreference.dateFormat !== null
    ? formatDate(user.changedDate, userPreference.dateFormat) 
    : user.changedDate
) : (
  ''
)}


    </td>

    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">
    {user.changedTime}
    </td>
    <td className="py-2 px-4 text-customGray border-r border-customTableBorder whitespace-nowrap">{user.changedBy}</td>
  </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {alert && (
        <Alert severity={alert.severity} onClose={() => setAlert(null)}>
          {alert.message}
        </Alert>
      )}
    </div>
  );
}
