
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import CountryServices from "../../../../../rest-services/CountryServices";
import { useToast } from "../../../../../components/toast/toast";
import * as XLSX from "xlsx";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoMdCreate, IoMdRefresh } from "react-icons/io";
import StateProvinceServices from "../../../../../rest-services/StateProvinceServices";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig.jsx";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import ReusableTable from "../../Components/Table.jsx";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup.jsx";
import CustomeUpLoader from "../../../../../components/CustomeLoader.jsx";
import ReTable from "../../../../../components/Table.jsx";
import SearchInput from "../../../../../components/SearchInput.jsx";

const MasterCountry = () => {
  const [countries, setCountries] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [countryId, setCountryId] = useState(null);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);

  const columns = [
    { Header: "Country", accessor: "country", visible: true, width: 200 },
    {
      Header: "Country Name",
      accessor: "countryName",
      visible: true,
      width: 200,
    },
    { Header: "LE", accessor: "language", visible: true, width: 200 },
    { Header: "Amt Fmt", accessor: "amountFormat", visible: true, width: 200 },
    { Header: "Date Fmt", accessor: "dateFormat", visible: true, width: 200 },
    {
      Header: "1,000-Sep",
      accessor: "thousandSeparator",
      visible: true,
      width: 200,
    },
    {
      Header: "Decimal-Sep",
      accessor: "decimalSeparator",
      visible: true,
      width: 200,
    },
    {
      Header: "Accel.(1000s)",
      accessor: "acceleratorForThousands",
      visible: true,
      width: 200,
    },
    {
      Header: "Accel.(lakhs)",
      accessor: "acceleratorForLakhs",
      visible: true,
      width: 200,
    },
    {
      Header: "Accel.(Millions)",
      accessor: "acceleratorForMillions",
      visible: true,
      width: 200,
    },
    { Header: "Created By", accessor: "createdBy", visible: true, width: 200 },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      },
    },
    {
      Header: "Created Time",
      accessor: "creationTime",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
    {
      Header: "Updated By",
      accessor: "changed_by",
      visible: true,
      width: 200,
      canResize: true,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
      },
    },
  ];

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  // Handle row selection and deletion
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this country?"
    );
    if (confirmed) {
      setLoading(true);
      try {
        await CountryServices.deleteCountry(id);
        fetchCountries();
        toast("success", "Country deleted successfully");
      } catch (error) {
        console.error("Error deleting country:", error);
        toast("error", "Failed to delete country");
      } finally {
        setLoading(false);
      }
    }
  };

  const extraButtons = [
    {
      icon: IoMdCreate,
      onClick: () => countryId && navigateToForm(countryId),
      title:"Edit Country",
      disabled: selectedRows.length === 0,  

    },
  ];

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };

  const handleDownload = () => {
    const dataToExport = filteredCountries.map((country) => ({
      "Country Code": country.country,
      "Country Name": country.countryName,
      Language: country.language,
      "Amount Format": country.amountFormat,
      "Date Format": country.dateFormat,
      "Thousand Separator": country.thousandSeparator,
      "Decimal Separator": country.decimalSeparator,
      AcceleratorForThousands: country.acceleratorForThousands,
      AcceleratorForLakhs: country.acceleratorForLakhs,
      AcceleratorForMillions: country.acceleratorForMillions,

      "Created By": country.createdBy,
      "Created Date": country.creationDate
        ? new Date(country.creationDate).toLocaleDateString()
        : "",
      "Created Time": country.creationTime
        ? new Date(country.creationTime).toLocaleTimeString()
        : "",
      "Changed By": country.changed_by,
      "Changed Date": country.changedDate
        ? new Date(country.changedDate).toLocaleDateString()
        : "",
      "Changed Time": country.changedTime
        ? new Date(country.changedTime).toLocaleTimeString()
        : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Countries");
    XLSX.writeFile(workbook, "Countries_Data.xlsx");
  };
  const handleReload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchCountries();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };


const existingButtons = PrimaryActionsConfig.screen1.map((button) => ({
  ...button,
  title:
    button.icon === IoAddCircleOutline
      ? "Add Country"
      : button.icon === MdOutlineFileDownload
      ? "Download"
      : button.icon === IoSettingsOutline
      ? "Settings"
       : button.icon === IoMdRefresh
      ? "Refresh"
      : button.title || "", // If a title already exists, use it, otherwise empty
  onClick:
    button.icon === IoAddCircleOutline
      ? () => handleNavigate("/add-country")
      : button.icon === MdOutlineFileDownload 
      ? handleDownload
      : button.icon === IoSettingsOutline
      ? handleSettingsButtonClick
      : button.icon === IoMdRefresh
      ? handleReload
      : button.onClick || (() => {}),
}));


const actionButtons = existingButtons.concat(
  extraButtons.map((button) => ({
    ...button,
    disabled: selectedRows.length === 0, // Disable button if no users are selected
    onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
  }))
);

  const handleNavigate = (path) => {
    navigate(path);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    const filtered = countries.filter((country) =>
      country.countryName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCountries(filtered);
  }, [searchQuery, countries]);
  console.log(filteredCountries);
  const fetchCountries = async () => {
    setLoading(true);
    try {
      const response = await StateProvinceServices.getAllStateprovince(
        `/get-country`
      );
      const responseData = response.data;

      if (responseData.error) {
        setAlert({ severity: "error", message: responseData.message });
        return;
      }

      if (responseData.data && Array.isArray(responseData.data)) {
        setCountries(responseData.data);
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching countries: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const navigateToForm = (id) => {
    navigate(`/add-country/${id}`);
  };

  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setCountryId(id);
    setShowExtraIcons(updatedSelectedRows.length > 0);
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = countries.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  if (loading)
    return (
      <div>
      </div>
    );
  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "Global Master Data") {
      navigate("/master-data");
    } else if (crumb.title === "Country") {
      navigate("/country");
    } else if (crumb.title === "Primary Data") {
      console.log("Navigating to /country");
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const handleDoubleClick = (id) => {
    navigate(`/country-view/${id}`, { state: { mode: "view" } });
  };

  return (
    <div>
      <Header
        title={"Primary Data > Global Master Data > Country"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <div>
        <div className="flex justify-between -ml-1">
          <PrimaryActions
            // buttons={[...existingButtons]}
            buttons={actionButtons} 
            selectedCount={selectedRows.length} 

            // extraButtons={extraButtons}
            isExtraButtonsVisible={showExtraIcons}
          />
          <div className="flex items-center">
            <p className="mr-4 text-customBlue">Country Code :</p>

            <SearchInput 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            placeholder="Search by Country" 
          />
          </div>
        </div>
        <ReTable
          data={filteredCountries}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
    </div>
  );
};

export default MasterCountry;
