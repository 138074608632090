import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  GlobeIcon,
  MapIcon,
  LanguageIcon,
  SourceCodeIcon,
} from "../../../components/SVG/FigmaIcons";
import { LuCalendarDays, LuUpload } from "react-icons/lu";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { CiBank } from "react-icons/ci";
import Dashboard from "../../../components/Dashboard";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import {
  MdCurrencyYen,
  MdOutlineGroups,
  MdOutlinePentagon,
} from "react-icons/md";
import { FaCodeBranch, FaUserClock } from "react-icons/fa6";
import { PiFolderUser } from "react-icons/pi";
import { RiAccountBoxLine } from "react-icons/ri";
import { FaExchangeAlt } from "react-icons/fa";
import MainHeader from "../../../components/MainHeader";
import { LiaUserClockSolid } from "react-icons/lia";
import { IoIosGitMerge } from "react-icons/io";
import { TbArrowsRightLeft } from "react-icons/tb";
import { useSearch } from "../../../context/SearchContext";
import UserService from "../../../rest-services/UserServices";
import { useToast } from "../../../components/toast/toast";
 
export default function MasterDataComponent() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const { highlightInfo } = useSearch();
  const [highlightedCard, setHighlightedCard] = useState(null);
    const [assignee, setAssignee] = useState({ id: "", username: "" });

    const [hasPermissionForCountry,SethasPermissionForCountry]= useState("");
    const [hasPermissionForState,SethasPermissionForState]= useState("");
    const [hasPermissionForCalendar,SethasPermissionForCalendar]= useState("");
    const [hasPermissionForCurrency,SethasPermissionForCurrency]= useState("");
    const [hasPermissionForBank,SethasPermissionForBank]= useState("");
    const [hasPermissionForLanguage,SethasPermissionForLanguage]= useState("");
    const [hasPermissionForLegalEntity,SethasPermissionForLegalEntity]= useState("");
    const [hasPermissionForBUGroup,SethasPermissionForBUGroup]= useState("");
    const [hasPermissionForCompany,SethasPermissionForCompany]= useState("");
    const [hasPermissionForBankID,SethasPermissionForBankID]= useState("");
    const [hasPermissionForBankBranch,SethasPermissionForBankBranch]= useState("");
    const [hasPermissionForMDSource,SethasPermissionForMDSource]= useState("");
    const [hasPermissionForSource,SethasPermissionForSource]= useState("");
    const [hasPermissionForCurrencyRate,SethasPermissionForCurrencyRate]= useState("");
    const [hasPermissionForUploads,SethasPermissionForUploads]= useState("");
    const [hasPermissionForCOA,SethasPermissionForCOA]= useState("");
    const [hasPermissionForAccountMaster,SethasPermissionForAccountMaster]= useState("");
    const [hasPermissionForAccountingCode,SethasPermissionForAccountingCode]= useState("");
    const [hasPermissionForAccountingPeriod,SethasPermissionForAccountingPeriod]= useState("");
    const [hasPermissionForProfitCost,SethasPermissionForProfitCost]= useState("");
const { toast } = useToast();

  const navigate = useNavigate();
// In MasterDataComponent
useEffect(() => {
  if (highlightInfo?.dashboard === 'master-data') {
    const term = highlightInfo.term.toLowerCase();
    const cardTitles = [
      "country", "state", "calendar", "currency", "bank", "language",
              "legal entity", "bugroup", "company", "bankid", "branch",
              "md source", "source", "currency rate", "uploads", "coa", "account master",
              "accounting code", "accounting period", "profit cost"
    ];
   
    const matchedTitle = cardTitles.find(title =>
      title.toLowerCase() === term
    );
 
    if (matchedTitle) {
      setHighlightedCard(matchedTitle);
      const timer = setTimeout(() => {
        setHighlightedCard(null);
        // Optionally clear the highlight from context
        // setHighlightInfo(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }
}, [highlightInfo]);

useEffect(() => {
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        console.error({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      console.error({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

    useEffect(() => {
        if (assignee.assignerId) {
          console.log("Assigner ID has changed:", assignee.assignerId);
          fetchUserIconAccessPermission();
        }
      }, [assignee.assignerId]);
  
      const fetchUserIconAccessPermission = async () => {
        try {
          const data = { userId: assignee.assignerId };
          // console.log("Request Data:", data);
    
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-Icon-access-permission-by-userId`,
            data
          );
          const responseData = response.data;
          console.log("user icon permissions details", responseData);  
          
          const hasPermissionForCountry = responseData.some(
            (Icon) => Icon.iconId == 1 && Icon.isChecked == 1
          );
        
          const hasPermissionForState = responseData.some(
            (Icon) => Icon.iconId == 2 && Icon.isChecked == 1
          );
  
          const hasPermissionForCalendar = responseData.some(
            (Icon) => Icon.iconId == 3 && Icon.isChecked == 1
          );

          const hasPermissionForCurrency = responseData.some(
            (Icon) => Icon.iconId == 4 && Icon.isChecked == 1
          );

          const hasPermissionForBank = responseData.some(
            (Icon) => Icon.iconId == 5 && Icon.isChecked == 1
          );

          const hasPermissionForLanguage = responseData.some(
            (Icon) => Icon.iconId == 6 && Icon.isChecked == 1
          ); 

          const hasPermissionForLegalEntity = responseData.some(
            (Icon) => Icon.iconId == 7 && Icon.isChecked == 1
          );

          const hasPermissionForBUGroup = responseData.some(
            (Icon) => Icon.iconId == 8 && Icon.isChecked == 1
          );

          const hasPermissionForCompany = responseData.some(
            (Icon) => Icon.iconId == 9 && Icon.isChecked == 1
          );

          const hasPermissionForBankID = responseData.some(
            (Icon) => Icon.iconId == 10 && Icon.isChecked == 1
          );

          const hasPermissionForBankBranch = responseData.some(
            (Icon) => Icon.iconId == 11 && Icon.isChecked == 1
          );

          const hasPermissionForMDSource = responseData.some(
            (Icon) => Icon.iconId == 12 && Icon.isChecked == 1
          );

          const hasPermissionForSource = responseData.some(
            (Icon) => Icon.iconId == 13 && Icon.isChecked == 1
          );

          const hasPermissionForCurrencyRate = responseData.some(
            (Icon) => Icon.iconId == 14 && Icon.isChecked == 1
          );

          const hasPermissionForUploads = responseData.some(
            (Icon) => Icon.iconId == 15 && Icon.isChecked == 1
          );

          const hasPermissionForCOA = responseData.some(
            (Icon) => Icon.iconId == 16 && Icon.isChecked == 1
          );

          const hasPermissionForAccountMaster = responseData.some(
            (Icon) => Icon.iconId == 17 && Icon.isChecked == 1
          );

          const hasPermissionForAccountingCode = responseData.some(
            (Icon) => Icon.iconId == 18 && Icon.isChecked == 1
          );

          const hasPermissionForAccountingPeriod = responseData.some(
            (Icon) => Icon.iconId == 19 && Icon.isChecked == 1
          );

          const hasPermissionForProfitCost = responseData.some(
            (Icon) => Icon.iconId == 20 && Icon.isChecked == 1
          );


          SethasPermissionForCountry(hasPermissionForCountry);
          SethasPermissionForState(hasPermissionForState);
          SethasPermissionForCalendar(hasPermissionForCalendar);
          SethasPermissionForCurrency(hasPermissionForCurrency);
          SethasPermissionForBank(hasPermissionForBank);
          SethasPermissionForLanguage(hasPermissionForLanguage);
          SethasPermissionForLegalEntity(hasPermissionForLegalEntity);
          SethasPermissionForBUGroup(hasPermissionForBUGroup);
          SethasPermissionForCompany(hasPermissionForCompany);
          SethasPermissionForBankID(hasPermissionForBankID);
          SethasPermissionForBankBranch(hasPermissionForBankBranch);
          SethasPermissionForMDSource(hasPermissionForMDSource);
          SethasPermissionForSource(hasPermissionForSource);
          SethasPermissionForCurrencyRate(hasPermissionForCurrencyRate);
          SethasPermissionForUploads(hasPermissionForUploads);
          SethasPermissionForCOA(hasPermissionForCOA);
          SethasPermissionForAccountMaster(hasPermissionForAccountMaster);
          SethasPermissionForAccountingCode(hasPermissionForAccountingCode);
          SethasPermissionForAccountingPeriod(hasPermissionForAccountingPeriod);
          SethasPermissionForProfitCost(hasPermissionForProfitCost);
        } catch (error) {
          console.error(error.message); // Set error message
        }
      };

      const countryPermission = async () => {
        toast("error", "You don't have permission to view this Country icon.");
      }

      const statePermission = async () => {
        toast("error", "You don't have permission to view this State icon.");
      }

      const calendarPermission = async () => {
        toast("error", "You don't have permission to view this Calendar icon.");
      }

      const currencyPermission = async () => {
        toast("error", "You don't have permission to view this Currency icon.");
      }
  
      const bankPermission = async () => {
        toast("error", "You don't have permission to view this Bank icon.");
      }

      const languagePermission = async () => {
        toast("error", "You don't have permission to view this Language icon.");
      }
      const legalEntityPermission = async () => {
        toast("error", "You don't have permission to view this Legal Entity icon.");
      }

      const buGroupPermission = async () => {
        toast("error", "You don't have permission to view this BU Group icon.");
      }

      const companyPermission = async () => {
        toast("error", "You don't have permission to view this Company icon.");
      }

      const bankIDPermission = async () => {
        toast("error", "You don't have permission to view this Bank ID icon.");
      }

      const BankBranchPermission = async () => {
        toast("error", "You don't have permission to view this Bank Branch icon.");
      }

      const MDSourcePermission = async () => {
        toast("error", "You don't have permission to view this MD Source icon.");
      }

      const SourcePermission = async () => {
        toast("error", "You don't have permission to view this Source icon.");
      }
      const CurrencyRatePermission= async () => {
        toast("error", "You don't have permission to view this Currency Rate icon.");
      }
      const UploadsPermission= async () => {
        toast("error", "You don't have permission to view this Uploads icon.");
      }

      const COAPermission= async () => {
        toast("error", "You don't have permission to view this COA icon.");
      }

      const AccountMasterPermission= async () => {
        toast("error", "You don't have permission to view this Account Master icon.");
      }

      const AccountingCodePermission= async () => {
        toast("error", "You don't have permission to view this Accounting Code icon.");
      }

      const AccountingPeriodPermission= async () => {
        toast("error", "You don't have permission to view this Accounting Period icon.");
      }
      const ProfitCostPermission= async () => {
        toast("error", "You don't have permission to view this Profit Cost icon.");
      }
  // Move the sections creation logic to a separate function
  const createSections = (menus, highlighted) => {
    return menus.map((menu) => {
      let cards;
 
      if (menu.name === "Global Data") {
        cards = [
          {
            title: "Country",
            icon: <GlobeIcon />,
            isHighlighted: highlighted === "country",
            onClick: hasPermissionForCountry === false
            ? countryPermission 
            : () => handleCardClick("/2/master-data/1/country"),
          },
          {
            title: "State/Province",
            icon: <MapIcon />,
            isHighlighted: highlighted === "state",
            onClick: hasPermissionForState === false
            ? statePermission
            : () => handleCardClick("/2/master-data/2/state-province"),
          },
          {
            title: "Calendar",
            icon: <LuCalendarDays />,
            isHighlighted: highlighted === "calendar",
            onClick: hasPermissionForCalendar === false
            ? calendarPermission
            : () => handleCardClick("/2/master-data/3/calendar"),
          },
          {
            title: "Currency",
            icon: <HiOutlineCurrencyDollar />,
            isHighlighted: highlighted === "currency",
            onClick: hasPermissionForCurrency === false
            ? currencyPermission
            : () => handleCardClick("/2/master-data/4/currency"),
          },
          {
            title: "Bank",
            icon: <CiBank />,
            isHighlighted: highlighted === "bank",
            onClick: hasPermissionForBank === false
            ? bankPermission
            : () => handleCardClick("/2/master-data/5/bank"),
          },
          {
            title: "Language",
            icon: <LanguageIcon />,
            isHighlighted: highlighted === "language",
            onClick: hasPermissionForLanguage === false
            ? languagePermission
            : () => handleCardClick("/2/master-data/6/language"),
          },
        ];
      } else if (menu.name === "Company & Bank Data") {
        cards = [
          {
            title: "Legal Entity",
            icon: <HiOutlineBuildingOffice2 />,
            isHighlighted: highlighted === "legal entity",
            onClick: hasPermissionForLegalEntity === false
            ? legalEntityPermission
            : () => handleCardClick("/2/master-data/7/legalentity"),
          },
          {
            title: "BU Group ",
            icon: <MdOutlineGroups />,
            isHighlighted: highlighted === "bugroup",
            onClick: hasPermissionForBUGroup === false
            ? buGroupPermission
            : () => handleCardClick("/2/master-data/8/bugroup"),
          },
          {
            title: "Company",
            icon: <HiOutlineBuildingOffice2 />,
            isHighlighted: highlighted === "company",
            onClick: hasPermissionForCompany === false
            ? companyPermission
            : () => handleCardClick("/2/master-data/9/company"),
          },
          {
            title: "Bank ID",
            icon: <CiBank />,
            isHighlighted: highlighted === "bankid",
            onClick: hasPermissionForBankID === false
            ? bankIDPermission
            : () => handleCardClick("/2/master-data/10/bankidscreen"),
          },
          {
            title: "Bank Branch",
            icon: <IoIosGitMerge />,
            isHighlighted: highlighted === "branch",
            onClick: hasPermissionForBankBranch === false
            ? BankBranchPermission
            : () => handleCardClick("/2/master-data/11/bankbranch"),
          },
        ];
      } else if (menu.name === "Market data") {
        cards = [
          {
            title: "MD Source",
            icon: <TbArrowsRightLeft />,
            isHighlighted: highlighted === "md source",
            onClick: hasPermissionForMDSource === false
            ? MDSourcePermission
            : () => handleCardClick("/2/master-data/12/md-source"),
          },
          {
            title: "Source",
            icon: <SourceCodeIcon />,
            isHighlighted: highlighted === "source",
            onClick: hasPermissionForSource === false
            ? SourcePermission
            : () => handleCardClick("/2/master-data/13/source-code"),
          },
          {
            title: "Currency Rate",
            icon: <MdCurrencyYen />,
            isHighlighted: highlighted === "currency rate",
            onClick: hasPermissionForCurrencyRate === false
            ? CurrencyRatePermission
            : () => handleCardClick("/2/master-data/14/currency-rate"),
          },
          {
            title: "Uploads",
            icon: <LuUpload />,
            isHighlighted: highlighted === "Uploads",
            onClick: hasPermissionForUploads === false
            ? UploadsPermission
            : () => handleCardClick("/2/master-data/15/market-data-uploads-add"),
          },
        ];
      } else if (menu.name === "Accounts") {
        cards = [
          {
            title: "COA",
            icon: <MdOutlinePentagon />,
            isHighlighted: highlighted === "COA",
            onClick: hasPermissionForCOA === false
            ? COAPermission
            : () => handleCardClick("/negative-balances"),
          },
          {
            title: "Account Master",
            icon: <RiAccountBoxLine />,
            isHighlighted: highlighted === "Account Master",
            onClick: hasPermissionForAccountMaster === false
            ? AccountMasterPermission
            : () => handleCardClick("/negative-balances"),
          },
          {
            title: "Accounting Code",
            icon: <PiFolderUser />,
            isHighlighted: highlighted === "Accounting Code",
            onClick: hasPermissionForAccountingCode === false
            ? AccountingCodePermission
            : () => handleCardClick("/negative-balances"),
          },
          {
            title: "Accounting Period",
            icon: <LiaUserClockSolid />,
            isHighlighted: highlighted === "Accounting Period",
            onClick: hasPermissionForAccountingPeriod === false
            ? AccountingPeriodPermission
            : () => handleCardClick("/negative-balances"),
          },
          {
            title: "Profit Cost",
            icon: <CiBank />,
            isHighlighted: highlighted === "Profit Cost",
            onClick: hasPermissionForProfitCost === false
            ? ProfitCostPermission
            : () => handleCardClick("/negative-balances"),
          },
        ];
      } else {
        cards = [];
      }
 
      return {
        title: menu.name,
        subMenuId: menu.id,
        cards: cards,
      };
    });
  };
 
  useEffect(() => {
    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };
 
    fetchUserId();
 
    // Fetch sub-menu data
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(
          (menu) => menu.mainMenuId === 2
        );
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));
 
    // Fetch module assignment data
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
      .then((response) => {
        const filteredAssignments = response.data.filter(
          (assignment) =>
            assignment.userId === String(userId) &&
            assignment.isChecked === true
        );
        setModuleAssignments(filteredAssignments);
      })
      .catch((error) =>
        console.error("Error fetching module assignments:", error)
      );
  }, [userId]);
 
  useEffect(() => {
    const handleSearchHighlight = (event) => {
      if (event.detail?.section === 'master-data') {
        setHighlightedCard(event.detail.term);
        setTimeout(() => setHighlightedCard(null), 3000);
      }
    };
 
    window.addEventListener('search-highlight', handleSearchHighlight);
    return () => window.removeEventListener('search-highlight', handleSearchHighlight);
  }, []);
 
  const handleCardClick = (route) => {
    navigate(route);
  };
 
  // Filter subMenus based on moduleAssignments
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });
 
  // Define an order for submenus to control their display order
  const submenuOrder = [
    "Global Master Data",
    "Company & Bank Master Data",
    "Market data",
    "Accounts",
  ];
 
  // Sort the filteredSections by the defined submenuOrder
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });
 
  // Create sections with current highlighted card
  const sections = createSections(filteredSections, highlightedCard);
 
  return (
    <>
      <div>
        <MainHeader title={"Primary Data"} />
        <Dashboard sections={sections} />
      </div>
    </>
  );
}