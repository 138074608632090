import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../../../../components/Header';
import PrimaryActions from '../../Components/ActionButton';
import {  IoMdCreate, IoMdRefresh,  } from 'react-icons/io';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryActionsConfig } from '../../Components/ActionButtonConfig';
import LanguageServices from '../../../../../rest-services/LanguagesServices';
import { useToast } from '../../../../../components/toast/toast';

import * as XLSX from 'xlsx'; // Import xlsx library
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md';
import ColumnSettingsPopup from '../../Components/ColumnSettingPopup';

import ReTable from '../../../../../components/Table';
import SearchInput from '../../../../../components/SearchInput';
import { debounce } from 'lodash';
import Pagination from '../../../../../components/PageNation';
import UserService from '../../../../../rest-services/UserServices';

export default function LanguageScreen() {
    const { iconId } = useParams();
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedLanguageId, setSelectedLanguageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");


  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20); // Default page size
  const [rolesPermissions, setRolesPermissions] = useState([]);
      const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    fetchAssignee();
  }, []);

const fetchAssignee = async () => {
  try {
    const userDetailString = localStorage.getItem("userDetail");

    if (userDetailString) {
      const userDetail = JSON.parse(userDetailString);
      const assigneeData = {
        id: userDetail.id || "",
        assigner: userDetail.username || "",
        clientId: userDetail.clientId || "",
        assignerId: userDetail.userId || "",
      };
      console.log("assigneeData is", assigneeData);

      setAssignee(assigneeData);

      // Log the id and assigner values to the console
      console.log("Assignee ID:", assigneeData.id);
      console.log("Assigner:", assigneeData.assigner);
      console.log("Assigner userId:", assigneeData.assignerId);
      console.log("Assigner clientId:", assigneeData.clientId);
    } else {
      setAlert({
        severity: "error",
        message: "No user detail found in session storage.",
      });
    }
  } catch (error) {
    setAlert({
      severity: "error",
      message: `Error fetching user details: ${error.message}`,
    });
  }
};

  useEffect(() => {
    if (assignee.assignerId) {
      console.log("Assigner ID has changed:", assignee.assignerId);
      fetchUserPermissionDetails();
    }
  }, [assignee.assignerId]);

const fetchUserPermissionDetails = async () => {
  try {
    const data = { userId: assignee.assignerId };
    console.log("Request Data:", data);

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
      data
    );
    console.log("user permissions details", response);
    const responseData = response.data;
    setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
    console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
  } catch (error) {
    console.error(error.message); // Set error message
  } finally {
    setLoading(false); // Reset loading state
  }
};

  const columns = [
    { Header: "LE", accessor: "LE", visible: true, width:("LE".length -2)*10 ,alignment: "left"},
    { Header: "Description", accessor: "Description", visible: true, width:("Description".length -2)*10 ,alignment: "left" },
    { Header: "Created By", accessor: "created_by", visible: true, width:("Created by".length -2)*10 ,alignment: "left" },
    {
      
        Header: "Created Date",
        accessor: "creationDate",
        visible: true,
        width:("Created Date".length -2)*10,
        canResize: true,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Change here
        }
      },
      {
        Header: "Created Time",
        accessor: "creationTime", 
        visible: true,
        width:("Created Time".length -2)*10,
        canResize: true,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return date.toLocaleTimeString("en-GB", {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true, 
          });
        }
      },
      { Header: "Updated By", accessor: "changed_by", visible: true, width:("Updated By".length -2)*10, canResize: true ,alignment: "left" },
      {
        Header: "Updated Date",
        accessor: "changedDate",
        visible: true,
        width:("Updated Date".length -2)*10,
        canResize: true,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`; // Change here
        }
      },
      {
        Header: "Updated Time",
        accessor: "changedTime", 
        visible: true,
        width:("Updated Time".length -2)*10,
        canResize: true,
        Cell: ({ value }) => {
          if (!value) return "";
          const date = new Date(value);
          return date.toLocaleTimeString("en-GB", {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',

            hour12: true, 
          });
        }
      },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  useEffect(() => {
     fetchLanguages(currentPage, searchQuery);
   }, [pageSize, currentPage]);
 

  const fetchLanguages = async (page = 1, searchQuery = "") => {
    setLoading(true);
    try {
      const response = await LanguageServices.getAllCountrywithPage(
        pageSize,
        page,
        searchQuery
      );
      let { LE, totalCount } = response.data.data;
      if (!LE) {
        LE = [];
      } else if (!Array.isArray(LE)) {
        LE = [LE];
      }
      setFilteredLanguages(LE);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching language data", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced fetch function (to avoid too many API calls)
  const debouncedFetchlanguages = useCallback(
    debounce((page, query) => {
      fetchLanguages(page, query);
    }, 500), // 500ms debounce delay
    []
  );

  // UseEffect to trigger API call on query or page change
  useEffect(() => {
    debouncedFetchlanguages(currentPage, searchQuery);
    // Cleanup debounce on unmount
    return () => debouncedFetchlanguages.cancel();
  }, [currentPage, searchQuery, debouncedFetchlanguages]);
  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchLanguages(1, searchQuery); // Fetch data with updated page size
  };
  const modifiedColumns = columnSettings.filter(col => col.visible).map(col => ({
    ...col,
    width: col.width // This should hold the updated width
  }));
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };
  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleNavigate = (path) => {
    navigate(path);
  };

  // Move handleDownload before existingButtons
const handleDownload = () => {
  // Create an array of data to download
  const dataToExport = filteredLanguages.map((language) => ({
    "Language Code": language.LE,
    "Language Name": language.Description,
    "Created By": language.created_by,
    "Created Date": language.creationDate
      ? new Date(language.creationDate).toLocaleDateString()
      : "",
    "Created Time": language.creationTime
      ? new Date(language.creationTime).toLocaleTimeString()
      : "",
    "Updated By": language.changed_by,
    "Updated Date": language.changedDate
      ? new Date(language.changedDate).toLocaleDateString()
      : "",
    "Updated Time": language.changedTime
      ? new Date(language.changedTime).toLocaleTimeString()
      : "",
  }));

  // Create a new worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(dataToExport);

  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Languages");

  // Generate Excel file and trigger download
  XLSX.writeFile(workbook, "Languages_Data.xlsx");
};
const handleReload = async () => {
  setLoading(true);  // Start loading
  try {
    await fetchLanguages();  // Fetch users
  } catch (error) {
    console.error('Error reloading users:', error);
  } finally {
    setLoading(false);  // Stop loading
  }
};
const extraButtons = [
  { icon: IoMdCreate, onClick: () => selectedLanguageId && navigateToForm(selectedLanguageId) ,title:"Edit language",disabled: selectedRows.length === 0, }
];
// Now define existingButtons
// const existingButtons = PrimaryActionsConfig.screen5.map((button) => ({
//   ...button,
//   title:
//     button.icon === IoAddCircleOutline
//       ? "Add Language"
//       : button.icon === MdOutlineFileDownload
//       ? "Download"
//       : button.icon === IoSettingsOutline
//       ? "Settings"
//           : button.icon === IoMdRefresh
//       ? "Refresh"
//       : button.title || "", // If a title already exists, use it, otherwise empty
//   onClick:
//     button.icon === IoAddCircleOutline
//       ? () => handleNavigate("/language-add")
//       : button.icon === MdOutlineFileDownload 
//       ? handleDownload
//       : button.icon === IoSettingsOutline
//       ? handleSettingsButtonClick
//       : button.icon === IoMdRefresh
//       ? handleReload
//       : button.onClick || (() => {}),
// }));


 const existingButtons = PrimaryActionsConfig.screen5.map((button) => {
    let onClick;
    let title;

    if (button.icon === IoMdRefresh) {
      onClick = handleReload; // Reference the reload function, not invoking it
      title = "Refresh";
    } else if (button.icon === IoAddCircleOutline) {
        onClick = () => handleNavigate("/language-add");
      title = "Add Language";
    } else if (button.icon === IoSettingsOutline) {
      onClick = handleSettingsButtonClick;
      title = "Table Settings";
    }  else if (button.icon === MdOutlineFileUpload) {
       onClick = () => handleNavigate("/2/master-data/6/language-upload");
      title = "Upload File";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = handleDownload;
      title = "Download File";
    } else {
      onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
      title = button.title || ""; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title, // Add the title property to the button configuration
    };
  });


const actionButtons = existingButtons.concat(
  extraButtons.map((button) => ({
    ...button,
    disabled: selectedRows.length === 0, // Disable button if no users are selected
    onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
  }))
);



  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setSelectedLanguageId(id);
    setShowExtraIcons(updatedSelectedRows.length > 0);
  };

  const navigateToForm = (id) => {
    navigate(`/language-add/${id}`);
  };
  const handleDoubleClick = (id) => {
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );

    console.log("Check user permissions with iconId", hasPermission);

    if (hasPermission) {
    navigate(`/language-view/${id}`, { state: { mode: 'view' } }); 
    console.log("Navigated to user view screen.");
  } else {
    console.log(
      "No permission with permissionsId === 3 and matching iconId, navigation skipped."
    );
  }
 };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = filteredLanguages.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  if (loading) return <div></div>;
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {
      console.log("Navigating to /2/master-data");
      navigate('/2/master-data');
    } else if (crumb.title === 'language') {
      console.log("Navigating to /country");
      navigate('/2/master-data/6/language');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); 
    }
  };
  const handleSearch = (query) => {
    // When Enter key is pressed, fetch the data
    setSearchQuery(query);
    fetchLanguages(currentPage, query);  // Fetch data based on search query
  };
  return (
    <div>
   
      <Header title={"Primary Data > Global Data > Language"}
      onBreadcrumbClick={handleBreadcrumbClick}
      backRoute={"/2/master-data"} />
      
      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">

        <SearchInput
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        placeholder="Search by Lge"
        maxLength={2}
        onSearch={handleSearch}  // Pass the handleSearch function here
      />


          <PrimaryActions
           icons={iconId}
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            isExtraButtonsVisible={showExtraIcons}
            selectIds={selectedRows}
          />
          </div>
          <div className="flex items-center mr-4">

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
            onShowAll={handleShowAll}
          />
          
        </div>
        </div>

        <div>
         
        </div>
      <ReTable
          data={filteredLanguages}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
    </div>
  );
}
