import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { IoMdCloudDownload } from 'react-icons/io';
import * as XLSX from "xlsx";
import { useToast } from '../../../../../../components/toast/toast';
import UserService from '../../../../../../rest-services/UserServices';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import Footer from '../../../../../../components/footer';
import Header from '../../../../../../components/Header';
import UploadComponent from '../../../../../../components/UploadComponent';

export default function BUGroupsUpload() {
const navigate = useNavigate();

    const { iconId } = useParams();
const { toast } = useToast();
const [tableData, setTableData] = useState([]);
const [buttonText, setButtonText] = useState("Upload from desktop");
const [isUploaded, setIsUploaded] = useState(false);
const [displayOption, setDisplayOption] = useState(""); // Default value
const [uploadOption, setUploadOption] = useState(""); // Default value
const [isDisplayChecked, setIsDisplayChecked] = useState(false); // State for checkbox
const [isUploadChecked, setIsUploadChecked] = useState(false); // State for checkbox
const [isError, setIsError] = useState(false);
const [showConfirmation, setShowConfirmation] = useState(false);
const [assignee, setAssignee] = useState({ id: "", username: "" });
const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

const allowedFormat = ["Action","SLNo","BuGroup","BuGroupDescription"]; 


  useEffect(() => {
    fetchAssignee();
  }, []);
      
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",

        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        // console.log("Assignee ID:", assigneeData.id);
        // console.log("Assigner:", assigneeData.assigner);
        // console.log("Assigner clientId:", assigneeData.clientId);
        // console.log("Assigner userId:", assigneeData.userId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

const cancelDeletion = () => {
  setShowConfirmation(false);
};

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Bu Group") {
      // navigate("/2/master-data/8/bugroup");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  
const confirmDeletion = async () => {
  console.log("Download the excel sheet");
  // Simulate the download by creating an anchor element
  const fileUrl = "/BUGroup_Upload.xlsx"; 
  console.log("file ur is",fileUrl);
  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.download = "BUGroup_Upload.xlsx"; // Set the file name for download
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (!file) return;
  
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Get first sheet
        const sheet = workbook.Sheets[sheetName];
  
        // Ensure we read only headers
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
        // console.log("Raw Sheet Data:", jsonData); // Debugging: Check the first row
  
        if (jsonData.length === 0) {
          setButtonText("File Format is Incorrect !");
          setIsUploaded(false);
          setIsError(true);
          return;
        }
  
        // Extract headers from the first row
        const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
        const expectedHeaders = allowedFormat.map(header => header.trim().toLowerCase());
  
        // console.log("Uploaded Headers:", uploadedHeaders);
        // console.log("Expected Headers:", expectedHeaders);
  
        // Validate headers
        const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));
  
        if (isValidFormat) {
          // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
          const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);
  
        // Convert Excel date serial numbers to readable dates
        const formattedRows = rows.map((row) => {
          const formattedRow = { ...row };

      
          return formattedRow;
        });
      
  
        console.log("set Table Data:", formattedRows);
  
        setTableData(formattedRows);
          setButtonText("File Uploaded");
          setIsUploaded(true);
          setIsError(false);
        } else {
          setButtonText("File Format is Incorrect !");
          setIsUploaded(false);
          setIsError(true);
          setShowConfirmation(true);
        }
  
        setTimeout(() => setIsUploaded(false), 1000);
      };
      reader.readAsArrayBuffer(file);
    };
  
    
  const handleSubmit = async () => {
    // console.log("table data length is",tableData.length);

  if(buttonText === 'Upload from desktop'){
      toast("error", "Please upload a file.");
      return;
  }
  else if (tableData.length === 0) { 
      // alert("Please upload a file before submitting."); // Validation alert
      toast("error", "Please upload the correct file containing the data.");
      // toast("error", "Please upload a file containing at least one record.");
      return;
  }

       // Check if either of the checkboxes is selected
  if (!isDisplayChecked && !isUploadChecked) {
      toast("error", "Please check at least one checkbox (Display or Upload).");
      return;
  }


  console.log("tableData send to the prop",tableData);
  // console.log("displayOption selected", displayOption);
  // console.log("uploadOption selected", uploadOption);

  if(uploadOption !== '' && displayOption === '' ){
    console.log("User arry upload data");
    storeArrayDataForUsers();
  }
  else {
    if(uploadOption != ''){
      storeArrayDataForUsers();
    }
    navigate(`/2/master-data/8/bugroup-upload/display-screen`, {
          state: { tableData, displayOption, uploadOption } // Passing tableData via state
      });
  }
}



const getExistingBugroupIds = async (tableData) => {
  try {
    console.log("BugroupIds", tableData);
    
    const validRows = tableData.filter((row, index) => isRowValid(row, index));

    // Make API call to fetch existing user IDs
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BuGroup-duplicate/BugroupId`,
      { validRows }
    );
    console.log("errored records user id's : Response data", response.data);
    const existingBuGroupIds = response.data; // Extract existing user IDs from API response

    // Remove duplicates based on API response
    const withoutDuplicateUserIds = await filterExistingUserIds(validRows, existingBuGroupIds);

    // Further filter the results to ensure only valid rows are returned
    // const validWithoutDuplicateUserIds = withoutDuplicateUserIds.filter((row) =>
    //   isRowValid(row)
    // );

    console.log("successfuland processed Records data:", withoutDuplicateUserIds);

    return withoutDuplicateUserIds;

  } catch (error) {
    console.error("Error fetching existing user IDs:", error);
    return [];
  }
};


const filterExistingUserIds = async (tableData, existingBuGroupIds) => {
  // const existingBuGroupSet = new Set(existingBuGroupIds.map(item => item.BU_Group));
  return tableData.filter((row) => existingBuGroupIds.includes(row.BuGroup));
//   return tableData.filter((row) => !existingBuGroupSet.has(row.BuGroup));
};

    // const isRowValid = (row) => {
      // const isRowValid = (row) => {
      //   // Common validations
      //   const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
      //   const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
      //   const isBuGroupValid = row.BuGroup && String(row.BuGroup).length <= 10;
      
      //   // Action "A" or "a" specific validations
      //   if (row.Action === "A" || row.Action === "a") {
      //     const isBuGroupDescriptionValid = row.BuGroupDescription && String(row.BuGroupDescription).length <= 40;
         
      //     return (
      //       isActionValid &&
      //       isSLNoValid &&
      //       isBuGroupValid &&
      //       isBuGroupDescriptionValid
      //     );
      //   }
      
      //   // Action "E" or "e" specific validations
      //   if (row.Action === "E" || row.Action === "e") {
      //     const isBuGroupDescriptionValid = row.BuGroupDescription ? String(row.BuGroupDescription).length <= 40 : true; 
         
      //     return (
      //       isActionValid &&
      //       isSLNoValid &&
      //       isBuGroupValid &&
      //       isBuGroupDescriptionValid 
      //     );
      //   }
      
      //   // Default case if no matching Action
      //   return (
      //     isActionValid &&
      //     isSLNoValid &&
      //     isBuGroupValid
      //   );
      
      // };
      const isRowValid = (row, index) => {
        // Common validations
        const isActionValid = row.Action && ["A", "a"].includes(row.Action) && String(row.Action).length === 1;
        const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
        
        let errors = [];
        
        if (!isActionValid) errors.push("Invalid Action");
        if (!isSLNoValid) errors.push("Invalid SLNo");
      
        // Action "A" or "a" specific validations
        if (row.Action === "A" || row.Action === "a") {
          const isBuGroupValid = row.BuGroup && String(row.BuGroup).length <= 10;
          const isBuGroupDescriptionValid = row.BuGroupDescription && String(row.BuGroupDescription).length <= 40;
         
         
          if (!isBuGroupValid) errors.push("Invalid A BuGroup Code");
          if (!isBuGroupDescriptionValid) errors.push("Invalid A BuGroup Description");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
          return (
          isBuGroupValid  &&
          isBuGroupDescriptionValid
          );
        }
      
        // Action "E" or "e" specific validations
        if (row.Action === "E" || row.Action === "e") {
            const isBuGroupValid = row.BuGroup && String(row.BuGroup).length <= 10;

          const isBuGroupDescriptionValid = row.BuGroupDescription ? String(row.BuGroupDescription).length <= 40 : true;
         
          if (!isBuGroupValid) errors.push("Invalid E BuGroup");
          if (!isBuGroupDescriptionValid) errors.push("Invalid E BuGroup Description");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
  
          return (
            isBuGroupValid &&
            isBuGroupDescriptionValid   );
        }
      
        
        if (errors.length > 0) {
          console.log(`Rows data ${index + 1} is invalid:`, errors);
      }
  
        // Default case if no matching Action
        return (
          isActionValid &&
          isSLNoValid
        );
      
      };
 
  // const successfulRecords = tableData.filter((row) => isRowValid(row));
  // console.log("successfulRecords data filtered",successfulRecords);
  // console.log("uploadOption data",uploadOption); 
 

const getDataToDisplay = async (uploadOption, tableData) => {
  
  let dataToDisplay;
  // if (displayOption === "1" && uploadOption === "") {
  // console.log("displayOption is 1");
  // // dataToDisplay = filteredData; // Display filteredData if displayOption is "1"
  // dataToDisplay = tableData; 
  // } else if (displayOption === "2" && uploadOption === "") {
  // console.log("displayOption is 2");
  // dataToDisplay = tableData; // Display tableData if displayOption is "2"
  // } else 
  if (uploadOption === "3") {
  console.log("displayOption is 3");
  dataToDisplay = await getExistingBugroupIds(tableData); // Display successfulRecords if uploadOption is "3"
  // dataToDisplay = successfulRecords; // Display successfulRecords if uploadOption is "3"
  } 
  else {
  dataToDisplay = tableData; // Default to tableData if no conditions are met
  }
  
console.log("dataToDisplay data is",dataToDisplay);
return dataToDisplay;
};
// console.log("dataToDisplay data FILTER is", dataToDisplay.filter((row) => isRowValid(row)));

// const dataToDisplay = getDataToDisplay(displayOption, uploadOption, tableData);




const isExistingUserId = async (BUGroup) => {
  try {
    console.log("exisitng user id",BUGroup);
    const data = { BUGroupId: BUGroup };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BuGroup-duplicates`,
      data);
    console.log("existingUserId details:", response);
    console.log("existingUserId details:", response.BUGroupData);

    // return response && response.BUGroupData !== null; // Return true if BUGroup exists, false otherwise
    return response?.BUGroupData ? response : false;
  } catch (error) {
    console.error("Error checking existing BUGroup:", BUGroup, error);
    return false;
  }
};


async function checkDuplicateUserIdsFromDB(BUGroupIds) {
  try {
    const data = { BUGroupIds };
    // console.log("UserIds duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BUGroups-duplicates`,
      data
    );

    // console.log("API Response for response details:", response);
    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}


const processData = async (data) => {
  // Extract all userIds from dataToDisplay
  
  // if (!Array.isArray(data)) {
  //   console.error("processData received invalid data:", data);
  //   return []; // Return an empty array to prevent further errors
  // }
  console.log("processData received  data:", data);

  const BUGroupIds = data.map(row => row.BuGroup).filter(Boolean);
  console.log("Extracted Bu:", BUGroupIds);

  const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(BUGroupIds);


   // Preprocess table data to add conditional rendering for userId and username
  //  return data.map((row, _, allRows) => {
    return Promise.all(data.map(async (row, _, allRows) => {
    // const rowInvalid = isRowInvalid(row);
    // Define the max allowed characters for each column (this can come from your column definitions)
  
      // Check for duplicate userId in dataToDisplay
  const isDuplicateUserId = allRows.filter(r => r.BuGroup == row.BuGroup).length > 1;
console.log("Duplicate user id is ",isDuplicateUserId);

 // Check for duplicate userId in the database
 const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.BuGroup);

 const userExists = await isExistingUserId(row.BuGroup);
 console.log(`User ${row.BuGroup} exists:`, userExists);
 // Check for errors (red text) and apply highlight based on that

const hasNotError = [
  row.SLNo,
  row.Action,
  row.BuGroup,
  row.BuGroupDescription,
 
].every(field => {
  let isValid = true;
  let reason = "";
  
  if (field === row.SLNo) {
    if (!field) { isValid = false; reason = "SLNo should not be blank."; }
    else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
    else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
  }
  
  else if (field === row.Action) {
    if (!field) { isValid = false; reason = "Action should not be blank."; }
    else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
  }

  if (!row.BuGroup || String(row.BuGroup).trim() === "") {
    if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
      isValid = false;
      reason = "BuGroup should not be empty.";
  }
  } else if (field === row.BuGroup) {
    if (String(field).length > 10) { 
      isValid = false; reason = "BuGroup Code exceeds max length of 10."; 
    }
    else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
      isValid = false; reason = "BuGroup Code does not exist.";
    } 
    else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
      isValid = false; reason = "BuGroup Code is duplicate.";
    }
  }

  // else if (field === row.BuGroup) {
  //   if (!field) { isValid = false; reason = "BUGroup should not be blank."; }
  //   else if (String(field).length > 10) { isValid = false; reason = "BUGroup exceeds max length of 10."; }
  //   else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
  //     isValid = false; reason = "User ID is duplicate.";
  //   }else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
  //     isValid = false; reason = "User ID does not exist.";
  //   }
  // }
  
  
  if (!row.BuGroupDescription || String(row.BuGroupDescription).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "BuGroupDescription should not be empty.";
  }
  } else if (field === row.BuGroupDescription) {
      if (row.Action === "E" || row.Action === "e") {
          if (!field.trim()) {
              isValid = true;
              reason = "BuGroupDescription should be blank for Edit action.";
          } else if (String(field).trim().length > 40) {
              isValid = false;
              reason = "BuGroupDescription exceeds max length of 40.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!field.trim()) {
              isValid = false;
              reason = "BuGroupDescription should not be blank for Add action.";
          } else if (String(field).trim().length > 30) {
              isValid = false;
              reason = "BuGroupDescription exceeds max length of 30.";
          }
      }
  }

  // else if (field === row.BuGroupDescription) {
  //   if (row.Action === "E" || row.Action === "e") {
  //     if (!field) { isValid = true; reason = "BuGroupDescription should be blank for Edit action."; }
  //   } else if (row.Action === "A" || row.Action === "a") {
  //     if (!field) { isValid = false; reason = "BuGroupDescription should not be blank for Add action."; }
  //   }
  //   if (String(field).length > 40) { isValid = false; reason = "BuGroupDescription exceeds max length of 40."; }
  // }

  if (!isValid) {
    console.log(`Validation Failed: ${reason}`);
  } else {
    console.log(`Validation Passed: ${field}`);
  }
  
  return isValid;
});

console.log(`Final hasNotError status: ${hasNotError}`);

    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error

      isDuplicateUserId,
      isDuplicateInDB,
      userExists,

      Action: row.Action ? (
        String(row.Action).length === 1 ? (
          ["A", "a", "E", "e"].includes(row.Action) ? (
            row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Only "A", "E" are allowed)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Max. allowed character is 1)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
        

        // BuGroup: row.BuGroup ? (
        //   String(row.BuGroup).length > 10 ? (
        //     <span style={{ color: "red" }}>
        //       {row.BuGroup} (Max. allowed character is 10)
        //     </span>
        //   ) : row.Action === "A" || row.Action === "a" ? (
        //     // When Action is "A", show duplicate message if user ID is a duplicate
        //     (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
        //     (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
        //       <span style={{ color: "red" }}>
        //         {row.BuGroup} (BuGroup Duplicate exists)
        //       </span>
        //     ) : (
        //       String(row.BuGroup) // Otherwise, show userId normally
        //     )
        //   ) : row.Action === "E" || row.Action === "e" ? (
        //     // When Action is "E", show userId without the duplicate message
        //     (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
        //     (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
        //       <span>
        //         {row.BuGroup} 
        //       </span>
        //     ) : (
        //       // String(row.userId) // Otherwise, show userId normally
        //       <span style={{ color: "red" }}>
        //       {row.BuGroup} (BU Group not exists)
        //     </span>
        //     )
        //   ) : (
        //     String(row.BuGroup) // Default case, show userId normally
        //   )
        // ) : (
        //   <span style={{ color: "red" }}>
        //     Shouldn't be blank
        //   </span>
        // ),


        BuGroup: row.BuGroup ? (
          String(row.BuGroup).length > 10 ? (
            <span style={{ color: "red" }}>
              {row.BuGroup} (Max. allowed character is 10)
            </span>
          ) : row.Action === "A" || row.Action === "a" ? (
            // When Action is "A", show duplicate message if user ID is a duplicate
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span style={{ color: "red" }}>
                {row.BuGroup} (BuGroup Code Duplicate exists)
              </span>
            ) : (
              String(row.BuGroup) // Otherwise, show userId normally
            )
          ) : row.Action === "E" || row.Action === "e" ? (
            // When Action is "E", show userId without the duplicate message
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span>
                {row.BuGroup} 
              </span>
            ) : (
              // String(row.BuGroup) // Otherwise, show BuGroup normally
              <span style={{ color: "red" }}>
              {row.BuGroup} (BuGroup Code not exists)
            </span>
            )
          ) : (
            String(row.BuGroup) // Default case, show userId normally
          )
        ) : (
          <span style={{ color: "red" }}>
            Shouldn't be blank
          </span>
        ),

        // BuGroupDescription: row.BuGroupDescription ? (
        //   String(row.BuGroupDescription).length > 40 ? (
        //     <span style={{ color: "red" }}>
        //       {row.BuGroupDescription} (Max. allowed character is 40)
        //     </span>
        //   ) : (
        //     String(row.BuGroupDescription) // Ensure BuGroupDescription is treated as a string
        //   )
        // ) : (
        //   row.Action === "A" || row.Action === "a" ? (
        //     <span style={{ color: "red" }}>
        //       Shouldn't be blank
        //     </span>
        //   ) : row.Action === "E" || row.Action === "e" ? (
        //     <span></span> // Empty span for "E"
        //   ) : null // Handle other cases, if any
        // ),

        BuGroupDescription: row.BuGroupDescription ? (
          String(row.BuGroupDescription).length > 40 ? (
            <span style={{ color: "red" }}>
              {row.BuGroupDescription} (Max. allowed character is 40)
            </span>
          ) : (
            String(row.BuGroupDescription) // Ensure Username is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

    
      };  
  }));
}

const storeArrayDataForUsers= async () => {
  // console.log("store successfulRecords data is",dataToDisplay);

  setLoading(true);
  try {
    const dataToDisplay = await getDataToDisplay(uploadOption, tableData);
  console.log("store successfulRecords data 2 is", dataToDisplay);

  const processed = await processData(tableData);
  console.log("display datas",processed);
 const extractText = (cellValue) => {
              if (Array.isArray(cellValue)) {
                return cellValue.join(", "); // Convert array to string
              } else if (typeof cellValue === "string") {
                return cellValue;
              } else if (React.isValidElement(cellValue)) {
                return cellValue.props.children;
              }
              return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
            };

            const formattedUsers = processed.map((user) => {

              return {
              "Action": extractText(user.Action),
              "SL No": extractText(user.SLNo),
              "BuGroup": extractText(user.BuGroup),
              "BuGroupDescription": extractText(user.BuGroupDescription),
            };
          });
            
              // Insert second and third row at the beginning of the formatted data
              const rowsToDisplay = [ ...formattedUsers];
      
              console.log("Formatted USERS Data:", formattedUsers);
              console.log("Formatted Data:", rowsToDisplay);
      
              
              const updatedData = rowsToDisplay.map(user => {
      
                const isObjectField = ["Action",
                  "SL No",
                  "BuGroup",
                  "BuGroupDescription",
                ].some(field => typeof user[field] === 'object' || user[field] === "Shouldn't be blank");

                  return {
                    // update: typeof user.UserID === 'object' ? "No" : "Yes",
                    update: isObjectField ? "No" : "Yes",
                          ...user,
                        };
                    });
        console.log("updatedData data",updatedData);

        
      const data = {
        clientId: assignee.clientId,
        dataToDisplay,
        createdBy: assignee.userId,
        allrecords: updatedData,
      }
      console.log("storeArrayDataForUsers dataToDisplay is",data);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/store-array-buGroup-data`,
        data
      );
      console.log("Roles Response data", response);
  
      if (dataToDisplay.length === 0) { 
        // toast("error", "Please upload the correct file OR containing the data.");
        toast("error", "Error fields exist.");
        return;
    }
    else{
      if (response.status === 200) {
        toast("success", "Uploaded BUGroup successfully");
      }
      else if(response.status === 400){
        toast("error", "Invalid or empty array of BUGroup");
      }
      else if(response.status === 404){
        toast("error", "Missing required fields");
      }
      else if(response.status === 500){
        toast("error", "Duplicate BUGroup IDs found.");
        console.log("response duplicate BUGroup id data",response.duplicates)
      }
    }
    } catch (error) {
      console.error("Error fetching array BUGroup data", error);
    }
    finally {
          setLoading(false);
        }
  };



  return (
    <div>
      <div>
        <Header title={`Primary Data > Company & Bank Data > Bu Group > Upload `}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/2/master-data/8/bugroup"}
        />
      
      {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}
           <UploadComponent
              downloadPath="/BUGroup_Upload.xlsx"  // Replace with a dynamic file path if needed
              handleFileChange={handleFileChange}  // Function to handle file selection
              buttonText={buttonText}
              isUploaded={isUploaded}
              isError={isError}
              handleSubmit={handleSubmit}
              isDisplayChecked={isDisplayChecked}
              setIsDisplayChecked={setIsDisplayChecked}
              isUploadChecked={isUploadChecked}
              setIsUploadChecked={setIsUploadChecked}
              displayOption={displayOption}
              setDisplayOption={setDisplayOption}
              uploadOption={uploadOption}
              setUploadOption={setUploadOption}
              icons={iconId}
            />
        <ConfirmationDialog
          open={showConfirmation}
          title="Upload File"
          message="Do you need to download the latest upload format?"
          onConfirm={confirmDeletion}
          onCancel={cancelDeletion}
        />
        <Footer>
          <button
            onClick={handleSubmit}
            className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
          >
            Run
          </button>
        </Footer>

      </div>
    </div>
  )
}