
import React, { useEffect, useState } from 'react'
import Tabs from '../../../../../components/Tabs';
import Footer from '../../../../../components/footer';
import ButtonGroup from '../../../../../components/FooterButton';
import Header from '../../../../../components/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LegalEntityServices from '../../../../../rest-services/LegalEntityServices';
import { useToast } from "../../../../../components/toast/toast";
import { RxDropdownMenu } from 'react-icons/rx';
import CurrencyServicess from '../../../../../rest-services/CurrencyServices';
import CountryServices from '../../../../../rest-services/CountryServices';
import StateProvinceServices from '../../../../../rest-services/StateProvinceServices';
import BankIdServices from '../../../../../rest-services/BankIdServices';
import BankServices from '../../../../../rest-services/BankServices';
import CustomButton from '../../../../../components/CustomButton';

export default function BankIdAdd() {
    const [activeTab, setActiveTab] = useState("addbankiddetails");
    const navigate = useNavigate();
    const { bankidId } = useParams();
    const location = useLocation();
    const { mode } = location.state || {}; 
    const isViewMode = mode === 'view';
    const [errors, setErrors] = useState({}); // Validation errors
    const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
    const { toast } = useToast();
const [alert, setAlert] = useState(null); 
const [searchQuery, setSearchQuery] = useState(""); // State for search query
const [bankID,setBankID] = useState("");
const [bankName,setBankName] = useState("");

const [bank,setBank]=useState("");
const [banks,setBanks]=useState([]);
const [countryCodeType,setCountryCodeType]=useState([]);
const [minLength,setMinLength]=useState([]);
const [maxLength,setMaxLength]=useState([]);

const [countryCode, setCountryCode] = useState(""); // Country Code
const [countryName, setCountryName] = useState("");
const [district, setdistrict] = useState("");
const [poBox, setpoBox] = useState("");
const [state, setstate] = useState("");
const [stateName, setStateName] = useState(''); 
// const [countryCode, setcountryCode] = useState("");
const [countryId, setCountryId] = useState('');
const [areaBlock, setareaBlock] = useState("");
const [roadNo, setroadNo] = useState("");
const [roadName, setRoadName] = useState("");
const [streetNo, setstreetNo] = useState("");
const [buildingName, setbuildingName] = useState("");
const [buildingNo, setbuildingNo] = useState("");
const [doorNo, setdoorNo] = useState("");
const [floorNo, setfloorNo] = useState("");
const [addressLine1, setaddressLine1] = useState("");
const [addressLine2, setaddressLine2] = useState("");
const [addressLine3, setaddressLine3] = useState("");
const [countries, setCountries] = useState([]);
const [stateProvinces, setStateProvinces] = useState([]);
const [selectedCountry, setSelectedCountry] = useState(countryCode || "");
const[postalCode,setpostalCode]=useState("");
const [searchQueryState, setSearchQueryState] = useState(""); // State for search query
const [selectedState, setSelectedState] = useState(state || "");

  const [streetName, setstreetName] = useState("");
  const [areaName, setareaName] = useState("");
  // const [cityName, setcityName] = useState("");
  const [landMark, setlandMark] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const[ selectedBank,setSelectedBank]=useState("");
  const [isModalOpenForState, setIsModalOpenForState] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState(false);
  const [isBankModalOpen, setIsBankModalOpen] = useState(false);
  const [isStateModalOpen, setIsStateModalOpen] = useState(false);
  const [filteredStateProvinces, setFilteredStateProvinces] = useState([]);
  const [isValidationTriggered, setIsValidationTriggered] = useState(false);

  useEffect(() => {
    fetchcountry();
    fetchStateProvinces();
    fetchbank();
    fetchAssignee();
  }, []); 
  useEffect(() => {
    if (bankidId) {
      getDataForEdit();
    }
  }, [  bankidId,countries,stateProvinces]);
  


const getDataForEdit = async () => {
  try {
    const response = await BankIdServices.getBankIdById(bankidId);
    console.log("Response data:", response);
    const address = response.addresses[0]; 
    const countrycodetype = response.countrycode[0]; 

    // Existing data handling
    setBankID(response.bankID); // Adjust according to API response structure
    setBankName(response.bankName);    
    setCountryCode(response.countryCode);  // Set country code
    setBank(response.bank); // Set currency code
    setdoorNo(address.doorNo); 
    setfloorNo(address.floorNo); 
    setbuildingNo(address.buildingNo); 
    setbuildingName(address.buildingName); 
    setlandMark(address.landMark); 
    setstreetNo(address.streetNo); 
    setstreetName(address.streetName); 
    setroadNo(address.roadName); 
    setRoadName(address.roadName); 
    setareaBlock(address.areaBlock); 
    setareaName(address.areaName); 
    setaddressLine1(address.addressLine1); 
    setaddressLine2(address.addressLine2); 
    setaddressLine3(address.addressLine3); 
    setstate(address.state); 
    setdistrict(address.district); 
    setpostalCode(address.postalCode); 
    setzipCode(address.zipCode); 
    setpoBox(address.poBox); 
    setCountryCodeType(countrycodetype.countryCodeType); 
    setMaxLength(countrycodetype.maxLength); 
    setMinLength(countrycodetype.minLength); 

    

    // Country lookup based on country code from response
    const country = countries.find(
      (c) => c.country === address.countryCode
    );
    console.log("country code from response:", address.countryCode);
    console.log("Available countries:", countries);
    if (countries) {
      setCountryName(country.countryName);
      setCountryCode(country.country);
       // Set currency name for display
      setSelectedCountry(country.countryName); // Set the selected currency based on currency name
      // Set the currency code// Set the selected country based on countryName
    } else {
      console.error("Country not found for the provided country code.");
    }

    // for state
    const fetchedState = stateProvinces.find(c => c.state === address.state);
    console.log("State code from response:", address.state);
    console.log("Available states:", stateProvinces);
    console.log("the fetched console",fetchedState.State_Name
    );
    if (fetchedState) {
      setStateName(fetchedState.State_Name);  // Set state name for display
    } else {
      console.error("State not found for the provided state code:", response.state);
    }

// Currency lookup based on currency code from response
const bank = banks.find(c => c.bankCode === response.bank);
console.log("Currency code from response:", response.bank);
console.log("Available currencies:", banks);
if (banks) {
  setBank(bank.bankCode);  // Set currency name for display
    setSelectedBank(bank.bankCode); // Set the selected currency based on currency name
} else {
  console.error("bank not found for the provided bank code.");
}
  } catch (error) {
    console.error("Error fetching bankid data:", error);
    setAlert({
      severity: "error",
      message: "Error fetching bankid data.",
    });
  }
};

const fetchAssignee = async () => {
  try {
    const userDetailString = sessionStorage.getItem("userDetail");
    if (userDetailString) {
      const userDetail = JSON.parse(userDetailString);
      const assigneeData = {
        id: userDetail.id || "",
        assigner: userDetail.username || "",
      };
      setAssignee(assigneeData);
    } else {
      setAlert({
        severity: "error",
        message: "No user detail found in session storage.",
      });
    }
  } catch (error) {
    setAlert({
      severity: "error",
      message: `Error fetching user details: ${error.message}`,
    });
  }
};


const fetchcountry = async () => {
  try {
    const response = await CurrencyServicess.getAllCalendar(`${process.env.REACT_APP_BASE_URL}/get-country`);
    console.log("Fetched countries:", response); // Add this log
    setCountries(response.data.data); // Ensure this is correct
  } catch (error) {
    console.error("Error fetching countries:", error);
  }
};
const fetchStateProvinces=async()=>{
  try {
    const response = await StateProvinceServices.getAllStateprovince(`${process.env.REACT_APP_BASE_URL}/get-stateprovince`);
    console.log("Fetched StateProvinces:", response); // Add this log
    setStateProvinces(response.data.data); // Ensure this is correct
  } catch (error) {
    console.error("Error fetching countries:", error);
  }
};
const fetchbank = async () => {
  try {
    const response = await BankServices.getAllBank(`${process.env.REACT_APP_BASE_URL}/get-bank`);
    console.log("Fetched bank:", response); // Add this log
    setBanks(response.data.data); // Ensure this is correct
  } catch (error) {
    console.error("Error fetching banks:", error);
  }
};
const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  const data = {
    id: bankidId , // Use 'id' for updates
    bankID,
    bankName,    
    bank,
    created_by: assignee.assigner,
    changed_by: assignee.assigner,
    doorNo,
    floorNo,
    buildingNo,
    buildingName,
    landMark,
    streetNo,
    streetName,
    roadNo,
    roadName,
    areaBlock,
    areaName,
    addressLine1,
    addressLine2,
    addressLine3,
    countryCode,
    state,
    district,
    postalCode,
    zipCode,
    poBox, 
    countryCodeType: typeof countryCodeType === 'string' ? countryCodeType : "",
        minLength,
    maxLength
  };

  try {
    let response;
    if (bankidId) {
      // Updating the existing currency
      response = await BankIdServices.addBankId(
        `${process.env.REACT_APP_BASE_URL}/update-bankid`, // Ensure this endpoint is for updates
        data
      );
    } else {
      // Creating a new currency
      response = await BankIdServices.addBankId(
        `${process.env.REACT_APP_BASE_URL}/create-bankid`,
        data
      );
    }

    if (response && !response.error) {
      toast("success", bankidId ? "bankid Updated successfully" : "bankid Added successfully");
      // Clear the form and navigate
   
      navigate("/bankidscreen"); // Redirect after saving
    } else {
      toast("error", "Error adding/updating bankid");
    }
  } catch (error) {
    console.error("Request error:", error);
    setAlert({
      severity: "error",
      message: `Error adding/updating bankid: ${error.message}`,
    });
  }
};
const handleSaveAndContinue = () => {
  // Validate required fields
  const isValid = validateForm(); // Call your validation function

  if (isValid) {
    // Only change the tab if validation passes
    setActiveTab("bankidaddress");
  } else {
    // Show an error message or handle the error state as needed
    toast("error", "Required Fields are Missing.");
  }
};
// Separate handler for country selection
const handleCountryCheckBoxChange = (event) => {
  console.log(`this is the ${countries}`)

  const selectedName = event.target.value; // Get the selected country name
  setSelectedCountry(selectedName);

  const selectedCountryData = countries.find(country => country.countryName === selectedName);
  if (selectedCountryData) {
    setCountryCode(selectedCountryData.country); // Set the country code
    setCountryName(selectedCountryData.countryName); // Set the country name

    // Filter states by the selected country code
    const filteredStates = stateProvinces.filter(state => state.Country_Code === selectedCountryData.country);
    setFilteredStateProvinces(filteredStates); // Assuming you have state to hold the filtered states
  }
};


// Separate handler for bankid selection
const handleBankCheckBoxChange = (event) => {
  banks.forEach((bank) => {
    console.log(bank);
  });
  const selectedName = event.target.value; // Get the selected currency name
  setSelectedBank(selectedName);

  const selectedbankData = banks.find(bank => bank.bankCode === selectedName);
  
  if (selectedbankData) {
    setBank(selectedbankData.bankCode); // Set the currency code
 // Set the currency name
  }
};
const handleCheckboxChangeForState = (event) =>{

  const selectedCode = event.target.value;
  console.log("selected State code",selectedCode);
  setSelectedState(selectedCode);
  setstate(selectedCode);
  
  const selectedCountryData = stateProvinces.find(stateP => stateP.state === selectedCode);
  console.log('selected state country data',selectedCountryData);
  if (selectedCountryData) {
    console.log('check selected country data',selectedCountryData.State_Name);
    setStateName(selectedCountryData.State_Name);
  }
};
const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
};
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSearchChangeState = (e) => {
    setSearchQueryState(e.target.value);
  };
 

const validateForm = () => {
  setIsValidationTriggered(true);

  const isAddressSectionFilled = addressLine1 || addressLine2 || addressLine3;
  const isBuildingSectionFilled = doorNo || floorNo || buildingNo || buildingName || landMark;
  const isStreetSectionFilled = streetNo || streetName || roadNo || roadName;
  const isAreaSectionFilled = areaBlock || areaName;
  const isBuildingStreetAreaSectionFilled = isBuildingSectionFilled || isStreetSectionFilled || isAreaSectionFilled;
  const newErrors = {};
  if (!bankID) newErrors.bankID = "bankID is required.";
  if (!bankName) newErrors.bankName = "bankName is required.";
  if (!bank) newErrors.bank = "bank   is required.";
  if (!countryCode) newErrors.countryCode = "country Code   is required.";
  if (filteredStateProvinces.length > 0 && !state) {
    newErrors.state = "state is required.";
  }


  if (!isAddressSectionFilled && !isBuildingStreetAreaSectionFilled) {
    newErrors.addressSection = "Either Address Section or Building, Street, and Area Section must be entered";
  }
  setErrors(newErrors);
  if (Object.keys(newErrors).length > 0) {
    toast("warning", "Missing Required Fields");
  }
  return Object.keys(newErrors).length === 0;
};
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Bank&Company masterData") {
          navigate("/master-data");
        } else if (crumb.title === "Bank ID") {
          navigate("/bankidscreen");
        } else if (crumb.title === "Primary Data") {
          navigate("/master-data");
        } else if (crumb.path) {
          navigate(crumb.path); 
        }
      };
      const toggleModalForState = () => {
        setIsModalOpenForState(!isModalOpenForState);
    
      }

      const handleTabClick = (tab) => {
        setActiveTab(tab);
      };
      const tabs = [
        { value: "addbankiddetails", label: "Basic Details" },
        { value: "bankidaddress", label: "Bank Address" },
        { value: "countrycodetype", label: "Country Code Type" },

      ];
   
      const errorss = {
        addbankiddetails: !bankID || !bankName || !bank ,
        bankidaddress: !addressLine1,
       
      };
      const nextToAddress = () => {
        setActiveTab("bankidaddress");
      
    }
    const nextToCountryCodetype = () => {
      setActiveTab("countrycodetype");
    
  }
  return (
    <div>
      <Header
        title={`Primary Data > Bank&Company masterData > Bank ID > ${
          bankidId ? (isViewMode ? "View" : "Edit") : "Add"
        }`}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
            <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} isValidationTriggered={isValidationTriggered} errors={errorss}/>
            <div className="flex-grow">
        {activeTab === "addbankiddetails" && (
          <div className="flex flex-col space-y-8 col-span-1 p-10">
        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">Bank ID<span className="text-red-500 ml-1">*</span></span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={bankID}
            onChange={(e) => {
              setBankID(e.target.value);
              if (errors.bankID) {
                setErrors(prevErrors => ({ ...prevErrors, bankID: "" }));
              }
            }}
            className={`border text-customGray ${errors.bankID ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>

        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">Bank Name<span className="text-red-500 ml-1">*</span></span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={bankName}
            onChange={(e) => {
              setBankName(e.target.value);
              if (errors.bankName) {
                setErrors(prevErrors => ({ ...prevErrors, bankName: "" }));
              }
            }}
            className={`border text-customGray  ${errors.bankName ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>

        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500" disabled={isViewMode}>Bank  <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
              onClick={() => setIsCountryModalOpen(true)}
              disabled={isViewMode}

            className={`border border-gray-300 text-customGray p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 text-left flex items-center justify-between ${errors.bank ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{selectedBank || 'Select Bank Code'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>

          {isCountryModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden"> {/* Added rounded-lg for border-radius */}
      {/* Header */}
      <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
        <h2 className="text-lg font-semibold text-customBlue">Select BankCode</h2>
        <input
          type="text"
          placeholder="Search country..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="border  text-customGray border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
          disabled={isViewMode}
        />
        <button   onClick={() => setIsCountryModalOpen(false)}
 className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-auto p-4">
      {banks.length > 0 ? (
  <div className="grid grid-cols-3 gap-4">
    {banks
      .filter((bank) =>
    (bank.bankCode && bank.bankCode.trim() && bank.bankCode.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (bank.Description && bank.Description.trim() && bank.Description.toLowerCase().includes(searchQuery.toLowerCase()))
  )
      .map((bank, index) => (
        
        <div key={bank.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
          <input
            type="radio"
            name="Bank Code"
            value={bank.bankCode}
            checked={selectedBank === bank.bankCode}
            onChange={handleBankCheckBoxChange}
            className="mr-2 text-blue-200"
            disabled={isViewMode}
          />
          <label>{bank.bankCode}</label>
        </div>
      ))}
  </div>
) : (
  <p>Loading countries...</p>
)}
      </div>
      

      {/* Footer */}
      {!isViewMode && (
      <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
        <button
  onClick={() => setIsCountryModalOpen(false)}
  className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
          disabled={isViewMode}
        >
          Save
        </button>
      </div>
    )}
    </div>


  </div>
)}

        </div>

 
    

        <CustomButton onClick={nextToAddress} title={"Next"}/>
      </div>

        )}

        {activeTab === "bankidaddress" && (
          <div className="p-12">
          {/* Building Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Building</h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Door No </label>
                <span className="ml-4">:</span>
                <input type="text" 
                 value={doorNo                 }
                 onChange={(e) => setdoorNo(e.target.value)}
                 disabled={isViewMode}
                className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Floor No </label>
                <span className="ml-4">:</span>
                <input type="text" 
                value={floorNo}
                disabled={isViewMode}
                onChange={(e) => setfloorNo(e.target.value)}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Building No </label>
                <span className="ml-4">:</span>
                <input type="text"
                value={buildingNo}
                disabled={isViewMode}
                onChange={(e) => setbuildingNo(e.target.value)}
                 placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Building Name </label>
                <span className="ml-4">:</span>
                <input type="text"
                value={buildingName}
                disabled={isViewMode}
                onChange={(e) => setbuildingName(e.target.value)}
                 placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
            </div>

            <div className="flex items-center mt-4">
              <label className="w-48 text-sm font-medium text-gray-500">Landmark</label>
              <span className="ml-4">:</span>
              <input type="text" 
               value={landMark}
               onChange={(e) => setlandMark(e.target.value)}
               disabled={isViewMode}
              placeholder="" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
            </div>
          </div>

          {/* Street Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Street</h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Street No </label>
                <span className="ml-4">:</span>
                <input 
                  value={streetNo}
                  onChange={(e) => setstreetNo(e.target.value)}  
                  disabled={isViewMode}  
                type="text" placeholder="2/626" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Street Name</label>
                <span className="ml-4">:</span>
                <input type="text"  value={streetName} disabled={isViewMode} onChange={(e) => setstreetName(e.target.value)}
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
                {/* {errors.streetName && (
                    <p className="text-red-500 text-sm ml-4">{errors.streetName}</p>
                  )} */}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Road No </label>
                <span className="ml-4">:</span>
                <input type="text" 
                 value={roadNo}
                 onChange={(e) => setroadNo(e.target.value)}
                 disabled={isViewMode}
                placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Road Name </label>
                <span className="ml-4">:</span>
                <input
                  type="text"
                  value={roadName}
                  onChange={(e) => setRoadName(e.target.value)}
                  placeholder="B name"
                  disabled={isViewMode}
                  className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />
              </div>
            </div>
          </div>

            {/* Area Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Area</h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Block </label>
                <span className="ml-4">:</span>
                <input type="text" 
                 value={areaBlock}
                 onChange={(e) => setareaBlock(e.target.value)}
                placeholder="2/626" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Area Name </label>
                <span className="ml-4">:</span>
                <input type="text" 
                 value={areaName}
                 onChange={(e) => setareaName(e.target.value)}
                placeholder="4"   disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
            </div>
          </div>
            {/* Display the error message for address section */}
            {errors.addressSection && (
                <p className="text-red-500 text-sm mb-4">{errors.addressSection}</p>
              )}

            {/* Address Section */}
            <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Address</h3>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex items-center">
                <label className="w-56 text-sm font-medium text-gray-500">Address 1 </label>
                <span className="ml-4">:</span>
                <input type="text" value={addressLine1}  onChange={(e) => setaddressLine1(e.target.value)}
                placeholder="2/626"    disabled={isViewMode}        className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
                />

                {/* {errors.addressLine1 && (
                    <p className="text-red-500 text-sm ml-4">
                      {errors.addressLine1}
                    </p>
                  )} */}
              </div>
              {/* <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => setaddressLine2(e.target.value)}
                placeholder="4" className="border border-gray-300 p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div> */}
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="flex items-center">
                <label className="w-56 text-sm font-medium text-gray-500">Address 2 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine2}  onChange={(e) => setaddressLine2(e.target.value)}
                placeholder="4" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              
            </div>

            <div className="grid grid-cols-1 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-56 text-sm font-medium text-gray-500">Address 3 </label>
                <span className="ml-4">:</span>
                <input type="text"  value={addressLine3}  onChange={(e) => setaddressLine3(e.target.value)}
                placeholder="2A" disabled={isViewMode} className="border border-gray-300 p-1.5 rounded w-full h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              
            </div>
          </div>


            {/* Country Section */}
          <div className="mb-6 border border-gray-300 p-5 rounded-lg">
            <h3 className="text-lg font-semibold text-gray-500 mb-4">Country</h3>
            
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Country Code <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
              onClick={() => setIsCountryModalOpen(true)}
              disabled={isViewMode}
              className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between ${errors.countryCode ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{countryCode || 'Select Country Code'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>

          { isCountryModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden"> 
              <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-CustomBlue">Select Country Code</h2>
                  <input
                    type="text"
                    placeholder="Search country..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    disabled={isViewMode}
                    className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
                  />
                  <button               onClick={() => setIsCountryModalOpen(false)}
className="text-gray-500 hover:text-gray-700">&times;</button>
                </div>

                 {/* Scrollable Content */}
                <div className="flex-1 overflow-auto p-4">
                  {countries.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                      {countries
                      .filter((country) =>
                        country.countryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        country.country.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((country, index) => (
                        <div key={country.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={country.countryName}
                            checked={selectedCountry === country.countryName}
                            onChange={handleCountryCheckBoxChange}
                            className="mr-2 text-blue-200"
                            disabled={isViewMode}
                          />
                          <label>{country.countryName}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Loading countries...</p>
                  )}
                </div>
               
                    <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                    {(!isViewMode &&  <button
              onClick={() => setIsCountryModalOpen(false)}
              className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                      >
                        Save
                      </button>)}
                    </div>
             
                
              </div>
              

            </div>
          )}
        </div>
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">Country Name</label>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={countryName}
            // readOnly
            disabled={isViewMode}    
                    className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-400 focus:outline-none hover:border-blue-400 ml-4"
          />
        </div>
      </div>
        
      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">State / Province <span className="text-red-500 ml-1">*</span></label>
          <span className="ml-4">:</span>
          <button
              onClick={() => setIsStateModalOpen(true)}
              disabled={isViewMode}
              className={`border border-gray-300 p-1 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between ${errors.state ? 'border-red-500 hover:border-red-500' : 'border-gray-300 hover:border-blue-400'}`}
          >
            <span>{state || 'Select State / Province'}</span>
            <RxDropdownMenu className="ml-2" />
          </button>

          {isStateModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-0 w-[800px] h-[400px] flex flex-col rounded-2xl overflow-hidden">
                <div className="flex justify-between items-center p-4 shadow shadow-blue-100 bg-white sticky top-0 z-10">
                  <h2 className="text-lg font-semibold text-CustomBlue">Select State / Province</h2>

                      <input
              type="text"
              placeholder="Search state province..."
              value={searchQueryState}
              onChange={handleSearchChangeState}
              className="border border-gray-300 p-1 rounded w-80 ml-60 placeholder-gray-500 focus:outline-none focus:border-customBlue focus:ring-1 focus:ring-customBlue"
            />
                  <button               onClick={() => setIsStateModalOpen(false)}
 className="text-gray-500 hover:text-gray-700 text-2xl">&times;</button>
                </div>
                <div className="flex-1 overflow-auto p-4">

                  {filteredStateProvinces.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                      {filteredStateProvinces
                      .filter((stateP) =>
                        stateP.State_Name.toLowerCase().includes(searchQueryState.toLowerCase()) ||
                      stateP.state.toLowerCase().includes(searchQueryState.toLowerCase())
                      )
                      .map((stateP, index) => (
                        <div key={stateP.id} className={`flex items-center ${index % 2 === 0 ? 'bg-sky-blue-200' : ''}`}>
                          <input
                            type="radio"
                            name="country"
                            value={stateP.state}
                            checked={selectedState === stateP.state}
                            onChange={handleCheckboxChangeForState}
                            className="mr-2"
                            disabled={isViewMode}
                          />
                          <label>{stateP.state}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Loading State...</p>
                  )}
                </div>
                
                  <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
                 {( !isViewMode && <button
              onClick={() => setIsStateModalOpen(false)}
              className="mt-2 bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 mr-20"
                  >
                    Save
                  </button>)}
                </div>
              
                
              </div>
            </div>
          )}
        </div>
          
        <div className="flex items-center">
          <label className="w-48 text-sm font-medium text-gray-500">State / Province Name</label>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={stateName}
            disabled={isViewMode}
            // readOnly
            
            className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-400 focus:outline-none hover:border-blue-400 ml-4"
          />
                  {/* <select
                  disabled
                    value={state}
                    onChange={(e) => setstate(e.target.value)}
                    className="border border-gray-300 p-1 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
                  >
                    <option value="" disabled>Select State / Province</option>
                    {stateProvinces.map((statePName) => (
                      <option key={statePName.id} value={statePName.id}>
                        {statePName.State_Name}
                      </option>
                    ))}
                  </select> */}
          {/* {errors.countryId && <p className="text-red-500 text-sm ml-4">{errors.countryId}</p>} */}
        </div>

      </div>



{/* <div className="grid grid-cols-2 gap-4 mt-2">
            </div> */}

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">District</label>
                <span className="ml-4">:</span>
                <input type="text"
                 value={district}
                 onChange={(e) => setdistrict(e.target.value)}
                 disabled={isViewMode}
                 placeholder="2A" 
                 className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Postal Code</label>
                <span className="ml-4">:</span>
                <input type="text"
                value={postalCode}
                onChange={(e) => setpostalCode(e.target.value)}
                disabled={isViewMode}
                 placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">Zip Code</label>
                <span className="ml-4">:</span>
                <input type="text" 
                value={zipCode}
                onChange={(e) => setzipCode(e.target.value)}
                disabled={isViewMode}
                placeholder="2A" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>
              
              <div className="flex items-center">
                <label className="w-48 text-sm font-medium text-gray-500">PO Box</label>
                <span className="ml-4">:</span>
                <input type="text" 
                value={poBox}
                onChange={(e) => setpoBox(e.target.value)}
                disabled={isViewMode}

                placeholder="B name" className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0" />
              </div>

            </div>

          </div>

          <CustomButton onClick={nextToCountryCodetype} title={"Next"} className={"mb-10"}/>

        </div>
        
        )}

        {activeTab === "countrycodetype" && (
          <div className='flex flex-col space-y-8 col-span-1 p-10'>
          <div className="grid grid-cols-2 gap-8">
                <div className="flex items-center">
                  <label className="flex items-center w-full">
                    <span className="w-48 font-small text-customGray whitespace-nowrap">
                      Country Code Type<span className="text-red-500 ml-1">*</span>
                    </span>
                    <span className="ml-4">:</span>
                    <input
                      type="text"
                      value={countryCodeType}
                      onChange={(e) => {
                        setCountryCodeType(e.target.value);
                       
                      }}
                      className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 "

                      disabled={isViewMode}
                    />
                  </label>
                </div>

                <div className="flex items-center">
                <label className="flex items-center ">
  <span className="font-small text-customGray">Min</span>
  <span className="ml-4">:</span>
  <input
    type="number"  // Changed type to number
    value={minLength}
    onChange={(e) => {
      setMinLength(e.target.value);
    }}
    className="border border-gray-300 p-1.5 rounded w-20 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 mr-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
    disabled={isViewMode}
    min="0"  // Optional: restrict minimum value
  />
</label>

<label className="flex items-center">
  <span className="font-small text-customGray">Max</span>
  <span className="ml-4">:</span>
  <input
    type="number"  // Changed type to number
    value={maxLength}
    onChange={(e) => {
      setMaxLength(e.target.value);
    }}
    className="border border-gray-300 p-1.5 rounded w-20 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
    disabled={isViewMode}
    min="0"  // Optional: restrict minimum value
  />
</label>
                </div>
              </div>
            </div>
)}

      </div>
      <Footer>
        {/* Footer */}
        {(!isViewMode &&
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
          />
   )}
      </Footer>
    </div>
  )
}
