import React, { useEffect, useState } from "react";
import NoActionLayout from "../../../../components/NoActionLayout";
import { Alert } from "@mui/material";
import BtcServices from "../../../../rest-services/BtcServices";
import Header from "../../../../components/Header";
import CustomButton from "../../../../components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../../../components/toast/toast";
import Footer from "../../../../components/footer";


function AddBTC() {
  const [btcCode, setBtcCode] = useState("");
  const [description, setdescription] = useState("");
  const [statement, setstatement] = useState("");
  const [inflow, setInflow] = useState("0");
  const [outflow, setOutflow] = useState("0");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    assigner: "",
    userId: "",
  }); const [unsavedChanges, setUnsavedChanges] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state

  const { id } = location.state || {};
  const isViewMode = mode === 'view';

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {

      }
    } catch (error) {

    }
  };
  useEffect(() => {
    fetchAssignee();
  }, []);

  useEffect(() => {
    if (id) {
      fetchBtcCodeForFields();
    }
  }, [id]);

  const validateForm = () => {
    const newErrors = {};
    if (!btcCode) newErrors.btcCode = "BTC Code is required.";
    if (!description) newErrors.description = "Description is required.";
    if (!inflow) newErrors.inflow = "Inflow is required.";
    console.log("outflow", outflow);
    if (outflow === "0") newErrors.outflow = "Outflow is required.";
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  }

  const fetchBtcCodeForFields = async () => {
    try {
      const response = await BtcServices.getBtcCodeForFields(id);
      if (response.status === 200) {
        const { btcCode, description, inflow, outflow, statement } = response.data.data;
        setBtcCode(btcCode);
        setdescription(description);
        setInflow(inflow);
        setOutflow(outflow);
        setstatement(statement);
      } else {
        toast("error", response.message);
      }
    } catch (error) {
      console.error("Error details:", error);
    }
  };

  const resetForm = () => {
    setBtcCode("");
    setdescription("");
    setstatement("");
    setInflow("0");
    setOutflow("0");
  }
  const handleClickSave = (e, scontinue) => {
    if (!id) {
      handleSave(e, scontinue);
    } else {
      updateBtcCode();
    }

  }
  const handleSave = async (e, scontinue) => {
    e.preventDefault();
    const data = {
      btcCode,
      description,
      inflow,
      outflow,
      statement,
      createdBy: assignee.userId,
      changedBy: "",
    };
    if (!validateForm()) return;
    try {
      const response = await BtcServices.saveBtcCode(data);
      if (response.status === 200) {
        toast("success", "BTC Code Created Successfully")
        if (scontinue) {
          resetForm();
        } else {
          navigate("/btc");
        }
      } else {
        toast("error", response.message)
      }
    } catch (error) {
      console.error("Error details:", error);
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BTC") {
      navigate("/btc");
    } else if (crumb.title === "Bank Statements") {
      navigate("/bank-statements");
    } else if (crumb.title === "MT940") {
      navigate("/bank-statements");
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  const checkbtcExist = async () => {
    try {
      const response = await BtcServices.isBTCExist(btcCode);
      if (response.status === 201) {
        const newErrors = {};
        newErrors.btcCode = "BTC Code Already Exists.";
        toast("error", "BTC Code Already Exists")
        setErrors(newErrors);
      }
    } catch (error) {
      console.error("Error details:", error);
    }
  }
  const updateBtcCode = async () => {
    try {
      const data = {
        description,
        inflow,
        outflow,
        statement
      }
      const response = await BtcServices.updateBtcCode(id, data);
      if (response.status === 200) {
        toast("success", "BTC Code Updated Successfully")
        navigate("/btc");
      } else {
        toast("error", response.message)
      }
    } catch (error) {
      console.error("Error details:", error);
    }
  }
  return (
    <div>
      <Header
        title={`Bank Statements > MT940 > BTC > ${id ? "Edit" : "Add"}`}
        onBreadcrumbClick={handleBreadcrumbClick}
        unsavedChanges={unsavedChanges}
      />      <div className="m-6 border border-gray-200 p-4  rounded-lg">


        <div className="mb-4 flex items-center">
          <label
            className="w-48"
            htmlFor="btccode"
          >
            <span className="text-xs font-small text-customGray">BTC Code <span className="text-red-500">*</span></span>
          </label>           <span>:</span>

          <input
            maxLength={6}
            id="btccode"
            value={btcCode}
            onChange={(e) => { setBtcCode(e.target.value); setUnsavedChanges(true); }}
            readOnly={id ? true : false}
            onBlur={!id ? checkbtcExist : null}
            type="text"
            placeholder="Btc Code"
            required
            className={`border p-1.5 rounded w-72 h-8  text-customGray text-xs focus:outline-none hover:border-blue-400 ml-4 ${errors.btcCode
              ? "border-red-500 hover:border-red-500"
              : "border-gray-300 hover:border-blue-400"
              }`}
          />
        </div>
        <div className="mb-4 flex items-center">
          <label
            className="w-48"
            htmlFor="description"
          >
            <span className="text-xs font-small text-customGray">BTC Description <span className="text-red-500">*</span></span>

          </label> <span>:</span>
          <input
            maxLength={50}
            id="description"
            value={description}
            onChange={(e) => { setdescription(e.target.value); setUnsavedChanges(true); }}
            type="text"
            placeholder="Description"
            required
            className={`border p-1.5 rounded  text-customGray text-xs w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${errors.description
              ? "border-red-500 hover:border-red-500"
              : "border-gray-300 hover:border-blue-400"
              }`} />
        </div>
        <div className="mb-4 flex items-center">
          <label
            className="w-48"
            htmlFor="statetype"
          >
            <span className="text-xs font-small text-customGray">Statement Type </span>

          </label><span>:</span>
          <input
            maxLength={30}
            id="statetype"
            value={statement}
            onChange={(e) => { setstatement(e.target.value); setUnsavedChanges(true); }}
            type="text"
            placeholder="Statement Type"
            required
            className="border border-gray-300 p-1.5 rounded w-72 h-8 text-customGray text-xs focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
          />
        </div>
        <div className="mb-4 flex items-center">
          <label
            className="w-48"
            htmlFor="inflow"
          >
            <span className="text-xs font-small text-customGray">Inflow </span>

          </label><span>:</span>
          <input
            maxLength={30}
            id="inflow"
            value={inflow}
            onChange={() => {
              setInflow(inflow === "1" ? "0" : "1"); // Toggle between "1" and "0"
              setUnsavedChanges(true);
            }}
            type="checkbox"
            checked={inflow === "1"}
            required
            className=" ml-4 w-4 h-4  accent-customBlue"
          />
        </div>

        <div className="mb-4 flex items-center">
          <label
            className="w-48"
            htmlFor="outflow"
          >
            <span className="text-xs font-small text-customGray">Outflow </span>

          </label><span>:</span>
          <input
            maxLength={30}
            id="outflow"
            value={outflow}
            onChange={() => { setOutflow(outflow === "1" ? "0" : "1"); setUnsavedChanges(true); }}
            checked={outflow === "1"}
            type="checkbox"
            required
            className={` ml-4 w-4 h-4 accent-customBlue ${errors.outflow
              ? "border-red-500 hover:border-red-500"
              : "border-gray-300 hover:border-blue-400"
              } `}
          />
        </div>
        {/* <div className="flex justify-start space-x-4">
          <CustomButton title={"Save"} onClick={(e) => handleSave(e)} />

        </div> */}

      </div>


      <div className="ml-2"></div>
      {/* {!isViewMode && ( */}
      <Footer>
        <button
          onClick={(e) => handleClickSave(e, false)}
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
        >
          Save
        </button>
        {!id && (
          <button
            onClick={(e) => handleClickSave(e, true)}
            className="bg-white text-sm border border-customBlue text-customBlue py-1 px-2 rounded hover:bg-customBlue hover:text-white transition duration-200 ease-in-out ml-4 w-30"
          >
            Save & Continue
          </button>
        )}
      </Footer>
      {/* )} */}
    </div>
  );
}

export default AddBTC;
