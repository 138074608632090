import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdEdit, MdOutlineFileDownload, MdOutlineFileUpload, MdOutlineMotionPhotosAuto } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { PiGlobeXBold, PiSortDescendingBold } from "react-icons/pi";

export const bankStatementActionsConfig = {
  screen1: [
    {
      icon: IoAddCircleOutline,
      onClick: () => { },
      rotate: true,
      className: "text-blue-500",
    },
    {
      icon: GiSettingsKnobs,
      onClick: () => { },
      rotate: true,
      className: "text-green-500",
    },
    {
      icon: IoSettingsOutline,
      onClick: () => console.log("Settings"),
      className: "text-red-500",
    },
    {
      icon: MdOutlineFileDownload,
      onClick: () => { },
      className: "text-yellow-500",
    },
    { icon: MdOutlineFileUpload, onClick: () => { }, className: "text-purple-500" },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(), // Refreshes the page
      rotate: true,
      className: "text-pink-500",
    },

  ],
  // Same changes for other screens
  screen2: [
    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
      className: "text-green-500",
    },
    {
      icon: IoSettingsOutline,
      onClick: () => console.log("Settings"),
      className: "text-red-500",
    },
    {
      icon: MdOutlineFileDownload,
      onClick: () => console.log("Download"),
      className: "text-yellow-500",
    },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(), // Refreshes the page
      rotate: true,
      className: "text-pink-500",
    },
    {
      icon: PiGlobeXBold,
      onClick: () => window.location.reload(), // Refreshes the page

      className: "text-pink-500",
    },
    {
      icon: PiSortDescendingBold,
      onClick: () => window.location.reload(), // Refreshes the page

      className: "text-pink-500",
    },

  ],
  screen3: [
    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
      className: "text-green-500",
    },
    {
      icon: IoSettingsOutline,
      onClick: () => console.log("Settings"),
      className: "text-red-500",
    },
    {
      icon: MdOutlineFileDownload,
      onClick: () => console.log("Download"),
      className: "text-yellow-500",
    },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(), // Refreshes the page
      rotate: true,
      className: "text-pink-500",
    },
  ],

  screen4: [

    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
      className: "text-green-500",
    },
    {
      icon: IoSettingsOutline,
      onClick: () => console.log("Settings"),
      className: "text-red-500",
    },
    {
      icon: MdOutlineFileDownload,
      onClick: () => console.log("Download"),
      className: "text-yellow-500",
    },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(), // Refreshes the page
      rotate: true,
      className: "text-pink-500",
    },
    {
      icon: MdEdit,
      onClick: () => console.log("Settings Knobs"),

      className: "text-green-500",
    },
  ],
  screen5: [

    {
      icon: GiSettingsKnobs,
      onClick: () => { },
      rotate: true,
      className: "text-green-500",
    },
    {
      icon: IoSettingsOutline,
      onClick: () => console.log("Settings"),
      className: "text-red-500",
    },
    {
      icon: MdOutlineFileDownload,
      onClick: () => { },
      className: "text-yellow-500",
    },
    {
      icon: MdOutlineFileUpload,
      onClick: () => { },
      className: "text-yellow-500",
    },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(), // Refreshes the page
      rotate: true,
      className: "text-pink-500",
    },
    {
      icon: MdOutlineMotionPhotosAuto,
      onClick: () => { },
      className: "text-yellow-500",
    },
    // {
    //   icon: BsUbuntu,
    //   onClick: () => { },
    //   className: "text-yellow-500",
    // },
  ],
  screen6: [

    {
      icon: GiSettingsKnobs,
      onClick: () => { },
      rotate: true,
      className: "text-green-500",
    },
    {
      icon: IoSettingsOutline,
      onClick: () => console.log("Settings"),
      className: "text-red-500",
    },
    {
      icon: MdOutlineFileDownload,
      onClick: () => { },
      className: "text-yellow-500",
    },

    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(), // Refreshes the page
      rotate: true,
      className: "text-pink-500",
    },

  ],

};
