import React, { useEffect, useState } from 'react';
import Header from '../../../../../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import ReTable from '../../../../../../components/Table';
import UserService from '../../../../../../rest-services/UserServices';
import { useToast } from '../../../../../../components/toast/toast';
import AdminActions from '../../../Components/AdminActions';
import { IoMdRefresh } from 'react-icons/io';
import { AdminActionsConfig } from '../../../Components/ActionConfig';
import Footer from '../../../../../../components/footer';
import ButtonGroup from '../../../../../../components/FooterButton';
import { MdOutlineFileDownload } from 'react-icons/md';
import * as XLSX from 'xlsx'; // Import the xlsx library
import CustomeLoading from '../../../../../../components/CustomeLoading';

// import { toast } from 'react-toastify';
export default function MakerAndChecker() {
  const navigate = useNavigate();
  const { iconId } = useParams();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [userPreference, setUserPreference] = useState("");
  const [error, setError] = useState(null);
  const [assignee, setAssignee] = useState({ id: "", username: "" });

  useEffect(() => {
      if (assignee.assignerId) {
        console.log("Assigner ID has changed:", assignee.assignerId);
  fetchUserPreferenceDetails();
}
}, [assignee.assignerId]);

  const fetchUserPreferenceDetails = async () => {
    try {
      const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
      console.log("Requestfor user pref Data:", data);

      // setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log("user prefference details", response.data);
      setUserPreference(response.data); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    }
    //  finally {
    //   setLoading(false); // Reset loading state
    // }
  };

  
  useEffect(() => {
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        // console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        // console.log("Assignee ID:", assigneeData.id);
        // console.log("Assigner:", assigneeData.assigner);
        // console.log("Assigner userId:", assigneeData.assignerId);
        // console.log("Assigner clientId:", assigneeData.clientId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };


  const formatDate = (dateString, format) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const shortYear = String(year).slice(-2);
  
    const formatMap = {
        "DD/MM/YYYY": `${day}/${month}/${year}`,
        "MM/DD/YYYY": `${month}/${day}/${year}`,
        "DD/MM/YY": `${day}/${month}/${shortYear}`,
        "MM/DD/YY": `${month}/${day}/${shortYear}`,
        "DD.MM.YYYY": `${day}.${month}.${year}`,
        "DDMMYY": `${day}${month}${shortYear}`,
        "DD-MM-YYYY": `${day}-${month}-${year}`,
    };
  
    return formatMap[format] || dateString;
  };
  
  const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format
  

  
  // Define columns for the table
  const columns = [
    {
      Header: 'S.No',
      accessor: 'serialNumber', // This will be calculated dynamically
      visible: true,
      width: ("S.No".length - 2) * 10,
      Cell: ({ row }) => row.index + 1 // Generate serial number based on row index
    },
    { Header: 'Module', accessor: 'icon.mainMenu.name', visible: true, width: ("Module".length - 2) * 10, alignment: "left" },
    { Header: 'Icons', accessor: 'icon.name', visible: true, width: ("Icons".length - 2) * 10, alignment: "left" },
    {
      Header: "Active",
      accessor: "isChecked",
      visible: true,
      width: ("Active".length - 2) * 10,
      // Cell: ({ row }) => (
      //   <input
      //     type="checkbox"
      //     checked={selectedRows.includes(row.original.makerCheckerId)} // Use selectedRows state
      //     onChange={() => handleSelectRow(row.original.makerCheckerId)}
      //   />
      // ),
      Cell: ({ value, row }) => (
        <input
          type="checkbox"
          checked={value === '1'}
          className="accent-customBlue"
          onChange={(e) =>
            handleCheckboxChangeActive(row.original.makerCheckerId, "isChecked", e.target.checked)
          }
        />
      ),
    },
    
    { Header: 'Updated By', accessor: 'changedBy', visible: true, width: ("Updated By".length - 2) * 10, alignment: "left" },
    {
          Header: "Updated Date",
          accessor: "changedDate",
          visible: true,
          width: ("Updated Date".length - 2) * 10,
          canResize: true,
          Cell: ({ value }) => formatDate(value, dateFormat),
      },

  {
    Header: "Updated Time",
    accessor: "changedTime",
    visible: true,
    width: ("Updated Time".length - 2) * 10,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
    },
  },

  ];

  const [columnSettings, setColumnSettings] = useState(columns);
  const filteredUsers = [];
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [Icons, setIcons] = useState([]);
  const [alert, setAlert] = useState(null);
  const [modifiedRows, setModifiedRows] = useState([]);

  useEffect(() => {
    if (assignee.clientId) {
      fetchIconsData();
    }
  }, [assignee]);


  useEffect(() => {
    if (assignee.assignerId) {
      fetchUserPermissionDetails();
    }
  }, [assignee.assignerId]);


  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.assignerId };
      // console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      // console.log("user permissions details", response);
      const responseData = response.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      // console.log("Set Permission details for bam screen", responseData.rolesPermissionsTables);


    } catch (error) {
      console.error(error.message); // Set error message
    }
  };


  // console.log("Check user permissions", rolesPermissions);
  const hasPermissionForSave = rolesPermissions.some(
    (permission) => permission.permissionsId === 23 && permission.iconId === parseInt(iconId)
  );
  // console.log("Check user permissions to user view list", hasPermissionForSave);


  const fetchIconsData = async () => {
    try {
      // console.log("assignees data", assignee);
      const data = { clientId: assignee.clientId };
      // console.log("Request Data for users:", data);


      const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-icons-data-maker-checker`);
      console.log("Response from server fetching maker and checker:", response.data.data);
      const responseData = response.data.data;
      setIcons(responseData);


      console.log("double check users", response.data.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
    }
  };

  const handleDownload = () => {
    if (Icons.length === 0) {
      setAlert({ severity: 'warning', message: 'No data available to export!' });
      return;
    }

    // Prepare data for export
    const formattedUsers = Icons.map(user => ({
      'User ID': user.userId,
      'Status': user.approved === '0' ? 'Created' :
        user.approved === '1' ? 'Waiting for Approval' :
          user.approved === '2' ? 'Modified' :
            user.approved === '3' ? 'Approved' :
              user.approved === '4' ? 'Rejected' : 'Inactive',
      'Lock': user.userStatus === '0' ? 'Unlock' : 'Lock',
      'Employee No': user.employeeNo,
      'Salute': user.salute,
      'Username': user.username,
      'First Name': user.firstName,
      'Middle Name': user.middleName,
      'Last Name': user.lastName,
      'Language': user.UserPreference?.language || '',
      'Time Format': user.UserPreference?.timeFormat === '1' ? '12 hours' : user.UserPreference?.timeFormat === '2' ? '24 hours' : ' ',
      'Time Zone': user.UserPreference?.timeZone || '',
      'User Type': user.UserType?.name || '',
      'Company Code': user.companyCodeId,
      'Department': user.department,

      'Email ID': user.email,
      'Address 1': user.Address?.addressLine1 || '',
      'Address 2': user.Address?.addressLine2 || '',
      'Address 3': user.Address?.addressLine3 || '',
      'Country': user.Address?.Country?.country_name ?? '',
      'Mobile No': user.mobileNo,
      'Nationality': user.nationality,
      'Passport No': user.passportNo,
      'Driving License No': user.drivingLicenseNo,
      'Local ID': user.localId,

      'Update Time': user.changedTime ? user.changedTime : '',
      'Update By': user.changedBy ? user.changedBy : '',
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedUsers); // Converts JSON to sheet
    const workbook = XLSX.utils.book_new(); // Creates a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users'); // Appends the sheet to the workbook

    // Export as an Excel file
    XLSX.writeFile(workbook, 'Maker&Checker_Data.xlsx');
  };

  // Handle breadcrumb click navigation
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'User & SOD') {
      navigate('/14/admin');
    } else if (crumb.title === 'Admin') {
      navigate('/14/admin');
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the specified path in the breadcrumb
    }
  };

  // Filter and map visible columns to be used in the table
  const modifiedColumns = columnSettings
    .filter(col => col.visible)
    .map(col => ({ ...col, width: col.width }));


  const handleSelectRow = (makerCheckerId) => {
    console.log('checked maker id 2', makerCheckerId);
    handleCheckboxChange(makerCheckerId);
  };

  // const handleDoubleClick = (id) => {
  //   // console.log("check roles id", id);
  // };

  const handleCheckboxChangeActive = (makerCheckerId, field, value) => {
    
    console.log("Id of chcked maker and checker",makerCheckerId);
    console.log("field of chcked maker and checker",field);
    console.log("value of chcked maker and checker",value);
    setIcons((prevData) =>
      prevData.map((row) =>
        row.makerCheckerId === makerCheckerId ? { ...row, [field]: value ? '1' : '0' } : row
      )
    );

    
    setModifiedRows((prevModified) => {
      const modifiedRow = { makerCheckerId, [field]: value.toString() ? '1' : '0' };

      // Check if row is already modified
      const existingIndex = prevModified.findIndex((row) => row.makerCheckerId === makerCheckerId);

      if (existingIndex > -1) {
        // Merge changes if the row is already in the list
        const updatedRow = { ...prevModified[existingIndex], ...modifiedRow };
        return [
          ...prevModified.slice(0, existingIndex),
          updatedRow,
          ...prevModified.slice(existingIndex + 1),
        ];
      } else {
        // Add the new modified row
        return [...prevModified, modifiedRow];
      }
    });
  };

  const handleCheckboxChange = (makerCheckerId) => {
    console.log('checked maker id', makerCheckerId);
    const updatedSelectedRows = selectedRows.includes(makerCheckerId)
      ? selectedRows.filter((rowId) => rowId !== makerCheckerId) // Deselect if already selected
      : [...selectedRows, makerCheckerId]; // Add to selection if not selected
console.log('check box:', updatedSelectedRows);
    setSelectedRows(updatedSelectedRows);
    // console.log('check box:', updatedSelectedRows);

  };

  const handleSaveMakerAndChecker = async () => {

    try {
      console.log("maker and checker modifiedRows",modifiedRows);

      console.log("entered");
      const data = {
        ids: modifiedRows,
        changedBy: assignee.assignerId
      };

      console.log("selected row in save details", data);

      const response = await UserService.saveAllUserDetails(
        `${process.env.REACT_APP_BASE_URL}/edit-maker-and-checker-update`,
        data
      );
      console.log("response", response);
      console.log("response status", response.status);
      if (response.status === 200) {
        setAlert({
          severity: "success",
          message: "Basic Details saved successfully.",
        });

        // Wait for the success message to be displayed before navigating
        toast("success", "Changes Saved");
        navigate("/14/admin"); // Navigate to loginMethod Details tab
      }
      else {
        setAlert({ severity: "error", message: "Not saved" });
        toast("error", "Not saved.");

      }
    } catch (error) {
      console.error("Error saving basic details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while saving.",
      });
    }
  };


  const reload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchIconsData();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  }
  const existingButtons = AdminActionsConfig.screen3.map((button) => {
    let onClick;
    let title;


    if (button.icon === IoMdRefresh) {
      onClick = reload; // Reference the reload function, not invoking it
      title = "Refresh";
    }
    else if (button.icon === MdOutlineFileDownload) {
      // onClick = handleDownload;
      title = "Download File";
    }
    else {
      onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
      title = button.title || ""; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title,   // Add the title property to the button configuration
    };
  });

  const extraButtons = [];

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );


  return (
    <div>
      {/* Page Header with Breadcrumb */}
      <Header
        title="Admin > User & SOD > Maker & Checker"
        onBreadcrumbClick={handleBreadcrumbClick}
      />

      <div className="flex justify-between">
        <AdminActions
          icons={iconId}
          buttons={actionButtons}
          // extraButtons={extraButtons}
          selectedCount={selectedRows.length}
          selectUserIds={selectedRows} />

      </div>

      <div>
        {loading &&
          //  <div className="loading-spinner">Loading...</div>
          <div><CustomeLoading /></div>
        }

        {/* Table Component */}
        <ReTable
          data={Icons}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          showHeaderCheckbox={false}
          // customWidth="80%" // Set 50% width
          showCheckbox={false}
          onRowDoubleClick={() => {}}
          checkboxPosition="end" // Set the checkbox at the end
          rowKey='makerCheckerId'
        />
      </div>

      <Footer>

        {/* <ButtonGroup
        onSave={handleSaveMakerAndChecker}
      />  */}
        <button

          // onClick={handleSaveMakerAndChecker}
          onClick={hasPermissionForSave ? handleSaveMakerAndChecker : null}
          disabled={!hasPermissionForSave} // Disable button if no permission
          title={!hasPermissionForSave ? "You don't have permission" : ""}
          className={`bg-customBlue text-sm text-white py-1 px-2 rounded 
            ${hasPermissionForSave ? "hover:bg-blue-600" : "bg-gray-400"}
            transition duration-200 ease-in-out w-16 ml-7`}
        >
          {/* className={`bg-customBlue text-sm text-white py-1 px-2 rounded 
          hover:bg-blue-600
          transition duration-200 ease-in-out w-16 ml-7`} */}

          {/* className={`bg-customBlue text-sm text-white py-1 px-2 rounded 
          ${hasPermissionForSave ? "hover:bg-blue-600" : "bg-gray-400"}
          transition duration-200 ease-in-out w-16 ml-7`} */}

          Submit
        </button>
      </Footer>

    </div>
  );
}
