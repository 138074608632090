import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminActions from '../../../../Admin/Components/AdminActions';
import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import XLSX from "xlsx-js-style";
import ReTable from '../../../../../../components/Table';
import UserService from '../../../../../../rest-services/UserServices';
import CustomeLoading from '../../../../../../components/CustomeLoading';

export default function BankBranchUploadTableScreen() {
const navigate = useNavigate();
const { iconId } = useParams();   
const location = useLocation();
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
const [selectedRows, setSelectedRows] = useState([]); 
  const [processedData, setProcessedData] = useState([]);
  const { tableData, displayOption, uploadOption  } = location.state || {}; // Retrieve tableData from state
  const [loading, setLoading] = useState(false);
  const [stateCodes, setStateCodes] = useState([]);


  useEffect(() => {
    fetchAssignee();
    fetchStateProvinceCode();

  }, []);
      
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",

        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        // console.log("Assignee ID:", assigneeData.id);
        // console.log("Assigner:", assigneeData.assigner);
        // console.log("Assigner clientId:", assigneeData.clientId);
        // console.log("Assigner userId:", assigneeData.userId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchStateProvinceCode = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-stateprovince-code`
      );
      console.log("response. state data",response.data.data);
      setStateCodes(response.data.data);
      // console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
    };

  
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Company & Bank Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Bank Branch") {
      navigate("/2/master-data/11/bankbranch");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  
  const handleDownload = () => {
                            if (processedData.length === 0) {
                              setAlert({
                                severity: "warning",
                                message: "No data available to export!",
                              });
                              return;
                            }
                          
                            const extractText = (cellValue) => {
                              if (Array.isArray(cellValue)) {
                                return cellValue.join(", "); // Convert array to string
                              } else if (typeof cellValue === "string") {
                                return cellValue;
                              } else if (React.isValidElement(cellValue)) {
                                return cellValue.props.children;
                              }
                              return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                            };
                          
                            const formattedUsers = processedData.map((user) => ({
                              "Action": extractText(user.Action),
                              "SLNo": extractText(user.SLNo),
                              "BankBranch": extractText(user.BankBranch),
                              "BankBranchName": extractText(user.BankBranchName),
                              "BankId": extractText(user.BankId),
                              "CopyTheBankIDAddress": extractText(user.CopyTheBankIDAddress),
  
                              "DoorNo": extractText(user.DoorNo),
                              "FloorNo": extractText(user.FloorNo),
                              "BuildingNo": extractText(user.BuildingNo),
                              "BuildingName": extractText(user.BuildingName),
                              "LandMark": extractText(user.LandMark),
                              "StreetNo": extractText(user.StreetNo),
                              "StreetName": extractText(user.StreetName),
                              "RoadNo": extractText(user.RoadNo),
                              "RoadName": extractText(user.RoadName),
                              "Block": extractText(user.Block),
                              "AreaName": extractText(user.AreaName),
                              "Phase": extractText(user.Phase),
                              "Sector": extractText(user.Sector),
                              "Village": extractText(user.Village),
                              "Town": extractText(user.Town),
                              "CityName": extractText(user.CityName),
                              "AddressLine1": extractText(user.AddressLine1 || ""),
                              "AddressLine2": extractText(user.AddressLine2 || ""),
                              "AddressLine3": extractText(user.AddressLine3 || ""),
                              "CountryId": extractText(user.CountryId || ""),
                              "StateProvince": extractText(user.StateProvince),
                              "District": extractText(user.District),
                              "PostalCode": extractText(user.PostalCode),
                              "ZipCode": extractText(user.ZipCode),
                              "POBox": extractText(user.POBox),
                              "BankBic": extractText(user.BankBic),
                              "CountryCode": extractText(user.CountryCode),
                              "CountryCodeType": extractText(user.CountryCodeType),
                              "CustomerCode": extractText(user.CustomerCode),
                              "MICR": extractText(user.MICR),
                              "BankCode": extractText(user.BankCode),
                              "BranchCode": extractText(user.BranchCode),
                              "Min": extractText(user.Min),
                              "Max": extractText(user.Max),
                              "IBANRequired": extractText(user.IBANRequired),
                              }));
                          
                    
                      // Add second and third row
                      const secondRow = {
                        "Action": "R","SLNo": "R", "BankBranch": "R","BankBranchName": "R",  "BankId": "R", "CopyTheBankIDAddress": "R",
                        "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
                        "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "AreaName": "", "Phase":"","Sector": "", "Village":"", "Town": "", "CityName": "",  "AddressLine1": "", "AddressLine2": "", "AddressLine3": "", 
                        "CountryId": "R", "StateProvince": "Conditional", "District": "", "PostalCode": "", "ZipCode": "", "POBox": "","BankBic":'', "CountryCode":'', "CountryCodeType":"","CustomerCode":"","MICR":"","BankCode":"","BranchCode":"","Min":"","Max":"","IBANRequired":"Check box"
                      };
                    
                      const thirdRow = {
                         "Action": "1","SLNo": "5", "BankBranch": "10","BankBranchName": "150","BankId": "3", "CopyTheBankIDAddress": "Check box",
                         "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
                        "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "AreaName": "", "Phase":"","Sector": "", "Village":"", "Town": "", "CityName": "",  "AddressLine1": "50", "AddressLine2": "50", "AddressLine3": "50", 
                        "CountryId": "2", "StateProvince": "3", "District": "", "PostalCode": "", "ZipCode": "","POBox": "","BankBic":'', "CountryCode":'', "CountryCodeType":"","CustomerCode":"","MICR":"","BankCode":"","BranchCode":"","Min":"","Max":"","IBANRequired":""
                      };
                    
                        // Insert second and third row at the beginning of the formatted data
                        const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
                    
                            const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
                          console.log("rowsToDownload data is",rowsToDownload);
                     
                            // Define border style
                      const border = {
                        top: { style: "thin", color: { rgb: "000000" } },
                        right: { style: "thin", color: { rgb: "000000" } },
                        bottom: { style: "thin", color: { rgb: "000000" } },
                        left: { style: "thin", color: { rgb: "000000" } },
                      };
                    
                          // Apply borders to the first three rows
                          rowsToDownload.slice(0, 1).forEach((row, rowIdx) => {
                            Object.keys(row).forEach((col, colIdx) => {
                              const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
                              if (worksheet[cellAddress]) {
                                worksheet[cellAddress].s = { border };
                              }
                            });
                          });
                    
                          
                           // Additional cell styling for validation errors
                            Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
                              rowsToDownload.forEach((row, rowIdx) => {
                                const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
                                const cellValue = row[col];
                          
                                if (
                                  typeof cellValue === "string" && (
                                    cellValue.includes("Shouldn't be blank") 
                                    ||
                                    cellValue.includes("Either Address Section or Building, Street, and Area Section must be entered")
                                    // cellValue.includes("Max. allowed character")
                                  )
                                ) {
                                  worksheet[cellAddress] = {
                                    v: cellValue,
                                    s: { font: { color: { rgb: "FF0000" } } },
                                  };
                                } else if (Array.isArray(row[col])) {
                                  worksheet[cellAddress] = {
                                    v: extractText(row[col]),
                                    s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
                                  };
                                }
                                else {
                                  worksheet[cellAddress] = { v: cellValue };
                                }
                              });
                            });
                          
                            // AutoFit column width
                            worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
                              const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
                              return { wch: maxLength + 2 }; // Add extra space
                            });
                    
                    
                            const workbook = XLSX.utils.book_new();
                            XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
                            XLSX.writeFile(workbook, "Bank_Branch_Upload.xlsx");
                          };
  
  const columns = [   
                  
            { Header: 'Action', accessor: 'Action', visible: true, width: ("".length -1)*10, alignment: "left" },
            { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1)*10, alignment: "left" },
           {
                         Header: 'Update',
                         accessor: (row) => {
                           if (row.successfulrecords && uploadOption === '3') {
                             return 'Yes';
                           } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                             return 'No';
                           } else {
                             return <span style={{ color: 'red' }}>No</span>;
                           }
                         },
                         visible: true,
                         width: ("Update".length -1)*10,
                         alignment: 'left',
                       },
            { Header: 'BankBranch', accessor: 'BankBranch', visible: true, width: ("BankBranch".length -1)*10, alignment: "left" },
            { Header: 'BankBranchName', accessor: 'BankBranchName', visible: true, width: ("BankBranchName".length -1)*10, alignment: "left" },
            { Header: 'BankId', accessor: 'BankId', visible: true, width: ("BankId".length -1)*10, alignment: "left" },
            { Header: 'CopyTheBankIDAddress', accessor: 'CopyTheBankIDAddress', visible: true, width: ("CopyTheBankIDAddress".length -1)*10, alignment: "left" },
          { Header: 'DoorNo', accessor: 'DoorNo', visible: true, width: ("DoorNo".length -1)*10, alignment: "left" },
                  { Header: 'FloorNo', accessor: 'FloorNo', visible: true, width: ("FloorNo".length -1)*10, alignment: "left" },
                  { Header: 'BuildingNo', accessor: 'BuildingNo', visible: true, width: ("BuildingNo".length -1)*10, alignment: "left" },
                  { Header: 'BuildingName', accessor: 'BuildingName', visible: true, width: ("BuildingName".length -1)*10, alignment: "left" },
                  { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1)*10, alignment: "left" },
                  { Header: 'StreetNo', accessor: 'StreetNo', visible: true, width: ("StreetNo".length -1)*10, alignment: "left" },
                  { Header: 'StreetName', accessor: 'StreetName', visible: true, width: ("StreetName".length -1)*10, alignment: "left" },
                  { Header: 'RoadNo', accessor: 'RoadNo', visible: true, width: ("RoadNo".length -1)*10, alignment: "left" },
                  { Header: 'RoadName', accessor: 'RoadName', visible: true, width: ("RoadName".length -1)*10, alignment: "left" },
                  { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1)*10, alignment: "left" },
                  // { Header: 'Area Name', accessor: 'Area Name', visible: true, width: ("".length -1)*10, alignment: "left" },
                  // { Header: 'streetName', accessor: 'streetName', visible: true, width: ("".length -1)*10, alignment: "left" },
                  { Header: 'AreaName', accessor: 'AreaName', visible: true, width: ("AreaName".length -1)*10, alignment: "left" },
                  { Header: 'Phase', accessor: 'Phase', visible: true, width: ("Phase".length -1)*10, alignment: "left" },
                  { Header: 'Sector', accessor: 'Sector', visible: true, width: ("Sector".length -1)*10, alignment: "left" },
                  { Header: 'Village', accessor: 'Village', visible: true, width: ("Village".length -1)*10, alignment: "left" },
                  { Header: 'Town', accessor: 'Town', visible: true, width: ("Town".length -1)*10, alignment: "left" },
                  { Header: 'CityName', accessor: 'CityName', visible: true, width: ("CityName".length -1)*10, alignment: "left" },
                  // { Header: 'landmarkName', accessor: 'landmarkName', visible: true, width: ("".length -1)*10, alignment: "left" },
                  { Header: 'AddressLine1', accessor: 'AddressLine1', visible: true, width: ("AddressLine1".length -1)*10, alignment: "left" },
                  { Header: 'AddressLine2', accessor: 'AddressLine2', visible: true, width: ("AddressLine2".length -1)*10, alignment: "left" },
                  { Header: 'AddressLine3', accessor: 'AddressLine3', visible: true, width: ("AddressLine3".length -1)*10, alignment: "left" },
                  { Header: 'CountryId', accessor: 'CountryId', visible: true, width: ("CountryId".length -1)*10, alignment: "left" },
                  { Header: 'StateProvince', accessor: 'StateProvince', visible: true, width: ("StateProvince".length -1)*10, alignment: "left" },
                  { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1)*10, alignment: "left" },
                  { Header: 'PostalCode', accessor: 'PostalCode', visible: true, width: ("PostalCode".length -1)*10, alignment: "left" },
                  { Header: 'ZipCode', accessor: 'ZipCode', visible: true, width: ("ZipCode".length -1)*10, alignment: "left" },
                  { Header: 'POBox', accessor: 'POBox', visible: true, width: ("POBox".length -1)*10, alignment: "left" },
                  { Header: 'BankBic', accessor: 'BankBic', visible: true, width: ("BankBic".length -1)*10, alignment: "left" },
                  { Header: 'CountryCode', accessor: 'CountryCode', visible: true, width: ("CountryCode".length -1)*10, alignment: "left" },
                  { Header: 'CountryCodeType', accessor: 'CountryCodeType', visible: true, width: ("CountryCodeType".length -1)*10, alignment: "left" },
                  { Header: 'CustomerCode', accessor: 'CustomerCode', visible: true, width: ("CustomerCode".length -1)*10, alignment: "left" },
                  { Header: 'MICR', accessor: 'MICR', visible: true, width: ("MICR".length -1)*10, alignment: "left" },
                  { Header: 'BankCode', accessor: 'BankCode', visible: true, width: ("BankCode".length -1)*10, alignment: "left" },
                  { Header: 'BranchCode', accessor: 'BranchCode', visible: true, width: ("BranchCode".length -1)*10, alignment: "left" },
                  { Header: 'Min', accessor: 'Min', visible: true, width: ("Min".length -1)*10, alignment: "left" },
                  { Header: 'Max', accessor: 'Max', visible: true, width: ("Max".length -1)*10, alignment: "left" },
                  { Header: 'IBANRequired', accessor: 'IBANRequired', visible: true, width: ("IBANRequired".length -1)*10, alignment: "left" },
                
            ];

        const existingButtons = AdminActionsConfig.screen7.map((button) => {
          let onClick;
          let title;
      
      
          if (button.icon === MdOutlineFileDownload) {
            onClick = handleDownload;
            title = "Download File";
          }
      
          else {
            onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
            title = button.title || ""; // Use existing title or default to an empty string
          }
      
          return {
            ...button,
            onClick, // Reference the function without invoking it
            title,   // Add the title property to the button configuration
          };
        });
              
          const extraButtons = [
  
          ];
                  
        const actionButtons = existingButtons.concat(
          extraButtons.map((button) => ({
            ...button,
            disabled: selectedRows.length === 0, // Disable button if no users are selected
            onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
          }))
        );
              
                        
        const [columnSettings, setColumnSettings] = useState(columns);       
                
        const modifiedColumns = columnSettings
        .filter((col) => col.visible)
        .map((col) => ({
          ...col,
          width: col.width, // This should hold the updated width
        }));

       
        const getExistingUserIds = async (tableData) => {
          try {
            console.log("userIds", tableData);

              // Filter valid rows from the table data
              const validRows = tableData.filter((row, index) => isRowValid(row, index));
              console.log("valid rows checking in upload:", validRows);
        
              // BankID valid data get
            const response1 = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-BankIDGreen-duplicate/BankId`,
              { validRows}
            );
            console.log("Bank id's : Response data", response1.data);
            const existingBankIDs = response1.data; // Extract existing user IDs from API response
          
            // Remove duplicates based on API response
            const CheckBoxBasedCountryStateValidRows = await filterData(validRows, existingBankIDs);

            console.log("CheckBox Based Country State ValidRows  removed data",CheckBoxBasedCountryStateValidRows);

// Country Code valid data get
// const response4 = await UserService.saveContactDetails(
//   `${process.env.REACT_APP_BASE_URL}/get-CountryID-exists-only`,
//   { validRows:withoutDuplicateBankIDs }
//   );
//   console.log("check Country code is exists", response4.data);
//   const existingCountryIds = response4.data; // Extract existing user IDs from API response
  
//   const ExistCountryIdValidRows = await filterExistingCountryIds(withoutDuplicateBankIDs, existingCountryIds);
  
//   console.log("successful and row valid in countryID filters 1", ExistCountryIdValidRows);
  
  
  
//   // State Code valid data get
//   const response5 = await UserService.saveContactDetails(
//   `${process.env.REACT_APP_BASE_URL}/get-StateID-exists-only`,
//   { validRows:ExistCountryIdValidRows }
//   );
//   console.log("check state code is exists 1", response5.data);
//   const existingstateIds = response5.data; 
  
//   const ExiststateIdValidRows = await filterExistingstateIds(ExistCountryIdValidRows, existingstateIds);
  
//   console.log("successful and row valid in stateID filters 1", ExiststateIdValidRows);
  
  
            
            // Bank Branch valid Get data 
            // Make API call to fetch existing user IDs
            const response = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-BankBranch-duplicate/BankBranch`,
              { validRows:CheckBoxBasedCountryStateValidRows }
            );
  
            console.log("errored records Bank id's : Response data", response.data);
            const existingUserIds = response.data; // Extract existing user IDs from API response
            console.log("existing Bank Branch exists",existingUserIds);

            const withoutDuplicateUserIds = await filterExistingUserIds(CheckBoxBasedCountryStateValidRows, existingUserIds);
       
            // Further filter the results to ensure only valid rows are returned
            // const validWithoutDuplicateUserIds = withoutDuplicateUserIds.filter((row) =>
            //   isRowValid(row)
            // );
        
            console.log("successfuland processed Records data:", withoutDuplicateUserIds);
        
            return withoutDuplicateUserIds;
        
          } catch (error) {
            console.error("Error fetching existing user IDs:", error);
            return [];
          }
        };
  
        const filterExistingUserIds = async (ExiststateIdValidRows, existingUserIds) => {
          return ExiststateIdValidRows.filter((row) => existingUserIds.includes(row.BankBranch));
        };

        const filterData = async (validRows, existingBankIDs) => {
          // Remove duplicates based on API response
          const withoutDuplicateBankIDs = await filterExistingBankIds(validRows, existingBankIDs);
          console.log("BankId removed data", withoutDuplicateBankIDs);
        
          // Step 1: Extract BankBranch values where CopyTheBankIDAddress exists
          const directBankBranches = withoutDuplicateBankIDs
                        .filter(row => {
                            if (row.StateProvince && !row.CountryId) {
                                return false;
                            }
                            if (row.CountryId && row.StateProvince) {
                              return false; // Exclude rows where both CountryId and StateProvince exist
                          }
                            return row.CopyTheBankIDAddress;
                        })
                        .map(row => row.BankBranch);
          
          console.log("Direct bank branches (from CopyTheBankIDAddress):", directBankBranches);
        
          // Step 2: Process when CopyTheBankIDAddress is empty
          const toProcessRows = withoutDuplicateBankIDs.filter(row => !row.CopyTheBankIDAddress);
          console.log("Step 2 process data",toProcessRows);
          
          let existsCountryStateValidBankBranch = [];
        
          if (toProcessRows.length > 0) {
           
            // Country Code valid data get
            const response4 = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-CountryID-exists-only`,
              { validRows: toProcessRows }
            );
            console.log("Check Country code is exists", response4.data);
            const existingCountryIds = response4.data;
        
            const ExistCountryIdValidRows = await filterExistingCountryIds(toProcessRows, existingCountryIds);
            console.log("Successful and row valid in countryID filters", ExistCountryIdValidRows);
        
            const getExistingCountryNotExistingStateData = ExistCountryIdValidRows
            .filter(row => row.CountryId && !row.StateProvince)
            .map(row => row.BankBranch);
          
          console.log("Direct bank branches (from country Id):", getExistingCountryNotExistingStateData);
        
            
            // State Code and Country valid data get
            const response5 = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-StateID-exists-only-1`,
              { validRows: ExistCountryIdValidRows }
            );
            console.log("Check state code is exists", response5.data);
            const existingstateIds = response5.data;
        
            const ExiststateIdValidRows = await filterExistingstateIds(ExistCountryIdValidRows, existingstateIds);
            console.log("Successful and row valid in stateID filters", ExiststateIdValidRows);
        
            // Step 3: Extract BankBranch values from processed data
            // existsCountryStateValidBankBranch = ExiststateIdValidRows.map(row => row.BankBranch);
            existsCountryStateValidBankBranch = [
              ...getExistingCountryNotExistingStateData,
              ...ExiststateIdValidRows.map(row => row.BankBranch)
            ];

            console.log("Exists country state valid BankBranch",existsCountryStateValidBankBranch);
          }
        
          // Step 4: Merge both arrays
          const finalBankBranches = [...new Set([...directBankBranches, ...existsCountryStateValidBankBranch])];
          console.log("Final Bank Branches (merged):", finalBankBranches);
        
          // Step 5: Filter withoutDuplicateBankIDs based on finalBankBranches
          const filteredData = withoutDuplicateBankIDs.filter(row =>
            finalBankBranches.includes(row.BankBranch)
          );
        
          console.log("Final Filtered Data:", filteredData);
        
          return filteredData;
        };
        
        
        const filterExistingBankIds = async (validRows, existingBankIDs) => {
          return validRows.filter((row) => existingBankIDs.includes(row.BankId));
        }
  
        const filterExistingCountryIds = async (withoutDuplicateBankIDs, existingCountryIds) => {
          return withoutDuplicateBankIDs.filter((row) => existingCountryIds.includes(row.CountryId));
        };
      
        const filterExistingstateIds = async (ExistCountryIdValidRows, existingstateIds) => {
          // Convert existing state-country pairs into a Set for quick lookup
          const existingStateCountrySet = new Set(
            existingstateIds.map(({ state, country }) => `${state}-${country}`)
          );
        
          // Filter valid rows based on matching state and country
          return ExistCountryIdValidRows.filter(
            (row) => existingStateCountrySet.has(`${row.StateProvince}-${row.CountryId}`)
          );
        };
      
        const isRowValid = (row, index) => {
          // Common validations
          const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
          const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
          // const isBankBranchValid = row.BankBranch && String(row.BankBranch).length <= 10;
          const isBankBranchValid = row.BankBranch && String(row.BankBranch).trim().length <= 10;
          const isAddress1Valid = row.AddressLine1 ? String(row.AddressLine1).length <= 50 : true;
          const isAddress2Valid = row.AddressLine2 ? String(row.AddressLine2).length <= 50 : true;
          const isAddress3Valid = row.AddressLine3 ? String(row.AddressLine3).length <= 50 : true;
           
          let errors = [];
      
          if (!isActionValid) errors.push("Invalid Action");
          if (!isSLNoValid) errors.push("Invalid SLNo");
          if (!isBankBranchValid) errors.push("Invalid BankBranch");
           
          if (!isAddress1Valid) errors.push("Invalid Address1");
          if (!isAddress2Valid) errors.push("Invalid Address2");
          if (!isAddress3Valid) errors.push("Invalid Address3");
  
        
  
  
          // Action "A" or "a" specific validations
          if (row.Action === "A" || row.Action === "a") {
            const isBankBranchNameValid = row.BankBranchName && String(row.BankBranchName).length <= 150;
            const isBankIdValid = row.BankId && String(row.BankId).length <= 10;
  
            
          
              // Check required address fields only if CopyTheBankIDAddress is NOT present
              if (!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === "") {
                  // Check if at least one of DoorNo, BuildingNo, BuildingName, or Address1 is filled
                  const hasRequiredInfo = !!(row.DoorNo || row.FloorNo || row.BuildingNo || row.BuildingName || 
                    row.LandMark || row.StreetNo || row.StreetName || row.RoadNo || row.RoadName || row.Block ||
                    row.AreaName || row.Phase || row.Sector || row.Village || row.Town || row.CityName || 
                    row.AddressLine1 || row.AddressLine2 || row.AddressLine3 );
                    const isCountryIdValid = row.CountryId && String(row.CountryId).length <= 2;
  
                if (!hasRequiredInfo) {
                    errors.push("At least one of DoorNo, FloorNo, BuildingNo, or BuildingName is required");
                }
                if (!isCountryIdValid) errors.push("Invalid CountryId");
  
                return (
                  hasRequiredInfo &&
                  isCountryIdValid
  
              );
            }
  
            // if (!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === "") {
            //   const isCountryIdValid = row.CountryId && String(row.CountryId).length <= 2;
            //     if (!isCountryIdValid) errors.push("Invalid CountryId");
            //       return (
            //         isCountryIdValid
            //       );
            // }
          // Only check required address fields when CopyTheBankIDAddress is NOT present
          // if (!row.CopyTheBankIDAddress && !hasRequiredInfo) {
          //   errors.push("At least one of DoorNo, FloorNo, BuildingNo, or BuildingName is required");
          // }
  
            if (!isBankBranchNameValid) errors.push("Invalid Bank Branch Name");
            if (!isBankIdValid) errors.push("Invalid BankId");
      
            
              if (errors.length > 0) {
                  console.log(`Row ${index + 1} is invalid:`, errors);
              }
      
              return (
                  isBankBranchNameValid &&
                  isBankIdValid 
                   
              );
          }
      
          // Action "E" or "e" specific validations
          if (row.Action === "E" || row.Action === "e") {
            console.log("enter in the Action E");
  
              const isBankBranchNameValid = row.BankBranchName ? String(row.BankBranchName).length <= 150 : true;
              const isBankIdValid = row.BankId ? String(row.BankId).length <= 30 : true;
              const isCountryIdValid = row.CountryId ? String(row.CountryId).length <= 2 : true;
      
              if (!isBankBranchNameValid) errors.push("Invalid LegalEntityName");
              if (!isBankIdValid) errors.push("Invalid BankId");
              if (!isCountryIdValid) errors.push("Invalid CountryId");
      
              if (errors.length > 0) {
                  console.log(`Row ${index + 1} is invalid:`, errors);
              }
      
              return (
                  isBankBranchNameValid &&
                  isBankIdValid &&
                  isCountryIdValid &&
                  isAddress1Valid &&
                  isAddress2Valid &&
                  isAddress3Valid
              );
          }
  
      //     if(!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === ""){
      //       console.log("inside the copy bank id address");
  
      //       if(row.Action === 'A' || row.Action === 'a' || row.Action === 'E' || row.Action === 'e'){
      //         console.log("inside the action button");
  
              
      //       }
      //     }
      // else{
      //   console.log("Outside the copy address");
      // }
          if (errors.length > 0) {
              console.log(`Row ${index + 1} is invalid:`, errors);
          }
      
          return isActionValid && isSLNoValid && isBankBranchValid;
      };
        
      const isExistingStateId = async (StateProvince) =>{
        try {
            console.log("exisitng user id",StateProvince);
            const data = { StateCode:StateProvince };
        
            const response = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-StateCode-duplicates`,
              data);
            console.log("existing state details:", response);
        
            // if (response?.CountryID !== undefined) {
            //   return response.CountryID; // Return the CountryID if found
            // }
            // return false; // Return false if CountryID is null
            return response?.StateID ? response : false; 
          } catch (error) {
            console.error("Error checking existing userId:", StateProvince, error);
            return false;
          }
        };
      
      const isExistingCountryId = async (CountryId) =>{
        try {
            console.log("exisitng user id",CountryId);
            const data = { CountryCode:CountryId };
        
            const response = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-CountryCode-duplicates`,
              data);
            console.log("existingUserId details:", response);
        
            // if (response?.CountryID !== undefined) {
            //   return response.CountryID; // Return the CountryID if found
            // }
            // return false; // Return false if CountryID is null
            return response?.CountryID ? response : false; 
          } catch (error) {
            console.error("Error checking existing userId:", CountryId, error);
            return false;
          }
        };

      const isExistingGreenBankId = async (BankId) => {
        try {
          console.log("exisitng user id",BankId);
          const data = { BankId };
      
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-BankIDGreenCode-duplicates`,
            data);
      
          console.log("existingUserId details:", response);
      
          // return response && response.BankId !== null; // Return true if userId exists, false otherwise
          return response?.BankId ? response : false; 
        } catch (error) {
          console.error("Error checking existing userId:", BankId, error);
          return false;
        }
      };
      const  isExistingBankId = async (BankId) => {
        try {
          console.log("exisitng New Bank id",BankId);
          const data = { BankId };
      
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-BankId-duplicates`,
            data);
      
          console.log("existingBankId details:", response.BankId);
          return response?.BankId ? response : false; 
          // return response && response.BankId !== null; // Return true if userId exists, false otherwise
        } catch (error) {
          console.error("Error checking existing userId:", BankId, error);
          return false;
        }
      };
      
const isExistingUserId = async (BankBranch) => {
  try {
    console.log("exisitng bank branch id",BankBranch);
    const data = { BankBranchid: BankBranch };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BankBranch-duplicates`,
      data);

    console.log("existingUserId details:", response.BankBranch);

    return response && response.BankBranch !== null; // Return true if userId exists, false otherwise
  } catch (error) {
    console.error("Error checking existing userId:", BankBranch, error);
    return false;
  }
};


const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
  let dataToDisplay;
  if (displayOption === "1") {
    console.log("Display option is 1");
    const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"

     // Extract the current dataToDisplay (which is only one record currently)
     const currentDataToDisplay = existingUsers.length ? existingUsers[0] : null;

     // Filter out the currentDataToDisplay row from tableData to get other 6 rows
     dataToDisplay = tableData.filter(row => row.BankBranch !== currentDataToDisplay?.BankBranch);
 
     console.log("Filtered remaining data excluding currentDataToDisplay:", dataToDisplay);

  } else if (displayOption === "2") {
    console.log("Display option is 2");
    dataToDisplay = tableData; // Display tableData if displayOption is "2"
  } else if (uploadOption === "3") {
    console.log("upload option is 3");
    dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"

    // dataToDisplay = successfulRecords; // Display successfulRecords if uploadOption is "3"
    // dataToDisplay = await fetchAndFilterSuccessfulRecords(tableData); // Successful records for uploadOption "3"

  } else {
    dataToDisplay = tableData; // Default to tableData if no conditions are met
  }

// console.log("dataToDisplay data is",dataToDisplay);
return dataToDisplay;
};


// (async () => {
//   const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
  
//   if (!Array.isArray(dataToDisplay)) {
//     console.error("getDataToDisplay did not return an array:", dataToDisplay);
//     return;
//   }

//   console.log("Final dataToDisplay data is", dataToDisplay);

//   // Call processData only when dataToDisplay is defined and valid
//   await processData(dataToDisplay);

// })();


async function checkDuplicateUserIdsFromDB(BankBranchs) {
  try {
    const data = { BankBranchs };
    console.log("BankBranchs duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-BankBranches-duplicates`,
      data
    );

    // console.log("API Response for response details:", response);
    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}
const processData = async (data) => {
  // Extract all userIds from dataToDisplay
  
  if (!Array.isArray(data)) {
    console.error("processData received invalid data:", data);
    return []; // Return an empty array to prevent further errors
  }
  console.log("processData received  data:", data);

  const BankBranchs = data.map(row => row.BankBranch).filter(Boolean);
  console.log("Extracted BankBranch's:", BankBranchs);

  const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(BankBranchs);


   // Preprocess table data to add conditional rendering for userId and username
  //  return data.map((row, _, allRows) => {
    return Promise.all(data.map(async (row, _, allRows) => {
    // const rowInvalid = isRowInvalid(row);
    // Define the max allowed characters for each column (this can come from your column definitions)
  
      // Check for duplicate userId in dataToDisplay
  const isDuplicateUserId = allRows.filter(r => r.BankBranch == row.BankBranch).length > 1;
console.log("Duplicate user id is ",isDuplicateUserId);

 // Check for duplicate userId in the database
 const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.BankBranch);

 const userExists = await isExistingUserId(row.BankBranch);
 const BankIDExists = await isExistingBankId(row.BankId);
 const BankIDGreenExists = await isExistingGreenBankId(row.BankId);
 const countryIDExists = await isExistingCountryId(row.CountryId);
//  const StateIDExists = await isExistingStateId(row.StateProvince);
const StateIDExists = stateCodes.some(
  (code) => 
    code.state.trim().toUpperCase() === row.StateProvince?.trim().toUpperCase() &&
    code.countryCode.trim().toUpperCase() === row.CountryId?.trim().toUpperCase()
);


 console.log(`BankBranch id ${row.BankBranch} exists:`, userExists);
 console.log(`Bank id ${row.BankId} exists:`, BankIDExists);
 console.log(`Bank approved id ${row.BankId} exists:`, BankIDGreenExists);
console.log(`Country ID is ${row.CountryId} exists:`, countryIDExists);
console.log(`state ID is ${row.StateProvince} exists:`, StateIDExists);
 // Check for errors (red text) and apply highlight based on that

const hasNotError = [
  row.SLNo,
  row.Action,
  row.BankBranch,
  row.BankBranchName,
  row.BankId,
  row.AddressLine1,
  row.AddressLine2,
  row.AddressLine3,
  row.CountryId,
  row.StateProvince,
  
].every(field => {
  let isValid = true;
  let reason = "";
  
  if (field === row.SLNo) {
    if (!field) { isValid = false; reason = "SLNo should not be blank."; }
    else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
    else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
  }
  
  else if (field === row.Action) {
    if (!field) { isValid = false; reason = "Action should not be blank."; }
    else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
  }
  
  
//   if (!row.BankBranch || String(row.BankBranch).trim() === "") {
//     if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
//       isValid = false;
//       reason = "BankBranch should not be empty.";
//   }
// else if (field === row.BankBranch) {
//   if (String(field).length > 10) { isValid = false; reason = "BankBranch ID exceeds max length of 10."; }
//   else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
//     isValid = false; reason = "BankBranch ID does not exist.";
//   }
//   else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
//     isValid = false; reason = "BankBranch ID is duplicate.";
//   }
// }
// }

if (!row.BankBranch || String(row.BankBranch).trim() === "") {
  isValid = false;
  reason = "BankBranch should not be empty.";
}
// if (!field) { isValid = false; reason = "BankBranch ID should not be blank."; }
else if (String(row.BankBranch).length > 10) { isValid = false; reason = "BankBranch ID exceeds max length of 10."; }
else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
isValid = false; reason = "BankBranch ID does not exist.";
}
else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
isValid = false; reason = "BankBranch ID is duplicate.";
}


if (!row.BankBranchName || String(row.BankBranchName).trim() === "") {
  if (row.Action === "A" || row.Action === "a") {
    isValid = false;
    reason = "BankBranchName should not be empty.";
}
} else if (field === row.BankBranchName) {
    if (row.Action === "E" || row.Action === "e") {
        if (!field.trim()) {
            isValid = true;
            reason = "BankBranchName should be blank for Edit action.";
        } else if (String(field).trim().length > 150) {
            isValid = false;
            reason = "BankBranchName exceeds max length of 150.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (!field.trim()) {
            isValid = false;
            reason = "BankBranchName should not be blank for Add action.";
        } else if (String(field).trim().length > 150) {
            isValid = false;
            reason = "BankBranchName exceeds max length of 150.";
        }
    }
}


if (!row.BankId || String(row.BankId).trim() === "") {
  if (row.Action === "A" || row.Action === "a") {
    isValid = false;
    reason = "BankId should not be empty.";
}
} else if (field === row.BankId) {
  if(BankIDExists){
    if(BankIDGreenExists){
      isValid = true;
      reason = "BANKID is exists";
    }
    else{
      isValid = false;
      reason = "BANKID is not exists because of the flag status"
    }
  }
  else{
    isValid = false;
      reason = "BANKID is not exists "
  }
    if (row.Action === "E" || row.Action === "e") {
        if (!field.trim()) {
            isValid = true;
            reason = "BankId should be blank for Edit action.";
        } else if (String(field).trim().length > 10) {
            isValid = false;
            reason = "BankId exceeds max length of 10.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (!field.trim()) {
            isValid = false;
            reason = "BankId should not be blank for Add action.";
        } else if (String(field).trim().length > 10) {
            isValid = false;
            reason = "BankId exceeds max length of 10.";
        }
    }
  }

// else if (field === row.BankName) {
// if (row.Action === "E" || row.Action === "e") {
// if (!field) { isValid = true; reason = "BankName should be blank for Edit action."; }
// else if (String(field).length > 150) { isValid = false; reason = "BankName exceeds max length of 150."; }
// } else if (row.Action === "A" || row.Action === "a") {
// if (!field) { isValid = false; reason = "BankName should not be blank for Add action."; }
// if (String(field).length > 150) { isValid = false; reason = "BankName exceeds max length of 150."; }
// }
// }


  // else if (field === row.addressLine1) {
  //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //     if (!field) { isValid = true; reason = "addressLine1 should be blank for Add/Edit action."; }
  //   }
  //   if (String(field).length > 50) { isValid = false; reason = "addressLine1 exceeds max length of 50."; }
  // }
  // console.log("row of copy address",row.CopyTheBankIDAddress);

  // if(!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === ""){
  //   console.log("enter in the copy adddress condition");

    
  //   if (row.AddressLine1 && String(row.AddressLine1).length > 50) {
  //     isValid = false;
  //     reason = "addressLine1 exceeds max length of 50.";
  // } else {
  //     const missingFields = [
  //         row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
  //         row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
  //         row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
  //         row.CityName, row.AddressLine1, row.AddressLine2, row.AddressLine3
  //     ].every(value => !value); // Check if all values are falsy

  //     if (row.Action === "E" || row.Action === "e") {
  //         if (!field) {
  //             isValid = true;
  //             reason = "addressLine1 should be blank for Edit action.";
  //         }
  //         else if (field.length > 50) { 
  //           isValid = false; 
  //           reason = "addressLine1 exceeds max length of 50."; 
  //         }
  //     } else if (row.Action === "A" || row.Action === "a") {
  //         console.log("Address field 1 enter log");

  //         if (missingFields) {
  //             isValid = false;
  //             reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and addressLine1 should not be blank for Add action.";
  //         }
  //     }
  // }


  //   if (field === row.AddressLine2) {
  //     if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //       if (!field) { isValid = true; reason = "AddressLine2 should be blank for Add/Edit action."; }
  //     }
  //     if (String(field).length > 50) { isValid = false; reason = "AddressLine2 exceeds max length of 50."; }
  //   }
  
  //   if (field === row.AddressLine3) {
  //     if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
  //       if (!field) { isValid = true; reason = "AddressLine3 should be blank for Add/Edit action."; }
  //     }
  //     if (String(field).length > 50) { isValid = false; reason = "AddressLine3 exceeds max length of 50."; }
  //   }

  //   }

  //   else{
     
    
  // if (row.AddressLine1 && String(row.AddressLine1).length > 50) {
  //   isValid = false;
  //   reason = "addressLine1 exceeds max length of 50.";
  //   } else {
  //   // field = row.addressLine1 || "";  
    
  //   const missingFields = [
  //     row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
  //     row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
  //     row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
  //     row.CityName,  row.AddressLine1, row.AddressLine2, row.AddressLine3
  //   ].every(value => !value); // Check if all values are falsy (undefined, null, empty, false)
    
  //   if (row.Action === "E" || row.Action === "e") {
  //     if (!field) { 
  //         isValid = true; 
  //         reason = "addressLine1 should be blank for Edit action."; 
  //     } else if (field.length > 50) { 
  //         isValid = false; 
  //         reason = "addressLine1 exceeds max length of 50."; 
  //     }
  //   } else if (row.Action === "A" || row.Action === "a") {
  //     console.log("Address field 1 enter log");
  //     console.log("check door no:", row.DoorNo);
    
  //     if (missingFields) { 
  //         isValid = false; 
  //         reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and Address1 should not be blank for Add action."; 
  //     }
  //   }
  //   }
  //   }





    if(!row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === ""){
      console.log("enter in the copy adddress condition");
      
      
      if (row.AddressLine1 && String(row.AddressLine1).length > 50) {
      isValid = false;
      reason = "AddressLine1 exceeds max length of 50.";
      } else {
      const missingFields = [
          row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
          row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
          row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
          row.CityName, row.AddressLine1, row.AddressLine2, row.AddressLine3,
      ].every(value => !value); // Check if all values are falsy
      
      if (row.Action === "E" || row.Action === "e") {
          if (!field) {
              isValid = true;
              reason = "AddressLine1 should be blank for Edit action.";
          }
          else if (field.length > 50) { 
            isValid = false; 
            reason = "AddressLine1 exceeds max length of 50."; 
          }
      } else if (row.Action === "A" || row.Action === "a") {
          console.log("Address field 1 enter log");
      
          if (missingFields) {
              isValid = false;
              reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and AddressLine1 should not be blank for Add action.";
          }
      }
      }
      
      if (field === row.AddressLine2) {
      if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        if (!field) { isValid = true; reason = "AddressLine2 should be blank for Add/Edit action."; }
      }
      if (String(field).length > 50) { isValid = false; reason = "AddressLine2 exceeds max length of 50."; }
      }
      
      if (field === row.AddressLine3) {
      if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        if (!field) { isValid = true; reason = "AddressLine3 should be blank for Add/Edit action."; }
      }
      if (String(field).length > 50) { isValid = false; reason = "AddressLine3 exceeds max length of 50."; }
      }
      

      if (!row.CountryId || String(row.CountryId).trim() === "") {
        if (row.Action === "A" || row.Action === "a") {
          isValid = false;
          reason = "CountryId should not be empty.";
      }
      } else if (field === row.CountryId) {
        if(countryIDExists){
          isValid = true;
          reason = "country Code is exists";
        }
        else{
          isValid = false;
          reason = "country Code is not exists";
        }
      
          if (row.Action === "E" || row.Action === "e") {
              if (!field.trim()) {
                  isValid = true;
                  reason = "CountryId should be blank for Edit action.";
              } else if (String(field).trim().length > 2) {
                  isValid = false;
                  reason = "CountryId exceeds max length of 2.";
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (!field.trim()) {
                  isValid = false;
                  reason = "CountryId should not be blank for Add action.";
              } else if (String(field).trim().length > 2) {
                  isValid = false;
                  reason = "CountryId exceeds max length of 2.";
              }
          }
      }
  
     if (field === row.StateProvince) {
      if ((row.Action === "A" || row.Action === "a") && (!row.CountryId || String(row.CountryId).trim() === "")) {
        isValid = false;
        reason = "StateProvince should not be blank for Add action.";
    } else 
      if ((row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") && (row.CountryId)) {
        if (!field) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
        else {
          if (!StateIDExists) { 
            isValid = false;
            reason = "State/Province is Not exists."; 
          } 
          if (String(field).length > 3) { isValid = false; reason = "StateProvince exceeds max length of 3."; }
        }
      }
      
    }


      }
else{
  if (row.StateProvince) {
    if ((row.Action === "A" || row.Action === "a") && (!row.CountryId || String(row.CountryId).trim() === "")) {
      isValid = false;
      reason = "StateProvince should not be blank for Add action.";
  } else 
    if ((row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") && (row.CountryId)) {
      if (!row.StateProvince) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
      else {
        if (!StateIDExists) { 
          isValid = false;
          reason = "State/Province is Not exists."; 
        } 
        if (String(row.StateProvince).length > 3) { isValid = false; reason = "StateProvince exceeds max length of 3."; }
      }
    }
    
  }

}
      








  if (!isValid) {
    console.log(`Validation Failed: ${reason}`);
  } else {
    console.log(`Validation Passed: ${field}`);
  }
  
  return isValid;
});

console.log(`Final hasNotError status: ${hasNotError}`);

    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error

      isDuplicateUserId,
      isDuplicateInDB,
      userExists,
      BankIDExists,
      BankIDGreenExists,
      countryIDExists,
      StateIDExists,

      Action: row.Action ? (
        String(row.Action).length === 1 ? (
          ["A", "a", "E", "e"].includes(row.Action) ? (
            row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Only "A", "E" are allowed)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Max. allowed character is 1)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
  
        BankBranch: row.BankBranch ? (
          String(row.BankBranch).length > 10 ? (
            <span style={{ color: "red" }}>
              {row.BankBranch} (Max. allowed character is 10)
            </span>
          ) : row.Action === "A" || row.Action === "a" ? (
            // When Action is "A", show duplicate message if user ID is a duplicate
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span style={{ color: "red" }}>
                {row.BankBranch} (BankBranch Duplicate exists)
              </span>
            ) : (
              String(row.BankBranch) // Otherwise, show BankBranch normally
            )
          ) : row.Action === "E" || row.Action === "e" ? (
            // When Action is "E", show BankBranch without the duplicate message
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span>
                {row.BankBranch} 
              </span>
            ) : (
              // String(row.BankBranch) // Otherwise, show BankBranch normally
              <span style={{ color: "red" }}>
              {row.BankBranch} (BankBranch not exists)
            </span>
            )
          ) : (
            String(row.BankBranch) // Default case, show BankBranch normally
          )
        ) : (
          <span style={{ color: "red" }}>
            Shouldn't be blank
          </span>
        ),

        BankBranchName: row.BankBranchName ? (
          String(row.BankBranchName).length > 150 ? (
            <span style={{ color: "red" }}>
              {row.BankBranchName} (Max. allowed character is 150)
            </span>
          ) : (
            String(row.BankBranchName) // Ensure BankBranchName is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

//         BankId: row.BankId ? (
//           String(row.BankId).length > 30 ? (
//             <span style={{ color: "red" }}>
//               {row.BankId} (Max. allowed character is 30)
//             </span>
//           ) : BankIDExists && displayOption !== '' ? (
//             BankIDGreenExists ?
//             <span>{row.BankId}</span> :
// <span style={{ color: "red" }}>
//     {row.BankId} (Bank Id is Not approved.)
//   </span>
          
//         ) : (
//           row.Action === "A" || row.Action === "a" ? (
//             <span style={{ color: "red" }}>
//               Shouldn't be blank
//             </span>
//           ) : row.Action === "E" || row.Action === "e" ? (
//             <span></span> // Empty span for "E"
//           ) : null // Handle other cases, if any
//         ),


        BankId: row.BankId ? (
          String(row.BankId).length > 30 ? (
            <span style={{ color: "red" }}>
              {row.BankId} (Max. allowed characters: 30)
            </span>
          ) : BankIDExists && displayOption !== "" ? (
            BankIDGreenExists ? (
              <span>{row.BankId}</span>
            ) : (
              <span style={{ color: "red" }}>
                {row.BankId} (Bank ID is not approved.)
              </span>
            )
          ) :(
            <span style={{ color: "red" }}>
            {row.BankId} (Bank ID not exists.)
          </span>
          ) )
          :
          (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),

        
          AddressLine1: !row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === "" ? (
            row.AddressLine1 ? (
            String(row.AddressLine1).length > 50 ? (
              <span style={{ color: "red"}}>
                {row.AddressLine1} (Max. allowed character is 50)
              </span>
            ) : (
                String(row.AddressLine1) // Ensure userId is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
            
            !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.AreaName && !row.Phase && !row.Sector && !row.Village && !row.Town && !row.CityName && !row.AddressLine2 && !row.AddressLine3
            // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
            ? (
              <span style={{ color: "red" }}>
                  Either Address Section or Building, Street, and Area Section must be entered
              </span>
          ) : (
              <span></span>
          )
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          )
        ) : (
          row.AddressLine1 ? (
            String(row.AddressLine1).length > 50 ? (
              <span style={{ color: "red"}}>
                {row.AddressLine1} (Max. allowed character is 50)
              </span>
            ) : (
                String(row.AddressLine1) // Ensure userId is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (                   
                <span></span> // Empty span for "E"
              ) : null // Handle other cases, if any
            )
        ),

        AddressLine2: row.AddressLine2 ? (
          String(row.AddressLine2).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.AddressLine2} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.AddressLine2) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        AddressLine3: row.AddressLine3 ? (
          String(row.AddressLine3).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.AddressLine3} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.AddressLine3) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        CountryId: !row.CopyTheBankIDAddress || String(row.CopyTheBankIDAddress).trim() === "" ? (

        row.CountryId ? (
          String(row.CountryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.CountryId} (Max. allowed character is 2)
            </span>
          )  : countryIDExists && displayOption !== "" ? (
            <span>{row.CountryId}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.CountryId} (Country Code not exists)
            </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        )
      ):(
        row.CountryId ? (
          String(row.CountryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.CountryId} (Max. allowed character is 2)
            </span>
          )  : countryIDExists && displayOption !== "" ? (
            <span>{row.CountryId}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.CountryId} (Country Code not exists)
            </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        )

      ),
      
        StateProvince: row.StateProvince ? (
          String(row.StateProvince).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.StateProvince} (Max. allowed character is 3)
            </span>
          ) : StateIDExists && displayOption !== "" ? (
            <span>{row.StateProvince}</span>
          ):(
            <span style={{ color: "red" }}>
              {row.StateProvince} (StateProvince not exists)
            </span>
          )
        ) : (
          <span>
           
          </span>
        ),
  
      };  
  }));
}

 useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
          
          if(stateCodes.length > 0){
          const processed = await processData(dataToDisplay);
          setProcessedData(processed);
          }

          setLoading(false);
        };
      
        fetchData();
      }, [stateCodes]);
      
  return (
    <div>
      <Header title={`Primary Data > Company & Bank Data > Bank Branch > Upload > Display`}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/2/master-data/11/bankBranch-upload"}
        />

{loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

<AdminActions
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              />

<ReTable
          data={processedData}
          // data={tableData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        //   onRowDoubleClick={handleDoubleClick}
        />

    </div>
  )
}
