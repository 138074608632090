import { useEffect, useState } from "react";
import CustomeLoading from "../../../../../components/CustomeLoading";
import Header from "../../../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import BAMActions from "../../Components/BamActions";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { IoMdCreate, IoMdRefresh } from "react-icons/io";
import { GiSettingsKnobs } from "react-icons/gi";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import ReTable from "../../../../../components/Table";
import { MdEdit, MdOutlineFileDownload } from "react-icons/md";
import BamServices from "../../../../../rest-services/BamServices";
import SearchInput from "../../../../../components/SearchInput";
import { useToast } from "../../../../../components/toast/toast";
export default function AccountClassification() {
    const [loading, setLoading] = useState(false);
    const { iconId } = useParams();
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const { toast } = useToast();
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "BAM") {
            navigate("/bam");
        } else if (crumb.title === "Set Up") {
            navigate("/bam");
        } else if (crumb.title === "Account Classfication") {
            navigate("/3/BAM/135/account-classfication");
        } else if (crumb.path) {
            navigate(crumb.path);
        }
    };
    const [searchQuery, setSearchQuery] = useState("");

    const existingButtons = BamButtonsConfigs.AccountStatus.map((button) => {
        let onClick;
        let title;

        if (button.icon === IoMdRefresh) {
            onClick = () => {
                fetchAccountClassifications(searchQuery);
            };
            title = "Refresh";
        } else if (button.icon === IoAddCircleOutline) {
            onClick = () => navigate("/3/BAM/135/account-classfication-add");
            title = "Add Account";
        } else if (button.icon === IoSettingsOutline) {
            // onClick = handleSettingsButtonClick;
            title = "Table Settings";
        }
        else if (button.icon === MdOutlineFileDownload) {
            onClick = () => handleDownload();
            title = "Download";
        }
        return {
            ...button,
            onClick, // Reference the function without invoking it
            title, // Add the title property to the button configuration
        };
    });

    const handleDownload = async () => {
        const response = await BamServices.exportAccountClassification();
        if (response.status === 200) {
            const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "account_classification.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            toast("success", "Downloaded Successfully");
        } else {
            toast("error", "Failed to download data");
        }
    };


    const extraButtons = [
        {
            icon: IoMdCreate,
            title: "Edit",
            disabled: selectedRows.length === 0,
            onClick: () => {
                navigate("/3/BAM/156/account-classfication-add", {
                    state: { mode: "edit", idview: selectedRows[0] },
                })
            },
        },
    ]
    const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
            ...button,
            disabled: selectedRows.length == 0,
            onClick: selectedRows.length > 0 ? button.onClick : null,
        }))
    );
    const columns = [
        {
            Header: "Account Classification",
            accessor: "account_classification",
            width: ("Account Classification".length - 2) * 10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Description",
            accessor: "description",
            width: ("Description".length - 2) * 10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Notes",
            accessor: "note",
            width: ("Notes".length - 2) * 10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Created by",
            accessor: "created_by",
            width: ("Created by".length - 2) * 10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Created date",
            accessor: "created_date",
            width: ("Created date".length - 2) * 10,
            Cell: ({ value }) => {
                if (!value) return <div style={{ textAlign: "left" }}></div>;
                if (typeof value === "string" && isNaN(Date.parse(value))) {
                    return <div style={{ textAlign: "left" }}>{value}</div>;
                }
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                return <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>;
            },
        },
        {
            Header: "Created time",
            accessor: "created_time",
            width: ("Created time".length - 2) * 10,
            Cell: ({ value }) => {
                if (!value) return ""; // Handle null or undefined values
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
            },

        },
        {
            Header: "Updated by",
            accessor: "changed_by",
            width: ("Updated by".length - 2) * 10,
            Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
        },
        {
            Header: "Updated date",
            accessor: "changed_date",
            width: ("Updated date".length - 2) * 10,
            Cell: ({ value }) => {
                if (!value) return <div style={{ textAlign: "left" }}></div>;
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                return <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>;
            },
        },
        {
            Header: "Updated time",
            accessor: "change_time",
            width: ("Updated time".length - 2) * 10,
            Cell: ({ value }) => {
                if (!value) return ""; // Handle null or undefined values
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
            },
        },
    ];
    const onSelectRow = (rowId) => {
        setSelectedRows((prevSelectedRows) => {
            const isSelected =
                Array.isArray(prevSelectedRows) && prevSelectedRows.includes(rowId);
            const nextSelectedRows = isSelected
                ? prevSelectedRows.filter((id) => id !== rowId)
                : [...(prevSelectedRows || []), rowId];

            //  setShowExtraIcons(nextSelectedRows.length === 1); // Show extra icons only if one row is selected
            return nextSelectedRows;
        });
    };

    const onSelectAll = () => {
        setSelectedRows(
            selectedRows.length === data.length ? [] : data.map((row) => row.id)
        );
    };

    const onRowDoubleClick = (id) => {
        navigate("/3/BAM/156/account-classfication-add", {
            state: { mode: "view", idview: id },
        });
    };

    useEffect(() => {
        fetchAccountClassifications();
    }, [searchQuery]);


    const fetchAccountClassifications = async () => {
        try {
            setLoading(true);
            const response = await BamServices.getAccountClassification(searchQuery);
            if (response.status === 200) {
                setLoading(false);
                setData(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    if (loading)
        return (
            <div>
                <CustomeLoading />
            </div>
        );
    return (
        <div><Header
            title={"BAM > Setups > Account Classfication"}
            onBreadcrumbClick={handleBreadcrumbClick}
            backRoute={"/bam"}
        />
            <div className="flex justify-between items-center -ml-1 pl-4">
                <div className="flex items-center">
                    <SearchInput
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        onChange={(e) => fetchAccountClassifications(searchQuery)}
                        placeholder="Search by Classifications"
                    />
                    <BAMActions
                        icons={iconId}
                        buttons={actionButtons}
                        selectedCount={selectedRows.length}
                        selectIds={selectedRows}

                    />
                </div>
            </div>
            <div className="mt-1">
                <ReTable
                    data={data}
                    columns={columns}
                    onSelectRow={onSelectRow}
                    selectedRows={selectedRows}
                    onSelectAll={onSelectAll}
                    onRowDoubleClick={onRowDoubleClick}
                />
            </div>

        </div>
    )
}