import RestService from "./RestServices";
const basePath = "/file";
const Mt940Service = {
  uploadMt940: (data) => {
    return RestService.UploadData(`${basePath}/upload`, data);
  },
  processSave: (data) => {
    return RestService.CreateData(`${basePath}/save-process`, data);
  },
  updateProcess: (data, subModule, iconId) => {
    return RestService.UpdateData(`${basePath}/update-process-list?subModule=${subModule}&iconId=${iconId}`, data)
  },
  getmt940: (pageSize, page) => {
    return RestService.GetAllData(
      `${basePath}/get-all?limit=${pageSize}&offset=${(page - 1) * pageSize}`
    );
  },
  getmt940s: (pageSize, page) => {
    return RestService.GetAllData(
      `${basePath}/get-all-statements?limit=${pageSize}&offset=${(page - 1) * pageSize
      }`
    );
  },
  getMt940MetaData: (pageSize, offset) => {
    return RestService.GetAllData(
      `${basePath}/get-meta-mt940?limit=${pageSize}&offset=${offset}`
    );
  },
};

export default Mt940Service;
