// utils/dateUtils.js
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = monthNames[d.getMonth()];
  const year = d.getFullYear();
  return `${day} ${month} ${year}`;
};

export const parseDate = (formattedDate) => {
  const [day, month, year] = formattedDate.split(" ");
  const monthIndex = monthNames.indexOf(month);
  return new Date(year, monthIndex, day);
};

const CustomDateInput = ({ value, onChange, name, ...props }) => {
  const handleDateChange = (e) => {
    const formattedDate = formatDate(e.target.value);
    onChange({ target: { name, value: formattedDate } }); // Mimic the native event
  };

  const getISODate = (date) => {
    return date ? parseDate(date).toISOString().split("T")[0] : "";
  };

  return (
    <input
      type="date"
      value={getISODate(value)}
      onChange={handleDateChange}
      name={name}
      {...props}
    />
  );
};

export default CustomDateInput;

// export default DatePickerComponent;
