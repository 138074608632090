import React, { useEffect, useState } from "react";
import Footer from "../../../../../../components/footer";
import BankBranchServices from "../../../../../../rest-services/BankBranchServices";
import BamServices from "../../../../../../rest-services/BamServices";
import CurrencyServicess from "../../../../../../rest-services/CurrencyServices";
import BankAddressContent from "./BankAddressContent";
import { useNavigate } from "react-router-dom";

const BankAccountContent = ({ actions, onNext }) => {
  const isAcNoDisabled = actions === "1";
  const navigate = useNavigate();
  //DropDown Values
  const [branch, setBranch] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [zbaType, setZbaType] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [legalEntity, setLegaleEntity] = useState([]);
  const [company, setCompany] = useState([]);

  //To save the or assing value for Account details form
  const [reqNo, setReqNo] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [branchID, setBranchID] = useState("");
  const [bankID, setBankID] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankIDName, setBankIDName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bank, setBank] = useState("");
  const [currencyid, setCurrencyid] = useState("");
  const [accountId, setAccountId] = useState("");
  const [zbaid, setZbaid] = useState("");
  const [overDraft, setOverDraft] = useState("");
  const [notes, setNotes] = useState("");

  //For an Entity
  const [legalEntityID, setLegalEntityID] = useState("");
  const [legalEntityName, setLegalEntityName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [buGroup, setBuGroup] = useState("");
  const [buGroupName, setBuGroupName] = useState("");
  useEffect(() => {
    fetchBranches();
    fetcAccountType();
    fetchZbaType();
    fetchCurrencies();
    fetchLegalEntity();
  }, []);

  useEffect(() => {
    if (branchID && branch.length > 0) {
      const selectedBranch = branch.find(
        (item) => item.id.toString() === branchID
      );
      if (selectedBranch) {
        setBranchName(selectedBranch.branchName);
        setBankName(selectedBranch.BankName);
        setBank(selectedBranch.Bank);
        setBankID(selectedBranch.BankId);
      }
    }
  }, [branchID, branch]);

  const fetchBranches = async () => {
    try {
      const response = await BankBranchServices.getAllBankBranch(
        "/get-all-bankbranch"
      );
      if (response && response.data) {
        setBranch(response.data);
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const fetcAccountType = async () => {
    try {
      const response = await BamServices.getAccountType();
      if (response && response.data) {
        setAccountType(response.data.data);
      }
    } catch (error) {}
  };

  const fetchZbaType = async () => {
    try {
      const response = await BamServices.getAllZBATypes();
      if (response && response.data) {
        setZbaType(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const generateReqNo = () => {
    const min = 100000;
    const max = 999999;
    const randomNo = Math.floor(Math.random() * (max - min + 1)) + min;
    setReqNo(randomNo.toString());
    if (isAcNoDisabled) {
      setAccountStatus("Requested");
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await BamServices.getAllCurrency();
      if (response && response.data) {
        setCurrency(response.data.data);
      }
    } catch (error) {}
  };

  const fetchLegalEntity = async () => {
    try {
      const response = await BamServices.getAllLegalEntity();
      if (response && response.data) {
        setLegaleEntity(response.data);
      }
    } catch (error) {}
  };

  const handleLegalEntityChange = async (event) => {
    setLegalEntityID(event.target.value);
    const selectedEntity = legalEntity.find(
      (item) => item.id.toString() === event.target.value
    );
    setLegalEntityName(selectedEntity.LGE_Description);
    const reponse = await BamServices.getAssociatedCompany(
      selectedEntity.LGE_Code
    );
    setCompany(reponse.data);
  };
  const handleCompanyChange = async (event) => {
    setCompanyId(event.target.value);
    const selectedCompany = company.find(
      (item) => item.id.toString() === event.target.value
    );
    setCompanyName(selectedCompany.companyShortName);
    setBuGroup(selectedCompany.buGroupCode);
    setBuGroupName(selectedCompany.buGroupName);
  };

  const [localData, setLocalData] = useState([]);

  const handleNextClick = () => {
    onNext(localData); // Pass data back to parent
  };
  return (
    <div className="p-4 mb-40">
      {/** Account Details */}
      <div className="mb-6 mx-10 border border-gray-300 p-5 rounded-lg">
        <h3 className="text-lg font-semibold text-gray-500 mb-4">
          Account details
        </h3>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              New Req No{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={reqNo}
              onClick={generateReqNo}
              readOnly
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Account Status{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={accountStatus}
              readOnly
              placeholder="4"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Branch ID
            </label>
            <span className="ml-4">:</span>
            <select
              onChange={(e) => setBranchID(e.target.value)}
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
            >
              <option value="">Select Branch ID</option>
              {branch.length > 0 ? (
                branch.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.id}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </select>
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Bank ID{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="B name"
              value={bankID}
              readOnly
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Branch Name{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              value={branchName}
              readOnly
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Bank ID Name{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              value={bankName}
              readOnly
              type="text"
              placeholder="B name"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Currency{" "}
            </label>
            <span className="ml-4">:</span>
            <select
              onChange={(e) => setCurrencyid(e.target.value)}
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            >
              <option value="" id="">
                Select Currency
              </option>
              {currency.length > 0 ? (
                currency.map((currency) => (
                  <option
                    key={currency.id}
                    id={currency.id}
                    value={currency.currencyCode}
                  >
                    {currency.currencyCode}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </select>
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Bank{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={bank}
              readOnly
              placeholder="B name"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Account Type{" "}
            </label>
            <span className="ml-4">:</span>
            <select
              onChange={(e) => setAccountId(e.target.value)}
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            >
              <option value="">Select Account Type</option>
              {accountType.length > 0 ? (
                accountType.map((accountType) => (
                  <option key={accountType.id} value={accountType.id}>
                    {accountType.Account_Type}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </select>
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Bank Name{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              value={bankName}
              readOnly
              type="text"
              placeholder="B name"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              ZBA Type{" "}
            </label>
            <span className="ml-4">:</span>
            <select
              onChange={(e) => setZbaid(e.target.value)}
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            >
              <option value="">Select ZBA Type</option>
              {zbaType.length > 0 ? (
                zbaType.map((zbaType) => (
                  <option key={zbaType.id} value={zbaType.id}>
                    {zbaType.ZBA_ID}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </select>
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Over Draft{" "}
            </label>
            <span className="ml-4">:</span>
            <select
              value={overDraft}
              onChange={(e) => setOverDraft(e.target.value)}
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
        </div>

        <div className="flex items-center mt-4">
          <label className="w-52 2xl:w-48 text-sm font-medium text-gray-500">
            Notes
          </label>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 "
          />
        </div>
      </div>
      {/** Account No*/}
      <div className="mb-6 border mx-10 border-gray-300 p-5 rounded-lg">
        <h3 className="text-lg font-semibold text-gray-500 mb-4">Account No</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Bank Account ID
            </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              disabled={isAcNoDisabled}
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Bank Account ID Description
            </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="4"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              disabled={isAcNoDisabled}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Account No
            </label>
            <span className="ml-4">:</span>
            <input
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
              disabled={isAcNoDisabled}
            />
          </div>
        </div>
        <div className="flex items-center mt-4">
          <label className="w-52 2xl:w-48 text-sm font-medium text-gray-500">
            IBAN No
          </label>
          <span className="ml-4">:</span>
          <input
            type="text"
            className="border border-gray-300 p-1.5 rounded 2xl:w-[72%] w-full h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4"
            disabled={isAcNoDisabled}
          />
        </div>
      </div>
      {/* For An Entity */}
      <div className="mb-6 border border-gray-300 p-5 rounded-lg mx-10">
        <h3 className="text-lg font-semibold text-gray-500 mb-4">
          For an Entity
        </h3>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Legal Entity <span className="text-red-500">*</span>
            </label>
            <span className="ml-4">:</span>
            <select
              onChange={(e) => handleLegalEntityChange(e)}
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-9 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            >
              <option value="">Select Legal Entity</option>
              {legalEntity.length > 0 ? (
                legalEntity.map((legalEntity) => (
                  <option key={legalEntity.id} value={legalEntity.id}>
                    {legalEntity.LGE_Code}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </select>
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              LE Name{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              value={legalEntityName}
              type="text"
              placeholder="B name"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Company Code
            </label>
            <span className="ml-4">:</span>
            <select
              onChange={(e) => handleCompanyChange(e)}
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-9 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            >
              <option value="">Select Legal Entity</option>
              {company.length > 0 ? (
                company.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.companyCode}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </select>
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              Company Short Name{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              value={companyName}
              readOnly
              type="text"
              placeholder="4"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              BU Group{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              value={buGroup}
              readOnly
              type="text"
              placeholder="2A"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
          <div className="flex items-center">
            <label className="w-48 text-sm font-medium text-gray-500">
              BU Group Description{" "}
            </label>
            <span className="ml-4">:</span>
            <input
              value={buGroupName}
              readOnly
              type="text"
              placeholder="B name"
              className="border border-gray-300 p-1.5 rounded w-80 h-8 text-gray-500 focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0"
            />
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          handleNextClick();
        }}
        className="bg-customBlue text-sm text-white py-1 px-2 ml-10 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 "
      >
        Next
      </button>
      <Footer>
        <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
          Save
        </button>
        <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
          Save & Continue
        </button>
      </Footer>
    </div>
  );
};

export default BankAccountContent;
