import RestService from "./RestServices";

const LegalEntityServices = {
  getAllLegalEntity: async (path) => {
    try {
      const response = await RestService.GetAllData(path);
      return response;
    } catch (error) {
      console.error('Error fetching LegalEntity data:', error);
      return { error: true, message: "Error fetching LegalEntity data" };
    }
  },
  getLegalEntityById: (id ) => {
    return RestService.GetAllData(`/get-legal-entity/${id}`);
  },
  deleteLegalEntity: (id ) => {
    return RestService.DeleteData('/delete-legal-entity',id);
  },
  addLegalEntity: async (path, data) => {
    try {
      console.log('Sending data:', data);
      const response = await RestService.CreateData(path, data);
      console.log('Response from Server:', response);
      return response;
    } catch (error) {
      console.error('Error adding LegalEntity:', error);
      return { error: true, message: "Error adding LegalEntity" };
    }
  },
  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },
  checkDuplicatelgecode: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching lge code:", error);
      return { error: true, message: "Error fetching lge code" };
    }
  },
  getAlllgewithPage: (pageSize, page, searchQuery = "") => {
    const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";

    return RestService.GetAllData(
      `/getalllgebypage?limit=${pageSize}&offset=${(page - 1) * pageSize}${searchParam}`
    );
  },
  getAudittrail: (path, data) => {
    try {
      const response = RestService.CreateData(path, data);
      return response;
    } catch (error) {
      console.error("Error getting audittrail:", error);
      return { error: true, message: "Error getting audittrail" };
    }
  },
  getFilterLge: async (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data);
      console.log("Response from Server:", response);
      return response;
    } catch (error) {
      console.error("Error getting filter Lge:", error);
      return { error: true, message: "Error getting filter Lge" };
    }
  },
};



export default LegalEntityServices;
