import RestService from "./RestServices";

const basePath = "/statement-to-bs";


const TransferToBsService = {
    transferConditionStament: (data, pageSize, page) => {
        return RestService.CreateData(`${basePath}/transfer-statement?&limit=${pageSize}&offset=${(page - 1) * pageSize}`, data);
    },
    addProcessDetails: (data) => {
        return RestService.CreateData(`${basePath}/add-process-details`, data);
    },
    getProcessedRunData: (data, pageSize, page) => {
        return RestService.CreateData(`${basePath
            }/get-run-details-byID?&limit=${pageSize}&offset=${(page - 1) * pageSize}`, data);
    },
    updateProcessDetails: (data, subModule, iconId) => {
        return RestService.UpdateData(`${basePath}/update-process-list?subModule=${subModule}&iconId=${iconId}`, data);
    },

}

export default TransferToBsService;