import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../../../components/Header';
import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import AdminActions from '../../../../Admin/Components/AdminActions';
import ReTable from '../../../../../../components/Table';
import UserService from '../../../../../../rest-services/UserServices';
import XLSX from "xlsx-js-style";
import CustomeLoading from '../../../../../../components/CustomeLoading';

export default function LegalEntityUploadTableScreen() {
   const location = useLocation();
      const { iconId } = useParams();
      const navigate = useNavigate();
      const { tableData, displayOption, uploadOption  } = location.state || {}; // Retrieve tableData from state
      const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [processedData, setProcessedData] = useState([]);
const [isExistsCurrencyCode, setIsExistsCurrencyCode] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

    console.log("table data received from prop in legalentity is",tableData);
    console.log("displayOption data received from prop in legalentity is",displayOption);
    console.log("uploadOption data received from prop in legalentity is",uploadOption);
   
    useEffect(() => {
      existCurrencyCode();
      fetchAssignee();
    }, []);

    const handleBreadcrumbClick = (crumb) => {
      console.log("Breadcrumb clicked:", crumb.title);
      if (crumb.title === "Company & Bank Data") {
          navigate("/2/master-data");
        } else if (crumb.title === "Legal Entity") {
          navigate("/2/master-data/7/legalentity");
        } else if (crumb.title === "Primary Data") {
          navigate("/2/master-data");
        } else if (crumb.path) {
          navigate(crumb.path);
        }
        else if (crumb.title === 'Upload') {
          // console.log("Navigating to /User");
          navigate('/2/master-data/8/bugroup-upload');
        }
      };
  
        const columns = [   
                  
                  { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1)*10,  alignment: "left" },
                  { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -1)*10, alignment: "left" },
                  {
                                Header: 'Update',
                                accessor: (row) => {
                                  if (row.successfulrecords && uploadOption === '3') {
                                    return 'Yes';
                                  } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                                    return 'No';
                                  } else {
                                    return <span style={{ color: 'red' }}>No</span>;
                                  }
                                },
                                visible: true,
                                width: ("Update".length -1)*10,
                                alignment: 'left',
                              },
                  { Header: 'LGE Code', accessor: 'LGECode', visible: true, width: ("LGE Code".length -1)*10, alignment: "left" },
                  { Header: 'Legal Entity Name', accessor: 'LegalEntityName', visible: true, width: ("Legal Entity Name".length -1)*10, alignment: "left" },
                  { Header: 'CurrencyCode', accessor: 'CurrencyCode', visible: true, width: ("CurrencyCode".length -1)*10, alignment: "left" },
               { Header: 'Door No', accessor: 'DoorNo', visible: true, width: ("Door No".length -1)*10, alignment: "left" },
                       { Header: 'Floor No', accessor: 'FloorNo', visible: true, width: ("Floor No".length -1)*10, alignment: "left" },
                       { Header: 'Building No', accessor: 'BuildingNo', visible: true, width: ("Building No".length -1)*10, alignment: "left" },
                       { Header: 'Building Name', accessor: 'BuildingName', visible: true, width: ("Building Name".length -1)*10, alignment: "left" },
                       { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1)*10, alignment: "left" },
                       { Header: 'Street No', accessor: 'StreetNo', visible: true, width: ("Street No".length -1)*10, alignment: "left" },
                       { Header: 'Street Name', accessor: 'StreetName', visible: true, width: ("Street Name".length -1)*10, alignment: "left" },
                       { Header: 'Road No', accessor: 'RoadNo', visible: true, width: ("Road No".length -1)*10, alignment: "left" },
                       { Header: 'Road Name', accessor: 'RoadName', visible: true, width: ("Road Name".length -1)*10, alignment: "left" },
                       { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1)*10, alignment: "left" },
                       // { Header: 'Area Name', accessor: 'Area Name', visible: true, width: ("".length -1)*10, alignment: "left" },
                       // { Header: 'streetName', accessor: 'streetName', visible: true, width: ("".length -1)*10, alignment: "left" },
                       { Header: 'AreaName', accessor: 'AreaName', visible: true, width: ("AreaName".length -1)*10, alignment: "left" },
                       { Header: 'Phase', accessor: 'Phase', visible: true, width: ("Phase".length -1)*10, alignment: "left" },
                       { Header: 'Sector', accessor: 'Sector', visible: true, width: ("Sector".length -1)*10, alignment: "left" },
                       { Header: 'Village', accessor: 'Village', visible: true, width: ("Village".length -1)*10, alignment: "left" },
                       { Header: 'Town', accessor: 'Town', visible: true, width: ("Town".length -1)*10, alignment: "left" },
                       { Header: 'CityName', accessor: 'CityName', visible: true, width: ("CityName".length -1)*10, alignment: "left" },
                       // { Header: 'landmarkName', accessor: 'landmarkName', visible: true, width: ("".length -1)*10, alignment: "left" },
                       { Header: 'AddressLine1', accessor: 'AddressLine1', visible: true, width: ("AddressLine1".length -1)*10, alignment: "left" },
                       { Header: 'AddressLine2', accessor: 'AddressLine2', visible: true, width: ("AddressLine2".length -1)*10, alignment: "left" },
                       { Header: 'AddressLine3', accessor: 'AddressLine3', visible: true, width: ("AddressLine3".length -1)*10, alignment: "left" },
                       { Header: 'CountryId', accessor: 'CountryId', visible: true, width: ("CountryId".length -1)*10, alignment: "left" },
                       { Header: 'State/Province', accessor: 'StateProvince', visible: true, width: ("State/Province".length -1)*10, alignment: "left" },
                       { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1)*10, alignment: "left" },
                       { Header: 'Postal Code', accessor: 'PostalCode', visible: true, width: ("Postal Code".length -1)*10, alignment: "left" },
                       { Header: 'Zip Code', accessor: 'ZipCode', visible: true, width: ("Zip Code".length -1)*10, alignment: "left" },
                       { Header: 'PO Box', accessor: 'POBox', visible: true, width: ("PO Box".length -1)*10, alignment: "left" },
                      
            ];

            
                   const handleDownload = () => {
                          if (processedData.length === 0) {
                            setAlert({
                              severity: "warning",
                              message: "No data available to export!",
                            });
                            return;
                          }
                        
                          const extractText = (cellValue) => {
                            if (Array.isArray(cellValue)) {
                              return cellValue.join(", "); // Convert array to string
                            } else if (typeof cellValue === "string") {
                              return cellValue;
                            } else if (React.isValidElement(cellValue)) {
                              return cellValue.props.children;
                            }
                            return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                          };
                        
                          const formattedUsers = processedData.map((user) => ({
                            "Action": extractText(user.Action),
                            "SLNo": extractText(user.SLNo),
                            "LGECode": extractText(user.LGECode),
                            "LegalEntityName": extractText(user.LegalEntityName),
                            "CurrencyCode": extractText(user.CurrencyCode),

                            "DoorNo": extractText(user.DoorNo),
                            "FloorNo": extractText(user.FloorNo),
                            "BuildingNo": extractText(user.BuildingNo),
                            "BuildingName": extractText(user.BuildingName),
                            "LandMark": extractText(user.LandMark),
                            "StreetNo": extractText(user.StreetNo),
                            "StreetName": extractText(user.StreetName),
                            "RoadNo": extractText(user.RoadNo),
                            "RoadName": extractText(user.RoadName),
                            "Block": extractText(user.Block),
                            "areaName": extractText(user.areaName),
                            "phase": extractText(user.phase),
                            "sector": extractText(user.sector),
                            "village": extractText(user.village),
                            "town": extractText(user.town),
                            "cityName": extractText(user.cityName),
                            "addressLine1": extractText(user.addressLine1 || ""),
                            "addressLine2": extractText(user.addressLine2 || ""),
                            "addressLine3": extractText(user.addressLine3 || ""),
                            "countryId": extractText(user.countryId || ""),
                            "StateProvince": extractText(user.StateProvince),
                            "District": extractText(user.District),
                            "PostalCode": extractText(user.PostalCode),
                            "ZipCode": extractText(user.ZipCode),
                            "POBox": extractText(user.POBox),
                            }));
                        
                  
                    // Add second and third row
                    const secondRow = {
                      "Action": "R","SLNo": "R", "LGECode": "R","LegalEntityName": "R",  "CurrencyCode": "R", 
                      "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
                      "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "areaName": "", "phase":"","sector": "", "village":"", "town": "", "cityName": "",  "addressLine1": "", "addressLine2": "", "addressLine3": "", 
                      "countryId": "R", "StateProvince": "Conditional", "District": "", "PostalCode": "", "ZipCode": "", "POBox": ""
                    };
                  
                    const thirdRow = {
                       "Action": "1","SLNo": "5", "LGECode": "10","LegalEntityName": "150","CurrencyCode": "3", 
                       "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
                      "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "areaName": "", "phase":"","sector": "", "village":"", "town": "", "cityName": "",  "addressLine1": "50", "addressLine2": "50", "addressLine3": "50", 
                      "countryId": "2", "StateProvince": "3", "District": "", "PostalCode": "", "ZipCode": "","POBox": ""
                    };
                  
                      // Insert second and third row at the beginning of the formatted data
                      const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
                  
                          const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
                        console.log("rowsToDownload data is",rowsToDownload);
                   
                          // Define border style
                    const border = {
                      top: { style: "thin", color: { rgb: "000000" } },
                      right: { style: "thin", color: { rgb: "000000" } },
                      bottom: { style: "thin", color: { rgb: "000000" } },
                      left: { style: "thin", color: { rgb: "000000" } },
                    };
                  
                        // Apply borders to the first three rows
                        rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
                          Object.keys(row).forEach((col, colIdx) => {
                            const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
                            if (worksheet[cellAddress]) {
                              worksheet[cellAddress].s = { border };
                            }
                          });
                        });
                  
                        
                         // Additional cell styling for validation errors
                          Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
                            rowsToDownload.forEach((row, rowIdx) => {
                              const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
                              const cellValue = row[col];
                        
                              if (
                                typeof cellValue === "string" && (
                                  cellValue.includes("Shouldn't be blank") 
                                  // ||
                                  // cellValue.includes("Mismatched Data Type") ||
                                  // cellValue.includes("Max. allowed character")
                                )
                              ) {
                                worksheet[cellAddress] = {
                                  v: cellValue,
                                  s: { font: { color: { rgb: "FF0000" } } },
                                };
                              } else if (Array.isArray(row[col])) {
                                worksheet[cellAddress] = {
                                  v: extractText(row[col]),
                                  s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
                                };
                              }
                              else {
                                worksheet[cellAddress] = { v: cellValue };
                              }
                            });
                          });
                        
                          // AutoFit column width
                          worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
                            const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
                            return { wch: maxLength + 2 }; // Add extra space
                          });
                  
                  
                          const workbook = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
                          XLSX.writeFile(workbook, "Legal_Entity_Upload.xlsx");
                        };

      const existingButtons = AdminActionsConfig.screen7.map((button) => {
        let onClick;
        let title;
    
    
        if (button.icon === MdOutlineFileDownload) {
          onClick = handleDownload;
          title = "Download File";
        }
    
        else {
          onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
          title = button.title || ""; // Use existing title or default to an empty string
        }
    
        return {
          ...button,
          onClick, // Reference the function without invoking it
          title,   // Add the title property to the button configuration
        };
      });
            
        const extraButtons = [

        ];
                
      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, // Disable button if no users are selected
          onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
        }))
      );
            
                      
      const [columnSettings, setColumnSettings] = useState(columns);       
              
      const modifiedColumns = columnSettings
      .filter((col) => col.visible)
      .map((col) => ({
        ...col,
        width: col.width, // This should hold the updated width
      }));

    

      const existCurrencyCode = async () => {
        try { 
          const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-currency-Code`);
          if (response && response.data) {
            console.log("response data for Currency", response.data.data);
            console.log("response data for Currency", response.data.data.map(item => item.currencyCode));
            // setIsExistsCurrencyCode(response.data);
            setIsExistsCurrencyCode(response.data.data.map(item => item.currencyCode)); // Extract currency codes only
          } 
        } catch (error) {
          console.error('Error fetching Language data', error);
        }
      };
  
      
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          userId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner clientId:", assigneeData.clientId);
        console.log("Assigner userId:", assigneeData.userId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

   
  const isRowValid = (row, index) => {
    // Common validations
    const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
    const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
    const isLGECodeValid = row.LGECode && String(row.LGECode).length <= 10;

    const isAddress1Valid = row.AddressLine1 ? String(row.AddressLine1).length <= 50 : true;
    const isAddress2Valid = row.AddressLine2 ? String(row.AddressLine2).length <= 50 : true;
    const isAddress3Valid = row.AddressLine3 ? String(row.AddressLine3).length <= 50 : true;

    let errors = [];

    if (!isActionValid) errors.push("Invalid Action");
    if (!isSLNoValid) errors.push("Invalid SLNo");
    if (!isLGECodeValid) errors.push("Invalid LGECode");
    if (!isAddress1Valid) errors.push("Invalid Address1 exceeds 50 characters");
    if (!isAddress2Valid) errors.push("Invalid Address2 exceeds 50 characters");
    if (!isAddress3Valid) errors.push("Invalid Address3 exceeds 50 characters");
   
     // Check if at least one of DoorNo, BuildingNo, BuildingName, or Address1 is filled
const hasRequiredInfo = !!(row.DoorNo || row.FloorNo || row.BuildingNo || row.BuildingName || 
  row.LandMark || row.StreetNo || row.StreetName || row.RoadNo || row.RoadName || row.Block ||
  row.AreaName || row.Phase || row.Sector || row.Village || row.Town || row.CityName || 
  row.AddressLine1 || row.AddressLine2 || row.AddressLine3 );


    // Action "A" or "a" specific validations
    if (row.Action === "A" || row.Action === "a") {
        const isLegalEnityNameValid = row.LegalEntityName && String(row.LegalEntityName).length <= 150;
        const isCurrencyCodeValid = row.CurrencyCode && String(row.CurrencyCode).length <= 4;
        const isCountryIdValid = row.CountryId && String(row.CountryId).length <= 3;

        if (!isLegalEnityNameValid) errors.push("Invalid LegalEntityName");
        if (!isCurrencyCodeValid) errors.push("Invalid CurrencyCode");
        if (!isCountryIdValid) errors.push("Invalid CountryId");

        if (!hasRequiredInfo) {
          errors.push("At least one of DoorNo, BuildingNo, BuildingName, or Address1 is required");
        }

        if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }

        return (
            isActionValid &&
            isSLNoValid &&
            isLGECodeValid &&
            isLegalEnityNameValid &&
            isCurrencyCodeValid &&
            isCountryIdValid &&
            hasRequiredInfo
        );
    }

    // Action "E" or "e" specific validations
    if (row.Action === "E" || row.Action === "e") {
        const isLegalEnityNameValid = row.LegalEnityName ? String(row.LegalEnityName).length <= 150 : true;
        const isCurrencyCodeValid = row.CurrencyCode ? String(row.CurrencyCode).length <= 3 : true;
        const isCountryIdValid = row.CountryId ? String(row.CountryId).length <= 2 : true;

        if (!isLegalEnityNameValid) errors.push("Invalid LegalEntityName");
        if (!isCurrencyCodeValid) errors.push("Invalid CurrencyCode");
        if (!isCountryIdValid) errors.push("Invalid CountryId");

        if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }

        return (
            isActionValid &&
            isSLNoValid &&
            isLGECodeValid &&
            isLegalEnityNameValid &&
            isCurrencyCodeValid &&
            isCountryIdValid
        );
    }

    if (errors.length > 0) {
        console.log(`Row ${index + 1} is invalid:`, errors);
    }

    return isActionValid && isSLNoValid && isLGECodeValid &&
    isAddress1Valid &&
    isAddress2Valid &&
    isAddress3Valid;
};

const getExistingUserIds = async (tableData) => {
  try {
    console.log("userIds", tableData);

    // Make API call to fetch existing user IDs
    const validRows = tableData.filter((row, index) => isRowValid(row, index));
console.log("valid rows checking in upload:", validRows);


// Currency Code valid data get
const response1 = await UserService.saveContactDetails(
  `${process.env.REACT_APP_BASE_URL}/get-currencyCode-exists-only`,
  { validRows:validRows }
);
console.log("check currency code is exists", response1.data);
const existingCurrencyIds = response1.data; // Extract existing user IDs from API response

const ExistCurrencyIdValidRows = await filterExistingCurrencyCodes(validRows, existingCurrencyIds);

console.log("successful and row valid in currency filters 1", ExistCurrencyIdValidRows);



// Country Code valid data get
const response2 = await UserService.saveContactDetails(
`${process.env.REACT_APP_BASE_URL}/get-CountryID2-exists-only`,
{ validRows:ExistCurrencyIdValidRows }
);
console.log("check Country code is exists", response2.data);
const existingCountryIds = response2.data; // Extract existing user IDs from API response

const ExistCountryIdValidRows = await filterExistingCountryIds(ExistCurrencyIdValidRows, existingCountryIds);

console.log("successful and row valid in countryID filters 1", ExistCountryIdValidRows);



// State Code valid data get
const response3 = await UserService.saveContactDetails(
`${process.env.REACT_APP_BASE_URL}/get-StateID-exists-only`,
{ validRows:ExistCountryIdValidRows }
);
console.log("check state code is exists 1", response3.data);
const existingstateIds = response3.data; 

const ExiststateIdValidRows = await filterExistingstateIds(ExistCountryIdValidRows, existingstateIds);

console.log("successful and row valid in stateID filters 1", ExiststateIdValidRows);


// LE valid data get
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-LE-duplicate/LEId`,
      { validRows:ExistCountryIdValidRows }
    );
    console.log("errored records user id's : Response data", response.data);
    const existingUserIds = response.data; // Extract existing user IDs from API response

    // Remove duplicates based on API response
    const withoutDuplicateUserIds = await filterExistingUserIds(ExistCountryIdValidRows, existingUserIds);

    console.log("successfuland processed Records data:", withoutDuplicateUserIds);

    return withoutDuplicateUserIds;

  } catch (error) {
    console.error("Error fetching existing user IDs:", error);
    return [];
  }
};

const filterExistingUserIds = async (ExistCountryIdValidRows, existingUserIds) => {
  return ExistCountryIdValidRows.filter((row) => existingUserIds.includes(row.LGECode));
};
const filterExistingCurrencyCodes = async (tableData, existingCurrencyIds) => {
  return tableData.filter((row) => existingCurrencyIds.includes(row.CurrencyCode));
};
const filterExistingCountryIds = async (ExistCurrencyIdValidRows, existingCountryIds) => {
  return ExistCurrencyIdValidRows.filter((row) => existingCountryIds.includes(row.CountryId));
};
const filterExistingstateIds =  async (ExistCountryIdValidRows, existingstateIds) => {
  return ExistCountryIdValidRows.filter((row) => existingstateIds.includes(row.StateProvince));
};

      const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
        let dataToDisplay;
        if (displayOption === "1") {
          console.log("Display option is 1");
          const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"
          const existingUserIds = new Set(existingUsers.map(user => user.LGECode));

          // Filter out the currentDataToDisplay row from tableData to get other 6 rows
          dataToDisplay = tableData.filter(user => !existingUserIds.has(user.LGECode));
          
           // Filter out the currentDataToDisplay row from tableData to get other 6 rows
          //  dataToDisplay = tableData.filter(row => row.LGECode !== currentDataToDisplay?.LGECode);
       
           console.log("Filtered remaining data excluding currentDataToDisplay:", dataToDisplay);
      
        } else if (displayOption === "2") {
          console.log("Display option is 2");
          dataToDisplay = tableData; // Display tableData if displayOption is "2"
        } else if (uploadOption === "3") {
          console.log("upload option is 3");
          dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"

          // dataToDisplay = successfulRecords; // Display successfulRecords if uploadOption is "3"
          // dataToDisplay = await fetchAndFilterSuccessfulRecords(tableData); // Successful records for uploadOption "3"
      
        } else {
          dataToDisplay = tableData; // Default to tableData if no conditions are met
        }
      
      // console.log("dataToDisplay data is",dataToDisplay);
      return dataToDisplay;
      };


      // (async () => {
      //   const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
        
      //   if (!Array.isArray(dataToDisplay)) {
      //     console.error("getDataToDisplay did not return an array:", dataToDisplay);
      //     return;
      //   }
      
      //   console.log("Final dataToDisplay data is", dataToDisplay);
      
      //   // Call processData only when dataToDisplay is defined and valid
      //   await processData(dataToDisplay);
      
      // })();

      const isExistingCountryId = async (countryId) =>{
        try {
            console.log("exisitng user id",countryId);
            const data = { CountryCode:countryId };
        
            const response = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-CountryCode-duplicates`,
              data);
            console.log("existingUserId details:", response);
        
            // if (response?.CountryID !== undefined) {
            //   return response.CountryID; // Return the CountryID if found
            // }
            // return false; // Return false if CountryID is null
            return response?.CountryID ? response : false; 
          } catch (error) {
            console.error("Error checking existing userId:", countryId, error);
            return false;
          }
        };

        const isExistingCurrencyId = async (CurrencyCode) =>{
          try {
              console.log("exisitng user id",CurrencyCode);
              const data = { CurrencyCode };
          
              const response = await UserService.saveContactDetails(
                `${process.env.REACT_APP_BASE_URL}/get-CurrencyCode-duplicates`,
                data);
              console.log("existingUserId details:", response);
          
              // if (response?.CountryID !== undefined) {
              //   return response.CountryID; // Return the CountryID if found
              // }
              // return false; // Return false if CountryID is null
              return response?.CurrencyID ? response : false; 
            } catch (error) {
              console.error("Error checking existing userId:", CurrencyCode, error);
              return false;
            }
          };
    
          const isCompareCountryStateId = async (CountryId,StateProvince) => {
          try {

            console.log("exisitng user id",StateProvince);
            const data = { StateCode:StateProvince, CountryCode: CountryId };

            const response = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-Compare-StateCode-CountryCode-duplicates`,
              data);
            console.log("existing state details:", response);
            return response?.StateComparisonID ? response : false;
          } catch (error) {
            console.error("Error checking existing userId:", StateProvince, error);
            return false;
          }
          };
      
        const isExistingStateId = async (StateProvince) =>{
          try {
              console.log("exisitng user id",StateProvince);
              const data = { StateCode:StateProvince };
          
              const response = await UserService.saveContactDetails(
                `${process.env.REACT_APP_BASE_URL}/get-StateCode-duplicates`,
                data);
              console.log("existing state details:", response);
          
              // if (response?.CountryID !== undefined) {
              //   return response.CountryID; // Return the CountryID if found
              // }
              // return false; // Return false if CountryID is null
              return response?.StateID ? response : false; 
            } catch (error) {
              console.error("Error checking existing userId:", StateProvince, error);
              return false;
            }
          };

const isExistingUserId = async (LGECode) => {
  try {
    console.log("exisitng user id",LGECode);
    const data = { LGECode };

    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-LGECode-duplicates`,
      data);

    console.log("existingUserId details:", response);

    // return response && response.LGECode !== null; // Return true if userId exists, false otherwise
    return response?.LGECode ? response : false;
  } catch (error) {
    console.error("Error checking existing userId:", LGECode, error);
    return false;
  }
};


async function checkDuplicateUserIdsFromDB(LGECodes) {
  try {
    const data = { LGECodes };
    console.log("LGECodes duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-LGECodes-duplicates`,
      data
    );

    // console.log("API Response for response details:", response);
    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}

const processData = async (data) => {
  // Extract all userIds from dataToDisplay
  // console.log("processData received  data:", data);

  const LGECodes= data.map(row => row.LGECode).filter(Boolean);
  console.log("Extracted userIds:",LGECodes);

  const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(LGECodes);
   // Preprocess table data to add conditional rendering for userId and username
    return Promise.all(data.map(async (row, _, allRows) => {
    // Define the max allowed characters for each column (this can come from your column definitions)
      // Check for duplicate userId in dataToDisplay
  const isDuplicateUserId = allRows.filter(r => r.LGECode == row.LGECode).length > 1;
console.log("Duplicate user id is ",isDuplicateUserId);

 // Check for duplicate userId in the database
 const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.LGECode);

 const userExists = await isExistingUserId(row.LGECode);
 const currencyIDExists = await isExistingCurrencyId(row.CurrencyCode);
 const countryIDExists = await isExistingCountryId(row.CountryId);
 const StateIDExists = await isExistingStateId(row.StateProvince);
//  const CompareCountryStateExists = await isCompareCountryStateId(row.CountryId,row.StateProvince);

 console.log(`User ${row.LGECode} exists:`, userExists);
 console.log(`Currency ID is ${row.CurrencyCode} exists:`, currencyIDExists);
 console.log(`Country ID is ${row.CountryId} exists:`, countryIDExists);
 console.log(`state ID is ${row.StateProvince} exists:`, StateIDExists);
// console.log(`state Comparison ID is ${row.StateProvince} exists:`, CompareCountryStateExists);
 // Check for errors (red text) and apply highlight based on that

const hasNotError = [
  row.SLNo,
  row.Action,
  row.LGECode,
  row.LegalEnityName,
  row.CurrencyCode,
  row.AddressLine1,
  row.AddressLine2,
  row.AddressLine3,
  row.CountryId,
  row.StateProvince,
  
].every(field => {
  let isValid = true;
  let reason = "";
  
  if (!row.SLNo || String(row.SLNo).trim() === "") {
    if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
      isValid = false;
      reason = "SLNo should not be empty.";
  }
  } else if (row.SLNo) {
  // if (field === row.SLNo) {
    if (!row.SLNo) { isValid = false; reason = "SLNo should not be blank."; }
    else if (isNaN(row.SLNo)) { isValid = false; reason = "SLNo should be a valid number."; }
    else if (String(row.SLNo).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
  }
  
  else if (row.Action) {
    if (!row.Action) { isValid = false; reason = "Action should not be blank."; }
    else if (String(row.Action).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
  }
  
   
  else if (field === row.LGECode) {
    if (!field) { isValid = false; reason = "LGECode ID should not be blank."; }
    else if (String(field).length > 10) { isValid = false; reason = "LGECode ID exceeds max length of 10."; }
    else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
      isValid = false; reason = "LGECode ID is duplicate.";
    }else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
      isValid = false; reason = "LGECode ID does not exist.";
    }
  }

  
  
  if (!row.LegalEntityName || String(row.LegalEntityName).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "LegalEntityName should not be empty.";
  }
  } else if (field === row.LegalEntityName) {
      if (row.Action === "E" || row.Action === "e") {
          if (!field.trim()) {
              isValid = true;
              reason = "LegalEntityName should be blank for Edit action.";
          } else if (String(field).trim().length > 150) {
              isValid = false;
              reason = "LegalEntityName exceeds max length of 150.";
          }
      } else if (row.Action === "A" || row.Action === "a") {
          if (!field.trim()) {
              isValid = false;
              reason = "LegalEntityName should not be blank for Add action.";
          } else if (String(field).trim().length > 150) {
              isValid = false;
              reason = "LegalEntityName exceeds max length of 150.";
          }
      }
  }

// else if (field === row.LegalEntityName) {
// if (row.Action === "E" || row.Action === "e") {
//   if (!field) { isValid = true; reason = "LegalEntityName should be blank for Edit action."; }
//   else if (String(field).length > 150) { isValid = false; reason = "LegalEntityName exceeds max length of 150."; }
// } else if (row.Action === "A" || row.Action === "a") {
//   if (!field) { isValid = false; reason = "LegalEntityName should not be blank for Add action."; }
//   if (String(field).length > 150) { isValid = false; reason = "LegalEntityName exceeds max length of 150."; }
// }
// }


if (!row.CurrencyCode || String(row.CurrencyCode).trim() === "") {
  if (row.Action === "A" || row.Action === "a") {
    isValid = false;
    reason = "CurrencyCode should not be empty.";
}
} else if (field === row.CurrencyCode) {
  if(!currencyIDExists){
    isValid = false;
    reason = "Currency Code is not exists";
  }

    if (row.Action === "E" || row.Action === "e") {
        if (!field.trim()) {
            isValid = true;
            reason = "CurrencyCode should be blank for Edit action.";
        } else if (String(field).trim().length > 3) {
            isValid = false;
            reason = "CurrencyCode exceeds max length of 3.";
        }
    } else if (row.Action === "A" || row.Action === "a") {
        if (!field.trim()) {
            isValid = false;
            reason = "CurrencyCode should not be blank for Add action.";
        } else if (String(field).trim().length > 3) {
            isValid = false;
            reason = "CurrencyCode exceeds max length of 3.";
        }
    }
}

// else if (field === row.CurrencyCode) {
// if (row.Action === "E" || row.Action === "e") {
//   if (!field) { isValid = true; reason = "CurrencyCode should be blank for Edit action."; }
//   else if (String(field).length > 3) { isValid = false; reason = "CurrencyCode exceeds max length of 3."; }
// } else if (row.Action === "A" || row.Action === "a") {
//   if (!field) { isValid = false; reason = "CurrencyCode should not be blank for Add action."; }
//   if (String(field).length > 3) { isValid = false; reason = "CurrencyCode exceeds max length of 3."; }
// }
// }

if (row.AddressLine1 && String(row.AddressLine1).length > 50) {
  isValid = false;
  reason = "addressLine1 exceeds max length of 50.";
} else {
// field = row.addressLine1 || "";  

  const missingFields = [
    row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
    row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
    row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
    row.CityName,  row.AddressLine1, row.AddressLine2, row.AddressLine3
].every(value => !value); // Check if all values are falsy (undefined, null, empty, false)

if (row.Action === "E" || row.Action === "e") {
    if (!field) { 
        isValid = true; 
        reason = "addressLine1 should be blank for Edit action."; 
    } else if (field.length > 50) { 
        isValid = false; 
        reason = "addressLine1 exceeds max length of 50."; 
    }
} else if (row.Action === "A" || row.Action === "a") {
    console.log("Address field 1 enter log");
    console.log("check door no:", row.DoorNo);

    if (missingFields) { 
        isValid = false; 
        reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and Address1 should not be blank for Add action."; 
    }
}
}

  if (row.AddressLine2) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!row.AddressLine2) { isValid = true; reason = "addressLine2 should be blank for Add/Edit action."; }
    }
    if (String(row.AddressLine2).length > 50) { isValid = false; reason = "addressLine2 exceeds max length of 50."; }
  }

  if (row.AddressLine3) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
      if (!row.AddressLine3) { isValid = true; reason = "addressLine3 should be blank for Add/Edit action."; }
    }
    if (String(row.AddressLine3).length > 50) { isValid = false; reason = "addressLine3 exceeds max length of 50."; }
  }

  // else if (field === row.countryId) {
  //   if (row.Action === "E" || row.Action === "e") {
  //     if (!field) { isValid = true; reason = "countryId should be blank for Edit action."; }
  //     else if (String(field).length > 2) { isValid = false; reason = "countryId exceeds max length of 2."; }
  //   } else if (row.Action === "A" || row.Action === "a") {
  //     if (!field) { isValid = false; reason = "countryId should not be blank for Add action."; }
  //     if (String(field).length > 2) { isValid = false; reason = "countryId exceeds max length of 2."; }
  //   }
  // }
  if (!row.CountryId || String(row.CountryId).trim() === "") {
    if (row.Action === "A" || row.Action === "a") {
      isValid = false;
      reason = "CountryId should not be empty.";
  }
  } else if (row.CountryId) {
      if (row.Action === "E" || row.Action === "e") {
          if (!row.CountryId.trim()) {
              isValid = true;
              reason = "CountryId should be blank for Edit action.";
          } else{
            if(!countryIDExists){
              isValid = false;
              reason = "country Code is not exists";
            }

            if (String(row.CountryId).trim().length > 2) {
              isValid = false;
              reason = "CountryId exceeds max length of 2.";
            }
          } 
      } else if (row.Action === "A" || row.Action === "a") {

        if(!countryIDExists){
          isValid = false;
          reason = "country Code is not exists";
        }

          if (!row.CountryId.trim()) {
              isValid = false;
              reason = "CountryId should not be blank for Add action.";
          } else if (String(row.CountryId).trim().length > 2) {
              isValid = false;
              reason = "CountryId exceeds max length of 2.";
          }
      }
  }


   if (row.StateProvince) {
    if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {

      if(!StateIDExists){
        isValid = false;
        reason = "StateProvince is not exists";
      }
      
      if (!row.StateProvince) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
      if (String(row.StateProvince).length > 3) { isValid = false; reason = "StateProvince exceeds max length of 3."; }

    }
  }


  if (!isValid) {
    console.log(`Validation Failed: ${reason}`);
  } else {
    console.log(`Validation Passed: ${field}`);
  }
  
  return isValid;
});

console.log(`Final hasNotError status: ${hasNotError}`);

    return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error

      isDuplicateUserId,
      isDuplicateInDB,
      userExists,
      currencyIDExists,
      countryIDExists,
      StateIDExists,

      Action: row.Action ? (
        String(row.Action).length === 1 ? (
          ["A", "a", "E", "e"].includes(row.Action) ? (
            row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Only "A", "E" are allowed)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Max. allowed character is 1)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
            </span>
          ) : String(row.SLNo).length > 5 ? (
            <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
            </span>
          ) : (
            row.SLNo // Valid SLNo
          )
        ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
        ),
        LGECode: row.LGECode ? (
          String(row.LGECode).length > 10 ? (
            <span style={{ color: "red" }}>
              {row.LGECode} (Max. allowed character is 10)
            </span>
          ) : row.Action === "A" || row.Action === "a" ? (
            // When Action is "A", show duplicate message if user ID is a duplicate
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span style={{ color: "red" }}>
                {row.LGECode} (LGECode Duplicate exists)
              </span>
            ) : (
              String(row.LGECode) // Otherwise, show LGECode normally
            )
          ) : row.Action === "E" || row.Action === "e" ? (
            // When Action is "E", show LGECode without the duplicate message
            (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
            (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
              <span>
                {row.LGECode} 
              </span>
            ) : (
              // String(row.LGECode) // Otherwise, show LGECode normally
              <span style={{ color: "red" }}>
              {row.LGECode} (LGECode not exists)
            </span>
            )
          ) : (
            String(row.LGECode) // Default case, show LGECode normally
          )
        ) : (
          <span style={{ color: "red" }}>
            Shouldn't be blank
          </span>
        ),

        LegalEntityName: row.LegalEntityName ? (
          String(row.LegalEntityName).length > 150 ? (
            <span style={{ color: "red" }}>
              {row.LegalEntityName} (Max. allowed character is 150)
            </span>
          ) : (
            String(row.LegalEntityName) // Ensure LegalEntityName is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

        CurrencyCode: row.CurrencyCode ? (
          String(row.CurrencyCode).length > 3 ? (
            <span style={{ color: "red" }}>
              {row.CurrencyCode} (Max. allowed character is 3)
            </span>
          ) : currencyIDExists && displayOption !== '' ? (
            <span>{row.CurrencyCode}</span>
          ):
          (
            <span style={{ color: "red" }}>
            {row.CurrencyCode} (Currency Code not exists)
          </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),

        AddressLine1: row.AddressLine1 ? (
          String(row.AddressLine1).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.AddressLine1} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.AddressLine1) // Ensure userId is treated as a string
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            
            !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.AreaName && !row.Phase && !row.Sector && !row.Village && !row.Town && !row.CityName && !row.AddressLine3 && !row.AddressLine2
            // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
            ? (
              <span style={{ color: "red" }}>
                  Either Address Section or Building, Street, and Area Section must be entered
              </span>
          ) : (
              <span></span>
          )
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
  
            
        ),
  
        AddressLine2: row.AddressLine2 ? (
          String(row.AddressLine2).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.AddressLine2} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.AddressLine2) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        AddressLine3: row.AddressLine3 ? (
          String(row.AddressLine3).length > 50 ? (
            <span style={{ color: "red"}}>
              {row.AddressLine3} (Max. allowed character is 50)
            </span>
          ) : (
              String(row.AddressLine3) // Ensure userId is treated as a string
          )
        ) : (
          <span>
           
          </span>
        ),
  
        CountryId: row.CountryId ? (
          String(row.CountryId).length > 2 ? (
            <span style={{ color: "red"}}>
              {row.CountryId} (Max. allowed character is 2)
            </span>
          )  : countryIDExists && displayOption !== "" ? (
            <span>{row.CountryId}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.CountryId} (Country Code not exists)
            </span>
          )
        ) : (
          row.Action === "A" || row.Action === "a" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : row.Action === "E" || row.Action === "e" ? (
            <span></span> // Empty span for "E"
          ) : null // Handle other cases, if any
        ),
      

        StateProvince: row.StateProvince ? (
          String(row.StateProvince).length > 3 ? (
            <span style={{ color: "red"}}>
              {row.StateProvince} (Max. allowed character is 3)
            </span>
          ) : StateIDExists && displayOption !== "" ? (
            <span>{row.StateProvince}</span>
          ):(
            <span style={{ color: "red" }}>
              {row.StateProvince} (StateProvince not exists)
            </span>
          )
        ) : (
          <span>
           
          </span>
        ),
  
      };  
  }));
}

      useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
          const processed = await processData(dataToDisplay);
          console.log("processed data is", processed);
          setProcessedData(processed);
          setLoading(false);
        };
      
        fetchData();
      }, []);


  return (
    <div>
      
      <Header
        title={`Primary Data > Company & Bank Data > Legal Entity > Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/2/master-data/7/legalentity-upload"
      />
{loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

<AdminActions
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              />


<ReTable
          data={processedData}
          // data={tableData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        //   onRowDoubleClick={handleDoubleClick}
        />

    </div>
  )
}
