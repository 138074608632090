import React, { useState, useEffect } from "react";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import BamActions from "../../Components/BamActions";
import { useNavigate, useParams } from "react-router-dom";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import FilterComponent from "../../Components/FilterComponent";
import { GiSettingsKnobs } from "react-icons/gi";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import * as XLSX from "xlsx";
import BamServices from "../../../../../rest-services/BamServices";
import { MdEdit } from "react-icons/md";
import { useToast } from "../../../../../components/toast/toast";
import CustomeLoading from "../../../../../components/CustomeLoading";
import { IoMdRefresh } from "react-icons/io";

export default function AccountTypes() {
  const { iconId } = useParams();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({
    clientId: "",
    assigner: "",
    userid: "",
  });

  useEffect(() => {
    const fetchAssignee = async () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            accountType: userDetail.accountType || "",
            assigner: userDetail.username || "",
            userid: userDetail.userId || "",
            clientId: userDetail.clientId || "",
          };
          setAssignee(assigneeData);
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            api: "No user detail found in session storage.",
          }));
        }
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          api: `Error fetching user details: ${error.message}`,
        }));
      }
    };

    fetchAssignee();
  }, []);

  useEffect(() => {
    fetchAccountTypes();
  }, [assignee.clientId]);

  const fetchAccountTypes = async () => {
    try {
      if (!assignee.clientId) {
        return;
      }
      const response = await BamServices.getAccountType(assignee.clientId);

      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleDownload = () => {
    const dataToExport = data.map((item) => ({
      "Account Type": item.Account_Type,
      Description: item.Account_Description,
      Notes: item.notes,
      "Created By": item.created_by,
      "Created Date": item.creationDate || "",
      "Created Time": item.creationTime || "",
      "Updated By": item.changed_by,
      "Updated Date": item.changed_date || "",
      "Updated Time": item.changedTime || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Account Types");

    XLSX.writeFile(workbook, "AccountTypes_Data.xlsx");
  };

  // const buttons = BamButtonsConfigs.AccountStatus.map((button) => ({
  //   ...button,
  //   onClick:
  //     button.icon === IoAddCircleOutline
  //       ? () => handleNavigate("/account-types-add")
  //       : button.icon === GiSettingsKnobs
  //         ? () => setShowFilter((prev) => !prev)
  //         : button.icon === MdOutlineFileDownload
  //           ? handleDownload
  //           : null,
  // }));

  const existingButtons = BamButtonsConfigs.AccountStatus.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add Account Types"
        : button.icon === GiSettingsKnobs
          ? "Filter"
          : button.icon === MdOutlineFileDownload
            ? "Download"
            //  : button.icon === MdOutlineFileUpload
            // ? "Upload"
            : button.icon === IoSettingsOutline
              ? "Settings"
              : button.icon === IoMdRefresh
                ? "Refresh"
                : button.title || "",
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/account-types-add")
        : button.icon === MdOutlineFileDownload
          ? handleDownload
          // : button.icon === IoSettingsOutline
          // ? handleSettingsButtonClick
          // : button.icon === IoMdRefresh
          //   ? fetchZBATypes
          : button.icon === GiSettingsKnobs
            ? () => setShowFilter((prev) => !prev)
            : button.onClick || (() => { }),
  }));

  const extraButtons = [
    {
      icon: MdEdit,
      title: "Edit",
      disabled: selectedRows.length === 0,
      onClick: () => {
        if (selectedRows.length === 1) {
          navigate(`/account-types-add`, {
            state: { mode: "edit", idview: selectedRows[0] },
          });
        }
      },
    },
  ];

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0,
      onClick: selectedRows.length > 0 ? button.onClick : null,
    }))
  );




  const handleFilter = (filters) => {
    console.log("Filtering with:", filters);
  };

  const handleReset = () => {
    console.log("Filters reset");
  };

  const columns = [
    {
      Header: "Account Type",
      accessor: "Account_Type",
      width: ("Account Type".length -2)*10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Description",
      accessor: "Account_Description",
      width: ("Description".length -2)*10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Notes",
      accessor: "notes",
      width: ("Notes".length -2)*10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      width: ("Created by".length -2)*10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Created date",
      accessor: "creationDate",
      width: ("Created date".length -2)*10,
      Cell: ({ value }) => {
        if (!value) return <div style={{ textAlign: "left" }}></div>;
        if (typeof value === "string" && isNaN(Date.parse(value))) {
          return <div style={{ textAlign: "left" }}>{value}</div>;
        }
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Created time",
      accessor: "creationTime",
      width: ("Created time".length -2)*10,
      Cell: ({ value }) => {
        if (!value) return <div style={{ textAlign: "left" }}></div>;
        const timeParts = value.split(":");
        const date = new Date();
        date.setHours(
          timeParts[0],
          timeParts[1],
          timeParts[2].split(".")[0],
          timeParts[2].split(".")[1] || 0
        );

        // Format the time
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });

        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },

    },
    {
      Header: "Updated by",
      accessor: "changed_by",
      width: ("Updated by".length -2)*10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Updated date",
      accessor: "changed_date",
      width: ("Updated date".length -2)*10,
      Cell: ({ value }) => {
        if (!value) return <div style={{ textAlign: "left" }}></div>;
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}>{`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Updated time",
      accessor: "changedTime",
      width: ("Updated time".length -2)*10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
  ];


  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected =
        Array.isArray(prevSelectedRows) && prevSelectedRows.includes(rowId);
      const nextSelectedRows = isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...(prevSelectedRows || []), rowId];

      setShowExtraIcons(nextSelectedRows.length === 1); // Show extra icons only if one row is selected
      return nextSelectedRows;
    });
  };

  const onSelectAll = () => {
    setSelectedRows(
      selectedRows.length === data.length ? [] : data.map((row) => row.id)
    );
  };

  const onRowDoubleClick = (id) => {
    navigate("/account-types-add", {
      state: { mode: "view", idview: id },
    });
  };



  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Set Up") {
      navigate("/bam");
    } else if (crumb.title === "Account Types") {
      navigate("/3/BAM/25/account-types");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  if (loading)
    return (
      <div>
        <CustomeLoading />
      </div>
    );

  return (
    <div>
      <Header
        title={"BAM > Set Up > Account Types"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/bam"}
      />
      <BamActions
        icons={iconId}
        buttons={actionButtons}
        selectedCount={selectedRows.length}
        selectIds={selectedRows}
      // extraButtons={extraButtons}
      // isExtraButtonsVisible={showExtraIcons}
      />
      {showFilter && (
        <FilterComponent onFilter={handleFilter} onReset={handleReset} />
      )}
      <ReTable
        data={data}
        columns={columns}
        onSelectRow={onSelectRow}
        selectedRows={selectedRows}
        onSelectAll={onSelectAll}
        onRowDoubleClick={onRowDoubleClick}
      />
    </div>
  );
}
