import RestService from "./RestServices";

const CountryServices = {
  getCountries: (id) => {
    return RestService.GetByIdData(`/get-country-id`, id);
  },
  deleteCountry:(id)=>{
    return RestService.DeleteData('/delele-country',id);
  },
};

export default CountryServices;
