import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../../../../components/footer';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import UploadComponent from '../../../../../../components/UploadComponent';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import { useToast } from '../../../../../../components/toast/toast';
import * as XLSX from "xlsx";
import UserService from '../../../../../../rest-services/UserServices';

export default function BankUpload() {
const navigate = useNavigate();
    const { iconId } = useParams();
        const { toast } = useToast();
        const [tableData, setTableData] = useState([]);
        const [buttonText, setButtonText] = useState("Upload from desktop");
        const [isUploaded, setIsUploaded] = useState(false);
        const [displayOption, setDisplayOption] = useState(""); // Default value
        const [uploadOption, setUploadOption] = useState(""); // Default value
        const [isDisplayChecked, setIsDisplayChecked] = useState(false); // State for checkbox
        const [isUploadChecked, setIsUploadChecked] = useState(false); // State for checkbox
        const [isError, setIsError] = useState(false);
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [assignee, setAssignee] = useState({ id: "", username: "" });
        const [alert, setAlert] = useState(null);
          const [loading, setLoading] = useState(false);
        
        // Allowed format (Replace with actual validation if needed)
        const allowedFormat = ["Action","SLNo","BankCode","Description"]; 
        
          useEffect(() => {
            fetchAssignee();
          }, []);
              
          const fetchAssignee = async () => {
            try {
              const userDetailString = localStorage.getItem("userDetail");
              if (userDetailString) {
                const userDetail = JSON.parse(userDetailString);
                const assigneeData = {
                  id: userDetail.id || "",
                  clientId: userDetail.clientId || "",
                  assigner: userDetail.username || "",
                  userId: userDetail.userId || "",
        
                };
        
                setAssignee(assigneeData);
              } else {
                setAlert({
                  severity: "error",
                  message: "No user detail found in session storage.",
                });
              }
            } catch (error) {
              setAlert({
                severity: "error",
                message: `Error fetching user details: ${error.message}`,
              });
            }
          };
        
        
            const cancelDeletion = () => {
            setShowConfirmation(false);
            };
        
        const handleFileChange = (e) => {
          const file = e.target.files[0];
          if (!file) return;
        
          const reader = new FileReader();
          reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0]; // Get first sheet
            const sheet = workbook.Sheets[sheetName];
        
            // Ensure we read only headers
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        
            // console.log("Raw Sheet Data:", jsonData); // Debugging: Check the first row
        
            if (jsonData.length === 0) {
              setButtonText("File Format is Incorrect !");
              setIsUploaded(false);
              setIsError(true);
              return;
            }
        
            // Extract headers from the first row
            const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
            const expectedHeaders = allowedFormat.map(header => header.trim().toLowerCase());
        
            // console.log("Uploaded Headers:", uploadedHeaders);
            // console.log("Expected Headers:", expectedHeaders);
        
            // Validate headers
            const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));
        
            if (isValidFormat) {
              // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
              const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);
        
            // Convert Excel date serial numbers to readable dates
            const formattedRows = rows.map((row) => {
              const formattedRow = { ...row };
        
          
              return formattedRow;
            });
          
        
            console.log("set Table Data:", formattedRows);
        
            setTableData(formattedRows);
              setButtonText("File Uploaded");
              setIsUploaded(true);
              setIsError(false);
            } else {
              setButtonText("File Format is Incorrect !");
              setIsUploaded(false);
              setIsError(true);
              setShowConfirmation(true);
            }
        
            setTimeout(() => setIsUploaded(false), 1000);
          };
          reader.readAsArrayBuffer(file);
        };
                  
        
            const confirmDeletion = async () => {
              console.log("Download the excel sheet");
              // Simulate the download by creating an anchor element
              const fileUrl = "/Bank_Upload.xlsx"; 
              console.log("file ur is",fileUrl);
              const anchor = document.createElement("a");
              anchor.href = fileUrl;
              anchor.download = "Bank_Upload.xlsx"; // Set the file name for download
              document.body.appendChild(anchor);
              anchor.click();
              document.body.removeChild(anchor);
            }
    
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {
      console.log("Navigating to /2/master-data");
      navigate('/2/master-data');
    } else if (crumb.title === 'Bank') {
      console.log("Navigating to /country");
      navigate('/2/master-data/5/bank');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };

  
  const isRowValid = (row, index) => {
    // Common validations
    const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
    const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
    
    
    let errors = [];
    
    if (!isActionValid) errors.push("Invalid Action");
    if (!isSLNoValid) errors.push("Invalid SLNo");
  
   
    // Action "A" or "a" specific validations
    if (row.Action === "A" || row.Action === "a") {
        const isBankCodeValid = row.BankCode && String(row.BankCode).length <= 30; 

        const isDescriptionValid = row.Description && String(row.Description).length <= 30;

      if (!isDescriptionValid) errors.push("Invalid Description ");
      if (!isBankCodeValid) errors.push("Invalid BankCode");

      if (errors.length > 0) {
        console.log(`Row ${index + 1} is invalid:`, errors);
    }
      return (
        isDescriptionValid  &&
       
        isBankCodeValid 
      );
    }
  
    // Action "E" or "e" specific validations
    if (row.Action === "E" || row.Action === "e") {
    const isBankCodeValid = row.BankCode && String(row.BankCode).length <= 30; 

        const isDescriptionValid = row.Description ? String(row.Description).length <= 30 : true;

      if (!isDescriptionValid) errors.push("Invalid E Description");
      if (!isBankCodeValid) errors.push("Invalid BankCode");

      if (errors.length > 0) {
        console.log(`Row ${index + 1} is invalid:`, errors);
    }

      return (
        isDescriptionValid &&
       
        isBankCodeValid   );
    }
  
    
    if (errors.length > 0) {
      console.log(`Rows data ${index + 1} is invalid:`, errors);
  }

    // Default case if no matching Action
    return (
      isActionValid &&
      isSLNoValid 
     
    );
  
  };

  const getExistingUserIds = async (tableData) => {
    try {
      // console.log("userIds", tableData);

      const validRows = tableData.filter((row, index) => isRowValid(row, index));
      console.log("valid rows in checking in upload:", validRows);
        

      // Make API call to fetch existing user IDs
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-Banks-duplicate/BankCode`,
        { validRows }
      );
      console.log("errored records user id's : Response data", response.data);
      const existingUserIds = response.data; // Extract existing user IDs from API response

      const withoutDuplicateUserIds = await filterExistingUserIds(validRows, existingUserIds);

      console.log("successful and processed Records data:", withoutDuplicateUserIds);

      return withoutDuplicateUserIds;

    } catch (error) {
      console.error("Error fetching existing user IDs:", error);
      return [];
    }
  };

  const filterExistingUserIds = async (tableData, existingUserIds) => {
    return tableData.filter((row) => existingUserIds.includes(row.BankCode));
  };

  const getDataToDisplay = async ( uploadOption, tableData) => {

    let dataToDisplay;
    if (uploadOption === "3") {
    console.log("displayOption is 3");
    dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
    } else {
    dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
    
  console.log("dataToDisplay data is",dataToDisplay);
  return dataToDisplay;
};


    const isExistingUserId = async (BankCode) => {
        try {
        console.log("exisitng user id",BankCode);
        const data = { BankCode };

        const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-BankCode-duplicates`,
        data);
        console.log("existingUserId details:", response);

        // return response && response.UserID !== null; // Return true if userId exists, false otherwise
        return response?.BankCodeID ? response : false; // Return response only if UserID exists
        } catch (error) {
        console.error("Error checking existing userId:", BankCode, error);
        return false;
        }
    };

async function checkDuplicateUserIdsFromDB(BankCodes) {
    try {
      const data = { BankCodes };
      // console.log("BankCodes duplicated DATA IS",data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-BankCodes-duplicates`,
        data
      );
  
      console.log("existingUserId API Response for duplicates details:", response.duplicates);
      return response.duplicates; // Return an array of duplicate userIds
    } catch (error) {
      console.error("Error checking duplicate userIds from DB:", error);
      return [];
    }
  }

const processData = async (data) => {

    // console.log("processData received  data:", data);
    const BankCodes = data.map(row => row.BankCode).filter(Boolean);
    console.log("Extracted BankCodes:", BankCodes);
  
    const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(BankCodes);
  
     // Preprocess table data to add conditional rendering for userId
      return Promise.all(data.map(async (row, _, allRows) => {
  
    // Check for duplicate same userId is repeated in the data
    const isDuplicateUserId = allRows.filter(r => r.BankCode == row.BankCode).length > 1;
    console.log("Duplicate user id is ",isDuplicateUserId);
  
   // Check for duplicate userId in the database
   const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.BankCode);
  
   const userExists = await isExistingUserId(row.BankCode);
   console.log(`BankCode ${row.BankCode} exists:`, userExists);
   // Check for errors (red text) and apply highlight based on that
  
   const hasNotError = [
    row.SLNo,
    row.Action,
    row.BankCode,
    row.Description
  
  ].every(field => {
    let isValid = true;
    let reason = "";
    
    if (field === row.SLNo) {
      if (!field) { isValid = false; reason = "SLNo should not be blank."; }
      else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
      else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
    }
    
    else if (field === row.Action) {
      if (!field) { isValid = false; reason = "Action should not be blank."; }
      else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
    }
    
if (!row.BankCode || String(row.BankCode).trim() === "") {
  if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
    isValid = false;
    reason = "Bank Code should not be empty.";
}
} else if (field === row.BankCode) {
  if (String(field).length > 30) { 
    isValid = false; reason = "Bank Code exceeds max length of 30."; 
  }
  else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
    isValid = false; reason = "Bank Code does not exist.";
  } 
  else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
    isValid = false; reason = "Bank Code is duplicate.";
  }
}
    
    
    if (!row.Description || String(row.Description).trim() === "") {
      if (row.Action === "A" || row.Action === "a") {
        isValid = false;
        reason = "Description should not be empty.";
    }
    } else if (field === row.Description) {
        if (row.Action === "E" || row.Action === "e") {
            if (!field.trim()) {
                isValid = true;
                reason = "Description should be blank for Edit action.";
            } else if (String(field).trim().length > 30) {
                isValid = false;
                reason = "Description exceeds max length of 30.";
            }
        } else if (row.Action === "A" || row.Action === "a") {
            if (!field.trim()) {
                isValid = false;
                reason = "Description should not be blank for Add action.";
            } else if (String(field).trim().length > 30) {
                isValid = false;
                reason = "Description exceeds max length of 30.";
            }
        }
    }
  
    if (!isValid) {
      console.log(`Validation Failed: ${reason}`);
    } else {
      console.log(`Validation Passed: ${field}`);
    }
    
    return isValid;
  });
  
  console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        isDuplicateUserId,
        isDuplicateInDB,
        userExists,
  
        Action: row.Action ? (
          String(row.Action).length === 1 ? (
            ["A", "a", "E", "e"].includes(row.Action) ? (
              row.Action // Valid Action ("A", "E", "a", "e")
            ) : (
              <span style={{ color: "red" }}>
                {row.Action} (Only "A", "E" are allowed)
              </span>
            )
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Max. allowed character is 1)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>Shouldn't be blank</span>
        ),
  
        SLNo: row.SLNo ? (
            isNaN(row.SLNo) ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Mismatched Data Type)
              </span>
            ) : String(row.SLNo).length > 5 ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Max. allowed character is 5)
              </span>
            ) : (
              row.SLNo // Valid SLNo
            )
          ) : (
            <span style={{ color: "red"}}>Shouldn't be blank</span>
          ),
          
  
          BankCode: row.BankCode ? (
            String(row.BankCode).length > 30 ? (
              <span style={{ color: "red" }}>
                {row.BankCode} (Max. allowed character is 30)
              </span>
            ) : row.Action === "A" || row.Action === "a" ? (
              // When Action is "A", show duplicate message if user ID is a duplicate
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span style={{ color: "red" }}>
                  {row.BankCode} (Bank Duplicate exists)
                </span>
              ) : (
                String(row.BankCode) // Otherwise, show userId normally
              )
            ) : row.Action === "E" || row.Action === "e" ? (
              // When Action is "E", show userId without the duplicate message
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span>
                  {row.BankCode} 
                </span>
              ) : (
                // String(row.BankCode) // Otherwise, show LE normally
                <span style={{ color: "red" }}>
                {row.BankCode} (Bank not exists)
              </span>
              )
            ) : (
              String(row.BankCode) // Default case, show userId normally
            )
          ) : (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ),
  
          
          Description: row.Description ? (
            String(row.Description).length > 30 ? (
              <span style={{ color: "red" }}>
                {row.Description} (Max. allowed character is 30)
              </span>
            ) : (
              String(row.Description) // Ensure Username is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),
      
        };  
    }));
  }

  const storeArrayDataForUsers= async () => {
    setLoading(true);
    try {
      const dataToDisplay = await getDataToDisplay(uploadOption, tableData);
    console.log("store successfulRecords data 2 is", dataToDisplay);

    const processed = await processData(tableData);
    console.log("display datas",processed);

      const extractText = (cellValue) => {
              if (Array.isArray(cellValue)) {
                return cellValue.join(", "); // Convert array to string
              } else if (typeof cellValue === "string") {
                return cellValue;
              } else if (React.isValidElement(cellValue)) {
                return cellValue.props.children;
              }
              return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
            };

            const formattedUsers = processed.map((user) => {

              return {
              "Action": extractText(user.Action),
              "SLNo": extractText(user.SLNo),
              "BankCode": extractText(user.BankCode),
              "Description": extractText(user.Description),
            };
    });
      
        // Insert second and third row at the beginning of the formatted data
        const rowsToDisplay = [ ...formattedUsers];

        console.log("Formatted USERS Data:", formattedUsers);
        console.log("Formatted Data:", rowsToDisplay);

        
        const updatedData = rowsToDisplay.map(user => {

          const isObjectField = ["Action",
"SLNo",
"BankCode",
"Description"
].some(field => typeof user[field] === 'object' || user[field] === "Shouldn't be blank");

          
          return {
            // update: typeof user.UserID === 'object' ? "No" : "Yes",
            update: isObjectField ? "No" : "Yes",
                  ...user,
                };
            });
console.log("updatedData data",updatedData);


      const data = {
        clientId: assignee.clientId,
        dataToDisplay,
        createdBy: assignee.userId,
        allrecords: updatedData,
      }
      console.log("storeArrayDataForUsers dataToDisplay is",data);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/store-array-Bank-data`,
        data
      );
      console.log("Roles Response data", response);


      if (dataToDisplay.length === 0) { 
        // toast("error", "Please upload the correct file OR containing the data.");
        toast("error", "Error fields exist.");
        return;
    }
    else{
      if (response.status === 200) {
        toast("success", "Uploaded Bank successfully");
      }
      else if(response.status === 400){
        toast("error", "Invalid or empty array of Bank");
      }
      else if(response.status === 404){
        toast("error", "Missing required fields");
      }
      else if(response.status === 500){
        toast("error", "Duplicate Bank found.");
        console.log("response duplicate Bank data",response.duplicates)
      }
    }


      
    } catch (error) {
      console.error("Error fetching array Country data", error);
    }
    finally {
          setLoading(false);
        }
  };

  const handleSubmit = async () => {

    if(buttonText === 'Upload from desktop'){
        toast("error", "Please upload a file.");
        return;
    }
    else if (tableData.length === 0) { 
        // toast("error", "Please upload the correct file OR containing the data.");
        toast("error", "Please upload a file containing at least one record.");
        return;
    }

         // Check if either of the checkboxes is selected
    if (!isDisplayChecked && !isUploadChecked) {
        toast("error", "Please check at least one checkbox (Display or Upload).");
        return;
    }


    // console.log("tableData send to the prop",tableData);
    // console.log("displayOption selected", displayOption);
    // console.log("uploadOption selected", uploadOption);

    if(uploadOption !== '' && displayOption === '' ){
      console.log("User arry upload data");
      storeArrayDataForUsers();
    }
    else {
      if(uploadOption != ''){
        storeArrayDataForUsers();
      }
      console.log("exit and navigate to the upload table screen");
      console.log("exit and navigate tableData",tableData);
      console.log("exit and navigate to displayOption",displayOption);
      console.log("exit and navigate to uploadOption",uploadOption);
      navigate(`/2/master-data/5/bank-upload/display-screen`, {
            state: { tableData, displayOption, uploadOption } // Passing tableData via state
        });
    }
  }

  return (
    <div>
       <Header title={`Primary Data > Global Data > Bank > Upload `}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/2/master-data/5/bank"}
        />

{loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

            <UploadComponent
              downloadPath="/Bank_Upload.xlsx"  // Replace with a dynamic file path if needed
              handleFileChange={handleFileChange}  // Function to handle file selection
              buttonText={buttonText}
              isUploaded={isUploaded}
              isError={isError}
              handleSubmit={handleSubmit}
              isDisplayChecked={isDisplayChecked}
              setIsDisplayChecked={setIsDisplayChecked}
              isUploadChecked={isUploadChecked}
              setIsUploadChecked={setIsUploadChecked}
              displayOption={displayOption}
              setDisplayOption={setDisplayOption}
              uploadOption={uploadOption}
              setUploadOption={setUploadOption}
              icons={iconId}
            />
      
              <ConfirmationDialog
                open={showConfirmation}
                title="Upload File"
                message="Do you need to download the latest upload format?"
                onConfirm={confirmDeletion}
                onCancel={cancelDeletion}
              />
              <Footer>
                <button
                  onClick={handleSubmit}
                  className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
                >
                  Run
                </button>
              </Footer>

    </div>
  )
}
