import React, { useEffect, useState } from 'react';

const ColumnSettingsPopup = ({ columns, onClose, onUpdate }) => {
  const [columnSettings, setColumnSettings] = useState(columns);
  const [selectAll, setSelectAll] = useState(false);

  
  // useEffect(() => {
  //   const savedSettings = localStorage.getItem('columnSettings');
  //   if (savedSettings) {
  //     setColumnSettings(JSON.parse(savedSettings));
  //   }
  // }, []);
  const handleCheckboxChange = (accessor) => {
    setColumnSettings((prev) =>
      prev.map((col) =>
        col.accessor === accessor ? { ...col, visible: !col.visible } : col
      )
    );
  };

  const handleColumnWidthChange = (accessor, newWidth) => {
    const widthValue = parseFloat(newWidth) || 0; // Ensure it's a number, including decimals
    setColumnSettings((prev) =>
      prev.map((col) =>
        col.accessor === accessor ? { ...col, width: widthValue } : col
      )
    );
  };

  const handleSave = () => {
    // localStorage.setItem('columnSettings', JSON.stringify(columnSettings));

    onUpdate(columnSettings); // Update parent state with new settings
    onClose();
  };

  const handleReset = () => {
    const defaultSettings = columns.map((col) => ({
      ...col,
      visible: true,
      width: 15, // Set a default width as percentage
    }));
    setColumnSettings(defaultSettings);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setColumnSettings((prev) =>
      prev.map((col) => ({ ...col, visible: !selectAll }))
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-0 w-[400px] h-[500px] flex flex-col rounded-2xl overflow-hidden" style={{ maxHeight: "500px", overflowY: "auto" }}>
        <div className="flex justify-between items-center ">
          <h2 className="text-md font-md text-customBlue ml-4 p-2">Column Settings</h2>
          <button
            onClick={handleReset}
            className="bg-white border border-customBlue text-customBlue px-2  rounded hover:bg-customBlue hover:text-white transition h-6 whitespace-nowrap text-xs mr-6"
          >
            Reset default
          </button>
        </div>

        {/* Header Row */}
        <div className="flex items-center mb-1 font-bold bg-gray-100 p-2 shadow-sm shadow-blue-100">
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
            className="form-checkbox h-4 w-4 text-blue-600 ml-4 border border-blue-200 accent-customBlue"
          />
          <label className="flex-grow text-center text-customBlue font-medium text-sm whitespace-nowrap mr-2">Column Header</label>
          <label className="w-20 text-center text-customBlue font-medium text-sm whitespace-nowrap mr-6">Width (%)</label>
        </div>

        {/* Column Settings */}
        <div className="flex-1 overflow-auto p-4">
        {columnSettings.map((column, index) => (
          <div key={column.accessor} className={`flex items-center mb-1 ${index % 2 === 0 ? 'bg-sky-50' : ''}`}>
            <input
              type="checkbox"
              checked={column.visible}
              onChange={() => handleCheckboxChange(column.accessor)}
              className="form-checkbox h-4 w-4 text-blue-600 p-2 ml-2 accent-customBlue "
            />
            <label className="text-sm flex-grow text-center whitespace-nowrap">
              {column.Header}
            </label>
            <input
              type="text"
              value={column.width}
              onChange={(e) => handleColumnWidthChange(column.accessor, e.target.value)}
              className="ml-2 border border-gray-300 rounded w-20 h-8 px-2 text-sm text-center"
              placeholder="Width"
            />
          </div>
        ))}
</div>
 <div className="flex justify-end p-2 bg-white sticky bottom-0 border-t">
        <hr className="my-4 border-gray-300" />
        <div className="flex justify-end mt-4">
          <p className='mr-48 p-2  whitespace-nowrap text-xs text-gray-500 font-md'></p>
          <button
            onClick={onClose}
            className="mr-2 bg-gray-300 text-black px-2 rounded hover:bg-gray-400 transition h-6"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="bg-customBlue text-white px-2 rounded hover:bg-blue-600 transition h-6"
          >
            Save
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnSettingsPopup;
