import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
  IoMdCreate,
  IoMdRefresh,
  IoMdTime,
  IoMdTrash,
} from "react-icons/io";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import CurrencyServices from "../../../../../rest-services/CurrencyServices";
import { useToast } from "../../../../../components/toast/toast";
import { Checkbox } from "@mui/material";
import { MdOutlineFileDownload } from "react-icons/md";
import * as XLSX from 'xlsx';
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import ReusableTable from "../../Components/Table";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";

export default function CurrencyScreen() {
  const [currencyId, setCurrencyId] = useState(null); // Track selected currency ID for editing
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by ID
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const{toast}=useToast();
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [filteredCurrencies, setFilteredCurrencies] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  // const handleDelete = async (id) => {
  //   const confirmed = window.confirm("Are you sure you want to delete this calendar?");
  //   if (confirmed) {
  //     setLoading(true);
  //     try {
  //       await CurrencyServices.deleteCurrency(id);
  //       toast("success","currency deleted successfully");
  //       // Refetch currencys after deletion
  //       fetchCurrencies();
  //     } catch (error) {
  //       console.error("Error deleting currency:", error);
  //       toast("success","Failed to delete currency");
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);

  const columns = [
    { Header: "Currency Code", accessor: "currencyCode", visible: true, width: 200 },
    { Header: "Currency Name", accessor: "Currency_Name", visible: true, width: 200 },
    { Header: "Created by", accessor: "created_by", visible: true, width: 200 },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      }
    },
    {
      Header: "Created Time",
      accessor: "creationTime", 
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true, 
        });
      }
    },
    { Header: "Updated By", accessor: "changed_by", visible: true, width: 200, canResize: true },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      }
    },
    {
      Header: "Updated Time",
      accessor: "changedTime", 
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true, 
        });
      }
    },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  useEffect(() => {
    fetchCurrencies();
  }, []);
  useEffect(() => {
    // Filter banks based on search query
    const filtered = currencies.filter((currency) =>
      currency.currencyCode.toLowerCase().includes(searchQuery.toLowerCase()) 
    );
    setFilteredCurrencies(filtered);
  }, [searchQuery, currencies]);

  const fetchCurrencies = async () => {
    setLoading(true);
    try {
      const response = await CurrencyServices.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-currency`
      );
      if (response && response.data) {
        setCurrencies(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Currency data", error);
    } finally {
      setLoading(false);
    }
  };
  const modifiedColumns = columnSettings.filter(col => col.visible).map(col => ({
    ...col,
    width: col.width // This should hold the updated width
  }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };
  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const extraButtons = [

    {
      icon: IoMdCreate,
      onClick: () => {
        handleNavigate(`/currency-add/${currencyId}`);
      },
      title:"Edit Currency",
      disabled: selectedRows.length === 0, 
    },
    // { icon: IoMdCopy, onClick: () => console.log("Copy") },
    // { icon: IoMdTime, onClick: () => console.log("Time") },
    // { icon: IoMdTrash, onClick: () => handleDelete(currencyId)},
  ];
  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredCurrencies.map((currency) => ({
      "Currency Code": currency.currencyCode,
      "Currency Name": currency.Currency_Name,
      "Created By": currency.created_by,
      "Created Date": currency.creationDate
        ? new Date(currency.creationDate).toLocaleDateString()
        : "",
      "Created Time": currency.creationTime
        ? new Date(currency.creationTime).toLocaleTimeString()
        : "",
      "Changed By": currency.changed_by,
      "Changed Date": currency.changedDate
        ? new Date(currency.changedDate).toLocaleDateString()
        : "",
      "Changed Time": currency.changedTime
        ? new Date(currency.changedTime).toLocaleTimeString()
        : "",
    }));
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Currencies");
  
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Currencies_Data.xlsx");
  };
  const handleDoubleClick = (id) => {
    navigate(`/currency-view/${id}`, { state: { mode: 'view' } });  };
    const handleReload = async () => {
      setLoading(true);  // Start loading
      try {
        await fetchCurrencies();  // Fetch users
      } catch (error) {
        console.error('Error reloading users:', error);
      } finally {
        setLoading(false);  // Stop loading
      }
    };
const existingButtons = PrimaryActionsConfig.screen1.map((button) => ({
  ...button,
  title:
    button.icon === IoAddCircleOutline
      ? "Add Currency"
      : button.icon === MdOutlineFileDownload
      ? "Download"
      : button.icon === IoSettingsOutline
      ? "Settings"
          : button.icon === IoMdRefresh
      ? "Refresh"
      : button.title || "", // If a title already exists, use it, otherwise empty
  onClick:
    button.icon === IoAddCircleOutline
      ? () => handleNavigate("/currency-add")
      : button.icon === MdOutlineFileDownload 
      ? handleDownload
      : button.icon === IoSettingsOutline
      ? handleSettingsButtonClick
      : button.icon === IoMdRefresh
      ? handleReload
      : button.onClick || (() => {}),
}));
const actionButtons = existingButtons.concat(
  extraButtons.map((button) => ({
    ...button,
    disabled: selectedRows.length === 0, // Disable button if no users are selected
    onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
  }))
);
  const handleNavigate = (path) => {
    navigate(path);
  };

 

  const handleCheckboxChange = (id) => {
    setCurrencyId(id); // Set the selected currency ID for further actions
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not already selected

    setSelectedRows(updatedSelectedRows);
    setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = currencies.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };
  if (loading) return <div></div>;
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'Currency') {
      console.log("Navigating to /country");
      navigate('/currency');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
    <div>
      <Header title={"Primary Data > Global Master Data > Currency"}
      onBreadcrumbClick={handleBreadcrumbClick} />
      <div className="flex justify-between">
      <PrimaryActions
        buttons={actionButtons} 
        selectedCount={selectedRows.length} 
        isExtraButtonsVisible={showExtraIcons}
      />
      
        <div className="flex  items-center ">
        <p className="mr-4 text-customBlue">Currency Code :</p>

        <SearchInput 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            placeholder="Search by Currency" 
          />
</div>
</div>
       <ReTable
          data={filteredCurrencies}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
    
    </div>
  );
}
