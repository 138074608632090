import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import XLSX from "xlsx-js-style";
import { PrimaryActionsConfig } from '../../../Components/ActionButtonConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import UserService from '../../../../../../rest-services/UserServices';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import PrimaryActions from '../../../Components/ActionButton';
import ReTable from '../../../../../../components/Table';

export default function BankTableScreen() {
    const navigate = useNavigate();
const location = useLocation();
  
    const { iconId } = useParams();
      const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
      const { tableData, displayOption, uploadOption, taskMonitor  } = location.state || {}; // Retrieve tableData from state
const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processedData, setProcessedData] = useState([]);

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Global Data') {
          console.log("Navigating to /2/master-data");
          navigate('/2/master-data');
        } else if (crumb.title === 'Bank') {
          console.log("Navigating to /country");
          navigate('/2/master-data/5/bank');
        }
        else if (crumb.title === 'Primary Data') {
          console.log("Navigating to /country");
          navigate('/2/master-data');
    
        } else if (crumb.path) {
          navigate(crumb.path); // Navigate to the path specified in the crumb
        }
      };

      
      const handleDownload = () => {
        if (processedData.length === 0) {
          setAlert({
            severity: "warning",
            message: "No data available to export!",
          });
          return;
        }
      
        const extractText = (cellValue) => {
          if (Array.isArray(cellValue)) {
            return cellValue.join(", "); // Convert array to string
          } else if (typeof cellValue === "string") {
            return cellValue;
          } else if (React.isValidElement(cellValue)) {
            return cellValue.props.children;
          }
          return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
        };
      
const formattedUsers = processedData.map((user) => ({
  "Action": extractText(user.Action),
  "SLNo": extractText(user.SLNo),
  "BankCode": extractText(user.BankCode),
  "Description": extractText(user.Description),
 
}));


// Add second and third row
const secondRow = {
"Action": "R","SLNo": "R", "BankCode": "R","Description": "R"
};

const thirdRow = {
"Action": "1","SLNo": "5", "BankCode": "30","Description": "30"
};


  // Insert second and third row at the beginning of the formatted data
  const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];

      const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
    console.log("rowsToDownload data is",rowsToDownload);

      // Define border style
const border = {
  top: { style: "thin", color: { rgb: "000000" } },
  right: { style: "thin", color: { rgb: "000000" } },
  bottom: { style: "thin", color: { rgb: "000000" } },
  left: { style: "thin", color: { rgb: "000000" } },
};

    // Apply borders to the first three rows
    rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
      Object.keys(row).forEach((col, colIdx) => {
        const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = { border };
        }
      });
    });

    
     // Additional cell styling for validation errors
      Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
        rowsToDownload.forEach((row, rowIdx) => {
          const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
          const cellValue = row[col];
    
          if (
            typeof cellValue === "string" && (
              cellValue.includes("Shouldn't be blank") 
              // ||
              // cellValue.includes("Either Address Section or Building, Street, and Area Section must be entered")
              // cellValue.includes("Max. allowed character")
            )
          ) {
            worksheet[cellAddress] = {
              v: cellValue,
              s: { font: { color: { rgb: "FF0000" } } },
            };
          } else if (Array.isArray(row[col])) {
            worksheet[cellAddress] = {
              v: extractText(row[col]),
              s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
            };
          }
          else {
            worksheet[cellAddress] = { v: cellValue };
          }
        });
      });
    
      // AutoFit column width
      worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
        const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
        return { wch: maxLength + 2 }; // Add extra space
      });


      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
      XLSX.writeFile(workbook, "Users_Uploading_Data.xlsx");
    };

    
    useEffect(() => {
      fetchAssignee();
    }, []);
    
    const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
    
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);
    
        setAssignee(assigneeData);
    
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
    };
      
              const existingButtons = PrimaryActionsConfig.screen6.map((button) => {
                let onClick;
                let title;
            
            
                if (button.icon === MdOutlineFileDownload) {
                  onClick = handleDownload;
                  title = "Download File";
                }
            
                else {
                  onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
                  title = button.title || ""; // Use existing title or default to an empty string
                }
            
                return {
                  ...button,
                  onClick, // Reference the function without invoking it
                  title,   // Add the title property to the button configuration
                };
              });
    
      const extraButtons = [
    
      ];
    
          const actionButtons = existingButtons.concat(
            extraButtons.map((button) => ({
              ...button,
              disabled: selectedRows.length === 0, // Disable button if no users are selected
              onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
            }))
          );
          
          const columns = [   
                  
            { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -2)*10, alignment: "left" },
            { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -2)*10, alignment: "left" },
            // { Header: 'Update', accessor: '', visible: true, width: ("".length -2)*10, alignment: "left" },
            {
                          Header: 'Update',
                          accessor: (row) => {
                            if (row.successfulrecords && uploadOption === '3') {
                              return 'Yes';
                            } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                              return 'No';
                            } else {
                              return <span style={{ color: 'red' }}>No</span>;
                            }
                          },
                          visible: true,
                          width: ("Update".length -2)*10,
                          alignment: 'left',
                        },
            { Header: 'BankCode', accessor: 'BankCode', visible: true, width: ("BankCode".length -2)*10, alignment: "left" },
            { Header: 'Description', accessor: 'Description', visible: true, width: ("Description".length -2)*10, alignment: "left" },

            
                  ];
                                
                    const [columnSettings, setColumnSettings] = useState(columns);
                  
                  const modifiedColumns = columnSettings
                  .filter((col) => col.visible)
                  .map((col) => ({
                    ...col,
                    width: col.width, // This should hold the updated width
                  }));
              
                  const isRowValid = (row, index) => {
                    // Common validations
                    const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
                    const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
                    
                    
                    let errors = [];
                    
                    if (!isActionValid) errors.push("Invalid Action");
                    if (!isSLNoValid) errors.push("Invalid SLNo");
                  
                   
                    // Action "A" or "a" specific validations
                    if (row.Action === "A" || row.Action === "a") {
                        const isBankCodeValid = row.BankCode && String(row.BankCode).length <= 30; 
                
                        const isDescriptionValid = row.Description && String(row.Description).length <= 30;
                
                      if (!isDescriptionValid) errors.push("Invalid Description ");
                      if (!isBankCodeValid) errors.push("Invalid BankCode");
                
                      if (errors.length > 0) {
                        console.log(`Row ${index + 1} is invalid:`, errors);
                    }
                      return (
                        isDescriptionValid  &&
                       
                        isBankCodeValid 
                      );
                    }
                  
                    // Action "E" or "e" specific validations
                    if (row.Action === "E" || row.Action === "e") {
                    const isBankCodeValid = row.BankCode && String(row.BankCode).length <= 30; 
                
                        const isDescriptionValid = row.Description ? String(row.Description).length <= 30 : true;
                
                      if (!isDescriptionValid) errors.push("Invalid E Description");
                      if (!isBankCodeValid) errors.push("Invalid BankCode");
                
                      if (errors.length > 0) {
                        console.log(`Row ${index + 1} is invalid:`, errors);
                    }
                
                      return (
                        isDescriptionValid &&
                       
                        isBankCodeValid   );
                    }
                  
                    
                    if (errors.length > 0) {
                      console.log(`Rows data ${index + 1} is invalid:`, errors);
                  }
                
                    // Default case if no matching Action
                    return (
                      isActionValid &&
                      isSLNoValid 
                     
                    );
                  
                  };


                  const getExistingUserIds = async (tableData) => {
                    try {
                      // console.log("userIds", tableData);
                
                      const validRows = tableData.filter((row, index) => isRowValid(row, index));
                      console.log("valid rows checking in upload:", validRows);
                        
                
                      // Make API call to fetch existing user IDs
                      const response = await UserService.saveContactDetails(
                        `${process.env.REACT_APP_BASE_URL}/get-Banks-duplicate/BankCode`,
                        { validRows }
                      );
                      console.log("errored records user id's : Response data", response.data);
                      const existingUserIds = response.data; // Extract existing user IDs from API response
                
                      const withoutDuplicateUserIds = await filterExistingUserIds(validRows, existingUserIds);
                
                      console.log("successful and processed Records data:", withoutDuplicateUserIds);
                
                      return withoutDuplicateUserIds;
                
                    } catch (error) {
                      console.error("Error fetching existing user IDs:", error);
                      return [];
                    }
                  };


    const filterExistingUserIds = async (tableData, existingUserIds) => {
    return tableData.filter((row) => existingUserIds.includes(row.BankCode));
    };

// Displaying data based on options
const getDataToDisplay = async (displayOption, uploadOption, tableData) => {

// Decide which data to display based on displayOption and uploadOption
let dataToDisplay;
if (displayOption === "1") {
console.log("Display option is 1");
const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"
console.log("existing users is ",existingUsers);

// Extract the current dataToDisplay (which is only one record currently)
const existingUserIds = new Set(existingUsers.map(user => user.BankCode));

// Filter out the currentDataToDisplay row from tableData to get other 6 rows
dataToDisplay = tableData.filter(user => !existingUserIds.has(user.BankCode));

console.log("Filtered remaining data excluding currentDataToDisplay:", dataToDisplay);

} else if (displayOption === "2") {
console.log("Display option is 2");
dataToDisplay = tableData; // Display tableData if displayOption is "2"
} else if (uploadOption === "3") {
console.log("upload option is 3");
dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
} 
else {
dataToDisplay = tableData; // Default to tableData if no conditions are met
}

console.log("dataToDisplay data is",dataToDisplay);
return dataToDisplay;
};


// Example usage
(async () => {
const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);

if (!Array.isArray(dataToDisplay)) {
console.error("getDataToDisplay did not return an array:", dataToDisplay);
return;
}

console.log("Final dataToDisplay data is", dataToDisplay);

// Call processData only when dataToDisplay is defined and valid
await processData(dataToDisplay);

})();


const isExistingUserId = async (BankCode) => {
    try {
    console.log("exisitng user id",BankCode);
    const data = { BankCode };

    const response = await UserService.saveContactDetails(
    `${process.env.REACT_APP_BASE_URL}/get-BankCode-duplicates`,
    data);
    console.log("existingUserId details:", response);

    // return response && response.UserID !== null; // Return true if userId exists, false otherwise
    return response?.BankCodeID ? response : false; // Return response only if UserID exists
    } catch (error) {
    console.error("Error checking existing userId:", BankCode, error);
    return false;
    }
};

async function checkDuplicateUserIdsFromDB(BankCodes) {
    try {
      const data = { BankCodes };
      // console.log("BankCodes duplicated DATA IS",data);
      
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-BankCodes-duplicates`,
        data
      );
  
      console.log("existingUserId API Response for duplicates details:", response.duplicates);
      return response.duplicates; // Return an array of duplicate userIds
    } catch (error) {
      console.error("Error checking duplicate userIds from DB:", error);
      return [];
    }
  }

const processData = async (data) => {

    // console.log("processData received  data:", data);
    const BankCodes = data.map(row => row.BankCode).filter(Boolean);
    console.log("Extracted BankCodes:", BankCodes);
  
    const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(BankCodes);
  
     // Preprocess table data to add conditional rendering for userId
      return Promise.all(data.map(async (row, _, allRows) => {
  
    // Check for duplicate same userId is repeated in the data
    const isDuplicateUserId = allRows.filter(r => r.BankCode == row.BankCode).length > 1;
    console.log("Duplicate user id is ",isDuplicateUserId);
  
   // Check for duplicate userId in the database
   const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.BankCode);
  
   const userExists = await isExistingUserId(row.BankCode);
   console.log(`BankCode ${row.BankCode} exists:`, userExists);
   // Check for errors (red text) and apply highlight based on that
  
   const hasNotError = [
    row.SLNo,
    row.Action,
    row.BankCode,
    row.Description
  
  ].every(field => {
    let isValid = true;
    let reason = "";
    
    if (field === row.SLNo) {
      if (!field) { isValid = false; reason = "SLNo should not be blank."; }
      else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
      else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
    }
    
    else if (field === row.Action) {
      if (!field) { isValid = false; reason = "Action should not be blank."; }
      else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
    }
    
if (!row.BankCode || String(row.BankCode).trim() === "") {
  if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
    isValid = false;
    reason = "Bank Code should not be empty.";
}
} else if (field === row.BankCode) {
  if (String(field).length > 30) { 
    isValid = false; reason = "Bank Code exceeds max length of 30."; 
  }
  else if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
    isValid = false; reason = "Bank Code does not exist.";
  } 
  else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
    isValid = false; reason = "Bank Code is duplicate.";
  }
}
    
    
    if (!row.Description || String(row.Description).trim() === "") {
      if (row.Action === "A" || row.Action === "a") {
        isValid = false;
        reason = "Description should not be empty.";
    }
    } else if (field === row.Description) {
        if (row.Action === "E" || row.Action === "e") {
            if (!field.trim()) {
                isValid = true;
                reason = "Description should be blank for Edit action.";
            } else if (String(field).trim().length > 30) {
                isValid = false;
                reason = "Description exceeds max length of 30.";
            }
        } else if (row.Action === "A" || row.Action === "a") {
            if (!field.trim()) {
                isValid = false;
                reason = "Description should not be blank for Add action.";
            } else if (String(field).trim().length > 30) {
                isValid = false;
                reason = "Description exceeds max length of 30.";
            }
        }
    }
  
    if (!isValid) {
      console.log(`Validation Failed: ${reason}`);
    } else {
      console.log(`Validation Passed: ${field}`);
    }
    
    return isValid;
  });
  
  console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        isDuplicateUserId,
        isDuplicateInDB,
        userExists,
  
        Action: row.Action ? (
          String(row.Action).length === 1 ? (
            ["A", "a", "E", "e"].includes(row.Action) ? (
              row.Action // Valid Action ("A", "E", "a", "e")
            ) : (
              <span style={{ color: "red" }}>
                {row.Action} (Only "A", "E" are allowed)
              </span>
            )
          ) : (
            <span style={{ color: "red" }}>
              {row.Action} (Max. allowed character is 1)
            </span>
          )
        ) : (
          <span style={{ color: "red" }}>Shouldn't be blank</span>
        ),
  
        SLNo: row.SLNo ? (
            isNaN(row.SLNo) ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Mismatched Data Type)
              </span>
            ) : String(row.SLNo).length > 5 ? (
              <span style={{ color: "red"}}>
                {row.SLNo} (Max. allowed character is 5)
              </span>
            ) : (
              row.SLNo // Valid SLNo
            )
          ) : (
            <span style={{ color: "red"}}>Shouldn't be blank</span>
          ),
          
  
          BankCode: row.BankCode ? (
            String(row.BankCode).length > 30 ? (
              <span style={{ color: "red" }}>
                {row.BankCode} (Max. allowed character is 30)
              </span>
            ) : row.Action === "A" || row.Action === "a" ? (
              // When Action is "A", show duplicate message if user ID is a duplicate
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span style={{ color: "red" }}>
                  {row.BankCode} (Bank Duplicate exists)
                </span>
              ) : (
                String(row.BankCode) // Otherwise, show userId normally
              )
            ) : row.Action === "E" || row.Action === "e" ? (
              // When Action is "E", show userId without the duplicate message
              (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
              (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                <span>
                  {row.BankCode} 
                </span>
              ) : (
                // String(row.BankCode) // Otherwise, show LE normally
                <span style={{ color: "red" }}>
                {row.BankCode} (Bank not exists)
              </span>
              )
            ) : (
              String(row.BankCode) // Default case, show userId normally
            )
          ) : (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ),
  
          
          Description: row.Description ? (
            String(row.Description).length > 30 ? (
              <span style={{ color: "red" }}>
                {row.Description} (Max. allowed character is 30)
              </span>
            ) : (
              String(row.Description) // Ensure Username is treated as a string
            )
          ) : (
            row.Action === "A" || row.Action === "a" ? (
              <span style={{ color: "red" }}>
                Shouldn't be blank
              </span>
            ) : row.Action === "E" || row.Action === "e" ? (
              <span></span> // Empty span for "E"
            ) : null // Handle other cases, if any
          ),
      
        };  
    }));
  }

useEffect(() => {
const fetchData = async () => {
setLoading(true);
const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
const processed = await processData(dataToDisplay);
setProcessedData(processed);
setLoading(false);
};

fetchData();
}, []);

  return (
    
    <div>
       <Header title={`Primary Data > Global Data > Bank > Upload > Display`}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"2/master-data/5/bank-upload"}
        />

        
{loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

      <PrimaryActions
          icons={iconId}
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            selectIds={selectedRows}
          />

        <ReTable
          data={processedData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        />


    </div>
  )
}
