import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import * as XLSX from "xlsx";
import { useToast } from '../../../../../../components/toast/toast';
import UserService from '../../../../../../rest-services/UserServices';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import Footer from '../../../../../../components/footer';
import UploadComponent from '../../../../../../components/UploadComponent';
import CustomeLoading from '../../../../../../components/CustomeLoading';
export default function StateProvinceUpload() {
    const navigate = useNavigate();
const { iconId } = useParams();
const { toast } = useToast();
const [tableData, setTableData] = useState([]);
const [buttonText, setButtonText] = useState("Upload from desktop");
const [isUploaded, setIsUploaded] = useState(false);
const [displayOption, setDisplayOption] = useState(""); // Default value
const [uploadOption, setUploadOption] = useState(""); // Default value
const [isDisplayChecked, setIsDisplayChecked] = useState(false); // State for checkbox
const [isUploadChecked, setIsUploadChecked] = useState(false); // State for checkbox
const [isError, setIsError] = useState(false);
const [showConfirmation, setShowConfirmation] = useState(false);
const [assignee, setAssignee] = useState({ id: "", username: "" });
const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [stateCodes, setStateCodes] = useState([]);

// Allowed format (Replace with actual validation if needed)
const allowedFormat = ["Action","SLNo","StateCode","StateName","CountryCode"]; 

  useEffect(() => {
    fetchAssignee();
    fetchCountryCodes();
    fetchStateProvinceCode();

  }, []);
      
  
  const fetchStateProvinceCode = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-stateprovince-code`
      );
      console.log("response. state data",response.data.data);
      setStateCodes(response.data.data);
      // console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
    };

  const fetchCountryCodes = async () => {
    try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-country-code`
      );
      console.log("response.data.data",response.data.data);
      setCountryCodes(response.data.data);
      // console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
  };
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          clientId: userDetail.clientId || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",

        };

        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };


    const cancelDeletion = () => {
    setShowConfirmation(false);
    };

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = (event) => {
    const data = new Uint8Array(event.target.result);
    const workbook = XLSX.read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0]; // Get first sheet
    const sheet = workbook.Sheets[sheetName];

    // Ensure we read only headers
    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    // console.log("Raw Sheet Data:", jsonData); // Debugging: Check the first row

    if (jsonData.length === 0) {
      setButtonText("File Format is Incorrect !");
      setIsUploaded(false);
      setIsError(true);
      return;
    }

    // Extract headers from the first row
    const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
    const expectedHeaders = allowedFormat.map(header => header.trim().toLowerCase());

    // console.log("Uploaded Headers:", uploadedHeaders);
    // console.log("Expected Headers:", expectedHeaders);

    // Validate headers
    const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));

    if (isValidFormat) {
      // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
      const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);

    // Convert Excel date serial numbers to readable dates
    const formattedRows = rows.map((row) => {
      const formattedRow = { ...row };

  
      return formattedRow;
    });
  

    console.log("set Table Data:", formattedRows);

    setTableData(formattedRows);
      setButtonText("File Uploaded");
      setIsUploaded(true);
      setIsError(false);
    } else {
      setButtonText("File Format is Incorrect !");
      setIsUploaded(false);
      setIsError(true);
      setShowConfirmation(true);
    }

    setTimeout(() => setIsUploaded(false), 1000);
  };
  reader.readAsArrayBuffer(file);
};
          

    const confirmDeletion = async () => {
      console.log("Download the excel sheet");
      // Simulate the download by creating an anchor element
      const fileUrl = "/State_Province_Upload.xlsx"; 
      console.log("file ur is",fileUrl);
      const anchor = document.createElement("a");
      anchor.href = fileUrl;
      anchor.download = "State_Province_Upload.xlsx"; // Set the file name for download
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }

    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === "Global Data") {
          navigate("/2/master-data");
        } else if (crumb.title === "State/Province") {
          navigate("/2/master-data/2/state-province");
        } else if (crumb.title === "Primary Data") {
          console.log("Navigating to /country");
          navigate("/2/master-data");
        } else if (crumb.path) {
          navigate(crumb.path); // Navigate to the path specified in the crumb
        }
      };

      
      const isRowValid = (row, index) => {
        // Common validations
        const isActionValid = row.Action && ["A", "a", "E", "e"].includes(row.Action) && String(row.Action).length === 1;
        const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
        
        let errors = [];
        
        if (!isActionValid) errors.push("Invalid Action");
        if (!isSLNoValid) errors.push("Invalid SLNo");
      
        // Action "A" or "a" specific validations
        if (row.Action === "A" || row.Action === "a") {
          const isStateValid = row.StateCode && String(row.StateCode).length <= 3;
          const isCountryCodeValid = row.CountryCode && String(row.CountryCode).length <= 2;
         
          const isStateNameValid = row.StateName && String(row.StateName).length <= 30;

          if (!isStateNameValid) errors.push("Invalid State name");
          if (!isStateValid) errors.push("Invalid A State code");
          if (!isCountryCodeValid) errors.push("Invalid A Country code");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
          return (
            isStateNameValid &&
          isStateValid  &&
          isCountryCodeValid
          );
        }
      
        // Action "E" or "e" specific validations
        if (row.Action === "E" || row.Action === "e") {
          const isStateValid = row.StateCode && String(row.StateCode).length <= 3;

          const isCountryCodeValid = row.CountryCode && String(row.CountryCode).length <= 2;
          const isStateNameValid = row.StateName && String(row.StateName).length <= 30;

          
          if (!isStateValid) errors.push("Invalid A State code");
          if (!isStateNameValid) errors.push("Invalid Country name");
          if (!isCountryCodeValid) errors.push("Invalid E Country code");

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
  
          return (
            isStateValid &&
            isStateNameValid &&
            isCountryCodeValid   );
        }
      
        
        if (errors.length > 0) {
          console.log(`Rows data ${index + 1} is invalid:`, errors);
      }
  
        // Default case if no matching Action
        return (
          isActionValid &&
          isSLNoValid
        );
      
      };

      

      const getExistingUserIds = async (tableData) => {
        try {
          // console.log("userIds", tableData);
    
          const validRows = tableData.filter((row, index) => isRowValid(row, index));
          console.log("valid rows checking in upload:", validRows);
            
    
          
        const response1 = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-countryCode-exists-only`,
            { validRows:validRows }
          );
          // console.log("errored records Country id's : Respons1", response1);
          console.log("check country code is exists", response1.data);
          const existingCountryIds = response1.data; // Extract existing user IDs from API response
         
          const ExistCountryIdValidRows = await filterExistingCountryIds(validRows, existingCountryIds);
    
          console.log("successful and row valid in country filters", ExistCountryIdValidRows);


          // Make API call to fetch existing user IDs
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-States-duplicate/stateCode`,
            { ExistCountryIdValidRows }
          );
          console.log("errored records user id's : Response data", response.data);
          const existingUserIds = response.data; // Extract existing user IDs from API response
    
          const withoutDuplicateUserIds = await filterExistingUserIds(ExistCountryIdValidRows, existingUserIds);
    
          console.log("successful and processed Records data:", withoutDuplicateUserIds);
    
          return withoutDuplicateUserIds;
    
        } catch (error) {
          console.error("Error fetching existing user IDs:", error);
          return [];
        }
      };
    
    
      // const filterExistingUserIds = async (ExistCountryIdValidRows, existingUserIds) => {
      //   return ExistCountryIdValidRows.filter(
      //     (row) =>
      //       existingUserIds.some(
      //         (existing) => existing.state === row.StateCode && existing.country === row.CountryCode
      //       )
      //   );
      // };
      
      const filterExistingUserIds = async (ExistCountryIdValidRows, existingUserIds) => {
        // ✅ Filter only successful records based on existingUserIds
        const filteredRecords = ExistCountryIdValidRows.filter(
          (row) =>
            existingUserIds.some(
              (existing) =>
                existing.state === row.StateCode &&
                existing.country === row.CountryCode
            )
        );

        const uniqueRecords = filteredRecords.filter(
          (row, index, self) =>
            self.findIndex(
              (r) =>
                r.StateCode === row.StateCode &&
                r.CountryCode === row.CountryCode
            ) === index
        );
      
        return uniqueRecords;
      };

      const filterExistingCountryIds = async (tableData, existingCountryIds) => {
        return tableData.filter((row) => existingCountryIds.includes(row.CountryCode));
      };


      const getDataToDisplay = async ( uploadOption, tableData) => {
    
        let dataToDisplay;
        if (uploadOption === "3") {
        console.log("displayOption is 3");
        dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
        } else {
        dataToDisplay = tableData; // Default to tableData if no conditions are met
        }
        
      console.log("dataToDisplay data is",dataToDisplay);
      return dataToDisplay;
    };



async function checkDuplicateUserIdsFromDB(statesProvinces) {
  try {
    const data = { statesProvinces };
    // console.log("countrys duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-StatesProvinces-duplicates`,
      data
    );

    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
}

const processData = async (data) => {
    
  const statesProvinces = data
  .filter(row => row.StateCode && row.CountryCode) // Ensure both values exist
  .map(row => ({ state: row.StateCode, country: row.CountryCode }));

console.log("Extracted statesProvinces:", statesProvinces);

    const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(statesProvinces);
  console.log("Duplicates in state province",duplicateUserIdsFromDB);



     // Preprocess table data to add conditional rendering for userId
      return Promise.all(data.map(async (row, _, allRows) => {
  


    // Check for duplicate same userId is repeated in the data
    const isDuplicateUserId  = allRows.filter(
      r => r.StateCode === row.StateCode && r.CountryCode === row.CountryCode
    ).length > 1;
    console.log("Duplicate user id is ",isDuplicateUserId);


   // Check for duplicate userId in the database
   const isDuplicateInDB = Array.isArray(duplicateUserIdsFromDB) && duplicateUserIdsFromDB.some(
      (entry) => entry.StateCode === row.StateCode && entry.CountryCode === row.CountryCode
    );
  // const isDuplicateInDB = Array.isArray(stateAndCountryCodes) &&
  // stateAndCountryCodes.some(
  //   (entry) =>
  //     entry.state === row.StateCode && 
  //     entry.Country_Code === row.CountryCode
  // );

console.log("Duplicate State Province id is ", isDuplicateInDB);


      //  const userExists = await isExistingUserId(row.StateCode);
  const userExists = stateCodes.some((entry) =>
    entry.state === row.StateCode &&
  entry.CountryCode === row.CountryCode
  )

   const countryIDExists = countryCodes
   .map(code => String(code).trim().toUpperCase()) // Normalize countryCodes
   .includes(String(row.CountryCode).trim().toUpperCase()); // Normalize row.CountryCode

   console.log(`User Exists for State: ${row.StateCode}, Country: ${row.CountryCode} → ${userExists}`);

   console.log(`User ${row.StateCode} exists:`, userExists);
   console.log(`Country ID is ${row.CountryCode} exists:`, countryIDExists);
   // Check for errors (red text) and apply highlight based on that
  
   const hasNotError = [
    row.SLNo,
    row.Action,
    row.StateCode,
    row.CountryCode,
    row.CountryName,
  
  ].every(field => {
    let isValid = true;
    let reason = "";
    
    if (field === row.SLNo) {
      if (!field) { isValid = false; reason = "SLNo should not be blank."; }
      else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
      else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
    }
    
    else if (field === row.Action) {
      if (!field) { isValid = false; reason = "Action should not be blank."; }
      else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
    }
    
    if (!row.StateCode) {
      isValid = false;
      reason = "State Code should not be empty.";
    } else if (String(row.StateCode).length > 3) {
      isValid = false;
      reason = "State Code exceeds max length of 3.";
    } else if ((row.Action === "E" || row.Action === "e") && !userExists) {
      isValid = false;
      reason = "State Code does not exist.";
    } else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
      isValid = false;
      reason = "State Code is duplicate.";
    }
    
    
    if (!row.StateName || String(row.StateName).trim() === "") {
      if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
        isValid = false;
        reason = "StateName should not be empty.";
    }
    } else if (field === row.StateName) {
          if (String(field).trim().length > 30) {
                isValid = false;
                reason = "StateName exceeds max length of 30.";
            }
    }
  
  
    if (!row.CountryCode || String(row.CountryCode).trim() === "") {
      if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
        isValid = false;
        reason = "CountryCode should not be empty.";
      }
  } else if (field === row.CountryCode) {
      if (countryIDExists) {
              isValid = true;
              reason = "Country code is exists."; 
      } else {
          isValid = false;
          reason = "Country code is Not exists."; 
      }
      
      // if (row.Action === "E" || row.Action === "e") {
      //     if (!field.trim()) {
      //         isValid = true;
      //         reason = "CountryCode should be blank for Edit action.";
      //     } else if (String(field).trim().length > 2) {
      //         isValid = false;
      //         reason = "CountryCode exceeds max length of 2.";
      //     }
      // } else 
      // if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
          // if (!field.trim()) {
          //     isValid = false;
          //     reason = "CountryCode should not be blank for Add/Edit action.";
          // } else 
          if (String(field).trim().length > 2) {
              isValid = false;
              reason = "CountryCode exceeds max length of 2.";
          }
      // }
  }
  
  
  
  
    if (!isValid) {
      console.log(`Validation Failed: ${reason}`);
    } else {
      console.log(`Validation Passed: ${field}`);
    }
    
    return isValid;
  });
  
  console.log(`Final hasNotError status: ${hasNotError}`);
  
  return {
    ...row,
    successfulrecords: hasNotError, // Mark as successful if no error
    isDuplicateUserId,
    isDuplicateInDB,
    userExists,
    countryIDExists,

    Action: row.Action ? (
      String(row.Action).length === 1 ? (
        ["A", "a", "E", "e"].includes(row.Action) ? (
          row.Action // Valid Action ("A", "E", "a", "e")
        ) : (
          <span style={{ color: "red" }}>
            {row.Action} (Only "A", "E" are allowed)
          </span>
        )
      ) : (
        <span style={{ color: "red" }}>
          {row.Action} (Max. allowed character is 1)
        </span>
      )
    ) : (
      <span style={{ color: "red" }}>Shouldn't be blank</span>
    ),

    SLNo: row.SLNo ? (
        isNaN(row.SLNo) ? (
          <span style={{ color: "red"}}>
            {row.SLNo} (Mismatched Data Type)
          </span>
        ) : String(row.SLNo).length > 5 ? (
          <span style={{ color: "red"}}>
            {row.SLNo} (Max. allowed character is 5)
          </span>
        ) : (
          row.SLNo // Valid SLNo
        )
      ) : (
        <span style={{ color: "red"}}>Shouldn't be blank</span>
      ),
      

      // StateCode: row.StateCode ? (
      //   String(row.StateCode).length > 3 ? (
      //     <span style={{ color: "red" }}>
      //       {row.StateCode} (Max. allowed character is 3)
      //     </span>
      //   ) : row.Action === "A" || row.Action === "a" ? (
      //     // When Action is "A", show duplicate message if user ID is a duplicate
      //     (uploadOption !== '' && displayOption !== '' && (isDuplicateInDB || isDuplicateUserId)) || 
      //     (displayOption !== '' && (isDuplicateInDB || isDuplicateUserId)) ? (
      //       <span style={{ color: "red" }}>
      //         {row.StateCode} (State Code Duplicate exists)
      //       </span>
      //     ) : (
      //       String(row.StateCode) // Otherwise, show userId normally
      //     )
      //   ) : row.Action === "E" || row.Action === "e" ? (
      //     // When Action is "E", show userId without the duplicate message
      //     (uploadOption !== '' && displayOption !== '' && (isDuplicateInDB || isDuplicateUserId)) || 
      //     (displayOption !== '' && (isDuplicateInDB || isDuplicateUserId)) ? (
      //       <span>
      //         {row.StateCode} 
      //       </span>
      //     ) : (
      //       // String(row.StateCode) // Otherwise, show StateCode normally
      //       <span style={{ color: "red" }}>
      //       {row.StateCode} (State Code not exists)
      //     </span>
      //     )
      //   ) : (
      //     String(row.StateCode) // Default case, show userId normally
      //   )
      // ) : (
      //   <span style={{ color: "red" }}>
      //     Shouldn't be blank
      //   </span>
      // ),
      
  
      StateCode: row.StateCode ? (
        String(row.StateCode).length > 3 ? (
          <span style={{ color: "red" }}>
            {row.StateCode} (Max. allowed character is 3)
          </span>
        ) : isDuplicateUserId || isDuplicateInDB ? (
          <span style={{ color: "red" }}>
            {row.StateCode} (State Code Duplicate exists)
          </span>
        ) : (
          row.StateCode
        )
      ) : (
        <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),

      StateName: row.StateName ? (
        String(row.StateName).length > 30 ? (
          <span style={{ color: "red" }}>
            {row.StateName} (Max. allowed character is 30)
          </span>
        ) : (
          String(row.StateName) // Ensure Username is treated as a string
        )
      ) : (
        row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (
          <span style={{ color: "red" }}>
            Shouldn't be blank
          </span>
        ) : null // Handle other cases, if any
      ),


      
      CountryCode: row.CountryCode ? (
        String(row.CountryCode).length > 2 ? (
          <span style={{ color: "red" }}>
            {row.CountryCode} (Max. allowed character is 2)
          </span>
        ) : countryIDExists && displayOption !== "" ? (
            <span>{row.CountryCode}</span>
          ) : (
            <span style={{ color: "red" }}>
              {row.CountryCode} (Country Code not exists)
            </span>
          )
      ) : (
        row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e" ? (
            <span style={{ color: "red" }}>
              Shouldn't be blank
            </span>
          ) : null
      ),
        

      

  
    };   
    }));
  }

      const storeArrayDataForUsers= async () => {
        setLoading(true);
        try {
          const dataToDisplay = await getDataToDisplay(uploadOption, tableData);
        console.log("store successfulRecords data 2 is", dataToDisplay);

        let processed=[];

          if(countryCodes && stateCodes){
            processed = await processData(tableData);
            console.log("display datas",processed);
          }
    
          const extractText = (cellValue) => {
                  if (Array.isArray(cellValue)) {
                    return cellValue.join(", "); // Convert array to string
                  } else if (typeof cellValue === "string") {
                    return cellValue;
                  } else if (React.isValidElement(cellValue)) {
                    return cellValue.props.children;
                  }
                  return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                };
    
                const formattedUsers = processed.map((user) => {
    
                  return {
                  "Action": extractText(user.Action),
                  "SLNo": extractText(user.SLNo),
                  "StateCode": extractText(user.StateCode),
                  "StateName": extractText(user.StateName),
                  "CountryCode": extractText(user.CountryCode),
                  
                };
        });
          
            // Insert second and third row at the beginning of the formatted data
            const rowsToDisplay = [ ...formattedUsers];
    
            console.log("Formatted USERS Data:", formattedUsers);
            console.log("Formatted Data:", rowsToDisplay);
    
            
            const updatedData = rowsToDisplay.map(user => {
    
              const isObjectField = ["Action",
    "SLNo",
    "StateCode",
    "StateName",
    "CountryCode"
    ].some(field => typeof user[field] === 'object' || user[field] === "Shouldn't be blank");
    
              
              return {
                // update: typeof user.UserID === 'object' ? "No" : "Yes",
                update: isObjectField ? "No" : "Yes",
                      ...user,
                    };
                });
    console.log("updatedData data",updatedData);
    
    
          const data = {
            clientId: assignee.clientId,
            dataToDisplay,
            createdBy: assignee.userId,
            allrecords: updatedData,
          }
          console.log("storeArrayDataForUsers dataToDisplay is",data);
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/store-array-state-data`,
            data
          );
          console.log("Roles Response data", response);
    
    
          if (dataToDisplay.length === 0) { 
            // toast("error", "Please upload the correct file OR containing the data.");
            toast("error", "Error fields exist.");
            return;
        }
        else{
          if (response.status === 200) {
            toast("success", "Uploaded State successfully");
          }
          else if(response.status === 400){
            toast("error", "Invalid or empty array of State");
          }
          else if(response.status === 404){
            toast("error", "Missing required fields");
          }
          else if(response.status === 500){
            toast("error", "Duplicate State IDs found.");
            console.log("response duplicate State id data",response.duplicates)
          }
        }
    
    
          
        } catch (error) {
          console.error("Error fetching array State data", error);
        }
        finally {
              setLoading(false);
            }
      };

      const handleSubmit = async () => {

        if(buttonText === 'Upload from desktop'){
            toast("error", "Please upload a file.");
            return;
        }
        else if (tableData.length === 0) { 
            // toast("error", "Please upload the correct file OR containing the data.");
            toast("error", "Please upload a file containing at least one record.");
            return;
        }
    
             // Check if either of the checkboxes is selected
        if (!isDisplayChecked && !isUploadChecked) {
            toast("error", "Please check at least one checkbox (Display or Upload).");
            return;
        }
    
    
        // console.log("tableData send to the prop",tableData);
        // console.log("displayOption selected", displayOption);
        // console.log("uploadOption selected", uploadOption);
    
        if(uploadOption !== '' && displayOption === '' ){
          console.log("User arry upload data");
          storeArrayDataForUsers();
        }
        else {
          if(uploadOption != ''){
            storeArrayDataForUsers();
          }
          console.log("exit and navigate to the upload table screen");
          console.log("exit and navigate tableData",tableData);
          console.log("exit and navigate to displayOption",displayOption);
          console.log("exit and navigate to uploadOption",uploadOption);
          navigate(`/2/master-data/2/stateProvince-upload/display-screen`, {
                state: { tableData, displayOption, uploadOption } // Passing tableData via state
            });
        }
      }

  return (
    <div>
       <Header
        title={`Primary Data > Global Data > State/Province > Upload`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/2/master-data/2/state-province"
      />
     {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}
      
            <UploadComponent
              downloadPath="/State_Province_Upload.xlsx"  // Replace with a dynamic file path if needed
              handleFileChange={handleFileChange}  // Function to handle file selection
              buttonText={buttonText}
              isUploaded={isUploaded}
              isError={isError}
              handleSubmit={handleSubmit}
              isDisplayChecked={isDisplayChecked}
              setIsDisplayChecked={setIsDisplayChecked}
              isUploadChecked={isUploadChecked}
              setIsUploadChecked={setIsUploadChecked}
              displayOption={displayOption}
              setDisplayOption={setDisplayOption}
              uploadOption={uploadOption}
              setUploadOption={setUploadOption}
              icons={iconId}
            />
      
              <ConfirmationDialog
                open={showConfirmation}
                title="Upload File"
                message="Do you need to download the latest upload format?"
                onConfirm={confirmDeletion}
                onCancel={cancelDeletion}
              />
              <Footer>
                <button
                  onClick={handleSubmit}
                  className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
                >
                  Run
                </button>
              </Footer>
      
            </div>
        )
      }
