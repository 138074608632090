import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import BamActions from "../../Components/BamActions";
import ReTable from "../../../../../components/Table";
import { Checkbox } from "@mui/material";
import Header from "../../../../../components/Header";
import SetUpServices from "../../../../../rest-services/SetUpServices";
import Footer from "../../../../../components/footer";
import { useToast } from "../../../../../components/toast/toast";
import { IoAddCircleOutline } from "react-icons/io5";
import CustomButton from "../../../../../components/CustomButton";
import * as XLSX from "xlsx";
import { MdOutlineFileDownload } from "react-icons/md";
import UserService from "../../../../../rest-services/UserServices";
export default function AccountStatus() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { iconId } = useParams();
  const [hasPermissionForView, SethasPermissionForView] = useState("");
  const [hasPermissionForSave, SethasPermissionForSave] = useState("");
  const handleDownload = () => {
    const dataToExport = tableData.map((item) => ({
      Status: item.status || "",
      "Receive MT940": item.receiveMt940 === 1 ? "Yes" : "No", // Checkboxes as "Yes"/"No"
      FTOUT: item.ftOut === 1 ? "Yes" : "No",
      FTIN: item.ftIn === 1 ? "Yes" : "No",
      "Cash Accounting": item.cashAccounting === 1 ? "Yes" : "No",
      Reconciliation: item.reconciliation === 1 ? "Yes" : "No",
      "OD Calculation": item.odCalculation === 1 ? "Yes" : "No",
      "Loan Repayments": item.loanRepayments === 1 ? "Yes" : "No",
      "LC Repayments": item.lcRepayments === 1 ? "Yes" : "No",
      "Created by": item.created_by || "",
      "Created Date": item.creationDate ? formatDate(item.creationDate) : "",
      "Created Time": item.creationTime ? formatTime(item.creationTime) : "",
      "Updated by": item.changed_by || "",
      "Updated Date": item.changedDate ? formatDate(item.changedDate) : "",
      "Updated Time": item.changedTime ? formatTime(item.changedTime) : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Account Types");

    XLSX.writeFile(workbook, "AccountStatus.xlsx");
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };
  const buttons = BamButtonsConfigs.AccountStatus.map((button) => ({
    ...button,
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleAddRow()
        : button.icon === MdOutlineFileDownload
          ? handleDownload
          : null,
  }));

  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modifiedRows, setModifiedRows] = useState([]);
  const [assignee, setAssignee] = useState({
    id: "",
    assigner: "",
    clientId: "",
  });
  const [tableData, setTableData] = useState([]);
  const [newRowIndex, setNewRowIndex] = useState(null);
  const [isStsatusPopupOpen, setStatusPopupOpen] = useState(false);
  // Handle adding a new row
  const handleAddRow = () => {
    setStatusPopupOpen(true);
  };
  const closeStatusPopup = () => {
    setStatusPopupOpen(false);
  };

  useEffect(() => {
    // Fetch user details for assignee
    const fetchAssignee = async () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          setAssignee({
            id: userDetail.userId || "",
            assigner: userDetail.username || "",
            userId: userDetail.userId || "",
            clientId: userDetail.clientId || "",
          });
        } else {
          toast("error", "No user detail found in session storage.");
        }
      } catch (error) {
        toast("error", `Error fetching user details: ${error.message}`);
      }
    };

    fetchAssignee();
  }, []);

  useEffect(() => {
    fetchAccountStatusData();
  }, [assignee]);

  const fetchAccountStatusData = async () => {
    try {
      setLoading(true);
      if (!assignee.clientId) {
        return;
      }
      const response = await SetUpServices.getAccountStatus(assignee.clientId);
      const sortedData = response.data.data;
      setTableData(sortedData);
    } catch (error) {
      setError("Failed to fetch account statuses");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const AssignNewStatusModal = ({ isOpen, onClose }) => {
    const [status, setStatus] = useState("");
    const [options, setOptions] = useState({
      receiveMT940: false,
      ftout: false,
      ftin: false,
      cashAccounting: false,
      reconciliation: false,
      odCalculation: false,
      loanRepayment: false,
      lcRepayment: false,
    });
    const handleOptionChange = (key) => {
      setOptions((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const handleSubmit = async () => {
      if (!status) {
        toast("error", "Status is required.");
        return;
      }
      const newRow = {
        status,
        clientId: assignee.clientId,
        receiveMt940: options.receiveMT940 ? 1 : 0,
        ftOut: options.ftout ? 1 : 0,
        ftIn: options.ftin ? 1 : 0,
        cashAccounting: options.cashAccounting ? 1 : 0,
        reconciliation: options.reconciliation ? 1 : 0,
        odCalculation: options.odCalculation ? 1 : 0,
        loanRepayments: options.loanRepayment ? 1 : 0,
        lcRepayments: options.lcRepayment ? 1 : 0,
        created_by: assignee.id,
        created_date: new Date().toLocaleDateString(),
        created_time: new Date().toLocaleTimeString(),
      };

      const response = await SetUpServices.storeAccountStatus(newRow);
      if (response.status === 200) {
        toast("success", response.message);
        fetchAccountStatusData();
        onClose();
      } else if (response.status === 203) {
        toast("error", response.message);
      } else {
        toast("error", response.message);
      }

      // setTableData((prevData) => {
      //   const updatedData = [...prevData, newRow];
      //   setNewRowIndex(updatedData.length - 1);
      //   return updatedData;
      // });
      // setModifiedRows((prev) => [...prev, newRow]);
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
        <div className="ml-16 bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-3xl h-half relative">
          <h2 className="text-lg font-semibold mb-4 text-blue-600 p-4">
            Assigning New Status
          </h2>

          <div className="display-flex mb-4 flex items-center p-4">
            <label className="mr-1 text-xs text-customGray">
              Status 
            </label><span className="text-red-600 mr-6">*</span>
            <span className="ml-3">:</span>
            <input
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="border p-1.5 rounded w-72 h-8 text-xs text-customGray text-focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
            />
          </div>

          <div className="mb-6 mt-6 p-4">
            <div className="grid grid-cols-4 gap-4">
              <label className="flex items-center text-xs text-customGray ">
                <input
                  type="checkbox"
                  checked={options.receiveMT940}
                  onChange={() => handleOptionChange("receiveMT940")}
                  className="mr-2 border-2 border-blue-500 focus:ring-blue-500"
                />
                Receive MT940
              </label>
              <label className="flex items-center text-xs text-customGray ">
                <input
                  type="checkbox"
                  checked={options.ftout}
                  onChange={() => handleOptionChange("ftout")}
                  className="mr-2"
                />
                FTOUT
              </label>
              <label className="flex items-center text-xs text-customGray ">
                <input
                  type="checkbox"
                  checked={options.ftin}
                  onChange={() => handleOptionChange("ftin")}
                  className="mr-2"
                />
                FTIN
              </label>
              <label className="flex items-center text-xs text-customGray ">
                <input
                  type="checkbox"
                  checked={options.cashAccounting}
                  onChange={() => handleOptionChange("cashAccounting")}
                  className="mr-2"
                />
                Cash Accounting
              </label>
              <label className="flex items-center text-xs text-customGray ">
                <input
                  type="checkbox"
                  checked={options.reconciliation}
                  onChange={() => handleOptionChange("reconciliation")}
                  className="mr-2"
                />
                Reconciliation
              </label>
              <label className="flex items-center text-xs text-customGray ">
                <input
                  type="checkbox"
                  checked={options.odCalculation}
                  onChange={() => handleOptionChange("odCalculation")}
                  className="mr-2"
                />
                OD Calculation
              </label>
              <label className="flex items-center text-xs text-customGray ">
                <input
                  type="checkbox"
                  checked={options.loanRepayment}
                  onChange={() => handleOptionChange("loanRepayment")}
                  className="mr-2"
                />
                Loan Repayment
              </label>
              <label className="flex items-center text-xs text-customGray ">
                <input
                  type="checkbox"
                  checked={options.lcRepayment}
                  onChange={() => handleOptionChange("lcRepayment")}
                  className="mr-2"
                />
                LC Repayment
              </label>
            </div>
          </div>
          <div className="flex justify-between items-center mt-4 p-4">
            <CustomButton
              title={"Cancel"}
              className={"h-10 w-40"}
              onClick={onClose}
            />
            <CustomButton
              title={"Save"}
              className={"h-10 w-40"}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  };
  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    setSelectedRows(
      selectedRows.length === tableData.length
        ? []
        : tableData.map((row) => row.id)
    );
  };

  const handleRowDoubleClick = (id) => {
    console.log(`Row with ID ${id} was double clicked.`);
  };

  const handleCheckboxChange = (id, field) => {
    const updatedValue =
      tableData.find((row) => row.id === id)[field] === 1 ? 0 : 1;

    const updatedRow = {
      ...tableData.find((row) => row.id === id),
      [field]: updatedValue,
    };

    setTableData((prevData) =>
      prevData.map((row) => (row.id === id ? updatedRow : row))
    );

    setModifiedRows((prev) => {
      const exists = prev.some((row) => row.id === id);

      if (exists) {
        // Replace existing row
        return prev.map((row) => (row.id === id ? updatedRow : row));
      } else {
        // Add new row
        return [...prev, updatedRow];
      }
    });
  };

  const handleSubmitChanges = async () => {
    if (modifiedRows.length === 0) {
      toast("info", "No changes to save");
      return;
    }

    try {
      await Promise.all(
        modifiedRows.map((row) =>
          SetUpServices.updateAccountStatus({
            ...row,
            id: row.id,
            created_by: assignee.id,
            changed_by: assignee.id,
            clientId: assignee.clientId,
          })
        )
      );
      fetchAccountStatusData();

      toast("success", "Status updated successfully");

      setModifiedRows([]);
    } catch (error) {
      console.error("Failed to update account statuses:", error);
      toast("error", "Failed to update statuses");
    }
  };

  const columns = [
    {
      Header: "SL No",
      accessor: "slNo",
      width: ("SL No".length -2)*10,
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Status",
      accessor: "status",
      width: ("Status".length -2)*10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value.toUpperCase()}</div>,
    },
    {
      Header: "Receive MT940",
      accessor: "receiveMt940",
      width: ("Receive MT940".length -2)*10,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.receiveMt940 === 1}
          onChange={() => handleCheckboxChange(row.original.id, "receiveMt940")}
        />
      ),
    },
    {
      Header: "FTOUT",
      accessor: "ftOut",
      width: ("FTOUT".length -2)*10,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.ftOut === 1} // Assuming the API returns 0 or 1
          onChange={() => handleCheckboxChange(row.original.id, "ftOut")}
        />
      ),
    },
    {
      Header: "FTIN",
      accessor: "ftIn",
      width: ("FTIN".length -2)*10,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.ftIn === 1} // Assuming the API returns 0 or 1
          onChange={() => handleCheckboxChange(row.original.id, "ftIn")}
        />
      ),
    },
    {
      Header: "Cash Accounting",
      accessor: "cashAccounting",
      width: ("Cash Accounting".length -2)*10,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.cashAccounting === 1} // Assuming the API returns 0 or 1
          onChange={() =>
            handleCheckboxChange(row.original.id, "cashAccounting")
          }
        />
      ),
    },
    {
      Header: "Reconciliation",
      accessor: "reconciliation",
      width: ("Reconciliation".length -2)*10,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.reconciliation === 1} // Assuming the API returns 0 or 1
          onChange={() =>
            handleCheckboxChange(row.original.id, "reconciliation")
          }
        />
      ),
    },
    {
      Header: "OD Calculation",
      accessor: "odCalculation",
      width: ("OD Calculation".length -2)*10,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.odCalculation === 1}
          onChange={() =>
            handleCheckboxChange(row.original.id, "odCalculation")
          }
        />
      ),
    },
    {
      Header: "Loan Repayments",
      accessor: "loanRepayments",
      width: ("Loan Repayments".length -2)*10,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.loanRepayments === 1}
          onChange={() =>
            handleCheckboxChange(row.original.id, "loanRepayments")
          }
        />
      ),
    },
    {
      Header: "LC Repayments",
      accessor: "lcRepayments",
      width: ("LC Repayments".length -2)*10,
      Cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.original.lcRepayments === 1}
          onChange={() => handleCheckboxChange(row.original.id, "lcRepayments")}
        />
      ),
    },
    { Header: "Created by", accessor: "created_by", width: ("Created by".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
    {
      Header: "Created date",
      accessor: "creationDate",
      width: ("Created date".length -2)*10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Created time",
      accessor: "creationTime",
      width: ("Created time".length -2)*10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
    { Header: "Updated by", accessor: "changed_by", width: ("Updated by".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
    {
      Header: "Updated date",
      accessor: "changedDate",
      width: ("Updated date".length -2)*10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Updated time",
      accessor: "changedTime",
      width: ("Updated time".length -2)*10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
  ];

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "BAM") navigate("/bam");
    else if (crumb.title === "Set Up") navigate("/bam");
    else if (crumb.title === "Account Status") navigate("/3/BAM/21/account-status");
    else if (crumb.path) navigate(crumb.path);
  };
  const [rolesPermissions, setRolesPermissions] = useState([]);

  useEffect(() => {
        fetchUserPermissionDetails();
    }, [assignee.userId]);

  const fetchUserPermissionDetails = async () => {
    try {
      if(!assignee.userId) return;
      const data = { userId: assignee.userId };
      // console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      //console.log("user permissions details", response);
      const responseData = response.data;
      const permissions = responseData.rolesPermissionsTables.some((permission) => permission.iconId === iconId && permission.permissionsId === 3);
      const savepermission = responseData.rolesPermissionsTables.some((permission) => permission.iconId === iconId && permission.permissionsId === 23);
    
      // if(!permissions) {
      //         toast("error", "You Don't have permission to view this Account Status.");
      //       }
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      //console.log("Set Permission details for bam screen", responseData.rolesPermissionsTables);
      SethasPermissionForView(permissions);
      SethasPermissionForSave(savepermission);

    } catch (error) {
      console.error(error.message); // Set error message
    }
  };


  return (
    <div>
      <Header
        title={"BAM > Set Up > Account Status"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
{/* { hasPermissionForView ? ( */}
  <div>
      <div>
        {isStsatusPopupOpen && (
          <AssignNewStatusModal
            isOpen={isStsatusPopupOpen}
            onClose={closeStatusPopup}
          />
        )}
      </div>

      <div>
        <BamActions buttons={buttons}
          icons={iconId}
          selectedCount={selectedRows.length}
          selectIds={selectedRows}
        />
      </div>

      <ReTable
        data={tableData}
        columns={columns}
        showCheckbox={false}
        onSelectRow={handleSelectRow}
        selectedRows={selectedRows}
        onSelectAll={handleSelectAll}
        onRowDoubleClick={handleRowDoubleClick}
      />

      <Footer>
        <button
          onClick={iconId === 21 ? (hasPermissionForSave ? handleSubmitChanges: null) : null}
          disabled={!hasPermissionForSave}
          title={!hasPermissionForSave ? "You don't have permission" : ""}
          className={`text-sm text-white py-1 px-2 rounded transition duration-200 ease-in-out w-16 ml-7
            ${!hasPermissionForSave ? "bg-gray-400" : "bg-customBlue hover:bg-blue-600"}
            `}
        >
          Submit
        </button>
      </Footer>
</div>
      {/* ): ('')
} */}
    </div>
  );
}
