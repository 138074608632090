import RestService from "./RestServices";
const basePath = "/statement-analyzer";
const StatementAnalyzerService = {
  exporAll: (data) => {
    return RestService.ExportData(`${basePath}/export-data`, data);
  },
  getCompanyCode: () => {
    return RestService.GetAllData(
      `${basePath}/get-all-company`
    );
  },
  getBranch: () => {
    return RestService.GetAllData(
      `${basePath}/get-all-branch`
    );
  },
  getLegalEntity: () => {
    return RestService.GetAllData(
      `${basePath}/get-all-legal-entity`
    );
  },
  getAllBugroup: () => {
    return RestService.GetAllData(
      `${basePath}/get-all-buGroup`
    );
  },
  getAllBankAccountId: () => {
    return RestService.GetAllData(
      `${basePath}/get-all-bankAccountId`
    );
  },
  getAllStatement: () => {
    return RestService.GetAllData(
      `${basePath}/get-all-statement`
    );
  },
  getAllUserId: () => {
    return RestService.GetAllData(
      `${basePath}/get-user-id`
    );
  },
  addProcessDetails: (data) => {
    return RestService.CreateData(`${basePath}/add-process-details`, data);
  },
  updateProcessDetails: (data, subModule, iconId) => {
    return RestService.UpdateData(`${basePath}/update-process-list?subModule=${subModule}&iconId=${iconId}`, data);
  },
  getProcessedRunData: (data, pageSize, page, searchQuery) => {
    return RestService.CreateData(`${basePath
      }/get-run-details-byID?&limit=${pageSize}&offset=${(page - 1) * pageSize}&searchQuery=${searchQuery}`, data);
  },
  addProcessMt940BalanceDetails: (data) => {
    return RestService.CreateData(`${basePath}/add-balances-details`, data);
  },

  updateProcessMt940BalanceDetails: (data, subModule, iconId) => {
    return RestService.UpdateData(`${basePath}/update-balance-process-list?subModule=${subModule}&iconId=${iconId}`, data);
  },
  getMt940Balances: (data, searchQuery) => {
    return RestService.CreateData(`${basePath
      }/get-mt940-balance?searchQuery=${searchQuery}`, data);
  },
  exportProcessData: (data) => {
    return RestService.ExportData(`${basePath
      }/export-mt940-balance`, data);
  },
  removeBtc: (data) => {
    return RestService.CreateData(`${basePath}/remove-btce`, data);
  }
};

export default StatementAnalyzerService;
