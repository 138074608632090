import { Checkbox } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { IoMdCloudDownload } from 'react-icons/io';
import Footer from './footer';
import UserService from '../rest-services/UserServices';

export default function UploadComponent({
  downloadPath,
  handleFileChange,
  buttonText,
  setButtonText,
  setIsError,
  isUploaded,
  isError,
  handleSubmit,
  displayOption,
  setDisplayOption,
  uploadOption,
  setUploadOption,
  icons,
  isDisplayChecked,
  setIsDisplayChecked,
  isUploadChecked,
  setIsUploadChecked,
  downloadText = "Download Excel format",
  enableFormatSelection = false, // New prop to enable/disable format selection
  enableBAMFormatSelection = false, // New prop to enable/disable format selection
  onFormatChange,
  selectedFormat,
  setSelectedFormat,
}) {
const fileInputRef = useRef(null);
const [rolesPermissions, setRolesPermissions] = useState([]);
  const [assignee, setAssignee] = useState({ id: "", username: "" });
const iconId = parseInt(icons, 10);

// const hasPermissionForAdd = (iconId === 108 || iconId === 7)  && rolesPermissions.some(
//   (permission) => permission.permissionsId === 1  && permission.iconId === iconId
// );
// const [selectedFormat, setSelectedFormat] = useState('general');

    const handleFormatChange = (e) => {
        const format = e.target.value;
        setSelectedFormat(format);
        if (onFormatChange) {
            onFormatChange(format); // Pass the selected format to the parent
        }
        setButtonText("Upload from desktop");
        setIsError(false);
        // setTableData([]);
    };


const hasPermissionForAdd = (iconId === 108 || iconId === 7) && 
  rolesPermissions.some(permission => permission.permissionsId === 1 && permission.iconId === iconId) &&
  rolesPermissions.some(permission => permission.permissionsId === 2 && permission.iconId === iconId);


  // console.log("Check user permissions to user Add", hasPermissionForAdd);

    const handleClick = (e) => {
        fileInputRef.current.click();
      };

  
      useEffect(() => {
        fetchAssignee();
      }, []);
    
      const fetchAssignee = async () => {
        try {
          const userDetailString = localStorage.getItem("userDetail");
          if (userDetailString) {
            const userDetail = JSON.parse(userDetailString);
            const assigneeData = {
              clientId: userDetail.clientId || "",
              id: userDetail.id || "",
              assigner: userDetail.username || "",
              userId: userDetail.userId || "",
            };
            setAssignee(assigneeData);
          } else {
          }
        } catch (error) { }
      };
    
      useEffect(() => {
        if (assignee.userId) {
          fetchUserPermissionDetails();
        }
      }, [assignee.userId]);
    
      const fetchUserPermissionDetails = async () => {
        try {
          const data = { userId: assignee.userId };
          // console.log("Request Data:", data);
    
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
            data
          );
          const responseData = response.data;
          setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
          console.log("Set Permission details for bam screen", responseData.rolesPermissionsTables);
    
    
        } catch (error) {
          console.error(error.message); // Set error message
        }
      };
    
const handleRadioChange = (e) => {
    setDisplayOption(e.target.value);
  };

      const handleCheckboxChange = (e) => {
        if (!isUploadChecked) { // Prevent unchecking when upload is checked
        const isChecked = e.target.checked;
        setIsDisplayChecked(isChecked);
    
        if (isChecked) {
          setDisplayOption("2"); // Automatically select "Errors Only" when checked
        } else {
          setDisplayOption(""); // Reset radio button selection if unchecked
          
        }
      }
      };

      const  handleCheckboxChange2 = (e) => {
        const isChecked = e.target.checked;
        setIsUploadChecked(isChecked);
        if (isChecked) {
            setUploadOption("3"); // Automatically select "Errors Only" when checked
            setIsDisplayChecked(true); // Automatically check ERROR CHECK/DISPLAY
            setDisplayOption("2"); // Ensure "All" is selected
          } else {
            setUploadOption(""); // Reset radio button selection if unchecked
          }
      };
    
    
  return (
    <div>
      
    <div className="p-8 pr-24">
  
      <div className="flex flex-col space-y-8 p-10 border rounded-md">
        {/* File Upload Section */}
        {enableFormatSelection && (
      <div className="flex flex-col space-y-2 py-3">
        <div className="flex items-center">
          <span className="w-20 text-sm font-small text-customGray">Format</span>
          <span className="ml-4">:</span>
          <label className="flex items-center ml-6">
            <input
              type="radio"
              name="format"
              value="general"
              checked={selectedFormat === 'general'}
              onChange={handleFormatChange}
              className="mr-2 accent-customBlue"
            />
            <span className='text-sm text-customGray font-small'>General</span>
          </label>
          <label className="flex items-center ml-6">
            <input
              type="radio"
              name="format"
              value="reuters"
              checked={selectedFormat === 'reuters'}
              onChange={handleFormatChange}
              className="mr-2 accent-customBlue"
            />
            <span className='text-sm text-customGray font-small'>Reuters</span>
          </label>
        </div>
      </div>
    )}

    {enableBAMFormatSelection &&(
       <div className="flex flex-col space-y-2 py-3">
       <div className="flex items-center">
         <span className="w-20 text-sm font-small text-gray-600">Format</span>
         <span className="ml-4">:</span>
         <label className="flex items-center ml-6">
           <input
             type="radio"
             name="format"
             value="NewRequest"
             checked={selectedFormat === 'NewRequest'}
             onChange={handleFormatChange}
             className="mr-2 accent-customBlue"
           />
           <span className='text-sm text-customGray font-small'>New Request</span>
         </label>
         <label className="flex items-center ml-6">
           <input
             type="radio"
             name="format"
             value="AcctNoUpdate"
             checked={selectedFormat === 'AcctNoUpdate'}
             onChange={handleFormatChange}
             className="mr-2 accent-customBlue"
           />
           <span className='text-sm text-customGray font-small'>Account Number Update</span>
         </label>

         <label className="flex items-center ml-6">
           <input
             type="radio"
             name="format"
             value="Migration"
             checked={selectedFormat === 'Migration'}
             onChange={handleFormatChange}
             className="mr-2 accent-customBlue"
           />
           <span className='text-sm text-customGray font-small'>Migration</span>
         </label>

         {selectedFormat === 'Migration' && (
      <div className="flex items-center ml-6">
        <input
          type="checkbox"
          id="authorization"
          name="authorization"
          className="mr-2 accent-customBlue"
        />
        <label htmlFor="authorization" className='text-sm text-customGray font-small'>Authorization</label>
      </div>
    )}

       </div>
      

     </div>
    )}

        <div className="flex items-center">
          <label className="flex items-center">
            <span className="w-20 font-small text-sm text-customGray">File</span>
            <span className="ml-4">:</span>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".xlsx, .xls"
            />
          </label>

          <button
            type="button"
            onClick={handleClick}
            className={`border border-gray-300 p-2 w-80 h-10 text-sm focus:outline-none hover:border-blue-400 ml-6 rounded-full text-center
              ${isUploaded ? "text-green-500 animate-pulse" : isError ? "text-red-500" : "text-gray-700"}
              ${buttonText === "File Uploaded" ? "text-green-500" : ""}`}
          >
            {buttonText}
          </button>

          <a
            href={downloadPath}
            download
            className="flex items-center ml-4 text-blue-500 hover:underline"
          >
            {downloadText}
            <IoMdCloudDownload className="ml-2" />
          </a>
        </div>

        {/* Display Section */}
        <div className="flex flex-col space-y-2 py-3">
          <div className="flex items-center">
            <Checkbox
                checked={isDisplayChecked}
                onChange={handleCheckboxChange} // Handle checkbox state change
                className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            />
            <span className="text-sm font-medium text-gray-700">ERROR CHECK/DISPLAY</span>
          </div>

          <div className="flex space-x-6 ml-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="displayOption"
                value="2"
                className="mr-2 accent-customBlue"
                checked={displayOption === "2"}
                onChange={handleRadioChange}
                disabled={!isDisplayChecked}
              />
              <span className='text-sm text-customGray font-small'>All</span>
            </label>

            <label className="flex items-center">
              <input
                type="radio"
                value="1"
                name="displayOption"
                className="mr-2 accent-customBlue"
                checked={displayOption === "1"}
                onChange={handleRadioChange}
                disabled={!isDisplayChecked}
              />
              <span className='text-sm text-customGray font-small'>Errors Only</span>
            </label>
          </div>
        </div>

        {/* Upload Section */}
        <div className="flex flex-col space-y-2 py-3">
          <div className="flex items-center"  title={iconId === 108 || iconId === 7 ? (!hasPermissionForAdd ? "You don't have permission" : "") : ''} >
            <Checkbox
              onChange={handleCheckboxChange2}
              checked={isUploadChecked}
              disabled={iconId === 108 || iconId === 7 ? !hasPermissionForAdd : ''} // Disable button if permission exists
              className="form-checkbox text-blue-500 border-blue-500 rounded transform scale-75"
            /> 
            <span className="font-medium text-sm text-gray-700">
              UPDATE (ONLY SUCCESSFUL RECORDS WILL PROCESS)
            </span>
          </div>
        </div>
      </div>
    </div>

    <Footer>
              <button
                onClick={handleSubmit}
                className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
              >
                Run
              </button>
            </Footer>
    </div>
  )
}
