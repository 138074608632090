import React, { useState } from "react";
import ReTable from "../../../../../../components/Table"; // Import your ReTable component
import Footer from "../../../../../../components/footer";

export default function ValidityContents() {
  const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows
  const [tableData, setTableData] = useState([
    {
      id: 1,
      BankAccountCode: "ICICI6237283",
      CompanyCode: "TATA Motors",
      Status: "01.07.2024",
      ValidFrom: "31.12.1999",
      ValidTo: "01.07.2024",
      UpdatedBy: "Admin1",
      UpdatedDate: "01.02.2024",
      UpdatedTime: "10:00 AM",
    },
    {
      id: 2,
      BankAccountCode: "HDFC7890456",
      CompanyCode: "Reliance",
      Status: "01.08.2024",
      ValidFrom: "01.01.2000",
      ValidTo: "01.08.2024",
      UpdatedBy: "Admin2",
      UpdatedDate: "05.03.2024",
      UpdatedTime: "11:00 AM",
    },
    {
      id: 2,
      BankAccountCode: "HDFC7890456",
      CompanyCode: "Reliance",
      Status: "01.08.2024",
      ValidFrom: "01.01.2000",
      ValidTo: "01.08.2024",
      UpdatedBy: "Admin2",
      UpdatedDate: "05.03.2024",
      UpdatedTime: "11:00 AM",
    },
    {
      id: 2,
      BankAccountCode: "HDFC7890456",
      CompanyCode: "Reliance",
      Status: "01.08.2024",
      ValidFrom: "01.01.2000",
      ValidTo: "01.08.2024",
      UpdatedBy: "Admin2",
      UpdatedDate: "05.03.2024",
      UpdatedTime: "11:00 AM",
    },
  ]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Bank Account Code",
        accessor: "BankAccountCode",
        width: 150,
      },
      {
        Header: "Company Code",
        accessor: "CompanyCode",
        width: 150,
      },
      {
        Header: "Status",
        accessor: "Status",
        width: 200,
      },
      {
        Header: "Valid From",
        accessor: "ValidFrom",
        width: 200,
      },
      {
        Header: "Valid To",
        accessor: "ValidTo",
        width: 200,
      },
      {
        Header: "Updated By",
        accessor: "UpdatedBy",
        width: 200,
      },
      {
        Header: "Updated Date",
        accessor: "UpdatedDate",
        width: 200,
      },
      {
        Header: "Updated Time",
        accessor: "UpdatedTime",
        width: 200,
      },
    ],
    []
  );

  // Function to handle row selection
  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  // Function to handle select all checkbox
  const onSelectAll = () => {
    if (selectedRows.length === tableData.length) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(tableData.map((row) => row.id)); // Select all
    }
  };

  // Function to handle double-click on a row
  const onRowDoubleClick = (rowId) => {
    console.log("Double clicked row with id:", rowId);
  };

  // Function to add a new row
  const addNewRow = () => {
    const newRow = {
      id: tableData.length + 1,
      BankAccountCode: "NEW123456",
      CompanyCode: "New Company",
      Status: "Active",
      ValidFrom: "01.01.2024",
      ValidTo: "01.01.2025",
      UpdatedBy: "Admin3",
      UpdatedDate: "01.01.2024",
      UpdatedTime: "12:00 PM",
    };
    setTableData([...tableData, newRow]); // Add the new row to the existing data
  };

  return (
    <div className="mt-10">
      <ReTable
        data={tableData}
        columns={columns}
        onSelectRow={onSelectRow}
        selectedRows={selectedRows}
        onSelectAll={onSelectAll}
        onRowDoubleClick={onRowDoubleClick}
      />

      {/* Add "plus" icon button */}
      <div className="text-start text-customBlue mt-4 ml-4">
        <button onClick={addNewRow} color="primary" aria-label="add new row">
          + Add New
        </button>
      </div>
      <Footer>
        <button className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
          Save
        </button>
        <button className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30">
          Save & Continue
        </button>
      </Footer>
    </div>
  );
}
