import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import BankServices from "../../../../../rest-services/BankServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../../components/toast/toast";
import axios from "axios";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";

export default function BankAdd() {
  const { bankId } = useParams();
  const [bankCode, setBankCode] = useState("");
  const [description, setDescription] = useState(""); // Updated to use description
  const [logo, setLogo] = useState("");
  const [editlogo, setEditLogo] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const navigate = useNavigate();

  useEffect(() => {
    if (bankId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [bankId]);

  const validateForm = () => {
    const newErrors = {};
    if (!bankCode) newErrors.bankCode = "Bank Code is required.";
    if (!description) newErrors.description = "Description is required."; // Validate description
    if (!logo) newErrors.logo = "Logo is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  // Example usage in your component
  const getDataForEdit = async () => {
    try {
      const response = await BankServices.getBankById(bankId);
      if (response && response.data) {
        setBankCode(response.data.bankCode || "");
        setDescription(response.data.Description || "");
        fetchImage(response.data.logo);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching bank details: ${error.message}`,
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const fetchImage = async (image) => {
    const encodedCredentials = sessionStorage.getItem("encodedCredentials");
    const imageUrl = image;

    try {
      const response = await axios.get(imageUrl, {
        headers: {
          Authorization: `Bearer ${encodedCredentials}`,
        },
        responseType: "blob",
      });

      const imageBlob = URL.createObjectURL(response.data);
      setEditLogo(imageBlob);
      // You can now use `imageBlob` as the `src` for an <img> tag
    } catch (error) {
      console.error("Error fetching the image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form only for new entries (no bankId)
    if (!bankId && !validateForm()) return;
  
    const formData = new FormData();
    formData.append("bankCode", bankCode);
    formData.append("Description", description);
    formData.append("created_by", assignee.assigner);
    formData.append("changed_by", assignee.assigner);
  
    // If it's a new bank (no bankId), we need to upload the new logo
    if (!bankId) {
      if (logo instanceof File) {
        formData.append("logo", logo);
      }
    } else {
      // If editing, we use the existing logo (unless a new one is uploaded)
      const existingBank = await BankServices.getBankById(bankId);
      if (logo instanceof File) {
        formData.append("logo", logo); // New logo is uploaded
      } else {
        formData.append("logo", existingBank.data.logo); // Use the existing logo
      }
      formData.append("id", bankId); // Include the bankId for editing
    }
  
    try {
      const response = await BankServices.addBank(
        `${process.env.REACT_APP_BASE_URL}/create-bank`,
        formData
      );
  
      if (response && !response.error) {
        toast(
          "success",
          bankId ? "Bank updated successfully" : "Bank added successfully"
        );
        setBankCode("");
        setDescription("");
        setLogo("");
        navigate("/bank");
      } else {
        toast("error", "Error in adding/updating bank");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating bank: ${error.message}`,
      });
    }
  };
  
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'Bank') {
      console.log("Navigating to /country");
      navigate('/bank');
    }
      else if (crumb.title === 'Primary Data') {
        console.log("Navigating to /country");
        navigate('/master-data');
      
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
<div>
  <Header
    title={`Primary Data > Global Master Data > Bank > ${
      bankId ? (isViewMode ? "View" : "Edit") : "Add"
    }`}
    onBreadcrumbClick={handleBreadcrumbClick}
  />

  {alert && (
    <div className={`alert alert-${alert.severity} mt-4`}>
      {alert.message}
    </div>
  )}

  <div className="flex flex-col space-y-12 p-10">
    <div className="flex flex-row space-x-8">
      {/* Left Column - Bank Code and Description */}
      <div className="flex flex-col space-y-4 w-1/2">
        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Bank Code<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={bankCode}
            onChange={(e) => {
              setBankCode(e.target.value);
              if (errors.bankCode) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  bankCode: "",
                }));
              }
            }}
            className={`border ${
              errors.bankCode ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>

        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Description<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              if (errors.description) {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  description: "",
                }));
              }
            }}
            className={`border ${
              errors.description ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>
      </div>

      {/* Right Column - Upload and Logo */}
      <div className="flex flex-col space-y-4 w-1/2">
        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Logo<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (!bankId) {
                if (file && file.type.startsWith("image/")) {
                  setLogo(file);
                  setErrors((prev) => ({ ...prev, logo: "" }));
                } else {
                  setErrors((prev) => ({
                    ...prev,
                    logo: "Please upload a valid image file.",
                  }));
                }
              } else {
                setLogo(file);
              }
            }}
            className={`border ${
              errors.logo && !bankId ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>

        {(bankId ? editlogo : logo) && (
          <div className="mt-2">
            <img
              src={bankId ? editlogo : URL.createObjectURL(logo)}
              alt="Bank Logo"
              className="h-20 w-20 object-contain"
            />
          </div>
        )}
      </div>
    </div>

    {!isViewMode && <CustomButton onClick={handleSubmit} title="Save" />}
  </div>
</div>

  );
}
