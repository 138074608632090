// import React, { useState } from "react";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Custom eye icons

// const PasswordField = ({
//   label,
//   value,
//   placeholder,
//   onChange,
//   className,
//   style,
//   onBlur,
// }) => {
//   const [passwordVisible, setPasswordVisible] = useState(false);

//   // Toggle between showing and hiding the password
//   const togglePasswordVisibility = () => {
//     setPasswordVisible((prevState) => !prevState);
//   };

//   return (
//     <div style={{ position: "relative", width: "100%" }}>
//       {label && <label className="block xl:text-sm lg:text-xs lg:mb-1 xl:mb-2 text-customGray">{label}</label>}
//       <input
//         type={passwordVisible ? "text" : "password"}
//         placeholder={placeholder}
//         onChange={onChange}
//         className={className}
//         value={value}
//         style={{ paddingRight: "40px", ...style }}
//         autoComplete="off"
//         onBlur={onBlur}
//       />
//       <span
//       className="absolute right-[10px] lg:top-[35px] xl:top-1/2 transform -translate-y-1/2 cursor-pointer"
//         onClick={togglePasswordVisibility}
//         // style={{
//         //   position: "absolute",
//         //   right: "10px",
//         //   top: "50%",
//         //   transform: "translateY(-50%)",
//         //   cursor: "pointer",
//         // }}
//       >
//         {passwordVisible ? <FaEye /> : <FaEyeSlash />}{" "}
//         {/* Conditionally render icon */}
//       </span>
//     </div>
//   );
// };

// export default PasswordField;
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Custom eye icons

const PasswordField = ({
  label,
  value,
  placeholder,
  onChange,
  className,
  style,
  onBlur,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Toggle between showing and hiding the password
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="relative w-full">
      {label && (
        <label className="block text-xs lg:mb-1 xl:mb-2 text-customGray">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder={placeholder}
          onChange={onChange}
          className={`${className} pr-10`} // Add right padding
          value={value}
          style={{ ...style }}
          autoComplete="off"
          onBlur={onBlur}
        />
        <span
          className="absolute right-3 xl:top-[20px]  xl:-translate-y-2/3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
          onClick={togglePasswordVisibility}
        >
          {passwordVisible ? <FaEye /> : <FaEyeSlash />}
        </span>
      </div>
    </div>
  );
};

export default PasswordField;
