import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../../../components/toast/toast";
import Header from "../../../../../../components/Header";
import { TbFlag3Filled } from "react-icons/tb";
import Footer from "../../../../../../components/footer";
import ButtonGroup from "../../../../../../components/FooterButton";
import CustomDropdown from "../../../../../../components/CustomDropdown";
import LabelComponent from "../../../../../../components/LabelComponent";

export default function ScheduleAdd() {
  const { buGroupId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [buGroup, setBuGroup] = useState("");
  const [bugroupDescription, setBugroupDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "",clientId:"" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const [status, setStatus] = useState("");
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!buGroupId;

  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const { BU_Group, BU_Group_Description } = location.state.copiedData;
      setBuGroup(BU_Group);
      setBugroupDescription(BU_Group_Description);
    } else if (buGroupId) {
    //   getDataForEdit();
    }
    fetchAssignee();
  }, [buGroupId, location.state]);

//   const getDataForEdit = async () => {
//     try {
  
     
  
//       if (!buGroupId) {
//         console.error("BU Group ID is missing from local storage or state");
//         return; // Exit the function if buGroupId is missing
//       }
  
//       const response = await BuGroupServices.getBuGroupById( buGroupId);
//       const data = response.data.data;
  
//       setBuGroup(data.BU_Group);
//       setBugroupDescription(data.BU_Group_Description);
//       setStatus(data.status);
//     } catch (error) {
//       console.error("Error fetching BUGroup for edit:", error);
//     }
//   };
  

  const validateForm = () => {
    const newErrors = {};
    if (!buGroup) newErrors.buGroup = "BuGroup  is required.";
    if (!bugroupDescription) newErrors.bugroupDescription = "BuGroup Description is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate the form
//     if (!validateForm()) return;

//     // Check for duplicate BU Group code
//     try {
//       // Skip duplication check for edit mode
//       if (!buGroupId || isCopyMode) {
//         const duplicateCheckResponse = await BuGroupServices.checkDuplicateBugroupcode(
//           `${process.env.REACT_APP_BASE_URL}/getduplicatebugroup`,
//           { BU_Group: buGroup }
//         );

//         if (duplicateCheckResponse.message === "BU group code already exists") {
//           toast("error", "BuGroup Code already exists.");
//           setErrors((prev) => ({
//             ...prev,
//             buGroup: "BuGroup Code already exists.",
//           }));
//           return; // Stop further execution if duplicate exists
//         }
//       }

//       // Prepare the data for submission
//       const data = {
//         id: isCopyMode ? null : buGroupId,
//         BU_Group: buGroup,
//         BU_Group_Description: bugroupDescription,
//         created_by: assignee.assigner,
//         changed_by: assignee.assigner,
//       };

//       console.log("BuGroup data", data);


//       let response;
//       if (!unsavedChanges) {
//         toast(
//           "info",
//           "No BuGroup changes detected. Nothing to save."
//         );
//         navigate("/2/master-data/8/bugroup"); // Redirect after saving, only if Save and Continue is false
//         return false;
//       }
//       if (isCopyMode || !buGroupId) {
//         // Create a new BU Group
//         response = await BuGroupServices.addBuGroup(
//           `${process.env.REACT_APP_BASE_URL}/create-bugroup`,
//           data
//         );
//       } else {
//         // Update an existing BU Group
//         response = await BuGroupServices.addBuGroup(
//           `${process.env.REACT_APP_BASE_URL}/update-bugroup`,
//           data
//         );
//       }

//       if (response && !response.error) {
//         toast(
//           "success",
//           buGroupId && !isCopyMode
//             ? "Bu Group Updated successfully"
//             : "Bu Group Added successfully"
//         );

//         // Clear the form and navigate
//         setBuGroup("");
//         setBugroupDescription("");


//         navigate("/2/master-data/8/bugroup"); // Redirect after saving, only if Save and Continue is false

//       } else {
//         toast("error", "Error adding/updating Bu Group.");
//       }
//     } catch (error) {
//       console.error("Request error:", error);
//       setAlert({
//         severity: "error",
//         message: `Error adding/updating Bu Group: ${error.message}`,
//       });
//     }
//   };
//   const handleSaveandContinue = async (e) => {
//     e.preventDefault();

//     // Validate the form
//     if (!validateForm()) return;

//     // Check for duplicate BU Group code
//     try {
//       // Skip duplication check for edit mode
//       if (!buGroupId || isCopyMode) {
//         const duplicateCheckResponse = await BuGroupServices.checkDuplicateBugroupcode(
//           `${process.env.REACT_APP_BASE_URL}/getduplicatebugroup`,
//           { BU_Group: buGroup }
//         );

//         if (duplicateCheckResponse.message === "BU group code already exists") {
//           toast("error", "BuGroup Code already exists.");
//           setErrors((prev) => ({
//             ...prev,
//             buGroup: "BuGroup Code already exists.",
//           }));
//           return; // Stop further execution if duplicate exists
//         }
//       }

//       // Prepare the data for submission
//       const data = {
//         id: isCopyMode ? null : buGroupId,
//         BU_Group: buGroup,
//         BU_Group_Description: bugroupDescription,
//         created_by: assignee.assigner,
//         changed_by: assignee.assigner,
//       };

//       console.log("BuGroup data", data);


//       let response;
//       if (isCopyMode || !buGroupId) {
//         // Create a new BU Group
//         response = await BuGroupServices.addBuGroup(
//           `${process.env.REACT_APP_BASE_URL}/create-bugroup`,
//           data
//         );
//       } else {
//         // Update an existing BU Group
//         response = await BuGroupServices.addBuGroup(
//           `${process.env.REACT_APP_BASE_URL}/update-bugroup`,
//           data
//         );
//       }

//       if (response && !response.error) {
//         toast(
//           "success",
//           buGroupId && !isCopyMode
//             ? "Bu Group Updated successfully"
//             : "Bu Group Added successfully"
//         );

//         // Clear the form and navigate
//         setBuGroup("");
//         setBugroupDescription("");


//         // Redirect after saving, only if Save and Continue is false

//       } else {
//         toast("error", "Error adding/updating Bu Group.");
//       }
//     } catch (error) {
//       console.error("Request error:", error);
//       setAlert({
//         severity: "error",
//         message: `Error adding/updating Bu Group: ${error.message}`,
//       });
//     }
//   };

//   const checkDuplicateBugroupcode = async (code) => {
//     try {
//       const response = await BuGroupServices.checkDuplicateBugroupcode(
//         `/getduplicatebugroup`,
//         { BU_Group: code }
//       );
//       console.log("Response from server:", response);

//       if (response.message === "BU group code already exists") {
//         // Check if it's a duplicate
//         console.log("Response from internal server:", response);
//         setErrors({ buGroup: " " });

//         toast("error", "BuGroup Code already exists.");
//       }
//       console.log("Response from external server:", response);
//     } catch (error) {
//       console.error("Error checking duplicate buGroup:", error);
//       // Handle potential errors when making the API call
//       setErrors((prev) => ({ ...prev, buGroup: "Error checking role name" }));
//     }
//   };

//   const handleBugroupCodeChange = (e) => {
//     const code = e.target.value;
//     setBuGroup(code);
//     setUnsavedChanges(true);
//     // Clear previous errors when typing
//     setErrors(prev => ({ ...prev, buGroup: "" }));

//     // Check for duplicate LGE Code locally
//     if (code.trim() !== "") {
//       checkDuplicateBugroupcode(code);
//     }
//   };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Admin') {
      navigate('/14/admin');
    } else if (crumb.title === 'Interface Setup') {
      navigate('/14/admin');
    }
    else if (crumb.title === 'Schedule') {
      navigate('/14/admin/schedule-screen');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
 
const getApprovedIcon = () => {
  let color = "gray"; // Default color
  // Determine the color based on the approved status
  if (status === 4) {
    color = "#29CB00"; // Green
  } else if (status === 0) {
    color = "gray"; // Gray
  } else if (status === 1) {
    color = "#AF52DE"; 
  } else if (status === 2) {
    color = "yellow"; 
  } else if (status === 3) {
    color = "orange"; 
  }
  else if (status === 5) {
    color = "red"; // Red
  }
  else if (status === 6) {
    color = "black"; // Red
  }
  return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
};
  return (
    <div>
<Header
  title={`Admin > Interface Setup > Schedule > ${
    isCopyMode 
      ? 'Add' 
      : buGroupId 
        ? (isViewMode ? "View" : "Edit") 
        : "Add"
  }${buGroupId && (isViewMode || !isCopyMode) ? ` > ${buGroup}` : ''}`}
  onBreadcrumbClick={handleBreadcrumbClick} 
  extraContent={buGroupId && !isCopyMode ? getApprovedIcon() : null}
    hasUnsavedChanges={unsavedChanges}
    backRoute={"/14/admin/schedule-screen"}
  />
          <div className="m-6 border border-gray-200 p-2  rounded-lg">

        <div className="flex flex-col space-y-4 col-span-1 p-2">
        
          <label className="flex items-center">
            <span className="w-48 font-small text-customGray text-xs">
              Job Name
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={buGroup}
              maxLength={10}

            //   onChange={handleBugroupCodeChange}

              className={`border font-small text-customGray text-xs ${errors.buGroup ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-5`}
              required
              disabled={((isEditMode && !isCopyMode) || isViewMode)}
            />
          </label>
  {!isViewMode && buGroup.length === 10 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}

<label className="flex items-center">
                <span className="w-[195px] text-xs font-small text-customGray">
                 Month/Year 
                </span>
                <span className="ml-4">:</span>
                <input
                  onChange={(e) => {
                    // setLastStatementDate(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  // value={lastStatementDate}
                  readOnly={isViewMode || isEditMode}
                  type="date"
                  lang="en-GB"
                  className="border p-1.5 text-xs text-customGray rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  maxLength={30}
                />
              </label>
          {!isViewMode && bugroupDescription.length === 40 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}
                <LabelComponent 
                label="No of Jobs Created"
                labelwidth="w-48"
                className="pt-6 font-medium "
                textColor="text-[#B0B0B0]"
                value={"06" || "N/A"} />


        </div>

      </div>
      <Footer>
        {(!isViewMode && (<ButtonGroup 
        // onSave={handleSubmit} onSaveContinue={handleSaveandContinue} 
        // hasId={!!buGroupId} 
        />
        ))}
      </Footer>
    </div>
  );
}
