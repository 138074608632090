// buttonConfigs.js
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { PiCopy } from "react-icons/pi";
import { MdOutlineHistory } from "react-icons/md";
import { MdDelete } from "react-icons/md";

export const BamButtonsConfigs = {
  screen1: [
    {
      icon: IoAddCircleOutline,
      onClick: () => {},
      rotate: true,
      className: "text-blue-500",
    },

    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
      className: "text-blue-500",
    },

    {
      icon: IoSettingsOutline,
      onClick: () => {},
      className: "text-blue-500",
    },
    {
      icon: MdOutlineFileDownload,
      onClick: () => {},
      className: "text-blue-500",
    },
    {
      icon: IoMdRefresh,

      onClick: () => {},
      rotate: true,
      className: "text-blue-500",
    },
  ],
  AccountStatus: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings") },
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download") },
    {
      icon: IoMdRefresh,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
    },
  ],

  AccountLogic: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true },
    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
    },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings") },
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download") },
    { icon: MdOutlineFileUpload, onClick: () => console.log("Upload") },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(),
      rotate: true,
    },
  ],
  screen2: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true },
    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
    },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings") },
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download") },
    { icon: MdOutlineFileUpload, onClick: () => console.log("Upload") },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(),
      rotate: true,
    },
  ],
  screen3: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true },
    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
    },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings") },
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download") },
    { icon: MdOutlineFileUpload, onClick: () => console.log("Upload") },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(),
      rotate: true,
    },
  ],
  screen4: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true },
    {
      icon: GiSettingsKnobs,
      onClick: () => console.log("Settings Knobs"),
      rotate: true,
    },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings") },
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download") },
    { icon: MdOutlineFileUpload, onClick: () => console.log("Upload") },
    {
      icon: IoMdRefresh,
      onClick: () => window.location.reload(),
      rotate: true,
    },
  ],
  screen5: [
    { icon: IoAddCircleOutline, onClick: () => {}, rotate: true },
    {
      icon: GiSettingsKnobs,
      onClick: () => {},
      rotate: true,
    },

    { icon: IoSettingsOutline, onClick: () => console.log("Settings") },
    { icon: MdOutlineFileDownload, onClick: () => console.log("Download") },
    { icon: MdOutlineFileUpload, onClick: () => console.log("Upload") },
    { icon: IoMdRefresh, onClick: () => console.log("Refresh"), rotate: true },
    // { icon: FaCheck, onClick: () => console.log("Check") },
    // { icon: IoClose, onClick: () => console.log("Close") },
    // { icon: FaRegEdit, onClick: () => console.log("Edit-frame") },
    // { icon: MdEdit, onClick: () => console.log("Edit") },
    // { icon: PiCopy, onClick: () => console.log("Copy") },
    // { icon: MdOutlineHistory, onClick: () => console.log("History") },
    // { icon: MdDelete, onClick: () => console.log("Delete") },
  ],
};
