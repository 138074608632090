import React, { useState, useEffect } from 'react';
import ReTable from '../../../../../components/Table';
import Header from '../../../../../components/Header';
import { useNavigate } from 'react-router-dom';
import AccountNumberLogicServices from '../../../../../rest-services/SetUpServices';

export default function AccountNumberLogic() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    // Fetch data from the service when the component mounts
    const fetchAccountNumberLogic = async () => {
      try {
        const response = await AccountNumberLogicServices.getAccountNumberLogic();
        const fetchedData = response.data.data.map(item => ({
          ...item,
          subRows: [] // Add empty subRows array to avoid undefined errors
        }));
        setData(fetchedData);
        console.log(fetchedData);
      } catch (error) {
        console.error('Error fetching account number logic:', error);
      }
    };

    fetchAccountNumberLogic();
  }, []);

  const columns = React.useMemo(
    () => [
      { Header: 'Account ID Generation Options', accessor: 'accountIdGenerationOptions', width: 300 },
      { Header: 'Multiple', accessor: 'multiple', width: 200 },
      { 
        Header: 'New Account Request', 
        accessor: 'newAccountRequest', 
        width: 150, 
        Cell: ({ value }) => (
          <input 
            type="radio" 
            name="newAccountRequest" 
            checked={Number(value) == 1}  // Make sure value is a number, and check against 1
            
          />
        )
      },
      { 
        Header: 'For Bank Account ID', 
        accessor: 'forBankAccountId', 
        width: 150, 
        Cell: ({ value }) => (
          <input 
            type="radio" 
            name="forBankAccountId" 
            checked={Number(value) == 1}  // Make sure value is a number, and check against 1
            
          />
        )
      }
    ],
    []
  );

  const handleSelectRow = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((row) => row !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    setSelectedRows(selectedRows.length === data.length ? [] : data.map((d) => d.id));
  };

  const handleRowDoubleClick = (id) => {
    console.log('Row double-clicked:', id);
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'BAM') {
      navigate('/bam');
    } else if (crumb.title === 'Set Up') {
      navigate('/bam');
    } else if (crumb.title === 'Account Number Logic') {
      navigate('/account-number-logic');
    } else if (crumb.path) {
      navigate(crumb.path); 
    }
  };

  return (
    <div>
      <div>
        <Header 
          title={"BAM > Set Up > Account Number Logic"} 
          onBreadcrumbClick={handleBreadcrumbClick} 
        />
      </div>

      <ReTable
        data={data}
        columns={columns}
        onSelectRow={handleSelectRow}
        selectedRows={selectedRows}
        onSelectAll={handleSelectAll}
        onRowDoubleClick={handleRowDoubleClick}
      />
    </div>
  );
}
