// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import {
//   GlobeIcon,
//   MapIcon,
//   LanguageIcon,
//   SourceCodeIcon,
// } from "../../../components/SVG/FigmaIcons";
// import { LuCalendarDays, LuMinimize2, LuUpload } from "react-icons/lu";
// import { HiOutlineCash, HiOutlineCurrencyDollar } from "react-icons/hi";
// import { CiBank } from "react-icons/ci";
// import Dashboard from "../../../components/Dashboard";
// import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
// import {
//   MdCurrencyYen,
//   MdMergeType,
//   MdOutlineGroups,
//   MdOutlinePentagon,
// } from "react-icons/md";
// import { FaCodeBranch, FaRegAddressCard, FaUserClock } from "react-icons/fa6";
// import { PiFolderUser, PiUserList } from "react-icons/pi";
// import { RiAccountBoxLine, RiAccountPinBoxLine } from "react-icons/ri";
// import { FaBalanceScaleLeft, FaExchangeAlt } from "react-icons/fa";
// import MainHeader from "../../../components/MainHeader";
// import { LiaUserClockSolid } from "react-icons/lia";
// import { IoIosGitMerge } from "react-icons/io";
// import { TbArrowsRightLeft } from "react-icons/tb";
// import { IoBrowsersOutline, IoIdCardOutline } from "react-icons/io5";
// import { GiBanknote } from "react-icons/gi";

// export default function CashBalance() {
//   const [subMenus, setSubMenus] = useState([]);
//   const [moduleAssignments, setModuleAssignments] = useState([]);
//   const [userId, setUserId] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Fetch user ID from session storage
//     const fetchUserId = () => {
//       const userDetailString = localStorage.getItem("userDetail");
//       if (userDetailString) {
//         const userDetail = JSON.parse(userDetailString);
//         setUserId(userDetail.id || null);
//       }
//     };

//     fetchUserId();

//     // Fetch sub-menu data
//     axios
//       .get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
//       .then((response) => {
//         const filteredMenus = response.data.data.filter(
//           (menu) => menu.mainMenuId === 8
//         );
//         setSubMenus(filteredMenus);
//       })
//       .catch((error) => console.error("Error fetching sub-menus:", error));

//     // Fetch module assignment data
//     axios
//       .get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
//       .then((response) => {
//         // Filter module assignments by userId
//         const filteredAssignments = response.data.filter(
//           (assignment) =>
//             assignment.userId === String(userId) &&
//             assignment.isChecked === true
//         );
//         setModuleAssignments(filteredAssignments);
//       })
//       .catch((error) =>
//         console.error("Error fetching module assignments:", error)
//       );
//   }, [userId]);

//   const handleCardClick = (route) => {
//     navigate(route);
//   };

//   // Filter subMenus based on moduleAssignments
//   const filteredSections = subMenus.filter((menu) => {
//     return moduleAssignments.some(
//       (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
//     );
//   });

//   // Define an order for submenus to control their display order
//   const submenuOrder = [
//     "Cash in Hand",
//     "Cash in Bank",
//     "Irregularities",
//   ];

//   // Sort the filteredSections by the defined submenuOrder
//   const orderedSections = filteredSections.sort((a, b) => {
//     return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
//   });

//   const sections = filteredSections.map((menu) => {
//     let cards;

//     if (menu.name === "Cash in Hand") {
//       cards = [
//         {
//           title: "Cash",
//           icon: <HiOutlineCash />,
//           onClick: () => handleCardClick("/cashscreen"),
//         },
//       ];
//     } else if (menu.name === "Cash in Bank") {
//       cards = [
//         {
//           title: "Account Portal",
//           icon: <HiOutlineBuildingOffice2 />,
//           onClick: () => handleCardClick("/account-portal"),
//         },
//         {
//           title: "Company Wise",
//           icon: <IoIosGitMerge/>,
//           onClick: () => handleCardClick("/company-wise-add"),
//         },
//         {
//           title: "Bank Wise",
//           icon: <IoIdCardOutline />,
//           onClick: () => handleCardClick("/bank-wise-add"),
//         },
//         {
//           title: "Country Wise",
//           icon: <CiBank />,
//           onClick: () => handleCardClick("/country-wise-add"),
//         },
//         {
//           title: "Transactions",
//           icon: <PiUserList/>,
//           onClick: () => handleCardClick("/transaction-wise-add"),
//         },
//         {
//           title: "OD Balances",
//           icon: <FaRegAddressCard/>,
//           onClick: () => handleCardClick("/od-balances-add"),
//         },{
//           title: "Currency Wise",
//           icon: <IoIosGitMerge/>,
//           onClick: () => handleCardClick("/currency-wise-add"),
//         },{
//           title: "Closing Avail. balance",
//           icon: <IoBrowsersOutline />,
//           onClick: () => handleCardClick("/closing-avail-balance-add"),
//         },
//       ];
//     } else if (menu.name === "Irregularities") {
//       cards = [
//         {
//           title: "Negative Balances",
//           icon: <FaBalanceScaleLeft />,
//           onClick: () => handleCardClick("/negative-balances-add"),
//         },
//         {
//           title: "Dormant",
//           icon: <MdMergeType />,
//           onClick: () => handleCardClick("/dormant-add"),
//         },
//         {
//           title: "Minimum",
//           icon: <LuMinimize2/>,
//           onClick: () => handleCardClick("/minimum-add"),
//         },
//         {
//           title: "BS-Yet to",
//           icon: <GiBanknote/>,
//           onClick: () => handleCardClick("/bs-yetto-add"),
//         },
//         {
//           title: "ZBA",
//           icon: <RiAccountPinBoxLine />,
//           onClick: () => handleCardClick("/zba-add"),
//         },
//       ];
//     } else {
//       // Optionally handle other cases or default cards
//       cards = [];
//     }

//     return {
//       title: menu.name,
//       subMenuId: menu.id,
//       cards: cards,
//     };
//   });

//   return (
//     <>
//       <div>
//         <MainHeader title={"Cash Balance"} />
//         <Dashboard sections={sections} /> {/* Use the Dashboard component */}
//       </div>
//     </>
//   );
// }


import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  GlobeIcon,
  MapIcon,
  LanguageIcon,
  SourceCodeIcon,
} from "../../../components/SVG/FigmaIcons";
import { LuCalendarDays, LuMinimize2, LuUpload } from "react-icons/lu";
import { HiOutlineCash, HiOutlineCurrencyDollar } from "react-icons/hi";
import { CiBank } from "react-icons/ci";
import Dashboard from "../../../components/Dashboard";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import {
  MdCurrencyYen,
  MdMergeType,
  MdOutlineGroups,
  MdOutlinePentagon,
} from "react-icons/md";
import { FaCodeBranch, FaRegAddressCard, FaUserClock } from "react-icons/fa6";
import { PiFolderUser, PiUserList } from "react-icons/pi";
import { RiAccountBoxLine, RiAccountPinBoxLine } from "react-icons/ri";
import { FaBalanceScaleLeft, FaExchangeAlt } from "react-icons/fa";
import MainHeader from "../../../components/MainHeader";
import { LiaUserClockSolid } from "react-icons/lia";
import { IoIosGitMerge } from "react-icons/io";
import { TbArrowsRightLeft } from "react-icons/tb";
import { IoBrowsersOutline, IoIdCardOutline } from "react-icons/io5";
import { GiBanknote } from "react-icons/gi";
import { useSearch } from "../../../context/SearchContext";

export default function CashBalance() {
  const [subMenus, setSubMenus] = useState([]);
  const [moduleAssignments, setModuleAssignments] = useState([]);
  const [userId, setUserId] = useState(null);
  const { highlightInfo } = useSearch();
  const [highlightedCard, setHighlightedCard] = useState(null);
  const navigate = useNavigate();

  // Handle search highlighting
  useEffect(() => {
    if (highlightInfo?.dashboard === 'cash-balances') {
      const term = highlightInfo.term.toLowerCase();
      const cardTitles = [
        "cash", "account portal", "company wise", "bank wise", "country wise",
        "transactions", "od balances", "currency wise", "closing avail balance",
        "negative balances", "dormant", "minimum", "bs-yet to", "zba"
      ];
      
      const matchedTitle = cardTitles.find(title => 
        title.toLowerCase().includes(term) || term.includes(title.toLowerCase())
      );

      if (matchedTitle) {
        setHighlightedCard(matchedTitle);
        const timer = setTimeout(() => {
          setHighlightedCard(null);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [highlightInfo]);

  useEffect(() => {
    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    // Fetch sub-menu data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(menu => menu.mainMenuId === 8);
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    // Fetch module assignment data
    if (userId) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
        .then((response) => {
          const filteredAssignments = response.data.filter(
            (assignment) =>
              assignment.userId === String(userId) && assignment.isChecked === true
          );
          setModuleAssignments(filteredAssignments);
        })
        .catch((error) => console.error("Error fetching module assignments:", error));
    }
  }, [userId]);

  const handleCardClick = (route) => {
    navigate(route);
  };

  // Move the sections creation logic to a separate function
  const createSections = (menus, highlighted) => {
    return menus.map((menu) => {
      let cards;

      if (menu.name === "Cash in Hand") {
        cards = [
          {
            title: "Cash",
            icon: <HiOutlineCash />,
            onClick: () => handleCardClick("/cashscreen"),
            isHighlighted: highlighted === "cash"
          }
        ];
      } else if (menu.name === "Cash in Bank") {
        cards = [
          {
            title: "Account Portal",
            icon: <HiOutlineBuildingOffice2 />,
            onClick: () => handleCardClick("/account-portal"),
            isHighlighted: highlighted === "account portal"
          },
          {
            title: "Company Wise",
            icon: <IoIosGitMerge />,
            onClick: () => handleCardClick("/company-wise-add"),
            isHighlighted: highlighted === "company wise"
          },
          {
            title: "Bank Wise",
            icon: <IoIdCardOutline />,
            onClick: () => handleCardClick("/bank-wise-add"),
            isHighlighted: highlighted === "bank wise"
          },
          {
            title: "Country Wise",
            icon: <CiBank />,
            onClick: () => handleCardClick("/country-wise-add"),
            isHighlighted: highlighted === "country wise"
          },
          {
            title: "Transactions",
            icon: <PiUserList />,
            onClick: () => handleCardClick("/transaction-wise-add"),
            isHighlighted: highlighted === "transactions"
          },
          {
            title: "OD Balances",
            icon: <FaRegAddressCard />,
            onClick: () => handleCardClick("/od-balances-add"),
            isHighlighted: highlighted === "od balances"
          },
          {
            title: "Currency Wise",
            icon: <IoIosGitMerge />,
            onClick: () => handleCardClick("/currency-wise-add"),
            isHighlighted: highlighted === "currency wise"
          },
          {
            title: "Closing Avail. balance",
            icon: <IoBrowsersOutline />,
            onClick: () => handleCardClick("/closing-avail-balance-add"),
            isHighlighted: highlighted === "closing avail balance"
          }
        ];
      } else if (menu.name === "Irregularities") {
        cards = [
          {
            title: "Negative Balances",
            icon: <FaBalanceScaleLeft />,
            onClick: () => handleCardClick("/negative-balances-add"),
            isHighlighted: highlighted === "negative balances"
          },
          {
            title: "Dormant",
            icon: <MdMergeType />,
            onClick: () => handleCardClick("/dormant-add"),
            isHighlighted: highlighted === "dormant"
          },
          {
            title: "Minimum",
            icon: <LuMinimize2 />,
            onClick: () => handleCardClick("/minimum-add"),
            isHighlighted: highlighted === "minimum"
          },
          {
            title: "BS-Yet to",
            icon: <GiBanknote />,
            onClick: () => handleCardClick("/bs-yetto-add"),
            isHighlighted: highlighted === "bs-yet to"
          },
          {
            title: "ZBA",
            icon: <RiAccountPinBoxLine />,
            onClick: () => handleCardClick("/zba-add"),
            isHighlighted: highlighted === "zba"
          }
        ];
      } else {
        cards = [];
      }

      return {
        title: menu.name,
        subMenuId: menu.id,
        cards: cards,
      };
    });
  };

  // Filter subMenus based on moduleAssignments
  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  // Define an order for submenus to control their display order
  const submenuOrder = [
    "Cash in Hand",
    "Cash in Bank",
    "Irregularities"
  ];

  // Sort the filteredSections by the defined submenuOrder
  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  // Create sections with current highlighted card
  const sections = createSections(orderedSections, highlightedCard);

  return (
    <div>
      <MainHeader title={"Cash Balance"} />
      <Dashboard sections={sections} />
    </div>
  );
}