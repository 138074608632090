import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import AdminActions from '../../../../Admin/Components/AdminActions';
import ReTable from '../../../../../../components/Table';
import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import UserService from '../../../../../../rest-services/UserServices';

export default function BAMMigrationTableScreen() {
     const navigate = useNavigate();
 const { iconId } = useParams();   
    const location = useLocation();
    const [assignee, setAssignee] = useState({ id: "", username: "" });
    const [alert, setAlert] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]); 
    const [processedData, setProcessedData] = useState([]);
    const { tableData, displayOption, uploadOption  } = location.state || {}; // Retrieve tableData from state
    const [loading, setLoading] = useState(false);

     const [bankBranchesGreen, setBankBranchesGreen] = useState([]);
      const [bankBranches, setBankBranches] = useState([]);
      const [currencies, setCurrencies] = useState([]);
      const [LGE, setLGE] = useState([]);
      const [LGEGreen, setLGEGreen] = useState([]);
      const [companies, setCompanies] = useState([]);
      const [companiesGreen, setCompaniesGreen] = useState([]);
      const [companiesGreenAssignToLGE, setCompaniesGreenAssignToLGE] = useState([]);
      const [accountTypes, setAccountTypes] = useState([]);
      const [zbaTypes, setZbaTypes] = useState([]);
      const [accClassifications, setAccClassifications] = useState([]);
      const [statementTypes, setStatementTypes] = useState([]);
      const [mainBankAccounts, setMainBankAccounts] = useState([]);
      const [mainBankAccountsGreen, setMainBankAccountsGreen] = useState([]);
    
          const[ibanRequired,setibanRequired] = useState([]);
      
      const [migrationSuccessfulRecords, setMigrationSuccessfulRecords] = useState([]);
      
      useEffect(() => {
              fetchAssignee();
              fetchBankBranchesGreenCode();
              fetchBankBranchesCode();
              fetchCurrencyCodes();
              fetchLGECodes();
              fetchLGEGreenCodes();
              fetchCompanyCodes();
              fetchCompanyGreenCodes();
              fetchCompanyGreenCodesAssignToLGE();
              fetchAccountTypeCodes();
              fetchZBATypeCodes();
              fetchAccountClassification();
              fetchStatementTypes();
              fetchMainBankAccnts();
              fetchMainBankAccntsGreen();

              fetchIBANRequiredData();
          }, []);

          const fetchAssignee = async () => {
            try {
              const userDetailString = localStorage.getItem("userDetail");
              if (userDetailString) {
                const userDetail = JSON.parse(userDetailString);
                const assigneeData = {
                  id: userDetail.id || "",
                  clientId: userDetail.clientId || "",
                  assigner: userDetail.username || "",
                  userId: userDetail.userId || "",
        
                };
        
                setAssignee(assigneeData);
        
              } else {
                setAlert({
                  severity: "error",
                  message: "No user detail found in session storage.",
                });
              }
            } catch (error) {
              setAlert({
                severity: "error",
                message: `Error fetching user details: ${error.message}`,
              });
            }
          };

          const fetchBankBranchesGreenCode = async () => {
            try {
              const response = await UserService.getUsers(
                `${process.env.REACT_APP_BASE_URL}/bam/get-Bank-Branch-green-code`
              );
              console.log("response. Bank Branch Green data",response.data.data);
              setBankBranchesGreen(response.data.data);
              // console.log("Set user types data", response.data.data);
            } catch (error) {
              console.error("Error fetching user types:", error);
            }
            };
          
            const fetchBankBranchesCode = async () => {
                try {
                  const response = await UserService.getUsers(
                    `${process.env.REACT_APP_BASE_URL}/bam/get-Bank-Branch-code`
                  );
                  console.log("response. Bank Branch data",response.data.data);
                  setBankBranches(response.data.data);
                  // console.log("Set user types data", response.data.data);
                } catch (error) {
                  console.error("Error fetching user types:", error);
                }
                };

                const fetchCurrencyCodes = async () => {
                    try {
                      const response = await UserService.getUsers(
                        `${process.env.REACT_APP_BASE_URL}/bam/get-all-Currency-code`
                      );
                      console.log("response. currency data",response.data.data);
                      setCurrencies(response.data.data);
                      // console.log("Set user types data", response.data.data);
                    } catch (error) {
                      console.error("Error fetching user types:", error);
                    }
                    };

                    const fetchLGEGreenCodes = async () => {
                        try {
                          const response = await UserService.getUsers(
                            `${process.env.REACT_APP_BASE_URL}/bam/get-LGE-green-code`
                          );
                          console.log("response. LGE data",response.data.data);
                          setLGEGreen(response.data.data);
                          // console.log("Set user types data", response.data.data);
                        } catch (error) {
                          console.error("Error fetching user types:", error);
                        }
                        };
                const fetchLGECodes = async () => {
                    try {
                      const response = await UserService.getUsers(
                        `${process.env.REACT_APP_BASE_URL}/bam/get-LGE-code`
                      );
                      console.log("response. LGE data",response.data.data);
                      setLGE(response.data.data);
                      // console.log("Set user types data", response.data.data);
                    } catch (error) {
                      console.error("Error fetching user types:", error);
                    }
                    };

                    const fetchCompanyGreenCodes = async () => {
                        try {
                          const response = await UserService.getUsers(
                            `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-green-code`
                          );
                          console.log("response. LGE data",response.data.data);
                          setCompaniesGreen(response.data.data);
                          // console.log("Set user types data", response.data.data);
                        } catch (error) {
                          console.error("Error fetching user types:", error);
                        }
                        };

                        const fetchCompanyGreenCodesAssignToLGE = async () => {
                          try {
                            const response = await UserService.getUsers(
                              `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-green-code-with-LGE`
                            );
                            console.log("response. company and LGE data",response.data.data);
                            setCompaniesGreenAssignToLGE(response.data.data);
                            // console.log("Set user types data", response.data.data);
                          } catch (error) {
                            console.error("Error fetching user types:", error);
                          }
                        };
                    const fetchCompanyCodes = async () => {
                        try {
                          const response = await UserService.getUsers(
                            `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-code`
                          );
                          console.log("response. Company data",response.data.data);
                          setCompanies(response.data.data);
                          // console.log("Set user types data", response.data.data);
                        } catch (error) {
                          console.error("Error fetching user types:", error);
                        }
                        };

                        const fetchZBATypeCodes  = async () => {
                            try {
                              const response = await UserService.getUsers(
                                `${process.env.REACT_APP_BASE_URL}/bam/get-all-ZBA-Types-code`
                              );
                              console.log("response. ZBA types data",response.data.data);
                              setZbaTypes(response.data.data);
                              // console.log("Set user types data", response.data.data);
                            } catch (error) {
                              console.error("Error fetching user types:", error);
                            }
                            };

                            const fetchAccountClassification = async () => {
                                try {
                                    const response = await UserService.getUsers(
                                      `${process.env.REACT_APP_BASE_URL}/bam/get-all-Account-Classification-code`
                                    );
                                    console.log("response. Acc classification data",response.data.data);
                                    setAccClassifications(response.data.data);
                                    // console.log("Set user types data", response.data.data);
                                  } catch (error) {
                                    console.error("Error fetching user types:", error);
                                  }
                            };

                            const fetchStatementTypes = async () => {
                                try {
                                    const response = await UserService.getUsers(
                                      `${process.env.REACT_APP_BASE_URL}/bam/get-all-Statement-Types-code`
                                    );
                                    console.log("response. Acc classification data",response.data.data);
                                    setStatementTypes(response.data.data);
                                    // console.log("Set user types data", response.data.data);
                                  } catch (error) {
                                    console.error("Error fetching user types:", error);
                                  }
                            };

                            const fetchMainBankAccnts = async () => {
                                try {
                                    const response = await UserService.getUsers(
                                      `${process.env.REACT_APP_BASE_URL}/bam/get-all-Main-Bank-Accounts-code`
                                    );
                                    console.log("response. Main Bank aacount data",response.data.data);
                                    setMainBankAccounts(response.data.data);
                                    // console.log("Set user types data", response.data.data);
                                  } catch (error) {
                                    console.error("Error fetching user types:", error);
                                  }
                            };

                            const fetchMainBankAccntsGreen = async () => {
                                try {
                                    const response = await UserService.getUsers(
                                      `${process.env.REACT_APP_BASE_URL}/bam/get-all-Main-Bank-Accounts-green-code`
                                    );
                                    console.log("response. Acc classification data",response.data.data);
                                    setMainBankAccountsGreen(response.data.data);
                                    // console.log("Set user types data", response.data.data);
                                  } catch (error) {
                                    console.error("Error fetching user types:", error);
                                  }
                            };

                            const fetchAccountTypeCodes = async () => {
                                                        try {
                                                          const response = await UserService.getUsers(
                                                            `${process.env.REACT_APP_BASE_URL}/bam/get-all-Account-Types-code`
                                                          );
                                                          console.log("response. Account types data",response.data.data);
                                                          setAccountTypes(response.data.data);
                                                          // console.log("Set user types data", response.data.data);
                                                        } catch (error) {
                                                          console.error("Error fetching user types:", error);
                                                        }
                                                        };

                            const fetchIBANRequiredData = async () => {
                              try {
                                  const response = await UserService.getUsers(
                                    `${process.env.REACT_APP_BASE_URL}/bam/get-IBAN-required-BankBranch-code`
                                  );
                                  console.log("response. iban with respective bank branch data",response.data);
                                  setibanRequired(response.data.data);
                                  // console.log("Set user types data", response.data.data);
                                } catch (error) {
                                  console.error("Error fetching user types:", error);
                                }
                                };
                                
    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === "BAM") {
          navigate("/bam");
        } else if (crumb.title === "Bank Master Data") {
          navigate("/3/BAM/26/account-master");
        } else if (crumb.title === "Account Master") {
          navigate("/3/BAM/26/account-master");
        } else if (crumb.path) {
          navigate(crumb.path);
        }
      };

       const existingButtons = AdminActionsConfig.screen7.map((button) => {
                let onClick;
                let title;
            
            
                if (button.icon === MdOutlineFileDownload) {
                //   onClick = handleDownload;
                  title = "Download File";
                }
            
                else {
                  onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
                  title = button.title || ""; // Use existing title or default to an empty string
                }
            
                return {
                  ...button,
                  onClick, // Reference the function without invoking it
                  title,   // Add the title property to the button configuration
                };
              });
                                      
              const extraButtons = [ ];
                                          
              const actionButtons = existingButtons.concat(
                extraButtons.map((button) => ({
                  ...button,
                  disabled: selectedRows.length === 0, // Disable button if no users are selected
                  onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
                }))
              );
                        
              const columns = [   
                                          
                                    { Header: 'Action', accessor: 'Action', visible: true, width: ("".length -1)*10, alignment: "left" },
                                    { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1)*10, alignment: "left" },
                                    {
                                      Header: 'Update',
                                      accessor: (row) => {
                                        if (row.successfulrecords && uploadOption === '3') {
                                          return 'Yes';
                                        } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                                          return 'No';
                                        } else {
                                          return <span style={{ color: 'red' }}>No</span>;
                                        }
                                      },
                                      visible: true,
                                      width: ("Update".length - 2) * 10,
                                      alignment: 'left',
                                    },
                                    { Header: 'BankBranch', accessor: 'BankBranch', visible: true, width: ("BankBranch".length -1)*10, alignment: "left" },
                                    { Header: 'CurrencyCode', accessor: 'CurrencyCode', visible: true, width: ("CurrencyCode".length -1)*10, alignment: "left" },
                                    { Header: 'Note', accessor: 'Note', visible: true, width: ("Note".length -1)*10, alignment: "left" },
                                    { Header: 'LGECode', accessor: 'LGECode', visible: true, width: ("LGECode".length -1)*10, alignment: "left" },
                                    { Header: 'CompanyCode', accessor: 'CompanyCode', visible: true, width: ("CompanyCode".length -1)*10, alignment: "left" },
                                    { Header: 'AccountType', accessor: 'AccountType', visible: true, width: ("AccountType".length -1)*10, alignment: "left" },
                                    { Header: 'ZBAType', accessor: 'ZBAType', visible: true, width: ("ZBAType".length -1)*10, alignment: "left" },
                                    { Header: 'AccountClassification', accessor: 'AccountClassification', visible: true, width: ("AccountClassification".length -1)*10, alignment: "left" },
                                    { Header: 'Overdraft', accessor: 'Overdraft', visible: true, width: ("Overdraft".length -1)*10, alignment: "left" },
                                    { Header: 'StatementType', accessor: 'StatementType', visible: true, width: ("StatementType".length -1)*10, alignment: "left" },
                                    { Header: 'ODLimit', accessor: 'ODLimit', visible: true, width: ("ODLimit".length -1)*10, alignment: "left" },
                                    { Header: 'InterestIndicator', accessor: 'InterestIndicator', visible: true, width: ("InterestIndicator".length -1)*10, alignment: "left" },
                                    { Header: 'ODDate', accessor: 'ODDate', visible: true, width: ("ODDate".length -1)*10, alignment: "left" },
                                    { Header: 'MinimumBalance', accessor: 'MinimumBalance', visible: true, width: ("MinimumBalance".length -1)*10, alignment: "left" },
                                    { Header: 'Dormant', accessor: 'Dormant', visible: true, width: ("Dormant".length -1)*10, alignment: "left" },
                                    { Header: 'MainBankAccount', accessor: 'MainBankAccount', visible: true, width: ("MainBankAccount".length -1)*10, alignment: "left" },
                                    { Header: 'Text1', accessor: 'Text1', visible: true, width: ("Text1".length -1)*10, alignment: "left" },
                                    { Header: 'Text2', accessor: 'Text2', visible: true, width: ("Text2".length -1)*10, alignment: "left" },
                                    { Header: 'Text3', accessor: 'Text3', visible: true, width: ("Text3".length -1)*10, alignment: "left" },
                                    { Header: 'Text4', accessor: 'Text4', visible: true, width: ("Text4".length -1)*10, alignment: "left" },
                                    { Header: 'Text5', accessor: 'Text5', visible: true, width: ("Text5".length -1)*10, alignment: "left" },
                                    { Header: 'BankAccountNo', accessor: 'BankAccountNo', visible: true, width: ("BankAccountNo".length -1)*10, alignment: "left" },
                                    { Header: 'BankAccountIdDescription', accessor: 'BankAccountIdDescription', visible: true, width: ("BankAccountIdDescription".length -1)*10, alignment: "left" },
                                    { Header: 'TextField', accessor: 'TextField', visible: true, width: ("TextField".length -1)*10, alignment: "left" },
                                    { Header: 'IBANNo', accessor: 'IBANNo', visible: true, width: ("IBANNo".length -1)*10, alignment: "left" },
            ];
                                                
              const [columnSettings, setColumnSettings] = useState(columns);       
                      
              const modifiedColumns = columnSettings
              .filter((col) => col.visible)
              .map((col) => ({
                ...col,
                width: col.width, // This should hold the updated width
              }));
        
              const MigrationSuccessOnlyProcessData = async (data) => {
                const successfulRecordsArray = [];
                
                 console.log("processData received  data:", data);
            
            //  return data.map((row, _, allRows) => {
                await Promise.all(data.map(async (row, _, allRows) => {
                                
                  const BankBranchExists = bankBranches
                  .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.BankBranch).trim().toUpperCase()); 
  
  
                  const BankBranchGreenExists = bankBranchesGreen
                  .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.BankBranch).trim().toUpperCase()); // Normalize row.CountryCode
  
                   const CurrencyExists = currencies
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.CurrencyCode).trim().toUpperCase()); 
  
            
                   
                  const LGEIDExists = LGE
                  .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                  .includes(String(row.LGECode).trim().toUpperCase()); // Normalize row.CountryCode
  
                   const LGEIDGreenExists = LGEGreen
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.LGECode).trim().toUpperCase()); // Normalize row.CountryCode
  
                   const CompanyExists = companies
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.CompanyCode).trim().toUpperCase()); // Normalize row.CountryCode
  
                   const CompanyGreenExists = companiesGreen
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.CompanyCode).trim().toUpperCase()); // Normalize row.CountryCode
  
                   // const companiesGreenAssignToLGEExists = companiesGreenAssignToLGE
                   // .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   // .includes(String(row.CompanyCode).trim().toUpperCase()); // Normalize row.CountryCode
  
                   const companiesGreenAssignToLGEExists = companiesGreenAssignToLGE.some(
                     (item) => 
                       String(item.companyCode).trim().toUpperCase() === String(row.CompanyCode).trim().toUpperCase() &&
                       String(item.legalEntityCode).trim().toUpperCase() === String(row.LGECode).trim().toUpperCase()
                   );                        
  
                   const AccountTypeExists = accountTypes
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.AccountType).trim().toUpperCase()); // Normalize row.CountryCode
  
                   const zbaTypesExists =zbaTypes
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.ZBAType).trim().toUpperCase());
  
                   const accClassificationsExists = accClassifications
                   .map(item => String(item).trim().toUpperCase() )
                   .includes(String(row.AccountClassification).trim().toUpperCase());
  
                   const StatementTypesExists = statementTypes
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.StatementType).trim().toUpperCase());
  
                   const MainBankAccountsExists= mainBankAccounts
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.MainBankAccount).trim().toUpperCase());
  
                   const MainBankAccountGreenExists = mainBankAccountsGreen
                   .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
                   .includes(String(row.MainBankAccount).trim().toUpperCase());
  
                  console.log(`BankBranch id ${row.BankBranch} exists:`, BankBranchGreenExists);
                  console.log(`Bank id ${row.BankId} exists:`, LGEIDExists);
           
                 
              // Check for errors (red text) and apply highlight based on that
            
              const hasNotError = [
                row.SLNo,
                row.Action,
                row.BankBranch,
                row.CurrencyCode,
                row.LGECode,
                row.CompanyCode,
                row.AccountType,
                row.ZBAType,
                row.AccountClassification,
                row.StatementType,
  
              ].every(field => {
                let isValid = true;
                let reason = "";
                
                if (field === row.SLNo) {
                  if (!field) { isValid = false; reason = "SLNo should not be blank."; }
                  else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
                  else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
                }
                
                if (!row.Action || String(row.Action).trim() === "") {
                  isValid = false;
                  reason = "Action should not be empty.";
                } else {
                  if (!["M", "m"].includes(row.Action)) { isValid = false; reason = "Action only allows 'M'."; }
                  else if (String(row.Action).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
                }
                
  
              if ((!row.BankBranch || String(row.BankBranch).trim() === "") && (row.Action === "M" || row.Action === "m")) {
                  isValid = false;
                  reason = "BankBranch should not be empty.";
              } else if (field === row.BankBranch) {
                  if (String(field).trim().length > 10) {
                      isValid = false;
                      reason = "BankBranch exceeds max length of 10.";
                  }
  
                    if(BankBranchExists){
                        if(!BankBranchGreenExists){
                            isValid = false;
                            reason = "BankBranch is not exists because of the flag status"
                        }
                    }
                    else{
                        isValid = false;
                        reason = "BankBranch is not exists"
                    }
                }
              
  
                if ((!row.CurrencyCode || String(row.CurrencyCode).trim() === "") && (row.Action === "M" || row.Action === "m")) {
                    isValid = false;
                    reason = "CurrencyCode should not be empty.";
                } else if (field === row.CurrencyCode) {
                      if (String(field).trim().length > 10) {
                          isValid = false;
                          reason = "CurrencyCode exceeds max length of 10.";
                      }
                      if(!CurrencyExists){
                        isValid = false;
                        reason = "CurrencyCode is not exists"
                    }
                  }
  
                  if ((!row.LGECode || String(row.LGECode).trim() === "") && (row.Action === "M" || row.Action === "m")) {
                    isValid = false;
                    reason = "LGECode should not be empty.";
                } else if (field === row.LGECode) {
                    if (String(field).trim().length > 10) {
                        isValid = false;
                        reason = "LGECode exceeds max length of 10.";
                    }
  
                      if(LGEIDExists){
                          if(!LGEIDGreenExists){
                              isValid = false;
                              reason = "LGECode is not exists because of the flag status"
                          }
                      }
                      else{
                          isValid = false;
                          reason = "LGECode is not exists"
                      }
                  }
                
  
                  if(row.CompanyCode){
                  if(field === row.CompanyCode){
                    if (String(field).trim().length > 10) {
                        isValid = false;
                        reason = "CompanyCode exceeds max length of 10.";
                    }
  
                    if(CompanyExists){
                        if(CompanyGreenExists){
                          if(!companiesGreenAssignToLGEExists){
                            isValid = false;
                            reason = "CompanyCode is not exists because of the LGE and company missmatched";
                        }
                        }
                        else{
                          isValid = false;
                          reason = "CompanyCode is not exists because of the flag status";
                      }
                    }
                    else{
                        isValid = false;
                        reason = "CompanyCode is not exists";
                    }
                  }
                }
  
                if(row.AccountType){
                  if(field === row.AccountType){
                    if (String(field).trim().length > 4) {
                        isValid = false;
                        reason = "AccountType exceeds max length of 4.";
                    }
  
                    if(!AccountTypeExists){
                        isValid = false;
                        reason = "AccountType is not exists"
                    }
                  }
                }
  
                    if(row.ZBAType){
                        if(field === row.ZBAType){
                            if (String(field).trim().length > 4) {
                                isValid = false;
                                reason = "ZBAType exceeds max length of 4.";
                            }
    
                            if(!zbaTypesExists){
                                isValid = false;
                                reason = "ZBAType is not exists"
                            }
                        }
                    }
  
  
                    if( (!row.MainBankAccount || String(row.MainBankAccount).trim() === '') && 
                    (row.Action === "M" || row.Action === "m") &&
                    (row.ZBAType && String(row.ZBAType).trim() !== '')) 
                    {
                        isValid = false;
                        reason = "MainBankAccount should not be empty.";
                    }
                    else if (row.MainBankAccount) {
                          if (String(row.MainBankAccount).trim().length > 13) {
                            isValid = false;
                            reason = "MainBankAccount exceeds max length of 13.";
                          } 
                           if (!MainBankAccountsExists) {
                            isValid = false;
                            reason = "MainBankAccount does not exist.";
                          }
                           else if (!MainBankAccountGreenExists) {
                            isValid = false;
                            reason = "MainBankAccount is not Approved.";
                          }
                        }
                   
                  if(row.AccountClassification){
                    if(field === row.AccountClassification){
                        if (String(field).trim().length > 4) {
                            isValid = false;
                            reason = "AccountClassification exceeds max length of 4.";
                        }
  
                        if(!accClassificationsExists){
                            isValid = false;
                            reason = "AccountClassification is not exists"
                        }
                      }
                  }
  
                  if(row.StatementType){
                        if(!StatementTypesExists){
                            isValid = false;
                            reason = "StatementType is not exists"
                        }
                  }
  
                  if(row.Overdraft && String(row.Overdraft).trim() === "Yes"){
  
                    if (!row.ODLimit || String(row.ODLimit).trim() === ""){
                        isValid = false;
                        reason = "ODLimit should not be empty.";
                    } else{
                        if(field === row.ODLimit){
                            if (String(field).trim().length > 20) {
                                isValid = false;
                                reason = "ODLimit exceeds max length of 20.";
                            }
                        }
                    }
  
                    if (!row.InterestIndicator || String(row.InterestIndicator).trim() === ""){
                        isValid = false;
                        reason = "InterestIndicator should not be empty.";
                    } else{
                        if(field === row.InterestIndicator){
                            if (String(field).trim().length > 2) {
                                isValid = false;
                                reason = "InterestIndicator exceeds max length of 2.";
                            }
                        }
                    }
  
                    if(!row.ODDate || String(row.ODDate).trim() === ""){
                        isValid = false;
                        reason = "ODDate should not be empty.";
                    } else{
                        if(field === row.ODDate){
                            if (String(field).trim().length > 10) {
                                isValid = false;
                                reason = "ODDate exceeds max length of 10.";
                            }
                        }
                    }
                }
  
                if(row.MinimumBalance){
                    if(field === row.MinimumBalance){
                        if (String(field).length > 20) { isValid = false; reason = "MinimumBalance exceeds max length of 20."; }
                    }
                }
  
                if(row.Dormant){
                    if(field === row.Dormant){
                        if (String(field).length > 2) { isValid = false; reason = "Dormant exceeds max length of 2."; }
                    }
                }
  
                if(row.Text1){
                    if(field === row.Text1){
                        if (String(field).length > 50) { isValid = false; reason = "Text1 exceeds max length of 50."; }
                    }
                }
                if(row.Text2){
                    if(field === row.Text2){
                        if (String(field).length > 50) { isValid = false; reason = "Text2 exceeds max length of 50."; }
                    }
                }if(row.Text3){
                    if(field === row.Text3){
                        if (String(field).length > 50) { isValid = false; reason = "Text3 exceeds max length of 50."; }
                    }
                }if(row.Text4){
                    if(field === row.Text4){
                        if (String(field).length > 50) { isValid = false; reason = "Text4 exceeds max length of 50."; }
                    }
                }if(row.Text5){
                    if(field === row.Text5){
                        if (String(field).length > 50) { isValid = false; reason = "Text5 exceeds max length of 50."; }
                    }
                }
                if (!isValid) {
                  console.log(`Validation Failed: ${reason}`);
                } else {
                  console.log(`Validation Passed: ${field}`);
                }
                
                return isValid;
              });
              
              
              if (hasNotError) {
                  successfulRecordsArray.push(row);
              }
            console.log(`Final hasNotError status: ${hasNotError}`);
            
                return {
                ...row,
                successfulrecords: hasNotError, // Mark as successful if no error
        
            
                Action: row.Action ? (
                  String(row.Action).length === 1 ? (
                    ["M", "m"].includes(row.Action) ? (
                      row.Action // Valid Action ("M" or "m")
                    ) : (
                      <span style={{ color: "red" }}>
                        {row.Action} (Only "M" are allowed)
                      </span>
                    )
                  ) : (
                    <span style={{ color: "red" }}>
                      {row.Action} (Max. allowed character is 1)
                    </span>
                  )
                ) : (
                  <span style={{ color: "red" }}>Shouldn't be blank</span>
                ),
          
                SLNo: row.SLNo ? (
                    isNaN(row.SLNo) ? (
                      <span style={{ color: "red"}}>
                        {row.SLNo} (Mismatched Data Type)
                      </span>
                    ) : String(row.SLNo).length > 5 ? (
                      <span style={{ color: "red"}}>
                        {row.SLNo} (Max. allowed character is 5)
                      </span>
                    ) : (
                      row.SLNo // Valid SLNo
                    )
                  ) : (
                    <span style={{ color: "red"}}>Shouldn't be blank</span>
                  ),
           
                  BankBranch: row.BankBranch ? (
                    String(row.BankBranch).length > 10 ? (
                      <span style={{ color: "red" }}>
                        {row.BankBranch} (Max. allowed characters: 10)
                      </span>
                    ) : BankBranchExists && displayOption !== "" ? (
                        BankBranchGreenExists ? (
                        <span>{row.BankBranch}</span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {row.BankBranch} (Bank Branch is not approved.)
                        </span>
                      )
                    ) :(
                      <span style={{ color: "red" }}>
                      {row.BankBranch} (Bank Branch not exists.)
                    </span>
                    ) )
                    :
                    (
                        <span style={{ color: "red" }}>
                          Shouldn't be blank
                        </span>
                    ),
          
                    CurrencyCode: row.CurrencyCode ? (
                        String(row.CurrencyCode).length > 10 ? (
                          <span style={{ color: "red" }}>
                            {row.CurrencyCode} (Max. allowed characters: 10)
                          </span>
                        ) : CurrencyExists && displayOption !== "" ? (
                            <span>{row.CurrencyCode}</span>
                        ) :(
                          <span style={{ color: "red" }}>
                          {row.CurrencyCode} (Currency Code not exists.)
                        </span>
                        ) )
                        :
                        (
                            <span style={{ color: "red" }}>
                              Shouldn't be blank
                            </span>
                        ),
              
                        LGECode: row.LGECode ? (
                            String(row.LGECode).length > 10 ? (
                              <span style={{ color: "red" }}>
                                {row.LGECode} (Max. allowed characters: 10)
                              </span>
                            ) : LGEIDExists && displayOption !== "" ? (
                                LGEIDGreenExists ? (
                                <span>{row.LGECode}</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {row.LGECode} (LGE is not approved.)
                                </span>
                              )
                            ) :(
                              <span style={{ color: "red" }}>
                              {row.LGECode} (LGE not exists.)
                            </span>
                            ) )
                            :
                            (
                                <span style={{ color: "red" }}>
                                  Shouldn't be blank
                                </span>
                            ),
                   
                            CompanyCode: row.CompanyCode ? (
                                String(row.CompanyCode).length > 10 ? (
                                  <span style={{ color: "red" }}>
                                    {row.CompanyCode} (Max. allowed characters: 10)
                                  </span>
                                ) : CompanyExists && displayOption !== "" ? (
                                    CompanyGreenExists ? (
                                      companiesGreenAssignToLGEExists ? (
                                    <span>{row.CompanyCode}</span>
                                  ): (
                                    <span style={{ color: "red" }}>
                                      {row.CompanyCode} (Company Code is missmatched.)
                                    </span>
                                  )
                                )  : (
                                  <span style={{ color: "red" }}>
                                    {row.CompanyCode} (Company is not approved.)
                                  </span>
                                ) )
                                :(
                                  <span style={{ color: "red" }}>
                                  {row.CompanyCode} (Company not exists.)
                                </span>
                                ) )
                                :
                                (
                                    <span>
                                    
                                    </span>
                                ),

                                AccountType: row.AccountType ? (
                                    String(row.AccountType).length > 4 ? (
                                      <span style={{ color: "red" }}>
                                        {row.AccountType} (Max. allowed characters: 4)
                                      </span>
                                    ) : AccountTypeExists && displayOption !== "" ? (
                                      <span>{row.AccountType}</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {row.AccountType} (AccountType not exists.)
                                      </span>
                                    )
                                  ) : (
                                    <span></span>
                                  ),

                                  ZBAType: row.ZBAType ? (
                                    String(row.ZBAType).length > 4 ? (
                                      <span style={{ color: "red" }}>
                                        {row.ZBAType} (Max. allowed characters: 4)
                                      </span>
                                    ) : zbaTypesExists && displayOption !== "" ? (
                                      <span>{row.ZBAType}</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {row.ZBAType} (ZBAType not exists.)
                                      </span>
                                    )
                                  ) : (
                                    <span></span>
                                  ),

                                  AccountClassification: row.AccountClassification ? (
                                    String(row.AccountClassification).length > 4 ? (
                                      <span style={{ color: "red" }}>
                                        {row.AccountClassification} (Max. allowed characters: 4)
                                      </span>
                                    ) : accClassificationsExists && displayOption !== "" ? (
                                      <span>{row.AccountClassification}</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {row.AccountClassification} (AccountClassification not exists.)
                                      </span>
                                    )
                                  ) : (
                                    <span></span>
                                  ),


                                  StatementType: row.StatementType ? (
                                     StatementTypesExists && displayOption !== "" ? (
                                      <span>{row.StatementType}</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {row.StatementType} (StatementType not exists.)
                                      </span>
                                    )
                                  ) : (
                                    <span></span>
                                  ),
                                  

                                  ODLimit: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                                    row.ODLimit ? (
                                        String(row.ODLimit).length > 20 ? (
                                            <span style={{ color: "red"}}>
                                                {row.ODLimit} (Max. allowed character is 20)
                                            </span>
                                        ) : (
                                            String(row.ODLimit)
                                        )
                                    ) : (
                                        <span style={{ color: "red" }}>
                                            Shouldn't be blank
                                        </span>
                                    )
            
                                ) : (
                                    row.ODLimit ? (
                                        String(row.ODLimit).length > 20 ? (
                                            <span style={{ color: "red"}}>
                                                {row.ODLimit} (Max. allowed character is 20)
                                            </span>
                                        ) : (
                                            String(row.ODLimit)
                                        )
                                    ): (
                                        <span>
                                        </span>
                                    ) ),
                                

                                    InterestIndicator: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                                        row.InterestIndicator ? (
                                            String(row.InterestIndicator).length > 2 ? (
                                                <span style={{ color: "red"}}>
                                                    {row.InterestIndicator} (Max. allowed character is 2)
                                                </span>
                                            ) : (
                                                String(row.InterestIndicator)
                                            )
                                        ) : (
                                            <span style={{ color: "red" }}>
                                                Shouldn't be blank
                                            </span>
                                        )
                
                                    ) : (
                                        row.InterestIndicator ? (
                                            String(row.InterestIndicator).length > 2 ? (
                                                <span style={{ color: "red"}}>
                                                    {row.InterestIndicator} (Max. allowed character is 2)
                                                </span>
                                            ) : (
                                                String(row.InterestIndicator)
                                            )
                                        ): (
                                            <span>
                                            </span>
                                        ) ),
                                    
                                        ODDate: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                                            row.ODDate ? (
                                                String(row.ODDate).length > 10 ? (
                                                    <span style={{ color: "red"}}>
                                                        {row.ODDate} (Max. allowed character is 10)
                                                    </span>
                                                ) : (
                                                    String(row.ODDate)
                                                )
                                            ) : (
                                                <span style={{ color: "red" }}>
                                                    Shouldn't be blank
                                                </span>
                                            )
                                        ) : (
                                            row.ODDate ? (
                                                String(row.ODDate).length > 10 ? (
                                                    <span style={{ color: "red"}}>
                                                        {row.ODDate} (Max. allowed character is 10)
                                                    </span>
                                                ) : (
                                                    String(row.ODDate)
                                                )
                                            ): (
                                                <span>
                                                </span>
                                            ) ),

                                            MinimumBalance: row.MinimumBalance ? (
                                                String(row.MinimumBalance).length > 20 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.MinimumBalance} (Max. allowed characters: 20)
                                                  </span>
                                                ) :(
                                                     <span>{row.MinimumBalance}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              MainBankAccount: row.ZBAType && String(row.ZBAType).trim() !== "" ? (
                                                row.MainBankAccount ? (
                                                    String(row.MainBankAccount).length > 13 ? (
                                                        <span style={{ color: "red"}}>
                                                            {row.MainBankAccount} (Max. allowed character is 13)
                                                        </span>
                                                    ) : MainBankAccountsExists && displayOption !== "" ? (
                                                        MainBankAccountGreenExists ? (
                                                            String(row.MainBankAccount)
                                                        ) : (
                                                            <span style={{ color: "red" }}>
                                                              {row.MainBankAccount} (Main Bank Account is not approved.)
                                                            </span>
                                                          )  
                                                        ) : (
                                                            <span style={{ color: "red" }}>
                                                            {row.MainBankAccount} (Main Bank Account not exists.)
                                                          </span>
                                                    )

                                                ) : (
                                                    <span style={{ color: "red" }}>
                                                        Shouldn't be blank
                                                    </span>
                                                )
                                              ):(
                                                row.MainBankAccount ? (
                                                    String(row.MainBankAccount).length > 13 ? (
                                                        <span style={{ color: "red"}}>
                                                            {row.MainBankAccount} (Max. allowed character is 13)
                                                        </span>
                                                    ) : (
                                                         MainBankAccountsExists && displayOption !== "" ? (
                                                        MainBankAccountGreenExists ? (
                                                            String(row.MainBankAccount)
                                                        ) : (
                                                            <span style={{ color: "red" }}>
                                                              {row.MainBankAccount} (Main Bank Account is not approved.)
                                                            </span>
                                                          )  
                                                        ) : (
                                                            <span style={{ color: "red" }}>
                                                            {row.MainBankAccount} (Main Bank Account not exists.)
                                                          </span>
                                                    )
                                                    )
                                                ) : (
                                                    <span>
                                                    </span>
                                                ) 
                                              ),


                                              Dormant: row.Dormant ? (
                                                String(row.Dormant).length > 2 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Dormant} (Max. allowed characters: 2)
                                                  </span>
                                                ) :(
                                                     <span>{row.Dormant}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text1: row.Text1 ? (
                                                String(row.Text1).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text1} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text1}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text2: row.Text2 ? (
                                                String(row.Text2).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text2} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text2}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text3: row.Text3 ? (
                                                String(row.Text3).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text3} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text3}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text4: row.Text4 ? (
                                                String(row.Text4).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text4} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text4}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text5: row.Text5 ? (
                                                String(row.Text5).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text5} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text5}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),
                };  
            }));
            setMigrationSuccessfulRecords(successfulRecordsArray);
            console.log("successfulRecordsArray data",successfulRecordsArray);
            return successfulRecordsArray;
        
            }

              const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
                let dataToDisplay;
                if (displayOption === "1") {
                let processed=[];

                if(bankBranchesGreen && bankBranches && currencies && LGE && LGEGreen && companies && companiesGreen && companiesGreenAssignToLGE &&accountTypes && zbaTypes && accClassifications && statementTypes && mainBankAccounts && mainBankAccountsGreen && ibanRequired){
                  console.log("data to display table data 2",dataToDisplay);
  
                  processed = await MigrationSuccessOnlyProcessData(dataToDisplay);
                  console.log("Process data to disply in the table",processed);
                }
               
                // Convert existingUsers to a Set for faster lookup based on a unique identifier (e.g., SLNo)
                const existingUserSet = new Set(processed.map(row => JSON.stringify(row)));

                // console.log("Exisitng row list data",existingUserSet);

                // Filter tableData to get the non-existing rows
                const nonExistingRows = tableData.filter(row => !existingUserSet.has(JSON.stringify(row)));

                // console.log("Non-existing rows", nonExistingRows);

                dataToDisplay =nonExistingRows;                 
              
                } else if (displayOption === "2") {
                  dataToDisplay = tableData; // Display tableData if displayOption is "2"
                } else if (uploadOption === "3") {
                  dataToDisplay = migrationSuccessfulRecords; 
                } else {
                  dataToDisplay = tableData; // Default to tableData if no conditions are met
                }
              
              // console.log("dataToDisplay data is",dataToDisplay);
              return dataToDisplay;
              };

              const MigrationFullprocessData = async (data) => {
                const successfulRecordsArray = [];
                
                console.log("processData received  data:", data);
            
            //  return data.map((row, _, allRows) => {
              const results = await Promise.all(data.map(async (row, _, allRows) => {

                 const normalize = (value) => String(value).trim().toUpperCase();
                
                 const BankBranchExists = bankBranches.map(normalize).includes(normalize(row.BankBranch));
                 const BankBranchGreenExists = bankBranchesGreen.map(normalize).includes(normalize(row.BankBranch));
                 const CurrencyExists = currencies.map(normalize).includes(normalize(row.CurrencyCode));
                 const LGEIDExists = LGE.map(normalize).includes(normalize(row.LGECode));
                 const LGEIDGreenExists = LGEGreen.map(normalize).includes(normalize(row.LGECode));
                 const CompanyExists = companies.map(normalize).includes(normalize(row.CompanyCode));
                 const CompanyGreenExists = companiesGreen.map(normalize).includes(normalize(row.CompanyCode));
                 const AccountTypeExists = accountTypes.map(normalize).includes(normalize(row.AccountType));
                 const zbaTypesExists =zbaTypes.map(normalize).includes(normalize(row.ZBAType));
                 const accClassificationsExists = accClassifications.map(normalize).includes(normalize(row.AccountClassification));
                 const StatementTypesExists = statementTypes.map(normalize).includes(normalize(row.StatementType));
                 const MainBankAccountsExists= mainBankAccounts.map(normalize).includes(normalize(row.MainBankAccount));
                 const MainBankAccountGreenExists = mainBankAccountsGreen.map(normalize).includes(normalize(row.MainBankAccount));
                 
                 const companiesGreenAssignToLGEExists = companiesGreenAssignToLGE.some(
                  (item) => 
                    String(item.companyCode).trim().toUpperCase() === String(row.CompanyCode).trim().toUpperCase() &&
                    String(item.legalEntityCode).trim().toUpperCase() === String(row.LGECode).trim().toUpperCase()
                );  

                 const ibanRequiredExists = ibanRequired.map(normalize).includes(normalize(row.BankBranch));
           

                // console.log(`BankBranch id ${row.BankBranch} exists:`, BankBranchGreenExists);
                // console.log(`Bank id ${row.BankId} exists:`, LGEIDExists);
         
               
            // Check for errors (red text) and apply highlight based on that
          
            const hasNotError = [
              row.SLNo,
              row.Action,
              row.BankBranch,
              row.CurrencyCode,
              row.LGECode,
              row.CompanyCode,
              row.AccountType,
              row.ZBAType,
              row.AccountClassification,
              row.StatementType,
              row.IBANNo


            ].every(field => {
              let isValid = true;
              let reason = "";
              
              if (field === row.SLNo) {
                if (!field) { isValid = false; reason = "SLNo should not be blank."; }
                else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
                else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
              }
              
              if (!row.Action || String(row.Action).trim() === "") {
                isValid = false;
                reason = "Action should not be empty.";
              } else {
                if (!["M", "m"].includes(row.Action)) { isValid = false; reason = "Action only allows 'M'."; }
                else if (String(row.Action).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
              }
              

            if ((!row.BankBranch || String(row.BankBranch).trim() === "") && (row.Action === "M" || row.Action === "m")) {
                isValid = false;
                reason = "BankBranch should not be empty.";
            } else if (field === row.BankBranch) {
                if (String(field).trim().length > 10) {
                    isValid = false;
                    reason = "BankBranch exceeds max length of 10.";
                }

                  if(BankBranchExists){
                      if(!BankBranchGreenExists){
                          isValid = false;
                          reason = "BankBranch is not exists because of the flag status"
                      }
                  }
                  else{
                      isValid = false;
                      reason = "BankBranch is not exists"
                  }
              }
            

              if ((!row.CurrencyCode || String(row.CurrencyCode).trim() === "") && (row.Action === "M" || row.Action === "m")) {
                  isValid = false;
                  reason = "CurrencyCode should not be empty.";
              } else if (field === row.CurrencyCode) {
                    if (String(field).trim().length > 10) {
                        isValid = false;
                        reason = "CurrencyCode exceeds max length of 10.";
                    }
                    if(!CurrencyExists){
                      isValid = false;
                      reason = "CurrencyCode is not exists"
                  }
                }

                if ((!row.LGECode || String(row.LGECode).trim() === "") && (row.Action === "M" || row.Action === "m")) {
                  isValid = false;
                  reason = "LGECode should not be empty.";
              } else if (field === row.LGECode) {
                  if (String(field).trim().length > 10) {
                      isValid = false;
                      reason = "LGECode exceeds max length of 10.";
                  }

                    if(LGEIDExists){
                        if(!LGEIDGreenExists){
                            isValid = false;
                            reason = "LGECode is not exists because of the flag status"
                        }
                    }
                    else{
                        isValid = false;
                        reason = "LGECode is not exists"
                    }
                }
              

                if(row.CompanyCode){
                if(field === row.CompanyCode){
                  if (String(field).trim().length > 10) {
                      isValid = false;
                      reason = "CompanyCode exceeds max length of 10.";
                  }

                  if(CompanyExists){
                      if(CompanyGreenExists){
                        if(!companiesGreenAssignToLGEExists){
                          isValid = false;
                          reason = "CompanyCode is not exists because of the LGE and company missmatched";
                      }
                      }
                      else{
                        isValid = false;
                        reason = "CompanyCode is not exists because of the flag status";
                    }
                  }
                  else{
                      isValid = false;
                      reason = "CompanyCode is not exists";
                  }
                }
              }

              if(row.AccountType){
                if(field === row.AccountType){
                  if (String(field).trim().length > 4) {
                      isValid = false;
                      reason = "AccountType exceeds max length of 4.";
                  }

                  if(!AccountTypeExists){
                      isValid = false;
                      reason = "AccountType is not exists"
                  }
                }
              }

                  if(row.ZBAType){
                      if(field === row.ZBAType){
                          if (String(field).trim().length > 4) {
                              isValid = false;
                              reason = "ZBAType exceeds max length of 4.";
                          }
  
                          if(!zbaTypesExists){
                              isValid = false;
                              reason = "ZBAType is not exists"
                          }
                      }
                  }


                  if( (!row.MainBankAccount || String(row.MainBankAccount).trim() === '') && 
                  (row.Action === "M" || row.Action === "m") &&
                  (row.ZBAType && String(row.ZBAType).trim() !== '')) 
                  {
                      isValid = false;
                      reason = "MainBankAccount should not be empty.";
                  }
                  else if (row.MainBankAccount) {
                        if (String(row.MainBankAccount).trim().length > 13) {
                          isValid = false;
                          reason = "MainBankAccount exceeds max length of 13.";
                        } 
                         if (!MainBankAccountsExists) {
                          isValid = false;
                          reason = "MainBankAccount does not exist.";
                        }
                         else if (!MainBankAccountGreenExists) {
                          isValid = false;
                          reason = "MainBankAccount is not Approved.";
                        }
                      }
                 
                if(row.AccountClassification){
                  if(field === row.AccountClassification){
                      if (String(field).trim().length > 4) {
                          isValid = false;
                          reason = "AccountClassification exceeds max length of 4.";
                      }

                      if(!accClassificationsExists){
                          isValid = false;
                          reason = "AccountClassification is not exists"
                      }
                    }
                }

                if(row.StatementType){
                      if(!StatementTypesExists){
                          isValid = false;
                          reason = "StatementType is not exists"
                      }
                }

                if(row.Overdraft && String(row.Overdraft).trim() === "Yes"){

                  if (!row.ODLimit || String(row.ODLimit).trim() === ""){
                      isValid = false;
                      reason = "ODLimit should not be empty.";
                  } else{
                      if(field === row.ODLimit){
                          if (String(field).trim().length > 20) {
                              isValid = false;
                              reason = "ODLimit exceeds max length of 20.";
                          }
                      }
                  }

                  if (!row.InterestIndicator || String(row.InterestIndicator).trim() === ""){
                      isValid = false;
                      reason = "InterestIndicator should not be empty.";
                  } else{
                      if(field === row.InterestIndicator){
                          if (String(field).trim().length > 2) {
                              isValid = false;
                              reason = "InterestIndicator exceeds max length of 2.";
                          }
                      }
                  }

                  if(!row.ODDate || String(row.ODDate).trim() === ""){
                      isValid = false;
                      reason = "ODDate should not be empty.";
                  } else{
                      if(field === row.ODDate){
                          if (String(field).trim().length > 10) {
                              isValid = false;
                              reason = "ODDate exceeds max length of 10.";
                          }
                      }
                  }
              }

              if(row.MinimumBalance){
                  if(field === row.MinimumBalance){
                      if (String(field).length > 20) { isValid = false; reason = "MinimumBalance exceeds max length of 20."; }
                  }
              }

              if(row.Dormant){
                  if(field === row.Dormant){
                      if (String(field).length > 2) { isValid = false; reason = "Dormant exceeds max length of 2."; }
                  }
              }

              if(row.Text1){
                  if(field === row.Text1){
                      if (String(field).length > 50) { isValid = false; reason = "Text1 exceeds max length of 50."; }
                  }
              }
              if(row.Text2){
                  if(field === row.Text2){
                      if (String(field).length > 50) { isValid = false; reason = "Text2 exceeds max length of 50."; }
                  }
              }if(row.Text3){
                  if(field === row.Text3){
                      if (String(field).length > 50) { isValid = false; reason = "Text3 exceeds max length of 50."; }
                  }
              }if(row.Text4){
                  if(field === row.Text4){
                      if (String(field).length > 50) { isValid = false; reason = "Text4 exceeds max length of 50."; }
                  }
              }if(row.Text5){
                  if(field === row.Text5){
                      if (String(field).length > 50) { isValid = false; reason = "Text5 exceeds max length of 50."; }
                  }
              }

              if ((!row.BankAccountNo || String(row.BankAccountNo).trim() === "") && (row.Action === "M" || row.Action === "m")) {
                isValid = false;
                reason = "BankAccountNo should not be empty.";
            } else if(row.BankAccountNo){
                if(field === row.BankAccountNo){
                    if (String(field).trim().length > 25) {
                        isValid = false;
                        reason = "BankAccountNo exceeds max length of 25.";
                    }
                  }
              }
        
        
              if ((!row.BankAccountIdDescription || String(row.BankAccountIdDescription).trim() === "")  && (row.Action === "M" || row.Action === "m")) {
                isValid = false;
                reason = "BankAccountIdDescription should not be empty.";
            } else if(row.BankAccountIdDescription){
                if(field === row.BankAccountIdDescription){
                    if (String(field).trim().length > 30) {
                        isValid = false;
                        reason = "BankAccountIdDescription exceeds max length of 30.";
                    }
                  }
              }
        
        
              if(row.TextField){
                if(field === row.TextField){
                    if (String(field).length > 30) { isValid = false; reason = "TextField exceeds max length of 30."; }
                }
            }

              if ((!row.IBANNo || String(row.IBANNo).trim() === "") && (row.Action === "M" || row.Action === "m") && ibanRequiredExists) {
                isValid = false;
                reason = "IBANNo should not be empty.";
            } else if(row.IBANNo){
                if(field === row.IBANNo){
                    if (String(field).trim().length > 50) {
                        isValid = false;
                        reason = "IBANNo exceeds max length of 50.";
                    }
                  }
              }

              if (!isValid) {
                console.log(`Validation Failed: ${reason}`);
              } else {
                console.log(`Validation Passed: ${field}`);
              }
              
              return isValid;
            });
            
            
            if (hasNotError) {
                successfulRecordsArray.push(row);
            }
            console.log(`Final hasNotError status: ${hasNotError}`);
            
                return {
                ...row,
                successfulrecords: hasNotError, // Mark as successful if no error
          
            
                Action: row.Action ? (
                  String(row.Action).length === 1 ? (
                    ["M", "m"].includes(row.Action) ? (
                      row.Action // Valid Action ("M" or "m")
                    ) : (
                      <span style={{ color: "red" }}>
                        {row.Action} (Only "M" are allowed)
                      </span>
                    )
                  ) : (
                    <span style={{ color: "red" }}>
                      {row.Action} (Max. allowed character is 1)
                    </span>
                  )
                ) : (
                  <span style={{ color: "red" }}>Shouldn't be blank</span>
                ),
          
                SLNo: row.SLNo ? (
                    isNaN(row.SLNo) ? (
                      <span style={{ color: "red"}}>
                        {row.SLNo} (Mismatched Data Type)
                      </span>
                    ) : String(row.SLNo).length > 5 ? (
                      <span style={{ color: "red"}}>
                        {row.SLNo} (Max. allowed character is 5)
                      </span>
                    ) : (
                      row.SLNo // Valid SLNo
                    )
                  ) : (
                    <span style={{ color: "red"}}>Shouldn't be blank</span>
                  ),
           
                  BankBranch: row.BankBranch ? (
                    String(row.BankBranch).length > 10 ? (
                      <span style={{ color: "red" }}>
                        {row.BankBranch} (Max. allowed characters: 10)
                      </span>
                    ) : BankBranchExists && displayOption !== "" ? (
                        BankBranchGreenExists ? (
                        <span>{row.BankBranch}</span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {row.BankBranch} (Bank Branch is not approved.)
                        </span>
                      )
                    ) :(
                      <span style={{ color: "red" }}>
                      {row.BankBranch} (Bank Branch not exists.)
                    </span>
                    ) )
                    :
                    (
                        <span style={{ color: "red" }}>
                          Shouldn't be blank
                        </span>
                    ),
          
                    CurrencyCode: row.CurrencyCode ? (
                        String(row.CurrencyCode).length > 10 ? (
                          <span style={{ color: "red" }}>
                            {row.CurrencyCode} (Max. allowed characters: 10)
                          </span>
                        ) : CurrencyExists && displayOption !== "" ? (
                            <span>{row.CurrencyCode}</span>
                        ) :(
                          <span style={{ color: "red" }}>
                          {row.CurrencyCode} (Currency Code not exists.)
                        </span>
                        ) )
                        :
                        (
                            <span style={{ color: "red" }}>
                              Shouldn't be blank
                            </span>
                        ),
              
                        LGECode: row.LGECode ? (
                            String(row.LGECode).length > 10 ? (
                              <span style={{ color: "red" }}>
                                {row.LGECode} (Max. allowed characters: 10)
                              </span>
                            ) : LGEIDExists && displayOption !== "" ? (
                                LGEIDGreenExists ? (
                                <span>{row.LGECode}</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {row.LGECode} (LGE is not approved.)
                                </span>
                              )
                            ) :(
                              <span style={{ color: "red" }}>
                              {row.LGECode} (LGE not exists.)
                            </span>
                            ) )
                            :
                            (
                                <span style={{ color: "red" }}>
                                  Shouldn't be blank
                                </span>
                            ),
                   
                            CompanyCode: row.CompanyCode ? (
                                String(row.CompanyCode).length > 10 ? (
                                  <span style={{ color: "red" }}>
                                    {row.CompanyCode} (Max. allowed characters: 10)
                                  </span>
                                ) : CompanyExists && displayOption !== "" ? (
                                    CompanyGreenExists ? (
                                      companiesGreenAssignToLGEExists ? (
                                    <span>{row.CompanyCode}</span>
                                  ): (
                                    <span style={{ color: "red" }}>
                                      {row.CompanyCode} (Company Code is missmatched.)
                                    </span>
                                  )
                                )  : (
                                  <span style={{ color: "red" }}>
                                    {row.CompanyCode} (Company is not approved.)
                                  </span>
                                ) )
                                :(
                                  <span style={{ color: "red" }}>
                                  {row.CompanyCode} (Company not exists.)
                                </span>
                                ) )
                                :
                                (
                                    <span>
                                    
                                    </span>
                                ),

                                AccountType: row.AccountType ? (
                                    String(row.AccountType).length > 4 ? (
                                      <span style={{ color: "red" }}>
                                        {row.AccountType} (Max. allowed characters: 4)
                                      </span>
                                    ) : AccountTypeExists && displayOption !== "" ? (
                                      <span>{row.AccountType}</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {row.AccountType} (AccountType not exists.)
                                      </span>
                                    )
                                  ) : (
                                    <span></span>
                                  ),

                                  ZBAType: row.ZBAType ? (
                                    String(row.ZBAType).length > 4 ? (
                                      <span style={{ color: "red" }}>
                                        {row.ZBAType} (Max. allowed characters: 4)
                                      </span>
                                    ) : zbaTypesExists && displayOption !== "" ? (
                                      <span>{row.ZBAType}</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {row.ZBAType} (ZBAType not exists.)
                                      </span>
                                    )
                                  ) : (
                                    <span></span>
                                  ),

                                  AccountClassification: row.AccountClassification ? (
                                    String(row.AccountClassification).length > 4 ? (
                                      <span style={{ color: "red" }}>
                                        {row.AccountClassification} (Max. allowed characters: 4)
                                      </span>
                                    ) : accClassificationsExists && displayOption !== "" ? (
                                      <span>{row.AccountClassification}</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {row.AccountClassification} (AccountClassification not exists.)
                                      </span>
                                    )
                                  ) : (
                                    <span></span>
                                  ),


                                  StatementType: row.StatementType ? (
                                     StatementTypesExists && displayOption !== "" ? (
                                      <span>{row.StatementType}</span>
                                    ) : (
                                      <span style={{ color: "red" }}>
                                        {row.StatementType} (StatementType not exists.)
                                      </span>
                                    )
                                  ) : (
                                    <span></span>
                                  ),
                                  

                                  ODLimit: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                                    row.ODLimit ? (
                                        String(row.ODLimit).length > 20 ? (
                                            <span style={{ color: "red"}}>
                                                {row.ODLimit} (Max. allowed character is 20)
                                            </span>
                                        ) : (
                                            String(row.ODLimit)
                                        )
                                    ) : (
                                        <span style={{ color: "red" }}>
                                            Shouldn't be blank
                                        </span>
                                    )
            
                                ) : (
                                    row.ODLimit ? (
                                        String(row.ODLimit).length > 20 ? (
                                            <span style={{ color: "red"}}>
                                                {row.ODLimit} (Max. allowed character is 20)
                                            </span>
                                        ) : (
                                            String(row.ODLimit)
                                        )
                                    ): (
                                        <span>
                                        </span>
                                    ) ),
                                

                                    InterestIndicator: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                                        row.InterestIndicator ? (
                                            String(row.InterestIndicator).length > 2 ? (
                                                <span style={{ color: "red"}}>
                                                    {row.InterestIndicator} (Max. allowed character is 2)
                                                </span>
                                            ) : (
                                                String(row.InterestIndicator)
                                            )
                                        ) : (
                                            <span style={{ color: "red" }}>
                                                Shouldn't be blank
                                            </span>
                                        )
                
                                    ) : (
                                        row.InterestIndicator ? (
                                            String(row.InterestIndicator).length > 2 ? (
                                                <span style={{ color: "red"}}>
                                                    {row.InterestIndicator} (Max. allowed character is 2)
                                                </span>
                                            ) : (
                                                String(row.InterestIndicator)
                                            )
                                        ): (
                                            <span>
                                            </span>
                                        ) ),
                                    
                                        ODDate: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                                            row.ODDate ? (
                                                String(row.ODDate).length > 10 ? (
                                                    <span style={{ color: "red"}}>
                                                        {row.ODDate} (Max. allowed character is 10)
                                                    </span>
                                                ) : (
                                                    String(row.ODDate)
                                                )
                                            ) : (
                                                <span style={{ color: "red" }}>
                                                    Shouldn't be blank
                                                </span>
                                            )
                                        ) : (
                                            row.ODDate ? (
                                                String(row.ODDate).length > 10 ? (
                                                    <span style={{ color: "red"}}>
                                                        {row.ODDate} (Max. allowed character is 10)
                                                    </span>
                                                ) : (
                                                    String(row.ODDate)
                                                )
                                            ): (
                                                <span>
                                                </span>
                                            ) ),

                                            MinimumBalance: row.MinimumBalance ? (
                                                String(row.MinimumBalance).length > 20 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.MinimumBalance} (Max. allowed characters: 20)
                                                  </span>
                                                ) :(
                                                     <span>{row.MinimumBalance}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              MainBankAccount: row.ZBAType && String(row.ZBAType).trim() !== "" ? (
                                                row.MainBankAccount ? (
                                                    String(row.MainBankAccount).length > 13 ? (
                                                        <span style={{ color: "red"}}>
                                                            {row.MainBankAccount} (Max. allowed character is 13)
                                                        </span>
                                                    ) : MainBankAccountsExists && displayOption !== "" ? (
                                                        MainBankAccountGreenExists ? (
                                                            String(row.MainBankAccount)
                                                        ) : (
                                                            <span style={{ color: "red" }}>
                                                              {row.MainBankAccount} (Main Bank Account is not approved.)
                                                            </span>
                                                          )  
                                                        ) : (
                                                            <span style={{ color: "red" }}>
                                                            {row.MainBankAccount} (Main Bank Account not exists.)
                                                          </span>
                                                    )

                                                ) : (
                                                    <span style={{ color: "red" }}>
                                                        Shouldn't be blank
                                                    </span>
                                                )
                                              ):(
                                                row.MainBankAccount ? (
                                                    String(row.MainBankAccount).length > 13 ? (
                                                        <span style={{ color: "red"}}>
                                                            {row.MainBankAccount} (Max. allowed character is 13)
                                                        </span>
                                                    ) : (
                                                         MainBankAccountsExists && displayOption !== "" ? (
                                                        MainBankAccountGreenExists ? (
                                                            String(row.MainBankAccount)
                                                        ) : (
                                                            <span style={{ color: "red" }}>
                                                              {row.MainBankAccount} (Main Bank Account is not approved.)
                                                            </span>
                                                          )  
                                                        ) : (
                                                            <span style={{ color: "red" }}>
                                                            {row.MainBankAccount} (Main Bank Account not exists.)
                                                          </span>
                                                    )
                                                    )
                                                ) : (
                                                    <span>
                                                    </span>
                                                ) 
                                              ),


                                              Dormant: row.Dormant ? (
                                                String(row.Dormant).length > 2 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Dormant} (Max. allowed characters: 2)
                                                  </span>
                                                ) :(
                                                     <span>{row.Dormant}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text1: row.Text1 ? (
                                                String(row.Text1).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text1} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text1}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text2: row.Text2 ? (
                                                String(row.Text2).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text2} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text2}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text3: row.Text3 ? (
                                                String(row.Text3).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text3} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text3}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text4: row.Text4 ? (
                                                String(row.Text4).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text4} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text4}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              Text5: row.Text5 ? (
                                                String(row.Text5).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.Text5} (Max. allowed characters: 50)
                                                  </span>
                                                ) :(
                                                     <span>{row.Text5}</span>
                                                ) 
                                              ) : (
                                                <span></span>
                                              ),

                                              
                  BankAccountNo: row.BankAccountNo ? (
                    String(row.BankAccountNo).length > 25 ? (
                      <span style={{ color: "red" }}>
                        {row.BankAccountNo} (Max. allowed character is 25)
                      </span>
                    ) : (
                      String(row.BankAccountNo) // Ensure BankAccountNo is treated as a string
                    )
                  ) : (
                    row.Action === "M" || row.Action === "m" ? (
                      <span style={{ color: "red" }}>
                        Shouldn't be blank
                      </span>
                    ): null // Handle other cases, if any
                  ),

                  BankAccountIdDescription: row.BankAccountIdDescription ? (
                    String(row.BankAccountIdDescription).length > 30 ? (
                      <span style={{ color: "red" }}>
                        {row.BankAccountIdDescription} (Max. allowed character is 30)
                      </span>
                    ) : (
                      String(row.BankAccountIdDescription) // Ensure BankAccountIdDescription is treated as a string
                    )
                  ) : (
                    row.Action === "M" || row.Action === "m" ? (
                      <span style={{ color: "red" }}>
                        Shouldn't be blank
                      </span>
                    ): null // Handle other cases, if any
                  ),

                  TextField: row.TextField ? (
                    String(row.TextField).length > 30 ? (
                      <span style={{ color: "red" }}>
                        {row.TextField} (Max. allowed character is 30)
                      </span>
                    ) : (
                      String(row.TextField) // Ensure TextField is treated as a string
                    )
                  ) : (
                      <span>
                      </span>
                  ),

                                              IBANNo: row.IBANNo ? (
                                                String(row.IBANNo).length > 50 ? (
                                                  <span style={{ color: "red" }}>
                                                    {row.IBANNo} (Max. allowed character is 50)
                                                  </span>
                                                ) : (
                                                  String(row.IBANNo) // Ensure BankAccountIdDescription is treated as a string
                                                )
                                              ) : (
                                                row.Action === "M" || row.Action === "m" ? (
                                                    ibanRequiredExists ? (
                                                  <span style={{ color: "red" }}>
                                                    Shouldn't be blank
                                                  </span>
                                                    ): (
                                                    <span> </span>
                                                    )     
                                              ):null
                                            )


                };  
            }));
            setMigrationSuccessfulRecords(successfulRecordsArray);
            return results;
          
            }

              useEffect(() => {
              const fetchData = async () => {
                setLoading(true);
                const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
                console.log("data to display table data",dataToDisplay);

                if(bankBranchesGreen && bankBranches && currencies && LGE && LGEGreen && companies && companiesGreen && companiesGreenAssignToLGE &&accountTypes && zbaTypes && accClassifications && statementTypes && mainBankAccounts && mainBankAccountsGreen && ibanRequired){
                console.log("data to display table data 2",dataToDisplay);

                const processed = await MigrationFullprocessData(dataToDisplay);
                console.log("Process data to disply in the table",processed);

                setProcessedData(processed);
                }
      
                setLoading(false);
              };
            
              fetchData();
            }, [bankBranchesGreen, bankBranches, currencies, LGE, LGEGreen, companies, companiesGreen, companiesGreenAssignToLGE, accountTypes, zbaTypes, accClassifications, statementTypes, mainBankAccounts, mainBankAccountsGreen, ibanRequired]);


  return (
    <div>
       <Header title={`BAM > Bank Master Data > Account Master > Upload > Display`}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/3/BAM/26/account-master-upload"}
        />
   {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

            <AdminActions
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
            />

        <ReTable
          data={processedData}
          // data={tableData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          onRowDoubleClick={() => {}}
        />

    </div>
  )
}
