import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdRefresh,
  IoMdTrash,
} from "react-icons/io";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import BankServices from "../../../../../rest-services/BankServices";
import { useToast } from "../../../../../components/toast/toast";
import { Checkbox } from "@mui/material";
import axios from "axios";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import ReusableTable from "../../Components/Table";
import { MdOutlineFileDownload } from "react-icons/md";
import * as XLSX from "xlsx";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";

export default function BankScreen() {
  const [bankId, setBankId] = useState(null); // Track selected bank ID for editing
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [banks, setBanks] = useState([]);
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const navigate = useNavigate();
  const { toast } = useToast();
  const [logo, setLogo] = useState({});
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);

  const columns = [
    { Header: "Bank Code", accessor: "bankCode", visible: true, width: 200 },
    { Header: "Description", accessor: "Description", visible: true, width: 200 },
    // { Header: "Logo", accessor: "logo", visible: true, width: 200 },
    { Header: "Created By", accessor: "created_by", visible: true, width: 200 },
    {
      Header: "Created Date",
      accessor: "creationDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      }
    },
    {
      Header: "Created Time",
      accessor: "creationTime", 
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',

          hour12: true, 
        });
      }
    },
    { Header: "Updated By", accessor: "changed_by", visible: true, width: 200, canResize: true },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
      }
    },
    {
      Header: "Updated Time",
      accessor: "changedTime", 
      visible: true,
      width: 200,
      canResize: true,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return date.toLocaleTimeString("en-GB", {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',

          hour12: true, 
        });
      }
    },
  ];
  
  const handleDoubleClick = (id) => {
    navigate(`/bank-view/${id}`, { state: { mode: 'view' } });  };

  const [columnSettings, setColumnSettings] = useState(columns);
  useEffect(() => {
    fetchBanks();
  }, []);

  // Filter banks based on search query
  useEffect(() => {
    const filtered = banks.filter(
      (bank) =>
        bank.bankCode.toLowerCase().includes(searchQuery.toLowerCase()) )
       
    
    setFilteredBanks(filtered);
  }, [searchQuery, banks]);

  // Fetch images for filtered banks
  useEffect(() => {
    filteredBanks.forEach((bank) => {
      if (bank.logo && !logo[bank.id]) {
        fetchImage(bank.id, bank.logo);
      }
    });
  }, [filteredBanks]);

  const fetchBanks = async () => {
    setLoading(true);
    try {
      const response = await BankServices.getAllBank(
        `${process.env.REACT_APP_BASE_URL}/get-bank`
      );
      console.log(`this the  the bankd data ${response.data.data.logo}`);
      if (response && response.data) {
        setBanks(response.data.data);
        //  fetchImage(response.data.data.logo);
      }
    } catch (error) {
      console.error("Error fetching bank data", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchImage = async (id, image) => {
    const encodedCredentials = sessionStorage.getItem("encodedCredentials");
    const imageUrl = `${process.env.REACT_APP_BASE_URL}/uploads/${image}`;

    try {
      const response = await axios.get(imageUrl, {
        headers: {
          Authorization: `Bearer ${encodedCredentials}`,
        },
        responseType: "blob",
      });

      const imageBlob = URL.createObjectURL(response.data);

      // Store the image in state using the bank id as the key
      setLogo((prev) => ({ ...prev, [id]: imageBlob }));
    } catch (error) {
      console.error("Error fetching the image:", error);
    }
  };
  const modifiedColumns = columnSettings.filter(col => col.visible).map(col => ({
    ...col,
    width: col.width 
  }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this bank?"
    );
    if (confirmed) {
      setLoading(true);
      try {
        await BankServices.deleteBank(id);
        toast("success", "Bank deleted successfully");
        fetchBanks();
      } catch (error) {
        console.error("Error deleting bank:", error);
        toast("error", "Failed to delete bank");
      } finally {
        setLoading(false);
      }
    }
  };
  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredBanks.map((bank) => ({
      "Bank Code": bank.bankCode,
      "Description": bank.Description,
      "Created By": bank.created_by,
      "Created Date": bank.creationDate
        ? new Date(bank.creationDate).toLocaleDateString()
        : "",
      "Created Time": bank.creationTime
        ? new Date(bank.creationTime).toLocaleTimeString()
        : "",
      "Changed By": bank.changed_by,
      "Changed Date": bank.changedDate
        ? new Date(bank.changedDate).toLocaleDateString()
        : "",
      "Changed Time": bank.changedTime
        ? new Date(bank.changedTime).toLocaleTimeString()
        : "",
    }));
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Banks");
  
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Banks_Data.xlsx");
  };
  const extraButtons = [
    {
      icon: IoMdCreate,
      onClick: () => {
        handleNavigate(`/bank-add/${bankId}`);
      },
      title:"Edit Bank",
      disabled: selectedRows.length === 0, 
    },
  ];
  const handleReload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchBanks();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };
  const existingButtons = PrimaryActionsConfig.screen1.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add Bank"
        : button.icon === MdOutlineFileDownload
        ? "Download"
        : button.icon === IoSettingsOutline
        ? "Settings"
            : button.icon === IoMdRefresh
      ? "Refresh"
        : button.title || "", // If a title already exists, use it, otherwise empty
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/bank-add")
        : button.icon === MdOutlineFileDownload 
        ? handleDownload
        : button.icon === IoSettingsOutline
        ? handleSettingsButtonClick
        : button.icon === IoMdRefresh
        ? handleReload
        : button.onClick || (() => {}),
  }));
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );
  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleCheckboxChange = (id) => {
    setBankId(id); // Set the selected bank ID for further actions
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id)
      : [...selectedRows, id];

    setSelectedRows(updatedSelectedRows);
    setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      const allIds = banks.map((item) => item.id);
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  if (loading) return <div></div>;
  
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'Bank') {
      console.log("Navigating to /country");
      navigate('/bank');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
    <div>
      <Header title={"Primary Data > Global Master Data > Bank"}
      onBreadcrumbClick={handleBreadcrumbClick}
       />
      <div>
        <div className="flex justify-between">
          <PrimaryActions
            buttons={actionButtons} 
            selectedCount={selectedRows.length} 
            isExtraButtonsVisible={showExtraIcons}
          />

          <div className="flex  items-center ">
          <p className="mr-4 text-customBlue">Bank Code :</p>
            {/* Search box */}
            <SearchInput 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            placeholder="Search by Bank" 
          />
          </div>
        </div>
       
        <ReTable
          data={filteredBanks}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      </div>
    </div>
  );
}
