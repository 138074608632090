import React, { useEffect, useRef, useState } from 'react'
import MainHeader from '../../../components/MainHeader'
import LabelWithButton from '../../../components/InputButtonField';
import ReusableModal from '../../../components/PopupComponent';
import ReTable from '../../../components/Table';
import { IoMdRefresh, IoMdTrash } from 'react-icons/io';
import UserService from '../../../rest-services/UserServices';
import { useNavigate, useParams } from 'react-router-dom';
import CustomeLoading from '../../../components/CustomeLoading';
import { useToast } from '../../../components/toast/toast';
import ConfirmationDialog from '../../../components/Deletionpopup';
import AdminActions from '../Admin/Components/AdminActions';
import SearchInput from '../../../components/SearchInput';
import { AdminActionsConfig } from '../Admin/Components/ActionConfig';
import Pagination from '../../../components/PageNation';
import TableWrapper from '../../../components/ScrollBarComponent';

export default function TaskMonitor() {
  const navigate = useNavigate();
    const { iconId } = useParams();
    const { toast } = useToast();
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
const [taskMonitor,setTaskMonitor] = useState([]);
const [fulltaskMonitor,setFullTaskMonitor] = useState([]);
const [filteredTask,setFilteredTask] = useState([]);
  const [userPreference, setUserPreference] = useState("");
  const [error, setError] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userId, setUserId] = useState("");
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [refreshKey, setRefreshKey] = useState(0); // Add refreshKey state
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [hasPermission, SethasPermissionForView] = useState("");


  
    // Logged in User USEEFFECT
    useEffect(() => {
      if (assignee.assignerId) {
        console.log("Assigner ID has changed:", assignee.assignerId);
        fetchUserPermissionDetails();
      }
    }, [assignee.assignerId]);

  // Get Logged in User Permission details function
  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.assignerId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("user permissions details", response);
      const responseData = response.data;

      const hasPermission = responseData.rolesPermissionsTables.some(
        (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
      );

      if(!hasPermission) {
        toast("error", "You Don't have permission to view this Task Monitor.");
      }

      SethasPermissionForView(hasPermission);

      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
    } catch (error) {
      console.error(error.message); // Set error message
    }
    //  finally {
    //   setLoading(false); // Reset loading state
    // }
  };

  const handleSearchChangeUserID = (e) => {
    setSearchTerm(e.target.value);

  }

  const handleCheckboxChangeForUser = (event) => {
    const selectedUserId = event.target.value; // Get the selected user's ID
    console.log("selected user id data", selectedUserId);
    setSelectedUserId(selectedUserId); // Update the state to reflect the selected user
    setUserId(selectedUserId);

    
  };

  // pagination
    const [pageSize, setPageSize] = useState(20); // Default page size
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
      const [searchQuery, setSearchQuery] = useState(""); // Manage search input
    
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    const handleShowAll = () => {
      setPageSize(totalCount); // Set page size to total count to display all records
      setCurrentPage(1); // Reset to the first page
      fetchTaskMonitor(1, searchQuery); // Fetch data with updated page size
    };

     useEffect(() => {
            fetchTaskMonitor(currentPage);
    }, [currentPage,userId]);

      // useEffect(() => {
      //   if (userId) {
      //     handleApplyFilter(1,userId);
      //   }
      // }, [userId,currentPage]);

      
      const reload = async () => {
        setLoading(true); // Start loading
        try {
          await fetchTaskMonitor();
         
        } catch (error) {
          console.error("Error reloading users:", error);
        } finally {
          setLoading(false); // Stop loading
        }
      };

      const handleDeleteClick = async () => {
        if (selectedRows.length === 0) return;
        setShowConfirmation(true);
      };


        const existingButtons = AdminActionsConfig.screen8.map((button) => {
          let onClick;
          let title;
      
          if (button.icon === IoMdRefresh) {
            onClick = reload; // Reference the reload function, not invoking it
            title = "Refresh";
          } 
          else {
            onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
            title = button.title || ""; // Use existing title or default to an empty string
          }
      
          return {
            ...button,
            onClick, // Reference the function without invoking it
            title, // Add the title property to the button configuration
          };
        });

  const extraButtons = [
 {
      icon: IoMdTrash,
      onClick: handleDeleteClick,
      title: "Delete",
      disabled: selectedRows.length === 0,
    },
  ];


      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, // Disable button if no users are selected
          onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
        }))
      );

      const handleApplyFilter = async (page, userId) => {
    setLoading(true);
    try {
          const data = {
            clientId: assignee.clientId,
            userId,
            limit: pageSize || 10,
            offset: page && pageSize ? (page - 1) * pageSize : 0,
            searchParam : searchQuery,
          };
          console.log("Request Data for filters task:", data);
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-filter-task-details`,
            data
          );
          console.log("Response from server fetching users:", response);
          setFilteredTask(response.data);
          setTotalCount(response.totalCount)
          setTotalPages(Math.ceil(response.totalCount / pageSize));

        }catch (error) {
          console.error("Error fetching Users data:", error);
          setAlert({
            severity: "error",
            message: `Error fetching Users data. Please check your connection: ${error.message}`,
          });
        }
        finally {
          setLoading(false);
        }
      };

  const handleCheckboxChange = (rowId) => {
    console.log("checked user rowId", rowId);
 
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(rowId);
      const nextSelectedRows = isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId];
    
      return nextSelectedRows;
    });


 };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = filteredTask.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };


  useEffect(() => {
    fetchAssignee();
    fetchUserPreferenceDetails();
  }, []);

  
  const fetchUserPreferenceDetails = async () => {
    try {
      const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
      console.log("Requestfor user pref Data:", data);

      // setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log("user prefference details", response.data);
      setUserPreference(response.data); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    }
    //  finally {
    //   setLoading(false); // Reset loading state
    // }
  };

  const fetchTaskMonitor = async (page, searchQuery = "") => {
    setLoading(true);
    try {
  
      const data = {

        userId:userId||'',
        limit: pageSize || 10,
        offset: page && pageSize ? (page - 1) * pageSize : 0,
        searchParam : searchQuery,
      };
      console.log("Request Data for users:", data);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-task-details`,
        data
      );
      console.log("Response from server fetching task monitor details:", response);
      setTaskMonitor(response.data);
      setFullTaskMonitor(response.allData.rows);
      setTotalCount(response.totalCount)
      setTotalPages(Math.ceil(response.totalCount / pageSize));

    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data, kindly check the connection ${error.message}`,
      });
    }
    finally {
      setLoading(false);
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          clientId: userDetail.clientId || "",
          assignerId: userDetail.userId || "",
        };
        console.log("assigneeData is", assigneeData);

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };


   useEffect(() => {
      console.log("check users filtered", taskMonitor);
      console.log("check users fulltaskMonitor filtered", fulltaskMonitor);
  
      if (taskMonitor.length > 0) {
        const filtered = taskMonitor.filter((task) =>
          task.user_id && task.user_id.toLowerCase().includes(searchQuery.toLowerCase())
        );
        console.log("Filtered roles data",filtered);
        setFilteredTask(filtered);
      }
    }, [searchQuery, taskMonitor]);
  
  


  const formatDate = (dateString, format) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const shortYear = String(year).slice(-2);
  
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const isPM = hours >= 12;
    const hour12 = hours % 12 || 12; // Convert to 12-hour format
    const period = isPM ? 'PM' : 'AM';

    const formattedTime = `${hour12}:${minutes}:${seconds} ${period}`; // 12-hour format with seconds


    const formatMap = {
        "DD/MM/YYYY": `${day}/${month}/${year}`,
        "MM/DD/YYYY": `${month}/${day}/${year}`,
        "DD/MM/YY": `${day}/${month}/${shortYear}`,
        "MM/DD/YY": `${month}/${day}/${shortYear}`,
        "DD.MM.YYYY": `${day}.${month}.${year}`,
        "DDMMYY": `${day}${month}${shortYear}`,
        "DD-MM-YYYY": `${day}-${month}-${year}`,
        "12-hour-time": formattedTime, // Adding a specific entry for 12-hour time format
    };
  
    return formatMap[format] || dateString;
  };
  const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format
  const timeFormat = "12-hour-time";

   const columns = [  
     
    { Header: 'Date', accessor: 'date', visible: true, width: ("Date".length -2)*10, alignment: "left",canResize: true,
      Cell: ({ value }) => formatDate(value, dateFormat), },
    { Header: 'Time', accessor: 'time', visible: true, width: ("Time".length -2)*10, alignment: "left" ,canResize: true,
      Cell: ({ value }) => formatDate(value, timeFormat),},
   
          // {
          //       Header: 'S.No',
          //       accessor: 'serialNumber', // This will be calculated dynamically
          //       visible: true,
          //       width: 50,
          //       Cell: ({ row }) => row.index + 1 // Generate serial number based on row index
          //     },
          { Header: 'Modules', accessor: 'icons.mainMenu.name', visible: true, width: ("Modules".length -2)*10, alignment: "left" },
          { Header: 'Sub Modules', accessor: 'icons.subMenu.name', visible: true, width: ("Sub modules".length -2)*10, alignment: "left" },
          { Header: 'Icons', accessor: 'icons.name', visible: true, width: ("Object".length -2)*10, alignment: "left" },
          { Header: 'Action', accessor: 'action', visible: true, width: ("Action".length -2)*10, alignment: "left" },
          {
                Header: "Status",
                accessor: "Status",
                alignment: "left",
                visible: true,
                width: ("Status".length -2)*10,
                Cell: ({ row }) => {
                  const user = row.original;
                  let statusText = "N/A";
                  let statusStyle = {}; // Default style
          
                  if (user.status == 0) {
                    statusText = "In Progress";
                    statusStyle = { fontWeight: 200, color: "orange" }; // Offline style
                  } else if (user.status == 1) {
                    statusText = "Completed";
                    statusStyle = { fontWeight: 600, color: "#29CB00" }; // Online style (green text)
                  } else {
                    statusText = "Error";
                    statusStyle = { fontWeight: 600, color: "red" }; // Online style (green text)
                
                  }
          
                  return <span style={statusStyle}>{statusText}</span>;
                },
              },
              {
                Header: 'Log / Attachments',
                accessor: 'attachement',
                visible: true,
                width: ("Log / Attachments".length -2)*10,
                alignment: "left",
                Cell: ({ row }) => {
                  const attachment = row.original.attachement;
                  const icon = row.original.icon; // Extract icon value from row
                  // Declare options outside the onClick function
               
              
                  return (
                    <button
                    style={{
                      color: '#42A0EE', 
                      textDecoration: 'none', 
                      background: 'none', 
                      border: 'none', 
                      cursor: 'pointer'
                    }}
                      onClick={() => {
                        // Navigate to the display screen with the attachment data
                        navigate(`/134/task-monitor/display-screen/${icon}`, {
                          state: { tableData: attachment }
                        });
                      }}
                      onMouseEnter={(e) => e.target.style.textDecoration = 'underline'} // Underline on hover
                      onMouseLeave={(e) => e.target.style.textDecoration = 'none'} // Remove underline when hover ends
         
                    >
                      View
                    </button>
                  );
                }
              },
          { Header: 'User ID', accessor: 'user_id', visible: true, width: ("User ID".length -2)*10, alignment: "left" },
           // { Header: 'Attachments', accessor: '', visible: true, width: ("".length -2)*10, alignment: "left" },

        ];

       

        
  const cancelDeletion = () => {
    setShowConfirmation(false);
  };

  const confirmDeletion = async () => {
    setShowConfirmation(false);
    setLoading(true);

    try {
            const response = await UserService.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/delete-task-montior`,
              { ids: selectedRows} // Pass selectedUserIds in the body
            );
    
            if (response.status === 200) {
              toast("error", "Record successfully Deleted.");
    
              // Remove deleted users from the selectedRows
              setSelectedRows((prevSelectedRows) =>
                prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
              );
              await fetchTaskMonitor();
              await reload();
            } else {
              console.log("Error updating users.");
            }
          } catch (error) {
            console.error("Error updating users:", error);
          } finally {
            setLoading(false);
          }
        };

      
    const [columnSettings, setColumnSettings] = useState(columns);
  

  const modifiedColumns = columnSettings
  .filter((col) => col.visible)
  .map((col) => ({
    ...col,
    width: col.width, // This should hold the updated width
  }));


  // const hasPermission = rolesPermissions.some(
  //   (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
  // );

  
    // const hasShownToast = useRef(false);
  
    // if(!hasPermission && !hasShownToast.current) {
    //   toast("error", "You Don't have permission to view this Task Monitor.");
    //   hasShownToast.current = true;
    // }

  return (
    <>
       <MainHeader title={"Task Monitor"} />
     {hasPermission ? (
       <div className="pt-0">
        <div className={`max-w-8xl `}>
          
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center mb-0">

            <LabelWithButton
              label="User ID"
              labelwidth='w-[60px]'
              // labelwidth='w-1/5'
              onClick={() => {setIsUserModalOpen(true) }
              }
              buttonText={userId || 'Select User ID'}
              marginLeft="ml-2"   // Set custom margin-left
              // width='w-72'
              width='w-[200px]'
              marginbottom='0'
            />
           {/* <div className="flex items-center space-x-3"> */}

            <AdminActions
              key={refreshKey} // Force re-render by updating key
              icons={iconId}
              buttons={actionButtons}
              // extraButtons={extraButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows}

            />

          </div>
      
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>

          </div>

           <ReusableModal
              title="Select User ID"
              isOpen={isUserModalOpen}
              onClose={() => {
                setIsUserModalOpen(false);
                setSearchTerm('');
              }}
              onSave={() => {
                setIsUserModalOpen(false);
                setSearchTerm('');
              }}
              onReset={() => {
                setSelectedUserId("");
                setUserId("");
                setFilteredTask(fulltaskMonitor); // Reset filteredTask to the original taskMonitor list
              }}
              showReset={true} // Only show Reset button in this modal
              searchPlaceholder="Search User ID..."
              searchQuery={searchTerm}
              onSearchChange={handleSearchChangeUserID}
              // isViewMode={isViewMode}
            >
              
              {fulltaskMonitor.length > 0 ? (
  // Remove duplicate user records based on user_id
  [...new Map(
    fulltaskMonitor.map((user) => [user.user_id, user])
  ).values()]
    .filter((user) =>
      user.user_id.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .map((user, index) => (
      <div
        key={user.user_id}
        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
      >
        <input
          type="radio"
          name="user Id"
          value={user.user_id}
          checked={selectedUserId === user.user_id}
          onChange={handleCheckboxChangeForUser}
          className="mr-2 text-blue-200"
        />
        <label>{user.user_id}</label>
      </div>
    ))
) : (
  <p>No Users Found</p>
)}



            </ReusableModal>
        
        </div>
        <div>
        {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

        <ReTable
          data={filteredTask}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={() => {}}
        />

<ConfirmationDialog
              open={showConfirmation}
              title="Confirm Deletion"
              message="Are you sure you want to delete this selected User ID records?"
              onConfirm={confirmDeletion}
              onCancel={cancelDeletion}
            />

      </div>
      </div>

    ) : (
      ''
    )}

    </>
  )
}
