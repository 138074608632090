import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../../../components/Header';
import ReTable from '../../../../../../components/Table';
import ColumnSettingsPopup from '../../../../Masterdata/Components/ColumnSettingPopup';
import AdminActions from '../../../Components/AdminActions';
import { AdminActionsConfig } from '../../../Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
// import * as XLSX from "xlsx"; // Import the xlsx library
import XLSX from "xlsx-js-style";
import UserService from '../../../../../../rest-services/UserServices';
import CustomeLoading from '../../../../../../components/CustomeLoading';

export default function UserUploadTableScreen() {
    const location = useLocation();
    const { iconId } = useParams();
    const navigate = useNavigate();
    const { tableData, displayOption, uploadOption, taskMonitor  } = location.state || {}; // Retrieve tableData from state
      const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
      const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
     const [unsavedChanges, setUnsavedChanges] = useState(true);
     const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [processedData, setProcessedData] = useState([]);
  const [existingUserId, setExistingUserId] = useState([]);

      const[getExistsUserids,setgetExistsUserids] = useState([]);
      const[getExistsLanguageids,setgetExistsLanguageids] = useState([]);
      const [countryCodes, setCountryCodes] = useState([]);
        const [stateCodes, setStateCodes] = useState([]);
      
      const [companies, setCompanies] = useState([]);
      const [companiesGreen, setCompaniesGreen] = useState([]);
          const [accountNoUpdatesuccessfulRecords, setAccountNoUpdateSuccessfulRecords] = useState([]);
      

    console.log("table data received from prop is",tableData);
    
    const handleSelectRow = (id) => {
        handleCheckboxChange(id);
      };

  

const [userPreference, setUserPreference] = useState("");


useEffect(() => {
  fetchAssignee();

  fetchExistingUserids();
  fetchExistingLanguageIds();
  fetchCountryCodes();
  fetchStateProvinceCode();
  fetchCompanyCodes();
  fetchCompanyGreenCodes();
}, []);

const fetchExistingUserids = async () => {
  try {
      const response = await UserService.getUsers(
        `${process.env.REACT_APP_BASE_URL}/get-userIds-code`
      );
      console.log("response. Request Number data",response.data);
      setgetExistsUserids(response.data.data);
      
      // console.log("Set user types data", response.data.data);
    } catch (error) {
      console.error("Error fetching user types:", error);
    }
    };

    const fetchExistingLanguageIds = async () => {
      try {
          const response = await UserService.getUsers(
            `${process.env.REACT_APP_BASE_URL}/get-language-code`
          );
          console.log("response. Request Number data",response.data);
          setgetExistsLanguageids(response.data.data);
          
          // console.log("Set user types data", response.data.data);
        } catch (error) {
          console.error("Error fetching user types:", error);
        }
        };
        
        const fetchCountryCodes = async () => {
          try {
            const response = await UserService.getUsers(
              `${process.env.REACT_APP_BASE_URL}/get-country-code`
            );
            // console.log("response.data.data",response.data.data);
            setCountryCodes(response.data.data);
            // console.log("Set user types data", response.data.data);
          } catch (error) {
            console.error("Error fetching user types:", error);
          }
        };
        const fetchStateProvinceCode = async () => {
          try {
            const response = await UserService.getUsers(
              `${process.env.REACT_APP_BASE_URL}/get-stateprovince-code`
            );
            console.log("response. state data",response.data.data);
            setStateCodes(response.data.data);
            // console.log("Set user types data", response.data.data);
          } catch (error) {
            console.error("Error fetching user types:", error);
          }
          };

    const fetchCompanyCodes = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-code`
        );
        console.log("response. Company data",response.data.data);
        setCompanies(response.data.data);
        // console.log("Set user types data", response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
      };

      const fetchCompanyGreenCodes = async () => {
        try {
          const response = await UserService.getUsers(
            `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-green-code`
          );
          console.log("response. LGE data",response.data.data);
          setCompaniesGreen(response.data.data);
          // console.log("Set user types data", response.data.data);
        } catch (error) {
          console.error("Error fetching user types:", error);
        }
        };

const fetchAssignee = async () => {
try {
  const userDetailString = localStorage.getItem("userDetail");

  if (userDetailString) {
    const userDetail = JSON.parse(userDetailString);
    const assigneeData = {
      id: userDetail.id || "",
      assigner: userDetail.username || "",
      clientId: userDetail.clientId || "",
      assignerId: userDetail.userId || "",
    };
    console.log("assigneeData is", assigneeData);

    setAssignee(assigneeData);

    // Log the id and assigner values to the console
    console.log("Assignee ID:", assigneeData.id);
    console.log("Assigner:", assigneeData.assigner);
    console.log("Assigner userId:", assigneeData.assignerId);
    console.log("Assigner clientId:", assigneeData.clientId);
  } else {
    setAlert({
      severity: "error",
      message: "No user detail found in session storage.",
    });
  }
} catch (error) {
  setAlert({
    severity: "error",
    message: `Error fetching user details: ${error.message}`,
  });
}
};

useEffect(() => {
  if (assignee.id) {
    fetchUserPreferenceDetails();
  }
}, [assignee.id]);

const fetchUserPreferenceDetails = async () => {
try {
  const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
  console.log("Requestfor user pref Data:", data);

  setLoading(true); // Set loading state
  const response = await UserService.saveContactDetails(
    `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
    data
  );
  console.log("user prefference details", response.data);
  setUserPreference(response.data); // Set user preference data
} catch (error) {
  setError(error.message); // Set error message
} finally {
  setLoading(false); // Reset loading state
}
};

const formatDate = (dateString, format) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const shortYear = String(year).slice(-2);

  const formatMap = {
      "DD/MM/YYYY": `${day}/${month}/${year}`,
      "MM/DD/YYYY": `${month}/${day}/${year}`,
      "DD/MM/YY": `${day}/${month}/${shortYear}`,
      "MM/DD/YY": `${month}/${day}/${shortYear}`,
      "DD.MM.YYYY": `${day}.${month}.${year}`,
      "DDMMYY": `${day}${month}${shortYear}`,
      "DD-MM-YYYY": `${day}-${month}-${year}`,
  };

  return formatMap[format] || dateString;
};
const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format


    const handleCheckboxChange = (id) => {
        console.log('checked user id', id);
        const updatedSelectedRows = selectedRows.includes(id)
          ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
          : [...selectedRows, id]; // Add to selection if not selected
          
          setSelectedRows(updatedSelectedRows);
          console.log('check box:', updatedSelectedRows);
    
      };

      const handleDownload = () => {
        if (processedData.length === 0) {
          setAlert({
            severity: "warning",
            message: "No data available to export!",
          });
          return;
        }
      
        const extractText = (cellValue) => {
          if (Array.isArray(cellValue)) {
            return cellValue.join(", "); // Convert array to string
          } else if (typeof cellValue === "string") {
            return cellValue;
          } else if (React.isValidElement(cellValue)) {
            return cellValue.props.children;
          }
          return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
        };
      
        const formattedUsers = processedData.map((user) => ({
          "Action": extractText(user.Action),
          "SLNo": extractText(user.SLNo),
          "UserID": extractText(user.UserID),
          "EmployeeNo": extractText(user.EmployeeNo),
          "Salute": extractText(user.Salute),
          "AccountingSystemId": extractText(user.AccountingSystemId),
          "Username": extractText(user.Username),
          "FirstName": extractText(user.FirstName),
          "MiddleName": extractText(user.MiddleName),
          "LastName": extractText(user.LastName),
          "Language": extractText(user.Language || ""),
          "TimeFormat": extractText(user.TimeFormat || ""),
          "TimeZone": extractText(user.TimeZone || ""),
          "CompanyCodeId": extractText(user.CompanyCodeId),
          "Department": extractText(user.Department),
          "ValidFrom": extractText(user.ValidFrom || ''),
          "ValidTo": extractText(user.ValidTo || ''),
          "Email": extractText(user.Email),
          "MobileNo": extractText(user.MobileNo),
          "WhatsAppNo": extractText(user.WhatsAppNo),
          "Nationality": extractText(user.Nationality),
          "PassportNo": extractText(user.PassportNo),
          "DrivingLicenseNo": extractText(user.DrivingLicenseNo),
          "LocalID": extractText(user.LocalID),
          "OfficeNo": extractText(user.OfficeNo),
          "Extension": extractText(user.Extension),
          "JoiningDate": extractText(user.JoiningDate),
          "ResignationDate": extractText(user.ResignationDate),
          "DoorNo": extractText(user.DoorNo),
          "FloorNo": extractText(user.FloorNo),
          "BuildingNo": extractText(user.BuildingNo),
          "BuildingName": extractText(user.BuildingName),
          "LandMark": extractText(user.LandMark),
          "StreetNo": extractText(user.StreetNo),
          "StreetName": extractText(user.StreetName),
          "RoadNo": extractText(user.RoadNo),
          "RoadName": extractText(user.RoadName),
          "Block": extractText(user.Block),
          "AreaName": extractText(user.AreaName),
          "Phase": extractText(user.Phase),
          "Sector": extractText(user.Sector),
          "Village": extractText(user.Village),
          "Town": extractText(user.Town),
          "CityName": extractText(user.CityName),
          "Address1": extractText(user.Address1 || ""),
          "Address2": extractText(user.Address2 || ""),
          "Address3": extractText(user.Address3 || ""),
          "CountryId": extractText(user.CountryId || ""),
          "StateProvince": extractText(user.StateProvince),
          "District": extractText(user.District),
          "PostalCode": extractText(user.PostalCode),
          "ZipCode": extractText(user.ZipCode),
          "POBox": extractText(user.POBox),
          "DateFormat": extractText(user.DateFormat || ""),
          "AmountFormat": extractText(user.AmountFormat || ""),
          // "ThousandSeparator": extractText(user.ThousandSeparator),
          // "DecimalSeparator": extractText(user.DecimalSeparator),
          "AcceleratorForThousands": extractText(user.AcceleratorForThousands),
          "AcceleratorForLakhs": extractText(user.AcceleratorForLakhs),
          "AcceleratorForMillions": extractText(user.AcceleratorForMillions),
        }));
      

  // Add second and third row
  const secondRow = {
    "Action": "R","SLNo": "R", "UserID": "R","Username": "R",  "Salute": "", "FirstName": "R", "MiddleName": "", "LastName": "R",  "AccountingSystemId": "", "Email": "R",
    "MobileNo": "R", "WhatsAppNo": "",  "OfficeNo": "", "Extension": "", "PassportNo": "", "DrivingLicenseNo": "", "LocalID": "","Nationality": "R", "EmployeeNo": "R",
    "CompanyCodeId": "","Department": "", "ValidFrom": "R", "ValidTo": "R",  "JoiningDate": "", "ResignationDate": "",
    "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
    "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "AreaName": "", "Phase":"","Sector": "", "Village":"", "Town": "", "CityName": "",  "Address1": "", "Address2": "", "Address3": "", 
    "CountryId": "R", "StateProvince": "Conditional", "District": "", "PostalCode": "", "ZipCode": "", "POBox": "", "Language": "R", "TimeFormat": "R", "TimeZone": "",   "DateFormat": "R", "AmountFormat": "R", 
    // "ThousandSeparator": "", "DecimalSeparator": "",
     "AcceleratorForThousands": "", "AacceleratorForLakhs":"","AcceleratorForMillions": ""
  };

  const thirdRow = {
     "Action": "1","SLNo": "5", "UserID": "12","Username": "12","Salute": "",  "FirstName": "20", "MiddleName": "20", "LastName": "20","AccountingSystemId": "12",  "Email": "80",
    "MobileNo": "13", "WhatsAppNo": "13", "OfficeNo": "13", "Extension": "10","PassportNo": "10", "DrivingLicenseNo": "20", "LocalID": "20", "Nationality": "20",  "EmployeeNo": "12", 
     "CompanyCodeId": "10", "Department": "10", "ValidFrom": "10", "ValidTo": "10", "JoiningDate": "10", "ResignationDate": "19", 
     "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
    "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "AreaName": "", "Phase":"","Sector": "", "Village":"", "Town": "", "CityName": "",  "Address1": "50", "Address2": "50", "Address3": "50", 
    "CountryId": "2", "StateProvince": "3", "District": "", "PostalCode": "", "ZipCode": "","POBox":"", "Language": "2", "TimeFormat": "8", "TimeZone": "",  "DateFormat": "", "AmountFormat": "19", 
  //  "ThousandSeparator": "1", "DecimalSeparator": "1", 
  "AcceleratorForThousands": "1", "AacceleratorForLakhs":"1","AcceleratorForMillions": "1"
  };

    // Insert second and third row at the beginning of the formatted data
    const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];

        const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
      console.log("rowsToDownload data is",rowsToDownload);
 
        // Define border style
  const border = {
    top: { style: "thin", color: { rgb: "000000" } },
    right: { style: "thin", color: { rgb: "000000" } },
    bottom: { style: "thin", color: { rgb: "000000" } },
    left: { style: "thin", color: { rgb: "000000" } },
  };

      // Apply borders to the first three rows
      rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
        Object.keys(row).forEach((col, colIdx) => {
          const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].s = { border };
          }
        });
      });

      
       // Additional cell styling for validation errors
        Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
          rowsToDownload.forEach((row, rowIdx) => {
            const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
            const cellValue = row[col];
      
            if (
              typeof cellValue === "string" && (
                cellValue.includes("Shouldn't be blank") 
                ||
                cellValue.includes("Either Address Section or Building, Street, and Area Section must be entered")
                // cellValue.includes("Max. allowed character")
              )
            ) {
              worksheet[cellAddress] = {
                v: cellValue,
                s: { font: { color: { rgb: "FF0000" } } },
              };
            } else if (Array.isArray(row[col])) {
              worksheet[cellAddress] = {
                v: extractText(row[col]),
                s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
              };
            }
            else {
              worksheet[cellAddress] = { v: cellValue };
            }
          });
        });
      
        // AutoFit column width
        worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
          const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
          return { wch: maxLength + 2 }; // Add extra space
        });


        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
        XLSX.writeFile(workbook, "Users_Uploading_Data.xlsx");
      };
      
    const columns = [   
        
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length - 2) * 10, alignment: "left" },
        { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length - 2) * 10, alignment: "left" },
        // { Header: 'Update', accessor: '', visible: true, width: ("Role ID".length - 2) * 10, alignment: "left" },
        {
                      Header: 'Update',
                      accessor: (row) => {
                        if (row.successfulrecords && uploadOption === '3') {
                          return 'Yes';
                        } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                          return 'No';
                        } else {
                          return <span style={{ color: 'red' }}>No</span>;
                        }
                      },
                      visible: true,
                      width: ("Update".length - 2) * 10,
                      alignment: 'left',
                    },
        { Header: 'UserID', accessor: 'UserID', visible: true, width: ("UserID".length - 2) * 10, alignment: "left" },
        { Header: 'EmployeeNo', accessor: 'EmployeeNo', visible: true, width: ("EmployeeNo".length - 2) * 10, alignment: "left" },
        { Header: 'Salute', accessor: 'Salute', visible: true, width: ("Salute".length - 2) * 10, alignment: "left" },
        { Header: 'AccountingSystemId', accessor: 'AccountingSystemId', visible: true, width: ("AccountingSystemId".length - 2) * 10, alignment: "left" },
        { Header: 'Username', accessor: 'Username', visible: true, width: ("Username".length - 2) * 10, alignment: "left" },
        { Header: 'FirstName', accessor: 'FirstName', visible: true, width: ("FirstName".length - 2) * 10, alignment: "left" },
        { Header: 'MiddleName', accessor: 'MiddleName', visible: true, width: ("MiddleName".length - 2) * 10, alignment: "left" },
        { Header: 'LastName', accessor: 'LastName', visible: true, width: ("LastName".length - 2) * 10, alignment: "left" },
        { Header: 'Language', accessor: 'Language', visible: true, width: ("Language".length - 2) * 10, alignment: "left" },
        { Header: 'TimeFormat', accessor: 'TimeFormat', visible: true, width: ("TimeFormat".length - 2) * 10, alignment: "left" },
        { Header: 'TimeZone', accessor: 'TimeZone', visible: true, width: ("TimeZone".length - 2) * 10, alignment: "left" },
        { Header: 'CompanyCodeId', accessor: 'CompanyCodeId', visible: true, width: ("CompanyCodeId".length - 2) * 10, alignment: "left" },
        { Header: 'Department', accessor: 'Department', visible: true, width: ("Department".length - 2) * 10, alignment: "left" },
        {
          Header: 'ValidFrom',
          accessor: 'ValidFrom',
          visible: true,
          width: ("ValidFrom".length - 2) * 10,
          alignment: "left",
          Cell: ({ value, row }) => {
            // Check if the value is empty
            if (!value) {
              // If validFrom is empty, check for row.Action
              if (row.Action === "A" || row.Action === "a") {
                return (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                );
              } else if (row.Action === "E" || row.Action === "e") {
                return <span></span>; // Empty span for "E"
              } else {
                return null; // For other cases, handle accordingly
              }
            }
        
            // Handle the case if value is a React element (e.g., JSX)
            if (React.isValidElement(value)) {
              return value; // If it's already a React element, just return it
            }
        
            // If value is an object, handle it
            if (typeof value === 'object') {
              // If it's a date object, we format it as a string (e.g., 'YYYY-MM-DD')
              if (value instanceof Date) {
                return value.toISOString().split('T')[0]; // Formats to 'YYYY-MM-DD'
              }
              return JSON.stringify(value); // If it's another object, show the JSON string (you can adjust as needed)
            }
        
            // If value is a string or another primitive type, return it directly
            return String(value);
          },
        },
        
        {
          Header: 'ValidTo',
          accessor: 'ValidTo',
          visible: true,
          width: ("ValidTo".length - 2) * 10,
          alignment: "left",
          Cell: ({ value, row }) => {
            // Check if the value is empty
            if (!value) {
              // If validTo is empty, check for row.Action
              if (row.Action === "A" || row.Action === "a") {
                return (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                );
              } else if (row.Action === "E" || row.Action === "e") {
                return <span></span>; // Empty span for "E"
              } else {
                return null; // For other cases, handle accordingly
              }
            }
        
            // Handle the case if value is a React element (e.g., JSX)
            if (React.isValidElement(value)) {
              return value; // If it's already a React element, just return it
            }
        
            // If value is an object, handle it
            if (typeof value === 'object') {
              // If it's a date object, we format it as a string (e.g., 'YYYY-MM-DD')
              if (value instanceof Date) {
                return value.toISOString().split('T')[0]; // Formats to 'YYYY-MM-DD'
              }
              return JSON.stringify(value); // If it's another object, show the JSON string (you can adjust as needed)
            }
        
            // If value is a string or another primitive type, return it directly
            return String(value);
          },
        },
      
        { Header: 'Email', accessor: 'Email', visible: true, width: ("Email".length - 2) * 10, alignment: "left" },      
        { Header: 'MobileNo', accessor: 'MobileNo', visible: true, width: ("MobileNo".length - 2) * 10, alignment: "left" },
        { Header: 'WhatsAppNo', accessor: 'WhatsAppNo', visible: true, width: ("WhatsAppNo".length - 2) * 10, alignment: "left" },
        { Header: 'Nationality', accessor: 'Nationality', visible: true, width: ("Nationality".length - 2) * 10, alignment: "left" },
        { Header: 'PassportNo', accessor: 'PassportNo', visible: true, width: ("PassportNo".length - 2) * 10, alignment: "left" },
        { Header: 'DrivingLicenseNo', accessor: 'DrivingLicenseNo', visible: true, width: ("DrivingLicenseNo".length - 2) * 10, alignment: "left" },
        { Header: 'LocalId', accessor: 'LocalId', visible: true, width: ("LocalId".length - 2) * 10, alignment: "left" },
        { Header: 'OfficeNo', accessor: 'OfficeNo', visible: true, width: ("OfficeNo".length - 2) * 10, alignment: "left" },
        { Header: 'Extension', accessor: 'Extension', visible: true, width: ("Extension".length - 2) * 10, alignment: "left" },
      
           
        {
          Header: 'JoiningDate',
          accessor: 'JoiningDate',
          visible: true,
          width: ("JoiningDate".length - 2) * 10,
          alignment: "left",
          Cell: ({ value, row }) => {
            // Check if the value is empty
            if (!value) {
              // If JoiningDate is empty, check for row.Action
             if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
                return <span></span>; // Empty span for "E"
              } else {
                return null; // For other cases, handle accordingly
              }
            }
        
            // Handle the case if value is a React element (e.g., JSX)
            if (React.isValidElement(value)) {
              return value; // If it's already a React element, just return it
            }
        
            // If value is an object, handle it
            if (typeof value === 'object') {
              // If it's a date object, we format it as a string (e.g., 'YYYY-MM-DD')
              if (value instanceof Date) {
                return value.toISOString().split('T')[0]; // Formats to 'YYYY-MM-DD'
              }
              return JSON.stringify(value); // If it's another object, show the JSON string (you can adjust as needed)
            }
        
            // If value is a string or another primitive type, return it directly
            return String(value);
          },
        },

        // { 
        //   Header: 'JoiningDate', 
        //   accessor: 'JoiningDate', 
        //   visible: true, 
        //   width: ("JoiningDate".length - 2) * 10, 
        //   alignment: "left", 
        //   Cell: ({ value }) => 
        //     value ? (
        //       isNaN(new Date(value).getTime()) ? (
        //         <span> </span>
        //       ) : (
        //         formatDate(value, dateFormat) // Use the formatDate function for valid dates
        //       )
        //     ) : (
        //       <span> </span>
        //     )
        // },

        {
          Header: 'ResignationDate',
          accessor: 'ResignationDate',
          visible: true,
          width: ("ResignationDate".length - 2) * 10,
          alignment: "left",
          Cell: ({ value, row }) => {
            // Check if the value is empty
            if (!value) {
              // If ResignationDate is empty, check for row.Action
             if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
                return <span></span>; // Empty span for "E"
              } else {
                return null; // For other cases, handle accordingly
              }
            }
        
            // Handle the case if value is a React element (e.g., JSX)
            if (React.isValidElement(value)) {
              return value; // If it's already a React element, just return it
            }
        
            // If value is an object, handle it
            if (typeof value === 'object') {
              // If it's a date object, we format it as a string (e.g., 'YYYY-MM-DD')
              if (value instanceof Date) {
                return value.toISOString().split('T')[0]; // Formats to 'YYYY-MM-DD'
              }
              return JSON.stringify(value); // If it's another object, show the JSON string (you can adjust as needed)
            }
        
            // If value is a string or another primitive type, return it directly
            return String(value);
          },
        },
        // { 
        //   Header: 'ResignationDate', 
        //   accessor: 'ResignationDate', 
        //   visible: true, 
        //   width: ("ResignationDate".length - 2) * 10, 
        //   alignment: "left", 
        //   Cell: ({ value }) => 
        //     value ? (
        //       isNaN(new Date(value).getTime()) ? (
        //         <span> </span>
        //       ) : (
        //         formatDate(value, dateFormat) // Use the formatDate function for valid dates
        //       )
        //     ) : (
        //       <span> </span>
        //     )
        // },

        // { Header: 'resignationDate', accessor: 'resignationDate', visible: true, width: ("Role ID".length - 2) * 10, alignment: "left" },
        { Header: 'DoorNo', accessor: 'DoorNo', visible: true, width: ("DoorNo".length - 2) * 10, alignment: "left" },
        { Header: 'FloorNo', accessor: 'FloorNo', visible: true, width: ("FloorNo".length - 2) * 10, alignment: "left" },
        { Header: 'BuildingNo', accessor: 'BuildingNo', visible: true, width: ("BuildingNo".length - 2) * 10, alignment: "left" },
        { Header: 'BuildingName', accessor: 'BuildingName', visible: true, width: ("BuildingName".length - 2) * 10, alignment: "left" },
        { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length - 2) * 10, alignment: "left" },
        { Header: 'StreetNo', accessor: 'StreetNo', visible: true, width: ("StreetNo".length - 2) * 10, alignment: "left" },
        { Header: 'StreetName', accessor: 'StreetName', visible: true, width: ("StreetName".length - 2) * 10, alignment: "left" },
        { Header: 'RoadNo', accessor: 'RoadNo', visible: true, width: ("RoadNo".length - 2) * 10, alignment: "left" },
        { Header: 'RoadName', accessor: 'RoadName', visible: true, width: ("RoadName".length - 2) * 10, alignment: "left" },
        { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length - 2) * 10, alignment: "left" },
        // { Header: 'Area Name', accessor: 'Area Name', visible: true, width: ("Role ID".length - 2) * 10, alignment: "left" },
        // { Header: 'streetName', accessor: 'streetName', visible: true, width: ("Role ID".length - 2) * 10, alignment: "left" },
        { Header: 'AreaName', accessor: 'AreaName', visible: true, width: ("AreaName".length - 2) * 10, alignment: "left" },
        { Header: 'Phase', accessor: 'Phase', visible: true, width: ("Phase".length - 2) * 10, alignment: "left" },
        { Header: 'Sector', accessor: 'Sector', visible: true, width: ("Sector".length - 2) * 10, alignment: "left" },
        { Header: 'Village', accessor: 'Village', visible: true, width: ("Village".length - 2) * 10, alignment: "left" },
        { Header: 'Town', accessor: 'Town', visible: true, width: ("Town".length - 2) * 10, alignment: "left" },
        { Header: 'CityName', accessor: 'CityName', visible: true, width: ("CityName".length - 2) * 10, alignment: "left" },
        // { Header: 'landmarkName', accessor: 'landmarkName', visible: true, width: ("Role ID".length - 2) * 10, alignment: "left" },
        { Header: 'Address1', accessor: 'Address1', visible: true, width: ("Address1".length - 2) * 10, alignment: "left" },
        { Header: 'Address2', accessor: 'Address2', visible: true, width: ("Address2".length - 2) * 10, alignment: "left" },
        { Header: 'Address3', accessor: 'Address3', visible: true, width: ("Address3".length - 2) * 10, alignment: "left" },
        { Header: 'CountryId', accessor: 'CountryId', visible: true, width: ("CountryId".length - 2) * 10, alignment: "left" },
        { Header: 'StateProvince', accessor: 'StateProvince', visible: true, width: ("StateProvince".length - 2) * 10, alignment: "left" },
        { Header: 'District', accessor: 'District', visible: true, width: ("District".length - 2) * 10, alignment: "left" },
        { Header: 'PostalCode', accessor: 'PostalCode', visible: true, width: ("PostalCode".length - 2) * 10, alignment: "left" },
        { Header: 'ZipCode', accessor: 'ZipCode', visible: true, width: ("ZipCode".length - 2) * 10, alignment: "left" },
        { Header: 'POBox', accessor: 'POBox', visible: true, width: ("POBox".length - 2) * 10, alignment: "left" },
        { Header: 'DateFormat', accessor: 'DateFormat', visible: true, width: ("DateFormat".length - 2) * 10, alignment: "left" },
        { Header: 'AmountFormat', accessor: 'AmountFormat', visible: true, width: ("AmountFormat".length - 2) * 10, alignment: "left" },
        // { Header: 'ThousandSeparator', accessor: 'ThousandSeparator', visible: true, width: ("ThousandSeparator".length - 2) * 10, alignment: "left" },
        // { Header: 'DecimalSeparator', accessor: 'DecimalSeparator', visible: true, width: ("DecimalSeparator".length - 2) * 10, alignment: "left" },
        { Header: 'AcceleratorForThousands', accessor: 'AcceleratorForThousands', visible: true, width: ("AcceleratorForThousands".length - 2) * 10, alignment: "left" },
        { Header: 'AcceleratorForLakhs', accessor: 'AcceleratorForLakhs', visible: true, width: ("AcceleratorForLakhs".length - 2) * 10, alignment: "left" },
        { Header: 'AcceleratorForMillions', accessor: 'AcceleratorForMillions', visible: true, width: ("AcceleratorForMillions".length - 2) * 10, alignment: "left" },
      
      ];

        const existingButtons = AdminActionsConfig.screen7.map((button) => {
          let onClick;
          let title;
      
      
          if (button.icon === MdOutlineFileDownload) {
            onClick = handleDownload;
            title = "Download File";
          }
      
          else {
            onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
            title = button.title || ""; // Use existing title or default to an empty string
          }
      
          return {
            ...button,
            onClick, // Reference the function without invoking it
            title,   // Add the title property to the button configuration
          };
        });
      
 const extraButtons = [];

      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, // Disable button if no users are selected
          onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
        }))
      );

  const [columnSettings, setColumnSettings] = useState(columns);

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for basic email validation
    return emailRegex.test(email);
  };

      const SuccessOnlyprocessData = async (data) => {
        const successfulRecordsArray = [];
        
        const userIds = data.map(row => row.UserID).filter(Boolean);
        const countryIds = data.map(row => row.CountryId).filter(Boolean);
        console.log("Extracted userIds:", userIds);
        console.log("Extracted countryIds:", countryIds);
      
        const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(userIds);
      
        await Promise.all(data.map(async (row, _, allRows) => {
      
        // Check for duplicate same userId is repeated in the data
        const isDuplicateUserId = allRows.filter(r => r.UserID == row.UserID).length > 1;
        console.log("Duplicate user id is ",isDuplicateUserId);
      
       // Check for duplicate userId in the database
       const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.UserID);
      
      const normalize = (value) => String(value).trim().toUpperCase();
      const userExists = getExistsUserids.map(normalize).includes(normalize(row.UserID));
      const languageExists = getExistsLanguageids.map(normalize).includes(normalize(row.Language));
      const CompanyExists = companies.map(normalize).includes(normalize(row.CompanyCodeId));
      const CompanyGreenExists = companiesGreen.map(normalize).includes(normalize(row.CompanyCodeId));
      const countryIDExists = countryCodes.map(normalize).includes(normalize(row.CountryId));
      // const stateIDExists = stateCodes.map(normalize).includes(normalize(row.StateProvince));
      const stateIDExists = stateCodes.some((entry) =>
        normalize(entry.state) === normalize(row.StateProvince) &&
        normalize(entry.countryCode) === normalize(row.CountryId)
      );
      
       const hasNotError = [
        row.SLNo,
        row.Action,
        row.UserID,
        row.Username,
        row.FirstName,
        row.MiddleName,
        row.LastName,
        row.AccountingSystemId,
        row.MobileNo,
        row.WhatsAppNo,
        row.OfficeNo,
        row.Extension,
        row.PassportNo,
        row.DrivingLicenseNo,
        row.LocalId,
        row.Nationality,
        row.EmployeeNo,
        row.CompanyCodeId,
        row.Department,
        row.ValidFrom,
        row.ValidTo,
        row.ResignationDate,
        row.JoiningDate,
        row.Address1,
        row.Address2,
        row.Address3,
        row.CountryId,
        row.StateProvince,
        row.Language,
        row.DateFormat,
        row.TimeFormat,
        row.AmountFormat,
        // row.ThousandSeparator,
        // row.DecimalSeparator,
        row.AcceleratorForThousands,
        row.AcceleratorForLakhs,
        row.AcceleratorForMillions,
        row.Email
      
      ].every(field => {
        let isValid = true;
        let reason = "";
        
        if (field === row.SLNo) {
          if (!field) { isValid = false; reason = "SLNo should not be blank."; }
          else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
          else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
        }
        
        else if (field === row.Action) {
          if (!field) { isValid = false; reason = "Action should not be blank."; }
          else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
        }
        
        
        if (!row.UserID || String(row.UserID).trim() === "") {
          if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
            isValid = false;
            reason = "User ID should not be empty.";
        }
        } else if (field === row.UserID) {
            if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
              isValid = false; reason = "User ID does not exist.";
            } else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
              isValid = false; reason = "User ID is duplicate.";
            }
        }
      
        else if (field === row.Username) {
          if (row.Action === "E" || row.Action === "e") {
            if (!field) { isValid = true; reason = "Username should be blank for Edit action."; }
            else if (String(field).length > 12) { isValid = false; reason = "Username exceeds max length of 12."; }
          } else if (row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = false; reason = "Username should not be blank for Add action."; }
            if (String(field).length > 12) { isValid = false; reason = "Username exceeds max length of 12."; }
          }
        }
        
        else if (field === row.FirstName) {
          if (row.Action === "E" || row.Action === "e") {
            if (!field) { isValid = true; reason = "firstName should be blank for Edit action."; }
            else if (String(field).length > 20) { isValid = false; reason = "firstName exceeds max length of 20."; }
          } else if (row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = false; reason = "firstName should not be blank for Add action."; }
            if (String(field).length > 20) { isValid = false; reason = "firstName exceeds max length of 20."; }
          }
        }
      
        else if (field === row.MiddleName) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "middleName should be blank for Add/Edit action."; }
            else if (String(field).length > 20) { isValid = false; reason = "middleName exceeds max length of 20."; }
          }
        }
      
        if (!row.LastName || String(row.LastName).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "LastName should not be empty.";
        }
        } else if (field === row.LastName) {
            if (row.Action === "E" || row.Action === "e") {
              if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "LastName should be blank for Edit action.";
                } else if (String(field).trim().length > 20) {
                    isValid = false;
                    reason = "LastName exceeds max length of 20.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
              if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "LastName should not be blank for Add action.";
                } else if (String(field).trim().length > 20) {
                    isValid = false;
                    reason = "LastName exceeds max length of 20.";
                }
            }
        }
      
        else if (field === row.AccountingSystemId) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "AccountingSystemId should be blank for Add/Edit action."; }
            else if (String(field).length > 12) { isValid = false; reason = "AccountingSystemId exceeds max length of 12."; }
          }
        }
      
        if (!row.MobileNo || String(row.MobileNo).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "MobileNo should not be empty.";
        }
      } else if (field === row.MobileNo) {
          const mobileNoStr = String(field); // Ensure it's a string
      
          if (row.Action === "E" || row.Action === "e") {
              if (mobileNoStr.trim() === "") {
                  isValid = true;
                  reason = "Mobile No should be blank for Edit action.";
              } else {
                  if (isNaN(mobileNoStr)) {
                      isValid = false;
                      reason = "Mobile No should be a valid number.";
                  }
                  if (mobileNoStr.length > 13) {
                      isValid = false;
                      reason = "Mobile No exceeds max length of 13.";
                  }
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (mobileNoStr.trim() === "") {
                  isValid = false;
                  reason = "Mobile No should not be blank for Add action.";
              } else {
                  if (isNaN(mobileNoStr)) {
                      isValid = false;
                      reason = "Mobile No should be a valid number.";
                  }
                  if (mobileNoStr.length > 13) {
                      isValid = false;
                      reason = "Mobile No exceeds max length of 13.";
                  }
              }
          }
      }
      
        
        else if (field === row.WhatsAppNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "whatsApp No should be blank for Edit action."; }
            else{
              if (isNaN(field)) { isValid = false; reason = "whatsApp No should be a valid number."; }
              if (String(field).length > 13) { isValid = false; reason = "whatsApp No exceeds max length of 13."; }
            }
          } 
        }
      
        else if (field === row.OfficeNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "office No should be blank for Edit action."; }
            else{
            if (isNaN(field)) { isValid = false; reason = "office No should be a valid number."; }
            if (String(field).length > 13) { isValid = false; reason = "office No exceeds max length of 13."; }
          } 
        } 
        }
      
        else if (field === row.Extension) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "extension should be blank for Edit action."; }
            else{
            if (isNaN(field)) { isValid = false; reason = "extension should be a valid number."; }
            if (String(field).length > 10) { isValid = false; reason = "extension exceeds max length of 10."; }
          } 
        } 
        }
      
        else if (field === row.PassportNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "passportNo should be blank for Add/Edit action."; }
            else if (String(field).length > 10) { isValid = false; reason = "passportNo exceeds max length of 10."; }
      
          }
        }
      
        else if (field === row.DrivingLicenseNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "drivingLicense No should be blank for Add/Edit action."; }
            else if (String(field).length > 20) { isValid = false; reason = "drivingLicense No exceeds max length of 20."; }
          }
        }
      
        else if (field === row.LocalId) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "localId should be blank for Add/Edit action."; }
            else if (String(field).length > 20) { isValid = false; reason = "localId exceeds max length of 20."; }
          }
        }
      
        
        if (!row.Nationality || String(row.Nationality).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "Nationality should not be empty.";
        }
        } else if (field === row.Nationality) {
            if (row.Action === "E" || row.Action === "e") {
                if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "Nationality should be blank for Edit action.";
                } else if (String(field).trim().length > 20) {
                    isValid = false;
                    reason = "Nationality exceeds max length of 20.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
                if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "Nationality should not be blank for Add action.";
                } else if (String(field).trim().length > 20) {
                    isValid = false;
                    reason = "Nationality exceeds max length of 20.";
                }
            }
        }
      
        else if (field === row.EmployeeNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "employeeNo should be blank for Add/Edit action."; }
            else if (String(field).length > 12) { isValid = false; reason = "employeeNo exceeds max length of 12."; }
          }
        }
      
        if (row.CompanyCodeId) {
          if (String(field).length > 10) { isValid = false; reason = "companyCodeId exceeds max length of 10."; }
      
          if(CompanyExists){
            if(!CompanyGreenExists){
               isValid = false;
               reason = "CompanyCode is not exists because of the flag status";
            }
          }
          else{
            isValid = false;
            reason = "CompanyCode is not exists";
          }
      
        }
      
        else if (field === row.Department) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "department should be blank for Add/Edit action."; }
            else if (String(field).length > 6) { isValid = false; reason = "department exceeds max length of 6."; }
          }
        }
      
        if (!row.ValidFrom || String(row.ValidFrom).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "ValidFrom should not be empty.";
        }
      } else if (field === row.ValidFrom) {
          const validFromStr = String(field).trim(); // Ensure it's a string
      
          if (row.Action === "E" || row.Action === "e") {
              if (validFromStr === "") {
                  isValid = true;
                  reason = "ValidFrom should be blank for Edit action.";
              } else if (validFromStr.length > 10) {
                  isValid = false;
                  reason = "ValidFrom exceeds max length of 10.";
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (validFromStr === "") {
                  isValid = false;
                  reason = "ValidFrom should not be blank for Add action.";
              } else if (validFromStr.length > 10) {
                  isValid = false;
                  reason = "ValidFrom exceeds max length of 10.";
              }
          }
      }
      
      
        if (!row.ValidTo || String(row.ValidTo).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "ValidTo should not be empty.";
        }
      } else if (field === row.ValidTo) {
          const ValidToStr = String(field).trim(); // Ensure it's a string
      
          if (row.Action === "E" || row.Action === "e") {
              if (ValidToStr === "") {
                  isValid = true;
                  reason = "ValidTo should be blank for Edit action.";
              } else if (ValidToStr.length > 10) {
                  isValid = false;
                  reason = "ValidTo exceeds max length of 10.";
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (ValidToStr === "") {
                  isValid = false;
                  reason = "ValidTo should not be blank for Add action.";
              } else if (ValidToStr.length > 10) {
                  isValid = false;
                  reason = "ValidTo exceeds max length of 10.";
              }
          }
      }
      
        else if (field === row.ResignationDate) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "ResignationDate should be blank for Add/Edit action."; }
            else if (String(field).length > 10) { isValid = false; reason = "ResignationDate exceeds max length of 10."; }
          }
        }
      
        else if (field === row.JoiningDate) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "joiningDate should be blank for Add/Edit action."; }
            else if (String(field).length > 10) { isValid = false; reason = "joiningDate exceeds max length of 10."; }
          }
        }
      
        if (row.Address1 && String(row.Address1).length > 50) {
          isValid = false;
          reason = "Address1 exceeds max length of 50.";
      } else {
      // field = row.Address1 || "";  
      
          const missingFields = [
            row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
            row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
            row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
            row.CityName,  row.Address1, row.Address2, row.Address3
        ].every(value => !value); // Check if all values are falsy (undefined, null, empty, false)
        
        if (row.Action === "E" || row.Action === "e") {
            if (!field) { 
                isValid = true; 
                reason = "Address1 should be blank for Edit action."; 
            } else if (field.length > 50) { 
                isValid = false; 
                reason = "Address1 exceeds max length of 50."; 
            }
        } else if (row.Action === "A" || row.Action === "a") {
            console.log("Address field 1 enter log");
            console.log("check door no:", row.DoorNo);
        
            if (missingFields) { 
                isValid = false; 
                reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and Address1 should not be blank for Add action."; 
            }
        }
        }
         
        // Ensure field is explicitly assigned to row.Address1 if available
      
      // Check if all address-related fields are missing
      
      
        if (field === row.Address2) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "addressLine2 should be blank for Add/Edit action."; }
            else if (String(field).length > 50) { isValid = false; reason = "addressLine2 exceeds max length of 50."; }
          }
        }
      
        if (field === row.Address3) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "addressLine3 should be blank for Add/Edit action."; }
            else if (String(field).length > 50) { isValid = false; reason = "addressLine3 exceeds max length of 50."; }
          }
        }
      
      
        if (!row.CountryId || String(row.CountryId).trim() === "") {
          if (row.Action === "A" || row.Action === "a" ) {
            isValid = false;
            reason = "CountryId should not be empty.";
          }
      } else if (field === row.CountryId) {
          if (!countryIDExists) {
              isValid = false;
              reason = "Country code is Not exists."; 
          }
          if (String(field).trim().length > 2) {
            isValid = false;
            reason = "CountryId exceeds max length of 2.";
        }
      }
      
      
      
      // if (!row.StateProvince || String(row.StateProvince).trim() === "") {
      //   if (row.Action === "A" || row.Action === "a" ) {
      //     isValid = false;
      //     reason = "StateProvince should not be empty.";
      //   }
      // } else 
      if (row.StateProvince) {
        if (!stateIDExists) {
            isValid = false;
            reason = "StateProvince is Not exists."; 
        }
        if (String(row.StateProvince).trim().length > 2) {
          isValid = false;
          reason = "StateProvince exceeds max length of 2.";
      }
      }
      
        //  if (field === row.StateProvince) {
        //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        //     if (!field) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
        //     else if (String(field).length > 2) { isValid = false; reason = "StateProvince exceeds max length of 2."; }
        //   }
        // }
      
      
        if ((!row.Language || String(row.Language).trim() === "") && (row.Action === "A" || row.Action === "a")) {
            isValid = false;
            reason = "Language should not be empty.";
        
        } else if (field === row.Language) {
          if(!languageExists){
            isValid = false;
            reason = "language is not exists. ";
        }
            if (row.Action === "E" || row.Action === "e") {
                if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "Language should be blank for Edit action.";
                } else if (String(field).trim().length > 2) {
                    isValid = false;
                    reason = "Language exceeds max length of 2.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
             
      
                if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "Language should not be blank for Add action.";
                } else if (String(field).trim().length > 2) {
                    isValid = false;
                    reason = "Language exceeds max length of 2.";
                }
            }
        }
      
      
        if (!row.DateFormat || String(row.DateFormat).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "DateFormat should not be empty.";
        }
        } else if (field === row.DateFormat) {
            if (row.Action === "E" || row.Action === "e") {
                if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "DateFormat should be blank for Edit action.";
                } else if (String(field).trim().length > 10) {
                    isValid = false;
                    reason = "DateFormat exceeds max length of 10.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
                if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "DateFormat should not be blank for Add action.";
                } else if (String(field).trim().length > 10) {
                    isValid = false;
                    reason = "DateFormat exceeds max length of 10.";
                }
            }
        }
      
        if (!row.TimeFormat || String(row.TimeFormat).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "TimeFormat should not be empty.";
        }
        } else if (field === row.TimeFormat) {
            if (row.Action === "E" || row.Action === "e") {
                if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "TimeFormat should be blank for Edit action.";
                } else if (String(field).trim().length > 8) {
                    isValid = false;
                    reason = "TimeFormat exceeds max length of 8.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
                if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "TimeFormat should not be blank for Add action.";
                } else if (String(field).trim().length > 8) {
                    isValid = false;
                    reason = "TimeFormat exceeds max length of 8.";
                }
            }
        }
      
        if (!row.AmountFormat || String(row.AmountFormat).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "AmountFormat should not be empty.";
        }
        } else if (field === row.AmountFormat) {
            if (row.Action === "E" || row.Action === "e") {
              if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "AmountFormat should be blank for Edit action.";
                } else if (String(field).trim().length > 19) {
                    isValid = false;
                    reason = "AmountFormat exceeds max length of 19.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
              if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "AmountFormat should not be blank for Add action.";
                } else if (String(field).trim().length > 19) {
                    isValid = false;
                    reason = "AmountFormat exceeds max length of 19.";
                }
            }
        }
      
        else if (field === row.AcceleratorForThousands) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "AcceleratorForThousands should be blank for Add/Edit action."; }
          else{
            if (String(field).length > 1) { isValid = false; reason = "AcceleratorForThousands exceeds max length of 1."; }
            if (!["K", "T"].includes(String(field))) { isValid = false; reason = "Only 'K' or 'T' allowed."; }
            }
            }
        }
      
        else if (field === row.AcceleratorForLakhs) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "AcceleratorForLakhs should be blank for Add/Edit action."; }
          else{
            if (String(field).length > 1) { isValid = false; reason = "AcceleratorForLakhs exceeds max length of 1."; }
            if (!["L"].includes(String(field))) { isValid = false; reason = "Only 'L' allowed."; }
            }
            }
        }
      
        
        else if (field === row.AcceleratorForMillions) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "AcceleratorForMillions should be blank for Add/Edit action."; }
          else{
            if (String(field).length > 1) { isValid = false; reason = "AcceleratorForMillions exceeds max length of 1."; }
            if (!["M"].includes(String(field))) { isValid = false; reason = "Only 'M' allowed."; }
            }
            }
        }
      
        else if (field === row.Email) {
          if (row.Action === "E" || row.Action === "e") {
            if (!field) { isValid = true; reason = "email should be blank for Edit action."; }
            else{
              if (String(field).length > 80) { isValid = false; reason = "email exceeds max length of 80."; }
              if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid email format."; }
            }
          } else if (row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = false; reason = "email should not be blank for Add action."; }
            if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid email format."; }
            if (String(field).length > 80) { isValid = false; reason = "email exceeds max length of 80."; }
          }
        }
      
      
        if (!isValid) {
          console.log(`Validation Failed: ${reason}`);
        } else {
          console.log(`Validation Passed: ${field}`);
        }
        
        return isValid;
      });
      
      if (hasNotError) {
        successfulRecordsArray.push(row);
      }
      
      console.log(`Final hasNotError status: ${hasNotError}`);
      
          return {
            ...row,
            successfulrecords: hasNotError, // Mark as successful if no error
      
            isDuplicateUserId,
            isDuplicateInDB,
            userExists,
      
            Action: row.Action ? (
              String(row.Action).length === 1 ? (
                ["A", "a", "E", "e"].includes(row.Action) ? (
                  row.Action // Valid Action ("A", "E", "a", "e")
                ) : (
                  <span style={{ color: "red" }}>
                    {row.Action} (Only "A", "E" are allowed)
                  </span>
                )
              ) : (
                <span style={{ color: "red" }}>
                  {row.Action} (Max. allowed character is 1)
                </span>
              )
            ) : (
              <span style={{ color: "red" }}>Shouldn't be blank</span>
            ),
      
            SLNo: row.SLNo ? (
                isNaN(row.SLNo) ? (
                  <span style={{ color: "red"}}>
                    {row.SLNo} (Mismatched Data Type)
                  </span>
                ) : String(row.SLNo).length > 5 ? (
                  <span style={{ color: "red"}}>
                    {row.SLNo} (Max. allowed character is 5)
                  </span>
                ) : (
                  row.SLNo // Valid SLNo
                )
              ) : (
                <span style={{ color: "red"}}>Shouldn't be blank</span>
              ),
              
      
              UserID: row.UserID ? (
                String(row.UserID).length > 12 ? (
                  <span style={{ color: "red" }}>
                    {row.UserID} (Max. allowed character is 12)
                  </span>
                ) : row.Action === "A" || row.Action === "a" ? (
                  // When Action is "A", show duplicate message if user ID is a duplicate
                  (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
                  (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                    <span style={{ color: "red" }}>
                      {row.UserID} (User ID Duplicate exists)
                    </span>
                  ) : (
                    String(row.UserID) // Otherwise, show userId normally
                  )
                ) : row.Action === "E" || row.Action === "e" ? (
                  // When Action is "E", show userId without the duplicate message
                  (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
                  (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                    <span>
                      {row.UserID} 
                    </span> 
                  ) : (
                    // String(row.userId) // Otherwise, show userId normally
                    <span style={{ color: "red" }}>
                    {row.UserID} (User ID not exists)
                  </span>
                  )
                ) : (
                  String(row.UserID) // Default case, show userId normally
                )
              ) : (
                <span style={{ color: "red" }}>
                  Shouldn't be blank
                </span>
              ),
      
              
              Username: row.Username ? (
                String(row.Username).length > 12 ? (
                  <span style={{ color: "red" }}>
                    {row.Username} (Max. allowed character is 12)
                  </span>
                ) : (
                  String(row.Username) // Ensure Username is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
      
        
              FirstName: row.FirstName ? (
                String(row.FirstName).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.FirstName} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.FirstName) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              MiddleName: row.MiddleName ? (
                String(row.MiddleName).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.MiddleName} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.MiddleName) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              AccountingSystemId: row.AccountingSystemId ? (
                String(row.AccountingSystemId).length > 12 ? (
                  <span style={{ color: "red"}}>
                    {row.AccountingSystemId} (Max. allowed character is 12)
                  </span>
                ) : (
                    String(row.AccountingSystemId) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              LastName: row.LastName ? (
                String(row.LastName).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.LastName} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.LastName) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              MobileNo: row.MobileNo ? (
                isNaN(row.MobileNo) ? (
                  <span style={{ color: "red"}}>
                    {row.MobileNo} (Mismatched Data Type)
                  </span>
                ) : String(row.MobileNo).length > 13 ? (
                  <span style={{ color: "red"}}>
                    {row.MobileNo} (Max. allowed character is 13)
                  </span>
                ) : (
                  row.MobileNo // Valid MobileNo
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              WhatsAppNo: row.WhatsAppNo ? (
                isNaN(row.WhatsAppNo) ? (
                  <span style={{ color: "red"}}>
                    {row.WhatsAppNo} (Mismatched Data Type)
                  </span>
                ) : String(row.WhatsAppNo).length > 13 ? (
                  <span style={{ color: "red"}}>
                    {row.WhatsAppNo} (Max. allowed character is 13)
                  </span>
                ) : (
                  row.WhatsAppNo // Valid WhatsAppNo
                )
              ) : (
                <span> </span>
              ),
        
              OfficeNo: row.OfficeNo ? (
                isNaN(row.OfficeNo) ? (
                  <span style={{ color: "red"}}>
                    {row.OfficeNo} (Mismatched Data Type)
                  </span>
                ) : String(row.OfficeNo).length > 13 ? (
                  <span style={{ color: "red"}}>
                    {row.OfficeNo} (Max. allowed character is 13)
                  </span>
                ) : (
                  row.OfficeNo // Valid OfficeNo
                )
              ) : (
                <span> </span>
              ),
              Extension: row.Extension ? (
                isNaN(row.Extension) ? (
                  <span style={{ color: "red"}}>
                    {row.Extension} (Mismatched Data Type)
                  </span>
                ) : String(row.Extension).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.Extension} (Max. allowed character is 10)
                  </span>
                ) : (
                  row.Extension // Valid Extension
                )
              ) : (
                <span> </span>
              ),
        
              PassportNo: row.PassportNo ? (
                String(row.PassportNo).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.PassportNo} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.PassportNo) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              DrivingLicenseNo: row.DrivingLicenseNo ? (
                String(row.DrivingLicenseNo).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.DrivingLicenseNo} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.DrivingLicenseNo) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              LocalId: row.LocalId ? (
                isNaN(row.LocalId) ? (
                  <span style={{ color: "red"}}>
                    {row.LocalId} (Mismatched Data Type)
                  </span>
                ) : String(row.LocalId).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.LocalId} (Max. allowed character is 20)
                  </span>
                ) : (
                  row.LocalId // Valid LocalId
                )
              ) : (
                <span> </span>
              ),
        
              Nationality: row.Nationality ? (
                String(row.Nationality).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.Nationality} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.Nationality) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              EmployeeNo: row.EmployeeNo ? (
                String(row.EmployeeNo).length > 12 ? (
                  <span style={{ color: "red"}}>
                    {row.EmployeeNo} (Max. allowed character is 12)
                  </span>
                ) : (
                    String(row.EmployeeNo) // Ensure userId is treated as a string
                )
              ) : (
                <span>
               
                </span>
              ),
        
              CompanyCodeId: row.CompanyCodeId ? (
                String(row.CompanyCodeId).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.CompanyCodeId} (Max. allowed character is 10)
                  </span>
                ) : CompanyExists ? (
                  CompanyGreenExists ? (
                  <span>{row.CompanyCodeId}</span>
                ): (
                  <span style={{ color: "red" }}>
                    {row.CompanyCodeId} (Company is not approved.)
                  </span>
                )
              ) : (
                 <span style={{ color: "red" }}>
                    {row.CompanyCodeId} (Company is not exists.)
                </span>
              )
            ):(
              <span></span>
            ),
      
      
              Department: row.Department ? (
                String(row.Department).length > 6 ? (
                  <span style={{ color: "red"}}>
                    {row.Department} (Max. allowed character is 6)
                  </span>
                ) : (
                    String(row.Department) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
              ValidFrom: row.ValidFrom ? (
                String(row.ValidFrom).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.ValidFrom} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.ValidFrom) // Ensure userId is treated as a string
                )
              ) : (
                
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
                
      
              ),
        
              ValidTo: row.ValidTo ? (
                String(row.ValidTo).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.ValidTo} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.ValidTo) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
           
              ResignationDate: row.ResignationDate ? (
                String(row.ResignationDate).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.ResignationDate} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.ResignationDate) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                
                </span>
              ),
        
              JoiningDate: row.JoiningDate ? (
                String(row.JoiningDate).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.JoiningDate} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.JoiningDate) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                
                </span>
              ),
        
              Address1: row.Address1 ? (
                String(row.Address1).length > 50 ? (
                  <span style={{ color: "red"}}>
                    {row.Address1} (Max. allowed character is 50)
                  </span>
                ) : (
                    String(row.Address1) // Ensure userId is treated as a string
                )
              ) : (
      
                row.Action === "A" || row.Action === "a" ? (
                  
                !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.AreaName && !row.Phase && !row.Sector && !row.Village && !row.Town && !row.CityName && !row.Address2 && !row.Address3
                // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
                ? (
                  <span style={{ color: "red" }}>
                      Either Address Section or Building, Street, and Area Section must be entered
                  </span>
              ) : (
                  <span></span>
              )
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
      
                
              ),
        
              Address2: row.Address2 ? (
                String(row.Address2).length > 50 ? (
                  <span style={{ color: "red"}}>
                    {row.Address2} (Max. allowed character is 50)
                  </span>
                ) : (
                    String(row.Address2) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              Address3: row.Address3 ? (
                String(row.Address3).length > 50 ? (
                  <span style={{ color: "red"}}>
                    {row.Address3} (Max. allowed character is 50)
                  </span>
                ) : (
                    String(row.Address3) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              CountryId: row.CountryId ? (
                String(row.CountryId).length > 2 ? (
                  <span style={{ color: "red"}}>
                    {row.CountryId} (Max. allowed character is 2)
                  </span>
                ) : countryIDExists && displayOption !== "" ? (
                  <span>{row.CountryId}</span>
                ) : (
                  <span style={{ color: "red" }}>
                    {row.CountryId} (Country Code not exists)
                  </span>
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
            
              StateProvince: row.StateProvince ? (
                String(row.StateProvince).length > 3 ? (
                  <span style={{ color: "red"}}>
                    {row.StateProvince} (Max. allowed character is 3)
                  </span>
                ) :  stateIDExists && displayOption !== "" ? (
                  <span>{row.StateProvince}</span>
                ) : (
                  <span style={{ color: "red" }}>
                    {row.StateProvince} (StateProvince Code not exists)
                  </span>
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              Language: row.Language ? (
                String(row.Language).length > 3 ? (
                  <span style={{ color: "red"}}>
                    {row.Language} (Max. allowed character is 3)
                  </span>
                ) : languageExists ?
                    ( <span>{row.Language}</span> ) :
                    (
                      <span style={{ color: "red" }}>
                      {row.Language} (Language Code not exists)
                    </span>
                    )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
              
              DateFormat: row.DateFormat ? (
                String(row.DateFormat).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.DateFormat} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.DateFormat) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              TimeFormat: row.TimeFormat ? (
                String(row.TimeFormat).length > 8 ? (
                  <span style={{ color: "red"}}>
                    {row.TimeFormat} (Max. allowed character is 8)
                  </span>
                ) : (
                    String(row.TimeFormat) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              AmountFormat: row.AmountFormat ? (
                String(row.AmountFormat).length > 19 ? (
                  <span style={{ color: "red"}}>
                    {row.AmountFormat} (Max. allowed character is 19)
                  </span>
                ) : (
                    String(row.AmountFormat) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
                AcceleratorForThousands: row.AcceleratorForThousands ? ( 
                  String(row.AcceleratorForThousands).length > 1 ? (
                      <span style={{ color: "red"}}>
                          {row.AcceleratorForThousands} (Max. allowed character is 1)
                      </span>
                  ) : !["K", "T"].includes(String(row.AcceleratorForThousands)) ? (
                      <span style={{ color: "red"}}>
                          {row.AcceleratorForThousands} (Only 'K' or 'T' allowed)
                      </span>
                  ) : (
                      <span>{row.AcceleratorForThousands}</span>
                  )
              ) : (
                  <span></span>
              ),
      
        
              AcceleratorForLakhs: row.AcceleratorForLakhs ? (
                String(row.AcceleratorForLakhs).trim() === "L" ? (
                    <span>{row.AcceleratorForLakhs}</span>
                ) : (
                    <span style={{ color: "red" }}>
                        {row.AcceleratorForLakhs} (Only 'L' allowed)
                    </span>
                )
            ) : (
                <span></span>
            ),
      
            AcceleratorForMillions: row.AcceleratorForMillions ? (
              String(row.AcceleratorForMillions).trim() === "M" ? (
                  <span>{row.AcceleratorForMillions}</span>
              ) : (
                  <span style={{ color: "red" }}>
                      {row.AcceleratorForMillions} (Only 'M' allowed)
                  </span>
              )
          ) : (
              <span></span>
          ),
      
      
      
              Email: row.Email ? (
                String(row.Email).length > 80 ? (
                  <span style={{ color: "red"}}>
                    {row.Email} (Max. allowed character is 80)
                  </span>
                ) : !validateEmail(row.Email) ? (
                  <span style={{ color: "red"}}>
                    {row.Email} (Invalid Email format)
                  </span>
                ) : (
                  row.Email // If valid, display the email normally
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ), 
          
            };  
        }));
        setAccountNoUpdateSuccessfulRecords(successfulRecordsArray);
        return successfulRecordsArray;
      
      }
  
      const FullprocessData = async (data) => {
        const successfulRecordsArray = [];
        
        const userIds = data.map(row => row.UserID).filter(Boolean);
        const countryIds = data.map(row => row.CountryId).filter(Boolean);
        console.log("Extracted userIds:", userIds);
        console.log("Extracted countryIds:", countryIds);
      
        const duplicateUserIdsFromDB = await checkDuplicateUserIdsFromDB(userIds);
      
        const results = await Promise.all(data.map(async (row, _, allRows) => {
      
        // Check for duplicate same userId is repeated in the data
        const isDuplicateUserId = allRows.filter(r => r.UserID == row.UserID).length > 1;
        console.log("Duplicate user id is ",isDuplicateUserId);
      
       // Check for duplicate userId in the database
       const isDuplicateInDB = duplicateUserIdsFromDB.includes(row.UserID);
      
      const normalize = (value) => String(value).trim().toUpperCase();
      const userExists = getExistsUserids.map(normalize).includes(normalize(row.UserID));
      const languageExists = getExistsLanguageids.map(normalize).includes(normalize(row.Language));
      const CompanyExists = companies.map(normalize).includes(normalize(row.CompanyCodeId));
      const CompanyGreenExists = companiesGreen.map(normalize).includes(normalize(row.CompanyCodeId));
      const countryIDExists = countryCodes.map(normalize).includes(normalize(row.CountryId));
      // const stateIDExists = stateCodes.map(normalize).includes(normalize(row.StateProvince));
      const stateIDExists = stateCodes.some((entry) =>
        normalize(entry.state) === normalize(row.StateProvince) &&
        normalize(entry.countryCode) === normalize(row.CountryId)
      );
      
       const hasNotError = [
        row.SLNo,
        row.Action,
        row.UserID,
        row.Username,
        row.FirstName,
        row.MiddleName,
        row.LastName,
        row.AccountingSystemId,
        row.MobileNo,
        row.WhatsAppNo,
        row.OfficeNo,
        row.Extension,
        row.PassportNo,
        row.DrivingLicenseNo,
        row.LocalId,
        row.Nationality,
        row.EmployeeNo,
        row.CompanyCodeId,
        row.Department,
        row.ValidFrom,
        row.ValidTo,
        row.ResignationDate,
        row.JoiningDate,
        row.Address1,
        row.Address2,
        row.Address3,
        row.CountryId,
        row.StateProvince,
        row.Language,
        row.DateFormat,
        row.TimeFormat,
        row.AmountFormat,
        // row.ThousandSeparator,
        // row.DecimalSeparator,
        row.AcceleratorForThousands,
        row.AcceleratorForLakhs,
        row.AcceleratorForMillions,
        row.Email
      
      ].every(field => {
        let isValid = true;
        let reason = "";
        
        if (field === row.SLNo) {
          if (!field) { isValid = false; reason = "SLNo should not be blank."; }
          else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
          else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
        }
        
        else if (field === row.Action) {
          if (!field) { isValid = false; reason = "Action should not be blank."; }
          else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
        }
        
        
        if (!row.UserID || String(row.UserID).trim() === "") {
          if (row.Action === "A" || row.Action === "a" || row.Action === "E" || row.Action === "e") {
            isValid = false;
            reason = "User ID should not be empty.";
        }
        } else if (field === row.UserID) {
            if ((row.Action === "E" || row.Action === "e") && !userExists  ) {
              isValid = false; reason = "User ID does not exist.";
            } else if ((row.Action === "A" || row.Action === "a") && (isDuplicateUserId || isDuplicateInDB)) {
              isValid = false; reason = "User ID is duplicate.";
            }
        }
      
        else if (field === row.Username) {
          if (row.Action === "E" || row.Action === "e") {
            if (!field) { isValid = true; reason = "Username should be blank for Edit action."; }
            else if (String(field).length > 12) { isValid = false; reason = "Username exceeds max length of 12."; }
          } else if (row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = false; reason = "Username should not be blank for Add action."; }
            if (String(field).length > 12) { isValid = false; reason = "Username exceeds max length of 12."; }
          }
        }
        
        else if (field === row.FirstName) {
          if (row.Action === "E" || row.Action === "e") {
            if (!field) { isValid = true; reason = "firstName should be blank for Edit action."; }
            else if (String(field).length > 20) { isValid = false; reason = "firstName exceeds max length of 20."; }
          } else if (row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = false; reason = "firstName should not be blank for Add action."; }
            if (String(field).length > 20) { isValid = false; reason = "firstName exceeds max length of 20."; }
          }
        }
      
        else if (field === row.MiddleName) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "middleName should be blank for Add/Edit action."; }
            else if (String(field).length > 20) { isValid = false; reason = "middleName exceeds max length of 20."; }
          }
        }
      
        if (!row.LastName || String(row.LastName).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "LastName should not be empty.";
        }
        } else if (field === row.LastName) {
            if (row.Action === "E" || row.Action === "e") {
              if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "LastName should be blank for Edit action.";
                } else if (String(field).trim().length > 20) {
                    isValid = false;
                    reason = "LastName exceeds max length of 20.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
              if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "LastName should not be blank for Add action.";
                } else if (String(field).trim().length > 20) {
                    isValid = false;
                    reason = "LastName exceeds max length of 20.";
                }
            }
        }
      
        else if (field === row.AccountingSystemId) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "AccountingSystemId should be blank for Add/Edit action."; }
            else if (String(field).length > 12) { isValid = false; reason = "AccountingSystemId exceeds max length of 12."; }
          }
        }
      
        if (!row.MobileNo || String(row.MobileNo).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "MobileNo should not be empty.";
        }
      } else if (field === row.MobileNo) {
          const mobileNoStr = String(field); // Ensure it's a string
      
          if (row.Action === "E" || row.Action === "e") {
              if (mobileNoStr.trim() === "") {
                  isValid = true;
                  reason = "Mobile No should be blank for Edit action.";
              } else {
                  if (isNaN(mobileNoStr)) {
                      isValid = false;
                      reason = "Mobile No should be a valid number.";
                  }
                  if (mobileNoStr.length > 13) {
                      isValid = false;
                      reason = "Mobile No exceeds max length of 13.";
                  }
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (mobileNoStr.trim() === "") {
                  isValid = false;
                  reason = "Mobile No should not be blank for Add action.";
              } else {
                  if (isNaN(mobileNoStr)) {
                      isValid = false;
                      reason = "Mobile No should be a valid number.";
                  }
                  if (mobileNoStr.length > 13) {
                      isValid = false;
                      reason = "Mobile No exceeds max length of 13.";
                  }
              }
          }
      }
      
        
        else if (field === row.WhatsAppNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "whatsApp No should be blank for Edit action."; }
            else{
              if (isNaN(field)) { isValid = false; reason = "whatsApp No should be a valid number."; }
              if (String(field).length > 13) { isValid = false; reason = "whatsApp No exceeds max length of 13."; }
            }
          } 
        }
      
        else if (field === row.OfficeNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "office No should be blank for Edit action."; }
            else{
            if (isNaN(field)) { isValid = false; reason = "office No should be a valid number."; }
            if (String(field).length > 13) { isValid = false; reason = "office No exceeds max length of 13."; }
          } 
        } 
        }
      
        else if (field === row.Extension) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "extension should be blank for Edit action."; }
            else{
            if (isNaN(field)) { isValid = false; reason = "extension should be a valid number."; }
            if (String(field).length > 10) { isValid = false; reason = "extension exceeds max length of 10."; }
          } 
        } 
        }
      
        else if (field === row.PassportNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "passportNo should be blank for Add/Edit action."; }
            else if (String(field).length > 10) { isValid = false; reason = "passportNo exceeds max length of 10."; }
      
          }
        }
      
        else if (field === row.DrivingLicenseNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "drivingLicense No should be blank for Add/Edit action."; }
            else if (String(field).length > 20) { isValid = false; reason = "drivingLicense No exceeds max length of 20."; }
          }
        }
      
        else if (field === row.LocalId) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "localId should be blank for Add/Edit action."; }
            else if (String(field).length > 20) { isValid = false; reason = "localId exceeds max length of 20."; }
          }
        }
      
        
        if (!row.Nationality || String(row.Nationality).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "Nationality should not be empty.";
        }
        } else if (field === row.Nationality) {
            if (row.Action === "E" || row.Action === "e") {
                if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "Nationality should be blank for Edit action.";
                } else if (String(field).trim().length > 20) {
                    isValid = false;
                    reason = "Nationality exceeds max length of 20.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
                if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "Nationality should not be blank for Add action.";
                } else if (String(field).trim().length > 20) {
                    isValid = false;
                    reason = "Nationality exceeds max length of 20.";
                }
            }
        }
      
        else if (field === row.EmployeeNo) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "employeeNo should be blank for Add/Edit action."; }
            else if (String(field).length > 12) { isValid = false; reason = "employeeNo exceeds max length of 12."; }
          }
        }
      
        if (row.CompanyCodeId) {
          if (String(field).length > 10) { isValid = false; reason = "companyCodeId exceeds max length of 10."; }
      
          if(CompanyExists){
            if(!CompanyGreenExists){
               isValid = false;
               reason = "CompanyCode is not exists because of the flag status";
            }
          }
          else{
            isValid = false;
            reason = "CompanyCode is not exists";
          }
      
        }
      
        else if (field === row.Department) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "department should be blank for Add/Edit action."; }
            else if (String(field).length > 6) { isValid = false; reason = "department exceeds max length of 6."; }
          }
        }
      
        if (!row.ValidFrom || String(row.ValidFrom).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "ValidFrom should not be empty.";
        }
      } else if (field === row.ValidFrom) {
          const validFromStr = String(field).trim(); // Ensure it's a string
      
          if (row.Action === "E" || row.Action === "e") {
              if (validFromStr === "") {
                  isValid = true;
                  reason = "ValidFrom should be blank for Edit action.";
              } else if (validFromStr.length > 10) {
                  isValid = false;
                  reason = "ValidFrom exceeds max length of 10.";
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (validFromStr === "") {
                  isValid = false;
                  reason = "ValidFrom should not be blank for Add action.";
              } else if (validFromStr.length > 10) {
                  isValid = false;
                  reason = "ValidFrom exceeds max length of 10.";
              }
          }
      }
      
      
        if (!row.ValidTo || String(row.ValidTo).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "ValidTo should not be empty.";
        }
      } else if (field === row.ValidTo) {
          const ValidToStr = String(field).trim(); // Ensure it's a string
      
          if (row.Action === "E" || row.Action === "e") {
              if (ValidToStr === "") {
                  isValid = true;
                  reason = "ValidTo should be blank for Edit action.";
              } else if (ValidToStr.length > 10) {
                  isValid = false;
                  reason = "ValidTo exceeds max length of 10.";
              }
          } else if (row.Action === "A" || row.Action === "a") {
              if (ValidToStr === "") {
                  isValid = false;
                  reason = "ValidTo should not be blank for Add action.";
              } else if (ValidToStr.length > 10) {
                  isValid = false;
                  reason = "ValidTo exceeds max length of 10.";
              }
          }
      }
      
        else if (field === row.ResignationDate) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "ResignationDate should be blank for Add/Edit action."; }
            else if (String(field).length > 10) { isValid = false; reason = "ResignationDate exceeds max length of 10."; }
          }
        }
      
        else if (field === row.JoiningDate) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "joiningDate should be blank for Add/Edit action."; }
            else if (String(field).length > 10) { isValid = false; reason = "joiningDate exceeds max length of 10."; }
          }
        }
      
        if (row.Address1 && String(row.Address1).length > 50) {
          isValid = false;
          reason = "Address1 exceeds max length of 50.";
      } else {
      // field = row.Address1 || "";  
      
          const missingFields = [
            row.DoorNo, row.FloorNo, row.BuildingNo, row.BuildingName, row.LandMark,
            row.StreetNo, row.StreetName, row.RoadNo, row.RoadName, row.Block,
            row.AreaName, row.Phase, row.Sector, row.Village, row.Town,
            row.CityName,  row.Address1, row.Address2, row.Address3
        ].every(value => !value); // Check if all values are falsy (undefined, null, empty, false)
        
        if (row.Action === "E" || row.Action === "e") {
            if (!field) { 
                isValid = true; 
                reason = "Address1 should be blank for Edit action."; 
            } else if (field.length > 50) { 
                isValid = false; 
                reason = "Address1 exceeds max length of 50."; 
            }
        } else if (row.Action === "A" || row.Action === "a") {
            console.log("Address field 1 enter log");
            console.log("check door no:", row.DoorNo);
        
            if (missingFields) { 
                isValid = false; 
                reason = "DoorNo, FloorNo, BuildingNo, BuildingName, and Address1 should not be blank for Add action."; 
            }
        }
        }
         
        // Ensure field is explicitly assigned to row.Address1 if available
      
      // Check if all address-related fields are missing
      
      
        if (field === row.Address2) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "addressLine2 should be blank for Add/Edit action."; }
            else if (String(field).length > 50) { isValid = false; reason = "addressLine2 exceeds max length of 50."; }
          }
        }
      
        if (field === row.Address3) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "addressLine3 should be blank for Add/Edit action."; }
            else if (String(field).length > 50) { isValid = false; reason = "addressLine3 exceeds max length of 50."; }
          }
        }
      
      
        if (!row.CountryId || String(row.CountryId).trim() === "") {
          if (row.Action === "A" || row.Action === "a" ) {
            isValid = false;
            reason = "CountryId should not be empty.";
          }
      } else if (field === row.CountryId) {
          if (!countryIDExists) {
              isValid = false;
              reason = "Country code is Not exists."; 
          }
          if (String(field).trim().length > 2) {
            isValid = false;
            reason = "CountryId exceeds max length of 2.";
        }
      }
      
      
      
      // if (!row.StateProvince || String(row.StateProvince).trim() === "") {
      //   if (row.Action === "A" || row.Action === "a" ) {
      //     isValid = false;
      //     reason = "StateProvince should not be empty.";
      //   }
      // } else 
      if (row.StateProvince) {
        if (!stateIDExists) {
            isValid = false;
            reason = "StateProvince is Not exists."; 
        }
        if (String(row.StateProvince).trim().length > 2) {
          isValid = false;
          reason = "StateProvince exceeds max length of 2.";
      }
      }
      
        //  if (field === row.StateProvince) {
        //   if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
        //     if (!field) { isValid = true; reason = "StateProvince should be blank for Add/Edit action."; }
        //     else if (String(field).length > 2) { isValid = false; reason = "StateProvince exceeds max length of 2."; }
        //   }
        // }
      
      
        if ((!row.Language || String(row.Language).trim() === "") && (row.Action === "A" || row.Action === "a")) {
            isValid = false;
            reason = "Language should not be empty.";
        
        } else if (field === row.Language) {
          if(!languageExists){
            isValid = false;
            reason = "language is not exists. ";
        }
            if (row.Action === "E" || row.Action === "e") {
                if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "Language should be blank for Edit action.";
                } else if (String(field).trim().length > 2) {
                    isValid = false;
                    reason = "Language exceeds max length of 2.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
             
      
                if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "Language should not be blank for Add action.";
                } else if (String(field).trim().length > 2) {
                    isValid = false;
                    reason = "Language exceeds max length of 2.";
                }
            }
        }
      
      
        if (!row.DateFormat || String(row.DateFormat).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "DateFormat should not be empty.";
        }
        } else if (field === row.DateFormat) {
            if (row.Action === "E" || row.Action === "e") {
                if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "DateFormat should be blank for Edit action.";
                } else if (String(field).trim().length > 10) {
                    isValid = false;
                    reason = "DateFormat exceeds max length of 10.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
                if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "DateFormat should not be blank for Add action.";
                } else if (String(field).trim().length > 10) {
                    isValid = false;
                    reason = "DateFormat exceeds max length of 10.";
                }
            }
        }
      
        if (!row.TimeFormat || String(row.TimeFormat).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "TimeFormat should not be empty.";
        }
        } else if (field === row.TimeFormat) {
            if (row.Action === "E" || row.Action === "e") {
                if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "TimeFormat should be blank for Edit action.";
                } else if (String(field).trim().length > 8) {
                    isValid = false;
                    reason = "TimeFormat exceeds max length of 8.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
                if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "TimeFormat should not be blank for Add action.";
                } else if (String(field).trim().length > 8) {
                    isValid = false;
                    reason = "TimeFormat exceeds max length of 8.";
                }
            }
        }
      
        if (!row.AmountFormat || String(row.AmountFormat).trim() === "") {
          if (row.Action === "A" || row.Action === "a") {
            isValid = false;
            reason = "AmountFormat should not be empty.";
        }
        } else if (field === row.AmountFormat) {
            if (row.Action === "E" || row.Action === "e") {
              if (!String(field || "").trim()) {
                    isValid = true;
                    reason = "AmountFormat should be blank for Edit action.";
                } else if (String(field).trim().length > 19) {
                    isValid = false;
                    reason = "AmountFormat exceeds max length of 19.";
                }
            } else if (row.Action === "A" || row.Action === "a") {
              if (!String(field || "").trim()) {
                    isValid = false;
                    reason = "AmountFormat should not be blank for Add action.";
                } else if (String(field).trim().length > 19) {
                    isValid = false;
                    reason = "AmountFormat exceeds max length of 19.";
                }
            }
        }
      
        else if (field === row.AcceleratorForThousands) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "AcceleratorForThousands should be blank for Add/Edit action."; }
          else{
            if (String(field).length > 1) { isValid = false; reason = "AcceleratorForThousands exceeds max length of 1."; }
            if (!["K", "T"].includes(String(field))) { isValid = false; reason = "Only 'K' or 'T' allowed."; }
            }
            }
        }
      
        else if (field === row.AcceleratorForLakhs) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "AcceleratorForLakhs should be blank for Add/Edit action."; }
          else{
            if (String(field).length > 1) { isValid = false; reason = "AcceleratorForLakhs exceeds max length of 1."; }
            if (!["L"].includes(String(field))) { isValid = false; reason = "Only 'L' allowed."; }
            }
            }
        }
      
        
        else if (field === row.AcceleratorForMillions) {
          if (row.Action === "E" || row.Action === "e" || row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = true; reason = "AcceleratorForMillions should be blank for Add/Edit action."; }
          else{
            if (String(field).length > 1) { isValid = false; reason = "AcceleratorForMillions exceeds max length of 1."; }
            if (!["M"].includes(String(field))) { isValid = false; reason = "Only 'M' allowed."; }
            }
            }
        }
      
        else if (field === row.Email) {
          if (row.Action === "E" || row.Action === "e") {
            if (!field) { isValid = true; reason = "email should be blank for Edit action."; }
            else{
              if (String(field).length > 80) { isValid = false; reason = "email exceeds max length of 80."; }
              if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid email format."; }
            }
          } else if (row.Action === "A" || row.Action === "a") {
            if (!field) { isValid = false; reason = "email should not be blank for Add action."; }
            if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(field)) { isValid = false; reason = "Invalid email format."; }
            if (String(field).length > 80) { isValid = false; reason = "email exceeds max length of 80."; }
          }
        }
      
      
        if (!isValid) {
          console.log(`Validation Failed: ${reason}`);
        } else {
          console.log(`Validation Passed: ${field}`);
        }
        
        return isValid;
      });
      
      if (hasNotError) {
        successfulRecordsArray.push(row);
      }
      
      console.log(`Final hasNotError status: ${hasNotError}`);
      
          return {
            ...row,
            successfulrecords: hasNotError, // Mark as successful if no error
      
            isDuplicateUserId,
            isDuplicateInDB,
            userExists,
      
            Action: row.Action ? (
              String(row.Action).length === 1 ? (
                ["A", "a", "E", "e"].includes(row.Action) ? (
                  row.Action // Valid Action ("A", "E", "a", "e")
                ) : (
                  <span style={{ color: "red" }}>
                    {row.Action} (Only "A", "E" are allowed)
                  </span>
                )
              ) : (
                <span style={{ color: "red" }}>
                  {row.Action} (Max. allowed character is 1)
                </span>
              )
            ) : (
              <span style={{ color: "red" }}>Shouldn't be blank</span>
            ),
      
            SLNo: row.SLNo ? (
                isNaN(row.SLNo) ? (
                  <span style={{ color: "red"}}>
                    {row.SLNo} (Mismatched Data Type)
                  </span>
                ) : String(row.SLNo).length > 5 ? (
                  <span style={{ color: "red"}}>
                    {row.SLNo} (Max. allowed character is 5)
                  </span>
                ) : (
                  row.SLNo // Valid SLNo
                )
              ) : (
                <span style={{ color: "red"}}>Shouldn't be blank</span>
              ),
              
      
              UserID: row.UserID ? (
                String(row.UserID).length > 12 ? (
                  <span style={{ color: "red" }}>
                    {row.UserID} (Max. allowed character is 12)
                  </span>
                ) : row.Action === "A" || row.Action === "a" ? (
                  // When Action is "A", show duplicate message if user ID is a duplicate
                  (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
                  (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                    <span style={{ color: "red" }}>
                      {row.UserID} (User ID Duplicate exists)
                    </span>
                  ) : (
                    String(row.UserID) // Otherwise, show userId normally
                  )
                ) : row.Action === "E" || row.Action === "e" ? (
                  // When Action is "E", show userId without the duplicate message
                  (uploadOption !== '' && displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) || 
                  (displayOption !== '' && (isDuplicateUserId || isDuplicateInDB)) ? (
                    <span>
                      {row.UserID} 
                    </span> 
                  ) : (
                    // String(row.userId) // Otherwise, show userId normally
                    <span style={{ color: "red" }}>
                    {row.UserID} (User ID not exists)
                  </span>
                  )
                ) : (
                  String(row.UserID) // Default case, show userId normally
                )
              ) : (
                <span style={{ color: "red" }}>
                  Shouldn't be blank
                </span>
              ),
      
              
              Username: row.Username ? (
                String(row.Username).length > 12 ? (
                  <span style={{ color: "red" }}>
                    {row.Username} (Max. allowed character is 12)
                  </span>
                ) : (
                  String(row.Username) // Ensure Username is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
      
        
              FirstName: row.FirstName ? (
                String(row.FirstName).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.FirstName} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.FirstName) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              MiddleName: row.MiddleName ? (
                String(row.MiddleName).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.MiddleName} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.MiddleName) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              AccountingSystemId: row.AccountingSystemId ? (
                String(row.AccountingSystemId).length > 12 ? (
                  <span style={{ color: "red"}}>
                    {row.AccountingSystemId} (Max. allowed character is 12)
                  </span>
                ) : (
                    String(row.AccountingSystemId) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              LastName: row.LastName ? (
                String(row.LastName).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.LastName} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.LastName) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              MobileNo: row.MobileNo ? (
                isNaN(row.MobileNo) ? (
                  <span style={{ color: "red"}}>
                    {row.MobileNo} (Mismatched Data Type)
                  </span>
                ) : String(row.MobileNo).length > 13 ? (
                  <span style={{ color: "red"}}>
                    {row.MobileNo} (Max. allowed character is 13)
                  </span>
                ) : (
                  row.MobileNo // Valid MobileNo
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              WhatsAppNo: row.WhatsAppNo ? (
                isNaN(row.WhatsAppNo) ? (
                  <span style={{ color: "red"}}>
                    {row.WhatsAppNo} (Mismatched Data Type)
                  </span>
                ) : String(row.WhatsAppNo).length > 13 ? (
                  <span style={{ color: "red"}}>
                    {row.WhatsAppNo} (Max. allowed character is 13)
                  </span>
                ) : (
                  row.WhatsAppNo // Valid WhatsAppNo
                )
              ) : (
                <span> </span>
              ),
        
              OfficeNo: row.OfficeNo ? (
                isNaN(row.OfficeNo) ? (
                  <span style={{ color: "red"}}>
                    {row.OfficeNo} (Mismatched Data Type)
                  </span>
                ) : String(row.OfficeNo).length > 13 ? (
                  <span style={{ color: "red"}}>
                    {row.OfficeNo} (Max. allowed character is 13)
                  </span>
                ) : (
                  row.OfficeNo // Valid OfficeNo
                )
              ) : (
                <span> </span>
              ),
              Extension: row.Extension ? (
                isNaN(row.Extension) ? (
                  <span style={{ color: "red"}}>
                    {row.Extension} (Mismatched Data Type)
                  </span>
                ) : String(row.Extension).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.Extension} (Max. allowed character is 10)
                  </span>
                ) : (
                  row.Extension // Valid Extension
                )
              ) : (
                <span> </span>
              ),
        
              PassportNo: row.PassportNo ? (
                String(row.PassportNo).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.PassportNo} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.PassportNo) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              DrivingLicenseNo: row.DrivingLicenseNo ? (
                String(row.DrivingLicenseNo).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.DrivingLicenseNo} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.DrivingLicenseNo) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              LocalId: row.LocalId ? (
                isNaN(row.LocalId) ? (
                  <span style={{ color: "red"}}>
                    {row.LocalId} (Mismatched Data Type)
                  </span>
                ) : String(row.LocalId).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.LocalId} (Max. allowed character is 20)
                  </span>
                ) : (
                  row.LocalId // Valid LocalId
                )
              ) : (
                <span> </span>
              ),
        
              Nationality: row.Nationality ? (
                String(row.Nationality).length > 20 ? (
                  <span style={{ color: "red"}}>
                    {row.Nationality} (Max. allowed character is 20)
                  </span>
                ) : (
                    String(row.Nationality) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              EmployeeNo: row.EmployeeNo ? (
                String(row.EmployeeNo).length > 12 ? (
                  <span style={{ color: "red"}}>
                    {row.EmployeeNo} (Max. allowed character is 12)
                  </span>
                ) : (
                    String(row.EmployeeNo) // Ensure userId is treated as a string
                )
              ) : (
                <span>
               
                </span>
              ),
        
              CompanyCodeId: row.CompanyCodeId ? (
                String(row.CompanyCodeId).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.CompanyCodeId} (Max. allowed character is 10)
                  </span>
                ) : CompanyExists ? (
                  CompanyGreenExists ? (
                  <span>{row.CompanyCodeId}</span>
                ): (
                  <span style={{ color: "red" }}>
                    {row.CompanyCodeId} (Company is not approved.)
                  </span>
                )
              ) : (
                 <span style={{ color: "red" }}>
                    {row.CompanyCodeId} (Company is not exists.)
                </span>
              )
            ):(
              <span></span>
            ),
      
      
              Department: row.Department ? (
                String(row.Department).length > 6 ? (
                  <span style={{ color: "red"}}>
                    {row.Department} (Max. allowed character is 6)
                  </span>
                ) : (
                    String(row.Department) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
              ValidFrom: row.ValidFrom ? (
                String(row.ValidFrom).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.ValidFrom} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.ValidFrom) // Ensure userId is treated as a string
                )
              ) : (
                
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
                
      
              ),
        
              ValidTo: row.ValidTo ? (
                String(row.ValidTo).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.ValidTo} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.ValidTo) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
           
              ResignationDate: row.ResignationDate ? (
                String(row.ResignationDate).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.ResignationDate} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.ResignationDate) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                
                </span>
              ),
        
              JoiningDate: row.JoiningDate ? (
                String(row.JoiningDate).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.JoiningDate} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.JoiningDate) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                
                </span>
              ),
        
              Address1: row.Address1 ? (
                String(row.Address1).length > 50 ? (
                  <span style={{ color: "red"}}>
                    {row.Address1} (Max. allowed character is 50)
                  </span>
                ) : (
                    String(row.Address1) // Ensure userId is treated as a string
                )
              ) : (
      
                row.Action === "A" || row.Action === "a" ? (
                  
                !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName && !row.LandMark && !row.StreetNo && !row.StreetName && !row.RoadNo && !row.RoadName && !row.Block && !row.AreaName && !row.Phase && !row.Sector && !row.Village && !row.Town && !row.CityName && !row.Address2 && !row.Address3
                // !row.DoorNo && !row.FloorNo && !row.BuildingNo && !row.BuildingName 
                ? (
                  <span style={{ color: "red" }}>
                      Either Address Section or Building, Street, and Area Section must be entered
                  </span>
              ) : (
                  <span></span>
              )
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
      
                
              ),
        
              Address2: row.Address2 ? (
                String(row.Address2).length > 50 ? (
                  <span style={{ color: "red"}}>
                    {row.Address2} (Max. allowed character is 50)
                  </span>
                ) : (
                    String(row.Address2) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              Address3: row.Address3 ? (
                String(row.Address3).length > 50 ? (
                  <span style={{ color: "red"}}>
                    {row.Address3} (Max. allowed character is 50)
                  </span>
                ) : (
                    String(row.Address3) // Ensure userId is treated as a string
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              CountryId: row.CountryId ? (
                String(row.CountryId).length > 2 ? (
                  <span style={{ color: "red"}}>
                    {row.CountryId} (Max. allowed character is 2)
                  </span>
                ) : countryIDExists && displayOption !== "" ? (
                  <span>{row.CountryId}</span>
                ) : (
                  <span style={{ color: "red" }}>
                    {row.CountryId} (Country Code not exists)
                  </span>
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
            
              StateProvince: row.StateProvince ? (
                String(row.StateProvince).length > 3 ? (
                  <span style={{ color: "red"}}>
                    {row.StateProvince} (Max. allowed character is 3)
                  </span>
                ) :  stateIDExists && displayOption !== "" ? (
                  <span>{row.StateProvince}</span>
                ) : (
                  <span style={{ color: "red" }}>
                    {row.StateProvince} (StateProvince Code not exists)
                  </span>
                )
              ) : (
                <span>
                 
                </span>
              ),
        
              Language: row.Language ? (
                String(row.Language).length > 3 ? (
                  <span style={{ color: "red"}}>
                    {row.Language} (Max. allowed character is 3)
                  </span>
                ) : languageExists ?
                    ( <span>{row.Language}</span> ) :
                    (
                      <span style={{ color: "red" }}>
                      {row.Language} (Language Code not exists)
                    </span>
                    )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
              
              DateFormat: row.DateFormat ? (
                String(row.DateFormat).length > 10 ? (
                  <span style={{ color: "red"}}>
                    {row.DateFormat} (Max. allowed character is 10)
                  </span>
                ) : (
                    String(row.DateFormat) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              TimeFormat: row.TimeFormat ? (
                String(row.TimeFormat).length > 8 ? (
                  <span style={{ color: "red"}}>
                    {row.TimeFormat} (Max. allowed character is 8)
                  </span>
                ) : (
                    String(row.TimeFormat) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
              AmountFormat: row.AmountFormat ? (
                String(row.AmountFormat).length > 19 ? (
                  <span style={{ color: "red"}}>
                    {row.AmountFormat} (Max. allowed character is 19)
                  </span>
                ) : (
                    String(row.AmountFormat) // Ensure userId is treated as a string
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ),
        
                AcceleratorForThousands: row.AcceleratorForThousands ? ( 
                  String(row.AcceleratorForThousands).length > 1 ? (
                      <span style={{ color: "red"}}>
                          {row.AcceleratorForThousands} (Max. allowed character is 1)
                      </span>
                  ) : !["K", "T"].includes(String(row.AcceleratorForThousands)) ? (
                      <span style={{ color: "red"}}>
                          {row.AcceleratorForThousands} (Only 'K' or 'T' allowed)
                      </span>
                  ) : (
                      <span>{row.AcceleratorForThousands}</span>
                  )
              ) : (
                  <span></span>
              ),
      
        
              AcceleratorForLakhs: row.AcceleratorForLakhs ? (
                String(row.AcceleratorForLakhs).trim() === "L" ? (
                    <span>{row.AcceleratorForLakhs}</span>
                ) : (
                    <span style={{ color: "red" }}>
                        {row.AcceleratorForLakhs} (Only 'L' allowed)
                    </span>
                )
            ) : (
                <span></span>
            ),
      
                    AcceleratorForMillions: row.AcceleratorForMillions ? (
                      String(row.AcceleratorForMillions).trim() === "M" ? (
                          <span>{row.AcceleratorForMillions}</span>
                      ) : (
                          <span style={{ color: "red" }}>
                              {row.AcceleratorForMillions} (Only 'M' allowed)
                          </span>
                      )
                  ) : (
                      <span></span>
                  ),
      
      
      
              Email: row.Email ? (
                String(row.Email).length > 80 ? (
                  <span style={{ color: "red"}}>
                    {row.Email} (Max. allowed character is 80)
                  </span>
                ) : !validateEmail(row.Email) ? (
                  <span style={{ color: "red"}}>
                    {row.Email} (Invalid Email format)
                  </span>
                ) : (
                  row.Email // If valid, display the email normally
                )
              ) : (
                row.Action === "A" || row.Action === "a" ? (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
                ) : row.Action === "E" || row.Action === "e" ? (
                  <span></span> // Empty span for "E"
                ) : null // Handle other cases, if any
              ), 
          
            };  
        }));
        setAccountNoUpdateSuccessfulRecords(successfulRecordsArray);
        return results;
      
      }

  // Displaying data based on options
const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
  
  let dataToDisplay;
  if (displayOption === "1") {

    let processed=[];


    if(getExistsUserids && getExistsLanguageids && companies && companiesGreen && countryCodes && stateCodes){
      processed = await SuccessOnlyprocessData(tableData);
    }

    
    const existingUserIds = new Set(processed.map(user => user.UserID));

    dataToDisplay = tableData.filter(user => !existingUserIds.has(user.UserID));

  } else if (displayOption === "2") {
    console.log("Display option is 2");
    dataToDisplay = tableData; // Display tableData if displayOption is "2"
  } else if (uploadOption === "3") {
    console.log("upload option is 3");
    // dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
    dataToDisplay = accountNoUpdatesuccessfulRecords; 
  } 
  else {
    dataToDisplay = tableData; // Default to tableData if no conditions are met
  }

console.log("dataToDisplay data is",dataToDisplay);
return dataToDisplay;
};



async function checkDuplicateUserIdsFromDB(userIds) {
  try {
    const data = { userIds };
    console.log("UserIds duplicated DATA IS",data);
    
    const response = await UserService.saveContactDetails(
      `${process.env.REACT_APP_BASE_URL}/get-Users-duplicates`,
      data
    );

    console.log("existingUserId API Response for duplicates details:", response.duplicates);
    return response.duplicates; // Return an array of duplicate userIds
  } catch (error) {
    console.error("Error checking duplicate userIds from DB:", error);
    return [];
  }
};



useEffect(() => {
  const fetchData = async () => {
    setLoading(true);
    const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
    
    if(getExistsUserids && getExistsLanguageids && companies && companiesGreen && countryCodes && stateCodes){
      const processed = await FullprocessData(dataToDisplay);
    setProcessedData(processed);
    }
    setLoading(false);
  };

  fetchData();
}, [getExistsUserids, getExistsLanguageids,companies,companiesGreen,countryCodes,stateCodes ]);

    const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));


const handleBreadcrumbClick = (crumb) => {
  console.log("Breadcrumb clicked:", crumb.title);
  if (crumb.title === 'Admin') {
    console.log("Navigating to /Admin");
    navigate('/14/admin');
  } else if (crumb.title === 'User & SOD') {
    console.log("Navigating to User & SOD");
    navigate('/14/admin');
  }
  else if (crumb.title === 'User') {
    console.log("Navigating to /User");
    navigate('/14/admin/108/userscreen');
  }
  else if (crumb.title === 'Upload') {
    // console.log("Navigating to /User");
    navigate('/14/admin/108/user-upload');
  }
  else if (crumb.title === 'Task Monitor') {
    console.log("Navigating to Task Monitor");
    navigate('/134/task-monitor');
  }
  else if (crumb.title === 'Attachments') {
    console.log("Navigating to Attachments");
  }
  else if (crumb.title === 'Display') {
    console.log("Navigating to Display");
  }
  else if (crumb.title === "View") {
    console.log("View");
    // navigate("/14/admin");
  }
};


  return (
    <div> 
      {/* <h2>User Upload Display option{displayOption} </h2>
      <h2>User Upload upload option{uploadOption} </h2> 
      <h2>User Upload taskMonitor option{taskMonitor} </h2> */}
   
 {/* Conditional Rendering of Header Component based on taskMonitor */}
 {/* {taskMonitor === '1' ? (
      <Header
        title={`Task Monitor > Attachments > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        backRoute="/134/task-monitor"
      />
    ) : ( */}
      <Header
        title={`Admin > User & SOD > User > Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/14/admin/108/user-upload"
      />
    {/* )} */}

<AdminActions
              icons={iconId}
              buttons={actionButtons}
              // extraButtons={extraButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              // hasFiltersApplied={hasFiltersApplied}
              />

{isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
       {loading &&
          <div><CustomeLoading /></div>
        }

        <ReTable
          data={processedData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        />

    </div>
  );
}
