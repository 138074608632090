import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UserService from '../../../../rest-services/UserServices';
import NoActionLayout from '../../../../components/NoActionLayout';
import { useMemo } from 'react';
import Header from '../../../../components/Header';
import { TbFlag3Filled } from 'react-icons/tb';

export default function UserAuditTrail() {
    const location = useLocation();
    const navigate = useNavigate();
    const { iconId } = useParams(); 
  const { UserId } = useParams(); 

    // const user = location.state?.user;
    const [approved, setApproved] = useState("");

    
    const [auditData, setAuditData] = useState([]);
    const [alert, setAlert] = useState(null);
    const [userId, setUserId] = useState("");

    useEffect(() => {
        if (UserId) {
            setUserId(UserId);
            fetchAuditTrailDetails();
        }
    }, [UserId]);

    const fetchAuditTrailDetails = async () => {
        try {
            console.log("user Id:", { userId: UserId });
            const data = { userId: UserId, iconId };
            console.log("Request Data:", data);

            const response = await UserService.saveContactDetails(
                `${process.env.REACT_APP_BASE_URL}/get-audit-trail-details`,
                data
            );

            console.log("API Response:", response);
            console.log("Response Data:", response.data);

            if (response.data && response.data.length > 0) {
                setAuditData(response.data);
            } else {
                setAlert({
                    severity: "warning",
                    message: "No audit trail data found in response.",
                });
            }
        } catch (error) {
            console.error("Error fetching audit trail details:", error);
            setAlert({
                severity: "error",
                message: "An error occurred while fetching audit trail details.",
            });
        }
    };

    const groupAuditDataByFieldAndTime = (auditData) => {
        return auditData.reduce((acc, audit) => {
            const { field_name, date, time } = audit;
            const dateTimeGroup = `${new Date(date).toLocaleDateString()} ${time}`; // Group by date and time
    
            if (!acc[dateTimeGroup]) {
                acc[dateTimeGroup] = {};
            }
            if (!acc[dateTimeGroup][field_name]) {
                acc[dateTimeGroup][field_name] = [];
            }
    
            acc[dateTimeGroup][field_name].push(audit);
            return acc;
        }, {});
    };
    const groupedAuditData = useMemo(() => groupAuditDataByFieldAndTime(auditData), [auditData]);
    
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Admin') {
          console.log("Navigating to /Admin");
          navigate('/14/admin');
        } else if (crumb.title === 'User & SOD') {
          console.log("Navigating to /User & SOD");
          navigate('/14/admin');
        }
        
        else if (crumb.title === 'Permissions') {
          console.log("Navigating to /Permission");
          navigate('/14/admin/108/roles-and-permission');
        } 
        else if (crumb.title === 'User') {
          console.log("Navigating to /User");
          navigate('/14/admin/105/userscreen');
        } 
        else if (crumb.title === 'Audit Trail') {
          console.log("Navigating to /User");
          navigate(`/user-audit-trail-screen/${userId}`);
        } 
        else if (crumb.path) {
          navigate(crumb.path); // Navigate to the path specified in the crumb
        }
      };

      useEffect(() => {
        if (userId) {
          fetcheditUserDetails();
        }
      }, [userId]);

      const fetcheditUserDetails = async () => {

        try {
            const data = { userId: userId };
            console.log("Request Data:", data);
            
            const response = await UserService.saveContactDetails(
              `${process.env.REACT_APP_BASE_URL}/get-edit-user-details`,
              data
            );
            
            console.log("API Response:", response);
            console.log("Response Data:", response.data);
          
            if (response.data) {
              // Update state with fetched address details
             
              setApproved(response.data.approved || '');
            //   setSalute(response.data.salute || '');
            //   setUserType(response.data.userType || '');
            //   setAccountingSystemId(response.data.accountingSystemId || '');
            //   setSurname(response.data.surname || '');
            //   setFirstName(response.data.firstName || '');
            //   setLastName(response.data.lastName || '');
            //   setMiddleName(response.data.middleName || '');
            //   setEmail(response.data.email || '');
            //   setMobileNo(response.data.mobileNo || '');
            //   setWhatsappNo(response.data.whatsappNo || '');
            //   setOneTimePassword(response.data.oneTimePassword || '');
            //   setOfficeNo(response.data.officeNo || '');
            //   setExtension(response.data.extension || '');
            //   setNationality(response.data.nationality || '');
            //   setPassportNo(response.data.passportNo || '');
            //   setDrivingLicenseNo(response.data.drivingLicenseNo || '');
            //   setLocalId(response.data.localId || '');
            //   setemployeeNo(response.data.employeeNo || '');
            //   setcompanyCodeId(response.data.companyCodeId || '');
            //   setdepartment(response.data.department || '');
            //   setvalidFrom(response.data.validFrom || '');
            //   setvalidTo(response.data.validTo || '');
         
            //   setresignationDate(response.data.resignationDate || '');
            //   setjoiningDate(response.data.joiningDate || '');
            //   setLoginMethod(response.data.loginMethod || '');
              
    
              
            }
          } catch (error) {
            console.error("Error fetching address details:", error);
            setAlert({
              severity: "error",
              message: "An error occurred while fetching address details.",
            });
          }
        };

      const getApprovedIcon = () => {
        let color = "black"; // Default color
        // Determine the color based on the approved status
        if (approved == 3) {
          color = "#29CB00"; // Green
        } else if (approved == 0) {
          color = "gray"; // Gray
        } else if (approved == 1) {
          color = "yellow"; // Yellow
        } else if (approved == 2) {
          color = "orange"; // Orange
        } else if (approved == 4) {
          color = "red"; // Red
        }
    
        return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
      };

      const extraContent = iconId === "105" ? getApprovedIcon() : null;
      
    return (
        <div>
            {/* <NoActionLayout title={"Admin > User & SOD > User > Audit Trail"} /> */}
            {/* <Header title={`Admin > User & SOD > User > Audit Trail > ${userId} ` } */}
            <Header 
  title={`Admin > User & SOD > ${iconId === "108" ? 'Permissions > Audit Trail' : `User > Audit Trail > ${userId}`} `} 
        onBreadcrumbClick={handleBreadcrumbClick}
        // extraContent={getApprovedIcon()} 
        extraContent= {extraContent}
      />

            <div className='p-4'>
                <div className="overflow-x-auto" style={{ maxWidth: '1600px' }}>
                    <div className="overflow-y-auto" style={{ maxHeight: '720px' }}>
                        {auditData.length > 0 ? (
                         <table className="table-fixed min-w-[800px] bg-white border border-customBlue">
                         <thead className="bg-auditBlue text-primary-foreground text-left text-sm font-medium sticky top-0">
                             <tr className="bg-primary">
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">S.No</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Module</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Icon</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Action</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">MD Code</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Name</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Field Name</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Old Value</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">New Value</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Performed By</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">User Type</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Date</th>
                                 <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Time</th>
                             </tr>
                         </thead>
                         <tbody>
                             {auditData.map((audit, index) => (
                                 <>
                                     {/* Main audit row */}
                                     <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-[#FFFFFF]' : 'bg-sky-50'}`}>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{index + 1}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.module}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.icon}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.action}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.md_code}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.name}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.auditTrailEdits[0]?.field_name || '-'}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.auditTrailEdits[0]?.old_value || '-'}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.auditTrailEdits[0]?.new_value || '-'}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.performed_by}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                                              {audit.performedByUser && audit.performedByUser.userTypeDetails 
                                                ? audit.performedByUser.userTypeDetails.name 
                                                : "N/A"}
                                            </td>


                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{new Date(audit.date).toLocaleDateString()}</td>
                                         <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{audit.time}</td>
                                     </tr>
                     
                                     {/* Rows for each auditTrailEdit */}
                                     {audit.auditTrailEdits.slice(1).map((edit, editIndex) => (
                                         <tr key={`${index}-${editIndex}`} className={`border-b ${index % 2 === 0 ? 'bg-[#FFFFFF]' : 'bg-sky-50'}`}>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{edit.field_name}</td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{edit.old_value}</td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">{edit.new_value}</td>
                                             <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                            <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                            <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td>
                                         </tr>
                                     ))}
                                 </>
                             ))}
                         </tbody>
                     </table>
                     
                      
                       

                        //     <table className="table-fixed min-w-[800px] bg-white border border-customBlue">
                        //     <thead className="bg-auditBlue text-primary-foreground text-left text-sm font-medium sticky top-0">
                        //         <tr className="bg-primary">
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">S.No</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Module</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Icon</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Action</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">MD Code</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Name</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Field Name</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Old Value</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">New Value</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Performed By</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Date</th>
                        //             <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">Time</th>
                        //         </tr>
                        //     </thead>
                        //     <tbody>
                        //         {Object.entries(groupedAuditData).map(([dateTimeGroup, fields], groupIndex) => (
                        //             <React.Fragment key={groupIndex}>
                        //                     {/* Display Grouped Rows by Date and Time */}
                        //                 {/* <tr className="bg-gray-100">
                        //                     <td colSpan="12" className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                        //                         Changes on: {dateTimeGroup}
                        //                     </td>
                        //                 </tr> */}
                
                        //                 {Object.entries(fields).map(([fieldName, audits], fieldIndex) => (
                        //                     <React.Fragment key={fieldIndex}>
                        //                         {/* <tr className="bg-gray-50">
                        //                             <td colSpan="12" className="py-2 px-4 font-medium border-r border-white">
                        //                                 Field Name: {fieldName}
                        //                             </td>
                        //                         </tr> */}
                
                        //                         {audits.map((audit, index) => (
                        //                             <tr key={index}>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{index + 1}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.module}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.icon}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.action}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.md_code}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.name}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.field_name || '-'}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.old_value || '-'}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.new_value || '-'}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.performed_by || '-'}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{new Date(audit.date).toLocaleDateString()}</td>
                        //                                 <td className="py-2 px-4 border-r border-sky-50 whitespace-nowrap">{audit.time}</td>
                        //                             </tr>
                        //                         ))}
                        //                     </React.Fragment>
                        //                 ))}
                        //             </React.Fragment>
                        //         ))}
                        //     </tbody>
                        // </table>
                        ) : (
                            <p>No audit trail data available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
