import React from 'react';

const LabelWithInput = ({
  label,
  value,
  onChange,
  maxLength,
  placeholder,
  error,
  isRequired = false,
  isDisabled = false,
  inputType = 'text',
  isreadonly = false,
  width = 'w-80',
  spanMargin = 'ml-4'
}) => {
  return (
    <div className="flex items-center">
      <label className="w-48 font-small text-xs text-customGray">
        {label}
        {isRequired && <span className="text-red-500 ml-1">*</span>}
      </label>
      <span className={`${spanMargin}`}>:</span>
      <input
        type={inputType}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        placeholder={placeholder}
        readOnly={isreadonly}
        className={`border ${error ? 'border-red-500' : 'border-gray-300'
          } p-1.5  disabled:bg-[#FAFAFA] rounded ${width} h-8 focus:outline-none hover:border-blue-400 ml-4 text-xs font-small text-customGray`}
        required={isRequired}
        disabled={isDisabled}
      />
    </div>
  );
};

export default LabelWithInput;
