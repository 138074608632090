import React from 'react'; 
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = ({ title = "", onBreadcrumbClick, extraContent }) => {  
  const navigate = useNavigate();
  const location = useLocation();

  const breadcrumbs = title ? title.split(' > ') : [];

  const getBreadcrumbPath = (breadcrumb) => {
    const routes = {
      // Add your custom routes here
    };
    return routes[breadcrumb] || location.pathname; // Fallback to current path if no match
  };

  const handleBreadcrumbClick = (breadcrumb) => {
    const crumb = {
      title: breadcrumb,
      path: getBreadcrumbPath(breadcrumb),
    };
    onBreadcrumbClick(crumb); 
  };

  return (
    <header className="bg-white text-primary-foreground p-4 flex items-center space-x-2 shadow-md shadow-blue-100 mb-2 ml-2">
      <FaArrowLeft
        style={{ color: '#0B5299' }}
        onClick={() => navigate(-1)}
        className="cursor-pointer text-customBlue mr-4"
      />
      <div className="flex space-x-2 items-center">
        {breadcrumbs.map((breadcrumb, index) => {
          const isLast = index === breadcrumbs.length - 1;

          return (
            <span key={breadcrumb}>
              <span
                className={`text-xl font-md cursor-pointer transition-all duration-200 hover:text-customBlue hover:text-lg ${
                  isLast ? 'text-customBlue' : 'text-[#0B5299]'
                }`}
                onClick={() => handleBreadcrumbClick(breadcrumb)}
              >
                {breadcrumb}
              </span>
              {!isLast && ' > '}
            </span>
          );
        })}
        {extraContent && <span>{extraContent}</span>} {/* Render the extra content, i.e., approval icon */}
      </div>
    </header>
  );
};

export default Header;
