import React, { useEffect, useState } from "react";

const MainHeader = ({ title }) => {
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    const fetchClientId = () => {
      try {
        const userDetailString = localStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          setClientId(userDetail.clientId || null); // Set clientId from session storage
        } else {
          console.error("No user detail found in session storage.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchClientId();
  }, []); // Runs once on component mount

  return (
    <header className="bg-white text-primary-foreground p-4 ml-2 flex justify-between items-center shadow-md shadow-blue-200 mb-2">
      {/* Left-aligned Title */}
      <h4 className="text-xl text-customBlue font-small">
        {title}
      </h4>

      {/* Right-aligned Client ID */}
      {clientId && (
        <div className="text-md  font-small text-customBlue mr-6">
          Client ID: {clientId}
        </div>
      )}
    </header>
  );
};

export default MainHeader;
