import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import AdminActions from '../../../../Admin/Components/AdminActions';
import ReTable from '../../../../../../components/Table';
import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import UserService from '../../../../../../rest-services/UserServices';
import XLSX from "xlsx-js-style";

export default function BAMAcctNoUpdateTableScreen() {
    const navigate = useNavigate();
    const { iconId } = useParams();   
    const location = useLocation();
    const [assignee, setAssignee] = useState({ id: "", username: "" });
    const [alert, setAlert] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]); 
    const [processedData, setProcessedData] = useState([]);
    const { tableData, displayOption, uploadOption  } = location.state || {}; // Retrieve tableData from state
    const [loading, setLoading] = useState(false);
    const[newRequestNo,setNewRequestNo] = useState([]);
    const[newRequestNoGreen,setNewRequestNoGreen] = useState([]);
    const [bankAccountId,setBankAccountId] = useState([]);
    const[ibanRequired,setibanRequired] = useState([]);

    const [accountNoUpdatesuccessfulRecords, setAccountNoUpdateSuccessfulRecords] = useState([]);

    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === "BAM") {
          navigate("/bam");
        } else if (crumb.title === "Bank Master Data") {
          navigate("/3/BAM/26/account-master");
        } else if (crumb.title === "Account Master") {
          navigate("/3/BAM/26/account-master");
        } else if (crumb.path) {
          navigate(crumb.path);
        }
      };

      useEffect(() => {
          fetchAssignee();
          fetchNewRequestNoData();
          fetchIBANRequiredData();
      }, []);

      const fetchAssignee = async () => {
          try {
            const userDetailString = localStorage.getItem("userDetail");
            if (userDetailString) {
              const userDetail = JSON.parse(userDetailString);
              const assigneeData = {
                id: userDetail.id || "",
                clientId: userDetail.clientId || "",
                assigner: userDetail.username || "",
                userId: userDetail.userId || "",
      
              };
      
              setAssignee(assigneeData);
      
              // Log the id and assigner values to the console
              // console.log("Assignee ID:", assigneeData.id);
              // console.log("Assigner:", assigneeData.assigner);
              // console.log("Assigner clientId:", assigneeData.clientId);
              // console.log("Assigner userId:", assigneeData.userId);
      
            } else {
              setAlert({
                severity: "error",
                message: "No user detail found in session storage.",
              });
            }
          } catch (error) {
            setAlert({
              severity: "error",
              message: `Error fetching user details: ${error.message}`,
            });
          }
        };

      const fetchIBANRequiredData = async () => {
        try {
            const response = await UserService.getUsers(
              `${process.env.REACT_APP_BASE_URL}/bam/get-IBAN-required-code`
            );
            console.log("response. iban Number data",response.data);
            setibanRequired(response.data.data);
            // console.log("Set user types data", response.data.data);
          } catch (error) {
            console.error("Error fetching user types:", error);
          }
          };

      const fetchNewRequestNoData = async () => {
        try {
            const response = await UserService.getUsers(
              `${process.env.REACT_APP_BASE_URL}/bam/get-Account_detail-ReqNo-code`
            );
            console.log("response. Request Number data",response.data);
            setNewRequestNo(response.data.data);
            setNewRequestNoGreen(response.data.data2);
            setBankAccountId(response.data.data3);
            // console.log("Set user types data", response.data.data);
          } catch (error) {
            console.error("Error fetching user types:", error);
          }
          };

      const columns = [   
                      
                { Header: 'Action', accessor: 'Action', visible: true, width: ("".length -1)*10, alignment: "left" },
                { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1)*10, alignment: "left" },
                {
                  Header: 'Update',
                  accessor: (row) => {
                    if (row.successfulrecords && uploadOption === '3') {
                      return 'Yes';
                    } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
                      return 'No';
                    } else {
                      return <span style={{ color: 'red' }}>No</span>;
                    }
                  },
                  visible: true,
                  width: ("Update".length - 2) * 10,
                  alignment: 'left',
                },
                { Header: 'NewRequestNo', accessor: 'NewRequestNo', visible: true, width: ("NewRequestNo".length -1)*10, alignment: "left" },
                { Header: 'BankAccountNo', accessor: 'BankAccountNo', visible: true, width: ("BankAccountNo".length -1)*10, alignment: "left" },
                { Header: 'BankAccountIdDescription', accessor: 'BankAccountIdDescription', visible: true, width: ("BankAccountIdDescription".length -1)*10, alignment: "left" },
                { Header: 'TextField', accessor: 'TextField', visible: true, width: ("TextField".length -1)*10, alignment: "left" },
                { Header: 'IBANNo', accessor: 'IBANNo', visible: true, width: ("IBANNo".length -1)*10, alignment: "left" },
            ];

            const handleDownload = () => {
              if (processedData.length === 0) {
                setAlert({
                  severity: "warning",
                  message: "No data available to export!",
                });
                return;
              }
            
              const extractText = (cellValue) => {
                if (Array.isArray(cellValue)) {
                  return cellValue.join(", "); // Convert array to string
                } else if (typeof cellValue === "string") {
                  return cellValue;
                } else if (React.isValidElement(cellValue)) {
                  return cellValue.props.children;
                }
                return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
              };
            
              const formattedUsers = processedData.map((user) => ({
                "Action": extractText(user.Action),
                "SLNo": extractText(user.SLNo),
                "NewRequestNo": extractText(user.NewRequestNo),
                "BankAccountNo": extractText(user.BankAccountNo),
                "BankAccountIdDescription": extractText(user.BankAccountIdDescription),

                "TextField": extractText(user.TextField),
                "IBANNo": extractText(user.IBANNo),
                
              
                }));
            

        // Add second and third row
        const secondRow = {
          "Action": "R","SLNo": "R", "NewRequestNo":"R","BankAccountNo":"R","BankAccountIdDescription":"R","TextField": "","IBANNo": "Conditional"
        };

        const thirdRow = {
            "Action": "1","SLNo": "5", "NewRequestNo":"12","BankAccountNo":"25","BankAccountIdDescription":"30","TextField": "30","IBANNo": "50"
          
        };

          // Insert second and third row at the beginning of the formatted data
          const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];

              const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
            console.log("rowsToDownload data is",rowsToDownload);
        
              // Define border style
        const border = {
          top: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
        };

            // Apply borders to the first three rows
            rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
              Object.keys(row).forEach((col, colIdx) => {
                const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
                if (worksheet[cellAddress]) {
                  worksheet[cellAddress].s = { border };
                }
              });
            });

            
              // Additional cell styling for validation errors
              Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
                rowsToDownload.forEach((row, rowIdx) => {
                  const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
                  const cellValue = row[col];
            
                  if (
                    typeof cellValue === "string" && (
                      cellValue.includes("Shouldn't be blank") 
                      // ||
                      // cellValue.includes("Mismatched Data Type") ||
                      // cellValue.includes("Max. allowed character")
                    )
                  ) {
                    worksheet[cellAddress] = {
                      v: cellValue,
                      s: { font: { color: { rgb: "FF0000" } } },
                    };
                  } else if (Array.isArray(row[col])) {
                    worksheet[cellAddress] = {
                      v: extractText(row[col]),
                      s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
                    };
                  }
                  else {
                    worksheet[cellAddress] = { v: cellValue };
                  }
                });
              });
            
              // AutoFit column width
              worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
                const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
                return { wch: maxLength + 2 }; // Add extra space
              });


              const workbook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
              XLSX.writeFile(workbook, "BAM_New_Request_Upload.xlsx");
            };

       const existingButtons = AdminActionsConfig.screen7.map((button) => {
          let onClick;
          let title;
      
      
          if (button.icon === MdOutlineFileDownload) {
            onClick = handleDownload;
            title = "Download File";
          }
      
          else {
            onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
            title = button.title || ""; // Use existing title or default to an empty string
          }
      
          return {
            ...button,
            onClick, // Reference the function without invoking it
            title,   // Add the title property to the button configuration
          };
        });
                                
        const extraButtons = [ ];
                                    
        const actionButtons = existingButtons.concat(
          extraButtons.map((button) => ({
            ...button,
            disabled: selectedRows.length === 0, // Disable button if no users are selected
            onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
          }))
        );
                                
                                          
        const [columnSettings, setColumnSettings] = useState(columns);       
                
        const modifiedColumns = columnSettings
        .filter((col) => col.visible)
        .map((col) => ({
          ...col,
          width: col.width, // This should hold the updated width
        }));

        const AcctNoUpdateGetDataToDisplay = async (displayOption, uploadOption, tableData) => {
          let dataToDisplay;
          if (displayOption === "1") {
        
            let processed=[];
 
            if(newRequestNo && newRequestNoGreen && ibanRequired) {
              console.log("entered in this console log");
              processed = await AcctNoUpdateprocessData(tableData);
              console.log("Processed data in data to display",processed);
            }
          console.log("account No Update successfulRecords",processed);


          // Convert existingUsers to a Set for faster lookup based on a unique identifier (e.g., SLNo)
          const existingRowSet = new Set(processed.map(row => JSON.stringify(row)));

          console.log("Exisitng row list data",existingRowSet);
          // Filter tableData to get the non-existing rows
          const nonExistingRows = tableData.filter(row => !existingRowSet.has(JSON.stringify(row)));

          console.log("Non-existing rows", nonExistingRows);

          dataToDisplay =nonExistingRows;

            
        
          } else if (displayOption === "2") {
            console.log("Display option is 2");
            dataToDisplay = tableData; // Display tableData if displayOption is "2"
          } else if (uploadOption === "3") {
            console.log("upload option is 3");
          //   dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
        
            dataToDisplay = accountNoUpdatesuccessfulRecords; // Display successfulRecords if uploadOption is "3"
            // dataToDisplay = await fetchAndFilterSuccessfulRecords(tableData); // Successful records for uploadOption "3"
        
          } else {
            dataToDisplay = tableData; // Default to tableData if no conditions are met
          }
        
        // console.log("dataToDisplay data is",dataToDisplay);
        return dataToDisplay;
        };

        const AcctNoUpdateprocessData = async (data) => {
          const successfulRecordsArray = [];
          
      console.log("processData received  data:", data);
      
      //  return data.map((row, _, allRows) => {
          await Promise.all(data.map(async (row, _, allRows) => {
                          
          const isDuplicateReqNo = allRows.filter(r => r.NewRequestNo == row.NewRequestNo).length > 1;
          
          const normalize = (value) => String(value).trim().toUpperCase();
  
          const NewRequestNoExists = newRequestNo.map(normalize).includes(normalize(row.NewRequestNo));
          const NewRequestNoGreenExists = newRequestNoGreen.map(normalize).includes(normalize(row.NewRequestNo));
          const ibanRequiredExists = ibanRequired.map(normalize).includes(normalize(row.NewRequestNo));
  
          
         console.log(`NewRequestNo id ${row.NewRequestNo} exists:`, NewRequestNoExists);
         console.log(`NewRequestNo green id ${row.NewRequestNo} exists:`, NewRequestNoGreenExists);
          
         
      // Check for errors (red text) and apply highlight based on that
  
      const hasNotError = [
      row.SLNo,
      row.Action,
      row.NewRequestNo,
      row.BankAccountNo,
      row.BankAccountIdDescription,
      row.IBANNo
      ].every(field => {
      let isValid = true;
      let reason = "";
      
      if (field === row.SLNo) {
          if (!field) { isValid = false; reason = "SLNo should not be blank."; }
          else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
          else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
      }
      

      if (!row.Action || String(row.Action).trim() === "") {
        isValid = false;
        reason = "Action should not be empty.";
      } else {
        if (!["U", "u"].includes(row.Action)) { isValid = false; reason = "Action only allows 'U'."; }
        else if (String(row.Action).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
      }
      
  
      if ((!row.NewRequestNo || String(row.NewRequestNo).trim() === "") && (row.Action === "U" || row.Action === "u")) {
          isValid = false;
          reason = "NewRequestNo should not be empty.";
      } else if (field === row.NewRequestNo) {
          if(isDuplicateReqNo){
              isValid = false; reason = "New Request Number is duplicate.";
          }
          
          if (String(field).trim().length > 12) {
              isValid = false;
              reason = "NewRequestNo exceeds max length of 12.";
          }
            if(NewRequestNoExists){
                if(!NewRequestNoGreenExists){
                    isValid = false;
                    reason = "NewRequestNo is not exists because of the flag status"
                }
            }
            else{
                isValid = false;
                reason = "NewRequestNo is not exists"
            }
        }
      
      if ((!row.BankAccountNo || String(row.BankAccountNo).trim() === "") && (row.Action === "U" || row.Action === "u")) {
          isValid = false;
          reason = "BankAccountNo should not be empty.";
      } else if(row.BankAccountNo){
          if(field === row.BankAccountNo){
              if (String(field).trim().length > 25) {
                  isValid = false;
                  reason = "BankAccountNo exceeds max length of 25.";
              }
            }
        }
  
  
        if ((!row.BankAccountIdDescription || String(row.BankAccountIdDescription).trim() === "")  && (row.Action === "U" || row.Action === "u")) {
          isValid = false;
          reason = "BankAccountIdDescription should not be empty.";
      } else if(row.BankAccountIdDescription){
          if(field === row.BankAccountIdDescription){
              if (String(field).trim().length > 30) {
                  isValid = false;
                  reason = "BankAccountIdDescription exceeds max length of 30.";
              }
            }
        }
  
  
        if(row.TextField){
          if(field === row.TextField){
              if (String(field).length > 30) { isValid = false; reason = "TextField exceeds max length of 30."; }
          }
      }
  
  
      if ((!row.IBANNo || String(row.IBANNo).trim() === "") && (row.Action === "U" || row.Action === "u") && ibanRequiredExists) {
          isValid = false;
          reason = "IBANNo should not be empty.";
      } else if(row.IBANNo){
          if(field === row.IBANNo){
              if (String(field).trim().length > 50) {
                  isValid = false;
                  reason = "IBANNo exceeds max length of 50.";
              }
            }
        }
  
  
      if (!isValid) {
          console.log(`Validation Failed: ${reason}`);
      } else {
          console.log(`Validation Passed: ${field}`);
      }
      
      return isValid;
      });
      
      if (hasNotError) {
          successfulRecordsArray.push(row);
      }
      console.log(`Final hasNotError status: ${hasNotError}`);
      
          return {
          ...row,
          successfulrecords: hasNotError, // Mark as successful if no error
  
      
          Action: row.Action ? (
              String(row.Action).length === 1 ? (
              ["U", "u"].includes(row.Action) ? (
                  row.Action // Valid Action ("A", "E", "a", "e")
              ) : (
                  <span style={{ color: "red" }}>
                  {row.Action} (Only "U" is allowed)
                  </span>
              )
              ) : (
              <span style={{ color: "red" }}>
                  {row.Action} (Max. allowed character is 1)
              </span>
              )
          ) : (
              <span style={{ color: "red" }}>Shouldn't be blank</span>
          ),
      
          SLNo: row.SLNo ? (
              isNaN(row.SLNo) ? (
                  <span style={{ color: "red"}}>
                  {row.SLNo} (Mismatched Data Type)
                  </span>
              ) : String(row.SLNo).length > 5 ? (
                  <span style={{ color: "red"}}>
                  {row.SLNo} (Max. allowed character is 5)
                  </span>
              ) : (
                  row.SLNo // Valid SLNo
              )
              ) : (
              <span style={{ color: "red"}}>Shouldn't be blank</span>
              ),
      
              NewRequestNo: row.NewRequestNo ? (
                  String(row.NewRequestNo).length > 12 ? (
                    <span style={{ color: "red" }}>
                      {row.NewRequestNo} (Max. allowed characters: 12)
                    </span>
                  ) : isDuplicateReqNo ?
                  (
                      <span style={{ color: "red" }}>
                        {row.NewRequestNo} (NewRequestNo Duplicate exists)
                      </span>
                    ) :
                  NewRequestNoExists && displayOption !== "" ? (
                      NewRequestNoGreenExists ? (
                      <span>{row.NewRequestNo}</span>
                    ) : (
                      <span style={{ color: "red" }}>
                        {row.NewRequestNo} (New RequestNo is not approved.)
                      </span>
                    )
                  ) :(
                    <span style={{ color: "red" }}>
                    {row.NewRequestNo} (New RequestNo not exists.)
                  </span>
                  ) )
                  :
                  (
                      <span style={{ color: "red" }}>
                        Shouldn't be blank
                      </span>
                  ),
  
  
                  BankAccountNo: row.BankAccountNo ? (
                      String(row.BankAccountNo).length > 25 ? (
                        <span style={{ color: "red" }}>
                          {row.BankAccountNo} (Max. allowed character is 25)
                        </span>
                      ) : (
                        String(row.BankAccountNo) // Ensure BankAccountNo is treated as a string
                      )
                    ) : (
                      row.Action === "U" || row.Action === "u" ? (
                        <span style={{ color: "red" }}>
                          Shouldn't be blank
                        </span>
                      ): null // Handle other cases, if any
                    ),
  
                    BankAccountIdDescription: row.BankAccountIdDescription ? (
                      String(row.BankAccountIdDescription).length > 30 ? (
                        <span style={{ color: "red" }}>
                          {row.BankAccountIdDescription} (Max. allowed character is 30)
                        </span>
                      ) : (
                        String(row.BankAccountIdDescription) // Ensure BankAccountIdDescription is treated as a string
                      )
                    ) : (
                      row.Action === "U" || row.Action === "u" ? (
                        <span style={{ color: "red" }}>
                          Shouldn't be blank
                        </span>
                      ): null // Handle other cases, if any
                    ),
  
                    TextField: row.TextField ? (
                      String(row.TextField).length > 30 ? (
                        <span style={{ color: "red" }}>
                          {row.TextField} (Max. allowed character is 30)
                        </span>
                      ) : (
                        String(row.TextField) // Ensure TextField is treated as a string
                      )
                    ) : (
                        <span>
                        </span>
                    ),
                    
                    IBANNo: row.IBANNo ? (
                      String(row.IBANNo).length > 50 ? (
                        <span style={{ color: "red" }}>
                          {row.IBANNo} (Max. allowed character is 50)
                        </span>
                      ) : (
                        String(row.IBANNo) // Ensure BankAccountIdDescription is treated as a string
                      )
                    ) : (
                      row.Action === "U" || row.Action === "u" ? (
                          ibanRequiredExists ? (
                        <span style={{ color: "red" }}>
                          Shouldn't be blank
                        </span>
                          ): (
                          <span> </span>
                          )     
                    ):null
                  )
          };  
      }));
      setAccountNoUpdateSuccessfulRecords(successfulRecordsArray);
      console.log("successfulRecordsArray data",successfulRecordsArray);
      return successfulRecordsArray;
  
      }
                    const AcctNoUpdateFullprocessData = async (data) => {
                      const successfulRecordsArray = [];
                      
                  console.log("processData received  data:", data);
                  
                  //  return data.map((row, _, allRows) => {
                    const results = await Promise.all(data.map(async (row, _, allRows) => {
                                      
                      const isDuplicateReqNo = allRows.filter(r => r.NewRequestNo == row.NewRequestNo).length > 1;
                      
                      const normalize = (value) => String(value).trim().toUpperCase();
                
                      const NewRequestNoExists = newRequestNo.map(normalize).includes(normalize(row.NewRequestNo));
                      const NewRequestNoGreenExists = newRequestNoGreen.map(normalize).includes(normalize(row.NewRequestNo));
                      const ibanRequiredExists = ibanRequired.map(normalize).includes(normalize(row.NewRequestNo));
                
                      
                     console.log(`NewRequestNo id ${row.NewRequestNo} exists:`, NewRequestNoExists);
                     console.log(`NewRequestNo green id ${row.NewRequestNo} exists:`, NewRequestNoGreenExists);
                      
                     
                  // Check for errors (red text) and apply highlight based on that
                
                  const hasNotError = [
                  row.SLNo,
                  row.Action,
                  row.NewRequestNo,
                  row.BankAccountNo,
                  row.BankAccountIdDescription,
                  row.IBANNo
                  ].every(field => {
                  let isValid = true;
                  let reason = "";
                  
                  if (field === row.SLNo) {
                      if (!field) { isValid = false; reason = "SLNo should not be blank."; }
                      else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
                      else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
                  }
                  
                  if (row.Action) {
                    // if(row.Action !== "U" || row.Action !== "u"){ isValid = false; reason = "Action only allow U."; }
                    if (!["U"].includes(row.Action)) { 
                      isValid = false; 
                      reason = "Action only allows 'U'."; 
                  }
                      if (!row.Action) { isValid = false; reason = "Action should not be blank."; }
                      else if (String(row.Action).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
                  }
                  
                
                  if ((!row.NewRequestNo || String(row.NewRequestNo).trim() === "") && (row.Action === "U" || row.Action === "u")) {
                      isValid = false;
                      reason = "NewRequestNo should not be empty.";
                  } else if (field === row.NewRequestNo) {
                      if(isDuplicateReqNo){
                          isValid = false; reason = "New Request Number is duplicate.";
                      }
                      
                      if (String(field).trim().length > 12) {
                          isValid = false;
                          reason = "NewRequestNo exceeds max length of 12.";
                      }
                        if(NewRequestNoExists){
                            if(!NewRequestNoGreenExists){
                                isValid = false;
                                reason = "NewRequestNo is not exists because of the flag status"
                            }
                        }
                        else{
                            isValid = false;
                            reason = "NewRequestNo is not exists"
                        }
                    }
                  
                  if ((!row.BankAccountNo || String(row.BankAccountNo).trim() === "") && (row.Action === "U" || row.Action === "u")) {
                      isValid = false;
                      reason = "BankAccountNo should not be empty.";
                  } else if(row.BankAccountNo){
                      if(field === row.BankAccountNo){
                          if (String(field).trim().length > 25) {
                              isValid = false;
                              reason = "BankAccountNo exceeds max length of 25.";
                          }
                        }
                    }
                
                
                    if ((!row.BankAccountIdDescription || String(row.BankAccountIdDescription).trim() === "")  && (row.Action === "U" || row.Action === "u")) {
                      isValid = false;
                      reason = "BankAccountIdDescription should not be empty.";
                  } else if(row.BankAccountIdDescription){
                      if(field === row.BankAccountIdDescription){
                          if (String(field).trim().length > 30) {
                              isValid = false;
                              reason = "BankAccountIdDescription exceeds max length of 30.";
                          }
                        }
                    }
                
                
                    if(row.TextField){
                      if(field === row.TextField){
                          if (String(field).length > 30) { isValid = false; reason = "TextField exceeds max length of 30."; }
                      }
                  }
                
                
                  if ((!row.IBANNo || String(row.IBANNo).trim() === "") && (row.Action === "U" || row.Action === "u") && ibanRequiredExists) {
                      isValid = false;
                      reason = "IBANNo should not be empty.";
                  } else if(row.IBANNo){
                      if(field === row.IBANNo){
                          if (String(field).trim().length > 50) {
                              isValid = false;
                              reason = "IBANNo exceeds max length of 50.";
                          }
                        }
                    }
                
                
                  if (!isValid) {
                      console.log(`Validation Failed: ${reason}`);
                  } else {
                      console.log(`Validation Passed: ${field}`);
                  }
                  
                  return isValid;
                  });
                  
                  if (hasNotError) {
                      successfulRecordsArray.push(row);
                  }
                  console.log(`Final hasNotError status: ${hasNotError}`);
                  
                      return {
                      ...row,
                      successfulrecords: hasNotError, // Mark as successful if no error
                
                  
                      Action: row.Action ? (
                          String(row.Action).length === 1 ? (
                          ["U", "u"].includes(row.Action) ? (
                              row.Action // Valid Action ("A", "E", "a", "e")
                          ) : (
                              <span style={{ color: "red" }}>
                              {row.Action} (Only "U" is allowed)
                              </span>
                          )
                          ) : (
                          <span style={{ color: "red" }}>
                              {row.Action} (Max. allowed character is 1)
                          </span>
                          )
                      ) : (
                          <span style={{ color: "red" }}>Shouldn't be blank</span>
                      ),
                  
                      SLNo: row.SLNo ? (
                          isNaN(row.SLNo) ? (
                              <span style={{ color: "red"}}>
                              {row.SLNo} (Mismatched Data Type)
                              </span>
                          ) : String(row.SLNo).length > 5 ? (
                              <span style={{ color: "red"}}>
                              {row.SLNo} (Max. allowed character is 5)
                              </span>
                          ) : (
                              row.SLNo // Valid SLNo
                          )
                          ) : (
                          <span style={{ color: "red"}}>Shouldn't be blank</span>
                          ),
                  
                          NewRequestNo: row.NewRequestNo ? (
                              String(row.NewRequestNo).length > 12 ? (
                                <span style={{ color: "red" }}>
                                  {row.NewRequestNo} (Max. allowed characters: 12)
                                </span>
                              ) : isDuplicateReqNo ?
                              (
                                  <span style={{ color: "red" }}>
                                    {row.NewRequestNo} (NewRequestNo Duplicate exists)
                                  </span>
                                ) :
                              NewRequestNoExists && displayOption !== "" ? (
                                  NewRequestNoGreenExists ? (
                                  <span>{row.NewRequestNo}</span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    {row.NewRequestNo} (New RequestNo is not approved.)
                                  </span>
                                )
                              ) :(
                                <span style={{ color: "red" }}>
                                {row.NewRequestNo} (New RequestNo not exists.)
                              </span>
                              ) )
                              :
                              (
                                  <span style={{ color: "red" }}>
                                    Shouldn't be blank
                                  </span>
                              ),
                
                
                              BankAccountNo: row.BankAccountNo ? (
                                  String(row.BankAccountNo).length > 25 ? (
                                    <span style={{ color: "red" }}>
                                      {row.BankAccountNo} (Max. allowed character is 25)
                                    </span>
                                  ) : (
                                    String(row.BankAccountNo) // Ensure BankAccountNo is treated as a string
                                  )
                                ) : (
                                  row.Action === "U" || row.Action === "u" ? (
                                    <span style={{ color: "red" }}>
                                      Shouldn't be blank
                                    </span>
                                  ): null // Handle other cases, if any
                                ),
                
                                BankAccountIdDescription: row.BankAccountIdDescription ? (
                                  String(row.BankAccountIdDescription).length > 30 ? (
                                    <span style={{ color: "red" }}>
                                      {row.BankAccountIdDescription} (Max. allowed character is 30)
                                    </span>
                                  ) : (
                                    String(row.BankAccountIdDescription) // Ensure BankAccountIdDescription is treated as a string
                                  )
                                ) : (
                                  row.Action === "U" || row.Action === "u" ? (
                                    <span style={{ color: "red" }}>
                                      Shouldn't be blank
                                    </span>
                                  ): null // Handle other cases, if any
                                ),
                
                                TextField: row.TextField ? (
                                  String(row.TextField).length > 30 ? (
                                    <span style={{ color: "red" }}>
                                      {row.TextField} (Max. allowed character is 30)
                                    </span>
                                  ) : (
                                    String(row.TextField) // Ensure TextField is treated as a string
                                  )
                                ) : (
                                    <span>
                                    </span>
                                ),
                                
                                IBANNo: row.IBANNo ? (
                                  String(row.IBANNo).length > 50 ? (
                                    <span style={{ color: "red" }}>
                                      {row.IBANNo} (Max. allowed character is 50)
                                    </span>
                                  ) : (
                                    String(row.IBANNo) // Ensure BankAccountIdDescription is treated as a string
                                  )
                                ) : (
                                  row.Action === "U" || row.Action === "u" ? (
                                      ibanRequiredExists ? (
                                    <span style={{ color: "red" }}>
                                      Shouldn't be blank
                                    </span>
                                      ): (
                                      <span> </span>
                                      )     
                                ):null
                              )
                      };  
                  }));
                  setAccountNoUpdateSuccessfulRecords(successfulRecordsArray);
                  return results;
                
                  }
                  
        useEffect(() => {
            const fetchData = async () => {
            setLoading(true);
            const dataToDisplay = await AcctNoUpdateGetDataToDisplay(displayOption, uploadOption, tableData);
            console.log("data to display table data",dataToDisplay);

            if(newRequestNo && newRequestNoGreen && ibanRequired && bankAccountId){
            console.log("data to display table data 2",dataToDisplay);

            const processed = await AcctNoUpdateFullprocessData(dataToDisplay);
            console.log("Process data to disply in the table",processed);

            setProcessedData(processed);
            }
    
            setLoading(false);
            };
        
            fetchData();
        }, [newRequestNo, newRequestNoGreen, ibanRequired, bankAccountId]);



  return (
    <div>
       <Header title={`BAM > Bank Master Data > Account Master > Upload > Display`}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/3/BAM/26/account-master-upload"}
        />

        {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

            <AdminActions
              icons={iconId}
              buttons={actionButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
            />

        <ReTable
          data={processedData}
        //   data={tableData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        //   onRowDoubleClick={handleDoubleClick}
        />

    </div>
  )
}
