import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../../../../../components/toast/toast";
import Header from "../../../../../../components/Header";
import { TbFlag3Filled } from "react-icons/tb";
import Footer from "../../../../../../components/footer";
import ButtonGroup from "../../../../../../components/FooterButton";
import CustomDropdown from "../../../../../../components/CustomDropdown";
import LabelWithButton from "../../../../../../components/InputButtonField";
import ReusableModal from "../../../../../../components/PopupComponent";
import LabelWithInput from "../../../../../../components/InputFiledComponent";

export default function HolidayCalenderAdd() {
  const { buGroupId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [buGroup, setBuGroup] = useState("");
  const [bugroupDescription, setBugroupDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "",clientId:"" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const [status, setStatus] = useState("");
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!buGroupId;

  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const { BU_Group, BU_Group_Description } = location.state.copiedData;
      setBuGroup(BU_Group);
      setBugroupDescription(BU_Group_Description);
    } else if (buGroupId) {
    //   getDataForEdit();
    }
    fetchAssignee();
  }, [buGroupId, location.state]);

//   const getDataForEdit = async () => {
//     try {
  
     
  
//       if (!buGroupId) {
//         console.error("BU Group ID is missing from local storage or state");
//         return; // Exit the function if buGroupId is missing
//       }
  
//       const response = await BuGroupServices.getBuGroupById( buGroupId);
//       const data = response.data.data;
  
//       setBuGroup(data.BU_Group);
//       setBugroupDescription(data.BU_Group_Description);
//       setStatus(data.status);
//     } catch (error) {
//       console.error("Error fetching BUGroup for edit:", error);
//     }
//   };
  

  const validateForm = () => {
    const newErrors = {};
    if (!buGroup) newErrors.buGroup = "BuGroup  is required.";
    if (!bugroupDescription) newErrors.bugroupDescription = "BuGroup Description is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate the form
//     if (!validateForm()) return;

//     // Check for duplicate BU Group code
//     try {
//       // Skip duplication check for edit mode
//       if (!buGroupId || isCopyMode) {
//         const duplicateCheckResponse = await BuGroupServices.checkDuplicateBugroupcode(
//           `${process.env.REACT_APP_BASE_URL}/getduplicatebugroup`,
//           { BU_Group: buGroup }
//         );

//         if (duplicateCheckResponse.message === "BU group code already exists") {
//           toast("error", "BuGroup Code already exists.");
//           setErrors((prev) => ({
//             ...prev,
//             buGroup: "BuGroup Code already exists.",
//           }));
//           return; // Stop further execution if duplicate exists
//         }
//       }

//       // Prepare the data for submission
//       const data = {
//         id: isCopyMode ? null : buGroupId,
//         BU_Group: buGroup,
//         BU_Group_Description: bugroupDescription,
//         created_by: assignee.assigner,
//         changed_by: assignee.assigner,
//       };

//       console.log("BuGroup data", data);


//       let response;
//       if (!unsavedChanges) {
//         toast(
//           "info",
//           "No BuGroup changes detected. Nothing to save."
//         );
//         navigate("/2/master-data/8/bugroup"); // Redirect after saving, only if Save and Continue is false
//         return false;
//       }
//       if (isCopyMode || !buGroupId) {
//         // Create a new BU Group
//         response = await BuGroupServices.addBuGroup(
//           `${process.env.REACT_APP_BASE_URL}/create-bugroup`,
//           data
//         );
//       } else {
//         // Update an existing BU Group
//         response = await BuGroupServices.addBuGroup(
//           `${process.env.REACT_APP_BASE_URL}/update-bugroup`,
//           data
//         );
//       }

//       if (response && !response.error) {
//         toast(
//           "success",
//           buGroupId && !isCopyMode
//             ? "Bu Group Updated successfully"
//             : "Bu Group Added successfully"
//         );

//         // Clear the form and navigate
//         setBuGroup("");
//         setBugroupDescription("");


//         navigate("/2/master-data/8/bugroup"); // Redirect after saving, only if Save and Continue is false

//       } else {
//         toast("error", "Error adding/updating Bu Group.");
//       }
//     } catch (error) {
//       console.error("Request error:", error);
//       setAlert({
//         severity: "error",
//         message: `Error adding/updating Bu Group: ${error.message}`,
//       });
//     }
//   };
//   const handleSaveandContinue = async (e) => {
//     e.preventDefault();

//     // Validate the form
//     if (!validateForm()) return;

//     // Check for duplicate BU Group code
//     try {
//       // Skip duplication check for edit mode
//       if (!buGroupId || isCopyMode) {
//         const duplicateCheckResponse = await BuGroupServices.checkDuplicateBugroupcode(
//           `${process.env.REACT_APP_BASE_URL}/getduplicatebugroup`,
//           { BU_Group: buGroup }
//         );

//         if (duplicateCheckResponse.message === "BU group code already exists") {
//           toast("error", "BuGroup Code already exists.");
//           setErrors((prev) => ({
//             ...prev,
//             buGroup: "BuGroup Code already exists.",
//           }));
//           return; // Stop further execution if duplicate exists
//         }
//       }

//       // Prepare the data for submission
//       const data = {
//         id: isCopyMode ? null : buGroupId,
//         BU_Group: buGroup,
//         BU_Group_Description: bugroupDescription,
//         created_by: assignee.assigner,
//         changed_by: assignee.assigner,
//       };

//       console.log("BuGroup data", data);


//       let response;
//       if (isCopyMode || !buGroupId) {
//         // Create a new BU Group
//         response = await BuGroupServices.addBuGroup(
//           `${process.env.REACT_APP_BASE_URL}/create-bugroup`,
//           data
//         );
//       } else {
//         // Update an existing BU Group
//         response = await BuGroupServices.addBuGroup(
//           `${process.env.REACT_APP_BASE_URL}/update-bugroup`,
//           data
//         );
//       }

//       if (response && !response.error) {
//         toast(
//           "success",
//           buGroupId && !isCopyMode
//             ? "Bu Group Updated successfully"
//             : "Bu Group Added successfully"
//         );

//         // Clear the form and navigate
//         setBuGroup("");
//         setBugroupDescription("");


//         // Redirect after saving, only if Save and Continue is false

//       } else {
//         toast("error", "Error adding/updating Bu Group.");
//       }
//     } catch (error) {
//       console.error("Request error:", error);
//       setAlert({
//         severity: "error",
//         message: `Error adding/updating Bu Group: ${error.message}`,
//       });
//     }
//   };

//   const checkDuplicateBugroupcode = async (code) => {
//     try {
//       const response = await BuGroupServices.checkDuplicateBugroupcode(
//         `/getduplicatebugroup`,
//         { BU_Group: code }
//       );
//       console.log("Response from server:", response);

//       if (response.message === "BU group code already exists") {
//         // Check if it's a duplicate
//         console.log("Response from internal server:", response);
//         setErrors({ buGroup: " " });

//         toast("error", "BuGroup Code already exists.");
//       }
//       console.log("Response from external server:", response);
//     } catch (error) {
//       console.error("Error checking duplicate buGroup:", error);
//       // Handle potential errors when making the API call
//       setErrors((prev) => ({ ...prev, buGroup: "Error checking role name" }));
//     }
//   };

//   const handleBugroupCodeChange = (e) => {
//     const code = e.target.value;
//     setBuGroup(code);
//     setUnsavedChanges(true);
//     // Clear previous errors when typing
//     setErrors(prev => ({ ...prev, buGroup: "" }));

//     // Check for duplicate LGE Code locally
//     if (code.trim() !== "") {
//       checkDuplicateBugroupcode(code);
//     }
//   };
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Others') {
      navigate('/14/admin');
    } else if (crumb.title === 'Holiday Calender') {
      navigate('/14/admin/141/holiday-calender/141');
    }
    else if (crumb.title === 'Primary Data') {
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
 
const getApprovedIcon = () => {
  let color = "gray"; // Default color
  // Determine the color based on the approved status
  if (status === 4) {
    color = "#29CB00"; // Green
  } else if (status === 0) {
    color = "gray"; // Gray
  } else if (status === 1) {
    color = "#AF52DE"; 
  } else if (status === 2) {
    color = "yellow"; 
  } else if (status === 3) {
    color = "orange"; 
  }
  else if (status === 5) {
    color = "red"; // Red
  }
  else if (status === 6) {
    color = "black"; // Red
  }
  return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
};
  return (
    <div>
<Header
  title={`Admin > Others > Holiday Calender > ${
    isCopyMode 
      ? 'Add' 
      : buGroupId 
        ? (isViewMode ? "View" : "Edit") 
        : "Add"
  }${buGroupId && (isViewMode || !isCopyMode) ? ` > ${buGroup}` : ''}`}
  onBreadcrumbClick={handleBreadcrumbClick} 
  extraContent={buGroupId && !isCopyMode ? getApprovedIcon() : null}
    hasUnsavedChanges={unsavedChanges}
    backRoute={"/14/admin/141/holiday-calender/141"}
  />
<div className="m-6 border border-gray-200 p-4  rounded-lg">
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 mb-4">
            <LabelWithButton
              label="Calendar Code"
              // isDisabled={isAccontDetaislsDisabled || isViewMode}
              // onClick={() => setIsAccountTypeModalOpen(true)}
              buttonText={ "Calendar Code"}
              className="mb-[-2px]"
            />
            <ReusableModal
              title="Select Calendar Code"
              // onRefresh={fetcAccountType}
              // isOpen={isAccountTypeModalOpen}
              onClose={() => {
                // setIsAccountTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onSave={() => {
                // setIsAccountTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onReset={() => {
                // setAccountId("");
              }}
              showReset={true}
              searchPlaceholder="Search Calendar Code..."
              // searchQuery={searchQuery}
              // onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
            >
              {/* {accountType && accountType.length > 0 ? (
                accountType
                  .filter((account, index, self) =>
                    // Keep only the first occurrence of Account_Type
                    index === self.findIndex((a) => a.Account_Type === account.Account_Type)
                  )
                  .filter((account) =>
                    account.Account_Type
                      ?.toString()
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ).sort((a, b) =>
                    a.Account_Type.localeCompare(b.Account_Type, undefined, { sensitivity: 'base' })
                  )
                  .map((account, index) => (
                    <div
                      key={account.Account_Type}
                      className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                    >
                      <input
                        type="radio"
                        name="AccountType"
                        value={account.Account_Type}
                        checked={accountId === account.Account_Type}
                        onChange={(e) => {
                          handleAccountTypeChange(e.target.value)
                          setUnsavedChanges(true);
                        }}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{account.Account_Type || "N/A"}</label>
                    </div>
                  ))
              ) : (
                <p>{accountType ? "No Account Type Found" : "Loading Account Types..."}</p>
              )} */}

            </ReusableModal>
            <LabelWithButton
              label="Calendar Name"
              // isDisabled={isAccontDetaislsDisabled || isViewMode}
              // onClick={() => setIsZbaTypeModalOpen(true)}
              buttonText={"Calendar Name"}
              className="mb-[-2px]"
            />
            <ReusableModal
              title="Select Calendar Name"
              // onRefresh={fetchZbaType}
              // isOpen={isZbaTypeModalOpen}
              onClose={() => {
                // setIsZbaTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onSave={() => {
                // setIsZbaTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onReset={() => {
                // setZbaid("");
              }}
              showReset={true}
              searchPlaceholder="Search Calendar Name..."
              // searchQuery={searchQuery}
              // onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
            >
              {/* {zbaType && zbaType.length > 0 ? (
                zbaType
                  .filter((zba, index, self) =>
                    // Keep only the first occurrence of ZBA_ID
                    index === self.findIndex((z) => z.ZBA_ID === zba.ZBA_ID)
                  )
                  .filter((zba) =>
                    zba.ZBA_ID
                      ?.toString()
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ).sort((a, b) =>
                    a.ZBA_ID.localeCompare(b.ZBA_ID, undefined, { sensitivity: 'base' })
                  )
                  .map((zba, index) => (
                    <div
                      key={zba.ZBA_ID}
                      className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                    >
                      <input
                        type="radio"
                        name="AccountType"
                        value={zba.ZBA_ID}
                        checked={zbaid == zba.ZBA_ID}
                        onChange={(e) => {
                          handleZbaType(e.target.value)
                          setUnsavedChanges(true); // Mark changes as unsaved
                        }}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{zba.ZBA_ID || "N/A"}</label>
                    </div>
                  ))
              ) : (
                <p>{"No Data Found"}</p>
              )} */}

            </ReusableModal>

          </div>
          
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 mt-4 mb-4">
          <label className="flex items-center">
                <span className="w-[195px] text-xs font-small text-customGray">
                 Month/Year 
                </span>
                <span className="ml-3">:</span>
                <input
                  onChange={(e) => {
                    // setLastStatementDate(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  // value={lastStatementDate}
                  readOnly={isViewMode || isEditMode}
                  type="date"
                  lang="en-GB"
                  className="border p-1.5 text-xs text-customGray rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  maxLength={30}
                />
              </label>
            
            <label className="flex items-center">
                <span className="w-[195px] text-xs font-small text-customGray">
                  Date
                </span>
                <span className="ml-3">:</span>
                <input
                  onChange={(e) => {
                    // setLastStatementDate(e.target.value);
                    setUnsavedChanges(true);
                  }}
                  // value={lastStatementDate}
                  readOnly={isViewMode || isEditMode}
                  type="date"
                  lang="en-GB"
                  className="border p-1.5 text-xs text-customGray rounded w-80 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                  required
                  maxLength={30}
                />
              </label>
          </div>
          <LabelWithInput
            label={"Holiday Name"}
            width=" 2xl:w-[78%] xl:w-[85%] w-full"
            // value={accounClassNotes}
          />
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 mt-4">
          <div className="flex items-center ">
              <label className="2xl:w-52 xl:w-48 lg:w-40 font-small text-xs text-customGray ">
                Holiday Type{" "}
              </label>
              <span className="4">:</span>
              <CustomDropdown
                // value={
                //   overDraftOptions.find(
                //     (option) => option.value === overDraft
                //   ) || null
                // }
                // options={overDraftOptions}
                // onChange={(value) => setOverDraft(value)}
                // isOpen={openDropdown === "overDraft"}
                // onToggle={() => handleToggle("overDraft")}
                // isDisabled={isAccontDetaislsDisabled || isViewMode}
                className="ml-4 border-gray-300"
                placeholder="Public, Company, Bank, Other"
                width="2xl:w-[318px] xl:w-[295px] lg:w-[245px]" 
                />
            </div>
            <LabelWithButton
              label="Value"
              // isDisabled={isAccontDetaislsDisabled || isViewMode}
              // onClick={() => setIsZbaTypeModalOpen(true)}
              buttonText={"Company or Bank"}
              className="mb-[-2px]"
            />
            <ReusableModal
              title="Company or Bank"
              // onRefresh={fetchZbaType}
              // isOpen={isZbaTypeModalOpen}
              onClose={() => {
                // setIsZbaTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onSave={() => {
                // setIsZbaTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onReset={() => {
                // setZbaid("");
              }}
              showReset={true}
              searchPlaceholder="Search Company or Bank..."
              // searchQuery={searchQuery}
              // onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
            >
              {/* {zbaType && zbaType.length > 0 ? (
                zbaType
                  .filter((zba, index, self) =>
                    // Keep only the first occurrence of ZBA_ID
                    index === self.findIndex((z) => z.ZBA_ID === zba.ZBA_ID)
                  )
                  .filter((zba) =>
                    zba.ZBA_ID
                      ?.toString()
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ).sort((a, b) =>
                    a.ZBA_ID.localeCompare(b.ZBA_ID, undefined, { sensitivity: 'base' })
                  )
                  .map((zba, index) => (
                    <div
                      key={zba.ZBA_ID}
                      className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                    >
                      <input
                        type="radio"
                        name="AccountType"
                        value={zba.ZBA_ID}
                        checked={zbaid == zba.ZBA_ID}
                        onChange={(e) => {
                          handleZbaType(e.target.value)
                          setUnsavedChanges(true); // Mark changes as unsaved
                        }}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{zba.ZBA_ID || "N/A"}</label>
                    </div>
                  ))
              ) : (
                <p>{"No Data Found"}</p>
              )} */}

            </ReusableModal>

          </div>
          <div className="grid grid-cols-2 gap-x-8 gap-y-8 mt-4">
            
            <LabelWithButton
              label="Country Code"
              // isDisabled={isAccontDetaislsDisabled || isViewMode}
              // onClick={() => setIsZbaTypeModalOpen(true)}
              buttonText={"Calendar Code"}
              className="mb-[-2px]"
            />
            <ReusableModal
              title="Select ZBA Type"
              // onRefresh={fetchZbaType}
              // isOpen={isZbaTypeModalOpen}
              onClose={() => {
                // setIsZbaTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onSave={() => {
                // setIsZbaTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onReset={() => {
                // setZbaid("");
              }}
              showReset={true}
              searchPlaceholder="Search ZBA Type..."
              // searchQuery={searchQuery}
              // onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
            >
              {/* {zbaType && zbaType.length > 0 ? (
                zbaType
                  .filter((zba, index, self) =>
                    // Keep only the first occurrence of ZBA_ID
                    index === self.findIndex((z) => z.ZBA_ID === zba.ZBA_ID)
                  )
                  .filter((zba) =>
                    zba.ZBA_ID
                      ?.toString()
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ).sort((a, b) =>
                    a.ZBA_ID.localeCompare(b.ZBA_ID, undefined, { sensitivity: 'base' })
                  )
                  .map((zba, index) => (
                    <div
                      key={zba.ZBA_ID}
                      className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                    >
                      <input
                        type="radio"
                        name="AccountType"
                        value={zba.ZBA_ID}
                        checked={zbaid == zba.ZBA_ID}
                        onChange={(e) => {
                          handleZbaType(e.target.value)
                          setUnsavedChanges(true); // Mark changes as unsaved
                        }}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{zba.ZBA_ID || "N/A"}</label>
                    </div>
                  ))
              ) : (
                <p>{"No Data Found"}</p>
              )} */}

            </ReusableModal>
            <LabelWithButton
              label="State/Province"
              // isDisabled={isAccontDetaislsDisabled || isViewMode}
              // onClick={() => setIsZbaTypeModalOpen(true)}
              buttonText={"Calendar Name"}
              className="mb-[-2px]"
            />
            <ReusableModal
              title="Select ZBA Type"
              // onRefresh={fetchZbaType}
              // isOpen={isZbaTypeModalOpen}
              onClose={() => {
                // setIsZbaTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onSave={() => {
                // setIsZbaTypeModalOpen(false);
                // setSearchQuery("");
              }}
              onReset={() => {
                // setZbaid("");
              }}
              showReset={true}
              searchPlaceholder="Search ZBA Type..."
              // searchQuery={searchQuery}
              // onSearchChange={handleSearchChange}
              isViewMode={isViewMode}
            >
              {/* {zbaType && zbaType.length > 0 ? (
                zbaType
                  .filter((zba, index, self) =>
                    // Keep only the first occurrence of ZBA_ID
                    index === self.findIndex((z) => z.ZBA_ID === zba.ZBA_ID)
                  )
                  .filter((zba) =>
                    zba.ZBA_ID
                      ?.toString()
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ).sort((a, b) =>
                    a.ZBA_ID.localeCompare(b.ZBA_ID, undefined, { sensitivity: 'base' })
                  )
                  .map((zba, index) => (
                    <div
                      key={zba.ZBA_ID}
                      className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                    >
                      <input
                        type="radio"
                        name="AccountType"
                        value={zba.ZBA_ID}
                        checked={zbaid == zba.ZBA_ID}
                        onChange={(e) => {
                          handleZbaType(e.target.value)
                          setUnsavedChanges(true); // Mark changes as unsaved
                        }}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{zba.ZBA_ID || "N/A"}</label>
                    </div>
                  ))
              ) : (
                <p>{"No Data Found"}</p>
              )} */}

            </ReusableModal>


          </div>
        </div>
      <Footer>
        {(!isViewMode && (<ButtonGroup 
        // onSave={handleSubmit} onSaveContinue={handleSaveandContinue} 
        // hasId={!!buGroupId} 
        />
        ))}
      </Footer>
    </div>
  );
}
