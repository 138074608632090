import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import CurrencyServices from "../../../../../rest-services/CurrencyServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CurrencyServicess from "../../../../../rest-services/CurrencyServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";

export default function CurrencyAdd() {
  const { currencyId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!currencyId;

  const { toast } = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    if (currencyId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [currencyId]);

  const validateForm = () => {
    const newErrors = {};
    if (!currencyCode) newErrors.currencyCode = "Currency Code is required.";
    if (!currencyName) newErrors.currencyName = "Currency Name is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const getDataForEdit = async () => {
    try {
      const response = await CurrencyServicess.getCurrencyById(currencyId);
      setCurrencyCode(response.data.currencyCode);
      setCurrencyName(response.data.Currency_Name);
    } catch { }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!currencyId) {
        const duplicateCheckResponse = await CurrencyServices.checkDuplicateCurrencycode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatecurrency`,
          { currencyCode: currencyCode }
        );

        if (duplicateCheckResponse.message === "currency code already exists") {
          toast("error", "currency already exists.");
          setErrors((prev) => ({
            ...prev,
            currencyCode: "currency code already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: currencyId || null, // Use 'id' for updates
        currencyCode,
        Currency_Name: currencyName,
        created_by: assignee.assigner,
        changed_by: assignee.assigner, // Will be set in the backend if updating
      };

      let response;
      if (!unsavedChanges) {
        toast(
          "info",
          "No Currency changes detected. Nothing to save."
        );
        navigate("/2/master-data/4/currency"); // Redirect after saving
        return false;
      }      if (currencyId) {
        // Updating the existing currency
        response = await CurrencyServices.addCurrency(
          `${process.env.REACT_APP_BASE_URL}/create-currency`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await CurrencyServices.addCurrency(
          `${process.env.REACT_APP_BASE_URL}/create-currency`,
          data
        );
      }

      if (response && !response.error) {
        toast("success", currencyId ? "Currency Updated successfully" : "Currency Added successfully");
        // Clear the form and navigate
        setCurrencyCode("");
        setCurrencyName("");
        navigate("/2/master-data/4/currency"); // Redirect after saving
      } else {
        toast("error", "Error adding/updating Currency");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Currency: ${error.message}`,
      });
    }
  };
  const handleSaveAndContinue = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!currencyId) {
        const duplicateCheckResponse = await CurrencyServices.checkDuplicateCurrencycode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatecurrency`,
          { currencyCode: currencyCode }
        );

        if (duplicateCheckResponse.message === "currency code already exists") {
          toast("error", "currency already exists.");
          setErrors((prev) => ({
            ...prev,
            currencyCode: "currency code already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: currencyId || null, // Use 'id' for updates
        currencyCode,
        Currency_Name: currencyName,
        created_by: assignee.assigner,
        changed_by: assignee.assigner, // Will be set in the backend if updating
      };


      let response;
      if (!unsavedChanges) {
        toast(
          "info",
          "No Currency changes detected. Nothing to save."
        );
        navigate("/2/master-data/7/legalentity");
        return false;
      }      if (currencyId) {
        // Updating the existing currency
        response = await CurrencyServices.addCurrency(
          `${process.env.REACT_APP_BASE_URL}/create-currency`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new currency
        response = await CurrencyServices.addCurrency(
          `${process.env.REACT_APP_BASE_URL}/create-currency`,
          data
        );
      }

      if (response && !response.error) {
        toast("success", currencyId ? "Currency Updated successfully" : "Currency Added successfully");
        // Clear the form and navigate
        setCurrencyCode("");
        setCurrencyName("");
      } else {
        toast("error", "Error adding/updating Currency");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Currency: ${error.message}`,
      });
    }
  };
  const checkDuplicateCurrencycode = async (code) => {
    try {
      const response = await CurrencyServices.checkDuplicateCurrencycode(
        `/getduplicatecurrency`,
        { currencyCode: code }  // Sending roleName to backend
      );


      console.log("Response from server:", response);

      if (response.message === "currency code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ currencyCode: " " });

        toast("error", "currency already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate currencyCode:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, currencyCode: "Error checking role name" }));
    }
  };

  const handleCurrencyChange = (e) => {
    const code = e.target.value.toUpperCase(); // Ensure input is uppercase
    setUnsavedChanges(true);
    // Limit input to 3 letters
    if (errors.currencyCode) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        currencyCode: "",
      }));
      return; // Stop further processing
    }

    // Clear errors if any


    // Update the calendar code
    setCurrencyCode(code);

    // Check for duplicate Calendar Code locally
    if (code.trim() !== "") {
      checkDuplicateCurrencycode(code);
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {
      console.log("Navigating to /2/master-data");
      navigate('/2/master-data');
    } else if (crumb.title === 'Currency') {
      console.log("Navigating to /country");
      navigate('/2/master-data/4/currency');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
      <Header
        title={`Primary Data > Global Data > Currency > ${currencyId ? (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/2/master-data/4/currency"}
      />


      <div className="m-6 border border-gray-200 p-2  rounded-lg">

        <div className="flex flex-col space-y-4 col-span-1 p-2">
          <label className="flex items-center">
            <span className="w-48 font-small text-xs text-customGray">
              Currency Code<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={currencyCode}
              maxLength={4}

              onChange={handleCurrencyChange}
              className={`border ${errors.currencyCode ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 font-small text-xs text-customGray`}
              required
              disabled={isViewMode || isEditMode}
            />
          </label>
          {!isViewMode && currencyCode.length === 4 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}

          <label className="flex items-center">
            <span className="w-48 font-small text-xs text-customGray">
              Currency Name<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={currencyName}
              maxLength={30}

              onChange={(e) => {
                setCurrencyName(e.target.value)
                setUnsavedChanges(true);
                if (errors.currencyName) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    currencyName: "",
                  }))

                }
              }}
              className={`border ${errors.currencyName ? "border-red-500" : "border-gray-300"
                } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 font-small text-xs text-customGray`}
              required
              disabled={isViewMode}
            />
          </label>
          {!isViewMode && currencyName.length === 30 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}

        </div>

      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
            hasId={!!currencyId}
          />
        )}
      </Footer>
    </div>
  );
}
