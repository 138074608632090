import React, { useEffect, useState } from 'react';
import { BsUbuntu } from 'react-icons/bs';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdTime, IoMdTrash } from 'react-icons/io';
import { LuKeyRound } from 'react-icons/lu';
import { MdOutlineLock } from 'react-icons/md';
import { VscVmActive } from "react-icons/vsc";
import BankBranchServices from "../../../../rest-services/BankBranchServices";
import UserService from '../../../../rest-services/UserServices';
import { GiSettingsKnobs } from 'react-icons/gi';

const PrimaryActions = ({ buttons = [], extraButtons = [], selectedCount, isExtraButtonsVisible = false, selectIds, icons, hasFiltersApplied, }) => {

  const [alert, setAlert] = useState("");
  const [bankbranches, setbankBranches] = useState("");
  const [LGE, setLGE] = useState("");
  const [bugroup, setbugroup] = useState("");
  const [bankid, setbankid] = useState("");
  const [company, setCompany] = useState("");
  const [areAllButtonsDisabled, setAreAllButtonsDisabled] = useState(false);
  const [rolesPermissions, setRolesPermissions] = useState([]);
  const [error, setError] = useState(null);
  const [assignee, setAssignee] = useState({ id: "", username: "", clientId: "" });
  const [loading, setLoading] = useState(false);
  const [MakerCheker, setMakerChecker] = useState("");

  useEffect(() => {
    if (selectIds && selectIds.length > 0) {
      console.log("fetched aray users", selectIds);
      fetchUserPreferenceDetails(icons);
    }
  }, [selectIds, icons]);

  const fetchUserPreferenceDetails = async (icons) => {
    try {
      const data = { icons }; // Assumes 'icons' is the correct field for the request
      console.log("Request Data:", data);

      setLoading(true); // Start loading indicator

      // Call UserService with the correct endpoint and data
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-Icons-data-by-IconId`,
        data
      );

      if (response.data) {
        console.log("Fetched user icons preference details:", response.data);
        // setUserPreference(response.data); // Update state with the user preference details
        setMakerChecker(response.data.isChecked);
        console.log(
          "Fetched user icons MakerCheker details:",
          response.data.isChecked
        );
      } else {
        setError("User preference details not found.");
      }
    } catch (error) {
      console.error("Error fetching user preference details:", error);
      setError(error.message); // Handle error
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };





  const shouldDisableDueToPermission = (button) => {
    const permissionCheck = (permissionsId) =>
      rolesPermissions.some(
        (permission) =>
          permission.permissionsId === permissionsId &&
          permission.iconId === iconId
      );

    //  console.log("checking roles and ", rolesPermissions);
    return (
      (areAllButtonsDisabled &&
        //   // Add Button
        //   button.icon?.name === "IoAddCircleOutline" &&
        //   !permissionCheck(1)) ||
        // // Edit Button
        // (button.icon?.name === "IoMdCreate" && !permissionCheck(2)) ||
        // // Copy
        // (button.icon?.name === "IoMdCopy" && !permissionCheck(4)) ||
        // // Audit Trail
        // (button.icon?.name === "IoMdTime" && !permissionCheck(5)) ||
        // // Approve
        // (button.icon?.name === "IoMdCheckmark" && !permissionCheck(6)) ||
        // // Reject
        // (button.icon?.name === "IoMdClose" && !permissionCheck(7)) ||
        // // Trigger OTP
        // (button.icon?.name === "LuKeyRound" && !permissionCheck(8)) ||
        // // Upload
        // (button.icon?.name === "MdOutlineFileUpload" && !permissionCheck(9)) ||
        // // Download
        // (button.icon?.name === "MdOutlineFileDownload" && !permissionCheck(10)) ||
        // // Inactive
        // (button.icon?.name === "VscVmActive" && !permissionCheck(11)) ||
        // // Lock
        // (button.icon?.name === "MdOutlineLock" && !permissionCheck(12)) ||
        // // Delete
        // (button.icon?.name === "IoMdTrash" && !permissionCheck(13)) ||
        // // Settings
        // (button.icon?.name === "IoSettingsOutline" && !permissionCheck(14)) ||
        //   // Filter
        (button.icon?.name === "GiSettingsKnobs" && !permissionCheck(15))

        // // Refresh

        // (
        // button.icon?.name === "IoMdRefresh" && !permissionCheck(16)

      )
    );
  };


  useEffect(() => {
    if (selectIds && selectIds.length > 0) {
      console.log("fetched aray users", selectIds);
      fetchBankBranches(selectIds);
      fetchArrayLGE(selectIds);
      fetchBankid(selectIds);
      fetchbugroup(selectIds);
      fetchcompany(selectIds);
    }
  }, [selectIds, icons]);

  useEffect(() => {
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };

        setAssignee(assigneeData);
        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };


  useEffect(() => {
    // Function to check if permissions array is empty or not
    const areAllButtonsDisabled = rolesPermissions.length >= 0;
    // const isConsultant = '';
    setAreAllButtonsDisabled(areAllButtonsDisabled);
    console.log("All Buttons Disabled", areAllButtonsDisabled);

  }, [rolesPermissions]);


  useEffect(() => {
    if (assignee.assignerId) {
      fetchUserPermissionDetails();
    }
  }, [assignee.assignerId]);


  const fetchUserPermissionDetails = async () => {
    setLoading(true);
    try {
      const data = { userId: assignee.assignerId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-approved-users-permissions-by-userId`,
        data
      );

      const responseData = response.data.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set roles & permission details", responseData);
    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const fetchArrayLGE = async (ids) => {
    try {
      // Prepare the request data with single UserId
      const requestData = { ids };
      console.log("Request Data from admin actions:", requestData); // Debugging the request data

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-array-LGE-details`,
        requestData // Send the correctly formatted request data
      );

      console.log("API Response from admin actions 07:", response.data);
      setLGE(response.data); // Assuming response data structure has `data` property for roles
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      // Optionally handle the error state
    }
  };
  const fetchBankBranches = async (ids) => {
    try {
      // Prepare the request data with single UserId
      const requestData = { ids };
      console.log("Request Data from admin actions:", requestData); // Debugging the request data

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/getarraybankbranch`,
        requestData // Send the correctly formatted request data
      );

      console.log("API Response from admin actions 11:", response.data);
      setbankBranches(response.data); // Assuming response data structure has `data` property for roles
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      // Optionally handle the error state
    }
  };

  const fetchBankid = async (ids) => {
    try {
      // Prepare the request data with single UserId
      const requestData = { ids };
      console.log("Request Data from admin actions:", requestData); // Debugging the request data

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/getarraybankid`,
        requestData // Send the correctly formatted request data
      );

      console.log("API Response from admin actions 10:", response.data);
      setbankid(response.data); // Assuming response data structure has `data` property for roles
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      // Optionally handle the error state
    }
  };
  const fetchbugroup = async (ids) => {
    try {
      // Prepare the request data with single UserId
      const clientId = localStorage.getItem("clientId");
      const requestData = { ids, clientId };
      console.log("Request Data from admin actions:", requestData); // Debugging the request data

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/getarraybugroup`,
        requestData // Send the correctly formatted request data
      );

      console.log("API Response from admin actions 08:", response.data);
      setbugroup(response.data); // Assuming response data structure has `data` property for roles
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      // Optionally handle the error state
    }
  };
  const fetchcompany = async (ids) => {
    try {
      // Prepare the request data with single UserId
      const requestData = { ids };
      console.log("Request Data from admin actions:", requestData); // Debugging the request data

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/getarraycompany`,
        requestData // Send the correctly formatted request data
      );

      console.log("API Response from admin actions 09:", response.data);
      setCompany(response.data); // Assuming response data structure has `data` property for roles
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      // Optionally handle the error state
    }
  };
  const iconId = parseInt(icons, 10);

  // const getIconSize = (isVisible) => {
  //   return isVisible ? 'text-3xl' : 'text-2xl'; // Reduced size for disabled state
  // };

  const isDeleteButtonDisabled = (button) => {
    if (button.icon === IoMdTrash) {
      if (iconId === 7) {
        // return users.some((user) => user.approved === "4");
        return (LGE || []).some((lge) => lge.status === 4 || lge.status === 5 || lge.status === 3 || lge.status === 6 || lge.status === 2);
      }
      else if (iconId === 8) {
        return (bugroup || []).some((bu) => bu.status === 4 || bu.status === 5 || bu.status === 3 || bu.status === 6 || bu.status === 2);
      }
      else if (iconId === 9) {
        return (company || []).some((com) => com.status === 4 || com.status === 5 || com.status === 3 || com.status === 6 || com.status === 2);
      }
      else if (iconId === 10) {
        return (bankid || []).some((bank) => bank.status === 4 || bank.status === 5 || bank.status === 3 || bank.status === 6 || bank.status === 2);
      }
      else if (iconId === 11) {
        return (bankbranches || []).some((branch) => branch.status === 4 || branch.status === 5 || branch.status === 3 || branch.status === 6 || branch.status === 2);
      }
    }
    return false;
  };


  const isApproveButtonDisabled = (button) => {
    if (button.icon === IoMdCheckmark || button.icon === IoMdTrash) {
      console.log("Is maker and checker are", MakerCheker);
      console.log("isapprove button", iconId);
      if (MakerCheker === 1) {

        if (iconId === 11) {
          return (bankbranches || []).some((branch) => branch.status === 4 || branch.status === 5 || branch.status === 6 ||
            (branch.changed_by !== null &&
              assignee.assigner === branch.changed_by) ||
            assignee.assigner === branch.created_by
          );
        }
        else if (iconId === 7) {
          return (LGE || []).some((lge) => lge.status === 4 || lge.status === 5 || lge.status === 6 ||
            (lge.changed_by !== null &&
              assignee.assigner === lge.changed_by) ||
            assignee.assigner === lge.created_by
          );
        }
        else if (iconId === 8) {
          return (bugroup || []).some((bu) => bu.status === 4 || bu.status === 5 || bu.status === 6 ||
            (bu.changed_by !== null &&
              assignee.assigner === bu.changed_by) ||
            assignee.assigner === bu.created_by
          );
        }
        else if (iconId === 9) {
          return (company || []).some((com) => com.status === 4 || com.status === 5 || com.status === 6 ||
            (com.changed_by !== null &&
              assignee.assigner === com.changed_by) ||
            assignee.assigner === com.created_by
          );
        }
        else if (iconId === 10) {
          return (bankid || []).some((bank) => bank.status === 4 || bank.status === 5 || bank.status === 6 ||
            (bank.changed_by !== null &&
              assignee.assigner === bank.changed_by) ||
            assignee.assigner === bank.created_by
          );
        }
      }
      else if (MakerCheker === 0) {

        if (iconId === 11) {
          return (bankbranches || []).some((branch) => branch.status === 4 || branch.status === 5 || branch.status === 6);
        }
        else if (iconId === 7) {
          return (LGE || []).some((lge) => lge.status === 4 || lge.status === 5 || lge.status === 6);
        }
        else if (iconId === 8) {
          return (bugroup || []).some((bu) => bu.status === 4 || bu.status === 5 || bu.status === 6);
        }
        else if (iconId === 9) {
          return (company || []).some((com) => com.status === 4 || com.status === 5 || com.status === 6);
        }
        else if (iconId === 10) {
          return (bankid || []).some((bank) => bank.status === 4 || bank.status === 5 || bank.status === 6);
        }
      }


    }
    return false;
  };
  const isCloseButtonDisabled = (button) => {
    if (button.icon === IoMdClose) {
      console.log("Is maker and checker are", MakerCheker);
      console.log("isclose button", iconId);
      if (MakerCheker === 1) {

        if (iconId === 11) {
          return (bankbranches || []).some((branch) => branch.status === 4 || branch.status === 5 || branch.status === 6 ||
            (branch.changed_by !== null &&
              assignee.assigner === branch.changed_by) ||
            assignee.assigner === branch.created_by
          );
        }
        else if (iconId === 7) {
          return (LGE || []).some((lge) => lge.status === 4 || lge.status === 5 || lge.status === 6 ||
            (lge.changed_by !== null &&
              assignee.assigner === lge.changed_by) ||
            assignee.assigner === lge.created_by
          );
        }
        else if (iconId === 8) {
          return (bugroup || []).some((bu) => bu.status === 4 || bu.status === 5 || bu.status === 6 ||
            (bu.changed_by !== null &&
              assignee.assigner === bu.changed_by) ||
            assignee.assigner === bu.created_by
          );
        }
        else if (iconId === 9) {
          return (company || []).some((com) => com.status === 4 || com.status === 5 || com.status === 6 ||
            (com.changed_by !== null &&
              assignee.assigner === com.changed_by) ||
            assignee.assigner === com.created_by
          );
        }
        else if (iconId === 10) {
          return (bankid || []).some((bank) => bank.status === 4 || bank.status === 5 || bank.status === 6 ||
            (bank.changed_by !== null &&
              assignee.assigner === bank.changed_by) ||
            assignee.assigner === bank.created_by
          );
        }
      }
      else if (MakerCheker === 0) {

        if (iconId === 11) {
          return (bankbranches || []).some((branch) => branch.status === 4 || branch.status === 5 || branch.status === 6);
        }
        else if (iconId === 7) {
          return (LGE || []).some((lge) => lge.status === 4 || lge.status === 5 || lge.status === 6);
        }
        else if (iconId === 8) {
          return (bugroup || []).some((bu) => bu.status === 4 || bu.status === 5 || bu.status === 6);
        }
        else if (iconId === 9) {
          return (company || []).some((com) => com.status === 4 || com.status === 5 || com.status === 6);
        }
        else if (iconId === 10) {
          return (bankid || []).some((bank) => bank.status === 4 || bank.status === 5 || bank.status === 6);
        }
      }


    }
    return false;
  };
  const getButtonTitle = (button) => {
    if (
      // isApproveButtonDisabled(button) ||
      isDeleteButtonDisabled(button) ||
      isApproveButtonDisabled(button) ||
      isCloseButtonDisabled(button)
      //  ||
      // isTriggerOTPButtonDisabled(button)
    ) {
      if (MakerCheker === 1) {
        if (button.icon === IoMdCheckmark || button.icon === IoMdClose) {
          return "Maker & Checker is Active";
        } else {
          return "Action Disabled";
        }
      }
      else if (MakerCheker === 0) {
        return "Action Disabled";
      }
    }
    else if (
      shouldDisableDueToPermission(button)) {
      return "You Don't have Permission";
    }
    else
      return button.title;
  };

  const shouldDisableDueToSelectedCount = (button) => {
    if (selectedCount > 1) {
      // Check for IoMdCheckmark and IoMdClose icons
      if (button.icon === IoMdCheckmark || button.icon === IoMdClose) {
        if (iconId === 7) {
          return (LGE || []).some((lge) => lge.status == 3 || lge.status == 4 || lge.status == 5);
        }
        else if (iconId === 8) {
          return (bugroup || []).some((bu) => bu.status == 3 || bu.status == 4 || bu.status == 5);
        }
        else if (iconId === 9) {
          return (company || []).some((com) => com.status == 3 || com.status == 4 || com.status == 5);
        }
        else if (iconId === 10) {
          return (bankid || []).some((bank) => bank.status == 3 || bank.status == 4 || bank.status == 5);
        }
        else if (iconId === 11) {
          return (bankbranches || []).some((branch) => branch.status == 3 || branch.status == 4 || branch.status == 5);
        }
      }

      // Disable for other icons if selectedCount > 1
      return (
        button.icon === IoMdTime ||
        button.icon === IoMdCreate ||
        button.icon === BsUbuntu ||
        button.icon === IoMdTrash ||
        button.icon === IoMdCopy ||
        button.icon === VscVmActive ||
        button.icon === LuKeyRound ||
        button.icon === MdOutlineLock
      );
    }

    return false;
  };


  const getIconColor = (icon, disabled) => {
    if (disabled) return 'text-gray-300';

    switch (icon) {
      case IoMdCheckmark:
        return 'text-green-500';
      case IoMdClose:
        return 'text-red-500';
      case IoMdTrash:
        return 'text-red-500';
      case VscVmActive:
        return 'text-green-500';
      case GiSettingsKnobs: // Update filter icon color based on state
        return hasFiltersApplied ? 'text-orange-600' : 'text-sky-900';
      default:
        return 'text-sky-900';
    }
  };
  return (
    <div className="px-4">
      <div className="flex justify-between items-center">
        {/* Existing buttons aligned to the start */}
        <div className="flex items-center space-x-2">

          {buttons.map((button, index) => {
            // const shouldDisableDueToSelectedCount =
            //   selectedCount > 1 &&
            //   (button.icon === IoMdTime ||
            //     button.icon === IoMdCreate ||
            //     button.icon === BsUbuntu ||
            //     button.icon === IoMdTrash ||
            //     // button.icon === IoMdCheckmark ||
            //     // button.icon === IoMdClose ||
            //     button.icon === IoMdCopy ||
            //     button.icon === VscVmActive ||
            //     button.icon === LuKeyRound ||
            //     button.icon === MdOutlineLock);

            const shouldDisableDueToApproval =
              isApproveButtonDisabled(button) ||
              isCloseButtonDisabled(button) ||
              isDeleteButtonDisabled(button);
            //  ||
            // isTriggerOTPButtonDisabled(button);

            const shouldDisable =
              shouldDisableDueToSelectedCount(button);

            return (
              <button
                key={index}
                className={`p-1 rounded-lg ${button.disabled ||
                  shouldDisable ||
                  shouldDisableDueToApproval ||
                  shouldDisableDueToPermission(button)
                  ? ""
                  : "bg-secondary text-secondary-foreground"
                  }`}
                onClick={
                  !shouldDisable &&
                    !shouldDisableDueToApproval &&
                    !shouldDisableDueToPermission(button)
                    ? button.onClick
                    : null
                }
                disabled={
                  shouldDisable ||
                  shouldDisableDueToApproval ||
                  shouldDisableDueToPermission(button)
                }
                title={getButtonTitle(button)}
              >
                <button.icon
                  className={`text-3xl border border-blue-100 rounded-lg cursor-pointer hover:border-customBlue hover:shadow-md p-1  ${button.rotate ? "rotate-90" : ""} ${getIconColor(
                    button.icon,
                    button.disabled ||
                    shouldDisable ||
                    shouldDisableDueToApproval ||
                    shouldDisableDueToPermission(button)
                  )}`}
                />
              </button>
            );
          })}
        </div>

        {/* Extra buttons aligned to the end */}
        <div className="flex items-center space-x-2">
          {extraButtons.map((button, index) => (
            <button
              key={index}
              className={`p-1 rounded-lg ${button.disabled
                ? "bg-gray-200 cursor-not-allowed"
                : "bg-secondary text-secondary-foreground"
                }`}
              onClick={!button.disabled ? button.onClick : null}
              disabled={button.disabled}
              title={button.title}
            >
              <button.icon
                className={`text-3xl border border-dark-gray shadow-md cursor-pointer p-1 hover:border-customBlue ${getIconColor(
                  button.icon,
                  button.disabled
                )}`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrimaryActions;






