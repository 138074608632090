import React, { useEffect, useState } from 'react';
import UploadComponent from '../../../../../../components/UploadComponent';
import * as XLSX from 'xlsx';
import Header from '../../../../../../components/Header';
import { useNavigate } from 'react-router-dom';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import { useToast } from '../../../../../../components/toast/toast';
import UserService from '../../../../../../rest-services/UserServices';
import { update } from 'lodash';
import SourceServices from '../../../../../../rest-services/SourceServices';

export default function SourceUpload() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [isError, setIsError] = useState(false);
  const [buttonText, setButtonText] = useState('Upload from desktop');
  const [isUploaded, setIsUploaded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [assignee, setAssignee] = useState({ id: '', username: '' });
  const [alert, setAlert] = useState(null);

  const [isDisplayChecked, setIsDisplayChecked] = useState(false);
  const [isUploadChecked, setIsUploadChecked] = useState(false);
  const [displayOption, setDisplayOption] = useState('');
  const [uploadOption, setUploadOption] = useState('');


const allowedFormat = [
    'SourceType', 
    'SourceCode', 
    'OurCode', 
    'SourceCodeDescription', 
    'OurCodeDescription', 
    'DateType', 
    'RateType', 
    'Ratio', 
    'MDType', 
  ];
  
  useEffect(() => {
    fetchAssignee();
  }, []);
  
  
  // Fetch the logged-in user details
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem('userDetail');
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || '',
          clientId: userDetail.clientId || '',
          assigner: userDetail.username || '',
          userId: userDetail.userId || '',
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: 'error',
          message: 'No user detail found in session storage.',
        });
      }
    } catch (error) {
      setAlert({
        severity: 'error',
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
  
  // Convert Excel date to JavaScript date
  const excelDateToJSDate = (date) => {
    if (!isNaN(date)) {
      const excelEpoch = new Date(Date.UTC(1899, 11, 30));
      const jsDate = new Date(excelEpoch.getTime() + date * 86400000);
  
      // Format to DD-MM-YYYY
      const day = String(jsDate.getUTCDate()).padStart(2, '0');
      const month = String(jsDate.getUTCMonth() + 1).padStart(2, '0');
      const year = jsDate.getUTCFullYear();
  
      return `${day}-${month}-${year}`;
    }
    return date;
  };
  
  // Handle file upload and validation
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
  
      // Convert sheet to JSON with headers
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      if (jsonData.length === 0) {
        setButtonText('File Format is Incorrect!');
        setIsUploaded(false);
        setIsError(true);
        toast('error', 'The uploaded file is empty or invalid.');
        return;
      }
  
      // Define the new headers
      const newHeaders = [
        'Action',
        'SLNo',
        'SourceType',
        'SourceCode',
        'SourceCodeDescription',
        'OurCode',
        'OurCodeDescription',
        'RateType',
        'DataType',
        'Ratio',
        'MDType',
      ];
  
      // Check if the file format matches the allowed format
      const headers = jsonData[0].map((header) => header.trim().toLowerCase());
      const isSourceFormat = newHeaders
        .map((header) => header.trim().toLowerCase())
        .every((header) => headers.includes(header));
  
      if (!isSourceFormat) {
        setButtonText('File Format is Incorrect!');
        setIsUploaded(false);
        setIsError(true);
        toast('error', 'The uploaded file does not match the required format.');
        return;
      }
  
      // Skip the first two rows (header and any additional row)
      const rows = jsonData.slice(3);
  
      // Process and format rows
      const formattedRows = rows.map((row) => {
        const formattedRow = {};
        newHeaders.forEach((header, index) => {
          formattedRow[header] = row[index] || null; // Use null for missing values
        });
  
        // Handle date format for 'RateDate' (if applicable)
        if (formattedRow.RateDate) {
          formattedRow.RateDate = excelDateToJSDate(formattedRow.RateDate);
        }
  
        return formattedRow;
      });
  
      console.log("Formatted Rows:", formattedRows);
      setTableData(formattedRows);
      setButtonText("File Uploaded");
      setIsUploaded(true);
      setIsError(false);
  
      setTimeout(() => setIsUploaded(false), 1000);
    };
  
    reader.readAsArrayBuffer(file);
  };
  
// I’ve added the date format handling back in! Let me know if this works for you. 🚀

// Let me know if you’d like me to tweak anything! 🚀


const isRowValid = (row, index) => {
  console.log("Row Object in isRowValid:", row);
  const isActionValid = row.Action && ["A", "a"].includes(row.Action) && String(row.Action).length === 1;
  const isSLNoValid = row.SLNo === undefined || row.SLNo === '' || (!isNaN(row.SLNo) && String(row.SLNo).length <= 5);    // Validate required fields
  const isSourcetypeValid = row.SourceType && String(row.SourceType).length <= 10;
  const isSourceCodeValid = row.SourceCode && String(row.SourceCode).length <= 20;
  const isSourceCodeDescriptionValid = row.SourceCodeDescription && String(row.SourceCodeDescription).length <= 50;
  const isOurCodeValid = row.OurCode && String(row.OurCode).length <= 20;
  const isOurCodeDescriptionValid = row.OurCodeDescription && String(row.OurCodeDescription).length <= 50;
  const isRateTypeValid = row.RateType && ['SPT', 'FWD','SER','LON'].includes(row['RateType']);
  // const isDataTypeValid = !row.DataType || String(row.DataType).length <= 3;
  const isDataTypeValid = row.DataType && String(row.DataType).length <= 3;
  const isRatioValid = row.Ratio && String(row.Ratio) === '1:1';
  const isMDTypeValid = row.MDType && ['EXT', 'CHG','INT'].includes(row['MDType']);

  // Validate Bid and Ask fields
  

  // Collect errors
  let errors = [];
  if (!isActionValid) errors.push("Invalid Action");
  if (!isSLNoValid) errors.push("Invalid Serial No");
  if (!isSourcetypeValid) errors.push("Invalid Source Type )");
  if (!isSourceCodeValid) errors.push("Invalid Source Code");
  if (!isSourceCodeDescriptionValid) errors.push("Invalid  Source Code Description )");
  if (!isOurCodeValid) errors.push("Invalid Our Code )");
  if (!isOurCodeDescriptionValid) errors.push("Invalid  Our Code Description )");
  if (!isRateTypeValid) errors.push("Invalid RateType");
  if (!isDataTypeValid) errors.push("Invalid data Type )");
  if (!isRatioValid) errors.push("Invalid ratio");
  if (!isMDTypeValid) errors.push("Invalid Md Type");


  if (errors.length > 0) {
    console.log(`Row ${index + 1} is invalid:`, errors);
  }

  return (
    isRateTypeValid &&
    isActionValid &&
    isSourceCodeValid &&
    isSourceCodeDescriptionValid &&
    isOurCodeDescriptionValid &&
    isOurCodeValid &&
    isMDTypeValid &&
    isDataTypeValid &&
    isSLNoValid &&
    isRatioValid &&
    isSourcetypeValid 
  );
};
const getExistingUserIds = async (tableData) => {
  try {
    // Filter rows based on basic validation
    let validRows = tableData.filter((row, index) => isRowValid(row, index));
    console.log("Initial valid rows:", validRows);

    // Check for existing SourceCode and duplicate combinations in the database
    const validatedRows = await Promise.all(
      validRows.map(async (row) => {
        // Check if SourceType exists in the database
        const sourceCodeExistsResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkexistingmdsource`,
          { source: row.SourceType }
        );

        const sourceCodeExists = sourceCodeExistsResponse?.exists;

        // If SourceCode does not exist, mark the row as invalid
        if (!sourceCodeExists) {
          console.log(`SourceCode ${row.SourceType} does not exist in the database.`);
          return null; // Return null for invalid rows
        }

        // Check for duplicate combination of source, sourceCode, and ourCode in the database
        const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatesource`,
          {
            source: row.SourceType,
            sourceCode: row.SourceCode,
            ourCode: row.OurCode,
          }
        );

        console.log("isDuplicateInDB API Response:", isDuplicateInDBResponse);

        // Extract the `isDuplicate` property from the response
        const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
        console.log("isDuplicateInDB:", isDuplicateInDB);

        // If the combination already exists, mark the row as invalid
        if (isDuplicateInDB) {
          console.log(`Duplicate combination found for SourceType: ${row.SourceType}, SourceCode: ${row.SourceCode}, OurCode: ${row.OurCode}`);
          return null; // Return null for invalid rows
        }

        return row; // Return the row if it is valid
      })
    );

    // Filter out null values (invalid rows)
    validRows = validatedRows.filter((row) => row !== null);
    console.log("Final valid rows after SourceCode and duplicate combination validation:", validRows);

    return validRows;
  } catch (error) {
    console.error("Error fetching existing user IDs:", error);
    return [];
  }
};
  const getDataToDisplay = async () => {
    let dataToDisplay;
    if (uploadOption === "3") {
      console.log("displayOption is 3");
      dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"

      // dataToDisplay = await processData(tableData); // Display processed data if uploadOption is "3"
    } else {
      console.log("displayOption not selected");
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
    
    console.log("dataToDisplay data is", dataToDisplay);
    return dataToDisplay;
  };

  const processData = async (data) => {
    if (!Array.isArray(data)) {
      console.error("processData received invalid data:", data);
      return []; // Return an empty array to prevent further errors
    }
    console.log("processData received data:", data);
  
    return Promise.all(
      data.map(async (row) => {
        const isDuplicateUserId = data.some(
          (r, index) =>
            index !== data.indexOf(row) && // Exclude the current row
            r.SourceType === row.SourceType &&
            r.SourceCode === row.SourceCode &&
            r.OurCode === row.OurCode
        );
        console.log("Duplicate user id is ", isDuplicateUserId);
  
        // Check if the sourceCode exists in the database
        const sourceCodeExistsResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkexistingmdsource`,
          { source: row.SourceType }
        );
  
        const sourceCodeExists = sourceCodeExistsResponse?.exists;
  
        // Check for duplicate combination in the database
        const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatesource`,
          {
            source: row.SourceType,
            sourceCode: row.SourceCode,
            ourCode: row.OurCode,
          }
        );
  
        console.log("isDuplicateInDB API Response:", isDuplicateInDBResponse);
  
        // Extract the `isDuplicate` property from the response
        const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
        console.log("isDuplicateInDB:", isDuplicateInDB);
      // Validate all fields
      const hasNotError = [
        row.Action,
        row.SLNo,
        row.SourceType,
        row.SourceCode,
        row.SourceCodeDescription,
        row.OurCode,
        row.OurCodeDescription,
        row.RateType,
        row.DataType,
        row.Ratio,
        row.MDType,
      ].every((field, index) => {
        let isValid = true;
        let reason = "";
  
        switch (index) {
          case 0: 
          if (!field) {
            isValid = false;
            reason = "Action should not be blank.";
          } 
          else if (!['A', 'a'].includes(field)) {
            isValid = false;
            reason = "Action should be either 'A' or 'a'.";
          }
          else if (String(field).length > 1) { isValid = false; reason = "SLNo exceeds max length of 5."; }
          break;

          case 1: // SLNo
          if (row.SLNo !== undefined && row.SLNo !== '') {
              if (isNaN(row.SLNo)) {
                  isValid = false;
                  reason = "SLNo should be a valid number.";
              } else if (String(row.SLNo).length > 5) {
                  isValid = false;
                  reason = "SLNo exceeds max length of 5.";
              }
          }
          break;

          case 2: // SourceType
          if (!field) {
            isValid = false;
            reason = "SourceType should not be blank.";
          } else if (String(field).length > 10) {
            isValid = false;
            reason = "SourceType exceeds max length of 10.";
          }
          else if (!sourceCodeExists) {
            isValid = false;
            reason = "SourceType does not exist in the database.";
          }
          break;
          case 3: // SourceCode
          if (!field) {
            isValid = false;
            reason = "SourceCode should not be blank.";
          } else if (String(field).length > 20) {
            isValid = false;
            reason = "SourceCode exceeds max length of 20.";
          } else if (field && (isDuplicateUserId || isDuplicateInDB)) {
            isValid = false;
            reason = "Duplicate entry.";
          }
          break;
        
          case 4: // SourceType
          if (String(field).length > 50) {
            isValid = false;
            reason = "Source Code Description exceeds max length of 50.";
          } else if (!field) {
            isValid = false;
            reason = "SourceCode Description should not be blank.";
          }
          break;
          case 5: // SourceType
          if (!field) {
            isValid = false;
            reason = "OurCode should not be blank.";
          } else if (String(field).length > 20) {
            isValid = false;
            reason = "OurCode exceeds max length of 20.";
          }
          break;
          case 6: // SourceType
          if (String(field).length > 50) {
            isValid = false;
            reason = "Our Code Description exceeds max length of 50.";
          } else if (!field) {
            isValid = false;
            reason = "OurCode Description should not be blank.";
          }
          break;

          case 7: // RateType
            if (!field) {
              isValid = false;
              reason = "RateType should not be blank.";
            } else if (!['SPT', 'FWD', 'SER', 'LON'	].includes(field)) {
              isValid = false;
              reason = "RateType should be either 'SPT', 'FWD', 'SER', 'LON'.";
            }
            break;
          case 8: // SourceType
          if (field && String(field).length > 3) {
            isValid = false;
            reason = "Data Type exceeds max length of 3.";
        } else if (!field) {
          isValid = false;
          reason = "Data Type should not be blank.";
        }
        break;

          case 9: // Ratio
          if (!field) {
            isValid = false;
            reason = "Ratio should not be blank.";
          } else if (String(field) !== '1:1') {
            isValid = false;
            reason = "Ratio should be 1:1.";
          }
          break;
        

          case 10: // RateType
          if (!field) {
            isValid = false;
            reason = "MD Type should not be blank.";
          } else if (!['EXT', 'CHG', 'INT'].includes(field)) {
            isValid = false;
            reason = "MD Type should be either 'EXT', 'CHG', 'SER'.";
          }
          break;
  
          default:
            break;
        }
  
        if (!isValid) {
          console.log(`Validation Failed: ${reason}`);
        } else {
          console.log(`Validation Passed: ${field}`);
        }
  
        return isValid;
      });
  
      console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        isDuplicateUserId,
        sourceCodeExists,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        'Action': row['Action'] ? (
          ['A', 'a'].includes(row['Action']) ? (
            row['Action']
          ) : (
            <span style={{ color: 'red' }}>{row['Action']} (Invalid Action)</span>
          )
        ) : (
          <span style={{ color: 'red' }}> Shouldn't be blank</span>
        ),
        'SLNo': row.SLNo ? (
          !isNaN(row.SLNo) && String(row.SLNo).length <= 5 ? (
            row.SLNo
          ) : (
            <span style={{ color: 'red' }}>{row.SLNo} (Invalid SLNo)</span>
          )
        ) : (
          <span style={{ color: 'red' }}></span>
        ),
     
  'SourceType': row['SourceType'] ? (
  String(row['SourceType']).length <= 10 ? (
    sourceCodeExists ? ( // Check if sourceCode exists in the database
      row['SourceType']
    ) : (
      <span style={{ color: 'red' }}>{row.SourceType}, SourceType does not exist</span>
    )
  ) : (
    <span style={{ color: 'red' }}>{row['SourceType']} (Max. allowed character is 10)</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
)
,
  
     'SourceCode': row.SourceCode ? (
  String(row.SourceCode).length <= 20 ? (
    isDuplicateUserId || isDuplicateInDB ? ( // Check for duplication in dataset or database
      <span style={{ color: 'red' }}>{row.SourceCode}, Duplicate SourceCode</span>
    ) : (
      row.SourceCode // No error, return the SourceCode
    )
  ) : (
    <span style={{ color: 'red' }}>{row.SourceCode}, Max. allowed character is 20</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
),
'OurCode': row.OurCode ? (
  String(row.OurCode).length <= 20 ? (
    isDuplicateUserId ? ( // Check for duplication
      <span style={{ color: 'red' }}>{row.OurCode}, Duplicate OurCode</span>
    ) : (
      row.OurCode // No error, return the OurCode
    )
  ) : (
    <span style={{ color: 'red' }}>{row.OurCode}, Max. allowed character is 20</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
)
,
'SourceCodeDescription': row.SourceCodeDescription ? (
  String(row.SourceCodeDescription).length <= 50 ? (
    row.SourceCodeDescription
  ) : (
    <span style={{ color: 'red' }}>{row.SourceCodeDescription} (Max. allowed character is 50)</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
),
'OurCodeDescription': row.OurCodeDescription ? (
  String(row.OurCodeDescription).length <= 50 ? (
    row.OurCodeDescription
  ) : (
    <span style={{ color: 'red' }}>{row.OurCodeDescription} (Max. allowed character is 50)</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
),
'DataType': row.DataType ? (
  String(row.DataType).length <= 3 ? (
    row.DataType
  ) : (
    <span style={{ color: 'red' }}>{row.DataType} (Max. allowed character is 3)</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
),
'RateType': row['RateType'] ? (
  ['SPT', 'FWD', 'SER', 'LON'].includes(row['RateType']) ? (
    row['RateType']
  ) : (
    <span style={{ color: 'red' }}>{row['RateType']} (Invalid Rate Type)</span>
  )
) : (
  <span style={{ color: 'red' }}> Shouldn't be blank</span>
),
'Ratio': row['Ratio'] ? (
  String(row['Ratio']) === '1:1' ? (
    row['Ratio']
  ) : (
    <span style={{ color: 'red' }}>{row['Ratio']} (Invalid Ratio)</span>
  )
) : (
  <span style={{ color: 'red' }}> Shouldn't be blank</span>
),

'MDType': row['MDType'] ? (
  ['EXT', 'CHG', 'INT'].includes(row['MDType']) ? (
    row['MDType']
  ) : (
    <span style={{ color: 'red' }}>{row['MDType']} (Invalid Rate Type)</span>
  )
) : (
  <span style={{ color: 'red' }}> Shouldn't be blank</span>
),
      };
    }));
  };
  const storeArrayDataForUsers = async () => {
    // console.log("store successfulRecords data is", getDataToDisplay);

    setLoading(true);
    try {
        const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
        console.log("table data",tableData);
        console.log("store successfulRecords data 2 is", dataToDisplay);

       const processed = await processData(tableData);
                 console.log("display datas",processed);
       
                const extractText = (cellValue) => {
                             if (Array.isArray(cellValue)) {
                               return cellValue.join(", "); // Convert array to string
                             } else if (typeof cellValue === "string") {
                               return cellValue;
                             } else if (React.isValidElement(cellValue)) {
                               return cellValue.props.children;
                             }
                             return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                           };
               
                           const formattedUsers = processed.map((user) => {
               
                             return {
                              "Action": extractText(user.Action),
                              "SLNo": extractText(user.SLNo),
                              "SourceType": extractText(user.SourceType),
                              "SourceCode": extractText(user.SourceCode),
                              "SourceCodeDescription": extractText(user.SourceCodeDescription),
                              "OurCode": extractText(user.OurCode),
                             "OurCodeDescription": extractText(user.OurCodeDescription),
                             "RateType": extractText(user.RateType),
                             "DataType": extractText(user.DataType),
                             "Ratio": extractText(user.Ratio),
                             "MDType": extractText(user.MDType),
                          
                           };
                         });
                           
                             // Insert second and third row at the beginning of the formatted data
                             const rowsToDisplay = [ ...formattedUsers];
                     
                             console.log("Formatted USERS Data:", formattedUsers);
                             console.log("Formatted Data:", rowsToDisplay);
                     
                             
                             const updatedData = rowsToDisplay.map(user => {
                              const hasErrorField = [
                                "Action",
                                "SLNo",
                                "SourceType",
                                "SourceCode",
                                "SourceCodeDescription",
                                "OurCode",
                                "OurCodeDescription",
                                "RateType",
                                "DataType",
                                "Ratio",
                                "MDType",
                                
                              ].some(field => typeof user[field] === 'object' || user[field]?.includes("(Invalid") || user[field]?.includes("Shouldn't be blank") || user[field]?.includes("Duplicate SourceCode") || user[field]?.includes("SourceCode does not exist"));
                            
                              return {
                                update: hasErrorField ? "No" : "Yes",
                                ...user,
                              };
                            });
                            
                       console.log("updatedData data",updatedData);
                       const mappedData = dataToDisplay.map((row) => ({
                                Action: row.Action,
                                SLNo: row.SLNo,
                                source: row.SourceType,
                                sourceCode: row.SourceCode, // Map SourceType to source
                                sourceCodeDescription: row.SourceCodeDescription,
                                ourCode: row.OurCode,
                                ourCodeDescription: row.OurCodeDescription,
                                rateType: row.RateType,
                                dataType: row.DataType,
                                ratio: row.Ratio, // Map SourceType to source
                                mdType: row.MDType,
                                created_by:assignee.userId
                                
                              }));
                           
        // Prepare the final data object
        const data = {
            dataToDisplay:mappedData, // Only valid rows
            created_by: assignee.userId,
            allrecords: updatedData, // All rows with update flag
        };

        console.log("storeArrayDataForCurrencyRates dataToDisplay is", data);

        // Send the data to the server
        const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/bulk-create-source`,
            data
        );
if (dataToDisplay.length === 0) {
  toast("error", "Error fields exist.");
  return;
}
        else if (response.status === 200) {
            toast('success', 'Uploaded Source successfully');
        } else if (response.status === 400) {
            toast('error', 'Invalid or empty array of Source');
        } else if (response.status === 404) {
            toast('error', 'Missing required fields');
        } else if (response.status === 500) {
            toast('error', 'Duplicate Source found.');
        }
    } catch (error) {
        console.error('Error uploading Source:', error);
    } finally {
        setLoading(false);
    }
};
  
  

  const handleSubmit = async () => {
    if (buttonText === 'Upload from desktop') {
      toast('error', 'Please upload a file.');
      return;
    } else if (tableData.length === 0) {
      toast('error', 'Please upload the correct file containing the data.');
      return;
    }

    // const validRows = tableData.filter((row) => isRowValid(row));
    // if (validRows.length === 0) {
    //   toast('error', 'No valid rows found in the uploaded file.');
    //   return;
    // }

    if (!isDisplayChecked && !isUploadChecked) {
      toast('error', 'Please check at least one checkbox (Display or Upload).');
      return;
    }

    if (uploadOption !== '' && displayOption === '') {
      storeArrayDataForUsers();
    } else {
      if (uploadOption !== '') {
        storeArrayDataForUsers();
      }
      navigate(`/2/master-data/14/source-upload/display-screen`, {
        state: { tableData, displayOption, uploadOption }, // Only pass valid rows
      });
    }
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };


  const confirmDeletion = async () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Define the headers for the Source format
    const headers = ['RateType', 'RateDate', 'SourceType', 'SourceCode', 'Bid', 'Ask'];
  
    // Get the current date
    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  
    // Example: Fill 10 rows with current date
    const rowCount = 10; // You can change this or make it dynamic
    const worksheetData = [headers];
  
    for (let i = 0; i < rowCount; i++) {
      worksheetData.push(['FX', formattedDate, 'SOURCE_TYPE', 'INRKWD=CBKK', '', '']);
    }
  
    // Create a worksheet with headers and data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
    // Create a Blob and trigger the download
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'Currency_Rate_Upload.xlsx';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  };
  
  // Now, every time the user downloads, the date will automatically update in every row! 🚀
  
  // Let me know if you want me to make any tweaks or add dynamic row counts! ✨
  

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === 'Market Data') {
      navigate('/2/master-data');
    } else if (crumb.title === 'Source') {
      navigate('/2/master-data/14/source-code');
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  return (
    <div>
      <Header
        title={`Primary Data > Market Data > Source > Source upload`}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={'/2/master-data/14/Source-code'}
      />
      {loading && <CustomeLoading />}

      <UploadComponent
 downloadPath="/Source_Format_Upload.xlsx"
        handleFileChange={handleFileChange}
        buttonText={buttonText}
        isUploaded={isUploaded}
        isError={isError}
        handleSubmit={handleSubmit}
        isDisplayChecked={isDisplayChecked}
        setIsDisplayChecked={setIsDisplayChecked}
        isUploadChecked={isUploadChecked}
        setIsUploadChecked={setIsUploadChecked}
        displayOption={displayOption}
        setDisplayOption={setDisplayOption}
        uploadOption={uploadOption}
        setUploadOption={setUploadOption}
       
        downloadText='Download Source Format' // Dynamic text
      />

      <ConfirmationDialog
        open={showConfirmation}
        title="Upload File"
        message="Do you need to download the latest upload format?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      />
    </div>
  );
}



