import React, { useState, useEffect } from "react";
import axios from "axios";
import { BamButtonsConfigs } from "../../Components/BamButtonsConfig";
import BamActions from "../../Components/BamActions";
import { useNavigate } from "react-router-dom";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import FilterComponent from "../../Components/FilterComponent";
import { GiSettingsKnobs } from "react-icons/gi";
import ReTable from "../../../../../components/Table";
import Header from "../../../../../components/Header";
import * as XLSX from "xlsx";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import BamServices from "../../../../../rest-services/BamServices";

export default function AccountTypes() {
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAccountTypes = async () => {
      try {
        const response = await BamServices.getAccountType();
        const accountTypesData = response.data.data.map((item) => ({
          id: item.id,
          AccountType: item.Account_Type,
          description: item.Account_Description,
          Notes: item.notes,
          createdBy: item.created_by,
          createdDate: new Date(item.creationDate).toLocaleDateString(),
          createdTime: item.creationTime,
          changedBy: item.changed_by,
          changedDate: new Date(item.changed_date).toLocaleDateString(),
          changedTime: item.changedTime,
        }));
        setData(accountTypesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountTypes();
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleDownload = () => {
    const dataToExport = data.map((item) => ({
      "Account Type": item.AccountType,
      Description: item.description,
      Notes: item.Notes,
      "Created By": item.createdBy,
      "Created Date": item.createdDate || "",
      "Created Time": item.createdTime || "",
      "Changed By": item.changedBy,
      "Changed Date": item.changedDate || "",
      "Changed Time": item.changedTime || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Account Types");

    XLSX.writeFile(workbook, "AccountTypes_Data.xlsx");
  };

  const buttons = BamButtonsConfigs.screen4.map((button) => ({
    ...button,
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/account-types-add")
        : button.icon === GiSettingsKnobs
        ? () => setShowFilter((prev) => !prev)
        : button.icon === MdOutlineFileDownload
        ? handleDownload
        : button.onClick,
  }));

  const handleFilter = (filters) => {
    console.log("Filtering with:", filters);
  };

  const handleReset = () => {
    console.log("Filters reset");
  };

  const columns = [
    { Header: "Account Type", accessor: "AccountType", width: 150 },
    { Header: "Description", accessor: "description", width: 200 },
    { Header: "Notes", accessor: "Notes", width: 150 },
    { Header: "Created by", accessor: "createdBy", width: 150 },
    { Header: "Created date", accessor: "createdDate", width: 150 },
    { Header: "Created time", accessor: "createdTime", width: 150 },
    { Header: "Changed by", accessor: "changedBy", width: 150 },
    { Header: "Changed date", accessor: "changedDate", width: 150 },
    { Header: "Changed time", accessor: "changedTime", width: 150 },
  ];

  const onSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const onSelectAll = () => {
    setSelectedRows(
      selectedRows.length === data.length ? [] : data.map((row) => row.id)
    );
  };

  const onRowDoubleClick = (id) => {
    console.log("Double clicked row with id:", id);
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Set Up") {
      navigate("/bam");
    } else if (crumb.title === "Account Types") {
      navigate("/account-types");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  if (loading)
    return (
      <div>
        <CustomeUpLoader />
      </div>
    );

  return (
    <div>
      <Header
        title={"BAM > Set Up > Account Types"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <BamActions buttons={buttons} />
      {showFilter && (
        <FilterComponent onFilter={handleFilter} onReset={handleReset} />
      )}
      <ReTable
        data={data}
        columns={columns}
        onSelectRow={onSelectRow}
        selectedRows={selectedRows}
        onSelectAll={onSelectAll}
        onRowDoubleClick={onRowDoubleClick}
      />
    </div>
  );
}
