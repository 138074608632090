// import React, { useState } from 'react';
// import DataGrid from 'react-data-grid';
// import { Checkbox } from '@mui/material';
// import styled from 'styled-components';
// import 'react-data-grid/lib/styles.css';
// import { TbFlag3Filled } from 'react-icons/tb';

// // Styled wrapper for the table
// const TableWrapper = styled.div`
//   max-height: 80vh;
//   width: ${(props) => props.customWidth || '100%'};
//   overflow: ${(props) => (props.disableScroll ? 'visible' : 'auto')};
//   position: relative;

//   /* Scrollbar styles */
//   ::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//   }
//   ::-webkit-scrollbar-thumb {
//     background-color: #8aa6c1;
//     border-radius: 6px;
//   }
//   ::-webkit-scrollbar-track {
//     background-color: #f0f4f8;
//     border-radius: 6px;
//   }
//   scrollbar-width: thin;
//   scrollbar-color: #8aa6c1 #f0f4f8;

//   /* DataGrid overrides */
//   .rdg {
//     --header-background-color: #0B60B0;
//     --header-color: white;
//     --row-hover-background-color: #f0f4f8;
//     border: 1px solid #1e3a8a;

//     .rdg-cell {
//       display: flex;
//       align-items: center;
//       font-size: 11px;
//       border-right: 1px solid #bfdbfe;
//       border-bottom: 1px solid #bfdbfe;
//       padding-left: 8px; /* Add some padding */
//     }

//     .rdg-header-row {
//       background: var(--header-background-color);
//       color: var(--header-color);
//       font-weight: 500;
//     }

//     .highlight-row { background-color: #FFE7B7; }
//     .success-row { background-color: #C8FACD; }
//     .selected-row { background-color: #bee3f8; }
//     .even-row { background-color: white; }
//     .odd-row { background-color:rgb(240,249,255); }
//   }
//   .rdg-header-row .rdg-cell {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   .rdg-row-text-color {
//     color: 'red'; /* Default color black */
//   }
//   .text-center {
//     text-align: center !important;
//   }

//   td.text-center {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// `;

// // Modal Component
// const Modal = ({ isOpen, onClose, rowData }) => {
//   if (!isOpen) return null;

//   return (
//     <div style={{
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       zIndex: 1000,
//     }}>
//       <div style={{
//         backgroundColor: 'white',
//         padding: '20px',
//         borderRadius: '8px',
//         width: '50%',
//         maxWidth: '600px',
//       }}>
//         <h2>Row Details</h2>
//         <pre>{JSON.stringify(rowData, null, 2)}</pre>
//         <button onClick={onClose} style={{ marginTop: '10px' }}>Close</button>
//       </div>
//     </div>
//   );
// };

// // Helper function to get a unique row key
// const getRowKey = (row, index, rowKey) => {
//   if (row[rowKey] !== undefined) return row[rowKey];
  
//   const alternativeKeys = ['ID', 'key', 'uuid', 'uniqueId'];
//   for (const key of alternativeKeys) {
//     if (row[key] !== undefined) {
//       console.warn(`Using '${key}' as row identifier since '${rowKey}' was not found`);
//       return row[key];
//     }
//   }

//   console.warn(`Using row index as key for row ${index} - consider adding unique identifier`);
//   return `row-${index}`;
// };

// // Main ReTable component
// const ReTable = ({
//   data,
//   columns,
//   onSelectRow,
//   selectedRows = [],
//   onSelectAll,
//   customRowColor,
//   rowKey = "id",
//   showCheckbox = true,
//   showHeaderCheckbox = true,
//   customWidth = "100%",
//   checkboxPosition = "start",
//   className,
//   disableScroll = false,
// }) => {
//   const [sortColumns, setSortColumns] = useState([]);
//   const [overriddenRows, setOverriddenRows] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedRowData, setSelectedRowData] = useState(null);
//   const [filters, setFilters] = useState({});

//   // Process data to add unique row keys
//   const processedData = (Array.isArray(data) ? data : [data]).map((row, index) => ({
//     ...row,
//     __rowKey: getRowKey(row, index, rowKey),
//     __index: index,
//   }));

//   // Row key getter for DataGrid
//   const rowKeyGetter = (row) => row.__rowKey;

//   // Sort rows based on sortColumns
//   const sortedRows = React.useMemo(() => {
//     if (sortColumns.length === 0) return processedData;

//     return [...processedData].sort((a, b) => {
//       for (const sort of sortColumns) {
//         const comparator = (a, b) => {
//           if (a[sort.columnKey] < b[sort.columnKey]) return -1;
//           if (a[sort.columnKey] > b[sort.columnKey]) return 1;
//           return 0;
//         };
//         const result = comparator(a, b);
//         if (result !== 0) return sort.direction === 'ASC' ? result : -result;
//       }
//       return 0;
//     });
//   }, [processedData, sortColumns]);

//   // Handle row selection
//   const handleRowSelection = (rowId, isMakeAndChecker) => {
//     if (isMakeAndChecker) {
//       setOverriddenRows(prev => 
//         prev.includes(rowId) ? prev.filter(id => id !== rowId) : [...prev, rowId]
//       );
//     }
//     onSelectRow(rowId);
//   };

//   // Check if a row is selected
//   const isRowChecked = (rowId, isMakeAndChecker) => {
//     return selectedRows.includes(rowId) && !overriddenRows.includes(rowId);
//   };

//   // Determine row class based on conditions
//   const rowClass = (row) => {
//     if (row.highlight) return 'highlight-row';
//     if (row.successfulrecords) return 'success-row';
//     if (selectedRows.includes(row.__rowKey)) return 'selected-row';
//     return row.__index % 2 === 0 ? 'even-row' : 'odd-row';
//   };

//   // Check if all rows are selected
//   const allSelected = selectedRows.length === processedData.length;

//   // Handle select all
//   const handleSelectAll = (checked) => {
//     const allIds = processedData.map(row => row.__rowKey);
//     onSelectAll(checked ? allIds : []);
//   };

//   // Handle double-click on a row
//   const handleRowDoubleClick = (row) => {
//     setSelectedRowData(row); // Set the selected row data
//     setIsModalOpen(true); // Open the modal
//   };
//   const filteredRows = React.useMemo(() => {
//     return sortedRows.filter((row) => {
//       return Object.keys(filters).every((columnKey) => {
//         if (!filters[columnKey]) return true;
//         return String(row[columnKey]).toLowerCase().includes(filters[columnKey].toLowerCase());
//       });
//     });
//   }, [sortedRows, filters]);
//   // Format columns to include the checkbox column if needed
//   const formattedColumns = [
//     {
//       key: "select",
//       name: "",
//       width: 50,
//       frozen: true,
//       headerRenderer: () =>
//         showHeaderCheckbox ? (
//           <Checkbox
//             size="small"
//             checked={allSelected}
//             indeterminate={selectedRows.length > 0 && selectedRows.length < processedData.length}
//             onChange={(e) => handleSelectAll(e.target.checked)}
//             sx={{ color: "white", "&.Mui-checked": { color: "white" } }}
//           />
//         ) : null,
//       renderCell: ({ row }) => (
//         <Checkbox
//           size="small"
//           checked={isRowChecked(row.__rowKey, row.makeAndChecker === 1)}
//           onChange={() => handleRowSelection(row.__rowKey, row.makeAndChecker === 1)}
//           sx={{ transform: "scale(0.8)" }}
//         />
//       ),
//     },
//     ...columns.map((col) => ({
//       ...col,
//       resizable: true,
//       sortable: col.sortable || false,
//       headerRenderer: () => (
//         <div className="flex flex-col items-center">
//           <span>{col.name}</span>
//           <input
//             type="text"
//             placeholder="Filter..."
//             value={filters[col.key] || ""}
//             onChange={(e) => setFilters({ ...filters, [col.key]: e.target.value })}
//             style={{
//               width: "100%",
//               fontSize: "10px",
//               padding: "2px",
//               border: "1px solid #ddd",
//             }}
//           />
//         </div>
//       ),
//       renderCell: col.Cell || (({ row }) => (
//         <div
//           style={{
//             textAlign: col.cellClass === "text-left" ? "left" : "center",
//             width: "100%",
//           }}
//         >
//           {row[col.accessor]}
//         </div>
//       )),
//     })),
//   ];
  
  
  
  

//   return (
//     <div className='p-2'>
//       <TableWrapper customWidth={customWidth} disableScroll={disableScroll}>
//       <DataGrid
//   columns={formattedColumns}
//   rows={filteredRows} // Use filteredRows instead of sortedRows
//   rowKeyGetter={rowKeyGetter}
//   rowHeight={25}
//   headerRowHeight={40}
//   className="rdg-light text-[2px] font-small text-customGray"
//   rowClass={rowClass}
//   style={{ height: disableScroll ? "auto" : "80vh" }}
//   onRowDoubleClick={handleRowDoubleClick}
// />
//       </TableWrapper>

//       {/* Modal for displaying row details */}
//       <Modal
//         isOpen={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         rowData={selectedRowData}
//       />
//     </div>
//   );
// };

// export default ReTable;


import React, { useMemo, useState } from 'react';
import { useTable, useResizeColumns, useFlexLayout, useSortBy } from 'react-table';
import { Checkbox } from '@mui/material';
import styled from 'styled-components';
import CustomScroll from './ScrollBarComponent';

const TableWrapper = styled.div`
  max-height: 95vh;
  width: ${(props) => props.customWidth || '100%'};
  overflow: ${(props) => (props.disableScroll ? 'visible' : 'auto')};
  position: relative;

  /* Scrollbar styles */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8aa6c1;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #6b8db2;
  }
  ::-webkit-scrollbar-track {
    background-color: #f0f4f8;
    border-radius: 6px;
  }
  scrollbar-width: thin;
  scrollbar-color: #8aa6c1 #f0f4f8;
`;

const Resizer = styled.div`
  display: inline-block;
  width: 5px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
    transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  background: ${(props) => (props.isResizing ? '#6b8db2' : 'transparent')};
  &:hover {
    background: #6b8db2;
  }
`;

const ReTable = ({
  data,
  columns,
  onSelectRow,
  selectedRows,
  onSelectAll,
  onRowDoubleClick,
  customRowColor,
  rowKey = "id",
  showCheckbox = true,
  showHeaderCheckbox = true,
  customWidth = "100%",
  checkboxPosition = "start",
  className,
  disableScroll = false,
  userTableWidth,
  not20Records = false 
}) => {
  const safeData = Array.isArray(data) ? data : [data];
  const [overriddenRows, setOverriddenRows] = useState([]);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30, // Default minimum width
      width: 150, // Default width
      maxWidth: 500, // Maximum width
   
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: safeData,
        defaultColumn,
      },
      useFlexLayout,
      useSortBy,
      useResizeColumns
    );

  // const totalWidth = useMemo(() => {
  //   return headerGroups.reduce((acc, headerGroup) => {
  //     return acc + headerGroup.headers.reduce((acc, header) => acc + (header.width || 0), 0);
  //   }, 0);
  // }, [headerGroups]);
  //--------------------------- commemted out to make the table to grow in width when resizing ---------------------------------------
  const totalWidth = useMemo(() => {
    return headerGroups.reduce((acc, headerGroup) => {
      return acc + headerGroup.headers.reduce((acc, header) => acc + (header.width || 0), 0);
    }, 0);
  }, [headerGroups, headerGroups.map(hg => hg.headers.map(h => h.width)).flat()]);
//----------------------------------------------------------------------------------------------------------------------------
  const handleRowSelection = (rowId, isMakeAndChecker) => {
    if (isMakeAndChecker) {
      setOverriddenRows((prev) =>
        prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
      );
    }
    onSelectRow(rowId);
  };

  const isRowChecked = (rowId, isMakeAndChecker) => {
    return (
      (selectedRows.includes(rowId) && !overriddenRows.includes(rowId)) ||
      (isMakeAndChecker && !overriddenRows.includes(rowId))
    );
  };

  return (
    <TableWrapper
      {...getTableProps()}
      className="pr-2 pl-2"
      customWidth={customWidth}
      style={{ minWidth: "unset !important" }}
      minWidth= {userTableWidth}
      disableScroll={disableScroll}
      
    >
<CustomScroll maxHeight={disableScroll ? "auto" : not20Records ? "72vh" : "82vh"}>
<table
        className="table-auto bg-white border border-customBlue"
        style={{
          borderCollapse: 'collapse',
          width: `${totalWidth}px`, // Set the table width dynamically
        }}
      >
        <thead className={`bg-customBlue text-center text-sm font-small border border-customBlue sticky top-0 z-10 ${className}`}>
          <tr className="z-20">
            {checkboxPosition === "start" && showCheckbox && (
              <th
                className="text-center text-white text-[11px] font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10"
                style={{
                  width: "30px",
                  minWidth: "30px",
                  maxWidth: "30px",
                  padding: '4px', // Reduced padding
                }}
              >
                {showHeaderCheckbox ? (
                  <Checkbox
                    size="small"
                    checked={selectedRows.length === safeData.length}
                    onChange={onSelectAll}
                    sx={{ transform: "scale(0.8)", padding: '0' }} // Reduced padding
                  />
                ): (
                  <span>Active</span>
                )
 }
              </th>
            )}


            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                {headerGroup.headers.map((column, columnIndex) => {
                  const isCheckboxPositionLastThree = columnIndex === headerGroup.headers.length - 3;
                  const isLastColumn = columnIndex === headerGroup.headers.length - 1;
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={`px-2 text-center text-[11px] text-white font-medium
                        ${!isLastColumn ? 'border-r border-sky-100' : ''}
                        whitespace-nowrap bg-customBlue sticky top-0 z-10`}
                      style={{
                        width: `${column.width}px`,
                        height: '24px',
                        textAlign: column.alignment || 'center',
                        padding: '4px',
                      }}
                    >
                    <span
                      {...column.getSortByToggleProps()}
                      style={{ cursor: 'pointer' }}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </span>
                    {column.canResize && (
                      <Resizer
                        {...column.getResizerProps()}
                        isResizing={column.isResizing}
                      />
                    )}
                  </th>

                  );
                })}
              </React.Fragment>
            ))}


            {checkboxPosition === "end" && showCheckbox && (
              <th
                className="text-center text-white font-medium border-r border-customTableBorder relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
                style={{
                  width: "50px",
                  minWidth: "50px",
                  maxWidth: "50px",
                  padding: '2px', // Reduced padding
                }}
              >
                {showHeaderCheckbox ? (
                  <Checkbox
                    size="small"
                    checked={selectedRows.length === safeData.length}
                    onChange={onSelectAll}
                    sx={{
                      transform: "scale(0.8)",
                      padding: "0",
                      color: "white",
                      "&.Mui-checked": { color: "white" }, // Makes the checkmark white
                      "& .MuiSvgIcon-root": { color: "white" }, // Ensures the checkbox icon is white
                      "& .MuiCheckbox-root": { color: "white" },
                      "& .MuiCheckbox-indeterminate": { color: "white" }, // If using indeterminate state
                    }}
                  />
                ): (
                  <span>Active</span>
                )
              }    
              </th>
            )}
          </tr>
        </thead>

        <tbody className="text-[11px]" {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <tr>
              <td
                colSpan={columns.length + (showCheckbox ? 1 : 0)}
                className="py-1 px-2 text-center"
                style={{ padding: '1px' }} // Reduced padding
              >
                No data found.
              </td>
            </tr>
          ) : (
            rows.map((row, rowIndex) => {
              prepareRow(row);
              const rowId = row.original[rowKey];
              const isMakeAndChecker = row.original.isChecked == 1;
              const isChecked = isRowChecked(rowId, isMakeAndChecker);
              const isLastRow = rowIndex === rows.length - 1;

              return (
                <tr
                  key={rowId}
                  className={
                    row.original.highlight
                      ? "bg-yellow-200"
                      : row.original.successfulrecords
                      ? "bg-green-100"
                      : selectedRows.includes(rowId)
                      ? "bg-blue-100"
                      : row.index % 2 === 0
                      ? "bg-white"
                      : "bg-sky-50"
                  }
                  onDoubleClick={() => onRowDoubleClick(rowId)}
                  style={{
                    height: '24px', // Reduced row height
                  }}
                >
                  {checkboxPosition === "start" && showCheckbox && (
                    <td
                      className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray"
                      style={{ padding: '4px' }} // Reduced padding
                    >
                      <Checkbox
                        size="small"
                        checked={isChecked}
                        onChange={() =>
                          handleRowSelection(rowId, isMakeAndChecker)
                        }
                        sx={{ transform: "scale(0.6)", padding: '0' }} // Reduced padding
                      />
                    </td>
                  )}

                  {row.cells.map((cell, cellIndex) => {
                    const isLastColumn = cellIndex === row.cells.length - 1;
                    const isCheckboxPositionLastThree = cellIndex === row.cells.length - 1;
                    return (
                      <td
                        key={cell.column.id}
                        {...cell.getCellProps()}
                        className={`px-2 text-center ${!isLastRow ? 'border-b' : ''} ${!isLastColumn ? 'border-r' : ''} border-customTableBorder whitespace-nowrap text-gray-700`}
                        style={{
                          width: `${cell.column.width}px`,
                          height: '24px', // Reduced cell height
                          textAlign: cell.column.alignment || "center",
                          padding: '4px', // Reduced padding
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}

                  {checkboxPosition === "end" && showCheckbox && (
                    <td
                      className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray"
                      style={{ padding: '4px' }} // Reduced padding
                    >
                      <Checkbox
                        size="small"
                        checked={isChecked}
                        onChange={() =>
                          handleRowSelection(rowId, isMakeAndChecker)
                        }
                        sx={{ transform: "scale(0.8)", padding: '0' }} // Reduced padding
                      />
                    </td>
                  )}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      </CustomScroll>
     
     {/* <table
   className="table-auto bg-white border border-customBlue"
   style={{
     borderCollapse: 'collapse',
     width: `${totalWidth}px`, // Set the table width dynamically
   }}
>
  <thead
    className={`bg-customBlue text-center text-sm font-small border border-customBlue sticky top-0 z-10 ${className}`}
  >
    <tr className="z-20">
      {checkboxPosition === "start" && showCheckbox && (
        <th
        className="text-center text-white text-[11px] font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10"
        style={{
          width: "30px",
          minWidth: "30px",
          maxWidth: "30px",
          padding: '4px', // Reduced padding
        }}
        >
          {showHeaderCheckbox ? (
            <Checkbox
              size="small"
              checked={selectedRows.length === (safeData?.length || 0)}
              onChange={onSelectAll}
              sx={{ transform: "scale(0.8)", padding: "0" }}
            />
          ) : (
            <span>Active</span>
          )}
        </th>
      )}

      {Array.isArray(headerGroups) && headerGroups.map((headerGroup) => (
          <React.Fragment key={headerGroup.id}>
            {Array.isArray(headerGroup.headers) && headerGroup.headers.map((column, columnIndex) => {
                const isLastColumn = columnIndex === headerGroup.headers.length - 1;
                const isBeforeLastThree = checkboxPosition === "lastThreeColumn" && showCheckbox && columnIndex === headerGroup.headers.length - 3;

                return (
                  <React.Fragment key={column.id}>
                    {isBeforeLastThree && (
                      <th
                        className="text-center text-white font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10"
                        style={{
                          width: "50px",
                          minWidth: "50px",
                          maxWidth: "50px",
                          padding: "2px",
                        }}
                      >
                        {showHeaderCheckbox ? (
                          <Checkbox
                            size="small"
                            checked={selectedRows.length === (safeData?.length || 0)}
                            onChange={onSelectAll}
                            sx={{ transform: "scale(0.8)", padding: "0", color: "white" }}
                          />
                        ) : (
                          <span>Active</span>
                        )}
                      </th>
                    )}

                    <th
                     {...column.getHeaderProps()}
                     className={`px-2 text-center text-[11px] text-white font-medium
                       ${!isLastColumn ? 'border-r border-sky-100' : ''}
                       whitespace-nowrap bg-customBlue sticky top-0 z-10`}
                     style={{
                       width: `${column.width}px`,
                       height: '24px',
                       textAlign: column.alignment || 'center',
                       padding: '4px',
                     }}
                    >
                      <span {...column.getSortByToggleProps()} style={{ cursor: "pointer" }}>
                        {column.render("Header")}
                        <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                      </span>
                      {column.canResize && <Resizer {...column.getResizerProps()} 
                      isResizing={column.isResizing} />}
                    </th>
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        ))}

      {checkboxPosition === "end" && showCheckbox && (
        <th
        className="text-center text-white font-medium border-r border-customTableBorder relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
        style={{
          width: "50px",
          minWidth: "50px",
          maxWidth: "50px",
          padding: '2px', // Reduced padding
        }}
      >
          {showHeaderCheckbox ? (
            <Checkbox
              size="small"
              checked={selectedRows.length === (safeData?.length || 0)}
              onChange={onSelectAll}
              sx={{
                transform: "scale(0.8)",
                padding: "0",
                color: "white",
                "&.Mui-checked": { color: "white" }, // Makes the checkmark white
                "& .MuiSvgIcon-root": { color: "white" }, // Ensures the checkbox icon is white
                "& .MuiCheckbox-root": { color: "white" },
                "& .MuiCheckbox-indeterminate": { color: "white" }, // If using indeterminate state
              }}
            />
          ) : (
            <span>Active</span>
          )}
        </th>
      )}
    </tr>
  </thead>

  <tbody className="text-[10px]" {...getTableBodyProps()}>
    {Array.isArray(rows) && rows.length > 0 ? (
      rows.map((row, rowIndex) => {
        prepareRow(row);
        const rowId = row.original?.[rowKey] ?? rowIndex; // Ensure valid key
        const isChecked = isRowChecked(rowId, row.original?.isChecked == 1);

        return (
          <tr key={rowId}>
            {checkboxPosition === "start" && showCheckbox && (
              <td className="text-center border-r border-customTableBorder">
                <Checkbox
                  size="small"
                  checked={selectedRows.includes(rowId)}
                  onChange={() => onSelectRow(rowId)}
                />
              </td>
            )}

            {Array.isArray(columns) &&
              columns.map((column, index) => (
                <React.Fragment key={column.id}>
                  {checkboxPosition === "lastThreeColumn" && showCheckbox && index === columns.length - 3 && (
                    <td className="text-center border-r border-customTableBorder">
                      <Checkbox
                        size="small"
                        checked={selectedRows.includes(rowId)}
                        onChange={() => onSelectRow(rowId)}
                      />
                    </td>
                  )}
                  <td className="text-center border-r border-customTableBorder">{row.values[column.accessor]}</td>
                </React.Fragment>
              ))}

            {checkboxPosition === "end" && showCheckbox && (
              <td className="text-center border-r border-customTableBorder">
                <Checkbox
                  size="small"
                  checked={selectedRows.includes(rowId)}
                  onChange={() => onSelectRow(rowId)}
                />
              </td>
            )}
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan={(columns?.length || 0) + (showCheckbox ? 1 : 0)} className="py-1 px-2 text-center">
          No data found.
        </td>
      </tr>
    )}
  </tbody>
</table> */}


    </TableWrapper>
  );
};

export default ReTable;












































































































































































































































































































































































































// import React, { useState } from 'react';
// import { useTable, useResizeColumns, useFlexLayout, useSortBy } from 'react-table';
// import { Checkbox } from '@mui/material';
// import styled from 'styled-components';

// const TableWrapper = styled.div`
//   max-height: 80vh;
//   width: ${(props) => props.customWidth || '100%'};
//   overflow: ${(props) => (props.disableScroll ? 'visible' : 'auto')};
//   position: relative;

//   /* Scrollbar styles */
//   ::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//   }
//   ::-webkit-scrollbar-thumb {
//     background-color: #8aa6c1;
//     border-radius: 6px;
//   }
//   ::-webkit-scrollbar-thumb:hover {
//     background-color: #6b8db2;
//   }
//   ::-webkit-scrollbar-track {
//     background-color: #f0f4f8;
//     border-radius: 6px;
//   }
//   scrollbar-width: thin;
//   scrollbar-color: #8aa6c1 #f0f4f8;
// `;

// const ReTable = ({
//   data,
//   columns,
//   onSelectRow,
//   selectedRows,
//   onSelectAll,
//   onRowDoubleClick,
//   customRowColor,
//   rowKey = "id",
//   showCheckbox = true,
//   showHeaderCheckbox = true,
//   customWidth = "100%",
//   checkboxPosition = "start",
//   className,
//   disableScroll = false,
// }) => {
//   const safeData = Array.isArray(data) ? data : [data];
//   const [overriddenRows, setOverriddenRows] = useState([]);

//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable(
//       {
//         columns,
//         data: safeData,
//       },
//       useFlexLayout,
//       useSortBy,
//       useResizeColumns
//     );

//   const handleRowSelection = (rowId, isMakeAndChecker) => {
//     if (isMakeAndChecker) {
//       setOverriddenRows((prev) =>
//         prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
//       );
//     }
//     onSelectRow(rowId);
//   };

//   const isRowChecked = (rowId, isMakeAndChecker) => {
//     return (
//       (selectedRows.includes(rowId) && !overriddenRows.includes(rowId)) ||
//       (isMakeAndChecker && !overriddenRows.includes(rowId))
//     );
//   };

//   return (
//     <TableWrapper
//       {...getTableProps()}
//       className="pr-2 pl-2"
//       customWidth={customWidth}
//       disableScroll={disableScroll}
//     >
//       <table
//         className="table-fixed min-w-full bg-white border border-customBlue"
//         style={{
//           borderCollapse: 'collapse',
//         }}
//       >
//         <thead className={`bg-customBlue text-center text-sm font-small border border-customBlue sticky top-0 z-10 ${className}`}>
//           <tr className="z-20">
//             {checkboxPosition === "start" && showCheckbox && (
//               <th
//                 className="text-center text-white text-[11px] font-medium border-r border-customTableBorder whitespace-nowrap bg-customBlue sticky top-0 z-10"
//                 style={{
//                   width: "30px",
//                   minWidth: "30px",
//                   maxWidth: "30px",
//                   padding: '4px', // Reduced padding
//                 }}
//               >
//                 {showHeaderCheckbox ? (
//                   <Checkbox
//                     size="small"
//                     checked={selectedRows.length === safeData.length}
//                     onChange={onSelectAll}
//                     sx={{ transform: "scale(0.8)", padding: '0' }} // Reduced padding
//                   />
//                 ) : (
//                   <span>Active</span>
//                 )}
//               </th>
//             )}

//             {headerGroups.map((headerGroup) => (
//               <React.Fragment key={headerGroup.id}>
//                 {headerGroup.headers.map((column, columnIndex) => {
//                   const isLastColumn = columnIndex === headerGroup.headers.length - 1;
//                   return (
//                     <th
//                       {...column.getHeaderProps(column.getSortByToggleProps())}
//                       className={`px-2 text-center text-[11px] text-white font-medium
//             ${!isLastColumn ? 'border-r border-sky-100' : ''}
//             whitespace-nowrap bg-customBlue sticky top-0 z-10`}
//                       style={{
//                         width: `${column.width}px`,
//                         height: '24px', // Reduced header height
//                         textAlign: column.alignment || 'center',
//                         padding: '4px', // Reduced padding
//                       }}
//                     >
//                       {column.render('Header')}
//                       <span>
//                         {column.isSorted
//                           ? column.isSortedDesc
//                             ? ' 🔽'
//                             : ' 🔼'
//                           : ''}
//                       </span>
//                       {column.canResize && (
//                         <div
//                           {...column.getResizerProps()}
//                           className="absolute top-0 right-0 w-2 h-full cursor-col-resize"
//                           style={{ transform: 'translateX(50%)' }}
//                         />
//                       )}
//                     </th>
//                   );
//                 })}
//               </React.Fragment>
//             ))}

//             {checkboxPosition === "end" && showCheckbox && (
//               <th
//                 className="text-center text-white font-medium border-r border-customTableBorder relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
//                 style={{
//                   width: "50px",
//                   minWidth: "50px",
//                   maxWidth: "50px",
//                   padding: '2px', // Reduced padding
//                 }}
//               >
//                 {showHeaderCheckbox ? (
//                   <Checkbox
//   size="small"
//   checked={selectedRows.length === safeData.length}
//   onChange={onSelectAll}
//   sx={{
//     transform: "scale(0.8)",
//     padding: "0",
//     color: "white",
//     "&.Mui-checked": { color: "white" }, // Makes the checkmark white
//     "& .MuiSvgIcon-root": { color: "white" }, // Ensures the checkbox icon is white
//     "& .MuiCheckbox-root": { color: "white" },
//     "& .MuiCheckbox-indeterminate": { color: "white" }, // If using indeterminate state
//   }}
// />
//                 ) : (
//                   <span>Active</span>
//                 )}
//               </th>
//             )}
//           </tr>
//         </thead>
//         <tbody className="text-[10px]" {...getTableBodyProps()}>
//           {rows.length === 0 ? (
//             <tr>
//               <td
//                 colSpan={columns.length + (showCheckbox ? 1 : 0)}
//                 className="py-1 px-2 text-center"
//                 style={{ padding: '1px' }} // Reduced padding
//               >
//                 No data found.
//               </td>
//             </tr>
//           ) : (
//             rows.map((row, rowIndex) => {
//               prepareRow(row);
//               const rowId = row.original[rowKey];
//               const isMakeAndChecker = row.original.makeAndChecker === 1;
//               const isChecked = isRowChecked(rowId, isMakeAndChecker);
//               const isLastRow = rowIndex === rows.length - 1;

//               return (
//                 <tr
//                   key={rowId}
//                   className={
//                     row.original.highlight
//                       ? "bg-yellow-200"
//                       : row.original.successfulrecords
//                       ? "bg-green-100"
//                       : selectedRows.includes(rowId)
//                       ? "bg-blue-100"
//                       : row.index % 2 === 0
//                       ? "bg-white"
//                       : "bg-sky-50"
//                   }
//                   onDoubleClick={() => onRowDoubleClick(rowId)}
//                   style={{
//                     height: '24px', // Reduced row height
//                   }}
//                 >
//                   {checkboxPosition === "start" && showCheckbox && (
//                     <td
//                       className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray"
//                       style={{ padding: '4px' }} // Reduced padding
//                     >
//                       <Checkbox
//                         size="small"
//                         checked={isChecked}
//                         onChange={() =>
//                           handleRowSelection(rowId, isMakeAndChecker)
//                         }
//                         sx={{ transform: "scale(0.6)", padding: '0' }} // Reduced padding
//                       />
//                     </td>
//                   )}

//                   {row.cells.map((cell, cellIndex) => {
//                     const isLastColumn = cellIndex === row.cells.length - 1;
//                     return (
//                       <td
//                         key={cell.column.id}
//                         {...cell.getCellProps()}
//                         className={`px-2 text-center ${!isLastRow ? 'border-b' : ''} ${!isLastColumn ? 'border-r' : ''} border-customTableBorder whitespace-nowrap text-gray-700`}
//                         style={{
//                           width: `${cell.column.width}px`,
//                           height: '24px', // Reduced cell height
//                           textAlign: cell.column.alignment || "center",
//                           padding: '4px', // Reduced padding
//                         }}
//                       >
//                         {cell.render("Cell")}
//                       </td>
//                     );
//                   })}

//                   {checkboxPosition === "end" && showCheckbox && (
//                     <td
//                       className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray"
//                       style={{ padding: '4px' }} // Reduced padding
//                     >
//                       <Checkbox
//                         size="small"
//                         checked={isChecked}
//                         onChange={() =>
//                           handleRowSelection(rowId, isMakeAndChecker)
//                         }
//                         sx={{ transform: "scale(0.8)", padding: '0' }} // Reduced padding
//                       />
//                     </td>
//                   )}
//                 </tr>
//               );
//             })
//           )}
//         </tbody>
//       </table>
//     </TableWrapper>
//   );
// };

// export default ReTable;