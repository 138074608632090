import React, { useEffect, useState } from "react";

import { BamButtonsConfigs } from "../../../Components/BamButtonsConfig";
import BamActions from "../../../Components/BamActions";
import { useNavigate, useParams } from "react-router-dom";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCreate,
  IoMdTrash,
} from "react-icons/io";
import Header from "../../../../../../components/Header";
import { FaRegEdit } from "react-icons/fa";
import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdOutlineHistory,
} from "react-icons/md";
import { PiCopy } from "react-icons/pi";
import { TbFlag3Filled } from "react-icons/tb";
import ReTable from "../../../../../../components/Table";
import BamServices from "../../../../../../rest-services/BamServices";
import { useToast } from "../../../../../../components/toast/toast";
import SearchInput from "../../../../../../components/SearchInput";
import { BsTags } from "react-icons/bs";
import CustomButton from "../../../../../../components/CustomButton";
import { IoMdRefresh } from "react-icons/io";
import { FiBox } from "react-icons/fi";
import SetUpServices from "../../../../../../rest-services/SetUpServices";
import ConfirmationDialog from "../../../../../../components/Deletionpopup";
import { LuCopyCheck } from "react-icons/lu";
import { GiSettingsKnobs } from "react-icons/gi";
import FilterDropdown from "../../../../../../components/FilterDropdown";
import HoverButton from "../../../../../../components/HoverButton";
import { RxDropdownMenu } from "react-icons/rx";
import Pagination from "../../../../../../components/PageNation";
import { VscVmActive, VscVmConnect } from "react-icons/vsc";
import ReusableModal from "../../../../../../components/PopupComponent";
import CheckboxPopup from "../../../../../../components/CheckBoxComponent";

export default function BankAccountMaster() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(20); // Default page size
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);

  const { toast } = useToast();
  const { iconId } = useParams();

  useEffect(() => {
    fetchAssignee();
  }, []);
  const [assignee, setAssignee] = useState({
    clientId: "",
    id: "",
    userId: "",
    assigner: "",
  });
  useEffect(() => {
    if (assignee.clientId) {
      fetchBankAccount(currentPage);
    }
  }, [assignee]);
  const handleNavigate = (path) => {
    navigate(path);
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          clientId: userDetail.clientId || "",
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          userId: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) { }
  };

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === "BAM") {
      navigate("/bam");
    } else if (crumb.title === "Bank Master Data") {
      navigate("/3/BAM/26/account-master");
    } else if (crumb.title === "Account Master") {
      navigate("/3/BAM/26/account-master");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };





  const existingButtons = BamButtonsConfigs.screen5.map((button) => {
    let onClick;
    let title;

    if (button.icon === IoMdRefresh) {
      onClick = () => {
        fetchBankAccount(1);
        setCurrentPage(1);
      };
      title = "Refresh";
    } else if (button.icon === IoAddCircleOutline) {
      onClick = () => handleNavigate("/3/BAM/26/account-master-add/1");
      title = "Add Account";
    } else if (button.icon === IoSettingsOutline) {
      // onClick = handleSettingsButtonClick;
      title = "Table Settings";
    } else if (button.icon === GiSettingsKnobs) {
      onClick = () => {
        setIsFilterPopupOpen(true);
      };
      title = "Filter";
    } else if (button.icon === MdOutlineFileUpload) {
      onClick = () => handleNavigate("/3/BAM/26/account-master-upload");
      title = "Upload File";
    } else if (button.icon === MdOutlineFileDownload) {
      onClick = () => downLoadData();
      title = "Download File";
    } else {
      onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
      title = button.title || ""; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title, // Add the title property to the button configuration
    };
  });

  const [currentstatus, setCurrentStatus] = useState("");



  const extraButtons = [
    {
      icon: IoMdCheckmark,
      title: "Approve",
      disabled: selectedRows.length === 0,
      onClick: () => handleApprove(selectedRows),
    },
    {
      icon: IoMdClose,
      title: "Reject",
      onClick: () => handleReject(selectedRows),
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdCreate,
      onClick: () =>
        navigate(`/3/BAM/26/account-master-add/3/${selectedId}`, {
          state: { mode: "edit" },
        }),
      title: "Edit",
      disabled: selectedRows.length === 0,
    },
    {
      icon: FaRegEdit,
      onClick: () => handleClickUpdate(selectedId),
      title: "Bank Account Number Update",
      disabled: selectedRows.length === 0,
    },

    {
      icon: BsTags,
      onClick: () => {
        setModalOpen(true);
      },
      title: "Status Change",
      disabled: selectedRows.length === 0,
    },
    {
      icon: FiBox,
      onClick: () => {
        setCompanyModalOpen(true);
      },
      title: "Company Change",
      disabled: selectedRows.length === 0,
    },
    {
      icon: PiCopy,
      onClick: () => navigate(`/3/BAM/26/account-master-add/5/${selectedId}`),
      title: "Copy",
      disabled: selectedRows.length === 0,
    },
    {
      icon: LuCopyCheck,
      onClick: () => navigate(`/3/BAM/26/account-master-add/6/${selectedId}`),
      title: "Special Copy",
      disabled: selectedRows.length === 0,
    },
    {
      icon: MdOutlineHistory,
      onClick: () =>
        navigate(`/bam-audit-trail/${selectedId}/26`, {
          state: { mode: "edit" },
        }),
      title: "Audit Trail",
      disabled: selectedRows.length === 0,
    },
    { icon: IoMdTrash, onClick: () => handleDelete(), title: "Delete", disabled: selectedRows.length === 0, },
    {
      icon: currentstatus.toString() === '6' ? VscVmActive : VscVmConnect,
      onClick: () => handleActiveInactive(selectedId),
      title: currentstatus.toString() === '6' ? "Active" : "Inactive",
      disabled: selectedRows.length === 0,
    },
  ];

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length == 0,
      onClick: selectedRows.length > 0 ? button.onClick : null,
    }))
  );

  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchBankAccount(1, searchQuery); // Fetch data with updated page size
  };

  const handleApprove = async (selectedRows) => {
    try {
      const matchingRow = tableData.find((row) => row.id === selectedRows);
      if (matchingRow && matchingRow.status === 3) {
        toast("warning", "Already Approved");
        return false;
      }
      const response = await BamServices.approveBankAccount(
        selectedRows,
        assignee.userId,
        assignee.assigner
      );
      if (response.status === 200) {
        toast("success", "Approved Successfully");
        fetchBankAccount(currentPage);
        setSelectedRows([]);
        setSelectedId([]);
        setShowExtraIcons(false);
      } else {
        toast("error", `${response.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleReject = async (selectedRows) => {
    try {
      const matchingRow = tableData.find((row) => row.id === selectedRows);
      if (matchingRow && matchingRow.status === 4) {
        toast("warning", "Already In Rejected Status");
        return false;
      }
      const response = await BamServices.rejectBankAccount(
        selectedRows,
        assignee.userId
      );
      if (response.status === 200) {
        toast("success", "Bank Account Rejected");
        fetchBankAccount(currentPage);
        setSelectedRows([]);
        setSelectedId([]);
        setShowExtraIcons(false);
      } else {
        toast("error", `${response.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  // //Navigate To Special Edit Page
  const handleClickUpdate = async (id) => {
    try {
      const matchingRow = tableData.find((row) => row.id === id);
      if (!matchingRow) {
        toast("error", "Row not found.");
        return;
      }

      const { accountNo, status } = matchingRow;


      if (accountNo) {
        console.log("Id is", id);

        handleNavigate(`/3/BAM/26/account-master-add/2/${id}`);
      }

      else {
        if (status === 3) {
          handleNavigate(`/3/BAM/26/account-master-add/2/${id}`);
        }

        else if (status === 4) {
          handleNavigate(`/3/BAM/26/account-master-add/2/${id}`);
        }
        else if (!accountNo) {
          toast("error", "Since the Request is not approved, Bank account no. update is not possible");
        } else {
          toast("error", "Since the Request is not approved, Bank account no. update is not possible");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast("error", "An unexpected error occurred.");
    }
  };




  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [isCompanyModalOpen, setCompanyModalOpen] = useState(false);

  const handleCompanyModalClose = () => {
    setCompanyModalOpen(false);
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0) return;
    setShowConfirmation(true);
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };

  const confirmDeletion = async () => {
    setShowConfirmation(false);
    setLoading(true);
    try {
      const response = await BamServices.deleBankAccount(
        selectedId,
        assignee.clientId
      );
      if (response.status === 200) {
        toast("success", "Deleted Successfully");
        fetchBankAccount(currentPage);
        setSelectedRows([]);
        setSelectedId([]);
        setShowExtraIcons(false);
      } else {
        toast("error", `${response.message}`);
      }
      selectedId(null);
      fetchBankAccount(currentPage);
    } catch (error) {
      console.error("Error deleting Bank Account:", error);
    } finally {
      setLoading(false);
    }
  };


  const handleActiveInactive = async () => {
    try {
      const response = await BamServices.activeInactiveBankAccount(
        selectedId,
        assignee.userId
      );
      if (response.status === 200) {
        toast("success", response.message);
        fetchBankAccount(currentPage);
        setSelectedRows([]);
        setSelectedId([]);
        setShowExtraIcons(false);
      } else {
        toast("error", response.message);
      }
    } catch (error) { }
  };

  const columns = React.useMemo(() => [
    {
      Header: "Status",
      accessor: "status",
      width: ("".length - 2) * 10, Cell: ({ value }) => {
        let color;
        switch (value) {

          case 0:
            color = "gray"; // Gray for status 0
            break;
          case 1:
            color = "#AF52DE"; // Purple for status 1
            break;
          case 2:
            color = "yellow"; // Yellow for status 2
            break;
          case 3:
            color = "orange"; // Orange for status 3
            break;
          case 4:
            color = "#29CB00"; // Green for status 4
            break;
          case 5:
            color = "red"; // Red for status 5
            break;
          case 6:
            color = "black"; // Balck for status 6
            break;
          default:
            color = "black"; // Default black flag
        }
        return <TbFlag3Filled style={{ color, textAlign: "left" }} />;
      },
    },
    {
      Header: "Request No",
      accessor: "reqNo",
      width: ("Request No".length - 2) * 10,
      aling: "left",
    },
    {
      Header: "Account Status",
      accessor: "accountStatus",
      width: ("Account Status".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{String(value).toUpperCase()}</div>,
    },
    {
      Header: "Approve For",
      accessor: "approveFor",
      width: ("Approve For".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Company Code",
      accessor: "companyID",
      width: ("Company Code".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Account ID",
      accessor: "bankAccountId",
      width: ("Bank Account ID".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Account No",
      accessor: "accountNo",
      width: ("Bank Account No".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Account Description",
      accessor: "bankAccountDescription",
      width: ("Bank Account Description".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "IBAN No",
      accessor: "iBanNo",
      width: ("IBAN No".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Account Currency",
      accessor: "currencyID",
      width: ("Bank Account Currency".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Statement",
      accessor: "StatementDetails.statementIdentifier",
      width: ("Bank Statement".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Branch",
      accessor: "BankBranch.bankBranch",
      width: ("Bank Branch".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Branch Name",
      accessor: "BankBranch.branchName",
      width: ("Branch Name".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Id",
      accessor: "BankBranch.BankId",
      width: ("Bank Id".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Name",
      accessor: "BankBranch.BankName",
      width: ("Bank Name".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bank Bic",
      accessor: "BankBranch.identifier[0].bankBIC",
      width: ("Bank Bic".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "County Code",
      accessor: "BankBranch.identifier[0].country_Code",
      width: ("County Code".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },

    {
      Header: "Country Code Type",
      accessor: "BankBranch.identifier[0].countryCodeType",
      width: ("Country Code Type".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },

    {
      Header: "MICR",
      accessor: "BankBranch.identifier[0].micr",
      width: ("MICR".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "OverDraft"
      , accessor: "overDraft"
      , width: ("OverDraft".length - 2) * 10,
      Cell: ({ value }) => {
        switch (value) {
          case 1:
            return <div style={{ textAlign: "left" }}> Yes</div>;
          case 0:
            return <div style={{ textAlign: "left" }}> No</div>;

          default:
            return <div style={{ textAlign: "left" }}> No</div>;
        }
      },
    },
    {
      Header: "Account Type",
      accessor: "accountID",
      width: ("Account Type".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "ZBA Type",
      accessor: "zbaID",
      width: ("ZBA Type".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Notes",
      accessor: "notes",
      width: ("Notes".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "LGE Code",
      accessor: "legalEntityAccount.LGE_Code",
      width: ("LGE Code".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Legal Entity Name",
      accessor: "legalEntityAccount.LGE_Description",
      width: ("Legal Entity Name".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bu Group",
      accessor: "buGroup",
      width: ("Bu Group".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Bu Group Name",
      accessor: "buGroupName",
      width: ("Bu Group Name".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Last Statement Date",
      accessor: "StatementDetails.lastStatementDate",
      width: ("Last Statement Date".length - 2) * 10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Last Statement Closing Balance",
      accessor: "StatementDetails.lastStateClosingbalance",
      width: ("Last Statement Closing Balance".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Last Statement NO",
      accessor: "StatementDetails.lastStatementNo",
      width: ("Last Statement NO".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "OD Limit",
      accessor: "OdLimitDetails.odLimit",
      width: ("OD Limit".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Interest Indicator",
      accessor: "OdLimitDetails.intrestIndicator",
      width: ("Interest Indicator".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "OD Date",
      accessor: "OdLimitDetails.odDate",
      width: ("OD Date".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Minimum Balance",
      accessor: "OdLimitDetails.minimumBalance",
      width: ("Minimum Balance".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Dormant",
      accessor: "BamControls.dormant",
      width: ("Dormant".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Main Bank Account",
      accessor: "BamControls.mainBankAccount",
      width: ("Main Bank Account".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Text Field 1",
      accessor: "TextFieldsDetails.textFields1",
      width: ("Main Bank Account".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Text Field 2",
      accessor: "TextFieldsDetails.textFields2",
      width: ("Main Bank Account".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Text Field 3",
      accessor: "TextFieldsDetails.textFields3",
      width: ("Main Bank Account".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Text Field 4",
      accessor: "TextFieldsDetails.textFields4",
      width: ("Main Bank Account".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Text Field 5",
      accessor: "TextFieldsDetails.textFields5",
      width: ("Main Bank Account".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Created By",
      accessor: "created_by",
      width: ("Created By".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Created Date",
      accessor: "creationDate",
      width: ("Created Date".length - 2) * 10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },

    {
      Header: "Created Time",
      accessor: "creationTime",
      width: ("Created Time".length - 2) * 10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },

    {
      Header: "Updated By",
      accessor: "changed_by",
      width: ("Updated By".length - 2) * 10,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
    },
    {
      Header: "Updated Date",
      accessor: "changedDate",
      width: ("Updated Date".length - 2) * 10,
      Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adds leading zero if month < 10
        const year = date.getFullYear();
        return <div style={{ textAlign: "left" }}> {`${day}-${month}-${year}`}</div>;
      },
    },
    {
      Header: "Updated Time",
      accessor: "changedTime",
      width: ("Updated Time".length - 2) * 10,
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined values
        const date = new Date(value);
        const formattedTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Ensures 12-hour format with AM/PM
        });
        return <div style={{ textAlign: "left" }}>{formattedTime}</div>;
      },
    },
  ]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      fetchBankAccount(currentPage);
    } else {
      fetchSearchedBankAccount(searchQuery);
    }
  }, [searchQuery]);

  const fetchSearchedBankAccount = async (searchQuery) => {
    console.log("searchQuery BM", searchQuery);
    setLoading(true);
    try {
      const response = await BamServices.searchBankAccount(searchQuery);
      if (response && response.data) {
        const totalCount = Number(response.totalCount) || 0;
        const pages = Math.ceil(totalCount / (pageSize || 1));
        setTableData(response.data.data);
        setTotalCount(totalCount);
        setTotalPages(pages);
      }
    } catch (error) {
      console.error("Error fetching Bank Account Data", error);
    } finally {
      setLoading(false);
    }
  };

  const [filters, setFilters] = useState({});
  useEffect(() => {
    fetchBankAccount(currentPage, filters);
  }, [currentPage, filters]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchBankAccount = async (page, filters) => {
    setLoading(true);
    if (!assignee.clientId) {
      return null;
    }
    try {
      const response = await BamServices.getDataForTable(filters, pageSize, page);
      if (response && response.data) {
        const totalCount = Number(response.totalCount) || 0;
        const pages = Math.ceil(totalCount / (pageSize || 1));
        setTableData(response.data);
        setTotalCount(totalCount);
        setTotalPages(pages);
      }
    } catch (error) {
      console.error("Error fetching Bank Account Data", error);
    } finally {
      setLoading(false);
    }
  };

  const onSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(rowId);
      const nextSelectedRows = isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId];
      setShowExtraIcons(nextSelectedRows.length === 1);
      if (nextSelectedRows.length === 1 && !isSelected) {
        setSelectedId(rowId);
      } else if (nextSelectedRows.length === 0) {
        setSelectedId(null);
      }
      handlCurrentStatus(rowId)
      return nextSelectedRows;
    });
  };

  const handlCurrentStatus = (id) => {
    try {
      const account = tableData.find((row) => row.id.toString() === id.toString())
      setCurrentStatus(account.status)
      console.log(typeof account.status)
    } catch (error) {

    }
  }

  const onSelectAll = () => {
    if (selectedRows.length === tableData.length) {
      setSelectedRows([]);
      setSelectedId(null);
      setShowExtraIcons(false);
    } else {
      setSelectedRows(tableData.map((row) => row.id));
    }
  };

  // Function to handle double-click on a row
  const handleDoubleClick = (id) => {
    navigate(`/3/BAM/26/account-master-add/4/${id}`, {
      state: { mode: "view" },
    });
  };

  const AssignNewStatusModal = ({ isOpen, onClose }) => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("");
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [options, setOptions] = useState({
      receiveMT940: false,
      ftout: false,
      ftin: false,
      cashAccounting: false,
      reconciliation: false,
      odCalculation: false,
      loanRepayment: false,
      lcRepayment: false,
    });
    const [validFrom, setValidFrom] = useState("");

    useEffect(() => {
      fetchStatus();
    }, []);

    const fetchStatus = async () => {
      try {
        const status = tableData.find((row) => row.id === selectedId);
        console.log(status);

        const response = await BamServices.getAccountStatus(
          assignee.clientId,
          status.accountStatus
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };
    const handleSubmit = async () => {
      const data = {
        client: assignee.clientId,
        accountId: selectedId,
        status: status,
        validFrom,
        changedBy: assignee.userId,
      };
      const response = await BamServices.assignNewStatus(data);
      if (response.status === 200) {
        toast("success", "Status Changed  Successfully");
        fetchBankAccount(currentPage);
        onClose();
      } else {
        toast("error", "Status Change Failed");
      }
    };

    const handleStatusChange = (e) => {
      setStatus(e.target.value);
      const selectedStatus = data.find(
        (status) => status.id.toString() === e.target.value
      );
      if (selectedStatus) {
        setOptions({
          receiveMT940: selectedStatus.receiveMt940 === 1 ? true : false,
          ftout: selectedStatus.ftOut === 1 ? true : false,
          ftin: selectedStatus.ftIn === 1 ? true : false,
          cashAccounting: selectedStatus.cashAccounting === 1 ? true : false,
          reconciliation: selectedStatus.reconciliation === 1 ? true : false,
          odCalculation: selectedStatus.odCalculation === 1 ? true : false,
          loanRepayment: selectedStatus.loanRepayments === 1 ? true : false,
          lcRepayment: selectedStatus.lcRepayments === 1 ? true : false,
        });
      }
    };

    if (!isOpen) return null;
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };
    const handleResetStatusSelection = () => {
      setSearchQuery("");
      setSelectedId(null);
    }
    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
        <div className="ml-16 bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-3xl h-half relative">
          <h2 className="text-lg font-semibold mb-4 text-blue-600 p-4">
            Assigning New Status
          </h2>

          <div className="display-flex mb-4 flex items-center p-4">
            <label className="w-48 text-sm font-medium text-gray-500">
              Status<span className="text-red-500">*</span>
            </label>
            <span className="ml-4">:</span>
            <button
              onClick={() => setIsStatusModalOpen(true)}
              className={`border border-gray-300 p-1 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-4 text-left flex items-center justify-between text-customGray border-gray-300 hover:border-blue-400`}
            >
              <span className="font-small text-customGray text-sm">
                {status && data.find((s) => s.id.toString() === status.toString())?.status || "Select Status"}
              </span>
              <RxDropdownMenu className="ml-2" />
            </button>
            <ReusableModal
              title="Select Status"
              isOpen={isStatusModalOpen}
              onRefresh={fetchStatus}
              onClose={() => {
                setIsStatusModalOpen(false);
                setSearchQuery("");
              }}
              onSave={() => {
                setIsStatusModalOpen(false);
                setSearchQuery("");
              }}
              searchPlaceholder="Search Status..."
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              isViewMode={false}
              showReset={true}
              onReset={() => {
                handleResetStatusSelection();
              }}
            >
              {data && data.length > 0 ? (
                data
                  .filter((statuses) =>
                    statuses.status?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .sort((a, b) =>
                    a.status.localeCompare(b.status, undefined, { sensitivity: "base" })
                  )
                  .map((statuses, index) => (
                    <div
                      key={statuses.id}
                      className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
                    >
                      <input
                        type="radio"
                        name="StatusID"
                        value={statuses.id}
                        checked={statuses.id.toString() === status?.toString()}
                        onChange={(e) => handleStatusChange(e)}
                        className="mr-2 accent-customBlue"
                      />
                      <label>{statuses.status || "N/A"}</label>
                    </div>
                  ))
              ) : (
                <p>{data ? "No Status Found" : "Loading Status..."}</p>
              )}
            </ReusableModal>
          </div>
          <div className="mb-6 mt-6 p-4">
            <div className="grid grid-cols-4 gap-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.receiveMT940}
                  //onChange={() => handleOptionChange("receiveMT940")}
                  className="mr-2 border-2 border-blue-500 focus:ring-blue-500"
                />
                Receive MT940
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.ftout}
                  //onChange={() => handleOptionChange("ftout")}
                  className="mr-2"
                />
                FTOUT
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.ftin}
                  // onChange={() => handleOptionChange("ftin")}
                  className="mr-2"
                />
                FTIN
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.cashAccounting}
                  //  onChange={() => handleOptionChange("cashAccounting")}
                  className="mr-2"
                />
                Cash Accounting
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.reconciliation}
                  // onChange={() => handleOptionChange("reconciliation")}
                  className="mr-2"
                />
                Reconciliation
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.odCalculation}
                  //onChange={() => handleOptionChange("odCalculation")}
                  className="mr-2"
                />
                OD Calculation
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.loanRepayment}
                  //  onChange={() => handleOptionChange("loanRepayment")}
                  className="mr-2"
                />
                Loan Repayment
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={options.lcRepayment}
                  //onChange={() => handleOptionChange("lcRepayment")}
                  className="mr-2"
                />
                LC Repayment
              </label>
            </div>
          </div>

          <div className="display-flex mb-4 flex items-center p-4">
            <label className="w-48 text-sm font-medium text-gray-500">
              Valid From <span className="text-red-600">*</span>
            </label>
            <span className="ml-4">:</span>
            <input
              value={validFrom}
              onChange={(e) => setValidFrom(e.target.value)}
              type="Date"
              className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
              required
            />
          </div>
          <div className="flex justify-end mt-4">
            <HoverButton
              title={"Close"}
              className={"mr-2"}
              onClick={onClose}
            />
            <CustomButton
              onClick={() => {
                handleSubmit();
              }}
              title={"Save"}
            />
          </div>
        </div>
      </div>
    );
  };

  const AssignNewCompanyCode = ({ isOpen, onClose, accountId, data }) => {
    const [company, setCompany] = useState([]);
    const [companyCode, setCompanyCode] = useState([]);
    const [buGroup, setBuGroup] = useState([]);
    const [legalEntity, setLegalEntity] = useState([]);
    const [validFrom, setValidFrom] = useState("");
    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false)
    //New Pop up Things
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
    };

    const fetchLeagalEntityFromData = async () => {
      const entity = data.find((item) => item.id === accountId);
      if (entity) {
        const response = await BamServices.getLegalEntityById(entity.legalEntityID);
        if (response.status === 200) {
          setLegalEntity(response.data.entities.LGE_Code);
          setBuGroup(entity.buGroup);
        }
      }
    }

    useEffect(() => {
      if (isOpen) {
        fetchLeagalEntityFromData();
      }
    }, []);

    useEffect(() => {

      if (legalEntity && isOpen) {
        fetchCompnayCode();
      }
    }, [legalEntity]);

    const fetchCompnayCode = async () => {
      try {
        if (!legalEntity) return
        const response = await BamServices.getCompanyCode(legalEntity);
        console.log(response.data.data)
        if (response.data.status === 200) {
          setCompany(response.data.data);
        }
      } catch (error) {
        console.log(error)
      }
    };

    const handleSubmit = async () => {
      const data = {
        client: assignee.clientId,
        accountId,
        companyCode,
        buGroup,
        validFrom,
        changedBy: assignee.userId,
      };
      if (!companyCode || !validFrom) return toast("error", "Please Fill All The Aequired Fields");
      const response = await BamServices.assignNewCompanyCode(data);

      if (response.status === 200) {
        toast("success", "Company Code Successfully");
        fetchBankAccount(currentPage);
        onClose();
      } else {
        toast("error", "Company Code Failed");
      }
    };

    const handleCompanyCodeChange = (e) => {
      setCompanyCode(e.target.value);


      const selectedCompany = company.find(
        (item) => item.companyCode === e.target.value
      );
      if (selectedCompany) {
        setBuGroup(selectedCompany.buGroupCode);
        setLegalEntity(selectedCompany.legalEntityCode);
      }
    };

    if (!isOpen) return null;


    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-4xl relative">
          <h2 className="text-lg font-semibold mb-4 text-blue-600 p-4">
            Assigning New Company Code
          </h2>
          {/* Company Code */}
          <div className="flex mb-4 items-center p-4 gap-4">
            <label
              htmlFor="companyCode"
              className="whitespace-nowrap font-medium"
            >
              Company <span className="text-red-600">*</span>
            </label>
            <span>:</span>
            <button
              onClick={() => setIsCompanyModalOpen(true)}
              className={`border border-gray-300 p-1 rounded w-72 h-10  focus:outline-none hover:border-blue-400 ml-1 text-left flex items-center justify-between text-customGray 
                border-gray-300 hover:border-blue-400
                }`}
            >
              <span className="font-small text-customGray text-sm">
                {companyCode.length > 0 ? companyCode : "Select Company Code"}
              </span>
              <RxDropdownMenu className="ml-2" />
            </button>
            <ReusableModal
              title="Select Company Code"
              // onRefresh={fetchBankAccountCompany(legalEntityID)}
              isOpen={isCompanyModalOpen}

              onClose={() => {
                setIsCompanyModalOpen(false);
                setSearchQuery("");
              }}
              onSave={() => {
                setIsCompanyModalOpen(false);
                setSearchQuery("");
              }}
              onReset={() => {
                setCompanyCode("");
              }}
              showReset={true}
              searchPlaceholder="Search Company..."
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              isViewMode={false}
            >

              {
                company && company.length > 0 ? (
                  company
                    .filter((company) => company.companyCode !== tableData.find((item) => item.id === accountId)?.companyID)
                    .filter((company) =>
                      company.companyCode
                        ?.toString()
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    ).sort((a, b) =>
                      a.companyCode.localeCompare(b.companyCode, undefined, { sensitivity: 'base' })
                    )
                    .map((company, index) => (
                      <div
                        key={company.companyCode}
                        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                          }`}
                      >
                        <input
                          type="radio"
                          name="Company Code"
                          value={company.companyCode}
                          checked={companyCode == company.companyCode}
                          onChange={(e) => {
                            handleCompanyCodeChange(e);
                          }}
                          className="mr-2 accent-customBlue"
                        />
                        <label>{company.companyCode || "N/A"}</label>{" "}
                      </div>
                    ))
                ) : (
                  <p>{"No Data Found"}</p>
                )}
            </ReusableModal>
          </div>
          {/* Legal Entity and Bu Group */}
          <div className="flex mb-4 items-center p-4 gap-8">
            <div className="flex items-center gap-4">
              <label
                htmlFor="legalEntity"
                className="whitespace-nowrap font-medium"
              >
                Legal Entity
              </label>
              <span>:</span>
              <input
                id="legalEntity"
                type="text"
                value={legalEntity}
                readOnly
                className="border p-2 rounded w-72 focus:outline-none hover:border-blue-400 border-gray-300"
              />
            </div>
            <div className="flex items-center gap-4">
              <label
                htmlFor="buGroup"
                className="whitespace-nowrap font-medium"
              >
                Bu Group
              </label>
              <span>:</span>
              <input
                value={buGroup}
                id="buGroup"
                type="text"
                readOnly
                className="border p-2 rounded w-72 focus:outline-none hover:border-blue-400 border-gray-300"
              />
            </div>
          </div>

          {/* Valid From */}
          <div className="flex mb-4 items-center p-4 gap-4">
            <label
              htmlFor="validFrom"
              className="whitespace-nowrap font-medium"
            >
              Valid From<span className="text-red-600">*</span>
            </label>
            <span>:</span>
            <input
              id="validFrom"
              type="date"
              value={validFrom}
              onChange={(e) => setValidFrom(e.target.value)}
              className="border p-2 rounded w-72 focus:outline-none hover:border-blue-400 border-gray-300"
              required
            />
          </div>

          {/* Submit Button */}
          {/* <div className="flex justify-center items-center mt-6">
            <CustomButton
              title="Submit"
              className="h-10 w-40 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 focus:outline-none"
              onClick={handleSubmit}
            />
          </div> */}
          <div className="flex justify-end mt-4">
            <HoverButton
              title={"Cancel"}
              className={"mr-2"}
              onClick={onClose}
            />
            <CustomButton
              onClick={() => {
                handleSubmit()
              }}
              title={"Save"}
            />
          </div>
        </div>
      </div>
    );
  };

  const statusOptions = [
    { value: "0", label: "Created", className: "text-gray-500" },
    { value: "1", label: "Changed", className: "text-[#AF52DE]" },
    { value: "2", label: "Waiting For Approval", className: "text-yellow-500" },
    { value: "3", label: "Modified", className: "text-orange-400" },
    { value: "4", label: "Approved", className: "text-[#29CB00]" },
    { value: "5", label: "Rejected", className: "text-red-500" },
    { value: "6", label: "Inactive", className: "text-black" },
  ];




  useEffect(() => {
    if (isFilterPopupOpen) {
      fetchStatus();
      fetchCompanyCode();
      fetchBranch()
      fetchLegalEnity()
      fetchBugroup()
      fetchAllBankAccountId()
      fetchUserId()
    }
  }, [isFilterPopupOpen]);

  const [newRequest, setNewRequest] = useState(false);
  const [accountNo, setAccountNo] = useState(false);
  const [status, setStatus] = useState("");
  const [accountStatus, setAccountStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filterType, setFilterType] = useState("1");


  const fetchStatus = async () => {
    try {
      const response = await SetUpServices.getAccountStatus(assignee.clientId);
      setAccountStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching status:", error);
    }
  };
  const handleFilterChange = (e) => {
    setStatus(e.target.value)
  };
  const handleApplyNewRequest = (isChecked) => {
    if (isChecked) {
      setNewRequest(true);
    } else {
      setNewRequest(false);

    }
  };


  const handleApplyAccountNo = (isChecked) => {
    if (isChecked) {
      setAccountNo(true);
    } else {
      setAccountNo(false);
    }
  };

  const [modalStates, setModalStates] = useState({
    bankAccountID: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankBranch: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankID: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bank: { isOpen: false, searchQuery: "", selected: [], tempSelected: [] },
    company: { isOpen: false, searchQuery: "", selected: [], tempSelected: [] },
    buGroup: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    legalEntity: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankCountry: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    bankBic: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    createdeBy: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    updatedBy: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
    approveFor: { isOpen: false, selected: [], tempSelected: [], searchQuery: "" },
  });

  const [dataLists, setDataLists] = useState({
    company: [],
    branch: [],
    legalEntity: [],
    buGroup: [],
    bankAccountID: [],
    createdeBy: [],
    updatedBy: [],
    statement: [],
    approveFor: ["New Request", "Bank Account ID Update", "Status Change", "Company Code Change"]
  });




  const fetchCompanyCode = async () => {
    try {
      const response = await BamServices.getCompanyCodeFil();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          company: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchUserId = async () => {
    try {
      const response = await BamServices.getUserIDFil();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          createdeBy: data,
          updatedBy: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }


  const fetchBranch = async () => {
    try {
      const response = await BamServices.getBranchFil();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          branch: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchLegalEnity = async () => {
    try {
      const response = await BamServices.getLegalEntityFil();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          legalEntity: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchBugroup = async () => {
    try {
      const response = await BamServices.getBuGroupFil();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          buGroup: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchAllBankAccountId = async () => {
    try {
      const response = await BamServices.getBankAccountIdFil();
      if (response.status === 200) {
        const data = response.data.data;
        setDataLists((prev) => ({
          ...prev,
          bankAccountID: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }


  const toggleModal = (key, isOpen) => {
    setModalStates((prev) => ({
      ...prev,
      [key]: { ...prev[key], isOpen },
    }));
  };

  const handleSelection = (key, value) => {
    setModalStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        tempSelected: prev[key].tempSelected.includes(value)
          ? prev[key].tempSelected.filter((item) => item !== value)
          : [...prev[key].tempSelected, value],
      },
    }));
  };

  const handleApplySelection = (key) => {
    setModalStates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        selected: [...prev[key].tempSelected],
        isOpen: false,
      },
    }));
  };

  const handleResetSelection = (key) => {
    setModalStates((prev) => ({
      ...prev,
      [key]: { ...prev[key], tempSelected: [] },
    }));
  };
  const handleResetFilters = () => {
    setNewRequest(false);
    setAccountNo(false);
    setAccountStatus([]);
    setStatus("");
    setModalStates(prevState => {
      return Object.keys(prevState).reduce((acc, key) => {
        acc[key] = {
          ...prevState[key],
          tempSelected: [],
          selected: []
        };
        return acc;
      }, {});
    });
    setFilters({});
    fetchBankAccount(currentPage);
  };
  const downLoadData = async () => {
    try {
      const response = await BamServices.getExportData(
        assignee.clientId,
        filters
      );

      if (response) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "bank_account.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast("success", "Downloaded Successfully");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      toast("error", "Failed to download data");
    }
  }
  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  }
  const FilterPopUp = ({ setFilters }) => {

    const [createdFromDate, setCreatedFromDate] = useState("");
    const [createdToDate, setCreatedToDate] = useState("");
    const [updatedFromDate, setUpdatedFromDate] = useState("");
    const [updatedToDate, setUpdatedToDate] = useState("");

    const handleFilterDateFrom = (e) => {
      console.log("Date From Changed:", e.target.value);
      setCreatedFromDate(e.target.value);
      if (filterType === "1") {

      } else {
        setUpdatedFromDate(e.target.value);
      }
    };

    const handleFilterDateTo = (e) => {
      console.log("Date To Changed:", e.target.value);
      if (filterType === "1") {
        setCreatedToDate(e.target.value);
      } else {
        setUpdatedToDate(e.target.value);
      }
    };
    const handleApplyFilter = async () => {
      try {
        setHasFiltersApplied(true);
        const filters = {
          accountStatus: selectedStatus,
          approveFor: modalStates.approveFor.tempSelected,
          bank: modalStates.bank.tempSelected,
          bankAccountId: modalStates.bankAccountID.tempSelected,
          bankBranch: modalStates.bankBranch.tempSelected,
          bankId: modalStates.bankID.tempSelected,
          buGroup: modalStates.buGroup.tempSelected,
          companyCode: modalStates.company.tempSelected,
          country: modalStates.bankCountry.tempSelected,
          created_by: modalStates.createdeBy.tempSelected,
          created_date_from: createdFromDate || "",
          created_date_to: createdToDate || "",
          legalEntity: modalStates.legalEntity.tempSelected,
          status: status,
          updated_by: modalStates.updatedBy.tempSelected,
          updated_date_from: updatedFromDate || "",
          updated_date_to: updatedToDate || "",
          ...(newRequest ? { status: "0" } : {}),
          ...(accountNo ? { accountNo: "1" } : {}),
        };
        setFilters(filters)
        // const response = await BamServices.getFilterBankAccount(filters);
        // if (response.status === 200) {
        //   setTableData(response.data);

        // }
        setIsFilterPopupOpen(false);
      } catch (error) {
        console.error("Error fetching filtered companies:", error);
      }
    };
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50">
          <div className="flex justify-between items-center mt-2">
            <h2 className="text-customBlue text-lg font-semibold mb-5 ">
              Apply Filters
            </h2>
            <div className={"p-2"}>
              <HoverButton
                onClick={handleResetFilters}
                title={"Reset Filter"}
              />
            </div>
          </div>
          {/* Grid Layout */}
          <div className="grid grid-cols-2 gap-4 ">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray text-xs mr-2 w-[140px] text-sm">
                  Status:
                </label>
                <FilterDropdown
                  name="status"
                  value={status}
                  onChange={handleFilterChange}
                  options={statusOptions}
                  placeholder="Select Status"
                  className="bg-white text-xs"
                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray text-xs mr-2 w-[140px] text-sm">
                Account Status:
              </label>
              <select
                onChange={(e) => setSelectedStatus(e.target.value)}
                value={selectedStatus}
                className="text-customGray text-xs text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
              >

                <option value="">Select Status</option>
                {accountStatus.map((statusItem) => (
                  <option key={statusItem.id} value={statusItem.id}>
                    {statusItem.status}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray text-xs mr-2 w-[140px] text-sm">
                  Bank Acc ID:
                </label>
                <button
                  className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                  onClick={() => toggleModal("bankAccountID", true)}
                >
                  <span className="truncate ">
                    {modalStates.bankAccountID.selected.length > 0 ? modalStates.bankAccountID.selected.join(", ") : "Bank Account ID"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
                <CheckboxPopup
                  title="Select Bank Account ID"
                  isOpen={modalStates.bankAccountID.isOpen}
                  onClose={() => toggleModal("bankAccountID", false)}
                  onSave={() => handleApplySelection("bankAccountID")}
                  onReset={() => handleResetSelection("bankAccountID")}
                  searchPlaceholder="Search Bank Account ID..."
                  searchQuery={modalStates.bankAccountID.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, bankAccountID: { ...prev.bankAccountID, searchQuery: e.target.value }
                  }))}
                  items={[...new Map(dataLists.bankAccountID.map(item => [item.bankAccountId, item])).values()]
                    .map(code => ({ id: code.bankAccountId, name: code.bankAccountId || '' }))
                    .filter(item => item.name?.toLowerCase().includes(modalStates.bankAccountID.searchQuery?.toLowerCase() || ''))
                  }
                  selectedItems={modalStates.bankAccountID.tempSelected}
                  onItemToggle={(val) => handleSelection("bankAccountID", val)}

                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray text-xs mr-2 w-[140px] text-sm">
                Bank Branch:
              </label>
              <button
                className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => toggleModal("bankBranch", true)}
              >
                <span className="truncate   ">
                  {modalStates.bankBranch.selected.length > 0 ? modalStates.bankBranch.selected.join(", ") : "Bank Branch"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              <CheckboxPopup
                title="Select Bank Branch"
                isOpen={modalStates.bankBranch.isOpen}
                onClose={() => toggleModal("bankBranch", false)}
                onSave={() => handleApplySelection("bankBranch")}
                onReset={() => handleResetSelection("bankBranch")}
                searchPlaceholder="Search Bank Branch..."
                searchQuery={modalStates.bankBranch.searchQuery}
                onSearchChange={(e) => setModalStates((prev) => ({
                  ...prev, bankBranch: { ...prev.bankBranch, searchQuery: e.target.value }
                }))}

                items={[...new Map(dataLists.branch.map(item => [item.bankBranch, item])).values()]
                  .map(code => ({ id: code.bankBranch, name: code.bankBranch || '' })) // Ensure name is always a string
                  .filter(item => item.name?.toLowerCase().includes(modalStates.bankBranch.searchQuery?.toLowerCase() || '')) // Search filter
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                }

                selectedItems={modalStates.bankBranch.tempSelected}
                onItemToggle={(val) => handleSelection("bankBranch", val)}

              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray text-xs text-sm mr-2 w-[140px]">
                  Bank ID:
                </label>
                <button
                  className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                  onClick={() => toggleModal("bankID", true)}
                  disabled={!filterType}
                >
                  <span className="truncate  ">
                    {modalStates.bankID.selected.length > 0 ? modalStates.bankID.selected.join(", ") : "Bank ID"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
                <CheckboxPopup
                  title="Select Bank ID"
                  isOpen={modalStates.bankID.isOpen}
                  onClose={() => toggleModal("bankID", false)}
                  onSave={() => handleApplySelection("bankID")}
                  onReset={() => handleResetSelection("bankID")}
                  searchPlaceholder="Search Bank ID..."
                  searchQuery={modalStates.bankID.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, bankID: { ...prev.bankID, searchQuery: e.target.value }
                  }))}

                  items={[...new Map(dataLists.branch.map(item => [item.BankId, item])).values()]
                    .map(code => ({ id: code.BankId, name: code.BankId || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.bankID.searchQuery?.toLowerCase() || '')).sort((a, b) => a.name.localeCompare(b.name))
                  }

                  selectedItems={modalStates.bankID.tempSelected}
                  onItemToggle={(val) => handleSelection("bankID", val)}

                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray text-xs  text-sm mr-2 w-[140px]">
                Bank :
              </label>
              <button
                className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => toggleModal("bank", true)}
                disabled={!filterType}
              >
                <span className="truncate  ">
                  {modalStates.bank.selected.length > 0 ? modalStates.bank.selected.join(", ") : "Bank"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              <CheckboxPopup
                title="Select Bank"
                isOpen={modalStates.bank.isOpen}
                onClose={() => toggleModal("bank", false)}
                onSave={() => handleApplySelection("bank")}
                onReset={() => handleResetSelection("bank")}
                searchPlaceholder="Search Bank..."
                searchQuery={modalStates.bank.searchQuery}
                onSearchChange={(e) => setModalStates((prev) => ({
                  ...prev, bank: { ...prev.bank, searchQuery: e.target.value }
                }))}

                items={[...new Map(dataLists.branch.map(item => [item.Bank, item])).values()]
                  .map(code => ({ id: code.Bank, name: code.Bank || '' })) // Ensure name is always a string
                  .filter(item => item.name?.toLowerCase().includes(modalStates.bank.searchQuery?.toLowerCase() || ''))
                }
                // items={dataLists.branch.map((code) => ({ id: code.Bank, name: code.Bank }))}
                selectedItems={modalStates.bank.tempSelected}
                onItemToggle={(val) => handleSelection("bank", val)}

              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            {/* Left-Side Single Column Fields */}
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray text-xs text-sm mr-2 w-[140px]">
                  Company Code:
                </label>
                <button
                  className="text-customGray text-xs text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                  onClick={() => toggleModal("company", true)}
                >
                  <span className="truncate  ">
                    {modalStates.company.selected.length > 0 ? modalStates.company.selected.join(", ") : "Company"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
                <CheckboxPopup
                  title="Select Company Code"
                  isOpen={modalStates.company.isOpen}
                  onClose={() => toggleModal("company", false)}
                  onSave={() => handleApplySelection("company")}
                  onReset={() => handleResetSelection("company")}
                  searchPlaceholder="Search Company..."
                  searchQuery={modalStates.company.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, company: { ...prev.company, searchQuery: e.target.value }
                  }))}
                  items={[...new Map(dataLists.company.map(item => [item.companyCode, item])).values()]
                    .map(code => ({ id: code.companyCode, name: code.companyCode || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.company.searchQuery?.toLowerCase() || ''))
                  }
                  selectedItems={modalStates.company.tempSelected}
                  onItemToggle={(val) => handleSelection("company", val)}

                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray text-xs text-sm mr-2 w-[140px]">
                Bu Group:
              </label>
              <button
                className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => toggleModal("buGroup", true)}
              >
                <span className="truncate  ">
                  {modalStates.buGroup.selected.length > 0 ? modalStates.buGroup.selected.join(", ") : "Bu Group"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              <CheckboxPopup
                title="Select Bu Group"
                isOpen={modalStates.buGroup.isOpen}
                onClose={() => toggleModal("buGroup", false)}
                onSave={() => handleApplySelection("buGroup")}
                onReset={() => handleResetSelection("buGroup")}
                searchPlaceholder="Search Legal Entity..."
                searchQuery={modalStates.buGroup.searchQuery}
                onSearchChange={(e) => setModalStates((prev) => ({
                  ...prev, buGroup: { ...prev.buGroup, searchQuery: e.target.value }
                }))}
                items={[...new Map(dataLists.buGroup.map(item => [item.BU_Group, item])).values()]
                  .map(code => ({ id: code.BU_Group, name: code.BU_Group || '' }))
                  .filter(item => item.name?.toLowerCase().includes(modalStates.buGroup.searchQuery?.toLowerCase() || '')).sort((a, b) => a.name.localeCompare(b.name))
                }
                selectedItems={modalStates.buGroup.tempSelected}
                onItemToggle={(val) => handleSelection("buGroup", val)}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                <label className="text-customGray text-xs mr-2 w-[140px] text-sm">
                  Legal Entity:
                </label>
                <button
                  className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                  onClick={() => toggleModal("legalEntity", true)}

                >
                  <span className="truncate  ">
                    {modalStates.legalEntity.selected.length > 0
                      ? modalStates.legalEntity.selected
                        .map(selectedId => dataLists.legalEntity.find(item => item.id === selectedId)?.LGE_Code)
                        .filter(Boolean)
                        .join(", ")
                      : "Legal Entity"}
                  </span>

                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
                <CheckboxPopup
                  title="Select Legal Entity"
                  isOpen={modalStates.legalEntity.isOpen}
                  onClose={() => toggleModal("legalEntity", false)}
                  onSave={() => handleApplySelection("legalEntity")}
                  onReset={() => handleResetSelection("legalEntity")}
                  searchPlaceholder="Search Legal Entity..."
                  searchQuery={modalStates.legalEntity.searchQuery}
                  onSearchChange={(e) => setModalStates((prev) => ({
                    ...prev, legalEntity: { ...prev.legalEntity, searchQuery: e.target.value }
                  }))}
                  items={[...new Map(dataLists.legalEntity.map(item => [item.LGE_Code, item])).values()]
                    .map(code => ({ id: code.id, name: code.LGE_Code || '' })) // Ensure name is always a string
                    .filter(item => item.name?.toLowerCase().includes(modalStates.legalEntity.searchQuery?.toLowerCase() || ''))
                  }
                  selectedItems={modalStates.legalEntity.tempSelected}
                  onItemToggle={(val) => handleSelection("legalEntity", val)}

                />
              </div>
            </div>
            <div className="flex items-center mb-2">
              <label className="text-customGray text-xs text-sm mr-2 w-[140px]">
                Country:
              </label>
              <button
                className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => toggleModal("bankCountry", true)}
                disabled={!filterType}
              >
                <span className="truncate ">
                  {modalStates.bankCountry.selected.length > 0 ? modalStates.bankCountry.selected.join(", ") : "Country"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              <CheckboxPopup
                title="Select Bank Country"
                isOpen={modalStates.bankCountry.isOpen}
                onClose={() => toggleModal("bankCountry", false)}
                onSave={() => handleApplySelection("bankCountry")}
                onReset={() => handleResetSelection("bankCountry")}
                searchPlaceholder="Search Bank Country..."
                searchQuery={modalStates.bankCountry.searchQuery}
                onSearchChange={(e) => setModalStates((prev) => ({
                  ...prev, bankCountry: { ...prev.bankCountry, searchQuery: e.target.value }
                }))}
                items={[...new Set(
                  dataLists.branch.flatMap((branch) =>
                    branch.addresses?.map((addr) => addr.countryCode).filter(Boolean) || []
                  )
                )]
                  .map(countryCode => ({ id: countryCode, name: countryCode || '' }))
                  .filter(item =>
                    modalStates.bankCountry?.searchQuery
                      ? item.name.toLowerCase().includes(modalStates.bankCountry.searchQuery.toLowerCase())
                      : true
                  )}

                selectedItems={modalStates.bankCountry.tempSelected}
                onItemToggle={(val) => handleSelection("bankCountry", val)}

              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                {/* Select Dropdown */}
                <select
                  name="filterType"
                  value={filterType} // State to track the selected filter option
                  onChange={handleFilterTypeChange}
                  className="text-customGray text-xs text-sm border border-gray-300 p-2 rounded-md w-[120px] h-[40px] focus:outline-none hover:border-blue-400"
                >
                  <option value="1">Created By</option>
                  <option value="2">Updated By</option>
                </select>

                {/* Filter Button */}
                <button
                  className="text-customGray text-xs text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-6 "
                  onClick={() => {
                    if (filterType === "1") {
                      toggleModal("createdeBy", true)
                    } else if (filterType === "2") {
                      toggleModal("updatedBy", true)
                    }
                  }}
                  disabled={!filterType} // Disable button if no filter is selected
                >
                  <span className="truncate">
                    {filterType === "1"
                      ? modalStates.createdeBy.selected.length > 0 ? modalStates.createdeBy.selected.join(", ") : "Created By"
                      : filterType === "2"
                        ? modalStates.createdeBy.selected.length > 0 ? modalStates.createdeBy.selected.join(", ") : "Updated By"
                        : "Select Filter"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>
              </div>
            </div>
            <CheckboxPopup
              title="Select Created By"
              isOpen={modalStates.createdeBy.isOpen}
              onClose={() => toggleModal("createdeBy", false)}
              onSave={() => handleApplySelection("createdeBy")}
              onReset={() => handleResetSelection("createdeBy")}
              searchPlaceholder="Search Created By..."
              searchQuery={modalStates.createdeBy.searchQuery}
              onSearchChange={(e) => setModalStates((prev) => ({
                ...prev, createdeBy: { ...prev.createdeBy, searchQuery: e.target.value }
              }))}
              items={[...new Map(dataLists.createdeBy.map(item => [item.created_by, item])).values()]
                .map(code => ({ id: code.created_by, name: code.created_by || '' })) // Ensure name is always a string
                .filter(item => item.name?.toLowerCase().includes(modalStates.createdeBy.searchQuery?.toLowerCase() || '')) // Search filter
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
              }
              selectedItems={modalStates.createdeBy.tempSelected}
              onItemToggle={(val) => handleSelection("createdeBy", val)}
            />
            <CheckboxPopup
              title="Select Updated By"
              isOpen={modalStates.updatedBy.isOpen}
              onClose={() => toggleModal("updatedBy", false)}
              onSave={() => handleApplySelection("updatedBy")}
              onReset={() => handleResetSelection("updatedBy")}
              searchPlaceholder="Search Created By..."
              searchQuery={modalStates.updatedBy.searchQuery}
              onSearchChange={(e) => setModalStates((prev) => ({
                ...prev, updatedBy: { ...prev.updatedBy, searchQuery: e.target.value }
              }))}
              items={[...new Map(dataLists.updatedBy.map(item => [item.changed_by, item])).values()]
                .map(code => ({ id: code.changed_by, name: code.changed_by || '' })) // Ensure name is always a string
                .filter(item => item.name?.toLowerCase().includes(modalStates.updatedBy.searchQuery?.toLowerCase() || '')) // Search filter
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
              }
              selectedItems={modalStates.updatedBy.tempSelected}
              onItemToggle={(val) => handleSelection("updatedBy", val)}
            />
            <div className="flex items-center mb-2">
              <label className="text-customGray text-xs text-sm mr-2 w-[140px]">
                Approve For:
              </label>
              <button
                className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer "
                onClick={() => {
                  toggleModal("approveFor", true)
                }}
                disabled={!filterType}
              >
                <span className="truncate  ">
                  {modalStates.approveFor.selected.length > 0 ? modalStates.approveFor.selected.join(", ")
                    : "Select Approve For"}
                </span>
                <RxDropdownMenu className="ml-2 text-lg" />
              </button>
              <CheckboxPopup
                title="Select Approve For"
                isOpen={modalStates.approveFor.isOpen}
                onClose={() => toggleModal("approveFor", false)}
                onSave={() => handleApplySelection("approveFor")}
                onReset={() => handleResetSelection("approveFor")}
                searchPlaceholder="Search Legal Entity..."
                searchQuery={modalStates.approveFor.searchQuery}
                onSearchChange={(e) => setModalStates((prev) => ({
                  ...prev, approveFor: { ...prev.approveFor, searchQuery: e.target.value }
                }))}
                items={[...new Map(dataLists.approveFor.map(item => [item, { id: item, name: item }])).values()]
                  .filter(item => item.name.toLowerCase().includes(modalStates.approveFor.searchQuery?.toLowerCase() || ''))
                }

                selectedItems={modalStates.approveFor.tempSelected}
                onItemToggle={(val) => handleSelection("approveFor", val)}

              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="col-span-2 sm:col-span-1">
              <div className="flex items-center mb-2">
                {filterType && (
                  <div className="col-span-2 flex justify-between items-center">
                    <div className="flex items-center">
                      <label className="text-customGray text-xs text-sm mr-2 w-[140px] pl-3">
                        {filterType === "1" ? "Date From:" : "Date From:"}
                      </label>
                      <input
                        type="date"
                        name="dateFrom"
                        value={filterType === "1" ? createdFromDate : updatedFromDate}
                        onChange={handleFilterDateFrom}
                        className="text-customGray text-xs text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 ml-2"
                      />
                    </div>
                    <div className="flex items-center">
                      <label className="text-customGray text-xs text-sm mr-2 w-[140px] pl-12 whitespace-nowrap">
                        Date To:
                      </label>
                      <input
                        type="date"
                        name="dateTo"
                        value={filterType === "1" ? createdToDate : updatedToDate}
                        onChange={handleFilterDateTo}
                        className="text-customGray text-xs text-sm border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 ml-[28%]"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center">
              <label className="text-customGray text-xs text-sm mr-2 ml-4">
                New Request
              </label>
              <input
                type="checkbox"
                className="accent-customBlue"
                checked={newRequest}
                onChange={(e) => handleApplyNewRequest(e.target.checked)}
              />
            </div>
            <div className="flex items-center">
              <label className="text-customGray text-xs text-sm mr-2">Account No</label>
              <input
                type="checkbox"
                className="accent-customBlue"
                checked={accountNo}
                onChange={(e) => handleApplyAccountNo(e.target.checked)}
              />
            </div>
            <div className="flex items-center">
              <HoverButton
                title="Cancel"
                className="mr-2"
                onClick={() => setIsFilterPopupOpen(false)}
              />
              <CustomButton onClick={handleApplyFilter} title="Apply" />
            </div>
          </div>
        </div>
      </div >
    );
  };

  return (
    <div>
      <div>
        <Header
          title={"BAM > Bank Master Data > Account Master"}
          onBreadcrumbClick={handleBreadcrumbClick}
          backRoute={"/bam"}
        />
      </div>
      <div className="flex justify-between items-center -ml-1 pl-4">
        <div className="flex items-center">
          <SearchInput
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onChange={(e) => fetchBankAccount(searchQuery)}
            placeholder="Search by Account Id"
          />
          <BamActions
            icons={iconId}
            buttons={actionButtons}
            selectedCount={selectedRows.length}
            // extraButtons={extraButtons}
            isExtraButtonsVisible={showExtraIcons}
            selectIds={selectedRows}
            hasFiltersApplied={hasFiltersApplied}
          />
        </div>
        <div className="flex items-center mr-4">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
            onShowAll={handleShowAll}
          />
        </div>
      </div>
      <ReTable
        data={tableData}
        columns={columns}
        onSelectRow={onSelectRow}
        selectedRows={selectedRows}
        onSelectAll={onSelectAll}
        onRowDoubleClick={handleDoubleClick}
      />

      <div>
        {isModalOpen && (
          <AssignNewStatusModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            accountId={selectedId}
          />
        )}
      </div>
      <div>
        <AssignNewCompanyCode
          isOpen={isCompanyModalOpen}
          onClose={handleCompanyModalClose}
          accountId={selectedId}
          data={tableData}
        />
      </div>
      <ConfirmationDialog
        open={showConfirmation}
        title="Confirm Deletion"
        message="Are you sure you want to delete the selected Bank Account?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      />


      {isFilterPopupOpen &&

        <FilterPopUp
          setFilters={setFilters}
        />
      }
    </div>
  );
}
