import React, { useState, useEffect } from 'react';
import Header from '../../../../components/Header';
import { useNavigate } from 'react-router-dom';
import { Checkbox, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import ModuleService from '../../../../rest-services/ModuleServices';

export default function ModuleAssignment() {
    const navigate = useNavigate();
    const [selectedValue, setSelectedValue] = useState('');
    const [groupedData, setGroupedData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [userData, setUserData] = useState([]);
    const [checkedStates, setCheckedStates] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch purchase details and initialize groupedData
                const purchaseResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/purchase-details`);
                const grouped = {};
                purchaseResponse.data.forEach(item => {
                    const mainMenuId = item.mainMenuId;
                    const subMenuDetails = {
                        id: item.subMenu.id,
                        name: item.subMenu.name,
                        purchased: item.purchased,
                        assigned: item.assigned,
                        available: item.available,
                    };
                    if (!grouped[mainMenuId]) {
                        grouped[mainMenuId] = {
                            mainMenuName: item.mainMenu.name,
                            subMenus: [],
                        };
                    }
                    grouped[mainMenuId].subMenus.push(subMenuDetails);
                });
                setGroupedData(grouped);
    
                // Fetch approved user data
                const response = await ModuleService.approvedUser();
                console.log(response);
                setUserData(response.data.data);
    
                // Fetch module assignment data
                const assignmentResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`);
                const initialCheckedStates = {};
                assignmentResponse.data.forEach(assignment => {
                    initialCheckedStates[`${assignment.userId}-${assignment.subMenuId}`] = {
                        isChecked: assignment.isChecked,
                        id: assignment.id, // Store the existing assignment ID for update purposes
                    };
                });
                setCheckedStates(initialCheckedStates);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    const handleMenuChange = (event) => {
        const selectedMenuId = event.target.value;
        setSelectedValue(selectedMenuId);

        if (selectedMenuId && groupedData[selectedMenuId]) {
            setFilteredData({
                [selectedMenuId]: groupedData[selectedMenuId],
            });
        } else {
            setFilteredData(groupedData);
        }
    };

    const handleCheckboxChange = (userId, subMenuId, isChecked) => {
        const key = `${userId}-${subMenuId}`;
        const assignment = checkedStates[key];

        if (assignment && assignment.id) {
            // Update existing assignment
            axios.put(`${process.env.REACT_APP_BASE_URL}/update-module-assignment/${assignment.id}`, {
                isChecked: isChecked
            })
            .then(response => {
                setCheckedStates(prevStates => ({
                    ...prevStates,
                    [key]: { ...prevStates[key], isChecked }
                }));
                console.log('Assignment updated successfully:', response.data);
            })
            .catch(error => console.error('Error updating assignment:', error));
        } else {
            // Create new assignment
            axios.post(`${process.env.REACT_APP_BASE_URL}/post-module-assignment`, {
                userId: userId,
                subMenuId: subMenuId,
                isChecked: isChecked
            })
            .then(response => {
                setCheckedStates(prevStates => ({
                    ...prevStates,
                    [key]: { isChecked, id: response.data.id } // Set the new ID from response
                }));
                console.log('Assignment created successfully:', response.data);
            })
            .catch(error => console.error('Error saving new assignment:', error));
        }
    };

    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === 'Admin') {
            navigate('/admin');
        } else if (crumb.title === 'Licenses') {
            navigate('/admin');
        } else if (crumb.title === 'Module Assignments') {
            navigate('/module-assignment');
        } else if (crumb.path) {
            navigate(crumb.path);
        }
    };

    return (
        <div>
            <Header 
                title={"Admin > Licenses > Module Assignments"}
                onBreadcrumbClick={handleBreadcrumbClick}
            />
            
            <div className="p-4">
                <Select
                    labelId="dropdown-label"
                    value={selectedValue}
                    onChange={handleMenuChange}
                    displayEmpty
                    className="min-w-[300px] bg-white border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-300 focus:border-blue-500 transition duration-200 ease-in-out"
                >
                    <MenuItem value="" className="font-normal">Select</MenuItem>
                    {Object.entries(groupedData).map(([menuId, menu]) => (
                        <MenuItem key={menuId} value={menuId}>
                            {menu.mainMenuName}
                        </MenuItem>
                    ))}
                </Select>
            </div>

            <div className="p-2 overflow-x-auto">
                <table className="min-w-[300px] border-collapse border border-customBlue">
                    <thead>
                        <tr className="bg-customBlue text-white text-center">
                            <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '150px' }}>User ID</th>
                            <th rowSpan="2" className="p-2 border-t border-white font-normal" style={{ width: '200px' }}>Username</th>                            
                        </tr>
                        <tr className="bg-customBlue text-white text-center">
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map((subMenu, subIndex) => (
                                    <th key={subIndex} className="p-2 border border-gray-300 font-normal" style={{ width: '200px' }}>
                                        {subMenu.name}
                                    </th>
                                ))
                            )}
                        </tr>
                    </thead>

                    <tbody>

                        {/* Display Purchased, Assigned, Available rows */}
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="2" className="p-2 border border-gray-300">Purchased</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.purchased}</td>
                                ))
                            )}
                        </tr>
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="2" className="p-2 border border-gray-300">Assigned</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300">{subMenu.assigned}</td>
                                ))
                            )}
                        </tr>
                        <tr className="bg-yellow-50 text-center">
                            <td colSpan="2" className="p-2 border border-gray-300">Available</td>
                            {Object.values(filteredData).flatMap(mainMenu => 
                                mainMenu.subMenus.map(subMenu => (
                                    <td key={subMenu.name} className="p-2 border border-gray-300" style={{ width: '200px' }}>{subMenu.available}</td>
                                ))
                            )}
                        </tr>
                        
                        {userData.map(user => (
                            <tr key={user.id} className="bg-blue-50 text-center">
                                <td className="p-2 border border-gray-300">{user.userId}</td>
                                <td className="p-2 border border-gray-300">{user.username}</td>
                                {Object.values(filteredData).flatMap(mainMenu => 
                                    mainMenu.subMenus.map(subMenu => (
                                        <td key={`${user.id}-${subMenu.id}`} className="p-2 border border-gray-300">
                                            <Checkbox
                                                checked={checkedStates[`${user.id}-${subMenu.id}`]?.isChecked || false}
                                                onChange={(event) => handleCheckboxChange(user.id, subMenu.id, event.target.checked)}
                                            />
                                        </td>
                                    ))
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
