import React from "react";

const CustomInput = React.forwardRef(({ value, onClick, error, placeholder }, ref) => (
  <input
    className={`border p-1.5 rounded w-72 h-8 
      ${error ? "border-red-500 hover:border-red-500" : "border-gray-300 hover:border-blue-400"} 
      text-customGray focus:outline-none ml-4 placeholder-gray-500`}  // Ensure placeholder shows
    value={value}
    onClick={onClick} // Opens calendar picker
    readOnly // Prevents manual typing
    placeholder={placeholder}
    ref={ref}
  />
));

export default CustomInput;
