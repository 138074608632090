import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbFlag3Filled } from "react-icons/tb";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";
import CurrencyRateServicesServices from "../../../../../rest-services/CurrencyRateServies";
import { use } from "react";
import MdSourceServices from "../../../../../rest-services/MdSourceServices";
import LabelWithButton from "../../../../../components/InputButtonField";
import ReusableModal from "../../../../../components/PopupComponent";
import { set } from "lodash";
import SourceServices from "../../../../../rest-services/SourceServices";
import CustomDropdown from "../../../../../components/CustomDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TableWrapper from "../../../../../components/ScrollBarComponent";

export default function CurrencyRateAdd() {
  const { currencyRateId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === "view";
  const [source, setSource] = useState("");
  const [rateDate, setRateDate] = useState("");
  const [ourCode, setOurCode] = useState("");
  const [rateType, setRateType] = useState("");
  const [quote, setQuote] = useState("");
  const [ratio, setRatio] = useState("");
  const [tenor, setTenor] = useState("");
  const [sourceCode, setSourceCode] = useState("");
  const [bid, setBid] = useState("");
  const [ask, setAsk] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const [status, setStatus] = useState("");
  const [selectedSourceType, setSelectedSourceType] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const isEditMode = !!currencyRateId;
  const [mdSource, setMdSource] = useState([]);
  const [Sources, setSources] = useState([]);
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [isSourceCodeModalOpen, setIsSourceCodeModalOpen] = useState(false);
  const [isOurCodeModalOpen, setIsOurCodeModalOpen] = useState(false);
  const [isSourceCompanyModalOpen, setIsSourceCompanyModalOpen] = useState(false);
  const [isSourceCodeDescriptionModalOpen, setIsSourceCodeDescriptionModalOpen] = useState(false);
  const [isOurCodeDescriptionModalOpen, setIsOurCodeDescriptionModalOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(false);
  const [selectedOurCode, setSelectedOurCode] = useState(false);
  const [selectedSourceCompanyName, setSelectedSourceCompanyName] = useState(false);
  const [selectedSourceCodeDescription, setSelectedSourceCodeDescription] = useState(false);
  const [selectedOurCodeDescription, setSelectedOurCodeDescription] = useState(false);
  const [ourCodeDescription, setourCodeDescription] = useState("");
  const [sourceCompanyName, setsourceCompanyName] = useState("");
  const [sourceCodeDescription, setsourceCodeDescription] = useState("");

  const [openDropdown, setOpenDropdown] = useState(null);
  const navigate = useNavigate();
  const [mdType, setMdType] = useState("");
  const [dataType, setDataType] = useState("");

  useEffect(() => {
    fetchSourceCode();
    fetchMdSource();
  }, []);

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  const normalizeDate = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (location.state?.copiedData) {
      setIsCopyMode(true);
      const copiedData = location.state.copiedData;
      setSource(copiedData.source);
      if (copiedData.rateDate) {
        const formattedRateDate = formatDateForInput(copiedData.rateDate); // Format the date
        setRateDate(formattedRateDate);
      } else {
        setRateDate(""); // Fallback if rateDate is not available
      }
      setSourceCode(copiedData.sourceCode);
      setQuote(copiedData.quote || "");
      setTenor(copiedData.tenor || "");
      setBid(copiedData.bid || "");
      setAsk(copiedData.ask || "");
      const selectedSourceData = mdSource.find(
        (md) => md.source === copiedData.source
      );
      if (selectedSourceData) {
        setSelectedSourceType(selectedSourceData.source);
        setSelectedSourceCompanyName(selectedSourceData.sourceCompanyName);
      }
      if (copiedData.sourceCode) {
        const selectedSources = Sources.find(
          (md) => md.sourceCode === copiedData.sourceCode
        );
        if (selectedSources) {
          setSourceCode(selectedSources.sourceCode);
          setSelectedSource(selectedSources.sourceCode);
          setSelectedOurCode(selectedSources.ourCode);
          setOurCode(selectedSources.ourCode);
          setRatio(selectedSources.ratio || "");
          setRateType(selectedSources.rateType || "");
          setDataType(selectedSources.dataType || "");
          setMdType(selectedSources.mdType || "");
          setsourceCodeDescription(selectedSources.sourceCodeDescription || "");
          setSelectedSourceCodeDescription(selectedSources.sourceCodeDescription || "");
          setourCodeDescription(selectedSources.ourCodeDescription || "");
          setSelectedOurCodeDescription(selectedSources.ourCodeDescription || "");
        }
      }
    } else if (currencyRateId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [currencyRateId, location.state, Sources, mdSource]);

  const getDataForEdit = async () => {
    try {
      const response = await CurrencyRateServicesServices.getCurrencyRateById(
        currencyRateId
      );
      setRateDate(formatDateForInput(response.data.rateDate) || "");
      setTenor(response.data.tenor || "");
      setQuote(response.data.quote || "");
      setBid(response.data.bid || "");
      setAsk(response.data.ask || "");
      if (response.data.source) {
        const selectedmd = mdSource.find(
          (md) => md.source === response.data.source
        );
        if (selectedmd) {
          setSource(selectedmd.source);
          setSelectedSourceType(selectedmd.source);
        }
      }
      if (response.data.sourceCode) {
        const selectedSources = Sources.find(
          (md) => md.sourceCode === response.data.sourceCode
        );
        if (selectedSources) {
          setSourceCode(selectedSources.sourceCode);
          setSelectedSource(selectedSources.sourceCode);
          setSelectedOurCode(selectedSources.ourCode);
          setOurCode(selectedSources.ourCode);
          setRatio(selectedSources.ratio || "");
          setRateType(selectedSources.rateType || "");
          setDataType(selectedSources.dataType || "");
          setMdType(selectedSources.mdType || "");
          setsourceCodeDescription(selectedSources.sourceCodeDescription || "");
          setSelectedSourceCodeDescription(selectedSources.sourceCodeDescription || "");
          setourCodeDescription(selectedSources.ourCodeDescription || "");
          setSelectedOurCodeDescription(selectedSources.ourCodeDescription || "");
        }
      }
    } catch (error) {
      console.error("Error fetching data for edit:", error);
    }
  };

  const fetchSourceCode = async () => {
    try {
      const response = await SourceServices.getAllSource(
        `${process.env.REACT_APP_BASE_URL}/get-source`
      );
      setSources(response.data.data);
    } catch (error) {
      console.error("Error fetching MdSource:", error);
    }
  };

  const fetchMdSource = async () => {
    try {
      const response = await MdSourceServices.getAllMdSource(
        `${process.env.REACT_APP_BASE_URL}/get-mdsource`
      );
      // Ensure response.data.data is an array
      setMdSource(Array.isArray(response.data.data) ? response.data.data : []);
    } catch (error) {
      console.error("Error fetching MdSource:", error);
      setMdSource([]); // Set to empty array in case of error
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!source) newErrors.source = "Source is required.";
    if (!rateDate) newErrors.rateDate = "Rate Date is required.";
    if (!ourCode) newErrors.ourCode = "Our Code is required.";
    if (!sourceCode) newErrors.sourceCode = "Source Code is required.";
    if (!quote) newErrors.quote = "Quote is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    // Normalize rateDate before sending to backend
    const normalizedRateDate = normalizeDate(rateDate);

    try {
      const duplicateCheckResponse =
        await CurrencyRateServicesServices.checkDuplicateCurrencyRatecode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatecurrencyrate`,
          { source, sourceCode, ourCode, rateDate: normalizedRateDate, quote }
        );

      if (duplicateCheckResponse.isDuplicate) {
        toast(
          "error",
          "A record with the same source, sourceCode, ourCode, rateDate, and quote already exists."
        );
        setErrors({
          source: "Duplicate source found",
          sourceCode: "Duplicate source code found",
          ourCode: "Duplicate our code found",
          rateDate: "Duplicate rate date found",
          quote: "Duplicate quote found",
        });
        return; // Stop further execution if duplicate exists
      }

      // Prepare the data for submission
      const data = {
        id: isCopyMode ? null : currencyRateId,
        source,
        rateDate: normalizedRateDate,
        ourCode,
        rateType,
        quote,
        ratio,
        tenor,
        sourceCode,
        bid,
        ask,
        mdType,
        dataType,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };

      console.log("Currency Rate data", data);

      let response;
      if (isCopyMode || !currencyRateId) {
        // Create a new currency rate
        response = await CurrencyRateServicesServices.addCurrencyRate(
          `${process.env.REACT_APP_BASE_URL}/create-currencyrate`,
          data
        );
      } else {
        // Update an existing currency rate
        response = await CurrencyRateServicesServices.addCurrencyRate(
          `${process.env.REACT_APP_BASE_URL}/create-currencyrate`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          currencyRateId && !isCopyMode
            ? "Currency Rate Updated successfully"
            : "Currency Rate Added successfully"
        );

        // Reset form fields
        setSource("");
        setRateDate("");
        setOurCode("");
        setRateType("");
        setQuote("");
        setRatio("");
        setTenor("");
        setSourceCode("");
        setBid("");
        setAsk("");
        setErrors({});

        // Redirect after saving
        navigate("/2/master-data/8/currency-rate");
      } else {
        toast("error", "Error adding/updating Currency rate.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Currency Rate: ${error.message}`,
      });
    }
  };

  const handleSaveandContinue = async (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    // Normalize rateDate before sending to backend
    const normalizedRateDate = normalizeDate(rateDate);

    try {
      const duplicateCheckResponse =
        await CurrencyRateServicesServices.checkDuplicateCurrencyRatecode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatecurrencyrate`,
          { source, sourceCode, ourCode, rateDate: normalizedRateDate, quote }
        );

      if (duplicateCheckResponse.isDuplicate) {
        toast(
          "error",
          "A record with the same source, sourceCode, ourCode, rateDate, and quote already exists."
        );
        setErrors({
          source: "Duplicate source found",
          sourceCode: "Duplicate source code found",
          ourCode: "Duplicate our code found",
          rateDate: "Duplicate rate date found",
          quote: "Duplicate quote found",
        });
        return; // Stop further execution if duplicate exists
      }

      // Prepare the data for submission
      const data = {
        id: isCopyMode ? null : currencyRateId,
        source,
        rateDate: normalizedRateDate,
        ourCode,
        rateType,
        quote,
        ratio,
        tenor,
        sourceCode,
        bid,
        ask,
        mdType,
        dataType,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };

      console.log("Currency Rate data", data);

      let response;
      if (isCopyMode || !currencyRateId) {
        // Create a new currency rate
        response = await CurrencyRateServicesServices.addCurrencyRate(
          `${process.env.REACT_APP_BASE_URL}/create-currencyrate`,
          data
        );
      } else {
        // Update an existing currency rate
        response = await CurrencyRateServicesServices.addCurrencyRate(
          `${process.env.REACT_APP_BASE_URL}/create-currencyrate`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          currencyRateId && !isCopyMode
            ? "Currency Rate Updated successfully"
            : "Currency Rate Added successfully"
        );

        // Reset form fields
        setSource("");
        setRateDate("");
        setOurCode("");
        setRateType("");
        setQuote("");
        setRatio("");
        setTenor("");
        setSourceCode("");
        setBid("");
        setAsk("");
        setErrors({});
      } else {
        toast("error", "Error adding/updating Currency rate.");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Currency Rate: ${error.message}`,
      });
    }
  };
    const handleSourceCodeCheckBoxChange = (event) => {

      const selectedValue = event.target.value; // Value from the checkbox (currency code or name)
    
      // Find the selected currency object
      const selectedSource = Sources.find(
        (Source) => Source.sourceCode === selectedValue || Source.sourceCodeDescription === selectedValue
      );
    
      if (selectedSource) {
        if (selectedValue === selectedSource.sourceCode) {
          // Handle selection for currency code
          setSelectedSource(selectedSource.sourceCode);
          setSourceCode(selectedSource.sourceCode);
          setSelectedOurCode(selectedSource.ourCode);
          setOurCode(selectedSource.ourCode);
          setRatio(selectedSource.ratio);
          setSelectedOurCodeDescription(selectedSource.ourCodeDescription);
          setSelectedSourceCodeDescription(selectedSource.sourceCodeDescription);
          setDataType(selectedSource.dataType);
          setRateType(selectedSource.rateType);
          setMdType(selectedSource.mdType);
        } else if (selectedValue === selectedSource.sourceCodeDescription) {
          // Handle selection for currency name
          setSelectedSource(selectedSource.sourceCode);
          setSourceCode(selectedSource.sourceCode);
          setSelectedOurCode(selectedSource.ourCode);
          setOurCode(selectedSource.ourCode);
          setRatio(selectedSource.ratio);
          setRateType(selectedSource.rateType);
          setSelectedOurCodeDescription(selectedSource.ourCodeDescription);
          setSelectedSourceCodeDescription(selectedSource.sourceCodeDescription);
          setDataType(selectedSource.dataType);
          setMdType(selectedSource.mdType);
        }
        setUnsavedChanges(true); // Mark unsaved changes
      } else {
        console.error("Selected currency not found in the list.");
      }
    };
    const handleOurCodeCheckBoxChange = (event) => {

      const selectedValue = event.target.value; // Value from the checkbox (currency code or name)
    
      // Find the selected currency object
      const selectedSource = Sources.find(
        (Source) => Source.ourCode === selectedValue || Source.ourCodeDescription === selectedValue
      );
    
      if (selectedSource) {
        if (selectedValue === selectedSource.ourCode) {
          // Handle selection for currency code
          setSelectedSource(selectedSource.sourceCode);
          setSourceCode(selectedSource.sourceCode);
          setSelectedOurCode(selectedSource.ourCode);
          setOurCode(selectedSource.ourCode);
          setRatio(selectedSource.ratio);
          setRateType(selectedSource.rateType);
          setSelectedOurCodeDescription(selectedSource.ourCodeDescription);
          setSelectedSourceCodeDescription(selectedSource.sourceCodeDescription);
          setDataType(selectedSource.dataType);
          setMdType(selectedSource.mdType);
        } else if (selectedValue === selectedSource.ourCodeDescription) {
          // Handle selection for currency name
          setSelectedSource(selectedSource.sourceCode);
          setSourceCode(selectedSource.sourceCode);
          setSelectedOurCode(selectedSource.ourCode);
          setOurCode(selectedSource.ourCode);
          setRatio(selectedSource.ratio);
          setRateType(selectedSource.rateType);
          setSelectedOurCodeDescription(selectedSource.ourCodeDescription);
          setSelectedSourceCodeDescription(selectedSource.sourceCodeDescription);
          setDataType(selectedSource.dataType);
          setMdType(selectedSource.mdType);
        }
        setUnsavedChanges(true); // Mark unsaved changes
      } else {
        console.error("Selected currency not found in the list.");
      }
    };
    const handleSourceCheckBoxChange = (event) => {

        const selectedValue = event.target.value; // Value from the checkbox (currency code or name)
      
        // Find the selected currency object
        const selectedSource = mdSource.find(
          (md) => md.source === selectedValue || md.sourceCompanyName === selectedValue
        );
      
        if (selectedSource) {
          if (selectedValue === selectedSource.source) {
            // Handle selection for currency code
            setSelectedSourceType(selectedSource.source);
            setSource(selectedSource.source);
            setsourceCompanyName(selectedSource.sourceCompanyName);
            setSelectedSourceCompanyName(selectedSource.sourceCompanyName);
           
        
          } else if (selectedValue === selectedSource.sourceCompanyName) {
            // Handle selection for currency name
            setSelectedSourceType(selectedSource.source);
            setSource(selectedSource.source);
            setsourceCompanyName(selectedSource.sourceCompanyName);
            setSelectedSourceCompanyName(selectedSource.sourceCompanyName);
          }
          setUnsavedChanges(true); // Mark unsaved changes
        } else {
          console.error("Selected value not found in the list.");
        }
      };
    // const handleDuplicationchange = (e) => {
    //     const code = e.target.value;
    //     setBuGroup(code);
    //     setUnsavedChanges(true);
    //     // Clear previous errors when typing
    //     setErrors(prev => ({ ...prev, buGroup: "" }));

    //     // Check for duplicate LGE Code locally
    //     if (code.trim() !== "") {
    //         checkDuplicateBugroupcode(code);
    //     }
    // };
    // const checkDuplicaterate = async (source, sourceCode, ourCode, rateDate, quote) => {
    //   try {
    //     const response = await SourceServices.checkDuplicateSourcecode(
    //       `/checkduplicatesource`,
    //       { source, sourceCode, ourCode, rateDate, quote }
    //     );
    
    //     if (response.isDuplicate) {
    //       toast("error", "A record with the same source, sourceCode, ourCode, and quote already exists.");
    //       setErrors((prev) => ({
    //         ...prev,
    //         sourceCode: "Duplicate source code found",
    //         ourCode: "Duplicate our code found",
    //         rateDate: "Duplicate rate date found",
    //         quote: "Duplicate quote found",
    //       }));
    //     } else {
    //       // Clear errors only when no duplicate is found
    //       setErrors((prev) => ({
    //         ...prev,
    //         sourceCode: "",
    //         ourCode: "",
    //         rateDate: "",
    //         quote: "",
    //       }));
    //     }
    //   } catch (error) {
    //     console.error("Error checking duplicate source:", error);
    //     setErrors((prev) => ({
    //       ...prev,
    //       sourceCode: "Error checking duplicate",
    //       ourCode: "Error checking duplicate",
    //       rateDate: "Error checking duplicate",
    //       quote: "Error checking duplicate",
    //     }));
    //   }
    // };
    
    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === 'Market Data') {
            navigate('/2/master-data');
        } else if (crumb.title === 'Currency Rate') {
            navigate('/currency-rate');
        }
        else if (crumb.title === 'Primary Data') {
            navigate('/2/master-data');

        } else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };
    const handleRefreshSource = async () => {
        try {
          await fetchMdSource();  // Fetch Branchs
        } catch (error) {
          console.error('Error reloading Branchs:', error);
        } finally {
        }
      };
      const handleSourceReset = () => {
        setSearchQuery(''); // Reset search query
        setSelectedSourceType("");
        setSource("");
        setSelectedSourceCompanyName("");
        setsourceCompanyName("");
      };
     
      const handleRefreshOurCode = async () => {
        try {
          await fetchSourceCode();  // Fetch Branchs
        } catch (error) {
          console.error('Error reloading Branchs:', error);
        } finally {
        }
      };
      const handleOurCodeReset = () => {
        setSearchQuery(''); // Reset search query
        setSelectedSource("");
        setSelectedOurCode("");
        setOurCode("");
        setSourceCode("");
        setsourceCodeDescription("");
        setDataType("");
        setMdType("");
        ourCodeDescription("");
        setRateType("");
        setRatio("");
      };
      const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
      };
      const handleToggle = (dropdownId) => {
        setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId)); // Toggle dropdown
      };
    const getApprovedIcon = () => {
        let color = "black"; // Default color
        // Determine the color based on the approved status
        if (status === 4) {
            color = "#29CB00"; // Green
        } else if (status === 0) {
            color = "gray"; // Gray
        } else if (status === 1) {
            color = "#AF52DE";
        } else if (status === 2) {
            color = "yellow";
        } else if (status === 3) {
            color = "orange";
        }
        else if (status === 5) {
            color = "red"; // Red
        }

        return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
    };
    const quoteOptions = [
      { value: "", label: "Select quote" },
      { value: "D - Direct Quote", label: "D - Direct Quote" },
      { value: "I - Indirect Quote", label: "I - Indirect Quote" },
    ];
    
    const handleQuoteChange = (value) => {
      setQuote(value);
      setUnsavedChanges(true);
    
      // if (value.trim() !== "" && rateDate.trim() !== "") {
      //   checkDuplicaterate(source, sourceCode, ourCode, rateDate, value);
      // } else {
      //   // Clear only the quote error if the value is empty
      //   setErrors((prev) => ({ ...prev, quote: "" }));
      // }
    };
    
    const handleratedateChange = (e) => {
      const code = e.target.value;
      setRateDate(code);
      setUnsavedChanges(true);
    
      // if (code.trim() !== "" && quote.trim() !== "") {
      //   checkDuplicaterate(source, sourceCode, ourCode, code, quote);
      // } else {
      //   // Clear only the rate date error if the value is empty
      //   setErrors((prev) => ({ ...prev, rateDate: "" }));
      // }
    };
    
    return (
        <div>
            <Header
                title={`Primary Data > Market Data > Currency Rate > ${isCopyMode
                    ? 'Add'
                    : currencyRateId
                        ? (isViewMode ? "View" : "Edit")
                        : "Add"
                    }${currencyRateId && (isViewMode || !isCopyMode) ? ` > ${source}` : ''}`}
                onBreadcrumbClick={handleBreadcrumbClick}
                hasUnsavedChanges={unsavedChanges}
                backRoute={"/2/master-data/14/currency-rate"}
            />
<div>
            <TableWrapper maxHeight="80vh">

            <div className="m-6 border border-gray-200 p-5  rounded-lg">  
              <div className="flex flex-col space-y-4">                
                <h3 className="text-sm font-medium text-customGray mb-4">
                Rate Source
            </h3>
                
                <div className="grid grid-cols-2 gap-4 ">
                    <LabelWithButton
                    marginbottom ="0px"
                    label="Source Type"
                    isRequired={true}
                    isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
                    onClick={() => { setIsSourceModalOpen(true); }
                    }
                    buttonText={selectedSourceType || 'Select Source Type'}
                    error={errors.source}
                  />

<ReusableModal
  title="Select Source Type"
  isOpen={isSourceModalOpen}
  onClose={() => {
    setIsSourceModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsSourceModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search Source Type..."
  searchQuery={searchQuery}
  showReset={true}
  onReset={handleSourceReset}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
  onRefresh={handleRefreshSource}
>
  {Array.isArray(mdSource) && mdSource.length > 0 ? (
    mdSource
      .filter(
        (md) =>
          md.source &&
          md.source.trim() &&
          md.source.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => a.source.localeCompare(b.source)) // Sort in alphabetical order
      .map((md, index) => (
        <div
          key={md.id}
          className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
        >
          <input
            type="radio"
            name="Source Type"
            value={md.source}
            checked={selectedSourceType === md.source}
            onChange={handleSourceCheckBoxChange}
            className="mr-2 accent-customBlue"
            disabled={isViewMode}
          />
          <label>{md.source}</label>
        </div>
      ))
  ) : (
    <p>Loading source type...</p>
  )}
</ReusableModal>
                  <LabelWithButton
                    marginbottom ="0px"
                    label="Source Company Name"
                    isRequired={true}
                    isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
                    onClick={() => { setIsSourceCompanyModalOpen(true); }
                    }
                    buttonText={selectedSourceCompanyName || 'Select Source Company Name'}
                    error={errors.source}
                  />

                  <ReusableModal
                    title="Select Source Company Name"
                    isOpen={isSourceCompanyModalOpen}
                    onClose={() => {
                      setIsSourceCompanyModalOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsSourceCompanyModalOpen(false);
                      setSearchQuery('');
                    }}
                    searchPlaceholder="Search Source Company Name..."
                    searchQuery={searchQuery}
                    showReset={true}
                    onReset={handleSourceReset}
                    onSearchChange={handleSearchChange}
                    isViewMode={isViewMode}
                    onRefresh={handleRefreshSource}
                  >
  {Array.isArray(mdSource) && mdSource.length > 0 ? (
    mdSource
                      .filter(
                          (md) =>
                            (md.sourceCompanyName &&
                              md.sourceCompanyName.trim() &&
                              md.sourceCompanyName
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()))

                        )
                        .sort((a, b) => a.sourceCompanyName.localeCompare(b.sourceCompanyName)) // Sort in alphabetical order

                        .map((md, index) => (
                          <div
                            key={md.id}
                            className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
                              }`}
                          >
                            <input
                              type="radio"
                              name="Source Company Name"
                              value={md.sourceCompanyName}
                              checked={
                                selectedSourceCompanyName === md.sourceCompanyName
                              }
                              onChange={handleSourceCheckBoxChange}
                              className="mr-2 accent-customBlue"
                              disabled={isViewMode}
                            />
                            <label>{md.sourceCompanyName}</label>
                          </div>
                        ))

                    ) : (
                      <p>Loading source compnay name...</p>
                    )}
                  </ReusableModal>
   
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                    <LabelWithButton
                    marginbottom ="0px"
                    label="Our Code"
                    isRequired={true}
                    isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
                    onClick={() => { setIsOurCodeModalOpen(true); }
                    }
                    buttonText={selectedOurCode || 'Select Our Code'}
                    error={errors.ourCode}
                  />

<ReusableModal
  title="Select Source company"
  isOpen={isOurCodeModalOpen}
  onClose={() => {
    setIsOurCodeModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsOurCodeModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search OurCode..."
  searchQuery={searchQuery}
  showReset={true}
  onReset={handleOurCodeReset}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
  onRefresh={handleOurCodeReset}
>
 {Sources && Sources.length > 0 ? (
  [...new Map(Sources.map((md) => [md.ourCode, md])).values()] // Remove duplicates by 'ourCode'
    .filter(
      (md) =>
        md.ourCode &&
        md.ourCode.trim() &&
        md.source === selectedSourceType && // Match source with selectedSourceType
        md.ourCode.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.ourCode.localeCompare(b.ourCode)) // Sort in alphabetical order
    .map((md, index) => (
      <div
        key={md.id}
        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
      >
        <input
          type="radio"
          name="ourCode Type"
          value={md.ourCode}
          checked={selectedOurCode === md.ourCode}
          onChange={handleOurCodeCheckBoxChange}
          className="mr-2 accent-customBlue"
          disabled={isViewMode}
        />
        <label>{md.ourCode}</label>
      </div>
    ))
) : (
  <p>Loading ourCode...</p>
)}

</ReusableModal>
                     <LabelWithButton
                    marginbottom ="0px"
                    label="Our Code Description"
                    isRequired={true}
                    isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
                    onClick={() => { setIsOurCodeDescriptionModalOpen(true); }
                    }
                    buttonText={selectedOurCodeDescription || 'Select Our Code Description'}
                    error={errors.ourCode}
                  />

<ReusableModal
  title="Select Our Code Description"
  isOpen={isOurCodeDescriptionModalOpen}
  onClose={() => {
    setIsOurCodeDescriptionModalOpen(false);
    setSearchQuery('');
  }}
  onSave={() => {
    setIsOurCodeDescriptionModalOpen(false);
    setSearchQuery('');
  }}
  searchPlaceholder="Search OurCode Description..."
  searchQuery={searchQuery}
  showReset={true}
  onReset={handleOurCodeReset}
  onSearchChange={handleSearchChange}
  isViewMode={isViewMode}
  onRefresh={handleOurCodeReset}
>
 {Sources && Sources.length > 0 ? (
  [...new Map(Sources.map((md) => [md.ourCodeDescription, md])).values()] // Remove duplicates by 'ourCodeDescription'
    .filter(
      (md) =>
        md.ourCodeDescription &&
        md.ourCodeDescription.trim() &&
        md.source === selectedSourceType && // Match source with selectedSourceType
        md.ourCodeDescription.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => a.ourCodeDescription.localeCompare(b.ourCodeDescription)) // Sort in alphabetical order
    .map((md, index) => (
      <div
        key={md.id}
        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""}`}
      >
        <input
          type="radio"
          name="ourCodeDescription Type"
          value={md.ourCodeDescription}
          checked={selectedOurCodeDescription === md.ourCodeDescription}
          onChange={handleOurCodeCheckBoxChange}
          className="mr-2 accent-customBlue"
          disabled={isViewMode}
        />
        <label>{md.ourCodeDescription}</label>
      </div>
    ))
) : (
  <p>Loading ourCodeDescription...</p>
)}

</ReusableModal>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="flex items-center">
    <label className="w-48 font-small text-customGray text-xs">
        Rate Date <span className="text-red-500 ml-1">*</span>
    </label>
    <span className="ml-4">:</span>
    <input
        type="date"
        placeholder="dd.mm.yyyy"
        disabled={((isEditMode && !isCopyMode) || isViewMode)}
        value={rateDate}
        onChange={handleratedateChange}
        className={`border ${errors.rateDate ? "border-red-500" : "border-gray-300"} border-gray-300 p-1.5 xl:text-xs lg:text-xs rounded w-80 h-8 
            ${isViewMode  ? 'text-gray-500' : 'text-customGray'} 
            focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0`}
    />
</div>
                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Quote<span className="text-red-500 ml-1">*</span>
                        </label>
                        <span className="ml-4">:</span>
                        <CustomDropdown
    value={quote}
    options={quoteOptions}
    onChange={(value) => {
        handleQuoteChange(value);            
        setUnsavedChanges(true); // Mark as unsaved
    }}
    isOpen={openDropdown === "quoteOptions"}
    onToggle={() => handleToggle("quoteOptions")}
    isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
    setUnsavedChanges={setUnsavedChanges}
    width="2xl:w-80 xl:w-[302px] lg:w-[250px]"
    error={errors.quote}
    className="ml-4 "
    placeholder="Select quote"
/>
                    </div>
                </div>
                
                <div className="grid grid-cols-2 gap-4 mt-4">
                       <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Tenor{" "}
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={tenor}
                            maxLength={2}
                            disabled={isViewMode}
                           onChange={(e) => {
                                  setTenor(e.target.value);
                                setUnsavedChanges(true);
                           }}
                            placeholder="2A"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
                    </div>
                  
                   
                </div>
                
      
                <div className="grid grid-cols-2 gap-4">
                  <div>{!isViewMode && tenor.length === 2 &&  (
                      <p className="text-red-500 text-xs pt-2">You have used the maximum character limit.</p>
                    )}</div>
                  <div></div>
                </div>

                </div>
            </div>
            <div className="m-6 border border-gray-300 p-5 rounded-lg"> 
                 <h3 className="text-md font-medium text-customGray mb-4">
                    Rate
                </h3> 
                <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">

  <div className="flex items-center">
    <label className="w-48 font-small text-customGray text-xs">
      BID{" "}
    </label>
    <span className="ml-4">:</span>
    <input
                            type="text"
                            value={bid}
                            maxLength={15}
                            disabled={isViewMode}
                           onChange={(e) => {
                                  setBid(e.target.value);
                                setUnsavedChanges(true);
                           }}
                            placeholder="2A"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
  </div>
  {!isViewMode && bid.length === 15 &&  (
                      <p className="text-red-500 text-xs pt-2">You have used the maximum character limit.</p>
                    )}
                    </div>
                    <div className="flex flex-col">

  <div className="flex items-center">
    <label className="w-48 font-small text-customGray text-xs">
      ASK{" "}
    </label>
    <span className="ml-4">:</span>
    <input
                            type="text"
                            value={ask}
                            maxLength={15}
                            disabled={isViewMode}
                           onChange={(e) => {
                                  setAsk(e.target.value);
                                setUnsavedChanges(true);
                           }}
                            placeholder="2A"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
  </div>
  {!isViewMode && ask.length === 15 &&  (
                      <p className="text-red-500 text-xs pt-2">You have used the maximum character limit.</p>
                    )}
                    </div>
</div>
</div>
            <div className="m-6 border border-gray-200 p-5  rounded-lg">
               
                {/* <div className="mb-6 border border-gray-300 p-5 rounded-lg"> */}
                {/* <h3 className="text-sm font-medium text-customGray mb-4">
                    Rate
                </h3> */}

                <div className="grid grid-cols-2 gap-4">
                <LabelWithButton
marginbottom ="0px"
label="Source Code "
isRequired={true}
isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
onClick={() => { setIsSourceCodeModalOpen(true); }
}
buttonText={selectedSource || 'Select Source Code '}
error={errors.sourceCode}
/>

<ReusableModal
title="Select Source Code "
isOpen={isSourceCodeModalOpen}
onClose={() => {
  setIsSourceCodeModalOpen(false);
  setSearchQuery('');
}}
onSave={() => {
  setIsSourceCodeModalOpen(false);
  setSearchQuery('');
}}
searchPlaceholder="Search Source Code..."
searchQuery={searchQuery}
showReset={true}
onReset={handleOurCodeReset}
onSearchChange={handleSearchChange}
isViewMode={isViewMode}
onRefresh={handleRefreshOurCode}
>
{Sources && Sources.length > 0 ? (
  [...new Map(Sources.map((md) => [md.sourceCode, md])).values()] // Remove duplicates by 'ourCode'
  .filter(
      (md) =>
        (md.sourceCode &&
          md.sourceCode.trim() &&
          md.source === selectedSourceType && 
          md.sourceCode
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))

    )
    .sort((a, b) => a.sourceCode.localeCompare(b.sourceCode)) // Sort in alphabetical order

    .map((md, index) => (
      <div
        key={md.id}
        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
          }`}
      >
        <input
          type="radio"
          name="Source Code "
          value={md.sourceCode}
          checked={
            selectedSource === md.sourceCode
          }
          onChange={handleSourceCodeCheckBoxChange}
          className="mr-2 accent-customBlue"
          disabled={isViewMode}
        />
        <label>{md.sourceCode}</label>
      </div>
    ))

) : (
  <p>Loading sourceCode...</p>
)}
</ReusableModal>

<LabelWithButton
marginbottom ="0px"
label="Source Code Description"
isRequired={true}
isDisabled={((isEditMode && !isCopyMode) || isViewMode)}
onClick={() => { setIsSourceCodeDescriptionModalOpen(true); }
}
buttonText={selectedSourceCodeDescription || 'Select Source Code Description '}
error={errors.sourceCode}
/>

<ReusableModal
title="Select Source Code"
isOpen={isSourceCodeDescriptionModalOpen}
onClose={() => {
  setIsSourceCodeDescriptionModalOpen(false);
  setSearchQuery('');
}}
onSave={() => {
  setIsSourceCodeDescriptionModalOpen(false);
  setSearchQuery('');
}}
searchPlaceholder="Search Source Code Description..."
searchQuery={searchQuery}
showReset={true}
onReset={handleOurCodeReset}
onSearchChange={handleSearchChange}
isViewMode={isViewMode}
onRefresh={handleRefreshOurCode}
>
{Sources && Sources.length > 0 ? (
  [...new Map(Sources.map((md) => [md.sourceCodeDescription, md])).values()] // Remove duplicates by 'ourCode'
  .filter(
      (md) =>
        (md.sourceCodeDescription &&
          md.sourceCodeDescription.trim() &&
          md.source === selectedSourceType && 
          md.sourceCodeDescription
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))

    )
    .sort((a, b) => a.sourceCodeDescription.localeCompare(b.sourceCodeDescription)) // Sort in alphabetical order

    .map((md, index) => (
      <div
        key={md.id}
        className={`flex items-center ${index % 2 === 0 ? "bg-sky-blue-200" : ""
          }`}
      >
        <input
          type="radio"
          name="Source Code Description"
          value={md.sourceCodeDescription}
          checked={
            selectedSourceCodeDescription === md.sourceCodeDescription
          }
          onChange={handleSourceCodeCheckBoxChange}
          className="mr-2 accent-customBlue"
          disabled={isViewMode}
        />
        <label>{md.sourceCodeDescription}</label>
      </div>
    ))

) : (
  <p>Loading sourceCodeDescription...</p>
)}
</ReusableModal>
</div>
 <div className="grid grid-cols-2 gap-4 mt-4">

 <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            MD Type
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={mdType}
                            disabled={true}
                         
                            placeholder="B name"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
                    </div>

<div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Data Type
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={dataType}
                            disabled={true}
                         
                            placeholder="B name"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
                    </div>
</div>
 <div className="grid grid-cols-2 gap-4 mt-4">



<div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Rate Type
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={rateType}
                            disabled={true}
                         
                            placeholder="B name"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
                    </div>
                    <div className="flex items-center">
                        <label className="w-48 font-small text-customGray text-xs">
                            Ratio{" "}
                        </label>
                        <span className="ml-4">:</span>
                        <input
                            type="text"
                            value={ratio}
                            disabled={true}
                           
                            placeholder="B name"
                            className="border border-gray-300 p-1.5 rounded w-80 h-8 text-customGray focus:outline-none hover:border-blue-400 ml-4 peer placeholder-transparent peer-focus:placeholder-opacity-0 font-small text-customGray text-xs font-small text-customGray text-xs"
                        />
                    </div>
</div>

                
                {/* </div> */}


            </div>
            </TableWrapper>
            </div>
            <Footer>
                {(!isViewMode && (<ButtonGroup onSave={handleSubmit} onSaveContinue={handleSaveandContinue} hasId={!!currencyRateId} />))}
            </Footer>
        </div>
    );
}
