import React, { useEffect, useState } from 'react';
import UploadComponent from '../../../../../../components/UploadComponent';
import * as XLSX from 'xlsx';
import Header from '../../../../../../components/Header';
import { useNavigate } from 'react-router-dom';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import { useToast } from '../../../../../../components/toast/toast';
import UserService from '../../../../../../rest-services/UserServices';
import { update } from 'lodash';
import SourceServices from '../../../../../../rest-services/SourceServices';

export default function CurrencyRateUpload() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [isError, setIsError] = useState(false);
  const [buttonText, setButtonText] = useState('Upload from desktop');
  const [isUploaded, setIsUploaded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [assignee, setAssignee] = useState({ id: '', username: '' });
  const [alert, setAlert] = useState(null);

  const [isDisplayChecked, setIsDisplayChecked] = useState(false);
  const [isUploadChecked, setIsUploadChecked] = useState(false);
  const [displayOption, setDisplayOption] = useState('');
  const [uploadOption, setUploadOption] = useState('');

  // Define the allowed columns for currency rate upload
  const allowedFormat = [
    'RateType', 
    'RateDate', 
    'SourceType', 
    'SourceCode', 
    'Bid', 
    'Ask', 
  ];

  useEffect(() => {
    fetchAssignee();
  }, []);
  const [selectedFormat, setSelectedFormat] = useState('general');

  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };
  // Fetch the logged-in user details
  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem('userDetail');
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || '',
          clientId: userDetail.clientId || '',
          assigner: userDetail.username || '',
          userId: userDetail.userId || '',
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: 'error',
          message: 'No user detail found in session storage.',
        });
      }
    } catch (error) {
      setAlert({
        severity: 'error',
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const excelDateToJSDate = (date) => {
    // If the date is a serial number (Excel date), convert it to a JS date
    if (!isNaN(date)) {
      const excelEpoch = new Date(Date.UTC(1899, 11, 30)); // Excel epoch starts on 1899-12-30
      const jsDate = new Date(excelEpoch.getTime() + date * 86400000); // Add serial days in milliseconds
  
      // Manually format to DD-MM-YYYY
      const day = String(jsDate.getUTCDate()).padStart(2, '0');
      const month = String(jsDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = jsDate.getUTCFullYear();
  
      return `${day}-${month}-${year}`;
    }
  
    // If the date is already in a string format, return it as-is
    return date;
  };
  
  // Handle file upload and validation
  const mapReutersToGeneralFormat = (row) => {
    return {
      RateType: row['Data Type'],
      RateDate: row['Date'], // This will be formatted later
      SourceType: row['Rate Type'],
      SourceCode: row['RIC'],
      Bid: row['BID'],
      Ask: row['ASK'],
    };
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
  
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  
      if (jsonData.length === 0) {
        setButtonText('File Format is Incorrect !');
        setIsUploaded(false);
        setIsError(true);
        toast('error', 'The uploaded file is empty or invalid.');
        return;
      }
  
      // Check if the file format matches the selected format
      const headers = jsonData[0].map((header) => header.trim().toLowerCase());
  
      if (selectedFormat === 'reuters') {
        const reutersHeaders = ['data type', 'date', 'rate type', 'ric', 'bid', 'ask'];
        const isReutersFormat = reutersHeaders.every((header) => headers.includes(header));
  
        if (!isReutersFormat) {
          setButtonText('File Format is Incorrect !');
          setIsUploaded(false);
          setIsError(true);
          toast('error', 'The uploaded file does not match the Reuters format.');
          return;
        }
      } else if (selectedFormat === 'general') {
        const generalHeaders = ['ratetype', 'ratedate', 'sourcetype', 'sourcecode', 'bid', 'ask'];
        const isGeneralFormat = generalHeaders.every((header) => headers.includes(header));
  
        if (!isGeneralFormat) {
          setButtonText('File Format is Incorrect !');
          setIsUploaded(false);
          setIsError(true);
          toast('error', 'The uploaded file does not match the General format.');
          return;
        }
      }
  
      // Keep only columns A to F
      const allowedColumns = ['A', 'B', 'C', 'D', 'E', 'F'];
      const rows = XLSX.utils.sheet_to_json(sheet, { raw: true });
  
      const formattedRows = rows
        .map((row) => {
          let trimmedRow = {}; // Use 'let' instead of 'const' to allow reassignment
          Object.keys(row).forEach((key, index) => {
            if (index < 6) {
              trimmedRow[key] = row[key];
            }
          });
  
          // Map Reuters format to General format if selected
          if (selectedFormat === 'reuters') {
            trimmedRow = mapReutersToGeneralFormat(trimmedRow); // Reassigning 'trimmedRow'
          }
  
          // Handle date format for 'RateDate'
          if (trimmedRow.RateDate) {
            trimmedRow.RateDate = excelDateToJSDate(trimmedRow.RateDate);
          }
  
          return trimmedRow;
        })
        .filter((row) => {
          // Filter out rows with data in columns beyond A-F
          return Object.keys(row).length <= 6;
        });
  
      console.log("Formatted Rows:", formattedRows);
      setTableData(formattedRows);
      setButtonText("File Uploaded");
      setIsUploaded(true);
      setIsError(false);
  
      setTimeout(() => setIsUploaded(false), 1000);
    };
  
    reader.readAsArrayBuffer(file);
  };

// I’ve added the date format handling back in! Let me know if this works for you. 🚀

// Let me know if you’d like me to tweak anything! 🚀


const isRowValid = (row, index) => {
  console.log("Row Object in isRowValid:", row);

  // Validate required fields
  const isRateTypeValid = row.RateType && ['FX', 'IN'].includes(row['RateType']);
  const isRateDateValid = row.RateDate && String(row.RateDate).length <= 10;
  const isSourceValid = row.SourceType && String(row.SourceType).length <= 10;
  const isSourceCodeValid = row.SourceCode && String(row.SourceCode).length <= 20;

  // Validate Bid and Ask fields
  const isBidValid = row.Bid ? !isNaN(row.Bid) && String(row.Bid).length <= 20 : true;
  const isAskValid = row.Ask ? !isNaN(row.Ask) && String(row.Ask).length <= 20 : true;

  // Collect errors
  let errors = [];
  if (!isRateTypeValid) errors.push("Invalid RateType");
  if (!isRateDateValid) errors.push("Invalid RateDate");
  if (!isSourceValid) errors.push("Invalid Source");
  if (!isSourceCodeValid) errors.push("Invalid Source Code");
  if (!isBidValid) errors.push("Invalid Bid (must be numeric)");
  if (!isAskValid) errors.push("Invalid Ask (must be numeric)");

  if (errors.length > 0) {
    console.log(`Row ${index + 1} is invalid:`, errors);
  }

  return (
    isRateTypeValid &&
    isRateDateValid &&
    isSourceValid &&
    isSourceCodeValid &&
    isBidValid &&
    isAskValid
  );
};
  
const getExistingUserIds = async (tableData) => {
  try {
    // Filter rows based on basic validation
    let validRows = tableData.filter((row, index) => isRowValid(row, index));
    console.log("Initial valid rows:", validRows);

    // Check for existing SourceCode in the database
    const validatedRows = await Promise.all(
      validRows.map(async (row) => {
        const sourceCodeExistsResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkexistingsourcecode`,
          { sourceCode: row.SourceCode }
        );

        const sourceCodeExists = sourceCodeExistsResponse?.exists;

        // If SourceCode does not exist, mark the row as invalid
        if (!sourceCodeExists) {
          console.log(`SourceCode ${row.SourceCode} does not exist in the database.`);
          return null; // Return null for invalid rows
        }

        return row; // Return the row if it is valid
      })
    );

    // Filter out null values (invalid rows)
    validRows = validatedRows.filter(row => row !== null);
    console.log("Final valid rows after SourceCode validation:", validRows);

    return validRows;
  } catch (error) {
    console.error("Error fetching existing user IDs:", error);
    return [];
  }
};
  const getDataToDisplay = async () => {
    let dataToDisplay;
    if (uploadOption === "3") {
      console.log("displayOption is 3");
      dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"

      // dataToDisplay = await processData(tableData); // Display processed data if uploadOption is "3"
    } else {
      console.log("displayOption not selected");
      dataToDisplay = tableData; // Default to tableData if no conditions are met
    }
    
    console.log("dataToDisplay data is", dataToDisplay);
    return dataToDisplay;
  };

  const processData = async (data) => {
    if (!Array.isArray(data)) {
      console.error("processData received invalid data:", data);
      return []; // Return an empty array to prevent further errors
    }
    console.log("processData received data:", data);
  
    return Promise.all(data.map(async (row) => {
      const isDuplicateUserId = data.filter(
        (r) =>
          r.RateType === row.RateType &&
          r.RateDate === row.RateDate &&
          r.SourceType === row.SourceType &&
          r.SourceCode === row.SourceCode
      ).length > 1;
      console.log("Duplicate user id is ", isDuplicateUserId);
  
      // Check if the sourceCode exists in the database
      const sourceCodeExistsResponse = await SourceServices.checkDuplicateSourcecode(
        `${process.env.REACT_APP_BASE_URL}/checkexistingsourcecode`,
        { sourceCode: row.SourceCode }
      );
  
      const sourceCodeExists = sourceCodeExistsResponse?.exists;
  
      // Validate all fields
      const hasNotError = [
        row.RateType,
        row.RateDate,
        row.SourceType,
        row.SourceCode,
        row.Bid,
        row.Ask,
      ].every((field, index) => {
        let isValid = true;
        let reason = "";
  
        switch (index) {
          case 0: // RateType
            if (!field) {
              isValid = false;
              reason = "RateType should not be blank.";
            } else if (!['FX', 'IN'].includes(field)) {
              isValid = false;
              reason = "RateType should be either 'FX' or 'IN'.";
            }
            break;
  
          case 1: // RateDate
            if (!field) {
              isValid = false;
              reason = "RateDate should not be blank.";
            } else if (!String(field)) {
              isValid = false;
              reason = "RateDate should be a valid date.";
            }
            break;
  
          case 2: // SourceType
            if (!field) {
              isValid = false;
              reason = "SourceType should not be blank.";
            } else if (String(field).length > 10) {
              isValid = false;
              reason = "SourceType exceeds max length of 10.";
            }
            break;
  
          case 3: // SourceCode
            if (!field) {
              isValid = false;
              reason = "SourceCode should not be blank.";
            } else if (field && isDuplicateUserId) {
              isValid = false;
              reason = "Duplicate entry.";
            } else if (String(field).length > 20) {
              isValid = false;
              reason = "SourceCode exceeds max length of 20.";
            } else if (!sourceCodeExists) {
              isValid = false;
              reason = "SourceCode does not exist in the database.";
            }
            break;
  
          case 4: // Bid
            if (field && (isNaN(field) || String(field).length > 20)) {
              isValid = false;
              reason = "Bid must be numeric and within 20 characters.";
            }
            break;
  
          case 5: // Ask
            if (field && (isNaN(field) || String(field).length > 20)) {
              isValid = false;
              reason = "Ask must be numeric and within 20 characters.";
            }
            break;
  
          default:
            break;
        }
  
        if (!isValid) {
          console.log(`Validation Failed: ${reason}`);
        } else {
          console.log(`Validation Passed: ${field}`);
        }
  
        return isValid;
      });
  
      console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        'RateType': row['RateType'] ? (
          ['FX', 'IN'].includes(row['RateType']) ? (
            row['RateType']
          ) : (
            <span style={{ color: 'red' }}>{row['RateType']} (Invalid Rate Type)</span>
          )
        ) : (
          <span style={{ color: 'red' }}> Shouldn't be blank</span>
        ),
  
        'RateDate': row['RateDate'] ? (
          String(row['RateDate']) ? (
            row['RateDate']
          ) : (
            <span style={{ color: 'red' }}>{row['RateDate']} (Invalid Rate Date)</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Shouldn't be blank</span>
        ),
  
        'SourceType': row['SourceType'] ? (
          String(row['SourceType']).length <= 10 ? (
            row['SourceType']
          ) : (
            <span style={{ color: 'red' }}>{row['SourceType']} (Max. allowed character is 10)</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Shouldn't be blank</span>
        ),
  
        'SourceCode': row.SourceCode ? (
          String(row.SourceCode).length <= 20 ? (
            isDuplicateUserId ? ( // Check for duplication
              <span style={{ color: 'red' }}>{row.SourceCode}, Duplicate SourceCode</span>
            ) : (
              sourceCodeExists ? ( // Check if sourceCode exists in the database
                row.SourceCode // No error, return the SourceCode
              ) : (
                <span style={{ color: 'red' }}>{row.SourceCode}, SourceCode does not exist</span>
              )
            )
          ) : (
            <span style={{ color: 'red' }}>{row.SourceCode}, Max. allowed character is 20</span>
          )
        ) : (
          <span style={{ color: 'red' }}>Shouldn't be blank</span>
        ),
  
        'Bid': row.Bid ? (
          !isNaN(row.Bid) && String(row.Bid).length <= 20 ? (
            row.Bid
          ) : (
            <span style={{ color: 'red' }}>{row.Bid} (Invalid Bid)</span>
          )
        ) : (
          <span style={{ color: 'red' }}></span>
        ),
  
        'Ask': row.Ask ? (
          !isNaN(row.Ask) && String(row.Ask).length <= 20 ? (
            row.Ask
          ) : (
            <span style={{ color: 'red' }}>{row.Ask} (Invalid Ask)</span>
          )
        ) : (
          <span style={{ color: 'red' }}></span>
        ),
      };
    }));
  };
  const storeArrayDataForUsers = async () => {
    // console.log("store successfulRecords data is", getDataToDisplay);

    setLoading(true);
    try {
        const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
        console.log("table data",tableData);
        console.log("store successfulRecords data 2 is", dataToDisplay);

       const processed = await processData(tableData);
                 console.log("display datas",processed);
       
                const extractText = (cellValue) => {
                             if (Array.isArray(cellValue)) {
                               return cellValue.join(", "); // Convert array to string
                             } else if (typeof cellValue === "string") {
                               return cellValue;
                             } else if (React.isValidElement(cellValue)) {
                               return cellValue.props.children;
                             }
                             return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                           };
               
                           const formattedUsers = processed.map((user) => {
               
                             return {
                           
                             "RateType": extractText(user.RateType),
                             "RateDate": extractText(user.RateDate),
                             "SourceType": extractText(user.SourceType),
                             "SourceCode": extractText(user.SourceCode),
                             "Bid": extractText(user.Bid),
                             "Ask": extractText(user.Ask),
                           
                           };
                         });
                           
                             // Insert second and third row at the beginning of the formatted data
                             const rowsToDisplay = [ ...formattedUsers];
                     
                             console.log("Formatted USERS Data:", formattedUsers);
                             console.log("Formatted Data:", rowsToDisplay);
                     
                             
                             const updatedData = rowsToDisplay.map(user => {
                              const hasErrorField = [
                                "RateType",
                                "RateDate",
                                "SourceType",
                                "SourceCode",
                                "Bid",
                                "Ask",
                              ].some(field => typeof user[field] === 'object' || user[field]?.includes("(Invalid") || user[field]?.includes("Shouldn't be blank") || user[field]?.includes("Duplicate SourceCode") || user[field]?.includes("SourceCode does not exist"));
                            
                              return {
                                update: hasErrorField ? "No" : "Yes",
                                ...user,
                              };
                            });
                            
                       console.log("updatedData data",updatedData);
                       const mappedData = dataToDisplay.map((row) => ({
                                rateType: row.RateType,
                                rateDate: row.RateDate,
                                source: row.SourceType, // Map SourceType to source
                                sourceCode: row.SourceCode,
                                bid: row.Bid,
                                ask: row.Ask,
                              }));
        // Prepare the final data object
        const data = {
            dataToDisplay:mappedData, // Only valid rows
            createdBy: assignee.userId,
            allrecords: updatedData, // All rows with update flag
        };

        console.log("storeArrayDataForCurrencyRates dataToDisplay is", data);

        // Send the data to the server
        const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/bulk-create-currencyrate`,
            data
        );
        if (dataToDisplay.length === 0) {
          toast("error", "Error fields exist.");
          return;
        }
             
        else if (response.status === 200) {
            toast('success', 'Uploaded currency rates successfully');
        } else if (response.status === 400) {
            toast('error', 'Invalid or empty array of currency rates');
        } else if (response.status === 404) {
            toast('error', 'Missing required fields');
        } else if (response.status === 500) {
            toast('error', 'Duplicate currency rates found.');
        }
    } catch (error) {
        console.error('Error uploading currency rates:', error);
    } finally {
        setLoading(false);
    }
};
  
  

  const handleSubmit = async () => {
    if (buttonText === 'Upload from desktop') {
      toast('error', 'Please upload a file.');
      return;
    } else if (tableData.length === 0) {
      toast('error', 'Please upload the correct file containing the data.');
      return;
    }

    // const validRows = tableData.filter((row) => isRowValid(row));
    // if (validRows.length === 0) {
    //   toast('error', 'No valid rows found in the uploaded file.');
    //   return;
    // }

    if (!isDisplayChecked && !isUploadChecked) {
      toast('error', 'Please check at least one checkbox (Display or Upload).');
      return;
    }

    if (uploadOption !== '' && displayOption === '') {
      storeArrayDataForUsers();
    } else {
      if (uploadOption !== '') {
        storeArrayDataForUsers();
      }
      navigate(`/2/master-data/14/currencyrate-upload/display-screen`, {
        state: { tableData, displayOption, uploadOption }, // Only pass valid rows
      });
    }
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };


  const confirmDeletion = async () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Define the headers
    const headers = ['RateType', 'RateDate', 'SourceType', 'SourceCode', 'Bid', 'Ask'];
  
    // Get the current date
    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  
    // Example: Fill 10 rows with current date
    const rowCount = 10; // You can change this or make it dynamic
    const worksheetData = [headers];
  
    for (let i = 0; i < rowCount; i++) {
      worksheetData.push(['FX', formattedDate, 'REUTERS', 'INRKWD=CBKK', '', '']);
    }
  
    // Create a worksheet with headers and data
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
    // Create a Blob and trigger the download
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'Reuters_Format_Upload.xlsx';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  };
  
  // Now, every time the user downloads, the date will automatically update in every row! 🚀
  
  // Let me know if you want me to make any tweaks or add dynamic row counts! ✨
  

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === 'Market Data') {
      navigate('/2/master-data');
    } else if (crumb.title === 'Currency Rate') {
      navigate('/2/master-data/14/currency-rate');
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  return (
    <div>
      <Header
        title={`Primary Data > Market Data > Currency Rate > Upload Rate`}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={'/2/master-data/14/currency-rate'}
      />
      {loading && <CustomeLoading />}

      <UploadComponent
        downloadPath={selectedFormat === 'reuters' ? '/Reuters_Format_Upload.xlsx' : '/General_Format_Upload.xlsx'}
        handleFileChange={handleFileChange}
        buttonText={buttonText}
        setButtonText= {setButtonText}
        setIsError = {setIsError}
        isUploaded={isUploaded}
        isError={isError}
        selectedFormat={selectedFormat}
        setSelectedFormat={setSelectedFormat}
        handleSubmit={handleSubmit}
        isDisplayChecked={isDisplayChecked}
        setIsDisplayChecked={setIsDisplayChecked}
        isUploadChecked={isUploadChecked}
        setIsUploadChecked={setIsUploadChecked}
        displayOption={displayOption}
        setDisplayOption={setDisplayOption}
        uploadOption={uploadOption}
        setUploadOption={setUploadOption}
        enableFormatSelection={true}
        onFormatChange={handleFormatChange} // Pass 
        downloadText={selectedFormat === 'reuters' ? 'Download Reuters Format' : 'Download General Format'} // Dynamic text
      />

      <ConfirmationDialog
        open={showConfirmation}
        title="Upload File"
        message="Do you need to download the latest upload format?"
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
      />
    </div>
  );
}



