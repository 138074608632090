import React, { useEffect, useState } from 'react'
import Header from '../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import SearchInput from '../../../../../components/SearchInput';
import PrimaryActions from '../../Components/ActionButton';
import { PrimaryActionsConfig } from '../../Components/ActionButtonConfig';
import { IoAddCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineFileDownload, MdOutlineFileUpload } from 'react-icons/md';
import { IoMdCheckmark, IoMdClose, IoMdCopy, IoMdCreate, IoMdRefresh, IoMdTime, IoMdTrash } from 'react-icons/io';
import LegalEntityServices from '../../../../../rest-services/LegalEntityServices';
import CustomeUpLoader from '../../../../../components/CustomeLoader';
import ColumnSettingsPopup from '../../Components/ColumnSettingPopup';
import ReTable from '../../../../../components/Table';
import * as XLSX from 'xlsx';
import BankBranchServices from '../../../../../rest-services/BankBranchServices';
import { TbDeviceDesktopCancel, TbFlag3Filled } from 'react-icons/tb';
import { GiSettingsKnobs } from 'react-icons/gi';
import { useToast } from "../../../../../components/toast/toast";

export default function BankBranch() {
  const { iconId } = useParams(); 

    const columns = [
      { Header: <TbFlag3Filled style={{ color: "#ffff" }} />, 
      accessor: "status", 
      visible: true, 
      width: 200,
      Cell: ({ value }) => {
        switch (value) {
          case 3:
            return <TbFlag3Filled style={{ color: "#29CB00" }} />; // Green for status 3
          case 0:
            return <TbFlag3Filled style={{ color: "gray" }} />; // Gray for status 0
          case 1:
            return <TbFlag3Filled style={{ color: "yellow" }} />; // Yellow for status 1
          case 2:
            return <TbFlag3Filled style={{ color: "orange" }} />; // Orange for status 2
          case 4:
            return <TbFlag3Filled style={{ color: "red" }} />; // Red for status 4
          default:
            return <TbFlag3Filled style={{ color: "black" }} />; // Default black flag
        }
      },
    },
        { Header: "Bank Branch", accessor: "bankBranch", visible: true, width: 200 },
        { Header: "Branch Name", accessor: "branchName", visible: true, width: 200 },
        { Header: "Bank ID", accessor: "BankId", visible: true, width: 200 },
        { Header: "Bank Name", accessor: "BankName", visible: true, width: 200 },
        { Header: "Bank ", accessor: "Bank", visible: true, width: 200 },
        { Header: "DoorNo ", accessor: "addresses[0].doorNo", visible: true, width: 200 },
        { Header: "FloorNo ", accessor: "addresses[0].floorNo", visible: true, width: 200 },
        { Header: "Building No ", accessor: "addresses[0].buildingNo", visible: true, width: 200 },
        { Header: "Building Name ", accessor: "addresses[0].buildingName", visible: true, width: 200 },
        { Header: "LandMark ", accessor: "addresses[0].landMark", visible: true, width: 200 },
        { Header: "Street No ", accessor: "addresses[0].streetNo", visible: true, width: 200 },
        { Header: "Street Name ", accessor: "addresses[0].streetName", visible: true, width: 200 },
        { Header: "Road No ", accessor: "addresses[0].roadNo", visible: true, width: 200 },
        { Header: "Road Name ", accessor: "addresses[0].roadName", visible: true, width: 200 },
        { Header: "Block ", accessor: "addresses[0].areaBlock", visible: true, width: 200 },
        { Header: "Area Name ", accessor: "addresses[0].areaName", visible: true, width: 200 },
        { Header: "Address 1 ", accessor: "addresses[0].addressLine1", visible: true, width: 200 },
        { Header: "Address 2 ", accessor: "addresses[0].addressLine2", visible: true, width: 200 },
        { Header: "Address 3 ", accessor: "addresses[0].addressLine3", visible: true, width: 200 },
        { Header: "Country Code ", accessor: "addresses[0].countryCode", visible: true, width: 200 },
        { Header: "State  ", accessor: "addresses[0].state", visible: true, width: 200 },
        { Header: "District ", accessor: "addresses[0].district", visible: true, width: 200 },
        { Header: "Postal Code ", accessor: "addresses[0].postalCode", visible: true, width: 200 },
        { Header: "Zip Code ", accessor: "addresses[0].zipCode", visible: true, width: 200 },
        { Header: "PO Box ", accessor: "addresses[0].poBox", visible: true, width: 200 },
        { Header: "Bank BIC ", accessor: "identifier[0].bankBIC", visible: true, width: 200 },
        { Header: "Country Code ", accessor: "identifier[0].country_Code", visible: true, width: 200 },
        { Header: "Country Code Type ", accessor: "identifier[0].countryCodeType", visible: true, width: 200 },
        { Header: "Customer Code ", accessor: "identifier[0].customerCode", visible: true, width: 200 },
        { Header: "MICR ", accessor: "identifier[0].micr", visible: true, width: 200 },
        { Header: "Bank Code ", accessor: "identifier[0].bankCode", visible: true, width: 200 },
        { Header: "Branch Code ", accessor: "identifier[0].BranchCode", visible: true, width: 200 },
        { Header: "Bank Account Min Length ", accessor: "specificchecks[0].bankAccountMinLength", visible: true, width: 200 },
        { Header: "Bank Account Max Length ", accessor: "specificchecks[0].bankAccountMaxLength", visible: true, width: 200 },





        { Header: "Updated By", accessor: "created_by", visible: true, width: 200 },
        {
          Header: "Updated Date",
          accessor: "creationDate",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
          },
        },
        {
          Header: "Updated Time",
          accessor: "creationTime",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return date.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });
          },
        },
        { Header: "Updated By", accessor: "changed_by", visible: true, width: 200 },
        {
          Header: "Updated Date",
          accessor: "changedDate",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
          },
        },
        {
          Header: "Updated Time",
          accessor: "changedTime",
          visible: true,
          width: 200,
          Cell: ({ value }) => {
            if (!value) return "";
            const date = new Date(value);
            return date.toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            });
          },
        },
      ];
      
    const [bankBranch, setBankBranch] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const navigate = useNavigate();
    // const { toast } = useToast();
    const [bankBranchId, setBankBranchId] = useState(null);
    const [filteredbankBranch, setfilteredBankBranch] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showExtraIcons, setShowExtraIcons] = useState(false);
    const [columnSettings, setColumnSettings] = useState(columns);
    const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
    const { toast } = useToast();
    const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee

    

    useEffect(() => {
      fetchBankBranch();
      fetchAssignee();
    }, []);
    
    useEffect(() => {
      if (searchQuery.trim() === "") {
        setfilteredBankBranch(bankBranch);
      } else {
        const filtered = bankBranch.filter((bankbranch) => 
          bankbranch.bankBranch &&
          bankbranch.bankBranch.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setfilteredBankBranch(filtered);
      }
    }, [searchQuery, bankBranch]);
    const fetchAssignee = async () => {
      try {
        const userDetailString = sessionStorage.getItem("userDetail");
        if (userDetailString) {
          const userDetail = JSON.parse(userDetailString);
          const assigneeData = {
            id: userDetail.id || "",
            assigner: userDetail.username || "",
          };
          setAssignee(assigneeData);
        } else {
          setAlert({
            severity: "error",
            message: "No user detail found in session storage.",
          });
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching user details: ${error.message}`,
        });
      }
    };
     
    const fetchBankBranch = async () => {
      setLoading(true);
      try {
        const response = await BankBranchServices.getAllBankBranch(`/get-all-bankbranch`);
        const responseData = response.data;
    
        if (responseData.error) {
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
    
        if (responseData && Array.isArray(responseData)) {
          setBankBranch(responseData);  // assuming responseData contains an array of legal entities
        }
      } catch (error) {
        setAlert({
          severity: "error",
          message: `Error fetching legal entities: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    };
      const modifiedColumns = columnSettings
      .filter((col) => col.visible)
      .map((col) => ({
        ...col,
        width: col.width,
      }));
      const handleApproveClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankBranchServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/approvebranch`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response);
      
            if (response.status === 200) {
              
              toast("success", "Branch successfully Approved");

              // Success logic, refresh the bank branches
               fetchBankBranch();
            }  else if (response.status === 400) {
              toast("warning", "Branch is unable to approve.");
             } 
             else if (response.status === 550) {
              toast("warning", "Branch Email is Not Sent.");
             } 
             else {
              console.log("Error updating Branchs.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
      
      const handleRejectClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankBranchServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/rejectbranch`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response.status);
      
            if (response.status === 200) {
              toast("error", "Branch successfully Rejected");
              fetchBankBranch();
            }  else if (response.status === 400){
              toast("warning", "Branch is unable to Reject.");
            } else {
              console.log("Error updating Branchs.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
      const handleDelete = async () => {
        if (selectedRows.length === 0) return; // No rows selected to delete
        
        const confirmed = window.confirm("Are you sure you want to delete the selected BuGroup(s)?");
        if (confirmed) {
          setLoading(true);
          try {
            // Loop through each selected row ID and delete
            for (const id of selectedRows) {
              await BankBranchServices.deleteBankBranch(id);
            }
            // toast("success", "Selected BuGroup(s) deleted successfully");
      
            // Clear selection after deletion and refresh the list
            setSelectedRows([]);
            fetchBankBranch();
          } catch (error) {
            console.error("Error deleting BuGroup(s):", error);
            // toast("error", "Failed to delete BuGroup(s)");
          } finally {
            setLoading(false);
          }
        }
      };
      
      const handleInactiveClick = async () => {
        if (selectedRows.length > 0) {
          try {
             // Assuming selectedRows contains the selected bank branch IDs
            
            // Pass the array of selected IDs to the approval service
            const response = await BankBranchServices.saveapproved(
              `${process.env.REACT_APP_BASE_URL}/inactivebranch`,
              { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
            );
            
            console.log("Response status:", response);
      
            if (response.status ===  200) {
              toast("success", "Branch successfully Change Status.");
              fetchBankBranch();
            } 
            else if (response.status === 400){
              toast("warning", "The Branch cannot be deactivated at this time.");
            } else {
              console.log("Error approving bank branches.");
            }
          } catch (error) {
            console.error("Error approving bank branches:", error);
          }
        } else {
          console.log("Please select at least one bank branch to approve.");
        }
      };
      
    const extraButtons = [
      {
        icon: IoMdCheckmark, // Pass the component itself
        onClick: handleApproveClick,
        title: "Approve",
        disabled: selectedRows.length === 0,
        
      },
      {
        icon: IoMdClose,
        onClick: handleRejectClick,
        title: "Reject",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdCreate,
        onClick: () => bankBranchId && navigateToForm(bankBranchId),
        title:"Edit",
        disabled: selectedRows.length === 0,  
  
      },
      {
        icon: IoMdCopy,
        // onClick: handleAddcopyClick,
        title: "Copy",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdTime,
        // onClick: handleAuditTrail,
        title: "Audit Trail",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdTrash,
        onClick: handleDelete,
        title: "Delete",
        disabled: selectedRows.length === 0,
      },
      {
        icon: TbDeviceDesktopCancel,
        onClick: handleInactiveClick,
        title: "Inactive/Active",
        disabled: selectedRows.length === 0,
      },
       
      ];
      const navigateToForm = (id) => {
        navigate(`/bankbranch-add/${id}`);
      };

    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Bank&Company masterData") {
          navigate("/master-data");
        } else if (crumb.title === "BankBranch") {
          navigate("/2/bankbranch");
        } else if (crumb.title === "Primary Data") {
          navigate("/master-data");
        } else if (crumb.path) {
          navigate(crumb.path); 
        }
      };
      const handleSettingsButtonClick = () => {
        setIsSettingsPopupOpen(true);
      };
      const handleDownload = () => {
        const dataToExport = filteredbankBranch.map((bankbranch) => ({
          "Status": bankbranch.status === 0 ? "Created" :
          bankbranch.status === 1 ? "Waiting for Approval" :
          bankbranch.status === 2 ? "Modified" :
          bankbranch.status === 3 ? "Approved" :
          bankbranch.status === 4 ? "Rejected" : "Inactive",
          "Branch": bankbranch.bankBranch || "",
          "Branch Name": bankbranch.branchName || "",
          "Bank Id": bankbranch.BankId || "",
          "Bank": bankbranch.Bank || "", // Handle Bank being null
          "Bank Name": bankbranch.BankName || "", // Handle BankName being null
          "Door No": bankbranch.addresses?.[0]?.doorNo || "", // Ensure addresses array exists
          "Floor No": bankbranch.addresses?.[0]?.floorNo || "",
          "Building No": bankbranch.addresses?.[0]?.buildingNo || "", // Ensure addresses array exists
          "Building Name": bankbranch.addresses?.[0]?.buildingName || "", // Ensure addresses array exists
          "Land Mark Name": bankbranch.addresses?.[0]?.landMark || "",
          "Street No": bankbranch.addresses?.[0]?.streetNo || "",
          "Street Name": bankbranch.addresses?.[0]?.streetName || "",
          "Road No": bankbranch.addresses?.[0]?.streetName || "",
          "Road Name": bankbranch.addresses?.[0]?.streetName || "",
          "Street Name": bankbranch.addresses?.[0]?.streetName || "",
          "Street Name": bankbranch.addresses?.[0]?.streetName || "",
          "Area Block": bankbranch.addresses?.[0]?.areaBlock || "", // Ensure addresses array exists
          "Area Name": bankbranch.addresses?.[0]?.areaName || "",
          "Address Line1": bankbranch.addresses?.[0]?.addressLine1 || "",
          "Address Line2": bankbranch.addresses?.[0]?.addressLine2 || "",
          "Address Line3": bankbranch.addresses?.[0]?.addressLine3 || "",
          "Country Code": bankbranch.identifier?.[0]?.country_Code || "", 
          "State Name": bankbranch.addresses?.[0]?.stateName || "", // Ensure state name from addresses
          "District": bankbranch.addresses?.[0]?.district || "",
          "Postal Code": bankbranch.addresses?.[0]?.postalCode || "",
          "Zip Code": bankbranch.addresses?.[0]?.zipCode || "",
          "Po Box": bankbranch.addresses?.[0]?.poBox || "",
          "Bank BIC": bankbranch.identifier?.[0]?.bankBIC || "",
          "Country Code": bankbranch.identifier?.[0]?.country_Code || "",
          "Country Code Type": bankbranch.identifier?.[0]?.countryCodeType || "",
          "Customer Code": bankbranch.identifier?.[0]?.customerCode || "",
          "MICR": bankbranch.identifier?.[0]?.micr || "", 
          "Bank Code": bankbranch.identifier?.[0]?.bankCode || "",
          "Branch Code": bankbranch.identifier?.[0]?.BranchCode || "",
          "Branch Code": bankbranch.specificchecks?.[0]?.bankAccountMinLength || "",
          "Branch Code": bankbranch.specificchecks?.[0]?.bankAccountMaxLength || "",
          "Created By": bankbranch.created_by || "",
          "Created Date": bankbranch.creationDate
            ? new Date(bankbranch.creationDate).toLocaleDateString()
            : "",
          "Created Time": bankbranch.creationTime
            ? new Date(bankbranch.creationTime).toLocaleTimeString()
            : "",
          "Updated By": bankbranch.changed_by || "", // Handle null values
          "Updated Date": bankbranch.changedDate
            ? new Date(bankbranch.changedDate).toLocaleDateString()
            : "",
          "Updated Time": bankbranch.changedTime
            ? new Date(bankbranch.changedTime).toLocaleTimeString()
            : "",
        }));
      
        console.log(dataToExport); // Log to verify
      
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "BankBranch");
        XLSX.writeFile(workbook, "BankBranch.xlsx");
      };
      const handleReload = async () => {
        setLoading(true);  // Start loading
        try {
          await fetchBankBranch();  // Fetch Branchs
        } catch (error) {
          console.error('Error reloading Branchs:', error);
        } finally {
          setLoading(false);  // Stop loading
        }
      };
      const existingButtons = PrimaryActionsConfig.screen2.map((button) => ({
        ...button,
        title:
          button.icon === IoAddCircleOutline
            ? "Add Country"
             : button.icon === MdOutlineFileDownload
            ? "Filter"
            : button.icon === GiSettingsKnobs
            ? "Download"
             : button.icon === MdOutlineFileUpload
            ? "Upload"
            : button.icon === IoSettingsOutline
            ? "Settings"
             : button.icon === IoMdRefresh
      ? "Refresh"
      : button.title|| "", 
        onClick:
          button.icon === IoAddCircleOutline
            ? () => handleNavigate("/bankbranch-add")
            : button.icon === MdOutlineFileDownload 
      ? handleDownload
      : button.icon === IoSettingsOutline
      ? handleSettingsButtonClick
      : button.icon === IoMdRefresh
      ? handleReload
      : button.onClick || (() => {}),
      }));
      const actionButtons = existingButtons.concat(
        extraButtons.map((button) => ({
          ...button,
          disabled: selectedRows.length === 0, 
          onClick: selectedRows.length > 0 ? button.onClick : null, 
        }))
      );
 
      
      const handleNavigate = (path) => {
        navigate(path);
      };
      const handleSelectRow = (id) => {
        handleCheckboxChange(id);
      };
      const handleSelectAll = () => {
        handleSelectAllChange();
      };
      const handleDoubleClick = (id) => {
        navigate(`/bankbranch-view/${id}`, { state: { mode: "view" } });
      };
      const handleCheckboxChange = (id) => {
        const updatedSelectedRows = selectedRows.includes(id)
          ? selectedRows.filter((rowId) => rowId !== id)
          : [...selectedRows, id];
    
        setSelectedRows(updatedSelectedRows);
        setBankBranchId(id);
        setShowExtraIcons(updatedSelectedRows.length > 0);
      };
    const handleUpdateColumnSettings = (updatedSettings) => {
        setColumnSettings(updatedSettings);
      };

   
    const handleSelectAllChange = () => {
        if (isAllSelected) {
          setSelectedRows([]);
        } else {
          const allIds = bankBranch.map((item) => item.id);
          setSelectedRows(allIds);
        }
        setIsAllSelected(!isAllSelected);
      };
      if (loading)
        return (
          <div>
          </div>
        );
  return (
    <div>
        <Header
        title={"Primary Data > Bank&Company masterData > Bank Branch"}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <div>
        <div className="flex justify-between -ml-1">
          <PrimaryActions
            // buttons={[...existingButtons]}
            buttons={actionButtons} 
            selectedCount={selectedRows.length} 

            // extraButtons={extraButtons}
            isExtraButtonsVisible={showExtraIcons}
          />
          <div className="flex items-center">
            <p className="mr-4 text-customBlue">Bank Branch :</p>

            <SearchInput 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            placeholder="Search by Branch" 
          />
          </div>
        </div>
        </div>
        <ReTable
          data={filteredbankBranch}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
     
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
    </div>
  )
}
