import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../../../components/toast/toast';
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import Footer from '../../../../../../components/footer';
import UploadComponent from '../../../../../../components/UploadComponent';
import * as XLSX from "xlsx";
import UserService from '../../../../../../rest-services/UserServices';
import { format } from 'date-fns';

export default function BAMUploads() {
     const navigate = useNavigate();
    
        const { iconId } = useParams();
    const { toast } = useToast();
    const [tableData, setTableData] = useState([]);
    const [buttonText, setButtonText] = useState("Upload from desktop");
    const [isUploaded, setIsUploaded] = useState(false);
    const [displayOption, setDisplayOption] = useState(""); // Default value
    const [uploadOption, setUploadOption] = useState(""); // Default value
    const [isDisplayChecked, setIsDisplayChecked] = useState(false); // State for checkbox
    const [isUploadChecked, setIsUploadChecked] = useState(false); // State for checkbox
    const [isError, setIsError] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [assignee, setAssignee] = useState({ id: "", username: "" });
    const [alert, setAlert] = useState(null);
      const [loading, setLoading] = useState(false);
        const [selectedFormat, setSelectedFormat] = useState('NewRequest');
      
        const [bankBranchesGreen, setBankBranchesGreen] = useState([]);
          const [bankBranches, setBankBranches] = useState([]);
          const [currencies, setCurrencies] = useState([]);
          const [LGE, setLGE] = useState([]);
          const [LGEGreen, setLGEGreen] = useState([]);
          const [companies, setCompanies] = useState([]);
          const [companiesGreen, setCompaniesGreen] = useState([]);
          const [accountTypes, setAccountTypes] = useState([]);
          const [zbaTypes, setZbaTypes] = useState([]);
          const [accClassifications, setAccClassifications] = useState([]);
          const [statementTypes, setStatementTypes] = useState([]);
          const [mainBankAccounts, setMainBankAccounts] = useState([]);
          const [mainBankAccountsGreen, setMainBankAccountsGreen] = useState([]);
          const [companiesGreenAssignToLGE, setCompaniesGreenAssignToLGE] = useState([]);
        
          const[newRequestNo,setNewRequestNo] = useState([]);
              const[newRequestNoGreen,setNewRequestNoGreen] = useState([]);
              const[ibanRequired,setibanRequired] = useState([]);
              const [accountNoUpdatesuccessfulRecords, setAccountNoUpdateSuccessfulRecords] = useState([]);
          
          
      const allowedFormat = ['Action',	
'SLNo',	
'BankBranch',	
'CurrencyCode',	
'Note',	
'LGECode',	
'CompanyCode',	
'AccountType',	
'ZBAType',	
'AccountClassification',
'Overdraft',	
'StatementType',	
'ODLimit',	
'InterestIndicator',	
'ODDate',	
'MinimumBalance',	
'Dormant',	
'MainBankAccount',	
'Text1',	
'Text2',	
'Text3',	
'Text4',	
'Text5'
]; 

const allowedFormat2 = ['Action',	
'SLNo',	
'NewRequestNo',
'BankAccountNo',
'BankAccountIdDescription',
'TextField',
'IBANNo',
];

const allowedFormat3 = ['Action',	
  'SLNo',	
  'BankBranch',	
  'CurrencyCode',	
  'Note',	
  'LGECode',	
  'CompanyCode',	
  'AccountType',	
  'ZBAType',	
  'AccountClassification',
  'Overdraft',	
  'StatementType',	
  'ODLimit',	
  'InterestIndicator',	
  'ODDate',	
  'MinimumBalance',	
  'Dormant',	
  'MainBankAccount',	
  'Text1',	
  'Text2',	
  'Text3',	
  'Text4',	
  'Text5',
  'BankAccountNo',
  'BankAccountIdDescription',
  'TextField',
  'IBANNo',
  ]; 
  

    const handleBreadcrumbClick = (crumb) => {
        if (crumb.title === "BAM") {
          navigate("/bam");
        } else if (crumb.title === "Bank Master Data") {
          navigate("/3/BAM/26/account-master");
        } else if (crumb.title === "Account Master") {
          navigate("/3/BAM/26/account-master");
        } else if (crumb.path) {
          navigate(crumb.path);
        }
      };

      
         useEffect(() => {
            fetchAssignee();
            fetchBankBranchesGreenCode();
            fetchBankBranchesCode();
            fetchCurrencyCodes();
            fetchLGECodes();
            fetchLGEGreenCodes();
            fetchCompanyCodes();
            fetchCompanyGreenCodes();
            fetchAccountTypeCodes();
            fetchZBATypeCodes();
            fetchAccountClassification();
            fetchStatementTypes();
            fetchMainBankAccnts();
            fetchMainBankAccntsGreen();
            fetchCompanyGreenCodesAssignToLGE();

            fetchNewRequestNoData();
            fetchIBANRequiredData();
          }, []);
            
        const fetchAssignee = async () => {
          try {
            const userDetailString = localStorage.getItem("userDetail");
            if (userDetailString) {
              const userDetail = JSON.parse(userDetailString);
              const assigneeData = {
                id: userDetail.id || "",
                clientId: userDetail.clientId || "",
                assigner: userDetail.username || "",
                userId: userDetail.userId || "",
      
              };
      
              setAssignee(assigneeData);
            } else {
              setAlert({
                severity: "error",
                message: "No user detail found in session storage.",
              });
            }
          } catch (error) {
            setAlert({
              severity: "error",
              message: `Error fetching user details: ${error.message}`,
            });
          }
        };
      
        const fetchBankBranchesGreenCode = async () => {
          try {
            const response = await UserService.getUsers(
              `${process.env.REACT_APP_BASE_URL}/bam/get-Bank-Branch-green-code`
            );
            console.log("response. Bank Branch Green data",response.data.data);
            setBankBranchesGreen(response.data.data);
            // console.log("Set user types data", response.data.data);
          } catch (error) {
            console.error("Error fetching user types:", error);
          }
          };
        
          const fetchBankBranchesCode = async () => {
              try {
                const response = await UserService.getUsers(
                  `${process.env.REACT_APP_BASE_URL}/bam/get-Bank-Branch-code`
                );
                console.log("response. Bank Branch data",response.data.data);
                setBankBranches(response.data.data);
                // console.log("Set user types data", response.data.data);
              } catch (error) {
                console.error("Error fetching user types:", error);
              }
              };

              const fetchCurrencyCodes = async () => {
                  try {
                    const response = await UserService.getUsers(
                      `${process.env.REACT_APP_BASE_URL}/bam/get-all-Currency-code`
                    );
                    console.log("response. currency data",response.data.data);
                    setCurrencies(response.data.data);
                    // console.log("Set user types data", response.data.data);
                  } catch (error) {
                    console.error("Error fetching user types:", error);
                  }
                  };

                  const fetchLGEGreenCodes = async () => {
                      try {
                        const response = await UserService.getUsers(
                          `${process.env.REACT_APP_BASE_URL}/bam/get-LGE-green-code`
                        );
                        console.log("response. LGE data",response.data.data);
                        setLGEGreen(response.data.data);
                        // console.log("Set user types data", response.data.data);
                      } catch (error) {
                        console.error("Error fetching user types:", error);
                      }
                      };
              const fetchLGECodes = async () => {
                  try {
                    const response = await UserService.getUsers(
                      `${process.env.REACT_APP_BASE_URL}/bam/get-LGE-code`
                    );
                    console.log("response. LGE data",response.data.data);
                    setLGE(response.data.data);
                    // console.log("Set user types data", response.data.data);
                  } catch (error) {
                    console.error("Error fetching user types:", error);
                  }
                  };

                  const fetchCompanyGreenCodes = async () => {
                      try {
                        const response = await UserService.getUsers(
                          `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-green-code`
                        );
                        console.log("response. LGE data",response.data.data);
                        setCompaniesGreen(response.data.data);
                        // console.log("Set user types data", response.data.data);
                      } catch (error) {
                        console.error("Error fetching user types:", error);
                      }
                      };

                      const fetchCompanyGreenCodesAssignToLGE = async () => {
                        try {
                          const response = await UserService.getUsers(
                            `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-green-code-with-LGE`
                          );
                          console.log("response. company and LGE data",response.data.data);
                          setCompaniesGreenAssignToLGE(response.data.data);
                          // console.log("Set user types data", response.data.data);
                        } catch (error) {
                          console.error("Error fetching user types:", error);
                        }
                      };

                  const fetchCompanyCodes = async () => {
                      try {
                        const response = await UserService.getUsers(
                          `${process.env.REACT_APP_BASE_URL}/bam/get-all-Company-code`
                        );
                        console.log("response. LGE data",response.data.data);
                        setCompanies(response.data.data);
                        // console.log("Set user types data", response.data.data);
                      } catch (error) {
                        console.error("Error fetching user types:", error);
                      }
                      };

                      const fetchZBATypeCodes  = async () => {
                          try {
                            const response = await UserService.getUsers(
                              `${process.env.REACT_APP_BASE_URL}/bam/get-all-ZBA-Types-code`
                            );
                            console.log("response. ZBA types data",response.data.data);
                            setZbaTypes(response.data.data);
                            // console.log("Set user types data", response.data.data);
                          } catch (error) {
                            console.error("Error fetching user types:", error);
                          }
                          };

                          const fetchAccountClassification = async () => {
                              try {
                                  const response = await UserService.getUsers(
                                    `${process.env.REACT_APP_BASE_URL}/bam/get-all-Account-Classification-code`
                                  );
                                  console.log("response. Acc classification data",response.data.data);
                                  setAccClassifications(response.data.data);
                                  // console.log("Set user types data", response.data.data);
                                } catch (error) {
                                  console.error("Error fetching user types:", error);
                                }
                          };

                          const fetchStatementTypes = async () => {
                              try {
                                  const response = await UserService.getUsers(
                                    `${process.env.REACT_APP_BASE_URL}/bam/get-all-Statement-Types-code`
                                  );
                                  console.log("response. Acc classification data",response.data.data);
                                  setStatementTypes(response.data.data);
                                  // console.log("Set user types data", response.data.data);
                                } catch (error) {
                                  console.error("Error fetching user types:", error);
                                }
                          };

                          const fetchMainBankAccnts = async () => {
                              try {
                                  const response = await UserService.getUsers(
                                    `${process.env.REACT_APP_BASE_URL}/bam/get-all-Main-Bank-Accounts-code`
                                  );
                                  console.log("response. Main Bank aacount data",response.data.data);
                                  setMainBankAccounts(response.data.data);
                                  // console.log("Set user types data", response.data.data);
                                } catch (error) {
                                  console.error("Error fetching user types:", error);
                                }
                          };

                          const fetchMainBankAccntsGreen = async () => {
                              try {
                                  const response = await UserService.getUsers(
                                    `${process.env.REACT_APP_BASE_URL}/bam/get-all-Main-Bank-Accounts-green-code`
                                  );
                                  console.log("response. Acc classification data",response.data.data);
                                  setMainBankAccountsGreen(response.data.data);
                                  // console.log("Set user types data", response.data.data);
                                } catch (error) {
                                  console.error("Error fetching user types:", error);
                                }
                          };

                      const fetchAccountTypeCodes = async () => {
                          try {
                            const response = await UserService.getUsers(
                              `${process.env.REACT_APP_BASE_URL}/bam/get-all-Account-Types-code`
                            );
                            console.log("response. Account types data",response.data.data);
                            setAccountTypes(response.data.data);
                            // console.log("Set user types data", response.data.data);
                          } catch (error) {
                            console.error("Error fetching user types:", error);
                          }
                          };

                          const fetchIBANRequiredData = async () => {
                            try {
                                const response = await UserService.getUsers(
                                  `${process.env.REACT_APP_BASE_URL}/bam/get-IBAN-required-code`
                                );
                                console.log("response. iban Number data",response.data);
                                setibanRequired(response.data.data);
                                // console.log("Set user types data", response.data.data);
                              } catch (error) {
                                console.error("Error fetching user types:", error);
                              }
                              };
            
            
                          const fetchNewRequestNoData = async () => {
                            try {
                                const response = await UserService.getUsers(
                                  `${process.env.REACT_APP_BASE_URL}/bam/get-Account_detail-ReqNo-code`
                                );
                                console.log("response. Request Number data",response.data);
                                setNewRequestNo(response.data.data);
                                setNewRequestNoGreen(response.data.data2);
                                // console.log("Set user types data", response.data.data);
                              } catch (error) {
                                console.error("Error fetching user types:", error);
                              }
                              };

          const cancelDeletion = () => {
          setShowConfirmation(false);
          };
      
          const excelDateToJSDate = (excelDate) => {
            const startDate = new Date(Date.UTC(1900, 0, 1)); // Excel base date is 1900-01-01
            const correctedDate = new Date(startDate.getTime() + (excelDate - 2) * 24 * 60 * 60 * 1000); // Adjust for leap year bug
            return correctedDate;
          };

          
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
      
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0]; // Get first sheet
          const sheet = workbook.Sheets[sheetName];
      
          // Ensure we read only headers
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      
          console.log("Raw Sheet Data:", jsonData); // Debugging: Check the first row
      
          console.log("selectedFormat data is",selectedFormat);

          let expectedHeaders = [];

          if (selectedFormat === 'NewRequest') {
            expectedHeaders = allowedFormat;
          } else if (selectedFormat === 'AcctNoUpdate') {
            expectedHeaders = allowedFormat2;
          } else if (selectedFormat === 'Migration') {
            expectedHeaders = allowedFormat3;
          }

          if (expectedHeaders.length > 0) {
            const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
            const formattedExpectedHeaders = expectedHeaders.map(header => header.trim().toLowerCase());
            
            console.log("Uploaded Headers:", uploadedHeaders);
            console.log("Expected Headers:", formattedExpectedHeaders);
            
            const isValidFormat = formattedExpectedHeaders.every(header => uploadedHeaders.includes(header));
            
            if (isValidFormat) {
              const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);

              const formattedRows = rows.map(row => {
                const formattedRow = { ...row };

                if (formattedRow.ODDate && !isNaN(formattedRow.ODDate)) {
                  const date = excelDateToJSDate(formattedRow.ODDate);
                  formattedRow.ODDate = date.toISOString().split("T")[0];
                }
                return formattedRow;
              });
              
              console.log("Formatted Table Data:", formattedRows);
              setTableData(formattedRows);
              setButtonText("File Uploaded");
              setIsUploaded(true);
              setIsError(false);
            } else {
              setButtonText("File Format is Incorrect!");
              setIsUploaded(false);
              setIsError(true);
              setShowConfirmation(true);
            }
          }
      

        //   if (selectedFormat === 'NewRequest') {
        //   // Extract headers from the first row
        //   const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
        //   expectedHeaders = allowedFormat.map(header => header.trim().toLowerCase());
      
        //   console.log("Uploaded Headers:", uploadedHeaders);
        //   console.log("Expected Headers:", expectedHeaders);
      
        //   // Validate headers
        //   const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));
      
        //   if (isValidFormat) {
        //     // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
        //     const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);
      
        //   // Convert Excel date serial numbers to readable dates
        //   const formattedRows = rows.map((row) => {
        //     const formattedRow = { ...row };
      
        //     if (formattedRow.ODDate && !isNaN(formattedRow.ODDate)) {
        //       const date = excelDateToJSDate(formattedRow.ODDate);
        //       formattedRow.ODDate = date.toISOString().split("T")[0]; // Format: YYYY-MM-DD
        //     }
        
        //     return formattedRow;
        //   });
        
      
        //   console.log("set Table Data:", formattedRows);
      
        //   setTableData(formattedRows);
        //     setButtonText("File Uploaded");
        //     setIsUploaded(true);
        //     setIsError(false);
        //   } 

        //   else {
        //     setButtonText("File Format is Incorrect !");
        //     setIsUploaded(false);
        //     setIsError(true);
        //     setShowConfirmation(true);
        //   }

        // } 
          
        // if(selectedFormat === 'AcctNoUpdate') {
          
        //    // Extract headers from the first row
        //    const uploadedHeaders = jsonData[0].map(header => header.trim().toLowerCase());
        //    expectedHeaders = allowedFormat2.map(header => header.trim().toLowerCase());
       
        //    console.log("Uploaded Headers:", uploadedHeaders);
        //    console.log("Expected Headers:", expectedHeaders);
       
        //    // Validate headers
        //    const isValidFormat = expectedHeaders.every(header => uploadedHeaders.includes(header));
       
        //    if (isValidFormat) {
        //      // setTableData(XLSX.utils.sheet_to_json(sheet)); // Convert to JSON properly
        //      const rows = XLSX.utils.sheet_to_json(sheet, { raw: true }).slice(2);
       
        //    // Convert Excel date serial numbers to readable dates
        //    const formattedRows = rows.map((row) => {
        //      const formattedRow = { ...row };
         
        //      return formattedRow;
        //    });
         
       
        //    console.log("set ACCOUNT no update Table Data:", formattedRows);
       
        //    setTableData(formattedRows);
        //      setButtonText("File Uploaded");
        //      setIsUploaded(true);
        //      setIsError(false);
        //    } 

        //    else {
        //     setButtonText("File Format is Incorrect !");
        //     setIsUploaded(false);
        //     setIsError(true);
        //     setShowConfirmation(true);
        //   }
        // }


          
      
          setTimeout(() => setIsUploaded(false), 1000);
        };
        reader.readAsArrayBuffer(file);
      };
                
      
          const confirmDeletion = async () => {
            console.log("Download the excel sheet");
            // Simulate the download by creating an anchor element
            let fileUrl = null;
            if(selectedFormat === 'AcctNoUpdate'){
              fileUrl = "/BAM_Account_No_Update_Upload.xlsx"; 
            }else if(selectedFormat === 'NewRequest') {
              fileUrl = "/BAM_New_Request_Upload.xlsx"; 
            }
            else if (selectedFormat === 'Migration'){
              fileUrl="/BAM_Migration_Upload.xlsx";
            }
            console.log("file ur is",fileUrl);
            const anchor = document.createElement("a");
            anchor.href = fileUrl;
            anchor.download = "BAM_New_Request_Upload.xlsx"; // Set the file name for download
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          }
      
          
      const isRowValid = (row, index) => {
        // Common validations
        const isActionValid = row.Action && ["A", "a"].includes(row.Action) && String(row.Action).length === 1;
        const isSLNoValid = row.SLNo && !isNaN(row.SLNo) && String(row.SLNo).length <= 5;
        const isBankBranchCodeValid = row.BankBranch && String(row.BankBranch).length <= 10;
        
        let errors = [];
        
        if (!isActionValid) errors.push("Invalid Action");
        if (!isSLNoValid) errors.push("Invalid SLNo");
        if (!isBankBranchCodeValid) errors.push("Invalid BankBranch");
      
        // Action "A" or "a" specific validations
        if (row.Action === "A" || row.Action === "a") {
          const isCurrencyCodeValid = row.CurrencyCode && String(row.CurrencyCode).length <= 4;
          const isNoteCodeValid = row.Note ? String(row.Note).length <= 100 : true;
          const isLGECodeCodeValid =row.LGECode && String(row.LGECode).trim() !== '' && String(row.LGECode).length <= 10;
          // const isLGECodeCodeValid = row.LGECode && String(row.LGECode).length <= 10;
          const isCompanyCodeCodeValid = row.CompanyCode ? String(row.CompanyCode).length <= 10 : true;
          const isAccountTypeCodeValid = row.AccountType ? String(row.AccountType).length <= 4 : true;
          const isZBATypeCodeValid = row.ZBAType ? String(row.ZBAType).length <= 4 : true;
          const isAccountClassificationCodeValid = row.AccountClassification ? String(row.AccountClassification).length <= 4 : true;
          const isMinimumBalanceCodeValid = row.MinimumBalance ? String(row.MinimumBalance).length <= 20 : true;
          const isDormantCodeValid = row.Dormant ? String(row.Dormant).length <= 2 : true;
          const isText1CodeValid = row.Text1 ? String(row.Text1).length <= 50 : true;
          const isText2CodeValid = row.Text2 ? String(row.Text2).length <= 50 : true;
          const isText3CodeValid = row.Text3 ? String(row.Text3).length <= 50 : true;
          const isText4CodeValid = row.Text4 ? String(row.Text4).length <= 50 : true;
          const isText5CodeValid = row.Text5 ? String(row.Text5).length <= 50 : true;
         
          if (!isCurrencyCodeValid) errors.push("Invalid A Currency Code");
          if (!isNoteCodeValid) errors.push("Invalid Note");
          if (!isLGECodeCodeValid) errors.push("Invalid LGECode");
          if (!isCompanyCodeCodeValid) errors.push("Invalid CompanyCode");
          if (!isAccountTypeCodeValid) errors.push("Invalid AccountType");
          if (!isZBATypeCodeValid) errors.push("Invalid ZBAType");
          if (!isAccountClassificationCodeValid) errors.push("Invalid AccountClassification");
          if (!isMinimumBalanceCodeValid) errors.push("Invalid MinimumBalance");
          if (!isDormantCodeValid) errors.push("Invalid Dormant");
          if (!isText1CodeValid) errors.push("Invalid Text1");
          if (!isText2CodeValid) errors.push("Invalid Text2");
          if (!isText3CodeValid) errors.push("Invalid Text3");
          if (!isText4CodeValid) errors.push("Invalid Text4");
          if (!isText5CodeValid) errors.push("Invalid Text5");

          if(String(row.Overdraft).trim() === 'Yes'){
            const isODLimitValid = row.ODLimit && String(row.ODLimit).length <= 20;
            const isInterestIndicatorValid = row.InterestIndicator && String(row.InterestIndicator).length <= 2;
            const isODDateValid = row.ODDate && String(row.ODDate).length <= 10;
          
            if (!isODLimitValid) errors.push("Invalid ODLimit");
            if (!isInterestIndicatorValid) errors.push("Invalid InterestIndicator");
            if (!isODDateValid) errors.push("Invalid ODDate");
            
            if (errors.length > 0) {
              console.log(`Row ${index + 1} is invalid:`, errors);
            }
            
            return (
              isODLimitValid &&
              isInterestIndicatorValid &&
              isODDateValid

          );
          }

          const isZBATypeExists = row.ZBAType && String(row.ZBAType).trim() !== '';

          if(isZBATypeExists){
            const isMainBankAccountValid = row.MainBankAccount && String(row.MainBankAccount).length <= 13;
            
            if (!isMainBankAccountValid) errors.push("Invalid MainBankAccount");
            
            if (errors.length > 0) {
              console.log(`Row ${index + 1} is invalid:`, errors);
            }

            return(
              isMainBankAccountValid
            );
         }
         

          if (errors.length > 0) {
            console.log(`Row ${index + 1} is invalid:`, errors);
        }
        
          return (
          isCurrencyCodeValid  &&
          isNoteCodeValid  &&
          isLGECodeCodeValid  &&
          isCompanyCodeCodeValid  &&
          isAccountTypeCodeValid  &&
          isZBATypeCodeValid  &&
          isAccountClassificationCodeValid  &&
          isMinimumBalanceCodeValid  &&
          isDormantCodeValid  &&
          isText1CodeValid  &&
          isText2CodeValid  &&
          isText3CodeValid  &&
          isText4CodeValid  &&
          isText5CodeValid  
          );
        }
        
        if (errors.length > 0) {
          console.log(`Rows data ${index + 1} is invalid:`, errors);
      }
  
        // Default case if no matching Action
        return (
          isActionValid &&
          isBankBranchCodeValid  &&
          isSLNoValid
        );
      
      };

      const getExistingUserIds = async (tableData) => {
        try {
          console.log("get all datas", tableData);
    
          const validRows = tableData.filter((row, index) =>  isRowValid(row, index));
          console.log("valid rows checking in upload:", validRows);
            
          
           // Make API call to fetch existing Bank Branch
           const response1 = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/bam/get-BankBranch-duplicate/BankBranchCode`,
            { validRows }
          );
          console.log("bankBranch id's : Response data", response1.data);
          const existingBankBranch = response1.data; // Extract existing user IDs from API response
    
          const withoutDuplicateBankBranch = await filterExistingBankBranchIds(validRows, existingBankBranch);
    console.log("Without duplicate for bank branch",withoutDuplicateBankBranch);


    
          // Make API call to fetch existing user IDs
          const response2 = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/bam/get-currency-code-duplicate/currencyCode`,
            { validRows: withoutDuplicateBankBranch }
          );
          console.log("errored records user id's : Response data", response2.data);
          const existingCurrencyIds = response2.data; // Extract existing user IDs from API response
    
          const withoutDuplicateCurrency = await filterExistingCurrencys(withoutDuplicateBankBranch, existingCurrencyIds);
    
          console.log("Currency and processed Records data:", withoutDuplicateCurrency);
    

           // Make API call to fetch existing user IDs
           const response3 = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/get-LEGreen-duplicate/LEId`,
            { validRows: withoutDuplicateBankBranch }
          );
          console.log("errored records user id's : Response data", response3.data);
          const existingLGEIDs = response3.data; // Extract existing user IDs from API response
    
          const withoutDuplicateLGEIDs = await filterExistingLGEIDs(withoutDuplicateBankBranch, existingLGEIDs);
    
          console.log("successful and processed Records data:", withoutDuplicateLGEIDs);



          return withoutDuplicateLGEIDs;
    
        } catch (error) {
          console.error("Error fetching existing user IDs:", error);
          return [];
        }
      };
    
    
      const filterExistingLGEIDs = async (validRows, existingLGEIDs) => {
        return validRows.filter((row) => existingLGEIDs.includes(row.LGECode));
      };

      const filterExistingCurrencys = async (withoutDuplicateBankBranch, existingCurrencyIds) => {
        return withoutDuplicateBankBranch.filter((row) => existingCurrencyIds.includes(row.CurrencyCode));
      };
      const filterExistingBankBranchIds = async (validRows, existingBankBranch) => {
        return validRows.filter((row) => existingBankBranch.includes(row.BankBranch));
      };

      const getDataToDisplay = async ( uploadOption, tableData) => {
    
        let dataToDisplay;
        if (uploadOption === "3") {
        console.log("displayOption is 3");
        dataToDisplay = await getExistingUserIds(tableData); 
        } else {
        dataToDisplay = tableData; // Default to tableData if no conditions are met
        }
        
      console.log("dataToDisplay data is",dataToDisplay);
      return dataToDisplay;
    };

    

    const processData = async (data) => {
      // Extract all userIds from dataToDisplay
      
      if (!Array.isArray(data)) {
        console.error("processData received invalid data:", data);
        return []; // Return an empty array to prevent further errors
      }
      console.log("processData received  data:", data);
    
      //  return data.map((row, _, allRows) => {
      return Promise.all(data.map(async (row, _, allRows) => {
                             
     const BankBranchExists = bankBranches
     .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.BankBranch).trim().toUpperCase()); 


     const BankBranchGreenExists = bankBranchesGreen
     .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.BankBranch).trim().toUpperCase()); // Normalize row.CountryCode

      const CurrencyExists = currencies
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.CurrencyCode).trim().toUpperCase()); 


      
     const LGEIDExists = LGE
     .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
     .includes(String(row.LGECode).trim().toUpperCase()); // Normalize row.CountryCode

      const LGEIDGreenExists = LGEGreen
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.LGECode).trim().toUpperCase()); // Normalize row.CountryCode

      const CompanyExists = companies
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.CompanyCode).trim().toUpperCase()); // Normalize row.CountryCode

      const CompanyGreenExists = companiesGreen
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.CompanyCode).trim().toUpperCase()); // Normalize row.CountryCode

      // const companiesGreenAssignToLGEExists = companiesGreenAssignToLGE
      // .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      // .includes(String(row.CompanyCode).trim().toUpperCase()); // Normalize row.CountryCode

      const companiesGreenAssignToLGEExists = companiesGreenAssignToLGE.some(
        (item) => 
          String(item.companyCode).trim().toUpperCase() === String(row.CompanyCode).trim().toUpperCase() &&
          String(item.legalEntityCode).trim().toUpperCase() === String(row.LGECode).trim().toUpperCase()
      );                        

      const AccountTypeExists = accountTypes
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.AccountType).trim().toUpperCase()); // Normalize row.CountryCode

      const zbaTypesExists =zbaTypes
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.ZBAType).trim().toUpperCase());

      const accClassificationsExists = accClassifications
      .map(item => String(item).trim().toUpperCase() )
      .includes(String(row.AccountClassification).trim().toUpperCase());

      const StatementTypesExists = statementTypes
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.StatementType).trim().toUpperCase());

      const MainBankAccountsExists= mainBankAccounts
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.MainBankAccount).trim().toUpperCase());

      const MainBankAccountGreenExists = mainBankAccountsGreen
      .map(code => String(code).trim().toUpperCase()) // Normalize BankBranchs
      .includes(String(row.MainBankAccount).trim().toUpperCase());

     console.log(`BankBranch id ${row.BankBranch} exists:`, BankBranchGreenExists);
     console.log(`Bank id ${row.BankId} exists:`, LGEIDExists);

     // Check for errors (red text) and apply highlight based on that
    
    const hasNotError = [
      row.SLNo,
      row.Action,
      row.BankBranch,
      row.CurrencyCode,
      row.LGECode,
      row.CompanyCode,
      row.AccountType,
      row.ZBAType,
      row.AccountClassification,
      row.StatementType,

    ].every(field => {
      let isValid = true;
      let reason = "";
      
      if (field === row.SLNo) {
        if (!field) { isValid = false; reason = "SLNo should not be blank."; }
        else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
        else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
      }
      
      else if (field === row.Action) {
        if (!field) { isValid = false; reason = "Action should not be blank."; }
        else if (String(field).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
      }
      

    if ((!row.BankBranch || String(row.BankBranch).trim() === "") && (row.Action === "A" || row.Action === "a")) {
        isValid = false;
        reason = "BankBranch should not be empty.";
    } else if (field === row.BankBranch) {
        if (String(field).trim().length > 10) {
            isValid = false;
            reason = "BankBranch exceeds max length of 10.";
        }

          if(BankBranchExists){
              if(!BankBranchGreenExists){
                  isValid = false;
                  reason = "BankBranch is not exists because of the flag status"
              }
          }
          else{
              isValid = false;
              reason = "BankBranch is not exists"
          }
      }
    

      if ((!row.CurrencyCode || String(row.CurrencyCode).trim() === "") && (row.Action === "A" || row.Action === "a")) {
          isValid = false;
          reason = "CurrencyCode should not be empty.";
      } else if (field === row.CurrencyCode) {
            if (String(field).trim().length > 10) {
                isValid = false;
                reason = "CurrencyCode exceeds max length of 10.";
            }
            if(!CurrencyExists){
              isValid = false;
              reason = "CurrencyCode is not exists"
          }
        }

        if ((!row.LGECode || String(row.LGECode).trim() === "") && (row.Action === "A" || row.Action === "a")) {
          isValid = false;
          reason = "LGECode should not be empty.";
      } else if (field === row.LGECode) {
          if (String(field).trim().length > 10) {
              isValid = false;
              reason = "LGECode exceeds max length of 10.";
          }

            if(LGEIDExists){
                if(!LGEIDGreenExists){
                    isValid = false;
                    reason = "LGECode is not exists because of the flag status"
                }
            }
            else{
                isValid = false;
                reason = "LGECode is not exists"
            }
        }
      

        if(row.CompanyCode){
        if(field === row.CompanyCode){
          if (String(field).trim().length > 10) {
              isValid = false;
              reason = "CompanyCode exceeds max length of 10.";
          }

          if(CompanyExists){
              if(CompanyGreenExists){
                if(!companiesGreenAssignToLGEExists){
                  isValid = false;
                  reason = "CompanyCode is not exists because of the LGE and company missmatched"
              }
              }
              else{
                isValid = false;
                reason = "CompanyCode is not exists because of the flag status"
            }
          }
          else{
              isValid = false;
              reason = "CompanyCode is not exists"
          }
        }
      }

      if(row.AccountType){
        if(field === row.AccountType){
          if (String(field).trim().length > 4) {
              isValid = false;
              reason = "AccountType exceeds max length of 4.";
          }

          if(!AccountTypeExists){
              isValid = false;
              reason = "AccountType is not exists"
          }
        }
      }

          if(row.ZBAType){
              if(field === row.ZBAType){
                  if (String(field).trim().length > 4) {
                      isValid = false;
                      reason = "ZBAType exceeds max length of 4.";
                  }

                  if(!zbaTypesExists){
                      isValid = false;
                      reason = "ZBAType is not exists"
                  }
              }
          }


          if( (!row.MainBankAccount || String(row.MainBankAccount).trim() === '') && 
          (row.Action === "A" || row.Action === "a") &&
          (row.ZBAType && String(row.ZBAType).trim() !== '')) 
          {
              isValid = false;
              reason = "MainBankAccount should not be empty.";
          }
          else if (row.MainBankAccount) {
                if (String(row.MainBankAccount).trim().length > 13) {
                  isValid = false;
                  reason = "MainBankAccount exceeds max length of 13.";
                } 
                 if (!MainBankAccountsExists) {
                  isValid = false;
                  reason = "MainBankAccount does not exist.";
                }
                 else if (!MainBankAccountGreenExists) {
                  isValid = false;
                  reason = "MainBankAccount is not Approved.";
                }
              }
         

            

        if(row.AccountClassification){
          if(field === row.AccountClassification){
              if (String(field).trim().length > 4) {
                  isValid = false;
                  reason = "AccountClassification exceeds max length of 4.";
              }

              if(!accClassificationsExists){
                  isValid = false;
                  reason = "AccountClassification is not exists"
              }
            }
        }

        if(row.StatementType){
              if(!StatementTypesExists){
                  isValid = false;
                  reason = "StatementType is not exists"
              }
        }

        if(row.Overdraft && String(row.Overdraft).trim() === "Yes"){

          if (!row.ODLimit || String(row.ODLimit).trim() === ""){
              isValid = false;
              reason = "ODLimit should not be empty.";
          } else{
              if(field === row.ODLimit){
                  if (String(field).trim().length > 20) {
                      isValid = false;
                      reason = "ODLimit exceeds max length of 20.";
                  }
              }
          }

          if (!row.InterestIndicator || String(row.InterestIndicator).trim() === ""){
              isValid = false;
              reason = "InterestIndicator should not be empty.";
          } else{
              if(field === row.InterestIndicator){
                  if (String(field).trim().length > 2) {
                      isValid = false;
                      reason = "InterestIndicator exceeds max length of 2.";
                  }
              }
          }

          if(!row.ODDate || String(row.ODDate).trim() === ""){
              isValid = false;
              reason = "ODDate should not be empty.";
          } else{
              if(field === row.ODDate){
                  if (String(field).trim().length > 10) {
                      isValid = false;
                      reason = "ODDate exceeds max length of 10.";
                  }
              }
          }
      }

      if(row.MinimumBalance){
          if(field === row.MinimumBalance){
              if (String(field).length > 20) { isValid = false; reason = "MinimumBalance exceeds max length of 20."; }
          }
      }

      if(row.Dormant){
          if(field === row.Dormant){
              if (String(field).length > 2) { isValid = false; reason = "Dormant exceeds max length of 2."; }
          }
      }

      if(row.Text1){
          if(field === row.Text1){
              if (String(field).length > 50) { isValid = false; reason = "Text1 exceeds max length of 50."; }
          }
      }
      if(row.Text2){
          if(field === row.Text2){
              if (String(field).length > 50) { isValid = false; reason = "Text2 exceeds max length of 50."; }
          }
      }if(row.Text3){
          if(field === row.Text3){
              if (String(field).length > 50) { isValid = false; reason = "Text3 exceeds max length of 50."; }
          }
      }if(row.Text4){
          if(field === row.Text4){
              if (String(field).length > 50) { isValid = false; reason = "Text4 exceeds max length of 50."; }
          }
      }if(row.Text5){
          if(field === row.Text5){
              if (String(field).length > 50) { isValid = false; reason = "Text5 exceeds max length of 50."; }
          }
      }
      if (!isValid) {
        console.log(`Validation Failed: ${reason}`);
      } else {
        console.log(`Validation Passed: ${field}`);
      }
      
      return isValid;
    });
    
    console.log(`Final hasNotError status: ${hasNotError}`);
    
        return {
          ...row,
          successfulrecords: hasNotError, // Mark as successful if no error

    
          Action: row.Action ? (
            String(row.Action).length === 1 ? (
              ["A", "a", "E", "e"].includes(row.Action) ? (
                row.Action // Valid Action ("A", "E", "a", "e")
              ) : (
                <span style={{ color: "red" }}>
                  {row.Action} (Only "A", "E" are allowed)
                </span>
              )
            ) : (
              <span style={{ color: "red" }}>
                {row.Action} (Max. allowed character is 1)
              </span>
            )
          ) : (
            <span style={{ color: "red" }}>Shouldn't be blank</span>
          ),
    
          SLNo: row.SLNo ? (
              isNaN(row.SLNo) ? (
                <span style={{ color: "red"}}>
                  {row.SLNo} (Mismatched Data Type)
                </span>
              ) : String(row.SLNo).length > 5 ? (
                <span style={{ color: "red"}}>
                  {row.SLNo} (Max. allowed character is 5)
                </span>
              ) : (
                row.SLNo // Valid SLNo
              )
            ) : (
              <span style={{ color: "red"}}>Shouldn't be blank</span>
            ),
     
            BankBranch: row.BankBranch ? (
              String(row.BankBranch).length > 10 ? (
                <span style={{ color: "red" }}>
                  {row.BankBranch} (Max. allowed characters: 10)
                </span>
              ) : BankBranchExists && displayOption !== "" ? (
                  BankBranchGreenExists ? (
                  <span>{row.BankBranch}</span>
                ) : (
                  <span style={{ color: "red" }}>
                    {row.BankBranch} (Bank Branch is not approved.)
                  </span>
                )
              ) :(
                <span style={{ color: "red" }}>
                {row.BankBranch} (Bank Branch not exists.)
              </span>
              ) )
              :
              (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
              ),
    
              CurrencyCode: row.CurrencyCode ? (
                  String(row.CurrencyCode).length > 10 ? (
                    <span style={{ color: "red" }}>
                      {row.CurrencyCode} (Max. allowed characters: 10)
                    </span>
                  ) : CurrencyExists && displayOption !== "" ? (
                      <span>{row.CurrencyCode}</span>
                  ) :(
                    <span style={{ color: "red" }}>
                    {row.CurrencyCode} (Currency Code not exists.)
                  </span>
                  ) )
                  :
                  (
                      <span style={{ color: "red" }}>
                        Shouldn't be blank
                      </span>
                  ),
        
                  LGECode: row.LGECode ? (
                      String(row.LGECode).length > 10 ? (
                        <span style={{ color: "red" }}>
                          {row.LGECode} (Max. allowed characters: 10)
                        </span>
                      ) : LGEIDExists && displayOption !== "" ? (
                          LGEIDGreenExists ? (
                          <span>{row.LGECode}</span>
                        ) : (
                          <span style={{ color: "red" }}>
                            {row.LGECode} (LGE is not approved.)
                          </span>
                        )
                      ) :(
                        <span style={{ color: "red" }}>
                        {row.LGECode} (LGE not exists.)
                      </span>
                      ) )
                      :
                      (
                          <span style={{ color: "red" }}>
                            Shouldn't be blank
                          </span>
                      ),
             
                      CompanyCode: row.CompanyCode ? (
                          String(row.CompanyCode).length > 10 ? (
                            <span style={{ color: "red" }}>
                              {row.CompanyCode} (Max. allowed characters: 10)
                            </span>
                          ) : CompanyExists && displayOption !== "" ? (
                              CompanyGreenExists ? (
                                companiesGreenAssignToLGEExists ? (
                              <span>{row.CompanyCode}</span>
                            ): (
                              <span style={{ color: "red" }}>
                                {row.CompanyCode} (Company Code is missmatched.)
                              </span>
                            )
                          )  : (
                            <span style={{ color: "red" }}>
                              {row.CompanyCode} (Company is not approved.)
                            </span>
                          ) )
                          :(
                            <span style={{ color: "red" }}>
                            {row.CompanyCode} (Company not exists.)
                          </span>
                          ) )
                          :
                          (
                              <span>
                              
                              </span>
                          ),

                          AccountType: row.AccountType ? (
                              String(row.AccountType).length > 4 ? (
                                <span style={{ color: "red" }}>
                                  {row.AccountType} (Max. allowed characters: 4)
                                </span>
                              ) : AccountTypeExists && displayOption !== "" ? (
                                <span>{row.AccountType}</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {row.AccountType} (AccountType not exists.)
                                </span>
                              )
                            ) : (
                              <span></span>
                            ),

                            ZBAType: row.ZBAType ? (
                              String(row.ZBAType).length > 4 ? (
                                <span style={{ color: "red" }}>
                                  {row.ZBAType} (Max. allowed characters: 4)
                                </span>
                              ) : zbaTypesExists && displayOption !== "" ? (
                                <span>{row.ZBAType}</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {row.ZBAType} (ZBAType not exists.)
                                </span>
                              )
                            ) : (
                              <span></span>
                            ),

                            AccountClassification: row.AccountClassification ? (
                              String(row.AccountClassification).length > 4 ? (
                                <span style={{ color: "red" }}>
                                  {row.AccountClassification} (Max. allowed characters: 4)
                                </span>
                              ) : accClassificationsExists && displayOption !== "" ? (
                                <span>{row.AccountClassification}</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {row.AccountClassification} (AccountClassification not exists.)
                                </span>
                              )
                            ) : (
                              <span></span>
                            ),


                            StatementType: row.StatementType ? (
                               StatementTypesExists && displayOption !== "" ? (
                                <span>{row.StatementType}</span>
                              ) : (
                                <span style={{ color: "red" }}>
                                  {row.StatementType} (StatementType not exists.)
                                </span>
                              )
                            ) : (
                              <span></span>
                            ),
                            

                            ODLimit: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                              row.ODLimit ? (
                                  String(row.ODLimit).length > 20 ? (
                                      <span style={{ color: "red"}}>
                                          {row.ODLimit} (Max. allowed character is 20)
                                      </span>
                                  ) : (
                                      String(row.ODLimit)
                                  )
                              ) : (
                                  <span style={{ color: "red" }}>
                                      Shouldn't be blank
                                  </span>
                              )
      
                          ) : (
                              row.ODLimit ? (
                                  String(row.ODLimit).length > 20 ? (
                                      <span style={{ color: "red"}}>
                                          {row.ODLimit} (Max. allowed character is 20)
                                      </span>
                                  ) : (
                                      String(row.ODLimit)
                                  )
                              ): (
                                  <span>
                                  </span>
                              ) ),
                          

                              InterestIndicator: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                                  row.InterestIndicator ? (
                                      String(row.InterestIndicator).length > 2 ? (
                                          <span style={{ color: "red"}}>
                                              {row.InterestIndicator} (Max. allowed character is 2)
                                          </span>
                                      ) : (
                                          String(row.InterestIndicator)
                                      )
                                  ) : (
                                      <span style={{ color: "red" }}>
                                          Shouldn't be blank
                                      </span>
                                  )
          
                              ) : (
                                  row.InterestIndicator ? (
                                      String(row.InterestIndicator).length > 2 ? (
                                          <span style={{ color: "red"}}>
                                              {row.InterestIndicator} (Max. allowed character is 2)
                                          </span>
                                      ) : (
                                          String(row.InterestIndicator)
                                      )
                                  ): (
                                      <span>
                                      </span>
                                  ) ),
                              
                                  ODDate: row.Overdraft && String(row.Overdraft).trim() === "Yes" ? (
                                      row.ODDate ? (
                                          String(row.ODDate).length > 10 ? (
                                              <span style={{ color: "red"}}>
                                                  {row.ODDate} (Max. allowed character is 10)
                                              </span>
                                          ) : (
                                              String(row.ODDate)
                                          )
                                      ) : (
                                          <span style={{ color: "red" }}>
                                              Shouldn't be blank
                                          </span>
                                      )
                                  ) : (
                                      row.ODDate ? (
                                          String(row.ODDate).length > 10 ? (
                                              <span style={{ color: "red"}}>
                                                  {row.ODDate} (Max. allowed character is 10)
                                              </span>
                                          ) : (
                                              String(row.ODDate)
                                          )
                                      ): (
                                          <span>
                                          </span>
                                      ) ),

                                      MinimumBalance: row.MinimumBalance ? (
                                          String(row.MinimumBalance).length > 20 ? (
                                            <span style={{ color: "red" }}>
                                              {row.MinimumBalance} (Max. allowed characters: 20)
                                            </span>
                                          ) :(
                                               <span>{row.MinimumBalance}</span>
                                          ) 
                                        ) : (
                                          <span></span>
                                        ),

                                        MainBankAccount: row.ZBAType && String(row.ZBAType).trim() !== "" ? (
                                          row.MainBankAccount ? (
                                              String(row.MainBankAccount).length > 13 ? (
                                                  <span style={{ color: "red"}}>
                                                      {row.MainBankAccount} (Max. allowed character is 13)
                                                  </span>
                                              ) : MainBankAccountsExists && displayOption !== "" ? (
                                                  MainBankAccountGreenExists ? (
                                                      String(row.MainBankAccount)
                                                  ) : (
                                                      <span style={{ color: "red" }}>
                                                        {row.MainBankAccount} (Main Bank Account is not approved.)
                                                      </span>
                                                    )  
                                                  ) : (
                                                      <span style={{ color: "red" }}>
                                                      {row.MainBankAccount} (Main Bank Account not exists.)
                                                    </span>
                                              )

                                          ) : (
                                              <span style={{ color: "red" }}>
                                                  Shouldn't be blank
                                              </span>
                                          )
                                        ):(
                                          row.MainBankAccount ? (
                                              String(row.MainBankAccount).length > 13 ? (
                                                  <span style={{ color: "red"}}>
                                                      {row.MainBankAccount} (Max. allowed character is 13)
                                                  </span>
                                              ) : (
                                                   MainBankAccountsExists && displayOption !== "" ? (
                                                  MainBankAccountGreenExists ? (
                                                      String(row.MainBankAccount)
                                                  ) : (
                                                      <span style={{ color: "red" }}>
                                                        {row.MainBankAccount} (Main Bank Account is not approved.)
                                                      </span>
                                                    )  
                                                  ) : (
                                                      <span style={{ color: "red" }}>
                                                      {row.MainBankAccount} (Main Bank Account not exists.)
                                                    </span>
                                              )
                                              )
                                          ) : (
                                              <span>
                                              </span>
                                          ) 
                                        ),


                                        Dormant: row.Dormant ? (
                                          String(row.Dormant).length > 2 ? (
                                            <span style={{ color: "red" }}>
                                              {row.Dormant} (Max. allowed characters: 2)
                                            </span>
                                          ) :(
                                               <span>{row.Dormant}</span>
                                          ) 
                                        ) : (
                                          <span></span>
                                        ),

                                        Text1: row.Text1 ? (
                                          String(row.Text1).length > 50 ? (
                                            <span style={{ color: "red" }}>
                                              {row.Text1} (Max. allowed characters: 50)
                                            </span>
                                          ) :(
                                               <span>{row.Text1}</span>
                                          ) 
                                        ) : (
                                          <span></span>
                                        ),

                                        Text2: row.Text2 ? (
                                          String(row.Text2).length > 50 ? (
                                            <span style={{ color: "red" }}>
                                              {row.Text2} (Max. allowed characters: 50)
                                            </span>
                                          ) :(
                                               <span>{row.Text2}</span>
                                          ) 
                                        ) : (
                                          <span></span>
                                        ),

                                        Text3: row.Text3 ? (
                                          String(row.Text3).length > 50 ? (
                                            <span style={{ color: "red" }}>
                                              {row.Text3} (Max. allowed characters: 50)
                                            </span>
                                          ) :(
                                               <span>{row.Text3}</span>
                                          ) 
                                        ) : (
                                          <span></span>
                                        ),

                                        Text4: row.Text4 ? (
                                          String(row.Text4).length > 50 ? (
                                            <span style={{ color: "red" }}>
                                              {row.Text4} (Max. allowed characters: 50)
                                            </span>
                                          ) :(
                                               <span>{row.Text4}</span>
                                          ) 
                                        ) : (
                                          <span></span>
                                        ),

                                        Text5: row.Text5 ? (
                                          String(row.Text5).length > 50 ? (
                                            <span style={{ color: "red" }}>
                                              {row.Text5} (Max. allowed characters: 50)
                                            </span>
                                          ) :(
                                               <span>{row.Text5}</span>
                                          ) 
                                        ) : (
                                          <span></span>
                                        ),

          };  
      }));
    }

      const storeArrayDataForUsers= async () => {
        setLoading(true);
        try {
          const dataToDisplay = await getDataToDisplay(uploadOption, tableData);
        console.log("store successfulRecords data 2 is", dataToDisplay);
    
        let processed=[];

        if(bankBranchesGreen && bankBranches && currencies && LGE && LGEGreen && companies && companiesGreen && accountTypes && zbaTypes && accClassifications && statementTypes && mainBankAccounts && mainBankAccountsGreen) {
          processed = await processData(tableData);
        }
        console.log("display datas",processed);
    
          const extractText = (cellValue) => {
                  if (Array.isArray(cellValue)) {
                    return cellValue.join(", "); // Convert array to string
                  } else if (typeof cellValue === "string") {
                    return cellValue;
                  } else if (React.isValidElement(cellValue)) {
                    return cellValue.props.children;
                  }
                  return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                };
    
                const formattedUsers = processed.map((user) => {
    
                  return {
                  "Action": extractText(user.Action),
                  "SLNo": extractText(user.SLNo),
                  "BankBranch":extractText(user.BankBranch),
                  "CurrencyCode": extractText(user.CurrencyCode),
                  "Note": extractText(user.Note),
                  "LGECode":extractText(user.LGECode),
                  "CompanyCode":extractText(user.CompanyCode),
                  "AccountType":extractText(user.AccountType),
                  "ZBAType":extractText(user.ZBAType),
                  "AccountClassification":extractText(user.AccountClassification),
                  "Overdraft":extractText(user.Overdraft),
                  "StatementType":extractText(user.StatementType),
                  "ODLimit":extractText(user.ODLimit),
                  "InterestIndicator":extractText(user.InterestIndicator),
                  "ODDate":extractText(user.ODDate),
                  "MinimumBalance":extractText(user.MinimumBalance),
                  "Dormant":extractText(user.Dormant),
                  "MainBankAccount":extractText(user.MainBankAccount),
                  "Text1":extractText(user.Text1),
                  "Text2":extractText(user.Text2),
                  "Text3":extractText(user.Text3),
                  "Text4":extractText(user.Text4),
                  "Text5":extractText(user.Text5),
                                   
                };
        });
          
            // Insert second and third row at the beginning of the formatted data
            const rowsToDisplay = [ ...formattedUsers];
    
            console.log("Formatted USERS Data:", formattedUsers);
            console.log("Formatted Data:", rowsToDisplay);
    
            
            const updatedData = rowsToDisplay.map(user => {
    
              const isObjectField = ["Action",
              "SLNo",
              "CurrencyCode",
              "BankBranch",
              "Note",
              "LGECode",
              "CompanyCode",
              "AccountType",
              "ZBAType",
              "AccountClassification",
              "Overdraft",
              "StatementType",
              "ODLimit",
              "InterestIndicator",
              "ODDate",
              "MinimumBalance",
              "Dormant",
              "MainBankAccount",
              "Text1",
              "Text2",
              "Text3",
              "Text4",
              "Text5",

    ].some(field => typeof user[field] === 'object' || user[field] === "Shouldn't be blank");
    
              
              return {
                // update: typeof user.UserID === 'object' ? "No" : "Yes",
                update: isObjectField ? "No" : "Yes",
                      ...user,
                    };
                });
    console.log("updatedData data",updatedData);
    
    
          const data = {
            clientId: assignee.clientId,
            dataToDisplay,
            createdBy: assignee.userId,
            allrecords: updatedData,
          }
          console.log("storeArrayDataForUsers dataToDisplay is",data);
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/bam/store-array-BAM-request-data`,
            data
          );
          console.log("Roles Response data", response);
    
    
          if (dataToDisplay.length === 0) { 
            // toast("error", "Please upload the correct file OR containing the data.");
            toast("error", "Error fields exist.");
            return;
        }
        else{
          if (response.status === 200) {
            toast("success", "Uploaded BAM New Request successfully");
          }
          else if(response.status === 400){
            toast("error", "Invalid or empty array of Currency");
          }
          else if(response.status === 404){
            toast("error", "Missing required fields");
          }
          else if(response.status === 500){
            toast("error", "Duplicate Currency IDs found.");
            console.log("response duplicate Currency id data",response.duplicates)
          }
        }
    
    
          
        } catch (error) {
          console.error("Error fetching array Currency data", error);
        }
        finally {
              setLoading(false);
            }
      };


      const AcctNoUpdateGetDataToDisplay = async (uploadOption, tableData) => {
        let dataToDisplay;
        // console.log("Enter in the account no update data to display");
        // console.log("display option is account update",displayOption);
        // console.log("uploadOption option is account update",uploadOption);
        if (displayOption === "1") {
          console.log("Display option is 1");
      
        // console.log("All data",tableData);
        console.log("existing row is ",accountNoUpdatesuccessfulRecords);

        // Convert existingUsers to a Set for faster lookup based on a unique identifier (e.g., SLNo)
        const existingUserSet = new Set(accountNoUpdatesuccessfulRecords.map(user => JSON.stringify(user)));

        console.log("Exisitng row list data",existingUserSet);
        // Filter tableData to get the non-existing rows
        const nonExistingRows = tableData.filter(row => !existingUserSet.has(JSON.stringify(row)));

        console.log("Non-existing rows", nonExistingRows);

        dataToDisplay =nonExistingRows;         
      
        } else if (displayOption === "2") {
          console.log("Display option is 2");
          dataToDisplay = tableData; // Display tableData if displayOption is "2"
        } 
        if (uploadOption === "3") {
          console.log("upload option is 3 for acct no update");

          let processed=[];
 
          if(newRequestNo && newRequestNoGreen && ibanRequired) {
            console.log("entered in this console log");
            processed = await AcctNoUpdateprocessData(tableData);
            console.log("Processed data in data to display",processed);
          }
        console.log("account No Update successfulRecords",processed);
      
          dataToDisplay = processed; // Display successfulRecords if uploadOption is "3"
      
        } else {
          dataToDisplay = tableData; // Default to tableData if no conditions are met
        }
      
      // console.log("dataToDisplay data is",dataToDisplay);
      return dataToDisplay;
      };


      const AcctNoUpdateprocessData = async (data) => {
        const successfulRecordsArray = [];
        
    console.log("processData received  data:", data);
    
    //  return data.map((row, _, allRows) => {
        await Promise.all(data.map(async (row, _, allRows) => {
                        
        const isDuplicateReqNo = allRows.filter(r => r.NewRequestNo == row.NewRequestNo).length > 1;
        
        const normalize = (value) => String(value).trim().toUpperCase();

        const NewRequestNoExists = newRequestNo.map(normalize).includes(normalize(row.NewRequestNo));
        const NewRequestNoGreenExists = newRequestNoGreen.map(normalize).includes(normalize(row.NewRequestNo));
        const ibanRequiredExists = ibanRequired.map(normalize).includes(normalize(row.NewRequestNo));

        
       console.log(`NewRequestNo id ${row.NewRequestNo} exists:`, NewRequestNoExists);
       console.log(`NewRequestNo green id ${row.NewRequestNo} exists:`, NewRequestNoGreenExists);
        
       
    // Check for errors (red text) and apply highlight based on that

    const hasNotError = [
    row.SLNo,
    row.Action,
    row.NewRequestNo,
    row.BankAccountNo,
    row.BankAccountIdDescription,
    row.IBANNo
    ].every(field => {
    let isValid = true;
    let reason = "";
    
    if (field === row.SLNo) {
        if (!field) { isValid = false; reason = "SLNo should not be blank."; }
        else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
        else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
    }
    
    if (row.Action) {
      // if(row.Action !== "U" || row.Action !== "u"){ isValid = false; reason = "Action only allow U."; }
      if (!["U"].includes(row.Action)) { 
        isValid = false; 
        reason = "Action only allows 'U'."; 
    }
        if (!row.Action) { isValid = false; reason = "Action should not be blank."; }
        else if (String(row.Action).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
    }
    

    if ((!row.NewRequestNo || String(row.NewRequestNo).trim() === "") && (row.Action === "U" || row.Action === "u")) {
        isValid = false;
        reason = "NewRequestNo should not be empty.";
    } else if (field === row.NewRequestNo) {
        if(isDuplicateReqNo){
            isValid = false; reason = "New Request Number is duplicate.";
        }
        
        if (String(field).trim().length > 12) {
            isValid = false;
            reason = "NewRequestNo exceeds max length of 12.";
        }
          if(NewRequestNoExists){
              if(!NewRequestNoGreenExists){
                  isValid = false;
                  reason = "NewRequestNo is not exists because of the flag status"
              }
          }
          else{
              isValid = false;
              reason = "NewRequestNo is not exists"
          }
      }
    
    if ((!row.BankAccountNo || String(row.BankAccountNo).trim() === "") && (row.Action === "U" || row.Action === "u")) {
        isValid = false;
        reason = "BankAccountNo should not be empty.";
    } else if(row.BankAccountNo){
        if(field === row.BankAccountNo){
            if (String(field).trim().length > 25) {
                isValid = false;
                reason = "BankAccountNo exceeds max length of 25.";
            }
          }
      }


      if ((!row.BankAccountIdDescription || String(row.BankAccountIdDescription).trim() === "")  && (row.Action === "U" || row.Action === "u")) {
        isValid = false;
        reason = "BankAccountIdDescription should not be empty.";
    } else if(row.BankAccountIdDescription){
        if(field === row.BankAccountIdDescription){
            if (String(field).trim().length > 30) {
                isValid = false;
                reason = "BankAccountIdDescription exceeds max length of 30.";
            }
          }
      }


      if(row.TextField){
        if(field === row.TextField){
            if (String(field).length > 30) { isValid = false; reason = "TextField exceeds max length of 30."; }
        }
    }


    if ((!row.IBANNo || String(row.IBANNo).trim() === "") && (row.Action === "U" || row.Action === "u") && ibanRequiredExists) {
        isValid = false;
        reason = "IBANNo should not be empty.";
    } else if(row.IBANNo){
        if(field === row.IBANNo){
            if (String(field).trim().length > 50) {
                isValid = false;
                reason = "IBANNo exceeds max length of 50.";
            }
          }
      }


    if (!isValid) {
        console.log(`Validation Failed: ${reason}`);
    } else {
        console.log(`Validation Passed: ${field}`);
    }
    
    return isValid;
    });
    
    if (hasNotError) {
        successfulRecordsArray.push(row);
    }
    console.log(`Final hasNotError status: ${hasNotError}`);
    
        return {
        ...row,
        successfulrecords: hasNotError, // Mark as successful if no error

    
        Action: row.Action ? (
            String(row.Action).length === 1 ? (
            ["U", "u"].includes(row.Action) ? (
                row.Action // Valid Action ("A", "E", "a", "e")
            ) : (
                <span style={{ color: "red" }}>
                {row.Action} (Only "U" is allowed)
                </span>
            )
            ) : (
            <span style={{ color: "red" }}>
                {row.Action} (Max. allowed character is 1)
            </span>
            )
        ) : (
            <span style={{ color: "red" }}>Shouldn't be blank</span>
        ),
    
        SLNo: row.SLNo ? (
            isNaN(row.SLNo) ? (
                <span style={{ color: "red"}}>
                {row.SLNo} (Mismatched Data Type)
                </span>
            ) : String(row.SLNo).length > 5 ? (
                <span style={{ color: "red"}}>
                {row.SLNo} (Max. allowed character is 5)
                </span>
            ) : (
                row.SLNo // Valid SLNo
            )
            ) : (
            <span style={{ color: "red"}}>Shouldn't be blank</span>
            ),
    
            NewRequestNo: row.NewRequestNo ? (
                String(row.NewRequestNo).length > 12 ? (
                  <span style={{ color: "red" }}>
                    {row.NewRequestNo} (Max. allowed characters: 12)
                  </span>
                ) : isDuplicateReqNo ?
                (
                    <span style={{ color: "red" }}>
                      {row.NewRequestNo} (NewRequestNo Duplicate exists)
                    </span>
                  ) :
                NewRequestNoExists && displayOption !== "" ? (
                    NewRequestNoGreenExists ? (
                    <span>{row.NewRequestNo}</span>
                  ) : (
                    <span style={{ color: "red" }}>
                      {row.NewRequestNo} (New RequestNo is not approved.)
                    </span>
                  )
                ) :(
                  <span style={{ color: "red" }}>
                  {row.NewRequestNo} (New RequestNo not exists.)
                </span>
                ) )
                :
                (
                    <span style={{ color: "red" }}>
                      Shouldn't be blank
                    </span>
                ),


                BankAccountNo: row.BankAccountNo ? (
                    String(row.BankAccountNo).length > 25 ? (
                      <span style={{ color: "red" }}>
                        {row.BankAccountNo} (Max. allowed character is 25)
                      </span>
                    ) : (
                      String(row.BankAccountNo) // Ensure BankAccountNo is treated as a string
                    )
                  ) : (
                    row.Action === "U" || row.Action === "u" ? (
                      <span style={{ color: "red" }}>
                        Shouldn't be blank
                      </span>
                    ): null // Handle other cases, if any
                  ),

                  BankAccountIdDescription: row.BankAccountIdDescription ? (
                    String(row.BankAccountIdDescription).length > 30 ? (
                      <span style={{ color: "red" }}>
                        {row.BankAccountIdDescription} (Max. allowed character is 30)
                      </span>
                    ) : (
                      String(row.BankAccountIdDescription) // Ensure BankAccountIdDescription is treated as a string
                    )
                  ) : (
                    row.Action === "U" || row.Action === "u" ? (
                      <span style={{ color: "red" }}>
                        Shouldn't be blank
                      </span>
                    ): null // Handle other cases, if any
                  ),

                  TextField: row.TextField ? (
                    String(row.TextField).length > 30 ? (
                      <span style={{ color: "red" }}>
                        {row.TextField} (Max. allowed character is 30)
                      </span>
                    ) : (
                      String(row.TextField) // Ensure TextField is treated as a string
                    )
                  ) : (
                      <span>
                      </span>
                  ),
                  
                  IBANNo: row.IBANNo ? (
                    String(row.IBANNo).length > 50 ? (
                      <span style={{ color: "red" }}>
                        {row.IBANNo} (Max. allowed character is 50)
                      </span>
                    ) : (
                      String(row.IBANNo) // Ensure BankAccountIdDescription is treated as a string
                    )
                  ) : (
                    row.Action === "U" || row.Action === "u" ? (
                        ibanRequiredExists ? (
                      <span style={{ color: "red" }}>
                        Shouldn't be blank
                      </span>
                        ): (
                        <span> </span>
                        )     
                  ):null
                )
        };  
    }));
    setAccountNoUpdateSuccessfulRecords(successfulRecordsArray);
    console.log("successfulRecordsArray data",successfulRecordsArray);
    return successfulRecordsArray;

    }

    const AcctNoUpdateFullprocessData = async (data) => {
      const successfulRecordsArray = [];
      
  console.log("processData received  data:", data);
  
  //  return data.map((row, _, allRows) => {
    const results = await Promise.all(data.map(async (row, _, allRows) => {
                      
      const isDuplicateReqNo = allRows.filter(r => r.NewRequestNo == row.NewRequestNo).length > 1;
      
      const normalize = (value) => String(value).trim().toUpperCase();

      const NewRequestNoExists = newRequestNo.map(normalize).includes(normalize(row.NewRequestNo));
      const NewRequestNoGreenExists = newRequestNoGreen.map(normalize).includes(normalize(row.NewRequestNo));
      const ibanRequiredExists = ibanRequired.map(normalize).includes(normalize(row.NewRequestNo));

      
     console.log(`NewRequestNo id ${row.NewRequestNo} exists:`, NewRequestNoExists);
     console.log(`NewRequestNo green id ${row.NewRequestNo} exists:`, NewRequestNoGreenExists);
      
     
  // Check for errors (red text) and apply highlight based on that

  const hasNotError = [
  row.SLNo,
  row.Action,
  row.NewRequestNo,
  row.BankAccountNo,
  row.BankAccountIdDescription,
  row.IBANNo
  ].every(field => {
  let isValid = true;
  let reason = "";
  
  if (field === row.SLNo) {
      if (!field) { isValid = false; reason = "SLNo should not be blank."; }
      else if (isNaN(field)) { isValid = false; reason = "SLNo should be a valid number."; }
      else if (String(field).length > 5) { isValid = false; reason = "SLNo exceeds max length of 5."; }
  }
  
  if (row.Action) {
    // if(row.Action !== "U" || row.Action !== "u"){ isValid = false; reason = "Action only allow U."; }
    if (!["U"].includes(row.Action)) { 
      isValid = false; 
      reason = "Action only allows 'U'."; 
  }
      if (!row.Action) { isValid = false; reason = "Action should not be blank."; }
      else if (String(row.Action).length > 1) { isValid = false; reason = "Action exceeds max length of 1."; }
  }
  

  if ((!row.NewRequestNo || String(row.NewRequestNo).trim() === "") && (row.Action === "U" || row.Action === "u")) {
      isValid = false;
      reason = "NewRequestNo should not be empty.";
  } else if (field === row.NewRequestNo) {
      if(isDuplicateReqNo){
          isValid = false; reason = "New Request Number is duplicate.";
      }
      
      if (String(field).trim().length > 12) {
          isValid = false;
          reason = "NewRequestNo exceeds max length of 12.";
      }
        if(NewRequestNoExists){
            if(!NewRequestNoGreenExists){
                isValid = false;
                reason = "NewRequestNo is not exists because of the flag status"
            }
        }
        else{
            isValid = false;
            reason = "NewRequestNo is not exists"
        }
    }
  
  if ((!row.BankAccountNo || String(row.BankAccountNo).trim() === "") && (row.Action === "U" || row.Action === "u")) {
      isValid = false;
      reason = "BankAccountNo should not be empty.";
  } else if(row.BankAccountNo){
      if(field === row.BankAccountNo){
          if (String(field).trim().length > 25) {
              isValid = false;
              reason = "BankAccountNo exceeds max length of 25.";
          }
        }
    }


    if ((!row.BankAccountIdDescription || String(row.BankAccountIdDescription).trim() === "")  && (row.Action === "U" || row.Action === "u")) {
      isValid = false;
      reason = "BankAccountIdDescription should not be empty.";
  } else if(row.BankAccountIdDescription){
      if(field === row.BankAccountIdDescription){
          if (String(field).trim().length > 30) {
              isValid = false;
              reason = "BankAccountIdDescription exceeds max length of 30.";
          }
        }
    }


    if(row.TextField){
      if(field === row.TextField){
          if (String(field).length > 30) { isValid = false; reason = "TextField exceeds max length of 30."; }
      }
  }


  if ((!row.IBANNo || String(row.IBANNo).trim() === "") && (row.Action === "U" || row.Action === "u") && ibanRequiredExists) {
      isValid = false;
      reason = "IBANNo should not be empty.";
  } else if(row.IBANNo){
      if(field === row.IBANNo){
          if (String(field).trim().length > 50) {
              isValid = false;
              reason = "IBANNo exceeds max length of 50.";
          }
        }
    }


  if (!isValid) {
      console.log(`Validation Failed: ${reason}`);
  } else {
      console.log(`Validation Passed: ${field}`);
  }
  
  return isValid;
  });
  
  if (hasNotError) {
      successfulRecordsArray.push(row);
  }
  console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
      ...row,
      successfulrecords: hasNotError, // Mark as successful if no error

  
      Action: row.Action ? (
          String(row.Action).length === 1 ? (
          ["U", "u"].includes(row.Action) ? (
              row.Action // Valid Action ("A", "E", "a", "e")
          ) : (
              <span style={{ color: "red" }}>
              {row.Action} (Only "U" is allowed)
              </span>
          )
          ) : (
          <span style={{ color: "red" }}>
              {row.Action} (Max. allowed character is 1)
          </span>
          )
      ) : (
          <span style={{ color: "red" }}>Shouldn't be blank</span>
      ),
  
      SLNo: row.SLNo ? (
          isNaN(row.SLNo) ? (
              <span style={{ color: "red"}}>
              {row.SLNo} (Mismatched Data Type)
              </span>
          ) : String(row.SLNo).length > 5 ? (
              <span style={{ color: "red"}}>
              {row.SLNo} (Max. allowed character is 5)
              </span>
          ) : (
              row.SLNo // Valid SLNo
          )
          ) : (
          <span style={{ color: "red"}}>Shouldn't be blank</span>
          ),
  
          NewRequestNo: row.NewRequestNo ? (
              String(row.NewRequestNo).length > 12 ? (
                <span style={{ color: "red" }}>
                  {row.NewRequestNo} (Max. allowed characters: 12)
                </span>
              ) : isDuplicateReqNo ?
              (
                  <span style={{ color: "red" }}>
                    {row.NewRequestNo} (NewRequestNo Duplicate exists)
                  </span>
                ) :
              NewRequestNoExists && displayOption !== "" ? (
                  NewRequestNoGreenExists ? (
                  <span>{row.NewRequestNo}</span>
                ) : (
                  <span style={{ color: "red" }}>
                    {row.NewRequestNo} (New RequestNo is not approved.)
                  </span>
                )
              ) :(
                <span style={{ color: "red" }}>
                {row.NewRequestNo} (New RequestNo not exists.)
              </span>
              ) )
              :
              (
                  <span style={{ color: "red" }}>
                    Shouldn't be blank
                  </span>
              ),


              BankAccountNo: row.BankAccountNo ? (
                  String(row.BankAccountNo).length > 25 ? (
                    <span style={{ color: "red" }}>
                      {row.BankAccountNo} (Max. allowed character is 25)
                    </span>
                  ) : (
                    String(row.BankAccountNo) // Ensure BankAccountNo is treated as a string
                  )
                ) : (
                  row.Action === "U" || row.Action === "u" ? (
                    <span style={{ color: "red" }}>
                      Shouldn't be blank
                    </span>
                  ): null // Handle other cases, if any
                ),

                BankAccountIdDescription: row.BankAccountIdDescription ? (
                  String(row.BankAccountIdDescription).length > 30 ? (
                    <span style={{ color: "red" }}>
                      {row.BankAccountIdDescription} (Max. allowed character is 30)
                    </span>
                  ) : (
                    String(row.BankAccountIdDescription) // Ensure BankAccountIdDescription is treated as a string
                  )
                ) : (
                  row.Action === "U" || row.Action === "u" ? (
                    <span style={{ color: "red" }}>
                      Shouldn't be blank
                    </span>
                  ): null // Handle other cases, if any
                ),

                TextField: row.TextField ? (
                  String(row.TextField).length > 30 ? (
                    <span style={{ color: "red" }}>
                      {row.TextField} (Max. allowed character is 30)
                    </span>
                  ) : (
                    String(row.TextField) // Ensure TextField is treated as a string
                  )
                ) : (
                    <span>
                    </span>
                ),
                
                IBANNo: row.IBANNo ? (
                  String(row.IBANNo).length > 50 ? (
                    <span style={{ color: "red" }}>
                      {row.IBANNo} (Max. allowed character is 50)
                    </span>
                  ) : (
                    String(row.IBANNo) // Ensure BankAccountIdDescription is treated as a string
                  )
                ) : (
                  row.Action === "U" || row.Action === "u" ? (
                      ibanRequiredExists ? (
                    <span style={{ color: "red" }}>
                      Shouldn't be blank
                    </span>
                      ): (
                      <span> </span>
                      )     
                ):null
              )
      };  
  }));
  setAccountNoUpdateSuccessfulRecords(successfulRecordsArray);
  return results;

  }


      const storeArrayDataForAccountNoUpdate = async () => {
        console.log("Enternin a store function");
        setLoading(true);
        try {
          const dataToDisplay = await AcctNoUpdateGetDataToDisplay(uploadOption, tableData);
        console.log("store successfulRecords for Account No update data is", dataToDisplay);
    
        let processed=[];

        if(newRequestNo && newRequestNoGreen && ibanRequired) {
          processed = await AcctNoUpdateFullprocessData(tableData);
        }
        console.log("display datas",processed);
    
          const extractText = (cellValue) => {
                  if (Array.isArray(cellValue)) {
                    return cellValue.join(", "); // Convert array to string
                  } else if (typeof cellValue === "string") {
                    return cellValue;
                  } else if (React.isValidElement(cellValue)) {
                    return cellValue.props.children;
                  }
                  return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
                };
    
                const formattedUsers = processed.map((user) => {
    
                  return {
                  "Action": extractText(user.Action),
                  "SLNo": extractText(user.SLNo),
                  "NewRequestNo":extractText(user.NewRequestNo),
                  "BankAccountNo": extractText(user.BankAccountNo),
                  "BankAccountIdDescription": extractText(user.BankAccountIdDescription),
                  "TextField":extractText(user.TextField),
                  "IBANNo":extractText(user.IBANNo),                                  
                };
        });
          
            // Insert second and third row at the beginning of the formatted data
            const rowsToDisplay = [ ...formattedUsers];
    
            console.log("Formatted USERS Data:", formattedUsers);
            console.log("Formatted Data:", rowsToDisplay);
    
            
            const updatedData = rowsToDisplay.map(user => {
    
              const isObjectField = ["Action",
              "SLNo",
              "NewRequestNo",
              "BankAccountNo",
              "BankAccountIdDescription",
              "TextField",
              "IBANNo",
    ].some(field => typeof user[field] === 'object' || user[field] === "Shouldn't be blank");
    
              
              return {
                // update: typeof user.UserID === 'object' ? "No" : "Yes",
                update: isObjectField ? "No" : "Yes",
                      ...user,
                    };
                });
    console.log("updatedData data",updatedData);
    
    
          const data = {
            clientId: assignee.clientId,
            dataToDisplay,
            createdBy: assignee.userId,
            allrecords: updatedData,
          }
          console.log("storeArrayDataForAccountNoUpdate dataToDisplay is",data);
          const response = await UserService.saveContactDetails(
            `${process.env.REACT_APP_BASE_URL}/bam/store-array-BAM-Account-Number-update-data`,
            data
          );
          console.log("Roles Response data", response);
    
    
          if (dataToDisplay.length === 0) { 
            // toast("error", "Please upload the correct file OR containing the data.");
            toast("error", "Error fields exist.");
            return;
        }
        else{
          if (response.status === 200) {
            toast("success", "Uploaded BAM Account Number Update successfully");
          }
          else if(response.status === 400){
            toast("error", "Invalid or empty array of Currency");
          }
          else if(response.status === 404){
            toast("error", "Missing required fields");
          }
          else if(response.status === 500){
            toast("error", "Duplicate Currency IDs found.");
            console.log("response duplicate Currency id data",response.duplicates)
          }
        }
    
    
          
        } catch (error) {
          console.error("Error fetching array Currency data", error);
        }
        finally {
              setLoading(false);
            }
      };

      const handleSubmit = async () => {

        if(buttonText === 'Upload from desktop'){
            toast("error", "Please upload a file.");
            return;
        }
        else if (tableData.length === 0) { 
            // toast("error", "Please upload the correct file OR containing the data.");
            toast("error", "Please upload a file containing at least one record.");
            return;
        }
    
             // Check if either of the checkboxes is selected
        if (!isDisplayChecked && !isUploadChecked) {
            toast("error", "Please check at least one checkbox (Display or Upload).");
            return;
        }
    
    
        // console.log("tableData send to the prop",tableData);
        // console.log("displayOption selected", displayOption);
        // console.log("uploadOption selected", uploadOption);
    
        if(selectedFormat === 'NewRequest'){ 
          if(uploadOption !== '' && displayOption === '' ){
            console.log("User arry upload data");
            storeArrayDataForUsers();
          }
          else {
            if(uploadOption != ''){
              storeArrayDataForUsers();
            }
            console.log("exit and navigate to the upload table screen");
            console.log("exit and navigate tableData",tableData);
            console.log("exit and navigate to displayOption",displayOption);
            console.log("exit and navigate to uploadOption",uploadOption);
            navigate(`/3/BAM/26/account-master-upload/display-screen`, {
                  state: { tableData, displayOption, uploadOption } // Passing tableData via state
              });
          }
        }
        else if(selectedFormat === 'AcctNoUpdate'){
          console.log("Upload option is",uploadOption);
          
            if(uploadOption !== ''){
              console.log("Enter  in the upload options.");
              storeArrayDataForAccountNoUpdate();
            }
            console.log("exit and navigate to the upload table screen");
            // console.log("exit and navigate tableData",tableData);
            // console.log("exit and navigate to displayOption",displayOption);
            // console.log("exit and navigate to uploadOption",uploadOption);
            navigate(`/3/BAM/26/account-number-update-upload/display-screen`, {
                  state: { tableData, displayOption, uploadOption } // Passing tableData via state
              });
          
        }
        else if(selectedFormat === 'Migration'){
          console.log("Upload option is",uploadOption);
          
            if(uploadOption !== ''){
              console.log("Enter  in the upload options.");
              // storeArrayDataForMigration();
            }
            console.log("exit and navigate to the upload table screen");
            // console.log("exit and navigate tableData",tableData);
            // console.log("exit and navigate to displayOption",displayOption);
            // console.log("exit and navigate to uploadOption",uploadOption);
            navigate(`/3/BAM/26/account-master-migration-upload/display-screen`, {
                  state: { tableData, displayOption, uploadOption } // Passing tableData via state
              });
          
        }
      }

      
  return (
    <div>
      <Header title={"BAM > Bank Master Data > Account Master > Upload"}
      onBreadcrumbClick={handleBreadcrumbClick}
      backRoute={"/3/BAM/26/account-master"} />

            <UploadComponent
              downloadPath={selectedFormat === "NewRequest" ? "/BAM_New_Request_Upload.xlsx" : selectedFormat === "AcctNoUpdate" ? "/BAM_Account_No_Update_Upload.xlsx" : '/BAM_Migration_Upload.xlsx'}
              handleFileChange={handleFileChange}  // Function to handle file selection
              buttonText={buttonText}
              setButtonText= {setButtonText}
              setIsError = {setIsError}
              isUploaded={isUploaded}
              isError={isError}
              handleSubmit={handleSubmit}
              isDisplayChecked={isDisplayChecked}
              setIsDisplayChecked={setIsDisplayChecked}
              isUploadChecked={isUploadChecked}
              setIsUploadChecked={setIsUploadChecked}
              displayOption={displayOption}
              setDisplayOption={setDisplayOption}
              uploadOption={uploadOption}
              setUploadOption={setUploadOption}
              selectedFormat={selectedFormat}
              setSelectedFormat={setSelectedFormat}
              icons={iconId}
              enableBAMFormatSelection={true}
              downloadText={selectedFormat === 'NewRequest' ? 'Download New Request Format' : selectedFormat === 'AcctNoUpdate' ? 'Download Account No Update Format' : 'Download Migration Format'} // Dynamic text

            />
      
              <ConfirmationDialog
                open={showConfirmation}
                title="Upload File"
                message="Do you need to download the latest upload format?"
                onConfirm={confirmDeletion}
                onCancel={cancelDeletion}
              />
              <Footer>
                <button
                  onClick={handleSubmit}
                  className="bg-customBlue text-sm text-white py-1 px-2  rounded hover:bg-blue-600 transition duration-200 ease-in-out w-28  whitespace-nowrap"
                >
                  Run
                </button>
              </Footer>
    </div>
  )
}
