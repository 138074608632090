import React, { useState } from "react";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  totalCount,
  onShowAll, // New prop to handle "Show All" action
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(currentPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 1 && value <= totalPages) {
      setInputValue(value);
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    const newPage = parseInt(inputValue, 10);
    if (newPage && newPage !== currentPage) {
      onPageChange(newPage);
    } else {
      setInputValue(currentPage);
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  const handleInputFocus = () => {
    setInputValue("");
  };

  return (
    <div className="flex items-center">
     <div
  className="min-w-[60px] text-nowrap text-customGray cursor-pointer text-xs"
  onClick={(e) => {
    e.preventDefault();
    e.stopPropagation();
    onShowAll();
  }}
>
  {totalCount} Records
</div>
      <div className="flex items-center xl:ml-4 lg:ml-0">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="mx-1 xl:px-3 lg:px-2 py-1 border rounded bg-white text-customGray"
        >
          <p className="lg:text-xs xl:text-sm">&lt;</p>
        </button>
        <span className="mx-2 text-customGray text-nowrap text-xs">
          {isEditing ? (
            <input
              type="number"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleInputKeyDown}
              onFocus={handleInputFocus}
              className="w-12 border rounded text-center"
              min={1}
              max={totalPages}
              autoFocus
            />
          ) : (
            <span
              onClick={() => setIsEditing(true)}
              className="cursor-pointer"
            >
              {currentPage}
            </span>
          )}{" "}
          of {totalPages}
        </span>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="mx-1 xl:px-3 lg:px-2 py-1 border rounded bg-white text-customGray"
        >
          <p className="lg:text-xs xl:text-sm">&gt;</p>
          </button>
      </div>
    </div>
  );
};

export default Pagination;
