import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
  IoMdCreate,
  IoMdRefresh,
  IoMdTime,
  IoMdTrash,
} from "react-icons/io";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import { Checkbox } from "@mui/material";
import { MdOutlineFileDownload } from "react-icons/md";
import * as XLSX from "xlsx";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import ReusableTable from "../../Components/Table";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";

export default function CalendarScreen() {
  const [calendarId, setCalendarId] = useState(null); // To store the selected calendar ID
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const{toast}=useToast();
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [filteredCalendars, setFilteredCalendars] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const columns = [
    { Header: "Calendar", accessor: "Calendar", visible: true, width: 200 },
  { Header: "Calendar Name", accessor: "Calendar_Name", visible: true, width: 200 },
  { Header: "Created By", accessor: "created_by", visible: true, width: 200 },
  {
    Header: "Created Date",
    accessor: "creationDate",
    visible: true,
    width: 200,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
    }
  },
  {
    Header: "Created Time",
    accessor: "creationTime", 
    visible: true,
    width: 200,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("en-GB", {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',

        hour12: true, 
      });
    }
  },
  { Header: "Updated By", accessor: "changed_by", visible: true, width: 200, canResize: true },
  {
    Header: "Updated Date",
    accessor: "changedDate",
    visible: true,
    width: 200,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
    }
  },
  {
    Header: "Updated Time",
    accessor: "changedTime", 
    visible: true,
    width: 200,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("en-GB", {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',

        hour12: true, 
      });
    }
  },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);
  useEffect(() => {
    fetchCalendars();
  }, []);
  useEffect(() => {
    // Filter banks based on search query
    const filtered = calendars.filter((calendar) =>
      calendar.Calendar.toLowerCase().includes(searchQuery.toLowerCase()) 
    );
    setFilteredCalendars(filtered);
  }, [searchQuery, calendars]);
  const fetchCalendars = async () => {
    setLoading(true);
    try {
      const response = await CalendarServices.getAllCalendar(
        `${process.env.REACT_APP_BASE_URL}/get-calendar`
      );
      if (response && response.data) {
        setCalendars(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Calendar data", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle checkbox change (selection)
  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

    setSelectedRows(updatedSelectedRows);
    setCalendarId(id); // Set the selected calendar ID for navigation or further actions
    setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };

  const modifiedColumns = columnSettings.filter(col => col.visible).map(col => ({
    ...col,
    width: col.width // This should hold the updated width
  }));

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  // Function to delete calendar
  const handleDelete = async (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this calendar?");
    if (confirmed) {
      setLoading(true);
      try {
         CalendarServices.deleteCalendar(id);
        toast("success","Calendar deleted successfully");
        // Refetch calendars after deletion
        fetchCalendars();
      } catch (error) {
        console.error("Error deleting calendar:", error);
        toast("Failed to delete calendar");
      } finally {
        setLoading(false);
      }
    }
  };

  // Extra buttons that appear when rows are selected
  const extraButtons = [
   
    {
      icon: IoMdCreate,
      onClick: () => handleNavigate(`/calendar-add/${calendarId}`),
      title:"Edit calendar",
      disabled: selectedRows.length === 0, 
    },
 
    // {
    //   icon: IoMdTrash,
    //   className: "text-red-500", // Optional: Add red color to trash icon
    //   onClick: () => handleDelete(calendarId), // Call delete function
    // },
  ];
  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredCalendars.map((calendar) => ({
      "Calendar": calendar.Calendar,
      "Calendar Name": calendar.Calendar_Name,
      "Created By": calendar.created_by,
      "Created Date": calendar.creationDate
        ? new Date(calendar.creationDate).toLocaleDateString()
        : "",
      "Created Time": calendar.creationTime
        ? new Date(calendar.creationTime).toLocaleTimeString()
        : "",
      "Changed By": calendar.changed_by,
      "Changed Date": calendar.changedDate
        ? new Date(calendar.changedDate).toLocaleDateString()
        : "",
      "Changed Time": calendar.changedTime
        ? new Date(calendar.changedTime).toLocaleTimeString()
        : "",
    }));
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Calendars");
  
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Calendars_Data.xlsx");
  };
  const handleReload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchCalendars();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };
  // Configuring existing buttons
  const existingButtons = PrimaryActionsConfig.screen1.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add Calendar"
        : button.icon === MdOutlineFileDownload
        ? "Download"
        : button.icon === IoSettingsOutline
        ? "Settings"
            : button.icon === IoMdRefresh
      ? "Refresh"
        : button.title || "", // If a title already exists, use it, otherwise empty
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/calendar-add")
        : button.icon === MdOutlineFileDownload 
        ? handleDownload
        : button.icon === IoSettingsOutline
        ? handleSettingsButtonClick
        : button.icon === IoMdRefresh
        ? handleReload
        : button.onClick || (() => {}),
  }));
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );
  // Navigation helper function
  const handleNavigate = (path) => {
    navigate(path);
    
  };
  const handleDoubleClick = (id) => {
    navigate(`/calendar-view/${id}`, { state: { mode: 'view' } });  };

  // Fetch calendars on component mount
   const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = calendars.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };
  if (loading) return <div></div>;
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Master Data') {
      console.log("Navigating to /master-data");
      navigate('/master-data');
    } else if (crumb.title === 'Calendar') {
      console.log("Navigating to /country");
      navigate('/calendar');
    } 
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
   
      <Header title={"Primary Data > Global Master Data > Calendar"
      } onBreadcrumbClick={handleBreadcrumbClick}/>
     
      <div className="flex justify-between">
      <PrimaryActions
         buttons={actionButtons} 
         selectedCount={selectedRows.length} 
        isExtraButtonsVisible={showExtraIcons}
      />
      
        <div className="flex  items-center ">
        <p className="mr-4 text-customBlue">Calendar Code :</p>

        <SearchInput 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            placeholder="Search by Calendar" 
          />
</div>
        </div>
     <div>
        <ReTable
          data={filteredCalendars}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      
    </div>
    
  );
}
