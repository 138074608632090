import React from 'react'
import Footer from '../../../../../../components/footer'

export default function BankstatementsContent() {
    return (
        <div className='p-4 mb-40'>
            <div className="flex flex-col gap-y-4 mt-4 ml-10">
                <label className="flex items-center">
                    <span className="w-60 text-sm font-medium text-gray-500">Statement Type<span className='text-red-500'>*</span></span>
                    <span className="ml-3">:</span>
                    <select
                        type="number"
                        className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                        required
                    />
                </label>
                <label className="flex items-center">
                    <span className="w-60 text-sm font-medium text-gray-500">Statement identifier</span>
                    <span className="ml-3">:</span>
                    <input
                        type="number"
                        className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                        required
                    />
                </label>
                <label className="flex items-center">
                    <span className="w-60 text-sm font-medium text-gray-500">Last Statement date</span>
                    <span className="ml-3">:</span>
                    <input
                        type="number"
                        className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                        required
                    />
                </label>
                <label className="flex items-center">
                    <span className="w-60 text-sm font-medium text-gray-500">Last statement Closing balance</span>
                    <span className="ml-3">:</span>
                    <input
                        type="number"
                        className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                        required
                    />
                </label>
                <label className="flex items-center">
                    <span className="w-60 text-sm font-medium text-gray-500">Last Statement No</span>
                    <span className="ml-3">:</span>
                    <input
                        type="number"
                        className="border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 border-gray-300 ml-4"
                        required
                    />
                </label>

            </div>
            <div className='ml-2'>
            <button
          className="bg-customBlue text-sm text-white py-1 px-2 ml-8 mt-10 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 "
        >
          Next 
        </button>
        </div>
        <Footer>
        <button
          className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7"
        >
          Save
        </button>
        <button
          className="ml-4 border border-blue-500 text-sm text-blue-500 py-1 px-2 rounded hover:bg-blue-100 transition duration-200 ease-in-out w-30"
        >
          Save & Continue
        </button>
        </Footer>
        </div>
    )
}
