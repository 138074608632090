import RestService from "./RestServices";

const basePath = "/cash-balance";

const CashBalanceServices = {
    getCompanyCode: () => {
        return RestService.GetAllData(
            `${basePath}/get-all-company`
        );
    },
    getBranch: () => {
        return RestService.GetAllData(
            `${basePath}/get-all-branch`
        );
    },

    getAllBranchData: () => {
        return RestService.GetAllData(`${basePath}/get-all-branch-data`)
    },

    getAllBankAccountId: (companyCode, branch) => {
        const code = companyCode || "";
        const branchCode = branch || "";
        return RestService.GetAllData(
            `${basePath}/get-all-bankAccountId?companyCode=${code}&branch=${branchCode}`
        );
    },
    getAllStatement: (data, pageSize, page) => {
        return RestService.CreateData(
            `${basePath}/get-filtered-data?&limit=${pageSize}&offset=${(page - 1) * pageSize}`, data
        );
    },
    getDataForExpand: (bankAccountID) => {
        return RestService.GetAllData(
            `${basePath}/get-data-expand?bankAccountId=${bankAccountID}`
        );
    },
    exportAccountPortal: (data) => {
        return RestService.ExportData(`${basePath}/export-account-portal`, data);
    },
    //Company Wise
    getAllBugroup: () => {
        return RestService.GetAllData(`${basePath}/get-all-bugroup-data`)
    },
    getAllLegalEnity: () => {
        return RestService.GetAllData(`${basePath}/get-all-legal-entity-data`)
    },

    saveProcessDetails: (data) => {
        return RestService.CreateData(`${basePath}/save-process-list`, data)
    },
    updateProcessDetails: (data, subModule, iconId) => {
        return RestService.UpdateData(`${basePath}/update-process-list?subModule=${subModule}&iconId=${iconId}`, data);
    },
    getCompanyWiseReport: (data) => {
        return RestService.CreateData(
            `${basePath}/get-company-wise-report`, data
        );
    },
    exportCompanyWiseReport: (data) => {
        return RestService.ExportData(`${basePath}/export-company-wise-report`, data);
    }
}

export default CashBalanceServices;