import React from "react";
import { useUser } from "./UserContext";
import { useSetState } from "react-use";
import UserService from "../rest-services/UserServices";

export const AuthContext = React.createContext("Default");
const initialState = {
  isLoggedIn: localStorage.getItem("encodedCredentials"),
  isLoginPending: false,
  loginError: null,
};

export const ContextProvider = (props) => {
  const { user, setUser } = useUser();
  // const navigate = useNavigate();
  const [state, setState] = useSetState(initialState);
  const setLoginPending = (isLoginPending) => setState({ isLoginPending });
  const setLoginSuccess = (isLoggedIn) => setState({ isLoggedIn });
  const setLoginError = (loginError) => setState({ loginError });

  const login = (userId, password, clientId) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    return UserService.login(userId, password, clientId)
      .then((res) => {
        setLoginPending(false);
        setLoginSuccess(true);
        localStorage.setItem("userDetail", JSON.stringify(res.data.user));
        localStorage.setItem(
          "encodedCredentials",
          res.data.tokens.accessToken
        );
        setUser(res.data.user);
        return res.data;
      })
      .catch((err) => {
        setLoginPending(false);
        setLoginError(err);

        // console.error("Login error:", err); // Log the error for inspection
        throw err; // Re-throw the error to be caught in handleSubmit
      });
  };

  const loginOtp = (userId, otp, clientId) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    return UserService.loginwithOtp(userId, otp, clientId)
      .then((res) => {
        setLoginPending(false);
        setLoginSuccess(true);
        localStorage.setItem("userDetail", JSON.stringify(res.data.user));
        localStorage.setItem(
          "encodedCredentials",
          res.data.tokens.accessToken
        );
        setUser(res.data.user);
        return res.data;
      })
      .catch((err) => {
        setLoginPending(false);
        setLoginError(err);

        // console.error("Login error:", err); // Log the error for inspection
        throw err; // Re-throw the error to be caught in handleSubmit
      });
  };

  const logout = () => {
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);

    localStorage.removeItem("encodedCredentials");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("loginTime"); // Ensure timer is cleared
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        logout,
        loginOtp,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
