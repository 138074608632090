import React, { useEffect, useState } from 'react'
import Header from '../../../../../../components/Header'
import { useNavigate, useParams } from 'react-router-dom';
import AdminActions from '../../../Components/AdminActions';
import { IoMdCheckmark, IoMdClose, IoMdCreate, IoMdRefresh, IoMdTrash } from 'react-icons/io';
import { BsUbuntu } from "react-icons/bs";
import { IoAddCircleOutline, IoPlay, IoSettingsOutline } from 'react-icons/io5';
import { GiSettingsKnobs } from 'react-icons/gi';
import ReTable from '../../../../../../components/Table';
import { AdminActionsConfig } from '../../../Components/ActionConfig';
import { PiCopy, PiNotEquals, PiUserSwitchLight } from 'react-icons/pi';
import UserService from '../../../../../../rest-services/UserServices';
import { useToast } from '../../../../../../components/toast/toast';
import ColumnSettingsPopup from '../../../../Masterdata/Components/ColumnSettingPopup';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import { VscVmActive, VscVmConnect } from 'react-icons/vsc';
import { TbFlag3Filled } from 'react-icons/tb';
import SearchInput from '../../../../../../components/SearchInput';
import Pagination from "../../../../../../components/PageNation";
import ConfirmationDialog from '../../../../../../components/Deletionpopup';
import { MdOutlineFileDownload, MdOutlineHistory, MdOutlineStop } from 'react-icons/md';
import Draggable from 'react-draggable';
import HoverButton from '../../../../../../components/HoverButton';
import { RxDropdownMenu } from 'react-icons/rx';
import CustomButton from '../../../../../../components/CustomButton';
// import CarbonIbmEventAutomation from '~icons/carbon/ibm-event-automation';
import XLSX from "xlsx-js-style";
import { FaPlay } from 'react-icons/fa';


export default function ScheduleScreen(props) {
  const navigate = useNavigate();
  const { iconId } = useParams();
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [rolesAndPermission, setRolesAndPermission] = useState([]);
  const [alert, setAlert] = useState(null);
  const [users, setUsers] = useState([]);
    const [userType, setUserType] = useState('');
    const [roleuserType, setroleUserType] = useState('');
    const [userTypes, setUserTypes] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ id: "", username: "" });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rolesPermissions, setRolesPermissions] = useState([]);

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isUserModalOpen2, setIsUserModalOpen2] = useState(false);
  const [usersToShow, setUsersToShow] = useState([]);
  const [usersToShow2, setUsersToShow2] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [filterType, setFilterType] = useState("1"); // 'createdBy' or 'updatedBy'
  const [isCreatedByPopupOpen, setIsCreatedByPopupOpen] = useState(false);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [tempSelectedcreatedby, setTempSelectedcreatedby] =
    useState(selectedCreatedBy);

  const [selectedUpdatedBy, setSelectedUpdatedBy] = useState([]);

  const [tempSelectedupdatedby, setTempSelectedupdatedby] =
    useState(selectedUpdatedBy);

  const [isUpdatedByPopupOpen, setIsUpdatedByPopupOpen] = useState(false);
  const [updatedbysearchquery, setupdatedbysearchquery] = useState("");
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
  const [createdbydatasearchQuery, setcreatedbydatasearchQuery] = useState("");
  const [selectedRoles, setselectedRoles] = useState({});
  const [selectedUsers, setSelectedUsers] = useState({});
  const [userPreference, setUserPreference] = useState("");
  const [error, setError] = useState(null);
  const [userStatus, setUserStatus] = useState('');
  const [checkingUserExists, setCheckingUserExists] = useState("");

    const [pageSize, setPageSize] = useState(20); // Default page size
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [filters, setFilters] = useState({
        status: "",
        roleId: "",
        userId:"",
        roleuserType: "",
        userType: "",
        createdBy: "",
        createdDate: "",
        created_date_from: "",
        created_date_to: "",
  
        updatedBy: "",
        updated_date_from: "",
        updated_date_to: "",
      });

      const handleSubmitUsers = () => {
        // Collect selected user IDs and close modal
        const selectedUserIds = Object.keys(selectedUsers).filter(
          (userId) => selectedUsers[userId]
        );
        console.log("selected users:", selectedUsers);
        console.log("selected :", selectedUserIds);
        setUsersToShow2(selectedUserIds); // Set selected IDs to be shown in the form
        closeUserModal2(); // Close modal after selection
      };

      const handleSubmitRoles = () => {
        // Collect selected user IDs and close modal
        const selectedRolesIds = Object.keys(selectedRoles).filter(
          (roleId) => selectedRoles[roleId]
        );
        console.log("selected users:", selectedRoles);
        console.log("selected :", selectedRolesIds);
        setUsersToShow(selectedRolesIds); // Set selected IDs to be shown in the form
        closeUserModal(); // Close modal after selection
      };

      const handleBankAccountIdReset = () => {
        setSelectedUsers([]); // Reset selection
      };
      
      const handleRolesIdReset = () => {
        setselectedRoles([]); // Reset selection
      };
      
    useEffect(() => {
      fetchUserTypes();
     fetchCheckingUsers();

    }, []);

    const fetchCheckingUsers  = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/getting-User-details`
        );
        console.log("Response from server fetching users:", response);
        const responseData = response.data.data;
        console.log("checking users data", responseData);
        setCheckingUserExists(responseData);
        console.log("double check users", response.data);
        if (responseData.error) {
          console.error("Error fetching Users data:", responseData.message);
          setAlert({ severity: "error", message: responseData.message });
          return;
        }
      } catch (error) {
        console.error("Error fetching Users data", error);
        setAlert({
          severity: "error",
          message: `Error fetching Users data, kindly check the connection ${error.message}`,
        });
      }
    };

    const fetchUserTypes = async () => {
      try {
        const response = await UserService.getUsers(
          `${process.env.REACT_APP_BASE_URL}/get-user-type`
        );
        console.log("set user types", response.data.data);
        setUserTypes(response.data.data);
      } catch (error) {
        console.error("Error fetching user types:", error);
      }
    };

    const handleApplyCreatedby = () => {
      setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
      setFilters((prevFilters) => ({
        ...prevFilters,
        createdBy: tempSelectedcreatedby, // Set the filter
      }));
      setIsCreatedByPopupOpen(false); // Close the popup
    };

    const handleUpdateBySelection = (changedby) => {
      setTempSelectedupdatedby((prev) => {
        return prev.includes(changedby)
          ? prev.filter((item) => item !== changedby)
          : [...prev, changedby];
      });
    };

    const handleApplyUpdatedby = () => {
      setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
      setFilters((prevFilters) => ({
        ...prevFilters,
        updatedBy: tempSelectedupdatedby, // Set the filter
      }));
      setIsUpdatedByPopupOpen(false); // Close the popup
    };
    
    const handleFilterDateFrom = (e) => {
      console.log(filterType);
      if (filterType === "1") {
        setFilters((prevFilters) => ({
          ...prevFilters,
          created_date_from: e.target.value,
        }));
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          updated_date_from: e.target.value,
        }));
      }
    };

    const handleFilterDateto = (e) => {
      if (filterType === "1") {
        setFilters((prevFilters) => ({
          ...prevFilters,
          created_date_to: e.target.value,
        }));
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          updated_date_to: e.target.value,
        }));
      }
    };
    
    const resetFilters = () => {
      setFilters({
        status: "",
        roleId: "",
        userId: "",
        userType: "",
        roleuserType: "",
        validFrom: "",
        validTo: "",
        createdBy: "",
        createdDate: "",
        created_date_from: "",
        created_date_to: "",
        updatedBy: "",
        updated_date_from: "",
        updated_date_to: "",
      });
      // Clear selected users
      setHasFiltersApplied(false)
      
      setUsersToShow([]);
      setUsersToShow2([]);
      // setselectedRoles({}); // Reset selected users (uncheck all checkboxes)
      setSearchTerm("");
      localStorage.removeItem("filterData");
      localStorage.removeItem("fetchUsersData");
      localStorage.removeItem("usersToShow");
    };

    
      const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("set usertypes list data", value);
        // setUserType(value);
        // If you want to update filters as well:
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: value,
        }));
        console.log("filters handle change user type is",filters)
      };

      const handleUserFilterPopup = () => {
        console.log("entered");
        setIsUserModalOpen(true);
      };

      const handleUserFilterPopup2 = () => {
        console.log("entered");
        setIsUserModalOpen2(true);
      };

      const closeUserModal = () => {
        setIsUserModalOpen(false);
      };

      const closeUserModal2 = () => {
        setIsUserModalOpen2(false);
      };
         
  const handleCreatedBySelection = (createdBy) => {
    setTempSelectedcreatedby((prev) => {
      // Add or remove company from temporary selection list
      return prev.includes(createdBy)
        ? prev.filter((item) => item !== createdBy) // Remove if already selected
        : [...prev, createdBy]; // Add if not selected
    });
  };

    
  const getSelectDisplayLabel2 = () => {
    const count = usersToShow2.length;
    console.log("count", count);

    if (count === 1) {
      console.log("equal to one count");
      // Show the single selected user ID when only one is selected
      return usersToShow2[0];
    } else if (count > 1) {
      console.log("more than 1 count");
      console.log(`more than 1 count display ${usersToShow2[0]} +${count - 1}`);
      // Show the first user ID and the number of remaining users
      return `${usersToShow2[0]} +${count - 1}`;
    } else {
      // Default text when no users are selected
      return "Select User";
    }
  };

  const getSelectDisplayLabel = () => {
    const count = usersToShow.length;
    console.log("count", count);

    if (count === 1) {
      console.log("equal to one count");
      // Show the single selected user ID when only one is selected
      return usersToShow[0];
    } else if (count > 1) {
      console.log("more than 1 count");
      console.log(`more than 1 count display ${usersToShow[0]} +${count - 1}`);
      // Show the first user ID and the number of remaining users
      return `${usersToShow[0]} +${count - 1}`;
    } else {
      // Default text when no users are selected
      return "Select Role";
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    fetchRolesAndPermissionDetails(1, searchQuery); // Fetch data with updated page size
  };

  // PERMISSION SCREEN Global SEARCH FILTER USEEFFECT
     useEffect(() => {
        if (searchQuery.length === 0) {
          fetchRolesAndPermissionDetails(currentPage);
        } else {
          fetchSearchedPermissions(searchQuery);
        }
      }, [searchQuery]);

  useEffect(() => {
    console.log("check users filtered", rolesAndPermission);
    console.log("check users data", rolesAndPermission);

    if (rolesAndPermission && rolesAndPermission) {
      const filtered = rolesAndPermission.filter((user) =>
        user.userId && user.userId.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchQuery, rolesAndPermission]);


  const closeModal = () => {
    setIsModalOpen(false);
  };


  const handleApplyFilter = async () => {
    try {
      setIsModalOpen(false);
      // Prepare the filter payload with dynamic filters from state

      // localStorage.setItem('fetchUsersData', JSON.stringify(filteredUsers));
      // console.log()
      const filterPayload = {
        clientId: assignee.clientId,
        status: filters.status,
        roleId: usersToShow,
        userId: usersToShow2,
        // country: countryToShow,
        userType: filters.userType,
        roleuserType: filters.roleuserType,
        updatedBy: filters.updatedBy,
        // validTo: filters.validTo,
        createdBy: filters.createdBy,
        created_date_from: filters.created_date_from,
        created_date_to: filters.created_date_to,
        updated_date_from: filters.updated_date_from,
    updated_date_to: filters.updated_date_to,
      };
      console.log("filters userid", usersToShow);
      console.log("Filter payload:", filterPayload);

      // Fetch data from the server using the filter payload
      const response = await UserService.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/get-permissions-filter`,
        filterPayload
      );

      console.log("Response from server for filtered data:", response);

      // const { data, error, message } = response.data;

      // Handle errors if any
      // if (error) {
      //   console.error("Error fetching Users data:", message);
      //   setAlert({ severity: "error", message });
      //   return;
      // }

      console.log("testing 1", response.data);
      if(response.status === 200){
        setHasFiltersApplied(true);
        setFilteredUsers(response.data);
        setTotalCount(response.totalCount);
        setTotalPages(Math.ceil(response.totalCount / pageSize));
      }
      // setUsers(response.data); // Set user preference data
      // Store filtered users in session storage
      localStorage.setItem("filterData", JSON.stringify(filters));
      // localStorage.setItem("fetchUsersData", JSON.stringify(response.data));
      localStorage.setItem("usersToShow", JSON.stringify(usersToShow));
    } catch (error) {
      console.error("Error fetching Users data:", error);
      setAlert({
        severity: "error",
        message: `Error fetching Users data. Please check your connection: ${error.message}`,
      });
    }
  };


  const handleSortByRoleUserType = () => {
    const sortedRoles = rolesAndPermission
      .map((item) => ({
        ...item,
        highlight: item.rolesTable?.userType?.toString() !== item.User?.userType,  // Mark rows where roleUserType and userType differ
      }))
      .sort((a, b) => {
        // Sort rows with highlight === true to the top
        if (a.highlight && !b.highlight) return -1;
        if (!a.highlight && b.highlight) return 1;

        // Maintain original order for rows with the same highlight value
        return 0;
      });

    // Log the rows where roleUserType differs
    const differentUserTypes = sortedRoles.filter((item) => item.highlight);
    console.log("Rows with different userTypes (sorted):", differentUserTypes);

    // Update state
    console.log("sorted permission data", sortedRoles);
    setRolesAndPermission(sortedRoles);
  };


  const handleNavigate = (path) => {
    navigate(path);
  };

  useEffect(() => {
    // if (assignee.clientId) {
      fetchRolesAndPermissionDetails(currentPage);
    // }
  }, [currentPage]);

  useEffect(() => {
    fetchUsers();
    fetchAssignee();
  }, []);


  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  
  useEffect(() => {
    if (assignee.id) {
      fetchUserPreferenceDetails();
    }
  }, [assignee.id]);

  const fetchUserPreferenceDetails = async () => {
    try {
      const data = { userId: assignee.userId }; // Assuming assignee.id is the user ID
      console.log("Requestfor user pref Data:", data);

      setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log("user prefference details", response.data);
      setUserPreference(response.data); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

    useEffect(() => {
      if (assignee.assignerId) {
        console.log("Assigner ID has role id changed:", assignee.assignerId);
        fetchUserPermissionDetails();
      }
    }, [assignee.assignerId]);

  const fetchUserPermissionDetails = async () => {
    try {
      const data = { userId: assignee.assignerId };
      console.log("Request Data:", data);
  
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("user permissions details", response);
      const responseData = response.data;
      setRolesPermissions(responseData.rolesPermissionsTables || []); // Save permission details
      console.log("Set Permission details for userscreen", responseData.rolesPermissionsTables);
    } catch (error) {
      console.error(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/user/get-user`);
      console.log("Response from server fetching users:", response);
      const responseData = response.data.data;
      console.log('add last seen and users data', responseData);
      setUsers(responseData);

      console.log("double check users", response.data)
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
    }
  };

  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleEditClick = () => {
    if (selectedRows.length === 1) {
      console.log("entered user id");
      // console.log()
      // const selectedRole = users.find((user) => user.userId === selectedRows[0]);
      // console.log('get roles users', selectedRole);
      console.log('get role user id', selectedRows[0]);
      // console.log('single user id', selectedUser);

      if (selectedRows[0]) {
        console.log('selected role data', selectedRows[0]);
        navigate(`/14/admin/112/roles-and-permission/edit/${selectedRows[0]}`, { state: { mode: 'edit' } });
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to Assign Role.", {
        duration: 10000, // Set the toast duration to 10 seconds (5000ms)
      });
    }
  };

  const handleAuditTrail = () => {
    if (selectedRows.length === 1) {
      const selectedUser = rolesAndPermission.find((user) => user.userId === selectedRows[0]);
      console.log('user id', selectedRows[0]);

      if (selectedRows[0]) {
        // Show the selected user's details using toast
        // toast("info", `Selected User: \nID: ${selectedUser.userId}\nName: ${selectedUser.username}`);

        // Navigate to the edit screen, passing the selected user as state
        navigate(`/audit-trail-screen/${iconId}/${selectedRows[0]}`);
      } else {
        toast("error", "Selected user not found.");
      }
    } else {
      toast("error", "Please select exactly one user to edit.");
    }
  };

  const handleApproveClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-user-permission-approved`,
          { userIds: selectedRows, createdBy: assignee.assignerId, }  // Pass selectedUserIds in the body
        );
        console.log("Response status:", response.status);

        if (response.status === 200) {
          toast("success", "Permission successfully Approved");
           // Remove the selectedRows
           setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchRolesAndPermissionDetails(currentPage);
          // if (refreshTable.current) {
          //   refreshTable.current();  // Call the refresh function to reload the user data
          // }
        }
        else if (response.status === 400) {
          toast("error", "Permission is unable to approve.");
        }

        else {
          console.log("Error updating Permission.");
        }
      } catch (error) {
        console.error("Error updating Permission:", error);
      }
    } else {
      console.log("Please select at least one user to Approve.");
    }
  };

  const handleCancelClick = async () => {
    if (selectedRows.length > 0) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-rejected-user-permissions`,
          { userIds: selectedRows, createdBy: assignee.assignerId, }  // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("error", "Permission successfully Rejected");
          // Remove the selectedRows
          setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchRolesAndPermissionDetails(currentPage);
        }
        else if (response.status === 400) {
          toast("error", "Permission is unable to Reject.");
        } else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Reject.");
    }
  };

  const cancelDeletion = () => {
    setShowConfirmation(false);
  };

  const confirmDeletion = async () => {
    setShowConfirmation(false);
    setLoading(true);
    try {
      console.log("selected rows :", selectedRows);
      const response = await UserService.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/delete-user-permissions`,
        { userIds: selectedRows, createdBy: assignee.assigner }  // Pass selectedUserIds in the body
      );

      if (response.status === 200) {
        toast("error", "User Permissions successfully Deleted.");

        // Remove deleted users from the selectedRows
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
        );
        await fetchRolesAndPermissionDetails(currentPage);

      } else {
        console.log("Error updating users.");
      }
    } catch (error) {
      console.error("Error updating users:", error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = async () => {
    if (selectedRows.length === 1) {
      setShowConfirmation(true);


    } else {
      console.log("Please select at least one user to Delete.");
    }
  };

  const handleInactiveClick = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await UserService.saveapproved(
          `${process.env.REACT_APP_BASE_URL}/edit-inactive-user-permissions`,
          { userIds: selectedRows[0], createdBy: assignee.assignerId }  // Pass selectedUserIds in the body
        );

        if (response.status === 200) {
          toast("success", "User successfully Change Status.");
          // Remove the selectedRows
          setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((rowId) => !selectedRows.includes(rowId))
          );
          await fetchRolesAndPermissionDetails(currentPage);
        }
        else if (response.status === 400) {
          toast("error", "The user cannot be deactivated at this time.");
        }
        else {
          console.log("Error updating users.");
        }
      } catch (error) {
        console.error("Error updating users:", error);
      }
    } else {
      console.log("Please select at least one user to Inactive.");
    }
  };

  
const formatDate = (dateString, format) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const shortYear = String(year).slice(-2);

  const formatMap = {
      "DD/MM/YYYY": `${day}/${month}/${year}`,
      "MM/DD/YYYY": `${month}/${day}/${year}`,
      "DD/MM/YY": `${day}/${month}/${shortYear}`,
      "MM/DD/YY": `${month}/${day}/${shortYear}`,
      "DD.MM.YYYY": `${day}.${month}.${year}`,
      "DDMMYY": `${day}${month}${shortYear}`,
      "DD-MM-YYYY": `${day}-${month}-${year}`,
  };

  return formatMap[format] || dateString;
};
const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format



  const columns = [
    {
        Header: () => <IoPlay color="white" className='text-xs' />, // White play icon in header
        accessor: 'true', 
        visible: true,
        width: 25, 
        Cell: () => <IoPlay className='text-xs text-customGray' />,
        alignment: 'center', // Black play icon in rows
      },
    { Header: 'S.No', accessor: '', visible: true, width: ("S.No".length - 2) * 10, },
    { Header: 'Job Name', accessor: '', visible: true, width: ("Job Name".length - 2) * 10, },
    { Header: 'System User ID', accessor: '', visible: true, width: ("System User ID".length - 2) * 10, },
    { Header: 'Start Date', accessor: '', visible: true, width: ("Start Date".length - 2) * 10, },
    { Header: 'Valid To', accessor: '', visible: true, width: ("Valid To".length - 2) * 10, },
    { Header: 'Times', accessor: '', visible: true, width: ("Times".length - 2) * 10, },
    { Header: 'When', accessor: '', visible: true, width: ("When".length - 2) * 10, },
    { Header: 'Day', accessor: '', visible: true, width: ("Day".length - 2) * 10, },
    // { Header: 'Created Date', accessor: 'createdDate', visible: true, width: ("".length - 2) * 10, },
    // { Header: 'Created Date', accessor: 'createdDate', visible: true, width: ("".length - 2) * 10, canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; } },
{
      Header: "Created Date",
      accessor: "",
      visible: true,
      width: ("Created Date".length - 2) * 10,
      canResize: true,
      Cell: ({ value }) => formatDate(value, dateFormat),
  },
    { Header: 'Created Time', accessor: '', visible: true, width: ("Created Time".length - 2) * 10, },
    { Header: 'Updated By', accessor: '', visible: true, width: ("Updated By".length - 2) * 10, },
    // { Header: 'Updated Date', accessor: 'updatedDate', visible: true, width: ("".length - 2) * 10, },
    // { Header: 'Updated Date', accessor: 'updatedDate', visible: true, width: ("".length - 2) * 10, canResize: true, Cell: ({ value }) => { if (!value) return ""; const date = new Date(value); return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; } },
 {
          Header: "Updated Date",
          accessor: "",
          visible: true,
          width: ("Updated Date".length - 2) * 10,
          canResize: true,
          Cell: ({ value }) => formatDate(value, dateFormat),
      },
    { Header: 'Updated Time', accessor: '', visible: true, width: ("Updated Time".length - 2) * 10, },


  ];

  const [columnSettings, setColumnSettings] = useState(columns);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [Icons, setIcons] = useState([]);

  const extraButtons = [
      {
        icon: IoPlay, // Pass the component itself
        onClick: handleApproveClick,
        title: "Approve",
        disabled: selectedRows.length === 0,
      },
      {
        icon: MdOutlineStop,
        // onClick: handleRejectClick,
        title: "Reject",
        disabled: selectedRows.length === 0,
      },
      // {
      //   icon: IoMdCreate,
      //   // onClick: () => handleNavigate(`/bugroup-add/${buGroupId}`),
      //   title: "Edit BuGroup",
      //   disabled: selectedRows.length === 0,
      // },
  
      // {
      //   icon: PiCopy,
      //   // onClick: handleAddCopyClick,
      //   title: "Copy",
      //   disabled: selectedRows.length === 0,
      // },
      {
        icon: MdOutlineHistory,
        onClick: handleAuditTrail,
        title: "Audit Trail",
        disabled: selectedRows.length === 0,
      },
      {
        icon: IoMdTrash,
        // onClick: handleDelete,
        title: "Delete",
        disabled: selectedRows.length === 0,
      },
      // {
      //   icon: VscVmActive ,
      //   onClick: handleInactiveClick,
      //   title:"Inactive",
      //   disabled: selectedRows.length === 0,
      // },
    ];
  const reload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchRolesAndPermissionDetails(currentPage);  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };

    const handleDownload = () => {
          if (rolesAndPermission.length === 0) {
            setAlert({
              severity: "warning",
              message: "No data available to export!",
            });
            return;
          }
          console.log("donwload filteredUsers permission", filteredUsers);
          // Prepare data for export
          const formattedUsers = filteredUsers.map((user) => ({
            Status:
              user.status == 0
                ? "Created"
                : user.status == 1
                  ? "Changed"
                  : user.status == 2
                  ? "Waiting for Approval"
                  : user.status == 3
                  ? "Modified"
                  : user.status == 4
                  ? "Approved"
                  : user.status == 5
                        ? "Rejected"
                        : "Inactive",
            "UserId ID": user.userId,
            "User Type": user.User?.userTypeDetails?.name ?? "N/A",
            "Role ID": user.roleId,

            "Role Name": user.rolesTable?.name || "N/A",
            "Role User Type": user.rolesTable?.UserType?.name ?? "N/A",
            "Role Status": user.rolesTable?.status || "N/A",

            "Permissions Count": user.permissionsCount,
            "Creation By": user.createdBy,
            "Creation Date": formatDate(user.createdDate, dateFormat),
         
            // "Creation Date": user.createdDate,
            "Creation Time": user.createdTime,
              "Update By": user.updatedBy ? user.updatedBy : "",
      
              "Update Date": formatDate(user.updatedDate, dateFormat),
            "Update Time": user.updatedTime ? user.updatedTime : "",
          }));
      
          const worksheet = XLSX.utils.json_to_sheet(formattedUsers); // Converts JSON to sheet
  
      // Define border style
    const border = {
      top: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
    };
  
       // Apply borders to the first three rows
       formattedUsers.slice(0, 1).forEach((row, rowIdx) => {
            Object.keys(row).forEach((col, colIdx) => {
              const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
              if (worksheet[cellAddress]) {
                worksheet[cellAddress].s = { border };
              }
            });
          });
    
           // Auto-fit column widths
    const columnWidths = Object.keys(formattedUsers[0]).map((key) => ({
      wch: Math.max(
        key.length, // Column header width
        ...formattedUsers.map((row) => (row[key] ? row[key].toString().length : 0)) // Max content width
      ),
    }));
  
    worksheet["!cols"] = columnWidths;
    
          const workbook = XLSX.utils.book_new(); // Creates a new workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, "Users"); // Appends the sheet to the workbook
      
          // Export as an Excel file
          XLSX.writeFile(workbook, "Permissions_Data.xlsx");
        };
  
        const UserNotExistsToast = async () => {
          toast("error", "Permission will be enabled after creating Users.");
        };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const existingButtons = AdminActionsConfig.screen11.map((button) => {
    let onClick;
    let title;


    if (button.icon === IoMdRefresh) {
      onClick = reload; // Reference the reload function, not invoking it
      title = "Refresh";
    }
    else if (button.icon === IoAddCircleOutline) {
      if(checkingUserExists.length === 0) {
        onClick = UserNotExistsToast;
      }
      else{
        onClick = () => handleNavigate("/schedule-add");
        title = "Add Permission";
      }
    }
    else if (button.icon === IoSettingsOutline) {
      onClick = handleSettingsButtonClick;
      title = "Table Settings";
    }
    else if (button.icon === GiSettingsKnobs) {
      onClick = () => {
        setIsModalOpen(true);
      }
      title = "Filter";
    }
    
    else if (button.icon === MdOutlineFileDownload) {
      onClick = handleDownload;
      title = "Download File";
    }
    else if (button.icon === PiNotEquals) {
      onClick = handleSortByRoleUserType;
      title = "Inconsistence User Types";
    }
    else {
      onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
      title = button.title || ""; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title,   // Add the title property to the button configuration
    };
  });

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );



  const modifiedColumns = columnSettings
    .filter(col => col.visible)
    .map(col => ({ ...col, width: col.width }));


    const handleSelectAll = () => {
      handleSelectAllChange();
    };

    const handleSelectAllChange = () => {
      console.log("rolesAndPermission data check all",rolesAndPermission);
      if (isAllSelected) {
        setSelectedRows([]); // Deselect all
      } else {
        const allIds = rolesAndPermission.map((item) => item.userId); // Select all IDs
        setSelectedRows(allIds);
      }
      setIsAllSelected(!isAllSelected); // Toggle select all state
    };
  

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleCheckboxChangefilter = (e) => {
    const { name, checked } = e.target;
    setselectedRoles((prev) => ({ ...prev, [name]: checked }));
  };

  const handleCheckboxChangefilter2 = (e) => {
    const { name, checked } = e.target;
    setSelectedUsers((prev) => ({ ...prev, [name]: checked }));
  };

  const handleCheckboxChange = (id) => {
    console.log('checked user id', id);
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

      fetchArrayPermissionDetails(id);

    setSelectedRows(updatedSelectedRows);
    console.log('check box:', updatedSelectedRows);

  };

  const fetchArrayPermissionDetails = async (userIds) => {
    try {
      // const userId = userIds.map((id) => ({ id }));
      console.log("assignees data", assignee);

      const formattedUserIds = Array.isArray(userIds) ? userIds : [userIds];

      const data = { clientId: assignee.clientId, userIds: formattedUserIds };
      console.log("Request Data for users:", data);

      // const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-all-users-permissions`);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-all-users-permissions`,
        data
      );
      console.log("Response from server fetching Permission in permission screen:", response);
      const status = response.data[0]?.status;
      console.log("Extracted status:", status);
      setUserStatus(status);
      // Handle the API response as needed, e.g., update state or notify user
    } catch (error) {
      console.error("Error fetching user permissions:", error);
      // Optionally handle the error state
    }
  };

  const handleDoubleClick = (id) => {
    console.log("check rolesandpermission id", id);
    const hasPermission = rolesPermissions.some(
      (permission) => permission.permissionsId === 3 && permission.iconId === parseInt(iconId)
    );
    if (hasPermission) {
    navigate(`/14/admin/112/roles-and-permission/view/${id}`, { state: { mode: 'view' } });
    console.log("Navigated to user view screen.");
  } else {
    console.log(
      "No permission with permissionsId === 3 and matching iconId, navigation skipped."
    );
  }
  };


  const fetchSearchedPermissions = async (searchQuery) => {
    // console.log("searchQuery Us", searchQuery);
    const data = { searchQuery };
    console.log("Request Data for search query:", data);
    
    setLoading(true);
    try {
      // const response = await UserService.saveContactDetails(
      //   `${process.env.REACT_APP_BASE_URL}/search-by-userId`,
      //   data);
      //   console.log("search response",response);

      // if (response && response.data) {
      //   const totalCount = Number(response.totalCount) || 0;
      //   const pages = Math.ceil(totalCount / (pageSize || 1));
      //   setUsers(response.data);
      //   setTotalCount(totalCount);
      //   setTotalPages(pages);
      // }
    } catch (error) {
      console.error("Error fetching Bank Account Data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (assignee.clientId) {
      fetchRolesAndPermissionDetails();
    }
  }, [assignee]);

  const fetchRolesAndPermissionDetails = async (page, searchQuery) => {
    setLoading(true);
    try {
      // const searchParam = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : "";
      console.log("searchQuery is", searchQuery);

      console.log("assignees data", assignee);
      const data = {
        clientId: assignee.clientId,
        limit: pageSize || 10,
        offset: page && pageSize ? (page - 1) * pageSize : 0,
        searchQuery: searchQuery || "",
      };
      console.log("Request Data for users:", data);

      // const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-all-users-permissions`);      
      const response = await UserService.saveContactDetails(`${process.env.REACT_APP_BASE_URL}/get-all-users-permissions-pagination`, data);
      console.log("Response from server fetching permission screen data:", response);
      let { searchUsers, totalCount } = response;


      if (!searchUsers) {
        searchUsers = [];
      } else if (!Array.isArray(searchUsers)) {
        searchUsers = [searchUsers];
      }



      console.log('set roles details', searchUsers);
      setRolesAndPermission(searchUsers);
      setTotalCount(totalCount);
      setTotalPages(Math.ceil(totalCount / pageSize))
      // if (responseData.error) {
      //   console.error("Error fetching roles data:", responseData.message);
      //   setAlert({ severity: "error", message: responseData.message });
      //   return;
      // }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({ severity: "error", message: `Error fetching roles data, kindly check the connection ${error.message}` });
    } finally {
      setLoading(false);
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Interface Setup') {
      navigate('/14/admin');
    } else if (crumb.title === 'Admin') {
      navigate('/14/admin');
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the specified path in the breadcrumb
    }
  };

  return (
    <div>

      <Header
        title="Admin > Interface Setup > Schedule"
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/14/admin"}
      />

      <div>
        <div className="flex justify-between items-center -ml-1 pl-4">
          <div className="flex items-center">
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search by Job Name"
              maxLength={12}
            />

            <AdminActions
              icons={iconId}
              buttons={actionButtons}
              // extraButtons={extraButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows}
              hasFiltersApplied={hasFiltersApplied}
               />

          </div>
          <div className="flex items-center mr-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              onShowAll={handleShowAll}
            />
          </div>

        </div>
      </div>

  {/* Modal */}
  {isModalOpen && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">

  <Draggable handle=".modal-header">   
       {/* Backdrop */}
     <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 z-50">

       {/* Modal Content */}
       <div className="relative bg-white rounded-lg p-6 w-10/12 max-w-3xl shadow-lg">
         <div className="modal-header flex justify-between items-center cursor-move p-4 bg-white-100 rounded-t-lg">
               <h2 className="text-customBlue font-semibold mb-5">
                 Apply Filters
               </h2>

               {/* <div className={"p-2"}> */}
               <HoverButton
                 onClick={resetFilters}
                 title={"Reset Filter"}
               />
               {/* </div> */}

         </div>

           {/* Form grid */}
           <div className="col-span-2 sm:col-span-1">
               {/* Status */}
               <div className="flex items-center mb-4">
                 <label className="text-customGray text-xs mr-2 w-[140px]">
                   Status:
                 </label>
               
                 <select
                   name="status"
                   value={filters.status}
                   onChange={handleChange}
                   className="text-customGray text-xs border border-gray-300 pl-1 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 "
                 >
                 <option value="" className=" text-blue-500">
                     Select Status
                   </option>
                   <option value="0" className=" text-gray-500">
                     Created
                   </option>
                   <option value="1" className=" text-[#AF52DE]">
                     Changed
                   </option>
                  
                   <option value="2 " className=" text-yellow-500">
                     Waiting for Approval
                   </option>
                   <option value="3" className=" text-orange-400">
                     Modified
                   </option>
                   <option value="4" className=" text-[#29CB00]">
                     Approved
                   </option>
                   <option value="5" className=" text-red-500">
                     Rejected
                   </option>
                   <option value="6" className=" text-black">
                     Inactive
                   </option>
                 </select>
               </div>

   {/* User id AN user type */}
   <div className="grid grid-cols-2 gap-4">
            {/* User ID & User Type in the same row */}
            <div className="col-span-2 flex justify-between items-center mb-4">
              {/* User ID */}
              <div className="flex items-center">
                <label className="text-customGray text-xs mr-2 w-[140px]">User ID:</label>
                <button
                  onClick={handleUserFilterPopup2}
                  className="border border-gray-300 p-2 rounded-md w-[165px] h-[40px] text-customGray focus:outline-none hover:border-blue-400 text-left flex items-center justify-between"
                >
                  <span className="truncate text-xs">
                    {filters.userId.length > 0 ? filters.userId.join(", ") : getSelectDisplayLabel2() || "Select User"}
                  </span>
                  <RxDropdownMenu className="ml-2 text-lg" />
                </button>

      {isUserModalOpen2 && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
          <div className="flex justify-between items-center mb-4">
              <h2 className="text-customBlue font-semibold">
                Select Users
              </h2>
              {/* <button className="text-red-500" onClick={closeUserModal2}>
                &times;
              </button> */}
              <HoverButton
                onClick={handleBankAccountIdReset}
                title={"Reset"}
              />
            </div>

            {/* Search Input */}
            <input
              type="text"
              placeholder="Search Users..."
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
              className="border text-customGray text-xs border-gray-300 rounded-md p-2 mb-4 w-full"
            />

            {/* Scrollable Checkbox Grid */}
            <div className="space-y-2 max-h-80 overflow-y-auto">
              {rolesAndPermission
                .filter(
                  (user) =>
                    user.userId &&
                    user.userId.toLowerCase().includes(searchTerm2.toLowerCase())
                )
                .reduce((acc, user, index) => {
                  const columnIndex = Math.floor(index / 10);
                  acc[columnIndex] = acc[columnIndex] || [];
                  acc[columnIndex].push(user);
                  return acc;
                }, [])
                .map((column, columnIndex) => (
                  <div key={columnIndex} className="flex flex-col space-y-2">
                    {column.map((user) => (
                      <label
                        key={user.value}
                        className="flex items-center text-xs text-customGray"
                      >
                        <input
                          type="checkbox"
                          name={user.userId}
                          checked={selectedUsers[user.userId] || false}
                          onChange={handleCheckboxChangefilter2}
                          className="mr-2"
                        />
                        {user.userId}
                      </label>
                    ))}
                  </div>
                ))}
            </div>

            {/* <div className="mt-8">
              <button
                onClick={handleSubmitUsers}
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Submit
              </button>
            </div> */}
            <div className="flex justify-end mt-4">
                                                 <HoverButton
                                                   title={"Cancel"}
                                                   className={"mr-2"}
                                                   onClick={closeUserModal2}
                                                 />
                                                 <CustomButton
                                                   onClick={() => {
                                                     // setIsBankAccountIdPopupOpen(false);
                                                     handleSubmitUsers();
                                                   }}
                                                   title={"Apply"}
                                                 />
                                               </div>
          </div>
        </div>
      )}

              </div>
              
              {/* User Type */}
              <div className="flex items-center">
                <label className="text-customGray text-xs mr-2 w-[140px]">User Type:</label>
                <select
                  name="userType"
                  value={filters.userType}
                  onChange={handleChange}
                  className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                >
                  <option className="text-sm" value="">Select user type</option>
                  {userTypes.map((type) => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  ))}
                </select>
              </div>
            </div>
            </div>
            
               {/* Role ID and role user type*/}
               
            <div className="grid grid-cols-2 gap-4">
              {/* Role ID & Role User Type in the same row */}
              <div className="col-span-2 flex justify-between items-center mb-4">
                {/* Role ID */}
                <div className="flex items-center">
                  <label className="text-customGray text-xs mr-2 w-[140px]">Role ID:</label>
                  <button
                    onClick={handleUserFilterPopup}
                    className="border border-gray-300 p-2 rounded-md w-[165px] h-[40px] text-customGray focus:outline-none hover:border-blue-400 text-left flex items-center justify-between"
                  >
                    <span className="truncate text-xs">
                      {filters.roleId.length > 0 ? filters.roleId.join(", ") : getSelectDisplayLabel() || "Select Role"}
                    </span>
                    <RxDropdownMenu className="ml-2 text-lg" />
                  </button>
                    {isUserModalOpen && (
                                           <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
                                           <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                                             <div className="flex justify-between items-center mb-4">
                                               <h2 className="text-customBlue font-semibold">
                                                   Select Roles
                                                 </h2>
                                                 {/* <button className="text-red-500" onClick={closeUserModal}>
                                                   &times;
                                                 </button> */}
                                                 <HoverButton
                                                   onClick={handleRolesIdReset}
                                                   title={"Reset"}
                                                 />
                                               </div>
                                   
                                               {/* Search Input */}
                                               <input
                                                 type="text"
                                                 placeholder="Search Roles..."
                                                 value={searchTerm}
                                                 onChange={(e) => setSearchTerm(e.target.value)}
                                                 className="border text-customGray text-xs border-gray-300 p-2 rounded-md mb-4 w-full"
                                               />
                                   
                                               {/* Scrollable Checkbox Grid */}
                                             
                                   <div className="space-y-2 max-h-80 overflow-y-auto">
                                        {[
                                          ...new Map(
                                            filteredUsers
                                              .filter(
                                                (role) =>
                                                  role.roleId &&
                                                  role.roleId.toLowerCase().includes(searchTerm.toLowerCase())
                                              )
                                              .map((role) => [role.roleId, role]) // Remove duplicates using Map
                                          ).values(),
                                        ]
                                          .reduce((acc, role, index) => {
                                            const columnIndex = Math.floor(index / 10);
                                            acc[columnIndex] = acc[columnIndex] || [];
                                            acc[columnIndex].push(role);
                                            return acc;
                                          }, [])
                                          .map((column, columnIndex) => (
                                            <div key={columnIndex} className="flex flex-col space-y-2">
                                              {column.map((role) => (
                                                <label key={role.value} className="flex items-center text-xs text-customGray">
                                                  <input
                                                    type="checkbox"
                                                    name={role.roleId}
                                                    checked={selectedRoles[role.roleId] || false}
                                                    onChange={handleCheckboxChangefilter}
                                                    className="mr-2"
                                                  />
                                                  {role.roleId}
                                                </label>
                                              ))}
                                            </div>
                                          ))}
                                      </div>

                                               <div className="flex justify-end mt-4">
                                                 <HoverButton
                                                   title={"Cancel"}
                                                   className={"mr-2"}
                                                   onClick={closeUserModal}
                                                 />
                                                 <CustomButton
                                                   onClick={() => {
                                                     // setIsBankAccountIdPopupOpen(false);
                                                     handleSubmitRoles();
                                                   }}
                                                   title={"Apply"}
                                                 />
                                               </div>
                                             </div>
                                           </div>
                                         )}
                                         
                </div>
                
                {/* Role User Type */}
                <div className="flex items-center">
                  <label className="text-customGray text-xs mr-2 w-[140px]">Role User Type:</label>
                  <select
                    name="roleuserType"
                    value={filters.roleuserType}
                    onChange={handleChange}
                    className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                  >
                    <option className="text-sm" value="">Select user type</option>
                    {userTypes.map((type) => (
                      <option key={type.id} value={type.id}>{type.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              </div>

            

           </div>

           <div className="col-span-2 flex justify-between items-center">
                           
           </div>

              


                <div className="flex items-center mb-4">
                               {/* Select Dropdown */}
                               <select
                                 name="filterType"
                                 value={filterType} // State to track the selected filter option
                                 onChange={(e) => {
                                   setFilterType(e.target.value); // Update the selected filter
                                 }}
                                 className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[120px] h-[40px] focus:outline-none hover:border-blue-400"
                               >
                                 <option value="1">Created By</option>
                                 <option value="2">Updated By</option>
                               </select>
               
                               {/* Filter Button */}
                               <button
                                 className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-6 "
                                 onClick={() => {
                                   if (filterType === "1") {
                                     setIsCreatedByPopupOpen(true);
                                     setTempSelectedcreatedby(selectedCreatedBy);
                                   } else if (filterType === "2") {
                                     setIsUpdatedByPopupOpen(true);
                                     setTempSelectedupdatedby(selectedUpdatedBy);
                                   }
                                 }}
                                 disabled={!filterType} // Disable button if no filter is selected
                               >
                                 <span className="truncate">
                                   {filterType === "1"
                                     ? selectedCreatedBy.length > 0
                                       ? selectedCreatedBy.join(", ")
                                       : "Created By"
                                     : filterType === "2"
                                       ? selectedUpdatedBy.length > 0
                                         ? selectedUpdatedBy.join(", ")
                                         : "Updated By"
                                       : "Select Filter"}
                                 </span>
                                 <RxDropdownMenu className="ml-2 text-lg" />
                               </button>
                             </div>
           
                 {/* Created/Updated by */}
                 
   
                 {isCreatedByPopupOpen && (
                   <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                     <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                       <div className="flex justify-between items-center mb-4">
                         <h2 className="text-customBlue font-semibold">
                           Select Created By
                         </h2>
                         <HoverButton
                           // onClick={handleCreatedbyReset}
                           title={"Reset"}
                         />
                       </div>
                       {/* Search and Filter */}
                       <input
                         type="text"
                         className="border border-gray-300 p-2 text-xs rounded-md mb-4 w-full"
                         placeholder="Search User Id"
                         value={createdbydatasearchQuery}
                         onChange={(e) => setcreatedbydatasearchQuery(e.target.value)}
                       />
                       <div className="space-y-2 max-h-80 overflow-y-auto">
                        
                         {[...new Set(filteredUsers.map((created) => created.createdBy))].map((uniqueCreatedBy) => (
                           <div key={uniqueCreatedBy} className="flex items-center">
                             <input
                               type="checkbox"
                               checked={tempSelectedcreatedby.includes(uniqueCreatedBy)}
                               onChange={() => handleCreatedBySelection(uniqueCreatedBy)}
                             />
                             <label className="ml-2 text-customGray text-xs">{uniqueCreatedBy}</label>
                           </div>
                         ))}


                       </div>
   
                       {/* Actions */}
                       <div className="flex justify-end mt-4">
                         <HoverButton
                           title={"Cancel"}
                           className={"mr-2"}
                           onClick={() => setIsCreatedByPopupOpen(false)}
                         />
                         <CustomButton
                           onClick={() => {
                             setIsCreatedByPopupOpen(false);
                             handleApplyCreatedby();                          // applyCreatedByFilter(); // Apply the selected filter
                           }}
                           title={"Apply"}
                         />
                       </div>
                     </div>
                   </div>
                 )}
   
                 {/* Updated By Popup */}
                 {isUpdatedByPopupOpen && (
                   <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
                     <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-xl z-50">
                       <div className="flex justify-between items-center mb-4">
                         <h2 className="text-customBlue font-semibold">
                           Select Updated By
                         </h2>
                         <HoverButton
                           //  onClick={resetSelectedUpdatedBy}
                           title={"Reset"}
                         />
                       </div>
                       {/* Search and Filter */}
                       <input
                         type="text"
                         className="border border-gray-300 p-2 rounded-md mb-4 w-full"
                         placeholder="Search Updated By"
                       value={updatedbysearchquery}
                       onChange={(e) => setupdatedbysearchquery(e.target.value)}
                       />
                       <div className="space-y-2 max-h-80 overflow-y-auto">
                       
                       {[...new Set(filteredUsers.map((updated) => updated.updatedBy))].map((uniqueUpdatedBy) => (
                           <div key={uniqueUpdatedBy} className="flex items-center">
                             <input
                               type="checkbox"
                               checked={tempSelectedupdatedby.includes(uniqueUpdatedBy)}
                               onChange={() => handleUpdateBySelection(uniqueUpdatedBy)}
                             />
                             <label className="ml-2">{uniqueUpdatedBy}</label>
                           </div>
                         ))}

                       </div>
                       {/* Actions */}
                       <div className="flex justify-end mt-4">
                         <HoverButton
                           title={"Cancel"}
                           className={"mr-2"}
                           onClick={() => setIsUpdatedByPopupOpen(false)}
                         />
                         <CustomButton
                           onClick={() => {
                             handleApplyUpdatedby();
                             setIsUpdatedByPopupOpen(false);
                             // applyUpdatedByFilter(); // Apply the selected filter
                           }}
                           title={"Apply"}
                         />
                       </div>
                     </div>
                   </div>
                 )}
   
                 {filterType && (
                   <div className="col-span-2 flex justify-between items-center">
                     <div className="flex items-center">
                       <label className="text-customGray text-xs mr-2 w-[140px]">
                         {filterType === "createdBy"
                           ? " Date From:"
                           : " Date From:"}
                       </label>
                       <input
                         type="date"
                         name="dateFrom"
                         value={
                           filterType === "1"
                             ? filters.created_date_from
                             : filters.updated_date_from
                         }
                         onChange={(e) => handleFilterDateFrom(e)}
                         className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                       />
                     </div>
                     <div className="flex items-center">
                       <label className="text-customGray text-xs mr-2 w-[140px]">
                         {filterType === "createdBy" ? " Date To:" : " Date To:"}
                       </label>
                       <input
                         type="date"
                         name="dateTo"
                         value={
                           filterType === "1"
                             ? filters.created_date_to
                             : filters.updated_date_to
                         }
                         onChange={(e) => handleFilterDateto(e)}
                         className="text-customGray text-xs border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
                       />
                     </div>
                   </div>
                 )}

        

       
         {/* Apply Button */}
         {/* <div className="mt-6 text-right">
           <button
             onClick={closeModal}
             className="bg-white text-customBlue border-customTableBorder px-4 py-2"
           >
             Cancel
           </button>
           <button
             className="bg-customBlue text-white px-4 py-2 rounded-md hover:bg-blue-600"
             onClick={handleApplyFilter} // Call the fetch function on Apply
           >
             Apply
           </button>
         </div> */}
       <div className="mt-6 flex justify-end space-x-3">
                                          <HoverButton
                                            onClick={closeModal}
                                            title={"Cancel"}
                                          />
                                          <CustomButton
                                            title={"Apply"}
                                            onClick={() => {
                                              handleApplyFilter();
                                            }}
                          
                                          />
                                        </div>
                                        
       </div>
       
       </div>
     </Draggable>
     </div>
   )}



      <div>
        {loading &&
          <div><CustomeLoading /></div>
        }

        {/* Table Component */}
        <ReTable
          data={filteredUsers}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectAll={handleSelectAll}
          onSelectRow={handleSelectRow}
          onRowDoubleClick={handleDoubleClick}
          rowKey="userId"  // Pass userId as the key for row selection
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}

      <div>
        <ConfirmationDialog
          open={showConfirmation}
          title="Confirm Deletion"
          message="Are you sure you want to delete this selected Permission ID?"
          onConfirm={confirmDeletion}
          onCancel={cancelDeletion}
        />
      </div>
    </div>
  )
}

