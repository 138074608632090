import React, { useState, useEffect } from "react";
import AdminService from "../../rest-services/AdminServices";
import { Snackbar, Alert } from "@mui/material";
import UserService from "../../rest-services/UserServices";
import { useToast } from "../../components/toast/toast";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import HomeService from "../../rest-services/HomeServices";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../components/Textfield";
import CustomButton from "../../components/CustomButton";

const Home = () => {
  const [user, setUser] = useState({ id: "", email: "" });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isExpirePopupOpen, setIsExpirePopupOpen] = useState(true);
  const { toast } = useToast();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [passwordPolicy, setPasswordPolicy] = useState(null);

  useEffect(() => {
    fetchUser();
    handleOpenPopup();
    handleGetPolicy();
  }, []);

  useEffect(() => {
    if (user.id.length > 0) {
      handleExpirePopup();
    }
  }, [user]);

  const handleOpenPopup = () => {
    const userDetailString = sessionStorage.getItem("userDetail");
    if (userDetailString) {
      const userDetail = JSON.parse(userDetailString);
      if (userDetail.oneTimePassword === "1") {
        setIsPopupOpen(true);
      } else {
        console.log("Not opening popup, oneTimePassword is not 1");
      }
    }
  };

  const handleGetPolicy = async () => {
    try {
      const response = await AdminService.getPasswordPolicy();
      console.log(response.data);
      setPasswordPolicy(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const fetchUser = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUser({
          id: userDetail.id || "",
          email: userDetail.email || "",
          OTP: userDetail.oneTimePassword,
        });
      } else {
        setSnackbarMessage("No user detail found in session storage.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage(`Error fetching user details: ${error.message}`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const [expireMessage, setExpireMessage] = useState("");
  const [countdown, setCountdown] = useState(null);

  const handleExpirePopup = async () => {
    try {
      const today = new Date().toISOString().split("T")[0];
      const laterDate = localStorage.getItem("passwordPopupLaterDate");
      if (laterDate === today) {
        setIsExpirePopupOpen(false);
        return;
      }
      const response = await HomeService.getexpired(user.id);
      if (response.message === "No active password found for user.") {
        setIsExpirePopupOpen(false);
        return;
      }
      if (response.data.message === "Your password is still valid.") {
        setIsExpirePopupOpen(false);
        return;
      }
      if (response.data.data === 1) {
        const countdownSeconds = 5;
        setExpireMessage(`${response.data.message}\n You are Going to log out`);
        toast("error", response.data.message);
        sessionStorage.removeItem("encodedCredentials");
        const timer = setTimeout(() => {
          setIsExpirePopupOpen(false);
          window.location.href = "/";
        }, countdownSeconds * 1000);
        setIsExpirePopupOpen(true);
        setCountdown(countdownSeconds);
      } else if (response.status === 200) {
        setExpireMessage(response.data.message);
        setIsExpirePopupOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const Popup = ({ title, onClose }) => {
    const [questions, setQuestions] = useState([]);
    const [otp, setOTP] = useState("");
    const [newPass, setNewPass] = useState("");
    const [reEnterPass, setReEnterPass] = useState("");
    const [selectedQuestions, setSelectedQuestions] = useState({
      question1: "",
      question2: "",
      question3: "",
    });
    const [answers, setAnswers] = useState({
      answer1: "",
      answer2: "",
      answer3: "",
    });

    useEffect(() => {
      fetchDataForDropDown();
    }, []);

    const fetchDataForDropDown = async () => {
      try {
        const response = await AdminService.getQuestion();
        setQuestions(response.data.data.questions);
      } catch (error) {}
    };

    const handleQuestionChange = (e, index) => {
      const { value } = e.target;
      setSelectedQuestions((prev) => ({
        ...prev,
        [`question${index}`]: value,
      }));
    };

    const handleAnswerChange = (e, index) => {
      setQuestionsErr("");
      const { value } = e.target;
      setAnswers((prev) => ({
        ...prev,
        [`answer${index}`]: value,
      }));
    };

    const availableQuestions = (index) => {
      const selectedIds = Object.keys(selectedQuestions)
        .filter((key) => key !== `question${index}`)
        .map((key) => selectedQuestions[key])
        .filter(Boolean);
      return questions.filter((q) => !selectedIds.includes(q.id.toString()));
    };

    const [questionsErr, setQuestionsErr] = useState("");
    const handleSubmit = async () => {
      try {
        if (!otp) {
          setOtpErr("Please Enter OTP");
          return;
        }
        if (
          !selectedQuestions.question1 ||
          !selectedQuestions.question2 ||
          !selectedQuestions.question3 ||
          !answers.answer1 ||
          !answers.answer2 ||
          !answers.answer3
        ) {
          setQuestionsErr("Please fill all Questions and Answers.");
          return;
        }

        const data = {
          userId: user.id,
          currentPass: otp,
          newPassword: newPass,
          security1: selectedQuestions.question1,
          security2: selectedQuestions.question2,
          security3: selectedQuestions.question3,
          answer1: answers.answer1,
          answer2: answers.answer2,
          answer3: answers.answer3,
        };

        const response = await UserService.saveresetPassword(data);

        if (response.status === 200) {
          // Trigger the popup with countdown before redirecting
          showLogoutCountdown();
        } else {
          const errorListHTML = (
            <ul>
              {response.errors.map((error, index) => (
                <li key={index}>*{error}</li>
              ))}
            </ul>
          );

          toast("error", errorListHTML);
        }
      } catch (err) {
        toast("error", err);
      }
    };

    // Countdown state and popup logic
    const [countdown, setCountdown] = useState(5); // Initial countdown of 5 seconds
    const [showPopup, setShowPopup] = useState(false);

    const showLogoutCountdown = () => {
      setShowPopup(true);
      let timer = 5;

      const intervalId = setInterval(() => {
        setCountdown(--timer);

        if (timer === 0) {
          clearInterval(intervalId);
          logoutAndRedirect();
        }
      }, 1000);
    };

    const logoutAndRedirect = () => {
      sessionStorage.removeItem("encodedCredentials");
      window.location.href = "/";
      handleClosePopup();
    };

    const [isPasswordPolicyOpen, setIsPasswordPolicyOpen] = useState(false);
    const onOpenPasswordPolicy = () => {
      setIsPasswordPolicyOpen(true);
    };

    const onClosePasswordPolicy = () => {
      setIsPasswordPolicyOpen(false);
    };

    //For Otp Validation
    const [otpErr, setOtpErr] = useState("");
    const [isOtpValid, setIsOtpValid] = useState(false);
    const verifyOTP = async () => {
      try {
        const response = await HomeService.getOtpVerify(user.id, otp);

        if (!response.data.message) {
          setOtpErr("Invalid One Time Password");

          setIsOtpValid(false);
        } else {
          setOtpErr("");
          setIsOtpValid(true);
        }
      } catch (error) {
        console.error("Error during OTP verification:", error);
      }
    };

    const validateOtp = (value) => {
      setOTP(value);
      if (otpErr) {
        setOtpErr("");
      }
      setIsOtpValid(false);
    };
    //For Password Validation
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const validateNewPassword = async (value) => {
      setNewPass(value);
      try {
        if (!value) {
          console.error("Password value is undefined or empty.");
          return;
        }

        let pass = value;
        const response = await HomeService.validateNewPassword(pass);
        if (response) {
          if (response.data.message === "Password is valid.") {
            setPasswordError("");
            return;
          }
          setPasswordError(response.data.message);
        }
      } catch (error) {
        console.error("Error validating new password:", error);
      }
      if (reEnterPass && value !== reEnterPass) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    };

    const validateConfirmPassword = (value) => {
      setReEnterPass(value);

      if (newPass !== value) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    };

    return (
      <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
        <div className="ml-16 bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-3xl h-half relative">
          <button
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            onClick={onClose}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <button
            className="absolute top-5 right-16 text-gray-600 hover:text-gray-800"
            onClick={onOpenPasswordPolicy}
          >
            <BsFillQuestionCircleFill />
          </button>
          <h2 className="text-xl font-semibold mb-4 ml-72 font-md text-md text-blue-600">
            {title}
          </h2>
          {isPasswordPolicyOpen && (
            <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 max-w-lg">
                <h3 className="text-lg font-semibold mb-4">Password Policy</h3>
                <p>
                  - Minimum length: {passwordPolicy.password_length_min} <br />-
                  Maximum length: {passwordPolicy.password_length_max} <br />-
                  Requires capital letters: {passwordPolicy.capital_letters}{" "}
                  <br />- Requires numerical characters:{" "}
                  {passwordPolicy.numerical_characters} <br />- Requires special
                  characters: {passwordPolicy.special_characters} <br />
                </p>
                <button
                  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
                  onClick={onClosePasswordPolicy}
                >
                  Close
                </button>
              </div>
            </div>
          )}
          <div>
            <div className="p-2">
              <div className="flex flex-col gap-y-4 ml-8">
                <div className="w-full">
                  <label className="flex items-center">
                    <span className="w-72 text-sm font-medium text-gray-500">
                      One Time Password
                    </span>
                    <span className="ml-3">:</span>
                    <div className="ml-4 w-72">
                      <PasswordField
                        placeholder=""
                        onChange={(e) => validateOtp(e.target.value)}
                        className={`border p-1.5 rounded w-full h-8 focus:outline-none hover:border-blue-400 ${
                          otpErr
                            ? "border-red-500"
                            : isOtpValid
                            ? "border-green-500"
                            : "border-gray-300"
                        }`}
                        onBlur={verifyOTP}
                      />
                      {otpErr && (
                        <span className="text-red-500 text-sm">{otpErr}</span>
                      )}
                    </div>
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <label className="flex items-center mt-4">
                    <span className="w-72 text-sm font-medium text-gray-500">
                      Enter New Password
                    </span>
                    <span className="ml-3">:</span>
                    <div className="ml-4 w-72">
                      <PasswordField
                        placeholder="Enter New Password"
                        onChange={(e) => validateNewPassword(e.target.value)}
                        className={`border p-1.5 rounded w-full h-8 focus:outline-none hover:border-blue-400 ${
                          passwordError
                            ? "border-red-500"
                            : newPass && reEnterPass && newPass === reEnterPass
                            ? "border-green-500"
                            : "border-gray-300"
                        }`}
                      />
                      {passwordError && (
                        <span className="text-red-500 text-sm">
                          {passwordError}
                        </span>
                      )}
                    </div>

                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <label className="flex items-center mt-4">
                    <span className="w-72 text-sm font-medium text-gray-500">
                      Confirm New Password
                    </span>
                    <span className="ml-3">:</span>
                    <div className="ml-4 w-72 mt-4">
                      <PasswordField
                        placeholder="Confirm New Password"
                        onChange={(e) =>
                          validateConfirmPassword(e.target.value)
                        }
                        className={`border p-1.5 rounded w-full h-8 focus:outline-none hover:border-blue-400 ${
                          confirmPasswordError
                            ? "border-red-500"
                            : newPass && reEnterPass && newPass === reEnterPass
                            ? "border-green-500"
                            : "border-gray-300"
                        }`}
                      />
                      {confirmPasswordError && (
                        <span className="text-red-500 text-sm">
                          {confirmPasswordError}
                        </span>
                      )}
                    </div>
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                </div>
                {[1, 2, 3].map((index) => (
                  <label className="flex items-center mt-4" key={index}>
                    <select
                      className={`border p-2 rounded w-72 h-10 mr-4 focus:outline-none hover:border-blue-400 ${
                        selectedQuestions[`question${index}`]
                          ? "border-green-500"
                          : "border-gray-600"
                      }`}
                      value={selectedQuestions[`question${index}`] || ""}
                      onChange={(e) => handleQuestionChange(e, index)}
                    >
                      <option value="">Select Question</option>
                      {availableQuestions(index).map((question) => (
                        <option key={question.id} value={question.id}>
                          {question.question}
                        </option>
                      ))}
                    </select>

                    <span>:</span>

                    <input
                      type="text"
                      value={answers[`answer${index}`] || ""}
                      onChange={(e) => handleAnswerChange(e, index)}
                      placeholder="Answer"
                      className={`border p-1.5 rounded w-72 h-8 focus:outline-none hover:border-blue-400 ml-4 ${
                        answers[`answer${index}`]
                          ? "border-green-500"
                          : "border-gray-300"
                      }`}
                    />

                    <span className="text-red-500 ml-1">*</span>

                    {questionsErr && (
                      <span className="text-red-500 text-sm">
                        {questionsErr}
                      </span>
                    )}
                  </label>
                ))}

                <CustomButton
                  title={"Save"}
                  className={" ml-64 h-10 w-40  mt-4"}
                  onClick={handleSubmit}
                />
                <div>
                  {showPopup && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
                      <div className="bg-white p-6 rounded shadow-lg text-center">
                        <p>
                          You will be logged out in {countdown} seconds. Please
                          sign in again.
                        </p>
                        {/* <button
                          className="mt-4 py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
                          onClick={logoutAndRedirect}
                        >
                          Logout Now
                        </button> */}
                        <CustomButton
                          title={"Logout Now"}
                          className={" ml-64 h-10 w-40  mt-4"}
                          onClick={logoutAndRedirect}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const popupStyles = {
    container: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999,
    },
    popup: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "20px",
      maxWidth: "400px",
      width: "90%",
      textAlign: "center",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      animation: "fadeIn 1s ease-out", // Initial animation
    },
    title: {
      fontSize: "18px",
      marginBottom: "15px",
      textDecoration: "underline",
    },
    message: {
      fontSize: "20px",
      marginBottom: "20px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      backgroundColor: "#28a745",
      color: "#fff",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "16px",
      width: "48%",
    },
    buttonLater: {
      backgroundColor: "#dc3545",
      color: "#fff",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "16px",
      width: "48%",
    },
  };
  const fadeInAnimation = `
    @keyframes fadeIn {
      0% { opacity: 0; transform: scale(0.9); }
      100% { opacity: 1; transform: scale(1); }
    }
  `;
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = fadeInAnimation;
  document.head.appendChild(styleSheet);

  const PasswordExpirePopup = ({ message, initialCountdown }) => {
    const [isAnimating, setIsAnimating] = useState(true);
    const [countdown, setCountdown] = useState(initialCountdown);

    const navigate = useNavigate();
    const handleChangeNow = () => {
      navigate("/forgot-password");
    };

    const handleLater = () => {
      const today = new Date().toISOString().split("T")[0];
      localStorage.setItem("passwordPopupLaterDate", today);
      setIsExpirePopupOpen(false);
      setIsAnimating(false);
    };
    useEffect(() => {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }, []);

    return (
      <div
        className={`popup-container ${isAnimating ? "popup-animate" : ""}`}
        style={popupStyles.container}
      >
        <div style={popupStyles.popup}>
          <h2 style={popupStyles.title}>Password Expiration Notice</h2>
          <p style={popupStyles.message}>
            {message}{" "}
            {countdown > 0 && `\n Redirecting in ${countdown} seconds`}
          </p>
          {countdown === 0 && (
            <div style={popupStyles.buttonContainer}>
              <button style={popupStyles.button} onClick={handleChangeNow}>
                Change it Now
              </button>
              <button style={popupStyles.buttonLater} onClick={handleLater}>
                Later
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {isPopupOpen && (
        <Popup title="Reset Password" onClose={handleClosePopup} />
      )}
      {isExpirePopupOpen && (
        <PasswordExpirePopup
          message={expireMessage}
          initialCountdown={countdown}
        />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Home;
