import Header from "../../../../../components/Header";
import SearchInput from "../../../../../components/SearchInput";
import TransferToBsService from "../../../../../rest-services/TransferToBsService";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReTable from "../../../../../components/Table";
import { useToast } from "../../../../../components/toast/toast";
import Pagination from "../../../../../components/PageNation";


export function TranserToBsTable() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [selectedRows, setSelectedRows] = useState([]);

    const location = useLocation();
    let analyserData = location.state;
    const { toast } = useToast();

    const navigate = useNavigate();

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    useEffect(() => {
        fetchDataForTable();
    }, []);
    const handleShowAll = () => {
        setPageSize(totalCount); // Set page size to total count to display all records
        setCurrentPage(1); // Reset to the first page
        fetchDataForTable(1);
    };
    const [fullData, setFullData] = useState([]); // Stores all data
    const fetchDataForTable = async () => {
        try {
            if (!analyserData) return;

            const response = await TransferToBsService.transferConditionStament(analyserData);
            if (response.status === 200) {
                const allRecords = response.updatedRecords; // Fetch all data at once

                setFullData(allRecords); // Store all data
                setTotalCount(allRecords.length);
                setTotalPages(Math.ceil(allRecords.length / pageSize));

                setCurrentPage(1); // Reset to first page
                analyserData = null;
            } else {
                toast("error", response.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const paginatedData = fullData.slice((currentPage - 1) * pageSize, currentPage * pageSize);


    const handleBreadcrumbClick = (crumb) => {
        console.log("Breadcrumb clicked:", crumb.title);
        if (crumb.title === "Tranfer to Bs") {
            navigate("/11/mt940/49/add-statement-analyser");
        } else if (crumb.title === "Bank Statements") {
            navigate("/bank-statements");
        } else if (crumb.title === "MT940") {
            navigate("/bank-statements");
        } else if (crumb.path) {
            navigate(crumb.path); // Navigate to the path specified in the crumb
        }
    };

    const columns = [
        { Header: "File Name", accessor: "file_name", visible: true, width: ("Time Stamp".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "MT940 Unique ID", accessor: "mt940_unique_id", visible: true, width: ("MT940 Unique ID".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Statement Type", accessor: "statement_type", visible: true, width: ("Statement Type".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tags", accessor: "tags", visible: true, width: ("Tags".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Transaction indicator", accessor: "transaction_indicator", visible: true, width: ("Transaction indicator".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Corporate BIC", accessor: "corprate_bic", visible: true, width: ("Corporate BIC".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Bank BIC", accessor: "bank_bic", visible: true, width: ("Bank BIC".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Reference", accessor: "reference", visible: true, width: ("Reference".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Statement Identifier", accessor: "statement_identifier", visible: true, width: ("Statement Identifier".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Statement No", accessor: "statement_no", visible: true, width: ("Statement No".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Dr/Cr", accessor: "dr_cr", visible: true, width: ("Dr/Cr".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Fund Code", accessor: "funCode", visible: true, width: ("Fund Code".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        {
            Header: "Value Date",
            accessor: "value_date",
            visible: true,
            width: ("Value Date".length -2)*10,
            Cell: ({ value }) => {
                if (!value) return "";
                const date = new Date(value);
                return <div style={{ textAlign: "center" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
            },
        },
        {
            Header: "Entry Date",
            accessor: "entry_date",
            visible: true,
            width: ("Entry Date".length -2)*10,
            Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>
        },
        { Header: "Currency", accessor: "currency", visible: true, width: ("Currency".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Amount", accessor: "amount", visible: true, width: ("Amount".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div> },
        { Header: "BTC Code", accessor: "btc_code", visible: true, width: ("BTC Code".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag61Description1", accessor: "tag61_description1", visible: true, width: ("Tag61Description1".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag61 Reference", accessor: "tag61_reference", visible: true, width: ("Tag61 Reference".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag61Description2", accessor: "tag61_description2", visible: true, width: ("Tag61Description2".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag86Description1", accessor: "tag81_description1", visible: true, width: ("Tag86Description1".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag86Description2", accessor: "tag81_description2", visible: true, width: ("Tag86Description2".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag86Description3", accessor: "tag81_description3", visible: true, width: ("Tag86Description3".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag86Description4", accessor: "tag81_description4", visible: true, width: ("Tag86Description4".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag86Description5", accessor: "tag81_description5", visible: true, width: ("Tag86Description5".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Tag86Description6", accessor: "tag81_description6", visible: true, width: ("Tag86Description6".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "BTCE", accessor: "btce", visible: true, width: ("BTCE".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },

        {
            Header: "Statement Imported Date",
            accessor: "statement_imported_date",
            visible: true,
            width: ("Statement Imported Date".length -2)*10,
            Cell: ({ value }) => {
                if (!value) return "";
                const date = new Date(value);
                return <div style={{ textAlign: "center" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
            },
        },
        {
            Header: "Time Stamp", accessor: "time_stamp", visible: true, width: ("Time Stamp".length -2)*10, Cell: ({ value }) => {
                if (!value) return "";
                const date = new Date(value);
                const formattedTime = date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true, // Ensures 12-hour format with AM/PM
                });
                return <div style={{ textAlign: "center" }}>{formattedTime}</div>;
            },
        },
        {
            Header: "Imported Status", accessor: "imported_status", visible: true, width: ("Imported Status".length -2)*10, Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={row.original.imported_status === 1}
                    readOnly
                />
            ),
        },
        { Header: "Statement Imported by", accessor: "statement_imported_by", visible: true, width: ("Statement Imported by".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Statement Status", accessor: "statement_status", visible: true, width: ("Statement Status".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Bank Account ID", accessor: "bank_account_id", visible: true, width: ("Bank Account ID".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Company Code", accessor: "company_code", visible: true, width: ("Company Code".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Legal Entity", accessor: "company.legalEntityCode", visible: true, width: ("Legal Entity".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Bu Group", accessor: "company.buGroupCode", visible: true, width: ("Bu Group".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Country", accessor: "country", visible: true, width: ("Country".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Bank", accessor: "bank", visible: true, width: ("Bank".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Bank Branch", accessor: "bank_branch", visible: true, width: ("Bank Branch".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        { Header: "Bank Country", accessor: "bank_country", visible: true, width: ("Bank Country".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
        {
            Header: "Transfer Status", accessor: "is_transfered", visible: true, width: ("Transfer Status".length -2)*10, Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={row.original.is_transfered === 1}
                    readOnly
                />
            ),
        },
        {
            Header: "Transfer Date", accessor: "transfer_date", visible: true, width: ("Transfer Date".length -2)*10, Cell: ({ value }) => {
                if (!value) return "";
                const date = new Date(value);
                return <div style={{ textAlign: "center" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
            },
        },

        { Header: "User ID", accessor: "transfer_by", visible: true, width: ("User ID".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    ];

    const handleDoubleClick = (row) => {
        console.log("Double-clicked row:", row);
    }
    return (
        <div>
            <Header title="Bank Statements > MT940 > Transfer to BS" onBreadcrumbClick={handleBreadcrumbClick} backRoute={"/11/mt940/51/add-transfer-bs"} />
            <div className="p-0">
                <div className="flex justify-between items-center -ml-1 pl-4">
                    <div className="flex items-center">
                        <SearchInput
                            // searchQuery={searchQuery}
                            // setSearchQuery={setSearchQuery}
                            // onChange={(e) => fetchBankAccount(searchQuery)}
                            placeholder="Search by Identifiers"
                        />

                        {/* <BankStatementActions buttons={buttons} /> */}
                    </div>
                    <div className="flex items-center mr-4">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            totalCount={totalCount}
                            onShowAll={handleShowAll}
                        />
                    </div>

                </div>
                <div>
                    <ReTable
                        data={paginatedData}
                        columns={columns}
                        selectedRows={selectedRows}
                        // onSelectRow={handleSelectRow}
                        // onSelectAll={handleSelectAll}
                        onRowDoubleClick={handleDoubleClick}
                    />
                </div>
            </div>
        </div>
    )
}