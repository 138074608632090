import React, { useEffect, useRef, useState } from "react";

const DynamicCustomDropdown = ({
  userTypes,
  userType,
  setUserType,
  isViewMode,
  isEditMode,
  className = "",
  error = false,
  width = "w-80",
  height = "h-8",
  placeholder = "Select an option",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
   // Close dropdown when clicking outside
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handles selection and ensures dropdown closes immediately
  const handleSelect = (typeId) => {
    setUserType(typeId); // Update selected user type
    setTimeout(() => setIsOpen(false), 0); // Ensure dropdown closes
  };


  // Find the user type name based on the selected ID
  const selectedType = userTypes.find((type) => type.id == userType)?.name || "Select user type";
console.log("selectedType in dynamic dropdomnw",selectedType);
  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
     {isViewMode || isEditMode ? (
        // Display text instead of a button in view/edit mode
        <div className={`border p-1 rounded ${width} ${height} text-customGray bg-gray-100 flex items-center justify-between`}>
          {selectedType}
          <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-4 h-4 transition-transform ${isOpen ? "rotate-180" : ""}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
        </div>
      ) : (
        // Dropdown Button
      <button
        type="button"
        onClick={() => setIsOpen((prev) => !prev)} // Toggle dropdown visibility
        disabled={isViewMode }
        className={`border text-customGray p-1 rounded ${width} ${height} focus:outline-none hover:border-blue-400 text-left flex items-center justify-between
        ${error ? "border-red-500 hover:border-red-500" : "border-gray-300"}
        `}
      >
        {/* {userType ? userTypes.find((type) => type.id === userType)?.name : "Select user type"} */}
        <span className="text-xs font-small text-customGray"> {selectedType}</span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-4 h-4 transition-transform ${isOpen ? "rotate-180" : ""}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
)}
      {/* Dropdown Options */}
      {isOpen && !isViewMode && !isEditMode && (
        <ul className="absolute w-full border mt-1 bg-white rounded shadow-md z-10">
          {userTypes.map((type) => (
            <li
              key={type.id}
              onClick={() => handleSelect(type.id)}
              className="px-3 py-1 cursor-pointer text-xs text-customGray hover:bg-customBlue hover:text-white"
            >
              {type.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DynamicCustomDropdown;
