import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import ReTable from './Table';
import UserService from '../rest-services/UserServices';
import CustomeLoading from './CustomeLoading';
import AdminActions from '../pages/Screens/Admin/Components/AdminActions';
import { AdminActionsConfig } from '../pages/Screens/Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
// import * as XLSX from "xlsx"; // Import the xlsx library
import XLSX from "xlsx-js-style";

export default function TaskMonitorLog() {
        const location = useLocation();
            const { iconId } = useParams();
            const {moduleId} = useParams();
        
            const navigate = useNavigate();
    const { tableData  } = location.state || {}; // Retrieve tableData from state
const [userPreference, setUserPreference] = useState("");
     const [assignee, setAssignee] = useState({ id: "", username: "" });
const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs


useEffect(() => {
  fetchAssignee();
  console.log("tableData is",tableData);
}, []);

const fetchAssignee = async () => {
try {
  const userDetailString = localStorage.getItem("userDetail");

  if (userDetailString) {
    const userDetail = JSON.parse(userDetailString);
    const assigneeData = {
      id: userDetail.id || "",
      assigner: userDetail.username || "",
      clientId: userDetail.clientId || "",
      assignerId: userDetail.userId || "",
    };
    console.log("assigneeData is", assigneeData);

    setAssignee(assigneeData);

    // Log the id and assigner values to the console
    console.log("Assignee ID:", assigneeData.id);
    console.log("Assigner:", assigneeData.assigner);
    console.log("Assigner userId:", assigneeData.assignerId);
    console.log("Assigner clientId:", assigneeData.clientId);
  } else {
    setAlert({
      severity: "error",
      message: "No user detail found in session storage.",
    });
  }
} catch (error) {
  setAlert({
    severity: "error",
    message: `Error fetching user details: ${error.message}`,
  });
}
};

useEffect(() => {
  if (assignee.id) {
    fetchUserPreferenceDetails();
  }
}, [assignee.id]);


const fetchUserPreferenceDetails = async () => {
    try {
      const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
      console.log("Requestfor user pref Data:", data);
    
      setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log("user prefference details", response.data);
      setUserPreference(response.data); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
    };

    
const formatDate = (dateString, format) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const shortYear = String(year).slice(-1);
  
    const formatMap = {
        "DD/MM/YYYY": `${day}/${month}/${year}`,
        "MM/DD/YYYY": `${month}/${day}/${year}`,
        "DD/MM/YY": `${day}/${month}/${shortYear}`,
        "MM/DD/YY": `${month}/${day}/${shortYear}`,
        "DD.MM.YYYY": `${day}.${month}.${year}`,
        "DDMMYY": `${day}${month}${shortYear}`,
        "DD-MM-YYYY": `${day}-${month}-${year}`,
    };
  
    return formatMap[format] || dateString;
  };
  const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format

  
      const columns = moduleId === "108" ? [  
            
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            {
              Header: 'Update',
              accessor: 'update',
              visible: true,
              width: ("Update".length -1) * 10,
              alignment: 'left',
              Cell: ({ value }) => (
                <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              )
            },
      
        {
            Header: 'User ID',
            accessor: 'UserID',
            visible: true,
            width: ("User ID".length -1) * 10,
            alignment: "left",
            Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )
          },
        { Header: 'Employee No', accessor: 'EmployeeNo', visible: true, width: ("Employee No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Salute', accessor: 'Salute', visible: true, width: ("Salute".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'AccountingSystemId', accessor: 'AccountingSystemId', visible: true, width: ("AccountingSystemId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Username', accessor: 'Username', visible: true, width: ("Username".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'First Name', accessor: 'FirstName', visible: true, width: ("First Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Middle Name', accessor: 'MiddleName', visible: true, width: ("Middle Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'last Name', accessor: 'LastName', visible: true, width: ("last Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'language', accessor: 'Language', visible: true, width: ("language".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Time Format', accessor: 'TimeFormat', visible: true, width: ("Time Format".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Time Zone', accessor: 'TimeZone', visible: true, width: ("Time Zone".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'companyCodeId', accessor: 'CompanyCode', visible: true, width: ("companyCodeId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'department', accessor: 'Department', visible: true, width: ("department".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        {
          Header: 'valid From',
          accessor: 'ValidFrom',
          visible: true,
          width: ("valid From".length -1) * 10,
          alignment: "left",
        //   Cell: ({ value }) => (
        //     <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
        //       {Array.isArray(value) ? value.join(', ') : value}
        //     </span>
        //   )
        },
        
        {
          Header: 'valid To',
          accessor: 'ValidTo',
          visible: true,
          width: ("valid To".length -1) * 10,
          alignment: "left",
          Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          )
        },
      
        { Header: 'Email ID', accessor: 'EmailID', visible: true, width: ("Email ID".length -1) * 10, alignment: "left" , Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )},      
        { Header: 'mobileNo', accessor: 'MobileNo', visible: true, width: ("mobileNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'whatsAppNo', accessor: 'whatsAppNo', visible: true, width: ("whatsAppNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'nationality', accessor: 'Nationality', visible: true, width: ("nationality".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'passportNo', accessor: 'passportNo', visible: true, width: ("passportNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'drivingLicenseNo', accessor: 'DrivingLicenseNo', visible: true, width: ("drivingLicenseNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'localId', accessor: 'LocalID', visible: true, width: ("localId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'officeNo', accessor: 'officeNo', visible: true, width: ("officeNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'extension', accessor: 'extension', visible: true, width: ("extension".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { 
          Header: 'joiningDate', 
          accessor: 'JoiningDate', 
          visible: true, 
          width: ("joiningDate".length -1) * 10, 
          alignment: "left", 
          Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          )
        },

        { 
          Header: 'resignationDate', 
          accessor: 'ResignDate', 
          visible: true, 
          width: ("resignationDate".length -1) * 10, 
          alignment: "left", 
          Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          )
        },

        { Header: 'Door No', accessor: 'DoorNo', visible: true, width: ("Door No".length -1) * 10, alignment: "left" , Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )},
        { Header: 'Floor No', accessor: 'FloorNo', visible: true, width: ("Floor No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Building No', accessor: 'BuildingNo', visible: true, width: ("Building No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Building Name', accessor: 'BuildingName', visible: true, width: ("Building Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Street No', accessor: 'StreetNo', visible: true, width: ("Street No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Street Name', accessor: 'StreetName', visible: true, width: ("Street Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Road No', accessor: 'RoadNo', visible: true, width: ("Road No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Road Name', accessor: 'RoadName', visible: true, width: ("Road Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'areaName', accessor: 'areaName', visible: true, width: ("areaName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'phase', accessor: 'phase', visible: true, width: ("phase".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'sector', accessor: 'sector', visible: true, width: ("sector".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'village', accessor: 'village', visible: true, width: ("village".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'town', accessor: 'town', visible: true, width: ("town".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'cityName', accessor: 'cityName', visible: true, width: ("cityName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'addressLine1', accessor: 'Address1', visible: true, width: ("addressLine1".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'addressLine2', accessor: 'Address2', visible: true, width: ("addressLine2".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'addressLine3', accessor: 'Address3', visible: true, width: ("addressLine3".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'countryId', accessor: 'Country', visible: true, width: ("countryId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'State/Province', accessor: 'StateProvince', visible: true, width: ("State/Province".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Postal Code', accessor: 'PostalCode', visible: true, width: ("Postal Code".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Zip Code', accessor: 'ZipCode', visible: true, width: ("Zip Code".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'PO Box', accessor: 'POBox', visible: true, width: ("PO Box".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'DateFormat', accessor: 'DateFormat', visible: true, width: ("DateFormat".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'amountFormat', accessor: 'AmountFormat', visible: true, width: ("amountFormat".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        // { Header: 'thousandSeparator', accessor: 'thousandSeparator', visible: true, width: ("thousandSeparator".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        //       <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
        //         {Array.isArray(value) ? value.join(', ') : value}
        //       </span>
        //     ) },
        // { Header: 'decimalSeparator', accessor: 'decimalSeparator', visible: true, width: ("decimalSeparator".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        //       <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
        //         {Array.isArray(value) ? value.join(', ') : value}
        //       </span>
        //     ) },
        { Header: 'acceleratorForThousands', accessor: 'acceleratorForThousands', visible: true, width: ("acceleratorForThousands".length -1) * 10, alignment: "left" , Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )},
        { Header: 'acceleratorForLakhs', accessor: 'acceleratorForLakhs', visible: true, width: ("acceleratorForLakhs".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'acceleratorForMillions', accessor: 'acceleratorForMillions', visible: true, width: ("acceleratorForMillions".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
      ] :
      moduleId === "1" ? [  
            
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            {
              Header: 'Update',
              accessor: 'update',
              visible: true,
              width: ("Update".length -1) * 10,
              alignment: 'left',
              Cell: ({ value }) => (
                <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              )
            },
      
        // { Header: 'User ID', accessor: 'User ID', visible: true, width: ("".length -1) * 10, alignment: "left" },
        {
            Header: 'CountryCode',
            accessor: 'CountryCode',
            visible: true,
            width: ("CountryCode".length -1) * 10,
            alignment: "left",
            Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )
          },
        { Header: 'CountryName', accessor: 'CountryName', visible: true, width: ("CountryName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
      
        { Header: 'Language', accessor: 'Language', visible: true, width: ("Language".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            
        { Header: 'DateFormat', accessor: 'DateFormat', visible: true, width: ("DateFormat".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
        { Header: 'TimeFormat', accessor: 'TimeFormat', visible: true, width: ("TimeFormat".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'TimeZone', accessor: 'TimeZone', visible: true, width: ("TimeZone".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
      
        { Header: 'AmountFormat', accessor: 'AmountFormat', visible: true, width: ("AmountFormat".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        // { Header: 'ThousandSeparator', accessor: 'ThousandSeparator', visible: true, width: ("ThousandSeparator".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        //       <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
        //         {Array.isArray(value) ? value.join(', ') : value}
        //       </span>
        //     ) },
        // { Header: 'DecimalSeparator', accessor: 'DecimalSeparator', visible: true, width: ("DecimalSeparator".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        //       <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
        //         {Array.isArray(value) ? value.join(', ') : value}
        //       </span>
        //     ) },
        { Header: 'AcceleratorForThousands', accessor: 'AcceleratorForThousands', visible: true, width: ("AcceleratorForThousands".length -1) * 10, alignment: "left" , Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )},
        { Header: 'AcceleratorForLakhs', accessor: 'AcceleratorForLakhs', visible: true, width: ("AcceleratorForLakhs".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'AcceleratorForMillions', accessor: 'AcceleratorForMillions', visible: true, width: ("AcceleratorForMillions".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
      ] :
      moduleId === "2" ? [  
            
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            {
              Header: 'Update',
              accessor: 'update',
              visible: true,
              width: ("Update".length -1) * 10,
              alignment: 'left',
              Cell: ({ value }) => (
                <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              )
            },
        
        { Header: 'StateCode', accessor: 'StateCode', visible: true, width: ("StateCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },

        { Header: 'StateName', accessor: 'StateName', visible: true, width: ("StateName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
        {
            Header: 'CountryCode',
            accessor: 'CountryCode',
            visible: true,
            width: ("CountryCode".length -1) * 10,
            alignment: "left",
            Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )
          },
       
      
      
      ] :
      moduleId === "4" ? [  
            
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            {
              Header: 'Update',
              accessor: 'update',
              visible: true,
              width: ("Update".length -1) * 10,
              alignment: 'left',
              Cell: ({ value }) => (
                <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              )
            },
        
        { Header: 'CurrencyCode', accessor: 'CurrencyCode', visible: true, width: ("CurrencyCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },

        { Header: 'CurrencynAME', accessor: 'CurrencynAME', visible: true, width: ("CurrencynAME".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },    
      
      ] :
      moduleId === "5" ? [  
            
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            {
              Header: 'Update',
              accessor: 'update',
              visible: true,
              width: ("Update".length -1) * 10,
              alignment: 'left',
              Cell: ({ value }) => (
                <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              )
            },
        
        { Header: 'BankCode', accessor: 'BankCode', visible: true, width: ("BankCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },

        { Header: 'Description', accessor: 'Description', visible: true, width: ("Description".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },    
      
      ] :
      moduleId === "6" ? [  
            
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            {
              Header: 'Update',
              accessor: 'update',
              visible: true,
              width: ("Update".length -1) * 10,
              alignment: 'left',
              Cell: ({ value }) => (
                <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              )
            },
        
        { Header: 'LE', accessor: 'LE', visible: true, width: ("LE".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },

        { Header: 'Description', accessor: 'Description', visible: true, width: ("Description".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },    
      
      ] 

      : moduleId === "8" ? [
          { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'SL No', accessor: 'SL No', visible: true, width: ("SL No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
              {
                Header: 'Update',
                accessor: 'update',
                visible: true,
                width: ("Update".length -1) * 10,
                alignment: 'left',
                Cell: ({ value }) => (
                  <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                    {Array.isArray(value) ? value.join(', ') : value}
                  </span>
                )
              },
          { Header: 'BU Group', accessor: 'BuGroup', visible: true, width: ("BU Group".length -1) * 10, alignment: "left",
            Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'BU Group Description', accessor: 'BuGroupDescription', visible: true, width: ("BU Group Description".length -1) * 10, alignment: "left",
            Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )
            },
            
      ] 
      : moduleId === "7" ? [
          { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
              {
                Header: 'Update',
                accessor: 'update',
                visible: true,
                width: ("Update".length -1) * 10,
                alignment: 'left',
                Cell: ({ value }) => (
                  <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                    {Array.isArray(value) ? value.join(', ') : value}
                  </span>
                )
              },
          { Header: 'LGECode', accessor: 'LGECode', visible: true, width: ("LGECode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'LegalEntityName', accessor: 'LegalEntityName', visible: true, width: ("LegalEntityName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'CurrencyCode', accessor: 'CurrencyCode', visible: true, width: ("CurrencyCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'Door No', accessor: 'DoorNo', visible: true, width: ("Door No".length -1) * 10, alignment: "left" , Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          )},
          { Header: 'Floor No', accessor: 'FloorNo', visible: true, width: ("Floor No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Building No', accessor: 'BuildingNo', visible: true, width: ("Building No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Building Name', accessor: 'BuildingName', visible: true, width: ("Building Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Street No', accessor: 'StreetNo', visible: true, width: ("Street No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Street Name', accessor: 'StreetName', visible: true, width: ("Street Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Road No', accessor: 'RoadNo', visible: true, width: ("Road No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Road Name', accessor: 'RoadName', visible: true, width: ("Road Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'AreaName', accessor: 'AreaName', visible: true, width: ("AreaName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Phase', accessor: 'Phase', visible: true, width: ("Phase".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Sector', accessor: 'Sector', visible: true, width: ("Sector".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Village', accessor: 'Village', visible: true, width: ("Village".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Town', accessor: 'Town', visible: true, width: ("Town".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'CityName', accessor: 'CityName', visible: true, width: ("CityName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'AddressLine1', accessor: 'AddressLine1', visible: true, width: ("AddressLine1".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'AddressLine2', accessor: 'AddressLine2', visible: true, width: ("AddressLine2".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'AddressLine3', accessor: 'AddressLine3', visible: true, width: ("AddressLine3".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'countryId', accessor: 'CountryId', visible: true, width: ("countryId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'State/Province', accessor: 'StateProvince', visible: true, width: ("State/Province".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Postal Code', accessor: 'PostalCode', visible: true, width: ("Postal Code".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'Zip Code', accessor: 'ZipCode', visible: true, width: ("Zip Code".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },
          { Header: 'PO Box', accessor: 'POBox', visible: true, width: ("PO Box".length -1) * 10, alignment: "left", Cell: ({ value }) => (
                <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              ) },

      ] 
      : moduleId === "9" ? [
        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
        { Header: 'SL No', accessor: 'SLNo', visible: true, width: ("SL No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            {
              Header: 'Update',
              accessor: 'update',
              visible: true,
              width: ("Update".length -1) * 10,
              alignment: 'left',
              Cell: ({ value }) => (
                <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              )
            },
        { Header: 'CompanyCode', accessor: 'CompanyCode', visible: true, width: ("CompanyCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
        { Header: 'CompanyName', accessor: 'CompanyName', visible: true, width: ("CompanyName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
        { Header: 'CompanyShortName', accessor: 'CompanyShortName', visible: true, width: ("CompanyShortName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
        { Header: 'LGECode', accessor: 'LGECode', visible: true, width: ("LGECode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
        { Header: 'BuGroupCode', accessor: 'BuGroupCode', visible: true, width: ("BuGroupCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
        { Header: 'CurrencyCode', accessor: 'CurrencyCode', visible: true, width: ("CurrencyCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        ) },
    
        { Header: 'Door No', accessor: 'DoorNo', visible: true, width: ("Door No".length -1) * 10, alignment: "left" , Cell: ({ value }) => (
          <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        )},
        { Header: 'Floor No', accessor: 'FloorNo', visible: true, width: ("Floor No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Building No', accessor: 'BuildingNo', visible: true, width: ("Building No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Building Name', accessor: 'BuildingName', visible: true, width: ("Building Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Street No', accessor: 'StreetNo', visible: true, width: ("Street No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Street Name', accessor: 'StreetName', visible: true, width: ("Street Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Road No', accessor: 'RoadNo', visible: true, width: ("Road No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Road Name', accessor: 'RoadName', visible: true, width: ("Road Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'areaName', accessor: 'areaName', visible: true, width: ("areaName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'phase', accessor: 'phase', visible: true, width: ("phase".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'sector', accessor: 'sector', visible: true, width: ("sector".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'village', accessor: 'village', visible: true, width: ("village".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'town', accessor: 'town', visible: true, width: ("town".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'cityName', accessor: 'cityName', visible: true, width: ("cityName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'addressLine1', accessor: 'Address1', visible: true, width: ("addressLine1".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'addressLine2', accessor: 'Address2', visible: true, width: ("addressLine2".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'addressLine3', accessor: 'Address3', visible: true, width: ("addressLine3".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'countryId', accessor: 'Country', visible: true, width: ("countryId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'State/Province', accessor: 'StateProvince', visible: true, width: ("State/Province".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Postal Code', accessor: 'PostalCode', visible: true, width: ("Postal Code".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Zip Code', accessor: 'ZipCode', visible: true, width: ("Zip Code".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'PO Box', accessor: 'POBox', visible: true, width: ("PO Box".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
            { Header: 'ContactPersonNumber', accessor: 'ContactPersonNumber', visible: true, width: ("ContactPersonNumber".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'MobileNumber', accessor: 'MobileNumber', visible: true, width: ("MobileNumber".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Email', accessor: 'Email', visible: true, width: ("Email".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'Telephone', accessor: 'Telephone', visible: true, width: ("Telephone".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
        { Header: 'FaxNo', accessor: 'FaxNo', visible: true, width: ("FaxNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
              <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            ) },
    ] : moduleId === "10" ? [
      { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },
      { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          {
            Header: 'Update',
            accessor: 'update',
            visible: true,
            width: ("".length -1) * 10,
            alignment: 'left',
            Cell: ({ value }) => (
              <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )
          },
      { Header: 'BankId', accessor: 'BankId', visible: true, width: ("BankId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },
      { Header: 'BankName', accessor: 'BankName', visible: true, width: ("BankName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },
      { Header: 'Bank', accessor: 'Bank', visible: true, width: ("Bank".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },

      { Header: 'Door No', accessor: 'DoorNo', visible: true, width: ("Door No".length -1) * 10, alignment: "left" , Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      )},
      { Header: 'Floor No', accessor: 'FloorNo', visible: true, width: ("Floor No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Building No', accessor: 'BuildingNo', visible: true, width: ("Building No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Building Name', accessor: 'BuildingName', visible: true, width: ("Building Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Street No', accessor: 'StreetNo', visible: true, width: ("Street No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Street Name', accessor: 'StreetName', visible: true, width: ("Street Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Road No', accessor: 'RoadNo', visible: true, width: ("Road No".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Road Name', accessor: 'RoadName', visible: true, width: ("Road Name".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'AreaName', accessor: 'AreaName', visible: true, width: ("AreaName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Phase', accessor: 'Phase', visible: true, width: ("Phase".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Sector', accessor: 'Sector', visible: true, width: ("Sector".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Village', accessor: 'Village', visible: true, width: ("Village".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Town', accessor: 'Town', visible: true, width: ("Town".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'CityName', accessor: 'CityName', visible: true, width: ("CityName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'AddressLine1', accessor: 'AddressLine1', visible: true, width: ("AddressLine1".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'AddressLine2', accessor: 'AddressLine2', visible: true, width: ("AddressLine2".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'AddressLine3', accessor: 'AddressLine3', visible: true, width: ("AddressLine3".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'CountryId', accessor: 'CountryId', visible: true, width: ("CountryId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'StateProvince', accessor: 'StateProvince', visible: true, width: ("StateProvince".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'PostalCode', accessor: 'PostalCode', visible: true, width: ("PostalCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'ZipCode', accessor: 'ZipCode', visible: true, width: ("ZipCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'POBox', accessor: 'POBox', visible: true, width: ("POBox".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
        
    ] 
    : moduleId === "11" ? [
      { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },
      { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          {
            Header: 'Update',
            accessor: 'update',
            visible: true,
            width: ("Update".length -1) * 10,
            alignment: 'left',
            Cell: ({ value }) => (
              <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
                {Array.isArray(value) ? value.join(', ') : value}
              </span>
            )
          },
      { Header: 'BankBranch', accessor: 'BankBranch', visible: true, width: ("BankBranch".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },
      { Header: 'BankBranchName', accessor: 'BankBranchName', visible: true, width: ("BankBranchName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },
      { Header: 'BankId', accessor: 'BankId', visible: true, width: ("BankId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },
      { Header: 'CopyTheBankIDAddress', accessor: 'CopyTheBankIDAddress', visible: true, width: ("CopyTheBankIDAddress".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      ) },

      { Header: 'Door No', accessor: 'DoorNo', visible: true, width: ("DoorNo".length -1) * 10, alignment: "left" , Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      )},
      { Header: 'Floor No', accessor: 'FloorNo', visible: true, width: ("FloorNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'BuildingNo', accessor: 'BuildingNo', visible: true, width: ("BuildingNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'BuildingName', accessor: 'BuildingName', visible: true, width: ("BuildingName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'LandMark', accessor: 'LandMark', visible: true, width: ("LandMark".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'StreetNo', accessor: 'StreetNo', visible: true, width: ("StreetNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'StreetName', accessor: 'StreetName', visible: true, width: ("StreetName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'RoadNo', accessor: 'RoadNo', visible: true, width: ("RoadNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'RoadName', accessor: 'RoadName', visible: true, width: ("RoadName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Block', accessor: 'Block', visible: true, width: ("Block".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'AreaName', accessor: 'AreaName', visible: true, width: ("AreaName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Phase', accessor: 'Phase', visible: true, width: ("Phase".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Sector', accessor: 'Sector', visible: true, width: ("Sector".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Village', accessor: 'Village', visible: true, width: ("Village".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'Town', accessor: 'Town', visible: true, width: ("Town".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'CityName', accessor: 'CityName', visible: true, width: ("CityName".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'AddressLine1', accessor: 'AddressLine1', visible: true, width: ("AddressLine1".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'AddressLine2', accessor: 'AddressLine2', visible: true, width: ("AddressLine2".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'AddressLine3', accessor: 'AddressLine3', visible: true, width: ("AddressLine3".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'CountryId', accessor: 'CountryId', visible: true, width: ("CountryId".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'StateProvince', accessor: 'StateProvince', visible: true, width: ("StateProvince".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'District', accessor: 'District', visible: true, width: ("District".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'PostalCode', accessor: 'PostalCode', visible: true, width: ("PostalCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'ZipCode', accessor: 'ZipCode', visible: true, width: ("ZipCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
      { Header: 'POBox', accessor: 'POBox', visible: true, width: ("POBox".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'BankBic', accessor: 'BankBic', visible: true, width: ("BankBic".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'CountryCode', accessor: 'CountryCode', visible: true, width: ("CountryCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'CountryCodeType', accessor: 'CountryCodeType', visible: true, width: ("'CountryCodeType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'CustomerCode', accessor: 'CustomerCode', visible: true, width: ("CustomerCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'MICR', accessor: 'MICR', visible: true, width: ("MICR".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'BankCode', accessor: 'BankCode', visible: true, width: ("BankCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'BranchCode', accessor: 'BranchCode', visible: true, width: ("BranchCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'Min', accessor: 'Min', visible: true, width: ("Min".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'Max', accessor: 'Max', visible: true, width: ("Max".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
          { Header: 'IBANRequired', accessor: 'IBANRequired', visible: true, width: ("IBANRequired".length -1) * 10, alignment: "left", Cell: ({ value }) => (
            <span style={{ color: value === "Shouldn't be blank" ||  Array.isArray(value) ? 'red' : 'inherit' }}>
              {Array.isArray(value) ? value.join(', ') : value}
            </span>
          ) },
    ]  : moduleId === "13" ? [
     
      {
        Header: 'Update',
        accessor: 'update',
        visible: true,
        width: ("Update".length -1) * 10,
        alignment: 'left',
        Cell: ({ value }) => (
          <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        )
      },
      { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'SourceType', accessor: 'SourceType', visible: true, width: ("SourceType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'SourceCode', accessor: 'SourceCode', visible: true, width: ("SourceCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'SourceCodeDescription', accessor: 'SourceCodeDescription', visible: true, width: ("SourceCodeDescription".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'OurCode', accessor: 'OurCode', visible: true, width: ("OurCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'OurCodeDescription', accessor: 'OurCodeDescription', visible: true, width: ("OurCodeDescription".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'RateType', accessor: 'RateType', visible: true, width: ("RateType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'DataType', accessor: 'DataType', visible: true, width: ("DataType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
     { Header: 'Ratio', accessor: 'Ratio', visible: true, width: ("Ratio".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'MDType', accessor: 'MDType', visible: true, width: ("MDType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}	
        </span>
      ) },
  
  ]     : moduleId === "14" ? [
     
      {
        Header: 'Update',
        accessor: 'update',
        visible: true,
        width: ("Update".length -1) * 10,
        alignment: 'left',
        Cell: ({ value }) => (
          <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
            {Array.isArray(value) ? value.join(', ') : value}
          </span>
        )
      },
      { Header: 'RateType', accessor: 'RateType', visible: true, width: ("RateType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'RateDate', accessor: 'RateDate', visible: true, width: ("RateDate".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'SourceType', accessor: 'SourceType', visible: true, width: ("SourceType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'SourceCode', accessor: 'SourceCode', visible: true, width: ("SourceCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
     { Header: 'Bid', accessor: 'Bid', visible: true, width: ("Bid".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}
        </span>
      ) },
      { Header: 'Ask', accessor: 'Ask', visible: true, width: ("Ask".length -1) * 10, alignment: "left", Cell: ({ value }) => (
        <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value || ""}	
        </span>
      ) },
  
  ]   : moduleId === "48" ? [
     
    {
      Header: 'Update',
      accessor: 'update',
      visible: true,
      width: ("Update".length -1) * 10,
      alignment: 'left',
      Cell: ({ value }) => (
        <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
          {Array.isArray(value) ? value.join(', ') : value}
        </span>
      )
    },
    { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
      <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value || ""}
      </span>
    ) },
    { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
      <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value || ""}
      </span>
    ) },
    { Header: 'BTCCode', accessor: 'BTCCode', visible: true, width: ("RateType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
      <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value || ""}
      </span>
    ) },
    { Header: 'BTCDescription', accessor: 'BTCDescription', visible: true, width: ("RateDate".length -1) * 10, alignment: "left", Cell: ({ value }) => (
      <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value || ""}
      </span>
    ) },
    { Header: 'StatementType', accessor: 'StatementType', visible: true, width: ("SourceType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
      <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value || ""}
      </span>
    ) },
    { Header: 'Inflow', accessor: 'Inflow', visible: true, width: ("SourceCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
      <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value || ""}
      </span>
    ) },
   { Header: 'Outflow', accessor: 'Outflow', visible: true, width: ("Bid".length -1) * 10, alignment: "left", Cell: ({ value }) => (
      <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value || ""}
      </span>
    ) },
  

] 
: moduleId === "27" ? [  

  { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  {
    Header: 'Update',
    accessor: 'update',
    visible: true,
    width: ("Update".length -1) * 10,
    alignment: 'left',
    Cell: ({ value }) => (
      <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value}
      </span>
    )
  },
  { Header: 'BankBranch', accessor: 'BankBranch', visible: true, width: ("BankBranch".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'CurrencyCode', accessor: 'CurrencyCode', visible: true, width: ("CurrencyCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'Note', accessor: 'Note', visible: true, width: ("Note".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'LGECode', accessor: 'LGECode', visible: true, width: ("LGECode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
 { Header: 'CompanyCode', accessor: 'CompanyCode', visible: true, width: ("CompanyCode".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },

  { Header: 'AccountType', accessor: 'AccountType', visible: true, width: ("AccountType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'ZBAType', accessor: 'ZBAType', visible: true, width: ("ZBAType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'AccountClassification', accessor: 'AccountClassification', visible: true, width: ("AccountClassification".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'Overdraft', accessor: 'Overdraft', visible: true, width: ("Overdraft".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
 { Header: 'StatementType', accessor: 'StatementType', visible: true, width: ("StatementType".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  
  { Header: 'ODLimit', accessor: 'ODLimit', visible: true, width: ("ODLimit".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'InterestIndicator', accessor: 'InterestIndicator', visible: true, width: ("InterestIndicator".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'ODDate', accessor: 'ODDate', visible: true, width: ("ODDate".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'MinimumBalance', accessor: 'MinimumBalance', visible: true, width: ("MinimumBalance".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
 { Header: 'Dormant', accessor: 'Dormant', visible: true, width: ("Dormant".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },

  { Header: 'MainBankAccount', accessor: 'MainBankAccount', visible: true, width: ("MainBankAccount".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'Text1', accessor: 'Text1', visible: true, width: ("Text1".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'Text2', accessor: 'Text2', visible: true, width: ("Text2".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'Text3', accessor: 'Text3', visible: true, width: ("Text3".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
 { Header: 'Text4', accessor: 'Text4', visible: true, width: ("Text4".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },

  { Header: 'Text5', accessor: 'Text5', visible: true, width: ("Text5".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },

] 
: moduleId === "28" ? [  

  { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  {
    Header: 'Update',
    accessor: 'update',
    visible: true,
    width: ("Update".length -1) * 10,
    alignment: 'left',
    Cell: ({ value }) => (
      <span style={{ color: value === 'No' || value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
        {Array.isArray(value) ? value.join(', ') : value}
      </span>
    )
  },
  { Header: 'NewRequestNo', accessor: 'NewRequestNo', visible: true, width: ("NewRequestNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'BankAccountNo', accessor: 'BankAccountNo', visible: true, width: ("BankAccountNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'BankAccountIdDescription', accessor: 'BankAccountIdDescription', visible: true, width: ("BankAccountIdDescription".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
  { Header: 'TextField', accessor: 'TextField', visible: true, width: ("TextField".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },
 { Header: 'IBANNo', accessor: 'IBANNo', visible: true, width: ("IBANNo".length -1) * 10, alignment: "left", Cell: ({ value }) => (
    <span style={{ color: value === "Shouldn't be blank" || Array.isArray(value) ? 'red' : 'inherit' }}>
      {Array.isArray(value) ? value.join(', ') : value || ""}
    </span>
  ) },


] 
      : [];

      const [columnSettings, setColumnSettings] = useState(columns);
    
      const handleUpdateColumnSettings = (updatedSettings) => {
        setColumnSettings(updatedSettings);
      };

      
  const handleDownload = () => {
    if (enhancedData.length === 0) {
      setAlert({
        severity: "warning",
        message: "No data available to export!",
      });
      return;
    }
    console.log("donwload filteredUsers", enhancedData);

 
         const extractText = (cellValue) => {
           if (Array.isArray(cellValue)) {
             return cellValue.join(", "); // Convert array to string
           } else if (typeof cellValue === "string") {
             return cellValue;
           } else if (React.isValidElement(cellValue)) {
             return cellValue.props.children;
           }
           return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
         };
       
         let rowsToDownload = [];

         if(moduleId === "108"){

          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo),
            "UserID": extractText(user.UserID),
            "EmployeeNo": extractText(user.EmployeeNo),
            "Salute": extractText(user.Salute),
            "AccountingSystemId": extractText(user.AccountingSystemId),
            "Username": extractText(user.Username),
            "FirstName": extractText(user.FirstName),
            "MiddleName": extractText(user.MiddleName),
            "LastName": extractText(user.LastName),
            "Language": extractText(user.Language || ""),
            "TimeFormat": extractText(user.TimeFormat || ""),
            "TimeZone": extractText(user.TimeZone || ""),
            "CompanyCode": extractText(user.CompanyCode),
            "Department": extractText(user.Department),
            "ValidFrom": extractText(user.ValidFrom || ''),
            "ValidTo": extractText(user.ValidTo || ''),
            "Email": extractText(user.EmailID),
            "MobileNo": extractText(user.MobileNo),
            "WhatsAppNo": extractText(user.WhatsAppNo),
            "Nationality": extractText(user.Nationality),
            "PassportNo": extractText(user.PassportNo),
            "DrivingLicenseNo": extractText(user.DrivingLicenseNo),
            "LocalID": extractText(user.LocalID),
            "OfficeNo": extractText(user.OfficeNo),
            "Extension": extractText(user.Extension),
            "JoiningDate": extractText(user.JoiningDate),
            "ResignDate": extractText(user.ResignDate),
            "DoorNo": extractText(user.DoorNo),
            "FloorNo": extractText(user.FloorNo),
            "BuildingNo": extractText(user.BuildingNo),
            "BuildingName": extractText(user.BuildingName),
            "LandMark": extractText(user.LandMark),
            "StreetNo": extractText(user.StreetNo),
            "StreetName": extractText(user.StreetName),
            "RoadNo": extractText(user.RoadNo),
            "RoadName": extractText(user.RoadName),
            "Block": extractText(user.Block),
            "AreaName": extractText(user.AreaName),
            "Phase": extractText(user.Phase),
            "Sector": extractText(user.Sector),
            "Village": extractText(user.Village),
            "Town": extractText(user.Town),
            "CityName": extractText(user.CityName),
            "Address1": extractText(user.Address1 || ""),
            "Address2": extractText(user.Address2 || ""),
            "Address3": extractText(user.Address3 || ""),
            "CountryId": extractText(user.CountryId || ""),
            "StateProvince": extractText(user.StateProvince),
            "District": extractText(user.District),
            "PostalCode": extractText(user.PostalCode),
            "ZipCode": extractText(user.ZipCode),
            "POBox": extractText(user.POBox),
            "DateFormat": extractText(user.DateFormat || ""),
            "AmountFormat": extractText(user.AmountFormat || ""),
            // "ThousandSeparator": extractText(user.ThousandSeparator),
            // "DecimalSeparator": extractText(user.DecimalSeparator),
            "AcceleratorForThousands": extractText(user.AcceleratorForThousands),
            "AcceleratorForLakhs": extractText(user.AcceleratorForLakhs),
            "AcceleratorForMillions": extractText(user.AcceleratorForMillions),
          }));
 
          
 
   // Add second and third row
   const secondRow = {
    "Action": "R","SLNo": "R", "UserID": "R","Username": "R",  "Salute": "", "FirstName": "R", "MiddleName": "", "LastName": "R",  "AccountingSystemId": "", "Email": "R",
    "MobileNo": "R", "WhatsAppNo": "",  "OfficeNo": "", "Extension": "", "PassportNo": "", "DrivingLicenseNo": "", "LocalID": "","Nationality": "R", "EmployeeNo": "R",
    "CompanyCodeId": "","Department": "", "ValidFrom": "R", "ValidTo": "R",  "JoiningDate": "", "ResignationDate": "",
    "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
    "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "AreaName": "", "Phase":"","Sector": "", "Village":"", "Town": "", "CityName": "",  "Address1": "", "Address2": "", "Address3": "", 
    "CountryId": "R", "StateProvince": "Conditional", "District": "", "PostalCode": "", "ZipCode": "", "POBox": "", "Language": "R", "TimeFormat": "R", "TimeZone": "",   "DateFormat": "R", "AmountFormat": "R", 
    // "ThousandSeparator": "", "DecimalSeparator": "", 
    "AcceleratorForThousands": "", "AacceleratorForLakhs":"","AcceleratorForMillions": ""
  };

  const thirdRow = {
     "Action": "1","SLNo": "5", "UserID": "12","Username": "12","Salute": "",  "FirstName": "20", "MiddleName": "20", "LastName": "20","AccountingSystemId": "12",  "Email": "80",
    "MobileNo": "13", "WhatsAppNo": "13", "OfficeNo": "13", "Extension": "10","PassportNo": "10", "DrivingLicenseNo": "20", "LocalID": "20", "Nationality": "20",  "EmployeeNo": "12", 
     "CompanyCodeId": "10", "Department": "10", "ValidFrom": "10", "ValidTo": "10", "JoiningDate": "10", "ResignationDate": "19", 
     "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
    "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "AreaName": "", "Phase":"","Sector": "", "Village":"", "Town": "", "CityName": "",  "Address1": "50", "Address2": "50", "Address3": "50", 
    "CountryId": "2", "StateProvince": "3", "District": "", "PostalCode": "", "ZipCode": "","POBox":"", "Language": "2", "TimeFormat": "8", "TimeZone": "",  "DateFormat": "", "AmountFormat": "19", 
  //  "ThousandSeparator": "1", "DecimalSeparator": "1", 
  "AcceleratorForThousands": "1", "AacceleratorForLakhs":"1","AcceleratorForMillions": "1"
  };
 
     // Insert second and third row at the beginning of the formatted data
     rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
         }

          else if(moduleId === "13"){

          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo || ""),
            "SourceType": extractText(user.SourceType),
            "SourceCode": extractText(user.SourceCode),
            "SourceCodeDescription": extractText(user.SourceCodeDescription || ""),
            "OurCode": extractText(user.OurCode),
            "OurCodeDescription": extractText(user.OurCodeDescription || ""),
            "RateType": extractText(user.RateType),
            "DataType": extractText(user.DataType || ""),
            "Ratio": extractText(user.Ratio),
            "MDType": extractText(user.MDType),
            
          }));
 
          
 
   // Add second and third row
   const secondRow = {
    "Action": "R","SLNo": "", "SourceType": "R","SourceCode": "R", "SourceCodeDescription": "",  "OurCode": "R", "OurCodeDescription": "", "RateType": "R", "DataType": "", "Ratio": "R", "MDType": "R",

  };

  const thirdRow = {
     "Action": "1","SLNo": "5", "SourceType": "10","SourceCode": "20", "SourceCodeDescription": "50", "OurCode": "20", "OurCodeDescription": "50", "RateType": "Dropdown", "DataType": "3", "Ratio": "Default", "MDType": "Dropdown",
  };
 
     // Insert second and third row at the beginning of the formatted data
     rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
         }

         else if(moduleId === "48"){

          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo || ""),
            "BTCCode": extractText(user.BTCCode),
            "BTCDescription": extractText(user.BTCDescription),
            "StatementType": extractText(user.StatementType || ""),
            "Inflow": extractText(user.Inflow || ""),
            "OutFlow": extractText(user.OutFlow || ""),
            
          
          }));
 
          
 
   // Add second and third row
   const secondRow = {
    "Action": "R","SLNo": "R", "BTCCode": "R","BTCDescription": "R", "StatementType": "",  "Inflow": "", "Outflow": "", 

  };

  const thirdRow = {
     "Action": "1","SLNo": "5", "BTCCode": "6","BTCDescription": "50", "StatementType": "", "Inflow": "", "Outflow": "",
    };
 
     // Insert second and third row at the beginning of the formatted data
     rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
         }
         else if(moduleId === "27"){

          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
                                                        "SLNo": extractText(user.SLNo),
                                                        "BankBranch": extractText(user.BankBranch),
                                                        "CurrencyCode": extractText(user.CurrencyCode),
                                                        "Note": extractText(user.Note),
                            
                                                        "LGECode": extractText(user.LGECode),
                                                        "CompanyCode": extractText(user.CompanyCode),
                                                        "AccountType": extractText(user.AccountType),
                                                        "ZBAType": extractText(user.ZBAType),
                                                        "AccountClassification": extractText(user.AccountClassification),
                                                        "Overdraft": extractText(user.Overdraft),
                                                        "StatementType": extractText(user.StatementType),
                                                        "ODLimit": extractText(user.ODLimit),
                                                        "InterestIndicator": extractText(user.InterestIndicator),
                                                        "ODDate": extractText(user.ODDate),
                                                        "MinimumBalance": extractText(user.MinimumBalance),
                                                        "Dormant": extractText(user.Dormant),
                                                        "MainBankAccount": extractText(user.MainBankAccount),
                                                        "Text1": extractText(user.Text1),
                                                        "Text2": extractText(user.Text2),
                                                        "Text3": extractText(user.Text3),
                                                 
                                                        "Text4": extractText(user.Text4),
                                                        "Text5": extractText(user.Text5),
            
          
          }));
 
          
 
   // Add second and third row
   const secondRow = {
    "Action": "R","SLNo": "R", "BankBranch":"R","CurrencyCode":"R","Note":"","LGECode": "","CompanyCode": "", "AccountType": "", 
    "ZBAType": "", "AccountClassification": "", "Overdraft": "", "StatementType": "", "ODLimit": "Conditional", "InterestIndicator": "Conditional", 
    "ODDate": "Conditional", "MinimumBalance": "", "Dormant": "", "MainBankAccount": "Conditional", "Text1": "", "Text2": "", "Text3": "", "Text4": "", "Text5": "",
  };

  const thirdRow = {
     "Action": "1","SLNo": "5", "BankBranch":"10","CurrencyCode":"4","Note":"100","LGECode": "10","CompanyCode": "10","AccountType": "4", 
     "ZBAType": "4", "AccountClassification": "4", "Overdraft": "Dropdown", "StatementType": "", "ODLimit": "20", "InterestIndicator": "2", 
    "ODDate": "DD-MM-YYYY", "MinimumBalance": "20", "Dormant": "2", "MainBankAccount": "13", "Text1": "50", "Text2": "50", "Text3": "50", "Text4": "50", "Text5": "50",
    
  };
 
     // Insert second and third row at the beginning of the formatted data
     rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
         }
         else if(moduleId === "28"){

          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo),
            "NewRequestNo": extractText(user.NewRequestNo),
            "BankAccountNo": extractText(user.BankAccountNo),
            "BankAccountIdDescription": extractText(user.BankAccountIdDescription),

            "TextField": extractText(user.TextField),
            "IBANNo": extractText(user.IBANNo),          
          
          }));
 
          
 
   // Add second and third row
   const secondRow = {
    "Action": "R","SLNo": "R", "NewRequestNo":"R","BankAccountNo":"R","BankAccountIdDescription":"R","TextField": "","IBANNo": "Conditional"
  };

  const thirdRow = {
      "Action": "1","SLNo": "5", "NewRequestNo":"12","BankAccountNo":"25","BankAccountIdDescription":"30","TextField": "30","IBANNo": "50"
    
  };
 
     // Insert second and third row at the beginning of the formatted data
     rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
         }

         else if(moduleId === "14"){
          
          const formattedUsers = enhancedData.map((user) => ({
"Update": extractText(user.update),
            "RateType": extractText(user.RateType),
            "RateDate": extractText(user.RateDate),
            "SourceType": extractText(user.SourceType),
            "SourceCode": extractText(user.SourceCode),
            "Bid": extractText(user.Bid),
            "Ask": extractText(user.Ask),
           
          }));
  
  
            // Add second and third row
          //   const secondRow = {
          //  "RateType": "R","RateDate": "R", "SourceType": "R", "SourceCode": "R", "Bid": "","Ask": "", 
          // };
  
          //   const thirdRow = {
          //    "RateType": "3","RateDate": "","SourceType": "2", "SourceCode": "20", "Bid": "","Ask": "", 
          //   };
            // rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
  rowsToDownload = formattedUsers;
           }
         else if(moduleId === "1"){
          
        const formattedUsers = enhancedData.map((user) => ({
          "Action": extractText(user.Action),
          "SLNo": extractText(user.SLNo),
          "CountryCode": extractText(user.CountryCode),
          "CountryName": extractText(user.CountryName),
        
          "Language": extractText(user.Language || ""),
          "DateFormat": extractText(user.DateFormat || ""),
          "TimeFormat": extractText(user.TimeFormat || ""),
          "TimeZone": extractText(user.TimeZone || ""),
          "AmountFormat": extractText(user.AmountFormat || ""),
          // "ThousandSeparator": extractText(user.ThousandSeparator),
          // "DecimalSeparator": extractText(user.DecimalSeparator),
          "AcceleratorForThousands": extractText(user.AcceleratorForThousands),
          "AcceleratorForLakhs": extractText(user.AcceleratorForLakhs),
          "AcceleratorForMillions": extractText(user.AcceleratorForMillions),
        }));


          // Add second and third row
          const secondRow = {
          "Action": "R","SLNo": "R", "CountryCode": "R","CountryName": "R", "Language": "R", "DateFormat": "R", "TimeFormat": "R","TimeZone": "", "AmountFormat": "R", 
          // "ThousandSeparator": "", "DecimalSeparator": "", 
          "AcceleratorForThousands": "", "AacceleratorForLakhs":"","AcceleratorForMillions": ""
          };

          const thirdRow = {
          "Action": "1","SLNo": "5", "CountryCode": "2","CountryName": "12","Language": "2", "DateFormat": "", "TimeFormat": "8", "TimeZone": "",  "AmountFormat": "19", 
          // "ThousandSeparator": "1", "DecimalSeparator": "1", 
          "AcceleratorForThousands": "1", "AacceleratorForLakhs":"1","AcceleratorForMillions": "1"
          };
          rowsToDownload = [secondRow, thirdRow, ...formattedUsers];

         }
         else if(moduleId === "2"){
          
          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo),
            "StateCode": extractText(user.StateCode || ""),
            "StateName": extractText(user.StateName),
            "CountryCode": extractText(user.CountryCode),
          
          }));
  
  
            // Add second and third row
            const secondRow = {
              "Action": "R","SLNo": "R","StateCode":"R", "StateName": "R","CountryCode": "R"
              };
              
              const thirdRow = {
              "Action": "1","SLNo": "5","StateCode":"3", "StateName": "30","CountryCode": "2"
              };
            rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
  
           }
           else if(moduleId === "4"){
          
            const formattedUsers = enhancedData.map((user) => ({
              "Action": extractText(user.Action),
              "SLNo": extractText(user.SLNo),
              "CurrencyCode": extractText(user.CurrencyCode || ""),
              "CurrencyName": extractText(user.CurrencyName),
            
            }));
    
    
              // Add second and third row
             
// Add second and third row
const secondRow = {
  "Action": "R","SLNo": "R", "CurrencyCode": "R","CurrencyName": "R"
  };
  
  const thirdRow = {
  "Action": "1","SLNo": "5", "CurrencyCode": "4","CurrencyName": "30"
  };
  
              rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
    
             }
           else if(moduleId === "5"){
          
            const formattedUsers = enhancedData.map((user) => ({
              "Action": extractText(user.Action),
              "SLNo": extractText(user.SLNo),
              "BankCode": extractText(user.BankCode || ""),
              "Description": extractText(user.Description),
            
            }));
    
    
              // Add second and third row
              const secondRow = {
                "Action": "R","SLNo": "R", "BankCode": "R","Description": "R"
                };
                
                const thirdRow = {
                "Action": "1","SLNo": "5", "BankCode": "30","Description": "30"
                };
              rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
    
             }
           else if(moduleId === "6"){
          
            const formattedUsers = enhancedData.map((user) => ({
              "Action": extractText(user.Action),
              "SLNo": extractText(user.SLNo),
              "LE": extractText(user.LE || ""),
              "Description": extractText(user.Description),
            
            }));
    
    
              // Add second and third row
              const secondRow = {
                "Action": "R","SLNo": "R", "LE": "R","Description": "R"
                };
                
                const thirdRow = {
                "Action": "1","SLNo": "5", "LE": "2","Description": "20"
                };
              rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
    
             }
         else if(moduleId === "8"){
          
          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo),
            "BuGroup": extractText(user.BuGroup),
            "BuGroupDescription": extractText(user.BuGroupDescription),
          }));
          const secondRow = {
            "Action": "R","SLNo": "R", "BuGroup": "R","BuGroupDescription": "R", 
          "Door No": "", "Floor No": "", "Building No": "", "Building Name": "", "LandMark": "", "Street No": "", 
     "Street Name": "", "Road No": "", "Road Name": "", "Block": "", "areaName": "", "phase":"","sector": "", "village":"", "town": "", "cityName": "",  "Address 1": "", "Address 2": "", "Address 3": "", 
     "Country": "R", "State/Province": "Conditional", "District": "", "PostalCode": "", "ZipCode": "", "POBox": ""  };
        
          const thirdRow = {
             "Action": "1","SLNo": "5", "BuGroup": "12","BuGroupDescription": "12",
            "Door No": "", "Floor No": "", "Building No": "", "Building Name": "", "LandMark": "", "Street No": "", 
     "Street Name": "", "Road No": "", "Road Name": "", "Block": "", "areaName": "", "phase":"","sector": "", "village":"", "town": "", "cityName": "",  "Address 1": "50", "Address 2": "50", "Address 3": "50", 
     "Country": "2", "State/Province": "3", "District": "", "PostalCode": "", "ZipCode": "",
      };
        
            // Insert second and third row at the beginning of the formatted data
            rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
            
         }
         else if(moduleId === "7"){
          
          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo),
            "LGECode": extractText(user.LGECode),
            "LegalEntityName": extractText(user.LegalEntityName),
            "CurrencyCode": extractText(user.CurrencyCode),
            "Door No": extractText(user.DoorNo),
            "Floor No": extractText(user.FloorNo),
            "Building No": extractText(user.BuildingNo),
            "Building Name": extractText(user.BuildingName),
            "LandMark": extractText(user.LandMark),
            "Street No": extractText(user.StreetNo),
            "Street Name": extractText(user.StreetName),
            "Road No": extractText(user.RoadNo),
            "Road Name": extractText(user.RoadName),
            "Block": extractText(user.Block),
            "areaName": extractText(user.areaName),
            "phase": extractText(user.phase),
            "sector": extractText(user.sector),
            "village": extractText(user.village),
            "town": extractText(user.town),
            "cityName": extractText(user.cityName),
            "Address 1": extractText(user.Address1 || ""),
            "Address 2": extractText(user.Address2 || ""),
            "Address 3": extractText(user.Address3 || ""),
            "Country": extractText(user.Country || ""),
            "State/Province": extractText(user.StateProvince),
            "District": extractText(user.District),
            "PostalCode": extractText(user.PostalCode),
            "ZipCode": extractText(user.ZipCode),
            "POBox": extractText(user.POBox),
          }));

          const secondRow = {
            "Action": "R","SLNo": "R", "LGECode": "R","LegalEntityName": "R", "CurrencyCode":"R"   };
        
          const thirdRow = {
             "Action": "1","SLNo": "5", "LGECode": "10","LegalEntityName": "150", "CurrencyCode":"3"  };
        
            // Insert second and third row at the beginning of the formatted data
            rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
            
         }
         else if(moduleId === "14"){
          
          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo),
            "CompanyCode": extractText(user.CompanyCode),
            "CompanyName": extractText(user.CompanyName),
            "CompanyShortName": extractText(user.CompanyShortName),
            "LGECode": extractText(user.LGECode),
            "BuGroupCode": extractText(user.BuGroupCode),
            "CurrencyCode": extractText(user.CurrencyCode),

           
          }));

          const secondRow = {
            "Action": "R","SLNo": "R", "LGECode": "R","LegalEntityName": "R", "CurrencyCode":"R"   };
        
          const thirdRow = {
             "Action": "1","SLNo": "5", "LGECode": "10","LegalEntityName": "150", "CurrencyCode":"3"  };
        
            // Insert second and third row at the beginning of the formatted data
            rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
            
         }  else if(moduleId === "1"){
          
          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo),
            "CountryCode": extractText(user.CountryCode),
            "CountryName": extractText(user.CountryName),
          
            "Language": extractText(user.Language || ""),
            "DateFormat": extractText(user.DateFormat || ""),
            "TimeFormat": extractText(user.TimeFormat || ""),
            "TimeZone": extractText(user.TimeZone || ""),
            "AmountFormat": extractText(user.AmountFormat || ""),
            // "ThousandSeparator": extractText(user.ThousandSeparator),
            // "DecimalSeparator": extractText(user.DecimalSeparator),
            "AcceleratorForThousands": extractText(user.AcceleratorForThousands),
            "AcceleratorForLakhs": extractText(user.AcceleratorForLakhs),
            "AcceleratorForMillions": extractText(user.AcceleratorForMillions),
          }));
  
  
            // Add second and third row
            const secondRow = {
            "Action": "R","SLNo": "R", "CountryCode": "R","CountryName": "R", "Language": "R", "DateFormat": "R", "TimeFormat": "R","TimeZone": "", "AmountFormat": "R", 
            // "ThousandSeparator": "", "DecimalSeparator": "",
             "AcceleratorForThousands": "", "AacceleratorForLakhs":"","AcceleratorForMillions": ""
            };
  
            const thirdRow = {
            "Action": "1","SLNo": "5", "CountryCode": "2","CountryName": "12","Language": "2", "DateFormat": "", "TimeFormat": "8", "TimeZone": "",  "AmountFormat": "19", 
            // "ThousandSeparator": "1", "DecimalSeparator": "1", 
            "AcceleratorForThousands": "1", "AacceleratorForLakhs":"1","AcceleratorForMillions": "1"
            };
            rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
  
           }
         else if(moduleId === "11"){
          
          const formattedUsers = enhancedData.map((user) => ({
            "Action": extractText(user.Action),
            "SLNo": extractText(user.SLNo),
            "BankBranch": extractText(user.BankBranch),
            "BankBranchName": extractText(user.BankBranchName),
            "BankId": extractText(user.BankId),
            "CopyTheBankIDAddress": extractText(user.CopyTheBankIDAddress),

            "DoorNo": extractText(user.DoorNo),
            "FloorNo": extractText(user.FloorNo),
            "BuildingNo": extractText(user.BuildingNo),
            "BuildingName": extractText(user.BuildingName),
            "LandMark": extractText(user.LandMark),
            "StreetNo": extractText(user.StreetNo),
            "StreetName": extractText(user.StreetName),
            "RoadNo": extractText(user.RoadNo),
            "RoadName": extractText(user.RoadName),
            "Block": extractText(user.Block),
            "AreaName": extractText(user.AreaName),
            "Phase": extractText(user.Phase),
            "Sector": extractText(user.Sector),
            "Village": extractText(user.Village),
            "Town": extractText(user.Town),
            "CityName": extractText(user.CityName),
            "AddressLine1": extractText(user.AddressLine1 || ""),
            "AddressLine2": extractText(user.AddressLine2 || ""),
            "AddressLine3": extractText(user.AddressLine3 || ""),
            "CountryId": extractText(user.CountryId || ""),
            "StateProvince": extractText(user.StateProvince),
            "District": extractText(user.District),
            "PostalCode": extractText(user.PostalCode),
            "ZipCode": extractText(user.ZipCode),
            "POBox": extractText(user.POBox),
            "BankBic": extractText(user.BankBic),
            "CountryCode": extractText(user.CountryCode),
            "CountryCodeType": extractText(user.CountryCodeType),
            "CustomerCode": extractText(user.CustomerCode),
            "MICR": extractText(user.MICR),
            "BankCode": extractText(user.BankCode),
            "BranchCode": extractText(user.BranchCode),
            "Min": extractText(user.Min),
            "Max": extractText(user.Max),
            "IBANRequired": extractText(user.IBANRequired),
          }));
        
            // Add second and third row
            const secondRow = {
              "Action": "R","SLNo": "R", "BankBranch": "R","BankBranchName": "R",  "BankId": "R", "CopyTheBankIDAddress": "R",
              "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
              "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "AreaName": "", "Phase":"","Sector": "", "Village":"", "Town": "", "CityName": "",  "AddressLine1": "", "AddressLine2": "", "AddressLine3": "", 
              "CountryId": "R", "StateProvince": "Conditional", "District": "", "PostalCode": "", "ZipCode": "", "POBox": "","BankBic":'', "CountryCode":'', "CountryCodeType":"","CustomerCode":"","MICR":"","BankCode":"","BranchCode":"","Min":"","Max":"","IBANRequired":"Check box"
            };
          
            const thirdRow = {
                "Action": "1","SLNo": "5", "BankBranch": "10","BankBranchName": "150","BankId": "3", "CopyTheBankIDAddress": "Check box",
                "DoorNo": "", "FloorNo": "", "BuildingNo": "", "BuildingName": "", "LandMark": "", "StreetNo": "", 
              "StreetName": "", "RoadNo": "", "RoadName": "", "Block": "", "AreaName": "", "Phase":"","Sector": "", "Village":"", "Town": "", "CityName": "",  "AddressLine1": "50", "AddressLine2": "50", "AddressLine3": "50", 
              "CountryId": "2", "StateProvince": "3", "District": "", "PostalCode": "", "ZipCode": "","POBox": "","BankBic":'', "CountryCode":'', "CountryCodeType":"","CustomerCode":"","MICR":"","BankCode":"","BranchCode":"","Min":"","Max":"","IBANRequired":""
            };
            // Insert second and third row at the beginning of the formatted data
            rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
            
         }

                 const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
               console.log("rowsToDownload data is",rowsToDownload);
           
               const border = {
                top: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
              };

                // Apply borders to the first three rows
                rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
                      Object.keys(row).forEach((col, colIdx) => {
                        const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
                        if (worksheet[cellAddress]) {
                          worksheet[cellAddress].s = { border };
                        }
                      });
                    });

                     // Additional cell styling for validation errors
                            Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
                              rowsToDownload.forEach((row, rowIdx) => {
                                const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
                                const cellValue = row[col];
                          
                                if (
                                  typeof cellValue === "string" && (
                                    cellValue.includes("Shouldn't be blank") 
                                    ||
                                    cellValue.includes("Either Address Section or Building, Street, and Area Section must be entered")
                                    // cellValue.includes("Max. allowed character")
                                  )
                                ) {
                                  worksheet[cellAddress] = {
                                    v: cellValue,
                                    s: { font: { color: { rgb: "FF0000" } } },
                                  };
                                } else if (Array.isArray(row[col])) {
                                  worksheet[cellAddress] = {
                                    v: extractText(row[col]),
                                    s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
                                  };
                                }
                                else {
                                  worksheet[cellAddress] = { v: cellValue };
                                }
                              });
                            });
                          
                            // AutoFit column width
                            worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
                              const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
                              return { wch: maxLength + 2 }; // Add extra space
                            });
                    


    // const worksheet = XLSX.utils.json_to_sheet(rowsToDownload); // Converts JSON to sheet
    const workbook = XLSX.utils.book_new(); // Creates a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users"); // Appends the sheet to the workbook

    // Export as an Excel file
    XLSX.writeFile(workbook, "Task_Monitor_Data.xlsx");
  };

    
    const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));

    
            const existingButtons = AdminActionsConfig.screen7.map((button) => {
              let onClick;
              let title;
          
          
              if (button.icon === MdOutlineFileDownload) {
                onClick = handleDownload;
                title = "Download File";
              }
          
              else {
                onClick = button.onClick || (() => { }); // Provide a default no-op function if onClick is undefined
                title = button.title || ""; // Use existing title or default to an empty string
              }
          
              return {
                ...button,
                onClick, // Reference the function without invoking it
                title,   // Add the title property to the button configuration
              };
            });
          

    const extraButtons = [];
  
        const actionButtons = existingButtons.concat(
          extraButtons.map((button) => ({
            ...button,
            disabled: selectedRows.length === 0, // Disable button if no users are selected
            onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
          }))
        );

    
const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Task Monitor') {
        console.log("Navigating to Task Monitor");
        navigate('/134/task-monitor');
      }
  
      else if (crumb.title === "Log / Attachments") {
        console.log("Log / Attachments");
        // navigate("/14/admin");
      }
      else if (crumb.title === "Display") {
        console.log("Display");
        // navigate("/14/admin");
      }
    };

    const enhancedData = tableData.map((row) => {
        if (row.update === "Yes") {
          return { ...row, successfulrecords: true };
        }
        return row;
      });

  return (
    <div>
        <Header
        title={`Task Monitor > Log / Attachments > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // hasUnsavedChanges={unsavedChanges}
        backRoute="/134/task-monitor"
      />
      {/* <h2>User Upload Display option{tableData} </h2> */}

      {loading &&
          <div><CustomeLoading /></div>
        }

<AdminActions
              icons={iconId}
              buttons={actionButtons}
              // extraButtons={extraButtons}
              selectedCount={selectedRows.length}
              selectUserIds={selectedRows} 
              // hasFiltersApplied={hasFiltersApplied}
              />


      <ReTable
          data={enhancedData}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={[]}
          onSelectRow={() => {}}
          onSelectAll={() => {}}
          showCheckbox={false}
          // rowKey="userId" 
          onRowDoubleClick={() => {}}
        />

    </div>
  )
}
