import React, { useEffect, useState } from "react";
import Card from "../../../components/Card";
import { BsBank } from "react-icons/bs";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import { MdAccountBalance, MdOutlineSecurityUpdateWarning } from "react-icons/md";
import axios from "axios";
import { IoIdCardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { AiOutlineFundView } from "react-icons/ai";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { FaBalanceScale } from "react-icons/fa";
import { PiFlowArrowBold } from "react-icons/pi";
import { TiFlowChildren, TiFlowMerge } from "react-icons/ti";
import { LuArrowUpWideNarrow } from "react-icons/lu";
import { LiaBalanceScaleSolid } from "react-icons/lia";
import { TbBrandCodesandbox } from "react-icons/tb";
import MainHeader from "../../../components/MainHeader";
import Dashboard from "../../../components/Dashboard";

export default function CashFlowScreen() {
  const [setUpOpen, setSetUpOpen] = useState(true);
  const [FlowAnalysisOpen, setFlowAnalysisOpen] = useState(true);
    const [moduleAssignments, setModuleAssignments] = useState([]);
    const [userId, setUserId] = useState(null);
    const [subMenus, setSubMenus] = useState([]);
  

  const toggleSetUp = () => {
    setSetUpOpen(!setUpOpen);
  };
  const ToggleFlowAnalysis = () => {
    setFlowAnalysisOpen(!FlowAnalysisOpen);
  };
  const handleCardClick = (route) => {
    navigate(route);
  };
  const navigate = useNavigate();

  useEffect(() => {

    // Fetch user ID from session storage
    const fetchUserId = () => {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setUserId(userDetail.id || null);
      }
    };

    fetchUserId();

    // Fetch sub-menu data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-sub-menu`)
      .then((response) => {
        const filteredMenus = response.data.data.filter(menu => menu.mainMenuId === 9);
        setSubMenus(filteredMenus);
      })
      .catch((error) => console.error("Error fetching sub-menus:", error));

    // Fetch module assignment data
    axios.get(`${process.env.REACT_APP_BASE_URL}/get-module-assignment`)
      .then((response) => {
        // Filter module assignments by userId
        const filteredAssignments = response.data.filter(
          (assignment) =>
            assignment.userId === String(userId) && assignment.isChecked === true
        );
        setModuleAssignments(filteredAssignments);
      })
      .catch((error) => console.error("Error fetching module assignments:", error));
  }, [userId]);

  const filteredSections = subMenus.filter((menu) => {
    return moduleAssignments.some(
      (assignment) => assignment.subMenuId === menu.id && assignment.isChecked
    );
  });

  const submenuOrder = ["Set up", "Flow Analysis"];

  const orderedSections = filteredSections.sort((a, b) => {
    return submenuOrder.indexOf(a.name) - submenuOrder.indexOf(b.name);
  });

  const sections = orderedSections.map((menu) => {
      let cards;
  
      if (menu.name === "Set up") {
        cards = [
          { title: "Flow Code Heads", icon: <LuArrowUpWideNarrow />, onClick: () => handleCardClick("/flow-code-heads") },
          { title: "NT Codes", icon: <TbBrandCodesandbox />, onClick: () => handleCardClick("/nt-codes") },
          { title: "Mapping Rule ID", icon: <TiFlowMerge />, onClick: () => handleCardClick("/mapping-rule-id") },
         ];
      }
      else if (menu.name === "Flow Analysis") {
        cards = [
          { title: "Cash Flows", icon: <LiaBalanceScaleSolid />, onClick: () => handleCardClick("/cash-flows") },
          { title: "Fund Transfer", icon: <FaMoneyBillTransfer />, onClick: () => handleCardClick("/fund-transfer") },
          { title: "Inter Company FT", icon: <IoIdCardOutline />, onClick: () => handleCardClick("/inter-company-ft") },
          { title: "Not Assigned", icon: <MdOutlineSecurityUpdateWarning />, onClick: () => handleCardClick("/not-assigned") },
          { title: "Standing", icon: <FaBalanceScale />, onClick: () => handleCardClick("/standing") },
          { title: "Advanced Analysis", icon: <AiOutlineFundView />, onClick: () => handleCardClick("/advanced-analysis") },
        ];
      } else {
        // Optionally handle other cases or default cards
        cards = [];
      }
  
      return {
        title: menu.name,
        subMenuId: menu.id,
        cards: cards,
      };
    });

  // return (
  //   <div>
  //     <MainHeader title={"Cash Flow"}/>
  //     <section>
  //       <div className="mt-2 ml-2 ">
  //         <div className="bg-muted  rounded-lg mb-12">
  //           <div
  //             className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
  //             onClick={toggleSetUp}
  //           >
  //             <h2 className="text-left text-md font-medium  text-muted-foreground text-muted-foreground">
  //               Set up
  //             </h2>
  //             <span className="p-1">
  //               {setUpOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
  //             </span>
  //           </div>
  //           <div
  //             className={`overflow-hidden transition-opacity duration-900 ${
  //               setUpOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
  //             }`}
  //           >
  //             <div className="my-2 -mt-4">
  //               {setUpOpen && (
  //                 <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">           
  //                   <Card
  //                     title="Flow Code Heads"
  //                     icon={<LuArrowUpWideNarrow />}
  //                     onClick={() => handleCardClick("/flow-code-heads")}
  //                   />
  //                   <Card
  //                     title="NT Codes"
  //                     icon={<TbBrandCodesandbox />}
  //                     onClick={() => handleCardClick("/nt-codes")}
  //                   />
  //                   <Card
  //                     title="Mapping Rule ID"
  //                     icon={<TiFlowMerge />}
  //                     onClick={() => handleCardClick("/mapping-rule-id")}
  //                   />
  //                 </div>
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="-mt-12 ml-2">
  //         <div className="bg-muted  rounded-lg mb-16">
  //           <div
  //             className="flex justify-between cursor-pointer border bg-sky-50 w-full  px-4 py-1"
  //             onClick={ToggleFlowAnalysis}
  //           >
  //             <h2 className="text-left text-md font-medium  text-muted-foreground text-muted-foreground">
  //               Flow Analysis
  //             </h2>
  //             <span className="p-1">
  //               {FlowAnalysisOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
  //             </span>
  //           </div>
  //           <div
  //             className={`overflow-hidden transition-opacity duration-900 ${
  //               FlowAnalysisOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0"
  //             }`}
  //           >
  //             <div className="my-2 -mt-4">
  //               {FlowAnalysisOpen && (
  //                 <div className="mt-4 flex flex-wrap gap-6  mt-1  p-3">           
  //                   <Card
  //                     title="Cash Flows"
  //                     icon={<LiaBalanceScaleSolid />}
  //                     onClick={() => handleCardClick("/cash-flows")}
  //                   />
  //                   <Card
  //                     title="Fund Transfer"
  //                     icon={<FaMoneyBillTransfer />}
  //                     onClick={() => handleCardClick("/fund-transfer")}
  //                   />
  //                   <Card
  //                     title="Inter Company FT"
  //                     icon={<IoIdCardOutline />}
  //                     onClick={() => handleCardClick("/inter-company-ft")}
  //                   />
  //                   <Card
  //                     title="Not Assigned"
  //                     icon={<MdOutlineSecurityUpdateWarning />}
  //                     onClick={() => handleCardClick("/not-assigned")}
  //                   />
  //                   <Card
  //                     title="Standing"
  //                     icon={<FaBalanceScale />}
  //                     onClick={() => handleCardClick("/standing")}
  //                   />
  //                   <Card
  //                     title="Advanced Analysis"
  //                     icon={<AiOutlineFundView />}
  //                     onClick={() => handleCardClick("/advanced-analysis")}
  //                   />
  //                 </div>
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   </div>
  // );

  
  return (
    <>
      <div>
        <MainHeader title={"Cash Flow"} />
        <Dashboard sections={sections} />
        </div>


</>
);
}

