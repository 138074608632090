import React, { useEffect, useState } from 'react';
import Header from '../../../../../../components/Header';
import { useNavigate } from 'react-router-dom';
import ReTable from '../../../../../../components/Table';
import UserService from '../../../../../../rest-services/UserServices';
import { useToast } from '../../../../../../components/toast/toast';
import AdminActions from '../../../Components/AdminActions';
import { IoMdRefresh } from 'react-icons/io';
import { AdminActionsConfig } from '../../../Components/ActionConfig';
import Footer from '../../../../../../components/footer';
import ButtonGroup from '../../../../../../components/FooterButton';
// import { toast } from 'react-toastify';
export default function MakerAndChecker() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  // Define columns for the table
  const columns = [
    {
      Header: 'S.No', 
      accessor: 'serialNumber', // This will be calculated dynamically
      visible: true, 
      width: 50, 
      Cell: ({ row }) => row.index + 1 // Generate serial number based on row index
    },
    { Header: 'Module', accessor: 'mainMenu.name', visible: true, width: 200 },
    { Header: 'Object', accessor: 'name', visible: true, width: 200 },
  ];

  const [columnSettings, setColumnSettings] = useState(columns);
  const filteredUsers = []; 
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [Icons, setIcons] = useState([]);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
      fetchIconsData();
    }, []);

  const fetchIconsData = async () => {
    try {
      const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-icons-data`);      
      console.log("Response from server fetching users:", response);
      const responseData = response.data.data;
      setIcons(responseData);


      console.log("double check users",response.data.data);
      if (responseData.error) {
        console.error("Error fetching Users data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching Users data", error);
      setAlert({ severity: "error", message: `Error fetching Users data, kindly check the connection ${error.message}` });
    }
  };


  // Handle breadcrumb click navigation
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'User & SOD') {
      navigate('/14/admin');
    } else if (crumb.title === 'Admin') {
      navigate('/14/admin');
    } else if (crumb.path) {
      navigate(crumb.path); // Navigate to the specified path in the breadcrumb
    }
  };

  // Filter and map visible columns to be used in the table
  const modifiedColumns = columnSettings
    .filter(col => col.visible)
    .map(col => ({ ...col, width: col.width }));

  
    const handleSelectRow = (id) => {
      handleCheckboxChange(id);
    };

      const handleCheckboxChange = (id) => {
      console.log('checked user id',id);
      const updatedSelectedRows = selectedRows.includes(id)
        ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
        : [...selectedRows, id]; // Add to selection if not selected
  
      setSelectedRows(updatedSelectedRows);
      console.log('check box:', updatedSelectedRows);
    
    };

    const handleSaveMakerAndChecker = async () => {
  
      try {
        console.log("entered");
        const data = {
          ids: selectedRows
        };
        
        console.log("selected row in save details",data);

        const response = await UserService.saveAllUserDetails(
         `${process.env.REACT_APP_BASE_URL}/edit-maker-and-checker-update`,
          data
        );
        console.log("response data", data);
        console.log("response", response);
        console.log("response status", response.status);
        if (response.status === 200) {
          setAlert({
            severity: "success",
            message: "Basic Details saved successfully.",
          });
  
            // Wait for the success message to be displayed before navigating
            toast("success", "Changes Saved");
              navigate("/14/admin"); // Navigate to loginMethod Details tab
        }         
        else {
          setAlert({ severity: "error", message: "Not saved" });
          toast("warning", "Not saved.");
  
        }
      } catch (error) {
        console.error("Error saving basic details:", error);
        setAlert({
          severity: "error",
          message: "An error occurred while saving.",
        });
      }
    };


    const reload = async () => {
      setLoading(true);  // Start loading
      try {
      await fetchIconsData();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
    }
    const existingButtons = AdminActionsConfig.screen3.map((button) => {
      let onClick;
      let title;
    
     
      if (button.icon === IoMdRefresh) {
        onClick = reload; // Reference the reload function, not invoking it
        title = "Refresh";
      } else {
        onClick = button.onClick || (() => {}); // Provide a default no-op function if onClick is undefined
        title = button.title || ""; // Use existing title or default to an empty string
      }
    
      return {
        ...button,
        onClick, // Reference the function without invoking it
        title,   // Add the title property to the button configuration
      };
    });

    const extraButtons = [ ];

    const actionButtons = existingButtons.concat(
      extraButtons.map((button) => ({
        ...button,
        disabled: selectedRows.length === 0, // Disable button if no users are selected
        onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
      }))
    );
    

  return (
    <div>
      {/* Page Header with Breadcrumb */}
      <Header 
        title="Admin > User & SOD > Maker & Checker"
        onBreadcrumbClick={handleBreadcrumbClick}
      />

      <div className="flex justify-between">
      <AdminActions
  buttons={actionButtons} 
  // extraButtons={extraButtons}
  selectedCount={selectedRows.length} 
  selectUserIds={selectedRows}/>

      </div>

      <div>
{loading && <div className="loading-spinner">Loading...</div>}

        {/* Table Component */}
        <ReTable
          data={Icons}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          showHeaderCheckbox={false} 
          customWidth="50%" // Set 50% width
          showCheckbox={true} 
          checkboxPosition="end" // Set the checkbox at the end
        />
      </div>

      <Footer>
  
     {/* <ButtonGroup
        onSave={handleSaveMakerAndChecker}
      />  */}
      <button
            
            onClick={handleSaveMakerAndChecker}
            className="bg-customBlue text-sm text-white py-1 px-2 rounded hover:bg-blue-600 transition duration-200 ease-in-out w-16 ml-7">
            Submit
            </button>
</Footer>

    </div>
  );
}
