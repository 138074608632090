import RestService from "./RestServices";
const basePath = "/bam";

const BamServices = {
  getAccountType: () => {
    return RestService.GetAllData("/get-account-types");
  },
  getAllZBATypes: () => {
    return RestService.GetAllData("/get-zba-types");
  },
  getAllCurrency: () => {
    return RestService.GetAllData("/get-currency");
  },
  getAllLegalEntity: () => {
    return RestService.GetAllData("/get-all-legal-entity");
  },
  getAssociatedCompany: (entitCode) => {
    return RestService.GetAllData(
      `${basePath}/get-associate-company?id=${entitCode}`
    );
  },
  addBankAccountMaster: (data) => {
    return RestService.CreateData(`${basePath}/create-account`, data);
  },
};
export default BamServices;
