import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";

import { useNavigate } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import { useToast } from "../../../../../components/toast/toast";

import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import BankTemporaryImage from "../../../../../assets/Hdfc.jpeg";
import ReTable from "../../../../../components/Table";
import TableWrapper from "../../../../../components/ScrollBarComponent";
import Pagination from "../../../../../components/PageNation";
import CustomeLoading from "../../../../../components/CustomeLoading";
import LabelWithButton from "../../../../../components/InputButtonField";
import ReusableModal from "../../../../../components/PopupComponent";
import LabelComponent from "../../../../../components/LabelComponent"
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import RadioPopUp from "../../../../../components/RadioButtonComponent";
import CashBalanceServices from "../../../../../rest-services/CashBalanceServices";
import { set } from "lodash";
import Footer from "../../../../../components/footer";
import CustomButton from "../../../../../components/CustomButton";
import HoverButton from "../../../../../components/HoverButton";
import { IoMdRefresh } from "react-icons/io";
import moment from "moment";
import { RxReset } from "react-icons/rx";
import { MdOutlineFileDownload } from "react-icons/md";

export default function AccountPortal() {
  const [errors, setErrors] = useState({});
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ id: "", assigner: "", clientId: "" }); // State to store assignee


  const [isCompanyCodeModelOpen, setIsCompanyCodeModelOpen] = useState(false);
  const [isBankBranchModelOpen, setIsBankBranchModelOpen] = useState(false);
  const [isCompanyNameOpen, setICompanyNameModelOpen] = useState(false);
  const [isBranchNameModelOpen, setIsBranchNameModelOpen] = useState(false);
  const [isBankAccountIdModelOpen, setIsBankAccountIdModelOpen] = useState(false);
  const [isAccountDescriptionModelOpen, setIsAccoutDescriptionModelOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  //*PageNation Fields
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  //*Master Data
  const [company, setCompany] = useState([])
  const [branch, setBranch] = useState([])
  const [bankAccount, setBankAccount] = useState([])



  const columns = React.useMemo(() => [
    {
      Header: "Transaction Date", accessor: "transactionDate", visible: true, width: ("Transaction Date".length -2)*10, Cell: ({ value }) => {
        if (!value) return "";
        const date = new Date(value);
        return <div style={{ textAlign: "center" }}>{`${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`}</div>;
      },
    },
    { Header: "Reference", accessor: "referenceNo", visible: true, width: ("Reference".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>, },
    { Header: "Description", accessor: "tagDescription1", visible: true, width: ("Description".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "Currency", accessor: "currency", visible: true, width: ("Currency".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div> },
    { Header: "Debit", accessor: "debitAmount", visible: true, width: ("Debit".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div> },
    { Header: "Credit", accessor: "creditAmount", visible: true, width: ("Credit".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div> },
    { Header: "Cumulative Balance", accessor: "cumulativeBalance", visible: true, width: ("Cumulative Balance".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "right" }}>{value}</div> },
    { Header: "Tag 86 Description", accessor: "tag86Desc1", visible: true, width: ("Tag 86 Description".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "NT Code", accessor: "", visible: true, width: ("NT Code".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },
    { Header: "NT Code Description", accessor: "", visible: true, width: ("NT Code Description".length -2)*10, Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div> },

    // { Header: "Created By", accessor: "created_by", visible: true, width: 200, alignment: "left" },
    // {
    //   Header: "Created Date",
    //   accessor: "creationDate",
    //   visible: true,
    //   width: 200,
    //   canResize: true,
    //   Cell: ({ value }) => {
    //     if (!value) return "";
    //     const date = new Date(value);
    //     const day = String(date.getDate()).padStart(2, "0");
    //     const month = String(date.getMonth() + 1).padStart(2, "0");
    //     const year = date.getFullYear();
    //     return `${day}-${month}-${year}`;
    //   },
    // },
    // {
    //   Header: "Created Time",
    //   accessor: "creationTime",
    //   visible: true,
    //   width: 200,
    //   canResize: true,
    //   Cell: ({ value }) => {
    //     if (!value) return "";
    //     const date = new Date(value);
    //     return date.toLocaleTimeString("en-GB", {
    //       hour: "2-digit",
    //       minute: "2-digit",
    //       second: "2-digit",

    //       hour12: true,
    //     });
    //   },
    // },
    // {
    //   Header: "Updated By",
    //   accessor: "changed_by",
    //   visible: true,
    //   width: 200,
    //   canResize: true,
    //   alignment: "left"
    // },
    // {
    //   Header: "Updated Date",
    //   accessor: "changedDate",
    //   visible: true,
    //   width: 200,
    //   canResize: true,
    //   Cell: ({ value }) => {
    //     if (!value) return "";
    //     const date = new Date(value);
    //     const day = String(date.getDate()).padStart(2, "0");
    //     const month = String(date.getMonth() + 1).padStart(2, "0");
    //     const year = date.getFullYear();
    //     return `${day}-${month}-${year}`;
    //   },
    // },
    // {
    //   Header: "Updated Time",
    //   accessor: "changedTime",
    //   visible: true,
    //   width: 200,
    //   canResize: true,
    //   Cell: ({ value }) => {
    //     if (!value) return "";
    //     const date = new Date(value);
    //     return date.toLocaleTimeString("en-GB", {
    //       hour: '2-digit',
    //       minute: '2-digit',
    //       second: '2-digit',

    //       hour12: true,
    //     });
    //   }
    // },
  ]);
  const [columnSettings, setColumnSettings] = useState(columns);




  useEffect(() => {
    fetchAssignee();
    fetchCompanyCode();
    fetchBranch();
    fetchAllBankAccountId();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };
        console.log("Assignee Data:", assigneeData);
        setAssignee(assigneeData);
      } else {
      }
    } catch (error) {

    }
  };

  const fetchCompanyCode = async () => {
    try {
      const response = await CashBalanceServices.getCompanyCode();
      if (response.status === 200) {
        const data = response.data.data;
        setCompany(data)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchBranch = async () => {
    try {
      const response = await CashBalanceServices.getBranch();
      if (response.status === 200) {
        const data = response.data.data;
        setBranch(data)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchAllBankAccountId = async (companyCode, branch) => {
    try {
      const response = await CashBalanceServices.getAllBankAccountId(companyCode, branch);
      if (response.status === 200) {
        const data = response.data.data;
        setBankAccount(data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [data, setData] = useState([]);


  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };



  const [companyCode, setCompanyCode] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [bankBranch, setBankBranch] = useState('')
  const [branchName, setBranchName] = useState('')
  const [bankAccountID, setBankAccountId] = useState('')
  const [accountDescription, setAccountDescription] = useState('')
  const [transactionFromDate, setTransactionFromDate] = useState('')
  const [transactionToDate, setTransactionToDate] = useState('')
  const [transactionFromMonth, setTransactionFromMonth] = useState('')
  const [transactionToMonth, setTransactionToMonth] = useState('')
  const [pageBalances, setPageBalances] = useState({});

  // const getDataFoTable = async (page) => {
  //   try {
  //     const isMonthRangeFilled = transactionFromMonth && transactionToMonth;
  //     const isDateRangeFilled = transactionFromDate && transactionToDate;

  //     if (!bankAccountID) return toast("error", "Please Select Bank Account Id");
  //     if (!isMonthRangeFilled && !isDateRangeFilled) {
  //       return toast("error", "Please fill either both month fields or both date fields.");
  //     }

  //     const previousBalance = pageBalances[page];

  //     const requestData = {
  //       bankAccountId: bankAccountID,
  //       accountDescription: accountDescription,
  //       transactionFromMonth,
  //       transactionToMonth,
  //       transactionFromDate,
  //       transactionToDate,
  //       lastCummalativeBalance: previousBalance
  //     };

  //     const response = await CashBalanceServices.getAllStatement(requestData, pageSize, page);

  //     if (response.status === 200) {
  //       const data = response.data;
  //       const totalCount = response.totalCount;
  //       const cumulativeBalance = response.lastCummalativeBalance;

  //       setData(data);
  //       setTotalCount(totalCount);
  //       setTotalPages(Math.ceil(totalCount / 20));

  //       // Store the cumulative balance for the page to use when navigating back
  //       setPageBalances((prev) => ({
  //         ...prev,
  //         [page]: cumulativeBalance
  //       }));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getDataFoTable = async (page) => {
    try {
      const isMonthRangeFilled = transactionFromMonth && transactionToMonth;
      const isDateRangeFilled = transactionFromDate && transactionToDate;

      if (!bankAccountID) return toast("error", "Please Select Bank Account Id");
      if (!isMonthRangeFilled && !isDateRangeFilled) {
        return toast("error", "Please fill either both month fields or both date fields.");
      }

      // Get previous balance logic
      let previousBalance;
      if (pageBalances[page] !== undefined) {
        // If balance exists for the current page, use it
        previousBalance = pageBalances[page];
      } else if (pageBalances[page - 1] !== undefined) {
        // If navigating back, get the balance from the previous page
        previousBalance = pageBalances[page - 1];
      } else {
        // Default to null or any fallback value
        previousBalance = null;
      }

      const requestData = {
        bankAccountId: bankAccountID,
        accountDescription: accountDescription,
        transactionFromMonth,
        transactionToMonth,
        transactionFromDate,
        transactionToDate,
        lastCummalativeBalance: previousBalance, // Send the correct cumulative balance
      };

      const response = await CashBalanceServices.getAllStatement(requestData, pageSize, page);

      if (response.status === 200) {
        if (response.totalCount === 0) {
          return toast("error", "No Data Found");
        }
        const data = response.data;
        const totalCount = response.totalCount;
        const cumulativeBalance = response.lastCummalativeBalance;

        setData(data);
        setTotalCount(totalCount);
        setTotalPages(Math.ceil(totalCount / (pageSize || 1)));
        setPageBalances((prev) => ({
          ...prev,
          [page + 1]: cumulativeBalance,
        }));
      } else {
        toast("error", "Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };


  const existingButtons = PrimaryActionsConfig.screen6.map((button) => {
    let onClick = button.onClick;
    let title = button.title;
    if (button.icon === IoMdRefresh) {
      onClick = () => {
        setPageBalances({}); // Clear the state
        if (Object.keys(pageBalances).length === 0) {
          getDataFoTable(currentPage);
        }

        setCurrentPage(1);
      };
      title = "Refresh";
    }
    if (button.icon === MdOutlineFileDownload) {
      onClick = () => {
        downLoadData();
      }
    }
    return {
      ...button,
      onClick,
      title,
    };
  });

  const onSelectRow = (id) => {
    console.log("Row ID:", id);
    console.log("Previous selected rows:", selectedRows);

    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

    setSelectedRows(updatedSelectedRows);

  };


  const handleShowAll = () => {
    setPageSize(totalCount); // Set page size to total count to display all records
    setCurrentPage(1); // Reset to the first page
    getDataFoTable(1); // Fetch data with updated page size
  };

  const toggleDetails = () => {
    if (!bankAccountID) return toast("error", "Please Select Bank Account Id")
    setIsDetailsOpen(!isDetailsOpen);
  }

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((row) => row.id));
    }
  };
  const handleDoubleClick = (id) => {
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (!bankAccountID) return
    getDataFoTable(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchAllBankAccountId(companyCode, bankBranch)
  }, [companyCode, bankBranch])

  const companyCodeChange = (selectedCode) => {
    setCompanyCode(selectedCode);
    setAccountDescription('');
    setBankAccountId('');

    const selectedCompany = company.find(item => item.companyCode === selectedCode);
    setCompanyName(selectedCompany ? selectedCompany.companyName : '');
  };

  const companyNameChange = (selectedCode) => {
    setCompanyName(selectedCode)
    setAccountDescription('');
    setBankAccountId('');
    const selectedCompany = company.find(item => item.companyName === selectedCode);
    setCompanyCode(selectedCompany ? selectedCompany.companyCode : '');
  }
  const bankBranchChange = (selectedCode) => {
    setBankBranch(selectedCode)
    setAccountDescription('');
    setBankAccountId('');
    const selectedCompany = branch.find(item => item.bankBranch === selectedCode);
    setBranchName(selectedCompany ? selectedCompany.branchName : '');
  }

  const branchNameChange = (selectedCode) => {
    setBranchName(selectedCode)
    setAccountDescription('');
    setBankAccountId('');
    const selectedCompany = branch.find(item => item.branchName === selectedCode);
    setBankBranch(selectedCompany ? selectedCompany.bankBranch : '');
  }
  const bankAccountIDChange = async (selectedCode) => {
    setBankAccountId(selectedCode)
    const selectedBankAccount = bankAccount.find(item => item.bankAccountId === selectedCode);
    setAccountDescription(selectedBankAccount ? selectedBankAccount.bankAccountDescription : '');
    handleExpandData(selectedCode);
    if (selectedBankAccount) {
      setCompanyCode(selectedBankAccount ? selectedBankAccount.company.companyCode : '');
      setCompanyName(selectedBankAccount ? selectedBankAccount.company.companyName : '');
      setBankBranch(selectedBankAccount ? selectedBankAccount.BankBranch.bankBranch : '');
      setBranchName(selectedBankAccount ? selectedBankAccount.BankBranch.branchName : '');
    }
  }
  const accountDescriptionChange = (selectedCode) => {
    setAccountDescription(selectedCode)
    const selectedBankAccount = bankAccount.find(item => item.bankAccountDescription === selectedCode);
    setBankAccountId(selectedBankAccount ? selectedBankAccount.bankAccountId : '');
    if (selectedBankAccount) {
      handleExpandData(selectedBankAccount ? selectedBankAccount.bankAccountId : '');
      setCompanyCode(selectedBankAccount ? selectedBankAccount.company.companyCode : '');
      setCompanyName(selectedBankAccount ? selectedBankAccount.company.companyName : '');
      setBankBranch(selectedBankAccount ? selectedBankAccount.BankBranch.bankBranch : '');
      setBranchName(selectedBankAccount ? selectedBankAccount.BankBranch.branchName : '');
    }

  }

  const [bankId, setBankId] = useState('');
  const [bankIDName, setBankIDName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [ibanNo, setIbanNo] = useState('');
  const [bankBic, setBankBic] = useState('');
  const [bankcountry, setBankCountry] = useState('');
  const [lgeCode, setLgeCode] = useState('');
  const [lgeName, setLgeName] = useState('');
  const [buGroup, setBuGroup] = useState('');
  const [buGroupName, setBuGroupName] = useState([]);

  const handleExpandData = async (bankAccountId) => {
    try {
      const response = await CashBalanceServices.getDataForExpand(bankAccountId);
      if (response.status === 200) {
        const data = response.data.data[0]; // Extract first item from array

        setBankId(data?.BankBranch?.BankId || '');
        setBankIDName(data?.BankBranch?.BankName || '');
        setAccountNo(data?.accountNo || '');
        setIbanNo(data?.iBanNo || '');
        setBankBic(data?.BankBranch?.identifier?.[0]?.bankBIC?.trim() || '');
        setBankCountry(data?.BankBranch?.addresses?.[0]?.countryCode || '');
        setLgeCode(data?.legalEntity?.LGE_Code || '');
        setLgeName(data?.legalEntity?.LGE_Description || '');
        setBuGroup(data?.buGroup || '');
        setBuGroupName(data?.buGroupName || '');
      }
    } catch (error) {
      console.log("Error fetching expanded data:", error);
    }
  };

  const validateRange = (from, to, key, isMonth = false) => {
    if (from && to && (isMonth ? to < from : new Date(to) < new Date(from))) {
      toast("error", `To ${isMonth ? "month" : "date"} cannot be earlier than From ${isMonth ? "month" : "date"}.`);
      setErrors(prev => ({ ...prev, [key]: "" }));

      const resetters = {
        transactionToMonth: () => setTransactionToMonth(""),
        importedToMonth: () => setTransactionFromMonth(""),
        transactionToDate: () => setTransactionToDate(""),
        importedToDate: () => setTransactionFromDate(""),
      };

      resetters[key]?.();
    }
  };

  const validateAndSet = (setter, value, isMonthSelected, resetMonthSetter) => {
    if (isMonthSelected) {
      resetMonthSetter();
      toast("info", "You can select either a month range or a date range, not both!");
    }
    setter(value);
  };

  const handleDateOrMonthChange = (setter, value, isMonth, resetSetter) => {
    validateAndSet(setter, value, isMonth, resetSetter);
  };

  const transactionFromDateChange = (e) =>
    handleDateOrMonthChange(setTransactionFromDate, e, transactionFromMonth || transactionToMonth, () => {
      setTransactionFromMonth("");
      setTransactionToMonth("");
    });

  const transactionToDateChange = (e) =>
    handleDateOrMonthChange(setTransactionToDate, e, transactionFromMonth || transactionToMonth, () => {
      setTransactionFromMonth("");
      setTransactionToMonth("");
    });

  const transactionFromMonthChange = (e) =>
    handleDateOrMonthChange(setTransactionFromMonth, e, transactionFromDate || transactionToDate, () => {
      setTransactionFromDate("");
      setTransactionToDate("");
    });

  const transactionToMonthChange = (e) =>
    handleDateOrMonthChange(setTransactionToMonth, e, transactionFromDate || transactionToDate, () => {
      setTransactionFromDate("");
      setTransactionToDate("");
    });

  //* BreadCrumb
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Cash Balances") {
      navigate("/2/master-data");
    } else if (crumb.title === "Cash in Bank") {
      navigate("/company-wise-add");
    } else if (crumb.title === "Account Portal") {
      //   navigate("/2/master-data");
    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };
  const handleReset = () => {
    setTransactionFromDate("");
    setTransactionToDate("");
    setTransactionFromMonth("");
    setTransactionToMonth("");
    setCompanyCode('');
    setSearchQuery('');
    setCompanyName('');
    setBankBranch('');
    setBranchName('');
    setAccountDescription('');
    setBankAccountId('')
  }

  const downLoadData = async () => {
    try {

      const requestData = {
        bankAccountId: bankAccountID,
        accountDescription: accountDescription,
        transactionFromMonth,
        transactionToMonth,
        transactionFromDate,
        transactionToDate,
      };
      const response = await CashBalanceServices.exportAccountPortal(requestData);
      if (response) {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"] || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "account_portal.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        toast("success", "Downloaded Successfully");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      toast("error", "Failed to download data");
    }
  }


  return (
    <div>
      <Header
        title={"Cash Balances > Cash in Bank > Account Portal"}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute={"/cash-balances"}
      />
      <div className="mr-4 ml-5">
        <div className="mx-4 my-2 border border-gray-200 px-4 py-2  rounded-lg">
          <div className="flex flex-row">
            <div>
              <div className="flex flex-col space-y-4 col-span-1 px-2 pt-2">
                <div className="grid grid-cols-2 gap-4">
                  <LabelWithButton
                    marginbottom="mb-2"
                    label="Company Code"
                    onClick={() => setIsCompanyCodeModelOpen(true)}
                    // className="w-[480px] text-xs"
                    buttonText={companyCode || "Select Company Code"}
                  />
                  <RadioPopUp
                    title="Company Code"
                    isOpen={isCompanyCodeModelOpen}
                    items={[...new Map(company.map(item => [item.companyCode, item])).values()]
                      .map(code => ({ id: code.companyCode, name: code.companyCode || '' }))
                      .filter(item => item.name.toLowerCase().includes(searchQuery?.toLowerCase() || ''))
                    }
                    onClose={() => {
                      setIsCompanyCodeModelOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsCompanyCodeModelOpen(false);
                      setSearchQuery('');
                    }}
                    selectedItems={companyCode}
                    searchPlaceholder="Search Company..."
                    searchQuery={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value)}
                    onItemToggle={(val) => companyCodeChange(val)}
                    onReset={() => setCompanyCode('')}
                  />

                  <LabelWithButton
                    marginbottom="mb-2"
                    label="Company Name"
                    onClick={() => setICompanyNameModelOpen(true)}
                    buttonText={companyName || 'Select Company Name'}
                  // className="w-[480px]"
                  />
                  <RadioPopUp
                    title="Company Name"
                    isOpen={isCompanyNameOpen}
                    items={[...new Map(company.map(item => [item.companyName, item])).values()]
                      .map(code => ({ id: code.companyName, name: code.companyName || '' }))
                      .filter(item => item.name.toLowerCase().includes(searchQuery?.toLowerCase() || ''))
                    }
                    onClose={() => {
                      setICompanyNameModelOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setICompanyNameModelOpen(false);
                    }}
                    selectedItems={companyName}
                    searchPlaceholder="Search Company..."
                    searchQuery={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value)}
                    onItemToggle={(val) => companyNameChange(val)}
                    onReset={() => setCompanyName('')}
                  />
                </div>
              </div>


              <div className="flex flex-col space-y-4 col-span-1 px-2 pt-2">
                <div className="grid grid-cols-2 gap-4">
                  <LabelWithButton
                    marginbottom="mb-2"
                    label="Bank Branch"
                    buttonText={bankBranch || "Select Bank Branch"}
                    onClick={() => setIsBankBranchModelOpen(true)}
                  // className="w-[480px]"
                  />
                  <RadioPopUp
                    title="Select Bank Branch"
                    isOpen={isBankBranchModelOpen}
                    items={[...new Map(branch.map(item => [item.bankBranch, item])).values()]
                      .map(code => ({ id: code.bankBranch, name: code.bankBranch || '' }))
                      .filter(item => item.name.toLowerCase().includes(searchQuery?.toLowerCase() || ''))
                    }
                    onClose={() => {
                      setIsBankBranchModelOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsBankBranchModelOpen(false);
                      setSearchQuery('');
                    }}
                    selectedItems={bankBranch}
                    searchPlaceholder="Search Bank Branch..."
                    searchQuery={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value)}
                    onItemToggle={(val) => bankBranchChange(val)}
                    onReset={() => setBankBranch('')}
                  />
                  <LabelWithButton
                    marginbottom="mb-2"
                    label="Branch Name"
                    buttonText={branchName || 'Select Branch Name'}
                    onClick={() => setIsBranchNameModelOpen(true)}
                  // className="w-[480px]"
                  />
                  <RadioPopUp
                    title="Bank Branch Name"
                    isOpen={isBranchNameModelOpen}
                    items={[...new Map(branch.map(item => [item.branchName, item])).values()]
                      .map(code => ({ id: code.branchName, name: code.branchName || '' }))
                      .filter(item => item.name.toLowerCase().includes(searchQuery?.toLowerCase() || ''))
                    }
                    onClose={() => {
                      setIsBranchNameModelOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsBranchNameModelOpen(false);
                      setSearchQuery('');
                    }}
                    selectedItems={branchName}
                    searchPlaceholder="Search Bank BranchName..."
                    searchQuery={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value)}
                    onItemToggle={(val) => branchNameChange(val)}
                    onReset={() => setBranchName('')}
                  />



                </div>


              </div>
              <div className="flex flex-col space-y-4 col-span-1 px-2 pt-2">
                <div className="grid grid-cols-2 gap-4">
                  <LabelWithButton
                    marginbottom="mb-2"
                    label="Bank Account ID"
                    buttonText={bankAccountID || "Select Bank Account ID"}
                    onClick={() => setIsBankAccountIdModelOpen(true)}
                  // className="w-[480px]"
                  />
                  <RadioPopUp
                    title="Bank Account Id"
                    isOpen={isBankAccountIdModelOpen}
                    items={[...new Map(bankAccount.map(item => [item.bankAccountId, item])).values()]
                      .map(code => ({ id: code.bankAccountId, name: code.bankAccountId || '' }))
                      .filter(item => item.name.toLowerCase().includes(searchQuery?.toLowerCase() || ''))
                    }
                    onClose={() => {
                      setIsBankAccountIdModelOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsBankAccountIdModelOpen(false);
                    }}
                    selectedItems={bankAccountID}
                    searchPlaceholder="Search Bank Account..."
                    searchQuery={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value)}
                    onItemToggle={(val) => bankAccountIDChange(val)}
                    onReset={() => setBankAccountId('')}
                  />

                  <LabelWithButton
                    marginbottom="mb-2"
                    label="Account ID Description"
                    onClick={() => setIsAccoutDescriptionModelOpen(true)}
                    buttonText={accountDescription || 'Select Account ID Description'}
                  // className="w-[480px] text-xs"
                  />
                  <RadioPopUp
                    title="Account Id Description"
                    isOpen={isAccountDescriptionModelOpen}
                    items={[...new Map(bankAccount.map(item => [item.bankAccountDescription, item])).values()]
                      .map(code => ({ id: code.bankAccountDescription, name: code.bankAccountDescription || '' }))
                      .filter(item => item.name.toLowerCase().includes(searchQuery?.toLowerCase() || ''))
                    }
                    onClose={() => {
                      setIsAccoutDescriptionModelOpen(false);
                      setSearchQuery('');
                    }}
                    onSave={() => {
                      setIsAccoutDescriptionModelOpen(false);
                      setSearchQuery('');
                    }}
                    selectedItems={accountDescription}
                    searchPlaceholder="Search Description..."
                    searchQuery={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value)}
                    onItemToggle={(val) => accountDescriptionChange(val)}
                    onReset={() => setAccountDescription('')}
                  />
                </div>
              </div>

              <div className="flex flex-col space-y-4 col-span-1 px-2 pt-2">
                <div className="grid grid-cols-2 gap-4">
                  <label className="flex items-center">
                    <span className="w-48 text-xs font-small  text-left text-gray-500">
                      Transaction Date
                    </span>
                    <span className="xl:ml-4 lg:ml-2">:</span>
                    <input
                      type="date"
                      placeholder="From"
                      value={transactionFromDate}
                      onChange={(e) => {
                        transactionFromDateChange(e.target.value);
                        validateRange(e.target.value, transactionToDate, "transactionFromDate", false);
                      }}
                      className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"} p-1.5 rounded xl:w-[125px] lg:w-[100px] 2xl:w-40 h-8 focus:outline-none hover:border-blue-400 ml-4 text-gray-500 text-xs`}
                    />
                    <input
                      type="date"
                      placeholder="To"
                      value={transactionToDate}
                      onChange={(e) => {
                        transactionToDateChange(e.target.value);
                        validateRange(transactionFromDate, e.target.value, "transactionToDate", false);
                      }}
                      className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"} p-1.5 rounded xl:w-[125px] lg:w-[100px] 2xl:w-40 h-8 focus:outline-none hover:border-blue-400 ml-2 text-gray-500 text-xs`}
                    />
                  </label>
                  <label className="flex items-center">
                    <span className="w-48 text-xs font-small text-left text-gray-500">
                      Month/Year
                    </span>
                    <span className="xl:ml-4 lg:ml-2">:</span>
                    <input
                      value={transactionFromMonth}
                      type="month"
                      onChange={(e) => {
                        transactionFromMonthChange(e.target.value);
                        validateRange(e.target.value, transactionToMonth, "transactionFromMonth", true);
                      }}
                      className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"} p-1.5 rounded xl:w-[125px] lg:w-[100px] 2xl:w-40 h-8 focus:outline-none hover:border-blue-400 ml-4 text-gray-500 text-xs`}
                      required
                    />
                    <input
                      type="month"
                      value={transactionToMonth}
                      onChange={(e) => {
                        transactionToMonthChange(e.target.value);
                        validateRange(transactionFromMonth, e.target.value, "transactionToMonth", true);
                      }}
                      className={`border ${errors.ntMainHead ? "border-red-500" : "border-gray-300"} p-1.5 rounded xl:w-[125px] lg:w-[100px] 2xl:w-40 h-8 focus:outline-none hover:border-blue-400 ml-2 text-gray-500 text-xs`}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>
            <div>

              <div className="flex flex-row space-x-2 mt-2">
                {/* Reset Button */}
                <div
                  onClick={handleReset}
                  className="flex items-center justify-center border border-gray-300 text-customBlue text-xs rounded w-6 h-6"
                  title="Reset"
                >
                  <RxReset />
                </div>

                {/* Show Details Button */}
                <button
                  title="Show Details"
                  onClick={toggleDetails}
                  className="flex items-center justify-center border border-gray-300 text-customBlue text-xs rounded w-6 h-6"
                >
                  {isDetailsOpen ? <FaArrowUp /> : <FaArrowDown />}
                </button>

                {/* Show List Button */}

                <HoverButton title="Show" onClick={() => getDataFoTable(currentPage)}
                  className="flex items-center justify-center border border-customBlue text-customBlue text-xs rounded  "
                />
              </div>
            </div>
          </div>

          {isDetailsOpen && (
            <div className="flex mt-8 pl-2">
              <div>
                <LabelComponent label="Bank ID" value={bankId || "N/A"} />
                <LabelComponent label="Account Number" value={accountNo || "N/A"} />
                <LabelComponent label="Bank BIC" value={bankBic || "N/A"} />
                <LabelComponent label="Bank Country Code" value={bankcountry || "N/A"} />
                <LabelComponent label="LGE Code" value={lgeCode || "N/A"} />
                <LabelComponent label="BU Group" value={buGroup || "N/A"} />
              </div>
              <div className="flex justify-between flex-col ml-24">
                <div>
                  <LabelComponent label="Bank ID Name" value={bankIDName || "N/A"} />
                  <LabelComponent label="IBAN No" value={ibanNo || "N/A"} />
                </div>
                <div>
                  <LabelComponent label="Legal Entity Name" value={lgeName || "N/A"} />
                  <LabelComponent label="BU Group Name" value={buGroupName || "N/A"} />
                </div>
              </div>
              <div className="flex flex-col justify-between p-4">
                <div></div>
                <div className="ml-4 rounded-xl h-40 w-62 border border-gray-300 focus:outline-none">
                  <img
                    src={BankTemporaryImage}
                    className="rounded-xl w-full h-full"
                    alt="imageinfo"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {loading === true ? <CustomeLoading /> : null}

      <div className="flex justify-between items-center -ml-1">
        <div className="flex items-center">
          <div className="mt-2">
            <PrimaryActions
              buttons={existingButtons}
            />
          </div>
        </div>
        <div className="flex items-center mr-4">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            totalCount={totalCount}
            onShowAll={handleShowAll}
          />
        </div>
      </div>
      <div className="mt-2 h-[100%] overflow-y-visible mr-3 ml-3">
        <ReTable
          data={data}
          columns={columns}
          selectedRows={selectedRows}
          onSelectRow={onSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      {/* <div>
        <Footer >{
          <HoverButton title="show" onClick={() => { getDataFoTable(currentPage) }} />
        }</Footer>
      </div> */}
    </div>
  );
}



// {isFilterPopupOpen && (
//   <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
//     <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 w-10/12 max-w-3xl z-50">
//       <div className="flex justify-between items-center mt-2">
//         <h2 className="text-customGray font-semibold mb-5">
//           Apply Filters
//         </h2>
//         <HoverButton
//           onClick={handleResetFilters}
//           title={"Reset Filter"}
//         />
//       </div>

//       {/* Grid Layout */}
//       <div className="grid grid-cols-2 gap-4">
//         {/* Left-Side Single Column Fields */}
//         <div className="col-span-2 sm:col-span-1">
//           <div className="flex items-center mb-2">
//             <label className="text-customGray mr-2 w-[140px]">
//               Status:
//             </label>
//             <select
//               name="status"
//               value={filters.status}
//               onChange={handleFilterChange}
//               className="text-customGray border border-gray-300 pl-1 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400 "
//             >
//               <option value="" className=" text-blue-500">
//                 Select Status
//               </option>
//               <option value="0" className=" text-gray-500">
//                 Created
//               </option>
//               <option value="1" className=" text-[#AF52DE]">
//                 Changed
//               </option>

//               <option value="2 " className=" text-yellow-500">
//                 Waiting for Approval
//               </option>
//               <option value="3" className=" text-orange-400">
//                 Modified
//               </option>
//               <option value="4" className=" text-[#29CB00]">
//                 Approved
//               </option>
//               <option value="5" className=" text-red-500">
//                 Rejected
//               </option>
//               <option value="6" className=" text-black">
//                 Inactive
//               </option>
//             </select>
//           </div>
//           <div className="flex items-center mb-2">
//             <label className="text-customGray mr-2 w-[140px]">
//               Bu Group:
//             </label>
//             <button
//               className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer"
//               onClick={() => {
//                 setTempSelectedbugroupall(selectedbugroup);
//                 setIsbugroupPopupOpen(true);
//                 // Reset filteredBuGroup to show all groups when the popup is opened
//               }}
//             >
//               <span className="truncate">
//                 {selectedbugroup.length > 0
//                   ? selectedbugroup.join(", ")
//                   : "Bu group"}
//               </span>
//               <RxDropdownMenu className="ml-2 text-lg" />
//             </button>
//           </div>
//           <CheckboxPopup
//             title="Select BuGroup "
//             isOpen={isbugroupPopupOpen}
//             onClose={() => setIsbugroupPopupOpen(false)}
//             onSave={handleApplybugroup}
//             onReset={handlebugroupReset}
//             searchPlaceholder="Search BuGroup "
//             searchQuery={bugroupallearchQuery}
//             onSearchChange={(e) => setbugroupallearchQuery(e.target.value)}
//             items={filteredbugroupall
//               .sort((a, b) => a.BU_Group.localeCompare(b.BU_Group))

//               .map((code) => ({
//                 id: code.BU_Group,
//                 name: code.BU_Group,
//               }))}
//             selectedItems={tempSelectedbugroupall}
//             onItemToggle={handlebugroupSelection}
//           />
//           <div className="flex items-center">
//             {/* Dropdown for selecting 'Created By' or 'Updated By' */}
//             <select
//               name="filterType"
//               value={filterType} // State to track the selected filter option
//               onChange={(e) => {
//                 setFilterType(e.target.value); // Update the selected filter
//               }}
//               className="text-customGray border border-gray-300 p-2 rounded-md w-[132px] h-[40px] focus:outline-none hover:border-blue-400"
//             >
//               <option value="1">Created By</option>
//               <option value="2">Updated By</option>
//             </select>

//             {/* Button to open the popup */}
//             <button
//               className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] flex justify-between items-center focus:outline-none hover:border-blue-400 cursor-pointer ml-4"
//               onClick={() => {
//                 if (filterType === "1") {
//                   setIsCreatedByPopupOpen(true);
//                   setTempSelectedcreatedby(selectedCreatedBy);// Open the Created By popup
//                 } else if (filterType === "2") {
//                   setIsUpdatedByPopupOpen(true); // Open the Updated By popup
//                   setTempSelectedupdatedby(selectedUpdatedBy); // Set the selected data correctly before opening the popup
//                 }

//               }}
//               disabled={!filterType} // Disable button if no filter is selected
//             >
//               <span className="truncate">
//                 {filterType === "1"
//                   ? selectedCreatedBy.length > 0
//                     ? selectedCreatedBy.join(", ")
//                     : "Created By"
//                   : filterType === "2"
//                     ? selectedUpdatedBy.length > 0
//                       ? selectedUpdatedBy.join(", ")
//                       : "Updated By"
//                     : "Select Filter"}
//               </span>
//               <RxDropdownMenu className="ml-2 text-lg" />
//             </button>
//           </div>
//           <CheckboxPopup
//             title="Select Created By "
//             isOpen={isCreatedByPopupOpen}
//             onClose={() => setIsCreatedByPopupOpen(false)}
//             onSave={handleApplyCreatedby}
//             onReset={handleCreatedbyReset}
//             searchPlaceholder="Search Created By "
//             searchQuery={createdbydatasearchQuery}
//             onSearchChange={(e) => setcreatedbydatasearchQuery(e.target.value)}
//             items={filteredcreatedbydatas
//               .sort((a, b) => a.created_by.localeCompare(b.created_by))

//               .map((code) => ({
//                 id: code.created_by,
//                 name: code.created_by,
//               }))}
//             selectedItems={tempSelectedcreatedby}
//             onItemToggle={handleCreatedBySelection}
//           />
//           <CheckboxPopup
//             title="Select Updated By "
//             isOpen={isUpdatedByPopupOpen}
//             onClose={() => setIsUpdatedByPopupOpen(false)}
//             onSave={handleApplyUpdatedby}
//             onReset={handleUpdatedbyReset}
//             searchPlaceholder="Search Updated By "
//             searchQuery={updatedbysearchquery}
//             onSearchChange={(e) => setupdatedbysearchquery(e.target.value)}
//             items={filteredupdatedbydata
//               .sort((a, b) => a.changed_by.localeCompare(b.changed_by))
//               .map((code) => ({
//                 id: code.changed_by,
//                 name: code.changed_by,
//               }))}
//             selectedItems={tempSelectedupdatedby}
//             onItemToggle={handleUpdateBySelection}
//           />

//           {/* Updated By Popup */}

//         </div>
//         {filterType && (
//           <div className="col-span-2 flex justify-between items-center">
//             <div className="flex items-center">
//               <label className="text-customGray mr-2 w-[140px]">
//                 {filterType === "createdBy"
//                   ? " Date From:"
//                   : " Date From:"}
//               </label>
//               <input
//                 type="date"
//                 name="dateFrom"
//                 value={
//                   filterType === "1"
//                     ? filters.created_date_from
//                     : filters.updated_date_from
//                 }
//                 onChange={(e) => handleFilterDateFrom(e)}
//                 className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
//               />
//             </div>
//             <div className="flex items-center">
//               <label className="text-customGray mr-2 w-[140px]">
//                 {filterType === "createdBy" ? " Date To:" : " Date To:"}
//               </label>
//               <input
//                 type="date"
//                 name="dateTo"
//                 value={
//                   filterType === "1"
//                     ? filters.created_date_to
//                     : filters.updated_date_to
//                 }
//                 onChange={(e) => handleFilterDateto(e)}
//                 className="text-customGray border border-gray-300 p-2 rounded-md w-[165px] h-[40px] focus:outline-none hover:border-blue-400"
//               />
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Buttons */}
//       <div className="mt-6 flex justify-end space-x-3">
//         <HoverButton
//           onClick={toggleFilterPopup}
//           title={"Cancel"}
//         />
//         <CustomButton
//           title={"Apply"}
//           onClick={() => {
//             handleApplyFilter();
//             toggleFilterPopup();
//           }}

//         />
//       </div>
//     </div>
//   </div>
// )}
// const handleResetFilters = () => {
//   setFilters({
//     status: "",
//     companyCode: [],
//     countryCode: [],
//     updated_by: "",
//     updated_date_from: "",
//     updated_date_to: "",
//     created_by: "",
//     created_date_from: "",
//     created_date_to: "",
//   });
//   setHasFiltersApplied(false)

//   setSelectedbugroup([]);
//   setSelectedCreatedBy([]);
//   setSelectedUpdatedBy([]);
//   // ithu apply click panni than rest pannanum na intha function call theva illa
//   fetchBuGroups();
// };

// const handlebugroupSelection = (bugroup) => {
//   setTempSelectedbugroupall((prev) => {
//     // Add or remove bugroup from temporary selection list
//     return prev.includes(bugroup)
//       ? prev.filter((item) => item !== bugroup) // Remove if already selected
//       : [...prev, bugroup]; // Add if not selected
//   });
// };

// // Reset temporary selection when resetting popup
// const handlebugroupReset = () => {
//   setTempSelectedbugroupall([]); // Reset selection
// };

// // Apply filter and close the popup
// const handleApplybugroup = () => {
//   setSelectedbugroup(tempSelectedbugroupall); // Apply temporary selection to actual selection state
//   setFilters((prevFilters) => ({
//     ...prevFilters,
//     BU_Group: tempSelectedbugroupall, // Set the filter
//   }));
//   setIsbugroupPopupOpen(false); // Close the popup
// };





// const handleCreatedBySelection = (createdBy) => {
//   setTempSelectedcreatedby((prev) => {
//     // Add or remove company from temporary selection list
//     return prev.includes(createdBy)
//       ? prev.filter((item) => item !== createdBy) // Remove if already selected
//       : [...prev, createdBy]; // Add if not selected
//   });
// };

// // Reset temporary selection when resetting popup
// const handleCreatedbyReset = () => {
//   setTempSelectedcreatedby([]); // Reset selection
// };

// // Apply filter and close the popup
// const handleApplyCreatedby = () => {
//   setSelectedCreatedBy(tempSelectedcreatedby); // Apply temporary selection to actual selection state
//   setFilters((prevFilters) => ({
//     ...prevFilters,
//     created_by: tempSelectedcreatedby, // Set the filter
//   }));
//   setIsCreatedByPopupOpen(false); // Close the popup
// };




// const handleUpdateBySelection = (changedby) => {
//   setTempSelectedupdatedby((prev) => {
//     // Add or remove company from temporary selection list
//     return prev.includes(changedby)
//       ? prev.filter((item) => item !== changedby) // Remove if already selected
//       : [...prev, changedby]; // Add if not selected
//   });
// };

// // Reset temporary selection when resetting popup
// const handleUpdatedbyReset = () => {
//   setTempSelectedupdatedby([]); // Reset selection
// };

// // Apply filter and close the popup
// const handleApplyUpdatedby = () => {
//   setSelectedUpdatedBy(tempSelectedupdatedby); // Apply temporary selection to actual selection state
//   setFilters((prevFilters) => ({
//     ...prevFilters,
//     updated_by: tempSelectedupdatedby, // Set the filter
//   }));
//   setIsUpdatedByPopupOpen(false); // Close the popup
// };

// const handleFilterChange = (e) => {
//   setFilters((prevFilters) => ({
//     ...prevFilters,
//     status: e.target.value,
//   }));
// };

// const handleFilterDateFrom = (e) => {
//   console.log(filterType);
//   if (filterType === "1") {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       created_date_from: e.target.value,
//     }));
//   } else {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       updated_date_from: e.target.value,
//     }));
//   }
// };
// const handleFilterDateto = (e) => {
//   if (filterType === "1") {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       created_date_to: e.target.value,
//     }));
//   } else {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       updated_date_to: e.target.value,
//     }));
//   }
// };