import React, { useState, useEffect } from "react";
import NoActionLayout from "../../../../../components/NoActionLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import CustomButton from "../../../../../components/CustomButton";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";

export default function BuGroupAdd() {
  const { buGroupId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view';
  const [buGroup, setBuGroup] = useState("");
  const [bugroupDescription, setBugroupDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const{toast}=useToast();
  const navigate = useNavigate();
  useEffect(() => {
    if (buGroupId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [buGroupId]);

  const getDataForEdit = async () => {
    try {
      const response = await BuGroupServices.getBuGroupById(buGroupId);
      setBuGroup(response.data.BU_Group);
      setBugroupDescription(response.data.BU_Group_Description);
    } catch {}
  };

  const validateForm = () => {
    const newErrors = {};
    if (!buGroup) newErrors.buGroup = "BuGroup  is required.";
    if (!bugroupDescription) newErrors.bugroupDescription = "BuGroup Description is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("warning", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = {
      id: buGroupId || null,
      BU_Group:buGroup,
      BU_Group_Description: bugroupDescription,
      created_by: assignee.assigner,
      changed_by: assignee.assigner,
    };
console.log('BuGroup data',data);

try {
  let response;
  if (buGroupId) {
    // Updating the existing currency
    response = await BuGroupServices.addBuGroup(
      `${process.env.REACT_APP_BASE_URL}/update-bugroup`, // Ensure this endpoint is for updates
      data
    );
  } else {
    // Creating a new currency
    response = await BuGroupServices.addBuGroup(
      `${process.env.REACT_APP_BASE_URL}/create-bugroup`,
      data
    );
  }

  if (response && !response.error) {
    toast("success", buGroupId ? "Currency Updated successfully" : "Currency Added successfully");
    // Clear the form and navigate
    setBuGroup("");
    setBugroupDescription("");
    navigate("/bugroup"); // Redirect after saving
  } else {
    toast("error", "Error adding/updating Currency");
  }
} catch (error) {
  console.error("Request error:", error);
  setAlert({
    severity: "error",
    message: `Error adding/updating Currency: ${error.message}`,
  });
}
};
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Bank&Company masterData') {
      navigate('/master-data');
    } else if (crumb.title === 'Bu Group') {
      navigate('/bugroup');
    } 
    else if (crumb.title === 'Primary Data') {
      navigate('/master-data');
    
  }else if (crumb.path) {
      navigate(crumb.path); // Navigate to the path specified in the crumb
    }
  };
  return (
    <div>
   <Header
        title={`Primary Data > Bank&Company masterData > Bu Group > ${buGroupId ?  (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick} // Pass the click handler to Header
      />
     

      <div className="flex flex-col space-y-12 col-span-1 p-10">
        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            Bu Group<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={buGroup}
            onChange={(e) => {setBuGroup(e.target.value);
            if(errors.buGroup){
              setErrors((prevErrors)=>({
                ...prevErrors,
                buGroup:"",
              }))
            }}}
            className={`border text-customGray ${
              errors.buGroup ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>
       

        <label className="flex items-center">
          <span className="w-48 font-small text-gray-500">
            BuGroup Description<span className="text-red-500 ml-1">*</span>
          </span>
          <span className="ml-4">:</span>
          <input
            type="text"
            value={bugroupDescription}
            onChange={(e) => {setBugroupDescription(e.target.value);
            if(errors.bugroupDescription){
              setErrors((prevErrors)=>({
                ...prevErrors,
                bugroupDescription:"",
              }))
            }}}
            className={`border text-customGray ${
              errors.bugroupDescription ? "border-red-500" : "border-gray-300"
            } p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8`}
            required
            disabled={isViewMode}
          />
        </label>
        

      {(!isViewMode && (  <CustomButton onClick={handleSubmit} title={"Save"}/>))}
      </div>
    </div>
  );
}
