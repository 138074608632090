import axios from "axios";
import RestService from "./RestServices";
const basePath = "/user";
const UserService = {
  login: (userid, password, customercode) => {
    const credentials = { userid, password, customercode };
    return axios.post(
      process.env.REACT_APP_BASE_URL + "/user/auth/signin",
      credentials
    );
  },
  loginwithOtp: (userid, otp) => {
    const credentials = { userid, otp };
    return axios.post(
      process.env.REACT_APP_BASE_URL + "/user/otp/signin",
      credentials
    );
  },

  blockUser: (userid) => {
    return RestService.CreateData(`${basePath}/block-user?userid=${userid}`);
  },

  forgotPassword: (currentPass, newPass, userid) => {
    const data = { currentPass, newPass, userid };
    return RestService.CreateData(`${basePath}/forgot-password`, data);
  },

  generatOtp: (userid) => {
    return RestService.GetAllData(
      `${basePath}/generate-reset-otp?userid=${userid}`
    );
  },

  checkLogin: (userid) => {
    return RestService.GetAllData(
      `${basePath}/is-first-login?userid=${userid}`
    );
  },

  resetPasswordWithOtp: (data) => {
    return RestService.CreateData(`${basePath}/validate-reset-otp`, data);
  },

  updatePassword: (confirmPass, newPass, userid) => {
    const data = { confirmPass, newPass, userid };
    return RestService.CreateData(`${basePath}/reset-password`, data);
  },

  getCountries: (path) => {
    return RestService.GetAllData(path);
  },

  getUsers: (path) => {
    return RestService.GetAllData(path);
  },

  getPurchaseUsertypes: () => {
    return RestService.GetAllData(`/get-purchase-usertype`);
  },

  batchUpdateUserTypes: (data) => {
    return RestService.CreateData(`/upsert-user-type`, data);
  },

  saveContactDetails: (path, data) => {
    try {
      const response = RestService.CreateData(path, data);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },

  saveapproved: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error updating users:", error);
      return { error: true, message: "Error updating users" };
    }
  },

  saveBasicDetails: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error creating users:", error);
      return { error: true, message: "Error creating users" };
    }
  },

  saveAllUserDetails: (path, data) => {
    try {
      console.log("Sending data:", data);
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error creating users:", error);
      return { error: true, message: "Error creating users" };
    }
  },

  checkDuplicateRoleName: async (path, roleData) => {
    try {
      const response = await RestService.CreateData(path, roleData);
      console.log("Response from server:", response);

      return response;
    } catch (error) {
      console.error("Error fetching roles:", error);
      return { error: true, message: "Error fetching roles" };
    }
  },

  saveAllRolesDetails: async (path, data) => {
    // Add 'async' here
    try {
      console.log("Sending data:", data);
      const response = await RestService.CreateData(path, data); // Use 'await' here
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error creating users:", error);
      return { error: true, message: "Error creating users" };
    }
  },

  getCountries: (id) => {
    return RestService.GetByIdData(`${basePath}/get-country`, id);
  },

  // saveCountry: (data) => {
  //   return axios.post(process.env.REACT_APP_BASE_URL + "/countries", data);
  // },
  saveCountry: (path, data) => {
    try {
      const response = RestService.CreateData(path, data);
      console.log("Response from server:", response);
      return response;
    } catch (error) {
      console.error("Error creating report:", error);
      return { error: true, message: "Error creating report" };
    }
  },

  saveresetPassword: (data) => {
    return RestService.CreateData(`/update-security-quetstions`, data);
  },

  getSecurityQuestions: (userid) => {
    return RestService.GetAllData(`/get-user-fav-question?userid=${userid}`);
  },
};

export default UserService;
