import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LanguageServices from "../../../../../rest-services/LanguagesServices";
import { useToast } from "../../../../../components/toast/toast";
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/footer";
import ButtonGroup from "../../../../../components/FooterButton";

export default function LanguageAdd() {
  const { languageId } = useParams();
  const location = useLocation();
  const { mode } = location.state || {}; // Get the mode from the state
  const isViewMode = mode === 'view'; // Check if it's view mode
  const isEditMode = !!languageId;
  const [languageCode, setLanguageCode] = useState("");
  const [languageName, setLanguageName] = useState("");
  const [errors, setErrors] = useState({});
  const [assignee, setAssignee] = useState({ id: "", assigner: "" });
  const [alert, setAlert] = useState(null);
  const { toast } = useToast();
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (languageId) {
      getDataForEdit();
    }
    fetchAssignee();
  }, [languageId]);

  const validateForm = () => {
    const newErrors = {};
    if (!languageCode) newErrors.languageCode = "Language Code is required.";
    if (!languageName) newErrors.languageName = "Language Name is required.";

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast("error", "Missing Required Fields");
    }
    return Object.keys(newErrors).length === 0;
  };

  const getDataForEdit = async () => {
    try {
      const response = await LanguageServices.getLanguageById(languageId);
      setLanguageCode(response.data.LE); // Adjust according to API response structure
      setLanguageName(response.data.Description); // Adjust according to API response structure
    } catch {
      setAlert({
        severity: "error",
        message: "Error fetching language data.",
      });
    }
  };

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        setAssignee({
          id: userDetail.id || "",
          assigner: userDetail.userId || "",
        });
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!languageId) {
        const duplicateCheckResponse = await LanguageServices.checkDuplicateLanguagecode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatelanguage`,
          { LE: languageCode }
        );

        if (duplicateCheckResponse.message === "language code already exists") {
          toast("error", "Language already exists.");
          setErrors((prev) => ({
            ...prev,
            languageCode: "language already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: languageId || null,
        LE: languageCode,
        Description: languageName,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };

      let response;
      if (!unsavedChanges) {
        toast(
          "info",
          "No language changes detected. Nothing to save."
        );
        navigate("/2/master-data/6/language"); // Redirect after saving
        return false;
      }      if (languageId) {
        // Updating the existing country
        response = await LanguageServices.addLanguage(
          `/create-language`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new country
        response = await LanguageServices.addLanguage(
          `/create-language`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          languageId
            ? "language Updated successfully"
            : "language Added successfully"
        );
        // Clear the form and navigate
        setLanguageCode("");
        setLanguageName("");
        navigate("/2/master-data/6/language"); // Redirect after saving
      } else {
        toast("error", "Error adding/updating Language");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Language: ${error.message}`,
      });
    }
  };
  const handleSaveAndContinue = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      // Skip duplication check for edit mode
      if (!languageId) {
        const duplicateCheckResponse = await LanguageServices.checkDuplicateLanguagecode(
          `${process.env.REACT_APP_BASE_URL}/getduplicatelanguage`,
          { LE: languageCode }
        );

        if (duplicateCheckResponse.message === "language code already exists") {
          toast("error", "Language already exists.");
          setErrors((prev) => ({
            ...prev,
            languageCode: "language already exists.",
          }));
          return; // Stop further execution if duplicate exists
        }
      }
      const data = {
        id: languageId || null,
        LE: languageCode,
        Description: languageName,
        created_by: assignee.assigner,
        changed_by: assignee.assigner,
      };

      let response;
      if (languageId) {
        // Updating the existing country
        response = await LanguageServices.addLanguage(
          `/create-language`, // Ensure this endpoint is for updates
          data
        );
      } else {
        // Creating a new country
        response = await LanguageServices.addLanguage(
          `/create-language`,
          data
        );
      }

      if (response && !response.error) {
        toast(
          "success",
          languageId
            ? "language Updated successfully"
            : "language Added successfully"
        );
        // Clear the form and navigate
        setLanguageCode("");
        setLanguageName("");
        // Redirect after saving
      } else {
        toast("error", "Error adding/updating Language");
      }
    } catch (error) {
      console.error("Request error:", error);
      setAlert({
        severity: "error",
        message: `Error adding/updating Language: ${error.message}`,
      });
    }
  };
  const checkDuplicateLanguagecode = async (code) => {
    try {
      const response = await LanguageServices.checkDuplicateLanguagecode(
        `/getduplicatelanguage`,
        { LE: code }  // Sending roleName to backend
      );

      console.log("Response from server:", response);

      if (response.message === "language code already exists") {
        // Check if it's a duplicate
        console.log("Response from internal server:", response);
        setErrors({ languageCode: " " });

        toast("error", "Language already exists.");
      }
      console.log("Response from external server:", response);
    } catch (error) {
      console.error("Error checking duplicate languageCode:", error);
      // Handle potential errors when making the API call
      setErrors((prev) => ({ ...prev, languageCode: "Error checking role name" }));
    }
  };

  const handleLanguageChange = (e) => {
    const code = e.target.value.toUpperCase(); // Ensure input is uppercase
    setUnsavedChanges(true);

    // Limit input to 3 letters
    if (errors.languageCode) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        languageCode: "",
      }));
      return; // Stop further processing
    }

    // Clear errors if any
    setErrors((prevErrors) => ({
      ...prevErrors,
      languageCode: "",
    }));

    // Update the calendar code
    setLanguageCode(code);

    // Check for duplicate Calendar Code locally
    if (code.trim() !== "") {
      checkDuplicateLanguagecode(code);
    }
  };

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === 'Global Data') {
      console.log("Navigating to /2/master-data");
      navigate('/2/master-data');
    } else if (crumb.title === 'Language') {
      console.log("Navigating to /country");
      navigate('/2/master-data/6/language');
    }
    else if (crumb.title === 'Primary Data') {
      console.log("Navigating to /country");
      navigate('/2/master-data');

    } else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  return (
    <div>
      <Header
        title={`Primary Data > Global Data > Language > ${languageId ? (isViewMode ? 'View' : 'Edit') : 'Add'}`}
        onBreadcrumbClick={handleBreadcrumbClick}
        hasUnsavedChanges={unsavedChanges}
        backRoute={"/2/master-data/6/language"}
      />
      <div className="m-6 border border-gray-200 p-2  rounded-lg">

        <div className="flex flex-col space-y-4 col-span-1 p-2">
          <label className="flex items-center">
            <span className="w-48 font-small text-xs text-customGray">
              LE <span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={languageCode}
              maxLength={2}
              onChange={handleLanguageChange}
              className={`border ${errors.languageCode ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 font-small text-xs text-customGray`}
              required
              disabled={isViewMode || isEditMode} // Disable input in view mode
            />
          </label>
          {!isViewMode && languageCode.length === 2 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}

          <label className="flex items-center">
            <span className="w-48 font-small text-xs text-customGray">
              Description<span className="text-red-500 ml-1">*</span>
            </span>
            <span className="ml-4">:</span>
            <input
              type="text"
              value={languageName}
              maxLength={20}

              onChange={(e) => {
                setLanguageName(e.target.value);
                setUnsavedChanges(true);
                if (errors.languageName) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    languageName: "",
                  }));
                }
              }}
              className={`border ${errors.languageName ? "border-red-500" : "border-gray-300"} p-1.5 rounded w-80 h-8 focus:outline-none hover:border-blue-400 ml-8 font-small text-xs text-customGray`}
              required
              disabled={isViewMode} // Disable input in view mode
            />
          </label>
          {!isViewMode && languageName.length === 20 &&  (
                      <p className="text-red-500 text-xs -pt-12">You have used the maximum character limit.</p>
                    )}

          {/* Hide the Save button if in view mode */}

        </div>

      </div>
      <Footer>
        {/* Footer */}
        {!isViewMode && (
          <ButtonGroup
            onSave={handleSubmit}
            onSaveContinue={handleSaveAndContinue}
            hasId={!!languageId}
          />
        )}
      </Footer>
    </div>
  );
}
