// import React, { useEffect, useState } from 'react';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import Header from '../../../../../../components/Header';
// import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
// import { MdOutlineFileDownload } from 'react-icons/md';
// import AdminActions from '../../../../Admin/Components/AdminActions';
// import ReTable from '../../../../../../components/Table';
// import UserService from '../../../../../../rest-services/UserServices';
// import XLSX from 'xlsx-js-style';
// import CustomeLoading from '../../../../../../components/CustomeLoading';
// import PrimaryActions from '../../../Components/ActionButton';
// import SourceServices from '../../../../../../rest-services/SourceServices';

// export default function PortalDisplayScreen() {
//   const location = useLocation();
//   const { iconId } = useParams();
//   const navigate = useNavigate();
//   const {  displayOption } = location.state || {}; // Retrieve tableData from state
//   const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
//   const [processedData, setProcessedData] = useState([]);
//   const [assignee, setAssignee] = useState({ id: '', username: '' });
//   const [alert, setAlert] = useState(null);
// const [loading, setLoading] = useState(false);
//   const[statementTypes,setStatementTypes]= useState([]);

//   console.log('table data received from prop in currency rate is', tableData);
//   console.log('displayOption data received from prop in currency rate is', displayOption);
//   console.log('uploadOption data received from prop in currency rate is', uploadOption);

//   useEffect(() => {
//     fetchAssignee();
//   }, []);

//   const handleBreadcrumbClick = (crumb) => {
//     console.log('Breadcrumb clicked:', crumb.title);
//     if (crumb.title === 'Bank Statements') {
//       navigate('/bank-statements');
//     } else if (crumb.title === 'Portal') {
//       navigate('/bank-statements');
//     } else if (crumb.title === 'BTC') {
//       navigate('/btc');
//     } else if (crumb.path) {
//       navigate(crumb.path);
//     } else if (crumb.title === 'Portal Upload') {
//       navigate('/5/bank-statement/53/Portal-upload');
//     }
//   };

//   const columns = [

//         { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -2)*10, alignment: 'left' },
//     { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -2)*10, alignment: 'left' },
//     {
//       Header: 'Update',
//       accessor: (row) => {
//         if (row.successfulrecords && uploadOption === '3') {
//           return 'Yes';
//         } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
//           return 'No';
//         } else {
//           return <span style={{ color: 'red' }}>No</span>;
//         }
//       },      visible: true,
//       width: ("Update".length -2)*10,
//       alignment: "left"
//     },
//     { Header: 'BTCCode', accessor: 'BTCCode', visible: true, width: ("BTCCode".length -2)*10, alignment: 'left' },
//     { Header: 'BTCDescription', accessor: 'BTCDescription', visible: true, width: ("BTCDescription".length -2)*10, alignment: 'left' },
//     { Header: 'StatementType', accessor: 'StatementType', visible: true, width: ("StatementType".length -2)*10, alignment: 'left' },
//     { Header: 'Inflow', accessor: 'Inflow', visible: true, width: ("Inflow".length -2)*10, alignment: 'left' },
//     { Header: 'Outflow', accessor: 'Outflow', visible: true, width: ("Outflow".length -2)*10, alignment: 'left' },
    
//   ];

//   const getExistingUserIds = async (tableData) => {
//     try {
//       // First fetch valid statement types ONCE
//       let validStatementTypes = [];
//       try {
//         const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
//         validStatementTypes = response.data.data || [];
//         console.log("Valid Statement Types:", validStatementTypes);
//       } catch (error) {
//         console.error("Error fetching statement types:", error);
//       }
  
//       // Async validation of all rows including dataset duplicates check
//       const validationResults = await Promise.all(
//         tableData.map(async (row, rowIndex) => {
//           // Check for duplicates within current dataset first
//           const isDuplicateInDataset = tableData.some(
//             (r, index) => index !== rowIndex && r.BTCCode === row.BTCCode
//           );
          
//           if (isDuplicateInDataset) {
//             console.log(`Duplicate BTCCode in dataset: ${row.BTCCode}`);
//             return { row, isValid: false, isDuplicate: true };
//           }
  
//           // Validate other fields if no dataset duplicate
//           const isValid = await isRowValid(row, rowIndex, validStatementTypes);
//           return { row, isValid, isDuplicate: false };
//         })
//       );
  
//       // Filter valid rows (not duplicates and passed validation)
//       let validRows = validationResults
//         .filter(result => result.isValid && !result.isDuplicate)
//         .map(result => result.row);
      
//       console.log("Initial valid rows after dataset duplicate check:", validRows);
  
//       // Then check for duplicates in DB
//       const validatedRows = await Promise.all(
//         validRows.map(async (row) => {
//           const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
//             `${process.env.REACT_APP_BASE_URL}/btc/getduplicatebtc`,
//             { btcCode: row.BTCCode }
//           );
  
//           const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
//           if (isDuplicateInDB) {
//             console.log(`Duplicate found in DB for BTCCode: ${row.BTCCode}`);
//             return null;
//           }
//           return row;
//         })
//       );
  
//       // Final valid rows
//       validRows = validatedRows.filter(row => row !== null);
//       console.log("Final valid rows after all checks:", validRows);
//       return validRows;
  
//     } catch (error) {
//       console.error("Error in getExistingUserIds:", error);
//       return [];
//     }
//   };
// // Displaying data based on options
// const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
//     // Decide which data to display based on displayOption and uploadOption
//     let dataToDisplay;
//     if (displayOption === "1") {
//         console.log("Display option is 1");
//         const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"

//         console.log("Table data:", tableData);
//         console.log("Existing users:", existingUsers);

//         const existingUserKeys = new Set(
//             existingUsers.map((user) => `${user.BTCCode}`)
//         );

//         dataToDisplay = tableData.filter(
//             (user) =>
//                 !existingUserKeys.has(`${user.BTCCode}`)
//         );

//         console.log("Filtered remaining data excluding existingUsers:", dataToDisplay);
//     } else if (displayOption === "2") {
//         console.log("Display option is 2");
//         dataToDisplay = tableData; // Display tableData if displayOption is "2"
//     } else if (uploadOption === "3") {
//         console.log("Upload option is 3");
//         dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
//     } else {
//         dataToDisplay = tableData; // Default to tableData if no conditions are met
//     }

//     console.log("Data to display:", dataToDisplay);
//     return dataToDisplay;
// };


//   // Example usage
//   // (async () => {
//   //   const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
    
//   //   if (!Array.isArray(dataToDisplay)) {
//   //     console.error("getDataToDisplay did not return an array:", dataToDisplay);
//   //     return;
//   //   }
  
//   //   console.log("Final dataToDisplay data is", dataToDisplay);
  
//   //   // Call processData only when dataToDisplay is defined and valid
//   //   await processData(dataToDisplay);
  
//   // })();

//      const handleDownload = () => {
//            if (processedData.length === 0) {
//              setAlert({
//                severity: "warning",
//                message: "No data available to export!",
//              });
//              return;
//            }
         
//            const extractText = (cellValue) => {
//              if (Array.isArray(cellValue)) {
//                return cellValue.join(", "); // Convert array to string
//              } else if (typeof cellValue === "string") {
//                return cellValue;
//              } else if (React.isValidElement(cellValue)) {
//                return cellValue.props.children;
//              }
//              return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
//            };
         
//    const formattedUsers = processedData.map((user) => ({
//      "Action": extractText(user.Action),
//      "SLNo": extractText(user.SLNo),
//      "BTCCode": extractText(user.BTCCode),
//      "BTCDescription": extractText(user.BTCDescription),
    
//      "StatementType": extractText(user.StatementType || ""),
//      "Inflow": extractText(user.Inflow || ""),
//      "Outflow": extractText(user.Outflow || ""),
     
//    }));
 
 
//  // Add second and third row
//  const secondRow = {
//  "Action": "R","SLNo": "R", "BTCCode": "R","BTCDescription": "R", "StatementType": "", "Inflow": "", "Outflow": "",
//  };
 
//  const thirdRow = {
//  "Action": "1","SLNo": "5", "BTCCode": "6","BTCDescription": "50","StatementType": "Dropdown", "Inflow": "Checkbox", "Outflow": "Checkbox", 
//  };
 
 
//      // Insert second and third row at the beginning of the formatted data
//      const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];
 
//          const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
//        console.log("rowsToDownload data is",rowsToDownload);
  
//          // Define border style
//    const border = {
//      top: { style: "thin", color: { rgb: "000000" } },
//      right: { style: "thin", color: { rgb: "000000" } },
//      bottom: { style: "thin", color: { rgb: "000000" } },
//      left: { style: "thin", color: { rgb: "000000" } },
//    };
 
//        // Apply borders to the first three rows
//        rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
//          Object.keys(row).forEach((col, colIdx) => {
//            const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
//            if (worksheet[cellAddress]) {
//              worksheet[cellAddress].s = { border };
//            }
//          });
//        });
 
       
//         // Additional cell styling for validation errors
//          Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
//            rowsToDownload.forEach((row, rowIdx) => {
//              const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
//              const cellValue = row[col];
       
//              if (
//                typeof cellValue === "string" && (
//                  cellValue.includes("Shouldn't be blank") 
//                  ||
//                  cellValue.includes("Either Address Section or Building, Street, and Area Section must be entered")
//                  // cellValue.includes("Max. allowed character")
//                )
//              ) {
//                worksheet[cellAddress] = {
//                  v: cellValue,
//                  s: { font: { color: { rgb: "FF0000" } } },
//                };
//              } else if (Array.isArray(row[col])) {
//                worksheet[cellAddress] = {
//                  v: extractText(row[col]),
//                  s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
//                };
//              }
//              else {
//                worksheet[cellAddress] = { v: cellValue };
//              }
//            });
//          });
       
//          // AutoFit column width
//          worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
//            const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
//            return { wch: maxLength + 2 }; // Add extra space
//          });
 
 
//          const workbook = XLSX.utils.book_new();
//          XLSX.utils.book_append_sheet(workbook, worksheet, "BTC");
//          XLSX.writeFile(workbook, "BTC_Uploading_Data.xlsx");
//        };

//   const existingButtons = AdminActionsConfig.screen7.map((button) => {
//     let onClick;
//     let title;

//     if (button.icon === MdOutlineFileDownload) {
//       onClick = handleDownload;
//       title = 'Download File';
//     } else {
//       onClick = button.onClick || (() => {}); // Provide a default no-op function if onClick is undefined
//       title = button.title || ''; // Use existing title or default to an empty string
//     }

//     return {
//       ...button,
//       onClick, // Reference the function without invoking it
//       title, // Add the title property to the button configuration
//     };
//   });

//   const extraButtons = [];

//   const actionButtons = existingButtons.concat(
//     extraButtons.map((button) => ({
//       ...button,
//       disabled: selectedRows.length === 0, // Disable button if no users are selected
//       onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
//     }))
//   );

//   const [columnSettings, setColumnSettings] = useState(columns);

//   const modifiedColumns = columnSettings
//     .filter((col) => col.visible)
//     .map((col) => ({
//       ...col,
//       width: col.width, // This should hold the updated width
//     }));

//   const fetchAssignee = async () => {
//     try {
//       const userDetailString = localStorage.getItem('userDetail');
//       if (userDetailString) {
//         const userDetail = JSON.parse(userDetailString);
//         const assigneeData = {
//           id: userDetail.id || '',
//           userId: userDetail.userId || '',
//           clientId: userDetail.clientId || '',
//           assigner: userDetail.username || '',
//         };

//         setAssignee(assigneeData);

//         // Log the id and assigner values to the console
//         console.log('Assignee ID:', assigneeData.id);
//         console.log('Assigner:', assigneeData.assigner);
//         console.log('Assigner clientId:', assigneeData.clientId);
//         console.log('Assigner userId:', assigneeData.userId);
//       } else {
//         setAlert({
//           severity: 'error',
//           message: 'No user detail found in session storage.',
//         });
//       }
//     } catch (error) {
//       setAlert({
//         severity: 'error',
//         message: `Error fetching user details: ${error.message}`,
//       });
//     }
//   };
//   useEffect(() => {
//     fetchStatementTypes();
//   }, []);
//   const fetchStatementTypes = async () => {
//     setLoading(true);
//     try {
//       const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
//       const responseData = response.data.data;
//   console.log("Response Data:", responseData);
//       if (responseData.error) {
//         setAlert({ severity: "error", message: responseData.message });
//         return;
//       }
  
//       setStatementTypes(responseData);
//       console.log("Statement Types:", statementTypes);
//     } catch (error) {
//       setAlert({
//         severity: "error",
//         message: `Error fetching legal entities: ${error.message}`,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };
  
//   const isRowValid = async (row, index) => {
//     console.log("Row Object in isRowValid:", row);
    
//     // 1. Fetch valid types
//     let validStatementTypes = [];
//     try {
//       const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
//       validStatementTypes = response.data.data || [];
//       console.log("Valid Statement Types:", validStatementTypes);
//     } catch (error) {
//       console.error("Error fetching statement types:", error);
//     }
  
//     // 2. Validate all fields
//     const isActionValid = row.Action && ["A", "a","E","e"].includes(row.Action) && String(row.Action).length === 1;
//     const isSLNoValid = row.SLNo === undefined || row.SLNo === '' || (!isNaN(row.SLNo) && String(row.SLNo).length <= 5);
//     const isBTCCodeValid = row.BTCCode && String(row.BTCCode).length <= 6;
//     const isBTCDescriptionValid = row.BTCDescription && String(row.BTCDescription).length <= 50;
    
//     // 3. Use the fetched validStatementTypes (FIXED THIS LINE)
//     const isStatementTypeValid = !row.StatementType || validStatementTypes.includes(row.StatementType);
//     const isInflowValid = !row.Inflow || ['X', 'x'].includes(row.Inflow);
//     const isOutflowValid = !row.Outflow || ['X', 'x'].includes(row.Outflow);
  
//     // 4. Collect errors with more detailed messages
//     let errors = [];
//     if (!isActionValid) errors.push("Invalid Action");
//     if (!isSLNoValid) errors.push("Invalid Serial No");
//     if (!isBTCCodeValid) errors.push("Invalid BTC Code");
//     if (!isBTCDescriptionValid) errors.push("Invalid BTC Description");
//     if (!isStatementTypeValid) errors.push(
//       `Invalid Statement Type '${row.StatementType}'. Valid types: ${validStatementTypes.join(', ') || 'None'}`
//     );
//     if (!isInflowValid) errors.push("Invalid Inflow");
//     if (!isOutflowValid) errors.push("Invalid Outflow");
  
//     if (errors.length > 0) {
//       console.log(`Row ${index + 1} is invalid:`, errors);
//       console.log("Provided StatementType:", row.StatementType);
//       console.log("Valid Types:", validStatementTypes);
//     }
  
//     return (
//       isSLNoValid &&
//       isActionValid &&
//       isBTCCodeValid &&
//       isBTCDescriptionValid &&
//       isStatementTypeValid &&
//       isInflowValid &&
//       isOutflowValid
//     );
//   };
  
//   const processData = async (data) => {
//     if (!Array.isArray(data)) {
//       console.error("processData received invalid data:", data);
//       return []; // Return an empty array to prevent further errors
//     }
//     console.log("processData received data:", data);
//     let validStatementTypes = [];
//     try {
//       const response = await SourceServices.getAllSource(`/statement-format/getstatementtypes`);
//       validStatementTypes = response.data.data || [];
//       console.log("Valid Statement Types:", validStatementTypes);
//     } catch (error) {
//       console.error("Error fetching statement types:", error);
//     }
//     return Promise.all(
//       data.map(async (row, rowIndex) => {
//         // Check for duplicate BTCCode in the current dataset
//         const isDuplicateUserId = data.some(
//           (r, index) =>
//             index !== rowIndex && // Exclude the current row by index
//             r.BTCCode === row.BTCCode // Compare BTCCode directly
//         );
//         console.log(`Row ${row.BTCCode}: isDuplicateUserId = ${isDuplicateUserId}`);
  
//         // Check for duplicate BTCCode in the database
//         const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
//           `${process.env.REACT_APP_BASE_URL}/btc/getduplicatebtc`,
//           {
//             btcCode: row.BTCCode,
//           }
//         );
  
//         console.log("isDuplicateInDB API Response:", isDuplicateInDBResponse);
  
//         // Extract the `isDuplicate` property from the response
//         const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
//         console.log(`Row ${row.BTCCode}: isDuplicateInDB = ${isDuplicateInDB}`);
  
//         // Validate all fields
//         const hasNotError = [
//           row.Action,
//           row.SLNo,
//           row.BTCCode,
//           row.BTCDescription,
//           row.StatementType,
//           row.Inflow,
//           row.Outflow,
//         ].every((field, index) => {
//           let isValid = true;
//           let reason = "";
  
//           switch (index) {
//             case 0: // Action
//               if (!field) {
//                 isValid = false;
//                 reason = "Action should not be blank.";
//               } else if (!['A', 'a', 'E', 'e'].includes(field)) {
//                 isValid = false;
//                 reason = "Action should be either 'A', 'a', 'E', or 'e'.";
//               } else if (String(field).length > 1) {
//                 isValid = false;
//                 reason = "Action exceeds max length of 1.";
//               }
//               break;
  
//             case 1: // SLNo
//               if (row.SLNo !== undefined && row.SLNo !== '') {
//                 if (isNaN(row.SLNo)) {
//                   isValid = false;
//                   reason = "SLNo should be a valid number.";
//                 } else if (String(row.SLNo).length > 5) {
//                   isValid = false;
//                   reason = "SLNo exceeds max length of 5.";
//                 }
//               }
//               break;
  
//             case 2: // BTCCode
//               if (!field) {
//                 isValid = false;
//                 reason = "BTCCode should not be blank.";
//               } else if (String(field).length > 6) {
//                 isValid = false;
//                 reason = "BTCCode exceeds max length of 6.";
//               } else if (isDuplicateUserId || isDuplicateInDB) {
//                 isValid = false;
//                 reason = "Duplicate BTCCode.";
//               }
//               break;
  
//             case 3: // BTCDescription
//               if (!field) {
//                 isValid = false;
//                 reason = "BTCDescription should not be blank.";
//               } else if (String(field).length > 50) {
//                 isValid = false;
//                 reason = "BTCDescription exceeds max length of 50.";
//               }
//               break;
  
//               case 4: // StatementType (optional)
//               if (field && !validStatementTypes.includes(field)) {
//                 isValid = false;
//                 reason = `Invalid Statement Type. Valid types: ${validStatementTypes.join(', ')}`;
//               }
//               break;
  
//             case 5: // Inflow (optional)
//               if (field && !['X', 'x'].includes(field)) {
//                 isValid = false;
//                 reason = "Inflow should be either 'X' or 'x'.";
//               }
//               break;
  
//             case 6: // Outflow (optional)
//               if (field && !['X', 'x'].includes(field)) {
//                 isValid = false;
//                 reason = "Outflow should be either 'X' or 'x'.";
//               }
//               break;
  
//             default:
//               break;
//           }
  
//           if (!isValid) {
//             console.log(`Row ${row.BTCCode}: Validation Failed - ${reason}`);
//           } else {
//             console.log(`Row ${row.BTCCode}: Validation Passed - ${field}`);
//           }
  
//           return isValid;
//         });
  
//         console.log(`Row ${row.BTCCode}: Final hasNotError status = ${hasNotError}`);
  
//         return {
//           ...row,
//           isDuplicateUserId,
//           isDuplicateInDB,
//           successfulrecords: hasNotError,
  
//           'Action': row['Action'] ? (
//             ['A', 'a', 'E', 'e'].includes(row['Action']) ? (
//               row['Action']
//             ) : (
//               <span style={{ color: 'red' }}>{row['Action']} (Invalid Action)</span>
//             )
//           ) : (
//             <span style={{ color: 'red' }}> Shouldn't be blank</span>
//           ),
//           'SLNo': row.SLNo ? (
//             !isNaN(row.SLNo) && String(row.SLNo).length <= 5 ? (
//               row.SLNo
//             ) : (
//               <span style={{ color: 'red' }}>{row.SLNo} (Invalid SLNo)</span>
//             )
//           ) : (
//             <span style={{ color: 'red' }}></span>
//           ),
//           'BTCCode': row.BTCCode ? (
//             String(row.BTCCode).length <= 6 ? (
//               isDuplicateUserId || isDuplicateInDB ? (
//                 <span style={{ color: 'red' }}>{row.BTCCode}, Duplicate BTCCode</span>
//               ) : (
//                 row.BTCCode
//               )
//             ) : (
//               <span style={{ color: 'red' }}>{row.BTCCode}, Max. allowed character is 6</span>
//             )
//           ) : (
//             <span style={{ color: 'red' }}>Shouldn't be blank</span>
//           ),
//           'BTCDescription': row.BTCDescription ? (
//             String(row.BTCDescription).length <= 50 ? (
//               row.BTCDescription
//             ) : (
//               <span style={{ color: 'red' }}>{row.BTCDescription} (Max. allowed character is 50)</span>
//             )
//           ) : (
//             <span style={{ color: 'red' }}>Shouldn't be blank</span>
//           ),
//      'StatementType': row['StatementType'] ? (
//         validStatementTypes.includes(row['StatementType']) ? (
//           row['StatementType']
//         ) : (
//           <span style={{ color: 'red' }}>
//             {row['StatementType']} (Invalid. Valid: {validStatementTypes.join(', ')})
//           </span>
//         )
//       ) : (
//         <span></span>
//       ),
//           'Inflow': row['Inflow'] ? (
//             ['X', 'x'].includes(row['Inflow']) ? (
//               row['Inflow']
//             ) : (
//               <span style={{ color: 'red' }}>{row['Inflow']} (Invalid Inflow)</span>
//             )
//           ) : (
//             <span></span> // Empty span for optional field
//           ),
//           'Outflow': row['Outflow'] ? (
//             ['X', 'x'].includes(row['Outflow']) ? (
//               row['Outflow']
//             ) : (
//               <span style={{ color: 'red' }}>{row['Outflow']} (Invalid Outflow)</span>
//             )
//           ) : (
//             <span></span> // Empty span for optional field
//           ),
//         };
//       })
//     );
//   };

//   // useEffect(() => {
      
    
//   //     fetchData();
//   //   }, []);

//   //   const fetchData = async () => {
//   //     setLoading(true);
//   //     const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
//   //     console.log("data to display is",dataToDisplay);

//   //     const processed = await processData(dataToDisplay);
//   //     console.log("Processed data is ",processed);

//   //     setProcessedData(processed);
//   //     setLoading(false);
//   //   };

//   useEffect(() => {
//       const fetchData = async () => {
//         setLoading(true);
//         const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
//         console.log("dataTo display is get",dataToDisplay);
        
//         const processed = await processData(dataToDisplay);
//         setProcessedData(processed);
//         setLoading(false);
//       };
    
//       fetchData();
//     }, []);

//   return (
//     <div>
//       <Header
//         title={`Bank Statements > Portal > Portal Upload > Preview`}
//         onBreadcrumbClick={handleBreadcrumbClick}
//         backRoute="/5/bank-statement/53/portal-upload/preview-screen"
//       />
//  {loading && (
//           <div>
//             <CustomeLoading />
//           </div>
//         )}

//       <PrimaryActions icons={iconId} buttons={actionButtons} selectedCount={selectedRows.length} selectIds={selectedRows} />

//       <ReTable
//         data={processedData}
//         columns={modifiedColumns} // Use modified columns based on visibility
//         selectedRows={[]}
//         onSelectRow={() => {}}
//         onSelectAll={() => {}}
//         onRowDoubleClick={() => {}}
//         showCheckbox={false}
//       />
//     </div>
//   );
// }

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../../../../components/Header';
import ReTable from '../../../../../../components/Table';
import CustomeLoading from '../../../../../../components/CustomeLoading';

export default function PortalDisplayScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const { tableData } = location.state || {}; // Get tableData from state
  const [processedData, setProcessedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { Header: 'Update', accessor: 'update', visible: true, width: 100, alignment: 'left' },
    { Header: 'Transaction Date', accessor: 'Transaction Date', visible: true, width: 150, alignment: 'left' },
    { Header: 'Bank Statement Reference', accessor: 'Bank Statement Reference', visible: true, width: 200, alignment: 'left' },
    { Header: 'Bank Description', accessor: 'Bank Description', visible: true, width: 200, alignment: 'left' },
    { Header: 'Customer Reference', accessor: 'Customer Reference', visible: true, width: 150, alignment: 'left' },
    { Header: 'Debit', accessor: 'Debit', visible: true, width: 100, alignment: 'left' },
    { Header: 'Credit', accessor: 'Credit', visible: true, width: 100, alignment: 'left' },
    { Header: 'Flow Code', accessor: 'Flow Code', visible: true, width: 100, alignment: 'left' },
    { Header: 'Errors', accessor: 'Errors', visible: true, width: 200, alignment: 'left' }
  ];

  const handleBreadcrumbClick = (crumb) => {
    if (crumb.title === 'Bank Statements') {
      navigate('/bank-statements');
    } else if (crumb.title === 'Portal') {
      navigate('/bank-statements');
    }
    else if (crumb.title === 'Portal Upload') {
      navigate('/5/bank-statement/53/portal-upload');
    }
    else if (crumb.path) {
      navigate(crumb.path);
    }
  };

  useEffect(() => {
    if (tableData) {
      setLoading(true);
      // Process the data if needed (add any simple transformations here)
      const processed = tableData.map(row => ({
        ...row,
        // Add any simple transformations if needed
      }));
      setProcessedData(processed);
      setLoading(false);
    }
  }, [tableData]);

  return (
    <div>
      <Header
        title="Bank Statements > Portal > Portal Upload > Preview"
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute="/5/bank-statement/53/portal-upload" // Adjust as needed
      />

      {loading && <CustomeLoading />}

      <ReTable
        data={processedData}
        columns={columns}
        selectedRows={[]}
        onSelectRow={() => {}}
        onSelectAll={() => {}}
        onRowDoubleClick={() => {}}
        showCheckbox={false}
      />
    </div>
  );
}