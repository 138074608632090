// import React, { useState } from 'react';
// import { useTable, useResizeColumns, useFlexLayout } from 'react-table';
// import { Checkbox } from '@mui/material';

// const ReTable = ({
//   data,
//   columns,
//   onSelectRow,
//   selectedRows,
//   onSelectAll,
//   onRowDoubleClick,
//   customRowColor,
//   rowKey = "id",
//   showCheckbox = true,
//   showHeaderCheckbox = true,
//   customWidth = '100%', // Add a customWidth prop with a default value
// }) => {
//   const safeData = Array.isArray(data) ? data : [data];
//   const [overriddenRows, setOverriddenRows] = useState([]); // Tracks rows that were manually toggled by the user

//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     rows,
//     prepareRow,
//   } = useTable(
//     {
//       columns,
//       data: safeData,
//     },
//     useFlexLayout,
//     useResizeColumns
//   );

//   const handleRowSelection = (rowId, isMakeAndChecker) => {
//     if (isMakeAndChecker) {
//       if (overriddenRows.includes(rowId)) {
//         setOverriddenRows((prev) => prev.filter((id) => id !== rowId));
//       } else {
//         setOverriddenRows((prev) => [...prev, rowId]);
//       }
//     }
//     onSelectRow(rowId);
//   };

//   const isRowChecked = (rowId, isMakeAndChecker) => {
//     return (
//       (selectedRows.includes(rowId) && !overriddenRows.includes(rowId)) || 
//       (isMakeAndChecker && !overriddenRows.includes(rowId))
//     );
//   };

//   return (
//     <div
//       {...getTableProps()}
//       className="overflow-x-auto p-2"
//       style={{
//         maxHeight: '80vh',
//         width: customWidth,
//         overflowY: 'auto',
//       }}
//     >
//       <div className="overflow-x-auto">
//         <div className="overflow-y-auto">
//           <table className="table-fixed min-w-full bg-white border border-customBlue">
//           <thead className="bg-customBlue text-primary-foreground text-left text-sm font-medium sticky top-0 z-20">
//   <tr className="z-10">
//     {showCheckbox && (
//       <th
//         className="text-center text-white font-medium border-r border-customTableBorder relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
//         style={{ width: '30px', minWidth: '30px', maxWidth: '30px' }}
//       >
//         {showHeaderCheckbox ? (
//           <Checkbox
//             size="small"
//             checked={selectedRows.length === safeData.length}
//             onChange={onSelectAll}
//             sx={{ transform: 'scale(0.8)' }}
//           />
//         ) : (
//           <span>Active</span>
//         )}
//       </th>
//     )}

//     {headerGroups.map((headerGroup) => (
//       <React.Fragment key={headerGroup.id}>
//         {headerGroup.headers.map((column) => (
//           <th
//             {...column.getHeaderProps()}
//             className="px-2 text-center text-white font-medium border-r border-sky-100 relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
//             style={{ 
//               width: `${column.width}px`, 
//               height: '30px', 
//               textAlign: 'center'  // Ensure all headers are centered
//             }} 
//           >
//             {column.render('Header')}
//             {column.canResize && (
//               <div
//                 {...column.getResizerProps()}
//                 className="absolute top-0 right-0 w-2 h-full cursor-col-resize"
//                 style={{ transform: 'translateX(50%)' }}
//               />
//             )}
//           </th>
//         ))}
//       </React.Fragment>
//     ))}
//   </tr>
// </thead>

//             <tbody className="text-sm" {...getTableBodyProps()}>
//               {rows.length === 0 ? (
//                 <tr>
//                   <td colSpan={columns.length + (showCheckbox ? 1 : 0)} className="py-1 px-2 text-center">
//                     No data found.
//                   </td>
//                 </tr>
//               ) : (
//                 rows.map((row) => {
//                   prepareRow(row);
//                   const rowId = row.original[rowKey];
//                   const isMakeAndChecker = row.original.makeAndChecker === 1;
//                   const isChecked = isRowChecked(rowId, isMakeAndChecker);
//                   return (
//                     <tr
//                       key={rowId}
//                       className={selectedRows.includes(rowId) ? 'bg-blue-100' : row.index % 2 === 0 ? 'bg-white' : 'bg-sky-50'}
//                       onDoubleClick={() => onRowDoubleClick(rowId)}
//                       style={{
//                         height: '20px',
//                         backgroundColor: customRowColor,
//                       }}
//                     >
//                       {showCheckbox && (
//                         <td className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray">
//                           <Checkbox
//                             size="small"
//                             checked={isChecked}
//                             onChange={() => handleRowSelection(rowId, isMakeAndChecker)}
//                             sx={{ transform: 'scale(0.8)' }}
//                           />
//                         </td>
//                       )}

//                       {row.cells.map((cell) => (
//                         <td
//                           key={cell.column.id}
//                           {...cell.getCellProps()}
//                           className="px-2 text-center border-b border-customTableBorder border-r border-customTableBorder whitespace-nowrap h-4 text-customGray"
//                           style={{
//                             width: `${cell.column.width}px`,
//                             height: '30px',
//                             textAlign: cell.column.alignment || 'center', 
//                           }}
//                         >
//                           {cell.render('Cell')}
//                         </td>
//                       ))}
//                     </tr>
//                   );
//                 })
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };


// export default ReTable;




import React, { useState } from 'react';
import { useTable, useResizeColumns, useFlexLayout } from 'react-table';
import { Checkbox } from '@mui/material';
const ReTable = ({
  data,
  columns,
  onSelectRow,
  selectedRows,
  onSelectAll,
  onRowDoubleClick,
  customRowColor,
  rowKey = "id",
  showCheckbox = true,
  showHeaderCheckbox = true,
  customWidth = '100%',
  checkboxPosition = 'start', // Add a new prop for checkbox position
}) => {
  const safeData = Array.isArray(data) ? data : [data];
  const [overriddenRows, setOverriddenRows] = useState([]); // Tracks rows that were manually toggled by the user

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: safeData,
    },
    useFlexLayout,
    useResizeColumns
  );

  const handleRowSelection = (rowId, isMakeAndChecker) => {
    if (isMakeAndChecker) {
      if (overriddenRows.includes(rowId)) {
        setOverriddenRows((prev) => prev.filter((id) => id !== rowId));
      } else {
        setOverriddenRows((prev) => [...prev, rowId]);
      }
    }
    onSelectRow(rowId);
  };

  const isRowChecked = (rowId, isMakeAndChecker) => {
    return (
      (selectedRows.includes(rowId) && !overriddenRows.includes(rowId)) || 
      (isMakeAndChecker && !overriddenRows.includes(rowId))
    );
  };

  return (
    <div
      {...getTableProps()}
      className="overflow-x-auto p-2"
      style={{
        maxHeight: '80vh',
        width: customWidth,
        overflowY: 'auto',
      }}
    >
      <div className="overflow-x-auto">
        <div className="overflow-y-auto">
          <table className="table-fixed min-w-full bg-white border border-customBlue">
            <thead className="bg-customBlue text-primary-foreground text-left text-sm font-medium sticky top-0 z-20">
              <tr className="z-10">
                {/* Conditionally render checkbox column at the start or end */}
                {checkboxPosition === 'start' && showCheckbox && (
                  <th
                    className="text-center text-white font-medium border-r border-customTableBorder relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
                    style={{ width: '30px', minWidth: '30px', maxWidth: '30px' }}
                  >
                    {showHeaderCheckbox ? (
                      <Checkbox
                        size="small"
                        checked={selectedRows.length === safeData.length}
                        onChange={onSelectAll}
                        sx={{ transform: 'scale(0.8)' }}
                      />
                    ) : (
                      <span>Active</span>
                    )}
                  </th>
                )}

                {headerGroups.map((headerGroup) => (
                  <React.Fragment key={headerGroup.id}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="px-2 text-center text-white font-medium border-r border-sky-100 relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
                        style={{ width: `${column.width}px`, height: '30px', textAlign: column.alignment || 'center' }} 
                      >
                        {column.render('Header')}
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className="absolute top-0 right-0 w-2 h-full cursor-col-resize"
                            style={{ transform: 'translateX(50%)' }}
                          />
                        )}
                      </th>
                    ))}
                  </React.Fragment>
                ))}

                {checkboxPosition === 'end' && showCheckbox && (
                  <th
                    className="text-center text-white font-medium border-r border-customTableBorder relative whitespace-nowrap bg-customBlue sticky top-0 z-10"
                    style={{ width: '50px', minWidth: '50px', maxWidth: '50px' }}
                  >
                    {showHeaderCheckbox ? (
                      <Checkbox
                        size="small"
                        checked={selectedRows.length === safeData.length}
                        onChange={onSelectAll}
                        sx={{ transform: 'scale(0.8)' }}
                      />
                    ) : (
                      <span>Active</span>
                    )}
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="text-sm" {...getTableBodyProps()}>
              {rows.length === 0 ? (
                <tr>
                  <td colSpan={columns.length + (showCheckbox ? 1 : 0)} className="py-1 px-2 text-center">
                    No data found.
                  </td>
                </tr>
              ) : (
                rows.map((row) => {
                  prepareRow(row);
                  const rowId = row.original[rowKey];
                  const isMakeAndChecker = row.original.makeAndChecker === 1;
                  const isChecked = isRowChecked(rowId, isMakeAndChecker);
                  return (
                    <tr
                      key={rowId}
                      className={selectedRows.includes(rowId) ? 'bg-blue-100' : row.index % 2 === 0 ? 'bg-white' : 'bg-sky-50'}
                      onDoubleClick={() => onRowDoubleClick(rowId)}
                      style={{
                        height: '20px',
                        backgroundColor: customRowColor,
                      }}
                    >
                      {checkboxPosition === 'start' && showCheckbox && (
                        <td className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray">
                          <Checkbox
                            size="small"
                            checked={isChecked}
                            onChange={() => handleRowSelection(rowId, isMakeAndChecker)}
                            sx={{ transform: 'scale(0.8)' }}
                          />
                        </td>
                      )}

                      {row.cells.map((cell) => (
                        <td
                          key={cell.column.id}
                          {...cell.getCellProps()}
                          className="px-2 text-center border-b border-customTableBorder border-r border-customTableBorder whitespace-nowrap h-4 text-customGray"
                          style={{
                            width: `${cell.column.width}px`,
                            height: '30px',
                            textAlign: cell.column.alignment || 'center', 
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}

                      {checkboxPosition === 'end' && showCheckbox && (
                        <td className="text-center border-r border-customTableBorder whitespace-nowrap text-customGray">
                          <Checkbox
                            size="small"
                            checked={isChecked}
                            onChange={() => handleRowSelection(rowId, isMakeAndChecker)}
                            sx={{ transform: 'scale(0.8)' }}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};



export default ReTable;
