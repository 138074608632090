
import { useContext } from 'react';
import './App.css';
import LoginComponent from './pages/login/LoginComponent';
import { AuthContext } from './context/AuthContext';
import Home from './pages/Screens/Home';

import AppRoutes from './routes';

const App = () => {
  document.title = "TMS";
  const { state } = useContext(AuthContext);

  return <AppRoutes />;
}

export default App;
