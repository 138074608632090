import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../../../components/Header';
import { AdminActionsConfig } from '../../../../Admin/Components/ActionConfig';
import { MdOutlineFileDownload } from 'react-icons/md';
import AdminActions from '../../../../Admin/Components/AdminActions';
import ReTable from '../../../../../../components/Table';
import UserService from '../../../../../../rest-services/UserServices';
import XLSX from 'xlsx-js-style';
import CustomeLoading from '../../../../../../components/CustomeLoading';
import PrimaryActions from '../../../Components/ActionButton';
import SourceServices from '../../../../../../rest-services/SourceServices';

export default function SourceDisplayScreen() {
  const location = useLocation();
  const { iconId } = useParams();
  const navigate = useNavigate();
  const { tableData, displayOption, uploadOption } = location.state || {}; // Retrieve tableData from state
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [processedData, setProcessedData] = useState([]);
  const [assignee, setAssignee] = useState({ id: '', username: '' });
  const [alert, setAlert] = useState(null);
const [loading, setLoading] = useState(false);

  console.log('table data received from prop in currency rate is', tableData);
  console.log('displayOption data received from prop in currency rate is', displayOption);
  console.log('uploadOption data received from prop in currency rate is', uploadOption);

  useEffect(() => {
    fetchAssignee();
  }, []);

  const handleBreadcrumbClick = (crumb) => {
    console.log('Breadcrumb clicked:', crumb.title);
    if (crumb.title === 'Market Data') {
      navigate('/2/master-data');
    } else if (crumb.title === 'Source') {
      navigate('/2/master-data/14/source-code');
    } else if (crumb.title === 'Primary Data') {
      navigate('/2/master-data');
    } else if (crumb.path) {
      navigate(crumb.path);
    } else if (crumb.title === 'Source Upload') {
      navigate('/2/master-data/14/source-upload');
    }
  };

  const columns = [

        { Header: 'Action', accessor: 'Action', visible: true, width: ("Action".length -2)*10, alignment: 'left' },
    { Header: 'SLNo', accessor: 'SLNo', visible: true, width: ("SLNo".length -2)*10, alignment: 'left' },
    {
      Header: 'Update',
      accessor: (row) => {
        if (row.successfulrecords && uploadOption === '3') {
          return 'Yes';
        } else if (row.successfulrecords && (displayOption === '1' || displayOption === '2')) {
          return 'No';
        } else {
          return <span style={{ color: 'red' }}>No</span>;
        }
      },      visible: true,
      width: ("Update".length -2)*10,
      alignment: "left"
    },
    { Header: 'SourceType', accessor: 'SourceType', visible: true, width: ("SourceType".length -2)*10, alignment: 'left' },
    { Header: 'SourceCode', accessor: 'SourceCode', visible: true, width: ("SourceCode".length -2)*10, alignment: 'left' },
    { Header: 'SourceCodeDescription', accessor: 'SourceCodeDescription', visible: true, width: ("SourceCodeDescription".length -2)*10, alignment: 'left' },
    { Header: 'OurCode', accessor: 'OurCode', visible: true, width: ("OurCode".length -2)*10, alignment: 'left' },
    { Header: 'OurCodeDescription', accessor: 'OurCodeDescription', visible: true, width: ("OurCodeDescription".length -2)*10, alignment: 'left' },
    { Header: 'RateType', accessor: 'RateType', visible: true, width: ("RateType".length -2)*10, alignment: 'left' },
    { Header: 'DataType', accessor: 'DataType', visible: true, width: ("DataType".length -2)*10, alignment: 'left' },
    { Header: 'Ratio', accessor: 'Ratio', visible: true, width: ("Ratio".length -2)*10, alignment: 'left' },
    { Header: 'MDType', accessor: 'MDType', visible: true, width: ("MDType".length -2)*10, alignment: 'left' },
   
  ];

  const getExistingUserIds = async (tableData) => {
    try {
      // Filter rows based on basic validation
      let validRows = tableData.filter((row, index) => isRowValid(row, index));
      console.log("Initial valid rows:", validRows);
  
      // Check for existing SourceCode and duplicate combinations in the database
      const validatedRows = await Promise.all(
        validRows.map(async (row) => {
          // Check if SourceType exists in the database
          const sourceCodeExistsResponse = await SourceServices.checkDuplicateSourcecode(
            `${process.env.REACT_APP_BASE_URL}/checkexistingmdsource`,
            { source: row.SourceType }
          );
  
          const sourceCodeExists = sourceCodeExistsResponse?.exists;
  
          // If SourceCode does not exist, mark the row as invalid
          if (!sourceCodeExists) {
            console.log(`SourceCode ${row.SourceType} does not exist in the database.`);
            return null; // Return null for invalid rows
          }
  
          // Check for duplicate combination of source, sourceCode, and ourCode in the database
          const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
            `${process.env.REACT_APP_BASE_URL}/checkduplicatesource`,
            {
              source: row.SourceType,
              sourceCode: row.SourceCode,
              ourCode: row.OurCode,
            }
          );
  
          console.log("isDuplicateInDB API Response:", isDuplicateInDBResponse);
  
          // Extract the `isDuplicate` property from the response
          const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
          console.log("isDuplicateInDB:", isDuplicateInDB);
  
          // If the combination already exists, mark the row as invalid
          if (isDuplicateInDB) {
            console.log(`Duplicate combination found for SourceType: ${row.SourceType}, SourceCode: ${row.SourceCode}, OurCode: ${row.OurCode}`);
            return null; // Return null for invalid rows
          }
  
          return row; // Return the row if it is valid
        })
      );
  
      // Filter out null values (invalid rows)
      validRows = validatedRows.filter((row) => row !== null);
      console.log("Final valid rows after SourceCode and duplicate combination validation:", validRows);
  
      return validRows;
    } catch (error) {
      console.error("Error fetching existing user IDs:", error);
      return [];
    }
  };

// Displaying data based on options
const getDataToDisplay = async (displayOption, uploadOption, tableData) => {
    // Decide which data to display based on displayOption and uploadOption
    let dataToDisplay;
    if (displayOption === "1") {
        console.log("Display option is 1");
        const existingUsers = await getExistingUserIds(tableData); // Display filteredData if displayOption is "1"

        console.log("Table data:", tableData);
        console.log("Existing users:", existingUsers);

        const existingUserKeys = new Set(
            existingUsers.map((user) => `${user.SourceType}-${user.SourceCode}-${user.OurCode}`)
        );

        dataToDisplay = tableData.filter(
            (user) =>
                !existingUserKeys.has(`${user.SourceType}-${user.SourceCode}-${user.OurCode}`)
        );

        console.log("Filtered remaining data excluding existingUsers:", dataToDisplay);
    } else if (displayOption === "2") {
        console.log("Display option is 2");
        dataToDisplay = tableData; // Display tableData if displayOption is "2"
    } else if (uploadOption === "3") {
        console.log("Upload option is 3");
        dataToDisplay = await getExistingUserIds(tableData); // Display successfulRecords if uploadOption is "3"
    } else {
        dataToDisplay = tableData; // Default to tableData if no conditions are met
    }

    console.log("Data to display:", dataToDisplay);
    return dataToDisplay;
};


  // Example usage
  // (async () => {
  //   const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
    
  //   if (!Array.isArray(dataToDisplay)) {
  //     console.error("getDataToDisplay did not return an array:", dataToDisplay);
  //     return;
  //   }
  
  //   console.log("Final dataToDisplay data is", dataToDisplay);
  
  //   // Call processData only when dataToDisplay is defined and valid
  //   await processData(dataToDisplay);
  
  // })();

  const handleDownload = () => {
    if (processedData.length === 0) {
      setAlert({
        severity: "warning",
        message: "No data available to export!",
      });
      return;
    }
  
    const extractText = (cellValue) => {
      if (Array.isArray(cellValue)) {
        return cellValue.join(", "); // Convert array to string
      } else if (typeof cellValue === "string") {
        return cellValue;
      } else if (React.isValidElement(cellValue)) {
        return cellValue.props.children;
      }
      return cellValue !== null && cellValue !== undefined ? cellValue.toString() : "";
    };
  
    const formattedUsers = processedData.map((user) => ({
      "Action": extractText(user.Action),
      "SLNo": extractText(user.SLNo || ""),
      "SourceType": extractText(user.SourceType),
      "SourceCode": extractText(user.SourceCode),
      "SourceCodeDescription": extractText(user.SourceCodeDescription || ""),
      "OurCode": extractText(user.OurCode),
      "OurCodeDescription": extractText(user.OurCodeDescription || ""),
      "RateType": extractText(user.RateType),
      "DataType": extractText(user.DataType || ""),
      "Ratio": extractText(user.Ratio),
      "MDType": extractText(user.MDType),
      
    }));



// Add second and third row
 // Add second and third row
 const secondRow = {
  "Action": "R","SLNo": "", "SourceType": "R","SourceCode": "R", "SourceCodeDescription": "",  "OurCode": "R", "OurCodeDescription": "", "RateType": "R", "DataType": "", "Ratio": "R", "MDType": "R",

};

const thirdRow = {
   "Action": "1","SLNo": "5", "SourceType": "10","SourceCode": "20", "SourceCodeDescription": "50", "OurCode": "20", "OurCodeDescription": "50", "RateType": "Dropdown", "DataType": "3", "Ratio": "Default", "MDType": "Dropdown",
};


// Insert second and third row at the beginning of the formatted data
const rowsToDownload = [secondRow, thirdRow, ...formattedUsers];

  const worksheet = XLSX.utils.json_to_sheet(rowsToDownload);
console.log("rowsToDownload data is",rowsToDownload);

  // Define border style
const border = {
top: { style: "thin", color: { rgb: "000000" } },
right: { style: "thin", color: { rgb: "000000" } },
bottom: { style: "thin", color: { rgb: "000000" } },
left: { style: "thin", color: { rgb: "000000" } },
};

// Apply borders to the first three rows
rowsToDownload.slice(0, 3).forEach((row, rowIdx) => {
  Object.keys(row).forEach((col, colIdx) => {
    const cellAddress = XLSX.utils.encode_cell({ r: rowIdx, c: colIdx });
    if (worksheet[cellAddress]) {
      worksheet[cellAddress].s = { border };
    }
  });
});


 // Additional cell styling for validation errors
  Object.keys(rowsToDownload[0]).forEach((col, colIdx) => {
    rowsToDownload.forEach((row, rowIdx) => {
      const cellAddress = XLSX.utils.encode_cell({ r: rowIdx + 1, c: colIdx });
      const cellValue = row[col];

      if (
        typeof cellValue === "string" && (
          cellValue.includes("Shouldn't be blank") 
          ||
          cellValue.includes("Either Address Section or Building, Street, and Area Section must be entered")
          // cellValue.includes("Max. allowed character")
        )
      ) {
        worksheet[cellAddress] = {
          v: cellValue,
          s: { font: { color: { rgb: "FF0000" } } },
        };
      } else if (Array.isArray(row[col])) {
        worksheet[cellAddress] = {
          v: extractText(row[col]),
          s: { font: { color: { rgb: "FF0000" } } },  // Apply red color for array values
        };
      }
      else {
        worksheet[cellAddress] = { v: cellValue };
      }
    });
  });

  // AutoFit column width
  worksheet["!cols"] = Object.keys(rowsToDownload[0]).map((col) => {
    const maxLength = Math.max(...rowsToDownload.map((row) => extractText(row[col]).length), col.length);
    return { wch: maxLength + 2 }; // Add extra space
  });


  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Source");
  XLSX.writeFile(workbook, "Source_Uploading_Data.xlsx");
};

  const existingButtons = AdminActionsConfig.screen7.map((button) => {
    let onClick;
    let title;

    if (button.icon === MdOutlineFileDownload) {
      onClick = handleDownload;
      title = 'Download File';
    } else {
      onClick = button.onClick || (() => {}); // Provide a default no-op function if onClick is undefined
      title = button.title || ''; // Use existing title or default to an empty string
    }

    return {
      ...button,
      onClick, // Reference the function without invoking it
      title, // Add the title property to the button configuration
    };
  });

  const extraButtons = [];

  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );

  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings
    .filter((col) => col.visible)
    .map((col) => ({
      ...col,
      width: col.width, // This should hold the updated width
    }));

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem('userDetail');
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || '',
          userId: userDetail.userId || '',
          clientId: userDetail.clientId || '',
          assigner: userDetail.username || '',
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log('Assignee ID:', assigneeData.id);
        console.log('Assigner:', assigneeData.assigner);
        console.log('Assigner clientId:', assigneeData.clientId);
        console.log('Assigner userId:', assigneeData.userId);
      } else {
        setAlert({
          severity: 'error',
          message: 'No user detail found in session storage.',
        });
      }
    } catch (error) {
      setAlert({
        severity: 'error',
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };

  const isRowValid = (row, index) => {
    console.log("Row Object in isRowValid:", row);
    const isActionValid = row.Action && ["A", "a"].includes(row.Action) && String(row.Action).length === 1;
    const isSLNoValid = row.SLNo === undefined || row.SLNo === '' || (!isNaN(row.SLNo) && String(row.SLNo).length <= 5);    // Validate required fields
    const isSourcetypeValid = row.SourceType && String(row.SourceType).length <= 10;
    const isSourceCodeValid = row.SourceCode && String(row.SourceCode).length <= 20;
    const isSourceCodeDescriptionValid = row.SourceCodeDescription && String(row.SourceCodeDescription).length <= 50;
    const isOurCodeValid = row.OurCode && String(row.OurCode).length <= 20;
    const isOurCodeDescriptionValid = row.OurCodeDescription && String(row.OurCodeDescription).length <= 50;
    const isRateTypeValid = row.RateType && ['SPT', 'FWD','SER','LON'].includes(row['RateType']);
    // const isDataTypeValid = !row.DataType || String(row.DataType).length <= 3;
    const isDataTypeValid = row.DataType && String(row.DataType).length <= 3;
    const isRatioValid = row.Ratio && String(row.Ratio) === '1:1';
    const isMDTypeValid = row.MDType && ['EXT', 'CHG','INT'].includes(row['MDType']);
  
    // Validate Bid and Ask fields
    
  
    // Collect errors
    let errors = [];
    if (!isActionValid) errors.push("Invalid Action");
    if (!isSLNoValid) errors.push("Invalid Serial No");
    if (!isSourcetypeValid) errors.push("Invalid Source Type )");
    if (!isSourceCodeValid) errors.push("Invalid Source Code");
    if (!isSourceCodeDescriptionValid) errors.push("Invalid  Source Code Description )");
    if (!isOurCodeValid) errors.push("Invalid Our Code )");
    if (!isOurCodeDescriptionValid) errors.push("Invalid  Our Code Description )");
    if (!isRateTypeValid) errors.push("Invalid RateType");
    if (!isDataTypeValid) errors.push("Invalid data Type )");
    if (!isRatioValid) errors.push("Invalid ratio");
    if (!isMDTypeValid) errors.push("Invalid Md Type");
  
  
    if (errors.length > 0) {
      console.log(`Row ${index + 1} is invalid:`, errors);
    }
  
    return (
      isRateTypeValid &&
      isActionValid &&
      isSourceCodeValid &&
      isSourceCodeDescriptionValid &&
      isOurCodeDescriptionValid &&
      isOurCodeValid &&
      isMDTypeValid &&
      isDataTypeValid &&
      isSLNoValid &&
      isRatioValid &&
      isSourcetypeValid 
    );
  };
  const processData = async (data) => {
    if (!Array.isArray(data)) {
      console.error("processData received invalid data:", data);
      return []; // Return an empty array to prevent further errors
    }
    console.log("processData received data:", data);
  
    return Promise.all(
      data.map(async (row) => {
        const isDuplicateUserId = data.some(
          (r, index) =>
            index !== data.indexOf(row) && // Exclude the current row
            r.SourceType === row.SourceType &&
            r.SourceCode === row.SourceCode &&
            r.OurCode === row.OurCode
        );
        console.log("Duplicate user id is ", isDuplicateUserId);
  
        // Check if the sourceCode exists in the database
        const sourceCodeExistsResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkexistingmdsource`,
          { source: row.SourceType }
        );
  
        const sourceCodeExists = sourceCodeExistsResponse?.exists;
  
        // Check for duplicate combination in the database
        const isDuplicateInDBResponse = await SourceServices.checkDuplicateSourcecode(
          `${process.env.REACT_APP_BASE_URL}/checkduplicatesource`,
          {
            source: row.SourceType,
            sourceCode: row.SourceCode,
            ourCode: row.OurCode,
          }
        );
  
        console.log("isDuplicateInDB API Response:", isDuplicateInDBResponse);
  
        // Extract the `isDuplicate` property from the response
        const isDuplicateInDB = isDuplicateInDBResponse?.isDuplicate || false;
        console.log("isDuplicateInDB:", isDuplicateInDB);
      // Validate all fields
      const hasNotError = [
        row.Action,
        row.SLNo,
        row.SourceType,
        row.SourceCode,
        row.SourceCodeDescription,
        row.OurCode,
        row.OurCodeDescription,
        row.RateType,
        row.DataType,
        row.Ratio,
        row.MDType,
      ].every((field, index) => {
        let isValid = true;
        let reason = "";
  
        switch (index) {
          case 0: 
          if (!field) {
            isValid = false;
            reason = "Action should not be blank.";
          } 
          else if (!['A', 'a'].includes(field)) {
            isValid = false;
            reason = "Action should be either 'A' or 'a'.";
          }
          else if (String(field).length > 1) { isValid = false; reason = "SLNo exceeds max length of 5."; }
          break;

          case 1: // SLNo
          if (row.SLNo !== undefined && row.SLNo !== '') {
              if (isNaN(row.SLNo)) {
                  isValid = false;
                  reason = "SLNo should be a valid number.";
              } else if (String(row.SLNo).length > 5) {
                  isValid = false;
                  reason = "SLNo exceeds max length of 5.";
              }
          }
          break;

          case 2: // SourceType
          if (!field) {
            isValid = false;
            reason = "SourceType should not be blank.";
          } else if (String(field).length > 10) {
            isValid = false;
            reason = "SourceType exceeds max length of 10.";
          }
          else if (!sourceCodeExists) {
            isValid = false;
            reason = "SourceType does not exist in the database.";
          }
          break;
          case 3: // SourceCode
          if (!field) {
            isValid = false;
            reason = "SourceCode should not be blank.";
          } else if (String(field).length > 20) {
            isValid = false;
            reason = "SourceCode exceeds max length of 20.";
          } else if (field && (isDuplicateUserId || isDuplicateInDB)) {
            isValid = false;
            reason = "Duplicate entry.";
          }
          break;
        
          case 4: // SourceType
          if (String(field).length > 50) {
            isValid = false;
            reason = "Source Code Description exceeds max length of 50.";
          } else if (!field) {
            isValid = false;
            reason = "SourceCode Description should not be blank.";
          }
          break;
          case 5: // SourceType
          if (!field) {
            isValid = false;
            reason = "OurCode should not be blank.";
          } else if (String(field).length > 20) {
            isValid = false;
            reason = "OurCode exceeds max length of 20.";
          }
          break;
          case 6: // SourceType
          if (String(field).length > 50) {
            isValid = false;
            reason = "Our Code Description exceeds max length of 50.";
          } else if (!field) {
            isValid = false;
            reason = "OurCode Description should not be blank.";
          }
          break;

          case 7: // RateType
            if (!field) {
              isValid = false;
              reason = "RateType should not be blank.";
            } else if (!['SPT', 'FWD', 'SER', 'LON'	].includes(field)) {
              isValid = false;
              reason = "RateType should be either 'SPT', 'FWD', 'SER', 'LON'.";
            }
            break;
          case 8: // SourceType
          if (field && String(field).length > 3) {
            isValid = false;
            reason = "Data Type exceeds max length of 3.";
        } else if (!field) {
          isValid = false;
          reason = "Data Type should not be blank.";
        }
        break;

          case 9: // Ratio
          if (!field) {
            isValid = false;
            reason = "Ratio should not be blank.";
          } else if (String(field) !== '1:1') {
            isValid = false;
            reason = "Ratio should be 1:1.";
          }
          break;
        

          case 10: // RateType
          if (!field) {
            isValid = false;
            reason = "MD Type should not be blank.";
          } else if (!['EXT', 'CHG', 'INT'].includes(field)) {
            isValid = false;
            reason = "MD Type should be either 'EXT', 'CHG', 'SER'.";
          }
          break;
  
          default:
            break;
        }
  
        if (!isValid) {
          console.log(`Validation Failed: ${reason}`);
        } else {
          console.log(`Validation Passed: ${field}`);
        }
  
        return isValid;
      });
  
      console.log(`Final hasNotError status: ${hasNotError}`);
  
      return {
        ...row,
        isDuplicateUserId,
        sourceCodeExists,
        successfulrecords: hasNotError, // Mark as successful if no error
  
        'Action': row['Action'] ? (
          ['A', 'a'].includes(row['Action']) ? (
            row['Action']
          ) : (
            <span style={{ color: 'red' }}>{row['Action']} (Invalid Action)</span>
          )
        ) : (
          <span style={{ color: 'red' }}> Shouldn't be blank</span>
        ),
        'SLNo': row.SLNo ? (
          !isNaN(row.SLNo) && String(row.SLNo).length <= 5 ? (
            row.SLNo
          ) : (
            <span style={{ color: 'red' }}>{row.SLNo} (Invalid SLNo)</span>
          )
        ) : (
          <span style={{ color: 'red' }}></span>
        ),
     
  'SourceType': row['SourceType'] ? (
  String(row['SourceType']).length <= 10 ? (
    sourceCodeExists ? ( // Check if sourceCode exists in the database
      row['SourceType']
    ) : (
      <span style={{ color: 'red' }}>{row.SourceType}, SourceType does not exist</span>
    )
  ) : (
    <span style={{ color: 'red' }}>{row['SourceType']} (Max. allowed character is 10)</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
)
,
  
     'SourceCode': row.SourceCode ? (
  String(row.SourceCode).length <= 20 ? (
    isDuplicateUserId || isDuplicateInDB ? ( // Check for duplication in dataset or database
      <span style={{ color: 'red' }}>{row.SourceCode}, Duplicate SourceCode</span>
    ) : (
      row.SourceCode // No error, return the SourceCode
    )
  ) : (
    <span style={{ color: 'red' }}>{row.SourceCode}, Max. allowed character is 20</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
),
'OurCode': row.OurCode ? (
  String(row.OurCode).length <= 20 ? (
    isDuplicateUserId ? ( // Check for duplication
      <span style={{ color: 'red' }}>{row.OurCode}, Duplicate OurCode</span>
    ) : (
      row.OurCode // No error, return the OurCode
    )
  ) : (
    <span style={{ color: 'red' }}>{row.OurCode}, Max. allowed character is 20</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
)
,
'SourceCodeDescription': row.SourceCodeDescription ? (
  String(row.SourceCodeDescription).length <= 50 ? (
    row.SourceCodeDescription
  ) : (
    <span style={{ color: 'red' }}>{row.SourceCodeDescription} (Max. allowed character is 50)</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
),
'OurCodeDescription': row.OurCodeDescription ? (
  String(row.OurCodeDescription).length <= 50 ? (
    row.OurCodeDescription
  ) : (
    <span style={{ color: 'red' }}>{row.OurCodeDescription} (Max. allowed character is 50)</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
),
'DataType': row.DataType ? (
  String(row.DataType).length <= 3 ? (
    row.DataType
  ) : (
    <span style={{ color: 'red' }}>{row.DataType} (Max. allowed character is 3)</span>
  )
) : (
  <span style={{ color: 'red' }}>Shouldn't be blank</span>
),
'RateType': row['RateType'] ? (
  ['SPT', 'FWD', 'SER', 'LON'].includes(row['RateType']) ? (
    row['RateType']
  ) : (
    <span style={{ color: 'red' }}>{row['RateType']} (Invalid Rate Type)</span>
  )
) : (
  <span style={{ color: 'red' }}> Shouldn't be blank</span>
),
'Ratio': row['Ratio'] ? (
  String(row['Ratio']) === '1:1' ? (
    row['Ratio']
  ) : (
    <span style={{ color: 'red' }}>{row['Ratio']} (Invalid Ratio)</span>
  )
) : (
  <span style={{ color: 'red' }}> Shouldn't be blank</span>
),

'MDType': row['MDType'] ? (
  ['EXT', 'CHG', 'INT'].includes(row['MDType']) ? (
    row['MDType']
  ) : (
    <span style={{ color: 'red' }}>{row['MDType']} (Invalid Rate Type)</span>
  )
) : (
  <span style={{ color: 'red' }}> Shouldn't be blank</span>
),
      };
    }));
  };
   

  // useEffect(() => {
      
    
  //     fetchData();
  //   }, []);

  //   const fetchData = async () => {
  //     setLoading(true);
  //     const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
  //     console.log("data to display is",dataToDisplay);

  //     const processed = await processData(dataToDisplay);
  //     console.log("Processed data is ",processed);

  //     setProcessedData(processed);
  //     setLoading(false);
  //   };

  useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        const dataToDisplay = await getDataToDisplay(displayOption, uploadOption, tableData);
        console.log("dataTo display is get",dataToDisplay);
        
        const processed = await processData(dataToDisplay);
        setProcessedData(processed);
        setLoading(false);
      };
    
      fetchData();
    }, []);

  return (
    <div>
      <Header
        title={`Primary Data > Market Data > Source > Source Upload > Display`}
        onBreadcrumbClick={handleBreadcrumbClick}
        backRoute="/2/master-data/14/source-upload"
      />
 {loading && (
          <div>
            <CustomeLoading />
          </div>
        )}

      <PrimaryActions icons={iconId} buttons={actionButtons} selectedCount={selectedRows.length} selectIds={selectedRows} />

      <ReTable
        data={processedData}
        columns={modifiedColumns} // Use modified columns based on visibility
        selectedRows={[]}
        onSelectRow={() => {}}
        onSelectAll={() => {}}
        onRowDoubleClick={() => {}}
        showCheckbox={false}
      />
    </div>
  );
}