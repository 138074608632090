import React, { useState, useEffect } from "react";
import Header from "../../../../../components/Header";
import PrimaryActions from "../../Components/ActionButton";
import {
  IoMdCheckmark,
  IoMdClose,
  IoMdCopy,
  IoMdCreate,
  IoMdRefresh,
  IoMdTime,
  IoMdTrash,
} from "react-icons/io";
import { IoAddCircleOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PrimaryActionsConfig } from "../../Components/ActionButtonConfig";
import CalendarServices from "../../../../../rest-services/CalendarServices";
import { useToast } from "../../../../../components/toast/toast";
import { Checkbox } from "@mui/material";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import * as XLSX from "xlsx";
import ColumnSettingsPopup from "../../Components/ColumnSettingPopup";
import ReusableTable from "../../Components/Table";
import CustomeUpLoader from "../../../../../components/CustomeLoader";
import ReTable from "../../../../../components/Table";
import SearchInput from "../../../../../components/SearchInput";
import BuGroupServices from "../../../../../rest-services/BuGroupServices";
import { TbDeviceDesktopCancel, TbFlag3Filled } from "react-icons/tb";
import { GiSettingsKnobs } from "react-icons/gi";

export default function BuGroup() {

  const [buGroupId, setBuGroupId] = useState(null); // To store the selected calendar ID
  const [showExtraIcons, setShowExtraIcons] = useState(false);
  const [buGroup, setBuGroup] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows by IDs
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAllSelected, setIsAllSelected] = useState(false); // Manage "Select All" checkbox state
  const [filteredBuGroup, setFilteredBuGroup] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Manage search input
  const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
  const { toast } = useToast();
  const [assignee, setAssignee] = useState({ id: "", assigner: "" }); // State to store assignee
  const [alert, setAlert] = useState(null);

  const columns = [
    { Header: <TbFlag3Filled style={{ color: "#ffff" }} />, 
    accessor: "status", 
    visible: true, 
    width: 200,
    Cell: ({ value }) => {
      switch (value) {
        case 3:
          return <TbFlag3Filled style={{ color: "#29CB00" }} />; // Green for status 3
        case 0:
          return <TbFlag3Filled style={{ color: "gray" }} />; // Gray for status 0
        case 1:
          return <TbFlag3Filled style={{ color: "yellow" }} />; // Yellow for status 1
        case 2:
          return <TbFlag3Filled style={{ color: "orange" }} />; // Orange for status 2
        case 4:
          return <TbFlag3Filled style={{ color: "red" }} />; // Red for status 4
        default:
          return <TbFlag3Filled style={{ color: "black" }} />; // Default black flag
      }
    },
  },
    { Header: "BuGroup", accessor: "BU_Group", visible: true, width: 200 },
  { Header: "BuGroup Descrition", accessor: "BU_Group_Description", visible: true, width: 200 },
  { Header: "Created By", accessor: "created_by", visible: true, width: 200 },
  {
    Header: "Created Date",
    accessor: "creationDate",
    visible: true,
    width: 15,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
    }
  },
  {
    Header: "Created Time",
    accessor: "creationTime", 
    visible: true,
    width: 15,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("en-GB", {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',

        hour12: true, 
      });
    }
  },
  { Header: "Updated By", accessor: "changed_by", visible: true, width: 15, canResize: true },
  {
    Header: "Updated Date",
    accessor: "changedDate",
    visible: true,
    width: 15,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`; // Change here
    }
  },
  {
    Header: "Updated Time",
    accessor: "changedTime", 
    visible: true,
    width: 15,
    canResize: true,
    Cell: ({ value }) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleTimeString("en-GB", {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',

        hour12: true, 
      });
    }
  },
  ];
  const [columnSettings, setColumnSettings] = useState(columns);

  const modifiedColumns = columnSettings.filter(col => col.visible).map(col => ({
    ...col,
    width: col.width // This should hold the updated width
  }));
  useEffect(() => {
    fetchBuGroups();
    fetchAssignee();
  }, []);
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredBuGroup(buGroup);
    } else {
      const filtered = buGroup.filter((bugroup) => 
        bugroup.BU_Group &&
        bugroup.BU_Group.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredBuGroup(filtered);
    }
  }, [searchQuery, buGroup]);
  const fetchBuGroups = async () => {
    setLoading(true);
    try {
      const response = await BuGroupServices.getAllBuGroup(
        `${process.env.REACT_APP_BASE_URL}/get-bugroup`
      );
      if (response && response.data) {
        setBuGroup(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Calendar data", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchAssignee = async () => {
    try {
      const userDetailString = sessionStorage.getItem("userDetail");
      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
        };
        setAssignee(assigneeData);
      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };
  const handleUpdateColumnSettings = (updatedSettings) => {
    setColumnSettings(updatedSettings);
  };

  const handleSettingsButtonClick = () => {
    setIsSettingsPopupOpen(true);
  };

  const handleSelectRow = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectAll = () => {
    handleSelectAllChange();
  };
  // Function to delete calendar

// Function to delete BuGroup(s)
const handleDelete = async () => {
  if (selectedRows.length === 0) return; // No rows selected to delete
  
  const confirmed = window.confirm("Are you sure you want to delete the selected BuGroup(s)?");
  if (confirmed) {
    setLoading(true);
    try {
      // Loop through each selected row ID and delete
      for (const id of selectedRows) {
        await BuGroupServices.deleteBuGroup(id);
      }
      // toast("success", "Selected BuGroup(s) deleted successfully");

      // Clear selection after deletion and refresh the list
      setSelectedRows([]);
      fetchBuGroups();
    } catch (error) {
      console.error("Error deleting BuGroup(s):", error);
      // toast("error", "Failed to delete BuGroup(s)");
    } finally {
      setLoading(false);
    }
  }
};
const handleApproveClick = async () => {
  if (selectedRows.length > 0) {
    try {
       // Assuming selectedRows contains the selected bank branch IDs
      
      // Pass the array of selected IDs to the approval service
      const response = await BuGroupServices.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/approvebugroup`,
        { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
      );
      
      console.log("Response status:", response);

      if (response.status === 200) {
        
        toast("success", "Branch successfully Approved");

        // Success logic, refresh the bank branches
         fetchBuGroups();
      }  else if (response.status === 400) {
        toast("warning", "Branch is unable to approve.");
       } 
       else if (response.status === 550) {
        toast("warning", "Branch Email is Not Sent.");
       } 
       else {
        console.log("Error updating Branchs.");
      }
    } catch (error) {
      console.error("Error approving bank branches:", error);
    }
  } else {
    console.log("Please select at least one bank branch to approve.");
  }
};

const handleRejectClick = async () => {
  if (selectedRows.length > 0) {
    try {
       // Assuming selectedRows contains the selected bank branch IDs
      
      // Pass the array of selected IDs to the approval service
      const response = await BuGroupServices.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/rejectbugroup`,
        { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
      );
      
      console.log("Response status:", response.status);

      if (response.status === 200) {
        toast("error", "Branch successfully Rejected");
        fetchBuGroups();
      }  else if (response.status === 400){
        toast("warning", "Branch is unable to Reject.");
      } else {
        console.log("Error updating Branchs.");
      }
    } catch (error) {
      console.error("Error approving bank branches:", error);
    }
  } else {
    console.log("Please select at least one bank branch to approve.");
  }
};
const handleInactiveClick = async () => {
  if (selectedRows.length > 0) {
    try {
       // Assuming selectedRows contains the selected bank branch IDs
      
      // Pass the array of selected IDs to the approval service
      const response = await BuGroupServices.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/inactivebugroup`,
        { id: selectedRows ,created_by: assignee.assigner }  // Pass selectedBankBranchIds in the body
      );
      
      console.log("Response status:", response);

      if (response.status ===  200) {
        toast("success", "Branch successfully Change Status.");
        fetchBuGroups();
      } 
      else if (response.status === 400){
        toast("warning", "The Branch cannot be deactivated at this time.");
      } else {
        console.log("Error approving bank branches.");
      }
    } catch (error) {
      console.error("Error approving bank branches:", error);
    }
  } else {
    console.log("Please select at least one bank branch to approve.");
  }
};
  // Extra buttons that appear when rows are selected
  const extraButtons = [
    {
      icon: IoMdCheckmark, // Pass the component itself
      onClick: handleApproveClick,
      title: "Approve",
      disabled: selectedRows.length === 0,
      
    },
    {
      icon: IoMdClose,
      onClick: handleRejectClick,
      title: "Reject",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdCreate,
      onClick: () => handleNavigate(`/bugroup-add/${buGroupId}`),
      title:"Edit BuGroup",
      disabled: selectedRows.length === 0, 
    },
 
    {
      icon: IoMdCopy,
      // onClick: handleAddcopyClick,
      title: "Copy",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTime,
      // onClick: handleAuditTrail,
      title: "Audit Trail",
      disabled: selectedRows.length === 0,
    },
    {
      icon: IoMdTrash,
      onClick: handleDelete,
      title: "Delete",
      disabled: selectedRows.length === 0,
    },
    {
      icon: TbDeviceDesktopCancel,
      onClick: handleInactiveClick,
      title: "Inactive/Active",
      disabled: selectedRows.length === 0,
    },
  ];
  const handleDownload = () => {
    // Create an array of data to download
    const dataToExport = filteredBuGroup.map((bugroup) => ({
      "BuGroup": bugroup.BU_Group,
      "BuGroup Description": bugroup.BU_Group_Description,
      "Created By": bugroup.created_by,
      "Created Date": bugroup.creationDate
        ? new Date(bugroup.creationDate).toLocaleDateString()
        : "",
      "Created Time": bugroup.creationTime
        ? new Date(bugroup.creationTime).toLocaleTimeString()
        : "",
      "Changed By": bugroup.changed_by,
      "Changed Date": bugroup.changedDate
        ? new Date(bugroup.changedDate).toLocaleDateString()
        : "",
      "Changed Time": bugroup.changedTime
        ? new Date(bugroup.changedTime).toLocaleTimeString()
        : "",
    }));
  
    // Create a new worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
    // Create a new workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "BuGroup");
  
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "BuGroup_Data.xlsx");
  };
  const handleReload = async () => {
    setLoading(true);  // Start loading
    try {
      await fetchBuGroups();  // Fetch users
    } catch (error) {
      console.error('Error reloading users:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };
  // Configuring existing buttons
  const existingButtons = PrimaryActionsConfig.screen2.map((button) => ({
    ...button,
    title:
      button.icon === IoAddCircleOutline
        ? "Add Country"
         : button.icon === MdOutlineFileDownload
        ? "Filter"
        : button.icon === GiSettingsKnobs
        ? "Download"
         : button.icon === MdOutlineFileUpload
        ? "Upload"
        : button.icon === IoSettingsOutline
        ? "Settings"
        : button.icon === IoMdRefresh
      ? "Refresh"
        : button.title|| "", 
    onClick:
      button.icon === IoAddCircleOutline
        ? () => handleNavigate("/bugroup-add")
        : button.icon === MdOutlineFileDownload 
        ? handleDownload
        : button.icon === IoSettingsOutline
        ? handleSettingsButtonClick
        : button.icon === IoMdRefresh
        ? handleReload
        : button.onClick || (() => {}),
  }));
  const actionButtons = existingButtons.concat(
    extraButtons.map((button) => ({
      ...button,
      disabled: selectedRows.length === 0, // Disable button if no users are selected
      onClick: selectedRows.length > 0 ? button.onClick : null, // Enable onClick only if users are selected
    }))
  );
  // Navigation helper function
  const handleNavigate = (path) => {
    navigate(path);
    
  };
  const handleDoubleClick = (id) => {
    navigate(`/bugroup-view/${id}`, { state: { mode: 'view' } });  };

  // Fetch calendars on component mount
 

  // Handle checkbox change (selection)
  const handleCheckboxChange = (id) => {
    const updatedSelectedRows = selectedRows.includes(id)
      ? selectedRows.filter((rowId) => rowId !== id) // Deselect if already selected
      : [...selectedRows, id]; // Add to selection if not selected

    setSelectedRows(updatedSelectedRows);
    setBuGroupId(id); // Set the selected calendar ID for navigation or further actions
    setShowExtraIcons(updatedSelectedRows.length > 0); // Show extra icons if any row is selected
  };
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows([]); // Deselect all
    } else {
      const allIds = buGroup.map((item) => item.id); // Select all IDs
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected); // Toggle select all state
  };
  if (loading) return <div></div>;
  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Bank&Company masterData") {
      navigate("/master-data");
    } else if (crumb.title === "Bu Group") {
      navigate("/bugroup");
    } else if (crumb.title === "Primary Data") {
      navigate("/master-data");
    } else if (crumb.path) {
      navigate(crumb.path); 
    }
  };
  return (
    <div>
   
      <Header title={"Primary Data > Bank&Company masterData > Bu Group"
      } onBreadcrumbClick={handleBreadcrumbClick}/>
     
      <div className="flex justify-between">
      <PrimaryActions
         buttons={actionButtons} 
         selectedCount={selectedRows.length} 
        isExtraButtonsVisible={showExtraIcons}
      />
      
        <div className="flex  items-center ">
        <p className="mr-4 text-customBlue">BuGroup :</p>

        <SearchInput 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery} 
            placeholder="Search by BuGroup" 
          />
</div>
        </div>
     <div>
        <ReTable
          data={filteredBuGroup}
          columns={modifiedColumns} // Use modified columns based on visibility
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          onSelectAll={handleSelectAll}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
      {isSettingsPopupOpen && (
        <ColumnSettingsPopup
          columns={columnSettings}
          onClose={() => setIsSettingsPopupOpen(false)}
          onUpdate={handleUpdateColumnSettings}
        />
      )}
      
    </div>
    
  );
}
