import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserService from "../../../../rest-services/UserServices";
import NoActionLayout from "../../../../components/NoActionLayout";
import { useMemo } from "react";
import Header from "../../../../components/Header";
import { TbFlag3Filled } from "react-icons/tb";
import BankBranchServices from "../../../../rest-services/BankBranchServices";
import LegalEntityServices from "../../../../rest-services/LegalEntityServices";
import BuGroupServices from "../../../../rest-services/BuGroupServices";
import BankIdServices from "../../../../rest-services/BankIdServices";
import CompanyServices from "../../../../rest-services/CompanyServices";
import { TableContainer } from "@mui/material";
import styled from "styled-components";
const TableWrapper = styled.div`
  max-height: 80vh;
  width: '100%';
  overflow: ${(props) => (props.disableScroll ? 'visible' : 'auto')};
  position: relative;
 
  /* For WebKit-based browsers (Chrome, Edge, Safari) */
  ::-webkit-scrollbar {
    width: 6px; /* Vertical scrollbar width */
    height: 6px; /* Horizontal scrollbar height */
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8aa6c1;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #6b8db2; /* Slightly darker for hover */
  }
  ::-webkit-scrollbar-track {
    background-color: #f0f4f8;
    border-radius: 6px;
  }
 
  /* For Firefox */
  scrollbar-width: thin; /* Applies to both horizontal and vertical */
  scrollbar-color: #8aa6c1 #f0f4f8; /* Thumb and track colors */
`;
export default function UserAuditTrail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { iconId } = useParams();
  const { UserId } = useParams();

  // const user = location.state?.user;
  const [approved, setApproved] = useState("");
  const [roleId, setRoleId] = useState("");

  const [auditData, setAuditData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [userId, setUserId] = useState("");
  const [bankbranch, setbankbranch] = useState("");
  const [lgeCode, setLgeCode] = useState("");
  const [bankid, setbankid] = useState("");
  const [bugroupCode, setbugroupCode] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [assignee, setAssignee] = useState({ id: "", username: "" });
 const [loading, setLoading] = useState(false);
 const [userPreference, setUserPreference] = useState("");
   const [error, setError] = useState(null);

  const { bankbranchId } = useParams(); // Assuming bankbranchId is passed in the URL
  const { bankidId } = useParams(); // Assuming bankbranchId is passed in the URL
  const { companyId } = useParams(); // Assuming bankbranchId is passed in the URL
  const { buGroupId } = useParams(); // Assuming bankbranchId is passed in the URL
  const { legalentityId } = useParams(); // Assuming bankbranchId is passed in the URL
  //
  useEffect(() => {
    fetchAssignee();
  }, []);

  const fetchAssignee = async () => {
    try {
      const userDetailString = localStorage.getItem("userDetail");

      if (userDetailString) {
        const userDetail = JSON.parse(userDetailString);
        const assigneeData = {
          id: userDetail.id || "",
          assigner: userDetail.username || "",
          assignerId: userDetail.userId || "",
          clientId: userDetail.clientId || "",
        };

        setAssignee(assigneeData);

        // Log the id and assigner values to the console
        console.log("Assignee ID:", assigneeData.id);
        console.log("Assigner:", assigneeData.assigner);
        console.log("Assigner userId:", assigneeData.assignerId);
        console.log("Assigner clientId:", assigneeData.clientId);

      } else {
        setAlert({
          severity: "error",
          message: "No user detail found in session storage.",
        });
      }
    } catch (error) {
      setAlert({
        severity: "error",
        message: `Error fetching user details: ${error.message}`,
      });
    }
  };


  useEffect(() => {
    if (assignee.id) {
      fetchUserPreferenceDetails();
    }
  }, [assignee.id]);

  const fetchUserPreferenceDetails = async () => {
    try {
      const data = { userId: assignee.assignerId }; // Assuming assignee.id is the user ID
      console.log("Requestfor user pref Data:", data);

      setLoading(true); // Set loading state
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-preference-details-by-Id`,
        data
      );
      console.log("user prefference details", response.data);
      setUserPreference(response.data); // Set user preference data
    } catch (error) {
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

const formatDate = (dateString, format) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const shortYear = String(year).slice(-2);

  const formatMap = {
      "DD/MM/YYYY": `${day}/${month}/${year}`,
      "MM/DD/YYYY": `${month}/${day}/${year}`,
      "DD/MM/YY": `${day}/${month}/${shortYear}`,
      "MM/DD/YY": `${month}/${day}/${shortYear}`,
      "DD.MM.YYYY": `${day}.${month}.${year}`,
      "DDMMYY": `${day}${month}${shortYear}`,
      "DD-MM-YYYY": `${day}-${month}-${year}`,
  };

  return formatMap[format] || dateString;
};
const dateFormat = userPreference?.dateFormat || "DD-MM-YYYY"; // Default format



  useEffect(() => {
    if (bankbranchId) {
      fetchBankBranchById(bankbranchId);
    }
  }, [bankbranchId]);

  const fetchBankBranchById = async (bankbranchId) => {
    try {
      // Assuming you have an endpoint to fetch the bank branch data by ID
      const response = await BankBranchServices.getAllBankBranch(
        `${process.env.REACT_APP_BASE_URL}/get-bankbranch/${bankbranchId}`
      );

      if (response.data && response.data.bankBranch) {
        // Extract bankBranch value from the response and pass it as md_code
        const { bankBranch } = response.data;
        setApproved(response.data.status || "");
        setbankbranch(response.data.bankBranch);
        fetchAuditTrailbranch(bankBranch); // Pass bankBranch as md_code
      } else {
        setAlert({
          severity: "warning",
          message: "Bank branch not found.",
        });
      }
    } catch (error) {
      console.error("Error fetching bank branch details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching bank branch details.",
      });
    }
  };

  const fetchAuditTrailbranch = async (bankBranch) => {
    try {
      const data = { md_code: bankBranch, iconId }; // Sending bankBranch as md_code to the backend
      console.log("Fetching audit trail for bankBranch: ", bankBranch);

      const response = await BankBranchServices.getAudittrail(
        `/getaudittrail`,
        data
      );
      console.log("reponseeee", response);
      if (response.auditRecords && response.auditRecords.length > 0) {
        setAuditData(response.auditRecords);
        console.log("kiiiii", auditData); // Set audit data if found
      } else {
        setAlert({
          severity: "warning",
          message: "No audit trail data found for this bank branch.",
        });
      }
    } catch (error) {
      console.error("Error fetching audit trail details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching audit trail details.",
      });
    }
  };

  useEffect(() => {
    if (legalentityId) {
      fetchlgeById(legalentityId);
    }
  }, [legalentityId]);

  const fetchlgeById = async (legalEntityId) => {
    try {
      // Assuming you have an endpoint to fetch the bank branch data by ID
      const response = await LegalEntityServices.getAllLegalEntity(
        `${process.env.REACT_APP_BASE_URL}/get-legal-entity/${legalEntityId}`
      );

      if (response.data && response.data.LGE_Code) {
        // Extract bankBranch value from the response and pass it as md_code
        const { LGE_Code } = response.data;
        setApproved(response.data.status || "");
        setLgeCode(response.data.LGE_Code);
        fetchAuditTraillge(LGE_Code); // Pass bankBranch as md_code
      } else {
        setAlert({
          severity: "warning",
          message: "lge not found.",
        });
      }
    } catch (error) {
      console.error("Error fetching lge details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching lge details.",
      });
    }
  };

  const fetchAuditTraillge = async (LGE_Code) => {
    try {
      const data = { md_code: LGE_Code, iconId }; // Sending bankBranch as md_code to the backend
      console.log("Fetching audit trail for bankBranch: ", LGE_Code);

      const response = await LegalEntityServices.getAudittrail(
        `/getaudittraillge`,
        data
      );
      console.log("reponseeee", response);
      if (response.auditRecords && response.auditRecords.length > 0) {
        setAuditData(response.auditRecords);
      } else {
        setAlert({
          severity: "warning",
          message: "No audit trail data found for this lge.",
        });
      }
    } catch (error) {
      console.error("Error fetching audit trail details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching audit trail details.",
      });
    }
  };

  useEffect(() => {
    if (buGroupId) {
      fetchbugroupById(buGroupId);
    }
  }, [buGroupId]);

  const fetchbugroupById = async (buGroupId) => {
    try {
      console.log("hiii");
      const clientId = localStorage.getItem("clientId");
      // Assuming you have an endpoint to fetch the bank branch data by ID
      const response = await BuGroupServices.getAllBuGroup(
        `/get-bugroup-id/${buGroupId}?clientId=${clientId}`
      );

      if (response.data.data && response.data.data.BU_Group) {
        // Extract bankBranch value from the response and pass it as md_code
        const { BU_Group } = response.data.data;
        setApproved(response.data.data.status || "");
        setbugroupCode(response.data.data.BU_Group);
        fetchAuditTrailbugroup(BU_Group); // Pass bankBranch as md_code
      } else {
        setAlert({
          severity: "warning",
          message: "bugroup not found.",
        });
      }
    } catch (error) {
      console.error("Error fetching bugroup details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching lge details.",
      });
    }
  };

  const fetchAuditTrailbugroup = async (BU_Group) => {
    try {
      // const clientId = localStorage.getItem("clientId");

      const data = { md_code: BU_Group, iconId }; // Sending bankBranch as md_code to the backend
      console.log("Fetching audit trail for bankBranch: ", BU_Group);

      const response = await BuGroupServices.getAudittrail(
        `/getaudittrailbugroup`,
        data
      );
      console.log("reponseeee", response);
      if (response.auditRecords && response.auditRecords.length > 0) {
        setAuditData(response.auditRecords);
        console.log("kiiiii", auditData); // Set audit data if found
      } else {
        setAlert({
          severity: "warning",
          message: "No audit trail data found for this lge.",
        });
      }
    } catch (error) {
      console.error("Error fetching audit trail details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching audit trail details.",
      });
    }
  };

  const fetchRolesAndPermissionDetails = async (UserId) => {
    try {
      // const userId = UserId.map((id) => ({ id }));
      // console.log("assignees data", assignee);
      const data = { userId: UserId };
      console.log("Request Data for users permissions:", data);

      // const response = await UserService.getUsers(`${process.env.REACT_APP_BASE_URL}/get-all-users-permissions`);
      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-users-permissions-by-userId`,
        data
      );
      console.log("Response from server fetching roles:", response);
      const responseData = response.data;
      console.log("set only roles details", responseData);
      setApproved(responseData.status);
      setUserId(responseData.userId);
      if (responseData.error) {
        console.error("Error fetching roles data:", responseData.message);
        setAlert({ severity: "error", message: responseData.message });
        return;
      }
    } catch (error) {
      console.error("Error fetching roles data", error);
      setAlert({
        severity: "error",
        message: `Error fetching roles data, kindly check the connection ${error.message}`,
      });
    }
  };


  const fetchRoleById = async (UserId) => {
    try {
      // Assuming you have an endpoint to fetch the bank branch data by ID
      const response = await CompanyServices.saveapproved(
        `${process.env.REACT_APP_BASE_URL}/get-role-by-roleId`, { roleId: UserId }
      );
      console.log("response from backend for geting role id", response);
      if (response.data) {
        // Extract bankBranch value from the response and pass it as md_code

        setApproved(response.data.status || "");
        setRoleId(response.data.roleId || "");
      } else {
        setAlert({
          severity: "warning",
          message: "company not found.",
        });
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching company details.",
      });
    }
  }

  useEffect(() => {
    if (companyId) {
      fetchcompanyById(companyId);
    }
  }, [companyId]);

  const fetchcompanyById = async (companyId) => {
    try {
      // Assuming you have an endpoint to fetch the bank branch data by ID
      const response = await CompanyServices.getAllCompany(
        `${process.env.REACT_APP_BASE_URL}/get-company/${companyId}`
      );

      if (response.data && response.data.companyCode) {
        // Extract bankBranch value from the response and pass it as md_code
        const { companyCode } = response.data;
        setApproved(response.data.status || "");
        setCompanyCode(response.data.companyCode);
        fetchAuditTrailcompany(companyCode); // Pass bankBranch as md_code
      } else {
        setAlert({
          severity: "warning",
          message: "company not found.",
        });
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching company details.",
      });
    }
  };

  const fetchAuditTrailcompany = async (companyCode) => {
    try {
      const data = { md_code: companyCode, iconId }; // Sending bankBranch as md_code to the backend
      console.log("Fetching audit trail for bankBranch: ", companyCode);

      const response = await CompanyServices.getAudittrail(
        `/getaudittrailcompany`,
        data
      );
      console.log("reponseeee", response);
      if (response.auditRecords && response.auditRecords.length > 0) {
        setAuditData(response.auditRecords);
        console.log("kiiiii", auditData); // Set audit data if found
      } else {
        setAlert({
          severity: "warning",
          message: "No audit trail data found for this lge.",
        });
      }
    } catch (error) {
      console.error("Error fetching audit trail details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching audit trail details.",
      });
    }
  };

  useEffect(() => {
    if (bankidId) {
      fetchbankidById(bankidId);
    }
  }, [bankidId]);

  const fetchbankidById = async (bankidId) => {
    try {
      // Assuming you have an endpoint to fetch the bank branch data by ID
      const response = await BankIdServices.getAllBankId(
        `${process.env.REACT_APP_BASE_URL}/get-bankid/${bankidId}`
      );

      if (response.data && response.data.bankID) {
        // Extract bankBranch value from the response and pass it as md_code
        const { bankID } = response.data;
        setApproved(response.data.status || "");
        setbankid(response.data.bankID);
        fetchAuditTrailbankid(bankID); // Pass bankBranch as md_code
      } else {
        setAlert({
          severity: "warning",
          message: "bankid not found.",
        });
      }
    } catch (error) {
      console.error("Error fetching bankid details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching bankid details.",
      });
    }
  };

  const fetchAuditTrailbankid = async (bankID) => {
    try {
      const data = { md_code: bankID, iconId }; // Sending bankBranch as md_code to the backend
      console.log("Fetching audit trail for bankBranch: ", bankID);

      const response = await BankIdServices.getAudittrail(
        `/getaudittrailbankid`,
        data
      );
      console.log("reponseeee", response);
      if (response.auditRecords && response.auditRecords.length > 0) {
        setAuditData(response.auditRecords);
        console.log("kiiiii", auditData); // Set audit data if found
      } else {
        setAlert({
          severity: "warning",
          message: "No audit trail data found for this lge.",
        });
      }
    } catch (error) {
      console.error("Error fetching audit trail details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching audit trail details.",
      });
    }
  };

  // useEffect(() => {
  //   if (iconId == '111') {
  //     if(UserId)
  //     fetchRoleById(UserId);
  //   }
  // }, [UserId]);

  // useEffect(() => {
  //   if (userId) {
  //     fetcheditUserDetails();
  //   }
  // }, [userId]);

  useEffect(() => {
    if (iconId == '111') {
      if (UserId) {
        fetchRoleById(UserId);
      }
    }
    else if (iconId == '112') {
      if (UserId) {
        fetchRolesAndPermissionDetails(UserId);
      }
    }
    if (UserId) {
      console.log("User id in audit trail ",UserId);
      setUserId(UserId);
      fetchAuditTrailDetails();
      fetcheditUserDetails();
    }
  }, [UserId]);

  const fetchAuditTrailDetails = async () => {
    try {
      console.log("user Id:", { userId: UserId });
      const data = { userId: UserId, iconId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-audit-trail-details`,
        data
      );

      const validAmountFormatMap = {
        "1": "1,234,567.89",
        "2": "1.234.567,890",
        "3": "12,34,567.890"
      };
      
      const mapAmountFormat = (value) => validAmountFormatMap[value] || value;

      console.log("user Audit trail detail Response:", response);
      console.log("Response audit trail Data:", response.data);

      if (response.data && response.data.length > 0) {
        const mappedAuditData = response.data.map((entry) => {
          if (entry.auditTrailEdits) {
            entry.auditTrailEdits = entry.auditTrailEdits.map((edit) => ({
              ...edit,
              old_value: edit.field_name === "amountFormat" ? mapAmountFormat(edit.old_value) : edit.old_value,
              new_value: edit.field_name === "amountFormat" ? mapAmountFormat(edit.new_value) : edit.new_value
            }));
          }
          return entry;
        });
      
        setAuditData(mappedAuditData);
      } else {
        setAlert({
          severity: "warning",
          message: "No audit trail data found in response.",
        });
      }
    } catch (error) {
      console.error("Error fetching audit trail details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching audit trail details.",
      });
    }
  };

  const groupAuditDataByFieldAndTime = (auditData) => {
    return auditData.reduce((acc, audit) => {
      const { field_name, date, time } = audit;
      const dateTimeGroup = `${new Date(date).toLocaleDateString()} ${time}`; // Group by date and time

      if (!acc[dateTimeGroup]) {
        acc[dateTimeGroup] = {};
      }
      if (!acc[dateTimeGroup][field_name]) {
        acc[dateTimeGroup][field_name] = [];
      }

      acc[dateTimeGroup][field_name].push(audit);
      return acc;
    }, {});
  };
  const groupedAuditData = useMemo(
    () => groupAuditDataByFieldAndTime(auditData),
    [auditData]
  );

  const handleBreadcrumbClick = (crumb) => {
    console.log("Breadcrumb clicked:", crumb.title);
    if (crumb.title === "Admin") {
      console.log("Navigating to /Admin");
      navigate("/14/admin");
    } else if (crumb.title === "User & SOD") {
      console.log("Navigating to /User & SOD");
      navigate("/14/admin");
    } else if (crumb.title === "Roles") {
      console.log("Navigating to /Roles");
      navigate("/14/admin/111/roles-and-permission/roles");
    } else if (crumb.title === "Permissions") {
      console.log("Navigating to /Permissions");
      navigate("/14/admin/112/roles-and-permission");
    } else if (crumb.title === "User") {
      navigate("/14/admin/108/userscreen");
    } else if (crumb.title === "Primary Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "Company & Bank Data") {
      navigate("/2/master-data");
    } else if (crumb.title === "BankBranch") {
      navigate("/2/master-data/11/bankbranch");
    } else if (crumb.title === "Audit Trail") {
      navigate(`/user-audit-trail-screen/${userId}`);
    } else if (crumb.title === "LegalEntity") {
      navigate("/2/master-data/7/legalentity");
    } else if (crumb.title === "Company") {
      navigate("/2/master-data/9/company");
    } else if (crumb.title === "Bu Group") {
      navigate("/2/master-data/8/bugroup");
    } else if (crumb.title === "Bank ID") {
      navigate("/2/master-data/10/bankidscreen");
    }
    // else if (crumb.path) {
    //   navigate(crumb.path); // Navigate to the path specified in the crumb
    // }
  };



  const fetcheditUserDetails = async () => {
    try {
      const data = { userId: UserId };
      console.log("Request Data:", data);

      const response = await UserService.saveContactDetails(
        `${process.env.REACT_APP_BASE_URL}/get-edit-user-details`,
        data
      );

      console.log("API Response:", response);
      console.log("Response ffetch users Data:", response.data);

      if (response.data) {
        // Update state with fetched address details

        setApproved(response.data.approved || "");
        //   setSalute(response.data.salute || '');
        //   setUserType(response.data.userType || '');
        //   setAccountingSystemId(response.data.accountingSystemId || '');
        //   setSurname(response.data.surname || '');
        //   setFirstName(response.data.firstName || '');
        //   setLastName(response.data.lastName || '');
        //   setMiddleName(response.data.middleName || '');
        //   setEmail(response.data.email || '');
        //   setMobileNo(response.data.mobileNo || '');
        //   setWhatsappNo(response.data.whatsappNo || '');
        //   setOneTimePassword(response.data.oneTimePassword || '');
        //   setOfficeNo(response.data.officeNo || '');
        //   setExtension(response.data.extension || '');
        //   setNationality(response.data.nationality || '');
        //   setPassportNo(response.data.passportNo || '');
        //   setDrivingLicenseNo(response.data.drivingLicenseNo || '');
        //   setLocalId(response.data.localId || '');
        //   setemployeeNo(response.data.employeeNo || '');
        //   setcompanyCodeId(response.data.companyCodeId || '');
        //   setdepartment(response.data.department || '');
        //   setvalidFrom(response.data.validFrom || '');
        //   setvalidTo(response.data.validTo || '');

        //   setresignationDate(response.data.resignationDate || '');
        //   setjoiningDate(response.data.joiningDate || '');
        //   setLoginMethod(response.data.loginMethod || '');
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
      setAlert({
        severity: "error",
        message: "An error occurred while fetching address details.",
      });
    }
  };

  const getApprovedIcon = () => {
    let color = "black"; // Default color
    // Determine the color based on the approved status
    if (approved == 4) {
      color = "#29CB00"; // Green
    } else if (approved == 0) {
      color = "gray"; // Gray
    } else if (approved == 1) {
      color = "#AF52DE"; //Purple
    } else if (approved === 2) {
      color = "yellow"; // Yellow
    } else if (approved == 3) {
      color = "orange"; // Orange
    } else if (approved == 5) {
      color = "red"; // Red
    }
 
     return <TbFlag3Filled style={{ color, marginLeft: '8px' }} />; // Return the icon with the determined color
   };

  const extraContent =
    iconId === "108"
      ? getApprovedIcon()
      : iconId === "111"
        ? getApprovedIcon()
        : iconId === "112"
          ? getApprovedIcon()
          : iconId === "11"
            ? getApprovedIcon()
            : iconId === "7"
              ? getApprovedIcon()
              : iconId === "8"
                ? getApprovedIcon()
                : iconId === "9"
                  ? getApprovedIcon()
                  : iconId === "10"
                    ? getApprovedIcon()
                    : null;

  return (
    <div>
      {/* <NoActionLayout title={"Admin > User & SOD > User > Audit Trail"} /> */}
      {/* <Header title={`Admin > User & SOD > User > Audit Trail > ${userId} ` } */}
      <Header
        title={` ${iconId === "111"
            ? `Admin > User & SOD > Roles > Audit Trail > ${roleId}`
            : iconId === "112"
              ? `Admin > User & SOD > Permissions > Audit Trail > ${userId}`
              : iconId === "7"
                ? `Primary Data > Company & Bank Data > LegalEntity > Audit Trail > ${lgeCode}`
                : iconId === "8"
                  ? `Primary Data > Company & Bank Data > Bu Group > Audit Trail > ${bugroupCode}`
                  : iconId === "9"
                    ? `Primary Data > Company & Bank Data > Company > Audit Trail > ${companyCode}`
                    : iconId === "10"
                      ? `Primary Data > Company & Bank Data > Bank ID > Audit Trail > ${bankid}`
                      : iconId === "11"
                        ? `Primary Data > Company & Bank Data > Bank Branch > Audit Trail > ${bankbranch}`
                        : `Admin > User & SOD > User > Audit Trail > ${userId}`
          }`}
        onBreadcrumbClick={handleBreadcrumbClick}
        // extraContent={getApprovedIcon()}
        extraContent={extraContent}
      />

      <div className="p-4">
      <TableWrapper>
        <div className="overflow-x-auto" style={{ maxWidth: "1600px" }}>
          <div className="overflow-y-auto" style={{ maxHeight: "720px" }}>
            {auditData && auditData.length > 0 ? (
              <table className="table-fixed min-w-[800px] bg-white border border-customBlue">
                <thead className="bg-auditBlue text-primary-foreground text-left text-sm font-medium sticky top-0">
                  <tr className="bg-primary">
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      S.No
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Module
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Icon
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Action
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      MD Code
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Name
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Field Name
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Old Value
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      New Value
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Performed By
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      User Type
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium border-r border-white whitespace-nowrap">
                      Date
                    </th>
                    <th className="py-2 px-4 text-customGray font-medium whitespace-nowrap">
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {auditData.map((audit, index) => (
                    <>
                      {/* Main audit row */}
                      <tr
                        key={index}
                        className={` ${index % 2 === 0 ? "bg-[#FFFFFF]" : "bg-sky-50"
                          }`}
                      >
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.module}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.iconDetails.name}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.action}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.md_code}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.name}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.auditTrailEdits[0]?.field_name || "-"}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.auditTrailEdits[0]?.old_value || "-"}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.auditTrailEdits[0]?.new_value || "-"}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.performed_by}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {audit.performedByUser &&
                            audit.performedByUser.userTypeDetails
                            ? audit.performedByUser.userTypeDetails.name
                            : "N/A"}
                        </td>
                        {/* <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                          {new Date(audit.date).toLocaleDateString()
                            .split('/')
                            .join('.')}

                        </td> */}
                        <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {formatDate(audit.date, dateFormat)}
                        </td>
                        <td className="py-2 px-4 text-customGray text-sm font-small  whitespace-nowrap">
                        {audit.time
                            ? new Date(audit.time).toLocaleTimeString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              })
                            : ""}
                        </td>
                      </tr>

                      {/* Rows for each auditTrailEdit */}
                      {audit.auditTrailEdits.slice(1).map((edit, editIndex) => (
                        <tr
                          key={`${index}-${editIndex}`}
                          className={` ${index % 2 === 0 ? "bg-[#FFFFFF]" : "bg-sky-50"
                            }`}
                        >
                          {/* <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap"></td> */}
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.module}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.iconDetails.name}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.action}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.md_code}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.name}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {edit.field_name}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {edit.old_value}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {edit.new_value}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.performed_by}
                          </td>
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {audit.performedByUser &&
                              audit.performedByUser.userTypeDetails
                              ? audit.performedByUser.userTypeDetails.name
                              : "N/A"}
                          </td>
                          {/* <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                            {new Date(audit.date).toLocaleDateString()
                              .split('/')
                              .join('.')}
                          </td> */}
                          <td className="py-2 px-4 text-customGray text-sm font-small border-r border-customTableBorder whitespace-nowrap">
                              {formatDate(audit.date, dateFormat)}
                          </td>

                          <td className="py-2 px-4 text-customGray text-sm font-small  whitespace-nowrap">
                          {audit.time
                            ? new Date(audit.time).toLocaleTimeString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              })
                            : ""}
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No audit trail data available.</p>
            )}
          </div>
       
       
        </div>
        </TableWrapper>
      </div>
    </div>
  );
}
