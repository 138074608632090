import { Checkbox } from '@mui/material';
import React from 'react'

export default function RolesComponent(
    expandedIcon,
    allExpanded,
    icon,
    userType,
    permissionIcons,
    selectedPermissions,
    handlePermissionChange,
    isViewMode,
    selectedIcons,
    iconMapping,
) {
  return (
    <div>
      {allExpanded || expandedIcon.includes(icon.id) ? (
                                          <div className="px-4 py-2 grid grid-cols-4 gap-4 items-center bg-white rounded">
                                            {permissionIcons.map((permission) => {
                                              const isDisabled =
                                              userType == 1 &&
                                              (
                                                (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 25].includes(permission.id)) ||
                                                (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
      
                                                (icon.id === 44 && [17, 23].includes(permission.id)) ||
                                                (icon.id === 45 && [1, 2, 4, 13, 17,23].includes(permission.id)) ||
      
                                                (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                (icon.id === 110 && permission.id === 23) ||
                                                (icon.id === 111 && [1, 2, 4, 13, 10, 17, 18].includes(permission.id)) ||
                                                (icon.id === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 25].includes(permission.id)) ||
                                                (icon.id === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                (icon.id === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                (icon.id === 115 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id))
      
                                              ) ||
                                              //  usertype SuperUser
                                              (userType == 2 &&
                                                ((icon.id === 114 && [2].includes(permission.id)) ||
                                                  (icon.id === 115 && [2].includes(permission.id))
                                                )
                                              ) ||
                                              //  usertype Approver
                                              (userType == 3 &&
                                                (
                                                  // Country
                                                  (icon.id === 1 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // State provice
                                                  (icon.id === 2 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Calender
                                                  (icon.id === 3 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Currency
                                                  (icon.id === 4 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Bank
                                                  (icon.id === 5 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Language
                                                  (icon.id === 6 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Legal entity
                                                  (icon.id === 7 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Bu group
                                                  (icon.id === 8 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Company
                                                  (icon.id === 9 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Bank Id
                                                  (icon.id === 10 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Branch
                                                  (icon.id === 11 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  (icon.id === 12 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  (icon.id === 13 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  (icon.id === 14 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  (icon.id === 15 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Account status
                                                  (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                  // Account no logic
                                                  (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                  // ZBA type
                                                  (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // DB area
                                                  (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Account types
                                                  (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Bank account master
                                                  (icon.id === 26 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  // Statement controls
                                                  (icon.id === 44 && [17, 23].includes(permission.id)) ||
                                                  // Statement format
                                                  (icon.id === 45 && [1, 2, 4, 13, 17, 23].includes(permission.id)) ||
                                                  // Statement identifier
                                                  (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Mt940 uploads
                                                  (icon.id === 47 && [17, 23].includes(permission.id)) ||
                                                  // BTC
                                                  (icon.id === 48 && [1, 2, 10, 17, 23].includes(permission.id)) ||
                                                  // Statement analyser
                                                  (icon.id === 49 && [3, 17, 18].includes(permission.id)) ||
                                                  // Statement analyser 2
                                                  (icon.id === 50 && [3, 17, 18].includes(permission.id)) ||
      
                                                  // User Icon
                                                  (icon.id === 108 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Key data Icon
                                                  (icon.id === 109 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Maker and checker Icons
                                                  (icon.id === 110 &&  [17, 23].includes(permission.id)) ||
                                                  // Roles Icon
                                                  (icon.id === 111 && [1, 2, 4, 13, 10, 17, 18].includes(permission.id)) ||
                                                  // Permission Icon
                                                  (icon.id === 112 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 25].includes(permission.id)) ||
      
                                                  (icon.id === 113 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                  (icon.id === 114 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                  (icon.id === 115 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id)))
      
                                              ) ||
                                              //  usertype Admin
                                              (userType == 4 &&
                                                (
                                                  // Country
                                                  (icon.id === 1 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // State provice
                                                  (icon.id === 2 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Calender
                                                  (icon.id === 3 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Currency
                                                  (icon.id === 4 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Bank
                                                  (icon.id === 5 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Language
                                                  (icon.id === 6 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Legal entity
                                                  (icon.id === 7 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Bu group
                                                  (icon.id === 8 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Company
                                                  (icon.id === 9 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Bank Id
                                                  (icon.id === 10 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Branch
                                                  (icon.id === 11 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  (icon.id === 12 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  (icon.id === 13 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  (icon.id === 14 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  (icon.id === 15 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Account status
                                                  (icon.id === 21 && [1, 2, 4, 8, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                  // Account no logic
                                                  (icon.id === 22 && [1, 2, 4, 8, 10, 11, 12, 13, 17, 18, 23].includes(permission.id)) ||
                                                  // ZBA type
                                                  (icon.id === 23 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // DB area
                                                  (icon.id === 24 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Account types
                                                  (icon.id === 25 && [1, 2, 4, 8, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Bank account master
                                                  (icon.id === 26 && [1, 2, 4, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  // Statement controls
                                                  (icon.id === 44 && [17, 23].includes(permission.id)) ||
                                                  // Statement format
                                                  (icon.id === 45 && [1, 2, 4, 13, 17, 23].includes(permission.id)) ||
                                                  // Statement identifier
                                                  (icon.id === 46 && [1, 2, 4, 8, 9, 11, 12, 13, 17, 18].includes(permission.id)) ||
                                                  // Mt940 uploads
                                                  (icon.id === 47 && [17, 23].includes(permission.id)) ||
                                                  // BTC
                                                  (icon.id === 48 && [1, 2, 17, 23].includes(permission.id)) ||
                                                  // Statement analyser
                                                  (icon.id === 49 && [3, 17, 18].includes(permission.id)) ||
                                                  // Statement analyser 2
                                                  (icon.id === 50 && [3, 17, 18].includes(permission.id)) ||
                                                  // User Type
                                                  (icon.id === 115 && [2].includes(permission.id)) ||
                                                  // module assignment
                                                  (icon.id === 114 && [2].includes(permission.id))
      
                                                )
                                              ) ||
                                              //  usertype System
                                              (userType == 7 &&
                                                ((icon.id === 114 && [2].includes(permission.id)) ||
                                                  (icon.id === 115 && [2].includes(permission.id))
                                                )
                                              ) ||
                                              //  usertype Kickstart
                                              (userType == 8 &&
                                                (
                                                  // Country
                                                  (icon.id === 1 && [4, 5, 6, 7, 8, 11, 12, 13, 15].includes(permission.id)) ||
                                                  // State provice
                                                  (icon.id === 2 && [4, 5, 6, 7, 8, 11, 12, 13, 15].includes(permission.id)) ||
                                                  // Calender
                                                  (icon.id === 3 && [4, 5, 6, 7, 8, 11, 12, 13, 15].includes(permission.id)) ||
                                                  // Currency
                                                  (icon.id === 4 && [4, 5, 6, 7, 8, 11, 12, 13, 15].includes(permission.id)) ||
                                                  // Bank
                                                  (icon.id === 5 && [4, 5, 6, 7, 8, 11, 12, 13, 15].includes(permission.id)) ||
                                                  // Language
                                                  (icon.id === 6 && [4, 5, 6, 7, 8, 11, 12, 13, 15].includes(permission.id)) ||
                                                  // Legal entity
                                                  (icon.id === 7 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Bu group
                                                  (icon.id === 8 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Company
                                                  (icon.id === 9 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Bank Id
                                                  (icon.id === 10 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Branch
                                                  (icon.id === 11 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // MD Source
                                                  (icon.id === 12 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Source
                                                  (icon.id === 13 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Currency rate
                                                  (icon.id === 14 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Uploads
                                                  (icon.id === 15 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Account status
                                                  (icon.id === 21 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23].includes(permission.id)) ||
                                                  // Account no logic
                                                  (icon.id === 22 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23].includes(permission.id)) ||
                                                  // ZBA type
                                                  (icon.id === 23 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // DB area
                                                  (icon.id === 24 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Account types
                                                  (icon.id === 25 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Bank account master
                                                  (icon.id === 26 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  // Statement controls
                                                  (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23].includes(permission.id)) ||
                                                  // Statement format
                                                  (icon.id === 45 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23].includes(permission.id)) ||
                                                  // Statement identifier
                                                  (icon.id === 46 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23].includes(permission.id)) ||
                                                  // Mt940 uploads
                                                  (icon.id === 47 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23].includes(permission.id)) ||
                                                  // BTC
                                                  (icon.id === 48 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23].includes(permission.id)) ||
                                                  // Statement analyser
                                                  (icon.id === 49 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Statement analyser 2
                                                  (icon.id === 50 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
      
                                                  // Key data Icon
                                                  (icon.id === 109 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Maker and checker Icons
                                                  (icon.id === 110 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23].includes(permission.id)) ||
                                                  // Roles Icon
                                                  (icon.id === 111 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) ||
                                                  // Task Monitor
                                                  (icon.id === 134 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].includes(permission.id)) 
      
                                                )
                                              )
                                              ;
      
                                              const isChecked = !isDisabled && (selectedPermissions[icon.id]?.[permission.id] || false);
      
                                              const handleCheckboxChange = () => {
                                                if (!isDisabled) {
                                                  handlePermissionChange(icon.id, permission.id, !isChecked);
                                                }
                                              };
      
                                              return (
                                                // Below the Action Icons are Not Needed, Permission table Id
                                                (icon.id === 1 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 2 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 3 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 4 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 5 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 6 && [4, 5, 6, 7, 8, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 7 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 8 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 9 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 10 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 11 && [8, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 12 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 13 && [5, 6, 7, 8, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 14 && [5, 6, 7, 8, 11, 12, 13, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 15 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 21 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 22 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 23 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 24 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 16, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 25 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 15, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 26 && [8, 12, 23, 24, 25].includes(permission.id)) ||
      
                                                  (icon.id === 44 && [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 45 && [4, 5, 6, 7, 8, 9, 11, 12, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 46 && [2, 4, 5, 6, 7, 8, 11, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 47 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 48 && [4, 5, 6, 7, 8, 9, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 49 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 50 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
      
                                                  (icon.id === 108 && [19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 109 && [8, 9, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 110 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 111 && [8, 9, 12, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 112 && [2, 8, 9, 12, 19, 20, 21, 22, 23].includes(permission.id)) ||
                                                  (icon.id === 113 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 114 && [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 115 && [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25].includes(permission.id)) ||
                                                  (icon.id === 134 && [1, 2, 4, 5, 6, 7, 8, 9, 11, 12, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25].includes(permission.id))
                                                  ? null : (
                                                  <div key={`${icon.id}-${permission.id}`} className="flex items-center space-x-2">
                                                    <Checkbox
                                                      disabled
                                                      checked={isChecked}
                                                      onChange={handleCheckboxChange}
                                                      sx={{
                                                        transform: 'scale(1.0)', // Reduces the size further
                                                        color: 'customGray', '& .MuiSvgIcon-root': {
                                                          backgroundColor: 'white', // Background color for the checkbox
                                                          borderRadius: '4px', // Optional: round the corners slightly
                                                          fontSize: 18, // Adjust the size of the checkbox
                                                          // border: '1px solid #ccc', // Custom border
                                                        }, '&.Mui-checked': {
                                                          color: (isViewMode || !selectedIcons[icon.id])
                                                            ? 'rgba(41, 203, 0, 0.5)' // Dimmed green for disabled checked state
                                                            : 'rgba(41, 203, 0, 0.5)', // Bright green when checked and enabled
                                                        },
                                                        '&.Mui-disabled .MuiSvgIcon-root': {
                                                          backgroundColor: '#F2F4F9', // Explicitly set disabled bg color
                                                        },
                                                      }}
                                                    />
                                                    <span className="text-md text-customGray">{permission.name}</span>
                                                    <span className="ml-2 text-customGray">{iconMapping[permission.name] || null}</span>
                                                  </div>
                                                )
                                              );
                                            })}
                                          </div>
                                        ) : null}
    </div>
  )
}
